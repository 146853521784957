export enum HEALTH_PLAN_INFO_PAYMENT_STATUS {
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
}

export const NOT_ENROLLED_IN_HEALTH_INSURANCE_THROUGH_CUSTOMER = {
  eligibility_name: 'notSupported',
  enabled: false,
}

export enum REMOVE_HEALTH_PLAN_INFO_TYPE {
  INFRACTION_PAYMENTS = 'INFRACTION_PAYMENTS',
  COST_SHARING_PAYMENTS = 'COST_SHARING_PAYMENTS',
  HEALTH_PLAN = 'HEALTH_PLAN',
}

export const HEALTH_PLAN_ELIGIBILITY_STATUSES = {
  ELIGIBLE: 'eligible',
  PENDING: 'pending',
  INELIGIBLE: 'ineligible',
}

export enum HealthPlanFromPage {
  PROFILE = 'profile',
  ONBOARD = 'onboard',
  OTHER = 'other',
}

export const HealthPlanKeys = [
  'medicalPlanEligibilityStatus',
  'healthPlanMembershipId',
  'healthPlanName',
  'legalDob',
  'legalFirstName',
  'legalLastName',
  'healthPlanConsent',
  'eligibilityType',
  'isHealthPlanIntegrated',
]

export const SecondaryHealthPlanKeys = [
  'isSecondaryHealthPlanIntegrated',
  'secondaryHealthPlanMedicalPlanEligibilityStatus',
  'secondaryHealthPlanMembershipId',
  'secondaryHealthPlanName',
  'secondaryHealthPlanLegalDob',
  'secondaryHealthPlanLegalFirstName',
  'secondaryHealthPlanLegalLastName',
]

import { useEffect, useState } from 'react'

import { AxiosInstance } from 'axios'

import { useBackToTriage } from './useBackToTriage'
import {
  Address,
  GetWordCloudWordsActionData,
  LocationMatcherDisplayFormats,
  NewSearchData,
  RiskFactorsRequest,
  RiskFactorsResponse,
  SearcherInfo,
  SearchRequestData,
  TriageSearch,
  WordCloudWords,
} from '../../../models'
import { TREATMENT_OPTIONS } from '../../common/constants/treatmentOptions'
import { hasPastSI } from '../../highAlert/utils'
import { useOnsiteOptions } from '../../locationFinder/hooks/useOnsiteOptions'

export interface useLocationFinderArgs {
  selectedSearch?: TriageSearch
  searcherInfo?: SearcherInfo | null
  postTriageSearch: (newSearchData: NewSearchData) => Promise<any>
  goBack: () => void
  shouldFetchJWT?: boolean
  isDirectPath?: boolean
  userId: string
  selectedTreatmentOption: string | null
  supportedPrograms: string[]
  isCreatingSearch: boolean
  axiosInstance: AxiosInstance
  getWordCloudWords: (data: GetWordCloudWordsActionData) => Promise<any>
  wordCloudWords: WordCloudWords | null
  searcherAge?: number
  primaryNeed?: string
  searchRequest: SearchRequestData
  postOnsiteProviders: (arg: { lyraId: string; data: SearchRequestData }) => any
  clearOnsiteProviders: () => void
  addressOptions: Array<Address>
  selectedDropdownValue: { value: number; locationName: string }
  customerSpecificOptions:
    | { onsiteDescription: React.ReactNode; onsiteFormat: LocationMatcherDisplayFormats | undefined }
    | undefined
  userCountryName: string
  isLyraSocialCareEnabled: boolean
  fetchedMetadataVersion?: string
}

export const useLocationFinder = ({
  selectedSearch,
  searcherInfo,
  postTriageSearch,
  shouldFetchJWT = false,
  isDirectPath = false,
  userId,
  selectedTreatmentOption,
  searchRequest,
  clearOnsiteProviders,
  postOnsiteProviders,
  supportedPrograms,
  axiosInstance,
  goBack,
  primaryNeed,
  isCreatingSearch,
  wordCloudWords,
  getWordCloudWords,
  addressOptions,
  selectedDropdownValue,
  customerSpecificOptions,
  userCountryName,
  isLyraSocialCareEnabled,
  fetchedMetadataVersion,
}: useLocationFinderArgs) => {
  const [loading, setLoading] = useState(true)
  const [hasSIHistory, setHasSIHistory] = useState(true)
  const [jwtToken, setJwtToken] = useState('')

  /** Define onsite provider options */
  const { onsiteLocationRadioOptions, onsiteCityDropdownItems, setOnsiteLocationRadioOptions, doOnsiteLocationsExist } =
    useOnsiteOptions({
      addressOptions,
      selectedDropdownValue,
      customerSpecificOptions,
    })

  useEffect(() => {
    async function fetchData() {
      const requests: Promise<any>[] = []

      // Web requires a JWT to send to our own proxy server
      if (shouldFetchJWT) {
        requests.push(
          axiosInstance.get(`v2/okta/jwt`).then((response) => {
            setJwtToken(response.data)
          }),
        )
      }

      if (isDirectPath) {
        // Direct path provider search is skipped if user has SI history.
        // monthsToLookBack param matches triage API logic to determine SI history
        const request: RiskFactorsRequest = {
          lyra_id: userId,
          monthsToLookBack: 6,
        }
        requests.push(
          axiosInstance.post<RiskFactorsResponse>(`/trs/v1/risk_factors`, request).then((response) => {
            setHasSIHistory(hasPastSI(response.data))
          }),
        )
      }

      // Clear the onsite providers from redux to make sure treatment options other than individual
      // therapy do not list onsite locations on the location finder page. The onsite locations will
      // be populated when the user selects individual therapy as the treatment option.
      clearOnsiteProviders()
      if (selectedTreatmentOption === TREATMENT_OPTIONS.INDIVIDUAL_THERAPY) {
        requests.push(
          postOnsiteProviders({
            lyraId: userId,
            data: { ...searchRequest, treatmentPreference: TREATMENT_OPTIONS.INDIVIDUAL_THERAPY },
          }),
        )
      }

      await Promise.all(requests).finally(() => {
        setLoading(false)
      })
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldFetchJWT,
    isDirectPath,
    axiosInstance,
    userId,
    clearOnsiteProviders,
    selectedTreatmentOption,
    postOnsiteProviders,
  ])

  let directPathIneligible = false
  if (!selectedTreatmentOption) {
    directPathIneligible = true
  } else if (selectedTreatmentOption === TREATMENT_OPTIONS.COACHING) {
    directPathIneligible = hasSIHistory || !supportedPrograms.some((p) => p === selectedTreatmentOption)
  }

  const handleBackPress = useBackToTriage({
    goBack,
    search: selectedSearch as TriageSearch,
    searcherInfo,
    country: userCountryName,
    lyraSocialCareEnabled: isLyraSocialCareEnabled,
    postTriageSearch,
    isCreatingSearch,
    primaryNeedsWord: primaryNeed ?? 'N/A',
    wordCloudWords,
    getWordCloudWords,
    fetchedMetadataVersion,
  })
  return {
    loading,
    jwtToken,
    directPathIneligible,
    handleBackPress,
    onsiteLocationRadioOptions,
    onsiteCityDropdownItems,
    setOnsiteLocationRadioOptions,
    doOnsiteLocationsExist,
  }
}

import React, { FunctionComponent, useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { Divider } from '../../atoms/divider/Divider'
import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { ScheduleIcon } from '../../atoms/icons/ScheduleIcon'
import { CareNavigatorCalendarIconIllustration } from '../../atoms/illustrations/CareNavigatorCalendarIconIllustration'
import { Image } from '../../atoms/image/Image'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { AppContext } from '../../context'
import { CareOptionCard } from '../../templates/careOptionCard/CareOptionCard'
import { ColumnView, RowView, SpacedView } from '../../templates/content/CommonViews'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export interface CareNavigatorScheduleCallOptionCardProps {
  careNavigatorLicense?: string
  careNavigatorHeadshot?: React.ReactNode
  careNavigatorName?: string
  showMinimalCTAText?: boolean
  onPress: (() => void) | undefined
}
const CardContainer = styled(PressableOpacity)<{
  theme: ThemeType
}>(({ theme }) => ({
  borderColor: theme.colors.borderDefault,
  borderRadius: theme.spacing['8px'],
  borderStyle: 'solid',
  borderWidth: '1px',
  minHeight: '72px',
  padding: theme.spacing['16px'],
  marginTop: theme.spacing['16px'],
}))

const ContentContainer = styled(RowView)<{ theme: ThemeType }>({
  alignItems: 'center',
})

const DividerContainer = styled.View(({ theme }) => ({
  marginVertical: theme.spacing['16px'],
}))

const IconContainer = styled.View({
  alignSelf: 'center',
})

const BodyContainer = styled(ColumnView)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['16px'],
  flex: 'auto',
}))

const CareNavigatorImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  height: spacing['40px'],
  width: spacing['40px'],
  borderRadius: '65536px',
}))

const optionHeader = (
  <FormattedMessage
    defaultMessage='Schedule a call'
    description='Label of button that leads to a page for scheduling a call with a Care Navigator'
  />
)

const optionDescription = (
  <FormattedMessage
    defaultMessage='We’ll call you at a convenient time.'
    description='Text description explaining that our Care Navigator team will call the user at a time that is convenient for the user'
  />
)

const careNavigatorHeader = (
  <FormattedMessage
    defaultMessage='Your Dedicated Care Navigator'
    description='Label of button that leads to a page for scheduling a call with a Care Navigator that'
  />
)

const careNavigatorDescription = ({
  careNavigatorLicense,
  careNavigatorName,
  showMinimalCTAText,
}: Partial<CareNavigatorScheduleCallOptionCardProps>) => {
  if (showMinimalCTAText) {
    return (
      <FormattedMessage
        defaultMessage='{name} {license}'
        description='Text describing the name and license of the care navigator'
        values={{
          name: careNavigatorName,
          license: careNavigatorLicense,
        }}
      />
    )
  } else {
    return (
      <FormattedMessage
        defaultMessage='{name} {license} can help you choose a provider and care option.'
        description='Text that explains that the care navigator can help the user understand and select a provider and care options'
        values={{
          name: careNavigatorName,
          license: careNavigatorLicense,
        }}
      />
    )
  }
}

export const CareNavigatorScheduleCallOptionCard: FunctionComponent<CareNavigatorScheduleCallOptionCardProps> = ({
  careNavigatorHeadshot,
  careNavigatorLicense,
  careNavigatorName,
  showMinimalCTAText,
  onPress,
}) => {
  const { colors } = useTheme()
  const { isDedicatedCareNavigatorEnabled } = useContext(AppContext)
  if (isDedicatedCareNavigatorEnabled) {
    return (
      <CardContainer testID={tID('CareNavigatorScheduleCallOptionCard')} onPress={onPress}>
        <ContentContainer>
          <IconContainer>
            <ScheduleIcon />
          </IconContainer>
          <BodyContainer>
            <BodyText text={optionHeader} size={BodyTextSize.DEFAULT} />
          </BodyContainer>
          <SpacedView marginLeft='16px'>
            <ChevronIcon direction={ChevronIconDirection.RIGHT} fillColor={colors.iconDefault} size={24} />
          </SpacedView>
        </ContentContainer>
        <DividerContainer>
          <Divider />
        </DividerContainer>
        <ContentContainer>
          <CareNavigatorImageContainer source={careNavigatorHeadshot as string} />
          <BodyContainer>
            <BodyText text={careNavigatorHeader} size={BodyTextSize.CAPTION} color={colors.textSecondary} />
            <BodyText
              testID={'CareNavigatorScheduleCallOptionCard-BodyText'}
              text={careNavigatorDescription({
                careNavigatorLicense,
                careNavigatorName,
                showMinimalCTAText,
              })}
            />
          </BodyContainer>
        </ContentContainer>
      </CardContainer>
    )
  } else {
    return (
      <CareOptionCard
        onPress={onPress}
        optionIcon={<CareNavigatorCalendarIconIllustration />}
        optionHeader={optionHeader}
        optionDescription={optionDescription}
        testId={'CareNavigatorScheduleCallOptionCard'}
      />
    )
  }
}

import React from 'react'

import { Map } from 'immutable'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { Header as UICoreHeader } from '@lyrahealth-inc/ui-core'
import { colors } from '@lyrahealth-inc/ui-core-crossplatform'

import LanguageSelector from '../../../features/intl/LanguageSelector'

export interface RegistrationHeaderProps {
  customClass: string
  handleBackButtonClick: (() => void) | undefined
  handleLogout: () => void
  handleLogin: () => void
  trackMenuClick: () => void
  lyraLogo: React.ReactElement
  handleRegister: () => void
  ghost: boolean
}

const RegistrationHeader: React.FunctionComponent<RegistrationHeaderProps> = ({
  customClass,
  handleBackButtonClick,
  handleLogout,
  handleLogin,
  trackMenuClick,
  lyraLogo,
  handleRegister,
  ghost,
}) => {
  const { isDisplayLanguageEnabled } = useFlags()
  const loggedOutButtons = []
  if (isDisplayLanguageEnabled) {
    loggedOutButtons.push(<LanguageSelector textColor={colors.teal5} dropdownAlignment='right' />)
  }

  return (
    <UICoreHeader
      className={customClass}
      notification={true}
      handleBackButtonClick={handleBackButtonClick}
      handleLogout={handleLogout}
      handleLogin={handleLogin}
      trackMenuClick={trackMenuClick}
      user={Map()}
      icon={''}
      lyraLogo={lyraLogo}
      shouldHideLoginButton
      handleRegister={handleRegister}
      loggedOutButtons={loggedOutButtons}
      loggedInButtons={[]}
      ghost={ghost}
    />
  )
}

export default RegistrationHeader

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import {
  getSessionLimitProgramCategoryByTreatmentOption,
  TREATMENT_OPTIONS_TYPE,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'

import {
  getIsEAPSessionCountExhausted,
  getIsEAPSessionCountExhaustedByProgramGetter,
} from '../../data/customer/customerSelectors'

export type GetIsEAPSessionCountExhaustedOptions = {
  // treatment option can be expectedly undefined if treatment option has not been selected yet during triage
  treatmentOption?: TREATMENT_OPTIONS_TYPE | undefined
}

export function useGetIsEAPSessionCountExhausted() {
  const { isProgramLevelSessionLimitEnabled } = useFlags()
  const isEAPSessionCountExhausted = useSelector(getIsEAPSessionCountExhausted)
  const getIsEAPSessionCountExhaustedByProgram = useSelector(getIsEAPSessionCountExhaustedByProgramGetter)

  return useCallback(
    (options?: GetIsEAPSessionCountExhaustedOptions) => {
      const { treatmentOption } = options ?? {}
      if (treatmentOption == null || !isProgramLevelSessionLimitEnabled) {
        // if treatment option is unknown or program-level session limit is disabled,
        // return whether overall session limit is exhausted
        return isEAPSessionCountExhausted
      } else {
        const programCategory = getSessionLimitProgramCategoryByTreatmentOption(treatmentOption)
        const isEAPSessionCountExhaustedByProgram = getIsEAPSessionCountExhaustedByProgram(programCategory)
        return isEAPSessionCountExhaustedByProgram
      }
    },
    [isProgramLevelSessionLimitEnabled, isEAPSessionCountExhausted, getIsEAPSessionCountExhaustedByProgram],
  )
}

export function useIsEAPSessionCountExhausted(options?: GetIsEAPSessionCountExhaustedOptions) {
  const getIsEAPSessionCountExhaustedByProgram = useGetIsEAPSessionCountExhausted()
  return getIsEAPSessionCountExhaustedByProgram(options)
}

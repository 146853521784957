import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Icon of a person with headphones on
 */
export const ContactIcon: FunctionComponent<ContactIconProps> = ({ size = 24, fillColor, ...props }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} fill='none' {...props}>
      <Path
        d='M19 20.75C18.8019 20.7474 18.6126 20.6676 18.4725 20.5275C18.3324 20.3874 18.2526 20.1981 18.25 20C18.25 19.2707 17.9603 18.5712 17.4445 18.0555C16.9288 17.5397 16.2293 17.25 15.5 17.25H8.5C7.77065 17.25 7.07118 17.5397 6.55546 18.0555C6.03973 18.5712 5.75 19.2707 5.75 20C5.75 20.1989 5.67098 20.3897 5.53033 20.5303C5.38968 20.671 5.19891 20.75 5 20.75C4.80109 20.75 4.61032 20.671 4.46967 20.5303C4.32902 20.3897 4.25 20.1989 4.25 20C4.25264 18.8736 4.70126 17.7942 5.49771 16.9977C6.29417 16.2013 7.37364 15.7526 8.5 15.75H15.5C16.6264 15.7526 17.7058 16.2013 18.5023 16.9977C19.2987 17.7942 19.7474 18.8736 19.75 20C19.7474 20.1981 19.6676 20.3874 19.5275 20.5275C19.3874 20.6676 19.1981 20.7474 19 20.75Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M12.0001 13.3801C11.1338 13.3801 10.287 13.1232 9.56672 12.642C8.84643 12.1607 8.28504 11.4766 7.95353 10.6763C7.62201 9.87593 7.53528 8.99526 7.70428 8.14562C7.87328 7.29599 8.29044 6.51555 8.90299 5.90299C9.51555 5.29044 10.296 4.87328 11.1456 4.70428C11.9953 4.53528 12.8759 4.62201 13.6763 4.95353C14.4766 5.28504 15.1607 5.84643 15.642 6.56672C16.1232 7.28701 16.3801 8.13384 16.3801 9.00012C16.3775 10.161 15.9152 11.2735 15.0943 12.0943C14.2735 12.9152 13.161 13.3775 12.0001 13.3801V13.3801ZM12.0001 6.13012C11.4301 6.13012 10.8729 6.29929 10.399 6.61619C9.92521 6.93309 9.55609 7.38348 9.3384 7.91031C9.12072 8.43715 9.06427 9.01673 9.17621 9.57567C9.28815 10.1346 9.56344 10.6478 9.96721 11.0501C10.371 11.4525 10.8851 11.726 11.4444 11.836C12.0037 11.946 12.5831 11.8875 13.1092 11.668C13.6353 11.4485 14.0844 11.0778 14.3996 10.6029C14.7149 10.1279 14.8821 9.57015 14.8801 9.00012C14.8749 8.23884 14.5694 7.51039 14.0302 6.97301C13.4909 6.43563 12.7614 6.13274 12.0001 6.13012Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M19.75 8H18.25C18.25 5.66 15.45 3.75 12 3.75C8.55 3.75 5.75 5.66 5.75 8H4.25C4.25 4.83 7.73 2.25 12 2.25C16.27 2.25 19.75 4.83 19.75 8Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M18.5 8H19C19.3978 8 19.7794 8.15804 20.0607 8.43934C20.342 8.72064 20.5 9.10218 20.5 9.5V10.5C20.5 10.8978 20.342 11.2794 20.0607 11.5607C19.7794 11.842 19.3978 12 19 12H18.5C18.2348 12 17.9804 11.8946 17.7929 11.7071C17.6054 11.5196 17.5 11.2652 17.5 11V9C17.5 8.73478 17.6054 8.48043 17.7929 8.29289C17.9804 8.10536 18.2348 8 18.5 8Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M19 12.75H18.5C18.0359 12.75 17.5908 12.5656 17.2626 12.2374C16.9344 11.9092 16.75 11.4641 16.75 11V9C16.7526 8.53668 16.9378 8.09309 17.2655 7.76546C17.5931 7.43784 18.0367 7.25263 18.5 7.25H19C19.5967 7.25 20.169 7.48705 20.591 7.90901C21.0129 8.33097 21.25 8.90326 21.25 9.5V10.5C21.25 11.0967 21.0129 11.669 20.591 12.091C20.169 12.5129 19.5967 12.75 19 12.75V12.75ZM18.5 8.75C18.4337 8.75 18.3701 8.77634 18.3232 8.82322C18.2763 8.87011 18.25 8.9337 18.25 9V11C18.25 11.0663 18.2763 11.1299 18.3232 11.1768C18.3701 11.2237 18.4337 11.25 18.5 11.25H19C19.1981 11.2474 19.3874 11.1676 19.5275 11.0275C19.6676 10.8874 19.7474 10.6981 19.75 10.5V9.5C19.7474 9.30189 19.6676 9.11263 19.5275 8.97253C19.3874 8.83244 19.1981 8.75259 19 8.75H18.5Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M5.5 12H5C4.60218 12 4.22064 11.842 3.93934 11.5607C3.65804 11.2794 3.5 10.8978 3.5 10.5L3.5 9.5C3.5 9.10218 3.65804 8.72064 3.93934 8.43934C4.22064 8.15804 4.60218 8 5 8H5.5C5.76522 8 6.01957 8.10536 6.20711 8.29289C6.39464 8.48043 6.5 8.73478 6.5 9V11C6.5 11.2652 6.39464 11.5196 6.20711 11.7071C6.01957 11.8946 5.76522 12 5.5 12Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M5.5 12.75H5C4.40326 12.75 3.83097 12.5129 3.40901 12.091C2.98705 11.669 2.75 11.0967 2.75 10.5V9.5C2.75 8.90326 2.98705 8.33097 3.40901 7.90901C3.83097 7.48705 4.40326 7.25 5 7.25H5.5C5.96332 7.25263 6.40691 7.43784 6.73454 7.76546C7.06216 8.09309 7.24737 8.53668 7.25 9V11C7.25 11.4641 7.06563 11.9092 6.73744 12.2374C6.40925 12.5656 5.96413 12.75 5.5 12.75V12.75ZM5 8.75C4.80189 8.75259 4.61263 8.83244 4.47253 8.97253C4.33244 9.11263 4.25259 9.30189 4.25 9.5V10.5C4.25259 10.6981 4.33244 10.8874 4.47253 11.0275C4.61263 11.1676 4.80189 11.2474 5 11.25H5.5C5.5663 11.25 5.62989 11.2237 5.67678 11.1768C5.72366 11.1299 5.75 11.0663 5.75 11V9C5.75 8.9337 5.72366 8.87011 5.67678 8.82322C5.62989 8.77634 5.5663 8.75 5.5 8.75H5Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M7.75 13.5C7.47386 13.5 7.25 13.7239 7.25 14C7.25 14.2761 7.47386 14.5 7.75 14.5H8.25C8.52614 14.5 8.75 14.2761 8.75 14C8.75 13.7239 8.52614 13.5 8.25 13.5H7.75Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M8.25 15.25H7.75C7.41848 15.25 7.10054 15.1183 6.86612 14.8839C6.6317 14.6495 6.5 14.3315 6.5 14C6.5 13.6685 6.6317 13.3505 6.86612 13.1161C7.10054 12.8817 7.41848 12.75 7.75 12.75H8.25C8.58152 12.75 8.89946 12.8817 9.13388 13.1161C9.3683 13.3505 9.5 13.6685 9.5 14C9.5 14.3315 9.3683 14.6495 9.13388 14.8839C8.89946 15.1183 8.58152 15.25 8.25 15.25V15.25Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M7 14.75H6C5.77019 14.75 5.54262 14.7047 5.3303 14.6168C5.11798 14.5288 4.92507 14.3999 4.76256 14.2374C4.60006 14.0749 4.47116 13.882 4.38321 13.6697C4.29526 13.4574 4.25 13.2298 4.25 13V12C4.25 11.8011 4.32902 11.6103 4.46967 11.4697C4.61032 11.329 4.80109 11.25 5 11.25C5.19891 11.25 5.38968 11.329 5.53033 11.4697C5.67098 11.6103 5.75 11.8011 5.75 12V13C5.75 13.0663 5.77634 13.1299 5.82322 13.1768C5.87011 13.2237 5.9337 13.25 6 13.25H7C7.19891 13.25 7.38968 13.329 7.53033 13.4697C7.67098 13.6103 7.75 13.8011 7.75 14C7.75 14.1989 7.67098 14.3897 7.53033 14.5303C7.38968 14.671 7.19891 14.75 7 14.75V14.75Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

type ContactIconProps = {
  size?: string | number
  fillColor?: string
}

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export interface BoltIconProps {
  width?: number
  height?: number
  fillColor?: string
}

export const BoltIcon: FunctionComponent<BoltIconProps> = ({ width = 8, height = 14, fillColor }) => {
  const { colors } = useTheme()
  const { fill } = colors.icons.boltIcon

  return (
    <Svg width={width} height={height} fill='none' viewBox='0 0 8 14' testID={tID('BoltIcon')}>
      <Path
        fill={fillColor || fill}
        d='M3.16 12.906v-4.14H.5c-.37 0-.62-.4-.44-.73L3.74.866c.23-.47.94-.3.94.23v4.19h2.54c.37 0 .61.39.45.72l-3.56 7.12c-.24.48-.95.31-.95-.22z'
      ></Path>
    </Svg>
  )
}

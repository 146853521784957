import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { SET_CSRF, SET_OKTA_WIDGET, SET_USER_INFO_FETCHED, SET_USER_INFO_STALE } from './loginActions'
import { CSRF_HEADER_NAME, CSRF_TOKEN } from '../../common/constants/appConstants'

const initialState = fromJS({})
export default createReducer(initialState, {
  [SET_OKTA_WIDGET]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('oktaWidget', action.data)
    return $$state
  },
  [SET_USER_INFO_FETCHED]: ($$state: $TSFixMe) => {
    return $$state.set('hasFetchedUser', true)
  },
  [SET_USER_INFO_STALE]: ($$state: $TSFixMe) => {
    return $$state.set('hasFetchedUser', false)
  },
  [SET_CSRF]: ($$state: $TSFixMe, action: $TSFixMe) => {
    // Our store uses Session Storage for persisence.
    // In order to save requests , we are using the local storage to store the CSRF.
    // That way, when opening an instance of the app in a new tab, we don't need to refetch the CSRF
    localStorage.setItem(CSRF_HEADER_NAME, action.data['csrf-header'])
    localStorage.setItem(CSRF_TOKEN, action.data['csrf-token'])
    return $$state
  },
})

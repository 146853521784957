import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export interface SelfCareIconProps {
  size?: number
  fillColor?: string
}

export const SelfCareIcon: FunctionComponent<SelfCareIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()
  const { selfCareIcon } = colors.icons

  return (
    <Svg testID={tID('SelfCareIcon')} width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <G clip-path='url(#clip0_1718_74194)'>
        <Path
          d='M22.06 9.55C22.06 5.38 18.67 2 14.51 2C11.13 2 8.26 4.23 7.3 7.3C4.23 8.26 2 11.13 2 14.5C2 18.67 5.39 22.05 9.55 22.05C12.93 22.05 15.8 19.82 16.76 16.75C19.83 15.79 22.06 12.92 22.06 9.54V9.55ZM9.55 20.56C6.21 20.56 3.5 17.84 3.5 14.51C3.5 12.09 4.92 10.01 6.97 9.04C6.96 9.21 6.94 9.38 6.94 9.56C6.94 13.73 10.33 17.11 14.5 17.11C14.68 17.11 14.85 17.1 15.02 17.08C14.05 19.13 11.97 20.56 9.55 20.56ZM17.08 15.03C17.09 14.86 17.11 14.69 17.11 14.51C17.11 10.34 13.72 6.96 9.56 6.96C9.38 6.96 9.21 6.97 9.04 6.99C10.01 4.94 12.1 3.51 14.51 3.51C17.85 3.51 20.56 6.23 20.56 9.56C20.56 11.98 19.14 14.06 17.08 15.03Z'
          fill={fillColor || selfCareIcon.fill}
        />
      </G>
      <Defs>
        <ClipPath id='clip0_1718_74194'>
          <Rect width='20' height='20' fill={selfCareIcon.clipPath.fill} transform='translate(2 2)' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

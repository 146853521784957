import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { SET_CURRENT_COUNTRY } from './currentCountryActions'

const initialState = fromJS({
  isLoaded: false,
})

export default createReducer(initialState, {
  [SET_CURRENT_COUNTRY]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const customerCountryInfo = fromJS(action.val)
    $$state = $$state.mergeDeep(customerCountryInfo, fromJS({ isLoaded: true }))
    return $$state
  },
})

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal, View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { XIcon } from '../../atoms/icons/XIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { TertiaryButton } from '../../atoms/tertiaryButton/TertiaryButton'
import { IS_WEB } from '../../constants'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { ThemeType, tID } from '../../utils'

export type OnboardingExitModalProps = {
  isVisible: boolean
  onCancel: () => void
  onExit: () => void
  isLatestMatches?: boolean
}

const ModalContainer = styled.View<{ theme: ThemeType; isMinWidthTablet: boolean }>(({ theme, isMinWidthTablet }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.backgroundScrim,
  justifyContent: 'flex-end' as const,
  position: 'absolute' as const,
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  ...(IS_WEB && {
    position: 'fixed' as const,
    justifyContent: isMinWidthTablet ? ('start' as const) : ('flex-end' as const),
  }),
}))

const ModalCard = styled.View<{ theme: ThemeType; isMinWidthTablet: boolean }>(({ theme, isMinWidthTablet }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  borderBottomRightRadius: isMinWidthTablet ? '16px' : 0,
  borderBottomLeftRadius: isMinWidthTablet ? '16px' : 0,
  marginTop: isMinWidthTablet ? '30px' : 0,
  padding: isMinWidthTablet ? '24px' : '32px 16px',
  shadowColor: theme.colors.shadowLow,
  shadowOffset: {
    height: 0,
    width: 0,
  },
  shadowOpacity: 0.05,
  shadowRadius: '15px',
  width: isMinWidthTablet ? '544px' : '100%',
}))

const ModalHeader = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const ModalBody = styled(BodyText)({
  marginBottom: '32px',
})

const ButtonContainer = styled.View(({ isMinWidthTablet }: { isMinWidthTablet: boolean }) => {
  const flexDirection = isMinWidthTablet ? ('row' as const) : ('column-reverse' as const)

  return { flexDirection, justifyContent: 'flex-end' }
})

export const OnboardingExitModal = ({ isVisible, onCancel, onExit, isLatestMatches }: OnboardingExitModalProps) => {
  const { isMinWidthTablet } = useMediaQuerySize()
  const { colors } = useTheme()

  return (
    <Modal
      animationType='fade'
      onRequestClose={onCancel}
      visible={isVisible}
      transparent
      testID={tID('OnboardingExitModal')}
    >
      <ModalContainer isMinWidthTablet={isMinWidthTablet}>
        <ModalCard isMinWidthTablet={isMinWidthTablet}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <ModalHeader
              color={colors.textPrimary}
              size={SubheadSize.MEDIUM}
              text={
                !isLatestMatches ? (
                  <FormattedMessage
                    defaultMessage='Exit without finishing?'
                    description='Prompt asking if the user wants to leave onboarding even though they have not yet completed it'
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage='Exit without booking?'
                    description='Prompt asking if the user wants to leave booking if they are in latest provider matches flow'
                  />
                )
              }
            />
            <PressableOpacity onPress={onCancel} testID={tID('OnboardingExitModal-xButton')}>
              <XIcon fillColor={colors.iconDefault} />
            </PressableOpacity>
          </View>
          <ModalBody
            color={colors.textPrimary}
            size={BodyTextSize.DEFAULT}
            text={
              !isLatestMatches ? (
                <FormattedMessage
                  defaultMessage='To ensure your responses are current, they are only saved for a short period of time. You may need to start over when you return.'
                  description='Message that appears before the user exits onboarding to warn that the information they entered may be gone the next time they return'
                />
              ) : (
                <FormattedMessage
                  defaultMessage='Since appointment availability may change, you will need to start over when you return.'
                  description='Message that appears before the user exits booking for latest provider results to warn that the availability for providers might not be the same'
                />
              )
            }
          />
          <ButtonContainer isMinWidthTablet={isMinWidthTablet}>
            <TertiaryButton
              onPress={onCancel}
              text={
                <FormattedMessage
                  defaultMessage='Cancel'
                  description='Label of button that closes the modal if the user decides not to exit onboarding'
                />
              }
              fullWidth={!isMinWidthTablet}
              testID={tID('OnboardingExitModal-cancelButton')}
            />
            <View style={{ width: 16, height: 8 }} />
            <PrimaryButton
              onPress={onExit}
              text={
                <FormattedMessage
                  defaultMessage='Exit'
                  description='Label of button that allows the user to leave the onboarding flow and return to the home base'
                />
              }
              fullWidth={!isMinWidthTablet}
              testID={tID('OnboardingExitModal-exitButton')}
            />
          </ButtonContainer>
        </ModalCard>
      </ModalContainer>
    </Modal>
  )
}

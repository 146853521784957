import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'
import { defineMessage } from 'react-intl'

export const nextera: GetCustomerCopyFunc = () => {
  return {
    dependentButtonText: defineMessage({
      defaultMessage: 'No, I’m a dependent, spouse/domestic partner, or household member',
      description: 'Declaring the current user is a dependent of an eligible user',
    }),
  }
}

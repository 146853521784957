import { GetCustomerCopyFunc, WORKLIFE_SERVICE_IDS } from '@lyrahealth-inc/shared-app-logic'

import DependentsIllustration from '../assets/worklife/illustration-dependents.png'
import FinancialIllustration from '../assets/worklife/illustration-financial.png'
import LegalIllustration from '../assets/worklife/illustration-legal.png'
import SecurityIllustration from '../assets/worklife/illustration-security.png'

export const dell: GetCustomerCopyFunc = () => {
  return {
    workLifeServices: [
      {
        identifiers: WORKLIFE_SERVICE_IDS.LEGAL_SERVICES,
        title: 'Legal Services',
        img: LegalIllustration,
        bullets: [],
        CTA: 'Access legal support',
        PATH: 'https://leplb0050.upoint.ap.alight.com/ah-angular-afirst-web/#/web/dell/contentPage?articleId=A52AE01B-988D-42F0-9A43-2E9668C5CD77',
      },
      {
        identifiers: WORKLIFE_SERVICE_IDS.FINANCIAL_SERVICES,
        title: 'Financial Services',
        img: FinancialIllustration,
        bullets: [],
        CTA: 'Access financial support',
        PATH: 'https://leplb0050.upoint.ap.alight.com/ah-angular-afirst-web/#/web/dell/contentPage?articleId=D0F4A9BF-CB29-4993-80C3-429D3AA0811F',
      },
      {
        identifiers: WORKLIFE_SERVICE_IDS.IDENTITY_THEFT,
        title: 'Identity Theft',
        img: SecurityIllustration,
        bullets: [],
        CTA: 'Consult with an expert',
        PATH: 'https://leplb0050.upoint.ap.alight.com/ah-angular-afirst-web/#/web/dell/contentPage?articleId=A52AE01B-988D-42F0-9A43-2E9668C5CD77',
      },
      {
        identifiers: WORKLIFE_SERVICE_IDS.DEPENDENT_CARE,
        title: 'Dependent Care',
        img: DependentsIllustration,
        bullets: [],
        CTA: 'Find dependent care',
        PATH: 'https://leplb0050.upoint.ap.alight.com/ah-angular-afirst-web/#/web/dell/cp/landing6',
      },
    ],
  }
}

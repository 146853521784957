import { createContext } from 'react'

import { noop } from 'lodash-es'

import { MIXPANEL_PROPERTY } from '@lyrahealth-inc/shared-app-logic'

export const AppContext = createContext<{
  customerName?: string
  detectedCountryIso?: string
  domain?: string
  copyPasteDisabled?: boolean
  setModalStatusOpen?: (status: boolean) => void
  initiateSSOForLyraLearn?: () => void
  /* BEGIN feature flags */
  shouldSkipTreatmentRecsPageForTier3?: boolean
  isLatestMatchesEnabled?: boolean
  shouldDisplayFastTrackBooking?: boolean
  isCHCOutage?: boolean
  isHPIConsentWithMedicareEnabled?: boolean
  isParentLedBookingEnabled?: boolean
  shouldShowLCPositioning?: boolean
  showEnhancedProviderResults?: boolean
  hideUserTestimonialsFromLCxModal?: boolean
  isDedicatedCareNavigatorEnabled?: boolean
  shouldSeePendoGuides?: boolean
  shouldShowCostEstimatesAsFullyCovered?: boolean
  isCostMessagingEnabled?: boolean
  isFamilyHubEnabled?: boolean
  isPreferredNameEnabled?: boolean
  shouldShowNewMinimizedIntegratedBenefitsDesign?: boolean
  showPickedForYouV2?: boolean
  showUpdatedConnectedCalendar?: boolean
  showPickedForYouV3?: boolean
  enableNewClientTestimonials?: boolean
  shouldHardCodeFreenomeSessionResetDate?: boolean
  showChatBusinessHoursEndingAt6PM?: boolean
  routeUserDirectlyToAgentInInAppMessagingChatWindow?: boolean
  chatSessionTimeoutDuration?: number
  isEnhancedRiskProtocolEnabled?: boolean
  shouldShowAvailabilityPreference?: boolean
  /* END feature flags */

  /* Please be aware that using trackEvent may lead to loss of reusability within components
   that use this function. See "Tracking user interaction events with Mixpanel" in README
   for more details. */
  trackEvent?: (event: string, eventProps?: { [key in MIXPANEL_PROPERTY]?: any }) => void
  maxBenefitsToShowInExplorer?: number
  isClientDataShareEnabled?: boolean
  openBranchUrl?: (url: string) => void
}>({
  initiateSSOForLyraLearn: () => null,
  customerName: '',
  detectedCountryIso: undefined,
  domain: '',
  copyPasteDisabled: false,
  setModalStatusOpen: noop,
  isLatestMatchesEnabled: false,
  trackEvent: noop,
  maxBenefitsToShowInExplorer: 3,
  isClientDataShareEnabled: false,
  shouldDisplayFastTrackBooking: false,
  shouldSkipTreatmentRecsPageForTier3: false,
  isHPIConsentWithMedicareEnabled: false,
  shouldShowLCPositioning: false,
  showEnhancedProviderResults: false,
  hideUserTestimonialsFromLCxModal: false,
  isDedicatedCareNavigatorEnabled: false,
  shouldSeePendoGuides: false,
  shouldShowCostEstimatesAsFullyCovered: false,
  isCostMessagingEnabled: false,
  isFamilyHubEnabled: false,
  isPreferredNameEnabled: false,
  shouldShowNewMinimizedIntegratedBenefitsDesign: false,
  showPickedForYouV2: false,
  showUpdatedConnectedCalendar: false,
  showPickedForYouV3: false,
  enableNewClientTestimonials: false,
  shouldHardCodeFreenomeSessionResetDate: false,
  showChatBusinessHoursEndingAt6PM: false,
  routeUserDirectlyToAgentInInAppMessagingChatWindow: false,
  chatSessionTimeoutDuration: 600,
  isEnhancedRiskProtocolEnabled: false,
  shouldShowAvailabilityPreference: false,
})

import React from 'react'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import MoneyImage from '../assets/worklife/EY.png'
import LifeCareImage from '../assets/worklife/LifeCare.png'
import { BodyText } from '../atoms'

export const newyorkfed: GetCustomerCopyFunc = () => {
  return {
    workLifeServices: [
      {
        identifiers: {
          id: 'fedProtect',
          dataTestId: 'workLife-fedProtect',
        },
        title: 'FedProtect',
        img: MoneyImage,
        paragraph: (
          <BodyText
            text={
              // eslint-disable-next-line formatjs/no-literal-string-in-jsx
              'FedProtect, powered by Allstate Identity Protection, offers identity and credit monitoring, fraud remediation and restoration, and identity theft reimbursement.'
            }
          />
        ),
        bullets: [],
      },
      {
        identifiers: {
          id: 'ayco',
          dataTestId: 'workLife-ayco',
        },
        title: 'Ayco Financial Education',
        img: LifeCareImage,
        paragraph: (
          <>
            <BodyText
              text={
                // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                'FedProtect, powered by Allstate Identity Protection, offers identity and credit monitoring, fraud remediation and restoration, and identity theft reimbursement.'
              }
            />
            <BodyText
              text={
                // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                'Although Ayco coaches can’t provide investment advice, they are versed in Federal Reserve retirement benefits, and can share guidance to help you make informed decisions.'
              }
            />
          </>
        ),
        bullets: [],
      },
    ],
  }
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { noop } from 'lodash-es'
import { useTheme } from 'styled-components/native'

import { HeartDottedBorderIcon, MenuIcon } from '../../atoms'
import { IS_WEB } from '../../constants'
import { Menu, MenuItemProps } from '../menu/Menu'

export interface HeaderNavMenuProps {
  handleBenefitsPress: () => void
  handleAccountPress: () => void
  handleFAQsPress?: () => void
  handleReferralPress?: () => void
  handleLogOutPress: () => void
  handleHomePress?: () => void
  handleCarePress?: () => void
  handleSessionsPress?: () => void
  handleProgressPress?: () => void
  handleEssentialsPress?: () => void
  shouldShowFAQs?: () => boolean
  shouldShowReferral?: () => boolean
  shouldShowEssentials?: () => boolean
  shouldShowHome?: () => boolean
  shouldShowCare?: () => boolean
  shouldShowSessions?: () => boolean
  shouldShowProgress?: () => boolean
  currentPathname: string
  trackMenuClick?: () => void
  showAccountBadge?: boolean
  animateAccountBadge?: boolean
}

interface HeaderNavMenuItemProps extends MenuItemProps {
  condition?: () => boolean
  activePathname?: string
}

enum NAV_MENU_ITEM {
  BENEFITS = 'navMenuBenefitsButton',
  ACCOUNT = 'navMenuAccountButton',
  FAQS = 'navMenuFAQsButton',
  REFERRAL = 'navMenuReferralButton',
  LOG_OUT = 'navMenuLogOutButton',
  HOME = 'navMenuHomeButton',
  CARE = 'navMenuCareButton',
  SESSIONS = 'navMenuSessionsButton',
  PROGRESS = 'navMenuProgressButton',
  ESSENTIALS = 'navMenuEssentialsButton',
}

const NAV_MENU_ITEM_LABELS = defineMessages({
  [NAV_MENU_ITEM.BENEFITS]: {
    defaultMessage: 'My benefits',
    description: 'Link to benefits page',
  },
  [NAV_MENU_ITEM.ACCOUNT]: {
    defaultMessage: 'Account',
    description: 'Link to user profile settings',
  },
  [NAV_MENU_ITEM.FAQS]: {
    defaultMessage: 'FAQs',
    description: 'Link to frequently asked questions from clients, shown in microsite header',
  },
  [NAV_MENU_ITEM.REFERRAL]: {
    defaultMessage: 'Send a referral',
    description: 'Link to referral',
  },
  [NAV_MENU_ITEM.LOG_OUT]: {
    defaultMessage: 'Log out',
    description: 'Link text for a logged-in user to log out',
  },

  [NAV_MENU_ITEM.HOME]: {
    defaultMessage: 'Home',
    description: 'Link to the home page',
  },
  [NAV_MENU_ITEM.CARE]: {
    defaultMessage: 'Care',
    description: 'Link to the care page',
  },
  [NAV_MENU_ITEM.SESSIONS]: {
    defaultMessage: 'Sessions',
    description: "Link to the appointments dashboard page which shows the patient's scheduled sessions",
  },
  [NAV_MENU_ITEM.PROGRESS]: {
    defaultMessage: 'Progress',
    description: 'Link to view a user’s assessment progress',
  },
  [NAV_MENU_ITEM.ESSENTIALS]: {
    defaultMessage: 'Essentials',
    description: 'Link to Lyra Essentials',
  },
  library: {
    defaultMessage: 'Library',
    description: 'Link to Lyra Essentials',
  },
})

export const HeaderNavMenu: FunctionComponent<HeaderNavMenuProps> = ({
  handleBenefitsPress,
  handleAccountPress,
  handleFAQsPress = noop,
  handleReferralPress = noop,
  handleLogOutPress,
  handleHomePress = noop,
  handleCarePress = noop,
  handleSessionsPress = noop,
  handleProgressPress = noop,
  handleEssentialsPress = noop,
  shouldShowFAQs = () => true,
  shouldShowEssentials,
  shouldShowHome,
  shouldShowCare,
  shouldShowSessions,
  shouldShowProgress,
  currentPathname,
  trackMenuClick,
  showAccountBadge = false,
  animateAccountBadge = true,
}) => {
  const { formatMessage } = useIntl()
  const { colors, breakpoints } = useTheme()
  const [activeMenuItem, setActiveMenuItem] = useState<string | undefined>(undefined)
  const testId = 'HeaderNavMenu'
  const menuStyle = { marginTop: breakpoints.isMinWidthTablet ? 20 : 16 }
  const isLaptopWeb = !breakpoints.isMinWidthLaptop && IS_WEB
  const conditionForLaptopWeb = (condition?: () => boolean) => {
    return () => (condition && condition() && isLaptopWeb) ?? false
  }

  const menuItemsList: HeaderNavMenuItemProps[] = [
    {
      id: NAV_MENU_ITEM.HOME,
      text: formatMessage(NAV_MENU_ITEM_LABELS[NAV_MENU_ITEM.HOME]),
      onPressItem: handleHomePress,
      activePathname: '/search-care',
      condition: conditionForLaptopWeb(shouldShowHome),
    },
    {
      id: NAV_MENU_ITEM.CARE,
      text: formatMessage(NAV_MENU_ITEM_LABELS[NAV_MENU_ITEM.CARE]),
      onPressItem: handleCarePress,
      activePathname: '/assignments',
      condition: conditionForLaptopWeb(shouldShowCare),
    },
    {
      id: NAV_MENU_ITEM.SESSIONS,
      text: formatMessage(NAV_MENU_ITEM_LABELS[NAV_MENU_ITEM.SESSIONS]),
      onPressItem: handleSessionsPress,
      activePathname: '/sessions',
      condition: conditionForLaptopWeb(shouldShowSessions),
    },
    {
      id: NAV_MENU_ITEM.PROGRESS,
      text: formatMessage(NAV_MENU_ITEM_LABELS[NAV_MENU_ITEM.PROGRESS]),
      onPressItem: handleProgressPress,
      activePathname: '/progress',
      condition: conditionForLaptopWeb(shouldShowProgress),
    },
    {
      id: NAV_MENU_ITEM.ESSENTIALS,
      text: formatMessage(NAV_MENU_ITEM_LABELS.library),
      onPressItem: handleEssentialsPress,
      activePathname: '/essentials',
      condition: conditionForLaptopWeb(shouldShowEssentials),
    },

    {
      id: NAV_MENU_ITEM.BENEFITS,
      text: formatMessage(NAV_MENU_ITEM_LABELS[NAV_MENU_ITEM.BENEFITS]),
      onPressItem: handleBenefitsPress,
      activePathname: '/search-care/benefits',
      icon: <HeartDottedBorderIcon></HeartDottedBorderIcon>,
    },
    {
      id: NAV_MENU_ITEM.ACCOUNT,
      text: formatMessage(NAV_MENU_ITEM_LABELS[NAV_MENU_ITEM.ACCOUNT]),
      onPressItem: handleAccountPress,
      activePathname: '/profile',
      icon: <MenuIcon fillColor={colors.iconDefault}></MenuIcon>,
      showBadge: IS_WEB ? showAccountBadge : undefined,
    },
    {
      id: NAV_MENU_ITEM.FAQS,
      text: formatMessage(NAV_MENU_ITEM_LABELS[NAV_MENU_ITEM.FAQS]),
      condition: shouldShowFAQs,
      onPressItem: handleFAQsPress,
      activePathname: '/faq',
    },
    {
      id: NAV_MENU_ITEM.REFERRAL,
      text: formatMessage(NAV_MENU_ITEM_LABELS[NAV_MENU_ITEM.REFERRAL]),
      onPressItem: handleReferralPress,
    },
    {
      id: NAV_MENU_ITEM.LOG_OUT,
      text: formatMessage(NAV_MENU_ITEM_LABELS[NAV_MENU_ITEM.LOG_OUT]),
      onPressItem: handleLogOutPress,
    },
  ]
  menuItemsList.forEach((item) => (item.testId = `${testId}-${item.id}`))
  const menuItemsListFiltered = menuItemsList.filter((item) => {
    return (item.condition && item.condition()) ?? true
  })

  useEffect(() => {
    const foundActivePath = menuItemsListFiltered.filter(
      (item) => item.activePathname && currentPathname.includes(item.activePathname),
    )
    let activeItemText = ''
    if (foundActivePath.length > 0) {
      // Find longest match (eg being on /search-care/benefits should not match for the homepage)
      activeItemText = foundActivePath.reduce((max: HeaderNavMenuItemProps, item: HeaderNavMenuItemProps) => {
        return item.text.length > max.text.length ? item : max
      }, foundActivePath[0]).text
    }

    setActiveMenuItem(activeItemText)
  }, [currentPathname, menuItemsListFiltered])

  return (
    <Menu
      menuItems={menuItemsListFiltered}
      testID={testId}
      horizontalPosition='right'
      dropdownStyle={menuStyle}
      preselectedValue={activeMenuItem}
      showIcons={!IS_WEB}
      disableBottomSheetForWeb
      trackMenuClick={trackMenuClick}
      showMenuBadge={showAccountBadge}
      animateBadge={animateAccountBadge}
    />
  )
}

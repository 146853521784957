import { useSelector } from 'react-redux'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'

import {
  getUserDisplayFirstName,
  getUserDisplayLastName,
  getUserLegalFirstName,
  getUserLegalLastName,
} from '../../data/user/userSelectors'

/*
  Gets the user's first name and last name to be displayed
  Use legal first and last name if isPreferredNameEnabled flag is disabled
*/
export const useGetUserDisplayName = () => {
  const { isPreferredNameEnabled } = useFlags()

  const legalFirstName = useSelector(getUserLegalFirstName)
  const displayFirstName = useSelector(getUserDisplayFirstName)
  const legalLastName = useSelector(getUserLegalLastName)
  const displayLastName = useSelector(getUserDisplayLastName)
  const userDisplayFirstName = isPreferredNameEnabled ? displayFirstName || legalFirstName : legalFirstName
  const userDisplayLastName = isPreferredNameEnabled ? displayLastName || legalLastName : legalLastName

  return {
    firstName: userDisplayFirstName,
    lastName: userDisplayLastName,
  }
}

import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  generateLatestMatchesExperimentPayload,
  LatestMatchesArmMapping,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import {
  NavigateToLatestProviderMatchesProps,
  useNavigateToLatestProviderMatches,
} from './useNavigateToLatestProviderMatches'
import { postExperimentEntrypoint } from '../../data/appGlobals/appGlobalsActions'
import { getDeviceUUID } from '../../data/appGlobals/appGlobalsSelectors'
import { getId, getIsUserLoggedIn } from '../../data/user/userSelectors'
import { getLatestUserFavoriteProviders } from '../../features/onboard/data/onboardSelectors'

/**
 * Inits latest matches (`fetchLatestUserFavoriteProviders` and calls experiment endpoint with the viewed variant) and returns relevant props such as hasLatestMatches
 */
export const useInitLatestMatchesAndGetProps = ({
  navigateToLatestProviderMatchesProps,
  fetchLatestUserFavoriteProviders,
}: {
  navigateToLatestProviderMatchesProps: NavigateToLatestProviderMatchesProps
  fetchLatestUserFavoriteProviders: ({ latestSearchId }: { latestSearchId: string }) => any
}) => {
  const latestUserProviders = useSelector(getLatestUserFavoriteProviders)
  const { isLatestMatchesEnabled } = useFlags()
  const [fetchedUserFavoriteProviders, setFetchedUserFavoriteProviders] = useState(false)
  const deviceUUID = useSelector(getDeviceUUID)
  const userId = useSelector(getId)
  const isLoggedIn = useSelector(getIsUserLoggedIn)

  const navigateToLatestProviderMatches = useNavigateToLatestProviderMatches({
    ...navigateToLatestProviderMatchesProps,
  })
  const { inProgressSearch } = navigateToLatestProviderMatchesProps
  const latestSearchId = inProgressSearch?.id

  useFetcher(
    [[fetchLatestUserFavoriteProviders, { latestSearchId }, latestSearchId && isLoggedIn]],
    [latestSearchId, fetchLatestUserFavoriteProviders],
    {
      onFinally: () => {
        setFetchedUserFavoriteProviders(true)
      },
    },
  )

  useEffect(() => {
    if (isLoggedIn && fetchedUserFavoriteProviders) {
      const latestMatchesExperimentPayload = generateLatestMatchesExperimentPayload({
        arm: isLatestMatchesEnabled ? LatestMatchesArmMapping.CAN_SEE : LatestMatchesArmMapping.CANNOT_SEE,
        client_lyra_id: userId,
        device_uuid: deviceUUID,
      })
      postExperimentEntrypoint(latestMatchesExperimentPayload)
    }
  }, [isLatestMatchesEnabled, latestUserProviders, userId, fetchedUserFavoriteProviders, deviceUUID, isLoggedIn])

  return {
    onLatestMatches: navigateToLatestProviderMatches,
    hasLatestMatches: useMemo(
      () => latestUserProviders?.length > 0 && isLatestMatchesEnabled,
      [isLatestMatchesEnabled, latestUserProviders?.length],
    ),
  }
}

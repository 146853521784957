import { useMemo } from 'react'

import { format, parseISO } from 'date-fns'

import { ClinicalDataAccessCode, ClinicalDataSharingFormConfirmationPageProps } from '@lyrahealth-inc/shared-app-logic'

type Params = {
  accessCode?: ClinicalDataAccessCode
  hideHeader: boolean
  deleteAccessCode: () => void
  isProd: boolean
}

const emptyAccessCode = {
  access_code: '',
  client_dob: '',
  client_lyra_id: '',
  client_surname: '',
  create_datetime_utc: '',
  expire_datetime_utc: '',
  id: '',
  provider_surname: '',
}

export const useClinicalDataSharingConfirmationPage = ({
  accessCode = emptyAccessCode,
  hideHeader,
  deleteAccessCode,
  isProd,
}: Params): Omit<ClinicalDataSharingFormConfirmationPageProps, 'onPressClose'> => {
  return useMemo(() => {
    const { access_code, provider_surname, client_surname, client_dob: client_dob_iso } = accessCode
    return {
      accessCode: access_code,
      thirdPartyProviderSurname: provider_surname,
      userSurname: client_surname,
      userDateOfBirth: format(parseISO(client_dob_iso), 'MM/dd/yyyy'),
      hideHeader,
      isProd,
      onPressWithdrawConsent: deleteAccessCode,
    }
  }, [accessCode, hideHeader, deleteAccessCode, isProd])
}

import { BenefitGroup } from '../../../models/benefit/Benefit'

type Params = {
  benefitGroups: BenefitGroup[]
}

const DELIMITER = ','

export const serializeBenefitCategories = ({ benefitGroups }: Params) => {
  const benefitCategories = benefitGroups.map((bg) => bg.benefitCategory)
  const sortedBenefitCategories = benefitCategories.slice().sort()
  return sortedBenefitCategories.join(DELIMITER)
}

import { defineMessage, defineMessages } from 'react-intl'

import { CHANGE_EMAIL_ERROR_TYPE } from './constants'

export const userProfileMessages = defineMessages({
  dependentInfo: {
    defaultMessage: 'Add a dependent',
    description: 'Title for adding a family member account',
  },
  account: {
    defaultMessage: 'Account',
    description: 'Title for the user profile page',
  },
  personalInformation: {
    defaultMessage: 'Personal information',
    description: 'Title for the personal information section of the user profile page',
  },
  childInformation: {
    defaultMessage: "Child's profile information",
    description: 'Title for the personal information section of the user profile page',
  },
  updatePersonalInformation: {
    defaultMessage: 'Update personal information',
    description: 'Label of button to update personal information',
  },
  displayLanguage: {
    defaultMessage: 'Display language',
    description: 'Title for the display language section of the user profile page',
  },
  updateDisplayLanguage: {
    defaultMessage: 'Update display language',
    description: 'Label of button to update their preferred language',
  },
  security: {
    defaultMessage: 'Security',
    description:
      'Title for the security section of the user profile page which contains security related information such as email and password',
  },
  notifications: {
    defaultMessage: 'Notifications',
    description:
      'Title for the notifications section of the user profile page which contains settings for how user is notified',
  },
  settings: {
    defaultMessage: 'Settings',
    description: 'Title for the settings section of the user profile page',
  },
  updateNotifications: {
    defaultMessage: 'Update notification settings',
    description: 'Label of button to update notification settings',
  },
  location: {
    defaultMessage: 'Location',
    description: 'Title for the geo-location section of the user profile page',
  },
  updateLocation: {
    defaultMessage: 'Update location',
    description: 'Label of button to update user location',
  },
  healthPlanAndPayments: {
    defaultMessage: 'Health plan and payments',
    description: 'Title for the health plan and payments section of the user profile page',
  },
  payment: {
    defaultMessage: 'Payment information',
    description: 'Title for the payment information section of the user profile page',
  },
  eligibility: {
    defaultMessage: 'Eligibility',
    description: 'Title for the benefit eligibility section of the user profile page',
  },
  sessionHistory: {
    defaultMessage: 'Session history',
    description: 'Title for the session history section of the user profile page',
  },
  privacyAndLegal: {
    defaultMessage: 'Privacy and legal',
    description: 'Title for the privacy and legal section containing user consents and data',
  },
  settingsDescription: {
    defaultMessage: 'Change login, phone, language, or notification settings',
    description: 'Description text informing the user the settings a user can change in the settings subpage',
  },
  sessionHistoryDescription: {
    defaultMessage: 'Review your session activity',
    description: 'Description text informing the user this section has their session history information',
  },
  eligibilityDescription: {
    defaultMessage: 'Find detailed information about your benefit eligibility',
    description: 'Description text informing the user this section has their eligbility information',
  },
  healthPlanAndPaymentDescription: {
    defaultMessage: 'Add or edit information for session cost sharing',
    description: 'Description text informing the user this section has their health plan and payment information',
  },
  privacyAndLegalDescription: {
    defaultMessage: 'Review privacy, legal, and consent forms',
    description: 'Description text informing the user this section has their privacy and legal information',
  },
  feedback: {
    defaultMessage: 'Send feedback',
    description: 'Title for the send feedback section of the user profile page',
  },
  feedbackDescription: {
    defaultMessage: 'Tell us about your experience',
    description: 'Description text informing the user this section is for sending feedback',
  },
  faq: {
    defaultMessage: 'FAQs',
    description: 'Title for the common questions and answer section of the user profile page',
  },
  faqDescription: {
    defaultMessage: 'Commonly asked questions',
    description: 'Description text informing the user this section is for commonly asked questions with answers',
  },
  changeEmail: {
    defaultMessage: 'Change email',
    description: 'Title for the change email address page',
  },
  phoneNumber: {
    defaultMessage: 'Phone number',
    description: 'Title for the phone number page',
  },
  mfa: {
    defaultMessage: '2-factor authentication',
    description: 'Title to indicate two-factor authentication activation option is about to be selected',
  },
  mfaDescription: {
    defaultMessage:
      'For additional security, you can turn on 2-factor authentication. When you log into Lyra, a six digit code will be sent to you via email to enter to verify your identify.',
    description: 'Description for user to understand  what multifactor authentication does and how it will work.',
  },
  notificationsDescription: {
    defaultMessage:
      'Select how you would like Lyra to communicate with you. At least one selection must be made for each group to prevent potentially missing a session.',
    description: 'Description text informing the user this section is for updating their notification preferences',
  },
  displayLanguageDescription: {
    defaultMessage:
      'Set your preferred language for the Lyra website. To update the language on your Lyra mobile app, use your phone’s system settings.',
    description:
      'Description text informing the user to set their preferred language and that changing the language in their mobile device must be done in their device settings',
  },
  clinicalDataSharing: {
    defaultMessage: 'Share your assessment results',
    description: 'Title for the clinical data sharing tile',
  },
  clinicalDataSharingDescription: {
    defaultMessage: 'Share your results with a third-party provider',
    description: 'Description for the clinical data sharing tile',
  },
  adultChildEligibilityDescription: {
    defaultMessage:
      '{displayName} is now an adult and their account information is private. If they would like to continue care with Lyra, they should register at {host}. Contact a <cntLink>Care Navigator</cntLink> for support.',
    description: "Text to inform members about their company's session reset date.",
  },
  appleHealthTitle: {
    defaultMessage: 'Apple Health',
    description: 'Title for the Apple Health setting',
  },
  mindfulMinutes: {
    defaultMessage: 'Mindful Minutes',
    description: 'Subtitle for setting to enable Mindful Minutes in Apple Health.',
  },
  emptyPaymentHistoryEAP: {
    defaultMessage:
      'Sessions with Lyra are covered until you reach the covered session limit. All late-cancellation and no-show payments will also appear here.',
    description: 'Text to inform members about their session coverage/usage. Shown when there are no payment records.',
  },
  emptyPaymentHistoryEAPHPI: {
    defaultMessage:
      'Sessions with Lyra are covered until you reach the covered session limit. If you choose to continue care beyond your covered session limit, you can add a health plan where you may be responsible for cost-sharing. Sessions where you are responsible for cost-sharing (co-pays, co-insurances, or deductibles), as well as all late-cancellation and no-show payments, will appear here.',
    description: 'Text to inform members about their session coverage/usage. Shown when there are no payment records.',
  },
  emptyPaymentHistoryHPI: {
    defaultMessage:
      'Sessions where you are responsible for cost-sharing (co-pays, co-insurances, or deductibles) with your health plan will appear here. All late-cancellation and no-show payments will also appear here.',
    description: 'Text to inform members about their session coverage/usage. Shown when there are no payment records.',
  },
})

export const profileUpdateToastMessage = defineMessage({
  defaultMessage: 'Updated.',
  description: 'Toast text indicating the users selected profile setting has been successfully changed',
})

export const changeEmailMessages = defineMessages({
  SUCCESS: {
    defaultMessage: 'Your new email address {emailAddress} has been confirmed.',
    description: 'Banner text notifying user that their new email address has been confirmed',
  },
  SUCCESS_GENERIC: {
    defaultMessage: 'Your new email address has been confirmed.',
    description: 'Banner text notifying user that their new email address has been confirmed',
  },
  [CHANGE_EMAIL_ERROR_TYPE.INVALID]: {
    defaultMessage: 'Sorry, this link is no longer active. Please try updating your information again.',
    description: 'Description to notify users that url link to verify their account is not active',
  },
  [CHANGE_EMAIL_ERROR_TYPE.GENERIC]: {
    defaultMessage: 'Sorry, there was an error changing your email. Please try again.',
    description: 'Banner text notifying user that there was an error changing their email',
  },
})

export const downloadDataFileErrorMessage = defineMessage({
  defaultMessage:
    'There was a problem retrieving your download, please try again later. If the problem persists, please reach out to our <link>Care Navigator Team</link>.',
  description: 'Error text informing the user that something went wrong downloading their data',
})

export const userInfoFieldLabels = defineMessages({
  FIRST_NAME: {
    defaultMessage: '{showInternationalFormat, select, true {Your given name} other {Your first name}}',
    description: '"First name" or "Given name" based on the market',
  },
  LAST_NAME: {
    defaultMessage: '{showInternationalFormat, select, true {Your surname / family name} other {Your last name}}',
    description: '"Surname / family name" or "Last name"',
  },
  PREFERRED_FIRST_NAME: {
    defaultMessage:
      '{showInternationalFormat, select, true {Preferred given name (optional)} other {Preferred first name (optional)}}',
    description: '"Preferred first name" or "Preferred given name" based on the market',
  },
  PREFERRED_LAST_NAME: {
    defaultMessage:
      '{showInternationalFormat, select, true {Preferred surname (optional)} other {Preferred last name (optional)}}',
    description: '"Preferred surname" or "Preferred last name" based on the market',
  },
  DEPENDENT_FIRST_NAME: {
    defaultMessage: 'First name',
    description: 'First name label for dependent',
  },
  DEPENDENT_LAST_NAME: {
    defaultMessage: 'Last name',
    description: 'Last name label for dependent',
  },
  DOB: {
    defaultMessage: 'Date of Birth',
    description: 'Date of birth field label',
  },
})

export const lockedDOBDisclaimer = defineMessage({
  defaultMessage:
    'We use your name and date of birth to confirm your eligibility. If you’re also hoping to change your date of birth, please reach out to our <link>Care Navigator Team</link>.',
  description:
    'Text to inform user to contact support team if they need to change their date of birth when date of birth input field is locked and cannot be changed',
})

export const lockedChildDOBDisclaimer = defineMessage({
  defaultMessage:
    "If you’re also hoping to change your child's date of birth, please reach out to our <link>Care Navigator Team</link>.",
  description:
    "Text to inform user to contact support team if they need to change their child's date of birth when date of birth input field is locked and cannot be changed",
})

export const changeLocationErrorText = defineMessage({
  defaultMessage:
    'We were unable to save your location change. Please try again or reach out to our <link>Care Navigator Team</link>.',
  description: 'Error message shown when system fails to update a users country or timezone in profile settings',
})

export const countryChangeWarningText = defineMessage({
  defaultMessage:
    'Changing your country or territory may impact your care experience — for example, your current provider or your program eligibility. Please <link>contact us</link> with any questions.',
  description:
    'Warning message shown when user attempts to modify their country or territory. Modifying the country or territory can affect their existing experience',
})

export const changeMFAGenericError = defineMessage({
  defaultMessage:
    'Sorry, there was an error updating your 2-factor authentication settings. Please try again or reach out to our <link>Care Navigator Team</link>.',
  description: 'Banner text notifying user that there was an error changing their 2 factor authentication',
})

export const updateDisplayLanguageGenericError = defineMessage({
  defaultMessage:
    'Sorry, there was an error updating your display language settings. Please try again or reach out to our <link>Care Navigator Team</link>.',
  description: 'Banner text notifying user that there was an error changing their display language settings',
})

export const changeNotificationGenericError = defineMessage({
  defaultMessage:
    'Sorry, there was an error updating your notification settings. Please try again or reach out to our <link>Care Navigator Team</link>.',
  description: 'Banner text notifying user that there was an error changing their notification settings',
})

export const changePhoneNumberGenericError = defineMessage({
  defaultMessage:
    'Sorry, there was an error updating your phone number. Please try again or reach out to our <link>Care Navigator Team</link>.',
  description: 'Banner text notifying user that there was an error changing their phone number',
})

export const changeThemeLabel = defineMessages({
  system: {
    defaultMessage: 'System Selected',
    description: 'Display theme selection option for system default theme',
  },
  light: {
    defaultMessage: 'Light',
    description: 'Display theme selection option for light mode theme',
  },
  dark: {
    defaultMessage: 'Dark',
    description: 'Display theme selection option for dark mode theme',
  },
})

// TODO: CEA-2375 Confirm copy requirements for FamilyHub
export const createDependentAccountSuccess = defineMessage({
  defaultMessage: 'Account created',
  description: 'Toast text indicating that a user successfully created a dependent account',
})

export const createDependentAccountError = defineMessage({
  defaultMessage:
    'Sorry, there was an error creating an account. Please try again or reach out to our <link>Care Navigator Team</link>.',
  description: 'Banner text notifying user that there was an error creating a dependent account.',
})

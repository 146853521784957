import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { Flex1View } from '../../templates/content/CommonViews'

/**
 * Static content for displaying the Subjective Units of Distress Scale (SUDS)
 */

const Container = styled.View`
  margin-bottom: 24px;
`

const Table = styled(Flex1View)`
  align-items: center;
  justify-content: center;
`

const Row = styled(Flex1View)`
  align-self: stretch;
  flex-direction: row;
  padding: 8px;
`

const CellOne = styled.View`
  flex-basis: 70px;
`
const CellTwo = styled.View`
  flex-shrink: 1;
`

const content = [
  { suds: '0', description: 'No distress/Do not avoid' },
  { suds: '25', description: 'A little distress/Rarely avoid' },
  { suds: '50', description: 'Moderate distress/Sometimes avoid' },
  { suds: '75', description: 'Strong distress/Usually avoid' },
  { suds: '100', description: 'Extreme distress/Always avoid' },
]

export const SUDSTable: FunctionComponent = () => {
  const { colors } = useTheme()
  return (
    <Container>
      <Subhead
        text={
          <FormattedMessage
            defaultMessage='Use the following rating scale to rate your Subjective Units of Distress (SUDS):'
            description='Table header when displaying Subjective Units of Distress Scale (SUDS)'
          />
        }
        size={SubheadSize.SMALL}
      />
      <Table>
        <Row>
          <CellOne>
            <Subhead
              text={<FormattedMessage defaultMessage='SUDS' description='Column header for displaying SUDS info' />}
              size={SubheadSize.XSMALL}
            />
          </CellOne>
          <CellTwo>
            <Subhead
              text={
                <FormattedMessage
                  defaultMessage='Description'
                  description='Column header for Description of the SUDS measure'
                />
              }
              size={SubheadSize.XSMALL}
            />
          </CellTwo>
        </Row>
        {content.map((row, index) => (
          <Row key={row.suds} style={index % 2 ? null : { backgroundColor: colors.backgroundSecondary }}>
            <CellOne>
              <BodyText text={row.suds} size={BodyTextSize.DEFAULT} />
            </CellOne>
            <CellTwo>
              <BodyText text={row.description} size={BodyTextSize.DEFAULT} />
            </CellTwo>
          </Row>
        ))}
      </Table>
    </Container>
  )
}

export const TeensDistressTable: FunctionComponent = () => {
  const { colors } = useTheme()
  return (
    <Container>
      <Table>
        <Row>
          <CellOne>
            <Subhead
              text={
                <FormattedMessage
                  defaultMessage='Rating Scale'
                  description='Column header for displaying Teens Distress score info'
                />
              }
              size={SubheadSize.XSMALL}
            />
          </CellOne>
          <CellTwo>
            <Subhead
              text={
                <FormattedMessage
                  defaultMessage='Description'
                  description='Column header for Description of the Teen distress scale measure'
                />
              }
              size={SubheadSize.XSMALL}
            />
          </CellTwo>
        </Row>
        {content.map((row, index) => (
          <Row key={row.suds} style={index % 2 ? null : { backgroundColor: colors.backgroundSecondary }}>
            <CellOne>
              <BodyText text={row.suds} size={BodyTextSize.DEFAULT} />
            </CellOne>
            <CellTwo>
              <BodyText text={row.description} size={BodyTextSize.DEFAULT} />
            </CellTwo>
          </Row>
        ))}
      </Table>
    </Container>
  )
}

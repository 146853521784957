import { fromJS } from 'immutable'

import { DEFAULT_WORKLIFE_SERVICES, WORKLIFE_SERVICE_IDS } from '../../../features/workLife/constants/workLifeConstants'
import FinancialIllustration from '../../../images/microsite/illustration-financial.png'
import { BANNERS } from '../micrositeAndElsewhereTopBannerConstants'

export const google = () => {
  return fromJS({
    micrositeAndElsewhereTopBanner: {
      banners: [BANNERS.RACE],
    },
    workLifeServices: [
      Object.assign({}, DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES, { PATH: 'https://google.clcmembers.net' }),
      {
        identifiers: WORKLIFE_SERVICE_IDS.FINANCIAL_SERVICES,
        title: 'Ayco Financial Coaching',
        img: FinancialIllustration,
        paragraph: '',
        bullets: [
          'One-on-one phone-based financial coaching and digital tools via Ayco',
          'Support with Budgeting, Investment Guidance, Tax Planning, and more...',
        ],
        CTA: 'Access financial support',
        PATH: 'http://www.ayco.com/login/google',
      },
      Object.assign({}, DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT, { PATH: 'https://google.clcmembers.net' }),
      DEFAULT_WORKLIFE_SERVICES.DEPENDENT_CARE,
    ],
  })
}

import { Map } from 'immutable'
import { find, get } from 'lodash-es'

import { isCustomerProgramHPIExclusive } from '@lyrahealth-inc/shared-app-logic'

import { convertTriageTaxonomyOptionToProgram, convertTriageTreatmentOptionToProgram } from '../utils/onboardUtils'

/**
 * Standard object representation for the "health plan" of a user who is not on a Lyra-supported health plan.
 * The object represents health plans that are returned from the `health_plan` database table.
 * * `eligibility_name` is the string matching the name of the health plan as listed in our eligibility files.
 * * `enabled` represents whether the plan supports BHB treatment options.
 * We export this because this needs to be common across both the "not supported" health plan option (for BHB-
 * eligible users inputting their health plan) as well as users who do not get to select a health plan.
 */
export const NOT_ENROLLED_IN_HEALTH_INSURANCE_THROUGH_CUSTOMER = {
  eligibility_name: 'notSupported',
  enabled: false,
}

export const isAnyCustomerProgramHPIExclusive = (customerPrograms: $TSFixMe) => {
  return Object.keys(customerPrograms).some((programName) =>
    isCustomerProgramHPIExclusive(customerPrograms[programName]),
  )
}

// Checks if all the customer's programs are all HPI exclusive. Takes a vanilla JS object, NOT an immutable object
export const areAllCustomerProgramsHPIExclusive = (customerPrograms: $TSFixMe) => {
  return Object.keys(customerPrograms).every(
    (configuration) => !isCustomerProgramEAPSupported(customerPrograms[configuration]),
  )
}

// Same as `isAnyCustomerProgramHPIExclusive`, but uses an immutable object.
export const isAnyCustomerProgramBHBExclusive = (customerPrograms: $TSFixMe) => {
  return customerPrograms.some((configuration: $TSFixMe) => isCustomerProgramBHBExclusive(configuration))
}

export const areAllCustomerProgramsBHBExclusive = (customerPrograms: $TSFixMe) => {
  // Check if all programs are not EAP supported, which means at least some programs are BHB supported
  // Therefore, this customer is using a HPI-only configuration
  return customerPrograms.every((configuration: $TSFixMe) => !isImmutableCustomerProgramEAPSupported(configuration))
}

// Same as `isCustomerProgramHPIExclusive`, but uses an immutable object.
export const isCustomerProgramBHBExclusive = ($$programConfiguration = Map()) => {
  return (
    ($$programConfiguration.get('bhbSupported') || $$programConfiguration.get('specialtyBhbSupported')) &&
    !$$programConfiguration.get('eapSupported')
  )
}

// Same as `isCustomerProgramConfigurationEAPSupported`, but uses an immutable object.
export const isImmutableCustomerProgramEAPSupported = (programConfiguration = Map()) => {
  return programConfiguration.get('eapSupported')
}

export const areAllCustomerProgramsEAPExclusive = (customerPrograms: $TSFixMe) => {
  // Check if all programs are NOT bhbSupported and specialtyBhbSupported, which means at least some programs are EAP supported
  // Therefore, this customer is using a EAP-only configuration
  return customerPrograms.every(
    (configuration: $TSFixMe) => !configuration.get('bhbSupported') && !configuration.get('specialtyBhbSupported'),
  )
}

export const isCustomerProgramEAPSupported = (programConfiguration = {}) => {
  return (programConfiguration as $TSFixMe)?.eapSupported
}

export const isTreatmentOptionBHBExclusive = ({ treatmentOptionName, customerPrograms }: $TSFixMe) => {
  return isCustomerProgramHPIExclusive(
    find(
      customerPrograms,
      (programConfiguration, programName) =>
        programName === get(convertTriageTreatmentOptionToProgram({ treatmentOptionName }), 'customerPropertyId'),
    ),
  )
}

export const isProgramTaxonomyOptionBHBExclusive = ({ programTaxonomy, customerPrograms }: $TSFixMe) => {
  return isCustomerProgramHPIExclusive(
    find(customerPrograms, (programConfiguration, programName) => {
      return programName === get(convertTriageTaxonomyOptionToProgram({ programTaxonomy }), 'customerPropertyId')
    }),
  )
}

// Return names of customer programs that are supported for users covered under EAP
const getCustomerProgramsForEAPEligibleUsers = ({ $$customerPrograms }: $TSFixMe) => {
  return $$customerPrograms.entrySeq().reduce(
    // @ts-expect-error TS(7031): Binding element 'programName' implicitly has an 'a... Remove this comment to see the full error message
    (programOptions: $TSFixMe, [programName, $$programConfiguration]) => {
      return $$programConfiguration.get('eapSupported')
        ? {
            supportedPrograms: [...programOptions.supportedPrograms, programName],
            unsupportedPrograms: [...programOptions.unsupportedPrograms],
          }
        : {
            supportedPrograms: [...programOptions.supportedPrograms],
            unsupportedPrograms: [...programOptions.unsupportedPrograms, programName],
          }
    },
    { supportedPrograms: [], unsupportedPrograms: [] },
  )
}

// Return names of customer programs that are supported for users covered under BHB.
// This also includes customer programs that are supported for users covered under EAP, because EAP is extended to all users.
const getCustomerProgramsForBHBEligibleUsers = ({ $$customerPrograms }: $TSFixMe) => {
  const bhbEligiblePrograms = $$customerPrograms.entrySeq().reduce(
    // @ts-expect-error TS(7031): Binding element 'programName' implicitly has an 'a... Remove this comment to see the full error message
    (programOptions: $TSFixMe, [programName, $$programConfiguration]) => {
      return $$programConfiguration.get('bhbSupported') || $$programConfiguration.get('specialtyBhbSupported')
        ? {
            supportedPrograms: [...programOptions.supportedPrograms, programName],
            unsupportedPrograms: [...programOptions.unsupportedPrograms],
          }
        : {
            supportedPrograms: [...programOptions.supportedPrograms],
            unsupportedPrograms: [...programOptions.unsupportedPrograms, programName],
          }
    },
    { supportedPrograms: [], unsupportedPrograms: [] },
  )

  const eapEligiblePrograms = getCustomerProgramsForEAPEligibleUsers({ $$customerPrograms })
  return {
    // Supported programs for BHB-eligible users include both programs covered under BHB and under EAP.
    // Thus, the list of supported programs is the union of EAP and BHB programs.
    // The list of unsupported programs is the intersection of (EAP unsupported) and (BHB unsupported) programs.
    supportedPrograms: [
      ...new Set([...bhbEligiblePrograms.supportedPrograms, ...eapEligiblePrograms.supportedPrograms]),
    ],
    unsupportedPrograms: bhbEligiblePrograms.unsupportedPrograms.filter((bhbProgram: $TSFixMe) =>
      eapEligiblePrograms.unsupportedPrograms.includes(bhbProgram),
    ),
  }
}

// Given a list of customer programs and a Boolean representing BHB eligibility for a given user,
// return the compilation of supported and unsupported customer programs for that user.
export const getCustomerProgramsForUserByBHBEligibility = ({ $$customerPrograms, isBHBEligible }: $TSFixMe) => {
  return isBHBEligible
    ? getCustomerProgramsForBHBEligibleUsers({ $$customerPrograms })
    : getCustomerProgramsForEAPEligibleUsers({ $$customerPrograms })
}

export const isGivenCustomerProgramSupportedForUser = ({
  $$customerPrograms,
  isBHBEligible,
  customerProgramName,
}: $TSFixMe) => {
  const { supportedPrograms } = getCustomerProgramsForUserByBHBEligibility({ $$customerPrograms, isBHBEligible })
  return supportedPrograms.includes(customerProgramName)
}

/**
 * @deprecated We want to use/import HEALTH_PLAN_ELIGIBILITY_STATUSES from shared-app-logic directory
 * libs/shared-app-logic/src/features/healthPlan/constants.ts
 *
 * The reason for this is because we plan on using these statuses in the native mobile app
 * so we need to share this enum to our mobile components
 *
 * TODO: Replace use cases of this on lyrahealthwebapp with the shared-app version
 */
export const HEALTH_PLAN_ELIGIBILITY_STATUSES = {
  ELIGIBLE: 'eligible',
  PENDING: 'pending',
  INELIGIBLE: 'ineligible',
}

export enum HEALTH_PLAN_PAYMENT_STATUSES {
  SUCCESS = 'Success',
  FAIL = 'Fail',
}

export const HEALTH_PLAN_AND_PAYMENT_INFORMATION_OVERVIEW_COPY_TYPES = {
  HPI_REQUIRED_TO_PROCEED_WITH_SEARCH_OR_BOOKING: 'HPI_REQUIRED_TO_PROCEED_WITH_SEARCH_OR_BOOKING',
  HPI_ENTRY_OUTSIDE_OF_SEARCH_AND_BOOKING: 'HPI_ENTRY_OUTSIDE_OF_SEARCH_AND_BOOKING',
}

export const HEALTH_PLAN_INELIGIBLE_COPY_TYPES = {
  NO_EAP_ELIGIBILITY: 'NO_EAP_ELIGIBILITY',
  DEPLETED_EAP_ELIGIBILITY: 'DEPLETED_EAP_ELIGIBILITY',
}

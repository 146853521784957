import { defineMessage } from 'react-intl'

export enum NotificationMethod {
  EMAIL = 'email',
  TEXT = 'sms',
  PUSH = 'push',
}

export enum NotificationMethodLabel {
  EMAIL = 'Email',
  TEXT = 'Text',
  PUSH = 'Push (mobile app)',
}

export type NotificationMethodKey = keyof typeof NotificationMethod

export const NotificationMethodLabelsTranslated = {
  EMAIL: defineMessage({
    defaultMessage: 'Email',
    description: 'Notification by email',
  }),
  TEXT: defineMessage({
    defaultMessage: 'Text',
    description: 'Notification by text',
  }),
  PUSH: defineMessage({
    defaultMessage: 'Push (mobile app)',
    description: 'Push notification on the app',
  }),
}

export enum NotificationChannel {
  APPOINTMENTS = 'appointments',
  ASSIGNMENTS = 'assignments',
  MESSAGES = 'messages',
  DEFAULT = 'default',
  PROMOTIONS = 'promotions',
  CONNECT_WITH_CARE_NAVIGATOR = 'connectWithCareNavigator',
  NOTIFICATIONS = 'notifications',
  RESET_PASSWORD = 'resetPassword',
  LOGIN = 'login',
  REBOOK = 'rebook',
  ESSENTIALS = 'essentials',
  CHANGE_EMAIL = 'changeEmail',
  DIRECT_PATH_BOOKING = 'directPathBooking',
  UPDATE_USER_INFO = 'updateUserInfo',
}

export interface NotificationMethodValues {
  [NotificationMethod.EMAIL]: boolean
  [NotificationMethod.TEXT]: boolean
  [NotificationMethod.PUSH]: boolean
}

export interface NotificationMethods {
  [NotificationChannel.DEFAULT]: NotificationMethodValues
  [NotificationChannel.ASSIGNMENTS]: NotificationMethodValues
  [NotificationChannel.APPOINTMENTS]: NotificationMethodValues
  [NotificationChannel.MESSAGES]: NotificationMethodValues
  [NotificationChannel.PROMOTIONS]: Pick<NotificationMethodValues, NotificationMethod.EMAIL>
  [NotificationChannel.CONNECT_WITH_CARE_NAVIGATOR]: Pick<NotificationMethodValues, NotificationMethod.EMAIL>
  [NotificationChannel.NOTIFICATIONS]: Pick<NotificationMethodValues, NotificationMethod.EMAIL>
  [NotificationChannel.RESET_PASSWORD]: Pick<NotificationMethodValues, NotificationMethod.EMAIL>
  [NotificationChannel.LOGIN]: Pick<NotificationMethodValues, NotificationMethod.EMAIL>
  [NotificationChannel.REBOOK]: NotificationMethodValues
  [NotificationChannel.ESSENTIALS]: Pick<NotificationMethodValues, NotificationMethod.EMAIL>
  [NotificationChannel.DIRECT_PATH_BOOKING]: Pick<NotificationMethodValues, NotificationMethod.EMAIL>
}

export type OptionalNotificationMethods = Partial<NotificationMethods>

export interface NotificationPreferences {
  email_consent: boolean
  sms_number: string
  sms_consent: boolean
  email_address: string
  notification_methods: NotificationMethods
  push_verified: boolean
}

export enum CustomNotificationsDateSettings {
  DAY_BEFORE = 'day_before',
  DAY_OF = 'day_of',
  DAY_OF_THE_WEEK = 'day_of_the_week',
  UNSET = '',
}
export interface CustomNotificationsPreferences {
  date_setting: CustomNotificationsDateSettings
  time_setting: string // 24 hour time
  day_of_the_week?: number | string // 0 to 6 for monday to sunday that is set if date_setting == day_of_the_week
  client_timezone: string // America/Los_Angeles, etc.
  notification_message: string
}

export interface CustomNotificationsPayload {
  customNotifications: CustomNotificationsPreferences
  smsConsent: boolean
}

export interface UpdateNotificationPreferences {
  email_consent: boolean
  sms_number: string
  sms_consent: boolean
  email_address: string
  notification_methods: OptionalNotificationMethods
  push_verified: boolean
}

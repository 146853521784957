import React from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const GlobeIcon: React.FC<GlobeIconProps> = ({ fillColor, size = 24, strokeWidth = 0.5, color }) => {
  const { colors } = useTheme()

  return (
    <Svg stroke={fillColor || colors.iconActive} width={size} height={size} viewBox='0 0 24 24' color={color}>
      <Path
        d='M12,3a9,9,0,1,0,9,9A9,9,0,0,0,12,3Zm7.46,8.25H15.37a14.41,14.41,0,0,0-.81-4.15c-.28-.78-.6-1.59-1-2.42A7.52,7.52,0,0,1,19.45,11.25Zm-7.46,8c-.25-.56-.71-1.63-1.15-2.89a13.06,13.06,0,0,1-.72-3.65h3.75a13.08,13.08,0,0,1-.73,3.65C12.82,17.31,12.43,18.28,12,19.29Zm-1.87-8a13.06,13.06,0,0,1,.72-3.65c.44-1.24.9-2.32,1.15-2.88.44,1,.83,2,1.16,2.88a13.43,13.43,0,0,1,.72,3.65Zm.25-6.57c-.26.62-.61,1.47-.94,2.42a14.41,14.41,0,0,0-.81,4.15H4.52A7.53,7.53,0,0,1,10.37,4.68ZM4.52,12.75h4.1a14.74,14.74,0,0,0,.8,4.15c.34,1,.69,1.81.95,2.42A7.53,7.53,0,0,1,4.52,12.75Zm9.08,6.57c.36-.83.69-1.65,1-2.42a14.75,14.75,0,0,0,.81-4.15h4.08A7.52,7.52,0,0,1,13.6,19.32Z'
        fill={fillColor || colors.iconActive}
        strokeWidth={strokeWidth}
      />
    </Svg>
  )
}

type GlobeIconProps = {
  size?: number | string
  fillColor?: string
  strokeWidth?: number | string
  color?: string
}

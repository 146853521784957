// @intl project:microsite

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { DefaultButton, LostIllustrationUrl, RootLayout } from '@lyrahealth-inc/ui-core'
import { FullScreenOverlay } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './errorFallback.module.scss'
import MicrositeIntlProvider from '../../../../features/microsite/MicrositeIntlProvider'
import { careTeamEmailLink } from '../../../constants/appConstants'
import Header from '../../header/Header'

export default function ErrorFallback() {
  return (
    <MicrositeIntlProvider>
      <RootLayout>
        <FullScreenOverlay isOpen>
          <Header />
          <div className={styles.container}>
            <div className={styles.info}>
              <section>
                <h1>
                  <FormattedMessage defaultMessage='Oops!' description='Title text for error page when error occurs' />
                </h1>
                <p>
                  <FormattedMessage
                    defaultMessage='Something went wrong. Try refreshing the page. If there are still errors, contact {email}.'
                    description='Text in error page, informing user to refresh page or contact support'
                    values={{
                      email: careTeamEmailLink,
                    }}
                  />
                </p>
              </section>
              <section>
                <DefaultButton onClick={() => (window.location.href = '/')} customClass={styles.button}>
                  <FormattedMessage defaultMessage='Go home' description='Label of button to go to home page' />
                </DefaultButton>
              </section>
            </div>
            <section className={styles.graphic}>
              <img src={LostIllustrationUrl} alt='Three people studying map together' />
            </section>
          </div>
        </FullScreenOverlay>
      </RootLayout>
    </MicrositeIntlProvider>
  )
}

import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { ASSETS_CDN_URL, TREATMENT_REC_PRIMARY_CARD_CDN_PREFIX as namespace } from '../../constants'
import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageSizer = styled(Image)<{ height: string; width: string }>(({ height, width }) => ({
  width,
  height,
}))

const Container = styled.View({
  overflow: 'hidden',
})

const IMAGE_SIZES = {
  DESKTOP: {
    height: '356px',
    width: '416px',
  },
  TABLET: {
    height: '276px',
    width: '354px',
  },
  MOBILE: {
    height: '190px',
    width: '343px',
  },
}

/**
 * Illustration to show on the treatment card for Coaching
 */
export const CoachingRec: FunctionComponent<CoachingRecProps> = ({ viewport = 'DESKTOP' }) => {
  const uri = ASSETS_CDN_URL + namespace + 'coaching' + viewport + '.png'
  return (
    <Container>
      <ImageSizer {...IMAGE_SIZES[viewport]} testID={`${tID('CoachingRecIllustration')}`} source={{ uri }} />
    </Container>
  )
}

export type CoachingRecProps = {
  viewport?: 'DESKTOP' | 'TABLET' | 'MOBILE'
}

import { fromJS } from 'immutable'

import { DEFAULT_WORKLIFE_SERVICES } from '../../../features/workLife/constants/workLifeConstants'
import AycoImage from '../../../images/microsite/EY.png'
import BrightHorizonsImage from '../../../images/microsite/GapBrightHorizons.png'
import NationwidePetImage from '../../../images/microsite/NationwidePet.png'
import TuitionDiscountImage from '../../../images/microsite/TuitionDiscount.png'
import WageWorksImage from '../../../images/microsite/WageWorksCommuter.png'

export const gap = () => {
  return fromJS({
    workLifeServices: [
      DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.FINANCIAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT,
      DEFAULT_WORKLIFE_SERVICES.DEPENDENT_CARE,
      {
        identifiers: {
          id: 'brightHorizons',
          dataTestId: 'workLife-selectBrightHorizons',
        },
        title: 'Bright Horizons Family Support',
        img: BrightHorizonsImage,
        paragraph: '',
        bullets: ['Back Up Childcare', 'Adult and Elder Care', 'Tutoring and Nanny Placement Service Discounts'],
        CTA: 'Register Your Account',
        PATH: 'https://clients.brighthorizons.com/gapinc',
      },
      {
        identifiers: {
          id: 'nationwidePet',
          dataTestId: 'workLife-selectNationwidePet',
        },
        title: 'Nationwide Pet Insurance',
        img: NationwidePetImage,
        paragraph: '',
        bullets: [
          '50% - 70% reimbursement based on level of coverage',
          'Choose any vet (no network required) and submit claims for reimbursement',
          '24/7 Vet helpline',
        ],
        CTA: 'Get a Quote',
        PATH: 'http://benefits.petinsurance.com/gap-inc',
      },
      {
        identifiers: {
          id: 'ayco',
          dataTestId: 'workLife-selectAyco',
        },
        title: 'Ayco Digital Financial Platform',
        img: AycoImage,
        paragraph: '',
        bullets: [
          'Take control of your personal finances',
          'Plan for your goals, including retirement',
          'Create an investment strategy',
          'Plan for your taxes',
        ],
        CTA: 'Register or Login Now',
        PATH: 'http://www.ayco.com/login/gap',
      },
      {
        identifiers: {
          id: 'tuition',
          dataTestId: 'workLife-selectTuition',
        },
        title: 'Tuition Discounts',
        img: TuitionDiscountImage,
        paragraph: '',
        bullets: ['Tuition Reductions', 'Reduced or Waived Payments', 'Discounts for Immediate Family Members'],
        CTA: 'Access Now',
        PATH: 'https://clients.brighthorizons.com/gapinc/matrix',
      },
      {
        identifiers: {
          id: 'wageWorks',
          dataTestId: 'workLife-selectWageWorks',
        },
        title: 'WageWorks Commuter Benefits',
        img: WageWorksImage,
        paragraph: 'Save money on eligible transportation costs through automatic paycheck deductions before taxes.',
        bullets: [],
        CTA: 'Register Your Account',
        PATH: 'https://healthequity.com/',
      },
    ],
  })
}

import { GetCustomerCopyFunc, WORKLIFE_SERVICE_IDS } from '@lyrahealth-inc/shared-app-logic'

import FinancialIllustration from '../assets/worklife/illustration-financial.png'
import { DEFAULT_WORKLIFE_SERVICES } from '../constants'

export const skadden: GetCustomerCopyFunc = () => {
  return {
    workLifeServices: [
      DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES,
      {
        identifiers: WORKLIFE_SERVICE_IDS.FINANCIAL_SERVICES,
        title: 'MSA Financial Coaching',
        img: FinancialIllustration,
        paragraph: '',
        bullets: [
          'One-on-one phone-based financial coaching and digital tools via MSA',
          'Support with Budgeting, Investment Guidance, Tax Planning, and more...',
        ],
        CTA: 'Access financial support ',
        PATH: 'http://msamembers.net/launch/aa4601e0-990e-11ed-9f42-c8c4f5ad2957',
      },
      DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT,
      DEFAULT_WORKLIFE_SERVICES.DEPENDENT_CARE,
    ],
  }
}

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  CostMessagingCoverageSubCategoryType,
  CostMessagingCoverageType,
  CostMessagingModalContent,
  CoverageType,
} from '@lyrahealth-inc/shared-app-logic'

export const eapCoachingOrTherapyTitleForYourself = (
  <FormattedMessage
    defaultMessage='Coaching or therapy for yourself'
    description='Cost messaging modal EAP title for yourself text'
  />
)

export const eapCoachingOrTherapyTitleForFamily = (
  <FormattedMessage
    defaultMessage='Coaching or therapy for your family'
    description='Cost messaging modal EAP title for family text'
  />
)

const eapSessionForYourself = (numberOfSessions: string, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='You get {numberOfSessions} total sessions, fully covered by {customerDisplayName}.'
      description='Cost messaging modal session description text'
      values={{ numberOfSessions, customerDisplayName }}
    />
  )
}

const eapSessionForFamily = (numberOfSessions: string, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='You get {numberOfSessions} total sessions per dependent (child, spouse, or domestic partner), fully covered by {customerDisplayName}.'
      description='Cost messaging modal session description for family text'
      values={{ numberOfSessions, customerDisplayName }}
    />
  )
}

const eapMedicalSessionForYourself = (numberOfSessions: string, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='You get {numberOfSessions} total covered medication consultations, fully covered by {customerDisplayName}.'
      description='Cost messaging modal description medical sessions text'
      values={{ numberOfSessions, customerDisplayName }}
    />
  )
}

const eapMedicalSessionForFamily = (numberOfSessions: string, customerDisplayName: string) => {
  return (
    <FormattedMessage
      defaultMessage='You get {numberOfSessions} total covered medication consultations per you and each dependent (child, spouse, or domestic partner), fully covered by {customerDisplayName}.'
      description='Cost messaging modal description medical sessions for family text'
      values={{ numberOfSessions, customerDisplayName }}
    />
  )
}

export const eapAndHpiMedicationDescription = (
  <FormattedMessage
    defaultMessage='Depending on your health plan, cost-sharing for medical consultations may be available. We’ll need to verify your plan to estimate your cost.'
    description='Cost messaging modal description for EAP with HPI medication text'
  />
)

const eapCoachingOrTherapyTitleWithoutDependent = (
  <FormattedMessage
    defaultMessage='Coaching or therapy'
    description='Cost messaging modal EAP coaching or therapy title text'
  />
)

const hpiTherapyTitleForYourself = (
  <FormattedMessage
    defaultMessage='Therapy for yourself'
    description='Cost messaging modal HPI title for yourself text'
  />
)

const hpiTherapyDescriptionForYourself = (
  <FormattedMessage
    defaultMessage='Depending on your health plan, cost-sharing for mental health sessions may be available to you.'
    description='Cost messaging modal HPI description for yourself text'
  />
)

const hpiTherapyTitleForFamily = (
  <FormattedMessage
    defaultMessage='Therapy for your family'
    description='Cost messaging modal HPI therapy title for family text'
  />
)

const hpiTherapyDescriptionForFamily = (
  <FormattedMessage
    defaultMessage='Cost-sharing for mental health sessions may be available to your dependents (child, spouse, or domestic partner).'
    description='Cost messaging modal HPI therapy description for family text'
  />
)

const hpiTherapyTitleWithoutDependent = (
  <FormattedMessage
    defaultMessage='Therapy'
    description='Cost messaging modal HPI therapy without dependent title text'
  />
)

export const eapMedicalConsultationTitle = (
  <FormattedMessage
    defaultMessage='Medication consultation'
    description='Cost messaging EAP modal medication consultation title text'
  />
)

const eapAndHpiSessionTitle = (
  <FormattedMessage
    defaultMessage='Session coverage through your health plan'
    description='Cost messaging modal EAP and HPI session title text'
  />
)

const eapAndHpiSessionDescription = (
  <FormattedMessage
    defaultMessage='Depending on your health plan, cost-sharing for sessions may also be available. We’ll need to verify your plan to estimate your cost.'
    description='Cost messaging modal EAP and HPI session description text'
  />
)

const eapCoachingAndHpiTherapyCoachingTitle = (
  <FormattedMessage
    defaultMessage='Coaching'
    description='Cost messaging modal EAP coaching and HPI therapy coaching title text'
  />
)

const eapCoachingAndHpiTherapyTherapyTitle = (
  <FormattedMessage
    defaultMessage='Therapy'
    description='Cost messaging modal EAP coaching and HPI therapy therapy title text'
  />
)

const eapCoachingAndHpiTherapyCoachingTitleWithDependent = (
  <FormattedMessage
    defaultMessage='Coaching for yourself'
    description='Cost messaging modal EAP coaching and HPI therapy coaching title text'
  />
)

const eapCoachingAndHpiTherapyCoachingForFamilyTitleWithDependent = (
  <FormattedMessage
    defaultMessage='Coaching for your family'
    description='Cost messaging modal EAP coaching and HPI therapy family title text'
  />
)

const eapCoachingAndHpiTherapyTherapyTitleWithDependent = (
  <FormattedMessage
    defaultMessage='Therapy for your and your family'
    description='Cost messaging modal EAP coaching and HPI therapy therapy title text'
  />
)

const eapCoachingAndHpiTherapyTherapyDescriptionBaseText = (
  <FormattedMessage
    defaultMessage='Depending on your health plan, cost-sharing for therapy sessions may be available. We’ll need to verify your plan to estimate your cost.'
    description='Cost messaging modal EAP coaching and HPI therapy therapy descripton family description text'
  />
)

const eapOnlyBaseTexts = (numberOfSessions: string, customerDisplayName: string) => {
  return [
    {
      title: eapCoachingOrTherapyTitleWithoutDependent,
      description: eapSessionForYourself(numberOfSessions, customerDisplayName),
    },
  ]
}

const eapWithDependentBaseTexts = (numberOfSessions: string, customerDisplayName: string) => {
  return [
    {
      title: eapCoachingOrTherapyTitleForYourself,
      description: eapSessionForYourself(numberOfSessions, customerDisplayName),
    },
    {
      title: eapCoachingOrTherapyTitleForFamily,
      description: eapSessionForFamily(numberOfSessions, customerDisplayName),
    },
  ]
}

const eapAndHpiSessionBaseTexts = [
  {
    title: eapAndHpiSessionTitle,
    description: eapAndHpiSessionDescription,
  },
]

const eapAndHpiMedBaseTexts = [
  {
    title: eapMedicalConsultationTitle,
    description: eapAndHpiMedicationDescription,
  },
]

const hpiOnlyBaseTexts = [
  {
    title: hpiTherapyTitleWithoutDependent,
    description: hpiTherapyDescriptionForYourself,
  },
]

const hpiWithDependentBaseTexts = [
  {
    title: hpiTherapyTitleForYourself,
    description: hpiTherapyDescriptionForYourself,
  },
  {
    title: hpiTherapyTitleForFamily,
    description: hpiTherapyDescriptionForFamily,
  },
]

const hpiWithMedsDescription = (
  <FormattedMessage
    defaultMessage='Cost-sharing for medical consultations may be available to you.'
    description='Cost messaging modal EAP and HPI description text'
  />
)

const hpiWithMedsBaseDescription = [
  {
    title: eapMedicalConsultationTitle,
    description: hpiWithMedsDescription,
  },
]

export const getDefaultCostMessagingModalText = (
  numberOfSessions: string,
  customerDisplayName: string,
): {
  [key in CostMessagingCoverageType]: CostMessagingModalContent[]
} => {
  return {
    [CoverageType.HPI_ONLY_CHILDREN_DISABLED]: [],
    [CoverageType.EAP_ONLY_DEPENDENT_DISABLED]: [],
    [CostMessagingCoverageSubCategoryType.EAP_ONLY_WITH_DEPENDENT]: [
      ...eapWithDependentBaseTexts(numberOfSessions, customerDisplayName),
    ],
    [CoverageType.EAP_ONLY]: [...eapOnlyBaseTexts(numberOfSessions, customerDisplayName)],
    [CostMessagingCoverageSubCategoryType.EAP_ONLY_WITH_EAP_MEDS_WITH_DEPENDENT]: [
      ...eapWithDependentBaseTexts(numberOfSessions, customerDisplayName),
      {
        title: eapMedicalConsultationTitle,
        description: eapMedicalSessionForFamily(numberOfSessions, customerDisplayName),
      },
    ],
    [CostMessagingCoverageSubCategoryType.EAP_ONLY_WITH_EAP_MEDS]: [
      ...eapOnlyBaseTexts(numberOfSessions, customerDisplayName),
      {
        title: eapMedicalConsultationTitle,
        description: eapMedicalSessionForYourself(numberOfSessions, customerDisplayName),
      },
    ],
    [CostMessagingCoverageSubCategoryType.EAP_AND_HPI_WITH_DEPENDENT]: [
      ...eapWithDependentBaseTexts(numberOfSessions, customerDisplayName),
      ...eapAndHpiSessionBaseTexts,
    ],
    [CoverageType.EAP_AND_HPI]: [
      ...eapOnlyBaseTexts(numberOfSessions, customerDisplayName),
      ...eapAndHpiSessionBaseTexts,
    ],
    [CostMessagingCoverageSubCategoryType.EAP_WITH_HPI_MEDS_WITH_DEPENDENT]: [
      ...eapWithDependentBaseTexts(numberOfSessions, customerDisplayName),
      ...eapAndHpiMedBaseTexts,
    ],
    [CostMessagingCoverageSubCategoryType.EAP_WITH_HPI_MEDS]: [
      ...eapOnlyBaseTexts(numberOfSessions, customerDisplayName),
      ...eapAndHpiMedBaseTexts,
    ],
    [CostMessagingCoverageSubCategoryType.EAP_COACHING_WITH_HPI_THERAPY]: [
      {
        title: eapCoachingAndHpiTherapyCoachingTitle,
        description: eapSessionForYourself(numberOfSessions, customerDisplayName),
      },
      {
        title: eapCoachingAndHpiTherapyTherapyTitle,
        description: eapCoachingAndHpiTherapyTherapyDescriptionBaseText,
      },
    ],
    [CostMessagingCoverageSubCategoryType.EAP_COACHING_WITH_HPI_THERAPY_WITH_DEPENDENT]: [
      {
        title: eapCoachingAndHpiTherapyCoachingTitleWithDependent,
        description: eapSessionForYourself(numberOfSessions, customerDisplayName),
      },
      {
        title: eapCoachingAndHpiTherapyCoachingForFamilyTitleWithDependent,
        description: eapSessionForFamily(numberOfSessions, customerDisplayName),
      },
      {
        title: eapCoachingAndHpiTherapyTherapyTitleWithDependent,
        description: eapCoachingAndHpiTherapyTherapyDescriptionBaseText,
      },
    ],
    [CostMessagingCoverageSubCategoryType.HPI_ONLY_WITH_DEPENDENT]: [...hpiWithDependentBaseTexts],
    [CoverageType.HPI_ONLY]: [...hpiOnlyBaseTexts],
    [CostMessagingCoverageSubCategoryType.HPI_ONLY_WITH_HPI_MEDS_WITH_DEPENDENT]: [
      ...hpiWithDependentBaseTexts,
      ...hpiWithMedsBaseDescription,
    ],
    [CostMessagingCoverageSubCategoryType.HPI_ONLY_WITH_HPI_MEDS]: [...hpiOnlyBaseTexts, ...hpiWithMedsBaseDescription],
  }
}

import React from 'react'

import classNames from 'classnames'
import BootstrapAlert from 'react-bootstrap/lib/Alert'

import styles from './alert.module.scss'
import colors from '../../../styles/_0colors.scss'
import XIcon from '../../atoms/icons/XIcon'

interface AlertProps {
  customClass?: object
  dismissHandler?: () => void
  show: boolean
  children: React.ReactNode
  includeX?: boolean
  style?: string
  autoDismissTimer?: number
}

class Alert extends React.Component<AlertProps, {}> {
  static defaultProps = {
    showAlert: false,
    includeX: true,
    style: 'info',
  }

  constructor(props: AlertProps) {
    super(props)
    this.state = {
      dismiss: false,
      maxHeight: 'none',
    }
  }

  componentDidMount() {
    if (this.props.autoDismissTimer) {
      this.setState({ maxHeight: (this.refs.lyraalert as $TSFixMe).clientHeight })
      setTimeout(this.autoDismiss, this.props.autoDismissTimer)
    }
  }

  autoDismiss = () => {
    this.setState({ dismiss: true })
    setTimeout(() => {
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.dismissHandler()
      this.setState({ dismiss: false })
    }, 1100)
  }

  render() {
    if (!this.props.show) return null

    const { style } = this.props
    const xIconFill = style === 'warning' ? colors.ui_oatmeal_5 : colors.x_white

    return (
      <BootstrapAlert
        bsStyle={this.props.style}
        className={classNames(
          { [styles['auto-dismiss']]: (this.state as $TSFixMe).dismiss },
          styles.alert,
          this.props?.customClass,
        )}
        data-testid={`alert-${this.props.style}`}
      >
        <div className={styles.inner} ref='lyraalert' style={{ maxHeight: (this.state as $TSFixMe).maxHeight }}>
          {this.props.children}
          {this.props.includeX && (
            <button
              aria-label='Dismiss Alert'
              className={styles['dismiss-alert']}
              onClick={this.props.dismissHandler}
              data-test-id='dismiss-alert'
            >
              <XIcon fillColor={xIconFill} />
            </button>
          )}
        </div>
      </BootstrapAlert>
    )
  }
}

export default Alert

import React, { FunctionComponent } from 'react'
import Svg, { Defs, G, LinearGradient, Path, Stop } from 'react-native-svg'

import { tID } from '../../utils/utils'

export const SparkleGradient: FunctionComponent = () => {
  return (
    <Svg width='22' height='22' viewBox='0 0 22 22' fill='none' testID={tID('SparkleGradient')}>
      <G id='Group 1073712622'>
        <Path
          id='Vector'
          d='M11.0897 18.9098C10.6797 18.9098 10.3397 18.5698 10.3397 18.1598C10.3397 14.6798 7.39969 11.7498 3.92969 11.7498C3.51969 11.7498 3.17969 11.4098 3.17969 10.9998C3.17969 10.5898 3.51969 10.2498 3.92969 10.2498C7.40969 10.2498 10.3397 7.30984 10.3397 3.83984C10.3397 3.42984 10.6797 3.08984 11.0897 3.08984C11.4997 3.08984 11.8397 3.42984 11.8397 3.83984C11.8397 7.31984 14.7797 10.2498 18.2497 10.2498C18.6597 10.2498 18.9997 10.5898 18.9997 10.9998C18.9997 11.4098 18.6597 11.7498 18.2497 11.7498C14.7697 11.7498 11.8397 14.6898 11.8397 18.1598C11.8397 18.5698 11.4997 18.9098 11.0897 18.9098Z'
          fill='url(#paint0_linear_416_8437)'
        />
      </G>
      <Defs>
        <LinearGradient
          id='paint0_linear_416_8437'
          x1='17.9149'
          y1='-19.2443'
          x2='-6.15644'
          y2='-14.3938'
          gradientUnits='userSpaceOnUse'
        >
          <Stop offset='0.16' stopColor='#43A484' />
          <Stop offset='0.545' stopColor='#06A0BA' />
          <Stop offset='0.88' stopColor='#5F76C4' />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}

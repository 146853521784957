import {
  MovingBooksLottie,
  ProviderLedIllustration,
  ProviderWavingLottie,
  SelfCareIllustration,
  WellnessCheckinLottie,
  WellnessCheckInOverviewIllustration,
} from '../atoms'

export enum PATHWAY_ILLUSTRATIONS {
  PROVIDER_LED_ILLUSTRATION = 'ProviderLedIllustration',
  WELLNESS_CHECKIN_ILLUSTRATION = 'WellnessCheckinIllustration',
  SELF_CARE_ILLUSTRATION = 'SelfCareIllustration',
}

export const getPathwayTypes = ({ isMobileView }: { isMobileView: boolean }) => {
  return {
    ProviderLedIllustration: {
      Component: isMobileView ? ProviderLedIllustration : ProviderWavingLottie,
      testID: 'providerLed',
    },
    WellnessCheckinIllustration: {
      Component: isMobileView ? WellnessCheckInOverviewIllustration : WellnessCheckinLottie,
      testID: 'wellnessCheckin',
    },
    SelfCareIllustration: {
      Component: isMobileView ? SelfCareIllustration : MovingBooksLottie,
      testID: 'selfCare',
    },
  }
}

import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { FAQIllustrationIcon } from '../../atoms/illustrations/FAQIllustrationIcon'
import { CareOptionCard } from '../../templates/careOptionCard/CareOptionCard'

export interface CareNavigatorFAQOptionCardProps {
  onPress?: () => void
}

export const CareNavigatorFAQOptionCard: FunctionComponent<CareNavigatorFAQOptionCardProps> = ({ onPress }) => {
  const optionHeader = (
    <FormattedMessage
      defaultMessage='Check out our FAQs'
      description='Header text that indicates clicking this option will lead the users to frequently asked questions.'
    />
  )

  const optionDescription = (
    <FormattedMessage
      defaultMessage='Quick answers to your questions.'
      description='Text description explaining that the frequently asked questions will have answers to the users questions.'
    />
  )

  return (
    <CareOptionCard
      onPress={onPress}
      optionIcon={<FAQIllustrationIcon />}
      optionHeader={optionHeader}
      optionDescription={optionDescription}
      testId={'CareNavigatorFAQOptionCard'}
    />
  )
}

import React from 'react'
import Dotdotdot from 'react-dotdotdot'
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Measure from 'react-measure'
import styles from './truncate.module.scss'
import ChevronIcon from '../../atoms/icons/ChevronIcon'
import { get } from 'lodash-es'

type OwnProps = {
  content: string | $TSFixMe
  lines?: number
  moreText?: string
  lessText?: string
  onToggle?: $TSFixMeFunction
  className?: string
  noToggle?: boolean
}

type State = $TSFixMe

type Props = OwnProps & typeof Truncate.defaultProps

class Truncate extends React.Component<Props, State> {
  static defaultProps = {
    lines: 3,
    moreText: 'Show more',
    lessText: 'Show less',
    onToggle: () => {},
    noToggle: false,
  }

  state = {
    isExpanded: false,
    showViewAction: !this.props.noToggle,
  }

  _setExpandedState = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    })
  }

  _showViewActionText = ({ width }: $TSFixMe) => {
    const { content, lines } = this.props
    let shouldShow
    if (get(content, 'props.children.length')) {
      shouldShow = content.props.children.length > lines
    } else {
      const avgTextPer100px = 15
      const textScale = Math.round((width / 100) * avgTextPer100px * lines)
      shouldShow = content.length > textScale
    }

    this.setState({
      showViewAction: shouldShow,
    })
  }

  _renderMore() {
    const { lessText, content, noToggle } = this.props
    const { showViewAction } = this.state
    const viewAction =
      showViewAction && !noToggle ? (
        <span className={styles['truncate-view-action']} onClick={this._setExpandedState}>
          {lessText}
          <ChevronIcon isFilled width={13} direction='up' fillColor='currentColor' />
        </span>
      ) : (
        ''
      )
    return (
      <div>
        <div>{content}</div>
        {viewAction}
      </div>
    )
  }

  _renderLess() {
    const { lines, content, moreText, noToggle } = this.props
    const { showViewAction } = this.state
    const viewAction =
      showViewAction && !noToggle ? (
        <span
          className={styles['truncate-view-action']}
          onClick={() => {
            this._setExpandedState()
            this.props.onToggle()
          }}
        >
          {moreText}
          <ChevronIcon isFilled width={13} direction='down' fillColor='currentColor' />
        </span>
      ) : (
        ''
      )
    return (
      <div>
        <Dotdotdot clamp={lines}>
          <div>{content}</div>
        </Dotdotdot>
        {viewAction}
      </div>
    )
  }

  render() {
    const content = !this.state.isExpanded ? this._renderLess() : this._renderMore()
    return (
      <Measure onMeasure={this._showViewActionText}>
        <div className={this.props.className}>{content}</div>
      </Measure>
    )
  }
}

export default Truncate

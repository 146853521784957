import React, { FunctionComponent, ReactNode, useState } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { ChevronV2Icon } from '../../atoms/icons/ChevronV2Icon'
import { Image } from '../../atoms/image/Image'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

export type ActionCardProps = {
  id?: string
  title?: string | ReactNode
  onPress?: () => void
  pressedOpacity?: number
  ImageSource?: ImageSourcePropType
  iconSize?: number
  withChervon?: boolean
  chevronSize?: number
  disabled?: boolean
  disabledBackgroundColor?: string
}

type ActionCardContainerProps = {
  $hoveredIn: boolean
  disabled: boolean
}

const ActionCardContent = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  padding: spacing['16px'],
  flex: '1',
  flexDirection: 'row',
  alignItems: 'center',
}))

const CardImage = styled(Image)<{ size: number }>(({ size }) => ({
  width: size,
  height: size,
}))

const CardImageContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginRight: spacing['16px'],
}))

const CardDescription = styled.View({ flex: '1', justifyContent: 'center' })

const ChevronContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  minWidth: '24px',
  alignItems: 'center',
  marginLeft: spacing['16px'],
  justifyContent: 'center',
}))

const ActionCardContainer = styled(PressableOpacity)<ActionCardContainerProps>(
  ({
    $hoveredIn,
    disabled,
    theme: {
      breakpoints: { isMinWidthMobileS },
      colors,
      spacing,
    },
  }) => {
    const hasShadows: boolean = $hoveredIn
    const rest = {
      minHeight: isMinWidthMobileS ? '94px' : '76px',
      borderRadius: isMinWidthMobileS ? '16px' : '12px',
      border: disabled ? '' : `1px solid ${colors.borderDefault}`,
      backgroundColor: colors.backgroundPrimary,
      flexDirection: 'row' as const,
      marginTop: spacing['16px'],
    }

    const shadows = {
      shadowColor: colors.borderHover,
      shadowOffset: {
        width: '0px',
        height: '2px',
      },
      shadowOpacity: hasShadows ? '0.25' : '0',
      shadowRadius: '10px',
    }
    return { ...rest, ...shadows }
  },
)

export const ActionCard: FunctionComponent<ActionCardProps> = ({
  id,
  title,
  onPress,
  pressedOpacity = 1,
  ImageSource,
  iconSize = 48,
  withChervon = true,
  chevronSize = 12,
  disabled,
  disabledBackgroundColor,
}): JSX.Element => {
  const [hoveredIn, setHoveredIn] = useState(false)
  const { colors } = useTheme()

  return (
    <ActionCardContainer
      onPress={onPress}
      $hoveredIn={hoveredIn}
      pressedOpacity={pressedOpacity}
      pressedBorderColor={colors.primaryButtonBackgroundDefault}
      testID={tID(id)}
      getHoverVisible={(hoverVisible: boolean) => setHoveredIn(hoverVisible)}
      disabled={!!disabled}
      disabledBackgroundColor={disabledBackgroundColor}
    >
      <ActionCardContent>
        {!!ImageSource && (
          <CardImageContainer>
            <CardImage source={ImageSource} size={iconSize} />
          </CardImageContainer>
        )}
        <CardDescription>
          <Subhead size={SubheadSize.SMALL} text={title} color={colors.textPrimary} />
        </CardDescription>
        {withChervon && (
          <ChevronContainer>
            <ChevronV2Icon fillColor={colors.textSecondary} size={chevronSize} />
          </ChevronContainer>
        )}
      </ActionCardContent>
    </ActionCardContainer>
  )
}

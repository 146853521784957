import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export const StarRatingIcon = ({ height = 24, width = 24, fillColor, ...rest }: StarRatingIconProps & SvgProps) => {
  const { colors } = useTheme()
  const fill = fillColor || colors.icons.starRatingIcon.fill
  return (
    <Svg width={width} height={height} viewBox='0 0 24 24' fill='none' testID={tID('StarRatingIcon')} {...rest}>
      <Path
        d='M12 15.8504L15.28 18.237C15.8 18.6237 16.5067 18.1037 16.3067 17.4904L15.0533 13.637L18.2933 11.3304C18.8267 10.957 18.56 10.117 17.9067 10.117H13.9333L12.64 6.09037C12.44 5.47704 11.5733 5.47704 11.3733 6.09037L10.0667 10.117H6.09334C5.44 10.117 5.17334 10.957 5.70667 11.3304L8.94667 13.637L7.69334 17.4904C7.49334 18.1037 8.2 18.6104 8.72 18.237L12 15.8504Z'
        fill={fill}
      />
    </Svg>
  )
}

export type StarRatingIconProps = {
  height?: number
  width?: number
  fillColor?: string
}

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Home Icon representing activities or main page at the bottom navigation
 */
export const HomeIcon: FunctionComponent<HomeIconProps> = ({ size = 25, fillColor, isFilled = false }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 20' fill='none'>
      <Path
        d={
          isFilled
            ? 'M11.4704 0.920051C11.3372 0.81292 11.1714 0.754517 11.0004 0.754517C10.8295 0.754517 10.6636 0.81292 10.5304 0.920051L0.530419 8.92005C0.453596 8.98177 0.389682 9.05802 0.342326 9.14444C0.29497 9.23087 0.2651 9.32577 0.254421 9.42373C0.232855 9.62159 0.290767 9.8199 0.415419 9.97505C0.540071 10.1302 0.721251 10.2295 0.919102 10.251C1.11695 10.2726 1.31527 10.2147 1.47042 10.0901L2.25042 9.46005V18.4601C2.25042 18.659 2.32944 18.8497 2.47009 18.9904C2.61074 19.131 2.80151 19.2101 3.00042 19.2101H19.0004C19.1993 19.2101 19.3901 19.131 19.5308 18.9904C19.6714 18.8497 19.7504 18.659 19.7504 18.4601V9.46005L20.5304 10.0901C20.6648 10.1945 20.8303 10.2508 21.0004 10.2501C21.1136 10.2516 21.2257 10.2272 21.328 10.1787C21.4303 10.1301 21.5201 10.0587 21.5904 9.97005C21.7106 9.81366 21.7652 9.6167 21.7428 9.42078C21.7204 9.22485 21.6227 9.0453 21.4704 8.92005L11.4704 0.920051ZM8.25042 17.7501V13.0001C8.25042 12.4033 8.48747 11.831 8.90943 11.4091C9.33139 10.9871 9.90368 10.7501 10.5004 10.7501H11.5004C12.0972 10.7501 12.6695 10.9871 13.0914 11.4091C13.5134 11.831 13.7504 12.4033 13.7504 13.0001V17.7501H8.25042Z'
            : 'M21.4704 8.90995L11.4704 0.909946C11.335 0.807736 11.17 0.752441 11.0004 0.752441C10.8308 0.752441 10.6658 0.807736 10.5304 0.909946L0.530417 8.90995C0.373939 9.0346 0.273388 9.2163 0.250884 9.41509C0.22838 9.61388 0.285765 9.81347 0.410417 9.96995C0.535069 10.1264 0.716776 10.227 0.915564 10.2495C1.11435 10.272 1.31394 10.2146 1.47042 10.0899L2.25042 9.45995V18.4599C2.25301 18.6581 2.33286 18.8473 2.47295 18.9874C2.61305 19.1275 2.80231 19.2074 3.00042 19.2099H19.0004C19.1985 19.2074 19.3878 19.1275 19.5279 18.9874C19.668 18.8473 19.7478 18.6581 19.7504 18.4599V9.45995L20.5304 10.0899C20.664 10.1958 20.83 10.2523 21.0004 10.2499C21.157 10.2514 21.3102 10.2038 21.4383 10.1138C21.5665 10.0238 21.6633 9.896 21.7152 9.74821C21.767 9.60043 21.7713 9.44012 21.7274 9.28979C21.6835 9.13945 21.5936 9.00663 21.4704 8.90995ZM18.2504 17.7499H13.7504V12.9999C13.7504 12.4032 13.5134 11.8309 13.0914 11.409C12.6695 10.987 12.0972 10.7499 11.5004 10.7499H10.5004C9.90368 10.7499 9.33138 10.987 8.90943 11.409C8.48747 11.8309 8.25042 12.4032 8.25042 12.9999V17.7499H3.75042V8.25995L11.0004 2.45995L18.2504 8.25995V17.7499Z'
        }
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

type HomeIconProps = {
  size?: string | number
  fillColor?: string
  isFilled?: boolean
}

import { createSelector } from 'reselect'

const getBannerState = (state: $TSFixMe) => state.get('banner')

export const getBannerIncludeX = createSelector(getBannerState, (bannerState) => bannerState?.get('includeX', false))

export const getBannerIsOpen = createSelector(getBannerState, (bannerState) => bannerState?.get('isOpen', false))

export const getBannerMessage = createSelector(getBannerState, (bannerState) => bannerState?.get('message'))

export const getBannerType = createSelector(getBannerState, (bannerState) => bannerState?.get('bannerType', ''))

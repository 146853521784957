import React, { FunctionComponent, useEffect } from 'react'

import { useAnimateLottie } from '../../hooks'

export interface ProviderWavingLottieProps {
  play?: boolean
  size?: number
  duration?: number // in milliseconds
  bgColor?: 'oatmeal' | 'white'
}

export const ProviderWavingLottie: FunctionComponent<ProviderWavingLottieProps> = ({
  play = false,
  size = 158,
  duration = 900,
  bgColor = 'oatmeal',
}) => {
  const { progress, playLottie, reverseLottie, AnimationView } = useAnimateLottie(duration)

  useEffect(() => {
    if (play) {
      playLottie()
    } else {
      reverseLottie()
    }
  }, [duration, play, playLottie, reverseLottie])

  return (
    <AnimationView
      source={
        bgColor === 'white'
          ? require('../../assets/lottie/provider_led_white_bg.json')
          : require('../../assets/lottie/provider_led.json')
      }
      progress={progress.current}
      loop={false}
      autoPlay={false}
      style={{ height: size, width: size }}
    />
  )
}

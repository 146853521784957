import React from 'react'
import Svg, { Path, Rect, SvgProps } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export const SubtitleSettingIcon = ({
  height = 20,
  width = 24,
  fillColor,
  ...rest
}: SubtitleSettingIconProps & SvgProps) => {
  const { colors } = useTheme()
  return (
    <Svg width={width} height={height} viewBox='0 0 22 18' fill='none' testID={tID('Subtitle-settingIcon')} {...rest}>
      <Path
        d='M17.6004 9H4.40039'
        stroke={fillColor || colors.iconInverse}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <Path
        d='M12.1004 13.1254H4.40039'
        stroke={fillColor || colors.iconInverse}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <Rect
        x='0.75'
        y='0.75'
        width='20.5'
        height='16.5'
        rx='1.25'
        stroke={fillColor || colors.iconInverse}
        stroke-width='1.5'
      />
    </Svg>
  )
}

export type SubtitleSettingIconProps = {
  height?: number
  width?: number
  fillColor?: string
}

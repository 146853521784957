import { axiosInstance } from '../axiosInstance'

export function getJwt() {
  return axiosInstance.get(`v2/okta/jwt`)
}

export const getJwtToken = () => {
  return getJwt().then((response) => {
    return response.data
  })
}

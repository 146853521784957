import { combineReducers } from 'redux-immutablejs'

import { default as data } from './onboardDataReducer'
import { default as appointmentBooking } from '../appointmentBooking/data/appointmentBookingReducer'
import { default as carePreferences } from '../carePreferences/data/carePreferencesReducer'
import { default as individualTherapy } from '../individualTherapy/data/individualTherapyReducer'
import { default as location } from '../locationMatcher/data/locationMatcherReducer'
import { default as modalityQuestionnaire } from '../modalityQuestionnaire/data/modalityQuestionnaireReducer'
import { default as providerProfile } from '../providerProfile/data/providerProfileReducer'
import { default as providers } from '../providerRecommendations/data/providerRecommendationsReducers'
import treatmentOptions from '../treatmentOptions/data/treatmentOptionsReducer'
import { default as triageHealthPlan } from '../triageHealthPlan/data/triageHealthPlanReducer'

export default combineReducers({
  data,
  individualTherapy,
  location,
  providerProfile,
  providers,
  appointmentBooking,
  modalityQuestionnaire,
  triageHealthPlan,
  treatmentOptions,
  carePreferences,
})

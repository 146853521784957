import { useEffect, useState } from 'react'

import { isNumber } from 'lodash-es'

import CountdownWorker from '../workers/countdownWorker'

/**
 * @param {number} duration Number of seconds that the timer starts counting down from
 * @param {boolean} isWeb Determines whether this hook is used from a web app. If true, a web worker will be used to prevent browsers from
 *                        throttling callback functions passed to setInterval (see https://medium.com/@adithyaviswam/overcoming-browser-throttling-of-setinterval-executions-45387853a826)
 */
export const useCountdownTimer = (
  duration: number,
  isWeb: boolean,
): [countdown: number, resetCountdown: (newDuration: number) => void] => {
  const [countdown, setCountdown] = useState(duration)

  useEffect(() => {
    setCountdown(duration)
  }, [duration])

  useEffect(() => {
    if (countdown === 0) {
      return
    }

    if (isWeb && typeof Worker !== 'undefined') {
      const countdownWorker = new Worker(CountdownWorker())
      countdownWorker.postMessage(Math.max(countdown, 0))
      countdownWorker.onmessage = (e) => {
        if (isNumber(e?.data)) {
          setCountdown(Math.max(e?.data, 0))
        }
      }

      return () => {
        countdownWorker?.terminate()
      }
    } else {
      const interval = window.setInterval(() => {
        setCountdown(Math.max(countdown - 1, 0))
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [countdown, isWeb])

  const resetCountdown = (newDuration: number) => {
    setCountdown(newDuration)
  }

  return [countdown, resetCountdown]
}

import React, { FunctionComponent } from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { noop } from 'lodash-es'
import styled from 'styled-components/native'

import {
  ALREADY_HAVE_AN_ACCOUNT_MSG,
  CustomerName,
  genericErrorMessage,
  getLegalNoticeLink,
  useLyraIntl,
  validateEmailForm,
} from '@lyrahealth-inc/shared-app-logic'

import { FormContainer, SsoButton, TOOLTIP_TRIGGER_ICON } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { InputFieldRFF } from '../../atoms/formElements/InputField'
import { RegistrationEnhancementIllustration } from '../../atoms/illustrations/RegistrationEnhancementIllustration'
import { Link } from '../../atoms/link/Link'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { SSO_BUTTON_MESSAGE_TYPE, SSO_BUTTON_TYPE } from '../../atoms/ssoButton/SsoButton'
import { Subhead } from '../../atoms/subhead/Subhead'
import { IS_WEB } from '../../constants'
import { InlineErrorBanner, InputAccessory } from '../../molecules'
import { NavBar } from '../../molecules/navBar/NavBar'
import { SubheadSize } from '../../styles/typeStyles'
import { Flex1View, StickyView } from '../../templates'
import { openUrl, ThemeType, tID } from '../../utils'

export interface EmailCaptureFormValues {
  emailAddress: string
}

const Flex1Container = styled(Flex1View)<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  paddingBottom: theme.spacing['16px'],
  ...(theme.breakpoints.isMinWidthTablet && {
    borderRadius: 16,
    padding: theme.spacing['48px'],
    margin: '0 auto 48px auto',
    width: 446,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.colors.borderDefault}`,
  }),
}))

const EmailFormContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.breakpoints.isMinWidthTablet
    ? 0
    : `${theme.spacing['0px']} ${theme.spacing['16px']} ${theme.spacing['16px']}`,
}))

const HeaderContainer = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['32px'],
  marginBottom: theme.spacing['24px'],
}))

const DividerContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginVertical: theme.spacing['24px'],
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const DividerText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginHorizontal: theme.spacing['24px'],
  color: theme.colors.textSecondary,
}))

const SplitDivider = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flex: 1,
  height: 1,
  backgroundColor: theme.colors.dividerSecondary,
}))

const IllustrationContainer = styled.View({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const FooterTextContainer = styled.View({
  width: '100%',
  alignItems: 'center',
})

const ButtonGroupContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing['8px'],
}))

const ButtonContainer = styled.View<{ theme: ThemeType; addMarginBottom?: boolean }>(({ theme, addMarginBottom }) => ({
  width: '100%',
  ...(addMarginBottom && {
    marginBottom: theme.spacing['16px'],
  }),
}))

const AlreadyHaveAccountText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  color: theme.colors.textSecondary,
}))

const PrivacyPolicyText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  color: theme.colors.textSecondary,
  marginTop: theme.spacing['8px'],
}))

const RecaptchaContainer = styled.View<{ theme: ThemeType; lessMargin: boolean }>(({ theme, lessMargin }) => ({
  marginTop: lessMargin ? theme.spacing['24px'] : theme.spacing['72px'],
}))

export interface RegistrationEmailCaptureFormProps {
  onSignUpButtonPress: (values: EmailCaptureFormValues) => void
  onSignInLinkPress: () => void
  onBackButtonPress?: () => void
  appleSSOAvailable?: boolean
  googleSSOAvailable?: boolean
  onAppleSignUpButtonPress?: () => void
  onGoogleSignUpButtonPress?: () => void
  isGenerateVerificationTokenLoading?: boolean
  customAppleSSOContent?: React.ReactNode
  customGoogleSSOContent?: React.ReactNode
  reCaptchaContent?: React.ReactNode
  isEmailError?: boolean
  isModalView?: boolean
  customerName?: string
}

export const RegistrationEmailCaptureForm: FunctionComponent<RegistrationEmailCaptureFormProps> = ({
  appleSSOAvailable,
  googleSSOAvailable,
  onAppleSignUpButtonPress = noop,
  onGoogleSignUpButtonPress = noop,
  onBackButtonPress,
  onSignInLinkPress,
  onSignUpButtonPress,
  isGenerateVerificationTokenLoading,
  customAppleSSOContent,
  customGoogleSSOContent,
  reCaptchaContent,
  isEmailError,
  isModalView = false,
  customerName,
}) => {
  const name = 'RegistrationEmailCaptureForm'
  const isSsoAvailable = appleSSOAvailable || googleSSOAvailable || customAppleSSOContent || customGoogleSSOContent
  const { formatMessage } = useIntl()
  const { activeLanguage } = useLyraIntl()

  const subLabelText =
    customerName === CustomerName.AMAZON_KIDS
      ? formatMessage({
          defaultMessage: `If you already have a Lyra account, you'll need to use a different email address to create a new one. If you need help setting up an account, please reach out to a Lyra Care Navigator at (877) 505-7147 or care@lyrahealth.com.`,
          description:
            'Sublabel informing amazonkids user to use a different email address if they already have a lyra account.',
        })
      : formatMessage({
          defaultMessage: 'This gives you confidential access to Lyra',
          description: 'Sublabel informing user to use their access to Lyra will be confidential',
        })

  const body = (
    <>
      <IllustrationContainer>
        <RegistrationEnhancementIllustration />
      </IllustrationContainer>
      <EmailFormContainer testID={tID(`${name}-formContainer`)}>
        <Form
          onSubmit={onSignUpButtonPress}
          initialValues={{ emailAddress: '' }}
          validate={(formValues) => validateEmailForm(formValues, formatMessage)}
          render={({ handleSubmit, errors, touched }) => (
            <FormContainer handleSubmit={handleSubmit}>
              <HeaderContainer
                size={SubheadSize.LARGE}
                text={
                  <FormattedMessage
                    defaultMessage="Let's get started"
                    description='A title indicating that a user can set up an account with this form'
                  />
                }
              />

              {isEmailError && <InlineErrorBanner text={formatMessage(genericErrorMessage)} />}
              <Field
                testID={`${name}-emailAddressField`}
                name='emailAddress'
                label={formatMessage({
                  defaultMessage: 'Personal email',
                  description: 'Label of field for the user to enter their personal email address',
                })}
                sublabel={subLabelText}
                component={InputFieldRFF}
                toolTipContent={formatMessage({
                  defaultMessage: 'We recommend using a personal email address',
                  description:
                    'Tooltip text recommending that a personal email address be used to register for an account',
                })}
                toolTipTriggerConfig={{ icon: TOOLTIP_TRIGGER_ICON.INFO }}
                toolTipContentStyle={{ container: { maxWidth: 243 } }}
                baseInputStyle={{ marginBottom: 16 }}
                inputContainerStyle={{ borderRadius: 8 }}
                inputAccessoryViewID={name}
                returnKeyType='done'
                error={touched && errors?.emailAddress}
              />
              <ButtonGroupContainer>
                <PrimaryButton
                  loading={!!isGenerateVerificationTokenLoading}
                  fullWidth
                  testID={tID(`${name}-defaultSignupButton`)}
                  text={formatMessage({
                    defaultMessage: 'Sign up',
                    description: 'Button that allows user to sign up with their email',
                  })}
                  onPress={handleSubmit}
                />
                {isSsoAvailable && (
                  <DividerContainer>
                    <SplitDivider />
                    <DividerText
                      text={<FormattedMessage defaultMessage='or' description='The word "or"' />}
                      size={BodyTextSize.SMALL}
                    />
                    <SplitDivider />
                  </DividerContainer>
                )}
                {googleSSOAvailable && (
                  <ButtonContainer addMarginBottom={appleSSOAvailable}>
                    <SsoButton
                      testId={tID('Registration-GoogleButton')}
                      type={SSO_BUTTON_TYPE.GOOGLE}
                      messageType={SSO_BUTTON_MESSAGE_TYPE.CONTINUE}
                      onPress={onGoogleSignUpButtonPress}
                    />
                  </ButtonContainer>
                )}
                {customGoogleSSOContent}
                {appleSSOAvailable && (
                  <ButtonContainer>
                    <SsoButton
                      testId={tID('Registration-AppleButton')}
                      type={SSO_BUTTON_TYPE.APPLE}
                      messageType={SSO_BUTTON_MESSAGE_TYPE.SIGN_UP}
                      onPress={onAppleSignUpButtonPress}
                    />
                  </ButtonContainer>
                )}
                {customAppleSSOContent}
              </ButtonGroupContainer>
            </FormContainer>
          )}
        />
      </EmailFormContainer>
      <FooterTextContainer>
        <AlreadyHaveAccountText
          text={
            <FormattedMessage
              {...ALREADY_HAVE_AN_ACCOUNT_MSG}
              values={{
                link: (text: string) => (
                  <Link testID={tID(`${name}-signInLink`)} onPress={onSignInLinkPress} text={text} underline />
                ),
              }}
            />
          }
        />
      </FooterTextContainer>
    </>
  )

  return (
    <>
      {isModalView ? (
        <>
          {body}
          <FooterTextContainer>
            {IS_WEB && reCaptchaContent}
            <PrivacyPolicyText
              text={
                <FormattedMessage
                  defaultMessage='You can view Lyra’s <link>privacy policy</link>'
                  description='Message on registration page prompting users to sign in with an existing account'
                  values={{
                    link: (text: string) => (
                      <Link
                        testID={tID(`${name}-privacyLink`)}
                        onPress={() => openUrl(getLegalNoticeLink(activeLanguage, 'PRIVACY_POLICY'))}
                        text={text}
                        underline
                        size={BodyTextSize.SMALL}
                      />
                    ),
                  }}
                />
              }
              size={BodyTextSize.SMALL}
            />
          </FooterTextContainer>
        </>
      ) : (
        <>
          <Flex1Container testID={tID(name)}>
            <StickyView
              headerComponent={!!onBackButtonPress && <NavBar showBackButton onBack={onBackButtonPress} />}
              safeAreaInsets={{ bottom: 0 }}
            >
              {body}
              {!IS_WEB && <RecaptchaContainer lessMargin={!!appleSSOAvailable}>{reCaptchaContent}</RecaptchaContainer>}
            </StickyView>
            <InputAccessory nativeID={name} />
          </Flex1Container>
          {IS_WEB && reCaptchaContent}
        </>
      )}
    </>
  )
}

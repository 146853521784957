import React, { FunctionComponent, useEffect } from 'react'

import { useAnimateLottie } from '../../hooks'

export interface MovingBooksLottieProps {
  play?: boolean
  size?: number
  // in milliseconds
  duration?: number
}

export const WellnessCheckinLottie: FunctionComponent<MovingBooksLottieProps> = ({
  play = false,
  size = 158,
  duration = 700,
}) => {
  const { progress, playLottie, reverseLottie, AnimationView } = useAnimateLottie(duration)

  useEffect(() => {
    if (play) {
      playLottie()
    } else {
      reverseLottie()
    }
  }, [play, playLottie, reverseLottie])

  return (
    <AnimationView
      source={require('../../assets/lottie/wellness_checkin.json')}
      progress={progress.current}
      style={{ height: size, width: size }}
    />
  )
}

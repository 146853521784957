import { fromJS, Map } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  CLEAR_PROVIDER_PROFILE_DATA,
  GET_PROVIDER_COST_DATA,
  SET_PROVIDER_AVAILABILITY_DATA_CS,
  SET_PROVIDER_PROFILE_DATA,
} from './providerProfileActions'

const initialState = fromJS({})

export default createReducer(initialState, {
  [SET_PROVIDER_PROFILE_DATA]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('data', Map.isMap(action.payload) ? action.payload : fromJS(action.payload))
    return $$state
  },
  [CLEAR_PROVIDER_PROFILE_DATA]: () => {
    return initialState
  },
  [GET_PROVIDER_COST_DATA]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.setIn(['data', 'costInfo'], fromJS(action.payload))
    return $$state
  },
  [SET_PROVIDER_AVAILABILITY_DATA_CS]: ($$state: Map<string, any>, action: any) => {
    $$state = $$state.setIn(['data', 'availabilityCS'], fromJS(action.payload))
    return $$state
  },
})

import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { SET_CUSTOMER_PROPERTIES } from './customerPropertiesActions'

const initialState = fromJS({
  email: '',
  enabledChat: 'false',
  hasLogo: '',
  mystrength_accesscode: '',
  phone: '',
  videoTherapy: '',
  worklife: '',
  isLoaded: false,
})

export default createReducer(initialState, {
  [SET_CUSTOMER_PROPERTIES]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const customerProperties = fromJS(action.val)

    if (!customerProperties.getIn(['customerPropertiesMap', 'phone'])) {
      console.warn('Warning: this customer’s phone number is undefined.')
    }
    if (
      customerProperties.getIn(['customerPropertiesMap', 'worklife']) &&
      !customerProperties.getIn(['customerPropertiesMap', 'worklifephone'])
    ) {
      console.warn('Warning: this customer’s work-life phone number is undefined.')
    }

    $$state = $$state.merge(customerProperties)
    return $$state
  },
})

import { IntlShape } from 'react-intl'

import {
  appleCustomerCodeFieldLabel,
  ELIGIBILITY_TYPES,
  EligibilityField,
  EligibilityInfo,
  FieldSchema,
  FormMetadata,
  ThemeColors,
  USER_MAX_AGE_REQUIREMENT,
  USER_MIN_AGE_REQUIREMENT,
} from '@lyrahealth-inc/shared-app-logic'
import { formatMessageHelper } from '@lyrahealth-inc/shared-app-logic/src/features/intl'

import {
  getDobUiSchema,
  getEmployeeInformationFormMetadata,
  getFullNameUiSchema,
} from '../../../formMetadata/eligibilityMetadata'
import { commonInputFieldStyling } from '../../../organisms/formBody/sharedMetadata'
import { buildNameFields } from '../../../organisms/setUpAccountForm/basicInfoMetadata'

const showInternationalFormat = false

export const generateAppleDependentEligibilityMetadata = (
  intl: IntlShape,
  eligibilityInfo: EligibilityInfo,
  colors: ThemeColors,
  isMobileSized = false,
): FormMetadata => {
  const { showRegistrationTips = false, eligibilityDependentTitle, hasBlockingEligibilityCheck } = eligibilityInfo
  const showEligibilityErrorAsWarning = !hasBlockingEligibilityCheck

  const nameFields = buildNameFields(
    intl,
    showInternationalFormat,
    {
      firstName: { name: EligibilityField.EMPLOYEE_FIRST_NAME, isRequired: true },
      lastName: { name: EligibilityField.EMPLOYEE_LAST_NAME, isRequired: true },
    },
    showEligibilityErrorAsWarning,
  )

  const dependentRegistrationTipsSchema: FieldSchema = {
    type: 'string',
    show: showRegistrationTips,
    name: 'dependentRegistrationTips',
  }

  const informationSubHeaderSchema: FieldSchema = {
    type: 'string',
    name: 'informationSubHeader',
  }
  const informationSubHeaderUiSchema = { 'ui:field': 'EligibilityInformation' }

  const employeeDobSchema: FieldSchema = {
    name: EligibilityField.EMPLOYEE_DATE_OF_BIRTH,
    title: intl.formatMessage({
      defaultMessage: 'Date of birth',
      description: `Label text for date of birth form input`,
    }),
    type: 'string',
    placeholder: '',
    validation: {
      isRequired: true,
      isValidAge: {
        min: USER_MIN_AGE_REQUIREMENT.DOMESTIC_EMPLOYEE,
        max: USER_MAX_AGE_REQUIREMENT.ADULT,
      },
    },
  }

  const customerCodeSchema: FieldSchema = {
    type: 'string',
    title: intl.formatMessage(appleCustomerCodeFieldLabel.label),
    name: EligibilityField.DEPENDENT_CUSTOMER_CODE_FIELD_NAME,
    condition: {
      parentField: EligibilityField.ELIGIBILITY_TYPE,
      parentValue: ELIGIBILITY_TYPES.DEPENDENT,
    },
    validation: { isRequired: true, isValidCustomerCode: true },
    widgetProps: {
      showErrorAsWarning: showEligibilityErrorAsWarning,
    },
  }

  const eligibilityErrorBannerUiSchema = { 'ui:field': 'DependentEligibilityErrorBanner' }
  const employeeFullNameUiSchema = getFullNameUiSchema(isMobileSized)

  const customerCodeUiSchema = { 'ui:options': commonInputFieldStyling(isMobileSized, { marginBottom: 0 }) }
  return getEmployeeInformationFormMetadata({
    section1ItemsSchema: [
      {
        name: EligibilityField.DEPENDENT_HEADER,
        content: `###### ${formatMessageHelper(eligibilityDependentTitle, false, intl.formatMessage)}`,
        condition: {
          parentField: EligibilityField.ELIGIBILITY_TYPE,
          parentValue: ELIGIBILITY_TYPES.DEPENDENT,
        },
      },
      informationSubHeaderSchema,
      dependentRegistrationTipsSchema,
    ],
    section1ItemsUiSchema: [{}, informationSubHeaderUiSchema, eligibilityErrorBannerUiSchema],
    section2ItemsSchema: nameFields,
    section2ItemsUiSchema: employeeFullNameUiSchema,
    section3ItemsSchema: [employeeDobSchema, customerCodeSchema],
    section3ItemsUiSchema: [getDobUiSchema({ marginBottom: 24 }), customerCodeUiSchema],
    colors,
  })
}

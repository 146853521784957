import { defineMessage, MessageDescriptor } from 'react-intl'

export const ACCESS_DENIED = 'access_denied'

export const REGISTERED = 'registered'

export const INVALID = 'invalid'

export const INVALID_SECURITY_TOKEN_PARAM = 'token_error' // This key is hard coded on the backend and used to denote that an error has occurred during attempted FN/LN update.

export const GENERIC = 'generic'

export const INVALID_PAYMENT_ERROR: MessageDescriptor = defineMessage({
  defaultMessage: 'An error occurred in submitting your payment. Please try again.',
  description: 'Error message when payment submission fails',
})

import { PathwaysRedirectUrls } from './constants'

export const pathwaysRedirectToTriage = (redirectUrl: string | null) => {
  return redirectUrl === PathwaysRedirectUrls.SELF_TRIAGE || redirectUrl === PathwaysRedirectUrls.CHILD_TRIAGE
}

// TODO Hacky mapping to support essentials graphQL language code
export const translateToEssentialSiteCode = (lyraLanguage: string = '') => {
  const ESSENTIAL_DEFAULT_CODE = 'en'
  const essentialFormat = lyraLanguage.replace('-', '_').toLowerCase()
  switch (lyraLanguage) {
    case 'en-US':
    case '':
      return ESSENTIAL_DEFAULT_CODE
    case 'fil':
      return 'fil_ph'
    default:
      return essentialFormat
  }
}

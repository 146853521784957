import { Episode, PROVIDER_TYPES, ProviderEnums, ProviderInfo } from '../../../models'
import { TREATMENT_OPTIONS_TYPE } from '../../common/constants/treatmentOptions'
import {
  getConflictingEpisodeForProvider,
  getExistingSingleSessionCoachingEpisode,
  getTreatmentOptionBasedOnProviderCapacity,
  isProviderAnyTypeOf,
  isProviderBlendedCareType,
} from '../../providers/utils'

interface UseGetAppointmentConflictInfo {
  provider: ProviderInfo
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
  currentEpisodes: Episode[]
}

export const useGetAppointmentConflictInfo = () => {
  const getAppointmentConflictInfo = ({
    provider,
    selectedTreatmentOption,
    currentEpisodes,
  }: UseGetAppointmentConflictInfo) => {
    const isProviderBlendedCare = isProviderBlendedCareType({ provider })
    if (!isProviderBlendedCare) {
      return undefined
    }

    const treatmentOption = getTreatmentOptionBasedOnProviderCapacity({
      selectedTreatmentOption: selectedTreatmentOption || undefined,
      provider,
    })
    const conflictingEpisode = getConflictingEpisodeForProvider({
      currentEpisodes,
      selectedTreatmentOption: treatmentOption,
      provider,
    })

    const isProviderCoach = isProviderAnyTypeOf({ provider, providerTypes: [PROVIDER_TYPES[ProviderEnums.COACH]] })
    const singleSessionCoachingEpisode = getExistingSingleSessionCoachingEpisode({ currentEpisodes })
    if (!conflictingEpisode && (!singleSessionCoachingEpisode || !isProviderCoach)) {
      return undefined
    }
    return {
      hasSingleSessionConflict: false,
      hasEpisodeWithProviderBooked: conflictingEpisode?.provider_id === provider.lyra_id,
      hasAssociatedProviderTypeAppointmentBooked: Boolean(conflictingEpisode),
    }
  }
  return { getAppointmentConflictInfo }
}

// @intl project:provider-profile

import { defineMessages, IntlShape } from 'react-intl'

import { fromJS, List as ImmutableList, Iterable } from 'immutable'
import { get, intersection, trim } from 'lodash-es'

import {
  Address,
  Appointment,
  Customer,
  getPreferredContactInfo,
  getProviderSupportsLiveMessaging,
  ImmutableTypedMap,
  LocationObject,
  MEETING_FORMATS,
  PREFERRED_APPOINTMENT_MODALITIES,
  PROGRAM_TAXONOMY,
  PROGRAMS as ProgramsConfigurations,
  ProgramType,
  Provider,
  PROVIDER_CREDENTIALS,
  PROVIDER_TYPES,
  ProviderEnums,
  ProviderInfo,
  ProviderProgramTaxonomy,
  TREATMENT_MODE,
  TREATMENT_MODE_TO_PREFERRED_APPOINTMENT_MODALITY,
} from '@lyrahealth-inc/shared-app-logic'

import {
  PROGRAMS,
  TRIAGE_TREATMENT_OPTIONS_TO_PROVIDER_TYPES_FOR_DISPLAY,
} from '../constants/customerProgramAndTreatmentOptionConstants'

/**
 * Return the appropriate contact information for a given provider, based on their preferred contact methods.
 * We have no guarantee on how consistent the provider data will be, so the logic is somewhat complex.
 * @param $$provider
 * @returns contact information
 */
export const getContactInformation = ($$provider: ImmutableTypedMap<ProviderInfo>) => {
  const providerInfo = $$provider.toObject() as unknown as ProviderInfo
  return getPreferredContactInfo(providerInfo)
}

/**
 * Returns whether the given provider is of a type specified in providerTypes.
 * e.g. isProviderAnyTypeOf({$$provider, [PROVIDER_TYPES.COACH, PROVIDER_TYPES.THERAPIST]}) will return whether the
 * provider is either a coach or a therapist
 * @param $$provider - An object representing a provider
 * @param providerTypes - An object representing a provider type (from the constant PROVIDER_TYPES), or an iterable
 *  object containing one or more provider types.
 * @returns {*|boolean}
 */
export const isProviderAnyTypeOf = ({
  $$provider,
  providerTypes = [],
}: {
  $$provider: ImmutableTypedMap<ProviderInfo>
  providerTypes: Array<{ lyraType: string }> | { lyraType: string }
}) => {
  const isIterable = Array.isArray(providerTypes) || Iterable.isIterable(providerTypes)
  const providerTypesIterable = isIterable ? providerTypes : ImmutableList([providerTypes])
  return (providerTypesIterable as Array<{ lyraType: string }>).some((providerType) => {
    return (
      $$provider &&
      $$provider.get('lyra_type', PROVIDER_TYPES.PROVIDER.lyraType).toLowerCase() === providerType?.lyraType
    )
  })
}

/**
 * @deprecated, please make your object mutable and use `shared-app-logic/../providers/utils/isProviderBlendedCare`
 * going forward
 */
export const isProviderBlendedCareType = ({ $$provider }: { $$provider: ImmutableTypedMap<ProviderInfo> }) => {
  return $$provider
    .getIn(['program_taxonomy'], ImmutableList())
    .some(
      (taxonomy: ImmutableTypedMap<ProviderProgramTaxonomy>) =>
        taxonomy.get('partner') === PROGRAM_TAXONOMY.partner.blendedCare,
    )
}

export const providerHasAvailability = ($$provider: ImmutableTypedMap<ProviderInfo>) => {
  return $$provider.getIn(['availabilityCS', 'provider_availability', 0, 'availability'], ImmutableList()).size > 0
}

export const getTypeConstantForProvider = ($$provider: ImmutableTypedMap<ProviderInfo>) => {
  return Object.values(PROVIDER_TYPES).find((providerTypeObject) => {
    return providerTypeObject.lyraType === $$provider.get('lyraType', PROVIDER_TYPES.PROVIDER.lyraType).toLowerCase()
  })
}

export const getIsProviderBCTFellow = (provider: ProviderInfo) => {
  return provider.program_taxonomy
    ? provider.program_taxonomy.some((pt) => pt?.offering === PROGRAM_TAXONOMY.offering.fellowship)
    : false
}

export const getProgramConfigByProgramName = (programName: string) => {
  return PROGRAMS[programName]
}

export const getProgramConfigByProvider = (provider: ProviderInfo) =>
  PROGRAMS[PROVIDER_TYPES[ProviderEnums[provider?.lyra_type?.toUpperCase?.()]]?.program]

// Return a list of customer programs associated with the provider modalities,
// e.g. passing a modalities list of ['stressManagement', 'singleSessionCoaching'] would return ['stressManagement', 'guidedSelfCareEnabled']
export const getProviderProgramsFromProviderModalities = (modalities: string[]) => {
  const providerPrograms: string[] = []
  Object.values(ProgramsConfigurations)
    .filter((programConfig: ProgramType) => {
      // Filter down to programs where the associated modalities includes a modality found within the provider profile
      return programConfig.associatedProviderModalities
        ? intersection(programConfig.associatedProviderModalities, modalities).length > 0
        : false
    })
    .forEach((matchedProgram: ProgramType) => {
      providerPrograms.push(matchedProgram.customerPropertyId)
    })

  return providerPrograms
}

export const formatAddressForDisplay = ($$address: ImmutableTypedMap<Address>) => {
  const addressLine1 = `${$$address.get('street1')}`.concat(
    trim($$address.get('street2', '')) ? `, ${$$address.get('street2')}` : '',
  )
  const addressLine2 = `${$$address.get('city')}, ${$$address.get('state')} ${$$address.get(
    'zipcode',
    $$address.get('zip', ''),
  )}`
  return { addressLine1, addressLine2 }
}

export const getProvidersMatchingGivenAppointmentModalities = ({
  providers,
  modalities,
}: {
  providers: ImmutableList<ImmutableTypedMap<ProviderInfo>>
  modalities: ImmutableList<string>
}) => {
  if (modalities.includes(PREFERRED_APPOINTMENT_MODALITIES.NO_PREFERENCE)) {
    return providers
  } else {
    return providers.filter((provider) => {
      // Convert from the provider's `supportedVisitTypes` attribute (aka their supported modalities) to the preferred
      // modality enum values, then return all providers who have at least one modality that supports the client's choices
      if (!provider) return false
      return (
        intersection(
          TREATMENT_MODE_TO_PREFERRED_APPOINTMENT_MODALITY[provider.get('supportedVisitTypes', TREATMENT_MODE.NONE)],
          modalities.toJS ? modalities.toJS() : modalities,
        ).length > 0
      )
    })
  }
}

/*
 * Returns the contextual copy that is tied to a specific provider credential.
 * If the credential does not have an explicit context mapping, it falls back to the default for the provider type
 */
export const getCredentialContext = (
  { formatMessage }: IntlShape,
  $$provider: ImmutableTypedMap<ProviderInfo>,
  credential: string,
): string => {
  // Returns the first match between parentheses or empty string
  // Ex: "Licensed Marriage and Family Therapist (LMFT)" => "LMFT"
  const cred = (credential.match(/\((?<cred>[^)]+)\)/)?.groups?.cred ?? '').toUpperCase()

  const CREDENTIAL_CONTEXT = defineMessages({
    APRN: {
      defaultMessage:
        'Masters-level nurse practitioner who can provide evaluation and management of medications for behavioral health conditions.',
      description: "Provider Profile: Contextual copy for provider's credential: APRN",
    },
    LCSW: {
      defaultMessage:
        'Mental health professional with a master’s in social work. Lyra’s LCSWs are trained and licensed to provide therapy to individuals, couples, and families using evidence-based approaches.',
      description: "Provider Profile: Contextual copy for provider's credential: LSCW",
    },
    LMFT: {
      defaultMessage:
        'Mental health professional with a master’s in marriage and family therapy. Lyra’s LMFTs are trained and licensed to provide therapy to individuals, couples, and families using evidence-based approaches.',
      description: "Provider Profile: Contextual copy for provider's credential: LMFT",
    },
    MD: {
      defaultMessage:
        'Medical doctor who can provide evaluation and management of medications for behavioral health conditions.',
      description: "Provider Profile: Contextual copy for provider's credential: MD",
    },
    PHD: {
      defaultMessage:
        'Mental health professional with a doctorate in psychology, trained and licensed to provide therapy using a variety of approaches.',
      description: "Provider Profile: Contextual copy for provider's credential: PhD",
    },
    PSYD: {
      defaultMessage:
        'Mental health professional with a doctorate in psychology, trained and licensed to provide therapy using a variety of approaches.',
      description: "Provider Profile: Contextual copy for provider's credential: PsyD",
    },
    'REGISTERED ACSW': {
      defaultMessage:
        'ACSWs are completing their licensure in the Lyra Clinical Fellowship program. Each provider has 900+ hours of supervised clinical experience.',
      description: "Provider Profile: Contextual copy for provider's credential: Registered ACSW",
    },
  })

  if (CREDENTIAL_CONTEXT[cred]) {
    return formatMessage(CREDENTIAL_CONTEXT[cred])
  }

  /*
   * Known credentials not listed above, fall back to generic context by provider type
   * CMHC
   * CPC
   * LCPC
   * LICSW
   * LMHC
   * LPC
   * LPC-MHSP
   * LPCC
   */
  if (isProviderAnyTypeOf({ $$provider, providerTypes: PROVIDER_TYPES.COACH })) {
    return formatMessage({
      defaultMessage:
        'Lyra’s mental health coaches draw on principles and practices from evidence-based therapies such as cognitive behavioral therapy (CBT), acceptance and commitment therapy (ACT), and dialectical behavior therapy (DBT).',
      description:
        "Provider Profile: Contextual copy for provider's credential: Generic message for mental health coach",
    })
  } else if (
    isProviderAnyTypeOf({
      $$provider,
      providerTypes: [PROVIDER_TYPES.PSYCHIATRIST, PROVIDER_TYPES.LYRAPRESCRIBER],
    })
  ) {
    return formatMessage({
      defaultMessage:
        'Medical professional who can provide evaluation and management of medications for behavioral health conditions.',
      description:
        "Provider Profile: Contextual copy for provider's credential: Generic message for psychiatrist or physician",
    })
  } else {
    return formatMessage({
      defaultMessage:
        'Mental health professional trained and licensed to provide therapy using a variety of approaches. Depending on the provider, may work with adults, children, couples, or families.',
      description:
        "Provider Profile: Contextual copy for provider's credential: Generic message for mental health professional",
    })
  }
}

export const getProviderTitle = ({ formatMessage }: IntlShape, $$provider: ImmutableTypedMap<ProviderInfo>): string => {
  const providerType = getTypeConstantForProvider($$provider)
  return formatMessage(providerType?.displayTitle ?? PROVIDER_TYPES.PROVIDER.displayTitle)
}

export const getProviderCredential = ({ formatMessage }: IntlShape, credential: string): string => {
  if (PROVIDER_CREDENTIALS[credential]) {
    return formatMessage(PROVIDER_CREDENTIALS[credential])
  } else {
    return credential
  }
}

export const getProvidersModalitySupport = (
  $$provider: ImmutableTypedMap<ProviderInfo>,
  $$customer: ImmutableTypedMap<Customer>,
) => {
  const modalities: Array<MEETING_FORMATS> =
    TREATMENT_MODE_TO_PREFERRED_APPOINTMENT_MODALITY[$$provider.get('supportedVisitTypes', TREATMENT_MODE.NONE)]
  return {
    isOnsite: $$provider.get('onsite', false),
    supportsVideo: modalities.includes(MEETING_FORMATS.VIDEO),
    supportsInPerson: modalities.includes(MEETING_FORMATS.IN_PERSON),
    supportsLiveMessaging:
      $$customer.getIn(['customerPropertiesMap', 'liveMessagingEnabled']) === 'true' &&
      getProviderSupportsLiveMessaging({ provider: $$provider.toJS() }),
  }
}

export const getProviderTypeForSelectedTreatmentOption = (selectedTreatmentOption: string) => {
  return get(TRIAGE_TREATMENT_OPTIONS_TO_PROVIDER_TYPES_FOR_DISPLAY, selectedTreatmentOption, PROVIDER_TYPES.PROVIDER)
}

export const getTimeZoneLookupData = (locationData: LocationObject, providerId: string) => {
  const timeZoneLookupData = {
    latLong: '',
    locationState: locationData.geo?.state,
    providerIds: [providerId],
    location: '',
  }
  if (locationData.geo?.lat) {
    timeZoneLookupData.latLong = locationData.geo.lat + ', ' + locationData.geo.lon
  } else {
    timeZoneLookupData.location = locationData.geo?.formattedAddress ?? ''
  }

  return timeZoneLookupData
}

export const appointmentIsUpcomingOnPlatform = (appointment: Appointment, meetingFormat: string) => {
  return (
    appointment.meetingFormat === meetingFormat &&
    ['newAppt', 'rescheduled'].includes(appointment.appointmentStatus) &&
    isProviderBlendedCareType({ $$provider: fromJS(appointment.provider) })
  )
}

/**
 * Helper to get the episode current session count with a provider
 * @param {*} providerId string
 * @param {*} episodeId string
 * @param {*} providers Providers
 * @returns number
 */
export const getProviderSessionCounts = (providerId: string, episodeId: string | null, providers: Provider[]) => {
  const provider = providers.find((provider) => provider.provider.lyra_id === providerId)
  return (episodeId && provider?.provider?.episodeSessionCounts?.[episodeId]) || 0
}

import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export interface BoldCheckJaggedIconProps {
  size?: string | number
  fillColor?: string
}

export const BoldCheckJaggedIcon: FunctionComponent<BoldCheckJaggedIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} viewBox={`0 0 24 24`} testID={tID('BoldCheckJaggedIcon')}>
      <Path
        d='M22 11L19.56 8.21L19.9 4.52L16.29 3.7L14.4 0.5L11 1.96L7.6 0.5L5.71 3.69L2.1 4.5L2.44 8.2L0 11L2.44 13.79L2.1 17.49L5.71 18.31L7.6 21.5L11 20.03L14.4 21.49L16.29 18.3L19.9 17.48L19.56 13.79L22 11ZM9.09 15.72L5.29 11.91L6.77 10.43L9.09 12.76L14.94 6.89L16.42 8.37L9.09 15.72Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

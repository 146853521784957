import { fromJS } from 'immutable'

import { DEFAULT_WORKLIFE_SERVICES, WORKLIFE_SERVICE_IDS } from '../../../features/workLife/constants/workLifeConstants'
import DependentsIllustration from '../../../images/microsite/illustration-dependents.png'

export const roche = () => {
  return fromJS({
    workLifeServices: [
      DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.FINANCIAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT,
      {
        identifiers: WORKLIFE_SERVICE_IDS.DEPENDENT_CARE,
        title: 'Elder Care',
        img: DependentsIllustration,
        paragraph: 'Get assistance finding elder care.',
        bullets: [],
        CTA: 'Find elder care',
        PATH: 'https://clients.brighthorizons.com/roche',
      },
    ],
  })
}

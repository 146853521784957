import { fromJS } from 'immutable'
import { applyMiddleware, compose, createStore } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import { asyncSessionStorage } from 'redux-persist/storages'
import { autoRehydrate, persistStore } from 'redux-persist-immutable'

import autoThunkMiddleWare from '@lyrahealth-inc/auto-thunk'

import { actionErrorHandler } from '../../common/http/actionErrorHandler'
import { axiosInstance } from '../../common/http/axiosInstance'
import { getUsername as getHealthPlanUsername } from '../../features/healthPlan/data/healthPlanSelectors'
import reducers from '../reducers'
import { getUsername } from '../user/userSelectors'

const autoThunk = autoThunkMiddleWare({
  httpClient: axiosInstance,
  errorHandler: actionErrorHandler,
})

const middlewares = [autoThunk]
const initialState = fromJS({})

const store = createStore(
  enableBatching(reducers),
  initialState,
  compose(applyMiddleware(...middlewares), autoRehydrate()),
)

const persistor = persistStore(store, {
  storage: asyncSessionStorage,
  whitelist: ['triage', 'onboard', 'healthPlan', 'register', 'triageDemographics', 'clinicalDataSharing'],
})

store.subscribe(() => {
  const state = store.getState()
  // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
  const loggedInUsername = getUsername(state)
  const healthPlanUsername = getHealthPlanUsername(state)
  if (loggedInUsername && healthPlanUsername && healthPlanUsername !== loggedInUsername) {
    // Check if `healthPlan.username` does not match with `user.username`.
    // If so, purge `healthPlan`
    persistor.purge(['healthPlan'])
  }
})

export default store

const worker = () => {
  // self defaults to the Window interface, which does not exist in web workers
  // We need to cast self to the Worker interface to resolve ts errors
  const selfWorker: Worker = self as any
  selfWorker.onmessage = (e: MessageEvent<any>) => {
    const countdown = e?.data
    if (typeof countdown === 'number') {
      setInterval(() => {
        selfWorker.postMessage(countdown - 1)
      }, 1000)
    }
  }
}

let code = worker.toString()
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'))

const createWorkerScript = () => {
  // @ts-ignore
  const blob = new Blob([code], { type: 'application/javascript' })
  // @ts-ignore
  const workerScript = URL.createObjectURL(blob)
  return workerScript
}

export default createWorkerScript

import React, { FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { WellnessCheckInOverview } from '@lyrahealth-inc/ui-core-crossplatform'

import {
  WELLNESS_CHECK_IN_PAGE_TYPE,
  WellnessCheckInHeader,
} from '../../common/components/header/WellnessCheckInHeader'
import { useShouldShowWellnessCheckIn } from '../../common/hooks/useShouldShowWellnessCheckIn'

const WellnessCheckInOverviewContainer: FunctionComponent = () => {
  const navigate = useNavigate()
  const showWellnessCheckIn = useShouldShowWellnessCheckIn()

  useEffect(() => {
    if (!showWellnessCheckIn) {
      navigate('/')
    }
  }, [showWellnessCheckIn, navigate])

  return (
    <>
      <WellnessCheckInHeader pageType={WELLNESS_CHECK_IN_PAGE_TYPE.Overview} />
      <WellnessCheckInOverview onCTAPress={() => navigate(`/wellness-check-in/1`)} />
    </>
  )
}

export default WellnessCheckInOverviewContainer

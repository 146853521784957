import React, { forwardRef, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { ImageSourcePropType, ScrollView, useWindowDimensions, View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import boxShadowRectangleDesktopImage from '../../assets/wellnessCheckIn/BoxShadowRectangleDesktop.png'
import boxShadowRectangleMobileImage from '../../assets/wellnessCheckIn/BoxShadowRectangleMobile.png'
import { Image } from '../../atoms'
import { IS_WEB } from '../../constants'
import { ThemeType, tID } from '../../utils'

export type WellnessCheckInResultsPreviewOverlayProps = {
  children: ReactNode
}

const OverlayContainer = styled(ScrollView)({
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
  background: 'transparent',
})

const OverlayImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  height: theme.breakpoints.isMobileSized ? '180px' : '208px',
  width: '100%',
  top: theme.breakpoints.isMobileSized ? '-140px' : '-156px',
  position: 'absolute',
}))

const ModalContainer = styled(View)({
  minHeight: '860px',
})

const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  borderTopLeftRadius: theme.breakpoints.isMobileSized ? '24px' : '40px',
  borderTopRightRadius: theme.breakpoints.isMobileSized ? '24px' : '40px',
  flex: 1,
}))

const TransparentBackground = styled.View<{ theme: ThemeType; windowHeight: number }>(({ theme, windowHeight }) => ({
  height: theme.breakpoints.isMobileSized ? 0.6 * windowHeight : 0.7 * windowHeight,
  ...(windowHeight > 1000 && {
    height: '700px',
  }),
  ...(windowHeight > 2000 && {
    height: `${0.3 * windowHeight}px`,
  }),
}))

export const WellnessCheckInResultsPreviewOverlay = forwardRef(
  ({ children }: WellnessCheckInResultsPreviewOverlayProps) => {
    const { height } = useWindowDimensions()
    const { breakpoints } = useTheme()

    // TODO update image gradient to match background
    const renderOverlay = () => (
      <OverlayContainer testID={tID('WellnessCheckInResultsPreviewOverlayContainer')}>
        <TransparentBackground windowHeight={height} />
        <ModalContainer>
          <OverlayImageContainer
            source={
              breakpoints.isMobileSized
                ? (boxShadowRectangleMobileImage as ImageSourcePropType)
                : (boxShadowRectangleDesktopImage as ImageSourcePropType)
            }
            contentFit='cover'
            accessibilityIgnoresInvertColors
            testID={tID('WellnessCheckInResultsBoxShadow')}
          />
          <ContentContainer>{children}</ContentContainer>
        </ModalContainer>
      </OverlayContainer>
    )

    return IS_WEB ? createPortal(renderOverlay(), document.body) : <></>
  },
)

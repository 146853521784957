import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

export interface CloseQuotationIconProps {
  width?: string | number
}

export const CloseQuotationIcon: FunctionComponent<CloseQuotationIconProps> = ({ width = 24 }) => {
  return (
    <Svg width={width} height={width} viewBox='0 0 24 24' fill='none'>
      <Path
        opacity='0.8'
        d='M7.032 4C9.72 4 11.344 6.352 11.344 9.544C11.344 14.92 7.816 18.392 4.96 20.184L3.224 18.112C5.128 16.432 6.696 14.304 6.808 12.232C5.072 12.456 3 11.056 3 8.2C3 5.568 4.792 4 7.032 4ZM17.336 4C20.024 4 21.592 6.352 21.592 9.544C21.592 14.92 18.12 18.392 15.208 20.184L13.528 18.112C15.432 16.432 17 14.304 17.112 12.232C15.376 12.456 13.304 11.056 13.304 8.2C13.304 5.568 15.04 4 17.336 4Z'
        fill='#777673'
      />
    </Svg>
  )
}

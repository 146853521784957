import { useCallback } from 'react'
import { GooglePlaceDetail } from 'react-native-google-places-autocomplete'

import { blankAddressSearch } from '../constants'

interface AddressType {
  state?: string
  country?: string
}

/**
 * Given the google places data, create the location object to use in the store
 */
export const useLocationGeoData = () =>
  useCallback(({ details }: { details: GooglePlaceDetail }) => {
    let newAddressObj = Object.assign({}, blankAddressSearch, {
      userInput: '',
      lat: details.geometry.location.lat,
      lon: details.geometry.location.lng,
      formattedAddress: details.formatted_address,
    })

    const addressComponents = details.address_components
    const addressTypes: AddressType = addressComponents.reduce((prev, curr) => {
      if (curr.types.includes('administrative_area_level_1')) {
        return { state: curr.short_name, ...prev }
      } else if (curr.types.includes('country')) {
        return { country: curr.long_name, ...prev }
      }
      return prev
    }, {})
    // Temporary workaround to address Google handling Puerto Rico as a separate country
    // PSS / Arbiter assumes Puerto Rico as a region of the US but we don't have the syntax to indicate it
    // Thus we forcibly modify the generated location payload such that Puerto Rico is represented as both the province and the country.
    if (addressTypes.country === 'Puerto Rico') {
      addressTypes.state = 'PR'
    }
    newAddressObj = Object.assign({}, newAddressObj, addressTypes)
    return newAddressObj
  }, [])

import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  RESET_CARE_LANGUAGE_STATE,
  SET_AVAILABLE_CARE_LANGUAGES,
  SET_PARENT_INITIATED_IN_PERSON_S4C_PREFERENCE_CHECKED,
  SET_PARENT_INITIATED_IN_PERSON_TEEN_S4C_PREFERENCE_CHECKED,
  SET_USER_MEMBER_PREFERENCES_V2,
  UPDATE_SELECTED_CARE_LANGUAGE,
} from './carePreferencesActions'

const initialState = fromJS({
  availableCareLanguages: undefined,
  selectedCareLanguage: undefined,
  userMemberPreferencesV2: undefined,
  isParentInitiatedInPersonS4CPreferenceChecked: true, // Parent child searches aged (0-12)
  isParentInitiatedInPersonTeenS4CPreferenceChecked: true, // Parent child searches aged (13-17)
})

export default createReducer(initialState, {
  [SET_AVAILABLE_CARE_LANGUAGES]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('availableCareLanguages', action.data)
  },
  [UPDATE_SELECTED_CARE_LANGUAGE]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('selectedCareLanguage', action.data)
  },
  [RESET_CARE_LANGUAGE_STATE]: ($$state: any) => {
    $$state = $$state.set('availableCareLanguages', undefined)
    $$state = $$state.set('selectedCareLanguage', undefined)
    return $$state
  },
  [SET_USER_MEMBER_PREFERENCES_V2]: ($$state: any, action: any) => {
    $$state = $$state.set('userMemberPreferencesV2', action.value)
    return $$state
  },
  [SET_PARENT_INITIATED_IN_PERSON_S4C_PREFERENCE_CHECKED]: ($$state: any, action: any) => {
    $$state = $$state.set('isParentInitiatedInPersonS4CPreferenceChecked', action.value)
    return $$state
  },
  [SET_PARENT_INITIATED_IN_PERSON_TEEN_S4C_PREFERENCE_CHECKED]: ($$state: any, action: any) => {
    $$state = $$state.set('isParentInitiatedInPersonTeenS4CPreferenceChecked', action.value)
    return $$state
  },
})

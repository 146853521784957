import { CreateCrmProfileViewedEvent } from '@lyrahealth-inc/shared-app-logic'

import { axiosInstance } from '../axiosInstance'

export const createCRMAppointmentTicket = ({ lyraId, data }: CreateCrmProfileViewedEvent) => {
  return new Promise<string | void>(function (resolve) {
    createCRMAppointmentTicketPromise({ lyraId, data }).then((response) => {
      resolve(response)
    })
  })
}

const createCRMAppointmentTicketPromise = async ({ lyraId, data }: CreateCrmProfileViewedEvent): Promise<string> => {
  const { data: response } = await axiosInstance.post(`/v1/appointments/${lyraId}/ticket`, data)
  return response
}

/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import imageMobile from '../../assets/PathwaysCareNavigatorIllustration.png'
import imageWeb from '../../assets/PathwaysCareNavigatorIllustrationWeb.png'
import { IS_WEB } from '../../constants'
import { tID } from '../../utils'
import { Image } from '../image/Image'

type PathwaysCareNavigatorIllustrationProps = {
  size?: number
}

const ImageContainer = styled(Image)<{ size: string }>(({ size }) => ({
  width: size,
  height: size,
}))

export const PathwaysCareNavigatorIllustration: FunctionComponent<PathwaysCareNavigatorIllustrationProps> = ({
  size = 201,
  ...props
}) => {
  const sizeInPixels = `${size}px`
  return (
    <ImageContainer
      size={sizeInPixels}
      testID={`${tID('LiveChatIllustrationIcon')}`}
      source={(IS_WEB ? imageWeb : imageMobile) as ImageSourcePropType}
      {...props}
    />
  )
}

import React, { FunctionComponent } from 'react'
import Svg, { Path, Rect } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

type LyraHealthAppIconProps = {
  size?: number
  borderRadius?: number
}

export const LyraHealthAppIcon: FunctionComponent<LyraHealthAppIconProps> = ({ size = 48, borderRadius }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 48 48' fill='none' style={{ borderRadius }}>
      <Rect width='48' height='48' rx='8' fill={colors.iconActive} />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.9011 23.6288C37.9454 21.9142 36.5993 20.4735 34.9113 20.4289C33.14 20.3822 31.6712 21.714 31.6262 23.4075C31.5785 25.2072 32.9266 26.6319 34.7205 26.6775C36.4311 26.721 37.8564 25.3548 37.9011 23.6288ZM39.7987 28.4791H37.9422V27.4498C37.8199 27.5194 37.7412 27.5578 37.6693 27.6061C34.5602 29.6978 30.4446 27.9289 29.8616 24.2498C29.4382 21.5777 31.4282 18.9617 34.1298 18.6391C37.2254 18.2695 39.7976 20.5348 39.7986 23.6318C39.7991 25.1237 39.7987 26.6156 39.7987 28.1074V28.4791Z'
        fill={colors.textInverse}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.8496 25.8055C17.938 25.6109 17.9997 25.4811 18.0564 25.3491C18.9813 23.1969 19.9086 21.0458 20.8251 18.89C20.9159 18.6766 21.0224 18.5919 21.2608 18.6002C21.8243 18.6197 22.3889 18.6064 22.9942 18.6064C22.9464 18.7324 22.9147 18.8289 22.8742 18.9215C20.8631 23.521 18.8502 28.1196 16.8432 32.7208C16.758 32.9161 16.6642 33.0069 16.4344 32.999C15.8594 32.9792 15.2834 32.9925 14.6669 32.9925C14.7151 32.8689 14.7499 32.7662 14.7942 32.6679C15.426 31.2663 16.0542 29.863 16.6973 28.4666C16.794 28.2567 16.796 28.0883 16.7104 27.8766C15.508 24.9001 14.3139 21.9202 13.1184 18.9409C13.0803 18.8458 13.0497 18.7477 13 18.6071C13.6365 18.6071 14.2389 18.5963 14.8401 18.6196C14.9238 18.6228 15.0331 18.7805 15.0767 18.8885C15.8244 20.7404 16.5635 22.5959 17.3049 24.4504C17.4756 24.8773 17.6478 25.3035 17.8496 25.8055Z'
        fill={colors.textInverse}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00519 15H9.89944V15.4051C9.89944 18.4795 9.89912 21.5537 9.89957 24.6281C9.89977 26.0074 10.4388 26.5656 11.8622 26.6429C12.2301 26.663 12.6001 26.6459 12.9971 26.6459V28.4828C11.7639 28.5126 10.5257 28.5736 9.39146 27.9501C8.48745 27.4531 8.02431 26.6588 8.01648 25.6832C7.98789 22.1342 8.00519 18.5851 8.00519 15Z'
        fill={colors.textInverse}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.3594 18.6126H26.1708V20.4C27.1048 19.0922 28.3087 18.5496 29.8107 18.6032V20.3941C29.7489 20.4045 29.6801 20.429 29.6126 20.4258C27.9229 20.3436 26.7134 21.5285 26.3193 23.146C26.2147 23.575 26.1848 24.0308 26.1784 24.4752C26.1596 25.787 26.1698 27.0992 26.1679 28.4112C26.1679 28.4313 26.1587 28.4515 26.1468 28.4995H24.3594V18.6126Z'
        fill={colors.textInverse}
      />
    </Svg>
  )
}

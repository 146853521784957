// @ts-nocheck
import { useEffect } from 'react'

/* OneTrust code from https://my.onetrust.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046?language=en_US#UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046_section-idm46212287146848
 * needed to reinitialize the OneTrust Banner for SPAs. */
export const useReloadOneTrustBanner = (shouldShowCookieBanner: boolean = false, customizeCookieLinkText: string) => {
  useEffect(() => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const getCookie = (name) => {
      const value = '; ' + document.cookie
      const parts = value.split('; ' + name + '=')
      return parts.length == 2
    }

    const reloadOTBanner = () => {
      const otConsentSdk = document.getElementById('onetrust-consent-sdk')
      if (otConsentSdk) {
        otConsentSdk.remove()
      }
      if (window.OneTrust != null) {
        window.OneTrust.Init()

        setTimeout(function () {
          window.OneTrust.LoadBanner()

          const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings')
          // Banner reload seems to reset the link text, so changing it back here
          toggleDisplay[0].textContent = customizeCookieLinkText

          for (let i = 0; i < toggleDisplay.length; i++) {
            toggleDisplay[i].onclick = function (event) {
              event.stopImmediatePropagation()
              window.OneTrust.ToggleInfoDisplay()
            }
          }
        }, 1000)
      }
    }

    if (shouldShowCookieBanner) {
      reloadOTBanner()
    }
  }, [shouldShowCookieBanner, customizeCookieLinkText])
}

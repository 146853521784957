import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { ImageSourcePropType, View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  Image,
  PrimaryButton,
  Subhead,
  ThemeType,
  tID,
  useMediaQuerySize,
} from '@lyrahealth-inc/ui-core-crossplatform'

import wellnessCheckInResultsGetStartedImage from '../../assets/WellnessCheckInResultsFooter.png'
import { SubheadSize } from '../../styles'

export interface WellnessCheckInResultsGetStartedProps {
  onPress: () => void
}

const GetStartedContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSecondary,
  padding: `${theme.spacing['64px']} 0`,
}))

const TextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: `0 ${theme.spacing['16px']}`,
  margin: 'auto',
}))

const TitleTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: `${theme.spacing['8px']}`,
}))

const DescriptionTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['24px'],
}))

const ImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  width: theme.breakpoints.isMobileSized ? '172px' : '224px',
  height: theme.breakpoints.isMobileSized ? '128px' : '166px',
  margin: 'auto',
  marginBottom: theme.spacing['24px'],
}))

const ButtonContainer = styled.View<{ theme: ThemeType }>(({}) => ({
  margin: 'auto',
}))

export const WellnessCheckInResultsGetStarted: FunctionComponent<WellnessCheckInResultsGetStartedProps> = ({
  onPress,
}) => {
  const { colors } = useTheme()
  const { isMobileSized } = useMediaQuerySize()

  return (
    <View testID={tID(`WellnessCheckInResultsGetStarted`)}>
      <GetStartedContainer>
        <ImageContainer
          source={wellnessCheckInResultsGetStartedImage as ImageSourcePropType}
          contentFit='cover'
          accessibilityIgnoresInvertColors
        />
        <TextContainer>
          <TitleTextContainer>
            <Subhead
              level='2'
              size={isMobileSized ? SubheadSize.MEDIUM : SubheadSize.LARGE}
              text={
                <FormattedMessage
                  defaultMessage='Interested in talking with someone about your wellbeing?'
                  description='Wellness check in results footer title'
                />
              }
              textAlign='center'
            />
          </TitleTextContainer>
          <DescriptionTextContainer>
            <BodyText
              text={
                <FormattedMessage
                  defaultMessage='Discover personalized care recommendations and match with the right provider.'
                  description='Wellness check in results footer description'
                />
              }
              size={BodyTextSize.DEFAULT}
              color={colors.textSecondary}
              textAlign='center'
            />
          </DescriptionTextContainer>
          <ButtonContainer>
            <PrimaryButton
              onPress={onPress}
              text={
                <FormattedMessage
                  defaultMessage='Get started'
                  description='Wellness check in results get started button text'
                />
              }
              testID={tID('WellnessCheckInResultsGetStartedButton')}
            />
          </ButtonContainer>
        </TextContainer>
      </GetStartedContainer>
    </View>
  )
}

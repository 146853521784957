import React, { FunctionComponent } from 'react'
import Svg, { Ellipse, G, Mask, Path } from 'react-native-svg'

type ValuePropProvidersIntelligentlyMatchedProps = {
  width?: number
  height?: number
}

export const ValuePropProvidersIntelligentlyMatched: FunctionComponent<ValuePropProvidersIntelligentlyMatchedProps> = ({
  width = 57,
  height = 56,
}) => {
  return (
    <Svg width={width} height={height} viewBox='0 0 57 56' fill='none'>
      <Path
        d='M28.2203 0.234863C24.3548 0.234863 20.6045 0.897729 17.0733 2.20465C8.66628 5.32089 2.75582 12.2295 0.85686 21.1589C-0.398049 26.9808 -0.237589 32.3656 1.35041 37.6132C3.37774 44.3149 8.35422 49.9664 15.0039 53.1181C18.305 54.6861 22.0973 55.5814 26.2737 55.7784C26.8104 55.8027 27.356 55.816 27.8982 55.816C31.2491 55.816 34.6199 55.3568 37.9176 54.4515C38.171 54.3885 38.4123 54.3232 38.6668 54.2479C41.6303 53.3803 44.2973 52.0081 46.5791 50.1744C49.0568 48.1947 51.0963 45.6849 52.6345 42.7191C52.9378 42.1348 53.2277 41.5196 53.5475 40.7837C55.307 36.698 56.1569 32.1244 56.0064 27.5518C55.9356 25.53 55.6346 23.3809 55.1145 21.1589C54.258 17.5535 52.8614 14.1628 51.0753 11.3476C50.5862 10.5917 50.1369 9.95653 49.692 9.38551C46.4817 5.24454 41.4798 2.33855 35.2318 0.986259C32.9367 0.488279 30.5774 0.23597 28.2214 0.23597L28.2203 0.234863Z'
        fill='white'
      />
      <Mask
        id='mask0_10302_10824'
        // @ts-ignore-next-line maskType not supported in react-native-svg
        style={{
          maskType: 'luminance',
        }}
        maskUnits='userSpaceOnUse'
        x={0}
        y={-1}
        width={56}
        height={57}
      >
        <Path
          d='M55.9899 27.3901C55.9099 25.2701 55.5899 23.0801 55.0799 20.9001C54.2499 17.3901 52.8799 13.9301 50.9999 10.9601C50.5599 10.2901 50.1199 9.64006 49.6299 9.02006C46.1099 4.47006 40.7599 1.95006 35.2999 0.760063C29.2099 -0.559937 22.7299 -0.169937 16.8799 1.99006C8.40988 5.13006 2.63988 12.0101 0.759878 20.8501C0.0398784 24.2001 -0.180122 27.9901 0.149878 31.6201H0.119878C0.309878 33.6901 0.679878 35.7001 1.23988 37.5601C3.31988 44.4301 8.37988 49.8901 14.8199 52.9401C18.3099 54.6001 22.1999 55.4201 26.1699 55.6101C30.2199 55.8001 34.3299 55.2901 38.1299 54.2401C38.3799 54.1801 38.6199 54.1201 38.8599 54.0401C41.8399 53.1701 44.4299 51.8201 46.6399 50.0401C49.0599 48.1101 51.0499 45.6801 52.5899 42.7001C52.9299 42.0501 53.2199 41.4101 53.5099 40.7401C55.3299 36.5201 56.1399 31.9301 55.9799 27.3901H55.9899Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_10302_10824)'>
        <Ellipse cx={28} cy={28.5} rx={30} ry={30.5} fill='white' />
        <Path
          d='M48.4408 25.8401C48.2608 24.4301 47.8208 23.0201 47.3908 21.6701C46.9408 20.2601 46.4008 18.8801 45.8008 17.5201C45.5808 17.0301 44.8608 16.9101 44.4308 17.1601C43.9208 17.4601 43.8508 18.0301 44.0708 18.5301C44.5808 19.6801 45.0208 20.8601 45.4108 22.0501C45.1808 21.6401 44.7708 21.3701 44.2708 21.5101C43.7608 21.6501 43.4108 22.2201 43.5708 22.7401C43.9408 23.8901 44.3908 25.1501 44.2108 26.3801C44.1008 27.1101 43.6908 27.9901 42.9608 28.2801C42.5408 28.4501 42.2308 28.7701 42.2308 29.2401C42.2308 29.6401 41.8408 30.8301 42.1108 31.1701C42.8808 32.1001 45.8708 30.6901 47.0008 29.9101C48.1208 29.1301 48.6208 27.2301 48.4408 25.8401Z'
          fill='#99553F'
        />
        <Path
          d='M33.1605 19.1101C32.9205 21.0501 32.2504 21.8101 32.9204 24.4301C34.3104 28.1401 33.8305 28.7001 35.4005 30.4801C36.3605 31.5701 37.7205 31.6901 37.7205 31.6901V34.5901L44.0705 33.7401L43.4705 29.1401C43.4705 29.1401 45.2705 27.3301 45.7505 26.3601C47.3005 23.2401 44.1904 24.0601 44.1904 24.0601C44.1904 24.0601 44.6705 18.1301 41.5505 18.1301C38.4305 18.1301 33.1605 19.1001 33.1605 19.1001V19.1101Z'
          fill='#BF7760'
        />
        <Path
          d='M32.1801 17.6699C31.3801 19.2899 31.3801 20.9099 32.1801 21.4799C33.2401 22.2299 35.3901 20.6099 38.2001 20.5099C40.3701 20.4299 41.5701 20.2999 42.4501 20.7499C44.0501 21.5599 43.3701 23.8799 44.5701 24.1199C45.7701 24.3599 45.6601 24.1499 45.9001 23.4199C46.1401 22.6899 47.1801 19.9399 45.5001 17.0999C43.7301 14.1199 34.2701 13.4499 32.1801 17.6699Z'
          fill='#99553F'
        />
        <Path
          d='M46.1007 24.6701C46.1007 24.6701 46.0907 24.6001 46.0807 24.5701L46.1207 24.8401C46.1007 24.6901 46.0907 24.5401 46.0607 24.3901C46.0307 24.2501 45.9707 24.1201 45.9107 24.0001C45.9007 23.9801 45.8807 23.9501 45.8707 23.9301C45.8307 23.8801 45.7407 23.7401 45.6707 23.6801C45.5107 23.5401 45.3307 23.4101 45.1107 23.3601C45.1107 23.3601 45.1007 23.3601 45.0907 23.3601C45.0007 23.3501 44.9107 23.3401 44.8207 23.3201C44.8107 23.3201 44.7907 23.3201 44.7807 23.3201C44.6907 23.3201 44.5807 23.3401 44.4907 23.3601C44.4807 23.3601 44.4607 23.3601 44.4507 23.3601C44.3507 23.3901 44.2507 23.4401 44.1507 23.4801C44.1307 23.4901 44.1007 23.5101 44.0807 23.5201C43.9707 23.6001 43.8507 23.6801 43.7607 23.7801C43.6707 23.8801 43.5907 23.9901 43.5007 24.1101C43.4307 24.2201 43.3907 24.3501 43.3807 24.4801C43.3507 24.6101 43.3607 24.7501 43.4007 24.8801C43.4307 25.0101 43.4907 25.1201 43.5807 25.2201C43.6507 25.3301 43.7407 25.4101 43.8607 25.4801C43.9407 25.5101 44.0207 25.5501 44.1007 25.5801C44.2107 25.6101 44.3207 25.6101 44.4307 25.6101C44.4907 25.6801 44.5507 25.7501 44.6407 25.8001C44.7507 25.8701 44.8807 25.9101 45.0107 25.9201C45.1407 25.9501 45.2807 25.9401 45.4107 25.9001C45.4907 25.8701 45.5707 25.8301 45.6507 25.8001C45.8007 25.7101 45.9207 25.5901 46.0107 25.4401C46.0407 25.3601 46.0807 25.2801 46.1107 25.2001C46.1607 25.0201 46.1607 24.8501 46.1107 24.6701H46.1007Z'
          fill='#BF7760'
        />
        <Path
          d='M21.5 62.1501C22.23 59.2301 27.54 41.9301 32.14 37.5501C36.73 33.1701 43.43 30.8601 46.41 32.4301C51.49 35.1101 54.15 44.1201 54.15 55.3201C54.15 66.5201 21.51 62.1401 21.51 62.1401L21.5 62.1501Z'
          fill='#FFAF9B'
        />
        <Path
          d='M20.0798 29.46L19.7698 34.59C19.7698 34.59 15.2898 35.74 13.2598 33.7L13.6098 27.5L20.0898 29.45L20.0798 29.46Z'
          fill='#EBA06F'
        />
        <Path
          d='M11.9604 31.52C7.73039 33.1 4.91039 37.14 3.25039 41.34C2.62039 42.93 2.19039 44.76 2.13039 46.83C1.89039 55.11 30.7904 61.93 30.7904 61.93C30.7904 61.93 30.3104 42.93 27.6604 37.81C25.0104 32.69 22.2604 32.67 18.9404 32.5C16.0604 32.74 13.1504 31.07 11.9604 31.52Z'
          fill='#5DAD3E'
        />
        <Path
          d='M14.69 17.3401L24.5 18.5701C24.5 18.5701 24.5 27.9501 22.74 29.4101C20.09 31.9301 15.09 29.8201 13.59 27.8401C12.08 25.8701 13.94 18.0701 14.69 17.3301V17.3401Z'
          fill='#EBA06F'
        />
        <Path
          d='M21.0399 24.52C20.9999 24.33 20.9499 24.15 20.9199 23.96C20.9199 23.96 20.8999 23.8 20.9099 23.88C20.9099 23.84 20.9099 23.81 20.9099 23.77C20.9099 23.72 20.9099 23.66 20.9099 23.61C20.9099 23.59 20.9199 23.45 20.9099 23.53C20.9399 23.27 20.8599 22.98 20.6199 22.91C20.4199 22.86 20.1499 22.98 20.1099 23.26C20.0599 23.65 20.0899 24.03 20.1699 24.41C20.1999 24.57 20.2399 24.73 20.2799 24.89C20.2999 24.97 20.3199 25.05 20.3299 25.13C20.3299 25.17 20.3399 25.21 20.3499 25.25C20.3499 25.25 20.3499 25.29 20.3499 25.31C20.3499 25.38 20.3499 25.44 20.3499 25.51C20.3499 25.51 20.3499 25.54 20.3499 25.56C20.3499 25.58 20.3399 25.63 20.3399 25.64C20.3399 25.65 20.3399 25.66 20.3399 25.67C20.3399 25.69 20.3199 25.71 20.3199 25.72C20.3199 25.72 20.2899 25.76 20.2899 25.77C20.2899 25.77 20.2899 25.78 20.2799 25.79C20.2399 25.83 20.1899 25.86 20.1499 25.88C20.1499 25.88 20.1499 25.88 20.1299 25.88C20.0899 25.89 20.0599 25.9 20.0199 25.91C20.0099 25.91 20.0099 25.91 20.0099 25.91C19.9699 25.91 19.9399 25.91 19.8999 25.91C19.8299 25.91 19.7499 25.91 19.6799 25.9C19.4599 25.88 19.2599 26.15 19.2699 26.4C19.2799 26.69 19.4499 26.88 19.6799 26.9C20.0099 26.94 20.3699 26.89 20.6599 26.67C20.9799 26.44 21.1599 26.06 21.1999 25.62C21.2299 25.25 21.1499 24.87 21.0699 24.51L21.0399 24.52Z'
          fill='#CD7A56'
        />
        <Path
          d='M18.5598 22.81C18.5598 22.81 18.5598 22.76 18.5498 22.74C18.5198 22.62 18.4598 22.52 18.3698 22.43C18.3398 22.4 18.2998 22.37 18.2598 22.34C18.2598 22.34 18.2598 22.34 18.2498 22.34C18.2198 22.33 18.1498 22.3 18.1198 22.29C18.1198 22.29 18.1198 22.29 18.1098 22.29C18.0898 22.29 17.9898 22.27 17.9698 22.27C17.9398 22.27 17.9298 22.27 17.8898 22.27C17.8598 22.27 17.8398 22.27 17.8098 22.28C17.6098 22.33 17.4698 22.5 17.4098 22.7C17.3898 22.75 17.3798 22.81 17.3698 22.86C17.3598 22.93 17.3498 23 17.3398 23.06C17.3398 23.11 17.3398 23.16 17.3498 23.2C17.3698 23.31 17.3898 23.42 17.4598 23.51C17.5298 23.62 17.6198 23.72 17.7398 23.78C17.7998 23.82 17.8598 23.84 17.9298 23.84C17.9998 23.85 18.0598 23.85 18.1298 23.84C18.1998 23.82 18.2498 23.79 18.2998 23.75C18.3498 23.71 18.3998 23.67 18.4298 23.61C18.4298 23.61 18.4298 23.59 18.4398 23.58C18.4498 23.57 18.4698 23.56 18.4798 23.54C18.5098 23.48 18.5398 23.42 18.5598 23.36C18.5698 23.32 18.5798 23.27 18.5798 23.22C18.5798 23.15 18.5998 23.08 18.5798 23.01C18.5798 22.94 18.5598 22.88 18.5498 22.81H18.5598Z'
          fill='#CD7A56'
        />
        <Path
          d='M22.8502 23.3801C22.8502 23.3801 22.8502 23.2801 22.8302 23.2401C22.8302 23.2401 22.8302 23.2301 22.8302 23.2201C22.8102 23.1801 22.8002 23.1301 22.7702 23.0901C22.7502 23.0501 22.7202 23.0101 22.6802 22.9801C22.6702 22.9701 22.5902 22.9101 22.5802 22.9001C22.5402 22.8701 22.5002 22.8601 22.4502 22.8401C22.4302 22.8401 22.4202 22.8401 22.3802 22.8301C22.3802 22.8301 22.3302 22.8301 22.3102 22.8301C22.2902 22.8301 22.2402 22.8301 22.2302 22.8301C22.2102 22.8301 22.1802 22.8301 22.1602 22.8401C22.1402 22.8401 22.0702 22.8801 22.0902 22.8701C22.0602 22.8801 22.0502 22.8901 22.0302 22.9001C21.9902 22.9201 21.9502 22.9501 21.9202 22.9901C21.8902 23.0201 21.8602 23.0601 21.8402 23.1001C21.8402 23.1001 21.8402 23.1101 21.8302 23.1201C21.8102 23.1601 21.7902 23.2101 21.7702 23.2501C21.7702 23.2701 21.7602 23.2901 21.7602 23.3101C21.7602 23.3301 21.7602 23.3501 21.7602 23.3701C21.7602 23.4301 21.7402 23.4901 21.7402 23.5601C21.7402 23.6301 21.7402 23.6901 21.7502 23.7601C21.7502 23.8301 21.7702 23.8901 21.7902 23.9501C21.8102 24.0201 21.8402 24.0701 21.8802 24.1201C21.9202 24.1701 21.9602 24.2201 22.0202 24.2501C22.0802 24.2901 22.1402 24.3101 22.2102 24.3101C22.2802 24.3201 22.3402 24.3201 22.4102 24.3101C22.4502 24.2901 22.4902 24.2801 22.5302 24.2601C22.5502 24.2501 22.5602 24.2301 22.5702 24.2201C22.6102 24.2001 22.6602 24.1801 22.7002 24.1501C22.7302 24.1201 22.7502 24.0801 22.7802 24.0501C22.8302 23.9701 22.8502 23.8901 22.8502 23.8001C22.8602 23.7201 22.8702 23.6301 22.8802 23.5501C22.8802 23.5101 22.8902 23.4801 22.8902 23.4401C22.8902 23.4401 22.8902 23.4301 22.8902 23.4201L22.8502 23.3801Z'
          fill='#CD7A56'
        />
        <Path
          d='M23.7498 21.4299C23.2098 21.1799 22.6098 20.9999 21.9798 20.9099C21.7198 20.8699 21.4498 20.9699 21.4398 21.1299C21.4298 21.2899 21.6198 21.4499 21.8898 21.4899C22.0098 21.5099 22.1298 21.5299 22.2498 21.5599C22.5298 21.6299 22.7998 21.7099 23.0598 21.8099C23.0998 21.8299 23.1398 21.8399 23.1798 21.8599C23.2998 21.9099 23.4198 21.9499 23.5598 21.9399C23.6798 21.9399 23.8098 21.8999 23.8798 21.8499C24.0198 21.7399 23.9998 21.5299 23.7598 21.4299H23.7498Z'
          fill='#CD7A56'
        />
        <Path
          d='M19.2507 27.9802C19.2507 27.9802 19.1907 27.9602 19.1807 27.9602H19.1607C19.1007 27.9402 19.0407 27.9202 18.9907 27.9002C18.8707 27.8602 18.7607 27.8102 18.6507 27.7602C18.5907 27.7302 18.5407 27.7102 18.4907 27.6802C18.4707 27.6802 18.4507 27.6602 18.4507 27.6602C18.4407 27.6602 18.4207 27.6502 18.4107 27.6402C18.3007 27.5802 18.2007 27.5102 18.0907 27.4502C17.9907 27.3902 17.9007 27.3202 17.8107 27.2502C17.8107 27.2502 17.7907 27.2402 17.7807 27.2302C17.7807 27.2302 17.7507 27.2002 17.7407 27.2002C17.7007 27.1602 17.6607 27.1302 17.6207 27.0902C17.5407 27.0202 17.4707 26.9402 17.4007 26.8602C17.3707 26.8202 17.3307 26.7802 17.3007 26.7402C17.3007 26.7402 17.3007 26.7402 17.3007 26.7302C17.2807 26.7102 17.2707 26.6802 17.2507 26.6602C17.1307 26.4902 16.8707 26.3502 16.6107 26.4002C16.3907 26.4402 16.2107 26.6202 16.3407 26.8002C16.9107 27.6302 17.9107 28.2902 19.1207 28.6502C19.3707 28.7302 19.6507 28.6002 19.6807 28.4402C19.7107 28.2502 19.5307 28.0902 19.2607 28.0102L19.2507 27.9802Z'
          fill='#CD7A56'
        />
        <Path
          d='M18.3306 20.6599C18.2506 20.6199 18.1406 20.5799 18.0106 20.5899C17.8606 20.6099 17.7106 20.6299 17.5606 20.6599C17.4106 20.6899 17.2606 20.7399 17.1106 20.7899C16.8606 20.8699 16.6206 20.9899 16.4206 21.1099C16.3206 21.1699 16.2506 21.2399 16.2306 21.3199C16.2106 21.3899 16.2406 21.4799 16.3206 21.5199C16.4006 21.5599 16.5106 21.5999 16.6406 21.5899C16.7706 21.5899 16.9106 21.5499 17.0106 21.4799C17.0806 21.4399 17.1506 21.3999 17.2306 21.3599C17.3406 21.3099 17.4506 21.2699 17.5706 21.2299C17.7006 21.1999 17.8306 21.1699 17.9706 21.1499C17.9306 21.1499 17.9006 21.1499 17.8606 21.1599C17.9306 21.1599 17.9906 21.1499 18.0506 21.1199C18.1106 21.0999 18.1706 21.0799 18.2306 21.0499C18.3206 20.9999 18.4106 20.9099 18.4206 20.8399C18.4306 20.7599 18.4206 20.6899 18.3306 20.6399V20.6599Z'
          fill='#CD7A56'
        />
        <Path
          d='M38.9309 27.33C38.8609 27.07 38.6409 26.91 38.4009 26.98C38.3709 26.98 38.3509 26.99 38.3209 27C38.2709 27 38.2209 27 38.1709 27C38.1709 27 38.1709 27 38.1509 27C38.1209 27 38.0909 26.98 38.0709 26.97C38.0509 26.97 38.0309 26.95 38.0109 26.94C38.1009 26.97 38.0109 26.94 37.9909 26.93C37.9509 26.9 37.9009 26.86 37.8609 26.84C37.8409 26.82 37.8209 26.8 37.8009 26.77C37.7809 26.75 37.7609 26.72 37.7409 26.7C37.7209 26.64 37.6809 26.58 37.6509 26.53C37.6509 26.5 37.6409 26.48 37.6309 26.45C37.6309 26.45 37.6109 26.33 37.6109 26.35C37.6109 26.38 37.6109 26.26 37.6109 26.25C37.6009 26.06 37.5909 25.86 37.5909 25.67C37.5909 25.28 37.5909 24.88 37.6209 24.49C37.6409 24.23 37.4109 23.98 37.1909 23.99C36.9409 24 36.7809 24.21 36.7609 24.49C36.7309 24.94 36.7209 25.39 36.7409 25.85C36.7509 26.3 36.7609 26.76 36.9709 27.16C37.3209 27.83 38.0209 28.18 38.6709 27.99C38.8909 27.93 39.0409 27.63 38.9709 27.37L38.9309 27.33Z'
          fill='#99553F'
        />
        <Path
          d='M40.3296 23.46C40.3296 23.4 40.3196 23.35 40.3096 23.29C40.2996 23.23 40.2696 23.17 40.2396 23.12C40.1896 23.01 40.0796 22.91 39.9796 22.85C39.9296 22.82 39.8696 22.79 39.8096 22.78C39.7996 22.78 39.7896 22.78 39.7796 22.78C39.7296 22.78 39.6696 22.76 39.6196 22.76C39.4496 22.76 39.2896 22.82 39.1696 22.94C39.0396 23.06 38.9596 23.25 38.9796 23.43C38.9796 23.51 38.9996 23.58 39.0196 23.65C39.0396 23.69 39.0496 23.73 39.0696 23.77C39.0796 23.81 39.0996 23.84 39.1096 23.88C39.1396 23.94 39.1596 24.02 39.1996 24.08C39.2696 24.18 39.3396 24.26 39.4396 24.31C39.5796 24.38 39.7296 24.41 39.8896 24.37C39.9896 24.34 40.0896 24.29 40.1696 24.21C40.2296 24.15 40.2896 24.05 40.3096 23.97C40.3296 23.87 40.3296 23.81 40.3096 23.73C40.3096 23.68 40.3296 23.63 40.3296 23.58C40.3296 23.55 40.3296 23.51 40.3296 23.48V23.46Z'
          fill='#99553F'
        />
        <Path
          d='M35.5408 24.5999C35.5408 24.5099 35.5308 24.4299 35.5108 24.3399C35.5108 24.2899 35.4908 24.2499 35.4708 24.2099C35.4408 24.1299 35.4108 24.0499 35.3608 23.9799C35.3208 23.8999 35.2608 23.8499 35.1808 23.7999C35.1208 23.7599 35.0608 23.7399 34.9908 23.7399C34.9208 23.7299 34.8608 23.7299 34.7908 23.7399C34.7108 23.7599 34.6308 23.8099 34.5708 23.8699C34.4608 23.9899 34.3908 24.1099 34.3408 24.2699C34.3108 24.3799 34.2908 24.4999 34.3108 24.6099C34.3108 24.6899 34.3308 24.7699 34.3508 24.8399C34.3708 24.9099 34.4008 24.9799 34.4408 25.0499C34.4808 25.1199 34.5308 25.1799 34.5708 25.2299C34.6208 25.2899 34.6908 25.3399 34.7508 25.3799C34.8308 25.4299 34.9108 25.4499 35.0008 25.4499C35.0708 25.4499 35.1308 25.4399 35.1908 25.4099C35.2508 25.3899 35.3108 25.3599 35.3508 25.3099C35.4108 25.2499 35.4508 25.1699 35.4808 25.0899C35.5108 24.9999 35.5308 24.8999 35.5308 24.8099C35.5308 24.7799 35.5308 24.7599 35.5208 24.7299C35.5208 24.6899 35.5308 24.6499 35.5308 24.5999H35.5408Z'
          fill='#99553F'
        />
        <Path
          d='M40.8716 21.64C40.2616 21.46 39.5916 21.4 38.9516 21.45C38.6916 21.47 38.5116 21.66 38.5816 21.82C38.6516 22 38.9016 22.11 39.1816 22.08C39.2416 22.08 39.2916 22.08 39.3516 22.08C39.5816 22.08 39.8116 22.09 40.0316 22.12C40.2116 22.15 40.3916 22.19 40.5616 22.24C40.8016 22.31 41.1216 22.21 41.1916 22.06C41.2716 21.88 41.1316 21.72 40.8716 21.64Z'
          fill='#99553F'
        />
        <Path
          d='M35.1416 22.2352C34.5206 22.3724 33.905 22.6436 33.3689 22.9966C33.1509 23.1398 33.0851 23.3931 33.2237 23.4994C33.372 23.6231 33.644 23.5986 33.8747 23.4371C33.9272 23.4081 33.971 23.3839 34.0235 23.3549C34.2249 23.2438 34.4311 23.1414 34.6382 23.0613C34.8102 23.0006 34.9872 22.9486 35.1602 22.9103C35.4041 22.8556 35.6359 22.6134 35.6247 22.4482C35.6078 22.252 35.4079 22.1796 35.1416 22.2352Z'
          fill='#99553F'
        />
        <Path
          d='M40.9796 27.9C40.7096 27.81 40.4696 27.92 40.3196 28.13C40.3196 28.15 40.2796 28.18 40.2696 28.2C40.2396 28.24 40.1996 28.28 40.1696 28.33C40.0996 28.41 40.0296 28.48 39.9596 28.55C39.9396 28.57 39.9196 28.59 39.8996 28.6C39.8596 28.63 39.8096 28.67 39.7696 28.7C39.6796 28.77 39.5896 28.83 39.4896 28.89C39.4496 28.92 39.3996 28.94 39.3596 28.96C39.3596 28.96 39.3396 28.96 39.3296 28.97C39.3096 28.97 39.2996 28.99 39.2796 28.99C39.1796 29.04 39.0696 29.08 38.9696 29.12C38.8696 29.16 38.7696 29.18 38.6696 29.21C38.6496 29.21 38.6196 29.22 38.5996 29.22C38.5396 29.23 38.4696 29.24 38.4096 29.25C38.1496 29.28 37.9196 29.51 37.9596 29.75C37.9996 29.98 38.2296 30.17 38.5096 30.14C39.6496 29.99 40.6396 29.37 41.2296 28.48C41.3696 28.28 41.2096 27.97 40.9796 27.89V27.9Z'
          fill='#99553F'
        />
        <Path
          d='M21.8802 15.33C20.8102 14.98 19.6402 14.84 18.4902 14.9C18.3902 14.83 18.2902 14.76 18.1802 14.71C17.2902 14.33 16.2102 14.56 15.3402 14.9C14.5602 15.21 13.8502 15.66 13.2702 16.23C13.0902 16.39 12.9302 16.57 12.7702 16.76C12.7702 16.76 12.7702 16.77 12.7602 16.78C12.5102 17.09 12.3002 17.43 12.1302 17.79C12.0802 17.84 12.0402 17.9 12.0102 17.97C11.9502 18.12 11.9102 18.26 11.8602 18.41C11.8102 18.55 11.7602 18.68 11.7102 18.82C11.1702 20.56 10.7702 22.82 11.3402 24.54C11.6002 25.34 12.2502 27.86 13.1002 28.17C14.5002 28.76 13.9402 25.59 14.0302 24.43C14.0702 23.96 14.3802 23.54 14.4402 23.08C14.5002 22.6 14.5302 22.11 14.5602 21.63C14.5702 21.42 14.5802 21.22 14.6002 21.01C14.6002 20.99 14.6002 20.99 14.6002 20.97C14.6002 20.91 14.6102 20.86 14.6202 20.8C14.6302 20.71 14.6402 20.61 14.6602 20.52C14.6902 20.3 14.7302 20.09 14.7902 19.88C14.8102 19.79 14.8402 19.7 14.8702 19.61C14.8702 19.59 14.8702 19.59 14.8802 19.58C16.5602 20.05 18.3102 20.29 20.0602 20.27C20.9302 20.26 21.8102 20.19 22.6702 20.04C23.4902 19.9 24.3402 19.59 24.7502 18.83C25.7402 17 23.3202 15.82 21.8702 15.34L21.8802 15.33Z'
          fill='#99553F'
        />
        <Path
          d='M15.5313 23.78C15.4313 23.3 15.1413 22.82 14.6913 22.6C14.4313 22.47 14.1513 22.41 13.8613 22.45C13.5913 22.49 13.3713 22.6 13.1513 22.77C12.9013 22.96 12.7013 23.15 12.5813 23.45C12.4913 23.68 12.4813 23.93 12.5113 24.17C12.5713 24.65 12.8113 25.08 13.1213 25.44C13.4113 25.77 13.8313 26.04 14.2613 26.14C14.5813 26.21 14.9413 26.22 15.1613 25.93C15.3313 25.71 15.3313 25.41 15.3513 25.15C15.3613 24.88 15.3413 24.61 15.2913 24.34C15.4813 24.22 15.5713 24.02 15.5213 23.79L15.5313 23.78Z'
          fill='#EBA06F'
        />
      </G>
    </Svg>
  )
}

import React, { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import { useTheme } from 'styled-components/native'

import {
  ContactCareNavigatorConsolidatedPage as ContactCareNavigatorConsolidatedPageComponent,
  Layout,
  Modal,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { getIsUserLoggedIn } from '../../../data/user/userSelectors'
import ChatLive from '../chatLive/ChatLive'

export interface ContactCareNavigatorModalProps {
  isModalOpen: boolean
  isInternational: boolean
  isMobile: boolean
  isLyrian: boolean
  showLiveChat: boolean
  startLiveChat?: boolean
  showScheduleCall?: boolean
  customerPhoneNumber?: string
  useWebPortalForBottomSheet?: boolean
  isHomebaseFlow?: boolean
  setIsModalOpen: (newValue: boolean) => void
  onPressEmail: () => void
  onPressFAQ: () => void
  onPressPhoneCall: () => void
  onPressScheduleCall: () => void
  onPressLiveChat: () => void
  onExit: () => void
  careNavigatorHeadshot?: string
  careNavigatorLicense?: string
  careNavigatorName?: string
  showMinimalCTAText?: boolean
}

export const ContactCareNavigatorModal: React.FC<ContactCareNavigatorModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  isInternational,
  customerPhoneNumber,
  onPressEmail,
  onPressFAQ,
  onPressPhoneCall,
  onPressScheduleCall,
  onPressLiveChat,
  onExit,
  isMobile,
  isLyrian,
  showLiveChat,
  startLiveChat,
  showScheduleCall,
  useWebPortalForBottomSheet,
  isHomebaseFlow = false,
  careNavigatorHeadshot,
  careNavigatorLicense,
  careNavigatorName,
  showMinimalCTAText,
}) => {
  const { colors } = useTheme()
  const isLoggedIn = useSelector(getIsUserLoggedIn)

  // Initialize bottom sheet
  const bottomSheetRef = useRef<BottomSheetGorhom>(null)
  const snapHeight = useRef(Layout.window.height).current * 0.9
  const openBottomSheet = useCallback(() => {
    bottomSheetRef.current?.expand()
  }, [])
  const closeBottomSheet = useCallback(() => {
    bottomSheetRef?.current?.close()
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const snapPoints = isMobile ? [snapHeight] : [452]

  return (
    <>
      <Modal
        modalContents={
          <ContactCareNavigatorConsolidatedPageComponent
            customerPhoneNumber={customerPhoneNumber}
            isInternational={isInternational}
            onPressEmail={onPressEmail}
            onPressFAQ={onPressFAQ}
            onPressPhoneCall={onPressPhoneCall}
            onPressScheduleCall={onPressScheduleCall}
            onPressLiveChat={onPressLiveChat}
            onExit={onExit}
            useSidePanelStyle={true}
            isLyrianFlow={isLyrian}
            isHomebaseFlow={isHomebaseFlow}
            showLiveChat={showLiveChat}
            showScheduleCall={showScheduleCall}
            hideCustomerPhoneNumber={!isLoggedIn}
            careNavigatorHeadshot={careNavigatorHeadshot}
            careNavigatorLicense={careNavigatorLicense}
            careNavigatorName={careNavigatorName}
            showMinimalCTAText={showMinimalCTAText}
          />
        }
        onRequestClose={onExit}
        visible={isModalOpen}
        bottomSheetRef={bottomSheetRef}
        snapPoints={snapPoints}
        onCloseEnd={() => {
          onExit()
          closeBottomSheet()
        }}
        onLayout={openBottomSheet}
        isSidePanel={!isMobile}
        scrollable
        useWebPortalForBottomSheet={useWebPortalForBottomSheet}
        backgroundColor={colors.backgroundSecondary}
      />

      {showLiveChat && <ChatLive hideButton startChat={startLiveChat} />}
    </>
  )
}

export default ContactCareNavigatorModal

import { useMemo } from 'react'

import { CustomerPropertiesMap } from '../../../models'
import {
  CoachingToolkitAvailabilityInfo,
  useForkedTriageAvailability,
} from '../../common/hooks/useForkedTriageAvailability'
import { PathwayOptions, TEEN_ENABLED_PATHWAYS } from '../../pathways/constants'

export const useShouldShowForkedTriageEntrypoint = ({
  isUserTeen,
  customerPropertiesMap,
  coachingToolkitAvailability,
}: {
  isUserTeen: boolean
  customerPropertiesMap?: CustomerPropertiesMap
  coachingToolkitAvailability?: CoachingToolkitAvailabilityInfo
}) => {
  // if showMultiCTAPathways is true then there are more than 3 forked triage options available
  const { showMultiCTAPathways } = useForkedTriageAvailability({
    customerPropertiesMap,
    coachingToolkitAvailability,
  })
  return useMemo(() => {
    // Essentials is a pathway option that does NOT have an entrypoint in Forked Triage
    return isUserTeen
      ? TEEN_ENABLED_PATHWAYS.filter((option) => option !== PathwayOptions.ESSENTIALS).length > 1
      : showMultiCTAPathways
  }, [isUserTeen, showMultiCTAPathways])
}

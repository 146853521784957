import { fromJS, Map } from 'immutable'

import { VimeoFile, VimeoResponse } from '@lyrahealth-inc/shared-app-logic'

import {
  ADD_ASSIGNMENT_RESPONSE,
  CLEAR_ASSIGNMENT,
  DELETE_ASSIGNMENT_DRAFT,
  GET_ASSIGNMENT,
  GET_ASSIGNMENTS,
  GET_VIDEO_LINKS,
  PATCH_ASSIGNMENT_RESPONSE,
  SELECT_ASSIGNMENT,
  SET_ASSIGNMENT_STATUS,
  UPDATE_ASSIGNMENT,
  UPDATE_ASSIGNMENT_RESPONSE,
  UPDATE_SELECTED_SESSION,
} from './assignmentsConstants'

const assignmentsReducers = {
  [ADD_ASSIGNMENT_RESPONSE]: ($$state: Map<string, any>, action: AssignmentReducerAction) => {
    const updatedAssignmentIdx = $$state
      .getIn(['assignments', 'data'])
      .findIndex(($$assignment: Map<string, any>) => $$assignment.get('id') === action?.data?.assignment_id)
    const $$assignmentResponses = $$state.getIn(['assignments', 'data', updatedAssignmentIdx, 'assignment_responses'])
    $$state = $$state.setIn(
      ['assignments', 'data', updatedAssignmentIdx, 'assignment_responses'],
      $$assignmentResponses.push(fromJS(action.data)),
    )

    // Update Counter
    const responseType = action?.data?.status === 'draft' ? 'draft' : 'response'
    const responseCount = $$state.getIn(['assignments', 'data', updatedAssignmentIdx, `${responseType}_count`])
    $$state = $$state.setIn(['assignments', 'data', updatedAssignmentIdx, `${responseType}_count`], responseCount + 1)

    return $$state
  },
  [UPDATE_ASSIGNMENT_RESPONSE]: ($$state: Map<string, any>, action: AssignmentReducerAction) => {
    let responsePrevStatus
    const updatedAssignmentIdx = $$state
      .getIn(['assignments', 'data'])
      .findIndex(($$assignment: Map<string, any>) => $$assignment.get('id') === action?.data?.assignment_id)
    const $$assignmentResponses = $$state.getIn(['assignments', 'data', updatedAssignmentIdx, 'assignment_responses'])
    const $$updatedResponses = $$assignmentResponses.map((response: Map<string, any>) => {
      if (response.get('id') === action?.data?.id) {
        responsePrevStatus = response.get('status')
        return fromJS(action.data)
      }
      return response
    })
    $$state = $$state.setIn(['assignments', 'data', updatedAssignmentIdx, 'assignment_responses'], $$updatedResponses)

    // Update Counters
    if (responsePrevStatus === 'draft' && action?.data?.status === 'completed') {
      const draftCount = $$state.getIn(['assignments', 'data', updatedAssignmentIdx, 'draft_count'])
      const responseCount = $$state.getIn(['assignments', 'data', updatedAssignmentIdx, 'response_count'])
      $$state = $$state.setIn(['assignments', 'data', updatedAssignmentIdx, 'draft_count'], draftCount - 1)
      $$state = $$state.setIn(['assignments', 'data', updatedAssignmentIdx, 'response_count'], responseCount + 1)
    }

    return $$state
  },
  [PATCH_ASSIGNMENT_RESPONSE]: ($$state: Map<string, any>, action: AssignmentReducerAction) => {
    const updatedAssignmentIdx = $$state
      .getIn(['assignments', 'data'])
      .findIndex(($$assignment: Map<string, any>) => $$assignment.get('id') === action?.data?.assignment_id)
    const $$assignmentResponses = $$state.getIn(['assignments', 'data', updatedAssignmentIdx, 'assignment_responses'])
    const $$updatedResponses = $$assignmentResponses.map((response: Map<string, any>) => {
      if (response.get('id') === action?.data?.id) {
        return fromJS(action.data)
      }
      return response
    })
    $$state = $$state.setIn(['assignments', 'data', updatedAssignmentIdx, 'assignment_responses'], $$updatedResponses)
    return $$state
  },
  [DELETE_ASSIGNMENT_DRAFT]: ($$state: Map<string, any>, action: AssignmentReducerAction) => {
    const updatedAssignmentIdx = $$state
      .getIn(['assignments', 'data'])
      .findIndex(($$assignment: Map<string, any>) => $$assignment.get('id') === action?.data?.assignmentId)
    const $$assignmentResponses = $$state.getIn(['assignments', 'data', updatedAssignmentIdx, 'assignment_responses'])
    const $$updatedResponses = $$assignmentResponses?.filter(
      ($$response: Map<string, any>) => $$response.get('id') !== action?.data?.responseId,
    )
    $$state = $$state.setIn(['assignments', 'data', updatedAssignmentIdx, 'assignment_responses'], $$updatedResponses)

    // Update Counter
    const draftCount = $$state.getIn(['assignments', 'data', updatedAssignmentIdx, 'draft_count'])
    $$state = $$state.setIn(['assignments', 'data', updatedAssignmentIdx, 'draft_count'], draftCount - 1)
    return $$state
  },
  [SELECT_ASSIGNMENT]: ($$state: Map<string, any>, action: AssignmentReducerAction) => {
    const assignmentIdx = $$state
      .getIn(['assignments', 'data'])
      .findIndex(($$assignment: Map<string, any>) => $$assignment.get('id') === action?.data?.id)
    $$state = $$state.setIn(['assignments', 'selectedIndex'], assignmentIdx)
    return $$state
  },
  [SET_ASSIGNMENT_STATUS]: ($$state: Map<string, any>, action: AssignmentReducerAction) => {
    const updatedAssignmentIdx = $$state
      .getIn(['assignments', 'data'])
      .findIndex(($$assignment: Map<string, any>) => $$assignment.get('id') === action?.assignment?.id)
    $$state = $$state.setIn(['assignments', 'data', updatedAssignmentIdx, 'status'], action.status)
    return $$state
  },
  [GET_ASSIGNMENT]: ($$state: Map<string, any>, action: AssignmentReducerAction) => {
    const assignmentIdx = $$state
      .getIn(['assignments', 'data'])
      .findIndex(($$assignment: Map<string, any>) => $$assignment.get('id') === action?.data?.id)
    const $$assignment = fromJS(action.data)
    if (assignmentIdx === -1) {
      const $$assignments = $$state.getIn(['assignments', 'data']).push($$assignment)
      $$state = $$state.setIn(['assignments', 'data'], $$assignments)
    } else {
      $$state = $$state.setIn(['assignments', 'data', assignmentIdx], $$assignment)
    }
    return $$state
  },
  [GET_ASSIGNMENTS]: ($$state: Map<string, any>, action: AssignmentReducerAction) => {
    $$state = $$state.setIn(['assignments', 'data'], fromJS(action.data))
    return $$state
  },
  [GET_VIDEO_LINKS]: ($$state: Map<string, any>, action: AssignmentReducerAction) => {
    const videoLinksJson: VimeoResponse['data'] | undefined = action?.data?.data
    const links = videoLinksJson?.reduce(
      (idsToLinks, { uri, files, duration }: { uri: string; files: VimeoFile[]; duration: number }) => {
        idsToLinks[uri] = {
          files,
          duration,
        }
        return idsToLinks
      },
      {},
    )
    $$state = $$state.setIn(['assignments', 'videoLinks'], fromJS(links))
    return $$state
  },
  [UPDATE_ASSIGNMENT]: ($$state: Map<string, any>, action: AssignmentReducerAction) => {
    const $$updatedAssignments = $$state.getIn(['assignments', 'data']).map(($$assignment: Map<string, any>) => {
      if ($$assignment.get('id') === action?.data?.id) return fromJS(action.data)
      return $$assignment
    })
    $$state = $$state.setIn(['assignments', 'data'], $$updatedAssignments)
    return $$state
  },
  [CLEAR_ASSIGNMENT]: ($$state: Map<string, any>) => {
    $$state = $$state.setIn(['assignments', 'selectedIndex'], fromJS(undefined))
    return $$state
  },
  [UPDATE_SELECTED_SESSION]: ($$state: Map<string, any>, action: AssignmentReducerAction) => {
    $$state = $$state.set('selectedSession', fromJS(action.data))
    return $$state
  },
}

export default assignmentsReducers

type AssignmentReducerAction = {
  data?: {
    assignmentId?: string
    assignment_id?: string
    status?: string
    id?: string
    responseId?: string
    data?: VimeoResponse['data']
  }
  assignment?: {
    id: string
  }
  status?: string
}

import { fromJS, Map } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { SET_CUSTOMER_COPY, SET_CUSTOMER_INFO } from './customerActions'
import { DEFAULT_CUSTOMER } from '../../common/constants/appConstants'
import customers from '../../common/constants/customerConstants'
import { generateCustomerCopyWithProperties } from '../../common/utils/customerUtils'

const $$initialState = fromJS({
  allCustomerProperties: [],
  // get rid of the below customerPropertiesMap object during the clean up, as this is not necessary anymore.
  customerPropertiesMap: {
    email: '',
    enabledChat: 'false',
    hasLogo: '',
    mystrength_accesscode: '',
    phone: '',
    videoTherapy: '',
    worklife: '',
  },
  healthPlansList: [],
  customerType: '',
  info: '',
  name: '',
  isLoaded: false,
})

export default createReducer($$initialState, {
  [SET_CUSTOMER_COPY]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const customer = customers.get(action.customerName, Map())
    const customerInfo = $$state.get('customerPropertiesMap', Map()).toJS()
    const customerSpecificCopy = generateCustomerCopyWithProperties({
      copy: customer.get('copy', Map()),
      customerInfo,
    })

    const templateWithCustomerSpecificCopy = generateCustomerCopyWithProperties({
      copy: customer.get('template', Map()),
      customerInfo,
    })

    const defaultCustomerCopy = generateCustomerCopyWithProperties({
      copy: customers.getIn([DEFAULT_CUSTOMER, 'copy'], Map()),
      customerInfo,
    })

    const customerCopy = defaultCustomerCopy.merge(templateWithCustomerSpecificCopy).merge(customerSpecificCopy)
    $$state = $$state.set('copies', customerCopy)
    return $$state
  },
  [SET_CUSTOMER_INFO]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const customerInfo = fromJS(action.val)
    $$state = $$state.merge(customerInfo, fromJS({ isLoaded: true }))
    return $$state
  },
})

import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import {
  handleSubmitSubpopulationRedirection,
  showSubpopulationModal,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { IS_WEB, SubpopulationRedirection } from '@lyrahealth-inc/ui-core-crossplatform'

import { setHasVerifiedToNotRedirect } from '../../../../data/appGlobals/appGlobalsActions'
import {
  getCustomerName,
  getSubpopulations,
  getSubpopulations_lctTeens,
} from '../../../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../../../data/mixpanel'

type CustomerSpecificInstanceRedirectionContainerProps = ConnectedProps<typeof connector>

const CustomerSpecificInstanceRedirectionContainer = ({
  hasVerifiedToNotRedirect,
  subpopulations,
  subpopulations_lctTeens,
  customerName,
  setHasVerifiedToNotRedirect,
  trackEventWithObj,
}: CustomerSpecificInstanceRedirectionContainerProps) => {
  const isMicrosite = location.pathname === '/'

  const { customerInstanceMap } = useFlags()
  if (!subpopulations) {
    return null
  }
  if (!showSubpopulationModal(customerInstanceMap, customerName)) {
    return null
  }
  /*
    We render the modal iff the user has not manually verified that they are meant
    to be on this customer and it is either after release time (1/1/22) or the environment
    is set up such that this flag should be enabled.
   */
  const shouldModalBeOpen = !hasVerifiedToNotRedirect

  const handleSubmit = async (newCustomerIndex: number) => {
    handleSubmitSubpopulationRedirection({
      customerInstanceMap,
      customerName,
      newCustomerIndex,
      navigate: (newCustomerName) => {
        if (IS_WEB) {
          if (newCustomerName !== customerName) {
            window.location.href = window.location.href.replace(customerName, newCustomerName)
          } else {
            setHasVerifiedToNotRedirect()
          }
        }
      },
      trackEvent: (event, eventProps) => trackEventWithObj({ event, ...eventProps }),
    })
  }

  return (
    <SubpopulationRedirection
      subpopulations={!!subpopulations_lctTeens ? subpopulations_lctTeens : subpopulations}
      onClose={setHasVerifiedToNotRedirect}
      handleSubmit={handleSubmit}
      useModal
      isModalVisible={shouldModalBeOpen}
      showCloseIcon={!isMicrosite}
      showDelay={isMicrosite ? 5000 : 0}
      closeOnScrim={!isMicrosite}
    />
  )
}

const mapStateToProps = (state: $TSFixMe) => {
  return {
    customerName: getCustomerName(state),
    subpopulations: getSubpopulations(state),
    subpopulations_lctTeens: getSubpopulations_lctTeens(state),
    hasVerifiedToNotRedirect: state.getIn(['appGlobals', 'hasVerifiedToNotRedirect'], false),
  }
}

const connector = connect(mapStateToProps, { setHasVerifiedToNotRedirect, trackEventWithObj })

export default connector(CustomerSpecificInstanceRedirectionContainer)

import { User } from '../../models'

export type UserProfileInfo = {
  displayName: string
  dob?: string
  isChild?: boolean
  firstName?: string
  lastName?: string
  lyraId?: string
}

export const createChildUserInfo = (childUsers: User[], isInternational: boolean): UserProfileInfo[] => {
  return childUsers.map((childUser: User) => {
    const userProfileInfo: UserProfileInfo = {
      firstName: childUser.firstname,
      lastName: childUser.lastname,
      displayName: isInternational
        ? `${childUser.lastname}, ${childUser.firstname}`
        : `${childUser.firstname} ${childUser.lastname}`,
      dob: childUser.dob,
      isChild: true,
      lyraId: childUser.lyraId,
    }
    return userProfileInfo
  })
}

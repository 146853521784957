import { useCallback, useMemo } from 'react'

import { addDays } from 'date-fns'

import { useGetEssentialsArticlesByCategory } from './useGetEssentialsArticlesByCategory'
import { useGetEssentialsCourse } from './useGetEssentialsCourse'
import { getRandomItems, shuffleArray } from '../../common/utils/commonUtils'
import {
  ESSENTIALS_CATEGORY,
  ESSENTIALS_COURSE,
  ESSENTIALS_SUBCATEGORY,
  EssentialsArticleRaw,
  EssentialsCourseRaw,
  OnEssentialsCardPressFunction,
  ToolkitEssentialsContentRaw,
  WELLNESS_TOOLKIT_PROMOTION,
} from '../types'
import { formatEssentialsArticle, formatEssentialsCourse } from '../wellnessToolkitUtil'

type Params = {
  essentialsCategories: ESSENTIALS_CATEGORY[]
  essentialsExcludedSubcategories?: ESSENTIALS_SUBCATEGORY[]
  essentialsCourse?: ESSENTIALS_COURSE
  essentialsCmsUrl: string
  userDisplayLanguage: string
  onEssentialsCardPress: OnEssentialsCardPressFunction
  promotion: WELLNESS_TOOLKIT_PROMOTION
  contentsStringFromStorage: string | null
  saveToStorage: (
    data: { contents: ToolkitEssentialsContentRaw[]; expirationTimestamp: string },
    updateRef?: boolean,
  ) => void
  logError?: (error: Error) => void
}

/**
 * Get 3 essentials contents:
 * - May include a course as defined by `essentialsCourse`
 * - The rest of the content returned will be articles from categories defined by `essentialsCategories`
 * - Results will be shuffled
 * Be sure to memoize the arguments passed to this hook otherwise an infinite loop is likely to occur
 */
export const useToolkitEssentialsContents = ({
  essentialsCategories,
  essentialsExcludedSubcategories,
  essentialsCourse,
  essentialsCmsUrl,
  userDisplayLanguage,
  onEssentialsCardPress,
  promotion,
  contentsStringFromStorage,
  saveToStorage,
  logError,
}: Params) => {
  const getCachedEssentialsContents = useCallback(() => {
    const essentialsData = JSON.parse(contentsStringFromStorage || '{}')

    const essentialsForToolkit = essentialsData[promotion]
    if (essentialsForToolkit) {
      if (Date.now() > essentialsForToolkit.expirationTimestamp) {
        delete essentialsData[promotion]
        saveToStorage(essentialsData, true)
        return null
      }
      return essentialsForToolkit.contents
    }
    return null
  }, [promotion, contentsStringFromStorage, saveToStorage])

  const saveEssentialsContents = useCallback(
    (contents: ToolkitEssentialsContentRaw[]) => {
      const toolkitsEssentials = JSON.parse(contentsStringFromStorage || '{}')
      toolkitsEssentials[promotion] = {
        contents,
        expirationTimestamp: addDays(new Date(), 1).getTime(),
      }
      saveToStorage(toolkitsEssentials)
    },
    [promotion, contentsStringFromStorage, saveToStorage],
  )

  const cachedContents = useMemo(() => {
    return essentialsCategories ? getCachedEssentialsContents() : null
  }, [essentialsCategories, getCachedEssentialsContents])

  const categories = useMemo(() => {
    return cachedContents ? [] : essentialsCategories ?? []
  }, [cachedContents, essentialsCategories])

  const essentialsContentsByCategory = useGetEssentialsArticlesByCategory(
    categories,
    essentialsCmsUrl,
    userDisplayLanguage,
    essentialsExcludedSubcategories,
    logError,
  )

  const essentialsCourseContent = useGetEssentialsCourse(
    essentialsCmsUrl,
    userDisplayLanguage,
    cachedContents ? undefined : essentialsCourse,
    logError,
  )

  const formatContent = useCallback(
    (contentList: ToolkitEssentialsContentRaw[]) => {
      return contentList.map((rawContent) => {
        if (rawContent.hasOwnProperty('foreground')) {
          return formatEssentialsCourse(rawContent as EssentialsCourseRaw, onEssentialsCardPress, userDisplayLanguage)
        } else {
          return formatEssentialsArticle(rawContent as EssentialsArticleRaw, onEssentialsCardPress, userDisplayLanguage)
        }
      })
    },
    [onEssentialsCardPress, userDisplayLanguage],
  )

  return useMemo(() => {
    if (cachedContents) {
      return formatContent(cachedContents)
    }

    if (!essentialsContentsByCategory.length) {
      return []
    }

    let contentList: ToolkitEssentialsContentRaw[] = []
    let numArticles = 3
    if (essentialsCourseContent) {
      numArticles--
      contentList.push(essentialsCourseContent)
    }

    const articles = getRandomItems(essentialsContentsByCategory, numArticles)
    contentList = contentList.concat(articles)

    contentList = shuffleArray(contentList)
    saveEssentialsContents(contentList)
    return formatContent(contentList)
  }, [cachedContents, essentialsContentsByCategory, essentialsCourseContent, formatContent, saveEssentialsContents])
}

import { useEffect, useState } from 'react'

import { useLyraIntl } from '../../intl/hooks/useLyraIntl'
import { findAvailableCareLanguage } from '../../intl/utils/findAvailableCareLanguage'

export const useIsCareLanguageAvailable = (availableCareLanguages: any, selectedCareLanguage?: string) => {
  const { activeLanguage } = useLyraIntl()
  const [isCareLanguageAvailable, setIsCareLanguageAvailable] = useState<boolean>(true)
  const careLanguage = selectedCareLanguage || activeLanguage

  useEffect(() => {
    if (!availableCareLanguages?.length) {
      return
    }
    // We resolve to 'no-match' here in order to determine if the language was resolved to the default.
    // E.g. foundLanguageLocale({activeLanguage:'en-US', availableLanguages: ['en','es-419'], defaultCareLanguage:'en'}) --> 'en'. In this case we
    // can't tell if the function found a match or resolved to default because the result exists in availableLanguages and is equal to the default.
    const noMatchLocale = 'no-match'
    const foundLanguageLocale = findAvailableCareLanguage({
      activeLanguage: careLanguage,
      availableLanguages: availableCareLanguages,
      defaultCareLanguage: noMatchLocale,
    })

    const isLanguageSupported = foundLanguageLocale !== noMatchLocale

    setIsCareLanguageAvailable(Boolean(isLanguageSupported))
  }, [activeLanguage, availableCareLanguages, careLanguage, selectedCareLanguage])

  return isCareLanguageAvailable
}

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export const ChatLiveIcon: FunctionComponent<ChatLiveIconProps> = ({ size = 25, fillColor }) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' testID={tID('ChatLiveIcon')}>
      <Path
        d='M17.5 20.75C17.4015 20.7505 17.3038 20.7312 17.2128 20.6935C17.1218 20.6557 17.0392 20.6001 16.97 20.53L15.19 18.75H8.5C8.31183 18.7412 8.13364 18.6628 8 18.53V18.53L5.19 15.75H4C3.40568 15.7422 2.83789 15.5027 2.4176 15.0824C1.99732 14.6621 1.75777 14.0943 1.75 13.5V5.5C1.75777 4.90568 1.99732 4.33789 2.4176 3.9176C2.83789 3.49732 3.40568 3.25777 4 3.25H18C18.5943 3.25777 19.1621 3.49732 19.5824 3.9176C20.0027 4.33789 20.2422 4.90568 20.25 5.5V7.25H21C21.5943 7.25777 22.1621 7.49732 22.5824 7.9176C23.0027 8.33789 23.2422 8.90568 23.25 9.5V16.5C23.2422 17.0943 23.0027 17.6621 22.5824 18.0824C22.1621 18.5027 21.5943 18.7422 21 18.75H19.81L18.03 20.53C17.9608 20.6001 17.8782 20.6557 17.7872 20.6935C17.6962 20.7312 17.5985 20.7505 17.5 20.75V20.75ZM10.31 17.25H15.5C15.6988 17.2502 15.8895 17.3293 16.03 17.47L17.5 18.94L18.97 17.47C19.1105 17.3293 19.3012 17.2502 19.5 17.25H21C21.1965 17.2426 21.383 17.1612 21.5221 17.0221C21.6612 16.883 21.7426 16.6965 21.75 16.5V9.5C21.7426 9.30346 21.6612 9.11698 21.5221 8.9779C21.383 8.83883 21.1965 8.75742 21 8.75H20.25V13.5C20.2422 14.0943 20.0027 14.6621 19.5824 15.0824C19.1621 15.5027 18.5943 15.7422 18 15.75H11.81L10.31 17.25ZM4 4.75C3.80346 4.75742 3.61698 4.83883 3.4779 4.9779C3.33883 5.11698 3.25742 5.30346 3.25 5.5V13.5C3.25742 13.6965 3.33883 13.883 3.4779 14.0221C3.61698 14.1612 3.80346 14.2426 4 14.25H5.5C5.69885 14.2502 5.88948 14.3293 6.03 14.47L8.5 16.94L10.97 14.47C11.1105 14.3293 11.3012 14.2502 11.5 14.25H18C18.1965 14.2426 18.383 14.1612 18.5221 14.0221C18.6612 13.883 18.7426 13.6965 18.75 13.5V5.5C18.7426 5.30346 18.6612 5.11698 18.5221 4.9779C18.383 4.83883 18.1965 4.75742 18 4.75H4Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 4H18C18.3946 4.01012 18.7703 4.17141 19.0495 4.45055C19.3286 4.72969 19.4899 5.10537 19.5 5.5V13.5C19.4899 13.8946 19.3286 14.2703 19.0495 14.5495C18.7703 14.8286 18.3946 14.9899 18 15H11.5L8.5 18L5.5 15H4C3.60537 14.9899 3.22969 14.8286 2.95055 14.5495C2.67141 14.2703 2.51012 13.8946 2.5 13.5V5.5C2.51012 5.10537 2.67141 4.72969 2.95055 4.45055C3.22969 4.17141 3.60537 4.01012 4 4ZM7.5 9.5C7.5 10.3284 6.82843 11 6 11C5.17157 11 4.5 10.3284 4.5 9.5C4.5 8.67157 5.17157 8 6 8C6.82843 8 7.5 8.67157 7.5 9.5ZM11 11C11.8284 11 12.5 10.3284 12.5 9.5C12.5 8.67157 11.8284 8 11 8C10.1716 8 9.5 8.67157 9.5 9.5C9.5 10.3284 10.1716 11 11 11ZM17.5 9.5C17.5 10.3284 16.8284 11 16 11C15.1716 11 14.5 10.3284 14.5 9.5C14.5 8.67157 15.1716 8 16 8C16.8284 8 17.5 8.67157 17.5 9.5Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

export interface ChatLiveIconProps {
  size?: number
  fillColor?: string
}

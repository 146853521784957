import React, { FunctionComponent, ReactElement, ReactNode, useState } from 'react'
import { View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Divider, PressableOpacity } from '../../atoms'
import { BodyTextSize } from '../../styles'
import { Flex1View } from '../../templates/content/CommonViews'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface CarouselItem {
  title: string | ReactNode
  content: ReactElement
  onTabPress?: () => void
}

export interface InfoCarouselProps {
  items: CarouselItem[]
  testID?: string
}

const CarouselContainer = styled(Flex1View)<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundTransparent,
  justifyContent: 'center',
  alignItems: 'center',
}))

const MenuContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minWidth: '220px',
  marginBottom: theme.spacing['16px'],
}))

const MenuTitle = styled.View<{ theme: ThemeType; hasRightMargin: boolean }>(({ theme, hasRightMargin }) => ({
  ...(hasRightMargin && {
    marginRight: theme.breakpoints.isMinWidthTablet ? theme.spacing['32px'] : theme.spacing['16px'],
  }),
  justifyContent: 'center',
  alignItems: 'center',
}))

const MenuOptionPressable = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMobileSized && { flex: 1 }),
}))

const TitleText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  padding: `0 ${theme.spacing['8px']}`,
  marginBottom: theme.spacing['8px'],
  ...(theme.breakpoints.isMobileSized && { textAlign: 'center' }),
}))

const SelectedItemIndicator = styled(Divider)<{ theme: ThemeType; isSelected?: boolean }>(({ theme }) => ({
  borderTopLeftRadius: theme.spacing['16px'],
  borderTopRightRadius: theme.spacing['16px'],
}))

export const InfoCarousel: FunctionComponent<InfoCarouselProps> = ({ items, testID = 'InfoCarousel' }) => {
  const { colors, breakpoints } = useTheme()
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)
  const carouselMenu = (
    <MenuContainer>
      {items.map(({ title, onTabPress }, index) => (
        <MenuOptionPressable
          key={`InfoCarousel-option${index}`}
          onPress={() => {
            setSelectedItemIndex(index)
            if (onTabPress) {
              onTabPress()
            }
          }}
        >
          <MenuTitle hasRightMargin={index !== items.length - 1}>
            <View>
              <TitleText
                size={breakpoints.isMobileSized ? BodyTextSize.CAPTION : BodyTextSize.DEFAULT}
                color={index === selectedItemIndex ? colors.textActive : colors.textSecondary}
                text={title}
              />
              {index === selectedItemIndex && <SelectedItemIndicator height={3} color={colors.borderFocus} />}
            </View>
          </MenuTitle>
        </MenuOptionPressable>
      ))}
    </MenuContainer>
  )
  return (
    <CarouselContainer testID={tID(testID)}>
      {carouselMenu}
      {items[selectedItemIndex].content}
    </CarouselContainer>
  )
}

import React, { FunctionComponent } from 'react'
import { Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Shooting Star Icon
 * Used on provider results cards for BCT Fellowship providers
 */
export const ShootingStarIcon: FunctionComponent<ShootingStarIconProps> = ({
  size = 24,
  isFilled = false,
  fillColor,
}) => {
  const { colors } = useTheme()

  if (isFilled) {
    return (
      <Svg width={size} height={size} fill='none'>
        <Path
          d='m10.086 13.365-4.08 1.624a.594.594 0 0 1-.62-.114l4.7-1.51Zm0 0 3.655 2.435m-3.655-2.435 4.305-2.485m0 0 .28 4.383a.625.625 0 0 1-.93.537m.65-4.92 3.446-2.721-3.446 2.72Zm-.65 4.92.196-.35a.258.258 0 0 0 .01.005m-.206.345.223-.334-.017-.01m0 0a.225.225 0 0 0 .323-.187l-.28-4.364-.013-.21.165-.13 3.447-2.72v-.001a.192.192 0 0 0 .069-.193l-.002-.008a.201.201 0 0 0-.142-.154L13.25 6.418l-.205-.052-.073-.199-1.519-4.12a.208.208 0 0 0-.167-.134.232.232 0 0 0-.12.025.185.185 0 0 0-.06.061l-.005.006L8.757 5.72l-.112.178-.21.008-4.39.174h-.01a.184.184 0 0 0-.167.111v.001a.208.208 0 0 0 .03.217L6.714 9.78l.136.163-.058.204-1.203 4.227m8.358 1.081-3.639-2.424-.175-.116-.195.077-4.08 1.624a.193.193 0 0 1-.202-.036l-.006-.006a.2.2 0 0 1-.061-.2m0 0v-.002l-.387-.103.385.11.002-.005Zm3.658-7.412-.007.004a.978.978 0 0 1-.468.109l-2.657.118 1.73 2.092a1.01 1.01 0 0 1 .202.91l-.775-.207A.208.208 0 0 0 7.23 9.8l-.002-.003-2.243-2.713-.512-.62.803-.036 3.475-.155h.033a.176.176 0 0 0 .084-.018.176.176 0 0 0 .061-.058l1.871-2.93.434-.68.28.756 1.213 3.27v.004a.208.208 0 0 0 .14.13l3.363.83.792.196-.639.507-2.734 2.17a.21.21 0 0 0-.075.174l.223 3.479.052.81-.675-.45-2.906-1.941a.207.207 0 0 0-.188-.02l-.287-.749a1.01 1.01 0 0 1 .917.1l.002.001 2.233 1.491-.171-2.672v-.002a1.01 1.01 0 0 1 .372-.844l.003-.003 2.096-1.664-2.576-.637-.008-.002a1.01 1.01 0 0 1-.687-.63V6.89l-.932-2.513L9.605 6.63a.978.978 0 0 1-.358.332Z'
          fill={fillColor || colors.iconInactive}
          stroke={fillColor || colors.iconInactive}
          strokeWidth={0.802}
        />
        <Path
          d='m1 17.467 2-1.5M1 13.467l2-1.5M5 18.467l2-1.5'
          stroke={fillColor || colors.iconInactive}
          strokeWidth={1.2}
          strokeLinecap='round'
        />
      </Svg>
    )
  }
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Rect id='Rectangle' x='0' y='0' width={size} height={size} />
      <Path
        d='M13.7568 3.58912C13.6719 3.6383 13.6008 3.70803 13.5499 3.7919L11.2048 7.50634L6.81549 7.68002C6.70294 7.68157 6.59324 7.71554 6.49951 7.77786C6.40579 7.84018 6.33202 7.92821 6.28706 8.03139C6.24151 8.13486 6.22565 8.24896 6.24125 8.36093C6.25685 8.4729 6.3033 8.57832 6.37539 8.6654L9.19253 12.0377L7.9883 16.2691C7.95936 16.3773 7.96123 16.4914 7.99368 16.5986C8.02613 16.7058 8.08788 16.8018 8.17198 16.8758C8.2543 16.9513 8.35625 17.002 8.46611 17.0221C8.57596 17.0422 8.68926 17.0308 8.79296 16.9894L12.8726 15.3653L16.5278 17.8002C16.6223 17.853 16.7289 17.8804 16.8372 17.8796C16.9455 17.8789 17.0517 17.85 17.1455 17.7959C17.2393 17.7417 17.3174 17.6641 17.3722 17.5707C17.427 17.4773 17.4566 17.3713 17.4581 17.263L17.177 12.8801L20.6233 10.1591C20.7111 10.09 20.7775 9.99759 20.815 9.8924C20.8526 9.78722 20.8596 9.67358 20.8355 9.56455C20.8134 9.45473 20.7612 9.35325 20.6845 9.27155C20.6079 9.18984 20.51 9.13116 20.4018 9.10212L16.1352 8.0293L14.6164 3.90747C14.577 3.80149 14.5089 3.70856 14.4198 3.63906C14.3306 3.56957 14.2239 3.52625 14.1115 3.51396C13.9885 3.50392 13.8652 3.53004 13.7568 3.58912ZM11.8422 8.61023C11.9279 8.56381 12.0004 8.49644 12.0531 8.4144L13.9244 5.48264L15.1371 8.75367C15.1705 8.84581 15.2255 8.92855 15.2977 8.99488C15.3698 9.0612 15.4569 9.10915 15.5515 9.13465L18.9201 9.96685L16.1843 12.1388C16.1092 12.1997 16.0496 12.2777 16.0106 12.3662C15.9716 12.4548 15.9542 12.5513 15.9599 12.6479L16.1832 16.1292L13.276 14.1882C13.1952 14.135 13.1029 14.1017 13.0067 14.0913C12.9105 14.0808 12.8132 14.0934 12.7229 14.128L9.48205 15.4252L10.4457 12.0918C10.4709 11.9971 10.4729 11.8977 10.4515 11.8021C10.4302 11.7064 10.386 11.6174 10.3229 11.5424L8.08046 8.82884L11.5551 8.67416C11.6547 8.678 11.7536 8.65597 11.8422 8.61023Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M3.78638 19.4673L5.78638 17.9673'
        stroke={fillColor || colors.iconInactive}
        stroke-width='1.2'
        stroke-linecap='round'
      />
      <Path
        d='M3.78638 15.4673L5.78638 13.9673'
        stroke={fillColor || colors.iconInactive}
        stroke-width='1.2'
        stroke-linecap='round'
      />
      <Path
        d='M7.78638 20.4673L9.78638 18.9673'
        stroke={fillColor || colors.iconInactive}
        stroke-width='1.2'
        stroke-linecap='round'
      />
    </Svg>
  )
}

export type ShootingStarIconProps = {
  size?: number
  fillColor?: string
  isFilled?: boolean
}

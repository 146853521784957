import React, { MouseEvent, useRef } from 'react'

interface BlurringLinkProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
}

// This component is a button that removes the focus state after being clicked.

const HeaderCustomButton: React.FC<BlurringLinkProps> = ({ children, onClick, ...props }) => {
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (buttonRef.current) {
      buttonRef.current.blur() // Removes focus after click
    }

    if (onClick) {
      onClick(event) // Call the original onClick handler if it exists
    }
  }

  return (
    <button ref={buttonRef} onClick={handleClick} {...props}>
      {children}
    </button>
  )
}

export default HeaderCustomButton

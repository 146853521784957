import React, { FunctionComponent, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ImageSourcePropType, LayoutChangeEvent, View } from 'react-native'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import styled, { useTheme } from 'styled-components/native'

import {
  CostMessagingCoverageSubCategoryType,
  CostMessagingCoverageType,
  CostMessagingModalContent,
  CoverageType,
} from '@lyrahealth-inc/shared-app-logic'

import coverageMessaging from '../../assets/coverageMessaging.png'
import { BodyText, BulletedList, CloseIcon, Image, PressableOpacity, PrimaryButton } from '../../atoms'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { useMediaQuerySize } from '../../hooks'
import { Modal } from '../../organisms'
import { BodyTextSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export interface CoverageMessagingModalProps {
  setIsModalOpen: (isModalOpen: boolean) => void
  isModalOpen: boolean
  coverageModalContents: CostMessagingModalContent[]
  costMessagingCoverageType?: CostMessagingCoverageType
  trackCoverageModalPress: (isOpen: boolean) => void
}

const ContentContainer = styled.ScrollView<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.breakpoints.isMobileSized ? `${theme.spacing['24px']} ${theme.spacing['16px']}` : '',
}))

const CoverageContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.breakpoints.isMobileSized
    ? `${theme.spacing['16px']} ${theme.spacing['16px']} ${theme.spacing['8px']} ${theme.spacing['16px']}`
    : `${theme.spacing['24px']} ${theme.spacing['24px']} ${theme.spacing['8px']} ${theme.spacing['24px']}`,
  backgroundColor: theme.colors.backgroundElement,
  borderRadius: theme.spacing['16px'],
  marginBottom: theme.spacing['32px'],
}))

const ImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  width: '140px',
  height: '140px',
  margin: 'auto',
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['24px'],
}))

const TitleText = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['12px'] : theme.spacing['16px'],
}))

const DescriptionContainer = styled.ScrollView<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['16px'],
  padding: theme.breakpoints.isMobileSized ? `0 ${theme.spacing['8px']}` : `0 ${theme.spacing['12px']}`,
}))

const ButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignSelf: 'flex-end',
  width: theme.breakpoints.isMobileSized ? '100%' : 'auto',
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : 0,
}))

export interface CoverageContentProps {
  title: React.ReactNode
  description: React.ReactNode
}

const CloseIconContainer = styled(PressableOpacity)({
  alignSelf: 'flex-end',
})

export const CoverageContent: FunctionComponent<CoverageContentProps> = ({ title, description }) => {
  const { isMobileSized } = useMediaQuerySize()
  const { colors } = useTheme()

  return (
    <>
      <BodyText size={isMobileSized ? BodyTextSize.SMALL : BodyTextSize.DEFAULT} color={colors.textPrimary}>
        {title}
      </BodyText>
      {'\n'}
      <BodyText size={isMobileSized ? BodyTextSize.SMALL : BodyTextSize.DEFAULT} color={colors.textSecondary}>
        {description}
      </BodyText>
    </>
  )
}

const hasHPIDescription = (costMessagingCoverageType?: CostMessagingCoverageType) => {
  return (
    costMessagingCoverageType === CostMessagingCoverageSubCategoryType.HPI_ONLY_WITH_HPI_MEDS ||
    costMessagingCoverageType === CostMessagingCoverageSubCategoryType.HPI_ONLY_WITH_HPI_MEDS_WITH_DEPENDENT ||
    costMessagingCoverageType === CoverageType.HPI_ONLY ||
    costMessagingCoverageType === CostMessagingCoverageSubCategoryType.HPI_ONLY_WITH_DEPENDENT
  )
}

export const CoverageMessagingModal: FunctionComponent<CoverageMessagingModalProps> = ({
  setIsModalOpen,
  isModalOpen,
  coverageModalContents,
  costMessagingCoverageType,
  trackCoverageModalPress,
}) => {
  const { isMobileSized } = useMediaQuerySize()
  const { colors } = useTheme()

  const bottomSheetRef = useRef<BottomSheetGorhom>(null)
  const [snapHeight, setSnapHeight] = useState<number | null>(null)
  const snapPoints = snapHeight ? [snapHeight] : undefined

  const closingModal = () => {
    trackCoverageModalPress(false)
    setIsModalOpen(false)
  }

  const handleOnLayout = (e: LayoutChangeEvent) => {
    setSnapHeight(e.nativeEvent.layout.height)
  }

  const openBottomSheet = () => {
    bottomSheetRef.current?.expand()
  }

  const closeBottomSheet = () => {
    closingModal()
    bottomSheetRef.current?.close()
  }

  return (
    <Modal
      modalContents={
        <ContentContainer testID={tID('CoverageMessagingModal')} onLayout={handleOnLayout}>
          <CloseIconContainer testID={tID('CoverageMessagingModal-closeIcon')} onPress={closeBottomSheet}>
            <CloseIcon size={16} fillColor={colors.iconDefault} />
          </CloseIconContainer>
          <View>
            <ImageContainer source={coverageMessaging as ImageSourcePropType} accessibilityIgnoresInvertColors />
            <TitleText
              text={
                <FormattedMessage
                  defaultMessage='Understand your coverage'
                  description='Header text for coverage modal'
                />
              }
              size={SubheadSize.MEDIUM}
              textAlign='center'
            />
            <DescriptionContainer>
              <BodyText
                text={
                  hasHPIDescription(costMessagingCoverageType) ? (
                    <FormattedMessage
                      defaultMessage='Discover coverage options tailored to your unique care needs. We’ll need to verify your health plan to estimate your cost.'
                      description='Description text for coverage modal for HPI customers'
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage='Discover coverage options tailored to your unique care needs:'
                      description='Description text for coverage modal for customers'
                    />
                  )
                }
                size={isMobileSized ? BodyTextSize.SMALL : BodyTextSize.DEFAULT}
                textAlign='center'
              />
            </DescriptionContainer>
          </View>
          {coverageModalContents && coverageModalContents.length > 0 && (
            <CoverageContainer testID={tID('CoverageMessagingModal-contentContainer')}>
              <BulletedList
                spaceBetween={isMobileSized ? 8 : 16}
                bulletColor={colors.components.coverageMessagingModal.fill}
                data={coverageModalContents.map((content: CostMessagingModalContent) => {
                  return { value: <CoverageContent title={content.title} description={content.description} /> }
                })}
              />
            </CoverageContainer>
          )}
          <ButtonContainer>
            <PrimaryButton
              onPress={() => {
                closingModal()
              }}
              text={<FormattedMessage defaultMessage='Got it' description='Cost messaging modal button text' />}
              testID={tID('CoverageMessagingModal-confirmationButton')}
              fullWidth={isMobileSized}
            />
          </ButtonContainer>
        </ContentContainer>
      }
      onRequestClose={closeBottomSheet}
      visible={isModalOpen}
      bottomSheetRef={bottomSheetRef}
      snapPoints={snapPoints}
      onCloseEnd={closeBottomSheet}
      disableBottomSheet={!isMobileSized}
      onLayout={openBottomSheet}
      scrollable={isMobileSized}
    />
  )
}

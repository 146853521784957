export const SHOW_SEARCH_FOR_CARE_ANIMATION = 'SHOW_SEARCHING_OUR_NETWORK_MODAL'
export const HIDE_SEARCH_FOR_CARE_ANIMATION = 'HIDE_SEARCHING_OUR_NETWORK_MODAL'

const SEARCH_FOR_CARE_ANIMATION_TIMEOUT = 3000

export const showSearchForCareLoadingAnimationWithMinimumTimeout = () => {
  return (dispatch: $TSFixMe) => {
    dispatch(showSearchForCareLoadingAnimation())
    return new Promise((resolve) => setTimeout(resolve, SEARCH_FOR_CARE_ANIMATION_TIMEOUT))
  }
}

const showSearchForCareLoadingAnimation = () => {
  return { type: SHOW_SEARCH_FOR_CARE_ANIMATION }
}
export const hideSearchForCareLoadingAnimation = () => {
  return { type: HIDE_SEARCH_FOR_CARE_ANIMATION }
}

import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import {
  SEARCHER_TYPE_TO_MATCH_FOR,
  useFlags,
  useInProgressSearch,
  useSearchAgeAndType,
} from '@lyrahealth-inc/shared-app-logic'

import { useGetUserDisplayName } from './useGetUserDisplayName'
import { getSessions } from '../../data/lyraTherapy/lyraTherapySelectors'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import { getUserAge, getUserDOB } from '../../data/user/userSelectors'
import { handleZeroViewSelect } from '../../features/appointmentDashboard/data/appointmentDashboardActions'
import { setSearcherInfo } from '../../features/triage/data/triageActions'
import { getSearches } from '../../features/triage/data/triageSelectors'

export const useNavigateToSearchForCare = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { firstName, lastName } = useGetUserDisplayName()
  const userAge = useSelector(getUserAge)
  const userDOB = useSelector(getUserDOB)
  const searches = useSelector(getSearches)?.toJS()
  const providerSessions = useSelector(getSessions)
  const inProgressSearch = useInProgressSearch(searches, providerSessions.appointments)
  const getSearchType = useSearchAgeAndType()
  const { shouldShowTriageSummary } = useFlags()

  return useCallback(() => {
    if (userAge === undefined) {
      console.warn('Failed to navigate to search for care - missing user data')
      return
    }

    dispatch(
      handleZeroViewSelect({
        whoAmIHereFor: SEARCHER_TYPE_TO_MATCH_FOR.SELF,
        navigate,
        currentLocation: location.pathname,
        showSummaryView: shouldShowTriageSummary && inProgressSearch === undefined,
      }),
    )
    const { type } = getSearchType({ dob: userDOB, matchFor: SEARCHER_TYPE_TO_MATCH_FOR.SELF })
    dispatch(
      setSearcherInfo({
        name: firstName,
        age: userAge,
        type,
        dob: userDOB,
        lastName,
      }),
    )
  }, [
    firstName,
    getSearchType,
    inProgressSearch,
    lastName,
    navigate,
    shouldShowTriageSummary,
    userAge,
    userDOB,
    dispatch,
  ])
}

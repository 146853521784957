import React from 'react'
import { Mask, Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

type MorningIconProps = {
  fillColor?: string
  height?: string | number
  width?: string | number
}

export const MorningIcon: React.FC<MorningIconProps> = ({ fillColor, height = 32, width = 32 }) => {
  const { colors } = useTheme()
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none'>
      <Mask
        id='path-1-outside-1_4504_51633'
        maskUnits='userSpaceOnUse'
        x='5.36133'
        y='11'
        width='21'
        height='17'
        fill='black'
      >
        <Rect fill='white' x='5.36133' width='21' height='17' y='11' />
        <Path
          clipRule='evenodd'
          fillRule='evenodd'
          d='M23.5619 25.6768C24.2378 24.4556 24.6222 23.0532 24.6222 21.5618C24.6222 16.8332 20.7582 13 15.9918 13C11.2253 13 7.36133 16.8332 7.36133 21.5618C7.36133 23.0186 7.72809 24.3904 8.375 25.5913C10.7802 25.2265 13.3495 25.0495 15.9986 25.1004C18.6255 25.1003 21.174 25.3033 23.5619 25.6768Z'
        />
      </Mask>
      <Path
        fill={fillColor ?? colors.textPrimary}
        mask='url(#path-1-outside-1_4504_51633)'
        d='M23.5619 25.6768L23.3301 27.1588L24.3663 27.3208L24.8742 26.4032L23.5619 25.6768ZM8.375 25.5913L7.05442 26.3027L7.55543 27.2328L8.59991 27.0744L8.375 25.5913ZM15.9986 25.1004L15.9698 26.6001L15.9842 26.6004H15.9986L15.9986 25.1004ZM23.1222 21.5618C23.1222 22.7923 22.8057 23.9455 22.2495 24.9504L24.8742 26.4032C25.6699 24.9658 26.1222 23.3142 26.1222 21.5618H23.1222ZM15.9918 14.5C19.9411 14.5 23.1222 17.6729 23.1222 21.5618H26.1222C26.1222 15.9935 21.5753 11.5 15.9918 11.5V14.5ZM8.86133 21.5618C8.86133 17.6729 12.0424 14.5 15.9918 14.5V11.5C10.4082 11.5 5.86133 15.9935 5.86133 21.5618H8.86133ZM9.69559 24.8799C9.16333 23.8919 8.86133 22.7638 8.86133 21.5618H5.86133C5.86133 23.2734 6.29285 24.8889 7.05442 26.3027L9.69559 24.8799ZM8.59991 27.0744C10.9208 26.7224 13.4051 26.5509 15.9698 26.6001L16.0273 23.6006C13.2938 23.5482 10.6397 23.7307 8.15009 24.1083L8.59991 27.0744ZM15.9986 26.6004C18.5492 26.6003 21.0202 26.7975 23.3301 27.1588L23.7936 24.1948C21.3278 23.8092 18.7019 23.6003 15.9985 23.6004L15.9986 26.6004Z'
      />
      <Path
        d='M25.5 19.4691H29M27 10L22.5 14.5M16 6V12M9.5 14.5L5 10M3 19.4691H6.5'
        stroke={fillColor ?? colors.textPrimary}
        strokeWidth={1.5}
        strokeMiterlimit={1.55572}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M4 27.1706C7.6462 26.3567 11.7337 25.928 16 26.0099C20.2663 26.0099 24.3538 26.4723 28 27.2932'
        stroke={fillColor ?? colors.textPrimary}
        strokeWidth={1.5}
        strokeLinecap='round'
      />
    </Svg>
  )
}

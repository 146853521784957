import React, { useMemo } from 'react'

import { LyraDisplayLanguageTags, useFlags } from '@lyrahealth-inc/shared-app-logic'
import {
  EnableTranslations,
  LyraIntl,
  useSmartlingContextCapture,
} from '@lyrahealth-inc/shared-app-logic/src/features/intl'

import AppIntlProvider from './AppIntlProvider'
import { getLyraWebLanguageConfig } from './config'

export const LyraWebIntlProvider: React.FC = ({ children }) => {
  const flags = useFlags()
  const intl = useMemo(() => new LyraIntl<LyraDisplayLanguageTags>(getLyraWebLanguageConfig(flags)), [flags])

  return (
    <AppIntlProvider intl={intl as LyraIntl<string>}>
      <ContextCapture />
      {flags.isDisplayLanguageEnabled ? <EnableTranslations>{children}</EnableTranslations> : children}
    </AppIntlProvider>
  )
}

const ContextCapture: React.FC = () => {
  useSmartlingContextCapture('QWO4ncq74mg7R5feR-bQTQ')
  return null
}

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export const LinkedIcon: FunctionComponent<LinkedIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' testID={tID('LinkedIcon')}>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 15H10C10.55 15 11 15.45 11 16C11 16.55 10.55 17 10 17H7C4.24 17 2 14.76 2 12C2 9.24 4.24 7 7 7H10C10.55 7 11 7.45 11 8C11 8.55 10.55 9 10 9H7C5.35 9 4 10.35 4 12C4 13.65 5.35 15 7 15ZM17 7H14C13.45 7 13 7.45 13 8C13 8.55 13.45 9 14 9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H14C13.45 15 13 15.45 13 16C13 16.55 13.45 17 14 17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM8 12C8 12.55 8.45 13 9 13H15C15.55 13 16 12.55 16 12C16 11.45 15.55 11 15 11H9C8.45 11 8 11.45 8 12Z'
        fill={fillColor || colors.iconDefault}
      />
    </Svg>
  )
}

export interface LinkedIconProps {
  size?: number
  fillColor?: string
}

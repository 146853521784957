import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Circle Icon with an exclaimation mark
 */
export const AlertIcon: FunctionComponent<AlertIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM11.25 7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V12.5C12.75 12.91 12.41 13.25 12 13.25C11.59 13.25 11.25 12.91 11.25 12.5V7.5ZM12 17C11.45 17 11 16.55 11 16C11 15.45 11.45 15 12 15C12.55 15 13 15.45 13 16C13 16.55 12.55 17 12 17Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

type AlertIconProps = {
  size?: string | number
  fillColor?: string
}

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { ThemeType, tID } from '../../utils'

export const GroupSessionIcon: FunctionComponent<GroupSessionIconProps> = ({ size = '26', fillColor }) => {
  const { colors } = useTheme() as ThemeType

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none' testID={tID('GroupIcon')}>
      <Path
        id='Vector'
        d='M9.13672 11C11.0697 11 12.6367 9.433 12.6367 7.5C12.6367 5.567 11.0697 4 9.13672 4C7.20372 4 5.63672 5.567 5.63672 7.5C5.63672 9.433 7.20372 11 9.13672 11Z'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        id='Vector_2'
        d='M15 11C16.933 11 18.5 9.433 18.5 7.5C18.5 5.567 16.933 4 15 4'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        id='Rectangle 10416'
        d='M16 20V18C16 15.7909 14.2091 14 12 14H6C3.79086 14 2 15.7909 2 18V20'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        id='Rectangle 10418'
        d='M18.5 14V14C20.433 14 22 15.567 22 17.5V20'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  )
}

type GroupSessionIconProps = {
  size?: string
  fillColor?: string
}

import { useCallback } from 'react'

import { isNil, pickBy } from 'lodash-es'

import { CUSTOMER_CONFIGURATION } from '../../models'
import { CONTACT_CARE_TEAM_REQUEST_TYPES } from '../common/constants/constants'
import {
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PROPERTIES,
  COMMON_SEARCH_FOR_CARE_SCREENS,
  MIXPANEL_PROPERTY,
  S4C_EVENTS,
  S4C_PROPERTIES,
} from '../common/constants/mixpanelConstants'
import { PrimaryNeedsWords, SEARCHER_TYPE_TO_MATCH_FOR } from '../searchForCare/constants'

type Params = {
  customerLyraId?: number
  lyraId?: string
  matchFor?: SEARCHER_TYPE_TO_MATCH_FOR
  otherNeed?: string
  primaryNeed?: string
  searchId?: string | null
  matcherRequestId?: string | null
  userTimezone?: string
  countryCategory?: string
  childLyraId?: string
  childAge?: number
  customerConfiguration?: CUSTOMER_CONFIGURATION
  healthPlan?: string | null
}

export const useGetChildDirectPathToCareNavigatorProps = ({
  customerLyraId,
  lyraId,
  matchFor,
  otherNeed,
  primaryNeed,
  searchId,
  matcherRequestId,
  userTimezone,
  countryCategory,
  childLyraId,
  childAge,
  customerConfiguration,
  healthPlan,
}: Params) => {
  return useCallback(
    (event: string, customProps?: { [key in MIXPANEL_PROPERTY]?: any }) => {
      const props: { [key in MIXPANEL_PROPERTY]?: any } = {
        [COMMON_MIXPANEL_PROPERTIES.CUSTOMER_CONFIGURATION]: customerConfiguration,
        [COMMON_MIXPANEL_PROPERTIES.USER_TIMEZONE]: userTimezone,
        [COMMON_MIXPANEL_PROPERTIES.COUNTRY_CATEGORY]: countryCategory,
        [COMMON_MIXPANEL_PROPERTIES.CUSTOMER_ID]: customerLyraId,
        [COMMON_MIXPANEL_PROPERTIES.LYRA_ID]: lyraId,
        [S4C_PROPERTIES.OTHER_NEED]: otherNeed,
        [S4C_PROPERTIES.PRIMARY_NEED]: primaryNeed,
        [S4C_PROPERTIES.HEALTH_PLAN_NAME]: healthPlan,
        [S4C_PROPERTIES.CHILD_LYRA_ID]: childLyraId,
        [S4C_PROPERTIES.AGE]: childAge,
        [S4C_PROPERTIES.MATCHER_REQUEST_ID]: matcherRequestId,
        [S4C_PROPERTIES.MATCH_FOR]: matchFor,
        [S4C_PROPERTIES.SEARCH_ID]: searchId,
      }

      const isSpecializedNeed =
        primaryNeed === PrimaryNeedsWords.AUTISM_SPECTRUM || primaryNeed === PrimaryNeedsWords.DEVELOPMENTAL_CONCERNS

      switch (event) {
        case COMMON_MIXPANEL_EVENTS.CLICK_SCHEDULE_A_CN_CALL:
        case S4C_EVENTS.SUBMIT_EMAIL_CN:
          props[COMMON_MIXPANEL_PROPERTIES.ACTION] = COMMON_MIXPANEL_EVENTS.CLICK
          props[COMMON_MIXPANEL_PROPERTIES.PAGE] = COMMON_SEARCH_FOR_CARE_SCREENS.CHILD_CN_EMAIL_REQUEST
          props[S4C_PROPERTIES.CN_entrypoint] = COMMON_SEARCH_FOR_CARE_SCREENS.CHILD_CN_EMAIL_REQUEST
        case S4C_EVENTS.VIEW_CHILD_CN_EMAIL_REQUEST_PAGE:
          props[S4C_PROPERTIES.REQUEST_TYPE] = isSpecializedNeed
            ? CONTACT_CARE_TEAM_REQUEST_TYPES.SPECIALIZED_NEED
            : CONTACT_CARE_TEAM_REQUEST_TYPES.OTHER_PRIMARY_NEEDS
          break
      }

      const propsWithNonEmptyValues = pickBy(props, (value) => !isNil(value))
      return { ...propsWithNonEmptyValues, ...customProps }
    },
    [
      customerConfiguration,
      userTimezone,
      countryCategory,
      customerLyraId,
      lyraId,
      otherNeed,
      primaryNeed,
      healthPlan,
      childLyraId,
      childAge,
      matcherRequestId,
      searchId,
      matchFor,
    ],
  )
}

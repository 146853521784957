import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number | string
  outerCircleBgColor?: string
  innerCircleBgColor?: string
}

export const CoachingSpotIllustration: FunctionComponent<Props> = ({
  size = 124,
  outerCircleBgColor = 'white',
  innerCircleBgColor = '#FFE9E3',
}) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 515 513' fill='none'>
      <Path
        d='M258.3 0.728027C222.959 0.728027 188.67 6.78852 156.384 18.7375C79.5203 47.2289 25.4818 110.394 8.11986 192.033C-3.35359 245.262 -1.88652 294.495 12.6323 342.473C31.1679 403.745 76.6671 455.416 137.464 484.232C167.645 498.568 202.319 506.754 240.503 508.555C245.41 508.777 250.398 508.899 255.355 508.899C285.992 508.899 316.81 504.7 346.961 496.423C349.278 495.847 351.483 495.25 353.811 494.562C380.906 486.629 405.289 474.084 426.152 457.319C448.805 439.218 467.452 416.271 481.516 389.156C484.288 383.814 486.939 378.188 489.863 371.46C505.95 334.106 513.72 292.289 512.344 250.483C511.697 231.998 508.945 212.349 504.19 192.033C496.358 159.07 483.59 128.069 467.26 102.33C462.788 95.4194 458.68 89.6118 454.613 84.3911C425.262 46.5308 379.53 19.9617 322.405 7.59793C301.421 3.04497 279.85 0.738144 258.31 0.738144L258.3 0.728027Z'
        fill={outerCircleBgColor}
      />
      <Path
        d='M490.38 251.171C489.753 233.455 487.042 215.101 482.772 196.859C475.791 167.467 464.317 138.49 448.584 113.682C444.932 108.056 441.188 102.633 437.121 97.4225C407.618 59.38 362.908 38.2139 317.146 28.3087C266.173 17.2601 211.972 20.4877 162.973 38.6287C92.0881 64.8942 43.8268 122.433 28.0837 196.444C22.0333 224.48 20.2627 256.169 22.9742 286.613H22.7618C24.33 303.914 27.4564 320.801 32.1408 336.331C49.5534 393.87 91.8655 439.532 145.762 465.069C174.952 478.93 207.571 485.81 240.727 487.378C274.601 488.946 309.001 484.667 340.801 475.915C342.885 475.399 344.868 474.873 346.952 474.245C371.862 466.95 393.544 455.689 412.1 440.786C432.325 424.628 448.999 404.302 461.93 379.392C464.742 373.969 467.241 368.556 469.639 363.022C484.856 327.681 491.635 289.324 490.279 251.282L490.38 251.181V251.171Z'
        fill={innerCircleBgColor}
      />
      <Path
        d='M393.813 330.011C413.712 285.157 412.376 219.294 383.589 178.539C363.609 150.251 324.393 128.395 290.08 124.736C254.248 120.913 216.092 123.818 184.634 142.106C156.988 158.172 133.613 182.138 120.84 211.69C112.594 230.783 108.903 251.803 109.943 272.568C112.023 314.077 131.259 356.627 166.581 379.206C209.242 406.485 266.94 417.923 315.361 400.155C345.239 389.196 379.583 362.07 393.803 330.011H393.813Z'
        fill='#F69170'
      />
      <Path
        d='M366.383 257.807C370.216 307.279 338.91 358.483 279.143 367.188C179.783 381.643 110.118 279.469 181.445 199.661C242.547 131.28 359.145 164.523 366.383 257.807Z'
        fill='white'
      />
      <Path
        d='M260.569 336.025C297.423 336.025 327.299 306.149 327.299 269.296C327.299 232.442 297.423 202.566 260.569 202.566C223.716 202.566 193.84 232.442 193.84 269.296C193.84 306.149 223.716 336.025 260.569 336.025Z'
        fill='#F69170'
      />
      <Path
        d='M260.57 296.401C275.541 296.401 287.676 284.266 287.676 269.296C287.676 254.326 275.541 242.19 260.57 242.19C245.6 242.19 233.465 254.326 233.465 269.296C233.465 284.266 245.6 296.401 260.57 296.401Z'
        fill='white'
      />
      <Path
        d='M264.399 264.218L186.462 145.614C184.619 142.809 180.852 142.03 178.048 143.873L178.039 143.878C175.235 145.721 174.455 149.488 176.298 152.292L254.236 270.896C256.079 273.7 259.846 274.48 262.65 272.637L262.659 272.632C265.463 270.789 266.242 267.022 264.399 264.218Z'
        fill='#068298'
      />
      <Path
        d='M184.176 91.3604L221.68 148.987L208.132 188.356L208.122 188.376H208.101L166.622 184.808L129.129 127.182L172.688 133.92L184.176 91.3604Z'
        fill='#3BA5B5'
      />
    </Svg>
  )
}

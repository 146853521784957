import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { ASSETS_CDN_URL, TREATMENT_REC_PRIMARY_CARD_CDN_PREFIX as namespace } from '../../constants'
import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageSizer = styled(Image)<{ height: string; width: string }>(({ height, width }) => ({
  width,
  height,
}))

const Container = styled.View({
  overflow: 'hidden',
})

const IMAGE_SIZES = {
  DESKTOP: {
    height: '332px',
    width: '416px',
  },
  TABLET: {
    height: '276px',
    width: '354px',
  },
  MOBILE: {
    height: '190px',
    width: '343px',
  },
}

/**
 * Illustration to show on the treatment card for Therapy
 */
export const TeenTherapyRec: FunctionComponent<TeenTherapyRecProps> = ({ viewport = 'DESKTOP' }) => {
  const uri = ASSETS_CDN_URL + namespace + 'teenTherapy' + viewport + '.png'
  return (
    <Container>
      <ImageSizer {...IMAGE_SIZES[viewport]} testID={`${tID('TeenTherapyRecIllustration')}`} source={{ uri }} />
    </Container>
  )
}

export type TeenTherapyRecProps = {
  viewport?: 'DESKTOP' | 'TABLET' | 'MOBILE'
}

import React from 'react'
import { Linking } from 'react-native'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import DependentIllustration from '../assets/worklife/illustration-dependents.png'
import StationaryIllustration from '../assets/worklife/illustration-financial.png'
import HeartHandsIllustration from '../assets/worklife/illustration-heart-hands.png'
import YogaIllustration from '../assets/worklife/illustration-yoga.png'
import { BodyText, Link } from '../atoms'

export const thehartford: GetCustomerCopyFunc = () => {
  return {
    workLifeServices: [
      {
        identifiers: {
          id: 'careAtWork',
          dataTestId: 'workLife-selectCareAtWork',
        },
        title: 'Care @ Work',
        img: HeartHandsIllustration,
        paragraph: (
          <>
            <BodyText text='The Hartford provides – at no cost to you – unlimited access to ' />
            <Link
              text='Care.com'
              onPress={() => {
                Linking.openURL('https://care.com/')
              }}
            />
            <BodyText
              text=', the world’s largest online community for finding care, including nannies, tutors,
              senior caregivers, housekeepers, and more. Sign up at '
            />
            <Link
              text='care.com/thehartford'
              onPress={() => {
                Linking.openURL('http://care.com/thehartford')
              }}
            />
            <BodyText text='.' />
          </>
        ),
        bullets: [],
      },
      {
        identifiers: {
          id: 'peaceAtHome',
          dataTestId: 'workLife-selectPeaceAtHome',
        },
        title: 'Peace at Home Parenting Solutions',
        img: YogaIllustration,
        paragraph: (
          <>
            <BodyText
              text='Access to live and recorded online parenting classes at no cost to you. Classes
              include topic such as Back to School with Joy and Optimism, Positive Discipline, School Success –
              Inspire Motivation and Engagement, and more. '
            />
            <Link
              text='Learn more'
              onPress={() => {
                Linking.openURL('https://www.cafewell.com/pages/full?id=hartpah21')
              }}
            />
          </>
        ),
        bullets: [],
      },
      {
        identifiers: {
          id: 'mavenFamily',
          dataTestId: 'workLife-selectMavenFamily',
        },
        title: 'Maven Family Benefits',
        img: DependentIllustration,
        paragraph: (
          <>
            <BodyText
              text='Get unlimited, on-demand support for fertility, pregnancy, adoption, surrogacy,
              postpartum, returning to work, and infant care with Maven. '
            />
            <Link
              text='Learn more'
              onPress={() => {
                Linking.openURL('https://www.cafewell.com/pages/full?id=hart_mav21')
              }}
            />
          </>
        ),
        bullets: [],
      },
      {
        identifiers: {
          id: 'perksAtWork',
          dataTestId: 'workLife-selectPerksAtWork',
        },
        title: 'Perks at Work',
        img: StationaryIllustration,
        paragraph: (
          <>
            <BodyText
              text='Check out Perks at Work for back-to-school savings and access to enrichment programs
              for children. '
            />
            <Link
              text='Sign in Here.'
              onPress={() => {
                Linking.openURL('https://www.perksatwork.com/login')
              }}
            />
          </>
        ),
        bullets: [],
      },
    ],
  }
}

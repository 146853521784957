import React, { FunctionComponent, useEffect, useRef } from 'react'

import LottieView, { AnimationObject } from 'lottie-react-native'

import { isChromaticWeb } from '../../utils'

export const MoodEmojiLottie: FunctionComponent<{
  animationSource: Required<Pick<AnimationObject, 'markers'>> & AnimationObject
  size?: number
}> = ({ animationSource, size = 128 }) => {
  const animationRef = useRef<LottieView>(null)
  const { markers } = animationSource
  const idleMarker = markers[0]
  const start = idleMarker.tm
  const end = idleMarker.tm + idleMarker.dr

  useEffect(() => {
    animationRef.current?.play(start, end)
  }, [start, end])

  return (
    <LottieView
      ref={animationRef}
      source={animationSource}
      style={{ width: size, height: size }}
      loop={!isChromaticWeb()}
      autoPlay={!isChromaticWeb()}
    />
  )
}

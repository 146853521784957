import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { HIDE_SEARCH_FOR_CARE_ANIMATION, SHOW_SEARCH_FOR_CARE_ANIMATION } from './individualTherapyActions'

const initialState = fromJS({
  showModal: false,
})

export default createReducer(initialState, {
  [SHOW_SEARCH_FOR_CARE_ANIMATION]: ($$state: $TSFixMe) => {
    $$state = $$state.set('showModal', fromJS(true))
    return $$state
  },
  [HIDE_SEARCH_FOR_CARE_ANIMATION]: ($$state: $TSFixMe) => {
    $$state = $$state.set('showModal', fromJS(false))
    return $$state
  },
})

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * An icon used to represent "follow the science"
 */
export const TrendUpIcon: FunctionComponent<TrendUpIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        d='M22.7412 19.25H19.4912V4C19.4886 3.80189 19.4088 3.61263 19.2687 3.47253C19.1286 3.33244 18.9393 3.25259 18.7412 3.25H14.7412C14.5431 3.25259 14.3538 3.33244 14.2137 3.47253C14.0737 3.61263 13.9938 3.80189 13.9912 4V7.25H10.7412C10.5431 7.25259 10.3538 7.33244 10.2137 7.47253C10.0737 7.61263 9.9938 7.80189 9.99121 8V11.25H6.74121C6.5431 11.2526 6.35384 11.3324 6.21374 11.4725C6.07365 11.6126 5.9938 11.8019 5.99121 12V19.25H2.74121C2.5423 19.25 2.35153 19.329 2.21088 19.4697C2.07023 19.6103 1.99121 19.8011 1.99121 20C1.99121 20.1989 2.07023 20.3897 2.21088 20.5303C2.35153 20.671 2.5423 20.75 2.74121 20.75H22.7412C22.9401 20.75 23.1309 20.671 23.2715 20.5303C23.4122 20.3897 23.4912 20.1989 23.4912 20C23.4912 19.8011 23.4122 19.6103 23.2715 19.4697C23.1309 19.329 22.9401 19.25 22.7412 19.25ZM7.49121 12.75H9.99121V19.25H7.49121V12.75ZM17.9912 19.25H15.4912V4.75H17.9912V19.25Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M2.74104 11.7501C2.6425 11.7505 2.54486 11.7313 2.45385 11.6935C2.36283 11.6558 2.28028 11.6002 2.21104 11.5301C2.07059 11.3894 1.9917 11.1988 1.9917 11.0001C1.9917 10.8013 2.07059 10.6107 2.21104 10.4701L7.21104 5.47007C7.31593 5.36532 7.4495 5.29399 7.5949 5.26511C7.7403 5.23622 7.891 5.25107 8.02796 5.30779C8.16493 5.3645 8.28201 5.46053 8.36443 5.58374C8.44685 5.70696 8.49091 5.85183 8.49104 6.00007V9.00007C8.49104 9.19899 8.41202 9.38975 8.27137 9.5304C8.13072 9.67105 7.93995 9.75007 7.74104 9.75007C7.54213 9.75007 7.35136 9.67105 7.21071 9.5304C7.07006 9.38975 6.99104 9.19899 6.99104 9.00007V7.81007L3.27104 11.5301C3.20179 11.6002 3.11924 11.6558 3.02823 11.6935C2.93722 11.7313 2.83958 11.7505 2.74104 11.7501Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M7.74121 6.75H4.74121C4.5423 6.75 4.35153 6.67098 4.21088 6.53033C4.07023 6.38968 3.99121 6.19891 3.99121 6C3.99121 5.80109 4.07023 5.61032 4.21088 5.46967C4.35153 5.32902 4.5423 5.25 4.74121 5.25H7.74121C7.94012 5.25 8.13089 5.32902 8.27154 5.46967C8.41219 5.61032 8.49121 5.80109 8.49121 6C8.49121 6.19891 8.41219 6.38968 8.27154 6.53033C8.13089 6.67098 7.94012 6.75 7.74121 6.75Z'
        fill={fillColor || colors.iconInactive}
      />
    </Svg>
  )
}

type TrendUpIconProps = {
  size?: string | number
  fillColor?: string
}

import { isPast } from 'date-fns'
import { isFunction } from 'lodash-es'

import { CustomerPropertiesMap } from '../../../models'
import { excludedServices as defaultExcludedServices } from '../constants/constants'
import {
  CustomerInstanceMap,
  CustomerInstanceRedirectionProps,
  SubpopulationRedirectionProps,
} from '../constants/customer'
import { REGISTRATION_EVENTS } from '../constants/mixpanelConstants'

interface CustomerInfo extends CustomerPropertiesMap {
  eligibilityType?: string
}

export const generateServicesNotCovered = ({ excludedServices = defaultExcludedServices }) => {
  return `Services that are not covered include: ${excludedServices}.
      Please check with your health plan or benefits portal for coverage of these excluded services.`
}

// Passes an object of customer properties to a customer copy, if applicable
export const generateCustomerCopyWithProperties = ({
  customerName,
  copy,
  customerInfo,
  customerDisplayName,
}: {
  customerName: string
  copy: any
  customerInfo: CustomerInfo
  customerDisplayName: string
}) => {
  return isFunction(copy) ? copy({ ...customerInfo, customerName, customerDisplayName }) : copy
}

export const showSubpopulationModal = (customerInstanceMap: CustomerInstanceMap, customerName: string) => {
  let showSubpopulationModal = false
  // Check if customer has subpopulations and if customerName is not null
  // Check customer has a relationship in the customerInstanceMap LD flag and has more than one instance
  if (customerInstanceMap?.hasOwnProperty(customerName) && customerInstanceMap[customerName].instances.length > 0) {
    const subpopulations = [...customerInstanceMap[customerName].instances, customerName]
    showSubpopulationModal = subpopulations.every((subpopulation) => {
      if (customerInstanceMap[subpopulation]?.hasOwnProperty('launchDate')) {
        return isPast(new Date(customerInstanceMap[subpopulation].launchDate as string))
      } else {
        // If no launchDate is provided then return the subpopulation exist in the mapping
        return !!customerInstanceMap[subpopulation]
      }
    })
  }
  return showSubpopulationModal
}

export const buildCustomerInstanceRedirectionProps = ({
  customerInstanceMap,
  customerName,
  navigate,
  trackEvent,
}: CustomerInstanceRedirectionProps) => {
  const handleRedirect = (newCustomerName: string) => {
    trackEvent(REGISTRATION_EVENTS.CLICK_REDIRECTION_LINK, { customerName, newCustomerName })
    navigate(newCustomerName)
  }
  if (customerInstanceMap?.hasOwnProperty(customerName) && customerInstanceMap[customerName].instances.length > 0) {
    return handleRedirect(customerInstanceMap[customerName].instances[0])
  }
}

export const handleSubmitSubpopulationRedirection = ({
  customerInstanceMap,
  customerName,
  newCustomerIndex,
  navigate,
  trackEvent,
}: SubpopulationRedirectionProps) => {
  let newCustomerName = customerName
  // The customerInstanceMap LD flag contains a mapping of multi-instance customers to their other instances' customer names. Currently only supports 1 alternative instance per customer.
  // To retrieve the customer names based on index, it is designated that:
  // newCustomerIndex = 0 corresponds to the current customer
  // newCustomerIndex = 1 corresponds to customerInstanceMap[currentCustomer].instances[0]
  // newCustomerIndex = x correponds to customerInstanceMap[currentCustomer].instances[x - 1]
  if (newCustomerIndex - 1 >= 0) {
    if (customerInstanceMap?.hasOwnProperty(customerName) && customerInstanceMap[customerName].instances.length > 0) {
      newCustomerName = customerInstanceMap[customerName].instances[newCustomerIndex - 1]
    }
  }

  trackEvent(REGISTRATION_EVENTS.SELECT_REDIRECTION_MODAL_OPTION, { newCustomer: newCustomerName })
  navigate(newCustomerName)
}

import { IntlShape } from 'react-intl'

import {
  ELIGIBILITY_TYPES,
  EligibilityField,
  FormMetadata,
  registrationEligibilityFormHeader,
  USER_MAX_AGE_REQUIREMENT,
  USER_MIN_AGE_REQUIREMENT,
  walmartCustomerCodeFieldLabel,
} from '@lyrahealth-inc/shared-app-logic'

import { IS_WEB } from '../../../constants'
import { commonInputFieldBorderStyle } from '../../../organisms/formBody/sharedMetadata'
import { BasicInfoField } from '../../../organisms/setUpAccountForm/basicInfoMetadata'

export const walmartEmployeeEligibilityMetadata = (intl: IntlShape, isAttestation: boolean): FormMetadata => {
  return {
    schema: {
      type: 'object',
      properties: {
        employeeFormSubHeader: {
          name: 'employeeFormSubHeader',
          content: `#### ${intl.formatMessage(registrationEligibilityFormHeader)}`,
          condition: {
            parentField: EligibilityField.ELIGIBILITY_TYPE,
            parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
          },
        },
        [EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME]: {
          type: 'string',
          title: intl.formatMessage(walmartCustomerCodeFieldLabel),
          name: EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME,
          condition: {
            parentField: EligibilityField.ELIGIBILITY_TYPE,
            parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
          },
          validation: { isRequired: true, isValidCustomerCode: true },
        },
        ...(isAttestation && {
          [EligibilityField.EMPLOYEE_DATE_OF_BIRTH]: {
            name: BasicInfoField.USER_DATE_OF_BIRTH,
            condition: {
              parentField: EligibilityField.ELIGIBILITY_TYPE,
              parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
            },
            title: intl.formatMessage({
              defaultMessage: 'Your date of birth',
              description: 'Date of birth field label',
            }),
            placeholder: '',
            type: 'string',
            validation: {
              isRequired: true,
              isValidAge: {
                min: USER_MIN_AGE_REQUIREMENT.DOMESTIC_EMPLOYEE,
                max: USER_MAX_AGE_REQUIREMENT.ADULT,
              },
            },
          },
        }),
      },
    },
    uiSchema: {
      'ui:order': [
        'employeeFormSubHeader',
        EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME,
        EligibilityField.EMPLOYEE_DATE_OF_BIRTH,
      ],
      [EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME]: {
        'ui:options': {
          baseInputStyle: {
            marginBottom: 32,
          },
          ...commonInputFieldBorderStyle,
        },
      },
      [EligibilityField.EMPLOYEE_DATE_OF_BIRTH]: {
        'ui:widget': IS_WEB ? 'datePickerSelect' : 'date',
        'ui:options': IS_WEB
          ? {
              numYears: USER_MAX_AGE_REQUIREMENT.ADULT,
              selectFieldStyle: {
                borderRadius: 8,
              },
            }
          : {
              dateFieldDisplay: 'spinner',
              selectFieldStyle: {
                borderRadius: 8,
              },
            },
      },
    },
  }
}

export const generateWalmartEmployeeEligibilityMetadata = (intl: IntlShape, isAttestation = false) => {
  return walmartEmployeeEligibilityMetadata(intl, isAttestation)
}

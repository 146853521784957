import { useContext } from 'react'

import { LyraIntlContext } from '../LyraIntlContext'

export const useLyraIntl = () => {
  const ctx = useContext(LyraIntlContext)
  if (ctx == null) {
    throw new Error('useLyraIntl hook must be used within LyraIntlProvider component')
  }
  return ctx
}

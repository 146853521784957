import React from 'react'

import { fromJS } from 'immutable'

import { oneLine } from '../../utils/stringUtils'
import {
  COMMON_2020_FAQ_ANSWERS,
  COMMON_2020_FAQ_QUESTIONS,
  COMMON_2021_FAQ_ANSWERS,
  COMMON_2021_FAQ_QUESTIONS,
  DEFAULT_QUESTIONS,
  WorklifeLink,
} from '../faqConstants'

const customerName = `Netflix`
const linkText = `netflix.lyrahealth.com`

export const netflix = ({
  phone,
  numberOfSessions,
  cancellationLimit,
  cancellationsIncludedInSessionLimits,
}: $TSFixMe) => {
  return fromJS({
    faqQuestions: [
      {
        question: DEFAULT_QUESTIONS.WHAT_IS_LYRA_HEALTH,
        answer: (
          <h3>
            {oneLine(`Lyra is a ${customerName}-sponsored benefit that connects employees and their
                        dependents to mental and emotional health care that is effective, convenient, and personalized.
                        Using technology, proven treatments, and a network of top therapists and coaches, Lyra matches
                        you to the right care for your needs, from therapy and coaching to mental wellness tools. Lyra
                        also provides `)}
            {WorklifeLink()}
            {oneLine(` to help you work through personal life obstacles. These services include legal,
                        identity theft, financial, and dependent care services.`)}
          </h3>
        ),
      },
      {
        question: DEFAULT_QUESTIONS.WHO_CAN_USE_LYRA,
        answer: (
          <h3>
            {oneLine(`Lyra is here to serve ${customerName} employees and their dependents who are
                        either:`)}
            <ul>
              <li>{oneLine(`A benefits-eligible Netflix Streaming employee OR`)}</li>
              <li>{oneLine(`A full-time Union or Non-Union Netflix Animation employee OR`)}</li>
              <li>{oneLine(`A Netflix DVD employee`)}</li>
            </ul>
          </h3>
        ),
      },
      {
        question: DEFAULT_QUESTIONS.TYPES_OF_ISSUES,
        answer: COMMON_2021_FAQ_ANSWERS.WHAT_TYPES_OF_ISSUES_CAN_LYRA_HELP_ME_SOLVE(),
      },
      {
        question: DEFAULT_QUESTIONS.HOW_DOES_LYRA_WORK,
        answer: COMMON_2021_FAQ_ANSWERS.HOW_DOES_LYRA_WORK(),
      },
      DEFAULT_QUESTIONS.WHAT_IS_IT_LIKE_GETTING_CARE_WITH_LYRA,
      {
        question: DEFAULT_QUESTIONS.HOW_LONG_TO_USE,
        answer: COMMON_2021_FAQ_ANSWERS.HOW_LONG_TO_USE({ phone }),
      },
      {
        question: DEFAULT_QUESTIONS.HOW_MUCH_DOES_LYRA_COST,
        answer: (
          <h3>
            {oneLine(`Your Lyra mental health benefit covers up to ${numberOfSessions} sessions of
                        mental health coaching and therapy per calendar year, at no cost to you.`)}
            <br />
            <br />
            {oneLine(`We also cover the cost of your first consultations with work life services,
                        after which you will be able to pay a discounted payment if you choose to continue engagement with
                        the service.`)}
          </h3>
        ),
      },
      {
        question: COMMON_2021_FAQ_QUESTIONS.WHAT_DOES_THERAPY_ENTAIL(),
        answer: COMMON_2021_FAQ_ANSWERS.WHAT_DOES_THERAPY_ENTAIL(),
      },
      DEFAULT_QUESTIONS.WHAT_IS_MENTAL_HEALTH_COACHING,
      DEFAULT_QUESTIONS.WHAT_IS_A_MENTAL_HEALTH_COACH,
      DEFAULT_QUESTIONS.DIFFERENCE_BETWEEN_COACHING_AND_THERAPY,
      DEFAULT_QUESTIONS.WHAT_IS_GUIDED_SELF_CARE,
      {
        question: DEFAULT_QUESTIONS.WHAT_IF_I_REACH_MY_SESSION_LIMIT,
        answer: COMMON_2021_FAQ_ANSWERS.WHAT_IF_I_REACH_MY_SESSION_LIMIT({ numberOfSessions }),
      },
      {
        question: DEFAULT_QUESTIONS.FORMATS_FOR_CARE,
        answer: COMMON_2021_FAQ_ANSWERS.FORMATS_FOR_CARE({ phone, customerName, linkText, liveMessaging: true }),
      },
      DEFAULT_QUESTIONS.WHAT_DOES_LYRA_NOT_COVER,
      {
        question: COMMON_2020_FAQ_QUESTIONS.WILL_MY_EMPLOYER_KNOW({ customerName }),
        answer: COMMON_2020_FAQ_ANSWERS.WILL_MY_EMPLOYER_KNOW({ customerName }),
      },
      {
        question: DEFAULT_QUESTIONS.WHAT_IF_I_MISS_AN_APPOINTMENT,
        answer: (
          <h3>
            {COMMON_2021_FAQ_ANSWERS.WHAT_IF_I_MISS_AN_APPOINTMENT({
              customerName,
              cancellationLimit,
              numberOfSessions,
              cancellationsIncludedInSessionLimits,
            })}
            <br />
            <br />
            {oneLine(`If you miss an appointment or need to cancel last-minute with a provider you
                        are seeing through the health plan, you will be responsible for any payments associated with missed
                        appointments or late cancellations.`)}
          </h3>
        ),
      },
      DEFAULT_QUESTIONS.HOW_TO_RECOMMEND_LYRA_TO_A_COLLEAGUE_OR_DEPENDENT,
      {
        question: COMMON_2021_FAQ_QUESTIONS.WHY_DOES_LYRA_COLLECT_FEEDBACK(),
        answer: COMMON_2021_FAQ_ANSWERS.WHY_DOES_LYRA_COLLECT_FEEDBACK(),
      },
      DEFAULT_QUESTIONS.HOW_DOES_MEETING_VIA_LIVE_VIDEO_WORK,
      DEFAULT_QUESTIONS.HOW_DOES_MEETING_VIA_LIVE_MESSAGING_WORK,
      DEFAULT_QUESTIONS.WHAT_IF_I_WANT_TO_FIND_PROVIDER_OF_COLOR,
      {
        question: DEFAULT_QUESTIONS.CAN_I_USE_LYRA_WHILE_TRAVELING,
        answer: COMMON_2021_FAQ_ANSWERS.CAN_I_USE_LYRA_WHILE_TRAVELING(),
      },
      {
        question: DEFAULT_QUESTIONS.HOW_IS_LYRA_DIFFERENT_FROM_MEDICAL_PLAN,
        answer: (
          <h3>
            {oneLine(`Lyra covers evidence-based outpatient mental health services, at no cost to you.
                        There are no co-pays, no deductibles, and no prior authorizations or referrals needed to begin
                        care.`)}
            <br />
            <br />
            {oneLine(`Your medical plan is a more appropriate option for certain types of intensive
                        care such as medication management, emergency care, long-term care, or psychiatric conditions
                        that would require hospitalization.`)}
          </h3>
        ),
      },
      {
        question: COMMON_2020_FAQ_QUESTIONS.CONTINUING_TO_SEE_PROVIDER(),
        answer: (
          <h3>
            {oneLine(`If you are currently seeing a provider and are interested in learning if your sessions
            could be covered under the Lyra benefit, you can invite your provider to apply to join Lyra at `)}
            <a href='https://lyrahealth.com/apply-now'>lyrahealth.com/apply-now</a>
            {oneLine(`. If your provider chooses to apply, we’ll evaluate their approach to evidence-based
            therapy and see if they meet other criteria to become a Lyra provider.`)}
            <br />
            <br />
            {oneLine(`To work with Lyra, your provider must:`)}
            <br />
            <ul>
              <li>{oneLine(`Practice evidence-based therapies`)}</li>
              <li>
                {oneLine(`Have the appropriate credentials, including an active and independent clinical
                  license in your state`)}
              </li>
              <li>{oneLine(`Adhere to Lyra’s policies and procedures, including the reporting of outcomes`)}</li>
            </ul>
            <br />
            {oneLine(`Of the hundreds of therapies practiced today, only 20% of them have been proven to work
            through peer-reviewed studies. Lyra only works with providers who practice these proven, evidence-based
            therapies. As such, some providers may not be a good fit for Lyra’s approach to care, or they may decide
            they don’t want to partner with Lyra for any number of reasons. If that’s the case, Lyra can recommend
            top-tier providers whose expertise matches your needs and who practice evidence-based therapy for your
            consideration. We can also help you understand all options available to you through your health plan
            coverage and other employee benefits, so you can make the best choice for you.`)}
          </h3>
        ),
      },
      DEFAULT_QUESTIONS.WORK_LIFE_SERVICES,
    ],
  })
}

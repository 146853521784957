import React, { useEffect, useMemo, useState } from 'react'

import { Address, LocationMatcherDisplayFormats } from '../../../models'
import {
  generateOnsiteLocationRadioOptions,
  getConstantOnsiteDropdownOptions,
  getConstantOnsiteLocationRadioOptions,
} from '../locationFinderUtils'

/**
 * Logit to handle showing which dropdown or radio options to show for the onsite forms
 */
export const useOnsiteOptions = ({
  addressOptions,
  selectedDropdownValue,
  customerSpecificOptions,
}: {
  addressOptions: Array<Address>
  selectedDropdownValue: { value: number; locationName: string }
  customerSpecificOptions:
    | { onsiteDescription: React.ReactNode; onsiteFormat: LocationMatcherDisplayFormats | undefined }
    | undefined
}) => {
  const doOnsiteLocationsExist = addressOptions && addressOptions.length > 0
  const radioOptions = useMemo(() => {
    return {
      [LocationMatcherDisplayFormats.RADIO_BUTTONS_ONLY]: getConstantOnsiteLocationRadioOptions(addressOptions),
      [LocationMatcherDisplayFormats.DROPDOWN_MENU_TO_RADIO_BUTTONS]: selectedDropdownValue
        ? generateOnsiteLocationRadioOptions(addressOptions, selectedDropdownValue.locationName)
        : [],
    }
  }, [addressOptions, selectedDropdownValue])

  const radioOptionsInCustomerFormat = useMemo(() => {
    return doOnsiteLocationsExist && customerSpecificOptions?.onsiteFormat
      ? radioOptions[customerSpecificOptions.onsiteFormat]
      : []
  }, [customerSpecificOptions?.onsiteFormat, doOnsiteLocationsExist, radioOptions])

  const [onsiteLocationRadioOptions, setOnsiteLocationRadioOptions] = useState(radioOptionsInCustomerFormat)

  useEffect(() => {
    setOnsiteLocationRadioOptions(radioOptionsInCustomerFormat)
  }, [radioOptionsInCustomerFormat])

  const onsiteCityDropdownItems = doOnsiteLocationsExist ? getConstantOnsiteDropdownOptions(addressOptions) : []

  return { onsiteLocationRadioOptions, onsiteCityDropdownItems, setOnsiteLocationRadioOptions, doOnsiteLocationsExist }
}

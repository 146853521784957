import { List, Map } from 'immutable'
import { createSelector } from 'reselect'

export const getCustomerCountry = (state: $TSFixMe) => state.get('customerCountry', Map())

export const getCountryListForCustomerCountry = createSelector(getCustomerCountry, (customerCountry) =>
  customerCountry?.get('customerCountryList', List()),
)

export const getCountryList = createSelector(getCustomerCountry, (customerCountry) =>
  customerCountry?.get('allCountries', List()),
)

export const getSupportedCountryList = createSelector(getCustomerCountry, (customerCountry) =>
  customerCountry?.get('supportedCountries', List()),
)

import React from 'react'
import { connect } from 'react-redux'

import { compose } from 'redux'

import { getDepartingRedirectURL } from '../../../../data/customer/customerSelectors'

const withRedirectForDepartingCustomersOnLoad =
  (WrappedComponent: $TSFixMe) =>
  ({ departingRedirectUrl, ...rest }: $TSFixMe) => {
    if (departingRedirectUrl) {
      window.location.replace(departingRedirectUrl)
      return null
    } else {
      return <WrappedComponent {...rest} />
    }
  }

const mapStateToProps = (state: $TSFixMe) => {
  return {
    departingRedirectUrl: getDepartingRedirectURL(state),
  }
}

export default compose(connect(mapStateToProps), withRedirectForDepartingCustomersOnLoad)

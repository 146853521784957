import React, { PropsWithChildren } from 'react'
import { IntlProvider } from 'react-intl'

import { useLyraIntl } from './hooks/useLyraIntl'

/**
 * Any children of this component will display translations in the selected language.
 * This allows control over the routes or product areas that should not show translations
 * while translation is still incomplete.
 */
export const EnableTranslations = ({ children }: PropsWithChildren<{}>) => {
  const { intlConfig } = useLyraIntl()
  return <IntlProvider {...intlConfig}>{children}</IntlProvider>
}

export const ToggleTranslations = ({ children, enabled }: PropsWithChildren<{ enabled: boolean }>) => {
  const { intlConfig, disabledTranslationsIntlConfig } = useLyraIntl()
  return <IntlProvider {...(enabled ? intlConfig : disabledTranslationsIntlConfig)}>{children}</IntlProvider>
}

export const enableTranslations = <P extends {}>(Component: React.ComponentType<P>) => {
  return (props: P) => (
    <EnableTranslations>
      <Component {...props} />
    </EnableTranslations>
  )
}

import React from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

/**
 * Standard phone icon
 */
export const PhoneIcon: React.FC<PhoneIconProps> = ({ size = 24, fillColor, isFilled = false, rotation = 0 }) => {
  const { colors } = useTheme()
  const { phoneIcon } = colors.icons

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' rotation={rotation} testID={tID('PhoneIcon')}>
      {isFilled ? (
        <Path
          fill={fillColor || phoneIcon.fill}
          d='M6.88090398,10.1629849 C8.40090398,13.1502071 10.8497929,15.5885405 13.8370151,17.119096 L15.5994444,15.3566667 C15.8844444,15.0716667 16.3066667,14.9766667 16.6761111,15.1033333 C17.8583333,15.4938889 19.1355556,15.705 20.4444444,15.705 C21.025,15.705 21.5,16.18 21.5,16.7605556 L21.5,20.4444444 C21.5,21.025 21.025,21.5 20.4444444,21.5 C10.5327778,21.5 2.5,13.4672222 2.5,3.55555556 C2.5,2.975 2.975,2.5 3.55555556,2.5 L7.25,2.5 C7.83055556,2.5 8.30555556,2.975 8.30555556,3.55555556 C8.30555556,4.875 8.51666667,6.14166667 8.90722222,7.32388889 C9.02333333,7.69333333 8.93888889,8.105 8.64333333,8.40055556 L6.88090398,10.1629849 Z'
        />
      ) : (
        <Path
          fill={fillColor || phoneIcon.fill}
          fillRule='evenodd'
          d='M6.88090398,10.1629849 C8.40090398,13.1502071 10.8497929,15.5885405 13.8370151,17.119096 L15.5994444,15.3566667 C15.8844444,15.0716667 16.3066667,14.9766667 16.6761111,15.1033333 C17.8583333,15.4938889 19.1355556,15.705 20.4444444,15.705 C21.025,15.705 21.5,16.18 21.5,16.7605556 L21.5,20.4444444 C21.5,21.025 21.025,21.5 20.4444444,21.5 C10.5327778,21.5 2.5,13.4672222 2.5,3.55555556 C2.5,2.975 2.975,2.5 3.55555556,2.5 L7.25,2.5 C7.83055556,2.5 8.30555556,2.975 8.30555556,3.55555556 C8.30555556,4.875 8.51666667,6.14166667 8.90722222,7.32388889 C9.02333333,7.69333333 8.93888889,8.105 8.64333333,8.40055556 L6.88090398,10.1629849 Z M20.3125,16.8918481 L20.3125,20.3119916 C11.1613753,20.2414474 3.75855264,12.8386247 3.68800841,3.6875 L7.11869816,3.6875 C7.13185084,5.03702148 7.34675201,6.35640353 7.7521994,7.61231088 L5.43149688,9.9330134 L5.82253784,10.7015175 C7.45228403,13.9044215 10.0784241,16.5276142 13.2955182,18.1759486 L14.0651876,18.5703022 L16.3798368,16.2556529 C17.6428494,16.6629704 18.9701049,16.8785823 20.3125,16.8918481 Z'
        />
      )}
    </Svg>
  )
}

type PhoneIconProps = {
  size?: string | number
  fillColor?: string
  isFilled?: boolean
  rotation?: number
}

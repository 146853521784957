import React, { FunctionComponent } from 'react'
import Svg, { G, Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

/**
 * Exclamation mark icon that has a circle around it
 */
export const AlertIconStroke: FunctionComponent<AlertIconStrokeProps> = ({ size = 16, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg testID={tID('AlertIconStroke')} width={size} height={size} viewBox='0 0 16 16' fill='none'>
      <G id='alertIcon'>
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2ZM8 0.499999C12.1421 0.499999 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.499999 8C0.499999 3.85786 3.85786 0.5 8 0.499999Z'
          fill={fillColor || colors.textWarning}
        />
        <G id='innerGroup'>
          <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8 8.83325C8.41421 8.83325 8.75 8.49747 8.75 8.08325L8.75 5.41658C8.75 5.00237 8.41421 4.66658 8 4.66658C7.58579 4.66658 7.25 5.00237 7.25 5.41658L7.25 8.08325C7.25 8.49747 7.58579 8.83325 8 8.83325Z'
            fill={fillColor || colors.textWarning}
          />
          <Path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.25 10.75C7.25 11.1642 7.58579 11.5 8 11.5L8.00667 11.5C8.42088 11.5 8.75667 11.1642 8.75667 10.75C8.75667 10.3358 8.42088 10 8.00667 10L8 10C7.58579 10 7.25 10.3358 7.25 10.75Z'
            fill={fillColor || colors.textWarning}
          />
        </G>
      </G>
    </Svg>
  )
}

type AlertIconStrokeProps = {
  size?: string | number
  fillColor?: string
}

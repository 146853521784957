import { IntlShape } from 'react-intl'

import { Attestations, FormMetadata } from '../../models'
import { extendMetadata } from '../assignments/utils'

export const extendMetadataAttestations = ({
  attestationContents,
  errorMessage,
  metadata,
  intl,
  checkboxProps,
}: {
  attestationContents: Attestations
  errorMessage: {
    defaultMessage: string
    description: string
  }
  metadata: FormMetadata
  intl: IntlShape
  checkboxProps?: Dict
}) => {
  return attestationContents.reduce((combinedSchema, attestation, index) => {
    const fieldName = `attestation-${index}`
    combinedSchema = extendMetadata(combinedSchema, {
      schema: {
        properties: {
          [`${fieldName}`]: {
            name: fieldName,
            title: attestation.attestationText,
            type: 'boolean',
            validation: {
              isBooleanTrue: true,
            },
            customErrorMessage: intl.formatMessage(errorMessage),
            ...(checkboxProps && { widgetProps: checkboxProps }),
          },
        },
      },
      uiSchema: {
        'ui:order': [fieldName],
        [fieldName]: {
          'ui:widget': 'checkbox',
          'ui:options': {
            baseInputStyle: {
              marginBottom: 32,
            },
          },
        },
      },
    })
    return combinedSchema
  }, metadata)
}

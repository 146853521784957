import { axiosInstance } from '../../../common/http/axiosInstance'
import GraphqlSchema from '../../../data/graphql-queries/graphql-schema'
import { ProviderEditableData, ProviderProfileFullDetail } from '../../../data/graphql-queries/provider-profile-graphql'
import { setGraphqlTypeMapper } from '../../../data/graphql-type-mapper/graphql-type-mapper-actions'
import * as NotesActions from '../notes/notes-actions'

export const SET_DIRECT_ACCESS_PROVIDER = 'SET_DIRECT_ACCESS_PROVIDER'
export const setDirectAccessProvider = (providerProfile: $TSFixMe) => ({
  type: SET_DIRECT_ACCESS_PROVIDER,
  providerProfile,
})

export function getDirectAccessProvider(lyraId: $TSFixMe) {
  return function (dispatch: $TSFixMe, _getState: $TSFixMe) {
    const query = `
query DirectAccessDetailSearch {
    providerProfile(lyraId:"${lyraId}"){
        ...ProviderProfileFullDetail
    }
    editableData: providerProfile(lyraId:"${lyraId}"){
        ...ProviderEditableData
    }
    providerNotes: providerNotes(method:"getNotesByLyraId", lyraId: "${lyraId}"){
    lyraId
    text
    date
    dateString
    author
    notesId
    noteType
  }
    ${GraphqlSchema.getQuery()}
}
    ${ProviderProfileFullDetail}
    ${ProviderEditableData}
`
    const data = {
      query,
      variables: { var: 'hi' },
      operationName: 'DirectAccessDetailSearch',
    }
    const url = '/v1/graphql'
    dispatch(NotesActions.clearEditableNote())
    axiosInstance.post(url, data, { timeout: 60000 }).then(({ data: r }) => {
      const mapper = GraphqlSchema.transformSchemaToMap(r.data.__schema)
      dispatch(setGraphqlTypeMapper(mapper))
      dispatch(setDirectAccessProvider(r.data.providerProfile))
      dispatch(NotesActions.setNotes(lyraId, r.data.providerNotes))
      console.log(r)
    })
  }
}

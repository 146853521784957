import React from 'react'
import { defineMessage } from 'react-intl'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'
import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

export const maxar: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return {
    displaySessionLimit: false,
    servicesNotCovered:
      'Services that are not covered include: inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, autism spectrum disorder treatment, services for remedial education, mental health coaching, executive coaching, and non-evidence-based behavioral health care. Please check with your medical plan or benefits portal for coverage of these excluded services.',
    legalNotice: (
      <CoverageNotice
        excludedServices={defineMessage({
          defaultMessage:
            'Lyra does not cover inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, autism spectrum disorder treatment, services for remedial education, mental health coaching, executive coaching, and non-evidence-based behavioral health care.',
          description:
            'Text for describing the services that Lyra does not cover, displayed as part of the coverage notice',
        })}
        sessionLimit={numberOfSessions}
      />
    ),
  }
}

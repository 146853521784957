import React, { FunctionComponent } from 'react'
import { Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Activity Icon
 */
export const ActivityIcon: FunctionComponent<ActivityIconProps> = ({ size = 78, xRadius = 39 }) => {
  const { colors } = useTheme()
  const {
    notepadBack,
    notepadPaper,
    notepadText,
    notepadSpiral,
    pencil,
    pencilFerrule,
    pencilEraser,
    pencilWood,
    pencilTip,
  } = colors.icons.activityIcon

  return (
    <Svg width={size} height={size} fill='none'>
      <Rect width={size} height={size} rx={xRadius} fill={colors.backgroundHighlightTeal} />
      <Path
        d='M19 31.1983C19 31.1983 21.1772 38.7901 22.9056 44.0001C24.634 49.2102 26.9857 56.9951 26.9857 56.9951C26.9857 56.9951 33.5796 55.0854 38.7483 53.6477C43.917 52.21 47.5816 51.0341 47.5816 51.0341L48.1757 49.4677L19.6066 29.6318L19 31.1983Z'
        fill={notepadBack.fill}
      />
      <Path
        d='M19.6064 29.6318C19.6064 29.6318 22.2116 38.6957 23.94 43.9014C25.6685 49.1072 27.6005 55.5145 27.6005 55.5145C27.6005 55.5145 33.8328 53.7378 39.0223 52.3001C44.2118 50.8624 48.1922 49.4677 48.1922 49.4677L41.8518 22.6279C41.8518 22.6279 34.2109 24.4604 29.8607 25.8209C26.2501 26.9496 19.6064 29.6318 19.6064 29.6318Z'
        fill={notepadPaper.fill}
      />
      <Path
        d='M25.1656 34.437C29.3869 33.0508 33.5419 31.523 37.7175 30.0596C38.6316 29.742 39.5498 29.4201 40.4681 29.1197C40.8836 28.9738 40.7174 28.2614 40.2769 28.4073C36.0722 29.8063 31.9131 31.3299 27.725 32.7847C26.8109 33.1023 25.8927 33.4199 24.9744 33.7246C24.534 33.8705 24.721 34.5829 25.1614 34.437H25.1656Z'
        fill={notepadText.fill}
      />
      <Path
        d='M25.9219 36.2453C28.2279 35.4728 30.4923 34.5759 32.7899 33.7519C35.0876 32.9279 37.3603 32.0352 39.7369 31.576C40.1898 31.4902 39.9987 30.7778 39.5458 30.8636C37.1775 31.2928 34.8674 32.224 32.5988 33.0395C30.3302 33.8549 28.0284 34.7561 25.7308 35.5329C25.3153 35.6788 25.4815 36.3912 25.9219 36.2453Z'
        fill={notepadText.fill}
      />
      <Path
        d='M26.9275 38.3301C29.4827 37.7936 32.1336 37.2743 34.5476 36.2186C34.963 36.0298 34.6057 35.3946 34.1861 35.5792C33.0885 36.0315 31.9592 36.3973 30.8081 36.6735C29.4578 37.0297 28.0991 37.3301 26.7363 37.6177C26.2835 37.7121 26.4746 38.4245 26.9275 38.3301Z'
        fill={notepadText.fill}
      />
      <Path
        d='M23.2335 29.1555C22.3652 28.0868 22.4981 26.0569 23.2585 25.2029C23.6854 24.7577 24.2123 24.4287 24.7924 24.2453C25.3724 24.0618 25.9874 24.0296 26.5824 24.1514C27.1294 24.2958 27.6167 24.6184 27.9722 25.0714C28.3278 25.5244 28.5324 26.0837 28.556 26.6663C28.556 27.2071 28.2277 27.9152 27.5837 27.7092C26.2625 27.28 27.0394 25.3402 27.4923 24.5548C27.7828 24.0388 28.1819 23.5969 28.6602 23.2618C29.1385 22.9268 29.6837 22.7072 30.2553 22.6193C32.0918 22.3661 34.8257 25.0183 33.6748 26.9796C33.6176 27.0871 33.5328 27.1763 33.4297 27.2371C33.2833 27.2859 33.1261 27.2887 32.9782 27.2451C32.8303 27.2016 32.6982 27.1136 32.5987 26.9925C32.3502 26.7054 32.1752 26.3587 32.0896 25.9839C32.0039 25.6092 32.0104 25.2182 32.1084 24.8467C32.4159 23.2159 34.0944 21.3404 35.8146 21.3705C37.6594 21.4005 38.5236 23.306 38.2577 24.9626'
        stroke={notepadSpiral.fill}
        strokeWidth='2.15'
        strokeMiterlimit='10'
      />
      <Path d='M44.4111 34.5186L41.9307 38.3767L53.394 45.8312L55.9618 41.9044L44.4111 34.5186Z' fill={pencil.fill} />
      <Path
        d='M53.394 45.8321L54.4702 46.5316L57.1584 42.6949L55.9618 41.9053L53.394 45.8321Z'
        fill={pencilFerrule.fill}
      />
      <Path
        d='M54.4702 46.5451C54.4702 46.5451 56.9632 48.399 58.3592 46.309C59.7553 44.219 57.1584 42.6826 57.1584 42.6826L54.4702 46.5451Z'
        fill={pencilEraser.fill}
      />
      <Path
        d='M41.9306 38.3907C41.5971 37.8007 41.2934 37.1934 41.0207 36.571C40.5803 35.541 40.1897 34.3694 40.2769 34.2278C40.3642 34.0862 41.4195 34.1034 42.4375 34.2278C43.4554 34.3523 44.4069 34.5411 44.4069 34.5411L41.9306 38.3907Z'
        fill={pencilWood.fill}
      />
      <Path
        d='M41.1333 35.2917C41.4199 34.9226 41.6983 34.5492 41.9642 34.1673C41.1083 34.1115 40.3272 34.1287 40.2773 34.2274C40.3319 34.764 40.4723 35.2875 40.6928 35.7766L41.1333 35.2917Z'
        fill={pencilTip.fill}
      />
    </Svg>
  )
}

type ActivityIconProps = {
  size?: number
  xRadius?: number
}

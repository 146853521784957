import React from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const ArticleLayerIcon: React.FC<ArticleLayerIconProps> = ({ width = 16, height = 16 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={width} height={height} viewBox='0 0 12 12' fill='none'>
      <Path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8 10.5C8 9.94772 7.58672 9.5 7.07692 9.5L0.923077 9.5C0.413275 9.5 -5.06524e-08 9.94772 -1.13135e-07 10.5C-1.75618e-07 11.0523 0.413275 11.5 0.923076 11.5L7.07692 11.5C7.58672 11.5 8 11.0523 8 10.5Z'
        fill={colors.iconInactive}
      />
      <Path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11 6C11 5.44772 10.5655 5 10.0294 5L0.970588 5C0.434546 5 -5.06524e-08 5.44772 -1.13135e-07 6C-1.75618e-07 6.55229 0.434546 7 0.970588 7L10.0294 7C10.5655 7 11 6.55229 11 6Z'
        fill={colors.iconInactive}
      />
      <Path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11 1.5C11 0.947714 10.5655 0.499999 10.0294 0.499999L0.970588 0.499998C0.434546 0.499998 -5.06524e-08 0.947714 -1.13135e-07 1.5C-1.75618e-07 2.05228 0.434546 2.5 0.970588 2.5L10.0294 2.5C10.5655 2.5 11 2.05228 11 1.5Z'
        fill={colors.iconInactive}
      />
    </Svg>
  )
}
type ArticleLayerIconProps = {
  width?: string | number
  height?: string | number
}

import React, { FunctionComponent, useEffect, useRef } from 'react'
import LottieView from 'lottie-react-native'
import splashLogo from '../../assets/lottie/SplashLogo.json'
import styled from 'styled-components/native'
import { tID } from '../../utils/utils'

const Container = styled.View<{ size: number | string }>(({ size }) => ({
  height: `${size}px`,
  width: `${size}px`,
}))

export interface SplashLogoProps {
  size?: string | number
}

export const SplashLogo: FunctionComponent<SplashLogoProps> = ({ size = 290 }) => {
  const animationRef = useRef<LottieView>(null)

  useEffect(() => {
    // set a specific startFrame and endFrame for animation, only show last frame for cypress
    window.Cypress ? animationRef.current?.play(119, 120) : animationRef.current?.play()
  }, [])

  return (
    <Container size={size} testID={tID('SplashLogo')}>
      <LottieView ref={animationRef} source={splashLogo} loop={false} autoPlay style={{ width: size, height: size }} />
    </Container>
  )
}

import { useEffect, useState } from 'react'

import { Customer } from '../../../models'
import { CARE_ADVISOR_DISPLAY_PHONE_NUMBER, FetchStatus } from '../../common/constants/constants'
import { COMMON_MIXPANEL_EVENTS } from '../../common/constants/mixpanelConstants'

/**
 * Helps handle text input logic and errors for in the GooglePlacesField component
 * Hook for logic in Footer component
 */
export const useFooter = ({
  customer,
  isLoggedIn,
  isCustomerInternational,
  isUserInternational,
  detectedCountryFetchStatus,
  enabledChat,
  pathname,
  trackEventWithObj,
  onNavigate,
}: {
  customer: Customer
  isLoggedIn: boolean
  isCustomerInternational: boolean
  isUserInternational: boolean
  detectedCountryFetchStatus: FetchStatus
  enabledChat: boolean
  pathname: string
  trackEventWithObj: ({
    event,
    action,
    page,
  }: {
    event: string
    action?: string
    page?: string
    [key: string]: unknown
  }) => void
  onNavigate: (path: string) => void
}) => {
  const isInternational = isCustomerInternational || isUserInternational
  const disableLiveChat = isInternational
    ? detectedCountryFetchStatus === FetchStatus.SUCCESS && !enabledChat
    : !enabledChat
  const [startChat, setStartChat] = useState(false)

  let phoneNumber = null
  if (pathname === '/worklife' && customer.customerPropertiesMap?.worklifephone) {
    phoneNumber = customer.customerPropertiesMap.worklifephone
  } else {
    if (customer.id) {
      // wait until customer info is populated to avoid premature flash of fallback number
      phoneNumber = customer.customerPropertiesMap?.phone ?? CARE_ADVISOR_DISPLAY_PHONE_NUMBER
    }
  }

  useEffect(() => {
    if (startChat) {
      setStartChat(false)
    }
  }, [startChat])

  useEffect(() => {
    if (isInternational && detectedCountryFetchStatus === FetchStatus.ERROR) {
      trackEventWithObj({ event: COMMON_MIXPANEL_EVENTS.CHAT_COUNTRY_DETECTION_FAILURE })
    }
  }, [isInternational, detectedCountryFetchStatus, trackEventWithObj])

  const navigateToFAQ = () => {
    isLoggedIn && !isUserInternational ? onNavigate('/faq') : onNavigate('/#faq')
  }

  const handleChatLivePress = () => {
    setStartChat(!startChat)
  }

  const navigateToCareTeamPage = () => {
    onNavigate('/care-team-phone-numbers')
  }

  return { disableLiveChat, startChat, phoneNumber, navigateToCareTeamPage, navigateToFAQ, handleChatLivePress }
}

import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { CLEAR_DISPLAYED_PROVIDERS, GET_PROVIDER_RECOMMENDATIONS } from '../../data/onboardDataActions'

const initialState = fromJS({})

export default createReducer(initialState, {
  [GET_PROVIDER_RECOMMENDATIONS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('totalNumberOfProviders', action.totalNumberOfProviders)
    $$state = $$state.set(
      'numberOfProvidersMatchingModalityPreference',
      action.numberOfProvidersMatchingModalityPreference,
    )
    $$state = $$state.set('numberOfNonTopProviders', action.numberOfNonTopProviders)
    $$state = $$state.set('providersDisplayed', action.providersDisplayed)
    $$state = $$state.set('hasSeenExpandedSearchModal', false) // reset expanded search modal visibility every time new recommendations are set
    return $$state
  },
  [CLEAR_DISPLAYED_PROVIDERS]: ($$state: $TSFixMe) => {
    $$state = $$state.set('providersDisplayed', [])
    return $$state
  },
})

import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { AnyAction, Dispatch } from 'redux'

import { BaseModal } from '@lyrahealth-inc/ui-core'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import * as ModalActions from './modal-actions'

interface GenericModalViewProps {
  isOpen: boolean
  modalClass: string
  showCloseButton: boolean
  content: string
  closeModal: () => void
  customStyles: Dict
}

const GenericModalView: FunctionComponent<GenericModalViewProps> = ({
  isOpen,
  modalClass,
  closeModal,
  content,
  showCloseButton,
  customStyles,
}) => {
  return (
    <BaseModal
      isOpen={isOpen}
      modalClass={modalClass}
      showCloseButton={showCloseButton}
      body={content}
      closeModal={closeModal}
      customStyles={customStyles}
    />
  )
}

const mapStateToProps = (state: any) => {
  const modal = state.modal ? state.modal : state.get('modal') // transitioning to redux-immutablejs...everything is immutable in redux-immutable
  return {
    isOpen: modal.get('isOpen'),
    content: modal.get('content', ''),
    showCloseButton: modal.getIn(['modalProps', 'showCloseButton'], true),
    modalClass: modal.getIn(['modalProps', 'modalClass'], ''),
    customStyles: modal.getIn(['modalProps', 'customStyles'], undefined),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    closeModal: () => {
      dispatch(ModalActions.closeModal())
    },
  }
}

const GenericModalContainer = connect(mapStateToProps, mapDispatchToProps)(toJS(GenericModalView))

export default GenericModalContainer

import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { IntlProvider } from 'react-intl'

import { IntlErrorCode, OnErrorFn } from '@formatjs/intl'

import { LyraIntl, LyraIntlState } from './LyraIntl'
import { LyraIntlContext, LyraIntlContextType } from './LyraIntlContext'

export type LyraIntlProps<LanguageTag extends string> = PropsWithChildren<{
  intl: LyraIntl<LanguageTag>
  countryIsoCode: string
  timeZone: string
  initialLanguage?: string | LanguageTag | null
  textComponent?: React.ComponentType
}>

const onError: OnErrorFn = (error) => {
  if (error.code === IntlErrorCode.MISSING_TRANSLATION) {
    return
  }
  console.error(error)
}

export function LyraIntlProvider<LanguageTag extends string>({
  intl,
  countryIsoCode,
  timeZone,
  initialLanguage = null,
  textComponent,
  children,
}: LyraIntlProps<LanguageTag>) {
  const [intlState, setIntlState] = useState<LyraIntlState<LanguageTag> | null>(null)

  useEffect(() => {
    intl.initLocale(initialLanguage, countryIsoCode).then((newIntlState) => setIntlState(newIntlState))
  }, [intl, initialLanguage, countryIsoCode])

  const contextValue = useMemo(
    (): LyraIntlContextType | null =>
      intlState
        ? {
            availableLanguages: intl.config.languages,
            activeLanguage: intlState.language,
            resolved: intlState.resolved,
            // Provide the full config including messages for <EnableTranslations>
            intlConfig: {
              ...intlState.reactIntlConfig,
              timeZone,
              onError,
              textComponent,
            },
            disabledTranslationsIntlConfig: {
              ...intlState.disabledTranslationsReactIntlConfig,
              timeZone,
              onError,
              textComponent,
            },
            setLanguage: (lang) => {
              intl.changeLanguage(lang as LanguageTag, countryIsoCode).then((newIntlState) => {
                setIntlState(newIntlState)
              })
            },
          }
        : null,
    [countryIsoCode, intl, intlState, timeZone, textComponent],
  )

  if (contextValue == null) {
    return null
  }

  return (
    <LyraIntlContext.Provider value={contextValue}>
      <IntlProvider {...contextValue.disabledTranslationsIntlConfig}>{children}</IntlProvider>
    </LyraIntlContext.Provider>
  )
}

import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { ThemeType } from '../../utils'

const BackgroundImage = styled.ImageBackground<{ theme: ThemeType }>(({ theme }) => ({
  width: '100%',
  ...(theme.breakpoints.isMobileSized && { padding: `0 ${theme.spacing['24px']}` }),
  // sizes from laptop up to (but not including) desktop
  ...(theme.breakpoints.isMinWidthLaptop && !theme.breakpoints.isMinWidthDesktop && { padding: '0 100px' }),
  // sizes from tablet up to (but not including) laptop
  ...(theme.breakpoints.isMinWidthTablet &&
    !theme.breakpoints.isMinWidthLaptop && { padding: `0 ${theme.spacing['48px']}` }),
}))

const HomepageContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  height: '100%',
  alignItems: 'center',
  width: '100%',
  ...(theme.breakpoints.isMobileSized && { paddingLeft: theme.spacing['16px'], paddingRight: theme.spacing['16px'] }),
}))

export interface FAQSectionProps {
  ctaAndCollapsibleFAQsSection: JSX.Element
  bottomCTA: JSX.Element
  hasBackgroundImage?: boolean
  backgroundContainerProps?: { [key: string]: any }
}

export const FAQSection: FunctionComponent<FAQSectionProps> = ({
  ctaAndCollapsibleFAQsSection,
  bottomCTA,
  hasBackgroundImage = false,
  backgroundContainerProps,
}) => {
  const BackgroundContainer = hasBackgroundImage ? BackgroundImage : View

  return (
    // @ts-expect-error TS(2604): JSX element type 'BackgroundContainer' does... Remove this comment to see the full error message
    <BackgroundContainer {...backgroundContainerProps} id='faq'>
      <HomepageContainer>
        {ctaAndCollapsibleFAQsSection}
        {bottomCTA}
      </HomepageContainer>
    </BackgroundContainer>
  )
}

import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Essentials Icon currently used on the bottom nav
 */
export const EssentialsIcon: FunctionComponent<EssentialsIconProps> = ({ size = 26, fillColor }) => {
  const { colors } = useTheme()
  const { fill } = colors.icons.essentialsIcon

  return (
    <Svg width={size} height={size} viewBox='0 -1 26 26' fill='none'>
      {
        <Path
          d='M6.07895 2.25H16.5263C17.4928 2.25 18.2763 3.0335 18.2763 4V5.38055L18.4998 5.38053C19.4664 5.38044 20.25 6.16397 20.25 7.13053V19C20.25 20.5188 19.0188 21.75 17.5 21.75H6.5C4.98257 21.75 3.75 20.5229 3.75 19.0028V4.56522C3.75 4.04135 3.88437 3.59869 4.12437 3.24185C4.36098 2.89004 4.67057 2.66543 4.95637 2.52375C5.23939 2.38346 5.51322 2.31704 5.70991 2.28453C5.81004 2.26797 5.89511 2.25941 5.95805 2.25494C5.98964 2.2527 6.01603 2.25147 6.03636 2.2508L6.06234 2.25015L6.07181 2.25003L6.07564 2.25001L6.07733 2.25L6.07895 2.25C6.07932 2.25 6.07886 2.25 6.07895 3V2.25ZM6.08527 3.75C6.08199 3.75012 6.07477 3.75043 6.06416 3.75119C6.04075 3.75285 6.00246 3.75652 5.95457 3.76444C5.8552 3.78087 5.7343 3.81231 5.62258 3.86769C5.51364 3.92169 5.4285 3.99058 5.36906 4.07896C5.31299 4.16232 5.25 4.30657 5.25 4.56522C5.25 4.82393 5.313 4.96821 5.36906 5.05157C5.4285 5.13994 5.51363 5.20881 5.62254 5.2628C5.73425 5.31816 5.85513 5.34959 5.95449 5.36601C6.00238 5.37392 6.04067 5.37759 6.06407 5.37925C6.07471 5.38 6.08193 5.38032 6.08521 5.38043L16.7763 5.38061V4C16.7763 3.86193 16.6644 3.75 16.5263 3.75H6.08527ZM6.11842 6.88043L6.07893 6.88043L6.07895 6.20377C6.07892 6.88043 6.0793 6.88043 6.07893 6.88043L6.07742 6.88043L6.07573 6.88043L6.0719 6.8804L6.06243 6.88029L6.03645 6.87964C6.01611 6.87897 5.98973 6.87774 5.95813 6.8755C5.89519 6.87105 5.81012 6.86249 5.70998 6.84595C5.58279 6.82493 5.42334 6.78974 5.25 6.72957V19.0028C5.25 19.6918 5.80829 20.25 6.5 20.25H17.5C18.1904 20.25 18.75 19.6904 18.75 19V7.13053C18.75 6.99245 18.6381 6.88052 18.5 6.88053L12.75 6.88055V12.7826C12.75 13.0343 12.6238 13.2692 12.4139 13.4081C12.204 13.547 11.9384 13.5713 11.7067 13.4729L9.26095 12.4338L7.20149 13.4546C6.96901 13.5698 6.69349 13.5567 6.47301 13.4199C6.25253 13.2831 6.11842 13.0421 6.11842 12.7826V6.88043ZM7.61842 6.88053V11.5738L8.90377 10.9367C9.09988 10.8395 9.32866 10.8328 9.53011 10.9184L11.25 11.6491V6.88053H7.61842Z'
          fill={fillColor || fill}
        />
      }
    </Svg>
  )
}

type EssentialsIconProps = {
  size?: number | string
  fillColor?: string
}

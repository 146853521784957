import { useCallback, useState } from 'react'

/**
 * Helps handle text input logic and errors for in the GooglePlacesField component
 */
export const useGooglePlacesFieldTextInput = ({
  selectedRadioValue,
  formattedAddress,
  currentLocationTextInput,
}: {
  selectedRadioValue: number
  formattedAddress: string
  currentLocationTextInput: string
}) => {
  const [textInputError, setTextInputError] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)

  // Checks whether we have a valid location within LocationFinder
  const isInvalidInput = useCallback(() => {
    return selectedRadioValue === 0 && currentLocationTextInput === ''
  }, [currentLocationTextInput, selectedRadioValue])

  // Checks whether free text option or Google API generated text
  const isFreeTextOption = useCallback(() => {
    return selectedRadioValue === 0 && (currentLocationTextInput !== formattedAddress || formattedAddress === '')
  }, [currentLocationTextInput, formattedAddress, selectedRadioValue])

  return { textInputError, setTextInputError, submitAttempted, setSubmitAttempted, isInvalidInput, isFreeTextOption }
}

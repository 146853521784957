/* DEPRECATING - Use NavigationMenu */

import React from 'react'
import classNames from 'classnames'
import styles from './hamburgerMenu.module.scss'

type Props = {
  iconColor?: string
  title?: string
  alignment?: string
  className?: string
  trackMenuClick?: $TSFixMeFunction
}

type State = $TSFixMe

class HamburgerMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isOpen: false,
      isMouseOverListItem: false,
      menuHasTriggered: false,
    }
  }

  _handleDropdownClick = () => {
    this.setState({ isOpen: !this.state.isOpen })
    this.setState({ menuHasTriggered: true })
    if (this.props.trackMenuClick) {
      this.props.trackMenuClick()
    }
  }

  _handleDropdownBlur = () => {
    if (!this.state.isMouseOverListItem) {
      this.setState({ isOpen: false })
    }
  }

  _renderHamburger = (iconColor: $TSFixMe) => {
    let btnToggleClass = ''
    if (this.state.isOpen === false && this.state.menuHasTriggered === false) btnToggleClass = ''
    if (this.state.isOpen === true) btnToggleClass = 'open'
    if (this.state.isOpen === false && this.state.menuHasTriggered === true) btnToggleClass = 'closed'

    const btnClasses = classNames(
      styles.hamburger,
      btnToggleClass && styles[`${btnToggleClass}`],
      iconColor && styles[`${iconColor}`],
    )

    return (
      <div className={btnClasses}>
        <div className={styles['button-content']}>
          <span />
          <span />
          <span />
        </div>
      </div>
    )
  }

  _renderInnerNodes = (alignment: $TSFixMe, title: $TSFixMe, iconColor: $TSFixMe) => {
    if (alignment === 'right') {
      return (
        <div className={styles['btn-inner-container']}>
          <span className={styles.title}>{title}</span>
          {this._renderHamburger(iconColor)}
        </div>
      )
    } else {
      return (
        <div className={styles['btn-inner-container']}>
          {this._renderHamburger(iconColor)}
          <span className={styles.title}>{title}</span>
        </div>
      )
    }
  }

  render() {
    const { title, iconColor = '', alignment = 'right', className } = this.props

    return (
      <div className={classNames(className, styles['dropdown-container'], styles[`align-${alignment}`])}>
        <button
          className={styles['dropdown-button']}
          onClick={this._handleDropdownClick}
          onBlur={this._handleDropdownBlur}
          aria-expanded={this.state.isOpen}
        >
          {this._renderInnerNodes(alignment, title, iconColor)}
        </button>

        <ul
          className={this.state.isOpen ? styles['dropdown-list-open'] : styles['dropdown-list-closed']}
          onMouseLeave={() => {
            this.setState({ isMouseOverListItem: false })
          }}
          onMouseEnter={() => {
            this.setState({ isMouseOverListItem: true })
          }}
        >
          {this.props.children}
        </ul>
      </div>
    )
  }
}

export default HamburgerMenu

import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { tID } from '../../utils'
import { ImageComponentProps } from '../formElements/RadioButtons'
import { Image } from '../image/Image'

const WellnessCheckInIllustrationPNG = styled(Image)<{ width: number; height: number }>(({ width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
}))

export const WellnessCheckInOverviewIllustration: FunctionComponent<ImageComponentProps> = ({
  width = 96,
  height = 96,
}) => {
  return (
    <WellnessCheckInIllustrationPNG
      source={require('../../assets/WellnessCheckInOverviewIllustration.png')}
      testID={tID('WellnessCheckInOverviewIllustration')}
      width={width}
      height={height}
    />
  )
}

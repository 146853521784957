// Treatment options as returned from triage.
// keys = our common terminology we use in discussions

// values = the keys used in triage to represent each treatment option
export enum TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND {
  COACHING = 'stressManagement',
  LIVE_MESSAGING_COACHING = 'stressManagementLM',
  GUIDED_SELF_CARE = 'singleSessionCoaching',
  DIRECT_ACCESS_MEDS = 'medsSpecialistMD',
  BLENDED_CARE_MEDS = 'lyraMedManagement',
  INDIVIDUAL_THERAPY = 'individualTherapy',
  INDIVIDUAL_THERAPY_ICAS_PHONE = 'individualTherapyICASPhone',
  /**
   * On the backend, blended care therapy is the value of individualTherapy since
   * that value encompasses DA and BCT, on the FE, we map it to a different value
   * so we can display it
   */
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  BLENDED_CARE_THERAPY = 'individualTherapy',
  FAMILY_THERAPY = 'familyTherapy',
  COUPLES_THERAPY = 'couplesTherapy',
  SELF_CARE_APPS = 'selfCareApps',
  FAMILY_THERAPY_CHILD = 'familyTherapyChild',
  INDIVIDUAL_THERAPY_CHILD = 'individualTherapyChild',
  DIRECT_ACCESS_MEDS_CHILD = 'medsSpecialistMDChild',
  ALCOHOL_USE_DISORDER = 'alcoholUseDisorderTherapy',
}

/**
 * These are treatment options that the front end sets and does not come from the backend
 */
export enum TREATMENT_OPTIONS_FROM_FRONT_END {
  // Do not send blendedCareTherapy to BE when creating/handling appts, it should be sent individualTherapy
  BLENDED_CARE_THERAPY = 'blendedCareTherapy',
  ONSITE_THERAPY = 'onsiteTherapy',
  // the below are used for "Med Options Explained page" that aren't the same as the above treatment cards
  CONTROLLED_MEDICATION = 'controlledMedication',
  MEDICATION_CONSULTATION = 'medicationConsultation',
  CLINICAL_LEAVE_EVALUATION = 'clinicalLeaveEvaluation',
  INDIVIDUAL_THERAPY_CHILD_ICAS = 'individualTherapyChildICAS',
}

/**
 * Maps the Frontend representation of the treatment option to the value that the backend accepts
 */
const TREATMENT_OPTIONS_FRONT_END_TO_BACKEND = {
  [TREATMENT_OPTIONS_FROM_FRONT_END.BLENDED_CARE_THERAPY]: TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.BLENDED_CARE_THERAPY,
  [TREATMENT_OPTIONS_FROM_FRONT_END.MEDICATION_CONSULTATION]: TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.BLENDED_CARE_MEDS,
  [TREATMENT_OPTIONS_FROM_FRONT_END.CONTROLLED_MEDICATION]: TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.DIRECT_ACCESS_MEDS,
}

/**
 * The combined Front end and Back end values
 */
export type TREATMENT_OPTIONS_TYPE = TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND | TREATMENT_OPTIONS_FROM_FRONT_END

/**
 * Given a treatment option from the FE or BE convert it to a value that can be sent to the BE
 */
export const convertTreatmentOptionValueForBackend = (treatmentType: TREATMENT_OPTIONS_TYPE) => {
  // If there is a different mapping for FE/BE return it, else return the original one passed as it is already the BE value expected
  return TREATMENT_OPTIONS_FRONT_END_TO_BACKEND[treatmentType] ?? treatmentType
}

export const TREATMENT_OPTIONS = {
  ...TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND,
  ...TREATMENT_OPTIONS_FROM_FRONT_END,
}

// eligible treatment options to display copied over from logic in
// apps/lyrahealthwebapp/src/features/onboard/treatmentOptions/Tier[One,Two,Three]TreatmentRecommendations.tsx
export const tierOneTreatmentOptionsToDisplay: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.COACHING,
  TREATMENT_OPTIONS.GUIDED_SELF_CARE,
]

export const tierTwoTreatmentOptionsToDisplay: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.COACHING,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.COUPLES_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY,
  TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE,
]

export const tierThreeTreatmentOptionsToDisplay: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE,
  TREATMENT_OPTIONS.COUPLES_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY_CHILD,
  TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS_CHILD,
  TREATMENT_OPTIONS.ALCOHOL_USE_DISORDER,
]

export const tierOnePrimaryTreatmentOptionsToDisplay: TREATMENT_OPTIONS_TYPE[] = [TREATMENT_OPTIONS.COACHING]
export const tierOneSecondaryTreatmentOptionsToDisplay: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.GUIDED_SELF_CARE,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE,
  TREATMENT_OPTIONS.COUPLES_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY,
  TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS,
]

export const tierTwoPrimaryTreatmentOptionsToDisplay: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE,
]
export const tierTwoSecondaryTreatmentOptionsToDisplay: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.COACHING,
  TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS,
  TREATMENT_OPTIONS.COUPLES_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY,
]

export const tierThreePrimaryTreatmentOptionsToDisplay: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE,
  TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  TREATMENT_OPTIONS.ALCOHOL_USE_DISORDER,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD_ICAS,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS_CHILD,
]
export const tierThreeSecondaryTreatmentOptionsToDisplay: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE,
  TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS,
  TREATMENT_OPTIONS.COUPLES_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY_CHILD,
]

export const childPrimaryTreatmentOptionsToDisplay: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS_CHILD,
]
export const childSecondaryTreatmentOptionsToDisplay: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.FAMILY_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY_CHILD,
]

// international version of eligible treatment options to display copied over from logic in
// apps/lyrahealthwebapp/src/common/constants/tier[1-3]IntlTreatmentOptionsConstants.tsx
export const tierOneTreatmentOptionsToDisplayIntl: TREATMENT_OPTIONS_TYPE[] = [TREATMENT_OPTIONS.COACHING]

export const tierTwoTreatmentOptionsToDisplayIntl: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.COACHING,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE,
]

export const tierThreeTreatmentOptionsToDisplayIntl: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE,
]

export const allOptionsEligibleTreatmentOptions: TREATMENT_OPTIONS_TYPE[] = [
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.COUPLES_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY,
  TREATMENT_OPTIONS.COACHING,
  TREATMENT_OPTIONS.GUIDED_SELF_CARE,
  TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS,
  TREATMENT_OPTIONS.SELF_CARE_APPS,
]

// stubs
export const stubTierOneEligibleTreatmentOptions = [
  TREATMENT_OPTIONS.COACHING,
  TREATMENT_OPTIONS.GUIDED_SELF_CARE,
  TREATMENT_OPTIONS.SELF_CARE_APPS,
]

export const stubTierTwoEligibleTreatmentOptions = [
  TREATMENT_OPTIONS.COACHING,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.COUPLES_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY,
  TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  TREATMENT_OPTIONS.SELF_CARE_APPS,
]

export const stubTierThreeEligibleTreatmentOptions = [
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.COUPLES_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY,
  TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  TREATMENT_OPTIONS.SELF_CARE_APPS,
]

export const stubAllOptionsEligibleTreatmentOptions = [
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.COUPLES_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY,
  TREATMENT_OPTIONS.COACHING,
  TREATMENT_OPTIONS.GUIDED_SELF_CARE,
  TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  TREATMENT_OPTIONS.SELF_CARE_APPS,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE,
]

/** Direct path treatment options available on family hub */
export enum FAMILY_CARE_OPTION {
  PARENT_COACHING = 'ParentCoaching',
  COUPLES_THERAPY = 'CouplesTherapy',
  FAMILY_THERAPY = 'FamilyTherapy',
}

import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Mask, Path, Rect } from 'react-native-svg'

export const ProvidersIllustration: FunctionComponent<{ width?: number }> = ({ width = 348 }) => {
  return (
    <Svg width={width} height={width / 1.63} viewBox='0 0 348 213' fill='none'>
      <G clip-path='url(#clip0_1371_44597)'>
        <Mask
          id='mask0_1371_44597'
          // style='mask-type:luminance'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='348'
          height='209'
        >
          <Path
            d='M347.996 122.331C347.353 188.779 270.677 208.151 170.898 208.151C71.1197 208.151 0 186.719 0 125.383C0 64.047 71.4732 0 171.252 0C271.03 0 348.649 54.9203 347.996 122.331Z'
            fill='white'
          />
        </Mask>
        <G mask='url(#mask0_1371_44597)'>
          <Path
            d='M351.915 116.227C356.509 200.052 271.03 222.384 171.252 222.384C71.4732 222.384 -9.00404 207.995 -9.41152 116.227C-9.78158 33.1077 71.4732 -4.0166 171.252 -4.0166C271.03 -4.0166 348.493 53.8018 351.915 116.227Z'
            fill='white'
          />
          <Path
            d='M256.587 66.6541C253.685 63.8995 253.542 59.0326 255.219 55.2405C256.905 51.4194 260.475 48.8414 264.14 48.0764C266.874 47.4776 269.826 47.8123 272.369 49.0514C277.485 51.5337 281.494 57.5045 277.625 63.1198C273.286 69.3131 262.105 71.8619 256.614 66.677L256.589 66.6541H256.587Z'
            fill='#503519'
          />
          <Path
            d='M256.922 90.0862C252.775 81.2423 254.005 72.8245 260.477 66.0844C260.604 65.9576 263.81 62.5751 270.032 61.1677C277.365 59.5086 283.699 60.9972 284.72 61.1489C298.19 64.7289 301.651 76.8702 298.849 88.3794C298.331 90.502 296.373 96.2733 295.712 96.5539C293.394 97.5414 272.966 102.219 256.922 90.0883V90.0862Z'
            fill='#503519'
          />
          <Mask
            id='mask1_1371_44597'
            // style='mask-type:luminance'
            maskUnits='userSpaceOnUse'
            x='255'
            y='80'
            width='43'
            height='43'
          >
            <Path
              d='M256.731 89.7932C257.677 89.051 259.253 89.1238 260.386 89.2797C261.086 89.3691 261.502 89.498 261.735 88.8681C261.883 88.4752 261.932 87.8307 261.98 87.3359C262.359 83.5646 262.174 78.5543 266.614 80.6852C269.115 81.5771 271.431 82.1821 273.62 82.5958C274.23 82.7102 274.855 82.8099 275.514 82.8993C293.167 85.6519 300.884 73.6271 296.404 93.6185C294.909 99.7869 293.552 106.475 288.776 111.074C288.431 111.415 288.042 111.805 288.032 111.843C287.999 111.976 287.583 114.479 287.508 114.876C286.741 118.965 282.182 123.329 273.082 122.508C266.953 121.955 260.594 117.693 258.76 113.878C258.145 112.46 259.762 110.893 260.159 109.556C261.093 106.995 261.184 104.284 261.635 101.623C261.922 100.138 261.338 99.7848 260.07 99.2962C256.35 97.7453 253.502 92.8535 256.7 89.8161L256.731 89.7911V89.7932Z'
              fill='white'
            />
          </Mask>
          <G mask='url(#mask1_1371_44597)'>
            <Path
              d='M256.731 89.7932C257.677 89.051 259.253 89.1238 260.386 89.2797C261.086 89.3691 261.502 89.498 261.735 88.8681C261.883 88.4752 261.932 87.8307 261.98 87.3359C262.359 83.5646 262.174 78.5543 266.614 80.6852C269.115 81.5771 271.431 82.1821 273.62 82.5958C274.23 82.7102 274.855 82.8099 275.514 82.8993C293.167 85.6519 300.884 73.6271 296.404 93.6185C294.909 99.7869 293.552 106.475 288.776 111.074C288.431 111.415 288.483 111.957 288.354 112.41C288.194 112.984 287.839 114.121 287.757 114.758C287.367 117.791 282.182 123.327 273.082 122.506C266.953 121.953 260.594 117.691 258.76 113.876C258.145 112.458 259.762 110.891 260.159 109.554C261.093 106.993 261.184 104.282 261.635 101.621C261.922 100.136 261.338 99.7827 260.07 99.2942C256.35 97.7432 253.502 92.8514 256.7 89.814L256.731 89.7891V89.7932Z'
              fill='#EBBC96'
            />
            <Path
              d='M287.662 112.627C285.978 114.724 283.221 117.136 279.961 117.803C277.656 118.275 275.334 118.502 273.047 117.797C269.776 116.791 267.465 114.539 265.818 111.66C264.151 108.747 263.569 105.49 263.469 102.122C263.695 102.735 263.903 103.359 264.153 103.964C265.891 108.194 268.614 111.575 272.781 113.612C276.481 115.421 280.325 115.866 284.065 114.35C285.369 113.822 286.795 113.146 288.556 111.327C288.302 111.866 287.876 112.367 287.666 112.629L287.662 112.627Z'
              fill='#DEA780'
            />
          </G>
          <Path
            d='M253.712 239.498C250.897 239.619 219.374 237.675 217.259 235.8C215.79 234.496 212.505 199.875 212.505 191.245C212.505 189.197 205.636 133.308 245.228 120.826C247.242 120.192 249.089 119.144 251.041 118.358C252.735 117.678 254.18 116.402 254.533 115.169C255.03 113.431 255.766 111.705 257.878 110.708C258.571 110.381 259.679 110.591 259.698 110.629C259.188 111.489 258.644 112.468 259.014 113.231C259.585 114.408 260.078 115.298 261.076 116.217C264.007 118.913 267.508 120.073 271.433 120.599C282.591 122.096 287.086 118.169 287.874 112.732C288.755 112.585 289.566 113.751 289.87 114.229C290.537 115.281 290.554 116.092 291.169 117.173C292.111 118.826 293.537 119.78 295.302 120.421C301.464 122.657 307.211 125.791 312.951 128.899C319.368 132.373 323.424 137.882 325.801 144.709C326.404 146.441 332.749 178.21 332.782 181.033C332.959 196.353 331.549 236.153 253.712 239.498Z'
            fill='#FCD0C2'
          />
          <Path
            d='M261.77 125.984C270.022 128.614 278.658 129.446 285.755 123.608C286.708 122.864 287.604 122.04 288.523 121.213C288.718 121.038 289.017 121.053 289.192 121.248C289.358 121.433 289.352 121.712 289.186 121.888C288.344 122.791 287.471 123.693 286.516 124.523C281.539 129.003 275.115 130.233 268.645 129.003C266.165 128.537 263.762 127.784 261.463 126.82C260.955 126.595 261.224 125.824 261.768 125.984H261.77Z'
            fill='#FCB097'
          />
          <Path
            d='M285.333 93.4896C284.664 95.5374 284.895 97.0134 284.718 98.7431C284.616 99.6205 284.66 100.489 284.229 101.267C283.196 103.151 280.741 102.947 278.966 102.369C278.425 102.18 277.891 101.957 277.664 101.525C277.456 101.059 277.92 100.824 278.485 100.918C279.124 101.026 279.774 101.354 280.435 101.444C281.257 101.583 282.281 101.514 282.841 100.968C283.431 100.388 283.435 99.4812 283.516 98.6974C283.706 97.1132 283.666 95.4771 284.017 93.9033C284.469 91.8846 286.512 88.3483 287.288 89.5354C287.581 89.9844 285.997 91.4522 285.331 93.4875L285.333 93.4896Z'
            fill='#D99360'
          />
          <Path
            d='M277.119 87.0115C277.679 86.972 280.069 86.6436 279.425 88.0905C279.271 88.4377 278.205 88.6539 277.506 88.7246C276.737 88.8202 275.968 88.9325 275.223 89.1508C274.504 89.315 272.311 90.3358 272.065 89.3234C272.325 87.9367 275.808 86.9949 277.074 87.0115C277.074 87.0115 277.109 87.0115 277.121 87.0115H277.119Z'
            fill='#503519'
          />
          <Path
            d='M278.602 108.435C276.805 108.888 273.6 106.936 272.427 105.464C271.934 104.903 271.781 103.753 272.891 104.182C273.479 104.429 273.978 104.928 274.506 105.29C275.475 105.997 276.564 106.523 277.614 107.103C277.974 107.311 279.182 107.999 278.622 108.421L278.602 108.431V108.435Z'
            fill='#C6644A'
          />
          <Path
            d='M288.699 89.5187C287.246 89.4584 287.93 87.9989 289.462 87.7578C290.622 87.5291 291.635 87.5229 293.109 88.0551C293.552 88.2151 295.215 88.8139 294.437 89.29C293.841 89.6538 292.533 89.4417 291.225 89.2837C290.306 89.1736 288.701 89.5187 288.701 89.5187H288.699Z'
            fill='#503519'
          />
          <Path
            d='M277.198 93.9656C277.267 94.9157 276.516 96.2255 275.492 96.2899C275.119 96.3045 274.812 96.0654 274.693 95.7203C274.44 95.08 274.799 93.949 275.437 93.3939C276.117 92.7182 277.113 92.8575 277.194 93.924L277.198 93.9656Z'
            fill='#503519'
          />
          <Path
            d='M291.034 94.7182C291.148 95.4458 290.724 96.4479 290.159 96.9364C289.658 97.4416 288.785 97.4562 288.552 96.7285C288.333 96.1527 288.579 95.2005 289.109 94.5851C289.654 93.8762 290.839 93.4812 291.03 94.6787L291.036 94.7203L291.034 94.7182Z'
            fill='#503519'
          />
          <Path
            d='M262.23 93.5061C262.178 93.8533 261.749 93.5872 261.535 93.6932C261.338 93.7514 261.275 93.9385 261.255 94.1235C261.23 94.479 261.23 94.8054 261.113 95.1693C261.059 95.4624 260.708 95.6807 260.475 95.4312C260.377 95.3106 260.375 95.1194 260.379 94.9634C260.458 93.5331 260.448 92.4375 258.814 91.978C258.542 91.82 258.552 91.5664 258.829 91.4354C259.471 91.1194 260.134 91.4832 260.698 91.7951C261.384 92.1506 262.072 92.6745 262.234 93.4687L262.23 93.5061Z'
            fill='#D99360'
          />
          <Path
            d='M262.026 98.0362C262.068 98.531 261.76 98.7181 261.409 98.7368C260.86 98.7638 260.635 98.375 260.604 97.8823C260.579 97.4499 260.76 97.1381 261.246 97.1547C261.833 97.1755 261.891 97.6682 262.026 98.0362Z'
            fill='#EBE4DC'
          />
          <Path
            d='M269.897 94.3543C271.677 94.3606 273.369 95.7077 273.754 97.4478C274.242 99.2898 273.028 101.111 271.101 101.2C268.868 101.388 266.215 99.4873 266.423 97.0819C266.56 95.4104 268.24 94.3148 269.847 94.3522H269.897V94.3543Z'
            fill='#EDB691'
          />
          <Path
            d='M293.385 96.7806C291.606 96.7869 289.913 98.1341 289.529 99.8742C289.04 101.716 290.254 103.521 292.182 103.627C293.55 103.702 294.098 102.294 294.88 99.3981C295.319 97.7786 295.042 96.7411 293.435 96.7786H293.385V96.7806Z'
            fill='#EDB691'
          />
          <Path
            d='M294.98 196.295C290.225 196.823 288.699 196.881 286.18 196.222C281.76 195.064 277.841 192.642 273.924 190.326C273.298 189.956 271.656 188.894 271.207 188.312C272.009 186.753 271.814 186.449 273.319 185.846C275.086 185.139 285.812 183.143 288.36 182.738C289.055 182.628 289.479 182.368 289.755 181.62C292.204 174.986 294.055 168.162 296.057 161.387C297.14 157.72 299.146 151.863 300.362 148.237C300.838 146.82 301.302 146.516 303.258 145.655C309.221 145.352 314.51 153.938 313.472 158.491C312.021 164.857 299.391 195.89 294.982 196.293L294.98 196.295Z'
            fill='#FFFBF6'
          />
          <Path
            d='M302.107 146.35C302.786 145.341 306.67 144.632 309.04 144.167C312.689 143.449 347.044 139.356 357.879 138.142C359.405 137.971 360.701 139.246 360.553 140.774C358.908 157.898 351.266 187.332 348.102 189.954C342.281 194.779 289.421 197.399 287.757 196.528C286.417 195.827 286.768 193.729 287.053 192.771C288.55 187.746 293.82 174.541 295.271 168.175C296.308 163.622 301.389 147.412 302.105 146.348L302.107 146.35Z'
            fill='#FCB097'
          />
          <Path
            d='M333.941 167.047C332.742 169.833 329.898 171.207 327.585 170.115C325.272 169.024 324.369 165.884 325.567 163.098C326.766 160.312 329.61 158.938 331.923 160.029C334.236 161.121 335.139 164.261 333.941 167.047Z'
            fill='#FCD0C2'
          />
          <Path
            d='M245.427 188.769C245.427 188.769 246.444 187.438 249.184 184.856C253.024 181.237 256.467 178.403 261.159 175.933C265.026 174.01 267.498 172.958 271.781 172.416C272.693 172.314 273.926 172.331 274.631 172.796C275.3 173.202 274.623 174.759 271.178 175.619C270.309 175.838 266.637 177.42 266.09 178.046C266.034 178.11 269.446 177.551 272.117 177.497C276.03 177.418 278.3 178.278 280.022 179.141C280.88 179.57 281.038 180.316 280.57 181.177C280.535 181.243 281.217 182.237 280.649 183.212C280.082 184.187 280.175 184.759 279.319 185.324C277.39 186.599 273.323 186.245 271.101 187.515C267.814 189.395 266.993 192.607 263.978 194.638C260.142 197.22 258.386 196.948 256.856 196.948C251.847 196.948 246.548 191.008 245.43 188.769H245.427Z'
            fill='#EBBC96'
          />
          <Path
            d='M245.845 188.613C246.384 189.804 247.203 190.914 248.145 191.823C249.105 192.742 250.29 193.469 251.436 194.158C253.188 195.174 255.066 195.975 257.045 196.384C257.673 196.507 258.321 196.613 258.957 196.615C259.519 196.586 259.868 197.295 259.513 197.723C258.527 198.991 257.402 200.151 256.176 201.185C248.756 207.386 238.793 208.409 229.7 210.34C228.203 210.69 226.71 211.085 225.374 211.733C225.139 211.848 224.856 211.748 224.742 211.513C224.631 211.284 224.721 211.012 224.941 210.891C226.38 210.105 227.912 209.658 229.432 209.249C239.602 206.746 251.465 205.617 258.434 196.879L258.995 197.987C257.51 197.966 256.064 197.569 254.683 197.072C251.997 196.039 249.481 194.576 247.407 192.569C246.644 191.783 245.991 190.902 245.482 189.935C245.311 189.609 245.157 189.297 245.018 188.925C244.931 188.694 245.047 188.438 245.278 188.351C245.509 188.264 245.764 188.384 245.852 188.611L245.845 188.613Z'
            fill='#FCB097'
          />
          <Path
            d='M245.155 189.12C245.08 189.079 244.889 189.002 244.798 188.971C243.667 188.646 242.486 188.542 241.307 188.467C234.436 188.093 227.486 189.197 220.993 191.451C218.376 192.361 215.86 193.521 213.424 194.84C212.9 195.11 212.438 194.349 212.958 194.016C213.81 193.467 214.804 192.892 215.7 192.403C222.276 188.919 229.725 187.127 237.157 187.081C239.288 187.091 241.432 187.199 243.529 187.64C244.321 187.835 244.956 187.919 245.7 188.418C246.101 188.715 245.76 189.355 245.286 189.172L245.153 189.12H245.155Z'
            fill='#FCB097'
          />
          <Path
            d='M270.269 183.881C272.408 182.833 274.839 182.172 277.244 182.382C278.045 182.441 278.835 182.557 279.606 182.742C279.862 182.802 280.02 183.06 279.959 183.318C279.907 183.534 279.714 183.682 279.502 183.684C277.988 183.719 276.485 183.684 274.999 183.885C273.496 184.068 272.051 184.52 270.525 184.736C270.02 184.804 269.814 184.098 270.271 183.881H270.269Z'
            fill='#D99360'
          />
          <Path
            d='M268.344 180.688C270.98 179.22 274.18 179.048 277.096 179.578C278.069 179.759 279.024 180.017 279.941 180.366C280.186 180.459 280.309 180.736 280.215 180.981C280.134 181.195 279.911 181.316 279.693 181.281C276.943 180.834 274.159 180.547 271.394 180.946C270.483 181.073 269.585 181.264 268.683 181.509C268.182 181.644 267.893 180.942 268.344 180.686V180.688Z'
            fill='#D99360'
          />
          <Path
            d='M129.691 130.784C128.751 126.793 124.29 123.036 119.35 121.406C115.073 119.997 111.034 117.945 106.988 115.961C106.107 114.789 104.772 114.306 103.533 113.668C102.943 113.365 102.367 113.111 101.691 113.313C96.5664 116.676 91.0821 119.215 85.0904 120.608C83.3566 121.011 81.6082 121.331 79.8743 120.785C77.8411 120.144 76.286 119.061 75.6145 116.841C75.5646 116.155 75.6644 115.427 75.6145 114.743C73.5105 114.911 71.2756 115.689 69.5626 116.918C65.7913 118.148 61.9972 119.315 58.255 120.629C54.8018 121.841 51.7624 123.733 49.6002 126.778C48.1928 128.762 47.2926 131.009 46.461 133.279C46.4797 133.379 45.1034 200.752 47.7458 208.503C52.8746 223.544 122.383 223.397 126.522 219.068C126.63 218.956 142.61 185.682 129.693 130.782L129.691 130.784ZM106.986 116.18C106.986 116.192 106.982 116.205 106.98 116.217C106.98 116.205 106.984 116.192 106.986 116.18ZM106.955 116.381C106.955 116.381 106.951 116.396 106.949 116.404C106.949 116.396 106.953 116.39 106.955 116.381ZM106.897 116.57C106.897 116.57 106.895 116.575 106.895 116.577C106.895 116.575 106.897 116.573 106.897 116.57Z'
            fill='#9BB976'
          />
          <Path
            d='M69.7538 117.572C72.0116 122.173 75.9409 126.261 81.2942 126.649C90.658 127.73 100.109 123.953 106.198 116.749C106.371 116.552 106.67 116.531 106.868 116.703C107.057 116.87 107.082 117.155 106.928 117.352C105.766 118.836 104.458 120.215 103.034 121.464C101.595 122.703 100.034 123.803 98.3751 124.73C92.6205 127.953 85.4356 129.09 78.9845 127.668C75.1945 126.67 72.1634 123.799 70.2195 120.487C69.731 119.67 69.3027 118.82 68.941 117.938C68.8474 117.712 68.9576 117.45 69.1842 117.358C69.4046 117.269 69.654 117.367 69.7538 117.577V117.572Z'
            fill='#89AE65'
          />
          <Path
            d='M89.2879 147.327C89.211 145.89 88.2817 145.277 86.7869 145.391C84.8826 145.537 62.9057 146.928 61.9743 146.99C60.0034 147.123 58.2425 147.951 56.569 148.899C55.6147 149.439 55.6043 150.084 56.4671 150.545C57.9452 151.337 59.4338 152.156 61.2072 152.175C63.0076 152.194 85.6227 150.52 85.9657 150.499C89.448 150.294 89.5249 150.221 89.2879 147.327Z'
            fill='#799E57'
          />
          <Path
            d='M64.652 146.712C63.5564 146.202 63.3423 144.298 65.2092 143.364C69.7476 141.092 74.4108 140.487 79.286 142.31C81.1071 142.99 82.3171 144.279 83.4294 145.749C84.236 147.24 84.5499 148.888 84.9345 150.512C85.4418 157.568 84.4938 157.433 90.0405 164.751C90.4563 165.3 90.9927 165.742 91.5706 166.125C91.8243 166.354 92.0862 166.574 92.3295 166.815C93.3107 167.786 94.1195 169.285 93.8263 170.761C92.3586 178.158 86.4127 178.873 83.789 178.509C82.1009 178.277 81.2194 176.597 80.1674 175.601C75.6893 168.942 74.2569 167.875 73.2237 167.004C71.3443 165.42 69.5834 161.603 71.2278 159.002C71.1114 159.002 70.6021 158.886 70.1717 158.807C67.1343 157.628 66.6271 156.017 66.5896 152.67C66.5855 152.362 66.598 151.404 66.6104 151.096C66.6707 149.707 66.677 148.603 66.8454 147.221C66.8454 147.221 65.1635 146.949 64.6541 146.712H64.652Z'
            fill='#A2633E'
          />
          <Path
            d='M71.4316 158.35C72.5022 156.94 70.0844 156.067 70.8723 155.4C71.3214 155.159 71.9534 155.289 72.1634 155.674C74.0448 158.845 70.733 159.892 71.4129 158.387L71.4316 158.352V158.35Z'
            fill='#8E5131'
          />
          <Path
            d='M67.0512 147.304C66.4524 146.996 67.4399 146.273 67.7913 146.084C69.6104 145.15 71.6935 145.065 73.6769 145.395C74.1904 145.485 74.7787 145.641 75.1862 145.944C76.049 146.603 75.1446 146.911 74.4295 146.826C72.5293 146.51 70.6062 146.377 68.7643 147.03C68.2549 147.181 67.5543 147.439 67.072 147.312L67.0512 147.306V147.304Z'
            fill='#8E5131'
          />
          <Path
            d='M67.9368 151.637C69.0345 151.192 72.6811 151.198 73.2715 152.395C73.2465 152.998 71.8058 152.695 71.2299 152.691C70.4773 152.647 69.7227 152.707 68.9763 152.818C68.3589 152.919 67.3817 153.036 67.0969 152.772C66.6437 152.306 67.4774 151.805 67.8952 151.655L67.9368 151.639V151.637Z'
            fill='#8E5131'
          />
          <Path
            d='M69.9472 148.545C71.0449 148.603 72.3339 148.695 73.2382 149.352C73.7559 149.932 72.3172 149.836 71.652 149.78C70.9472 149.713 70.2382 149.686 69.5335 149.747C68.9015 149.78 67.1905 150.021 67.3277 149.323C67.8495 148.585 69.0532 148.578 69.8973 148.543H69.9472V148.545Z'
            fill='#8E5131'
          />
          <Path
            d='M126.404 190.777C126.086 190.669 119.182 183.715 114.078 180.798C106.681 176.572 99.82 170.17 100.117 171.353C100.793 174.911 99.6849 178.075 97.7597 180.938C94.8325 185.634 89.2754 186.312 84.0988 185.023C83.5146 184.89 83.3233 185.214 83.2568 185.767C83.0759 187.243 83.7724 188.411 84.4252 189.64C85.5125 191.686 87.2464 193.112 88.9885 194.497C95.6163 199.199 102.338 211.91 110.202 218.484C111.153 219.278 109.801 220.881 108.793 219.658C108.153 218.881 101.273 212.338 100.737 211.644C99.2213 209.677 97.8866 207.594 96.5124 205.532C94.5872 202.64 92.6309 199.738 89.9844 197.42C88.2235 195.879 86.3441 194.47 84.6456 192.867C82.2173 190.576 81.0427 187.777 81.7724 184.382C81.868 183.937 81.6186 183.682 81.3649 183.387C79.7891 181.337 78.2818 177.865 80.1633 175.603C81.2173 176.599 82.157 177.397 83.8451 177.632C87.2817 178.056 91.1029 176.963 92.475 173.582C93.3565 171.41 93.2962 167.418 91.5686 166.129C91.4334 166.027 91.7702 165.871 91.8783 165.767C93.87 163.825 97.3481 167.123 98.9323 168.609C99.6329 169.491 99.9385 169.239 100.81 169.532C101.776 170.023 102.327 170.349 102.219 169.649C100.751 161.397 101.415 151.68 104.439 143.817C104.816 144.672 104.737 145.431 104.635 146.119C103.828 152.056 102.017 157.986 103.157 164.01C103.475 165.697 103.467 169.817 103.629 171.526C103.67 171.958 110.437 177.279 116.543 180.33C121.857 182.987 127.732 189.688 128.048 190.075C129.104 191.366 128.283 191.836 126.404 190.779V190.777ZM80.6207 177.977C80.6186 179.565 81.5915 182.266 83.4252 183.33C87.3545 185.016 92.9137 184.511 95.818 181.185C98.0487 178.69 99.0778 175.736 98.8782 172.341C98.7972 170.965 98.9344 170.173 97.3481 168.879C96.2379 167.975 95.2338 167.168 93.6496 166.732C94.9199 170.62 95.0758 174.42 91.7494 177.075C88.5249 179.649 84.737 180.507 80.6207 177.975V177.977Z'
            fill='#89AE65'
          />
          <Path
            d='M75.3941 112.922C69.4045 112.292 65.5979 108.887 63.3235 103.487C62.23 100.893 61.9556 98.157 61.6645 95.4044C61.4296 93.1737 61.1281 86.1717 61.4421 82.8079C61.8183 78.7539 62.3817 73.1801 63.6333 71.9286C66.5189 69.0846 67.9389 68.5627 70.207 67.5461C71.3733 67.0243 90.0176 60.8955 95.7223 69.1116C96.7431 70.5814 103.015 87.8931 103.236 89.7745C103.556 92.5022 100.29 96.7786 99.8699 100.419C99.6578 102.255 99.7368 107.695 100.693 110.706C100.974 111.591 101.354 112.446 101.689 113.315C96.5643 116.679 93.2608 119.647 87.2692 121.042C85.5353 121.446 81.6061 121.335 79.8722 120.789C77.8389 120.148 76.103 119.092 75.4315 116.872C75.4897 116.153 75.3733 112.943 75.3941 112.924V112.922Z'
            fill='#A1633E'
          />
          <Path
            d='M99.8449 101.473C97.8034 102.101 97.7057 100.897 96.9676 99.2983C96.5685 98.4438 96.5622 97.949 97.6682 97.4792C100.207 96.4023 101.851 93.2131 101.531 90.4834C101.124 86.2236 95.0051 84.7766 92.5269 88.0365C92.2317 88.4107 92.028 88.9366 91.4209 88.7911C90.7889 88.6394 90.9365 88.0094 90.8263 87.5832C90.6787 87.0094 90.8159 86.4419 91.0737 85.8806C92.5436 82.6768 93.2317 79.2964 93.4978 75.783C93.5727 70.4795 87.132 73.2341 84.4397 74.3277C80.7828 76.1239 74.0095 77.3172 71.4232 73.2237C71.0656 72.6042 70.4877 72.7809 70.0366 72.862C67.5501 73.3027 64.099 73.6749 61.914 72.5502C60.5814 71.8641 60.0221 69.6458 60.4275 68.4026C61.3236 65.3652 63.4899 64.0014 66.3755 63.3943C67.8619 63.0804 69.5002 63.3694 69.3962 63.0742C68.0199 59.1678 70.4066 57.3591 73.733 56.9765C75.7683 56.7416 78.8119 57.5607 80.9948 57.8913C84.2422 58.3819 87.4958 58.7416 90.7598 59.1366C104.022 60.7353 106.017 72.9368 106.115 82.8078C105.803 89.2048 103.003 97.0488 99.8449 101.471V101.473Z'
            fill='#58330F'
          />
          <Path
            d='M75.4336 116.87C75.4336 116.188 75.3837 115.448 75.3795 114.76C75.3753 114.128 75.2568 112.953 75.2173 112.897C78.8971 113.096 82.7079 112.749 85.9012 110.945C87.2505 110.182 89.3066 108.635 90.8721 106.288C90.2899 109.739 88.3669 112.059 85.7058 114.115C83.159 116.082 78.6872 117.123 75.4336 116.87Z'
            fill='#955835'
          />
          <Path
            d='M98.5913 89.4004C98.4853 89.8016 97.9655 89.8328 97.6204 89.9014C97.1381 89.9845 96.5789 90.0989 96.2816 90.3858C95.899 90.7455 96.0861 91.3172 96.1631 91.8411C96.188 92.2007 96.3169 93.602 95.8159 93.6103C95.213 93.4315 95.7036 91.7039 94.8887 91.7953C94.5394 91.9118 94.3585 92.3837 94.0072 92.5916C93.766 92.7704 93.3294 92.7932 93.2338 92.4481C93.1506 92.1384 93.3689 91.8203 93.5332 91.5646C94.319 90.3983 95.2192 89.3421 96.6412 88.916C97.1589 88.7392 98.5061 88.6706 98.5934 89.3712L98.5892 89.4004H98.5913Z'
            fill='#955835'
          />
          <Path
            d='M74.2818 106.08C73.5188 106.115 72.9492 105.454 73.6872 104.949C75.1488 104.207 76.8286 103.882 78.2256 102.972C78.683 102.697 79.1175 102.381 79.5645 102.092C79.8847 101.887 80.3899 101.608 80.7204 101.751C81.1071 102.028 80.6123 102.62 80.3732 102.899C78.7267 104.708 76.5978 105.772 74.3213 106.082H74.2798L74.2818 106.08Z'
            fill='#873C1F'
          />
          <Path
            d='M84.0489 91.7203C82.1986 91.8201 80.5084 93.3066 80.1986 95.1361C79.7849 97.0778 81.1404 98.9073 83.1487 98.9032C85.4813 98.9843 88.1424 96.8699 87.8014 94.3793C87.5727 92.6475 85.7703 91.5956 84.1008 91.7162L84.0489 91.7203Z'
            fill='#A05836'
          />
          <Path
            d='M61.704 90.0116C62.8641 90.3899 64.6541 92.7142 64.6042 94.2194C64.6208 96.0988 63.8994 97.3233 62.257 96.7786C62.1635 96.7475 61.4358 89.9243 61.704 90.0116Z'
            fill='#A05836'
          />
          <Path
            d='M93.8242 86.9055C93.5165 86.9637 89.9885 88.0115 89.0613 88.315C88.344 88.5146 87.5665 88.9533 86.816 88.8015C86.0343 88.6061 85.6247 87.8868 84.8888 87.471C82.6996 86.4107 80.2651 85.8639 77.841 85.7225C76.9512 85.683 75.9575 85.7517 75.2693 86.3733C74.656 86.8805 74.5812 87.5936 73.945 87.8015C73.4461 87.9263 72.392 87.6227 71.654 87.5999C69.7705 87.5146 70.1675 85.7205 68.6478 84.9928C66.9202 84.0843 64.9576 83.733 63.0574 83.4149C61.1469 83.0802 59.2093 83.5563 59.1365 85.8286C59.0097 88.4086 59.3007 91.4709 61.3236 93.2817C62.6021 94.3607 64.361 95.0114 66.0262 94.5686C67.6125 94.1902 68.047 92.604 68.6624 91.2838C69.3318 89.8639 69.785 88.6373 71.5064 88.8722C73.8141 89.1861 74.3671 90.4751 74.8037 92.5042C76.6789 98.7765 84.7121 98.4064 85.9719 92.1819C86.2817 91.2256 86.922 90.631 87.8181 90.2963C89.4459 89.6705 91.8471 88.7516 92.1839 88.6061C92.1839 88.6061 92.4105 88.1945 92.8887 87.6373C93.1693 87.3088 93.8284 86.9034 93.8284 86.9034L93.8242 86.9055ZM68.706 87.7059C68.6998 89.7371 67.9659 92.3961 65.8183 93.0759C64.2466 93.4875 62.4982 92.6019 61.6479 91.288C60.941 90.3379 60.6895 89.1113 60.598 87.9076C60.544 87.2256 60.4171 86.525 60.5502 85.866C60.9639 84.2215 63.0554 85.0386 64.2404 85.1633C65.0242 85.3026 65.7996 85.4855 66.5605 85.7329C67.5896 86.0531 68.6353 86.5417 68.7019 87.6664L68.706 87.7059ZM82.8493 94.5894C81.4793 96.0488 79.0406 95.5831 77.6435 94.3815C76.1987 93.1299 75.9679 91.1092 75.8307 89.2506C75.7143 87.7849 76.1716 87.0427 77.735 87.2215C79.4751 87.4024 81.1383 87.7392 82.789 88.2859C83.8826 88.6206 84.4875 89.0302 84.4563 90.2921C84.4855 91.8389 83.9283 93.4085 82.8763 94.5624L82.8493 94.5915V94.5894Z'
            fill='#3E2316'
          />
          <Path
            d='M70.9077 89.1259C71.2465 89.7995 70.6083 90.5915 70.3027 91.1903C69.4149 92.5437 67.7809 96.6393 70.0012 97.3815C70.8848 97.6414 71.9555 97.1486 72.8473 97.1944C73.3213 97.2318 73.6581 97.5436 73.471 97.9303C72.9845 98.7224 71.2985 98.9178 70.442 98.9282C67.3775 98.8534 66.8453 95.6788 67.7913 93.3275C68.0553 92.5728 69.8079 88.0053 70.891 89.103L70.9077 89.1259Z'
            fill='#804829'
          />
          <Path
            d='M77.5146 79.8058C78.7121 79.7268 87.1174 81.6997 85.685 83.4315C85.3544 83.7745 84.868 83.5937 84.4938 83.4315C82.9678 82.7683 81.3794 82.2901 79.7869 81.8141C79.1653 81.6415 78.5167 81.5417 77.8868 81.4482C77.4793 81.4045 77.0136 81.3879 76.6581 81.1758C76.3234 80.9991 76.1134 80.5999 76.2631 80.234C76.4315 79.8141 77.0552 79.8203 77.4751 79.8058H77.5146Z'
            fill='#58330F'
          />
          <Path
            d='M64.8308 80.8847C64.153 80.8598 63.2611 81.0469 62.6291 80.681C62.3464 80.496 62.1988 80.103 62.3526 79.8203C62.519 79.5126 62.9285 79.4274 63.2508 79.365C64.5044 79.1529 65.7746 79.207 67.0262 79.338C67.2819 79.3692 67.5438 79.4253 67.7621 79.5625C68.2528 79.8078 68.3318 80.6269 67.8744 80.9097C67.5667 81.1051 67.0906 80.997 66.6728 80.9534C66.0886 80.8847 65.4108 80.8743 64.8682 80.8847H64.8308Z'
            fill='#58330F'
          />
          <Path
            d='M79.0219 91.4835C79.0136 93.3837 77.1155 93.1591 77.0427 91.3379C76.891 90.4939 77.3837 89.3463 78.3088 89.8494C78.8119 90.1737 78.9907 90.8515 79.0198 91.4377V91.4814L79.0219 91.4835Z'
            fill='#3E2316'
          />
          <Path
            d='M64.9098 89.5499C64.8475 88.6123 65.6271 87.5791 66.4046 88.5874C67.0013 89.3379 66.8849 91.5977 65.4982 91.0593C65.0138 90.7745 64.914 90.1279 64.9077 89.5936V89.5499H64.9098Z'
            fill='#3E2316'
          />
          <Path
            d='M49.2717 139.942C50.6813 144.285 51.5212 146.959 52.2987 149.672C53.8164 154.973 55.3631 160.273 57.5606 165.335C59.598 170.025 62.0138 174.52 65.3318 178.472C66.5023 179.865 66.4378 180.025 64.7497 180.765C63.837 181.164 55.1615 183.645 54.1449 183.732C53.1573 182.651 49.3528 140.899 49.2717 139.942Z'
            fill='#C0E9EE'
          />
          <Path
            d='M51.149 155.909C52.0846 157.77 54.2488 164.79 54.7623 166.778C55.7956 170.782 57.65 174.354 59.5772 178.029C60.0138 178.861 62.2445 182.383 62.2445 183.281C62.2445 184.615 56.9951 186.499 55.964 186.786C55.808 184.229 51.1844 156.491 51.1511 155.909H51.149Z'
            fill='#E4F6F7'
          />
          <Path
            d='M8.06434 146.869C7.2972 143.921 5.35336 135.246 5.12467 132.148C4.86272 128.585 7.96663 126.331 11.3658 126.874C19.4946 128.173 27.6608 129.19 35.7626 130.683C37.8706 131.071 39.9787 131.475 42.0619 131.971C43.8477 132.398 45.7105 132.626 47.0077 134.036C48.5961 135.761 49.1553 138.144 49.5586 140.383C49.6314 140.784 52.9266 160.888 52.9266 160.888C52.9599 161.47 57.7062 187.985 57.6188 188.948C57.4026 191.345 55.7748 192.846 53.3465 192.867C52.2447 192.877 31.8416 187.509 21.8667 183.854C18.7544 182.713 16.4094 180.106 15.6131 176.888C13.7961 169.547 10.2265 155.185 8.06434 146.867V146.869Z'
            fill='#DBCCBE'
          />
          <Path
            d='M33.2657 129.917C33.2844 131.063 32.642 131.678 31.6171 132.021C29.2907 132.801 26.9165 132.697 24.5465 132.383C22.2264 132.075 19.8958 131.787 17.6318 131.15C16.4281 130.811 15.2784 130.335 14.1911 129.722C12.9375 129.013 12.1683 127.797 12.7462 126.882C13.8356 125.159 16.0268 125.431 17.8834 125.562C18.4343 125.602 18.769 125.464 19.1474 124.955C21.4322 121.88 24.9519 122.639 26.6213 125.46C26.8895 125.913 27.114 126.152 27.6566 126.275C28.8001 126.533 29.8957 126.976 30.9518 127.51C32.01 128.046 32.8333 128.789 33.2636 129.92L33.2657 129.917Z'
            fill='#AD9580'
          />
          <Path
            d='M16.5383 153.639C15.4073 152.547 14.0913 151.739 12.8252 150.845C11.7857 150.111 10.977 149.158 10.1703 148.165C11.3346 148.452 12.4115 148.861 13.4863 149.275C14.4676 149.651 15.5258 149.518 16.0705 148.728C16.5965 147.965 15.7503 146.344 15.6318 146.177C13.9104 143.768 11.6028 142.148 8.82109 141.196C8.34501 141.034 6.34295 140.533 5.60076 142.566C5.11635 143.89 5.26812 145.377 6.13921 146.616C6.39285 146.978 6.35335 147.208 6.1309 147.529C5.27644 148.761 4.71927 150.136 4.26397 151.553C3.44901 158.768 7.92713 160.198 8.14542 160.333C8.85228 160.774 10.8065 162.68 14.4821 161.291C16.2202 160.634 21.4883 158.416 16.5403 153.643L16.5383 153.639Z'
            fill='#A2633E'
          />
          <Path
            d='M7.89387 157.123C8.04356 156.869 8.6028 157.275 8.79615 157.391C10.397 158.478 11.9832 159.607 13.8023 160.31C14.1266 160.451 14.6547 160.821 14.5154 161.169C14.4551 161.306 13.8501 161.167 13.6194 161.152C12.5695 160.994 11.7026 160.291 10.6069 159.705C9.75456 159.248 7.90219 157.709 7.88763 157.14L7.89387 157.123Z'
            fill='#8E5131'
          />
          <Path
            d='M18.085 157.539C16.322 158.115 13.7212 156.689 12.2015 155.788C10.9791 155.021 9.55287 154.366 8.58615 153.281C8.26807 152.822 8.57783 152.504 9.08095 152.709C10.0061 153.177 10.8023 153.924 11.6776 154.487C13.4239 155.691 15.347 156.576 17.4156 157.034C17.6609 157.096 18.3095 157.26 18.1016 157.526L18.085 157.541V157.539Z'
            fill='#8E5131'
          />
          <Path
            d='M9.72334 148.25C9.47178 148.583 7.25767 148.111 7.28262 147.377C7.60694 146.699 9.84392 147.822 9.73581 148.229C9.73581 148.235 9.7275 148.244 9.72334 148.25Z'
            fill='#8E5131'
          />
          <Path
            d='M173.158 124.953C171.037 124.791 169.085 124.157 164.771 123.319C154.499 121.144 137.632 117.512 133.395 116.585C132.963 116.491 132.597 116.35 132.35 115.967C131.684 114.681 131.734 112.645 131.726 111.117C131.801 108.105 132.223 105.036 133.019 102.109C133.52 100.194 134.163 98.3086 134.551 96.3668C134.919 94.5893 134.942 92.7557 134.35 91.026C132.961 87.1986 130.643 84.5416 132.682 80.2943C133.77 77.9138 135.797 75.9949 136.805 73.6353C137.572 71.9077 137.682 69.9909 137.969 68.1406C138.373 65.413 139.449 62.5772 141.086 60.5086C142.591 58.5648 144.678 57.1948 147.027 56.4505C148.229 56.0555 151.208 55.4713 151.412 55.2966C151.574 55.1574 151.834 53.2738 152.354 52.3487C154.206 48.9454 158.088 46.5753 161.817 46.0099C167.412 44.962 173.817 47.5462 177.908 51.3695C179.524 52.9017 180.846 54.8393 182.31 56.5253C184.318 58.8912 186.804 60.7061 189.49 62.2259C196.226 65.9992 199.077 69.5834 198.26 77.5126C198.112 79.6727 198.062 81.8722 198.813 83.9117C199.786 86.6497 201.881 88.8451 203.268 91.3815C206.401 96.6558 205.69 102.951 203.546 108.481C203.126 109.672 202.617 111.177 201.954 111.999C201.628 112.379 201.22 112.61 200.754 112.818C195.376 115.096 177.728 123.175 173.171 124.949H173.16L173.158 124.953Z'
            fill='#7F6246'
          />
          <Mask
            id='mask2_1371_44597'
            // style='mask-type:luminance'
            maskUnits='userSpaceOnUse'
            x='150'
            y='97'
            width='33'
            height='22'
          >
            <Path
              d='M182.355 111.67C181.524 111.957 176.031 118.04 164.676 118.184C160.135 118.24 150.119 117.479 150.57 111.735C151.57 109.94 153.287 108.049 152.701 100.142C152.886 100.223 160.62 103.134 165.106 102.529C169.256 101.97 177.619 98.0758 178.451 97.0903C177.942 101.317 180.526 108.126 182.757 109.568C182.823 110.306 182.488 110.97 182.355 111.67Z'
              fill='white'
            />
          </Mask>
          <G mask='url(#mask2_1371_44597)'>
            <Path
              d='M182.355 111.67C181.524 111.957 176.031 118.04 164.676 118.184C160.135 118.24 150.119 117.479 150.57 111.735C151.57 109.94 153.287 108.049 152.701 100.142C152.886 100.223 161.364 107.516 165.85 106.911C170 106.352 177.622 98.0756 178.451 97.0881C177.942 101.315 180.526 108.123 182.757 109.566C182.823 110.304 182.488 110.967 182.355 111.668V111.67Z'
              fill='#D08B5B'
            />
            <Path
              d='M152.584 99.8303C154.35 100.668 156.164 102.539 158.036 103.136C160.936 104.059 163.78 104.046 166.724 103.202C170.376 102.155 173.437 100.252 176.574 97.9904C176.983 97.6952 177.886 97.084 178.453 97.0112C178.445 97.6432 178.813 98.9613 178.805 99.5933C177.925 102.93 173.318 108.281 166.075 109.283C158.025 110.396 152.576 103.666 152.389 103.585C152.601 102.092 152.31 100.924 152.584 99.8282V99.8303Z'
              fill='#C17540'
            />
          </G>
          <Path
            d='M182.287 111.375C181.262 114.978 178.773 139.323 178.686 142.759C177.678 142.549 157.248 140.007 154.892 139.784C154.815 137.231 150.965 115.059 150.587 113.878C154.589 116.098 160.765 117.774 165.295 117.464C171.954 117.009 176.572 115.992 182.285 111.375H182.287Z'
            fill='#FFB199'
          />
          <Path
            d='M214.827 120.483C210.18 113.055 201.615 112.061 193.715 110.758C190.438 110.117 187.187 109.348 184.008 108.306C179.615 109.452 178.17 138.686 177.474 142.759C178.039 143.029 154.171 140.768 156.225 140.528C155.79 132.456 154.113 119.279 150.572 111.735C149.853 110.202 148.549 110.55 147.173 111.132C142.302 113.032 137.271 114.957 132.238 116.215C114.329 122.774 111.19 143.813 107.735 160.322C106.084 169.566 103.837 174.655 101.063 186.16C99.9406 190.592 114.238 196.299 123.587 192.303C121.452 209.014 121.522 227.717 121.641 229.101C124.996 237.856 151.335 237.904 167.025 238.091C173.713 237.943 212.611 238.627 214.33 230.114C217.274 215.548 215.16 195.59 215.453 193.359C216.698 183.892 221.792 146.165 219.151 128.73C218.975 127.562 215.507 121.441 214.827 120.483Z'
            fill='#3BA5B5'
          />
          <Path
            d='M239.814 121.392C238.203 122.51 232.854 124.04 230.334 124.238C226.787 124.516 220.95 122.153 219.621 121.392C219.8 118.564 220.247 115.288 220.403 113.564C220.532 112.138 220.623 111.267 219.621 107.616C219.072 105.614 217.116 97.2069 216.334 95.8763C215.565 94.5686 214.769 93.2942 214.848 92.2755C214.927 91.2568 214.852 90.8514 215.708 90.3961C217.758 89.3068 220.444 91.8681 221.27 93.0302C221.806 93.7849 222.322 94.5562 222.846 95.3192C222.898 96.3025 223.771 92.0406 224.787 87.656C225.006 86.7163 226.586 77.2653 229.405 77.4815C231.44 77.6375 230.421 83.5854 230.421 85.5438C230.642 85.2029 232.436 78.1219 234.022 75.8391C234.486 75.1697 235.278 74.8184 236.112 74.8994C238.132 75.0949 237.124 81.5189 237.29 82.0615C238.172 80.1821 239.502 77.3256 241.224 78.9701C242.912 80.5813 241.714 84.654 241.234 86.8598C240.654 89.525 239.409 94.7765 239.346 95.2505C240.174 94.3545 242.087 89.4585 243.506 88.5666C243.997 88.259 244.56 87.8827 245.138 88.207C245.766 88.5583 245.918 89.2278 245.874 89.9221C245.773 91.5333 244.521 96.1923 243.964 97.6767C242.386 101.889 242.267 107.51 240.677 111.687C239.596 114.529 239.885 119.365 239.816 121.392H239.814Z'
            fill='#D08B5B'
          />
          <Path
            d='M223.864 96.1383C224.862 97.4896 225.685 98.8389 226.86 100.028C228.413 101.712 229.324 103.955 229.48 106.234C229.502 106.803 228.692 106.926 228.544 106.377C228.041 104.656 227.311 102.926 226.22 101.512C225.268 100.404 224.33 99.2526 223.713 97.9179C223.492 97.4709 223.297 97.0094 223.112 96.6081C222.895 96.1362 223.536 95.737 223.864 96.1383Z'
            fill='#C17540'
          />
          <Path
            d='M230.685 86.7641C230.787 89.0801 230.415 91.394 229.877 93.6372C229.804 93.8888 229.54 94.0343 229.288 93.9636C228.991 93.8846 228.929 93.5832 228.922 93.3212C228.852 92.2256 228.945 91.1258 229.072 90.0427C229.22 88.8868 229.417 87.7516 229.804 86.6415C229.966 86.1799 230.66 86.2818 230.685 86.7683V86.7641Z'
            fill='#C17540'
          />
          <Path
            d='M237.729 83.0094C237.538 86.7869 236.804 90.5145 235.941 94.1881C235.897 94.4604 235.633 94.6496 235.361 94.5852C234.818 94.4459 235.035 93.7598 235.024 93.371C235.319 89.8326 235.895 86.2984 236.847 82.8764C236.987 82.3712 237.744 82.4834 237.729 83.0094Z'
            fill='#C17540'
          />
          <Path
            d='M227.55 93.263C231.623 92.8389 235.839 93.7183 239.467 95.5977C239.964 95.8701 239.646 96.6351 239.103 96.473C236.28 95.5998 233.421 94.9096 230.492 94.5187C229.519 94.3836 228.54 94.2734 227.55 94.1549C227.031 94.0926 227.031 93.3275 227.55 93.2651V93.263Z'
            fill='#C17540'
          />
          <Path
            d='M219.035 117.096C219.051 116.886 219.27 116.755 219.463 116.841C221.162 117.585 221.868 119.676 229.54 119.778C236.114 119.865 239.022 117.901 240.685 117.612C240.897 117.574 241.114 117.649 241.259 117.809C241.577 118.157 241.85 118.637 241.926 119.134C240.764 121.967 233.469 123.202 229.658 123.36C224.022 123.595 218.494 119.932 218.095 119.926C218.178 119.568 218.983 117.745 219.037 117.098L219.035 117.096Z'
            fill='#FFB199'
          />
          <Path
            d='M231.596 191.364C221.266 197.998 209.388 198.102 209.465 174.869C210.068 158.705 211.771 155.88 214.571 140C216.155 131.019 216.401 124.504 216.665 123.144C216.96 121.626 217.663 118.419 218.525 118.498C219.349 118.572 221.582 119.967 222.336 120.352C226.465 122.479 233.286 123.015 237.638 121.136C240.935 119.685 242.124 117.225 242.612 119.254C243.105 122.036 242.922 124.743 243.043 127.691C243.114 131.063 243.215 134.24 243.513 137.549C246.269 168.353 241.34 185.108 231.596 191.366V191.364Z'
            fill='#3BA5B5'
          />
          <Path
            d='M149.632 97.8906C148.31 95.8761 146.05 90.084 145.41 87.4541C145.275 86.897 145.113 86.6059 144.641 86.3606C142.25 85.1194 139.007 81.3191 140.14 78.5229C140.986 76.4376 142.504 75.1508 144.967 75.4065C145.626 75.4751 145.381 74.8971 145.381 74.5936C145.381 72.1009 145.599 69.629 146.227 67.2153C147.038 64.0906 148.755 61.5605 151.404 59.6894C151.857 59.3692 152.258 59.1905 152.749 59.7331C154.491 61.654 156.859 62.0989 159.279 62.4773C161.47 62.8203 163.603 62.205 165.769 62.2445C168.412 62.2944 170.821 64.4004 171.694 67.0157C172.131 68.3255 173.532 69.8702 174.077 70.4793C174.992 71.5001 176.054 72.288 177.362 72.7662C177.89 72.9595 178.148 72.9762 178.553 72.4814C180.815 69.7184 185.559 70.4003 186.779 73.7516C187.349 75.315 186.703 76.9761 185.952 78.4251C185.401 79.4896 184.734 80.5332 183.742 81.2754C183.276 81.6247 183.405 82.5415 183.38 82.9636C183.193 86.0883 181.809 91.7431 179.938 95.4873C178.058 99.244 171.798 102.219 167.337 103.471C164.389 104.298 160.936 104.059 158.036 103.136C156.164 102.541 150.98 99.9488 149.63 97.8927L149.632 97.8906Z'
            fill='#D08B5B'
          />
          <Path
            d='M155.857 78.1862C155.732 79.232 154.736 79.8806 153.927 79.0864C153.387 78.5646 153.194 77.6291 153.379 76.8598C153.701 75.4025 155.169 75.4461 155.701 76.7725C155.875 77.1862 155.93 77.6935 155.863 78.1384L155.855 78.1862H155.857Z'
            fill='#503519'
          />
          <Path
            d='M168.89 74.0616C169.112 73.2154 170.013 72.7788 170.636 73.5002C171.262 74.1697 171.489 75.4171 171.156 76.2133C170.848 77.022 169.94 77.1031 169.412 76.4274C168.902 75.8495 168.661 74.837 168.879 74.1031L168.892 74.0616H168.89Z'
            fill='#503519'
          />
          <Path
            d='M148.383 73.1363C149.287 70.4149 152.204 68.7496 155.011 69.1841C155.566 69.261 155.562 70.0531 155.011 70.13C153.266 70.4024 151.622 70.997 150.362 72.261C149.909 72.6872 149.564 73.1196 149.137 73.6123C148.971 73.7974 148.686 73.814 148.501 73.6477C148.354 73.5146 148.316 73.3109 148.385 73.1383L148.383 73.1363Z'
            fill='#5F4832'
          />
          <Path
            d='M165.532 67.3255C167.206 66.2133 169.52 66.1114 171.364 66.8806C171.994 67.1197 172.563 67.4773 173.081 67.8785C173.289 68.0407 173.326 68.3401 173.164 68.548C173.027 68.7247 172.796 68.7746 172.601 68.6956C170.988 68.0698 169.322 67.6686 167.591 67.8681C166.996 67.9201 166.46 68.0365 165.855 68.153C165.368 68.2444 165.114 67.5812 165.532 67.3234V67.3255Z'
            fill='#5F4832'
          />
          <Path
            d='M142.387 77.5542C144.948 77.6415 145.649 81.1176 145.092 83.1259C144.95 83.6248 144.214 83.5645 144.152 83.0573C144.09 82.5791 144.025 82.1009 143.938 81.6352C143.676 80.4294 143.399 79.0469 142.235 78.4315C141.786 78.2465 141.907 77.5708 142.387 77.5542Z'
            fill='#C17540'
          />
          <Path
            d='M158.372 70.8079C158.792 71.5127 159.017 72.2569 159.262 73.0241C159.697 74.5293 160.05 76.0573 160.062 77.6394C160.04 79.1716 159.358 80.7163 159.046 82.157C158.954 82.656 158.873 82.9886 159.033 83.4273C159.609 84.8514 161.035 85.7495 162.368 86.4854C162.877 86.7682 162.528 87.5457 161.977 87.3482C160.198 86.6726 158.297 85.5437 157.647 83.5998C157.331 82.2339 157.932 80.8847 158.237 79.6456C158.416 78.9596 158.611 78.3151 158.632 77.6124C158.651 76.9409 158.578 76.207 158.485 75.4877C158.277 74.076 158.052 72.6041 157.599 71.2486C157.358 70.7434 158.065 70.338 158.374 70.81L158.372 70.8079Z'
            fill='#C17540'
          />
          <Path
            d='M159.387 92.2776C161.129 92.4272 162.994 92.1549 164.719 91.8327C166.408 91.3774 168.195 90.6664 169.771 89.9283C170.277 89.6643 170.736 90.3441 170.301 90.7121C169.139 91.631 167.823 92.2443 166.462 92.7911C165.121 93.3503 163.561 93.5416 162.112 93.5644C161.117 93.5644 160.114 93.4979 159.162 93.1383C158.932 93.0468 158.819 92.7869 158.909 92.5561C158.986 92.3607 159.185 92.2485 159.385 92.2755L159.387 92.2776Z'
            fill='#9E4420'
          />
          <Path
            d='M182.877 73.1198C182.152 74.3214 182.021 75.8038 182.224 77.178C182.299 77.652 182.422 78.1176 182.578 78.5958C182.674 78.8515 182.538 79.1384 182.278 79.2216C182.087 79.284 181.883 79.2195 181.763 79.074C181.378 78.6145 181.104 78.0657 180.927 77.4898C180.576 76.3256 180.597 75.0408 181.093 73.9119C181.276 73.4296 182.341 71.8267 182.906 72.6C183.012 72.7684 182.989 72.968 182.881 73.1198H182.877Z'
            fill='#C17540'
          />
          <Path
            d='M217.301 124.745C215.444 135.73 212.964 147.776 211.191 158.769C210.53 163.011 209.788 167.264 209.509 171.539C209.428 172.792 209.48 174.543 209.476 175.811C209.503 177.239 209.526 178.665 209.667 180.064C210 184.355 211.989 188.125 214.153 191.821C214.284 192.048 214.207 192.339 213.981 192.47C213.763 192.597 213.482 192.526 213.347 192.316C212.561 191.1 211.802 189.863 211.106 188.578C208.752 184.443 208.126 180.518 208.083 175.834C207.842 170.015 208.848 164.25 209.769 158.537C211.434 148.67 213.478 138.618 215.555 128.828C215.792 127.676 216.209 125.757 216.422 124.604C216.465 124.362 216.696 124.2 216.939 124.244C217.176 124.286 217.336 124.51 217.301 124.747V124.745Z'
            fill='#1D92A5'
          />
          <Path
            d='M171.697 67.0159C172.133 68.3257 173.534 69.8703 174.079 70.4795C174.994 71.5003 176.056 72.2882 177.364 72.7664C177.892 72.9597 178.15 72.9763 178.555 72.4815C178.555 72.4815 175.229 67.7373 173.522 66.9972C173.104 66.8163 171.697 67.0159 171.697 67.0159Z'
            fill='#7F6246'
          />
          <Path
            d='M150.2 80.3463C151.979 80.3525 153.672 81.6997 154.056 83.4398C154.545 85.2818 153.331 87.1029 151.404 87.1923C149.171 87.3795 146.518 85.4793 146.726 83.0739C146.863 81.4024 148.543 80.3068 150.15 80.3442H150.2V80.3463Z'
            fill='#CC7A52'
          />
          <Path
            d='M174.726 76.4024C172.875 76.5022 171.185 77.9887 170.875 79.8182C170.462 81.76 171.817 83.5895 173.825 83.5853C176.158 83.6664 178.819 81.5521 178.478 79.0615C178.249 77.3297 176.447 76.2777 174.778 76.3983L174.726 76.4024Z'
            fill='#CC7A52'
          />
        </G>
        <Path
          d='M194.247 146.31C194.106 144.584 192.887 143.851 191.362 143.526C188.303 142.876 145.836 136.479 143.223 136.763C141.119 136.992 138.786 138.468 138.379 140.53C138.206 141.408 137.684 152.809 136.951 157.913C136.477 161.212 136.196 164.534 135.612 167.823C135.192 170.187 133.976 176.183 133.682 178.567C133.944 178.696 134.724 205.272 139.67 206.158C145.231 207.151 169.449 210.172 173.671 210.779C176.931 211.249 181.037 212.347 182.403 212.261C186.16 212.027 187.021 208.322 187.451 205.804C188.05 202.318 188.536 197.835 189.133 194.353C189.414 192.721 192.499 167.765 193.085 162.601C193.715 157.063 194.372 147.89 194.243 146.31H194.247Z'
          fill='#EBE4DC'
        />
        <Path
          d='M192.486 147.366C192.345 145.641 191.127 144.907 189.601 144.582C186.542 143.932 148.686 138.574 146.728 138.198C145.223 137.907 142.605 137.713 141.462 137.819C139.356 138.017 138.464 138.988 138.057 141.048C137.884 141.925 132.156 179.505 132.333 181.208C132.518 182.996 132.965 206.328 137.909 207.212C143.47 208.205 167.688 211.226 171.911 211.833C175.17 212.303 181.229 212.964 182.403 212.259C184.842 210.796 185.476 208.426 185.908 205.91C186.507 202.424 186.775 198.891 187.372 195.409C187.653 193.777 190.738 168.821 191.324 163.657C191.954 158.119 192.611 148.946 192.482 147.366H192.486Z'
          fill='#DBCCBE'
        />
        <Path
          d='M155.998 137.992C158.354 138.212 160.493 137.95 162.516 136.402C165.896 133.815 170.948 135.61 172.349 138.801C172.775 139.774 173.634 140.094 174.561 140.304C175.565 140.53 176.578 140.728 177.586 140.938C178.339 141.298 179.091 141.657 179.844 142.017C180.008 142.418 180.172 142.819 180.337 143.221C180.407 144.819 179.156 145.539 177.258 145.722C172.819 146.15 168.464 145.539 164.135 144.607C161.065 143.948 157.99 143.314 155.127 141.955C153.996 141.416 152.832 140.907 152.57 139.474C152.572 138.72 153.331 138.57 153.676 138.09C154.449 138.057 155.223 138.023 155.996 137.992H155.998Z'
          fill='#AD9580'
        />
        <Path
          d='M194.848 168.528C196.96 170.407 195.228 175.042 195.472 175.628C196.255 177.509 195.023 181.091 194.553 181.913C194.133 182.648 194.449 184.095 193.143 186.021C191.91 187.84 188.447 191.773 179.056 193.827C176.842 194.312 173.067 194.835 170.78 195.588C166.141 197.114 159.51 198.875 159.51 198.875C159.51 198.875 156.285 183.503 159.9 180.873C160.761 180.247 164.674 180.559 167.414 176.491C169.453 173.459 176.455 168.865 178.998 165.416C180.736 163.056 182.707 165.345 181.786 168.017C180.759 170.992 179.278 172.075 179.644 173.574C180.584 173.985 181.952 173.027 183.05 172.576C184.389 172.025 187.016 170.233 188.686 168.877C191.112 166.906 192.985 165.214 193.804 168.073C193.804 168.073 194.349 168.081 194.85 168.526L194.848 168.528Z'
          fill='#D08B5B'
        />
        <Path
          d='M155.986 201.436C156.526 200.879 157.888 179.875 157.809 179.507C158.387 179.445 159.688 179.859 160.507 180.48C161.524 181.78 163.152 185.493 163.21 188.665C163.283 192.659 163.152 196.12 161.391 198.821C160.929 199.53 157.782 201.222 155.988 201.438L155.986 201.436Z'
          fill='#FFB199'
        />
        <Path
          d='M137.649 176.118C144.992 175.43 155.811 172.466 159.019 181.018C160.572 185.937 161.453 195.126 158.455 199.228C152.703 206.507 143.817 206.222 135.445 208.386C126.512 210.149 111.99 213.02 105.469 205.92C101.435 201.363 99.7452 194.166 100.614 188.291C100.797 187.045 101.163 185.919 102.491 185.613C108.733 183.686 128.163 177.251 137.614 176.122L137.651 176.118H137.649Z'
          fill='#3BA5B5'
        />
        <Path
          d='M116.342 183.293C119.549 180.565 123.439 178.486 127.593 177.622C128.98 177.312 130.391 177.154 131.799 177.089C132.061 177.077 132.283 177.279 132.294 177.541C132.306 177.784 132.129 177.992 131.897 178.031C129.217 178.524 126.587 179.187 124.069 180.22C121.518 181.224 119.204 182.665 116.83 184.037C116.356 184.291 115.918 183.628 116.342 183.293Z'
          fill='#1D92A5'
        />
        <Path
          d='M111.639 208.567C113.381 209.207 115.296 209.247 117.142 209.291C120.949 209.33 124.77 208.948 128.551 208.484C134.237 207.758 139.907 206.725 145.445 205.272C148.187 204.526 150.932 203.704 153.462 202.428C154.235 202.01 155.058 201.53 155.605 200.864C155.763 200.648 156.075 200.607 156.283 200.775C156.48 200.935 156.516 201.222 156.366 201.426C155.701 202.324 154.834 202.864 153.979 203.372C151.41 204.815 148.597 205.721 145.799 206.55C143.938 207.095 142.057 207.559 140.165 207.977C132.595 209.586 124.865 210.706 117.103 210.461C115.119 210.326 113.138 210.209 111.267 209.378C110.762 209.124 111.082 208.349 111.633 208.567H111.639Z'
          fill='#1D92A5'
        />
        <Path
          d='M193.815 169.651C192.931 171.626 191.694 173.434 190.112 174.927C189.563 175.422 188.95 175.892 188.193 176.173C187.948 176.264 187.673 176.139 187.582 175.892C187.501 175.678 187.588 175.447 187.769 175.324C189.26 174.145 190.522 172.276 191.719 170.782C192.147 170.235 192.586 169.69 193.064 169.166C193.233 168.981 193.517 168.969 193.702 169.137C193.848 169.27 193.885 169.476 193.813 169.649L193.815 169.651Z'
          fill='#C17540'
        />
        <Path
          d='M195.072 176.004C194.642 177.642 193.497 179.033 192.125 179.992C191.661 180.31 191.166 180.58 190.636 180.765C190.384 180.852 190.11 180.719 190.023 180.47C189.956 180.276 190.019 180.071 190.164 179.944C190.925 179.281 191.663 178.636 192.343 177.9C192.935 177.274 193.723 176.291 194.272 175.611C194.574 175.231 195.189 175.541 195.07 176.006L195.072 176.004Z'
          fill='#C17540'
        />
        <Path
          d='M194.237 182.661C193.994 183.555 193.391 184.318 192.698 184.906C192.014 185.482 191.239 185.933 190.386 186.195C190.129 186.27 189.858 186.125 189.781 185.865C189.727 185.684 189.786 185.495 189.915 185.372C190.507 184.807 191.145 184.328 191.75 183.8C192.266 183.36 192.966 182.69 193.461 182.216C193.781 181.879 194.361 182.216 194.235 182.657L194.237 182.661Z'
          fill='#C17540'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_1371_44597'>
          <Rect width='348' height='212.569' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { BenefitGroup, serializeBenefitCategories } from '@lyrahealth-inc/shared-app-logic'
import { ENTRY_CARD_DISMISSED_LOCAL_STORAGE_KEY } from '@lyrahealth-inc/ui-core-crossplatform'

import { GET_ALL_BENEFITS_FOR_USER_fulfilled, GET_RECOMMENDED_BENEFITS_FOR_USER_fulfilled } from './benefitsActions'

const initialState = fromJS({})
export default createReducer(initialState, {
  [GET_RECOMMENDED_BENEFITS_FOR_USER_fulfilled]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const benefitGroups: BenefitGroup[] = action?.payload ?? []
    const newRecommendations = serializeBenefitCategories({ benefitGroups })
    const oldRecommendations = localStorage.getItem(ENTRY_CARD_DISMISSED_LOCAL_STORAGE_KEY)
    if (newRecommendations !== oldRecommendations) {
      localStorage.removeItem(ENTRY_CARD_DISMISSED_LOCAL_STORAGE_KEY)
    }

    return $$state.set('recommendedBenefitGroupsForUser', action?.payload)
  },
  [GET_ALL_BENEFITS_FOR_USER_fulfilled]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('allBenefitGroupsForUser', action?.payload)
  },
})

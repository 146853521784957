import { PostHealthPlanPaymentInfoBody, PostInfractionPaymentInfoBody } from '@lyrahealth-inc/shared-app-logic'
import { hrefUtils } from '@lyrahealth-inc/ui-core'

import * as BannerActions from '../../../common/components/banner/banner-actions'
import {
  downloadPaymentsData,
  getEligibilityData,
  getHealthPlanPaymentData,
  getPaymentHistoryData,
  removeHealthPlanInfoData,
  removeHealthPlanPaymentData,
  submitHealthPlanInfoData,
  submitHealthPlanPaymentData,
} from '../../../common/http/data/healthPlan'
import { getBaseApplicationData } from '../../../data/login/loginActions'
import { AppDispatch } from '../../../data/storeConfiguration/store'

export const SET_HEALTHPLAN_DATA = 'SET_HEALTHPLAN_DATA'
export const SET_PATIENT_DATA = 'SET_PATIENT_DATA'
export const SUBMIT_HEALTHPLAN_INFO = 'SUBMIT_HEALTHPLAN_INFO'
export const SUBMIT_SECONDARY_HEALTHPLAN_INFO = 'SUBMIT_SECONDARY_HEALTHPLAN_INFO'
export const REMOVE_HEALTHPLAN_INFO = 'REMOVE_HEALTHPLAN_INFO'
export const SUBMIT_HEALTHPLAN_PAYMENT = 'SUBMIT_HEALTHPLAN_PAYMENT'
export const UPDATE_HEALTHPLAN_PAYMENT = 'UPDATE_HEALTHPLAN_PAYMENT'
export const REMOVE_HEALTHPLAN_PAYMENT = 'REMOVE_HEALTHPLAN_PAYMENT'
export const UPDATE_INFRACTION_PAYMENT = 'UPDATE_INFRACTION_PAYMENT'
export const REMOVE_INFRACTION_PAYMENT = 'REMOVE_INFRACTION_PAYMENT'
export const START_HEALTHPLAN_AND_INFRACTION_CARD_SETUP = 'START_HEALTHPLAN_AND_INFRACTION_CARD_SETUP'
export const STOP_HEALTHPLAN_AND_INFRACTION_CARD_SETUP = 'STOP_HEALTHPLAN_AND_INFRACTION_CARD_SETUP'
export const UPDATE_HEALTHPLAN_AND_INFRACTION_CARD_REQUEST_TYPE = 'UPDATE_HEALTHPLAN_AND_INFRACTION_CARD_REQUEST_TYPE'
export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY'
export const GET_ELIGIBILITY_OPTIONS = 'GET_ELIGIBILITY_OPTIONS'
export const GET_HEALTHPLAN_PAYMENT = 'GET_HEALTHPLAN_PAYMENT'
export const GET_UNRESOLVED_INFRACTIONS = 'GET_UNRESOLVED_INFRACTIONS'

export const submitHealthPlanInfo = (lyraId: string, data: $TSFixMe) => {
  return (dispatch: AppDispatch) => {
    return new Promise(function (resolve, reject) {
      submitHealthPlanInfoData(lyraId, data).then(
        (response) => {
          if (response.data.medicalPlanEligibilityStatus !== 'ineligible') {
            dispatch({
              type: data?.isSecondary ? SUBMIT_SECONDARY_HEALTHPLAN_INFO : SUBMIT_HEALTHPLAN_INFO,
              payload: response,
            })
          }
          resolve(response)
        },
        (error) => {
          showErrorBanner(error, dispatch)
          reject(error)
        },
      )
    })
  }
}

export const removeHealthPlanInfo = (lyraId: string, isSecondary = false) => {
  return (dispatch: AppDispatch) => {
    return new Promise(function (resolve, reject) {
      removeHealthPlanInfoData(lyraId, isSecondary).then(
        (response) => {
          dispatch(getBaseApplicationData()) // refresh data flags
          dispatch(fetchPatientData())
          dispatch({
            type: REMOVE_HEALTHPLAN_INFO,
          })
          resolve(response)
        },
        (error) => {
          showErrorBanner(error, dispatch)
          reject(error)
        },
      )
    })
  }
}

export const submitHealthPlanPayment = (lyraId: string, data: PostHealthPlanPaymentInfoBody) => {
  return (dispatch: AppDispatch) => {
    return new Promise(function (resolve, reject) {
      submitHealthPlanPaymentData(lyraId, data).then(
        (response) => {
          dispatch({
            type: SUBMIT_HEALTHPLAN_PAYMENT,
            payload: response,
          })
          resolve(response)
        },
        (error) => {
          showErrorBanner(error, dispatch)
          reject(error)
        },
      )
    })
  }
}

export const removeHealthPlanPayment = (lyraId: string) => {
  return (dispatch: AppDispatch) => {
    return new Promise(function (resolve, reject) {
      removeHealthPlanPaymentData(lyraId).then(
        (response) => {
          dispatch(getBaseApplicationData()) // refresh data flags
          dispatch(fetchPatientData())
          dispatch({
            type: REMOVE_HEALTHPLAN_PAYMENT,
          })
          resolve(response)
        },
        (error) => {
          showErrorBanner(error, dispatch)
          reject(error)
        },
      )
    })
  }
}

export const getUnresolvedInfractions = ({ lyraId }: { lyraId: string }) => {
  return {
    action: GET_UNRESOLVED_INFRACTIONS,
    request: {
      method: 'get',
      url: `/v1/user/${lyraId}/unresolved-infractions`,
    },
  }
}

export const submitInfractionPayment = (lyraId: string, infractionCardData: PostInfractionPaymentInfoBody) => ({
  action: SUBMIT_HEALTHPLAN_PAYMENT,

  request: {
    method: 'post',
    url: `/v1/patient-payment-method/infraction/user/${lyraId}`,
    data: infractionCardData,
  },
})

export const updateInfractionPayment = (lyraId: string, infractionCardData: PostInfractionPaymentInfoBody) => ({
  action: UPDATE_INFRACTION_PAYMENT,

  request: {
    method: 'put',
    url: `/v1/patient-payment-method/infraction/user/${lyraId}`,
    data: infractionCardData,
  },
})

export const removeInfractionPayment = (lyraId: string) => ({
  action: REMOVE_INFRACTION_PAYMENT,
  request: {
    method: 'delete',
    url: `/v1/patient-payment-method/infraction/user/${lyraId}`,
  },
})

export const fetchPatientData = () => ({
  action: SET_PATIENT_DATA,
  request: {
    method: 'get',
    url: '/v1/patient/user',
  },
  errorHandler: (error: $TSFixMe) => console.error('Failed to fetch patient data', error),
})

export const setHealthPlanData = (data: $TSFixMe) => ({
  type: SET_HEALTHPLAN_DATA,
  data,
})
export const startHPAndInfractionCardsSetUp = () => ({ type: START_HEALTHPLAN_AND_INFRACTION_CARD_SETUP })
export const stopHPAndInfractionCardsSetUp = () => ({ type: STOP_HEALTHPLAN_AND_INFRACTION_CARD_SETUP })
export const updateHPAndInfractionCardRequestType = (val: string) => ({
  type: UPDATE_HEALTHPLAN_AND_INFRACTION_CARD_REQUEST_TYPE,
  val,
})

export const getPaymentHistory = (lyraId: $TSFixMe) => {
  return (dispatch: $TSFixMe) => {
    return new Promise(function (resolve, reject) {
      getPaymentHistoryData(lyraId).then(
        (response) => {
          dispatch({
            type: GET_PAYMENT_HISTORY,
            payload: response,
          })
          resolve(response)
        },
        (error) => {
          showErrorBanner(error, dispatch)
          reject(error)
        },
      )
    })
  }
}

export const getHealthPlanPayment = (lyraId: $TSFixMe) => {
  return (dispatch: $TSFixMe) => {
    return new Promise(function (resolve, reject) {
      getHealthPlanPaymentData(lyraId).then(
        (response) => {
          dispatch({
            type: GET_HEALTHPLAN_PAYMENT,
            payload: response,
          })
          resolve(response)
        },
        (error) => {
          showErrorBanner(error, dispatch)
          reject(error)
        },
      )
    })
  }
}

export const downloadPayments = (lyraId: $TSFixMe) => {
  return (dispatch: $TSFixMe) => {
    return new Promise(function (resolve, reject) {
      downloadPaymentsData(lyraId).then(
        (csvPayments) => {
          hrefUtils.createCSVDownload(csvPayments.data, 'payments_history.csv')
          // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
          resolve()
        },
        (error) => {
          showErrorBanner(error, dispatch)
          reject(error)
        },
      )
    })
  }
}

const showErrorBanner = (error: $TSFixMe, dispatch: $TSFixMe) => {
  dispatch(BannerActions.setBannerAndErrorMessage(error, true))
}

export const getEligibilityOptions = () => {
  return async (dispatch: $TSFixMe) => {
    try {
      const eligibilityOptions = await getEligibilityData()
      dispatch({
        type: GET_ELIGIBILITY_OPTIONS,
        data: eligibilityOptions.data,
      })
    } catch (error) {
      // Fail silently
    }
  }
}

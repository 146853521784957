import { List, Map } from 'immutable'
import { createSelector } from 'reselect'

import { fallbackICASPhoneNumber } from '@lyrahealth-inc/shared-app-logic'

import { getSupportedCountryList } from '../customerCountry/customerCountrySelectors'

export const getCurrentCountry = (state: $TSFixMe) => state.get('currentCountry', Map())

export const getCurrentCountryIsoCode = createSelector(getCurrentCountry, (country) => country?.get('isoCode'))

export const getCurrentCountryOutOfCountryNumbersList = createSelector(
  [getCurrentCountryIsoCode, getSupportedCountryList],
  (isoCode, supportedCountries) => {
    const list = supportedCountries
      .find((country: $TSFixMe) => country.get('isoCode') === isoCode)
      ?.get('outOfCountryNumbersList')
    if (!!list && !list.isEmpty()) {
      return list
    }
    return List([fallbackICASPhoneNumber])
  },
)

export const getCurrentCountryTollFreeNumbersList = createSelector(
  [getCurrentCountryIsoCode, getSupportedCountryList],
  (isoCode, supportedCountries) => {
    const list = supportedCountries
      .find((country: $TSFixMe) => country.get('isoCode') === isoCode)
      ?.get('tollFreeNumbersList')
    if (!!list && !list.isEmpty()) {
      return list
    }
    return List([fallbackICASPhoneNumber])
  },
)

export const getCurrentCountryEmergencyNumbersList = createSelector(
  getCurrentCountry,
  (country) => country?.get('emergencyPhoneNumbersList') ?? List(),
)

export const getCurrentCountryName = createSelector(getCurrentCountry, (country) => country?.get('name'))

import { useMemo } from 'react'

import { isEmpty } from 'lodash-es'

import { Benefit, BenefitGroup } from '@lyrahealth-inc/shared-app-logic'

export const useFilterBenefitsForExplorer = ({
  benefitGroups,
  shouldLimitNumberOfDisplayedBenefits = true,
  maxBenefitsToShowInExplorer,
}: {
  benefitGroups: BenefitGroup[]
  shouldLimitNumberOfDisplayedBenefits?: boolean
  maxBenefitsToShowInExplorer?: number
}): Map<string, Benefit[]> => {
  /* EXAMPLE DATA:
   *   maxBenefitsToShowInExplorer = 10
   *   benefitGroups = [
   *     {
   *        benefitCategory: 'families',
   *        benefits: [BENEFIT1],
   *     },
   *     {
   *        benefitCategory: 'chronicConditions'
   *        benefits: [BENEFIT2, BENEFIT3, BENEFIT4, BENEFIT5, BENEFIT6, BENEFIT7, BENEFIT8],
   *     },
   *     {
   *       benefitCategory: 'fertilityCare'
   *        benefits: [BENEFIT9, BENEFIT10],
   *     },
   *   ]
   *
   *  PROCESSING STEPS:
   *    1. Create a Map [BenefitGroup => COPY of BenefitGroup's benefits (sorted by rank)]
   *      - This map serves 2 purposes
   *        a. Provide O(1) access to a group's benefits
   *        b. Copy the groups' benefits, so processing them here won't directly mutate the benefits
   *    2. Create another Map [BenefitGroup => BenefitGroup's selected benefits for display]
   *        - {
   *            'families' => [],
   *            'chronicConditions' => [],
   *            'fertilityCare' => [],
   *          }
   *      - Using a map here rather than a singular list (e.g. "benefitItemsToDisplay")
   *        to keep each group's benefits together until display, at which point the map will
   *        be distilled to a list
   *    3. While totalBenefitsSelected < maxBenefitsToShowInExplorer && there are remaining groups with remaining
   *       benefits, then TAKE 1 BENEFIT FROM EACH GROUP until totalBenefitsSelected == maxBenefitsToShowInExplorer
   *       OR all the groups' benefits are exhausted
   *       - Rather than taking benefits from a single group (in this case, "chronicConditions") until we're
   *         max-ed out, we loop over each group & take 1 until full. This is so we try to represent each
   *         group equally in terms of # of benefits shown
   */
  return useMemo(() => {
    if (isEmpty(benefitGroups)) {
      return new Map<string, Benefit[]>()
    }

    // 1. Create a Map [BenefitGroup => COPY of BenefitGroup's benefits (sorted by rank)]
    const benefitsMap = new Map<string, Benefit[]>(
      benefitGroups.map(({ benefitCategory, benefits }) => {
        const copiedBenefits = benefits.slice()
        const benefitsSortedByRank = copiedBenefits.sort((a, b) => a.rank - b.rank)
        return [benefitCategory, benefitsSortedByRank]
      }),
    )

    // 2. Create another Map [BenefitGroup => BenefitGroup's selected benefits for display]
    let totalBenefitsSelected = 0
    const selectedBenefitsToDisplay = new Map<string, Benefit[]>(
      benefitGroups.map(({ benefitCategory: mapKey }) => [mapKey, []]),
    )

    /* 3. While totalBenefitsSelected < maxBenefitsToShowInExplorer && there are remaining groups with remaining
     *    benefits, then TAKE 1 BENEFIT FROM EACH GROUP until totalBenefitsSelected == maxBenefitsToShowInExplorer
     *    OR all the groups' benefits are exhausted */
    let groupIdx = -1
    const emptiedGroups = new Set()

    while (
      (shouldLimitNumberOfDisplayedBenefits
        ? maxBenefitsToShowInExplorer && totalBenefitsSelected < maxBenefitsToShowInExplorer
        : true) &&
      emptiedGroups.size < benefitGroups.length
    ) {
      groupIdx = (groupIdx + 1) % benefitGroups.length
      const mapKey = benefitGroups[groupIdx].benefitCategory
      const remainingBenefitsOfGroup = benefitsMap.get(mapKey) ?? []
      if (remainingBenefitsOfGroup.length === 0) {
        emptiedGroups.add(mapKey)
        continue
      }
      const nextBenefit = remainingBenefitsOfGroup.shift() as Benefit
      const selectedBenefits = selectedBenefitsToDisplay.get(mapKey) ?? []
      selectedBenefitsToDisplay.set(mapKey, [...selectedBenefits, nextBenefit])
      totalBenefitsSelected++
    }

    return selectedBenefitsToDisplay
  }, [benefitGroups, maxBenefitsToShowInExplorer, shouldLimitNumberOfDisplayedBenefits])
}

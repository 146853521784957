import React from 'react'
import { defineMessage } from 'react-intl'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'
import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

export const hologic: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return {
    legalNotice: (
      <CoverageNotice
        excludedServices={defineMessage({
          defaultMessage:
            'Lyra does not cover inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication* (*If not enrolled in the BCBS medical plan), autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care.',
          description:
            'Text for describing the services that Lyra does not cover, displayed as part of the coverage notice',
        })}
        sessionLimit={numberOfSessions}
      />
    ),
    servicesNotCovered:
      'Services that are not covered include: inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication* (*If not enrolled in the BCBS medical plan), autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care. Please check with your medical plan or benefits portal for coverage of these excluded services.',
  }
}

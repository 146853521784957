import React, { FunctionComponent } from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const LiveMessagingIcon: FunctionComponent = () => {
  const { colors } = useTheme()
  const componentColors = colors.icons.liveMessagingIcon

  return (
    <Svg width={24} height={24} fill='none'>
      <Circle cx={12} cy={12} r={12} fill={componentColors.background} />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 14.25V9.875C17 8.844 16.206 8 15.235 8h-6.47C7.795 8 7 8.844 7 9.875v4.375c0 .919.63 1.688 1.459 1.844.012.362-.053.944-.459 1.531a.23.23 0 0 0-.018.244.213.213 0 0 0 .194.131c1.318 0 2.377-1.25 2.824-1.875h4.235c.97 0 1.765-.844 1.765-1.875Zm-7.648-3.281c0 .256.2.469.44.469h4.412c.242 0 .442-.213.442-.47 0-.255-.2-.468-.442-.468H9.793c-.241 0-.441.213-.441.469Zm5.294 2.187c0-.256-.2-.469-.442-.469H9.793c-.241 0-.441.213-.441.47 0 .255.2.468.44.468h4.412c.242 0 .442-.213.442-.469Z'
        fill={componentColors.text}
      />
    </Svg>
  )
}

import React from 'react'

import { eligibilityDelayNoticeForApple } from '@lyrahealth-inc/shared-app-logic'

import { AppleEmployeeIdSublabel, ConfirmEligibilityDisclaimer } from '../../../atoms'
import { BodyTextSize } from '../../../styles'

export const AppleEligibilityInformation: React.FC<{
  isConfirmEligibility: boolean // true if it's displayed from confirm eligibility page
  onContactCNT?: () => void // only needed if it's displayed from confirm eligiblity page
}> = ({ isConfirmEligibility, onContactCNT }) => {
  const textSize = BodyTextSize.DEFAULT

  return isConfirmEligibility ? (
    <AppleEmployeeIdSublabel
      textSize={textSize}
      style={{ marginBottom: 12 }}
      text={
        <ConfirmEligibilityDisclaimer contextProps={{ stringMessage: eligibilityDelayNoticeForApple, onContactCNT }} />
      }
    />
  ) : (
    <AppleEmployeeIdSublabel textSize={textSize} />
  )
}

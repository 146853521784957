import { useMemo } from 'react'

import { Appointment } from '../../../models/appointment/Appointment'
import { Program } from '../../../models/programs/Programs'

export const useLateCancellationFee = (programs: Program[], appointment?: Appointment) => {
  const lateCancellationFee = useMemo(() => {
    const apptProviderProgram = programs.find(
      (program) => program?.provider_modality === appointment?.provider?.modalities?.[0],
    )
    return apptProviderProgram?.late_cancel_charge_initial
  }, [programs, appointment])
  return lateCancellationFee
}

import { TREATMENT_OPTIONS } from './treatmentOptions'

const MODALITIES_TO_TREATMENTS = {
  alcoholusedisordertherapy: {
    display: 'Lyra Renew',
    formValue: TREATMENT_OPTIONS.ALCOHOL_USE_DISORDER,
  },
  clinicalleaveevaluation: {
    display: 'Clinical Leave Evaluation',
    formValue: TREATMENT_OPTIONS.CLINICAL_LEAVE_EVALUATION,
  },
  couples: {
    display: 'Couples Therapy',
    formValue: TREATMENT_OPTIONS.COUPLES_THERAPY,
  },
  family: {
    display: 'Family Therapy',
    formValue: TREATMENT_OPTIONS.FAMILY_THERAPY,
  },
  individual: {
    display: 'Individual Therapy',
    formValue: TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  },
  lyramedmanagement: {
    display: 'Medication Consultation',
    formValue: TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  },
  lyratherapy: {
    display: 'Individual Therapy',
    formValue: TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  },
  stressmanagement: {
    display: 'Coaching',
    formValue: TREATMENT_OPTIONS.COACHING,
  },
  singlesessioncoaching: {
    display: 'Guided Self-Care',
    formValue: TREATMENT_OPTIONS.GUIDED_SELF_CARE,
  },
  'medication management': {
    display: 'Medication Consult',
    formValue: TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS,
  },
}

// Given a provider's treatment modality such as 'individual,' 'lyraTherapy,' or 'singleSessionCoaching,'
// return the corresponding treatment option
export const convertProviderTreatmentModalityToTreatmentOption = (modality: string) => {
  return MODALITIES_TO_TREATMENTS[modality.toLowerCase()]?.formValue
}

/**
 * Maps provider "modalities" to valid treatment options.
 *
 * In a provider object, the "modalities" attribute somewhat describes the groups of people to which the provider
 * supplies therapy. This array consists of the values ["couples", "family", "group", "individual",
 * "lyraTherapy", "stressManagement"].
 *
 * The mapping is required because:
 * * Group therapy is not a valid treatment option.
 * * There is no complete attribute that accurately describes all of the treatment options a provider can provide,
 *   so this attribute is our best option.
 *
 * @param modalities
 * @returns {*}
 */
export const convertProviderTreatmentModalitiesToDisplayedTreatmentOptions = (modalities: string[]) => {
  return modalities
    .filter((modality) => MODALITIES_TO_TREATMENTS[modality.toLowerCase()])
    .map((modality) => MODALITIES_TO_TREATMENTS[modality.toLowerCase()])
}

export default {
  TREATMENT_OPTIONS,
  MODALITIES_TO_TREATMENTS,
  convertProviderTreatmentModalityToTreatmentOption,
  convertProviderTreatmentModalitiesToDisplayedTreatmentOptions,
}

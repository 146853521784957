export const EmailCaptureExperimentUUID = 'd8623b36-d4b0-4140-b95f-fab0c6e5fd7a'

export enum EmailCaptureVariant {
  GENERAL = 'general',
  PROVIDER = 'provider',
  SELF_CARE = 'selfcare',
}

export enum EmailCaptureArmType {
  CONTROL = 'control',
  EXPERIMENT = 'experiment',
}

export enum EmailCaptureMetric {
  VIEW_PAGE = 'view email capture page',
  CLICK_CONTINUE = 'click continue',
  CLICK_CONTINUE_WITH_GOOGLE = 'click continue with google',
  CLICK_CONTINUE_WITH_APPLE = 'click continue with apple',
}

export enum EmailCaptureDescription {
  VIEW_PAGE = 'user lands on email capture page',
  CLICK_CONTINUE = 'user clicks continue to submit email address',
  CLICK_CONTINUE_WITH_GOOGLE = 'user clicks continue with google to submit email address using google',
  CLICK_CONTINUE_WITH_APPLE = 'user clicks continue with apple to submit email address using apple',
}

export interface EmailCaptureExperimentJoinKeys {
  customer_name: string
  description: string
  device_uuid: string
  metric: EmailCaptureMetric
  variant: EmailCaptureVariant | null
}

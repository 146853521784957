import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { COMMON_MIXPANEL_PAGE, useFlags, useLyraIntl, WELLNESS_TOOLKIT_TYPE } from '@lyrahealth-inc/shared-app-logic'
import { useIsWellnessToolkitAvailable as useIsWellnessToolkitAvailableShared } from '@lyrahealth-inc/shared-app-logic/src/features/wellnessToolkit/hooks/useIsWellnessToolkitAvailable'

import {
  getEssentialsDisabled,
  getHideChildSearchCTA,
  getHideDependentField,
  getIsCoachingEnabled,
} from '../../data/customer/customerSelectors'
import { getEpisodes } from '../../data/lyraTherapy/lyraTherapySelectors'
import {
  getId,
  getIsUserInternational,
  getIsUserLoggedIn,
  getIsUserTeen,
  getUserChildren,
  getUserCountryName,
  getUserDisplayLanguage,
  getUserDOB,
  getUserHasIndicatedIsGuardian,
} from '../../data/user/userSelectors'
import { postSpeculativeSearch } from '../../features/triage/data/triageActions'
import { getRiskFactors, getSearches } from '../../features/triage/data/triageSelectors'
import { useFetchChildren } from '../../hooks/useFetchChildren'

export const useIsWellnessToolkitAvailable = (toolkitType: WELLNESS_TOOLKIT_TYPE, page?: COMMON_MIXPANEL_PAGE) => {
  const { isFamilyHubEnabled, isCoachingEntryPointEnabled } = useFlags()
  const isEssentialsEnabled = !useSelector(getEssentialsDisabled)
  const isCoachingEnabled = useSelector(getIsCoachingEnabled)
  const hideChildSearchCTA = useSelector(getHideChildSearchCTA)
  const hideDependentField = useSelector(getHideDependentField)
  const isUserTeen = useSelector(getIsUserTeen)
  const isInternationalUser = useSelector(getIsUserInternational)
  const lyraId = useSelector(getId)
  const triageSearches = useSelector(getSearches)
  const triageSearchesMemoized = useMemo(() => {
    return triageSearches?.toJS() ?? []
  }, [triageSearches])
  const riskFactors = useSelector(getRiskFactors)
  const riskFactorsMemoized = useMemo(() => {
    return riskFactors?.toJS()
  }, [riskFactors])
  const episodes = useSelector(getEpisodes)
  const episodesMemoized = useMemo(() => {
    return episodes?.toJS() ?? []
  }, [episodes])
  const hasIndicatedIsGuardian = useSelector(getUserHasIndicatedIsGuardian)
  const isUserLoggedIn = useSelector(getIsUserLoggedIn)
  const userDOB = useSelector(getUserDOB)
  const userCountryName = useSelector(getUserCountryName)
  const userChildren = useSelector(getUserChildren)
  const userChildrenMemoized = useMemo(() => {
    return userChildren?.toJS()
  }, [userChildren])
  const userDisplayLanguage = useSelector(getUserDisplayLanguage)
  const { activeLanguage } = useLyraIntl()
  const displayLanguage = isUserLoggedIn ? userDisplayLanguage : activeLanguage

  return useIsWellnessToolkitAvailableShared({
    toolkitType,
    page,
    lyraId,
    isUserTeen,
    triageSearches: triageSearchesMemoized,
    isCoachingEnabled,
    riskFactors: riskFactorsMemoized,
    episodes: episodesMemoized,
    isUserLoggedIn,
    isInternationalUser,
    postSpeculativeSearch,
    userDOB,
    userCountryName,
    isEssentialsEnabled,
    hideDependentField,
    hideChildSearchCTA,
    hasIndicatedIsGuardian,
    userChildren: userChildrenMemoized,
    isFamilyHubEnabled,
    isCoachingEntryPointEnabled,
    displayLanguage,
    useFetchChildrenHook: useFetchChildren,
  })
}

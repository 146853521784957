import { defineMessage } from 'react-intl'

export const carpenterbenefits = () => {
  return {
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage: 'Are you a member of MACRC Health Plan?',
        description: 'Asking if the user is a member of the MACRC Health Plan',
      }),
  }
}

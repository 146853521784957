import { List, Map } from 'immutable'
import { createSelector } from 'reselect'

import { Episode } from '@lyrahealth-inc/shared-app-logic'

const getLyraTherapy = (state: Map<string, any>) => state.get('lyraTherapy', Map())

export const getLyraTherapyAssignments = createSelector(getLyraTherapy, (lyraTherapy) =>
  lyraTherapy?.get('assignments', Map()),
)

export const getLyraTherapyAssignmentsData = createSelector(getLyraTherapyAssignments, (lyraTherapyAssignments) =>
  lyraTherapyAssignments?.get('data', List()),
)

export const getLyraTherapyAssignmentsVideoLinksData = createSelector(
  getLyraTherapyAssignments,
  (lyraTherapyAssignments) => lyraTherapyAssignments?.get('videoLinks', List()),
)

export const getLyraTherapyAssignmentsSelectedIndex = createSelector(
  getLyraTherapyAssignments,
  (lyraTherapyAssignments) => lyraTherapyAssignments?.get('selectedIndex'),
)

export const getLyraTherapyEpisodes = createSelector(getLyraTherapy, (lyraTherapy) =>
  lyraTherapy?.get('episodes', List()),
)

export const getChildCurrentEpisodes = createSelector(
  [getLyraTherapy, (_, lyraId) => lyraId],
  (lyraTherapy, lyraId) => {
    const episodes: Episode[] | undefined = lyraTherapy?.get('childEpisodes', Map())?.get(lyraId, List())?.toJS()
    return episodes?.filter((episode) => episode.state === 'in_progress') ?? []
  },
)

export const getLyraTherapyEpisodeHasStarted = createSelector(getLyraTherapyEpisodes, (episodes) => episodes.size > 0)

export const getLyraTherapyNotifications = createSelector(getLyraTherapy, (lyraTherapy) =>
  lyraTherapy?.get('notifications', Map()),
)

export const getLyraTherapyCustomNotifications = createSelector(
  getLyraTherapyNotifications,
  (lyraTherapyNotifications) => lyraTherapyNotifications?.get('customNotificationPreferences', Map()),
)

export const getLyraTherapyNotificationsPreferences = createSelector(
  getLyraTherapyNotifications,
  (lyraTherapyNotifications) => lyraTherapyNotifications?.get('preferences', Map()),
)

export const getShowSessionNotificationPrompt = createSelector(
  getLyraTherapyNotifications,
  (lyraTherapyNotifications) => lyraTherapyNotifications?.get('showSessionNotificationPrompt'),
)

export const getIntakeFormOpen = createSelector(getLyraTherapy, (lyraTherapy) => lyraTherapy?.get('intakeFormOpen'))

export const getFirstAppointmentBooked = createSelector(getLyraTherapy, (lyraTherapy) =>
  lyraTherapy?.get('firstAppointmentBooked'),
)

export const getJustShowedPushNotificationPrompt = createSelector(getLyraTherapy, (lyraTherapy) =>
  lyraTherapy?.get('justShowedPushNotificationPrompt'),
)

export const getViewedNotificationModal = createSelector(getLyraTherapy, (lyraTherapy) =>
  lyraTherapy?.get('viewedNotificationModal'),
)

export const getHasFetchedDashboard = createSelector(getLyraTherapy, (lyraTherapy) =>
  lyraTherapy?.get('dashboardFetched'),
)

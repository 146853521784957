import React from 'react'

import WhatIsTherapyVideoLink from '../components/VideoLink/VideoLink'
import { articlize, numberToWord, oneLine } from '../utils/stringUtils'

export const DEFAULT_QUESTIONS = {
  WHAT_IS_LYRA_HEALTH: 'What is Lyra Health?',
  DISABILITY_PAPERWORK: 'Can you help me file disability paperwork?',
  WILL_MY_EMPLOYER_KNOW: 'Will my employer know if I use Lyra?',
  TYPES_OF_ISSUES: 'What types of issues can Lyra help me solve?',
  WHAT_IS_COVERED: 'What is covered?',
  WHAT_DOES_LYRA_NOT_COVER: 'What does Lyra not cover?',
  HOW_LONG_TO_USE: 'How long does it take to use Lyra?',
  HOW_DOES_LYRA_WORK: 'How does Lyra work?',
  HOW_MUCH_DOES_LYRA_COST: 'How much does Lyra cost?',
  FORMATS_FOR_CARE: 'What formats for care are available?',
  WHO_CAN_USE_LYRA: 'Who can use Lyra?',
  HOW_TO_RECOMMEND_LYRA: 'How can I recommend Lyra to a colleague, dependent or household member?',
  HOW_TO_RECOMMEND_LYRA_TO_A_COLLEAGUE_OR_DEPENDENT: 'How can I recommend Lyra to a colleague or dependent?',
  WHAT_DOES_THERAPY_ENTAIL: 'I’m new to therapy. What does it entail?',
  WHAT_IS_THE_DIFFERENCE_BETWEEN_THERAPY_AND_COACHING: 'What is the difference between therapy and coaching?',
  WHY_DOES_LYRA_COLLECT_FEEDBACK: 'Why does Lyra collect feedback after my therapy sessions?',
  WHATS_DIFFERENT_ABOUT_LYRA: 'What’s different about Lyra compared to my health plan?',
  CONTINUING_TO_SEE_PROVIDER:
    'If I’m already seeing a provider, can I continue to see them and have the session costs covered through the Lyra benefit?',
  WORK_LIFE_SERVICES: 'Does Lyra offer work life services?',
  WHAT_HAPPENS_IF_I_MISS_AN_APPOINTMENT: 'What happens if I miss an appointment or need to cancel last-minute?',
  WHAT_IF_I_MISS_AN_APPOINTMENT: 'What if I miss an appointment or need to cancel last-minute?',
  WHAT_IF_I_REACH_MY_SESSION_LIMIT: 'What if I reach my session limit and I want to continue meeting with my provider?',
  HOW_DOES_MEETING_VIA_LIVE_VIDEO_WORK: 'How does meeting with a provider via live video work?',
  HOW_DOES_MEETING_VIA_LIVE_MESSAGING_WORK: 'How does meeting with a provider via live messaging work?',
  HOW_DOES_ONSITE_SCHEDULING_WORK: 'How does scheduling for onsite therapy work?',
  CAN_I_USE_LYRA_WHILE_TRAVELING: 'Can I use Lyra if I’m traveling?',
  HOW_IS_LYRA_DIFFERENT_FROM_MEDICAL_PLAN: 'How is Lyra different from my medical plan?',
  IF_I_GO_ON_LEAVE_WILL_LYRA_COVER_MY_CARE: 'If I go on leave, will Lyra continue to cover my cost of care?',
  IF_I_LEAVE_OR_LOSE_MY_JOB_WILL_LYRA_COVER_MY_CARE:
    'If I leave or lose my job, will Lyra still cover the cost of my care?',
  WHAT_IS_IT_LIKE_GETTING_CARE_WITH_LYRA: 'What’s it like getting care with Lyra?',
  WHAT_IS_A_MENTAL_HEALTH_COACH: 'What is a mental health coach?',
  WHAT_IS_GUIDED_SELF_CARE: 'I’m not familiar with Guided Self-Care, what is it?',
  WHAT_IS_MENTAL_HEALTH_COACHING: 'I’m not familiar with Mental Health Coaching, what is it?',
  DIFFERENCE_BETWEEN_COACHING_AND_THERAPY: 'What’s the difference between Mental Health Coaching and therapy?',
  WHAT_IF_I_WANT_TO_FIND_PROVIDER_OF_COLOR: 'What if I want to find a provider of color?',
  WHAT_IS_MEDICATION_MANAGEMENT: 'I’m not familiar with medication management, what is it?',
}

export const COMMON_2020_FAQ_QUESTIONS = {
  WHAT_DOES_THERAPY_ENTAIL: () => 'I’m new to therapy. What is it like?',
  WILL_MY_EMPLOYER_KNOW: ({ customerName }: $TSFixMe) => `Will ${customerName} know if I use Lyra?`,
  IF_I_GO_ON_LEAVE_WILL_LYRA_COVER_MY_CARE: ({ customerName }: $TSFixMe) =>
    `If I go on leave, will ${customerName} continue to cover my cost of care?`,
  IF_I_LEAVE_OR_LOSE_MY_JOB_WILL_LYRA_COVER_MY_CARE: ({ customerName }: $TSFixMe) =>
    `If I leave or lose my job, will ${customerName} still cover the cost of my care?`,
  CONTINUING_TO_SEE_PROVIDER: () => 'Will Lyra cover the cost of my current provider?',
  CURRENTLY_SEEING_A_PROVIDER_THROUGH: ({ previousEAP }: $TSFixMe) =>
    `I’m currently seeing a provider through ${previousEAP}. What do I do?`,
}

export const COMMON_2021_FAQ_QUESTIONS = {
  WHY_DOES_LYRA_COLLECT_FEEDBACK: () => 'Why does Lyra collect feedback after my care sessions?',
  WHAT_DOES_THERAPY_ENTAIL: () => 'I’m new to therapy, what is it like?',
}

export const EXCLUDED_SERVICES = ({ considerMeds = 'false' } = {}) =>
  oneLine(
    `${considerMeds === 'true' ? '' : 'psychiatry, '}inpatient or residential treatment, hospitalization 
  (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription 
  medication, autism spectrum disorder treatment, services for remedial education, executive coaching, and 
  non-evidence-based behavioral health care`,
  )

export const COMMON_2020_FAQ_ANSWERS = {
  WHAT_IS_LYRA_HEALTH: ({
    customerName,
    whoIsEligible = 'employees and their dependents',
    worklifeServices = 'legal, identity theft, financial, and dependent care',
  }: $TSFixMe) => (
    <h3>
      {oneLine(
        `Lyra is ${articlize(customerName)} ${customerName}-sponsored benefit that connects ${whoIsEligible} to 
        mental and emotional health care that is effective, convenient, and personalized. Using technology, proven 
        therapeutic methods, and a network of top therapists and coaches, Lyra matches you to the right care for your 
        needs, from short-term therapy and coaching to self-guided online programs.`,
      )}
      {worklifeServices && (
        <>
          {' '}
          Lyra also provides {WorklifeLink()} to help you work through personal life obstacles. These services include{' '}
          {worklifeServices} services.
        </>
      )}
    </h3>
  ),
  SERVICES_NOT_COVERED: ({ considerMeds = 'false' } = {}) =>
    oneLine(
      `Services that are not covered include: ${EXCLUDED_SERVICES({ considerMeds })}. Please check with your medical 
      plan or benefits portal for coverage of these excluded services.`,
    ),
  TYPES_OF_ISSUES: () =>
    oneLine(
      `Lyra can help with stress, anxiety, depression, relationship issues, sleep disorders, 
    and other common issues that can be addressed through short-term care services such as therapy, coaching, or 
    self-guided apps. Maybe you are feeling sad, disinterested in things you used to enjoy, or maybe anxiety feels 
    like it’s taken over your life. Perhaps you can’t sleep or eat or you are sleeping or eating too much. You may 
    feel stuck in a relationship or job and don’t know what to do next.`,
    ),
  WHAT_TYPES_OF_ISSUES_CAN_LYRA_HELP_ME_SOLVE: () => (
    <h3>
      {oneLine(
        `Lyra can help with stress, anxiety, depression, communication issues, sleep disorders, and other 
              common issues that can be addressed through short-term care services such as therapy, coaching, or 
              self-guided apps. Maybe you are feeling sad, disinterested in things you used to enjoy, or maybe anxiety 
              feels like it’s taken over your life. Perhaps you can’t sleep or eat or you are sleeping or eating too 
              much.`,
      )}
      <br />
      <br />
      {oneLine(`Lyra can also help with family or couple issues. Whether you feel stuck in a relationship 
            and don’t know what to do next, are going through a rough patch with your partner, or there has been a 
            change that impacts the entire family, Lyra providers can help.`)}
    </h3>
  ),
  /* eslint-disable react/prop-types */
  HOW_LONG_TO_USE: ({ phone }: $TSFixMe) => (
    <h3>
      {oneLine(`It usually takes less than 5 minutes to complete the `)}
      <a href={`${window.location.protocol}//${window.location.host}`}>online assessment</a>
      {oneLine(` and get connected to a top therapist, coach, or self-guided program for your needs.
          You can also call the care navigator team at ${phone} to start the conversation immediately.`)}
    </h3>
  ),
  HOW_DOES_LYRA_WORK: () =>
    oneLine(
      `Use our online platform or contact our care navigator team directly to tell us about what you’re going through. 
    We’ll then share how we can help with different care options, such as therapy, coaching, and self-guided 
    programs that fit your needs and preferences, and get you started right away. Throughout your experience with 
    Lyra we’ll check in to see how it’s going and be there for you every step of the way.`,
    ),
  WILL_MY_EMPLOYER_KNOW: ({ customerName }: $TSFixMe) => (
    <h3>
      {oneLine(
        `Lyra does not share information with ${customerName} that identifies which individuals are in care unless 
        you request us to share this information. For more details about the types of information we collect and/or 
        share, please see our `,
      )}
      <a href='https://www.lyrahealth.com/privacy-policy/' target='_blank' rel='noreferrer'>
        privacy policy{' '}
      </a>
      {oneLine(` and `)}
      <a href='https://www.lyrahealth.com/hipaa-notice/' target='_blank' rel='noreferrer'>
        HIPAA notice
      </a>
      {oneLine(`.`)}
    </h3>
  ),
  HOW_MUCH_DOES_LYRA_COST: ({ numberOfSessions, customerName, worklifeServices = true }: $TSFixMe) => (
    <h3>
      {oneLine(
        `There’s no cost to you for emotional health care as a benefits-eligible employee or dependent! 
              Lyra has partnered with ${customerName} to cover the cost of outpatient therapy and mental health coaching 
              services. We cover up to ${numberOfSessions} sessions per calendar year as long as you are eligible for 
              benefits from ${customerName}.`,
      )}
      {worklifeServices && (
        <>
          <br />
          <br />
          {oneLine(
            `We also cover the cost of your first consultations with work life services, after which 
              you will be able to pay a discounted payment if you choose to continue engagement with the service.`,
          )}
        </>
      )}
    </h3>
  ),
  FORMATS_FOR_CARE: ({ phone, customerName, linkText = `${customerName.toLowerCase()}.lyrahealth.com` }: $TSFixMe) => (
    <h3>
      {oneLine(
        `For therapy, you can choose whether to meet in a provider’s office or by live video from any place
            with an internet connection. For mental health coaching, you have the option to meet by live video or 
            telephone for your sessions. There are also digital self-guided apps available. Not sure what is right for 
            you? The Lyra care navigator team can answer your questions 24/7 by phone at ${phone}, by email at `,
      )}
      <a href='mailto:care@lyrahealth.com'>care@lyrahealth.com</a>
      {oneLine(`, or by web chat at `)}
      <a href={`${window.location.protocol}//${window.location.host}`}>{linkText}</a>
      {oneLine(`.`)}
    </h3>
  ),
  WHO_CAN_USE_LYRA: ({
    customerName,
    whoIsEligible = 'employees, spouses and domestic partners, and dependents (up to age 26)',
  }: $TSFixMe) => oneLine(`Lyra is here to serve U.S. benefits-eligible ${customerName} ${whoIsEligible}.`),
  CURRENTLY_SEEING_A_PROVIDER_THROUGH: ({
    startDate,
    customerName,
    previousEAP,
    numberOfSessions,
    phone,
    linkText = `${customerName.toLowerCase()}.lyrahealth.com`,
  }: $TSFixMe) => (
    <h3>
      {oneLine(
        `Starting ${startDate}, ${customerName} is transitioning their EAP from ${previousEAP} to Lyra. If you’re 
        currently seeing a therapist through ${previousEAP}, you’ll have the option to transition to a Lyra provider 
        starting on ${startDate} and have the costs of your sessions covered, up to ${numberOfSessions} sessions per 
        year. You can get started at `,
      )}
      {MicrositeHomeLink({ linkText })}
      {oneLine(` or, if you have questions, contact Lyra’s care navigator team at `)}
      <a href='mailto:care@lyrahealth.com'>care@lyrahealth.com</a>
      {oneLine(` or ${phone}.`)}
    </h3>
  ),
  IF_I_GO_ON_LEAVE_WILL_LYRA_COVER_MY_CARE: ({
    customerName,
    benefitsTeam = `${customerName}’s benefits team`,
  }: $TSFixMe) =>
    oneLine(
      `As long as you remain eligible for benefits from ${customerName} during your leave, you will continue to be 
      eligible for the Lyra benefit. If you were not eligible for benefits before your leave, then you will not be 
      eligible for Lyra once you are on leave. Please connect with ${benefitsTeam} for more information.`,
    ),
  /* eslint-enable react/prop-types */
}

export const COMMON_2021_FAQ_ANSWERS = {
  WHAT_IS_LYRA_HEALTH: ({
    customerName,
    whoIsEligible = 'employees and their dependents',
    worklifeServices = 'legal, identity theft, financial, and dependent care',
  }: $TSFixMe) => (
    <h3>
      {oneLine(
        `Lyra is ${articlize(customerName)} ${customerName}-sponsored benefit that connects ${whoIsEligible} to 
        mental and emotional health care that is effective, convenient, and personalized. Using technology, proven 
        treatments, and a network of top therapists and coaches, Lyra matches you to the right care for your needs, 
        from short-term therapy and coaching to mental wellness tools.`,
      )}
      {worklifeServices && (
        <>
          {' '}
          Lyra also provides {WorklifeLink()} to help you work through personal life obstacles. These services include{' '}
          {worklifeServices} services.
        </>
      )}
    </h3>
  ),
  WHAT_TYPES_OF_ISSUES_CAN_LYRA_HELP_ME_SOLVE: ({ medManagement = false, coaching = true } = {}) => (
    <h3>
      {oneLine(
        `Lyra can help with stress, anxiety, depression, communication issues, sleep disorders, and other common 
        issues that can be addressed through services such as therapy, ${coaching ? 'coaching, ' : ''}${
          medManagement ? 'medication management, ' : ''
        }or mental wellness tools. Maybe you are feeling sad, disinterested in things you 
          used to enjoy, or like anxiety has taken over your life. Perhaps you can’t sleep or eat, or you are sleeping 
          or eating too much.`,
      )}
      <br />
      <br />
      {oneLine(
        `Lyra can also help with family or couple issues. Whether you feel stuck in a relationship and don’t 
        know what to do next, are going through a rough patch with your partner, or have encountered a life change 
        that impacts the entire family, Lyra providers can help.`,
      )}
    </h3>
  ),
  HOW_DOES_LYRA_WORK: ({ medManagement = false, coaching = true } = {}) =>
    oneLine(
      `Use our online platform or contact our care navigator team directly to tell us about what you’re going through. 
      We’ll then share how we can help with different care options, such as ${
        coaching ? 'Guided Self-Care, Mental Health Coaching, ' : ''
      }
      therapy, ${
        medManagement ? 'medication management, ' : ''
      }and mental wellness tools that fit your needs and preferences, and get you started right away. Throughout your 
      experience with Lyra we’ll check in to see how it’s going and be there for you every step of the way.`,
    ),
  HOW_LONG_TO_USE: ({ phone, coaching = true }: $TSFixMe) => (
    <h3>
      {oneLine(`It usually takes just over 10 minutes to complete the `)}
      <a href={`${window.location.protocol}//${window.location.host}`}>online assessment</a>
      {oneLine(` and get connected to a top therapist, ${coaching ? 'coach, ' : ''}or self-guided program for your 
      needs. You can also call the care navigator team at ${phone} to start the conversation immediately.`)}
    </h3>
  ),
  HOW_MUCH_DOES_LYRA_COST: ({ numberOfSessions, customerName, worklifeServices = true }: $TSFixMe) => (
    <h3>
      {oneLine(
        `There’s no cost to you for mental health care as a benefits-eligible employee or dependent! 
              Lyra has partnered with ${customerName} to cover the cost of outpatient therapy and mental health coaching 
              services. We cover up to ${numberOfSessions} sessions per calendar year as long as you are eligible for 
              benefits from ${customerName}.`,
      )}
      {worklifeServices && (
        <>
          <br />
          <br />
          {oneLine(
            `We also cover the cost of your first consultations with work life services, after which 
              you will be able to pay a discounted payment if you choose to continue engagement with the service.`,
          )}
        </>
      )}
    </h3>
  ),
  WHAT_DOES_THERAPY_ENTAIL: () => (
    <h3>
      {oneLine(
        `A Lyra therapist can help you to understand the relationships between thoughts, feelings, and 
          behaviors and how problems in one or more areas can lead to conditions like depression, eating disorders, or 
          post-traumatic stress disorder. A Lyra therapist can also support you in identifying factors that increase 
          emotional distress and suggest new strategies for managing these situations effectively. Lyra only supports 
          evidence-based therapies, which means the methods used by our providers have been proven to work.`,
      )}
      <br />
      <br />
      {oneLine(
        `In therapy, you may learn new skills for managing intense anxiety or deep depression and alternatives 
          to coping strategies that haven’t been working for you. Over time we’ll help you develop tools to manage and 
          relieve your symptoms so you can start to feel better and get back to feeling more like the real you.`,
      )}
      <br />
      <br />
      {oneLine(`Watch `)}
      {/* @ts-expect-error TS(2739): Type '{}' is missing the following properties from... Remove this comment to see the full error message */}
      <WhatIsTherapyVideoLink />
      {oneLine(` to learn more.`)}
    </h3>
  ),
  WHAT_IF_I_REACH_MY_SESSION_LIMIT: ({ numberOfSessions }: $TSFixMe) =>
    oneLine(
      `Our goal is for you to feel better and get back to living your life, not to remain in therapy indefinitely. 
      If you reach your ${numberOfSessions}-session limit for the year, and your therapist determines you would benefit 
      from additional care, you may elect to continue working with your Lyra provider, but any future sessions would 
      require out-of-pocket payment. You may be able to submit claims to your medical plan to cover part of the cost 
      of care if your plan includes partial reimbursement for out-of-network providers. Alternatively, you may opt to 
      find a provider who is in-network with your medical plan.`,
    ),
  FORMATS_FOR_CARE: ({
    phone,
    customerName,
    linkText = `${customerName.toLowerCase()}.lyrahealth.com`,
    liveMessaging = false,
    coaching = true,
  }: $TSFixMe) => (
    <h3>
      {oneLine(
        `For therapy, you can choose whether to meet in a provider’s office or by live video from any place with 
        an internet connection. ${
          coaching
            ? `For Mental Health Coaching, you have the option to meet by live video, ${
                liveMessaging ? 'live messaging, ' : ''
              }or telephone for your sessions. `
            : ''
        }There are also mental wellness tools available. Not sure what is right for 
        you? The Lyra care navigator team can answer your questions 24/7 by phone at ${phone}, by email at `,
      )}
      <a href='mailto:care@lyrahealth.com'>care@lyrahealth.com</a>
      {oneLine(`, or by web chat at `)}
      <a href={`${window.location.protocol}//${window.location.host}`}>{linkText}</a>
      {oneLine(`.`)}
    </h3>
  ),
  WHAT_IF_I_MISS_AN_APPOINTMENT: ({
    customerName,
    cancellationLimit,
    numberOfSessions,
    cancellationsIncludedInSessionLimits,
  }: $TSFixMe) => (
    <h3>
      {oneLine(
        `When you start care, it’s important to talk to your provider about their cancellation policy. Most 
        providers require 24-48 hours notice for cancellations. Please refer to your provider for questions about their 
        cancellation policy and payments. ${
          cancellationLimit === '0'
            ? `If`
            : `${customerName} will cover the cost of ${
                cancellationLimit === '1'
                  ? `${numberToWord(cancellationLimit)} last-minute cancellation or no-show`
                  : `up to ${numberToWord(cancellationLimit)} last-minute cancellations or no-shows`
              }. 
            Beyond this, if`
        } you do not cancel on time or miss an appointment without cancelling, for any reason, you will be required to 
        reimburse your provider for the missed session. `,
      )}
      <br />
      <br />
      {oneLine(
        `Any missed or late sessions ${cancellationsIncludedInSessionLimits === 'true' ? `will` : `will not`} 
        count against the ${numberOfSessions} sessions you get per calendar year.`,
      )}
    </h3>
  ),
  IF_I_LEAVE_OR_LOSE_MY_JOB_WILL_LYRA_COVER_MY_CARE: ({ customerName }: $TSFixMe) =>
    oneLine(
      `Generally, your benefits last until the end of the month that you terminate employment or start a new plan 
    through your new employer, whichever comes first. Lyra is available under COBRA for ${customerName} members. Your 
    benefits team can provide more detailed information about how long your benefits will be available and can provide 
    more information about how to elect COBRA continuation coverage for Lyra.`,
    ),
  CAN_I_USE_LYRA_WHILE_TRAVELING: () =>
    oneLine(
      `Because telehealth laws vary by state, your provider may only be able to deliver care in the state in 
      which they are licensed. It is important to discuss your travel plans with your provider in advance to determine if 
      you’ll need to adjust your session schedule. Lyra does not recommend providers to continue sessions when a client 
      travels outside of the United States unless the provider is licensed to practice in the applicable jurisdiction.`,
    ),
  WHY_DOES_LYRA_COLLECT_FEEDBACK: () =>
    oneLine(
      `Part of Lyra’s mission is to ensure that people are getting high-quality, evidence-based care so they can feel 
      better, faster. A standard part of evidence-based care is to capture ongoing feedback from you so that your 
      provider can better understand your progress and tailor your treatment plan. Typically, you are asked to report 
      on your progress in treatment once a month or weekly depending on the care program you are in and provider 
      preference.`,
    ),
  HOW_DOES_ONSITE_SCHEDULING_WORK: ({ linkText }: $TSFixMe) => (
    <h3>
      {oneLine(`The easiest way to check availability and book an appointment for onsite therapy is online at `)}
      {MicrositeHomeLink({ linkText })}
      {oneLine(`.`)}
      <br />
      <br />
      {oneLine(
        `You will be asked to register, answer a few questions about your needs, and review several care options. 
        If you are specifically looking for care with an onsite provider, please select individual therapy, indicate 
        that you’d like to meet your therapist in person, and select from one of the onsite locations. From there, you 
        can book an appointment online.`,
      )}
    </h3>
  ),
}

export const MicrositeHomeLink = ({ linkText }: $TSFixMe) => (
  <a href={`${window.location.protocol}//${window.location.host}`}>{linkText}</a>
)

export const WorklifeLink = ({ linkText = 'work life services' } = {}) => (
  <a href={`${window.location.protocol}//${window.location.host}/worklife`}>{linkText}</a>
)

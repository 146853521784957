import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { Headline, Subhead } from '../../../atoms'
import { useMediaQuerySize } from '../../../hooks'
import { HeadlineSize, SubheadSize } from '../../../styles'
import { ThemeType } from '../../../utils/themes/ThemeProvider'
import { tID } from '../../../utils/utils'
import { PathwaysTestimonialsCarousel } from '../pathwaysTestimonialsCarousel/PathwaysTestimonialsCarousel'

const SectionContainer = styled.View<{ theme: ThemeType; backgroundColor?: string }>(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor || theme.colors.backgroundSecondary,
  padding: theme.breakpoints.isMobileSized ? '64px 0' : '96px 0',
}))

const TitleContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.breakpoints.isMobileSized ? '53px' : '70px',
  padding: theme.breakpoints.isMobileSized ? '0 16px' : '0px',
}))

export const PathwaysTestimonialsSection: FunctionComponent<{ backgroundColor?: string }> = ({ backgroundColor }) => {
  const { colors } = useTheme()
  const { isMobileSized } = useMediaQuerySize()

  return (
    <SectionContainer testID={tID('PathwaysTestimonialsSection')} backgroundColor={backgroundColor}>
      <TitleContainer>
        {isMobileSized ? (
          <Subhead
            level='1'
            size={SubheadSize.MEDIUM}
            color={colors.textSecondary}
            testID={tID('PathwaysTestimonialsSection-pathwaysTestimonialTitleContainer')}
            textAlign='center'
          >
            <Subhead
              size={SubheadSize.MEDIUM}
              text={
                <FormattedMessage
                  description='Header text to inform user of testimonials title'
                  defaultMessage='Lyra offers life-changing support for {everyone}'
                  values={{
                    everyone: (
                      <Subhead
                        text={
                          <FormattedMessage
                            defaultMessage='everyone'
                            description='Header text to inform user of testimonials title for everyone'
                          />
                        }
                        color={colors.textActive}
                        size={SubheadSize.MEDIUM}
                      />
                    ),
                  }}
                />
              }
            ></Subhead>
          </Subhead>
        ) : (
          <Headline
            level='1'
            size={HeadlineSize.SMALL}
            color={colors.textSecondary}
            testID={tID('PathwaysTestimonialsSection-pathwaysTestimonialTitleContainer')}
            textAlign='center'
          >
            <Headline
              size={HeadlineSize.SMALL}
              text={
                <FormattedMessage
                  description='Header text to inform user of testimonials title'
                  defaultMessage='Lyra offers life-changing support for {everyone}'
                  values={{
                    everyone: (
                      <Headline
                        text={
                          <FormattedMessage
                            defaultMessage='everyone'
                            description='Header text to inform user of testimonials title for everyone'
                          />
                        }
                        color={colors.textActive}
                        size={HeadlineSize.SMALL}
                      />
                    ),
                  }}
                />
              }
            />
          </Headline>
        )}
      </TitleContainer>
      <PathwaysTestimonialsCarousel />
    </SectionContainer>
  )
}

import { ProgramType } from '../../features'

interface UserFavoriteProviderShape {
  createDate: string
  updateDate: string
  id: number
  version: number
  userId: number
  npi?: unknown
  likeType: string
  triageInfoId: number
  triageInfoSavedId: number
  score: number
  lyraId: string
  apptType: string
  locationSearched: string
  displayBucket?: unknown
  requestId: string
  providerBaseRate: number
  clientSessionCost: number
  treatmentPreference: string
  outreachStatus?: unknown
  totalSessionCost?: unknown
  costEstimateStatus?: unknown
  searchId?: string
  clicked: boolean
}

export type UserFavoriteProviders = Array<UserFavoriteProviderShape>

export type GetLatestUserFavoriteProviders = {
  searchId: string
}

export type PostLatestProviderResults = {
  searchId: string
  timeZone: string
  blendedCareProgramId?: ProgramType
}

export enum PROVIDER_AVAILABILITY_STATUS {
  HAS_CAPACITY_AND_HAS_AVAILABILITY = 'has capacity and availability',
  NO_CAPACITY_AND_NO_AVAILABILITY = 'no capacity and no availability',
  HAS_CAPACITY_AND_NO_AVAILABILITY = 'has capacity and no availability',
  NO_CAPACITY_AND_HAS_AVAILABILITY = 'no capacity and has availability',
}

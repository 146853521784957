import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { Map } from 'immutable'

import { pathwaysRedirectToTriage } from '@lyrahealth-inc/shared-app-logic'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import { REGISTRATION_SUCCESS_PATH } from '../../common/constants/registrationFormConstants'
import { REDIRECT_URL_KEY } from '../../common/constants/routingConstants'
import { updateRedirectPathForTeen } from '../../common/utils/pathwaysUtils'
import { hasRole } from '../../common/utils/userUtils'
import { getIsUserTeen } from '../../data/user/userSelectors'
import { clearSelectedPathwayOption } from '../register/data/registerActions'

type IndexRedirectorProps = {
  episodes?: $TSFixMe[]
  user?: $TSFixMe
  isTeenUser?: boolean
  clearSelectedPathwayOption: () => void
}

const IndexRedirector = ({ episodes, user, isTeenUser, clearSelectedPathwayOption }: IndexRedirectorProps) => {
  if (window.sessionStorage.getItem(REDIRECT_URL_KEY)) {
    // If there is a redirect URL in session storage, direct the user there
    let redirectUrl = JSON.parse(window.sessionStorage.getItem(REDIRECT_URL_KEY) || '')
    redirectUrl = updateRedirectPathForTeen(redirectUrl, isTeenUser ?? false, (redirectUrl: string) => {
      window.sessionStorage.setItem(REDIRECT_URL_KEY, JSON.stringify(redirectUrl))
    })
    const redirectToTriage = pathwaysRedirectToTriage(redirectUrl)
    clearSelectedPathwayOption()
    if (redirectToTriage) {
      // we cannot directly navigate to self or child triage from this point,
      // so we navigate to HomeBaseContainer
      // which handles redirecting to those paths
      return <Navigate to={REGISTRATION_SUCCESS_PATH} />
    } else {
      window.sessionStorage.removeItem(REDIRECT_URL_KEY)
      return <Navigate to={redirectUrl} />
    }
  } else if (hasRole(user, 'ROLE_care_manager')) {
    return <Navigate to='/secure/care-manager/provider-matcher' />
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  } else if (episodes.length) {
    // If the client is seeing an LT provider, redirect them to the assignments page
    return <Navigate to='/secure/index/assignments' />
  } else {
    return <Navigate to='/secure/index/search-care' />
  }
}

const mapStateToProps = ($$state: $TSFixMe) => ({
  episodes: $$state.getIn(['lyraTherapy', 'episodes'], Map()),
  user: $$state.get('user'),
  isTeenUser: getIsUserTeen($$state),
})

export default connect(mapStateToProps, { clearSelectedPathwayOption })(toJS(IndexRedirector))

import React from 'react'

type Props = {
  url: string
  description?: string
}

// TODO: Add style CSS module
class VideoModal extends React.Component<Props> {
  render() {
    return (
      <div>
        <div className='video-player' data-test-id='VideoModal'>
          <iframe
            id='modalVideo'
            width='100%'
            src={this.props.url}
            frameBorder='0'
            title='0'
            // @ts-expect-error TS(2322): Type '{ id: string; width: string; src: string; fr... Remove this comment to see the full error message
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
          />
        </div>
        <h4 className='video-description'>{this.props.description}</h4>
      </div>
    )
  }
}

export default VideoModal

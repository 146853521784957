import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import { getMicrositeAndElsewhereTopBanners } from '../../../../data/customer/customerSelectors'

interface MicrositeAndElsewhereTopBannerProps {
  banners: Array<any>
}

const MicrositeAndElsewhereTopBanner = ({ banners }: MicrositeAndElsewhereTopBannerProps) => {
  const { pathname: currentPath } = useLocation()

  return (
    banners?.map(({ component, paths = [] }, index) => {
      // The banner is shown on the Microsite by default, but can be specified
      // to show up elsewhere in the customer configs
      const isValidBannerPath =
        currentPath === '/' || paths.some((bannerPath: string) => currentPath.startsWith(bannerPath))

      if (!component || !isValidBannerPath) {
        return false
      } else {
        const Banner = component
        return <Banner key={index} />
      }
    }) ?? null
  )
}

const mapStateToProps = ($$state: any) => ({
  banners: getMicrositeAndElsewhereTopBanners($$state),
})

// @ts-expect-error TS(2345): Argument of type '({ banners }: MicrositeAndElsewh... Remove this comment to see the full error message
export default connect(mapStateToProps)(toJS(MicrositeAndElsewhereTopBanner))

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { logToSumoLogic } from '../common/utils/userUtils'
import { getHideChildSearchCTA } from '../data/customer/customerSelectors'
import { useAppDispatch } from '../data/storeConfiguration/store'
import { fetchUserChildren } from '../data/user/userActions'
import { getHasFetchedChildUsers, getId } from '../data/user/userSelectors'

/**
 * Makes API call to fetch child users
 * @param disableFetch Stop this hook from fetching child users
 * @param forceFetch Ignore feature flags (This hook isn't used only for showing child users in the profile page)
 */
export const useFetchChildren = (disableFetch?: boolean, forceFetch?: boolean) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const shouldShowChildrenInUserProfile: boolean = !useSelector(getHideChildSearchCTA)
  const lyraId: string = useSelector(getId)
  const hasFetchedChildUsers = useSelector(getHasFetchedChildUsers)

  useEffect(() => {
    const getChildUsersAsync = async () => {
      try {
        if (!hasFetchedChildUsers) {
          setIsLoading(true)
          await dispatch(fetchUserChildren(lyraId))
        }
      } catch (error) {
        logToSumoLogic('lyraWebErrors', lyraId, {
          message: `Failed to get user children data for lyra id - ${lyraId}`,
          type: 'error',
          error,
        })
      } finally {
        setIsLoading(false)
      }
    }
    !disableFetch && (forceFetch || shouldShowChildrenInUserProfile) && getChildUsersAsync()
  }, [disableFetch, dispatch, forceFetch, hasFetchedChildUsers, lyraId, shouldShowChildrenInUserProfile])

  return { isLoading }
}

import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { ContactUsFormValues, Customer, User } from '@lyrahealth-inc/shared-app-logic'
import { ContactUsPage, LiveChatUnavailableHelp, toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import { getCustomer, getCustomerPhone } from '../../data/customer/customerSelectors'
import { getUser } from '../../data/user/userSelectors'
import { CONTACT_US_TYPE, handleContactSubmit } from '../page-components/modals/contact-modal/contact-modal-actions'

export interface ContactUsProps {
  user: User
  customer: Customer
  actions: {
    submitForm: (v: {
      values: ContactUsFormValues
      type: string
      user: User
      customer: Customer
      matcherProvider: any
    }) => void
  }
  showSuccessDismiss?: boolean
  type?: string
  matcherProvider: any
  onExit?: () => void
  phoneNumber: string
  showOfflineExitButton?: boolean
}

/**
 * Display the Contact Us Full Page component for Mobile Web/Native View
 */
const ContactUs: FunctionComponent<ContactUsProps> = ({
  user,
  customer,
  actions: { submitForm },
  showSuccessDismiss,
  matcherProvider,
  onExit,
  type = CONTACT_US_TYPE.CONTACT_US,
  phoneNumber,
  showOfflineExitButton = true,
}) => {
  const navigate = useNavigate()
  onExit = onExit ?? (() => navigate(-1))

  const isChildConcern = type === CONTACT_US_TYPE.CHILD_CONCERN

  const onHandleSubmit = (v: { values: ContactUsFormValues }) => {
    return submitForm({ values: v.values, type, user, customer, matcherProvider })
  }

  return isChildConcern ? (
    <ContactUsPage
      submitForm={onHandleSubmit}
      onExit={onExit}
      user={user}
      showSuccessDismiss={showSuccessDismiss}
      onSuccessDismiss={onExit}
      isChildConcern={isChildConcern}
    />
  ) : (
    <LiveChatUnavailableHelp onExit={onExit} phoneNumber={phoneNumber} showExitButton={showOfflineExitButton} />
  )
}

const mapStateToProps = ($$state: any) => {
  return {
    user: getUser($$state),
    customer: getCustomer($$state),
    matcherProvider: $$state.getIn(['modal', 'contactModal', 'matcherProvider']),
    phoneNumber: getCustomerPhone($$state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      submitForm: handleContactSubmit,
    },
    dispatch,
  ),
})

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: ContactU... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(toJS(ContactUs))

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { ImageBackground, ImageSourcePropType } from 'react-native'
import { connect, ConnectedProps } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import styled, { useTheme } from 'styled-components/native'

import {
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  COVERAGE_MESSAGING_ENTRY_POINT,
  COVERAGE_MESSAGING_EVENTS,
  FORKED_TRIAGE_EVENTS,
  getHelpEmailAddress,
  LYRIANS_SUPPORT_LINK,
  PATHWAYS_ENTRY_POINTS,
  PATHWAYS_EVENTS,
  S4C_EVENTS,
  S4C_PROPERTIES,
  useFlags,
  useInProgressSearch,
  WELLNESS_TOOLKIT_TYPE,
} from '@lyrahealth-inc/shared-app-logic'
import { ForkedTriagePage, mailTo, openUrl, ThemeType, toJS } from '@lyrahealth-inc/ui-core-crossplatform'
import constellationCloudsBackgroundDesktop from '@lyrahealth-inc/ui-core-crossplatform/src/assets/constellationCloudsBackgroundDesktop.png'
import constellationCloudsBackgroundMobile from '@lyrahealth-inc/ui-core-crossplatform/src/assets/constellationCloudsBackgroundMobile.png'

import { forkedTriageNavigationRoutes } from './types'
import ContactCareNavigatorModal from '../../common/components/header/ContactCareNavigatorModal'
import { useCostMessagingCoverageType } from '../../common/hooks/useCostMessagingCoverageType'
import { useInitLatestMatchesAndGetProps } from '../../common/hooks/useInitLatestMatchesAndGetProps'
import { useIsWellnessToolkitAvailable } from '../../common/hooks/useIsWellnessToolkitAvailable'
import { useUpdateLDContext } from '../../common/hooks/useUpdateLDContext'
import { openICASChat } from '../../common/utils/ICASChatUtils'
import { goToCareNavigatorSchedulingPage } from '../../common/utils/onboardUtils'
import { getDetectedCountryISO, getShouldShowLiveChat } from '../../data/appGlobals/appGlobalsSelectors'
import { getCurrentCountryName } from '../../data/currentCountry/currentCountrySelectors'
import {
  getCustomerLaunchDate,
  getCustomerName,
  getCustomerPhone,
  getDefaultDirectCareNavigatorBookingLink,
  getHideDependentField,
  getIsBlendedCareMedsAvailable,
  getIsCustomerLyra,
  getIsDirectAccessMedsSupported,
  getIsDirectAccessTherapySupported,
} from '../../data/customer/customerSelectors'
import { getSessions } from '../../data/lyraTherapy/lyraTherapySelectors'
import { trackEventWithObj } from '../../data/mixpanel'
import { TrackEventWithObjAction } from '../../data/mixpanel/types'
import { getDirectDedicatedCareNavigatorLink, postGuardianIndicated } from '../../data/user/userActions'
import {
  getId,
  getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
  getIsUserInternational,
  getIsUserLoggedIn,
  getIsUserTeen,
  getUserAge,
  getUserDirectDedicatedCareNavigatorLink,
  getUserHasIndicatedIsGuardian,
  getUserLegalFirstName,
  getUsername,
  getUserSalesforceId,
} from '../../data/user/userSelectors'
import { handleZeroViewSelect } from '../appointmentDashboard/data/appointmentDashboardActions'
import { fetchLatestUserFavoriteProviders, setLatestMatchesSearch } from '../onboard/data/onboardDataActions'
import { setSelectedSearchId } from '../triage/data/triageActions'
import { getSearches } from '../triage/data/triageSelectors'

interface ForkedTriageContainerProps extends ConnectedProps<typeof connector> {
  navigationRoutes: forkedTriageNavigationRoutes
  trackEventWithObj: TrackEventWithObjAction
}

const StyledImageBackground = styled(ImageBackground)({
  flexGrow: 1,
  marginTop: '-80px',
  zIndex: '-1',
  paddingTop: '80px',
})

const SolidBackground = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSecondary,
  flexGrow: 1,
  marginTop: '-80px',
  zIndex: '-1',
  paddingTop: '80px',
}))

const ForkedTriageContainer: FunctionComponent<ForkedTriageContainerProps> = ({
  hideDependentField,
  navigationRoutes,
  trackEventWithObj,
  detectedCountryIso,
  setLatestMatchesSearch,
  handleZeroViewSelect,
  setSelectedSearchId,
  fetchLatestUserFavoriteProviders,
  providerSessions,
  searches,
  isLoggedIn,
  customerName,
  isUserAssumedInternational,
  isUserInternational,
  isLyrian,
  showLiveChat,
  customerPhoneNumber,
  countryName,
  userDirectDedicatedCareNavigatorLink,
  defaultDirectCareNavigatorBookingLink,
  name,
  email,
  userAge,
  salesforceId,
  launchDate,
  userId,
  isMedicationConsultationAvailable,
  isDirectAccessMedsSupported,
  isDirectAccessTherapySupported,
  hasIndicatedIsGuardian,
  getDirectDedicatedCareNavigatorLink,
  postGuardianIndicated,
  isUserTeen,
}) => {
  const { forkedTriageV2Enabled: showNewForkedTriage } = useFlags()

  const showDATherapyOptions = showNewForkedTriage && isDirectAccessTherapySupported

  const { breakpoints } = useTheme()
  const navigate = useNavigate()
  const updateLDContext = useUpdateLDContext()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [startChat, setStartChat] = useState(false)
  useEffect(() => {
    if (startChat) {
      setStartChat(false)
    }
  }, [startChat])
  const isInternational = isLoggedIn ? isUserInternational : isUserAssumedInternational
  const inProgressSearch = useInProgressSearch(searches, providerSessions.appointments)
  const shouldShowChildSearchOption = !hideDependentField
  const { coverageModalContents, isCostMessagingEnabled, costMessagingCoverageType } = useCostMessagingCoverageType()
  const isIndividualCoachingToolkitAvailableResult = useIsWellnessToolkitAvailable(
    WELLNESS_TOOLKIT_TYPE.INDIVIDUAL_COACHING,
    COMMON_MIXPANEL_PAGE.FORKED_TRIAGE,
  )
  const isParentToolkitAvailableResult = useIsWellnessToolkitAvailable(
    WELLNESS_TOOLKIT_TYPE.PARENT_COACHING,
    COMMON_MIXPANEL_PAGE.FORKED_TRIAGE,
  )
  const isLoadingToolkits =
    isIndividualCoachingToolkitAvailableResult.isLoading || isParentToolkitAvailableResult.isLoading

  // todo: add back event prop to view event when latest matches entry point is finalized [LATEST_PROVIDER_PROPERTIES.PAST_SEARCH_AVAILABLE]: latestMatchesLinkProps.hasLatestMatches
  const latestMatchesLinkProps = useInitLatestMatchesAndGetProps({
    navigateToLatestProviderMatchesProps: {
      setLatestMatchesSearch,
      setSelectedSearchId,
      inProgressSearch,
      handleZeroViewSelect,
    },
    fetchLatestUserFavoriteProviders,
  })

  const { state } = useLocation()
  const mixpanelEntryPoint = state?.mixpanelEntryPoint

  const careNavModalOpenedFromMedsConsultModal = useRef(false)

  const indicateIsGuardian = () => {
    if (isLoggedIn && userId && !hasIndicatedIsGuardian) {
      postGuardianIndicated(userId)
    }
  }

  const trackPathwayOption = (eventName: FORKED_TRIAGE_EVENTS) => {
    trackEventWithObj({
      event: eventName,
      [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: mixpanelEntryPoint,
      [S4C_PROPERTIES.AGE]: userAge,
    })
  }

  useEffect(() => {
    trackEventWithObj({
      event: PATHWAYS_EVENTS.VIEW_PROVIDER_CARE_PATHWAY,
      ...(mixpanelEntryPoint && { [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: mixpanelEntryPoint }),
    })
  }, [mixpanelEntryPoint, trackEventWithObj])

  useEffect(() => {
    if (!isLoggedIn) {
      updateLDContext({ country: detectedCountryIso })
    }
  }, [detectedCountryIso, isLoggedIn, updateLDContext])

  const handleSelfSearchPress = () => {
    trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_FIND_CARE_FOR_YOURSELF)
    navigationRoutes.onSelfSearchPress()
  }

  const handleChildSearchPress = () => {
    trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_FIND_CARE_FOR_CHILD)
    indicateIsGuardian()
    navigationRoutes.onChildSearchPress()
  }

  const handleCoachingToolkitPress = () => {
    trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_COACHING_FOR_YOURSELF)
    navigationRoutes.onCoachingToolkitPress()
  }

  const handleParentingToolkitPress = () => {
    trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_COACHING_FOR_PARENT)
    indicateIsGuardian()
    navigationRoutes.onParentingToolkitPress()
  }

  const handleMedsConsultPress = () => {
    trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_MEDICATION_CONSULTATION_FOR_YOURSELF)
    navigationRoutes.onMedsConsultPress()
  }

  const handleContactCareNavigatorPress = (fromMedsConsultModal = false) => {
    careNavModalOpenedFromMedsConsultModal.current = fromMedsConsultModal

    trackEventWithObj({
      event: fromMedsConsultModal
        ? FORKED_TRIAGE_EVENTS.CLICK_LEARN_MORE_LINK_IN_MEDS_CONSULT_MODAL
        : S4C_EVENTS.CLICK_TO_CONTACT_CN,
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.FORKED_TRIAGE,
      [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: fromMedsConsultModal
        ? PATHWAYS_ENTRY_POINTS.MEDS_CONSULT_MODAL
        : PATHWAYS_ENTRY_POINTS.FORKED_TRIAGE_FOOTER,
    })
    setIsModalOpen(true)
  }

  const onPressFAQ = () => {
    if (isLoggedIn) {
      openUrl(`${window.location.protocol}//${window.location.host}/faq`)
    } else {
      navigate('/#faq')
    }
  }

  const getExtraMixpanelPropsCareNavModalOnPress = () => {
    return {
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: careNavModalOpenedFromMedsConsultModal.current
        ? COMMON_MIXPANEL_PAGE.MICROSITE
        : COMMON_MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
      [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: careNavModalOpenedFromMedsConsultModal.current
        ? PATHWAYS_ENTRY_POINTS.MEDS_CONSULT_MODAL
        : COMMON_MIXPANEL_PAGE.FORKED_TRIAGE,
    }
  }

  const onPressEmail = () => {
    trackEventWithObj({
      event: COMMON_MIXPANEL_EVENTS.CLICK_EMAIL_CN,
      ...getExtraMixpanelPropsCareNavModalOnPress(),
    })
    if (isLyrian) {
      openUrl(LYRIANS_SUPPORT_LINK)
    } else {
      mailTo(getHelpEmailAddress({ isInternationalUser: isInternational }))
    }
  }

  const onPressLiveChat = () => {
    trackEventWithObj({
      event: S4C_EVENTS.CLICK_CHAT_LIVE,
      ...getExtraMixpanelPropsCareNavModalOnPress(),
    })
    if (isInternational) {
      setIsModalOpen(false)
      openICASChat({ customerName, countryName })
    } else {
      setIsModalOpen(false)
      setStartChat(!startChat)
    }
  }

  const onPressPhoneCall = () => {
    trackEventWithObj({
      event: COMMON_MIXPANEL_EVENTS.CLICK_PHONE_CN,
      ...getExtraMixpanelPropsCareNavModalOnPress(),
    })
    if (isLoggedIn) {
      openUrl(`tel:${customerPhoneNumber}`)
    } else {
      navigate('/care-team-phone-numbers')
    }
  }

  const onPressScheduleCall = async () => {
    trackEventWithObj({
      event: COMMON_MIXPANEL_EVENTS.CLICK_SCHEDULE_A_CN_CALL,
      ...getExtraMixpanelPropsCareNavModalOnPress(),
    })
    let directDedicatedCareNavigatorLink = userDirectDedicatedCareNavigatorLink
    try {
      directDedicatedCareNavigatorLink = await getDirectDedicatedCareNavigatorLink(userId)
    } catch (error) {
      console.error('Failed to get the user`s dedicated care navigator link ', error)
    } finally {
      goToCareNavigatorSchedulingPage({
        defaultDirectCareNavigatorBookingLink,
        directDedicatedCareNavigatorLink,
        name,
        email,
        salesforceId,
        launchDate,
        isLyrian,
      })
    }
  }

  const trackCoverageModalPress = (isOpen: boolean) => {
    trackEventWithObj({
      event: isOpen ? COVERAGE_MESSAGING_EVENTS.OPEN_COVERAGE_MODAL : COVERAGE_MESSAGING_EVENTS.CLOSE_COVERAGE_MODAL,
      page: COVERAGE_MESSAGING_ENTRY_POINT.FORKED_TRIAGE,
    })
  }

  const Background: React.ComponentType<any> = showNewForkedTriage ? SolidBackground : StyledImageBackground

  const imageBackgroundProps = {
    source: breakpoints.isMobileSized
      ? (constellationCloudsBackgroundMobile as ImageSourcePropType)
      : (constellationCloudsBackgroundDesktop as ImageSourcePropType),
    accessibilityIgnoresInvertColors: true,
    resizeMode: 'stretch',
  }

  return (
    <Background {...(showNewForkedTriage ? {} : imageBackgroundProps)}>
      <ForkedTriagePage
        showNewForkedTriage={showNewForkedTriage}
        loading={isLoadingToolkits}
        showChildSearch={shouldShowChildSearchOption}
        showCoachingToolkit={isIndividualCoachingToolkitAvailableResult.isToolkitAvailable}
        showParentingToolkit={isParentToolkitAvailableResult.isToolkitAvailable}
        showMedsConsult={!isInternational && isMedicationConsultationAvailable}
        showCouplesTherapy={showDATherapyOptions}
        showFamilyTherapy={showDATherapyOptions}
        onSelfSearchPress={handleSelfSearchPress}
        onChildSearchPress={handleChildSearchPress}
        onCoachingToolkitPress={handleCoachingToolkitPress}
        onParentingToolkitPress={handleParentingToolkitPress}
        // TODO (CEA-2493): Set these props
        // onCouplesTherapyPress={handleCouplesTherapyPress}
        // onFamilyTherapyPress={handleFamilyTherapyPress}
        onLearnMorePress={() => {
          handleContactCareNavigatorPress(true)
        }}
        isDirectAccessMedsSupported={isDirectAccessMedsSupported}
        onMedConsultPress={handleMedsConsultPress}
        isInternational={isInternational}
        onContactCareNavigatorPress={() => {
          handleContactCareNavigatorPress()
        }}
        careNavigatorFooterEntryPointModal={
          <ContactCareNavigatorModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            customerPhoneNumber={customerPhoneNumber}
            isInternational={isInternational}
            showLiveChat={showLiveChat}
            startLiveChat={startChat}
            isMobile={breakpoints.isMobileSized}
            isLyrian={isLyrian}
            onPressEmail={onPressEmail}
            onPressFAQ={onPressFAQ}
            onPressPhoneCall={onPressPhoneCall}
            onPressScheduleCall={onPressScheduleCall}
            onPressLiveChat={onPressLiveChat}
            onExit={() => setIsModalOpen(false)}
            showScheduleCall={isLoggedIn}
          />
        }
        latestMatchesLinkProps={latestMatchesLinkProps}
        isUserTeen={isUserTeen}
        coverageModalContents={coverageModalContents}
        costMessagingCoverageType={costMessagingCoverageType}
        isCostMessagingEnabled={isCostMessagingEnabled}
        trackCoverageModalPress={trackCoverageModalPress}
      />
    </Background>
  )
}

const mapStateToProps = ($$state: any) => {
  return {
    hideDependentField: getHideDependentField($$state),
    customerName: getCustomerName($$state),
    detectedCountryIso: getDetectedCountryISO($$state),
    providerSessions: getSessions($$state),
    searches: getSearches($$state),
    isLoggedIn: getIsUserLoggedIn($$state),
    isUserAssumedInternational: getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer($$state),
    isUserInternational: getIsUserInternational($$state),
    isLyrian: getIsCustomerLyra($$state),
    showLiveChat: getShouldShowLiveChat($$state),
    customerPhoneNumber: getCustomerPhone($$state),
    countryName: getCurrentCountryName($$state),
    userDirectDedicatedCareNavigatorLink: getUserDirectDedicatedCareNavigatorLink($$state),
    defaultDirectCareNavigatorBookingLink: getDefaultDirectCareNavigatorBookingLink($$state),
    name: getUserLegalFirstName($$state),
    email: getUsername($$state),
    userAge: getUserAge($$state),
    salesforceId: getUserSalesforceId($$state),
    launchDate: getCustomerLaunchDate($$state),
    userId: getId($$state),
    hasIndicatedIsGuardian: getUserHasIndicatedIsGuardian($$state),
    isUserTeen: getIsUserTeen($$state),
    isMedicationConsultationAvailable: getIsBlendedCareMedsAvailable($$state),
    isDirectAccessMedsSupported: getIsDirectAccessMedsSupported($$state),
    isDirectAccessTherapySupported: getIsDirectAccessTherapySupported($$state),
  }
}

const mapDispatchToProps = {
  trackEventWithObj,
  setLatestMatchesSearch,
  handleZeroViewSelect,
  setSelectedSearchId,
  fetchLatestUserFavoriteProviders,
  getDirectDedicatedCareNavigatorLink,
  postGuardianIndicated,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(ForkedTriageContainer))

import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { WellnessCheckInResultsEssentialsArticleContent } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  BulletIcon,
  ChevronIcon,
  ChevronIconDirection,
  Image,
  PressableOpacity,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

export interface WellnessCheckInResultsEssentialsCardProps {
  articleContent: WellnessCheckInResultsEssentialsArticleContent
  onArticlePress: (subPath: string) => void
}

const MainContainer = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme }) => ({
  padding: `${theme.spacing['12px']}`,
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: theme.colors.backgroundElement,
  borderRadius: theme.spacing['12px'],
  width: '100%',
  minWidth: '343px',
}))

const TextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: `0 ${theme.spacing['16px']} 0 ${theme.spacing['12px']}`,
  flex: 1,
}))

const DescriptionContainer = styled(BodyText)({
  paddingBottom: `6px`,
})

const TypeAndDurationContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
})

const TypeContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  paddingRight: theme.spacing['8px'],
}))

const AttributeContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  paddingLeft: theme.spacing['8px'],
}))

const ImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  width: '64px',
  height: '64px',
  borderRadius: theme.spacing['4px'],
}))

export const WellnessCheckInResultsEssentialsCard: FunctionComponent<WellnessCheckInResultsEssentialsCardProps> = ({
  articleContent,
  onArticlePress,
}) => {
  const { colors } = useTheme() as ThemeType
  const { breakpoints } = useTheme()
  const { formatMessage } = useIntl()

  return (
    <MainContainer
      testID={tID(`WellnessCheckInResultsEssentialsCard`)}
      onPress={() => {
        onArticlePress(articleContent.subPath)
      }}
    >
      <ImageContainer source={articleContent.imageUrl} accessibilityIgnoresInvertColors />
      <TextContainer>
        {breakpoints.isMobileSized ? (
          <BodyText text={formatMessage(articleContent.title)} size={BodyTextSize.SMALL} bold />
        ) : (
          <Subhead text={formatMessage(articleContent.title)} size={SubheadSize.XSMALL} />
        )}
        <DescriptionContainer
          text={formatMessage(articleContent.description)}
          numberOfLines={1}
          size={breakpoints.isMobileSized ? BodyTextSize.CAPTION : BodyTextSize.SMALL}
          color={colors.textSecondary}
        />
        <TypeAndDurationContainer>
          <TypeContainer
            text={formatMessage(articleContent.type)}
            size={BodyTextSize.CAPTION}
            color={colors.textSecondary}
          />
          {articleContent.attribute && (
            <>
              <BulletIcon fillColor={colors.textSecondary} size={3} />
              <AttributeContainer
                text={formatMessage(articleContent.attribute, articleContent.attributeDescValues)}
                size={BodyTextSize.CAPTION}
                color={colors.textSecondary}
              />
            </>
          )}
        </TypeAndDurationContainer>
      </TextContainer>
      <ChevronIcon direction={ChevronIconDirection.RIGHT} fillColor={colors.iconDefault} size={24} />
    </MainContainer>
  )
}

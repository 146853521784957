import React from 'react'
import { FormattedMessage } from 'react-intl'

import { fromJS } from 'immutable'

import { DEFAULT_WORKLIFE_SERVICES } from '../../../features/workLife/constants/workLifeConstants'
import { COMMON_2021_FAQ_ANSWERS, DEFAULT_QUESTIONS } from '../faqConstants'

export const onsiteDescription = (
  <FormattedMessage
    defaultMessage='For your convenience, you may have the option to meet with a provider at your office. To schedule an onsite appointment, select a location below. Be sure to select a location where you work or you can easily access.'
    description='Body text that explains that you can select a location below this text to meet with a provider onsite if it is available. The text also emphasizes that the user should choose a location where they work or can easily get to.'
  />
)

export const onsiteAlternativeDescription = (
  <FormattedMessage
    defaultMessage='If you don’t see providers in your area, you may not have an onsite provider at your office or the onsite provider may not have availability over the next two weeks. We suggest trying a different care option above.'
    description="Body text that explains if there is no offsite location below the text that is in the user's area, then they should try using different care option above."
  />
)

/* Default customer copy follows. This copy applies to all customers.
 * To replace any of this copy, create a file in the same directory with the name of the customer,
 * then create an object with the same structure as the defaultCustomer object.
 * A key in the customer-specific object will override the same key in the defaultCustomer object.
 * Otherwise, the defaultCustomer copy for that key will be used.
 */
export const defaultCustomer = ({ customerName, numberOfSessions }: $TSFixMe) => {
  return fromJS({
    /* The "default" list of FAQ a customer will have.
     * Each item in this list should represent an FAQ item and can either be a string or an object.
     * Specifying a string means the question and answer pair corresponds to one that can be found
     * in the QuestionGenerator class from FaqView.js.
     * Specifying an object means that the question differs in some fashion - i.e. question text or answer text.
     * Object attributes:
     ** question: The text that should be displayed for the question in the FAQ. Required.
     ** answer: The text that should be displayed for the answer in the FAQ. Required if `key` is not present.
     ** key: A string representing the question from QuestionGenerator that this item should correspond to. Specifying
     *       this attribute means that the answer should be pulled from QuestionGenerator, but the `answer` attribute,
     *       if given, will override this. The key is useful to identify questions that are contextually the same
     *       across customers even when the question and answers differ. Optional.
     *
     * In reality, every customer has little idiosyncrasies about their FAQ, so the following list will rarely be the
     * displayed FAQ. Please look at other customer copy files for more examples of FAQ lists.
     */
    faqQuestions: [
      DEFAULT_QUESTIONS.WHAT_IS_LYRA_HEALTH,
      DEFAULT_QUESTIONS.TYPES_OF_ISSUES,
      DEFAULT_QUESTIONS.WHAT_DOES_LYRA_NOT_COVER,
      DEFAULT_QUESTIONS.DISABILITY_PAPERWORK,
      DEFAULT_QUESTIONS.HOW_LONG_TO_USE,
      DEFAULT_QUESTIONS.HOW_DOES_LYRA_WORK,
      DEFAULT_QUESTIONS.WHAT_IS_IT_LIKE_GETTING_CARE_WITH_LYRA,
      DEFAULT_QUESTIONS.WHAT_IS_A_MENTAL_HEALTH_COACH,
      DEFAULT_QUESTIONS.WHAT_IS_MENTAL_HEALTH_COACHING,
      DEFAULT_QUESTIONS.DIFFERENCE_BETWEEN_COACHING_AND_THERAPY,
      DEFAULT_QUESTIONS.WHAT_IS_GUIDED_SELF_CARE,
      DEFAULT_QUESTIONS.HOW_DOES_MEETING_VIA_LIVE_MESSAGING_WORK,
      DEFAULT_QUESTIONS.WHAT_IF_I_WANT_TO_FIND_PROVIDER_OF_COLOR,
      {
        question: DEFAULT_QUESTIONS.HOW_MUCH_DOES_LYRA_COST,
        answer: COMMON_2021_FAQ_ANSWERS.HOW_MUCH_DOES_LYRA_COST({ numberOfSessions, customerName }),
      },
      DEFAULT_QUESTIONS.FORMATS_FOR_CARE,
      DEFAULT_QUESTIONS.WHO_CAN_USE_LYRA,
      DEFAULT_QUESTIONS.WILL_MY_EMPLOYER_KNOW,
      DEFAULT_QUESTIONS.WHAT_HAPPENS_IF_I_MISS_AN_APPOINTMENT,
      DEFAULT_QUESTIONS.HOW_TO_RECOMMEND_LYRA_TO_A_COLLEAGUE_OR_DEPENDENT,
      DEFAULT_QUESTIONS.WHAT_DOES_THERAPY_ENTAIL,
      DEFAULT_QUESTIONS.WHY_DOES_LYRA_COLLECT_FEEDBACK,
      DEFAULT_QUESTIONS.WHATS_DIFFERENT_ABOUT_LYRA,
      DEFAULT_QUESTIONS.CONTINUING_TO_SEE_PROVIDER,
      DEFAULT_QUESTIONS.WORK_LIFE_SERVICES,
    ],

    /* Worklife services that a customer can access, assuming full eligibility.
     * Not all customers have access to all services, but this is determined on component render, so we provide all
     * possible service copies to the customer without taking actual offered services into account.
     *
     * Customers can customize their service copy by passing in an array of different services (which will be displayed
     * in the order of items in the array). They can also generate entirely new copy for a given service so long as the
     * ID of the service object maps to a valid worklife service identifier used in the worklife customer property.
     *
     * e.g. This would constitute a valid custom service:
     * { identifiers: WORKLIFE_SERVICE_IDS.IDENTITY_THEFT, title: 'Legal Services (for U.S. employees only)', ... }
     * This would not constitute a valid custom service because 'legalServices' is not a valid worklife service ID:
     * { identifiers: { id: 'legalServices' }, title: 'Legal Services (for U.S. employees only)', ... }
     */
    workLifeServices: [
      DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.FINANCIAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT,
      DEFAULT_WORKLIFE_SERVICES.DEPENDENT_CARE,
    ],
  })
}

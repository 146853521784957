import React, { useEffect } from 'react'
import CSSModules from 'react-css-modules'

import { BootstrapContainer, ContentLayout, RootLayout } from '@lyrahealth-inc/ui-core'

import styles from './unsupportedBrowser.module.scss'
import Footer from '../common/components/footer/Footer'
import ErrorPageHeader from '../common/components/header/ErrorPageHeader'
import { logToSumoLogic } from '../common/utils/userUtils'

const UnsupportedBrowser = () => {
  useEffect(() => {
    logToSumoLogic('lyraWebErrors', undefined, {
      message: `User tried to access LyraWeb with a legacy browser. User-agent: ${window.navigator.userAgent}`,
      type: 'warning',
    })
  }, [])
  return (
    <RootLayout>
      <ErrorPageHeader />
      <ContentLayout styleName='unsupported-browser-container'>
        <BootstrapContainer>
          <h2>
            We are excited to support you in finding great mental health care, but it looks like you’re using an
            unsupported browser. Please use the latest version of{' '}
            <a href='https://www.google.com/chrome/' id='unsupported-browser-google-chrome-link'>
              Google Chrome
            </a>{' '}
            or{' '}
            <a href='https://support.apple.com/en-us/HT204416' id='unsupported-browser-apple-safari-link'>
              Apple Safari
            </a>{' '}
            (macOS) for the best experience with Lyra.
          </h2>
        </BootstrapContainer>
      </ContentLayout>
      <Footer />
    </RootLayout>
  )
}

export default CSSModules(UnsupportedBrowser, styles, { allowMultiple: true })

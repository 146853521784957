import { defineMessage } from 'react-intl'

import { OPTIONS_TYPE, PreferenceMetadataConfig } from './types'
import { MEMBER_PREFERENCE_IDS, S4C_PROGRAM_OPTION_FLOW } from '../../memberPreferences/constants'
import { MEMBER_PREFERENCE_LABELS } from '../../searchForCare/messages'

const meetingStylesPreferenceCoaching: PreferenceMetadataConfig = {
  name: 'meetingSetting',
  title: MEMBER_PREFERENCE_LABELS.meetingSetting,
  icon: 'MemberPreferencesMeetingSettingIllustrationIcon',
  options: [
    {
      text: MEMBER_PREFERENCE_LABELS.Video,
      id: MEMBER_PREFERENCE_IDS.VIDEO,
      description: [
        {
          value: defineMessage({
            defaultMessage: 'Option to switch to messaging after 1st session, when available',
            description: 'Bullet point describing video modality',
          }),
        },
      ],
    },
    {
      text: MEMBER_PREFERENCE_LABELS.live_messaging,
      id: MEMBER_PREFERENCE_IDS.LIVE_MESSAGING,
      description: [
        {
          value: defineMessage({
            defaultMessage:
              'Offers flexibility of messaging on-the-go, and can be a good alternative to video if finding a private space is challenging',
            description: 'First bullet point for live messaging modality description',
          }),
        },
        {
          value: defineMessage({
            defaultMessage: 'Option to switch to video after first session',
            description: 'Second bullet point for live messaging modality description',
          }),
        },
      ],
    },
  ],
  optionType: OPTIONS_TYPE.CHECKBOX,
  promptText: defineMessage({
    defaultMessage: 'I prefer to meet with a provider by:',
    description: 'Text prompting the user to select options they associate with',
  }),
  visibleFor: [S4C_PROGRAM_OPTION_FLOW.COACHING_WITH_LMS],
}

export default meetingStylesPreferenceCoaching

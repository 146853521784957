/*
    list of redux actions for Lyra Therapy (Actions files that used to be in the action file.)
*/

export const GET_VIDEO_CALL_TOKEN = 'GET_VIDEO_CALL_TOKEN'
export const GET_LT_APPOINTMENTS = 'GET_LT_APPOINTMENTS'
export const GET_LT_APPOINTMENTS_FOR_CLIENT = 'GET_LT_APPOINTMENTS_FOR_CLIENT'
export const GET_LT_APPOINTMENTS_BY_PROVIDER = 'GET_LT_APPOINTMENTS_BY_PROVIDER'
export const TOGGLE_VIDEO_SESSION_MODAL = 'TOGGLE_VIDEO_SESSION_MODAL'
export const VIDEO_SESSION_STATUS_UPDATE = 'VIDEO_SESSION_STATUS_UPDATE'
export const SUBMIT_VIDEO_QUALITY_FEEDBACK = 'SUBMIT_VIDEO_QUALITY_FEEDBACK'
export const VIDEO_SESSION_SETTINGS_UPDATE = 'VIDEO_SESSION_SETTINGS_UPDATE'
export const SET_APPOINTMENT = 'SET_APPOINTMENT_FOR_SESSION'
export const SET_APPOINTMENT_RESCHEDULE = 'SET_APPOINTMENT_RESCHEDULE'
export const CLEAR_SESSION_STORE = 'CLEAR_SESSION_STORE'
export const CLEAR_SELECTED_APPOINTMENT = 'CLEAR_SELECTED_APPOINTMENT'
export const SHOW_SESSION_CANCELLATION_MODAL = 'SHOW_SESSION_CANCELLATION_MODAL'
export const HIDE_SESSION_CANCELLATION_MODAL = 'HIDE_SESSION_CANCELLATION_MODAL'
export const GET_NOTIFICATION_PREFERENCES = 'GET_NOTIFICATION_PREFERENCES'
export const SET_NOTIFICATION_PREFERENCES = 'SET_NOTIFICATION_PREFERENCES'
export const GET_CUSTOM_NOTIFICATION_PREFERENCES = 'GET_CUSTOM_NOTIFICATION_PREFERENCES'
export const SET_CUSTOM_NOTIFICATION_PREFERENCES = 'SET_CUSTOM_NOTIFICATION_PREFERENCES'
export const DELETE_CUSTOM_NOTIFICATION_PREFERENCES = 'DELETE_CUSTOM_NOTIFICATION_PREFERENCES'
export const SET_SHOW_SESSION_NOTIFICATION_PROMPT = 'SET_SHOW_SESSION_NOTIFICATION_PROMPT'
export const GET_MESSAGE_CONVERSATION = 'GET_MESSAGE_CONVERSATION'
export const SELECT_MESSENGER_PROVIDER = 'SELECT_MESSENGER_PROVIDER'
export const DESELECT_MESSENGER_PROVIDER = 'DESELECT_MESSENGER_PROVIDER'
export const GET_MESSAGES = 'GET_MESSAGES'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const GET_CURRENT_EPISODES = 'GET_CURRENT_EPISODES'
export const UPDATE_UNREAD_MESSAGE_COUNT = 'UPDATE_UNREAD_MESSAGE_COUNT'
export const SET_CONVERSATIONS_CLIENT = 'SET_CONVERSATIONS_CLIENT'
export const UPDATE_SELECTED_CONVERSATION_ID = 'UPDATE_SELECTED_CONVERSATION_ID'
export const SET_LT_PROVIDER = 'SET_LT_PROVIDER'
export const INCREMENT_UNREAD_MESSAGE_COUNT = 'INCREMENT_UNREAD_MESSAGE_COUNT'
export const SET_OUTCOMES = 'SET_OUTCOMES'
export const GET_PROVIDERS = 'GET_PROVIDERS'
export const GET_CHILD_EPISODES = 'GET_CHILD_EPISODES'
export const SET_EPISODES = 'SET_EPISODES'
export const SET_PROGRAMS = 'SET_PROGRAMS'
export const SET_DASHBOARD_FETCHED = 'SET_DASHBOARD_FETCHED'
export const SAVE_MESSAGE_DRAFT = 'SAVE_MESSAGE_DRAFT'
export const TOGGLE_LIVE_MSG_SESSION = 'TOGGLE_LIVE_MSG_SESSION'
export const TOGGLE_SHOWN_LIVE_MSG_MODAL = 'TOGGLE_SHOWN_LIVE_MSG_MODAL'
export const SET_ACTIVE_LIVE_MSG_SESSION = 'SET_ACTIVE_LIVE_MSG_SESSION'
export const TOGGLE_SHOW_LIVE_MSG_MODAL = 'TOGGLE_SHOW_LIVE_MSG_MODAL'
export const SET_LIVE_MSG_PROVIDER_ID_TO_OPEN = 'SET_LIVE_MSG_PROVIDER_ID_TO_OPEN'
export const SET_INTAKE_FORM_OPEN = 'SET_INTAKE_FORM_OPEN'
export const SET_FIRST_APPOINTMENT_BOOKED = 'SET_FIRST_APPOINTMENT_BOOKED'
export const SET_JUST_SHOWED_PUSHED_NOTIFICATION_PROMPT = 'SET_JUST_SHOWED_PUSHED_NOTIFICATION_PROMPT'
export const SET_VIEWED_NOTIFICATION_MODAL = 'SET_VIEWED_NOTIFICATION_MODAL'

import React, { FunctionComponent, useEffect, useRef } from 'react'

import LottieView, { AnimationObject } from 'lottie-react-native'

import { isChromaticWeb } from '../../utils'

export const MoodEmojiPopInLottie: FunctionComponent<{
  animationSource: Required<Pick<AnimationObject, 'markers'>> & AnimationObject
  handleAnimationFinished: () => void
  size?: number
}> = ({ animationSource, handleAnimationFinished, size = 128 }) => {
  const animationRef = useRef<LottieView>(null)
  const { markers } = animationSource
  const popInMarker = markers[1]
  const start = popInMarker.tm
  const end = popInMarker.tm + popInMarker.dr

  useEffect(() => {
    animationRef.current?.play(start, end)
  }, [start, end])

  return (
    <LottieView
      ref={animationRef}
      source={animationSource}
      style={{ width: size, height: size }}
      loop={false}
      autoPlay={!isChromaticWeb()}
      onAnimationFinish={handleAnimationFinished}
    />
  )
}

import {
  AssignmentResponseReflectionInterface,
  CustomNotificationsPreferences,
  GetOutcomesAction,
} from '@lyrahealth-inc/shared-app-logic'

import { axiosInstance } from '../axiosInstance'

export function getTokenData({ apptID, providerID, patientID, sessionNumber }: $TSFixMe) {
  return axiosInstance.post(
    `/lt/v1/video/session?appointment_id=${apptID}&provider_id=${providerID}&patient_id=${patientID}&session_number=${sessionNumber}`,
  )
}

export const getVideoSessionData = ({ apptId, patientId }: $TSFixMe) => {
  return axiosInstance.get(`/lt/v1/video/session?appointment_id=${apptId}&patientId=${patientId}`)
}

// temp for LTV dev
export function getDummyVideoAppointmentData(id: $TSFixMe, data: $TSFixMe) {
  return axiosInstance.post(`/v1/calendar/${id}/appointments/dummy`, data)
}

export function getAppointmentsByProviderData({ patientId, providerId, ...params }: $TSFixMe) {
  return axiosInstance.get(`/v2/calendar/${patientId}/${providerId}/appointments`, {
    params,
  })
}

export function getAppointmentsForClientData({ patientId, ...params }: $TSFixMe) {
  return axiosInstance.get(`/v2/calendar/${patientId}/appointments`, {
    params,
  })
}

export function getAssignmentsData({ patientId, providerId = '' }: $TSFixMe) {
  return axiosInstance.get('/lt/v1/assignments', {
    params: {
      patient_id: patientId,
      provider_id: providerId,
    },
  })
}

export function getAssignmentData(assignmentId: $TSFixMe) {
  return axiosInstance.get(`/lt/v1/assignments/${assignmentId}`)
}

export function submitAssignmentData(assignmentId: $TSFixMe, data: $TSFixMe) {
  return axiosInstance.post(`/lt/v1/assignmentResponses/${assignmentId}`, data)
}

export function updateAssignmentData(assignmentId: $TSFixMe, data: $TSFixMe) {
  return axiosInstance.put(`/lt/v1/assignments/${assignmentId}`, data)
}

export function updateAssignmentStatusData(assignmentId: $TSFixMe, data: $TSFixMe) {
  return axiosInstance.patch(`/lt/v1/assignments/${assignmentId}`, data)
}

export function updateAssignmentResponseData(data: $TSFixMe) {
  return axiosInstance.put(`/lt/v1/assignmentResponse/${data.responseId}`, {
    provider_started: data.provider_started || false,
    response: data.response,
    status: data.status,
  })
}

export function patchAssignmentResponseData({ responseId, response }: AssignmentResponseReflectionInterface) {
  return axiosInstance.patch(`/lt/v1/assignmentResponse/${responseId}`, {
    response,
  })
}

export function deleteAssignmentResponseData(data: $TSFixMe) {
  return axiosInstance.delete(`/lt/v1/assignmentResponse/${data.responseId}`)
}

export const getNotificationPreferencesData = ({ lyraId }: $TSFixMe) => {
  return axiosInstance.get(`/lt/v1/preferences/notifications/${lyraId}`)
}

export const submitNotificationPreferencesData = ({ lyraId, data }: $TSFixMe) => {
  return axiosInstance.put(`/lt/v1/preferences/notifications/${lyraId}`, data)
}

export const getCustomNotificationsData = ({ lyraId }: { lyraId: string }) => {
  return axiosInstance.get(`/v1/user/${lyraId}/customNotificationSetting`)
}

export const submitCustomNotificationsData = ({
  lyraId,
  data,
}: {
  lyraId: string
  data: CustomNotificationsPreferences
}) => {
  return axiosInstance.put(`/v1/user/${lyraId}/customNotificationSetting`, data)
}

export const deleteCustomNotificationsData = ({ lyraId }: { lyraId: string }) => {
  return axiosInstance.delete(`/v1/user/${lyraId}/customNotificationSetting`)
}

export function getMessageConversationsData(providerId: $TSFixMe, clientId: $TSFixMe) {
  return axiosInstance.get(`/lt/v1/messaging/conversations`, {
    params: {
      provider_lyra_id: providerId,
      patient_lyra_id: clientId,
    },
  })
}

export function getAccessTokenData() {
  return axiosInstance.get(`/lt/v1/messaging/accessToken`)
}

export function getMessagesData(conversationId: $TSFixMe) {
  return axiosInstance.get(`/lt/v1/messaging/conversations/${conversationId}/messages`)
}

export function getMessageData(conversationId: $TSFixMe, messageId: $TSFixMe) {
  return axiosInstance.get(`/lt/v1/messaging/conversations/${conversationId}/messages/${messageId}`)
}

export function submitMessageData(conversationId: $TSFixMe, data: $TSFixMe) {
  return axiosInstance.post(`/lt/v1/messaging/conversations/${conversationId}/messages`, {
    message_body: data.body,
    message_type: data.type,
    message_author_id: data.client_id,
    message_author_type: 'patient',
    metadata: data.metadata,
  })
}

export function updateUnreadMessageCountData(conversationId: $TSFixMe, data: $TSFixMe) {
  return axiosInstance.patch(`/lt/v1/messaging/conversations/${conversationId}/attributes`, data)
}

export const getEpisodesData = ({ lyraId }: $TSFixMe) => {
  return axiosInstance.get(`/lt/v1/patient/${lyraId}/episodes`)
}

export function getChildEpisodesData(userLyraId: string, childLyraId: string) {
  return axiosInstance.get(`/v1/patient/${userLyraId}/child/${childLyraId}/episodes`)
}

export function getOutcomesData({ clientId, fields }: GetOutcomesAction) {
  return axiosInstance.get(`/v1/user/${clientId}/outcomes`, {
    params: {
      fields,
    },
  })
}

/**
 * get providers for user
 * @param clientId lyraId of the current user
 * @param includeChild set to true to get providers for current user and LCTT providers for current user's teens who they have booked for
 */
export function getProvidersData(clientId: string, includeChild?: boolean) {
  // This might be more appropriate for the appointmentDashboard file
  return axiosInstance.get(`/v1/patient/${clientId}/providers${includeChild ? '?includeChild=true' : ''}`)
}

export function ltNoop() {
  return axiosInstance.get('lt/v1/ping')
}

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Pencil Icon
 */
export const EditIcon: FunctionComponent<EditIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.4925 7.905L20.7025 6.695C21.7615 5.615 21.7615 3.875 20.7025 2.795C19.6225 1.735 17.8825 1.735 16.8025 2.795L15.5925 4.005L19.4925 7.905ZM3.01152 16.595L14.5325 5.065L18.4315 8.965L6.90252 20.485C6.86252 20.515 6.82252 20.535 6.77152 20.545L2.30252 21.485C2.28252 21.495 2.27152 21.495 2.25252 21.495C2.18152 21.495 2.12252 21.465 2.07252 21.425C2.01152 21.365 1.99252 21.275 2.00252 21.195L2.95252 16.725C2.96252 16.675 2.98252 16.635 3.01152 16.595Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

type EditIconProps = {
  size?: string | number
  fillColor?: string
}

import { fromJS } from 'immutable'

import * as ZendeskTicketsActions from './zendesk-tickets-actions'

const defaultState = {
  tickets: [],
  editableZendeskTicketId: '',
  lyraId: '',
}

export const zendeskTickets = (state = fromJS(defaultState), action = null) => {
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  switch (action.type) {
    case ZendeskTicketsActions.UPDATE_EDITABLE_ZENDESK_TICKET_ID: {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.set('editableZendeskTicketId', action.val)
    }
    case ZendeskTicketsActions.SET_ZENDESK_TICKETS: {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.set('tickets', fromJS(action.zendeskTickets)).set('lyraId', action.lyraId)
    }
    case ZendeskTicketsActions.CLEAR_ZENDESK_TICKET: {
      return state.set('editableZendeskTicketId', '')
    }
    default:
      return state
  }
}

export default zendeskTickets

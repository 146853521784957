import { defineMessage } from 'react-intl'

import get from 'lodash/get'
import { Dispatch } from 'redux'

import * as BannerActions from '../../../../common/components/banner/banner-actions'
import { setBannerAndErrorMessage } from '../../../../common/components/banner/banner-actions'
import { MEETING_FORMATS } from '../../../../common/constants/appConstants'
import { axiosInstance } from '../../../../common/http/axiosInstance'
import {
  getModalityQuestionnaireSurveyData,
  getModalityQuestionnaireSurveyStatusData,
  submitModalityPreferenceData,
  submitModalityQuestionnaireSurveyData,
} from '../../../../common/http/data/onboard'
import { changeRoute, PAGE_ROUTES } from '../../data/page-navigation/location-actions'

export const SAVE_MODALITY_QUESTIONNAIRE_ANSWER_GROUP = 'SAVE_MODALITY_QUESTIONNAIRE_ANSWER_GROUP'
export const GET_MODALITY_QUESTIONNAIRE_SURVEY = 'GET_MODALITY_QUESTIONNAIRE_SURVEY'
export const SET_MODALITY_QUESTIONNAIRE_RECOMMENDATIONS = 'SET_MODALITY_QUESTIONNAIRE_RECOMMENDATIONS'
export const SAVE_MODALITY_PREFERENCE = 'SAVE_MODALITY_PREFERENCE'
export const CLEAR_MODALITY_QUESTIONNAIRE_ANSWERS = 'CLEAR_MODALITY_QUESTIONNAIRE_ANSWERS'
export const SHOW_MODALITY_SURVEY_RECOMMENDATIONS = 'SHOW_MODALITY_SURVEY_RECOMMENDATIONS'
const DEFAULT_PREFERENCES = {
  modalityPreferences: [MEETING_FORMATS.VIDEO, MEETING_FORMATS.IN_PERSON],
}

export const getModalityQuestionnaireSurvey = (userId: $TSFixMe) => {
  const request = getModalityQuestionnaireSurveyData(userId)

  return (dispatch: $TSFixMe) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (requestReturn) => {
          dispatch({
            type: GET_MODALITY_QUESTIONNAIRE_SURVEY,
            payload: requestReturn.data,
          })
          // since questions may have changed, the answers could be stale, so reset
          dispatch({
            type: CLEAR_MODALITY_QUESTIONNAIRE_ANSWERS,
          })
          resolve(requestReturn)
        },
        (error) => {
          dispatch(
            BannerActions.setBannerPropsAndStatus(
              BannerActions.BANNER_TYPE.DANGER,
              defineMessage({
                defaultMessage: 'An error occurred in getting the modality survey. Please try again.',
                description: 'Error message when getting the modality survey fails',
              }),
              true,
            ),
          )
          reject(error)
        },
      )
    })
  }
}

export const submitModalityQuestionnaireAnswerGroup = (userId: $TSFixMe, groupIndex: $TSFixMe, data: $TSFixMe) => {
  const request = submitModalityQuestionnaireSurveyData(userId, data)

  return (dispatch: $TSFixMe) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (submissionReturn) => {
          dispatch({
            type: SAVE_MODALITY_QUESTIONNAIRE_ANSWER_GROUP,
            payload: data,
            groupIndex,
          })
          if (submissionReturn.data.recommendations) {
            dispatch({
              type: SET_MODALITY_QUESTIONNAIRE_RECOMMENDATIONS,
              payload: submissionReturn.data.recommendations,
            })
            dispatch({
              type: SHOW_MODALITY_SURVEY_RECOMMENDATIONS,
              payload: true,
            })
          }

          resolve(data)
        },
        (error) => {
          dispatch(
            BannerActions.setBannerPropsAndStatus(
              BannerActions.BANNER_TYPE.DANGER,
              defineMessage({
                defaultMessage: 'An error occurred in saving your answers. Please try again.',
                description: 'Error message when saving answers user selected in the modality survey fails',
              }),
              true,
            ),
          )
          reject(error)
        },
      )
    })
  }
}

export const submitModalityPreference = ({
  lyraId,
  searchId,
  data = DEFAULT_PREFERENCES,
}: {
  lyraId: string
  searchId: string
  data?: { modalityPreferences: string[] }
}) => {
  const isDefaultModalityPreferences = data === DEFAULT_PREFERENCES
  const request = submitModalityPreferenceData(lyraId, searchId, data)

  return (dispatch: Dispatch<any>) => {
    return new Promise(function (resolve, reject) {
      return request.then(
        (submissionReturn) => {
          dispatch({
            type: SAVE_MODALITY_PREFERENCE,
            modalityPreferences: get(data, ['modalityPreferences'], []),
          })
          return resolve(submissionReturn)
        },
        (error) => {
          dispatch(
            BannerActions.setBannerPropsAndStatus(
              BannerActions.BANNER_TYPE.DANGER,
              defineMessage({
                defaultMessage:
                  'An error occurred in {isDefaultModalityPreferences, select, yes {your selection} other {saving your preference}}. Please try again.',
                description: 'Error message when saving modality preferences fails',
                values: {
                  isDefaultModalityPreferences: isDefaultModalityPreferences ? 'yes' : 'other',
                },
              }),
              true,
            ),
          )
          return reject(error)
        },
      )
    })
  }
}

export const initModalityQuestionnaire = (userId: string) => {
  const request = getModalityQuestionnaireSurveyStatusData(userId)

  return (dispatch: $TSFixMe) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (requestReturn) => {
          if (requestReturn.data.previousRecommendations) {
            dispatch({
              type: SET_MODALITY_QUESTIONNAIRE_RECOMMENDATIONS,
              payload: requestReturn.data.previousRecommendations,
            })
          }

          // redirect here, since this behavior is used in multiple places
          if (requestReturn.data.surveyNeeded) {
            dispatch(changeRoute(`${PAGE_ROUTES.MODALITY_QUESTIONNAIRE.INTRO}`))
          } else {
            dispatch(changeRoute(`${PAGE_ROUTES.MODALITY_QUESTIONNAIRE.RESULTS}`))
          }

          resolve(requestReturn)
        },
        (error) => {
          dispatch(
            BannerActions.setBannerPropsAndStatus(
              BannerActions.BANNER_TYPE.DANGER,
              defineMessage({
                defaultMessage: 'An error occurred in getting your modality preference status. Please try again.',
                description: 'Error message when getting modality preference status fails',
              }),
              true,
            ),
          )
          reject(error)
        },
      )
    })
  }
}

export const setModalityQuestionnaireRecommendations = (recommendations: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_MODALITY_QUESTIONNAIRE_RECOMMENDATIONS,
      payload: recommendations,
    })
  }
}

export const getModalityQuestionnaireSurveyStatus = (lyraId: string) => {
  return async (dispatch: any) => {
    try {
      const response = await axiosInstance.put(`/v1/onboarding/modality/${lyraId}/survey-status`)
      return response.data
    } catch (error: any) {
      dispatch(setBannerAndErrorMessage(error, true))
    }
  }
}

import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Dimensions, FlatList, LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent, View } from 'react-native'

import { cloneDeep } from 'lodash-es'
import styled from 'styled-components/native'

import { useMediaQuerySize } from '../../../hooks'
import { CarouselSlider } from '../../../organisms/carouselSlider/CarouselSlider'
import { ThemeType } from '../../../utils'
import { PathwayTestimonialsCarouselCard } from '../../atoms/pathwayTestimonialsCarouselCard/PathwayTestimonialsCarouselCard'

export interface CarouselItem {
  imageUrl: string
  text: React.ReactNode
  field: React.ReactNode
  isOpaque: boolean
  imageWidth: number
  imageHeight: number
  width: string
}

export interface CarouselItemsContent {
  imageUrl: string
  text: React.ReactNode
  field: React.ReactNode
}
const BodyContainer = styled.View({
  flexDirection: 'column',
})

const FlatListCardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const CardContainer = styled(View)<{
  offsetLeft: number
  offsetRight: number
}>(({ offsetLeft, offsetRight }) => ({
  paddingLeft: `${offsetLeft}px`,
  paddingRight: `${offsetRight}px`,
}))

const TESTIMONIAL_ITEM_CONTENTS: CarouselItemsContent[] = [
  {
    imageUrl: require('./../../../assets/TestimonyMining.png'),
    text: (
      <FormattedMessage
        defaultMessage='I like the combination of personal discussion with my provider, and the videos and worksheets that Lyra provides. As someone who is new to all of this, it’s been helpful to have these guides and examples to understand how to use these techniques in practice.'
        description='Testimonial mining quote'
      />
    ),
    field: <FormattedMessage defaultMessage='— Construction/Mining' description='Testimonial mining field quote' />,
  },
  {
    imageUrl: require('./../../../assets/TestimonyHospitalFemale.png'),
    text: (
      <FormattedMessage
        defaultMessage='I was in a very dark place and spinning my wheels. I logged into the Lyra website, completed the assessments, and was matched with a provider who had availability the next day. I’ve done therapy before, but never seen this much progress in such a short period of time!'
        description='Testimonial hospital first quote'
      />
    ),
    field: (
      <FormattedMessage defaultMessage='— Hospital/Healthcare' description='Testimonial hospital field first quote' />
    ),
  },
  {
    imageUrl: require('./../../../assets/TestimonyProfessionalServiceMale.png'),
    text: (
      <FormattedMessage
        defaultMessage='My wife and I have only had 3 sessions, but I can tell our provider is knowledgeable and is helping us get our marriage back on track. He provides great examples and helps us to see both sides of the fence.'
        description='Testimonial professional service second quote'
      />
    ),
    field: (
      <FormattedMessage
        defaultMessage='— Professional Services'
        description='Testimonial professional service field second quote'
      />
    ),
  },
  {
    imageUrl: require('./../../../assets/TestimonyRetail.png'),
    text: (
      <FormattedMessage
        defaultMessage='I was able to find a provider and also make an appointment within 10 minutes that week. I was matched with the most wonderful therapist who is a perfect fit for me. Lyra is amazing, and I hope everyone who has this service available to them takes advantage of this benefit.'
        description='Testimonial retail quote'
      />
    ),
    field: <FormattedMessage defaultMessage='— Retail/Goods' description='Testimonial retail field quote' />,
  },
  {
    imageUrl: require('./../../../assets/TestimonyHospitalMale.png'),
    text: (
      <FormattedMessage
        defaultMessage='I’ve had a number of traumatic family events in the last few months, and my Lyra coach has helped me tremendously! I’m finally seeing the light at the end of the tunnel and feeling a sense of joy I haven’t felt in a long time.'
        description='Testimonial hospital second quote'
      />
    ),
    field: (
      <FormattedMessage defaultMessage='— Hospital/Healthcare' description='Testimonial hospital field second quote' />
    ),
  },
  {
    imageUrl: require('./../../../assets/TestimonyProfessionalFemale.png'),
    text: (
      <FormattedMessage
        defaultMessage="I love the meditations, videos, and articles. My provider is great. She makes me feel so comfortable that I'm really able to open up and share freely. I'm so glad that I signed up for this and today during a meeting at work, I even recommended my coworkers enroll as well. Thank you Lyra."
        description='Testimonial professional service first quote'
      />
    ),
    field: (
      <FormattedMessage
        defaultMessage='— Professional Services'
        description='Testimonial professional service field first quote'
      />
    ),
  },
]

export interface TESTIMONIALS_CAROUSEL_SUB_PROPERTIES {
  imageWidth: number
  imageHeight: number
  padding: number
  initialLeftOffset: number
  initialRightOffset: number
}

const MOBILE_SLIDER_PROPERTIES: TESTIMONIALS_CAROUSEL_SUB_PROPERTIES = {
  imageWidth: 240,
  imageHeight: 210,
  padding: 24,
  initialLeftOffset: 23,
  initialRightOffset: 23,
}

const DESKTOP_SLIDER_PROPERTIES: TESTIMONIALS_CAROUSEL_SUB_PROPERTIES = {
  imageWidth: 320,
  imageHeight: 280,
  padding: 24,
  initialLeftOffset: 100,
  initialRightOffset: 100,
}

export const PathwaysTestimonialsCarousel: FunctionComponent = () => {
  const viewPortWidth = Dimensions.get('window').width
  const { isMinWidthLaptop, isMinWidthTablet, isMobileSized } = useMediaQuerySize()
  const sliderProperties = isMinWidthTablet ? DESKTOP_SLIDER_PROPERTIES : MOBILE_SLIDER_PROPERTIES
  const spacing = sliderProperties.padding
  const cardSize = sliderProperties.imageWidth + 2 * sliderProperties.padding
  const initialLeftOffset = sliderProperties.initialLeftOffset
  const itemsRef = useRef<number[]>([])
  const lastScrollIndex = useRef<number>(0)
  const maxShowingCards = useMemo(() => {
    return isMobileSized
      ? 1
      : Math.floor((viewPortWidth - initialLeftOffset) / (sliderProperties.imageWidth + 2 * spacing))
  }, [initialLeftOffset, isMobileSized, sliderProperties.imageWidth, spacing, viewPortWidth])

  const getInitialCards = useCallback(() => {
    const contents = cloneDeep(TESTIMONIAL_ITEM_CONTENTS)
    return contents.map((card, index) => {
      let isOpaque = false

      if (isMobileSized) {
        isOpaque = lastScrollIndex && lastScrollIndex.current !== index
      } else {
        isOpaque = index >= maxShowingCards
      }

      return {
        ...card,
        isOpaque: isOpaque,
        imageWidth: sliderProperties.imageWidth,
        imageHeight: sliderProperties.imageHeight,
        width: isMobileSized ? `${viewPortWidth * 0.7}px` : `${cardSize}px`,
      }
    })
  }, [
    cardSize,
    isMobileSized,
    lastScrollIndex,
    maxShowingCards,
    sliderProperties.imageHeight,
    sliderProperties.imageWidth,
    viewPortWidth,
  ])

  const initialCards: CarouselItem[] = getInitialCards()
  const [cards, setCards] = useState<CarouselItem[]>(initialCards)

  useEffect(() => {
    if (isMobileSized) {
      if (lastScrollIndex) {
        lastScrollIndex.current = 0
      }
    }
    setCards(getInitialCards())
  }, [viewPortWidth, isMobileSized, getInitialCards])

  const renderCardItems = useCallback(
    ({ item, index }: { item: CarouselItem; index: number }) => {
      return (
        <FlatListCardContainer>
          <CardContainer
            offsetLeft={index === 0 ? initialLeftOffset : spacing}
            offsetRight={index >= initialCards.length - 1 ? sliderProperties.initialRightOffset : 0}
            key={index}
            onLayout={(e: LayoutChangeEvent) => {
              itemsRef.current[index] = e.nativeEvent.layout.width
            }}
          >
            <PathwayTestimonialsCarouselCard {...item} />
          </CardContainer>
        </FlatListCardContainer>
      )
    },
    [initialCards.length, initialLeftOffset, sliderProperties.initialRightOffset, spacing],
  )

  /**
   * Reset all the cards to be opaque
   */
  const resetCardsOpacity = (carouselItems: CarouselItem[]) => {
    return carouselItems.map((card) => {
      card.isOpaque = true
      return card
    })
  }

  /**
   * Decrement from last index and update opacity up to max card
   */
  const updateReverseCardsOpacity = useCallback(() => {
    const index = cards.length - 1

    const opacityCards = resetCardsOpacity(cards)
    for (let i = 0; i < maxShowingCards; i++) {
      if (index - i < 0) {
        break
      }
      const card = opacityCards[index - i]
      card.isOpaque = false
    }

    setCards(opacityCards)
  }, [cards, maxShowingCards])

  /**
   * Increment forward and update opacity up to max card
   */
  const updateCardsOpacity = useCallback(
    (index: number) => {
      const maxInterval = Math.floor(cards.length / maxShowingCards)
      const currentInterval = index / maxShowingCards
      if (maxInterval === currentInterval) {
        return updateReverseCardsOpacity()
      }

      const opacityCards = resetCardsOpacity(cards)
      for (let i = 0; i < maxShowingCards; i++) {
        if (index + i > opacityCards.length - 1) {
          break
        }
        const card = opacityCards[index + i]
        card.isOpaque = false
      }

      setCards(opacityCards)
    },
    [cards, maxShowingCards, updateReverseCardsOpacity],
  )

  /**
   * Track the scroll and update opacity based on the scroll x position
   */
  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const listScrollXOffset = event.nativeEvent.contentOffset.x
      // Retrieve the 2nd element from the list
      if (itemsRef.current && itemsRef.current.length > 2 && cards.length > 1) {
        const cardWidth = itemsRef.current[1]
        const threshold = 0.5
        const interval = threshold * cardWidth
        const index = Math.floor((listScrollXOffset - interval) / cardWidth)
        if (lastScrollIndex && lastScrollIndex.current != index) {
          if (index < 0) {
            updateCardsOpacity(0)
          } else if (index === cards.length - 1) {
            updateCardsOpacity(cards.length - 1)
          } else {
            updateCardsOpacity(index + 1)
          }
          lastScrollIndex.current = index
        }
      }
    },
    [cards.length, lastScrollIndex, updateCardsOpacity],
  )

  return (
    <BodyContainer>
      {isMinWidthLaptop ? (
        <CarouselSlider
          cards={cards}
          maxCards={maxShowingCards}
          renderCardItems={renderCardItems}
          onIndexChange={updateCardsOpacity}
          showButtons={isMinWidthLaptop}
        />
      ) : (
        <FlatList
          data={cards}
          renderItem={renderCardItems}
          horizontal
          initialScrollIndex={0}
          scrollEnabled
          onScroll={onScroll}
          showsHorizontalScrollIndicator={false}
        />
      )}
    </BodyContainer>
  )
}

// @intl project:microsite

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import classnames from 'classnames'

import { PATHWAYS_PAGE } from '@lyrahealth-inc/shared-app-logic'
import { LYRA_DISPLAY_LANGUAGES, RIGHT_TO_LEFT_LANGUAGES } from '@lyrahealth-inc/shared-app-logic/src/features/intl'
import { CheckIcon, PrimaryButton, QuestionMarkIcon, TextButton } from '@lyrahealth-inc/ui-core'
import { colors } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './languageSelectorModal.module.scss'
import { getBeforeLaunch } from '../../data/customer/customerSelectors'
import { MIXPANEL_EVENTS, trackEventWithObj } from '../../data/mixpanel'

interface LanguageSelectorModalProps {
  activeLanguage: string
  availableLanguages: readonly string[]
  beforeLaunch: boolean
  onClick: (selectedLocale: string) => void
  trackEventWithObj: ({ event }: { event: string }) => void
}

const LanguageSelectorModal = ({
  activeLanguage,
  availableLanguages,
  beforeLaunch,
  onClick,
  trackEventWithObj,
}: LanguageSelectorModalProps) => {
  const navigate = useNavigate()
  const languageButtons = availableLanguages.map((language) => {
    const isActiveLanguage = activeLanguage === language
    const languageName = LYRA_DISPLAY_LANGUAGES[language].name

    const getPage = () => {
      const isMicrosite = location.pathname === '/'
      if (beforeLaunch) return PATHWAYS_PAGE.COMING_SOON
      if (isMicrosite) return PATHWAYS_PAGE.CUSTOMER_MICROSITE
      return ''
    }

    return (
      <li key={languageName.id} lang={language}>
        <TextButton
          customClass={classnames(styles['language-button'], {
            [styles['selected-language']]: isActiveLanguage,
          })}
          onClick={() => {
            const page = getPage()
            trackEventWithObj({ event: `Language Updated to ${languageName}`, ...(page && { page }) })
            onClick(language)
          }}
          data-test-id={`DisplayLanguage-${language}-button`}
          role={RIGHT_TO_LEFT_LANGUAGES.hasOwnProperty(language) ? 'link' : 'button'}
        >
          {languageName}
          {isActiveLanguage && <CheckIcon fillColor={colors.teal5} isFilled width={24} withCircle={false} />}
        </TextButton>
      </li>
    )
  })

  return (
    <div data-test-id='LanguageSelectorModal' className={styles['modal-body']}>
      <ul
        className={styles['language-list']}
        style={{ gridTemplateRows: `repeat(${Math.ceil(availableLanguages.length / 3)}, 1fr)` }}
      >
        {languageButtons}
      </ul>
      {!beforeLaunch && (
        <PrimaryButton
          data-test-id='GetHelpButton'
          customClass={styles['care-team-button']}
          onClick={() => {
            trackEventWithObj({ event: MIXPANEL_EVENTS.CLICK_DONT_SEE_YOUR_LANGUAGE })
            navigate('/care-team-phone-numbers')
          }}
          isOutlined
        >
          <QuestionMarkIcon width={24} isFilled />
          <FormattedMessage
            defaultMessage='Get help'
            description='Button that links to a contact page, which contains a list of international phone numbers to contact the care navigator team in a specific language'
          />
        </PrimaryButton>
      )}
    </div>
  )
}

const mapStateToProps = ($$state: Map<string, any>) => {
  return {
    beforeLaunch: getBeforeLaunch($$state),
  }
}

export default connect(mapStateToProps, { trackEventWithObj })(LanguageSelectorModal)

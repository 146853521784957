import { Map } from 'immutable'

import * as BulkCopyActions from './bulk-copy-actions'
export const bulkCopy = (state = Map(), action = null) => {
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  switch (action.type) {
    case BulkCopyActions.ADD_BULK_KEY_VALUE:
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.set(action.key, action.value)
    case BulkCopyActions.REMOVE_BULK_KEY:
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.remove(action.key)
    case BulkCopyActions.RESET_BULK_COPY:
      return Map()
    default:
      return state
  }
}

export default bulkCopy

import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const ProfileCircleIcon: FunctionComponent<ProfileCircleIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fill={fillColor || colors.iconDefault}
        fillRule='evenodd'
        d='M12 22C6.48 22 2 17.519 2 12 2 6.48 6.48 2 12 2s10 4.48 10 10c0 5.519-4.48 10-10 10Zm0-1.5c-4.69 0-8.5-3.81-8.5-8.5 0-4.691 3.81-8.5 8.5-8.5 4.69 0 8.5 3.809 8.5 8.5 0 4.69-3.81 8.5-8.5 8.5Zm0-15c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3Zm-1.5 3c0 .827.673 1.5 1.5 1.5s1.5-.673 1.5-1.5S12.827 7 12 7s-1.5.673-1.5 1.5ZM17 16c0-1.654-1.346-3-3-3h-4c-1.654 0-3 1.346-3 3v.25a.75.75 0 0 0 1.5 0V16c0-.827.673-1.5 1.5-1.5h4c.827 0 1.5.673 1.5 1.5v.25a.75.75 0 0 0 1.5 0V16Z'
        clipRule='evenodd'
      />
    </Svg>
  )
}

export type ProfileCircleIconProps = {
  size?: string | number
  fillColor?: string
}

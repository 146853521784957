import { fromJS } from 'immutable'

import * as NotesActions from './notes-actions'

const defaultState = {
  notesList: [],
  editableNote: '',
  lyraId: '',
}

export const notes = (state = fromJS(defaultState), action = null) => {
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  switch (action.type) {
    case NotesActions.UPDATE_EDITABLE_NOTE_VALUE: {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.set('editableNote', action.val)
    }
    case NotesActions.SET_NOTES: {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.set('notesList', fromJS(action.notes)).set('lyraId', action.lyraId)
    }
    case NotesActions.CLEAR_EDITABLE_NOTE: {
      return state.set('editableNote', '')
    }
    default:
      return state
  }
}

export default notes

import { RECORDING_STATUS } from '../../features/videoSession/constants'
export interface VideoSession {
  id: string
  s3_object_name: string | null
  max_video_bitrate: number
  episode_id: string
  status: string
  patient_id: string
  recording_status: RECORDING_STATUS
  provider_id: string
  preferred_audio_codecs: string[]
  room_name: string
  expiration_time: string
  access_token: string
  provider_patient_id: string
  provider: string
  preferred_video_codecs: Codec[]
  appointment_id: string
  end_time: string | null
  room_sid: string
  room_key: string | null
  participant_identity: string | null
  start_time: string | null
  max_audio_bitrate: number
  video_provider: VideoProvider
}

export enum VideoProvider {
  TWILIO = 'twilio',
  ZOOM = 'zoom',
}

export interface Codec {
  codec: string
  simulcast: boolean
}

export enum DataTrackMessage {
  H264_NOT_SUPPORTED = 'H264_not_supported',
  RECONNECTED_WITH_VP8 = 'reconnected_with_vp8',
  RECORDING_OFF = 'recording_off',
}

export enum MEETING_FORMATS {
  IN_PERSON = 'inPerson',
  VIDEO = 'video',
  PHONE = 'phone',
  CALL = 'call',
  LIVE_MESSAGING = 'live_messaging',
  VIDEO_AND_LIVE_MESSAGING = 'video_and_live_messaging', // Member Preference valid coaching selection
  NONE_SPECIFIED = 'none_specified', // Member Preference no format specified for coaching
  VIDEO_OR_IN_PERSON = 'video_or_inPerson', // Member Preferences v1 experiment valid therapy selection
}

export enum APPOINTMENT_STATUS {
  NEW_APPT = 'newAppt',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  RESCHEDULED = 'rescheduled',
  MISSED = 'missed',
}

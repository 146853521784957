import { MEETING_FORMATS } from '../../../models'

export const PREFERRED_APPOINTMENT_MODALITIES = {
  IN_PERSON: MEETING_FORMATS.IN_PERSON,
  VIDEO: MEETING_FORMATS.VIDEO,
  PHONE: MEETING_FORMATS.PHONE,
  NO_PREFERENCE: 'noPreference',
}

export const TREATMENT_MODE = {
  ONLY_INPERSON: 'ONLY_INPERSON',
  ONLY_VIDEO: 'ONLY_VIDEO',
  BOTH_VIDEO_INPERSON: 'BOTH_VIDEO_INPERSON',
  BOTH_PHONE_INPERSON: 'BOTH_PHONE_INPERSON',
  BOTH_PHONE_VIDEO: 'BOTH_PHONE_VIDEO',
  INPERSON_PHONE_VIDEO: 'INPERSON_PHONE_VIDEO',
  NONE: 'NONE',
}

export const MODALITY_DISPLAY = {
  [TREATMENT_MODE.ONLY_INPERSON]: 'In person',
  [TREATMENT_MODE.ONLY_VIDEO]: 'Video',
  [TREATMENT_MODE.BOTH_VIDEO_INPERSON]: 'In person or video',
  [TREATMENT_MODE.BOTH_PHONE_INPERSON]: 'In person or phone',
  [TREATMENT_MODE.BOTH_PHONE_VIDEO]: 'Video or phone',
  [TREATMENT_MODE.INPERSON_PHONE_VIDEO]: 'In person, video, or phone',
  [TREATMENT_MODE.NONE]: 'No',
}

export const TREATMENT_MODE_TO_PREFERRED_APPOINTMENT_MODALITY = {
  [TREATMENT_MODE.ONLY_INPERSON]: [PREFERRED_APPOINTMENT_MODALITIES.IN_PERSON],
  [TREATMENT_MODE.ONLY_VIDEO]: [PREFERRED_APPOINTMENT_MODALITIES.VIDEO],
  [TREATMENT_MODE.BOTH_VIDEO_INPERSON]: [
    PREFERRED_APPOINTMENT_MODALITIES.VIDEO,
    PREFERRED_APPOINTMENT_MODALITIES.IN_PERSON,
  ],
  [TREATMENT_MODE.BOTH_PHONE_INPERSON]: [
    PREFERRED_APPOINTMENT_MODALITIES.PHONE,
    PREFERRED_APPOINTMENT_MODALITIES.IN_PERSON,
  ],
  [TREATMENT_MODE.BOTH_PHONE_VIDEO]: [PREFERRED_APPOINTMENT_MODALITIES.VIDEO, PREFERRED_APPOINTMENT_MODALITIES.PHONE],
  [TREATMENT_MODE.INPERSON_PHONE_VIDEO]: [
    PREFERRED_APPOINTMENT_MODALITIES.IN_PERSON,
    PREFERRED_APPOINTMENT_MODALITIES.VIDEO,
    PREFERRED_APPOINTMENT_MODALITIES.PHONE,
  ],
  [TREATMENT_MODE.NONE]: [],
}

export const convertUserPreferredAppointmentModalityToFriendlyString = (preferredAppointmentTypes: string[]) => {
  let friendlyString
  const numberOfPreferences = preferredAppointmentTypes.length
  switch (numberOfPreferences) {
    case 1:
      const preferredModality = preferredAppointmentTypes[0]
      if (preferredModality === PREFERRED_APPOINTMENT_MODALITIES.IN_PERSON) {
        friendlyString = 'In person'
      } else if (preferredModality === PREFERRED_APPOINTMENT_MODALITIES.VIDEO) {
        friendlyString = 'Video'
      } else if (preferredModality === PREFERRED_APPOINTMENT_MODALITIES.PHONE) {
        friendlyString = 'Phone'
      } else {
        friendlyString = 'No preference'
      }
      break
    case 2:
      if (
        preferredAppointmentTypes.includes(PREFERRED_APPOINTMENT_MODALITIES.IN_PERSON) &&
        preferredAppointmentTypes.includes(PREFERRED_APPOINTMENT_MODALITIES.VIDEO)
      ) {
        friendlyString = 'In person or video'
      } else if (
        preferredAppointmentTypes.includes(PREFERRED_APPOINTMENT_MODALITIES.IN_PERSON) &&
        preferredAppointmentTypes.includes(PREFERRED_APPOINTMENT_MODALITIES.PHONE)
      ) {
        friendlyString = 'In person or phone'
      } else if (
        preferredAppointmentTypes.includes(PREFERRED_APPOINTMENT_MODALITIES.VIDEO) &&
        preferredAppointmentTypes.includes(PREFERRED_APPOINTMENT_MODALITIES.PHONE)
      ) {
        friendlyString = 'Video or phone'
      }
      break
    case 3:
      friendlyString = 'In person, video, or phone'
      break
  }

  return friendlyString
}

export const getTherapyMeetingPreferenceForEmail = (meetingPreferencesInfo: {
  preferredAppointmentTypesMemberPreferences?: MEETING_FORMATS[]
  preferredAppointmentTypesUserObject?: MEETING_FORMATS[]
  forChild: boolean
}) => {
  const { preferredAppointmentTypesMemberPreferences, preferredAppointmentTypesUserObject, forChild } =
    meetingPreferencesInfo
  // When preferredAppointmentType is empty for member preferences we should default to video as the preferred option
  const meetingPreferencesFromMemberPreferences =
    preferredAppointmentTypesMemberPreferences?.length === 0
      ? [MEETING_FORMATS.VIDEO]
      : preferredAppointmentTypesMemberPreferences

  // Should try to use the meeting preference from member preferences first since it will be the most recent indicated preference
  // if therapy is for child then meetingPreferences should be undefined(axios serialization will remove undefined from payload)
  // since we don't intake meeting preference for a child yet.
  const meetingPreference = !forChild
    ? convertUserPreferredAppointmentModalityToFriendlyString(
        meetingPreferencesFromMemberPreferences || preferredAppointmentTypesUserObject || [],
      )
    : undefined

  return meetingPreference
}

// Used in Appointment Dashboard, Onboard
// TODO: is treatment mode and appointment options related? Maybe these can be combined.
export const APPOINTMENT_OPTIONS = {
  IN_PERSON: 'googleCalendar',
  LIVE_VIDEO: 'video',
  NAVIGATOR_VIDEO: 'navigator_video',
  NAVIGATOR_INPERSON: 'navigator_inperson',
}

export const DISCLAIMER_TYPES = {
  PARENTAL_CONSENT_WARNING: 'PARENTAL_CONSENT_WARNING',
  COACHING_DISCLAIMER: 'COACHING_DISCLAIMER',
  SHARED_COMPUTER_WARNING: 'SHARED_COMPUTER_WARNING',
}

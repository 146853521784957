export const CLOSE_MODAL = 'CLOSE_MODAL'
export const closeModal = () => ({ type: CLOSE_MODAL })

export const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT'
export const setModalContent = (content: $TSFixMe) => ({
  type: SET_MODAL_CONTENT,
  content,
})

export const SET_MODAL_PROPS = 'SET_MODAL_PROPS'
export const setModalProps = (modalProps: $TSFixMe) => ({
  type: SET_MODAL_PROPS,
  modalProps,
})

import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import ContactUs from '../../../chatLive/ContactUs'
import { closeModal } from '../generic/modal-actions'

type ContactModalViewProps = {
  type: string
  actions: {
    closeModal: () => {}
  }
}

const ContactModalView: FunctionComponent<ContactModalViewProps> = ({ type, actions: { closeModal } }) => {
  return <ContactUs type={type} onExit={closeModal} />
}

const mapStateToProps = ($$state: any) => {
  return {
    type: $$state.getIn(['modal', 'contactModal', 'type']),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      closeModal,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ContactModalView))

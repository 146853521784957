import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { PasswordPolicy, PasswordPolicyMet } from '@lyrahealth-inc/shared-app-logic'

import { BodyTextSize } from '../../../styles'
import { ThemeType, tID } from '../../../utils'
import { BulletedList } from '../../bulletedList/BulletedList'
import { CheckIcon, CircleIcon } from '../../icons'

const ChecklistContainer = styled.View<{ theme: ThemeType; customStyle?: CSSObject }>(({ theme, customStyle }) => ({
  marginBottom: theme.breakpoints.isMinWidthDesktop ? '20px' : theme.spacing['16px'],
  backgroundColor: theme.colors.backgroundPrimary,
  ...customStyle,
}))

export interface PasswordChecklistProps {
  passwordPolicy: PasswordPolicy
  passwordStrength: PasswordPolicyMet
  customStyle?: CSSObject
}

export const PasswordChecklist = ({ passwordPolicy, passwordStrength, customStyle }: PasswordChecklistProps) => {
  const { colors } = useTheme()
  const { checklistBullet } = colors.components.passwordChecklist

  const generateRequirementsList = () => {
    const reqString = {
      minUpperCase: (
        <FormattedMessage
          defaultMessage='Contains at least {count, plural, one {1 upper case letter} other {{count} upper case letters}}'
          description='Description informing the user an upper case letter is needed'
          values={{
            count: passwordPolicy.minUpperCase,
          }}
        />
      ),
      minLowerCase: (
        <FormattedMessage
          defaultMessage='Contains at least {count, plural, one {1 lower case letter} other {{count} lower case letters}}'
          description='Description informing the user a lower case letter is needed'
          values={{
            count: passwordPolicy.minLowerCase,
          }}
        />
      ),
      minNumber: (
        <FormattedMessage
          defaultMessage='Contains at least {count, plural, one {1 number} other {{count} numbers}}'
          description='Description informing the user a number case letter is needed'
          values={{
            count: passwordPolicy.minNumber,
          }}
        />
      ),
      minLength: (
        <FormattedMessage
          defaultMessage='Minimum of {count, plural, one {1 character} other {{count} characters}}'
          description='Description informing the user a character is needed'
          values={{
            count: passwordPolicy.minLength,
          }}
        />
      ),
    }
    const reqList = Object.keys(passwordPolicy).map((requirement) => {
      return {
        value: reqString[requirement],
        bulletIcon: passwordStrength[requirement] ? (
          <CheckIcon size={20} fillColor={colors.iconSuccess} />
        ) : (
          <CircleIcon size={20} />
        ),
        textColor: passwordStrength[requirement] ? colors.textSuccess : colors.textSecondary,
      }
    })
    return reqList
  }

  return (
    <ChecklistContainer testID={tID('PasswordChecklist')} customStyle={customStyle}>
      <BulletedList
        data={generateRequirementsList()}
        bulletColor={checklistBullet.fill}
        size={BodyTextSize.SMALL}
        centerAlignBullet
      />
    </ChecklistContainer>
  )
}

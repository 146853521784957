import React from 'react'
import { Path, Svg, SvgProps } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const SofaIcon = ({ height = 24, width = 24, fillColor, ...rest }: SofaIconProps & SvgProps) => {
  const { colors } = useTheme()

  return (
    <Svg width={width} height={height} viewBox='0 0 24 24' fill='none' {...rest}>
      <Path
        d='M20.7 7.85v-1.9c0-1.2-1-2.2-2.2-2.2h-13c-1.2 0-2.2 1-2.2 2.2v1.9c-1.2.3-2 1.4-2 2.6v6c0 .4.3.8.8.8h1.2v2.2c0 .4.3.8.8.8h3c.4 0 .8-.3.8-.8v-2.2h8.5v2.2c0 .4.3.8.8.8h3c.4 0 .8-.3.8-.8v-2.2h.9c.4 0 .8-.3.8-.8v-6c0-1.3-.9-2.3-2-2.6Zm-16-1.9c0-.4.3-.8.8-.8h13c.4 0 .8.3.8.8v1.9c-1.2.3-2 1.4-2 2.6v1.2H6.7v-1.2c0-1.3-.8-2.3-2-2.6v-1.9Zm16.4 9.7H2.7v-5.2c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2v2c0 .4.3.8.8.8h12c.4 0 .8-.3.8-.8v-2c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2v5.2Z'
        fill={fillColor || colors.iconInactive}
      />
    </Svg>
  )
}

export type SofaIconProps = {
  height?: number
  width?: number
  fillColor?: string
}

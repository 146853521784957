export const SET_CUSTOMER_COPY = 'SET_CUSTOMER_COPY'
export const SET_CUSTOMER_INFO = 'SET_CUSTOMER_INFO'

export const setCustomerCopy = (customerName: $TSFixMe) => {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const eligibilityType = getState().getIn(['healthPlan', 'eligibilityType'])
    dispatch({ type: SET_CUSTOMER_COPY, customerName, eligibilityType })
  }
}

export const setCustomerInfo = (val: $TSFixMe) => ({
  type: SET_CUSTOMER_INFO,
  val,
})

/**
 * react-bootstrap shows warnings as error in development mode.
 * This is a workaround to silence those warnings and display them as warnings instead of errors.
 * More details in: https://github.com/facebook/react/issues/5632
 *
 * Note: this is potentially fixed in higher versions of react-bootstrap. We should check if we need this once we upgrade the package to above v0.31.3.
 */
function silenceReactWarnings() {
  const errorsToWarn: string[] = ['Warning:']
  const oldConsError = console.error
  console.error = function (...args: any) {
    let toWarn = false

    if (typeof args[0] === 'string') {
      errorsToWarn.map(function (_s: string) {
        if (args[0].startsWith(_s)) {
          toWarn = true
        }
      })
    }

    toWarn ? console.warn(...args) : oldConsError(...args)
  }
}

silenceReactWarnings()

export {}

import { IntlShape } from 'react-intl'
import { Dimensions, Platform } from 'react-native'

import isChromatic from 'chromatic/isChromatic'
import * as Application from 'expo-application'
import { brand } from 'expo-device'
import { AuthenticationType } from 'expo-local-authentication'
import * as MailComposer from 'expo-mail-composer'
import { isNil } from 'lodash-es'
import moment from 'moment-timezone'

import {
  CalendarCardAppointment,
  CustomNotificationsDateSettings,
  CustomNotificationsPreferences,
  Episode,
  isWithin24Hours,
  NotificationMethod,
  NotificationMethods,
  PROGRAM_SESSION_RESCHEDULE_MAP,
  ProgramNames,
} from '@lyrahealth-inc/shared-app-logic'

import { Linking } from './expo-linking'
import { BiometricsTypes, IS_WEB } from '../constants'

/**
 * Avoid adding @data types here.
 * Types that are data specific should not live in ui-core-crossplatform
 */

// determine which biometrics string we should display for the user
export const getBiometricType = (authType?: AuthenticationType | null): BiometricsTypes | null => {
  switch (authType) {
    case AuthenticationType.FACIAL_RECOGNITION:
      return Platform.OS === 'ios' ? BiometricsTypes.FaceID : BiometricsTypes.FaceUnlock
    case AuthenticationType.FINGERPRINT:
      return Platform.OS === 'ios' ? BiometricsTypes.TouchID : BiometricsTypes.Fingerprint
    default:
      return null
  }
}

// friendly format the time stamp
// make sure to always keep timestamp even though we don't display it
export const getFriendlyFormattedTimeStamp = (intl: IntlShape, rawTimeStamp: string): string => {
  if (rawTimeStamp) {
    // Since we are using moment to help calculate time elapsed from passsed timestamp, timezone can not be undefined here.
    // 'moment.tz.guess' is added here to satisfy the linter because intl.timeZone will not be undefined unless user has not logged in
    // or has not done search for care at least once. In both those cases we do not show any dates/times and hence this function is not called.
    const displayedTimezone = intl.timeZone ?? moment.tz.guess(true)
    const ref = moment.tz(displayedTimezone)

    const today = ref.clone().startOf('day')
    const yesterday = ref.clone().subtract(1, 'days').startOf('day')
    const tomorrow = ref.clone().add(1, 'days').startOf('day')

    const timeStamp = moment(rawTimeStamp).tz(displayedTimezone)

    const isSameDay = timeStamp.isSame(today, 'day')
    const isYesterday = timeStamp.isSame(yesterday, 'day')
    const isTomorrow = timeStamp.isSame(tomorrow, 'day')

    const isWithinAWeek = moment().diff(timeStamp, 'days') >= 1 && moment().diff(timeStamp, 'days') <= 7

    // if the last message was sent today show as 1:00 pm format
    if (isSameDay) {
      return intl.formatTime(rawTimeStamp)
      // if last message was sent with 7 days
    } else if (isYesterday) {
      return intl.formatMessage({
        defaultMessage: 'Yesterday',
        description: 'The day before today.',
      })
    } else if (isTomorrow) {
      return intl.formatMessage({
        defaultMessage: 'Tommorow',
        description: 'The day after today.',
      })
    } else if (isWithinAWeek) {
      return intl.formatDate(rawTimeStamp, { weekday: 'long' })
    } else {
      return intl.formatDate(rawTimeStamp, { day: '2-digit', year: 'numeric', month: '2-digit' })
    }
  }
  return ''
}

export const getFriendlyFormattedTimeStampComment = (intl: IntlShape, rawTimeStamp: string): string => {
  return `${intl.formatDate(rawTimeStamp, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })}`
}

/**
 * @param isoDateTime ISO 8601 date time string i.e. 2023-03-19
 * Output Example: Tuesday · June 27, 2022
 */
export const getFriendlyFormattedDate = (intl: IntlShape, isoDateTime: string, timeZone?: string) => {
  const weekday = intl.formatDate(isoDateTime, {
    weekday: 'long',
    timeZone,
  })
  const date = intl.formatDate(isoDateTime, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone,
  })
  return `${weekday} · ${date}`
}

export const getEmptyMessageContent = (displayName: string): string =>
  `👋 Your messages with ${displayName} will appear here.`

//  formats css size value correctly
export const formatCssSizeValue = (size: string): string => {
  return size.includes('px') || size.includes('%') ? size : `${size}px`
}

/**
 * Get an array of active notification methods for a given channel name
 * If all channels are inactive allOff will be true
 * @param channelName name of notification channel
 * @param notificationMethods notification method object
 */
export const getActiveNotificationChannelMethods = ({
  channelName,
  notificationMethods,
}: {
  channelName: string
  notificationMethods?: NotificationMethods
}): [string[], boolean] => {
  const activeMethods = Object.keys(NotificationMethod).filter(
    (methodKey) => notificationMethods?.[channelName][NotificationMethod[methodKey]],
  )
  const allOff = activeMethods?.length === 0
  return [activeMethods, allOff]
}

export const getDayOfWeekFromNumber = (dayNumber?: number) => {
  switch (dayNumber) {
    case 0:
      return 'Monday'
    case 1:
      return 'Tuesday'
    case 2:
      return 'Wednesday'
    case 3:
      return 'Thursday'
    case 4:
      return 'Friday'
    case 5:
      return 'Saturday'
    case 6:
      return 'Sunday'
    default:
      return ''
  }
}

export const getCustomDayAsNumber = (day?: string) => {
  switch (day) {
    case 'Monday':
      return 0
    case 'Tuesday':
      return 1
    case 'Wednesday':
      return 2
    case 'Thursday':
      return 3
    case 'Friday':
      return 4
    case 'Saturday':
      return 5
    case 'Sunday':
      return 6
    default:
      return ''
  }
}

/**
 * Get the type of Custom Reminder that the user saved, if any
 * @param customNotificationPreferences notification preferences object
 */
export const getCustomNotificationType = ({
  customNotificationPreferences,
}: {
  customNotificationPreferences?: CustomNotificationsPreferences
}) => {
  if (!customNotificationPreferences) {
    return ''
  }
  const dateSetting = customNotificationPreferences?.date_setting
  if (dateSetting === CustomNotificationsDateSettings.DAY_BEFORE) {
    return 'Day before'
  } else if (dateSetting === CustomNotificationsDateSettings.DAY_OF) {
    return 'Morning of'
  } else if (dateSetting === CustomNotificationsDateSettings.DAY_OF_THE_WEEK) {
    return `${
      !isNil(customNotificationPreferences.day_of_the_week) &&
      Number.isInteger(customNotificationPreferences?.day_of_the_week)
        ? getDayOfWeekFromNumber(customNotificationPreferences.day_of_the_week as number)
        : customNotificationPreferences.day_of_the_week
    }`
  }
  return ''
}

/**
 * Helper function to prefix the package name to the testID
 * Appium requires the package name to be part of the resource-id(value is mapped from testID for android)
 * itself otherwise it will fail to find it
 * @param testID string
 */
export function getTestID(testID: string | undefined) {
  const appIdentifier = Application.applicationId

  if (!testID) {
    return undefined
  }

  const prefix = `${appIdentifier}:id/`
  const hasPrefix = testID.startsWith(prefix)

  return Platform.select({
    android: !hasPrefix ? `${prefix}${testID}` : testID,
    ios: hasPrefix ? testID.slice(prefix.length) : testID,
    web: hasPrefix ? testID.slice(prefix.length) : testID,
  })
}

export const tID = getTestID

/**
 * Helper function to determine if content is extending past device screen height
 * @param layout LayoutRectangle
 */
export function isContentOffScreenVertically(viewHeight: number) {
  const screenHeight = Dimensions.get('screen').height
  return viewHeight > screenHeight
}

/**
 * Determine if a device is made by Samsung.
 * This is used to fix a bug related to how Samsung devices handle the content menu
 */
export const deviceIsSamsung = () => brand === 'samsung'

/**
 * MailComposer will open mail modal on iOS and app on android
 * Fallback on Linking if it is blocked by user: https://docs.expo.io/versions/latest/sdk/mail-composer/
 */
export const mailTo = async (emailAddress: string) => {
  const mailComposerOptions = {
    recipients: [emailAddress],
  }
  return (await MailComposer.isAvailableAsync())
    ? MailComposer.composeAsync(mailComposerOptions)
    : Linking.openURL(`mailto:${emailAddress}`)
}

/**
 * Checks if using Chromatic on the web
 */
export const isChromaticWeb = IS_WEB ? isChromatic : () => false

/**
 * Web utilizes navigatation via lat/lng if values exist, otherwise navigate by the street address
 */
export const openHyperlinkForDirectionsToAddress = (address: string, lat?: number, lng?: number) => {
  const webLinkLatLng = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`
  const webLinkStreet = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`
  const url =
    Platform.select({
      ios: `maps:0,0?q=${encodeURIComponent(address)}`,
      android: `geo:0,0?q=${encodeURIComponent(address)}`,
      web: lat ? webLinkLatLng : webLinkStreet,
    }) ?? ''
  return IS_WEB ? window.open(url) : Linking.openURL(url)
}

interface ActionSheetOptions {
  options: string[]
  destructiveButtonIndex: number
  cancelButtonIndex: number
}

export const isPatientInEpisodeType = (episodes: Episode[], programType: ProgramNames) => {
  return episodes?.length > 0 && episodes.find((episode: Episode) => episode?.program_name === programType)
}

export const onUpcomingSessionPress = (
  session: CalendarCardAppointment,
  programName: ProgramNames,
  showActionSheetWithOptions: (options: ActionSheetOptions, callback: (i: number) => void) => void,
  navigateToRescheduleSession: (appointment: CalendarCardAppointment | number) => void,
  onCancelSessionPress: (appointment: CalendarCardAppointment) => void,
) => {
  const programAllowsReschedule = PROGRAM_SESSION_RESCHEDULE_MAP[programName]
  const canReschedule = !isWithin24Hours(session) && programAllowsReschedule
  if (canReschedule) {
    showActionSheetWithOptions(
      {
        options: ['Reschedule session', 'Cancel session', 'Close'],
        destructiveButtonIndex: 1,
        cancelButtonIndex: 2,
      },
      (buttonIndex: number) => {
        if (buttonIndex === 0) {
          navigateToRescheduleSession && navigateToRescheduleSession(session)
        } else if (buttonIndex === 1) {
          onCancelSessionPress(session as unknown as CalendarCardAppointment)
        }
      },
    )
  } else {
    showActionSheetWithOptions(
      {
        options: ['Cancel session', 'Close'],
        destructiveButtonIndex: 1,
        cancelButtonIndex: 2,
      },
      (buttonIndex: number) => {
        if (buttonIndex === 0) {
          onCancelSessionPress(session as unknown as CalendarCardAppointment)
        }
      },
    )
  }
}

/**
 * Put this after the onPress function in the form action button to scroll the view
 * to the first input that has an error message.
 *
 * Only needed for Web since Native handles this out of the box
 */
export const scrollToFormInputErrorWeb = () => {
  if (IS_WEB) {
    const FORM_ERROR_SUBSTRINGS = [
      'errorBanner',
      'error-message',
      'userAgeBelowMinError',
      'eligibilityErrorBanner',
      'EmployeeInfoForDependentEligibility-tips',
    ]

    const firstError = Array.from(document.querySelectorAll('[data-testid]')).find((el) => {
      const testId = el.getAttribute('data-testid')
      return testId ? FORM_ERROR_SUBSTRINGS.find((error) => testId.includes(error)) : false
    })
    if (firstError) {
      firstError.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }
}

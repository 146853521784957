import React, { FunctionComponent, useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { MessagingIcon } from '../../atoms/icons/MessagingIcon'
import { LiveChatIllustrationIcon } from '../../atoms/illustrations/LiveChatIllustrationIcon'
import { AppContext } from '../../context'
import { CareOptionCard } from '../../templates/careOptionCard/CareOptionCard'

export interface CareNavigatorLiveChatOptionCardProps {
  isInternational?: boolean
  onPress: (() => void) | undefined
}

export const CareNavigatorLiveChatOptionCard: FunctionComponent<CareNavigatorLiveChatOptionCardProps> = ({
  isInternational = false,
  onPress,
}) => {
  const { isDedicatedCareNavigatorEnabled, showChatBusinessHoursEndingAt6PM } = useContext(AppContext)
  const { colors } = useTheme()

  const optionHeader = (
    <FormattedMessage
      defaultMessage='Connect to live chat'
      description='Text description shown to user that clicking this option will start the process of chatting with a live agent'
    />
  )

  const optionDescription = isInternational ? (
    <FormattedMessage
      defaultMessage='We’re online 24/7.'
      description='Text description explaining that there are agents ready to live chat twenty four hours and seven days a week.'
    />
  ) : showChatBusinessHoursEndingAt6PM ? (
    <FormattedMessage
      defaultMessage='We’re available Mon-Fri 6AM-6PM PT.'
      description='Message describing the day and time range for when live chat via the app is available to the user.'
    />
  ) : (
    <FormattedMessage
      defaultMessage='We’re available Mon-Thu 6AM-8PM PT and Fri 6AM-6PM PT.'
      description='Text description explaining that there are agent ready to live chat within a specified time frame.'
    />
  )

  return (
    <CareOptionCard
      onPress={onPress}
      optionIcon={
        isDedicatedCareNavigatorEnabled ? (
          <MessagingIcon fillColor={colors.textSecondary} />
        ) : (
          <LiveChatIllustrationIcon />
        )
      }
      optionHeader={optionHeader}
      optionDescription={optionDescription}
      testId={'CareNavigatorLiveChatOptionCard'}
    />
  )
}

import React, { FunctionComponent } from 'react'
import { Circle, Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

type GroupAddIconProps = {
  width?: string | number
  fillColor?: string
}

export const GroupAddIcon: FunctionComponent<GroupAddIconProps> = ({ width = 24, fillColor }) => {
  const { colors } = useTheme()
  return (
    <Svg width={width} height={width} viewBox='0 0 24 24' fill='none'>
      <Circle
        cx={11.1383}
        cy={8.6666}
        r={2.65}
        stroke={fillColor || colors.iconDefault}
        strokeWidth={1.5}
        strokeLinecap='round'
      />
      <Path
        d='M16.371 19.816H5.905v1.5H16.37v-1.5zM5.734 19.68c.13-.888.457-2.197 1.26-3.27.774-1.034 2.024-1.894 4.144-1.894v-1.5c-2.612 0-4.298 1.098-5.345 2.495-1.019 1.36-1.398 2.954-1.543 3.953l1.484.216zm5.404-5.164c2.12 0 3.37.86 4.145 1.895.802 1.072 1.13 2.381 1.259 3.27l1.484-.217c-.145-.999-.524-2.593-1.543-3.953-1.047-1.397-2.733-2.495-5.345-2.495v1.5zm-5.233 5.3a.2.2 0 01-.147-.056.093.093 0 01-.022-.034.096.096 0 01-.002-.046l-1.484-.216c-.155 1.06.71 1.852 1.655 1.852v-1.5zm10.466 1.5c.946 0 1.81-.792 1.655-1.852l-1.484.216a.096.096 0 01-.002.046.093.093 0 01-.022.034.2.2 0 01-.147.056v1.5z'
        fill={fillColor || colors.iconDefault}
      />
      <Rect
        x={16.757}
        y={5.4084}
        width={4.81667}
        height={0.85}
        rx={0.425}
        stroke={fillColor || colors.iconDefault}
        strokeWidth={0.85}
        strokeLinecap='round'
      />
      <Rect
        x={19.5906}
        y={3.425}
        width={4.81667}
        height={0.85}
        rx={0.425}
        transform='rotate(90 19.59 3.425)'
        stroke={fillColor || colors.iconDefault}
        strokeWidth={0.85}
        strokeLinecap='round'
      />
    </Svg>
  )
}

import React, { FunctionComponent } from 'react'
import { G, Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Warning, this is not the same as Avatar.tsx
 * This is an icon that takes no image url src i.e using it for a profile photo
 */
export const AvatarIcon: FunctionComponent<AvatarIconProps> = ({ size = 48 }) => {
  const { colors } = useTheme()
  const { body, shadow, fill } = colors.icons.avatarIcon

  return (
    <Svg width={size} height={size} viewBox='0 0 49 48'>
      <G id='MVP-app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <G id='I3' transform='translate(-24.000000, -221.000000)'>
          <G id='il_48_avatar' transform='translate(24.000000, 221.000000)'>
            <G transform='translate(0.250000, 0.000000)'>
              <Rect id='Rectangle' x='0' y='0' width='48' height='48' />
              <G
                id='avatar-7672_3d4f0d7d-cfea-4b0d-a7ac-389ddd781889'
                transform='translate(8.000000, 8.000000)'
                fillRule='nonzero'
              >
                <Path
                  d='M23.4784,12.5978667 C23.4784,17.8997333 20.1232,22.4170667 16,22.4176 C11.8768,22.4181333 8.53333333,18.1194667 8.53333333,12.8176 L8.53333333,7.6976 C8.53333333,2.1504 12.4645333,0 16,0 C19.5328,0 23.4778667,1.9312 23.4778667,7.4784 L23.4784,12.5978667 Z'
                  id='Path'
                  fill={body.fill}
                />
                <Path
                  d='M25.6,26.1333333 C20.2832,25.1173333 20.2666667,23.4666667 20.2666667,22.4 L20.2666667,20.6208 C19.1257937,21.7422517 17.5994258,22.3852342 16,22.4181333 C14.4121016,22.3946165 12.8903222,21.7784861 11.7333333,20.6906667 L11.7333333,22.4 C11.7333333,23.4666667 11.2,25.1669333 5.86666667,26.1333333 C2.8573089,26.6322351 0.498901749,28.9906422 0,32 L32,32 C31.1434169,28.9941833 28.6688189,26.7258019 25.6,26.1333333 Z'
                  id='Path'
                  fill={body.fill}
                />
                <Path
                  d='M20.2666667,20.6208 C19.1259463,21.7424808 17.599483,22.3855035 16,22.4181333 C14.4121016,22.3946165 12.8903222,21.7784861 11.7333333,20.6906667 L11.7333333,22.4 C11.7333333,23.4666667 11.2,25.1669333 5.86666667,26.1333333 C2.8573089,26.6322351 0.498901749,28.9906422 0,32 L8.88693333,32 L20.2666667,20.6208 Z'
                  id='Path'
                  fill={shadow.fill}
                />
              </G>
            </G>
            <Path
              d='M3,12 L3,6.75 C3,4.540861 4.790861,2.75 7,2.75 L12.25,2.75 L12.25,2.75'
              id='Path-2'
              stroke={fill}
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <Path
              d='M36.25,45.25 L36.25,40 C36.25,37.790861 38.040861,36 40.25,36 L45.5,36 L45.5,36'
              id='Path-2'
              stroke={fill}
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
              transform='translate(40.875000, 40.625000) rotate(-180.000000) translate(-40.875000, -40.625000) '
            />
            <Path
              d='M36.25,12 L36.25,6.75 C36.25,4.540861 38.040861,2.75 40.25,2.75 L45.5,2.75 L45.5,2.75'
              id='Path-2'
              stroke={fill}
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
              transform='translate(40.875000, 7.375000) rotate(-270.000000) translate(-40.875000, -7.375000) '
            />
            <Path
              d='M3,45.25 L3,40 C3,37.790861 4.790861,36 7,36 L12.25,36 L12.25,36'
              id='Path-2'
              stroke={fill}
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
              transform='translate(7.625000, 40.625000) rotate(-90.000000) translate(-7.625000, -40.625000) '
            />
          </G>
        </G>
      </G>
    </Svg>
  )
}

type AvatarIconProps = {
  size?: string | number
}

import { ExperimentPayload, FetchStatus } from '@lyrahealth-inc/shared-app-logic'
import { ToastContent } from '@lyrahealth-inc/ui-core-crossplatform'

import { axiosInstance } from '../../common/http/axiosInstance'

export const SET_APP_CONSTANTS_ON_PAGELOAD = 'SET_APP_CONSTANTS_ON_PAGELOAD'
export const SET_DEVICE_UUID = 'SET_DEVICE_UUID'
export const setAppConstantsOnPageload = (val: $TSFixMe) => ({
  type: SET_APP_CONSTANTS_ON_PAGELOAD,
  val,
})

export const SET_HAS_VERIFIED_TO_NOT_REDIRECT = 'SET_HAS_VERIFIED_TO_NOT_REDIRECT'
export const setHasVerifiedToNotRedirect = () => ({ type: SET_HAS_VERIFIED_TO_NOT_REDIRECT })

export const SET_SHOULD_SHOW_COOKIE_BANNER = 'SET_SHOULD_SHOW_COOKIE_BANNER'
export const setShouldShowCookieBanner = (val: $TSFixMe) => ({
  type: SET_SHOULD_SHOW_COOKIE_BANNER,
  data: val,
})
export const SET_SHOULD_SHOW_COOKIE_SETTINGS = 'SET_SHOULD_SHOW_COOKIE_SETTINGS'

export const getShouldShowCookieBannerAndSettings = () => ({
  action: [SET_SHOULD_SHOW_COOKIE_BANNER, SET_SHOULD_SHOW_COOKIE_SETTINGS],
  request: {
    method: 'get',
    url: '/unsecured/v1/is-assumed-GDPR',
  },
})

export const SET_DETECTED_COUNTRY = 'SET_DETECTED_COUNTRY'
export const fetchDetectedCountry = () => ({
  action: SET_DETECTED_COUNTRY,
  request: {
    method: 'get',
    url: '/unsecured/v1/detect-country',
  },
})

export const SET_FETCH_DETECTED_COUNTRY_REQUEST_STATUS = 'SET_FETCH_DETECTED_COUNTRY_REQUEST_STATUS'
export const setFetchDetectedCountryRequestStatus = (status: FetchStatus) => ({
  type: SET_FETCH_DETECTED_COUNTRY_REQUEST_STATUS,
  data: status,
})

export const SET_ENABLE_MIXPANEL_EVENTS_FOR_ENV = 'SET_ENABLE_MIXPANEL_EVENTS_FOR_ENV'
export const setEnableMixpanelEventsForEnv = (val: $TSFixMe) => ({
  type: SET_ENABLE_MIXPANEL_EVENTS_FOR_ENV,
  enableMixpanelEventsForEnv: val,
})

export const SET_BYPASS_COMING_SOON_BEFORE_LAUNCH = 'SET_BYPASS_COMING_SOON_BEFORE_LAUNCH'
export const setBypassComingSoonBeforeLaunch = (val: boolean) => ({
  type: SET_BYPASS_COMING_SOON_BEFORE_LAUNCH,
  bypassComingSoonBeforeLaunch: val,
})

export const SET_TOAST_CONTENT = 'SET_TOAST_CONTENT'
export const CLEAR_TOAST_CONTENT = 'CLEAR_TOAST_CONTENT'
export const setToastContent = (content: ToastContent) => {
  return {
    content,
    type: SET_TOAST_CONTENT,
  }
}

export const clearToastContent = () => {
  return {
    type: CLEAR_TOAST_CONTENT,
  }
}

export const postExperimentEntrypoint = (payload: ExperimentPayload) => {
  return axiosInstance.post(`/unsecured/v1/utils/experiment-entrypoint`, payload)
}

export const setDeviceUUID = (deviceUUID: string) => ({ data: deviceUUID, type: SET_DEVICE_UUID })

import React from 'react'

import { fromJS } from 'immutable'

import { WORKLIFE_SERVICE_IDS } from '../../../features/workLife/constants/workLifeConstants'
import BrightHorizonsImage from '../../../images/microsite/BrightHorizons.png'
import CollegeCoachImage from '../../../images/microsite/CollegeCoach.png'
import FinancialImage from '../../../images/microsite/EY.png'
import LegalAssistanceImage from '../../../images/microsite/LegalAssistance.png'
import LifeCareImage from '../../../images/microsite/LifeCare.png'
import FamilyConciergeImage from '../../../images/microsite/ReThink.png'
import SittercityImage from '../../../images/microsite/Sittercity.png'
import { oneLine } from '../../utils/stringUtils'

export const amex = () => {
  return fromJS({
    workLifeServices: [
      {
        identifiers: WORKLIFE_SERVICE_IDS.FINANCIAL_SERVICES,
        title: 'Ayco Financial Services',
        img: FinancialImage,
        paragraph: (
          <>
            {oneLine(`Ayco Financial Coaches are here to help you improve your financial well-being. They can
                      help you manage your personal finances, plan for your retirement and more. These services are
                      available at no cost to you.`)}
          </>
        ),
        bullets: ['Call: 866-325-0099'],
        CTA: 'Financial Coaching',
        PATH: 'http://www.ayco.com/login/amex',
      },
      {
        identifiers: {
          id: 'brightHorizons',
          dataTestId: 'workLife-selectBrightHorizons',
        },
        title: 'Bright Horizons',
        img: BrightHorizonsImage,
        paragraph: (
          <>
            {oneLine(`When your regular family care plans fall through, Bright Horizons Back-Up Care provides
                        access to certified caregivers.`)}
          </>
        ),
        bullets: ['Username: AMEX', 'Password: Benefits4You'],
        CTA: 'Family Care (Bright Horizons)',
        PATH: 'https://clients.brighthorizons.com/amex',
      },
      {
        identifiers: {
          id: 'familyConcierge',
          dataTestId: 'workLife-selectFamilyConcierge',
        },
        title: 'Family Concierge',
        img: FamilyConciergeImage,
        paragraph: (
          <>
            {oneLine(`Whether you’re planning to grow your family through pregnancy, pursuing
                        adoption/surrogacy or caring for your new child, your personal family concierge will help you
                        understand and navigate parental leave and the wide array of free resources available to you.`)}
          </>
        ),
        bullets: [
          <>
            {oneLine(`Email: `)}
            <a href='mailto:familyconcierge@lifecare.com' target='_blank' rel='noreferrer'>
              familyconcierge@lifecare.com
            </a>
          </>,
          'Call: 888-288-2468',
        ],
        CTA: 'Family Concierge',
        PATH: 'https://thesquare.americanexpress.com/sites/enterprise/colleague-services/family/documents/112890/family-planning-assistance-parental-leave-us',
      },
      {
        identifiers: WORKLIFE_SERVICE_IDS.LEGAL_SERVICES,
        title: 'Legal Assistance',
        img: LegalAssistanceImage,
        paragraph: (
          <>
            {oneLine(`The Legal Assistance benefit offered through MetLife Legal Plans (formerly called Hyatt
                        Legal Plans) makes it affordable to get the legal help you need. You can enroll each year during
                        Annual Enrollment or when you elect benefits as a new hire.`)}
          </>
        ),
        bullets: [],
        CTA: 'Legal Assistance',
        PATH: 'https://www.americanexpress.com/en-us/colleagues/benefits/my-life-legal-assistance/',
      },
      {
        identifiers: {
          id: 'sittercity',
          dataTestId: 'workLife-selectSittercity',
        },
        title: 'Sittercity',
        img: SittercityImage,
        paragraph: (
          <>
            {oneLine(`Get free access to Sittercity.com to find support for your family. Services include
                        babysitting, petsitting, housekeeping, elder care and more.`)}
            <br />
            <br />
            {oneLine(`Click "Find Sitters, Nannies, & Housekeepers."`)}
          </>
        ),
        bullets: [],
        CTA: 'Free Sittercity Membership',
        PATH: 'https://www.sittercity.com/amex',
      },
      {
        identifiers: {
          id: 'collegeCoach',
          dataTestId: 'workLife-selectCollegeCoach',
        },
        title: 'College Coach',
        img: CollegeCoachImage,
        paragraph: (
          <>
            {oneLine(`Get free access to a team of college admissions and financial experts who can help you
                        maximize your child’s academic success and plan for college costs.`)}
          </>
        ),
        bullets: [
          'Username: Amex',
          'Password: Benefits4You',
          <>
            {oneLine(`Email: `)}
            <a href='mailto:aexp@getintocollege.com' target='_blank' rel='noreferrer'>
              aexp@getintocollege.com
            </a>
          </>,
        ],
        CTA: 'College Coach',
        PATH: 'https://passport.getintocollege.com/',
      },
      {
        identifiers: {
          id: 'lifeCare',
          dataTestId: 'workLife-selectLifeCare',
        },
        title: 'LifeCare',
        img: LifeCareImage,
        paragraph: (
          <>
            {oneLine(`Cross off your to-do’s with free support from a specialist at LifeCare. They can help
                        with everything from finding pet care or child care to sourcing home repairs or planning a move.
                        Ask their team of specialists for help checking off tasks on your to-do list.`)}
          </>
        ),
        bullets: [],
        CTA: 'Free Personal Assistant (LifeCare)',
        PATH: 'https://member.lifecare.com/',
      },
    ],
  })
}

import { defineMessage, defineMessages } from 'react-intl'

import { TREATMENT_OPTIONS } from './treatmentOptions'

/**
 * Shared messages between different treatment options / recommendations
 */
export const TREATMENT_CARD_MESSAGES = defineMessages({
  /* COACHING */
  CONNECT_COACHING_METHODS_TITLE: {
    defaultMessage: 'Choose your preferred way to connect for sessions:',
    description: 'Title text for choosing a method (e.g. live video) to connect for live coaching sessions.',
  },
  CONNECT_COACHING_METHODS_TITLE_2: {
    defaultMessage: 'Conveniently connect with your Lyra Coach',
    description: 'Title text for choosing a method (e.g. live video) to connect for live coaching sessions.',
  },
  COACHING_DESCRIPTION: {
    defaultMessage:
      'Work with your Lyra Coach through regularly-scheduled sessions to better understand what’s challenging you, decide what you want to work on, and plan a path forward. As you work together, you’ll continue to develop your toolkit of skills and strategies to support your mental health, and build strength for lasting change.',
    description: 'text giving an overview of the coaching treatment option',
  },
  COACHING_DESCRIPTION_T2: {
    defaultMessage:
      'Work 1 on 1 with a certified Lyra Coach to better understand what’s challenging you and to see things through a new perspective. Together, you’ll create a personalized toolkit of strategies, so you can feel confident moving forward.',
    description: 'Description for mental health coaching treatment option',
  },
  COACHING_AVAILABILITY_MESSAGE: {
    defaultMessage: 'This coach is not currently taking new clients for Mental Health Coaching',
    description: 'Text for the member when mental health coaching is not available.',
  },
  BOOK_COACH_BUTTON_TEXT: {
    defaultMessage: 'Book a coach',
    description: 'Button text to book an appointment with a coach',
  },
  VIEW_AVAILABLE_COACHES_BUTTON_TEXT: {
    defaultMessage: 'View available coaches',
    description: 'Button text for viewing available coaches for mental health coaching treatment option.',
  },

  /* GUIDED SELF-CARE */
  GUIDED_SELF_CARE_DESCRIPTION: {
    defaultMessage:
      'Meet with your Lyra Coach as early as today for a 50-minute live video session to discuss your needs. During the session, your coach will get to know you, and together, you’ll plan a path forward.',
    description: 'Description text for guided self care treatment option',
  },
  GUIDED_SELF_CARE_SUBTITLE: {
    defaultMessage: 'How this care option works:',
    description: 'Title text for guided self care treatment option',
  },
  GUIDED_SELF_CARE_APPOINTMENT_NOTE: {
    defaultMessage: 'Same-day, live-video appointments may be available',
    description: 'Note text for guided self care treatment appointment availability',
  },

  /* INDIVIDUAL THERAPY */
  INDIVIDUAL_THERAPY_DESCRIPTION: {
    defaultMessage:
      'Therapy can help you manage your thoughts, emotions, and behaviors so every day isn’t a struggle. Studies have shown that evidence-based therapy can provide lasting relief for a wide range of mental health conditions including anxiety, depression, trauma, and phobias.',
    description: 'Text for giving an overview of individual therapy treatment option',
  },
  INDIVIDUAL_THERAPY_SUBTITLE: {
    defaultMessage: 'Therapy works well for clinical diagnoses, such as:',
    description: 'Title text for a list of clinical diagnoses (e.g. depression)',
  },

  /* CHILD THERAPY */
  INDIVIDUAL_THERAPY_CHILD_DESCRIPTION: {
    defaultMessage:
      'Clinical studies have shown that therapy can help children and adolescents manage their mood and change their behavior. Lyra connects you with high-quality therapists who have experience treating young people. Depending on the concern, your therapist may incorporate parents in the treatment as well. All of our therapists practice evidence-based therapies that have been proven in research to make a difference.',
    description: 'Text for giving an overview of Child and Adolescent treatment option',
  },

  /* BLENDED CARE MEDS */
  BLENDED_CARE_MEDS_DESCRIPTION: {
    defaultMessage:
      'Understand if emotional wellness medication could help you start feeling like yourself again. Speak with a board-certified Lyra physician to evaluate treatment options and determine if emotional wellness medication might work for you. If you’re already taking a medication, see if it’s still the best fit for your needs.',
    description: 'Text for giving an overview of Medication consultation treatment option',
  },

  BLENDED_CARE_MEDS_SUBTITLE: {
    defaultMessage: 'We’re by your side throughout the entire process:',
    description: 'Title text for describing the expected benefits from the medication consultation process',
  },

  DIRECT_ACCESS_MEDS_DESCRIPTION: {
    defaultMessage:
      'Medication can be an effective tool for managing your mood so you can focus on what’s important. Learn more about your options from a specialist who can tailor the right plan to best meet your needs. Lyra connects you with a specialist at a location convenient to you.',
    description: 'Text for giving an overview of Medication consultation treatment option',
  },

  DIRECT_ACCESS_MEDS_CHILD_DESCRIPTION: {
    defaultMessage:
      'Medication can be an effective tool for managing your child’s mood so they can focus on what’s important. Learn more about your options from a specialist who can tailor the right plan to best meet your child’s needs. Lyra connects you with a specialist at a location convenient to you.',
    description: 'Text for giving an overview of child individual therapy treatment option',
  },

  /** COUPLES THERAPY */
  COUPLES_THERAPY_DESCRIPTION: {
    defaultMessage:
      'You and your significant other can meet with a high-quality therapist who is trained to help with couples concerns. Learn to better understand your relationship and practice new ways of connecting.',
    description: 'Text for giving an overview of couple therapy treatment option',
  },
  COUPLES_THERAPY_BUTTON_TEXT: {
    defaultMessage: 'Find a couples therapist',
    description: 'Label of button to find a therapist',
  },

  /** FAMILY THERAPY */
  FAMILY_THERAPY_DESCRIPTION: {
    defaultMessage:
      'Families can get stuck just like individuals can. Sometimes it’s good to get everyone in the room to work things out. Family therapy can offer practical skills for managing issues at home. Lyra connects you with high-quality therapists with experience helping families like yours.',
    description: 'Description text for family therapy treatment option',
  },

  /** ALCOHOL USE DISORDER */
  LYRA_RENEW_PROGRAM_NAME: {
    defaultMessage: 'Lyra Renew',
    description: 'Name of the program for treating alcohol use disorder',
  },
  ALCOHOL_USE_DISORDER_DESCRIPTION: {
    defaultMessage:
      'This online program is for people who want to reduce or quit their alcohol use, build healthier habits, and change how drinking affects their personal and professional lives.',
    description: 'Description text for alcohol treatment option',
  },
  ALCOHOL_USE_DISORDER_SUBTITLE: {
    defaultMessage: 'This inclusive & private program includes:',
    description: 'Title text for a list of features a program offers',
  },

  /** OTHERS */
  GENERIC_OPTION_SUBTITLE: {
    defaultMessage: 'This is a good fit if…',
    description: 'Title text for a list of reasons why a treatment option is a good fit',
  },
  CARE_OPTION_ELIGIBILITY_REQUIREMENT: {
    defaultMessage:
      'For this care option, you may have additional eligibility requirements and be subject to some cost sharing as defined under your health plan.',
    description: 'Text for care option eligibility requirements',
  },
  BOOK_THERAPIST_BUTTON_TEXT: {
    defaultMessage: 'Book a therapist',
    description: 'Button text to book an appointment with a therapist',
  },
  BOOK_CONSULTATION_BUTTON_TEXT: {
    defaultMessage: 'Book a consultation',
    description: 'Button text to book an appointment with a medication consultation',
  },
  CHECK_AVAILABILITY_BUTTON_TEXT: {
    defaultMessage: 'Check availability',
    description: 'Button text to check appointment availability',
  },

  /* ICAS */
  ICAS_DESCRIPTION: {
    defaultMessage: 'Counsellors will work with you 1:1 to manage your thoughts, feelings, and behaviors.',
    description: 'Description of how a user can find a counsellor',
  },
  ICAS_FAMILY_DESCRIPTION: {
    defaultMessage:
      'Discuss your options with a care navigator, who can help connect you with a counsellor suited to your family’s needs.',
    description: 'Description of how a user can find a counsellor for their family through ICAS',
  },
})

const IndividualTherapy = defineMessage({
  defaultMessage: 'Individual therapy',
  description: 'Name of treatment option',
})
const MentalHealthCoaching = defineMessage({
  defaultMessage: 'Mental health coaching',
  description: 'Name of treatment option',
})
const MedicationConsultation = defineMessage({
  defaultMessage: 'Medication consultation',
  description: 'Name of treatment option',
})
const FamilyTherapy = defineMessage({
  defaultMessage: 'Family therapy',
  description: 'Name of treatment option',
})

export const TREATMENT_OPTION_DISPLAY_NAMES = {
  [TREATMENT_OPTIONS.COACHING]: MentalHealthCoaching,
  [TREATMENT_OPTIONS.LIVE_MESSAGING_COACHING]: MentalHealthCoaching,
  [TREATMENT_OPTIONS.INDIVIDUAL_THERAPY]: IndividualTherapy,
  [TREATMENT_OPTIONS.BLENDED_CARE_THERAPY]: IndividualTherapy,
  [TREATMENT_OPTIONS.FAMILY_THERAPY]: FamilyTherapy,
  [TREATMENT_OPTIONS.FAMILY_THERAPY_CHILD]: FamilyTherapy,
  [TREATMENT_OPTIONS.MEDICATION_CONSULTATION]: MedicationConsultation,
  [TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS]: MedicationConsultation,
  [TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS_CHILD]: MedicationConsultation,
  ...defineMessages({
    [TREATMENT_OPTIONS.GUIDED_SELF_CARE]: {
      defaultMessage: 'Guided self-care',
      description: 'Name of treatment option',
    },
    [TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD]: {
      defaultMessage: 'Child and adolescent therapy',
      description: 'Name of treatment option',
    },
    [TREATMENT_OPTIONS.COUPLES_THERAPY]: {
      defaultMessage: 'Couples therapy',
      description: 'Name of treatment option',
    },
    [TREATMENT_OPTIONS.BLENDED_CARE_MEDS]: {
      defaultMessage: 'Medication consultation & prescription refill',
      description: 'Name of treatment option',
    },
    [TREATMENT_OPTIONS.ALCOHOL_USE_DISORDER]: {
      defaultMessage: 'Alcohol treatment program',
      description: 'Name of treatment option',
    },
    [TREATMENT_OPTIONS.SELF_CARE_APPS]: {
      defaultMessage: 'Self care apps',
      description: 'Name of treatment option',
    },
    [TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE]: {
      defaultMessage: 'Individual counselling',
      description: 'Name of treatment option',
    },
    [TREATMENT_OPTIONS.CONTROLLED_MEDICATION]: {
      defaultMessage: 'Controlled medication',
      description: 'Name of treatment option',
    },
    [TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD_ICAS]: {
      defaultMessage: 'Counselling for adolescents',
      description: 'Name of treatment option',
    },
  }),
}

import { defineMessages } from 'react-intl'
/**
 * All supported time zones
 * Key is the tz database name(https://en.wikipedia.org/wiki/List_of_tz_database_time_zones),
 * value is the display name for the time zone
 *
 * Referenced from https://github.com/ndom91/react-timezone-select/blob/main/src/timezone-list.ts
 */
export const timeZones = Object.freeze(
  defineMessages({
    'Pacific/Midway': {
      defaultMessage: 'Midway Island, Samoa',
      description: 'Option text for Pacific Midway time zone, which contains location names',
    },
    'Pacific/Honolulu': {
      defaultMessage: 'Hawaii',
      description: 'Option text for time zone in Hawaii',
    },
    'America/Juneau': {
      defaultMessage: 'Alaska',
      description: 'Option text for time zone in Alaska',
    },
    'America/Boise': {
      defaultMessage: 'Mountain Time',
      description: 'Option text for Mountain Time time zone',
    },
    'America/Dawson': {
      defaultMessage: 'Dawson, Yukon',
      description: 'Option text for Dawson time zone, which contains location names',
    },
    'America/Chihuahua': {
      defaultMessage: 'Chihuahua, La Paz, Mazatlan',
      description: 'Option text for Chihuahuan time zone, which contains location names',
    },
    'America/Phoenix': {
      defaultMessage: 'Arizona',
      description: 'Option text for time zone in Arizona',
    },
    'America/Chicago': {
      defaultMessage: 'Central Time',
      description: 'Option text for Central Time time zone',
    },
    'America/Regina': {
      defaultMessage: 'Saskatchewan',
      description: 'Option text for time zone in Saskatchewan',
    },
    'America/Mexico_City': {
      defaultMessage: 'Guadalajara, Mexico City, Monterrey',
      description: 'Option text for Mexico City time zone, which contains location names',
    },
    'America/Belize': {
      defaultMessage: 'Central America',
      description: 'Option text for Central America time zone',
    },
    'America/Detroit': {
      defaultMessage: 'Eastern Time',
      description: 'Option text for Eastern Time time zone',
    },
    'America/Bogota': {
      defaultMessage: 'Bogota, Lima, Quito',
      description: 'Option text for Bogota time zone, which contains location names',
    },
    'America/Caracas': {
      defaultMessage: 'Caracas, La Paz',
      description: 'Option text for Caracas time zone, which contains location names',
    },
    'America/Santiago': {
      defaultMessage: 'Santiago',
      description: 'Option text for Santiago time zone, which contains location names',
    },
    'America/St_Johns': {
      defaultMessage: 'Newfoundland and Labrador',
      description: 'Option text for St Johns time zone, which contains location names',
    },
    'America/Sao_Paulo': {
      defaultMessage: 'Brasilia',
      description: 'Option text for time zone in Brasilia',
    },
    'America/Tijuana': {
      defaultMessage: 'Tijuana',
      description: 'Option text for time zone in Tijuana',
    },
    'America/Montevideo': {
      defaultMessage: 'Montevideo',
      description: 'Option text for time zone in Montevideo',
    },
    'America/Argentina/Buenos_Aires': {
      defaultMessage: 'Buenos Aires, Georgetown',
      description: 'Option text for Argentina time zone, which contains location names',
    },
    'America/Godthab': {
      defaultMessage: 'Greenland',
      description: 'Option text for time zone in Greenland',
    },
    'America/Los_Angeles': {
      defaultMessage: 'Pacific Time',
      description: 'Option text for Pacific Time time zone',
    },
    'Atlantic/Azores': {
      defaultMessage: 'Azores',
      description: 'Option text for Azores time zone',
    },
    'Atlantic/Cape_Verde': {
      defaultMessage: 'Cape Verde Islands',
      description: 'Option text for Cape Verde time zone',
    },
    UTC: {
      defaultMessage: 'UTC',
      description: 'Option text for UTC time zone',
    },
    'Europe/London': {
      defaultMessage: 'Edinburgh, London',
      description: 'Option text for London time zone, which contains location names',
    },
    'Europe/Dublin': {
      defaultMessage: 'Dublin',
      description: 'Option text for Dublin time zone',
    },
    'Europe/Lisbon': {
      defaultMessage: 'Lisbon',
      description: 'Option text for Lisbon time zone',
    },
    'Africa/Casablanca': {
      defaultMessage: 'Casablanca, Monrovia',
      description: 'Option text for Casablanca time zone, which contains location names',
    },
    'Atlantic/Canary': {
      defaultMessage: 'Canary Islands',
      description: 'Option text for time zone in Canary Islands',
    },
    'Europe/Belgrade': {
      defaultMessage: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
      description: 'Option text for Belgrade time zone, which contains location names',
    },
    'Europe/Brussels': {
      defaultMessage: 'Brussels, Copenhagen, Madrid, Paris',
      description: 'Option text for Brussels time zone, which contains location names',
    },
    'Europe/Sarajevo': {
      defaultMessage: 'Sarajevo, Skopje, Warsaw, Zagreb',
      description: 'Option text for Sarajevo time zone, which contains location names',
    },
    'Europe/Amsterdam': {
      defaultMessage: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
      description: 'Option text for Amsterdam time zone, which contains location names',
    },
    'Africa/Algiers': {
      defaultMessage: 'West Central Africa',
      description: 'Option text for time zone in West Central Africa',
    },
    'Europe/Bucharest': {
      defaultMessage: 'Bucharest',
      description: 'Option text for Bucharest time zone',
    },
    'Africa/Cairo': {
      defaultMessage: 'Cairo',
      description: 'Option text for Cairo time zone',
    },
    'Europe/Helsinki': {
      defaultMessage: 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
      description: 'Option text for Helsinki time zone, which contains location names',
    },
    'Europe/Athens': {
      defaultMessage: 'Athens, Minsk',
      description: 'Option text for Athens time zone, which contains location names',
    },
    'Asia/Jerusalem': {
      defaultMessage: 'Jerusalem',
      description: 'Option text for time zone in Jerusalem',
    },
    'Africa/Harare': {
      defaultMessage: 'Harare, Pretoria',
      description: 'Option text for Harare time zone, which contains location names',
    },
    'Europe/Moscow': {
      defaultMessage: 'Istanbul, Moscow, St. Petersburg, Volgograd',
      description: 'Option text for Moscow time zone, which contains location names',
    },
    'Asia/Kuwait': {
      defaultMessage: 'Kuwait, Riyadh',
      description: 'Option text for Kuwait time zone, which contains location names',
    },
    'Africa/Nairobi': {
      defaultMessage: 'Nairobi',
      description: 'Option text for time zone in Nairobi',
    },
    'Asia/Baghdad': {
      defaultMessage: 'Baghdad',
      description: 'Option text for time zone in Baghdad',
    },
    'Asia/Tehran': {
      defaultMessage: 'Tehran',
      description: 'Option text for time zone in Tehran',
    },
    'Asia/Dubai': {
      defaultMessage: 'Abu Dhabi, Muscat',
      description: 'Option text for Dubai time zone, which contains location names',
    },
    'Asia/Baku': {
      defaultMessage: 'Baku, Tbilisi, Yerevan',
      description: 'Option text for Baku time zone, which contains location names',
    },
    'Asia/Kabul': {
      defaultMessage: 'Kabul',
      description: 'Option text for time zone in Kabul',
    },
    'Asia/Yekaterinburg': {
      defaultMessage: 'Ekaterinburg',
      description: 'Option text for time zone in Ekaterinburg',
    },
    'Asia/Karachi': {
      defaultMessage: 'Islamabad, Karachi, Tashkent',
      description: 'Option text for Karachi time zone, which contains location names',
    },
    'Asia/Kolkata': {
      defaultMessage: 'Chennai, Kolkata, Mumbai, New Delhi',
      description: 'Option text for Kolkata time zone, which contains location names',
    },
    'Asia/Kathmandu': {
      defaultMessage: 'Kathmandu',
      description: 'Option text for time zone in Kathmandu',
    },
    'Asia/Dhaka': {
      defaultMessage: 'Astana, Dhaka',
      description: 'Option text for Dhaka time zone, which contains location names',
    },
    'Asia/Colombo': {
      defaultMessage: 'Sri Jayawardenepura',
      description: 'Option text for time zone in Sri Jayawardenepura',
    },
    'Asia/Almaty': {
      defaultMessage: 'Almaty, Novosibirsk',
      description: 'Option text for Almaty time zone, which contains location names',
    },
    'Asia/Rangoon': {
      defaultMessage: 'Yangon Rangoon',
      description: 'Option text for time zone in Yangon Rangoon',
    },
    'Asia/Bangkok': {
      defaultMessage: 'Bangkok, Hanoi, Jakarta',
      description: 'Option text for Bangkok time zone, which contains location names',
    },
    'Asia/Krasnoyarsk': {
      defaultMessage: 'Krasnoyarsk',
      description: 'Option text for time zone in Krasnoyarsk',
    },
    'Asia/Shanghai': {
      defaultMessage: 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
      description: 'Option text for Beijing time zone, which contains city names',
    },
    'Asia/Kuala_Lumpur': {
      defaultMessage: 'Kuala Lumpur, Singapore',
      description: 'Option text for Kuala Lumpur time zone, which contains city names',
    },
    'Asia/Taipei': {
      defaultMessage: 'Taipei',
      description: 'Option text for time zone in Taipei',
    },
    'Australia/Perth': {
      defaultMessage: 'Perth',
      description: 'Option text for time zone in Perth',
    },
    'Asia/Irkutsk': {
      defaultMessage: 'Irkutsk, Ulaanbaatar',
      description: 'Option text for Irkutsk time zone, which contains city names',
    },
    'Asia/Seoul': {
      defaultMessage: 'Seoul',
      description: 'Option text for time zone in Seoul',
    },
    'Asia/Tokyo': {
      defaultMessage: 'Osaka, Sapporo, Tokyo',
      description: 'Option text for Tokyo time zone, which contains city names',
    },
    'Asia/Yakutsk': {
      defaultMessage: 'Yakutsk',
      description: 'Option text for time zone in Yakutsk',
    },
    'Australia/Darwin': {
      defaultMessage: 'Darwin',
      description: 'Option text for time zone in Darwin',
    },
    'Australia/Adelaide': {
      defaultMessage: 'Adelaide',
      description: 'Option text for time zone in Adelaide',
    },
    'Australia/Sydney': {
      defaultMessage: 'Canberra, Melbourne, Sydney',
      description: 'Option text for Sydney time zone, which contains city names',
    },
    'Australia/Brisbane': {
      defaultMessage: 'Brisbane',
      description: 'Option text for time zone in Brisbane',
    },
    'Australia/Hobart': {
      defaultMessage: 'Hobart',
      description: 'Option text for time zone in Hobart',
    },
    'Asia/Vladivostok': {
      defaultMessage: 'Vladivostok',
      description: 'Option text for time zone in Vladivostok',
    },
    'Pacific/Guam': {
      defaultMessage: 'Guam, Port Moresby',
      description: 'Option text for Guam time zone, which contains city names',
    },
    'Asia/Magadan': {
      defaultMessage: 'Magadan, Solomon Islands, New Caledonia',
      description: 'Option text for Magadan time zone, which contains city names',
    },
    'Asia/Kamchatka': {
      defaultMessage: 'Kamchatka, Marshall Islands',
      description: 'Option text for Kamchatka time zone, which contains city names',
    },
    'Pacific/Fiji': {
      defaultMessage: 'Fiji Islands',
      description: 'Option text for time zone in Fiji Islands',
    },
    'Pacific/Auckland': {
      defaultMessage: 'Auckland, Wellington',
      description: 'Option text for Auckland time zone, which contains city names',
    },
    'Pacific/Tongatapu': {
      defaultMessage: `Nuku'alofa`,
      description: 'Option text for time zone in Tongatapu',
    },
  }),
)

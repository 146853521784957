import React, { FunctionComponent, useContext } from 'react'

import { noop } from 'lodash-es'
import styled from 'styled-components/native'

import { AppContext } from '../../context'
import { CareNavigatorEmailOptionCard } from '../../molecules/careNavigatorOptionCard/CareNavigatorEmailOptionCard'
import { CareNavigatorFAQOptionCard } from '../../molecules/careNavigatorOptionCard/CareNavigatorFAQOptionCard'
import { CareNavigatorLiveChatOptionCard } from '../../molecules/careNavigatorOptionCard/CareNavigatorLiveChatOptionCard'
import { CareNavigatorPhoneCallOptionCard } from '../../molecules/careNavigatorOptionCard/CareNavigatorPhoneCallOptionCard'
import { CareNavigatorScheduleCallOptionCard } from '../../molecules/careNavigatorOptionCard/CareNavigatorScheduleCallOptionCard'
import { Flex1View } from '../../templates'
import { ThemeType } from '../../utils'

export interface CareNavigatorConsolidatedOptionsListProps {
  customerPhoneNumber?: string
  isInternational: boolean
  isLyrians?: boolean
  isHomebaseFlow?: boolean
  isSidePanel?: boolean
  onPressEmail: () => void
  onPressFAQ?: () => void
  onPressLiveChat: () => void
  onPressPhoneCall: () => void
  onPressScheduleCall: () => void
  showLiveChat: boolean
  showScheduleCall?: boolean
  hideCustomerPhoneNumber: boolean
  careNavigatorHeadshot: string
  careNavigatorLicense: string
  careNavigatorName: string
  showMinimalCTAText: boolean
}

const OptionContainer = styled(Flex1View)(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  marginBottom: theme.spacing['32px'],
}))

const OptionChoiceContainer = styled(Flex1View)<{
  isDedicatedCareNavigatorEnabled?: boolean
  theme?: ThemeType
}>(({ isDedicatedCareNavigatorEnabled, theme }) => ({
  marginTop: isDedicatedCareNavigatorEnabled ? undefined : theme.spacing['12px'],
}))

export const CareNavigatorConsolidatedOptionsList: FunctionComponent<CareNavigatorConsolidatedOptionsListProps> = ({
  customerPhoneNumber,
  isInternational,
  isLyrians = false,
  isHomebaseFlow = false,
  isSidePanel = false,
  onPressEmail = noop,
  onPressFAQ = noop,
  onPressLiveChat = noop,
  onPressPhoneCall = noop,
  onPressScheduleCall = noop,
  showLiveChat,
  showScheduleCall = true,
  hideCustomerPhoneNumber,
  careNavigatorHeadshot,
  careNavigatorLicense,
  careNavigatorName,
  showMinimalCTAText = false,
}) => {
  //  As of PROSPECT-3119 and PROSPECT-3144, the preferred order for the Care Navigator Options are as follows:
  //  International: Phone Call, Live Chat, Email
  //  US: Email, Schedule Call, Phone Call
  //  Lyrians: Email, FAQ
  //  US & Sidepanel: Email, Live Chat, Phone Call, Schedule Call
  //  As of CN 3.0, Live Chat component should be included to global navigation, search for care and homebase entrypoints

  const { isDedicatedCareNavigatorEnabled } = useContext(AppContext)

  //PROVREC-4307 Hide "schedule a call" feature on all CN entrypoints except Homebase and Welcome Email.
  const tempHideCareNavigatorScheduleCallFn = true
  // If isDedicatedCareNavigatorEnabled is on and if user is not the homebase page, show new CN section
  const shouldShowNewOrOldCareNavigatorSection = isDedicatedCareNavigatorEnabled
    ? Boolean(careNavigatorHeadshot) && Boolean(careNavigatorName) && !isHomebaseFlow && showScheduleCall
    : showScheduleCall
  const OutsideUSBasedNavigatorOptions = [
    {
      name: 'PhoneCallOptionCard',
      component: <CareNavigatorPhoneCallOptionCard onPress={onPressPhoneCall} isInternational />,
    },
    showLiveChat
      ? {
          name: 'LiveChatOptionCard',
          component: <CareNavigatorLiveChatOptionCard onPress={onPressLiveChat} isInternational />,
        }
      : null,
    { name: 'EmailOptionCard', component: <CareNavigatorEmailOptionCard onPress={onPressEmail} isInternational /> },
  ]

  const USBasedNavigatorOptions = [
    {
      name: 'PhoneCallOptionCard',
      component: (
        <CareNavigatorPhoneCallOptionCard
          onPress={onPressPhoneCall}
          customerPhoneNumber={customerPhoneNumber}
          hideCustomerPhoneNumber={hideCustomerPhoneNumber}
        />
      ),
    },
    isSidePanel || isDedicatedCareNavigatorEnabled
      ? {
          name: 'LiveChatOptionCard',
          component: <CareNavigatorLiveChatOptionCard onPress={onPressLiveChat} />,
        }
      : null,
    {
      name: 'EmailOptionCard',
      component: <CareNavigatorEmailOptionCard onPress={onPressEmail} />,
    },
    !tempHideCareNavigatorScheduleCallFn && shouldShowNewOrOldCareNavigatorSection
      ? {
          name: 'ScheduleCallOptionCard',
          component: (
            <CareNavigatorScheduleCallOptionCard
              onPress={onPressScheduleCall}
              careNavigatorHeadshot={careNavigatorHeadshot}
              careNavigatorLicense={careNavigatorLicense}
              careNavigatorName={careNavigatorName}
              showMinimalCTAText={showMinimalCTAText}
            />
          ),
        }
      : null,
  ]

  const LyriansNavigatorOptions = [
    {
      name: 'EmailOptionCard',
      component: <CareNavigatorEmailOptionCard onPress={onPressEmail} isLyrians />,
    },
    {
      name: 'FAQOptionCard',
      component: <CareNavigatorFAQOptionCard onPress={onPressFAQ} />,
    },
  ]

  const NavigatorOptions = () => {
    if (isLyrians) {
      return LyriansNavigatorOptions
    } else if (isInternational) {
      return OutsideUSBasedNavigatorOptions
    } else {
      return USBasedNavigatorOptions
    }
  }
  const NavigatorOptionsRendered = NavigatorOptions().map(
    (optionCard) =>
      optionCard && (
        <OptionChoiceContainer isDedicatedCareNavigatorEnabled={isDedicatedCareNavigatorEnabled} key={optionCard.name}>
          {optionCard.component}
        </OptionChoiceContainer>
      ),
  )

  return <OptionContainer>{NavigatorOptionsRendered}</OptionContainer>
}

import { IntlShape } from 'react-intl'
import { Share } from 'react-native'

import { REFERRAL_EVENTS } from '@lyrahealth-inc/shared-app-logic'
import { BodyTextSize, ToastContent } from '@lyrahealth-inc/ui-core-crossplatform'

import { TrackEventWithObjAction } from '../../data/mixpanel/types'

const UTM_CAMPAIGN = 'referral'

export type UTMSource =
  | 'login-page'
  | 'hamburger-menu'
  | 'pathways-microsite-banner'
  | 'coming-soon-microsite-banner'
  | 'welcome-email'
type UTMMedium = 'product-mobile' | 'product-desktop' | 'email-mobile' | 'email-desktop'

const getReferralUrl = (source: UTMSource, medium: UTMMedium) =>
  `${window.location.origin}/register/connect?utm_source=${source}&utm_medium=${medium}&utm_campaign=${UTM_CAMPAIGN}`

const getComingSoonReferralUrl = (source: UTMSource, medium: UTMMedium) =>
  `${window.location.origin}?utm_source=${source}&utm_medium=${medium}&utm_campaign=${UTM_CAMPAIGN}`

interface ShareReferralArgs {
  source: UTMSource
  formatMessage: IntlShape['formatMessage']
  trackEventWithObj: TrackEventWithObjAction
  setToastContent: (content: ToastContent) => void
  isMobileSized: boolean
  entryPoint: string
}

export const shareReferral = async (args: ShareReferralArgs) => {
  const { entryPoint, formatMessage, source, trackEventWithObj, setToastContent, isMobileSized } = args
  const medium =
    source === 'welcome-email'
      ? isMobileSized
        ? 'email-mobile'
        : 'email-desktop'
      : isMobileSized
      ? 'product-mobile'
      : 'product-desktop'

  const extraEventObj = { entrypoint: entryPoint, utm_source: source, utm_medium: medium, utm_campaign: UTM_CAMPAIGN }

  trackEventWithObj({ event: REFERRAL_EVENTS.CLICK_REFERRAL_CTA, ...extraEventObj })

  const referralUrl =
    source === 'coming-soon-microsite-banner'
      ? getComingSoonReferralUrl(source, medium)
      : getReferralUrl(source, medium)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralUrl)
    setToastContent({
      text: formatMessage({
        defaultMessage: 'Link copied! Share with family members, dependents or colleagues.',
        description: 'Text displayed when user copied referral link',
      }),
      toastType: 'success',
      id: 'Referral-toast',
      textSize: BodyTextSize.SMALL,
    })
  }

  if (isMobileSized) {
    try {
      await Share.share(
        {
          message: formatMessage({
            defaultMessage:
              'Lyra Health is a benefit that connects you to mental health professionals, self-care tools, and more. Use this personalized link to create your account today.',
            description: 'Body message when sharing with referral link on mobile device',
          }),
          url: referralUrl,
          title: 'Lyra Health',
        },
        { dialogTitle: 'Lyra Health', subject: 'Lyra Health' },
      )
      // Log successful sharing
      trackEventWithObj({ event: REFERRAL_EVENTS.SHARE_REFERRAL_LINK, ...extraEventObj })
    } catch (error: any) {
      // Log when sharing is dismissed
      if (error?.name === 'AbortError') {
        trackEventWithObj({ event: REFERRAL_EVENTS.SHARE_REFERRAL_LINK_CANCELLED, ...extraEventObj })
      } else {
        // If share menu doesn't open in web mobile, log the error and fallback
        // to copy to clipboard functionality
        console.error('Failed to open share menu on web mobile', error)
        copyToClipboard()
      }
    }
  } else {
    copyToClipboard()
  }
}

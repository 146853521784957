import React from 'react'
import { Outlet } from 'react-router-dom'

import ErrorBoundaryWrapper from './ErrorBoundaryWrapper'

const RouterErrorBoundaryWrapper = () => {
  return (
    <ErrorBoundaryWrapper>
      <Outlet />
    </ErrorBoundaryWrapper>
  )
}

export default RouterErrorBoundaryWrapper

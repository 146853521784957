import React, { FunctionComponent } from 'react'

import LottieView from 'lottie-react-native'
import styled from 'styled-components/native'

import { RibbonHeart } from '../../assets'

const Container = styled.View<{ size: number | string }>(({ size }) => ({
  height: `${size}px`,
  width: `${size}px`,
}))

export interface RibbonHeartAnimationProps {
  size?: string | number
  animationRef?: React.RefObject<LottieView>
}

export const RibbonHeartAnimation: FunctionComponent<RibbonHeartAnimationProps> = ({
  size = 24,
  animationRef = null,
}) => {
  // If storybook, dont run animation to avoid test flakiness during diff comparisons
  const isStorybook = !!(window as any).__STORYBOOK_PREVIEW__

  // https://github.com/airbnb/lottie-web/issues/1702
  // Create a new "instance" of the RibbonHeart Animation each time we render. Loading the same
  // animation object each time will cause nested repeaters and leads to web pages crashing due
  // to memory spikes
  const RibbonHeartData = JSON.parse(JSON.stringify(RibbonHeart))
  return (
    <Container size={size}>
      <LottieView
        loop={false}
        ref={animationRef}
        style={{ flex: 1 }}
        source={RibbonHeartData}
        autoPlay={!isStorybook}
      />
    </Container>
  )
}

import { Map } from 'immutable'
import { createSelector } from 'reselect'

export const getAuthState = (state: Map<string, any>) => state?.get('auth')

export const getTimeInactivityWarning = createSelector(getAuthState, (authState) =>
  authState?.get('timeInactivityWarning'),
)

export const getTimeLastActive = createSelector(getAuthState, (authState) => authState?.get('timeLastActive'))

import { fromJS } from 'immutable'
import { get } from 'lodash-es'
import { createReducer } from 'redux-immutablejs'

import { SET_PATIENT_DATA } from '../../features/healthPlan/data/healthPlanActions'

const $$initialState = fromJS({
  sessionUsage: [],
})

const minorsDataReducer = ($$state: $TSFixMe, action: $TSFixMe) => {
  const sessionUsageInfo = get(action.data, 'minorsSessionUsageInfo', [])
  $$state = $$state.set('sessionUsage', fromJS(sessionUsageInfo))
  return $$state
}

export default createReducer($$initialState, {
  [SET_PATIENT_DATA]: minorsDataReducer,
})

import { useMemo } from 'react'

import { useAddCustomerTreatmentOptions } from './useAddCustomerTreatmentOptions'
import { CustomerTreatmentsOptionLocation, TreatmentRecommendations } from '../../../models'
import {
  allOptionsEligibleTreatmentOptions,
  TREATMENT_OPTIONS,
  TREATMENT_OPTIONS_TYPE,
} from '../../common/constants/treatmentOptions'

/**
 * Get treatment options for the "see all" options screen in s4c
 */
export const useGetAllTreatmentOptions = (
  searcherAge: number,
  treatmentRecs: TreatmentRecommendations | null,
  isUserInternational?: boolean,
  customerCopy?: { [key: string]: any },
) => {
  const addCustomerTreatmentOptions = useAddCustomerTreatmentOptions()

  const result = useMemo(() => {
    const configForSpecificTreatmentOption: { [key in TREATMENT_OPTIONS_TYPE]?: any } = {}

    const eligibleTreatmentOptions =
      treatmentRecs?.eligibleTreatmentOptions.filter(
        (option) => allOptionsEligibleTreatmentOptions.includes(option) && option !== TREATMENT_OPTIONS.SELF_CARE_APPS,
      ) || []

    // Do not show customer specifix treatment options for international users
    const treatmentOptionsToDisplay = isUserInternational
      ? eligibleTreatmentOptions
      : addCustomerTreatmentOptions(
          searcherAge,
          'seeMore' as CustomerTreatmentsOptionLocation,
          eligibleTreatmentOptions,
          configForSpecificTreatmentOption,
          customerCopy,
        ).treatmentOptions

    return {
      treatmentOptions: treatmentOptionsToDisplay,
      configForSpecificTreatmentOption,
    }
  }, [
    searcherAge,
    addCustomerTreatmentOptions,
    customerCopy,
    isUserInternational,
    treatmentRecs?.eligibleTreatmentOptions,
  ])

  return result
}

import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Progress Icon currently used on the bottom nav
 */
export const ProgressIcon: FunctionComponent<ProgressIconProps> = ({ size = 24, fillColor, isFilled }) => {
  const { colors } = useTheme()
  const { progressIcon } = colors.icons

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      {isFilled ? (
        <>
          <Path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M3 2.25C3.41421 2.25 3.75 2.58579 3.75 3V20.25H21C21.4142 20.25 21.75 20.5858 21.75 21C21.75 21.4142 21.4142 21.75 21 21.75H3C2.58579 21.75 2.25 21.4142 2.25 21V3C2.25 2.58579 2.58579 2.25 3 2.25Z'
            fill={colors.iconActive}
          />
          <Path
            d='M12 8C8.26785 8 8.14286 14 3 14V21H21V15C21 14.4477 20.547 14.0116 20.0027 13.918C15.8226 13.1993 15.4742 8 12 8Z'
            fill={colors.iconActive}
          />
          <Path
            d='M3 14V13.25H2.25V14H3ZM20.0027 13.918L19.8756 14.6571L20.0027 13.918ZM21 21V21.75C21.4142 21.75 21.75 21.4142 21.75 21H21ZM3 21H2.25C2.25 21.4142 2.58579 21.75 3 21.75V21ZM3 14.75C5.9522 14.75 7.4655 12.9801 8.62602 11.454C9.22595 10.665 9.69603 9.99481 10.2478 9.49171C10.7661 9.01911 11.3013 8.75 12 8.75V7.25C10.8326 7.25 9.95264 7.73089 9.23714 8.38329C8.55509 9.00519 7.97271 9.83498 7.43201 10.546C6.31128 12.0199 5.19066 13.25 3 13.25V14.75ZM12 8.75C13.2989 8.75 14.0354 9.67341 15.137 11.1399C16.1641 12.5073 17.4568 14.2412 19.8756 14.6571L20.1298 13.1788C18.3686 12.876 17.397 11.651 16.3363 10.239C15.3502 8.92622 14.1753 7.25 12 7.25V8.75ZM20.25 15V21H21.75V15H20.25ZM21 20.25H3V21.75H21V20.25ZM3.75 21V14H2.25V21H3.75ZM19.8756 14.6571C19.9939 14.6775 20.0962 14.735 20.1631 14.805C20.2267 14.8715 20.25 14.9382 20.25 15H21.75C21.75 14.0042 20.9508 13.32 20.1298 13.1788L19.8756 14.6571Z'
            fill={colors.iconActive}
          />
        </>
      ) : (
        <>
          <Path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M3 2.25C3.41421 2.25 3.75 2.58579 3.75 3V20.25H21C21.4142 20.25 21.75 20.5858 21.75 21C21.75 21.4142 21.4142 21.75 21 21.75H3C2.58579 21.75 2.25 21.4142 2.25 21V3C2.25 2.58579 2.58579 2.25 3 2.25Z'
            fill={fillColor || progressIcon.fill}
          />
          <Path
            d='M3 14C8.14286 14 8.26785 8 12 8C15.7321 8 15.8571 14 21 14'
            stroke={fillColor || progressIcon.fill}
            stroke-width='1.5'
            stroke-linecap='round'
          />
        </>
      )}
    </Svg>
  )
}

type ProgressIconProps = {
  size?: number | string
  fillColor?: string
  isFilled?: boolean
}

import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export const StarIcon = ({ height = 24, width = 24, fillColor, ...rest }: StarIconProps & SvgProps) => {
  const { colors } = useTheme()
  return (
    <Svg width={width} height={height} fill='none' testID={tID('StarIcon')} {...rest}>
      <Path
        d='M10.91 21.83c-.41 0-.75-.34-.75-.75 0-3.48-2.94-6.41-6.41-6.41-.41 0-.75-.34-.75-.75s.34-.75.75-.75c3.48 0 6.41-2.94 6.41-6.41 0-.41.34-.75.75-.75s.75.34.75.75c0 3.48 2.94 6.41 6.41 6.41.41 0 .75.34.75.75s-.34.75-.75.75c-3.48 0-6.41 2.94-6.41 6.41 0 .41-.34.75-.75.75Zm-3.86-7.91c1.68.8 3.06 2.18 3.86 3.86.8-1.68 2.18-3.06 3.86-3.86a8.215 8.215 0 0 1-3.86-3.86 8.215 8.215 0 0 1-3.86 3.86ZM18.082 10.5c-.41 0-.75-.34-.75-.75 0-1.2-1.05-2.25-2.25-2.25-.41 0-.75-.34-.75-.75s.34-.75.75-.75c1.2 0 2.25-1.05 2.25-2.25 0-.41.34-.75.75-.75s.75.34.75.75c0 1.2 1.05 2.25 2.25 2.25.41 0 .75.34.75.75s-.34.75-.75.75c-1.2 0-2.25 1.05-2.25 2.25 0 .41-.34.75-.75.75Zm-.78-3.75c.3.22.56.49.78.78.22-.3.49-.56.78-.78-.3-.22-.56-.49-.78-.78-.22.3-.49.56-.78.78Z'
        fill={fillColor || colors.iconInactive}
      />
    </Svg>
  )
}

export type StarIconProps = {
  height?: number
  width?: number
  fillColor?: string
}

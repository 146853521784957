import { matchPath } from 'react-router-dom'

/** List of onboarding routes that lead to the provider profile page */
const providerProfileUrls = [
  '/secure/onboard/provider/',
  '/secure/onboard/t1-provider/',
  '/secure/onboard/coaching/coach/',
  '/secure/index/provider/',
]

/**
 * Determines whether the current user is in the provider profile page
 */
function useProviderProfilePage(pagePath: string) {
  let isProviderProfilePage = false

  for (const url of providerProfileUrls) {
    // @ts-expect-error TS(2345): Argument of type '{ path: `${string}:id`; exact: b... Remove this comment to see the full error message
    const match = matchPath({ path: `${url}:id`, exact: true }, pagePath)
    if (match) {
      isProviderProfilePage = true
      return isProviderProfilePage
    }
  }

  return isProviderProfilePage
}

export default useProviderProfilePage

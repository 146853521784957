import React from 'react'
import { EXCLUDED_SERVICES, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'

export const mcd: GetCustomerCopyFunc = ({ numberOfSessions, considerMeds }) => {
  const excludedServices = EXCLUDED_SERVICES({ considerMeds })
  const servicesNotCovered = `Services that are not covered include: ${excludedServices}. Please check your benefit plan documents for a list of covered services and exclusions.`
  return {
    servicesNotCovered,
    legalNotice: <CoverageNotice considerMeds={considerMeds} sessionLimit={numberOfSessions} />,
  }
}

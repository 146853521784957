import { CustomerCopy, TREATMENT_OPTIONS, TREATMENT_RECOMMENDATION_TIER } from '@lyrahealth-inc/shared-app-logic'
import React from 'react'
import { BodyText } from '../atoms'

export const bswqa: () => CustomerCopy = () => {
  const healthAccessConfiguration = {
    title: 'Baylor Scott & White Health Access',
    description: (
      <BodyText
        text={
          // eslint-disable-next-line formatjs/no-literal-string-in-jsx
          'Members enrolled in Baylor Scott and White’s Employee Plan can also connect with the Health Access team to connect with a primary care provider for their behavioral health needs.\n\n844.279.7589, 7:00A-9P 7 days a week'
        }
      />
    ),
    buttonText: 'Connect with BSWQA Health Access',
    url: 'https://www.bswhealth.com/qualityalliance/',
  }

  return {
    treatmentOptions: [
      {
        option: TREATMENT_OPTIONS.ONSITE_THERAPY,
        config: { customCopy: healthAccessConfiguration },
        optionRanking: { [TREATMENT_RECOMMENDATION_TIER.TIER_2]: 2, [TREATMENT_RECOMMENDATION_TIER.TIER_3]: 1 },
      },
    ],
  }
}

import { defineMessages } from 'react-intl'

export const VALUE_PROPS_TITLES = defineMessages({
  mentalHealthSessions: {
    defaultMessage: '1-on-1 mental health sessions for you and your family',
    description: 'Value prop item, under a header like "What you get with Lyra:"',
  },
  meditationsCoursesArticles: {
    defaultMessage: 'Meditations, courses, articles, and more wellness resources',
    description: 'Value prop item, under a header like "What you get with Lyra:"',
  },
  dedicatedSupport: {
    defaultMessage: 'Dedicated support, anytime day or night',
    description: 'Value prop item, under a header like "What you get with Lyra:"',
  },
  providersIntelligentlyMatched: {
    defaultMessage: 'Providers intelligently matched to your needs',
    description: 'Value prop item, under a header like "What you get with Lyra:"',
  },
})

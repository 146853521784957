import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { AnyAction, Dispatch } from 'redux'

import { Link, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import * as ModalActions from '../../../features/page-components/modals/generic/modal-actions'
import VideoModal from '../modals/videoModal/VideoModal'

type VideoLinkProps = {
  linkText: string
  videoUrl: string
  description: string
  dispatch: Dispatch<AnyAction>
}

const VideoLink: FunctionComponent<VideoLinkProps> = ({
  linkText = 'this video',
  videoUrl = 'https://player.vimeo.com/video/196029128',
  description = 'What Is Therapy?',
  dispatch,
}) => {
  return (
    <Link
      testID={tID('VideoLink')}
      onPress={() => {
        dispatch(ModalActions.setModalContent(<VideoModal url={videoUrl} description={description} />))
        dispatch(
          ModalActions.setModalProps({
            showCloseButton: true,
            modalClass: 'videoModal',
          }),
        )
      }}
      text={linkText}
      underline
    />
  )
}

export default connect()(VideoLink)

import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { CheckIcon } from '../../atoms/icons/CheckIcon'
import { CheckMarkIcon } from '../../atoms/icons/CheckMarkIcon'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { BodyTextSize } from '../../styles/typeStyles'

export type CheckIconListItemProps = {
  subtitle?: React.ReactNode
  body?: React.ReactNode
  checkmarkColor?: string
  textContainerSpacing?: number
  textSpacing?: number
  wrapperSpacing?: number
  bodyTextSize?: BodyTextSize
  level?: string
  iconSize?: number
  checkIconType?: CheckIconType // for distinguishing which of our check icons to use. Defauls to CheckIcon
}

export enum CheckIconType {
  CIRCLE_OUTLINE = 'CIRCLE_OUTLINE',
  NO_OUTLINE = 'NO_OUTLINE',
}

const ContentContainer = styled.View(({ wrapperSpacing }: { wrapperSpacing: number }) => {
  return { flexDirection: 'row' as const, marginBottom: `${wrapperSpacing}px` }
})

const CheckIconContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  width: theme.spacing['24px'],
  height: theme.spacing['24px'],
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 4,
}))

const TextContainer = styled.View(({ textContainerSpacing }: { textContainerSpacing: number }) => ({
  flex: 1,
  marginBottom: `${textContainerSpacing}px`,
}))

const SubHeadContainer = styled.View(({ textSpacing }: { textSpacing: number }) => {
  return { marginBottom: `${textSpacing}px` }
})

export const CheckIconListItem: FunctionComponent<CheckIconListItemProps> = ({
  subtitle,
  body,
  wrapperSpacing = 0,
  textContainerSpacing = 25,
  textSpacing = 8,
  checkmarkColor,
  bodyTextSize = BodyTextSize.SMALL,
  level,
  iconSize = 20,
  checkIconType = CheckIconType.CIRCLE_OUTLINE,
}) => {
  const CheckIconElement = checkIconType === CheckIconType.CIRCLE_OUTLINE ? CheckIcon : CheckMarkIcon
  const { colors } = useTheme()
  const { checkmark } = colors.components.checkIconListItem

  return (
    <ContentContainer wrapperSpacing={wrapperSpacing}>
      <CheckIconContainer>
        <CheckIconElement
          size={iconSize}
          fillColor={checkmarkColor || checkmark.fill}
          stroke={checkmarkColor || checkmark.fill}
        />
      </CheckIconContainer>
      <TextContainer textContainerSpacing={textContainerSpacing}>
        {!!subtitle && (
          <SubHeadContainer textSpacing={textSpacing}>
            <Subhead text={subtitle} size={SubheadSize.XSMALL} color={colors.textPrimary} level={level} />
          </SubHeadContainer>
        )}
        {!!body && <BodyText text={body} size={bodyTextSize} color={colors.textSecondary} />}
      </TextContainer>
    </ContentContainer>
  )
}

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'
import { defineMessage } from 'react-intl'

export const ivp: GetCustomerCopyFunc = () => {
  return {
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage: 'Are you an IVP employee, or a CEO or Founder of an active IVP portfolio company?',
        description: 'Asking if the user has certain role in the company IVP',
      }),
    dependentButtonText: defineMessage({
      defaultMessage: 'No, I am an IVP employee’s dependent',
      description: 'Declaring the current user is a dependent of an eligible user',
    }),
  }
}

import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { TREATMENT_OPTIONS_FROM_FRONT_END } from '@lyrahealth-inc/shared-app-logic'

import { Link, PressableOpacity, XIcon } from '../../atoms'
import { Modal } from '../../organisms/modal/Modal'
import { MedsTreatmentCard } from '../../organisms/treatmentCard/MedsTreatmentCard'
import { BodyTextSize } from '../../styles/typeStyles'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface MedsConsultModalProps {
  isDirectAccessMedsSupported: boolean
  triggerTextSize?: BodyTextSize
  onLearnMorePress: () => void
  onBookConsultationPress: () => void
}
const StyledModal = styled(Modal)<{ theme: ThemeType }>(({ theme }) => ({
  ...(!theme.breakpoints.isMobileSized && { width: '624px' }),
}))

const CardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(!theme.breakpoints.isMobileSized && { padding: `${theme.spacing['0px']} ${theme.spacing['12px']}` }),
}))

const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMobileSized && {
    padding: `${theme.spacing['0px']} ${theme.spacing['16px']} ${theme.spacing['8px']}`,
  }),
}))

const CloseButton = styled(PressableOpacity)({
  alignItems: 'flex-end',
})

export const MedsConsultModal: FunctionComponent<MedsConsultModalProps> = ({
  isDirectAccessMedsSupported,
  triggerTextSize = BodyTextSize.SMALL,
  onLearnMorePress,
  onBookConsultationPress,
}) => {
  const testID = 'MedsConsultModal'
  const [isModalOpen, setModalState] = useState(false)
  const { breakpoints } = useTheme()
  const { isMobileSized } = breakpoints
  const closeModal = () => {
    setModalState(false)
  }
  return (
    <View testID={tID(testID)}>
      <Link
        size={triggerTextSize}
        testID={tID(`${testID}-trigger`)}
        text={
          <FormattedMessage
            defaultMessage='More details'
            description='Link that opens modal with information about medication consultation'
          />
        }
        underline
        onPress={() => setModalState(true)}
      />
      <StyledModal
        visible={isModalOpen}
        modalContents={
          <ContentContainer testID={tID(`${testID}-content`)}>
            {!isMobileSized && (
              <CloseButton onPress={closeModal}>
                <XIcon size={24} />
              </CloseButton>
            )}
            <CardContainer>
              <MedsTreatmentCard
                name={TREATMENT_OPTIONS_FROM_FRONT_END.MEDICATION_CONSULTATION}
                onButtonPress={() => {
                  closeModal()
                  onBookConsultationPress()
                }}
                onLearnMorePress={() => {
                  closeModal()
                  onLearnMorePress()
                }}
                isDirectAccessMedsSupported={isDirectAccessMedsSupported}
                showExpandableContent={false}
                showFootnote={false}
                truncateDescription={isMobileSized}
                smallCardView
                buttonRightSide
                showModalView
              />
            </CardContainer>
          </ContentContainer>
        }
        scrollable={isMobileSized}
        onRequestClose={closeModal}
        onCloseEnd={closeModal}
        showCloseIcon
        disableBottomSheet
        enableTopOffset
      />
    </View>
  )
}

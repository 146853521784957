import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { COMMON_MIXPANEL_PAGE, createPageViewEventName, PAGES } from '@lyrahealth-inc/shared-app-logic'

import { trackEventWithObj } from '../../data/mixpanel'
import { useAppDispatch } from '../../data/storeConfiguration/store'

export function useTrackViewPage(
  page: PAGES,
  properties?: { [key: string]: any },
  entryPoint?: {
    entryPoint?: COMMON_MIXPANEL_PAGE
  } | null,
  skipAppendPageToEventName?: boolean,
) {
  const dispatch = useAppDispatch()
  const [pageTracked, setPageTracked] = useState('')
  const { search } = useLocation()

  useEffect(() => {
    if (entryPoint === null) {
      // Block until entry point is ready
      return
    }
    const track = () => {
      dispatch(
        trackEventWithObj({
          event: createPageViewEventName(page, skipAppendPageToEventName),
          ...entryPoint,
          ...properties,
        }),
      )
    }
    if (page !== pageTracked && !search.includes('utm_medium=tbd')) {
      setPageTracked(page)
      track()
    }
  }, [dispatch, page, properties, pageTracked, search, entryPoint, skipAppendPageToEventName])
}

import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export const PlusIcon = ({ height = 24, width = 24, fillColor, ...rest }: PlusIconProps & SvgProps) => {
  const { colors } = useTheme()
  const fill = fillColor || colors.icons.plusIcon.fill
  return (
    <Svg width={width} height={height} viewBox='0 0 24 25' fill='none' testID={tID('PlusIconIcon')} {...rest}>
      <Path d='M5 12.2036H19' stroke={fill} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <Path d='M12 19.2036L12 5.20361' stroke={fill} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </Svg>
  )
}

export type PlusIconProps = {
  height?: number
  width?: number
  fillColor?: string
}

import React, { FunctionComponent, ReactElement, ReactNode } from 'react'
import { Platform, View } from 'react-native'

import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { NavBarProps } from '@lyrahealth-inc/shared-app-logic'

import { useKeyboardVisible } from '../../hooks'
import { FixedBottomButton } from '../../molecules/fixedBottomButton/FixedBottomButton'
import { NavBar } from '../../molecules/navBar/NavBar'
import { ContentContainer as ContentContainerComponent } from '../../templates/content/ContentContainer'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'
import { StickyView } from '../stickyView/StickyView'

const ContentContainer = styled(ContentContainerComponent)<{ theme: ThemeType; backgroundColor?: string }>(
  ({ theme, backgroundColor }) => ({
    backgroundColor: backgroundColor ?? theme.colors.backgroundPrimary,
    padding: '0px',
  }),
)

const Content = styled.View<{ theme: ThemeType; customStyle?: CSSObject; overlayHeader?: boolean }>(
  ({ theme, customStyle, overlayHeader }) => ({
    maxWidth: '624px',
    marginHorizontal: 'auto',
    ...(!overlayHeader && {
      marginTop: theme.breakpoints.isMobileSized ? theme.spacing['24px'] : theme.spacing['32px'],
    }),
    ...customStyle,
  }),
)

const NavContainer = styled.View({
  width: '100%',
})

const Container = styled(View)({
  zIndex: 1,
})

export interface FixedButtonFormPageTemplateProps {
  children: ReactElement
  onBackPress?: () => void
  onExitPress?: () => void
  onSubmitPress: () => void
  buttonText: ReactNode
  isNavTransparent?: boolean
  isButtonTransparent?: boolean
  testID?: string
  loading?: boolean
  showBackButton?: boolean
  navBarProps: NavBarProps
  hideHeaderNav?: boolean
  hasFloatingFooter?: boolean
  /** Place button inline in the body and not fixed at the bottom (mobile) */
  inlineButton?: boolean
  secondaryButton?: ReactNode
  customStyle?: CSSObject
  buttonContainerCustomStyle?: CSSObject
  sticky?: boolean
  stickyViewBackgroundColor?: string
  buttonChildren?: ReactNode
  disabled?: boolean
  overlayHeader?: boolean
  ignoreTopInset?: boolean
}

export const FixedButtonFormPageTemplate: FunctionComponent<FixedButtonFormPageTemplateProps> = ({
  children,
  onSubmitPress,
  buttonText,
  isButtonTransparent = false,
  testID = '',
  loading,
  navBarProps,
  hideHeaderNav = false,
  hasFloatingFooter = false,
  inlineButton = false,
  secondaryButton,
  customStyle,
  buttonContainerCustomStyle,
  sticky,
  stickyViewBackgroundColor,
  buttonChildren,
  disabled,
  overlayHeader = false,
  ignoreTopInset,
}) => {
  /**
   * Checks if the keyboard is visible and hides the submit button, similar to the logic
   * done in FormBody.tsx to avoid the button from covering the input field
   */
  const keyboardVisible = useKeyboardVisible()
  const shouldHideSubmitButton = keyboardVisible && Platform.OS === 'android'

  const { breakpoints, colors } = useTheme()
  const SubmitButton = !shouldHideSubmitButton ? (
    <FixedBottomButton
      text={buttonText}
      onPress={onSubmitPress}
      testID={tID(`${testID}-submit`)}
      loading={loading}
      isTransparent={isButtonTransparent}
      secondaryButton={secondaryButton}
      customContainerStyle={buttonContainerCustomStyle}
      backgroundColor={stickyViewBackgroundColor}
      disabled={disabled}
    >
      {buttonChildren}
    </FixedBottomButton>
  ) : null
  const shouldShowFixedButton = breakpoints.isMobileSized && !inlineButton

  return (
    <Container testID={testID}>
      <StickyView
        sticky={sticky !== undefined ? sticky : breakpoints.isMobileSized}
        footerComponent={shouldShowFixedButton && SubmitButton}
        headerComponent={
          !hideHeaderNav && (
            <NavContainer>
              <NavBar {...navBarProps} backgroundColor={stickyViewBackgroundColor} />
            </NavContainer>
          )
        }
        hasFloatingFooter={hasFloatingFooter}
        viewBackgroundColor={stickyViewBackgroundColor}
        headerBackgroundColor={overlayHeader ? colors.backgroundTransparent : undefined}
        overlayHeader={overlayHeader}
        ignoreTopInset={ignoreTopInset}
      >
        <ContentContainer backgroundColor={stickyViewBackgroundColor}>
          <Content customStyle={customStyle} overlayHeader={overlayHeader}>
            {children}
            {!shouldShowFixedButton && SubmitButton}
          </Content>
        </ContentContainer>
      </StickyView>
    </Container>
  )
}

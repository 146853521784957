import { EmailCaptureExperimentJoinKeys } from './emailCapture/emailCaptureExperimentTypes'
import { SearchForCareHomebaseHeaderExperimentJoinKeys } from './searchForCareExperimentTypes'

export enum ExperimentUUIDs {
  LatestMatchesUUID = '65658a7b-e7c2-4fbf-923f-5640ffe20954',
  SearchForCareHomebaseHeaderUUID = '7491d7ac-742d-4687-89ae-3f338fd27eba',
  TriageSummaryUUID = 'd03d7916-6f2e-4bdc-b7db-d6fba47f3a43',
}

export const LatestMatchesArmMapping = {
  CAN_SEE: 'can see past provider result',
  CANNOT_SEE: 'cannot see past provider result',
}

interface LatestMatchesExperimentJoinKeys {
  client_lyra_id: string
}

type JoinKeys =
  | LatestMatchesExperimentJoinKeys
  | SearchForCareHomebaseHeaderExperimentJoinKeys
  | EmailCaptureExperimentJoinKeys

interface Assignment {
  arm: string
  joinKeys: JoinKeys
}

interface Experiment {
  assignment: Assignment[]
}

interface Experiments {
  [key: string]: Experiment
}

export interface ExperimentPayload {
  experimentUUID: string
  deviceUUID: string
  experiments: Experiments
}

import React from 'react'
import { defineMessage } from 'react-intl'

import { fromJS } from 'immutable'

import { CoverageNotice } from '../../components/footer/CoverageNotice'
import { DEFAULT_QUESTIONS } from '../faqConstants'

/* eslint-disable formatjs/no-literal-string-in-jsx */

export const organon = ({ numberOfSessions }: $TSFixMe) => {
  return fromJS({
    faqQuestions: [
      //KEEP
      {
        question: DEFAULT_QUESTIONS.HOW_MUCH_DOES_LYRA_COST,
        answer: (
          <h3>
            Lyra’s providers are all in-network with Horizon BCBS. These sessions are billed through the medical plan
            and subject to in-network co-insurance and deductibles (for details on costs, go to
            <a href='http://www.horizonblue.com/organon' target='_blank' rel='noreferrer'>
              www.horizonblue.com/organon
            </a>
            {` or Hera > HR > Lyra Health).`}
          </h3>
        ),
      },
      {
        question: DEFAULT_QUESTIONS.WHAT_DOES_LYRA_NOT_COVER,
        answer: (
          <h3>
            {`Services that are not covered include: inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, 
              emergent care, autism spectrum disorder treatment, services for remedial education, mental health coaching, executive 
              coaching, and non-evidence-based behavioral health care. Please check with your medical plan or 
              benefits portal for coverage of these excluded services.`}
            <br />
            <br />
            If you have questions about or need these services please contact your Horizon Health Guide at
            1-800-544-1112 for more information.
          </h3>
        ),
      },
    ],
    /* eslint-enable formatjs/no-literal-string-in-jsx */

    legalNotice: (
      <CoverageNotice
        excludedServices={defineMessage({
          defaultMessage: `Lyra does not cover inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, 
            emergent care, autism spectrum disorder treatment, services for remedial education, mental health coaching, executive 
            coaching, and non-evidence-based behavioral health care.`,
          description:
            'Text for describing the services that Lyra does not cover, displayed as part of the coverage notice',
        })}
        sessionLimit={numberOfSessions}
      />
    ),
  })
}

import React, { useEffect } from 'react'
import Animated, { Easing, useAnimatedProps, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated'
import { Circle, CircleProps, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { ThemeType, tID } from '../../utils'

export type CircleBadgeProps = {
  diameter?: number
  animate?: boolean
  animateDiameter?: number
}

const innerStrokeWidth = 1.5

export const CircleBadge: React.FC<CircleBadgeProps> = ({ diameter = 12, animateDiameter = 14, animate = false }) => {
  const { colors } = useTheme() as ThemeType
  const startRadius = diameter / 2 - innerStrokeWidth
  const endRadius = animateDiameter / 2 - innerStrokeWidth
  const circleProps: CircleProps = {
    cx: '50%',
    cy: '50%',
    r: startRadius,
    fill: colors.progressIndicator,
    stroke: 'white',
    strokeWidth: innerStrokeWidth,
  }
  const testID = tID('CircleBadgeIcon')
  const size = Math.max(diameter, animateDiameter)
  return animate ? (
    <AnimatedCircleBadge
      size={size}
      circleProps={circleProps}
      startRadius={startRadius}
      endRadius={endRadius}
      testID={testID}
    />
  ) : (
    <Svg width={size} height={size} testID={testID}>
      <Circle {...circleProps} />
    </Svg>
  )
}

const AnimatedCircle = Animated.createAnimatedComponent(Circle)

type AnimatedCircleBadgeProps = {
  size: number
  startRadius: number
  endRadius: number
  circleProps: CircleProps
  testID?: string
}

const AnimatedCircleBadge: React.FC<AnimatedCircleBadgeProps> = ({
  size,
  startRadius,
  endRadius,
  circleProps,
  testID,
}) => {
  const radiusSharedValue = useSharedValue(startRadius)

  useEffect(() => {
    radiusSharedValue.value = withRepeat(
      withTiming(endRadius, {
        duration: 800,
        easing: Easing.out(Easing.ease),
      }),
      -1,
      true,
    )
  }, [endRadius, radiusSharedValue])

  const animatedProps = useAnimatedProps(() => {
    return {
      r: radiusSharedValue.value,
    }
  })
  return (
    <Svg width={size} height={size} testID={testID}>
      <AnimatedCircle {...circleProps} animatedProps={animatedProps} />
    </Svg>
  )
}

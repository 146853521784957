import { CustomerSubpopulations, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'
import { defineMessage } from 'react-intl'

export const bluepearl: GetCustomerCopyFunc = () => {
  const subpopulations: CustomerSubpopulations = {
    header: defineMessage({
      defaultMessage: 'Which type of BluePearl member are you?',
      description: 'BluePearl subpopulation redirection header',
    }),
    options: [
      {
        label: defineMessage({
          defaultMessage: 'Associate',
          description: 'BluePearl Associate member label',
        }),
        description: defineMessage({
          defaultMessage: 'I work at BluePearl as a full or part time associate.',
          description: 'BluePearl Associate member description',
        }),
      },
      {
        label: defineMessage({
          defaultMessage: 'Dependent',
          description: 'BluePearl Dependent member label',
        }),
        description: defineMessage({
          defaultMessage:
            'I am not an associate of BluePearl. I am a spouse or dependent of a BluePearl associate and I am enrolled in a UnitedHealthcare Plan or I am an Associate of BluePearl seeking care for a dependent under 18 years old who is enrolled in a UnitedHealthcare Plan. I understand that I am NOT eligible for free sessions through Lyra.',
          description: 'BluePearl Dependent member description',
        }),
      },
    ],
  }
  return {
    subpopulations,
  }
}

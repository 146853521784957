import React from 'react'
import { Mask, Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

type EveningIconProps = {
  fillColor?: string
  size?: string | number
}

export const EveningIcon: React.FC<EveningIconProps> = ({ fillColor, size = 32 }) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} viewBox='0 0 32 32' fill='none'>
      <Mask id='path-1-outside-1_4995_14564' maskUnits='userSpaceOnUse' fill='black' x={3} y={3} width={23} height={25}>
        <Rect fill='white' x={3} y={3} width={23} height={25} />
        <Path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M23.8067 22.7074C22.9439 22.9414 22.0363 23.0662 21.0994 23.0662C15.3998 23.0662 10.7793 18.4457 10.7793 12.746C10.7793 9.66047 12.1334 6.89117 14.2799 5C9.03949 5.70926 5 10.2006 5 15.6354C5 21.5631 9.8053 26.3684 15.7329 26.3684C18.9517 26.3684 21.8395 24.9515 23.8067 22.7074Z'
        />
      </Mask>
      <Path
        d='M23.8067 22.7074L24.9347 23.6962C25.3707 23.1988 25.4294 22.4745 25.0793 21.9133C24.7291 21.3521 24.0526 21.0866 23.4142 21.2597L23.8067 22.7074ZM14.2799 5L15.2715 6.12547C15.7675 5.68844 15.9189 4.97822 15.6443 4.37688C15.3697 3.77555 14.7338 3.42489 14.0787 3.51355L14.2799 5ZM23.4142 21.2597C22.6782 21.4592 21.9025 21.5662 21.0994 21.5662V24.5662C22.17 24.5662 23.2097 24.4235 24.1992 24.1552L23.4142 21.2597ZM21.0994 21.5662C16.2282 21.5662 12.2793 17.6173 12.2793 12.746H9.2793C9.2793 19.2741 14.5714 24.5662 21.0994 24.5662V21.5662ZM12.2793 12.746C12.2793 10.1092 13.4345 7.74396 15.2715 6.12547L13.2882 3.87453C10.8323 6.03837 9.2793 9.21177 9.2793 12.746H12.2793ZM6.5 15.6354C6.5 10.9614 9.97451 7.09638 14.481 6.48645L14.0787 3.51355C8.10446 4.32213 3.5 9.43982 3.5 15.6354H6.5ZM15.7329 24.8684C10.6337 24.8684 6.5 20.7346 6.5 15.6354H3.5C3.5 22.3915 8.97687 27.8684 15.7329 27.8684V24.8684ZM22.6788 21.7186C20.9843 23.6516 18.5016 24.8684 15.7329 24.8684V27.8684C19.4018 27.8684 22.6947 26.2514 24.9347 23.6962L22.6788 21.7186Z'
        fill={fillColor ?? colors.textPrimary}
        mask='url(#path-1-outside-1_4995_14564)'
      />
      <Path
        d='M21.9844 12.0713L21.2385 12.1497C21.2614 12.3673 21.3781 12.564 21.558 12.6883C21.738 12.8127 21.9632 12.8523 22.1748 12.7968L21.9844 12.0713ZM17.4115 13.2164L17.893 13.7914C18.1426 13.5825 18.2289 13.2364 18.1066 12.9347L17.4115 13.2164ZM16.788 16.0625L16.1107 16.3846C16.235 16.646 16.4986 16.8125 16.788 16.8125V16.0625ZM28.0926 16.0625V16.8125C28.4656 16.8125 28.782 16.5383 28.835 16.169L28.0926 16.0625ZM25.0827 13.4329L24.4196 13.7834C24.5853 14.0968 24.9477 14.2514 25.2885 14.1541L25.0827 13.4329ZM19.6178 10.6934C20.4592 10.6934 21.1525 11.3315 21.2385 12.1497L22.7303 11.9929C22.5648 10.4193 21.235 9.19336 19.6178 9.19336V10.6934ZM17.9881 12.3231C17.9881 11.423 18.7178 10.6934 19.6178 10.6934V9.19336C17.8894 9.19336 16.4881 10.5946 16.4881 12.3231H17.9881ZM18.1066 12.9347C18.0305 12.7468 17.9881 12.5408 17.9881 12.3231H16.4881C16.4881 12.7372 16.569 13.1343 16.7164 13.4981L18.1066 12.9347ZM17.3086 15.0418C17.3086 14.5399 17.5345 14.0916 17.893 13.7914L16.93 12.6413C16.2458 13.2143 15.8086 14.0773 15.8086 15.0418H17.3086ZM17.4653 15.7404C17.3651 15.5296 17.3086 15.2933 17.3086 15.0418H15.8086C15.8086 15.5209 15.9167 15.9768 16.1107 16.3846L17.4653 15.7404ZM16.788 16.8125H28.0926V15.3125H16.788V16.8125ZM27.3668 15.7215C27.3668 15.8015 27.3611 15.8798 27.3502 15.956L28.835 16.169C28.856 16.0225 28.8668 15.873 28.8668 15.7215H27.3668ZM25.7371 14.0918C26.6372 14.0918 27.3668 14.8214 27.3668 15.7215H28.8668C28.8668 13.993 27.4656 12.5918 25.7371 12.5918V14.0918ZM25.2885 14.1541C25.4302 14.1137 25.5805 14.0918 25.7371 14.0918V12.5918C25.4398 12.5918 25.151 12.6335 24.8768 12.7117L25.2885 14.1541ZM22.6767 12.7324C23.4315 12.7324 24.0884 13.1568 24.4196 13.7834L25.7457 13.0824C25.165 11.9838 24.0092 11.2324 22.6767 11.2324V12.7324ZM22.1748 12.7968C22.3342 12.7549 22.5023 12.7324 22.6767 12.7324V11.2324C22.3726 11.2324 22.0766 11.2717 21.794 11.3459L22.1748 12.7968Z'
        fill={fillColor ?? colors.textPrimary}
      />
    </Svg>
  )
}

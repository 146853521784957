import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { ImageSourcePropType } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  BodyTextSize,
  Image,
  SecondaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  useMediaQuerySize,
} from '@lyrahealth-inc/ui-core-crossplatform'

import wellnessCheckInResultsFooterSpecialNeed from '../../assets/WellnessCheckInResultsFooterSpecialNeed.png'

export interface WellnessCheckInResultsSocialNeedsProps {
  onPress: () => void
}

const SocialNeedsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.breakpoints.isMobileSized
    ? `${theme.spacing['64px']} ${theme.spacing['16px']}`
    : `${theme.spacing['64px']} 0`,
  flexDirection: theme.breakpoints.isMobileSized ? 'column' : 'row',
  margin: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
}))

const TextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.breakpoints.isMobileSized ? `0 ${theme.spacing['16px']}` : `0 ${theme.spacing['40px']}`,
  maxWidth: '684px',
  flex: theme.breakpoints.isMobileSized ? 'auto' : 1,
}))

const TitleTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['4px'],
}))

const DescriptionTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : 0,
}))

const ImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  width: '128px',
  height: '128px',
  margin: theme.breakpoints.isMobileSized ? `0 auto ${theme.spacing['16px']} auto` : 0,
}))

const LearnMoreButton = styled(SecondaryButton)(() => ({
  alignSelf: 'center',
}))

export const WellnessCheckInResultsSocialNeeds: FunctionComponent<WellnessCheckInResultsSocialNeedsProps> = ({
  onPress,
}) => {
  const { colors } = useTheme()
  const { isMobileSized } = useMediaQuerySize()

  return (
    <SocialNeedsContainer testID={tID(`WellnessCheckInResultsSocialNeeds`)}>
      <ImageContainer
        source={wellnessCheckInResultsFooterSpecialNeed as ImageSourcePropType}
        contentFit='cover'
        accessibilityIgnoresInvertColors
      />
      <TextContainer>
        <TitleTextContainer>
          <Subhead
            text={
              <FormattedMessage
                defaultMessage='Looking for support with other needs?'
                description='Wellness check in results footer social need title'
              />
            }
            size={SubheadSize.MEDIUM}
            color={colors.textSecondary}
            textAlign={isMobileSized ? 'center' : 'left'}
          />
        </TitleTextContainer>
        <DescriptionTextContainer>
          <BodyText
            text={
              <FormattedMessage
                defaultMessage='Our Resource Coordinators can support your core needs, such as access to food, housing, transportation, social belonging, and more.'
                description='Wellness check in results footer social need description'
              />
            }
            size={BodyTextSize.DEFAULT}
            color={colors.textSecondary}
            textAlign={isMobileSized ? 'center' : 'left'}
          />
        </DescriptionTextContainer>
      </TextContainer>
      <LearnMoreButton
        onPress={onPress}
        text={
          <FormattedMessage
            defaultMessage='Learn more'
            description='Wellness check in results footer social need button text'
          />
        }
        testID={tID('WellnessCheckInResultsFooterSocialNeedButton')}
      />
    </SocialNeedsContainer>
  )
}

import { NotificationChannel, NotificationMethod, NotificationMethodKey, NotificationPreferences } from '../../models'

export type NotificationFormValues = {
  [NotificationChannel.APPOINTMENTS]: NotificationMethodKey
  [NotificationChannel.ASSIGNMENTS]: NotificationMethodKey
  [NotificationChannel.MESSAGES]: NotificationMethodKey
  [NotificationChannel.PROMOTIONS]: NotificationMethodKey
}

export type UpdatedNotificationFormValues = {
  updateNotificationPreferences: NotificationPreferences
  mixpanelUpdatedNotificationsObject: Record<string, string>
  pushUpdated: boolean
}

export const createUpdatedNotificationMethods = (
  values: NotificationFormValues,
  notificationPreferences: NotificationPreferences,
  disabledMethods?: NotificationMethod[],
): UpdatedNotificationFormValues | undefined => {
  let pushUpdated = false
  let didNotificationsChange = false
  const updateNotificationPreferences: NotificationPreferences = JSON.parse(JSON.stringify(notificationPreferences))
  const mixpanelUpdatedNotificationsObject = {}
  Object.keys(values).map((notificationChannel) => {
    Object.keys(NotificationMethod)
      .filter((method) => !disabledMethods?.includes(NotificationMethod[method]))
      .map((method) => {
        const newMethodValue = values[notificationChannel].includes(method)
        const currentMethodValue =
          updateNotificationPreferences.notification_methods[notificationChannel][NotificationMethod[method]]
        if (currentMethodValue !== newMethodValue) {
          didNotificationsChange = true
          updateNotificationPreferences.notification_methods[notificationChannel][NotificationMethod[method]] =
            newMethodValue
          mixpanelUpdatedNotificationsObject[`${notificationChannel}_${method.toLowerCase()}`] = newMethodValue
            ? 'on'
            : 'off'
          if (newMethodValue && method.toLowerCase() === NotificationMethod.PUSH) {
            pushUpdated = true
          }
          if (newMethodValue && NotificationMethod[method] === NotificationMethod.TEXT) {
            updateNotificationPreferences.sms_consent = true
          }
        }
      })
  })

  if (didNotificationsChange) {
    const isTextDisabledForAllChannels = Object.keys(updateNotificationPreferences.notification_methods).every(
      (channel: string) => !updateNotificationPreferences.notification_methods[channel][NotificationMethod.TEXT],
    )
    if (isTextDisabledForAllChannels) {
      updateNotificationPreferences.sms_consent = false
    }
  }

  return didNotificationsChange
    ? { pushUpdated, updateNotificationPreferences, mixpanelUpdatedNotificationsObject }
    : undefined
}

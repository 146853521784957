import { AvatarDetails } from '../../atoms/icons/Avatar'

export type ProviderFeedback = {
  avatarDetails: AvatarDetails
  feedbackBody: string
  feedbackDateTime: string
}

export enum MessageType {
  DELETED,
  ACTIVITY,
  CHAT_BUBBLE,
  SCHEDULING,
}

export enum MessageAttachmentType {
  DBL = 'DBL',
  PBL = 'PBL',
}

export interface BaseMessageAttachmentData {
  type: MessageAttachmentType
  key: string
}

export interface DBLMessageAttachmentData extends BaseMessageAttachmentData {
  type: MessageAttachmentType.DBL
}

export type MessageAttachmentData = DBLMessageAttachmentData

export interface Message {
  messageId: string
  receiver: boolean
  message: string
  avatarDetails?: AvatarDetails
  dateCreated: string
  isLatestMessage: boolean
  sendStatus: 'sent' | 'sending' | 'failed'
  showTime: boolean
  linkText?: string
  activityResponseId?: string
  activityId?: string
  submitDate?: string
  activityTitle?: string
  timeZone?: string
  type: MessageType
  messageType?: string
  authorType?: string
  attachments?: MessageAttachmentData[]
}

import React, { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { last } from 'lodash-es'

/**
 * Provides `previousRoute` - url pathname of the route user was on previously. If null, url has just changed and previousRoute is currently being set. If undefined, there is no previousRoute.
 */
export const PreviousRouteContext = createContext<{
  previousRoute: string | null | undefined
}>({
  previousRoute: null,
})

/** Wrap this around Routes in order to track which route the user was previously on. `previousRoute` is a url pathname and can be accessed from `PreviousRouteContext`  */
export const PreviousRouteProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  const [history, setHistory] = useState<string[]>([])
  const browserBack = useRef(false)

  useEffect(() => {
    const onPopState = () => {
      browserBack.current = true
    }

    window.addEventListener('popstate', onPopState, false)
    return () => {
      window.removeEventListener('popstate', onPopState)
    }
  }, [])

  useEffect(() => {
    const currentRoute = location.pathname
    if (browserBack.current) {
      history.pop()
      browserBack.current = false
    } else {
      history.push(currentRoute)
    }
    setHistory([...history])

    // Do not add history as a dependency since it gets updated within this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const previousRoute = useMemo(() => {
    let previousRoute
    if (history.length > 1) {
      previousRoute = history[history.length - 2]
    }
    // Return null if the history hasn't been updated with the latest route
    return last(history) !== location.pathname ? null : previousRoute
  }, [history, location.pathname])

  return (
    <PreviousRouteContext.Provider
      value={{
        previousRoute,
      }}
    >
      {children}
    </PreviousRouteContext.Provider>
  )
}

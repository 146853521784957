import { useRef } from 'react'

import {
  EmailCaptureArmType,
  EmailCaptureDescription,
  EmailCaptureExperimentUUID,
  EmailCaptureMetric,
  EmailCaptureVariant,
} from './emailCaptureExperimentTypes'
import { LD_CUSTOM_EVENTS } from '../../common/constants/constants'
import { COMMON_MIXPANEL_PAGE, COMMON_MIXPANEL_PROPERTIES } from '../../common/constants/mixpanelConstants'
import { PathwayOptions } from '../../pathways/constants'
import { ExperimentPayload } from '../types'

interface useEmailCaptureExperimentArgs {
  arm: EmailCaptureArmType
  deviceUUID: string
  customerName: string
  pathwayOption?: PathwayOptions
  postExperimentCallback?: (payload: ExperimentPayload) => void
  postLDCallback?: (event: LD_CUSTOM_EVENTS, payload: ExperimentPayload) => void
  postMixpanelCallback?: (event: string, eventData: { [key: string]: any }) => void
  logToSumo?: (message: string) => void
}

export const useEmailCaptureExperiment = ({
  arm,
  deviceUUID,
  customerName,
  pathwayOption,
  postExperimentCallback,
  postLDCallback,
  postMixpanelCallback,
  logToSumo,
}: useEmailCaptureExperimentArgs) => {
  let variant: EmailCaptureVariant | null = null
  if (arm === EmailCaptureArmType.EXPERIMENT) {
    if (pathwayOption != null) {
      variant =
        pathwayOption === PathwayOptions.ESSENTIALS ? EmailCaptureVariant.SELF_CARE : EmailCaptureVariant.PROVIDER
    } else {
      variant = EmailCaptureVariant.GENERAL
    }
  }

  const hasPostedViewPageMetric = useRef<boolean>(false)
  const submitExperimentPayload = ({
    metric,
    description,
    launchDarklyEvent,
    mixpanelEvent,
  }: {
    metric?: EmailCaptureMetric
    description?: EmailCaptureDescription
    launchDarklyEvent?: LD_CUSTOM_EVENTS
    mixpanelEvent?: string
  }) => {
    if (metric === EmailCaptureMetric.VIEW_PAGE && hasPostedViewPageMetric.current) return
    else if (metric != null && description != null) {
      if (metric === EmailCaptureMetric.VIEW_PAGE) {
        hasPostedViewPageMetric.current = true
      }
      const experimentPayload: ExperimentPayload = {
        experimentUUID: EmailCaptureExperimentUUID,
        deviceUUID,
        experiments: {
          [EmailCaptureExperimentUUID]: {
            assignment: [
              {
                arm,
                joinKeys: {
                  customer_name: customerName,
                  description,
                  device_uuid: deviceUUID,
                  metric,
                  variant,
                },
              },
            ],
          },
        },
      }

      // 'Click continue' events are not needed for the DS pipeline
      if (metric === EmailCaptureMetric.VIEW_PAGE) {
        try {
          postExperimentCallback?.(experimentPayload)
        } catch (error) {
          const errorMsg = 'Unable to post to experiment endpoint for email capture experiment'
          console.error(errorMsg)
          if (logToSumo) {
            logToSumo?.(`${errorMsg} - ${error}`)
          }
        }
      }
      if (launchDarklyEvent != null) {
        try {
          postLDCallback?.(launchDarklyEvent, experimentPayload)
        } catch (error) {
          const errorMsg = 'Unable to post to launch darkly endpoint for email capture experiment'
          console.error(errorMsg)
          if (logToSumo) {
            logToSumo?.(`${errorMsg} - ${error}`)
          }
        }
      }
    }
    if (mixpanelEvent != null) {
      try {
        postMixpanelCallback?.(mixpanelEvent, {
          arm,
          deviceUUID,
          variant,
          [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.REGISTER_BY_EMAIL,
        })
      } catch (error) {
        const errorMsg = 'Unable to post to Mixpanel endpoint for email capture experiment'
        console.error(errorMsg)
        if (logToSumo) {
          logToSumo?.(`${errorMsg} - ${error}`)
        }
      }
    }
  }

  return { variant, submitExperimentPayload }
}

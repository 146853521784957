import { AxiosRequestConfig } from 'axios'
import uuid from 'uuid'
const { v4: uuidv4 } = uuid

// Header name when setting LD flags via request header
export const API_FEATURES_HEADER_NAME = 'X-Features'

// Context UUID sent to our APIs
const CONTEXT_UUID_HEADER_NAME = 'X-Context-UUID'
const EXCLUDE_CONTEXT_UUID_HEADER_URL_REGEX = /^https:\/\/vendorapi.*\.lyrahealth\.com/

export function setContextUUIDHeader(config: AxiosRequestConfig) {
  if (config.url && !EXCLUDE_CONTEXT_UUID_HEADER_URL_REGEX.test(config.url) && config.headers) {
    config.headers[CONTEXT_UUID_HEADER_NAME] = uuidv4()
  }
}

import { fromJS } from 'immutable'

import { SET_TOP_RESULTS_PROVIDERS } from './results-actions'

export const results = (state = fromJS([]), action = null) => {
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  switch (action.type) {
    case SET_TOP_RESULTS_PROVIDERS:
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return action.providers
    default:
      return state
  }
}

import React, { FunctionComponent, MutableRefObject, ReactElement, useContext, useEffect, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import {
  NativeSyntheticEvent,
  Platform,
  ReturnKeyTypeOptions,
  TextInput as TextInputComponent,
  TextInputContentSizeChangeEventData,
  TextInputProps,
  TextStyle,
  ViewStyle,
} from 'react-native'

import { isNumber } from 'lodash-es'
import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'

import { AppContext } from '../../context'
import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { BodyTextSize, getFontStyles } from '../../styles/typeStyles'
import { deviceIsSamsung, ThemeType } from '../../utils'
import { BodyText } from '../bodyText/BodyText'
import { BaseInput } from '../formElements/BaseInput'
import { ToolTipTriggerConfig } from '../tooltip/Tooltip'

export interface InputFieldProps extends TextInputProps {
  label?: string
  sublabel?: string
  subLabelComponent?: string
  error?: string
  name?: string
  readOnly?: boolean
  active?: boolean
  typeStyle?: Dict
  secureTextEntry?: boolean
  placeholderTextColor?: string
  backgroundColor?: string
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters'
  baseInputStyle?: ViewStyle
  toolTipContent?: string | ReactElement
  maxCharLength?: number
  showCharLimit?: boolean
  toolTipTriggerConfig?: ToolTipTriggerConfig
  accessibilityLabelledBy?: string
  scrollEnabled?: boolean
  labelStyle?: ViewStyle
  labelContainerStyle?: ViewStyle
  isOptionalLabel?: boolean // show "OPTIONAL" tag next to the label
  inputContainerStyle?: ViewStyle
  toolTipContentStyle?: { container?: Dict }
  showErrorAsWarning?: boolean
  areAllInputRefsSet?: boolean
  inputExtras?: React.ReactElement
  navigateToNewField?: (direction: 'previous' | 'next') => void
  getReturnKeyType?: (fieldName: string) => 'next' | 'done' | 'previous'
  setInputRefs?: ({ name, ref }: { name: string; ref: MutableRefObject<any> }) => void
}

const InputFieldContainer = styled.View<{
  theme: ThemeType
  active?: boolean
  additionalHeight?: number
  error?: string
  backgroundColor?: string
  enableScroll?: CSSObject
  showErrorAsWarning?: boolean
}>(({ theme, active, additionalHeight = 0, error, backgroundColor, enableScroll, showErrorAsWarning }) => ({
  flexDirection: 'row',
  backgroundColor,
  borderWidth: '1px',
  borderColor: showErrorAsWarning
    ? theme.colors.borderWarning
    : error
    ? theme.colors.inputOutlineError
    : theme.colors.inputOutlineDefault,
  borderRadius: '4px',
  height: 'auto',
  padding: `${11 - additionalHeight}px 15px ${11 + additionalHeight}px 15px`,
  ...(active && {
    borderColor: theme.colors.inputOutlineFocus,
    borderWidth: '2px',
    padding: `${10 - additionalHeight}px 14px ${10 + additionalHeight}px 14px`,
  }),
  ...enableScroll,
}))

const TextInput = styled(TextInputComponent)`
  ${Platform.select({ web: `outline-style: none;` })};
`

const CharacterCounterContainer = styled(BodyText)<{
  theme: ThemeType
}>(({ theme: { colors } }) => ({
  position: 'absolute',
  right: '16px',
  bottom: '12px',
  color: colors.inputTextPlaceholder,
}))

export const useAutoFillHack = () => {
  // https://github.com/react-native-elements/react-native-elements/issues/2998
  /** THIS IS A TERRIBLE HACK TO MAKE AUTOFILL WORK ON ANDROID!!! */
  const [autoFill, setAutoFill] = useState(false)

  useEffect(() => {
    setAutoFill(true)
  }, [])

  return Platform.select({
    android: autoFill,
    ios: true,
    web: true,
  })
}

export const InputField: FunctionComponent<InputFieldProps> = ({
  label,
  sublabel,
  subLabelComponent,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  active,
  multiline,
  numberOfLines = 1,
  error,
  name,
  readOnly,
  keyboardType,
  inputAccessoryViewID,
  placeholderTextColor,
  backgroundColor,
  autoCapitalize = 'sentences',
  autoComplete,
  secureTextEntry,
  typeStyle,
  baseInputStyle,
  toolTipContent,
  maxCharLength,
  showCharLimit,
  toolTipTriggerConfig,
  accessibilityLabelledBy,
  scrollEnabled = false,
  children,
  testID,
  // 'default' returnKeyType is only available for IOS and the default equivalent for android is 'done
  returnKeyType = Platform.OS === 'ios' ? 'default' : 'done',
  inputContainerStyle,
  labelStyle,
  labelContainerStyle,
  isOptionalLabel,
  textContentType,
  toolTipContentStyle,
  showErrorAsWarning,
  areAllInputRefsSet,
  navigateToNewField,
  setInputRefs,
  getReturnKeyType,
  autoFocus,
  inputExtras,
}) => {
  const { colors, isDarkMode } = useTheme()
  const [focusRef] = useAccessibilityFocus({ active, delay: 200 })
  const { lineHeight, ...prunedTypeStyle } = typeStyle || getFontStyles(colors).body.default
  const inputLineHeight = isNumber(lineHeight) ? lineHeight : 0
  const additionalHeight = multiline ? Platform.select({ ios: 5, default: 0 }) : 0
  const initialContentHeight = inputLineHeight * numberOfLines + additionalHeight
  const [contentHeight, setContentHeight] = useState(initialContentHeight)
  const { copyPasteDisabled } = useContext(AppContext)
  const isSamsung = deviceIsSamsung()
  const autoFill = useAutoFillHack()
  const { isGPSv1Enabled } = useFlags()

  const handleOnContentSizeChange = (event: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
    const contentSize = event.nativeEvent.contentSize.height + additionalHeight
    /* possible outcomes:
     * fixed height for the textbox WITH scrollable content
     * dynamic height for the textbox WITHOUT scrollable content
     */
    if (!scrollEnabled) setContentHeight(contentSize > initialContentHeight ? contentSize : initialContentHeight)
  }

  // this logic enables user to navigate to InputField from keyboard(on mobile devices)
  useEffect(() => {
    if (setInputRefs && focusRef && name) {
      setInputRefs({ name, ref: focusRef })
    }
  }, [focusRef, name, setInputRefs])

  const [currentReturnKeyType, setCurrentReturnKeyType] = useState<ReturnKeyTypeOptions | undefined>(returnKeyType)

  // if keyboard form navigation is enabled, we want to make sure to update the returnKeyType after all the input refs have been set
  // since the array of navigable fields will be completed and we can determine if the user can navigate to next field.
  useEffect(() => {
    if (areAllInputRefsSet && name) {
      const updatedReturnKeyType = getReturnKeyType ? getReturnKeyType(name) : returnKeyType
      setCurrentReturnKeyType(updatedReturnKeyType)
    }
  }, [areAllInputRefsSet, getReturnKeyType, name, returnKeyType])

  return (
    <BaseInput
      label={label}
      labelStyle={labelStyle}
      labelContainerStyle={labelContainerStyle}
      isOptionalLabel={isOptionalLabel}
      subLabel={sublabel}
      subLabelComponent={subLabelComponent}
      error={error}
      name={name}
      style={baseInputStyle}
      toolTipContent={toolTipContent}
      toolTipTriggerConfig={toolTipTriggerConfig}
      toolTipContentStyle={toolTipContentStyle}
      showErrorAsWarning={showErrorAsWarning}
    >
      <InputFieldContainer
        active={active && !readOnly}
        additionalHeight={additionalHeight}
        backgroundColor={backgroundColor || colors.backgroundPrimary}
        error={error}
        style={inputContainerStyle}
        showErrorAsWarning={showErrorAsWarning}
      >
        {!autoFill ? (
          <></>
        ) : (
          <TextInput
            key={`inputField-${name}-${currentReturnKeyType}ReturnKey`}
            ref={focusRef}
            testID={testID ?? name}
            value={value}
            maxLength={maxCharLength}
            onChange={onChange}
            editable={!readOnly}
            onContentSizeChange={handleOnContentSizeChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={readOnly ? undefined : placeholder}
            placeholderTextColor={placeholderTextColor || colors.inputTextPlaceholder}
            multiline={multiline}
            numberOfLines={numberOfLines} // for android and web only
            textAlignVertical={multiline ? 'top' : 'center'}
            style={[
              { flexGrow: 1, ...(prunedTypeStyle as TextStyle) },
              Platform.OS === 'web' && { lineHeight: inputLineHeight },
              multiline && { lineHeight: inputLineHeight, height: contentHeight },
            ]}
            scrollEnabled={scrollEnabled}
            keyboardType={keyboardType}
            inputAccessoryViewID={inputAccessoryViewID}
            hitSlop={{ top: 24, left: 24, bottom: 24, right: 24 }}
            contextMenuHidden={copyPasteDisabled}
            /**
             * The context menu still appears on Samsung devices when contextMenuHidden is set to true.
             * Setting caretHidden to true fixes the problem but also hides the blinking typing indicator.
             * In order to not degrade the experience for all users we will only set this true for Samsung devices.
             */
            caretHidden={copyPasteDisabled && isSamsung}
            accessibilityLabel={label}
            accessibilityLabelledBy={accessibilityLabelledBy}
            secureTextEntry={secureTextEntry}
            autoCapitalize={autoCapitalize}
            autoComplete={autoComplete}
            returnKeyType={currentReturnKeyType}
            textContentType={textContentType}
            importantForAutofill='yes'
            keyboardAppearance={isDarkMode ? 'dark' : undefined}
            // We only want to define onSubmitEditing prop for non-multiline inputs because it causes unexpected behavior for text area inputs.
            // Additionally, only defining the following props for android devices when navigateToNewField is defined since other platforms don't
            // use return key for form navigation.
            {...(!multiline &&
              Platform.OS === 'android' &&
              navigateToNewField && {
                onSubmitEditing: () => {
                  if (currentReturnKeyType === 'next' || currentReturnKeyType === 'previous') {
                    navigateToNewField(currentReturnKeyType)
                  }
                },
                // prevents flickering when programmatically focusing on a new field
                blurOnSubmit: currentReturnKeyType !== 'next' && currentReturnKeyType !== 'previous',
              })}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
          />
        )}
        {children}
        {inputExtras}
        {isGPSv1Enabled && showCharLimit && maxCharLength && (
          <CharacterCounterContainer
            text={
              <FormattedMessage
                defaultMessage='Character limit ({count}/{length})'
                description='Shows how many characters the user has left to use in this field'
                values={{
                  count: value?.length ?? 0,
                  length: maxCharLength,
                }}
              />
            }
            size={BodyTextSize.CAPTION}
          />
        )}
      </InputFieldContainer>
    </BaseInput>
  )
}

export const InputFieldRFF: FunctionComponent<FieldRenderProps<string>> = ({
  input: { value, onChange, onFocus, onBlur, name },
  placeholder,
  meta: { touched, error, submitError, active, dirtySinceLastSubmit },
  label,
  sublabel,
  sublabelComponent,
  multiline,
  numberOfLines,
  readOnly,
  keyboardType,
  inputAccessoryViewID,
  typeStyle,
  secureTextEntry,
  placeholderTextColor,
  autoCapitalize,
  autoComplete,
  baseInputStyle,
  toolTipContent,
  toolTipTriggerConfig,
  backgroundColor,
  maxCharLength,
  showCharLimit,
  accessibilityLabelledBy,
  scrollEnabled,
  testID,
  returnKeyType,
  labelStyle,
  labelContainerStyle,
  isOptionalLabel,
  inputContainerStyle,
  textContentType,
  toolTipContentStyle,
  showErrorAsWarning,
  handleOnInputFocus,
  handleOnInputBlur,
  getReturnKeyType,
  setInputRefs,
  navigateToNewField,
  areAllInputRefsSet,
  inputExtras,
  CustomInputFieldComponent,
  customInputFieldProps,
}) => {
  const InputField_: typeof InputField = CustomInputFieldComponent ?? InputField
  return (
    <InputField_
      label={label}
      sublabel={sublabel}
      subLabelComponent={sublabelComponent}
      value={value}
      onChange={(e) => onChange(e)}
      onFocus={() => {
        if (handleOnInputFocus) {
          handleOnInputFocus(name)
        }
        onFocus()
      }}
      onBlur={() => {
        if (handleOnInputBlur) {
          handleOnInputBlur(name)
        }
        onBlur()
      }}
      placeholder={placeholder}
      active={active}
      multiline={multiline}
      numberOfLines={numberOfLines}
      error={touched && (error || (!dirtySinceLastSubmit && submitError))}
      name={name}
      readOnly={readOnly}
      keyboardType={keyboardType}
      inputAccessoryViewID={inputAccessoryViewID}
      typeStyle={typeStyle}
      secureTextEntry={secureTextEntry}
      placeholderTextColor={placeholderTextColor}
      autoCapitalize={autoCapitalize}
      autoComplete={autoComplete}
      baseInputStyle={baseInputStyle}
      toolTipContent={toolTipContent}
      toolTipTriggerConfig={toolTipTriggerConfig}
      backgroundColor={backgroundColor}
      maxCharLength={maxCharLength}
      showCharLimit={showCharLimit}
      accessibilityLabelledBy={accessibilityLabelledBy}
      scrollEnabled={scrollEnabled}
      testID={testID}
      returnKeyType={returnKeyType}
      labelStyle={labelStyle}
      labelContainerStyle={labelContainerStyle}
      isOptionalLabel={isOptionalLabel}
      inputContainerStyle={inputContainerStyle}
      textContentType={textContentType}
      toolTipContentStyle={toolTipContentStyle}
      /**
       * We only want to show the warning color after the user submits the form.
       * Using dirtySinceLastSubmit here to check if the user has updated the field
       * after submitting, so we so we can show the correct validation error
       * color or default color when needed.
       */
      showErrorAsWarning={showErrorAsWarning && !dirtySinceLastSubmit && !!submitError}
      setInputRefs={setInputRefs}
      areAllInputRefsSet={areAllInputRefsSet}
      getReturnKeyType={getReturnKeyType}
      navigateToNewField={navigateToNewField}
      inputExtras={inputExtras}
      {...customInputFieldProps}
    />
  )
}

import React, { FunctionComponent } from 'react'
import { MessageDescriptor, useIntl } from 'react-intl'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { WellnessCheckInResultsEssentialsArticleContent } from '@lyrahealth-inc/shared-app-logic/'
import { BodyText, BodyTextSize, ThemeType, TruncatedText } from '@lyrahealth-inc/ui-core-crossplatform'

import { WellnessCheckInResultsEssentialsCard } from '../wellnessCheckInResultsEssentialsCard/WellnessCheckInResultsEssentialsCard'

export interface WellnessCheckInResultsDomainContentProps {
  title: MessageDescriptor
  body: MessageDescriptor
  articleContents?: WellnessCheckInResultsEssentialsArticleContent[]
  onArticlePress: (subPath: string) => void
}

const ContentDescriptionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const ContentTitleContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing['4px'],
}))

const EssentialsCardContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['12px'],
  width: '100%',
}))

export const WellnessCheckInResultsDomainContent: FunctionComponent<WellnessCheckInResultsDomainContentProps> = ({
  title,
  body,
  articleContents,
  onArticlePress,
}) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <ContentTitleContainer text={formatMessage(title)} size={BodyTextSize.DEFAULT} />
      <ContentDescriptionContainer>
        <TruncatedText
          textSize={BodyTextSize.SMALL}
          text={formatMessage(body, { br: '\n' })}
          maxNumberOfLines={3}
          toggleCollapseExpand={true}
          collapseExpandText={[
            formatMessage({
              defaultMessage: 'Read less',
              description: 'Read less for truncated essential content',
            }),
            formatMessage({
              defaultMessage: 'Read more',
              description: 'Read more for truncated essential content',
            }),
          ]}
        />
      </ContentDescriptionContainer>
      {articleContents &&
        articleContents.length > 0 &&
        articleContents.map((content, index) => (
          <EssentialsCardContainer key={index}>
            <WellnessCheckInResultsEssentialsCard articleContent={content} onArticlePress={onArticlePress} />
          </EssentialsCardContainer>
        ))}
    </>
  )
}

import React from 'react'
import { useIntl } from 'react-intl'

import classNames from 'classnames'
import { capitalize } from 'lodash-es'

import { LoadingIndicator } from '@lyrahealth-inc/ui-core-crossplatform'
import { SSO_BUTTON_TYPE } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/ssoButton/SsoButton'

import styles from './ssoIframe.module.scss'

type SSOIframeProps = {
  iframeRef: React.RefObject<HTMLIFrameElement>
  registrationIframeURL: string
  onLoadCallback: () => void
  signUpButtonWidth?: number
  iFrameHeight: number
  iframeContainerRef: React.RefObject<HTMLDivElement>
  isSSOLoading: boolean
  padding?: number
  type: SSO_BUTTON_TYPE
  loadingContainerHeight?: number
}

export const SSOIframe: React.FC<SSOIframeProps> = ({
  iframeRef,
  registrationIframeURL,
  onLoadCallback,
  signUpButtonWidth,
  iFrameHeight,
  iframeContainerRef,
  isSSOLoading,
  padding = 0,
  type,
  loadingContainerHeight = 40,
}) => {
  const { formatMessage } = useIntl()
  const identityProvider = capitalize(type)

  return (
    <>
      <div
        aria-live='polite'
        aria-label={formatMessage(
          {
            defaultMessage: 'Loading the button to sign up using {identityProvider} SSO',
            description: 'Screener text for when the SSO sign up is loading',
          },
          { identityProvider },
        )}
        className={classNames(styles['loading-container'], { [styles.hide]: !isSSOLoading })}
        style={{ height: `${loadingContainerHeight}px` }}
      >
        <LoadingIndicator size={38} topPadding={padding} />
      </div>
      <div ref={iframeContainerRef} style={{ width: '100%' }}>
        <iframe
          className={styles['signup-button-iframe']}
          ref={iframeRef}
          src={registrationIframeURL}
          onLoad={onLoadCallback}
          frameBorder='0'
          width={signUpButtonWidth}
          height={iFrameHeight}
          scrolling='no'
          title={formatMessage(
            {
              defaultMessage: '{identityProvider} SSO Button',
              description: 'iframe title for SSO Button',
            },
            { identityProvider },
          )}
        />
      </div>
    </>
  )
}

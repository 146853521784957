import { GetCustomerCopyFunc, WORKLIFE_SERVICE_IDS } from '@lyrahealth-inc/shared-app-logic'

import DependentsIllustration from '../assets/worklife/illustration-dependents.png'
import FinancialIllustration from '../assets/worklife/illustration-financial.png'
import LegalIllustration from '../assets/worklife/illustration-legal.png'
import SecurityIllustration from '../assets/worklife/illustration-security.png'
import { DEFAULT_WORKLIFE_SERVICES } from '../constants'

export const roche: GetCustomerCopyFunc = () => {
  return {
    workLifeServices: [
      { ...DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES, img: LegalIllustration },
      { ...DEFAULT_WORKLIFE_SERVICES.FINANCIAL_SERVICES, img: FinancialIllustration },
      { ...DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT, img: SecurityIllustration },
      {
        identifiers: WORKLIFE_SERVICE_IDS.DEPENDENT_CARE,
        title: 'Elder Care',
        img: DependentsIllustration,
        paragraph: 'Get assistance finding elder care.',
        bullets: [],
        CTA: 'Find elder care',
        PATH: 'https://clients.brighthorizons.com/roche',
      },
    ],
  }
}

import { RequireEligibilityConfirmationLDFlag } from './types'
import { EligibilityStatus, YearProgramCoverage } from '../../models'
import { EligibilityType } from '../common/constants/constants'
import {
  COMMON_MIXPANEL_PROPERTIES,
  ELIGIBILITY_PROPERTIES,
  ELIGIBILITY_PROPERTY_VALUE,
  REGISTRATION_EVENTS,
} from '../common/constants/mixpanelConstants'
import { areAllCustomerProgramsBHBExclusive } from '../healthPlan/utils'
import { RegistrationType } from '../registration/types'

export interface EligibilityCheckParams {
  customerPrograms: YearProgramCoverage
  hasBlockingEligibilityCheck: boolean
  isCustomerOptedOutOfEligibilityCheck: boolean
  approveClaimsWithNoEligibilityCheck: boolean
  isInternationalRegistrationExperience: boolean
  hideDependentField?: boolean
  registrationType?: RegistrationType
}

export const skipEligibilityCheck = ({
  customerPrograms,
  hasBlockingEligibilityCheck,
  isCustomerOptedOutOfEligibilityCheck,
  approveClaimsWithNoEligibilityCheck,
  isInternationalRegistrationExperience,
  hideDependentField,
  registrationType,
}: EligibilityCheckParams) => {
  const isCustomerHPIExclusive = areAllCustomerProgramsBHBExclusive(customerPrograms)
  /*
    Users will automatically skip an eligibility check for the following cases:
    1. A self-managed teen is completing the parent-led registration
    2. A self-managed teen is completing the family hub registration
    where eligibility is inherited from their parent/guardian.
    3. Auto-enrollment, where the registration user info is sourced directly from eligibility data
   */
  if (
    registrationType === 'PARENT_LED_TEEN' ||
    registrationType === 'FAMILY_HUB' ||
    registrationType === 'AUTO_ENROLLMENT'
  ) {
    return true
  }

  if (hasBlockingEligibilityCheck) {
    return false
  }

  /*
    Users will skip an eligibility check if any of the following are true:
    1. The customer does not require a blocking eligibility check for registration or claims
    2. The customer has opted out of an eligibility check, processes claims without an eligibility check, is HPI-only, or
       is international and an international country is selected
  */
  return (
    isCustomerOptedOutOfEligibilityCheck ||
    approveClaimsWithNoEligibilityCheck ||
    isCustomerHPIExclusive ||
    isInternationalRegistrationExperience ||
    hideDependentField
  )
}

export function createEligibilityCheckEventData({
  page,
  eligibilityStatus,
  hasBlockingEligibilityCheck,
  eligibilityType,
  attemptCount,
  pageEntryPoint,
}: {
  page: string
  eligibilityStatus: EligibilityStatus | undefined
  hasBlockingEligibilityCheck: boolean | undefined
  eligibilityType: EligibilityType | undefined
  attemptCount?: number
  pageEntryPoint?: string
}): { event: string; props?: Record<string, any> } {
  const sharedProps = {
    [COMMON_MIXPANEL_PROPERTIES.PAGE]: page,
    [ELIGIBILITY_PROPERTIES.ELIGIBILITY_TYPE]: eligibilityType,
    ...(pageEntryPoint && { [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: pageEntryPoint }),
  }

  if (eligibilityStatus === EligibilityStatus.ELIGIBLE) {
    return {
      event: hasBlockingEligibilityCheck
        ? REGISTRATION_EVENTS.EAP_HARD_ELIGIBILITY_CHECK_SUCCESS
        : REGISTRATION_EVENTS.EAP_SOFT_ELIGIBILITY_CHECK_SUCCESS,
      props: {
        ...sharedProps,
        [COMMON_MIXPANEL_PROPERTIES.ATTEMPT_COUNT]: attemptCount,
      },
    }
  } else {
    return {
      event: hasBlockingEligibilityCheck
        ? REGISTRATION_EVENTS.EAP_HARD_ELIGIBILITY_CHECK_ERROR
        : REGISTRATION_EVENTS.EAP_SOFT_ELIGIBILITY_CHECK_ERROR,
      props: {
        ...sharedProps,
        [ELIGIBILITY_PROPERTIES.ELIGIBILITY_ERROR_CAUSE]:
          eligibilityStatus === EligibilityStatus.INELIGIBLE
            ? ELIGIBILITY_PROPERTY_VALUE.ELIGIBILITY_ERROR_CAUSE_INELIGIBILITY
            : ELIGIBILITY_PROPERTY_VALUE.ELIGIBILITY_ERROR_CAUSE_UNEXPECTED_ERROR,
      },
    }
  }
}

export function shouldRequireEligibilityConfirmation(
  eligibilityStatus: EligibilityStatus | undefined,
  requireEligibilityConfirmation: RequireEligibilityConfirmationLDFlag | undefined,
  userFriendlyLyraCode?: string | null,
): boolean {
  return (
    requireEligibilityConfirmation?.isEnabled === true &&
    eligibilityStatus === EligibilityStatus.INELIGIBLE &&
    // skip eligibility confirmation for users assigned with lyra code
    (!requireEligibilityConfirmation.skipIneligibleUserWithLyraCode || !userFriendlyLyraCode)
  )
}

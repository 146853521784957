import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Linking } from 'react-native'

import { useTheme } from 'styled-components/native'

import { Link } from '../../../atoms'
import { EligibilityInformation } from '../EligibilityInformation'

export const WalmartDependentEligibilityInformation: React.FC<{}> = () => {
  const { colors } = useTheme()

  return (
    <EligibilityInformation
      textStyle={{ color: colors.textSecondary }}
      text={
        <FormattedMessage
          defaultMessage='Add information about the Walmart associate who is giving you access to Lyra. Their WIN number can be found on the Me@Walmart app (WMUS associates), Me@Campus app (corporate/campus associates), <oneWalmartLink>OneWalmart</oneWalmartLink> and the <samWireLink>Sam’s Wire</samWireLink> websites.'
          description='Text to inform user to enter the eligibility information of the primary member'
          values={{
            oneWalmartLink: (text: string) => (
              <Link
                underline
                text={text}
                onPress={() => Linking.openURL('https://one.walmart.com/content/uswire/en_us/me/apps/winfinder.html')}
              />
            ),
            samWireLink: (text: string) => (
              <Link
                underline
                text={text}
                onPress={() =>
                  Linking.openURL('https://one.walmart.com/content/sams_wire/en_us/me/apps/winfinder.html')
                }
              />
            ),
          }}
        />
      }
    />
  )
}

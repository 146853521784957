import { defineMessage, MessageDescriptor } from 'react-intl'

import { PathwayOptions } from '@lyrahealth-inc/shared-app-logic'

import { LatestMatchesLinkProps } from '../molecules/latestMatchesLink/LatestMatchesLink'

export interface ForkedTriageCareOptionCard {
  header: MessageDescriptor
  description?: MessageDescriptor
  descriptionFormattedMessageValues?: Record<string, any>
  icon: string
  onCardPress: () => void
  testId: string
  latestMatchesLinkProps?: LatestMatchesLinkProps
}

export const ForkedTriageCareOptionsMetadata: { [key in PathwayOptions]?: Partial<ForkedTriageCareOptionCard> } = {
  [PathwayOptions.SELF_SEARCH]: {
    header: defineMessage({
      defaultMessage: 'Find care for me',
      description: 'Pathways: Adult Search option - header',
    }),
    icon: 'IndividualTherapySpotIllustrationWhiteBorder',
    testId: PathwayOptions.SELF_SEARCH,
  },
  [PathwayOptions.CHILD_SEARCH]: {
    header: defineMessage({
      defaultMessage: 'Find care for a child or teen (0-17)',
      description: 'Pathways: Child or Teen Search option - header',
    }),
    icon: 'CareForChildOrTeenSpotIllustration',
    testId: PathwayOptions.CHILD_SEARCH,
  },
  [PathwayOptions.COACHING_TOOLKIT]: {
    header: defineMessage({
      defaultMessage: 'Build skills with a coach',
      description: 'Pathways: Coaching Toolkit option - header',
    }),
    icon: 'CoachingSpotIllustration',
    testId: PathwayOptions.COACHING_TOOLKIT,
  },
  [PathwayOptions.PARENTING_TOOLKIT]: {
    header: defineMessage({
      defaultMessage: 'Get expert coaching for parents',
      description: 'Pathways: Parenting Toolkit option - header',
    }),
    icon: 'ParentCoachingSpotIllustration',
    testId: PathwayOptions.PARENTING_TOOLKIT,
  },
  [PathwayOptions.MEDICATION_CONSULTATION]: {
    header: defineMessage({
      defaultMessage: 'Consult about medication',
      description: 'Pathways: Medication consultation Toolkit option - header',
    }),
    description: defineMessage({
      defaultMessage: '<medsConsultModal></medsConsultModal>',
      description: 'Pathways: Medication consultation option - description',
    }),
    icon: 'MedicationManagementSpotIllustration',
    testId: PathwayOptions.MEDICATION_CONSULTATION,
  },
}

export const ForkedTriageCareOptionsMetadataV2: { [key in PathwayOptions]?: Partial<ForkedTriageCareOptionCard> } = {
  [PathwayOptions.CHILD_SEARCH]: {
    header: defineMessage({
      defaultMessage: 'Care for a child or teen',
      description: 'Pathways: Child or Teen Search option - header',
    }),
    icon: 'CareForChildOrTeenSpotIllustration',
    testId: PathwayOptions.CHILD_SEARCH,
  },
  [PathwayOptions.COACHING_TOOLKIT]: {
    header: defineMessage({
      defaultMessage: 'Mental health coaching',
      description: 'Pathways: Coaching Toolkit option - header',
    }),
    icon: 'CoachingSpotIllustration',
    testId: PathwayOptions.COACHING_TOOLKIT,
  },
  [PathwayOptions.PARENTING_TOOLKIT]: {
    header: defineMessage({
      defaultMessage: 'Coaching for parents',
      description: 'Pathways: Parenting Toolkit option - header',
    }),
    icon: 'ParentCoachingSpotIllustration',
    testId: PathwayOptions.PARENTING_TOOLKIT,
  },
  [PathwayOptions.MEDICATION_CONSULTATION]: {
    header: defineMessage({
      defaultMessage: 'Medication consultation',
      description: 'Pathways: Medication consultation Toolkit option - header',
    }),
    icon: 'MedsConsultationSecondaryRec',
    testId: PathwayOptions.MEDICATION_CONSULTATION,
  },
  [PathwayOptions.COUPLES_THERAPY]: {
    header: defineMessage({
      defaultMessage: 'Couples therapy',
      description: 'Pathways: Couples therapy search option - header',
    }),
    icon: 'CouplesAndFamilyTherapySpotIllustration',
    testId: PathwayOptions.COUPLES_THERAPY,
  },
  [PathwayOptions.FAMILY_THERAPY]: {
    header: defineMessage({
      defaultMessage: 'Family therapy',
      description: 'Pathways: Family therapy search option - header',
    }),
    icon: 'Family',
    testId: PathwayOptions.FAMILY_THERAPY,
  },
}

export const ForkedTriageCareOptionsOUSMetadata: { [key in PathwayOptions]?: Partial<ForkedTriageCareOptionCard> } = {
  [PathwayOptions.CHILD_SEARCH]: {
    header: defineMessage({
      defaultMessage: 'Find care for a teen',
      description: 'Pathways: Teen Search option - header',
    }),
    icon: 'CareForChildOrTeenSpotIllustration',
    testId: PathwayOptions.CHILD_SEARCH,
  },
}

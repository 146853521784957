export const POST_COUNTRY_INTERNATIONAL_CONSENT = 'POST_COUNTRY_INTERNATIONAL_CONSENT'

export const postCountryInternationalConsent = (data: {
  acceptInternationalConsent: string
  internationalConsent: { consentText: string; countryId: number; id: number }
  lyraId: string
}) => ({
  action: POST_COUNTRY_INTERNATIONAL_CONSENT,
  request: {
    method: 'post',
    url: `/v1/user/international-consent`,
    data,
  },
  errorHandler: () => null,
})

import React, { FunctionComponent } from 'react'

import { useTheme } from 'styled-components/native'

import { AccessibilityRolesNative } from '../../constants'
import { BaseButton, BaseButtonProps, ButtonSize, ButtonType } from '../baseButton/BaseButton'

export const WordCloudButton: FunctionComponent<WordCloudButtonProps> = (props) => {
  const { breakpoints } = useTheme()
  return (
    <BaseButton
      {...props}
      buttonType={ButtonType.WORDCLOUD}
      size={breakpoints.isMobileSized ? ButtonSize.SMALL : ButtonSize.DEFAULT}
      selected={props.isSelected}
      suppressAccessibilitySelected
      accessibilityRole={AccessibilityRolesNative.CHECKBOX}
    />
  )
}

export interface WordCloudButtonProps
  extends Omit<BaseButtonProps, 'buttonType' | 'size' | 'leftIcon' | 'rightIcon' | 'loading' | 'iconColor'> {
  isSelected: boolean
}

export default WordCloudButton

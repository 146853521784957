import { EAPEligibilityRequest, ELIGIBILITY_TYPES, EligibilityStatus } from '@lyrahealth-inc/shared-app-logic'

export interface verifyDependentEligibilityProps {
  customerName: string
  guardianLyraId: string
  firstname: string
  lastname: string
  dob: string
  fetchEAPEligibility: (args: EAPEligibilityRequest) => any
}

export const verifyDependentEligibility = async ({
  customerName,
  guardianLyraId,
  firstname,
  lastname,
  dob,
  fetchEAPEligibility,
}: verifyDependentEligibilityProps) => {
  const dependentDataForEligibilityCheck: EAPEligibilityRequest = {
    customerName,
    firstname,
    lastname,
    dob,
    lyraId: guardianLyraId,
    eligibilityType: ELIGIBILITY_TYPES.DEPENDENT,
  }

  const eligibilityResponse = await fetchEAPEligibility(dependentDataForEligibilityCheck)
  const eligibilityStatus = eligibilityResponse.eligibilityStatus
  const isEligible = eligibilityStatus === EligibilityStatus.ELIGIBLE

  return isEligible
}

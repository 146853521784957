import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Dimensions, FlatList } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import {
  ArrowIcon,
  ArrowIconDirection,
  ChevronIcon,
  ChevronIconDirection,
  SecondaryTertiaryIconButton,
} from '../../atoms'
import { ButtonSize } from '../../atoms/baseButton/BaseButton'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface CarouselSliderProps<T> {
  cards: T[]
  maxCards: number
  renderCardItems: ({ item }: { item: T }) => React.JSX.Element
  onIndexChange: (index: number) => void
  showButtons: boolean
  showChevronIcons?: boolean
}

const BodyContainer = styled.View(({}) => ({
  flexDirection: 'column',
}))

const FlatListContainer = styled.View<{ theme: ThemeType; showChevronIcons: boolean }>(
  ({ theme, showChevronIcons }) => ({
    width: '100%',
    marginBottom: showChevronIcons ? theme.spacing['12px'] : theme.breakpoints.isMinWidthLaptop ? '55px' : 0,
  }),
)

const CarouselButtonContainer = styled.View<{ theme: ThemeType; showChevronIcons: boolean }>(
  ({ theme, showChevronIcons }) => ({
    flexDirection: 'row',
    margin: 'auto',
    ...(showChevronIcons && { margin: 0, alignSelf: 'flex-end', gap: theme.spacing['8px'] }),
  }),
)

const CarouselLeftButtonContainer = styled.View<{ theme: ThemeType; showChevronIcons: boolean }>(
  ({ theme, showChevronIcons }) => ({
    marginRight: showChevronIcons ? theme.spacing['6px'] : theme.spacing['24px'],
  }),
)

export const CarouselSlider = <T,>({
  cards,
  maxCards,
  renderCardItems,
  onIndexChange,
  showButtons,
  showChevronIcons = false,
}: CarouselSliderProps<T>) => {
  const viewPortWidth = Dimensions.get('window').width
  const flatListRef = useRef<FlatList<T> | null>(null)
  const { formatMessage } = useIntl()
  const [lastIndex, setLastIndex] = useState<number>(0)
  const { colors } = useTheme()

  useEffect(() => {
    const listOfFields = flatListRef.current

    if (listOfFields) {
      listOfFields.scrollToIndex({ index: 0, animated: false })
      setLastIndex(0)
    }
  }, [viewPortWidth])

  /**
   * Increment or decrement to new index
   *
   * @param incrementInterval : number
   */
  const scrollToCardIndex = (incrementInterval: number) => {
    const listOfFields = flatListRef.current
    if (listOfFields) {
      const offsetIndex = lastIndex + incrementInterval * maxCards
      let newIndex = 0
      if (offsetIndex <= 0) {
        listOfFields.scrollToIndex({ index: 0, animated: true })
      } else if (offsetIndex >= cards.length - 1) {
        listOfFields.scrollToIndex({ index: cards.length - 1, animated: true })
        newIndex = cards.length - 1
      } else {
        listOfFields.scrollToIndex({ index: offsetIndex, animated: true })
        newIndex = offsetIndex
      }
      setLastIndex(newIndex)
      onIndexChange(newIndex)
    }
  }

  return (
    <BodyContainer testID={tID('CarouselSlider')}>
      <FlatListContainer showChevronIcons={showChevronIcons}>
        <FlatList ref={flatListRef} data={cards} renderItem={renderCardItems} horizontal scrollEnabled={false} />
      </FlatListContainer>
      {showButtons && (
        <CarouselButtonContainer showChevronIcons={showChevronIcons}>
          <CarouselLeftButtonContainer showChevronIcons={showChevronIcons}>
            <SecondaryTertiaryIconButton
              onPress={() => {
                scrollToCardIndex(-1)
              }}
              testID={
                showChevronIcons
                  ? tID('CarouselSlider-carouselSliderChevronLeftButton')
                  : tID('CarouselSlider-carouselSliderLeftButton')
              }
              leftIcon={
                showChevronIcons ? (
                  <ChevronIcon fillColor={colors.textPrimary} size={24} direction={ChevronIconDirection.LEFT} />
                ) : (
                  <ArrowIcon direction={ArrowIconDirection.LEFT} />
                )
              }
              accessibilityLabel={formatMessage({
                defaultMessage: 'Carousel slider left button',
                description: 'Carousel slider left button',
              })}
              disabled={lastIndex === 0}
              {...(showChevronIcons && { size: ButtonSize.SMALL })}
            />
          </CarouselLeftButtonContainer>
          <SecondaryTertiaryIconButton
            onPress={() => {
              scrollToCardIndex(1)
            }}
            testID={
              showChevronIcons
                ? tID('CarouselSlider-carouselSliderChevronRightButton')
                : tID('CarouselSlider-carouselSliderRightButton')
            }
            leftIcon={
              showChevronIcons ? (
                <ChevronIcon fillColor={colors.textPrimary} size={24} direction={ChevronIconDirection.RIGHT} />
              ) : (
                <ArrowIcon direction={ArrowIconDirection.RIGHT} />
              )
            }
            accessibilityLabel={formatMessage({
              defaultMessage: 'Carousel slider right button',
              description: 'Carousel slider right button',
            })}
            disabled={lastIndex > (cards.length - 1) / maxCards}
            {...(showChevronIcons && { size: ButtonSize.SMALL })}
          />
        </CarouselButtonContainer>
      )}
    </BodyContainer>
  )
}

import { defineMessages } from 'react-intl'

import { MEETING_FORMATS } from '../../models/session/Session'
import { Attendance } from '../../models/user/User'

export const ATTENDANCE_STATUS_MESSAGES = defineMessages({
  [Attendance.ATTENDED]: {
    defaultMessage: 'Attended',
    description: 'Text describing the given user visited therapy session',
  },
  [Attendance.NO_SHOW]: {
    defaultMessage: 'No show',
    description: 'Text describing the given user did not come to therapy session',
  },
  [Attendance.LAST_MINUTE_CANCELLATION]: {
    defaultMessage: 'Last minute cancellation',
    description: 'Text describing the given user canclled appointment without proper notice',
  },
})

type SessionTypeTextItems = {
  [key: string]: string
}
export const SessionTypeText: SessionTypeTextItems = {
  [MEETING_FORMATS.LIVE_MESSAGING]: 'Live messaging',
  [MEETING_FORMATS.VIDEO]: 'Video',
}

import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Menu Icon with three lines currently used as the more
 * tab icon on the bottom nav
 */
export const MenuIcon: FunctionComponent<MenuIconProps> = ({ size = 24, fillColor, isFilled = false }) => {
  const { colors } = useTheme()
  const { fill } = colors.icons.menuIcon

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24'>
      {isFilled ? (
        <>
          <Path
            d='M12 11.25C13.2426 11.25 14.25 10.2426 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 10.2426 10.7574 11.25 12 11.25Z'
            fill={fillColor || fill}
          />
          <Path
            d='M11.9994 1.25001C9.7512 1.2464 7.5585 1.94842 5.73041 3.25714C3.90232 4.56585 2.53103 6.41525 1.8098 8.54468C1.08858 10.6741 1.0538 12.9762 1.71036 15.1264C2.36693 17.2767 3.68173 19.1667 5.46945 20.53L5.59945 20.63C7.44925 22.0066 9.69362 22.7501 11.9994 22.7501C14.3053 22.7501 16.5496 22.0066 18.3994 20.63L18.5294 20.53C20.3172 19.1667 21.632 17.2767 22.2885 15.1264C22.9451 12.9762 22.9103 10.6741 22.1891 8.54468C21.4679 6.41525 20.0966 4.56585 18.2685 3.25714C16.4404 1.94842 14.2477 1.2464 11.9994 1.25001ZM17.9994 19C17.7594 19.2 17.5094 19.39 17.2494 19.57C15.7071 20.6383 13.8756 21.2107 11.9994 21.2107C10.1233 21.2107 8.29176 20.6383 6.74945 19.57C6.48945 19.4167 6.23945 19.2267 5.99945 19C5.7408 18.7762 5.49381 18.5392 5.25945 18.29C5.31798 17.3376 5.7374 16.4433 6.43227 15.7893C7.12715 15.1353 8.04521 14.7708 8.99945 14.77H14.9994C15.9602 14.7713 16.8838 15.1414 17.5797 15.8038C18.2757 16.4662 18.6908 17.3705 18.7394 18.33C18.5041 18.5655 18.2571 18.7891 17.9994 19ZM11.9994 5.25001C12.7411 5.25001 13.4662 5.46995 14.0828 5.882C14.6995 6.29406 15.1802 6.87973 15.464 7.56495C15.7478 8.25017 15.8221 9.00417 15.6774 9.7316C15.5327 10.459 15.1755 11.1272 14.6511 11.6517C14.1267 12.1761 13.4585 12.5333 12.731 12.678C12.0036 12.8227 11.2496 12.7484 10.5644 12.4646C9.87916 12.1807 9.29349 11.7001 8.88144 11.0834C8.46938 10.4667 8.24945 9.74169 8.24945 9.00001C8.24945 8.00545 8.64454 7.05162 9.3478 6.34836C10.0511 5.6451 11.0049 5.25001 11.9994 5.25001Z'
            fill={fillColor || fill}
          />
        </>
      ) : (
        <>
          <Path
            d='M12 12.75C12.7417 12.75 13.4667 12.5301 14.0834 12.118C14.7001 11.706 15.1807 11.1203 15.4645 10.4351C15.7484 9.74984 15.8226 8.99584 15.6779 8.26841C15.5333 7.54098 15.1761 6.8728 14.6517 6.34835C14.1272 5.8239 13.459 5.46675 12.7316 5.32206C12.0042 5.17736 11.2502 5.25162 10.5649 5.53545C9.87971 5.81928 9.29404 6.29993 8.88199 6.91661C8.46993 7.5333 8.25 8.25832 8.25 9C8.25 9.99456 8.64509 10.9484 9.34835 11.6517C10.0516 12.3549 11.0054 12.75 12 12.75ZM12 6.75C12.445 6.75 12.88 6.88196 13.25 7.12919C13.62 7.37643 13.9084 7.72783 14.0787 8.13896C14.249 8.5501 14.2936 9.0025 14.2068 9.43895C14.12 9.87541 13.9057 10.2763 13.591 10.591C13.2763 10.9057 12.8754 11.12 12.439 11.2068C12.0025 11.2936 11.5501 11.249 11.139 11.0787C10.7278 10.9084 10.3764 10.62 10.1292 10.25C9.88196 9.88002 9.75 9.44501 9.75 9C9.75 8.40326 9.98705 7.83097 10.409 7.40901C10.831 6.98705 11.4033 6.75 12 6.75Z'
            fill={fillColor || fill}
          />
          <Path
            d='M22.75 12C22.7537 10.1715 22.2909 8.37221 21.4054 6.77238C20.5199 5.17255 19.2409 3.82504 17.6895 2.85728C16.138 1.88952 14.3653 1.3335 12.5391 1.2418C10.7128 1.1501 8.89339 1.52576 7.2528 2.33325C5.61221 3.14073 4.2047 4.35336 3.16339 5.85644C2.12209 7.35952 1.4814 9.10338 1.30191 10.9231C1.12243 12.7428 1.41009 14.5782 2.13768 16.2558C2.86528 17.9333 4.00876 19.3976 5.45998 20.51C5.50545 20.5554 5.55575 20.5956 5.60998 20.63C7.45604 22.0065 9.69724 22.7501 12 22.7501C14.3027 22.7501 16.5439 22.0065 18.39 20.63C18.4442 20.5956 18.4945 20.5554 18.54 20.51C19.8481 19.5101 20.9084 18.2223 21.6385 16.7465C22.3686 15.2707 22.7489 13.6465 22.75 12ZM2.74998 12C2.75087 10.4918 3.12054 9.00668 3.82678 7.67404C4.53302 6.34139 5.55441 5.20166 6.80196 4.35412C8.04951 3.50658 9.48538 2.97695 10.9845 2.81137C12.4836 2.6458 14.0004 2.84929 15.4029 3.40414C16.8053 3.95898 18.0508 4.84834 19.0309 5.99473C20.0109 7.14112 20.6958 8.50977 21.0258 9.98143C21.3558 11.4531 21.321 12.9831 20.9243 14.4383C20.5277 15.8934 19.7812 17.2294 18.75 18.33C18.7036 17.3679 18.2879 16.4608 17.5893 15.7976C16.8907 15.1344 15.9632 14.7663 15 14.77H8.99998C8.04021 14.7715 7.11774 15.1418 6.42344 15.8045C5.72914 16.4671 5.31617 17.3714 5.26998 18.33C3.65173 16.6197 2.74996 14.3546 2.74998 12Z'
            fill={fillColor || fill}
          />
        </>
      )}
    </Svg>
  )
}

type MenuIconProps = {
  size?: number | string
  fillColor?: string
  isFilled?: boolean
}

import React from 'react'
import { defineMessage } from 'react-intl'
import { Linking } from 'react-native'
import { DEFAULT_QUESTIONS, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'
import { Subhead } from '../atoms/subhead/Subhead'
import { SubheadSize } from '../styles/typeStyles'
import { Link } from '../atoms/link/Link'

export const organon: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return {
    faqQuestions: [
      {
        question: DEFAULT_QUESTIONS.HOW_MUCH_DOES_LYRA_COST,
        answer: (
          <Subhead size={SubheadSize.MEDIUM}>
            {
              ' Lyra’s providers are all in-network with Horizon BCBS. These sessions are billed through the medical plan and subject to in-network co-insurance and deductibles (for details on costs, go to'
            }
            <Link
              text='www.horizonblue.com/organon'
              onPress={() => {
                Linking.openURL('http://www.horizonblue.com/organon')
              }}
            />
            {' or Hera > HR > Lyra Health).'}
          </Subhead>
        ),
      },
      {
        question: DEFAULT_QUESTIONS.WHAT_DOES_LYRA_NOT_COVER,
        answer: (
          <Subhead size={SubheadSize.MEDIUM}>
            {
              'Services that are not covered include: inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, autism spectrum disorder treatment, services for remedial education, mental health coaching, executive coaching, and non-evidence-based behavioral health care. Please check with your medical plan or benefits portal for coverage of these excluded services.\n\n'
            }
            {
              'If you have questions about or need these services please contact your Horizon Health Guide at 1-800-544-1112 for more information.'
            }
          </Subhead>
        ),
      },
    ],
    legalNotice: (
      <CoverageNotice
        excludedServices={defineMessage({
          defaultMessage:
            'Lyra does not cover inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, autism spectrum disorder treatment, services for remedial education, mental health coaching, executive coaching, and non-evidence-based behavioral health care.',
          description:
            'Text for describing the services that Lyra does not cover, displayed as part of the coverage notice',
        })}
        sessionLimit={numberOfSessions}
      />
    ),
  }
}

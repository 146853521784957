import { MessageDescriptor } from 'react-intl'

import { UiSchema } from '@rjsf/core'
import { NestedCondition } from 'json-rules-engine'
import { JSONSchema7 } from 'json-schema'

import { WellnessCheckInValency } from '../../features'

import type * as CSS from 'csstype'

export type SchemaProperties = NonNullable<FieldSchema['properties']>

export type FieldValue = string | boolean | number | Array<string | boolean | number> | { [key: string]: FieldValue }

export type DateFieldDisplay = 'spinner' | 'default'

export type Condition = {
  threshold?: number
  operator?: 'GTE' | 'LTE' // threshold value should be greater than equal to or less than equal to the sum for display
  parentField?: string // name of parent field
  parentValue?: FieldValue | FieldValue[] // values of parent field that we are comparing to
  conditionType?: 'AND' | 'OR' | 'SUM' // in the case of multiple parent fields, this determines if we need some or all of them to qualify
  match?: 'oneOf' | 'isNot' | 'exact' // type of match to parentValues
  checkVisibility?: boolean // are we checking the visibility of the parent (defaults to true)
  items?: Condition[] // array of conditions in the case of multiple parent fields
  parentRegex?: {
    pattern: string | RegExp // regex pattern for comparison to parent value
    flag?: string // optional flags for regex comparison (i.e. 'i')
  }
  noRender?: true // when condition is met the field will not be rendered (as opposed to rendered but hidden)
  fullPageIsConditional?: boolean // optional flag put on an item in a page stating that everything in that page is conditional
}
export type UiMetadata = Omit<UiSchema, 'ui:options'> & {
  'ui:options'?: {
    baseInputStyle?: React.CSSProperties
    inputContainerStyle?: React.CSSProperties
    dropdownContainerStyle?: React.CSSProperties
    dateFieldDisplay?: DateFieldDisplay
    labelStyle?: React.CSSProperties
    selectFieldStyle?: React.CSSProperties
    style?: React.CSSProperties
    customStyles?: { [key: string]: React.CSSProperties }
    rangeContainerStyle?: React.CSSProperties
    rangeLabelStyle?: React.CSSProperties
    customTextStyle?: React.CSSProperties
    labelContainerStyle?: React.CSSProperties
  }
  maxFormWidth?: number
}

export type FormMetadata<TMessage = MessageDescriptor | string> = {
  schema: FieldSchema<TMessage>
  uiSchema: UiMetadata
  initialValues?: Dict
  mobileOnly?: boolean
  maxNumberOfFields?: number
  hideSkip?: boolean
  hideSkipInternational?: boolean
}

export const SCHEMA_MESSAGE_FIELDS = new Set([
  'title',
  'titleInfoPopoverContent',
  'placeholder',
  'content',
  'minLabel',
  'midLabel',
  'maxLabel',
  'correctValueDetails',
  'enumNames',
  'options',
  'shortTitle',
  'subTitle',
  'badge',
  'starLabels',
])

// These should match the options from the Popover component in react-native-popper
export enum InfoPopoverPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP_LEFT = 'top left',
  TOP_RIGHT = 'top right',
  BOTTOM_LEFT = 'bottom left',
  BOTTOM_RIGHT = 'bottom right',
}

// These should match the options from the Popover component in react-native-popper
export enum InfoPopoverTriggerAction {
  HOVER = 'hover',
  PRESS = 'press',
  LONGPRESS = 'longPress',
}

export interface TitleInfoPopover {
  content: string
  header?: string
  footer?: string
  linkType?: 'internal' | 'external'
  webLink?: string
  mobileLink?: string
  icon?: string
  placement?: InfoPopoverPlacement
  popoverTriggerAction?: InfoPopoverTriggerAction
}

/**
 * Properties in `FieldSchema` that display text use `TMessage`.
 *
 * `TMessage` varies depending on `FieldSchema` usage:
 * - In the metadata definition, messages are defined in the default language, with `TMessage = MessageDescriptor`
 * - At render time in form fields and widgets, messages are translated and formatted, either `TMessage = React.ReactNode` or `string`
 */
export interface FieldSchema<TMessage = MessageDescriptor | string> extends Omit<JSONSchema7, RedefinedFields> {
  // JSONSchema specifies this must be a string
  title?: TMessage
  // Non-standard fields
  /**  Additional text after the main `title`. @todo: currently only implemented for radio and checkboxGroup widgets */
  titleSecondary?: TMessage
  titleInfoPopoverContent?: TMessage
  titleInfoPopoverConfig?: Omit<TitleInfoPopover, 'content'>
  InfoPopoverFooterContent?: TMessage
  InfoPopoverFooterContentConfig?: {
    webLink?: string
    mobileLink?: string
    linkType?: 'internal' | 'external'
  }
  popoverText?: TMessage
  placeholder?: TMessage
  accessibilityLabelledBy?: string
  name?: string
  multiline?: boolean
  readOnlyOverride?: boolean
  numberOfLines?: number
  inline?: boolean
  spaceBetweenFields?: number // used if inline is true
  flex?: number[] // Sets the flex value based on the index of schema items in an array
  content?: TMessage
  minLabel?: TMessage
  midLabel?: TMessage
  maxLabel?: TMessage
  condition?: Condition
  imageComponent?: string
  details?: TMessage
  mobilePage?: number
  version?: string
  correctValue?: number
  correctValueDetails?: TMessage
  enum?: Array<string | number | boolean>
  enumNames?: TMessage[]
  enumExclusiveValuesGroups?: number[]
  show?: boolean
  sectionHeader?: boolean
  autocomplete?: string
  // This field is overloaded - typeahead widget reads options as string[]
  options?: { fromContext?: string } | TMessage[]
  shortTitle?: TMessage
  subTitle?: TMessage
  subTitleComponent?: string // custom component name for the subTitle
  titleAlignment?: CSS.Properties['alignItems']
  validation?: {
    [key: string]: boolean | number | Dict
  }
  parseData?: string[]
  formatData?: string[]
  style?: React.CSSProperties
  multiSelect?: boolean
  saveFieldAsArray?: boolean
  allowUserInput?: boolean
  buttonType?: string
  backgroundColor?: string
  badge?: TMessage
  error?: string
  starLabels?: TMessage[]
  widgetProps?: Dict
  testID?: string
  customMetadata?: {
    [key: string]: boolean | string
  }
  dataSource?: string
  maxCharLength?: number
  showCharLimit?: boolean
  largeLabel?: boolean
  largeSubTitle?: boolean
  // flag to tell sliders to output decimal values
  decimalValue?: boolean
  // Prevent showing the field in the ExerciseResponseTable component
  hideTableColumn?: boolean
  // Recursive fields that must be redefined because they use this definition itself:

  // Difference from JSONSchema7: only allows array
  // The value of "items" MUST be either a valid JSON Schema or an array of valid JSON Schemas.
  items?: FieldSchemaDefinition<TMessage>[]

  allOf?: FieldSchemaDefinition<TMessage>[]
  anyOf?: FieldSchemaDefinition<TMessage>[]
  oneOf?: FieldSchemaDefinition<TMessage>[]
  properties?: {
    [key: string]: FieldSchemaDefinition<TMessage>
  }
  customErrorMessage?: string
  showPasswordChecklistByDefault?: boolean
  hidePasswordChecklistOnBlur?: boolean
  hidden?: boolean
  stepLabels?: TMessage[]
  aiPromptEnabled?: boolean
  modifier?: string
  passwordAutoComplete?: 'new-password' | 'current-password'
  engineRuleConditions?: {
    answeredCondition: NestedCondition
    lowWellbeingCondition: NestedCondition
    highWellbeingCondition: NestedCondition
  }
  valency?: WellnessCheckInValency
  noneOfTheAboveCheckboxId?: string
  // JSONSchema7 fields not in use

  // additionalItems?: FieldSchemaDefinition<TMessage>
  // patternProperties?: {
  //   [key: string]: FieldSchemaDefinition<TMessage>
  // }
  // additionalProperties?: FieldSchemaDefinition<TMessage>
  // contains?: FieldSchema<TMessage>
  // dependencies?: {
  //   [key: string]: FieldSchemaDefinition<TMessage> | string[]
  // }
  // propertyNames?: FieldSchemaDefinition<TMessage>
  // if?: FieldSchemaDefinition<TMessage>
  // then?: FieldSchemaDefinition<TMessage>
  // else?: FieldSchemaDefinition<TMessage>
  // not?: FieldSchemaDefinition<TMessage>
  // definitions?: {
  //   [key: string]: FieldSchemaDefinition<TMessage>
  // }
}

// Difference from JSONSchema7Definition: omits boolean
export type FieldSchemaDefinition<T> = FieldSchema<T> /* | boolean */

type RedefinedFields =
  | 'title'
  | 'items'
  | 'additionalItems'
  | 'contains'
  | 'properties'
  | 'patternProperties'
  | 'additionalProperties'
  | 'dependencies'
  | 'propertyNames'
  | 'if'
  | 'then'
  | 'else'
  | 'allOf'
  | 'anyOf'
  | 'oneOf'
  | 'not'
  | 'definitions'

export type postExternalMatchesArgs = { dataSource: string; input: string }
export type postExternalMatchesType = (args: postExternalMatchesArgs) => Promise<string[]>

export enum RadioButtonType {
  TEXT_OUTLINE = 'textOutline',
  CIRCLE_TEXT_OUTLINE = 'circleTextOutline',
  DESCRIPTION_NO_OUTLINE = 'descriptionNoOutline',
  DESCRIPTION_OUTLINE = 'descriptionOutline',
  CIRCLE_DESCRIPTION_OUTLINE = 'circleDescriptionOutline',
  GRID = 'grid', // used for primary needs, responsive and changes to CIRCLE_TEXT_OUTLINE (list format) at certain screen widths or text length
  CIRCULAR_WITH_NUMBER = 'circularWithNumber', // used for Wellness Check In flow
}
export enum CheckboxButtonType {
  TEXT_OUTLINE = 'textOutline',
  CHECKBOX_TEXT_OUTLINE = 'outlinedTextCheckbox',
  CHECKBOX_DESCRIPTION_NO_OUTLINE = 'descriptionNoOutline',
}

import { axiosInstance } from '../../../common/http/axiosInstance'

export const UPDATE_EDITABLE_ZENDESK_TICKET_ID = 'UPDATE_EDITABLE_ZENDESK_TICKET_ID'
export const updateEditableZendeskTicketId = (val: $TSFixMe) => ({
  type: UPDATE_EDITABLE_ZENDESK_TICKET_ID,
  val,
})

export const CLEAR_ZENDESK_TICKET = 'CLEAR_ZENDESK_TICKET'
export const clearZendeskTicket = () => ({ type: CLEAR_ZENDESK_TICKET })

export const SET_ZENDESK_TICKETS = 'SET_ZENDESK_TICKETS'
// @ts-expect-error TS(7006): Parameter 'lyraId' implicitly has an 'any' type.
export const setZendeskTickets = (lyraId, zendeskTickets) => ({
  type: SET_ZENDESK_TICKETS,
  lyraId,
  zendeskTickets,
})

export function addNewZendeskTicket() {
  return function (dispatch: $TSFixMe, getState: $TSFixMe) {
    const state = getState()

    const lyraId = state.getIn(['zendeskTickets', 'lyraId'])
    const externalId = state.getIn(['zendeskTickets', 'editableZendeskTicketId'])

    const query = `
mutation LinkZendeskTicket{
    zendeskTickets: linkZendeskTicket(lyraId:"${lyraId}", externalId: "${externalId}", systemType: "zendesk"){
    lyraId
    externalId
    link
    systemType
  }
}
`
    const data = {
      query,
      variables: { var: 'hi' },
    }
    const url = '/v1/graphql'
    axiosInstance.post(url, data).then(
      ({ data: r }) => {
        dispatch(clearZendeskTicket())
        if (!r.errors) {
          dispatch(setZendeskTickets(lyraId, r.data.zendeskTickets))
        }
      },
      () => {
        dispatch(clearZendeskTicket())
      },
    )
  }
}

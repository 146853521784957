import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Icon for Send Feedback
 */
export const FeedbackIcon: FunctionComponent<FeedbackIconProps> = ({ width = 24, height = 25 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={width} height={height} fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 3a1.75 1.75 0 0 0-1.75 1.75V6H8v.024a5.25 5.25 0 0 0-5.75 5.226v6.5c0 .414.336.75.75.75h7.25v2.25c0 .414.336.75.75.75h4a.75.75 0 0 0 .75-.75V18.5H21a.75.75 0 0 0 .75-.75v-6.5a5.25 5.25 0 0 0-5-5.244V4.75A.25.25 0 0 1 17 4.5h3.5a.75.75 0 0 0 0-1.5H17Zm-1.75 4.5v2.75a.75.75 0 0 0 1.5 0V7.508a3.75 3.75 0 0 1 3.5 3.742V17h-7.5v-5.75a5.234 5.234 0 0 0-1.576-3.75h4.076Zm-4 9.5v-5.75a3.75 3.75 0 1 0-7.5 0V17h7.5Zm.5 1.5V20h2.5v-1.5h-2.5Z'
        fill={colors.iconActive}
      />
    </Svg>
  )
}

type FeedbackIconProps = {
  width?: string | number
  height?: string | number
}

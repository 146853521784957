import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import styled from 'styled-components/native'

import { WellnessCheckInDomain, WellnessCheckInDomainToTitle } from '@lyrahealth-inc/shared-app-logic'

import {
  FinancesAndWorkIllustration,
  MoodAndEmotionsIllustration,
  PhysicalHealthAndActivityIllustration,
  ResilienceAndCopingIllustration,
  RolesAndRelationshipsIllustration,
  SpiritualityAndExplorationIllustration,
} from '../../atoms/illustrations'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { ThemeType } from '../../utils'

const WELLNESS_CHECK_IN_DOMAIN_TO_ICON: Record<WellnessCheckInDomain, React.ReactNode> = {
  [WellnessCheckInDomain.MoodAndEmotions]: <MoodAndEmotionsIllustration />,
  [WellnessCheckInDomain.ResilienceAndCoping]: <ResilienceAndCopingIllustration />,
  [WellnessCheckInDomain.RolesAndRelationships]: <RolesAndRelationshipsIllustration />,
  [WellnessCheckInDomain.PhysicalHealthAndActivity]: <PhysicalHealthAndActivityIllustration />,
  [WellnessCheckInDomain.FinancesAndWork]: <FinancesAndWorkIllustration />,
  [WellnessCheckInDomain.SpiritualityAndExploration]: <SpiritualityAndExplorationIllustration />,
}

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['12px'],
}))

/**
 * Displays domain name and illustration
 * Used in header throughout the Wellness Check In flow
 */
export const WellnessCheckInDomainHeader: FunctionComponent<{ wellnessCheckInDomain: WellnessCheckInDomain }> = ({
  wellnessCheckInDomain,
}) => {
  const intl = useIntl()
  const icon = WELLNESS_CHECK_IN_DOMAIN_TO_ICON[wellnessCheckInDomain]
  return (
    <Container>
      <Subhead
        text={intl.formatMessage(WellnessCheckInDomainToTitle[wellnessCheckInDomain])}
        size={SubheadSize.XSMALL}
      />
      {icon}
    </Container>
  )
}

import { fromJS, Map } from 'immutable'
import get from 'lodash/get'

import { SearchRequestData } from '@lyrahealth-inc/shared-app-logic'

import { axiosInstance } from '../../../../common/http/axiosInstance'

export const SET_ONSITE_PROVIDERS = 'SET_ONSITE_PROVIDERS'
export const setOnsiteProviders = (providers: $TSFixMe) => ({
  type: SET_ONSITE_PROVIDERS,
  providers,
})

export const CLEAR_ONSITE_PROVIDERS = 'CLEAR_ONSITE_PROVIDERS'
export const clearOnsiteProviders = () => ({
  type: CLEAR_ONSITE_PROVIDERS,
})

export const getOnsiteProviders = ({ lyraId, data }: { lyraId?: string; data: SearchRequestData }) => {
  return function (dispatch: $TSFixMe, getState: $TSFixMe) {
    const userId = lyraId ?? getState().getIn(['user', 'id'])
    return axiosInstance.post(`/v1/provider/onsite/${userId}`, data).then((response) => {
      dispatch(setOnsiteProviders(fromJS(get(response, ['data'], Map()))))
      return response
    })
  }
}

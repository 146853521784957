import { ProviderInfo } from '../../models'
import { APPOINTMENT_OPTIONS, TREATMENT_MODE } from '../common/constants/appointmentModalityConstants'

type DataForCrmAppointment = {
  provider: ProviderInfo
  userId: string
}

/**
 * Utility function to generate an object representing an appointment for crm related purposes. This function would
 * be used when needing a faux appointment object for non-appointment-booking purposes.
 * The only valid fields in the returned object are `userId`, representing the ID of the
 * user having the appointment; `lyraProviderId`, representing the ID of the provider providing
 * the appointment; and `supportedVisitTypes`, representing the provider's offered appointment
 * modalities. All other fields are pass-throughs.
 *
 * @param {provider, userId} Data used in creating the crm appointment object
 * @returns {scheduleObj} An object representing a crm appointment
 */
export const createCrmAppointmentObject = ({ provider, userId }: DataForCrmAppointment) => {
  const appointmentType = TREATMENT_MODE[provider.supportedVisitTypes ?? 'NONE'].toLowerCase().includes('inperson')
    ? APPOINTMENT_OPTIONS.NAVIGATOR_INPERSON
    : APPOINTMENT_OPTIONS.NAVIGATOR_VIDEO

  const scheduleObj = {
    userId: userId,
    lyraProviderId: provider.lyra_id,
    appointmentType,
    supportedVisitTypes: provider.supportedVisitTypes,
  }

  return scheduleObj
}

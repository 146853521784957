import {
  ChangeEmailRequest,
  PostEmailVerification,
  PostFamilyHubEmailVerification,
  PostParentLedTeenEmailVerification,
} from '@lyrahealth-inc/shared-app-logic'

import { axiosInstance } from '../axiosInstance'

export function postEmailVerification(data: PostEmailVerification) {
  return axiosInstance.post(`/v1/user/email/verification`, data)
}

export function postParentLedTeenEmailVerification(data: PostParentLedTeenEmailVerification) {
  return axiosInstance.post(`/v1/user/email/verification/parent-led-teen`, data)
}

export const changeEmail = (data: ChangeEmailRequest) => {
  return axiosInstance.post(`/v1/user/email/change`, data)
}

export function postFamilyHubEmailVerification(data: PostFamilyHubEmailVerification) {
  return axiosInstance.post(`/v1/user/email/verification/family-hub`, data)
}

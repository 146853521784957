// @intl project:microsite
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { fromJS } from 'immutable'
import UAParser from 'ua-parser-js'

import { Customer, FetchStatus, ImmutableTypedMap, useFooter } from '@lyrahealth-inc/shared-app-logic'
import { colors, Footer, useMediaQuerySize } from '@lyrahealth-inc/ui-core-crossplatform'

import { getDetectedCountryFetchStatus } from '../../../data/appGlobals/appGlobalsSelectors'
import { getCustomer, getIsCustomerInternational } from '../../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../../data/mixpanel'
import { getId, getIsChatEnabledForUser, getIsUserInternational } from '../../../data/user/userSelectors'
import { useShouldHideFloatingFooter } from '../../hooks/useShouldHideFloatingFooter'
import ChatLive from '../chatLive/ChatLive'

interface FloatingFooterProps {
  $$customer: ImmutableTypedMap<Customer>
  enabledChat: boolean
  isLoggedIn: boolean
  isCustomerInternational: boolean
  isUserInternational: boolean
  detectedCountryFetchStatus: FetchStatus
  hideFooter?: boolean
  trackEventWithObj: ({
    event,
    action,
    page,
  }: {
    event: string
    action?: string
    page?: string
    [key: string]: unknown
  }) => void
}

function FloatingFooter({
  $$customer,
  enabledChat,
  isLoggedIn,
  isCustomerInternational,
  isUserInternational,
  detectedCountryFetchStatus,
  hideFooter = false,
  trackEventWithObj,
}: FloatingFooterProps) {
  const { isMobileSized } = useMediaQuerySize()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { disableLiveChat, startChat, phoneNumber, navigateToCareTeamPage, navigateToFAQ, handleChatLivePress } =
    useFooter({
      customer: fromJS($$customer).toJS(),
      isLoggedIn,
      isCustomerInternational,
      isUserInternational,
      detectedCountryFetchStatus,
      enabledChat,
      pathname,
      trackEventWithObj,
      onNavigate: (path) => navigate(path),
    })

  // Defaults to white unless in modality section
  const footerBackgroundColor = isMobileSized && pathname.includes('modality') ? colors.ui_oatmeal1 : colors.white

  const shouldHideFooter = useShouldHideFloatingFooter()

  useEffect(() => {
    const userAgent = new UAParser(window.navigator.userAgent)
    const browserName = userAgent.getResult().browser.name
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    document.getElementsByTagName('html')[0].setAttribute('class', browserName)
  }, [])

  if (shouldHideFooter) {
    return null
  }

  return !hideFooter ? (
    <>
      <Footer
        isFloating
        backgroundColor={footerBackgroundColor}
        isUserInternational={isUserInternational}
        isCustomerInternational={isCustomerInternational}
        isLoggedIn={isLoggedIn}
        // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        careSupportNumber={phoneNumber}
        onChatLivePress={() => handleChatLivePress()}
        onFAQPress={() => navigateToFAQ()}
        navigateToCareTeamPage={() => navigateToCareTeamPage()}
        disableLiveChat={disableLiveChat}
      />
      <ChatLive hideButton startChat={startChat} />
    </>
  ) : (
    <></>
  )
}

const mapStateToProps = (state: $TSFixMe) => {
  const isCustomerInternational = getIsCustomerInternational(state)
  const isUserInternational = getIsUserInternational(state)

  return {
    $$customer: getCustomer(state),
    isCustomerInternational,
    isUserInternational,
    enabledChat: getIsChatEnabledForUser(state),
    isLoggedIn: Boolean(getId(state)),
    detectedCountryFetchStatus: getDetectedCountryFetchStatus(state),
  }
}

export default connect(mapStateToProps, { trackEventWithObj })(FloatingFooter)

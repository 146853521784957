import { defineMessages } from 'react-intl'

export const NotificationChannelStrings = defineMessages({
  sessions: {
    defaultMessage: 'Sessions',
    description: 'Header next to email, text, and mobile app notification preferences regarding a user’s sessions',
  },
  sessionsDescription: {
    defaultMessage: 'Get notified about upcoming sessions, rescheduling, or cancellations.',
    description:
      'Description under ‘Sessions‘ header and next to email, text, and mobile app notification preferences regarding a user‘s sessions',
  },
  activities: {
    defaultMessage: 'Activities',
    description:
      'Header next to email, text, and mobile app notification preferences regarding a user’s activity reminders',
  },
  activitiesDescription: {
    defaultMessage: 'Reminders to complete activities before session.',
    description:
      'Description under ‘Activities‘ header and next to email, text, and mobile app notification preferences regarding a user’s activity reminders',
  },
  messages: {
    defaultMessage: 'Provider messages',
    description:
      'Header next to email, text, and mobile app notification preferences when a provider contacts the user',
  },
  messagesDescription: {
    defaultMessage: 'Notifications for when your provider sends you a message.',
    description:
      'Description under Messages header and next to email, text, and mobile app notification preferences when a provider contacts the user',
  },
  promotions: {
    defaultMessage: 'Lyra updates',
    description: 'Header next to email, text, and mobile app notification preferences regarding a user’s promotions',
  },
  promotionsDescription: {
    defaultMessage: 'Receive announcements, updates, or general information about our services.',
    description:
      'Description under Lyra updates header and next to email, text, and mobile app notification preferences regarding a user‘s promotions',
  },
  customNotifications: {
    defaultMessage: 'Custom Reminder',
    description: 'Header to tell the user they can set reminders based on their convenience',
  },
})

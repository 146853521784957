import React, { FunctionComponent } from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { ChevronV2Icon, GenericListItem } from '../../../atoms'
import { tID } from '../../../utils/utils'
import { getPathwayTypes, PATHWAY_ILLUSTRATIONS } from '../../utils'

const PathwayTypes = getPathwayTypes({ isMobileView: true })
export interface PathwayListItemProps {
  title: MessageDescriptor
  illustration: PATHWAY_ILLUSTRATIONS
  onPress: () => void
}

export const PathwayListItem: FunctionComponent<PathwayListItemProps> = ({ illustration, onPress, title }) => {
  const { Component: Icon, testID } = PathwayTypes[illustration]
  const { colors } = useTheme()

  return (
    <GenericListItem
      testID={tID(`PathwayListItem-${testID}`)}
      title={<FormattedMessage {...title} />}
      icon={<Icon width={64} height={64} />}
      fullBorder
      height={'88px'}
      onClick={() => {
        onPress()
      }}
      rightItems={[
        <ChevronV2Icon key={`PathwayListItem-${testID}-rightArrow`} fillColor={colors.iconInactive} size={12} />,
      ]}
      containerStyle={{
        background: colors.backgroundPrimary,
        borderRadius: 16,
        minHeight: 76,
        width: 350,
        marginTop: 12,
      }}
      innerContainerStyle={{ margin: 0, padding: 12 }}
      textStyle={{ width: 220 }}
    />
  )
}

// @intl project:microsite

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'

import cx from 'classnames'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { DefaultButton, TextButton, XIcon } from '@lyrahealth-inc/ui-core'
import { useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './cookieBanner.module.scss'
import {
  getShouldShowCookieBannerAndSettings,
  setShouldShowCookieBanner,
} from '../../../data/appGlobals/appGlobalsActions'
import { getShouldShowCookieBannerSelector } from '../../../data/appGlobals/appGlobalsSelectors'
import { useReloadOneTrustBanner } from '../../../hooks/useReloadOneTrustBanner'
import { HAS_CONSENTED_TO_SITE_COOKIES } from '../../constants/appConstants'

interface CookieBannerProps {
  getShouldShowCookieBannerAndSettings: boolean
  setShouldShowCookieBanner: (val: boolean) => ThunkAction<Promise<void>, Map<string, any>, unknown, AnyAction>
  shouldShowCookieBanner: boolean
}

const CookieBanner = ({
  getShouldShowCookieBannerAndSettings,
  setShouldShowCookieBanner,
  shouldShowCookieBanner,
}: CookieBannerProps) => {
  useFetcher([getShouldShowCookieBannerAndSettings])
  const intl = useIntl()
  const customizeCookieLinkText = intl.formatMessage({
    defaultMessage: 'Customize',
    description: "Button to customize which cookies are tracked on a user's computer",
  })
  useReloadOneTrustBanner(shouldShowCookieBanner, customizeCookieLinkText)

  const banner = (
    <div className={styles['banner-container']}>
      <p className={styles.banner}>
        <FormattedMessage
          defaultMessage='This website uses cookies for performance and functionality—not for advertising.'
          description='Consent to cookies, banner text'
        />{' '}
        {/* @ts-expect-error TS(2741): Property 'onClick' is missing in type '{ isLink: t... Remove this comment to see the full error message */}
        <TextButton
          isLink
          href={'#'}
          text={customizeCookieLinkText}
          className={cx(styles.cta, 'ot-sdk-show-settings')} // Hardcoded class name from OneTrust
        />
      </p>
      <DefaultButton
        unstyled
        onClick={() => {
          setShouldShowCookieBanner(false)
          localStorage.setItem(HAS_CONSENTED_TO_SITE_COOKIES, JSON.stringify(true))
        }}
        aria-label={intl.formatMessage({
          defaultMessage: 'Close cookie banner',
          description: 'Button to close the cookie banner after popping on the screen',
        })}
      >
        <XIcon isFilled fillColor={styles.x_brand} height={24} width={24} />
      </DefaultButton>
    </div>
  )
  return shouldShowCookieBanner ? banner : null
}

const mapStateToProps = (state: any) => {
  const hasConsentedToSiteCookies = localStorage.getItem(HAS_CONSENTED_TO_SITE_COOKIES)
  return {
    shouldShowCookieBanner: getShouldShowCookieBannerSelector(state) && !hasConsentedToSiteCookies,
  }
}

export default connect(mapStateToProps, { getShouldShowCookieBannerAndSettings, setShouldShowCookieBanner })(
  // @ts-expect-error TS(2345): Argument of type '({ getShouldShowCookieBannerAndS... Remove this comment to see the full error message
  CookieBanner,
)

import React, { FunctionComponent } from 'react'
import { Field, useFormState } from 'react-final-form'

import rjsfFields from '@rjsf/core/lib/components/fields'
import { includes } from 'lodash-es'
import styled from 'styled-components/native'

import {
  audInformedConsent,
  coachingInformedConsent,
  coachingInformedConsentNonUS,
  informedConsent,
  isFieldDisplayed,
  medsInformedConsent,
  singleSessionCoachingInformedConsent,
  teensTherapyInformedConsent,
} from '@lyrahealth-inc/shared-app-logic'

import { type UICoreFieldProps } from './types'
import { FeedbackStars } from '../../../src/molecules/feedbackStars/FeedbackStars'
import { ClinicalScreenerHeader } from '../../molecules/clinicalScreenerHeader/ClinicalScreenerHeader'
import { ExperienceWithCareHeader } from '../../molecules/experienceWithCareHeader/ExperienceWithCareHeader'
import { InformedConsent } from '../../molecules/informedConsent/InformedConsent'
import { SUDSTable, TeensDistressTable } from '../../molecules/sudsTable/SUDSTable'
import { Flex1View } from '../../templates/content/CommonViews'
import { tID } from '../../utils'

const { SchemaField } = rjsfFields

const ConditionContainer = styled(Flex1View)<{ hide: boolean }>`
  ${({ hide }) =>
    hide &&
    `
    display: none;
    opacity: 0;
  `}
`

const SchemaFieldContainer = styled(SchemaField)`
  flex-grow: 1;
`

/**
 * Add custom components here such as SUDSTable, InformedConsent and FeedbackStars
 */

const addTitleStar = (props: UICoreFieldProps): UICoreFieldProps => {
  const title = props.schema?.title
  if (props.registry?.formContext.showAsteriskForRequiredFields && title !== undefined) {
    if (Boolean(props.schema?.validation?.isRequired) && title[title.length - 1] !== '*') {
      const newProps = { ...props, schema: { ...props.schema, title: `${props.schema.title} *` } }
      return newProps
    }
  }
  return props
}

const CustomSchemaField: React.FC<UICoreFieldProps> = (props) => {
  const newProps = addTitleStar(props)
  if (newProps.schema?.show === false) {
    return null
  }
  if (newProps.schema?.condition) {
    return <Condition {...newProps} />
  }
  return <SchemaFieldContainer {...newProps} />
}

const Condition: React.FC<UICoreFieldProps> = (props) => {
  const { values } = useFormState()
  const limitedFields = props.registry?.formContext?.limitedFields
  const hide = limitedFields
    ? !includes(limitedFields, props.schema?.name)
    : !isFieldDisplayed({
        condition: props.schema?.condition,
        formValues: { ...values, ...props.registry?.formContext?.externalValues },
        formFields: props.registry?.formContext?.schema?.properties,
        name: props.schema?.name,
      })
  const newProps = props?.schema ? { ...props, schema: { ...props.schema, hidden: hide } } : props
  if (hide && newProps.schema?.condition?.noRender) {
    return null
  } else {
    return (
      <ConditionContainer
        hide={hide}
        accessibilityHidden={hide}
        testID={tID(`conditionField-${hide ? 'hide' : 'show'}`)}
      >
        <SchemaField {...newProps} />
      </ConditionContainer>
    )
  }
}

export const fields: { [name: string]: FunctionComponent } = {
  UnsupportedField: ({ schema: name }: UICoreFieldProps) => {
    console.error(`Unsupported field defined - ${name.name}`)
    return null
  },
  SchemaField: CustomSchemaField,
  SUDSTable: () => <SUDSTable />,
  TeensDistressTable: () => <TeensDistressTable />,
  FeedbackStars: ({ schema: { name, starLabels, testID, backgroundColor } }: UICoreFieldProps) => (
    <Field
      name={name ?? ''}
      starLabels={starLabels}
      periwinkle={backgroundColor === 'periwinkle'}
      testID={testID}
      component={FeedbackStars}
    />
  ),
  InformedConsent: ({ schema: { title, name } }: UICoreFieldProps) => (
    <InformedConsent title={title} name={name} content={informedConsent} />
  ),
  MedsInformedConsent: ({ schema: { title, name } }: UICoreFieldProps) => (
    <InformedConsent title={title} name={name} content={medsInformedConsent} />
  ),
  CoachingInformedConsent: ({ schema: { title, name } }: UICoreFieldProps) => (
    <InformedConsent title={title} name={name} content={coachingInformedConsent} />
  ),
  CoachingInformedConsentNonUS: ({ schema: { title, name } }: UICoreFieldProps) => (
    <InformedConsent title={title} name={name} content={coachingInformedConsentNonUS} />
  ),
  SingleSessionCoachingInformedConsent: ({ schema: { title, name } }: UICoreFieldProps) => (
    <InformedConsent title={title} name={name} content={singleSessionCoachingInformedConsent} />
  ),
  AudInformedConsent: ({ schema: { title, name } }: UICoreFieldProps) => (
    <InformedConsent title={title} name={name} content={audInformedConsent} />
  ),
  TeensTherapyInformedConsent: ({ schema: { title, name } }: UICoreFieldProps) => (
    <InformedConsent title={title} name={name} content={teensTherapyInformedConsent} />
  ),
  ClinicalScreenerHeader: ({ schema: { title, subTitle, name } }: UICoreFieldProps) => (
    <ClinicalScreenerHeader title={title} subTitle={subTitle} name={name} />
  ),
  ExperienceWithCareHeader: ({ schema: { title, name } }: UICoreFieldProps) => (
    <ExperienceWithCareHeader title={title} name={name} />
  ),
}

import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Icon for Touch ID
 */
export const TouchIdIcon: FunctionComponent<TouchIdIconProps> = ({ size = 24 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.25 6A3.75 3.75 0 0 1 6 2.25h1a.75.75 0 0 1 0 1.5H6A2.25 2.25 0 0 0 3.75 6v1a.75.75 0 0 1-1.5 0V6Zm14-3a.75.75 0 0 1 .75-.75h1A3.75 3.75 0 0 1 21.75 6v1a.75.75 0 0 1-1.5 0V6A2.25 2.25 0 0 0 18 3.75h-1a.75.75 0 0 1-.75-.75Zm5.5 14a.75.75 0 0 0-1.5 0v1A2.25 2.25 0 0 1 18 20.25h-1a.75.75 0 0 0 0 1.5h1A3.75 3.75 0 0 0 21.75 18v-1ZM3 16.25a.75.75 0 0 1 .75.75v1A2.25 2.25 0 0 0 6 20.25h1a.75.75 0 0 1 0 1.5H6A3.75 3.75 0 0 1 2.25 18v-1a.75.75 0 0 1 .75-.75Zm8.83-8.483c1.372-.165 2.973.909 3.559 2.477a.75.75 0 0 0 1.405-.525c-.774-2.073-2.943-3.705-5.143-3.441a.75.75 0 0 0 .179 1.489Zm-1.912.51A.75.75 0 0 0 9.46 6.85c-1.209.387-2.103 1.148-2.62 2.195-.508 1.03-.619 2.273-.4 3.6.125.764.055 1.287-.035 1.812l-.025.146c-.088.5-.191 1.085-.083 1.81a.75.75 0 1 0 1.483-.222c-.07-.474-.008-.833.081-1.35l.023-.131c.102-.597.2-1.308.035-2.308-.182-1.111-.066-2.018.267-2.694.325-.659.885-1.159 1.732-1.43Zm.307 3.415c-.116-.426-.047-.732.087-.954.142-.239.397-.446.741-.577.725-.278 1.558-.13 1.985.348.237.265.483.745.661 1.39.175.634.27 1.366.25 2.084-.044 1.503-.56 2.63-1.467 3.012a.75.75 0 1 0 .58 1.383c1.753-.737 2.338-2.68 2.386-4.352a8.712 8.712 0 0 0-.303-2.526c-.211-.763-.538-1.488-.989-1.99-.946-1.06-2.494-1.189-3.64-.75-.59.226-1.143.625-1.492 1.208-.357.598-.462 1.328-.246 2.119.22.804.083 1.462-.075 2.226l-.06.296c-.086.426-.168.922-.105 1.436.067.542.287 1.063.73 1.558a.75.75 0 0 0 1.119-1c-.244-.272-.332-.51-.36-.74-.032-.26.005-.553.086-.96.017-.087.037-.178.057-.272.16-.752.376-1.765.055-2.94Zm2.404-.123a.75.75 0 0 0-1.438.425c.38 1.285.227 2.719-.039 3.475a.75.75 0 1 0 1.416.497c.36-1.026.538-2.783.061-4.397Zm4.944.255a.75.75 0 1 0-1.453.37c.307 1.205.24 2.812-.36 4.09a.75.75 0 0 0 1.359.637c.765-1.63.837-3.599.454-5.097Z'
        fill={colors.iconActive}
      />
    </Svg>
  )
}

type TouchIdIconProps = {
  size?: string | number
}

import React from 'react'
import { excludedServicesNotice } from './defaultWithoutMeds'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'
import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

export const tesla: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  const sessionLimits = `as many in-person or live video sessions as are clinically indicated by your Lyra provider, up to ${numberOfSessions} sessions, as long as you are eligible for benefits from your employer`
  const servicesCovered = `Lyra can help with stress, anxiety, depression, relationship issues, sleep disorders, and other common behavioral issues. Lyra has partnered with Tesla to cover the cost of outpatient therapy for ${sessionLimits}.`
  return {
    legalNotice: <CoverageNotice excludedServices={excludedServicesNotice} sessionLimit={numberOfSessions} />,
    servicesCovered,
  }
}

import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { ThemeType, tID } from '../../utils'

/**
 * Icon for Person
 */
export const PersonIcon: FunctionComponent<PersonIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme() as ThemeType

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' testID={tID('PersonIcon')}>
      <Path
        id='Vector'
        d='M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        id='Rectangle 10416'
        d='M21 22V20C21 17.2386 18.7614 15 16 15H8C5.23858 15 3 17.2386 3 20V22'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  )
}

type PersonIconProps = {
  size?: string | number
  fillColor?: string
}

import { useEffect, useState } from 'react'

import jwtDecode from 'jwt-decode'
import queryString from 'query-string'
import { v4 as uuidv4 } from 'uuid'

import { useScript } from '@lyrahealth-inc/shared-app-logic'

import { BASIC_INFO_FORM_PATH } from '../constants/registrationFormConstants'

declare global {
  interface Window {
    AppleID?: {
      auth: any
      init: any
    }
  }
}

export interface AppleJwtCredentials {
  email: string
}

export interface AppleJwtResponse {
  authorization?: {
    code: string
    id_token: string
    state: string
  }
  user?: {
    name: {
      firstName: string
      lastName: string
    }
    email: string
  }
}

export interface AppleSsoQueryParameters {
  isSSO: boolean
  id?: string
  firstName?: string
  lastName?: string
}

const APPLE_AUTH_SCRIPT = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'

export const useAppleAuth = (
  appleSSOClientId: string,
): {
  launchAppleAuthSignUp: () => Promise<AppleJwtResponse | undefined>
  appleSsoReady: boolean
} => {
  const secureString = uuidv4()
  const scriptStatus = useScript(APPLE_AUTH_SCRIPT, true, undefined)
  const [appleSsoReady, setAppleSsoReady] = useState(false)

  useEffect(() => {
    if (scriptStatus !== 'ready' || !appleSSOClientId) return

    const { AppleID } = window
    if (!AppleID) return

    window.AppleID?.auth.init({
      clientId: appleSSOClientId,
      scope: 'email name',
      redirectURI: window.location.origin,
      usePopup: true,
      state: secureString,
    })
    setAppleSsoReady(true)
  }, [appleSSOClientId, scriptStatus, secureString])

  const launchAppleAuthSignUp = async () => {
    const { AppleID } = window
    if (!AppleID) return

    try {
      const appleAuthResponse: AppleJwtResponse = await AppleID.auth.signIn()
      const { authorization, user } = appleAuthResponse

      if (authorization?.state !== secureString) {
        throw new Error('Apple authorization response state does not match Lyra Web client state')
      }

      const queryParameters: AppleSsoQueryParameters = {
        isSSO: true,
      }

      if (authorization?.id_token) {
        const { email }: AppleJwtCredentials = jwtDecode(authorization.id_token)
        queryParameters.id = email
      } else {
        throw new Error('Invalid authentication response received from Apple. Unable to parse user’s email from JWT.')
      }

      // Apple returns a `user` property in the authorization object for users who sign in for the first time
      if (user) {
        const { firstName, lastName } = user?.name
        queryParameters.firstName = firstName
        queryParameters.lastName = lastName
      }

      const registrationUrl = new URL(BASIC_INFO_FORM_PATH, document.referrer)
      registrationUrl.search = queryString.stringify(queryParameters)
      window.parent.location.href = registrationUrl.toString()

      return appleAuthResponse
    } catch (e: any) {
      const didUserCloseModal = !!e.error
      const errorMessage = didUserCloseModal ? e.error : e.message
      console.error('Failed to initiate Apple authentication: ' + errorMessage)

      if (!didUserCloseModal) {
        throw e
      } else {
        return
      }
    }
  }

  return { launchAppleAuthSignUp, appleSsoReady }
}

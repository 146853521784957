import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { ImageSourcePropType, ScrollView, useWindowDimensions } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { WellbeingResponse } from '@lyrahealth-inc/shared-app-logic'

import wellnessCheckInResultsHeaderImage from '../../assets/WellnessCheckInOverviewIllustration.png'
import wellnessCheckInResultsHeaderBackground from '../../assets/WellnessCheckInResultsHeaderBackground.png'
import wellnessCheckInResultsHeaderBackgroundMobile from '../../assets/WellnessCheckInResultsHeaderBackgroundMobile.png'
import { BodyText, Image, Subhead } from '../../atoms'
import {
  WellnessCheckInResultsFullViewDomain,
  WellnessCheckInResultsGetStarted,
  WellnessCheckInResultsMobileViewDomain,
  WellnessCheckInResultsSocialNeeds,
} from '../../molecules'
import { WellnessCheckInResultsEmptyState } from '../../molecules/wellnessCheckInResultsEmptyState/WellnessCheckInResultsEmptyState'
import { WellnessCheckInResultsPreviewOverlay } from '../../molecules/wellnessCheckInResultsPreviewOverlay/WellnessCheckInResultsPreviewOverlay'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { filterInvalidDomains } from '../../utils/wellnessCheckInResults/utils'

export interface WellnessCheckInResultsProps {
  wellbeingResponse?: WellbeingResponse
  onGetStartedPress?: () => void
  onLearnMorePress?: () => void
  isSocialNeedEnabled: boolean
  onArticlePress?: (subPath: string) => void
  onEmptyStatePress?: () => void
  isPreview?: boolean
  previewComponents?: React.ReactElement
}

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingBottom: theme.breakpoints.isMobileSized ? theme.spacing['56px'] : theme.spacing['48px'],
}))

const BackgroundImageHeaderContainer = styled(Image)(({}) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
}))

const BackgroundImageHeaderMobileContainer = styled(Image)<{ width: number }>(({ width }) => ({
  width: '600px',
  height: '100%',
  position: 'absolute',
  left: `-${(600 - width) / 2}px`,
}))

const ImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  width: theme.breakpoints.isMobileSized ? '112px' : '94px',
  height: theme.breakpoints.isMobileSized ? '112px' : '94px',
  margin: 'auto',
  marginBottom: theme.spacing['24px'],
  marginTop: '68px',
}))

const TitleContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

const ScrollViewContainer = styled(ScrollView)<{ isPreview: boolean; windowHeight: number }>(
  ({ isPreview, windowHeight }) => ({
    ...(isPreview && { maxHeight: 0.7 * windowHeight, overflow: 'hidden' }),
  }),
)

export const WellnessCheckInResults: FunctionComponent<WellnessCheckInResultsProps> = ({
  wellbeingResponse,
  onGetStartedPress = () => {},
  onLearnMorePress = () => {},
  isSocialNeedEnabled,
  onArticlePress = () => {},
  onEmptyStatePress = () => {},
  isPreview = false,
  previewComponents,
}) => {
  const { breakpoints } = useTheme()
  const { height, width } = useWindowDimensions()

  if (!wellbeingResponse) {
    return <WellnessCheckInResultsEmptyState onEmptyStatePress={onEmptyStatePress} />
  }

  const validWellbeingResponse = filterInvalidDomains(wellbeingResponse)
  const domains = Object.keys(validWellbeingResponse)
  const isEmptyState = domains.length === 0

  return (
    <>
      {isEmptyState ? (
        <WellnessCheckInResultsEmptyState onEmptyStatePress={onEmptyStatePress} />
      ) : (
        <>
          {isPreview && previewComponents && (
            <WellnessCheckInResultsPreviewOverlay>{previewComponents}</WellnessCheckInResultsPreviewOverlay>
          )}
          <ScrollViewContainer
            testID={tID(`WellnessCheckInResults`)}
            scrollEnabled={!isPreview}
            nestedScrollEnabled={!isPreview}
            isPreview={isPreview}
            pointerEvents={isPreview ? 'none' : undefined}
            windowHeight={height}
          >
            <HeaderContainer>
              {breakpoints.isMinWidthMobileM ? (
                <BackgroundImageHeaderContainer
                  source={wellnessCheckInResultsHeaderBackground as ImageSourcePropType}
                  contentFit='fill'
                  accessibilityIgnoresInvertColors
                  resizeMode={'center'}
                />
              ) : (
                <BackgroundImageHeaderMobileContainer
                  source={wellnessCheckInResultsHeaderBackgroundMobile as ImageSourcePropType}
                  contentFit='fill'
                  accessibilityIgnoresInvertColors
                  resizeMode={'center'}
                  width={width}
                />
              )}
              <ImageContainer
                source={wellnessCheckInResultsHeaderImage as ImageSourcePropType}
                contentFit='cover'
                accessibilityIgnoresInvertColors
              />
              <TitleContainer>
                <Subhead
                  level='2'
                  size={SubheadSize.LARGE}
                  text={
                    <FormattedMessage
                      defaultMessage='Your wellness results'
                      description='Title for wellness check in result header section'
                    />
                  }
                  textAlign='center'
                />
              </TitleContainer>
              <BodyText
                text={
                  <FormattedMessage
                    defaultMessage='Compiled by Lyra’s clinical experts'
                    description='Description for wellness check in result header section'
                  />
                }
                size={BodyTextSize.DEFAULT}
                textAlign='center'
              />
            </HeaderContainer>
            {breakpoints.isMobileSized ? (
              <WellnessCheckInResultsMobileViewDomain
                wellbeingResponse={validWellbeingResponse}
                onArticlePress={onArticlePress}
                isPreview={isPreview}
              />
            ) : (
              <WellnessCheckInResultsFullViewDomain
                wellbeingResponse={validWellbeingResponse}
                onArticlePress={onArticlePress}
                isPreview={isPreview}
              />
            )}
            <WellnessCheckInResultsGetStarted onPress={onGetStartedPress} />
            {isSocialNeedEnabled && <WellnessCheckInResultsSocialNeeds onPress={onLearnMorePress} />}
          </ScrollViewContainer>
        </>
      )}
    </>
  )
}

import React, { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { toNumber } from 'lodash-es'

import { getWellnessCheckInTotalNumPages } from '@lyrahealth-inc/shared-app-logic'
import { WellnessCheckInForm } from '@lyrahealth-inc/ui-core-crossplatform'

import {
  WELLNESS_CHECK_IN_PAGE_TYPE,
  WellnessCheckInHeader,
} from '../../common/components/header/WellnessCheckInHeader'
import { LOCAL_STORAGE_KEY } from '../../common/constants/appConstants'
import { useShouldShowWellnessCheckIn } from '../../common/hooks/useShouldShowWellnessCheckIn'
import { getIsLyraSocialCareEnabled } from '../../data/customer/customerSelectors'

const WellnessCheckInFormContainer: FunctionComponent = ({}) => {
  const navigate = useNavigate()
  const socialCareEnabled = useSelector(getIsLyraSocialCareEnabled)
  const showWellnessCheckIn = useShouldShowWellnessCheckIn()
  const { pageIndex } = useParams()
  const currentPage = toNumber(pageIndex)
  const totalPages = getWellnessCheckInTotalNumPages(socialCareEnabled)
  const validPageNumber = currentPage <= totalPages

  useEffect(() => {
    if (!showWellnessCheckIn) {
      navigate('/')
    } else if (!validPageNumber) {
      navigate('/wellness-check-in')
    }
  }, [showWellnessCheckIn, navigate, validPageNumber])

  const onSubmit = (values: object) => {
    localStorage.setItem(LOCAL_STORAGE_KEY.WELLNESS_CHECK_IN, JSON.stringify(values))
    navigate('/wellness-check-in/preview')
  }

  return (
    <>
      <WellnessCheckInHeader pageType={WELLNESS_CHECK_IN_PAGE_TYPE.Questionnaire} />
      <WellnessCheckInForm
        currentPage={currentPage}
        totalPages={totalPages}
        socialCareEnabled={socialCareEnabled}
        onSubmit={onSubmit}
        onCTAPress={() => navigate(`/wellness-check-in/${currentPage + 1}`)}
      />
    </>
  )
}

export default WellnessCheckInFormContainer

import { defineMessage, MessageDescriptor } from 'react-intl'

import { TREATMENT_OPTIONS, TREATMENT_OPTIONS_TYPE } from './treatmentOptions'
import { Episode, ProgramNames, PROVIDER_TYPES, ProviderEnums, ProviderProgramTaxonomy } from '../../../models'

const {
  COACHING,
  LIVE_MESSAGING_COACHING,
  DIRECT_ACCESS_MEDS,
  BLENDED_CARE_MEDS,
  INDIVIDUAL_THERAPY,
  FAMILY_THERAPY,
  COUPLES_THERAPY,
  SELF_CARE_APPS,
  FAMILY_THERAPY_CHILD,
  INDIVIDUAL_THERAPY_CHILD,
  DIRECT_ACCESS_MEDS_CHILD,
  GUIDED_SELF_CARE,
  ALCOHOL_USE_DISORDER,
  CLINICAL_LEAVE_EVALUATION,
} = TREATMENT_OPTIONS

const {
  [ProviderEnums.COACH]: COACH,
  [ProviderEnums.THERAPIST]: THERAPIST,
  [ProviderEnums.PSYCHIATRIST]: PSYCHIATRIST,
  [ProviderEnums['PSYCHIATRIC NURSE PRACTITIONER']]: PSYCHIATRIC_NURSE_PRACTITIONER,
  [ProviderEnums.PROVIDER]: PROVIDER,
  [ProviderEnums.LYRATHERAPIST]: LYRATHERAPIST,
  [ProviderEnums.LYRAPRESCRIBER]: LYRAPRESCRIBER,
  [ProviderEnums.LYRAFELLOW]: LYRAFELLOW,
  [ProviderEnums.LYRACLINICALLEAVEEVALUATOR]: LYRACLINICALLEAVEEVALUATOR,
  [ProviderEnums.LYRATEENSTHERAPIST]: LYRATEENSTHERAPIST,
} = PROVIDER_TYPES

export const COLLOQUIAL_STRINGS_FOR_TREATMENT_OPTIONS = {
  [COACHING]: 'Coaching',
  [LIVE_MESSAGING_COACHING]: 'Live Messaging Coaching',
  [GUIDED_SELF_CARE]: 'Guided Self-Care',
  [DIRECT_ACCESS_MEDS]: 'Medication Consult - DA Meds',
  [BLENDED_CARE_MEDS]: 'Medication Consult - BC Meds',
  [INDIVIDUAL_THERAPY]: 'Individual Therapy',
  [FAMILY_THERAPY]: 'Family Therapy',
  [COUPLES_THERAPY]: 'Couples Therapy',
  [SELF_CARE_APPS]: 'Mental Wellness Tools',
  [FAMILY_THERAPY_CHILD]: 'Family Therapy - Child',
  [INDIVIDUAL_THERAPY_CHILD]: 'Child and Adolescent Therapy',
  [DIRECT_ACCESS_MEDS_CHILD]: 'Medication Consult - Child',
  [ALCOHOL_USE_DISORDER]: 'Alcohol Use Disorder',
  [CLINICAL_LEAVE_EVALUATION]: 'Clinical Leave Evaluation',
}

// After a user selects a treatment option, we want to tailor the terminology used on subsequent
// pages to refer to the recommended care givers for the treatment in question. In some cases this
// maps to a specific provider type, but in others like Direct Access Meds, where multiple
// types of providers surface, we instead default to the generic "provider" type
export const TRIAGE_TREATMENT_OPTIONS_TO_PROVIDER_TYPES_FOR_DISPLAY = {
  [BLENDED_CARE_MEDS]: LYRAPRESCRIBER,
  [COACHING]: COACH,
  [DIRECT_ACCESS_MEDS]: PROVIDER,
  [DIRECT_ACCESS_MEDS_CHILD]: PROVIDER,
  [INDIVIDUAL_THERAPY]: THERAPIST,
  [FAMILY_THERAPY]: THERAPIST,
  [COUPLES_THERAPY]: THERAPIST,
  [FAMILY_THERAPY_CHILD]: THERAPIST,
  [INDIVIDUAL_THERAPY_CHILD]: THERAPIST,
  [GUIDED_SELF_CARE]: COACH,
  [CLINICAL_LEAVE_EVALUATION]: LYRACLINICALLEAVEEVALUATOR,
}

export const PROGRAM_TAXONOMY = {
  clientele: {
    individual: 'Individual',
    couples: 'Couples',
    family: 'Family',
    group: 'Group',
  },
  treatment: {
    therapy: 'Therapy',
    medicationManagement: 'MedicationManagement',
    coaching: 'Coaching',
    assessment: 'Assessment',
  },
  offering: {
    default: 'Default',
    singleSession: 'SingleSession',
    alcoholUseDisorder: 'AlcoholUseDisorder',
    clinicalLeave: 'ClinicalLeave',
    fellowship: 'Fellowship',
    parenting: 'Parenting',
  },
  partner: {
    blendedCare: 'BlendedCare',
    directAccess: 'DirectAccess',
    icas: 'Icas',
  },
}

export type Consent = {
  consentText: MessageDescriptor
  consentUrl: string
  showConsentIf: (options?: { isInternational?: boolean }) => boolean
}

export enum ProgramCustomerPropertyId {
  alcoholUseDisorderTherapy = 'alcoholUseDisorderTherapy',
  blendedCareMeds = 'blendedCareMeds',
  clinicalLeaveEvaluation = 'clinicalLeaveEvaluation',
  coachingForParents = 'coachingForParents',
  considerLyraTherapy = 'considerLyraTherapy',
  considerMeds = 'considerMeds',
  directAccessTherapy = 'directAccessTherapy',
  empty = '',
  essentialsDisabled = 'essentialsDisabled',
  guidedSelfCareEnabled = 'guidedSelfCareEnabled',
  icas = 'icas',
  mystrength_accesscode = 'mystrength_accesscode',
  stressManagement = 'stressManagement',
}

export type ProgramType = {
  treatmentOptionProperties?: {
    associatedTriageTreatmentOptions: Array<TREATMENT_OPTIONS_TYPE>
    programTaxonomyTreatmentOptions?: Array<ProviderProgramTaxonomy>
    isFullTreatmentOption: boolean
  }
  customerPropertyId: ProgramCustomerPropertyId
  displayText?: string
  blendedCareProgramId?: string | null
  associatedProviderTypes?: Array<string>
  associatedProviderModalities?: Array<string>
  canRescheduleAppointment?: boolean
  SI_QUESTION?: string
  SI_PLAN_QUESTION?: string
  SI_INTENT_QUESTION?: string
  CHECK_IN_SI_QUESTION?: string
  CHECK_IN_SELF_HARM_QUESTION?: string
  CHRT_SI_QUESTION?: string
  CHRT_SI_QUESTION_2?: string
  CHRT_SI_PLAN_QUESTION?: string
  THOUGHTS_ABOUT_DEATH_QUESTION?: string
  CLIENT_INTAKE_ATTEMPTED_SUICIDE_QUESTION?: string
  CLIENT_INTAKE_SELF_INJURY_QUESTION?: string
  consents?: Array<Consent>
}

export type ProgramsType = Record<ProgramNames, ProgramType>

export const DEFAULT_PROGRAM_TAXONOMY = {
  clientele: PROGRAM_TAXONOMY.clientele.individual,
  treatment: PROGRAM_TAXONOMY.treatment.therapy,
  partner: PROGRAM_TAXONOMY.partner.blendedCare,
  offering: PROGRAM_TAXONOMY.offering.default,
}

// Programs are collections of 1 or more treatment options available within the product.
// They are conditionally offered based on customer property configurations,
// and their level of support can alter the screens and content that we show to the end user as part of onboarding.
// The key of a program matches the BC program name when it is a BC program. All caps keys are not BC programs.
export const PROGRAMS: ProgramsType = {
  DIRECT_ACCESS_MEDS: {
    treatmentOptionProperties: {
      associatedTriageTreatmentOptions: [DIRECT_ACCESS_MEDS, DIRECT_ACCESS_MEDS_CHILD],
      programTaxonomyTreatmentOptions: [
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.medicationManagement,
          partner: PROGRAM_TAXONOMY.partner.directAccess,
          offering: PROGRAM_TAXONOMY.offering.default,
        },
      ],
      isFullTreatmentOption: true,
    },
    customerPropertyId: ProgramCustomerPropertyId.considerMeds,
    displayText: 'medication management',
    blendedCareProgramId: null,
    associatedProviderTypes: [PSYCHIATRIST.lyraType, PSYCHIATRIC_NURSE_PRACTITIONER.lyraType],
    associatedProviderModalities: ['medication management'],
    canRescheduleAppointment: true,
    SI_QUESTION: 'phq9_9',
    SI_PLAN_QUESTION: 'phq9_9_1',
    SI_INTENT_QUESTION: 'phq9_9_2',
  },
  MedicationManagement: {
    treatmentOptionProperties: {
      associatedTriageTreatmentOptions: [BLENDED_CARE_MEDS],
      programTaxonomyTreatmentOptions: [
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.medicationManagement,
          partner: PROGRAM_TAXONOMY.partner.blendedCare,
          offering: PROGRAM_TAXONOMY.offering.default,
        },
      ],
      isFullTreatmentOption: true,
    },
    customerPropertyId: ProgramCustomerPropertyId.blendedCareMeds,
    displayText: 'medication management',
    blendedCareProgramId: '1b2b8b47-4c0e-49ff-a65c-40e187360b70',
    associatedProviderTypes: [LYRAPRESCRIBER.lyraType],
    associatedProviderModalities: ['lyraMedManagement'],
    consents: [
      {
        consentText: defineMessage({
          defaultMessage: 'Medication Management Consent',
          description:
            "Link to a user consent agreement for users participating in Lyra's Medication Management program.",
        }),
        consentUrl: 'https://www.lyrahealth.com/bcm-agreement',
        showConsentIf: () => true,
      },
    ],
    canRescheduleAppointment: false,
    SI_QUESTION: 'phq9_9',
    SI_PLAN_QUESTION: 'phq9_9_1',
    SI_INTENT_QUESTION: 'phq9_9_2',
  },
  SELF_CARE_APPS: {
    treatmentOptionProperties: {
      associatedTriageTreatmentOptions: [SELF_CARE_APPS],
      isFullTreatmentOption: true,
    },
    customerPropertyId: ProgramCustomerPropertyId.mystrength_accesscode,
    displayText: 'self-guided care',
    blendedCareProgramId: null,
    associatedProviderTypes: [],
    SI_QUESTION: 'phq9_9',
    SI_PLAN_QUESTION: 'phq9_9_1',
    SI_INTENT_QUESTION: 'phq9_9_2',
  },
  DIRECT_ACCESS_THERAPY: {
    treatmentOptionProperties: {
      associatedTriageTreatmentOptions: [
        COUPLES_THERAPY,
        FAMILY_THERAPY,
        INDIVIDUAL_THERAPY,
        FAMILY_THERAPY_CHILD,
        INDIVIDUAL_THERAPY_CHILD,
      ],
      programTaxonomyTreatmentOptions: [
        {
          clientele: PROGRAM_TAXONOMY.clientele.couples,
          treatment: PROGRAM_TAXONOMY.treatment.therapy,
          partner: PROGRAM_TAXONOMY.partner.directAccess,
          offering: PROGRAM_TAXONOMY.offering.default,
        },
        {
          clientele: PROGRAM_TAXONOMY.clientele.family,
          treatment: PROGRAM_TAXONOMY.treatment.therapy,
          partner: PROGRAM_TAXONOMY.partner.directAccess,
          offering: PROGRAM_TAXONOMY.offering.default,
        },
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.therapy,
          partner: PROGRAM_TAXONOMY.partner.directAccess,
          offering: PROGRAM_TAXONOMY.offering.default,
        },
      ],
      isFullTreatmentOption: true,
    },
    customerPropertyId: ProgramCustomerPropertyId.directAccessTherapy,
    displayText: 'therapy',
    blendedCareProgramId: null,
    associatedProviderTypes: [THERAPIST.lyraType],
    associatedProviderModalities: ['individual', 'couples', 'family'],
    canRescheduleAppointment: true,
    SI_QUESTION: 'phq9_9',
    SI_PLAN_QUESTION: 'phq9_9_1',
    SI_INTENT_QUESTION: 'phq9_9_2',
  },
  TeensTherapy: {
    treatmentOptionProperties: {
      associatedTriageTreatmentOptions: [INDIVIDUAL_THERAPY],
      programTaxonomyTreatmentOptions: [
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.therapy,
          partner: PROGRAM_TAXONOMY.partner.blendedCare,
          offering: PROGRAM_TAXONOMY.offering.default,
        },
      ],
      isFullTreatmentOption: false,
    },
    customerPropertyId: ProgramCustomerPropertyId.considerLyraTherapy,
    displayText: 'therapy',
    blendedCareProgramId: 'b42a0c74-76c0-48c4-8a7e-a767418bd753',
    associatedProviderTypes: [LYRATEENSTHERAPIST.lyraType],
    associatedProviderModalities: ['lyraTherapy'],
    consents: [
      {
        consentText: defineMessage({
          defaultMessage: 'Therapy Consent',
          description:
            "Link to a user consent agreement for users participating in Lyra's Blended Care Therapy program.",
        }),
        consentUrl: 'https://www.lyrahealth.com/lt-teen-agreement/',
        showConsentIf: () => true,
      },
    ],
    canRescheduleAppointment: true,
    SI_QUESTION: 'phq9_9',
    SI_PLAN_QUESTION: 'phq9_9_1',
    SI_INTENT_QUESTION: 'phq9_9_2',
    CHECK_IN_SI_QUESTION: 'suicideIdeation',
    CHECK_IN_SELF_HARM_QUESTION: 'selfHarm',
    CHRT_SI_QUESTION: 'chrt_10',
    CHRT_SI_QUESTION_2: 'chrt_11',
    CHRT_SI_PLAN_QUESTION: 'chrt_12',
    THOUGHTS_ABOUT_DEATH_QUESTION: 'rcads25_18',
    CLIENT_INTAKE_ATTEMPTED_SUICIDE_QUESTION: 'suicide',
    CLIENT_INTAKE_SELF_INJURY_QUESTION: 'selfInjury',
  },
  BlendedCareTherapy: {
    treatmentOptionProperties: {
      associatedTriageTreatmentOptions: [INDIVIDUAL_THERAPY],
      programTaxonomyTreatmentOptions: [
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.therapy,
          partner: PROGRAM_TAXONOMY.partner.directAccess,
          offering: PROGRAM_TAXONOMY.offering.default,
        },
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.therapy,
          partner: PROGRAM_TAXONOMY.partner.blendedCare,
          offering: PROGRAM_TAXONOMY.offering.default,
        },
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.therapy,
          partner: PROGRAM_TAXONOMY.partner.blendedCare,
          offering: PROGRAM_TAXONOMY.offering.fellowship,
        },
      ],
      isFullTreatmentOption: false,
    },
    customerPropertyId: ProgramCustomerPropertyId.considerLyraTherapy,
    displayText: 'therapy',
    blendedCareProgramId: 'c8cb4da4-8957-4cff-9d8e-1fa742f06771',
    associatedProviderTypes: [LYRATHERAPIST.lyraType, LYRAFELLOW.lyraType],
    associatedProviderModalities: ['lyraTherapy'],
    consents: [
      {
        consentText: defineMessage({
          defaultMessage: 'Therapy Consent',
          description:
            "Link to a user consent agreement for users participating in Lyra's Blended Care Therapy program.",
        }),
        consentUrl: 'https://www.lyrahealth.com/lt-agreement',
        showConsentIf: () => true,
      },
    ],
    canRescheduleAppointment: true,
    SI_QUESTION: 'phq9_9',
    SI_PLAN_QUESTION: 'phq9_9_1',
    SI_INTENT_QUESTION: 'phq9_9_2',
    CHECK_IN_SI_QUESTION: 'suicideIdeation',
    CHECK_IN_SELF_HARM_QUESTION: 'selfHarm',
    CHRT_SI_QUESTION: 'chrt_10',
    CHRT_SI_QUESTION_2: 'chrt_11',
    CHRT_SI_PLAN_QUESTION: 'chrt_12',
  },
  Coaching: {
    treatmentOptionProperties: {
      associatedTriageTreatmentOptions: [COACHING],
      programTaxonomyTreatmentOptions: [
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.coaching,
          partner: PROGRAM_TAXONOMY.partner.blendedCare,
          offering: PROGRAM_TAXONOMY.offering.default,
        },
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.coaching,
          partner: PROGRAM_TAXONOMY.partner.blendedCare,
          offering: PROGRAM_TAXONOMY.offering.parenting,
        },
      ],
      isFullTreatmentOption: true,
    },
    customerPropertyId: ProgramCustomerPropertyId.stressManagement,
    displayText: 'mental health coaching',
    blendedCareProgramId: '7ec82ca3-843f-4996-bdf9-49d8c7a35ed5',
    associatedProviderTypes: [COACH.lyraType],
    associatedProviderModalities: ['stressManagement'],
    consents: [
      {
        consentText: defineMessage({
          defaultMessage: 'Coaching Consent',
          description:
            "Link to a user consent agreement for users participating in Lyra's Blended Care Coaching program.",
        }),
        consentUrl: 'https://www.lyrahealth.com/bcc-agreement',
        // @ts-expect-error TS(2322): Type '({ isInternational }: { isInternational: boo... Remove this comment to see the full error message
        showConsentIf: ({ isInternational }: { isInternational: boolean }) => isInternational === false,
      },
      {
        consentText: defineMessage({
          defaultMessage: 'Coaching Consent',
          description:
            "Link to a user consent agreement for users participating in Lyra's Blended Care Coaching program.",
        }),
        consentUrl: 'https://www.lyrahealth.com/bcc-agreement-2',
        // @ts-expect-error TS(2322): Type '({ isInternational }: { isInternational: boo... Remove this comment to see the full error message
        showConsentIf: ({ isInternational }: { isInternational: boolean }) => isInternational === true,
      },
    ],
    canRescheduleAppointment: true,
    SI_QUESTION: 'phq9_9',
    SI_PLAN_QUESTION: 'phq9_9_1',
    SI_INTENT_QUESTION: 'phq9_9_2',
  },
  // In case you think the following program should be named 'GuidedSelfCare',
  // the name matches the program name in the lyratherapy/program table, which is 'SingleSessionCoaching'
  SingleSessionCoaching: {
    treatmentOptionProperties: {
      associatedTriageTreatmentOptions: [GUIDED_SELF_CARE],
      programTaxonomyTreatmentOptions: [
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.coaching,
          partner: PROGRAM_TAXONOMY.partner.blendedCare,
          offering: PROGRAM_TAXONOMY.offering.singleSession,
        },
      ],
      isFullTreatmentOption: true,
    },
    customerPropertyId: ProgramCustomerPropertyId.guidedSelfCareEnabled,
    displayText: 'coaching', // this should go away with the treatment recommendation page promoting coaching
    blendedCareProgramId: 'deb574e4-6aa3-4b47-bd21-d8be3ba5d42b',
    associatedProviderTypes: [COACH.lyraType],
    associatedProviderModalities: ['singleSessionCoaching'],
    consents: [
      {
        consentText: defineMessage({
          defaultMessage: 'Guided Self-Care Consent',
          description: "Link to a user consent agreement for users participating in Lyra's Guided Self-Care program.",
        }),
        consentUrl: 'https://www.lyrahealth.com/gsc-agreement',
        showConsentIf: () => true,
      },
    ],
    canRescheduleAppointment: true,
    SI_QUESTION: 'phq9_9',
    SI_PLAN_QUESTION: 'phq9_9_1',
    SI_INTENT_QUESTION: 'phq9_9_2',
  },
  AlcoholUseDisorderTherapy: {
    treatmentOptionProperties: {
      associatedTriageTreatmentOptions: [ALCOHOL_USE_DISORDER],
      programTaxonomyTreatmentOptions: [
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.therapy,
          partner: PROGRAM_TAXONOMY.partner.blendedCare,
          offering: PROGRAM_TAXONOMY.offering.alcoholUseDisorder,
        },
      ],
      isFullTreatmentOption: true,
    },
    consents: [
      {
        consentText: defineMessage({
          defaultMessage: 'AUD Consent',
          description:
            "Link to a user consent agreement for users participating in Lyra's program encompassing Alcohol Use Disorder (AUD).",
        }),
        consentUrl: 'https://www.lyrahealth.com/aud-agreement',
        showConsentIf: () => true,
      },
    ],
    associatedProviderTypes: [LYRATHERAPIST.lyraType],
    associatedProviderModalities: ['alcoholUseDisorderTherapy'],
    blendedCareProgramId: 'edcea864-b6eb-4d16-8c4c-ce748c18025e',
    customerPropertyId: ProgramCustomerPropertyId.alcoholUseDisorderTherapy,
    canRescheduleAppointment: true,
    SI_QUESTION: 'phq9_9',
    SI_PLAN_QUESTION: 'phq9_9_1',
    SI_INTENT_QUESTION: 'phq9_9_2',
  },
  ICASTherapy: {
    customerPropertyId: ProgramCustomerPropertyId.icas,
    displayText: 'ICAS',
  },
  ClinicalLeaveEvaluation: {
    treatmentOptionProperties: {
      associatedTriageTreatmentOptions: [CLINICAL_LEAVE_EVALUATION],
      programTaxonomyTreatmentOptions: [
        {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.assessment,
          partner: PROGRAM_TAXONOMY.partner.blendedCare,
          offering: PROGRAM_TAXONOMY.offering.clinicalLeave,
        },
      ],
      isFullTreatmentOption: false,
    },
    customerPropertyId: ProgramCustomerPropertyId.clinicalLeaveEvaluation,
    displayText: 'clinical leave evaluation',
    blendedCareProgramId: '6f146c5b-ac3b-42e5-b2a2-aacb3035e6d8',
    associatedProviderTypes: [LYRACLINICALLEAVEEVALUATOR.lyraType],
    associatedProviderModalities: ['clinicalLeaveEvaluation'],
    consents: [
      {
        consentText: defineMessage({
          defaultMessage: 'Therapy Consent',
          description:
            "Link to a user consent agreement for users participating in Lyra's Blended Care Therapy program.",
        }),
        consentUrl: 'https://www.lyrahealth.com/lt-agreement',
        showConsentIf: () => true,
      },
    ],
    canRescheduleAppointment: false,
    SI_QUESTION: 'phq9_9',
    SI_PLAN_QUESTION: 'phq9_9_1',
    SI_INTENT_QUESTION: 'phq9_9_2',
  },
}

export const COACHING_TREATMENT_OPTIONS = [TREATMENT_OPTIONS.COACHING, TREATMENT_OPTIONS.GUIDED_SELF_CARE]

export const THERAPY_TREATMENT_OPTIONS = [
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
  TREATMENT_OPTIONS.BLENDED_CARE_THERAPY,
  TREATMENT_OPTIONS.FAMILY_THERAPY,
  TREATMENT_OPTIONS.COUPLES_THERAPY,
  TREATMENT_OPTIONS.ONSITE_THERAPY,
  TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD,
  TREATMENT_OPTIONS.FAMILY_THERAPY_CHILD,
]

export const MEDS_TREATMENT_OPTIONS = [
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS,
  TREATMENT_OPTIONS.BLENDED_CARE_MEDS,
  TREATMENT_OPTIONS.CONTROLLED_MEDICATION,
  TREATMENT_OPTIONS.MEDICATION_CONSULTATION,
  TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS_CHILD,
]

export enum DirectLinkIntent {
  MEDS_CONSULT = 'DIRECT_LINK_MEDS_CONSULT',
  PARENTING = 'DIRECT_LINK_INTENT_PARENTING',
  OUTPATIENT_MEDS = 'DIRECT_LINK_OUTPATIENT_MEDICATIONS',
  AUD = 'DIRECT_LINK_ALCOHOL_USE_SUPPORT',
  FAMILY_SUPPORT = 'DIRECT_LINK_INTENT_FAMILY_SUPPORT',
}

interface UserConsent {
  programName: string
  consentText: MessageDescriptor
  consentUrl: string
}

export const getConsentsInfoForUser = (episodes: Episode[], isUserInternational: boolean): UserConsent[] => {
  const userProgramsWithConsents = new Set<string>()
  episodes.map((episode) => {
    if (!userProgramsWithConsents.has(episode.program_name) && !!PROGRAMS[episode.program_name].consents) {
      userProgramsWithConsents.add(episode.program_name)
    }
  })
  const consentsArray: UserConsent[] = []
  userProgramsWithConsents.forEach((programName) =>
    PROGRAMS[programName].consents.forEach(({ consentText, consentUrl, showConsentIf }: Consent) => {
      if (showConsentIf({ isInternational: isUserInternational })) {
        consentsArray.push({
          programName,
          consentText,
          consentUrl,
        })
      }
    }),
  )
  return consentsArray
}

export const isValidCTPOCombination = (ctpo: Partial<ProviderProgramTaxonomy>): ctpo is ProviderProgramTaxonomy => {
  const { clientele, treatment, partner, offering } = ctpo
  if (clientele && treatment && partner && offering) {
    for (const key of Object.keys(PROGRAMS)) {
      const program = PROGRAMS[key as ProgramNames]
      const taxonomies = program.treatmentOptionProperties?.programTaxonomyTreatmentOptions
      if (taxonomies) {
        const idx = taxonomies.findIndex(
          (taxonomy) =>
            taxonomy.clientele === clientele &&
            taxonomy.treatment === treatment &&
            taxonomy.partner === partner &&
            taxonomy.offering === offering,
        )
        if (idx >= 0) {
          return true
        }
      }
    }
  }
  return false
}

export const getTriageTreatmentOptionFromCTPO = (ctpo: ProviderProgramTaxonomy): TREATMENT_OPTIONS_TYPE | undefined => {
  for (const key of Object.keys(PROGRAMS)) {
    const program = PROGRAMS[key] as ProgramType
    const treatmentOptions = program.treatmentOptionProperties?.associatedTriageTreatmentOptions
    const taxonomies = program.treatmentOptionProperties?.programTaxonomyTreatmentOptions
    if (taxonomies && treatmentOptions) {
      const idx = taxonomies.findIndex((taxonomy) => {
        return (
          taxonomy.clientele === ctpo.clientele &&
          taxonomy.treatment === ctpo.treatment &&
          taxonomy.partner === ctpo.partner &&
          taxonomy.offering === ctpo.offering
        )
      })
      if (idx >= 0) {
        if (treatmentOptions.length > idx) {
          return treatmentOptions[idx]
        }
        // associated treatment options generally match 1:1 with taxonomies
        // but individual therapy has 3 taxonomies that map to a single treatment option.
        return treatmentOptions[0]
      }
    }
  }
  return undefined
}

export const getListOfTreatmentOptionsFromProviderTaxonomy = ({ taxonomy }: { taxonomy?: ProviderProgramTaxonomy[] }) =>
  taxonomy
    ? taxonomy.reduce((treatmentOptions: TREATMENT_OPTIONS_TYPE[], taxonomy: ProviderProgramTaxonomy) => {
        const treatmentOption = getTriageTreatmentOptionFromCTPO(taxonomy)
        if (treatmentOption) {
          treatmentOptions.push(treatmentOption)
        }
        return treatmentOptions
      }, [])
    : []

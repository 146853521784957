export type RaceCategory = {
  text: ETHNICITY
  group: number
}

export interface GetRaceCategories {
  id: number
  raceCategories: RaceCategory[]
}
export interface PostEmailVerification {
  customerDomain: string
  language: string
  username: string
}

export interface PostParentLedTeenEmailVerification {
  childEmail: string
  appointmentId: string
  childLyraId?: string
  token?: string // request supports passing childLyraId or token. Pass verification token if childLyraId is not available.
}

export interface PostFamilyHubEmailVerification {
  token: string
  email: string
  isTeen: boolean
}

export interface DetectCountry {
  countryIso: string
  countryName: string
}

export enum GENDER_IDENTITY {
  MALE = 'male',
  FEMALE = 'female',
  NON_BINARY = 'non-binary',
  TRANSGENDER = 'transgender',
  OTHER = 'other',
  PREFER_NOT_TO_SAY = 'decline',
}

export enum ETHNICITY {
  ASIAN = 'Asian',
  BLACK_OR_AFRICAN_AMERICAN = 'Black or African American',
  HISPANIC_OR_LATINX = 'Hispanic or Latinx',
  NATIVE_AMERICAN_OR_ALASKA_NATIVE = 'Native American or Alaska Native',
  NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER = 'Native Hawaiian or Pacific Islander',
  WHITE = 'White',
  PREFER_NOT_TO_SAY = 'Prefer not to say',
}

export type VerifyAutoEnrollmentTokenResponse = {
  firstName: string
  emailAddress: string
}

export type ChangeEmailRequest = {
  username: string
  response: string
  isAutoEnrollment?: boolean
}

import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  APPOINTMENT_DATA_LOADED,
  CLEAR_BOOKING_STATUS,
  CLEAR_SELECTED_APPOINTMENT,
  SET_APPOINTMENT_DASHBOARD_VALUE,
  SET_BOOKING_STATUS,
  SET_SCHEDULES,
} from './appointmentDashboardConstants'

const initialState = fromJS({
  appointments: [],
  schedules: {},
  bookingStatus: '',
  selectedAppointment: {},
})

export default createReducer(initialState, {
  [SET_APPOINTMENT_DASHBOARD_VALUE]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const appointments = action.data

    // TODO: This handles an edge-case where sessionNumbers returned by
    //   the server can be 0. It will be safe to remove once sessionCounting is
    //   implemented on the server.
    const sessionCountByProviderId = {}
    appointments.forEach((appointment: $TSFixMe) => {
      const isCountable = !['canceled', 'missed'].includes(appointment.appointmentStatus.toLowerCase())
      if (appointment.sessionNumber === 0 && isCountable) {
        if (!sessionCountByProviderId[appointment.lyraProviderId]) {
          sessionCountByProviderId[appointment.lyraProviderId] = 0
        }

        appointment.sessionNumber = sessionCountByProviderId[appointment.lyraProviderId] + 1
        sessionCountByProviderId[appointment.lyraProviderId]++
      }
    })

    $$state = $$state.set('appointments', fromJS(appointments))
    return $$state
  },
  [SET_SCHEDULES]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('schedules', action.val)
    return $$state
  },
  [SET_BOOKING_STATUS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('bookingStatus', action.val)
    return $$state
  },
  [CLEAR_SELECTED_APPOINTMENT]: ($$state: $TSFixMe) => {
    $$state = $$state.set('selectedAppointment', fromJS({}))
    return $$state
  },
  [CLEAR_BOOKING_STATUS]: ($$state: $TSFixMe) => {
    $$state = $$state.set('bookingStatus', '')
    return $$state
  },
  [APPOINTMENT_DATA_LOADED]: ($$state: $TSFixMe) => {
    $$state = $$state.set('appointmentDataLoaded', true)
    return $$state
  },
})

import { fromJS } from 'immutable'

import * as ModalActions from './modal-actions'
import * as ContactModalActions from '../contact-modal/contact-modal-actions'

const defaultContactModal = {
  name: '',
  notes: '',
  status: ContactModalActions.CONTACT_US_STATUS.IDLE,
  type: ContactModalActions.CONTACT_US_TYPE.CONTACT_US,
  helperLabel: {
    value: 'CHOOSE ALL THAT APPLY',
    isValid: true,
  },
  email: {
    placeholder: 'Email Address',
    isValid: true,
    value: '',
  },
  phone: {
    placeholder: '000-000-0000',
    isValid: true,
    value: '',
  },
  emailChecked: false,
  phoneChecked: false,
  shouldShowMoreBio: false,
}

export const modal = (
  state = fromJS({ isOpen: false, content: '', modalProps: {}, contactModal: defaultContactModal }),
  action: $TSFixMe,
) => {
  switch (action.type) {
    case ModalActions.CLOSE_MODAL:
      return state.set('isOpen', false).set('modalProps', fromJS({})).set('shouldShowMoreBio', false)

    case ModalActions.SET_MODAL_CONTENT:
      return state.set('isOpen', true).set('content', action.content)
    case ModalActions.SET_MODAL_PROPS:
      return state.set('modalProps', fromJS(action.modalProps))
    case ContactModalActions.SET_CONTACT_MODAL_TYPE:
      return state.setIn(['contactModal', 'type'], action.contactModalType)
    default:
      return state
  }
}

export default modal

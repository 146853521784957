import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { SET_IS_SESSION_TIMED_OUT } from './logoutActions'

interface LogoutState extends Map<string, any> {
  isSessionTimedOut: boolean
}

const initialState: LogoutState = fromJS({
  isSessionTimedOut: false,
})

export default createReducer(initialState, {
  [SET_IS_SESSION_TIMED_OUT]: ($$state: LogoutState, action: { value: boolean }) => {
    return $$state.set('isSessionTimedOut', action.value)
  },
})

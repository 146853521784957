import React, { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import selfCareIllustration from './../../assets/SelfCareIllustration.png'
import { tID } from '../../utils'
import { ImageComponentProps } from '../formElements/RadioButtons'
import { Image } from '../image/Image'

const SelfCareIllustrationPNG = styled(Image)<{ width: number; height: number }>(({ width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
}))

/**
 * Since react-native-svg does not support Mask for native, use image for specifically mobile
 */
export const SelfCareIllustration: FunctionComponent<ImageComponentProps> = ({ width = 158, height = 158 }) => {
  return (
    <SelfCareIllustrationPNG
      source={selfCareIllustration as ImageSourcePropType}
      testID={tID(`SelfCareIllustration`)}
      width={width}
      height={height}
    />
  )
}

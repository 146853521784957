import React, { FunctionComponent } from 'react'
import { G, Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const TrashIcon: FunctionComponent<TrashIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} viewBox='0 0 20 20' fill='none'>
      <G stroke={fillColor || colors.iconDefault} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.25}>
        <Path d='M2.5 5h15M15.833 5v11.666a1.666 1.666 0 0 1-1.666 1.667H5.833a1.667 1.667 0 0 1-1.666-1.667V4.999m2.5 0V3.333a1.667 1.667 0 0 1 1.666-1.667h3.334a1.666 1.666 0 0 1 1.666 1.667v1.666M8.333 9.166v5M11.667 9.166v5' />
      </G>
    </Svg>
  )
}

export type TrashIconProps = {
  size?: string | number
  fillColor?: string
}

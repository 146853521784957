import React from 'react'
import { defineMessage } from 'react-intl'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import BrightHorizonsImage from '../assets/worklife/ReThink.png'
import { BodyText } from '../atoms'
import { DEFAULT_WORKLIFE_SERVICES } from '../constants'

/* eslint-disable formatjs/no-literal-string-in-jsx */
export const virginiamason: GetCustomerCopyFunc = () => {
  return {
    dependentButtonText: defineMessage({
      defaultMessage: 'No, I’m a dependent or household member',
      description: 'Declaring the current user is a dependent of an eligible user',
    }),
    workLifeServices: [
      DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.FINANCIAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT,
      DEFAULT_WORKLIFE_SERVICES.DEPENDENT_CARE,
      {
        identifiers: {
          id: 'brightHorizons',
          dataTestId: 'workLife-selectBrightHorizons',
        },
        title: 'Bright Horizons Family Support',
        img: BrightHorizonsImage,
        paragraph: 'Care Membership',
        bullets: [
          'Access to ongoing care providers - for children, seniors, pets, tutoring, housekeeping, and more',
          <>
            <BodyText text={'Backup Care \n'} />
            <BodyText text='Subsidized coverage when regular care is unavailable - for children, seniors, pets' />
          </>,
        ],
        CTA: 'Access Family Support',
        PATH: 'https://clients.brighthorizons.com/virginiamason',
      },
    ],
  }
}

import { AxiosResponse } from 'axios'

import { MemberPreferencesForUserV2, SearchProviders, SearchProvidersRequest } from '@lyrahealth-inc/shared-app-logic'

import { axiosInstance } from '../axiosInstance'

export const postCareNavigatorTicket = (userId: string, data: object) => {
  return axiosInstance.post(`/v1/onboarding/${userId}/careNavigatorTicket`, data)
}

export const updatePatientEligibility = (lyraId: string, data: $TSFixMe) => {
  return axiosInstance.post(`/v1/patient/${lyraId}/eligibility`, data)
}

export const postEssentialsTopic = (data: object): Promise<AxiosResponse<boolean>> => {
  return axiosInstance.post(`/v1/essentials/topics`, data)
}

export const getUserHealthPlansDataFromEligibilityService = ({ lyraId }: $TSFixMe) => {
  return axiosInstance.get(`/v1/patient/${lyraId}/eligibility-health-plan`)
}

export const getTreatmentOptionEligibilityForChildren = ({ lyraId, eligibilityName }: $TSFixMe) => {
  return axiosInstance.post(`/v1/onboarding/${lyraId}/shouldOfferTreatmentWhenChildSelected `, {
    eligibilityName,
  })
}

export const submitMemberPreferencesForUserV2 = (userId: string, data: MemberPreferencesForUserV2) => {
  return axiosInstance.post(`v1/onboarding/${userId}/newCarePreferences`, data)
}

export const getMemberPreferencesForUserV2 = (userId: string) => {
  return axiosInstance.get(`v1/onboarding/${userId}/newCarePreferences`)
}

export const getProvider = (id: string) => {
  return axiosInstance.post(`/v1/provider/${id}`)
}

export const getProviderAvailability = (lyraId: $TSFixMe, data = {}) => {
  return axiosInstance.post(`/v1/calendar/${lyraId}/free`, data)
}

export const getInitialAppointmentInfoData = (userId: $TSFixMe, providerId: $TSFixMe, blendedCareProgramId = '') => {
  return axiosInstance.get(
    `/v2/calendar/${userId}/${providerId}/initial-appointment-information?blendedCareProgramId=${blendedCareProgramId}`,
  )
}

export const getProviderLocalAvailability = (data: $TSFixMe) => {
  return axiosInstance.post('/v1/calendar/free', data)
}

export const getLocationSearchCountData = (id: string | number) => {
  return axiosInstance.get(`/v1/onboarding/${id}/locationCount/search`)
}

export const getProviderRecommendationsData = (
  userId: string,
  data: SearchProvidersRequest,
): Promise<AxiosResponse<SearchProviders>> => {
  return axiosInstance.post<SearchProviders>(`/v1/provider/tx/${userId}`, data)
}

export const getProviderRecommendationsDirectBookingData = (
  userId: string,
  data: SearchProvidersRequest,
): Promise<AxiosResponse<SearchProviders>> => {
  return axiosInstance.post<SearchProviders>(`/v1/provider/tx/${userId}/direct`, data)
}

export const getProvidersForLocationData = (userId: $TSFixMe, data: $TSFixMe) => {
  return axiosInstance.post(`/v1/provider/profiles/${userId}`, data)
}

export const getTreatmentTypeCost = (providerId: string, userId: string, searchId: string) => {
  return axiosInstance.patch(`/v1/provider/${providerId}/patient/${userId}/search/${searchId}/cost`)
}

export const getAllTreatmentOptionsCosts = (providerId: $TSFixMe, userId: $TSFixMe) => {
  return axiosInstance.get(`/v1/provider/${providerId}/patient/${userId}/costs`)
}

export const getModalityQuestionnaireSurveyData = (userId: $TSFixMe) => {
  return axiosInstance.get(`v1/onboarding/modality/${userId}/survey`)
}

export const submitModalityQuestionnaireSurveyData = (userId: $TSFixMe, data: $TSFixMe) => {
  return axiosInstance.post(`v1/onboarding/modality/${userId}/survey-question-group`, data)
}

export const submitModalityPreferenceData = (userId: string, searchId: string, data: $TSFixMe) => {
  return axiosInstance.post(`v1/onboarding/modality/${userId}/modality-preference/search/${searchId}`, data)
}

export const getModalityQuestionnaireSurveyStatusData = (userId: $TSFixMe) => {
  return axiosInstance.get(`v1/onboarding/modality/${userId}/survey-status`)
}

export const updateNavigatorTicketData = (data: $TSFixMe) => {
  return axiosInstance.put('/unsecured/v1/utils/navigator', data)
}

export const getMostRecentTriageInfoSavedForSelfSearchPastPrimaryNeedsSelection = (userId: $TSFixMe) => {
  return axiosInstance.get(`/v1/onboarding/${userId}/latest-needs-selected`)
}

export const getCareLanguagesForCountry = (country: $TSFixMe) => {
  return axiosInstance.get(`v1/country/${country}/care-languages/tags`)
}

export const generateLyraCode = (lyraId: string, customerCountryId: number) => {
  return axiosInstance.post(`/v1/user/${lyraId}/lyra-code`, { customerCountryId })
}

export const getLatestUserFavoriteProviders = (id: string) => {
  return axiosInstance.get(`/v1/onboarding/search/${id}/latest/user-favorite-providers`)
}

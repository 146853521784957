import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Play button on top of thumbnail
 */
export const ThumbnailPlayIcon: FunctionComponent<ThumbnailPlayIconProps> = ({
  height = 64,
  width = 64,
  fillColor,
}) => {
  const { colors } = useTheme()

  return (
    <Svg width={width} height={height} viewBox='0 0 64 64' fill='none'>
      <Path
        d='M28.2147 17.4576C26.8986 16.4514 25 17.3899 25 19.0464V31.0541V43.8161C25 45.4971 26.9478 46.4282 28.256 45.3726L44.0185 32.653C45.0273 31.8389 45.0071 30.295 43.9772 29.5077L28.2147 17.4576Z'
        fill={fillColor || colors.textDarkBackground}
      />
    </Svg>
  )
}

type ThumbnailPlayIconProps = {
  height?: string | number
  width?: string | number
  fillColor?: string
}

import { fromJS } from 'immutable'

import * as T1FlowActions from './t1-flow-actions'

/* -------------------------------------
 |SESSION STORAGE
 --------------------------------------- */
const T1MULTI_PROVIDERSINFO = 'T1MULTI_PROVIDERSINFO'
const T1MULTI_ADDRESS = 'T1MULTI_ADDRESS'
const T1MULTI_PROVIDERAVAILABILITIES = 'T1MULTI_PROVIDERAVAILABILITIES'
const T1_SELECTIONS = 'T1_SELECTIONS'

const T1PPROVIDER = 'T1PPROVIDER'

function updateT1Providers(immProvider: $TSFixMe) {
  sessionStorage.setItem(T1PPROVIDER, JSON.stringify(immProvider.toJS()))
}

function getCachedItem(k: $TSFixMe) {
  if (sessionStorage.getItem(k)) {
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    return JSON.parse(sessionStorage.getItem(k))
  }
  return null
}

export const t1Flow = (
  state = fromJS({
    t1: getCachedItem(T1PPROVIDER) || [],
    // t1Multi: stub,
    t1Multi: {
      providersInfo: getCachedItem(T1MULTI_PROVIDERSINFO) || [],
      address: getCachedItem(T1MULTI_ADDRESS) || null,
      providerAvailabilities: getCachedItem(T1MULTI_PROVIDERAVAILABILITIES) || [],
    },
    selections: {
      provider: {},
      address: {},
      onsiteLocationName: getCachedItem(T1_SELECTIONS)
        ? fromJS(getCachedItem(T1_SELECTIONS)).getIn(['onsiteLocationName'], '')
        : '',
      availabilities: [],
      startDate: '',
      startTime: '',
    },
    calendars: [],
    appointment: {},
    treatmentMode: '',
    suicideModalTriggered: false,
  }),
  action: $TSFixMe,
) => {
  switch (action.type) {
    case T1FlowActions.SET_ONSITE_PROVIDERS:
      updateT1Providers(action.providers)
      return state.setIn(['t1'], action.providers)
    case T1FlowActions.CLEAR_ONSITE_PROVIDERS:
      sessionStorage.removeItem(T1PPROVIDER)
      return state.set('t1', fromJS([]))
    default:
      return state
  }
}

export default t1Flow

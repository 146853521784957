import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

/**
 * Shield Icon that is used next to our confidential notice on triage
 */
export const ShieldTriageIcon: FunctionComponent<ShieldTriageIconProps> = ({ size = 24 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.63 21.15C11.7414 21.2176 11.8697 21.2523 12 21.25C12.1303 21.2523 12.2586 21.2176 12.37 21.15L16.45 18.82C17.4516 18.2452 18.284 17.4167 18.8633 16.4177C19.4427 15.4188 19.7485 14.2848 19.75 13.13V3.5C19.7474 3.30189 19.6676 3.11263 19.5275 2.97253C19.3874 2.83244 19.1981 2.75259 19 2.75H5C4.80189 2.75259 4.61263 2.83244 4.47253 2.97253C4.33244 3.11263 4.25259 3.30189 4.25 3.5V13.13C4.25148 14.2848 4.5573 15.4188 5.13665 16.4177C5.716 17.4167 6.54841 18.2452 7.55 18.82L11.63 21.15ZM5.75 13.13V4.25H18.25V13.11C18.2484 14.0013 18.0118 14.8764 17.5641 15.6472C17.1164 16.4179 16.4734 17.057 15.7 17.5L12 19.64L8.3 17.5C7.52951 17.0587 6.88843 16.4227 6.44092 15.6558C5.99341 14.8889 5.75515 14.0179 5.75 13.13ZM17 13.13V5.5H12V18.2L15.08 16.43C15.6621 16.0975 16.1461 15.6173 16.4832 15.0378C16.8203 14.4584 16.9986 13.8003 17 13.13Z'
        fill='#4F4D4A'
      />
    </Svg>
  )
}

export type ShieldTriageIconProps = {
  size?: string | number
}

import React from 'react'
import { Circle, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

type ResultsScoreIconProps = {
  width?: number
  height?: number
  outerCircleFillColor?: string
  innerCircleFillColor?: string
}

export const ResultsScoreIcon: React.FC<ResultsScoreIconProps> = ({
  width = 20,
  height = 20,
  outerCircleFillColor,
  innerCircleFillColor,
}) => {
  const { colors } = useTheme()
  const outerColor =
    outerCircleFillColor ?? colors.components.wellnessCheckInResultsDomainScore.default.outerCircle?.fill
  const innerColor =
    innerCircleFillColor ?? colors.components.wellnessCheckInResultsDomainScore.default.innerCircle?.fill

  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${width}`} fill='none'>
      <Circle cx={width / 2} cy={height / 2} r={width / 2} fill={outerColor} />
      <Circle cx={width / 2} cy={height / 2} r={width / 4} fill={innerColor} />
    </Svg>
  )
}

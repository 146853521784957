import React, { FunctionComponent } from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { SCORING_ICON_STYLE, ThemeColors } from '@lyrahealth-inc/shared-app-logic'

export const getScoringIconStyle = (colors: ThemeColors) => {
  const { negative, positive, neutral } = colors.icons.scoringIcon

  return {
    NEGATIVE_TREND_UP_STYLE: {
      arrowColor: negative.arrow.fill,
      fillColor: negative.fill,
      strokeColor: negative.stroke.fill,
      iconOrientation: 'UP',
    },
    NEGATIVE_TREND_DOWN_STYLE: {
      arrowColor: negative.arrow.fill,
      fillColor: negative.fill,
      strokeColor: negative.stroke.fill,
      iconOrientation: 'DOWN',
    },
    POSITIVE_TREND_UP_STYLE: {
      arrowColor: positive.arrow.fill,
      fillColor: positive.fill,
      strokeColor: positive.stroke.fill,
      iconOrientation: 'UP',
    },
    POSITIVE_TREND_DOWN_STYLE: {
      arrowColor: positive.arrow.fill,
      fillColor: positive.fill,
      strokeColor: positive.stroke.fill,
      iconOrientation: 'DOWN',
    },
    NEUTRAL_TREND_STYLE: {
      arrowColor: neutral.arrow.fill,
      fillColor: neutral.fill,
      strokeColor: neutral.stroke.fill,
      iconOrientation: 'CENTER',
    },
  }
}

type ScoringIconProps = {
  iconType: SCORING_ICON_STYLE
}

export const ScoringIcon: FunctionComponent<ScoringIconProps> = ({
  iconType = SCORING_ICON_STYLE.NEUTRAL_TREND_STYLE,
}) => {
  const { colors } = useTheme()
  const { arrowColor, fillColor, strokeColor, iconOrientation = 'CENTER' } = getScoringIconStyle(colors)[iconType]

  return (
    <Svg width='32' height='32' viewBox='0 0 32 32' fill='none'>
      <Circle cx='16' cy='16' r='15.5' fill={fillColor} stroke={strokeColor} />
      <Path
        d={
          iconOrientation === 'CENTER'
            ? 'M9.0512 15.8853L22.627 15.8853'
            : iconOrientation === 'UP'
            ? 'M11.1417 20.939L20.7412 11.3395'
            : 'M11.1456 10.8328L20.7451 20.4323'
        }
        stroke={arrowColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d={
          iconOrientation === 'CENTER'
            ? 'M18.4933 11.0377L23.3418 15.8862L18.4933 20.7347'
            : iconOrientation === 'UP'
            ? 'M14.3906 10.833L21.2474 10.833L21.2474 17.6897'
            : 'M21.2497 14.0817L21.2497 20.9385L14.3929 20.9385'
        }
        stroke={arrowColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  )
}

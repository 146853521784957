import { IntlConfig } from 'react-intl'

import { match } from '@formatjs/intl-localematcher'

import { IntlAdapter } from './adapter/IntlAdapter'
import { Resolved } from './LyraIntlContext'

export type BaseMessagesType = IntlConfig['messages']

export type LyraIntlConfig<LanguageTag extends string, TMessages = BaseMessagesType> = {
  /**
   * Default language when no available languages match user's requested
   */
  defaultLanguage: LanguageTag

  /**
   * Languages with available translations.
   * Defines valid languages that can loaded and can be automatically matched to the user's device setting.
   */
  languages: readonly LanguageTag[]
  loadMessages: (language: LanguageTag) => Promise<TMessages>

  adapter: IntlAdapter

  /**
   * If true, when the default language is active, uses the `defaultMessage`s from the code,
   * instead of loading or fetching the messages. Will set react-intl's `messages` to an empty object.
   *
   * Assumes that `removeDefaultMessage` option is false in formatjs babel plugin.
   *
   * Defaults to true.
   */
  useDefaultMessages?: boolean
}

export type LyraIntlState<LanguageTag> = {
  language: LanguageTag
  resolved?: Resolved
  reactIntlConfig: IntlConfig
  disabledTranslationsReactIntlConfig: IntlConfig
}

const emptyMessages = {}

export class LyraIntl<LanguageTag extends string, TMessages extends BaseMessagesType = BaseMessagesType> {
  readonly config: Required<LyraIntlConfig<LanguageTag, TMessages>>

  readonly availableLanguages: Set<LanguageTag>

  readonly pseudoLanguages: Set<string>

  private readonly adapter: IntlAdapter

  private state: LyraIntlState<LanguageTag> | null = null

  private subscribers = new Set<() => void>()

  constructor(config: LyraIntlConfig<LanguageTag, TMessages>) {
    this.config = {
      useDefaultMessages: true,
      ...config,
    }
    this.availableLanguages = new Set(this.config.languages)
    this.pseudoLanguages = new Set(
      typeof __DEV__ !== 'undefined' && __DEV__ ? ['en-XA', 'en-XB', 'xx-LS', 'xx-AC'] : [],
    )
    this.adapter = this.config.adapter
  }

  subscribe(callback: () => void) {
    this.subscribers.add(callback)
    return () => {
      this.subscribers.delete(callback)
    }
  }

  getState(): Readonly<LyraIntlState<LanguageTag>> | null {
    return this.state
  }

  private notify() {
    this.subscribers.forEach((callback) => {
      callback()
    })
  }

  /**
   * Resolve an initial language, load the messages, and compute the config for react-intl.
   *
   * @param initialLanguage If provided, initialize with a known language.
   * If null, use the adapter to match available languages with the client's list of preferred languages.
   * @param countryIsoCode When not null, overrides the region subtag with the country code in the output locale.
   * Required in some cases to support country-specific date localization, while sharing the messages from one language.
   * @returns A promise for LyraIntlState which contains IntlConfig with locale and messages
   */
  async initLocale(
    initialLanguage: string | LanguageTag | null,
    countryIsoCode: string | null,
  ): Promise<LyraIntlState<LanguageTag>> {
    let language: LanguageTag, resolvedToDefault: boolean, resolved: Resolved | undefined
    if (initialLanguage && this.validLanguage(initialLanguage)) {
      // Use a specific language
      language = initialLanguage
    } else {
      let preferredLanguages: string[] = []
      try {
        preferredLanguages = await this.adapter.getUserLanguages()
      } catch (error) {
        console.error('Could not get user preferred languages:', error)
      }
      // Match device or browser preferences against available languages
      ;({ language, resolvedToDefault } = this.matchLanguage(preferredLanguages))
      resolved = { preferredLanguages, resolvedToDefault }
    }
    this.state = await this._applyLocale(language, countryIsoCode, resolved)
    await this.adapter.onLanguageInit?.(this.state)
    this.notify()
    return this.state
  }

  async changeLanguage(language: LanguageTag, countryIsoCode: string | null): Promise<LyraIntlState<LanguageTag>> {
    if (!this.validLanguage(language)) {
      return Promise.reject(new Error(`Unknown language: ${language}`))
    }

    this.state = await this._applyLocale(language, countryIsoCode)
    await this.adapter.onLanguageChanged?.(this.state)
    this.notify()
    return this.state
  }

  validLanguage(language: string): language is LanguageTag {
    return this.availableLanguages.has(language as LanguageTag) || this.pseudoLanguages.has(language)
  }

  private async _applyLocale(
    language: LanguageTag,
    countryIsoCode: string | null,
    resolved?: Resolved,
  ): Promise<LyraIntlState<LanguageTag>> {
    const locale = this.computeLocale(language, countryIsoCode)
    const defaultLocale = this.computeLocale(this.config.defaultLanguage, countryIsoCode)
    return {
      language,
      resolved,
      reactIntlConfig: {
        locale,
        defaultLocale,
        messages:
          language === this.config.defaultLanguage && this.config.useDefaultMessages
            ? emptyMessages
            : await this._loadMessages(language),
      },
      disabledTranslationsReactIntlConfig: {
        locale: defaultLocale,
        defaultLocale,
        messages: this.config.useDefaultMessages
          ? emptyMessages
          : await this._loadMessages(this.config.defaultLanguage),
      },
    }
  }

  private async _loadMessages(language: LanguageTag): Promise<TMessages> {
    return this.config.loadMessages(language)
  }

  /**
   * @param requested List of requested languages in order of priority
   */
  matchLanguage(requested: string[]): { language: LanguageTag; resolvedToDefault: boolean } {
    // canonicalizes the requested locales and applies a best fit algorithm to match language
    // https://github.com/formatjs/formatjs/blob/main/packages/intl-localematcher/abstract/BestFitMatcher.ts
    let language = match(requested, Array.from(this.availableLanguages.values()), 'en-x-nomatch', {
      algorithm: 'best fit',
    }) as LanguageTag
    const resolvedToDefault = language === 'en-x-nomatch'
    language = resolvedToDefault ? this.config.defaultLanguage : language
    return { language, resolvedToDefault }
  }

  /**
   * @param language
   * @param countryIsoCode
   * @returns the locale to use for `IntlConfig.locale` param. This locale is passed to all of
   * the underlying Intl formatters
   */
  computeLocale(language: LanguageTag, countryIsoCode: string | null): string {
    // Inserts likely script and region subtags
    // en to en-Latn-US, zh-TW to zh-Hant-TW, zh-CN to zh-Hans-CN
    let locale = new Intl.Locale(language)
    const localeMax = locale.maximize()
    // Some pseudo locales returning 'und'
    if (localeMax.baseName !== 'und') {
      locale = localeMax
    }

    const overrides: Intl.LocaleOptions = {}
    if (countryIsoCode) {
      overrides.region = countryIsoCode
    }

    if (locale.language === 'th') {
      // `th` uses buddhist calendar by default, which has a different starting year
      overrides.calendar = 'gregory'
    }

    if (Object.keys(overrides).length > 0) {
      locale = new Intl.Locale(locale.toString(), overrides)
    }

    if (locale.script === 'Hant') {
      // https://cldr.unicode.org/index/cldr-spec/language-tag-equivalences
      // Locale.minimize removes `Hant` for some locales
      // ex: zh-Hant-US becomes zh-US, but it must be included for Intl formatters to use the correct script
      // Verify in LyraIntl.test.ts snapshot
    } else {
      // See "Remove Likely Subtags": http://unicode.org/reports/tr35/#Likely_Subtags
      // en-Latn-US-> en, de-Latn-DE -> de, fr-Latn-CA -> fr-CA
      locale = locale.minimize()
    }

    return locale.toString()
  }
}

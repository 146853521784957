import React, { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { View } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { TREATMENT_OPTION_DISPLAY_NAMES } from '@lyrahealth-inc/shared-app-logic'

import { TreatmentCardTemplate, TreatmentCardTemplateConsumerProps } from './TreatmentCardTemplate'
import medsBackground from '../../assets/card_meds.png'
import { CalendarBookingIcon, InfoIcon } from '../../atoms'
import { BodyText } from '../../atoms/bodyText/BodyText'
import { BulletedList } from '../../atoms/bulletedList/BulletedList'
import { Divider } from '../../atoms/divider/Divider'
import { TrendUpIcon } from '../../atoms/icons/TrendUpIcon'
import { MedicationConsultationIllustration } from '../../atoms/illustrations/MedicationConsultationIllustration'
import { Meds } from '../../atoms/illustrations/Meds'
import { Link } from '../../atoms/link/Link'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { Accordion } from '../../molecules/accordion/Accordion'
import { CheckIconListItem } from '../../molecules/checkIconListItem/CheckIconListItem'
import { IconList } from '../../molecules/iconList/IconList'
import { BodyTextSize } from '../../styles/typeStyles'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface MedsTreatmentCardProps extends TreatmentCardTemplateConsumerProps {
  showExpandableContent?: boolean
  showFootnote?: boolean
  onLearnMorePress: () => void
  isDirectAccessMedsSupported: boolean
}

const SymptomTrackingText = (
  <FormattedMessage
    defaultMessage='Ongoing symptom tracking to help mitigate any side effects'
    description='text describing skills'
  />
)

const EasyAccessToPrescriptionAndPhysicianText = (
  <FormattedMessage
    defaultMessage='Easy access to prescription refills and follow-up appointments with the same physician'
    description='text describing skills'
  />
)

const HealthPlanVerificationDisclaimerText = (
  <FormattedMessage
    defaultMessage='We’ll need to verify your health plan information, as you may have additional eligibility requirements and be subject to some cost sharing as defined under your health plan.'
    description="A description explaining Lyra will need to verify health plan in order to give more information about user's medication consultation coverage"
  />
)

const BulletedListContainer = styled(BulletedList)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['12px'],
}))

const ListHeader = styled(BodyText)<{ theme: ThemeType; showModalView?: boolean }>(({ theme, showModalView }) => ({
  ...(showModalView && { marginBottom: theme.spacing['12px'] }),
}))

const ModalIconList = styled(IconList)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['12px'],
}))

const HealthPlanVerificationDisclaimerContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing['16px'],
  marginTop: theme.spacing['12px'],
  backgroundColor: theme.colors.backgroundSection,
  borderRadius: '8px',
}))

const CheckIconListContainer = styled.View<{ horizontal?: boolean }>(({ horizontal }) => ({
  flexDirection: horizontal ? 'row' : 'column',
  justifyContent: 'space-between',
}))

const CheckIconItemContainer = styled.View<{ isLaptop?: boolean }>(
  ({ isLaptop }) =>
    isLaptop && {
      maxWidth: '240px',
    },
)

export const MedsTreatmentCard: FunctionComponent<MedsTreatmentCardProps> = ({
  showExpandableContent = true,
  showFootnote = true,
  onLearnMorePress = noop,
  isDirectAccessMedsSupported,
  name,
  showModalView = false,
  ...rest
}) => {
  const { colors } = useTheme()
  const { formatMessage } = useIntl()
  const { isMinWidthLaptop } = useMediaQuerySize()

  const bullets = [
    {
      value: SymptomTrackingText,
    },
    {
      value: EasyAccessToPrescriptionAndPhysicianText,
    },
  ]

  const footnote = isDirectAccessMedsSupported ? (
    <BodyText
      size={BodyTextSize.SMALL}
      text={
        <FormattedMessage
          defaultMessage='If you’re on a controlled medication (Adderall, Xanax, etc.) and wish to continue, we can help you understand your options. {hideLearnMoreLink, select, yes {} other {<link>Learn more</link>}}'
          description='Footnote information for access to medication'
          values={{
            link: (chunks: string) => (
              <Link
                text={chunks}
                size={BodyTextSize.SMALL}
                onPress={onLearnMorePress}
                underline
                testID={tID(`TreatmentCard-${name}-LearnMore`)}
              />
            ),
            hideLearnMoreLink: onLearnMorePress === noop ? 'yes' : 'other',
          }}
        />
      }
    />
  ) : (
    <BodyText
      size={BodyTextSize.SMALL}
      text={
        <FormattedMessage
          defaultMessage='If you require a prescription for controlled medications (such as Adderall or Xanax), please contact your health plan directly.'
          description='Footnote information for access to medication'
        />
      }
    />
  )

  const iconListItems = [
    { icon: <TrendUpIcon fillColor={colors.iconActive} />, text: SymptomTrackingText },
    {
      icon: <CalendarBookingIcon size='24' fillColor={colors.iconActive} />,
      text: EasyAccessToPrescriptionAndPhysicianText,
    },
    { icon: <InfoIcon fillColor={colors.iconActive} />, text: footnote },
  ]

  const content = (
    <>
      <ListHeader
        size={showModalView ? BodyTextSize.DEFAULT : BodyTextSize.LARGE}
        text={
          <FormattedMessage
            defaultMessage='We’re by your side throughout the entire process:'
            description='Title of list'
          />
        }
        showModalView={showModalView}
      />
      {showModalView ? (
        <>
          <ModalIconList data={iconListItems} itemStyle={{ alignItems: 'center' }} />
          <HealthPlanVerificationDisclaimerContainer>
            <BodyText size={BodyTextSize.SMALL} text={HealthPlanVerificationDisclaimerText} />
          </HealthPlanVerificationDisclaimerContainer>
        </>
      ) : (
        <BulletedListContainer data={bullets} spaceBetween={12} />
      )}
    </>
  )

  const valuePropositions = [
    {
      subtitle: <FormattedMessage defaultMessage='Build long-lasting skills' description='Title describing skills' />,
      body: (
        <FormattedMessage
          defaultMessage='Build skills to understand and manage symptoms and taper off medications when possible'
          description='Body copy for skills'
        />
      ),
      key: 'build_long_lasting_skills',
    },
    {
      subtitle: <FormattedMessage defaultMessage='Feel better faster' description='Title describing skills' />,
      body: (
        <FormattedMessage
          defaultMessage='80% remission with the combination of therapy and medication'
          description='Body copy describing skills'
        />
      ),
      key: 'feel_better_faster',
    },
    {
      subtitle: <FormattedMessage defaultMessage='Self-sustaining care' description='Title describing skill' />,
      body: (
        <FormattedMessage
          defaultMessage='Jumpstart care with fast-acting medication and lasting guidance for self-sustaining care'
          description='Body copy describing skills'
        />
      ),
      key: 'self_sustaining_care',
    },
  ]

  const valuePropContent = (
    <CheckIconListContainer horizontal={isMinWidthLaptop}>
      {valuePropositions.map(({ subtitle, body, key }) => (
        <CheckIconItemContainer key={key} isLaptop={isMinWidthLaptop}>
          <CheckIconListItem subtitle={subtitle} body={body} />
        </CheckIconItemContainer>
      ))}
    </CheckIconListContainer>
  )

  const expandableContent = (
    <>
      <Accordion
        title={
          <FormattedMessage
            defaultMessage='Why you might prefer Medication Management & Therapy together'
            description='Title for expandable content'
          />
        }
        content={valuePropContent}
      />
      {isMinWidthLaptop && <Divider color={colors.dividerPrimary} />}
    </>
  )

  const medsConsultModalTitle = (
    <FormattedMessage
      defaultMessage='Get a medication consultation'
      description='Title for modal that has information about the medication consultation offering'
    />
  )

  const cardTitle = showModalView ? (
    medsConsultModalTitle
  ) : (
    <FormattedMessage {...TREATMENT_OPTION_DISPLAY_NAMES.lyraMedManagement} />
  )

  const cardIcon = showModalView ? <MedicationConsultationIllustration /> : <Meds />

  return (
    <View testID={tID(`TreatmentCard-${name}`)}>
      <TreatmentCardTemplate
        {...rest}
        name={name}
        icon={cardIcon}
        title={cardTitle}
        description={formatMessage({
          defaultMessage:
            'Understand if emotional wellness medication could help you start feeling like yourself again. Speak with a board-certified Lyra physician to evaluate treatment options and determine if emotional wellness medication might work for you. If you’re already taking a medication, see if it’s still the best fit for your needs.',
          description: 'text giving an overview of medication treatment option',
        })}
        content={content}
        buttonText={
          <FormattedMessage defaultMessage='Book a consultation' description='Button text to book a consultation' />
        }
        expandableContent={showExpandableContent ? expandableContent : undefined}
        footnote={showFootnote ? footnote : undefined}
        background={medsBackground}
        showModalView={showModalView}
      />
    </View>
  )
}

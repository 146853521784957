import React, { FunctionComponent, useContext } from 'react'
import { Platform, Pressable, StyleSheet, TextStyle, View } from 'react-native'
import Markdown, { RenderFunction } from 'react-native-markdown-display'

import * as Linking from 'expo-linking'
import * as WebBrowser from 'expo-web-browser'
import styled, { useTheme } from 'styled-components/native'

import { InfoPopoverPlacement, InfoPopoverTriggerAction, TitleInfoPopover } from '@lyrahealth-inc/shared-app-logic'

import { AccessibilityRolesNative, IS_WEB } from '../../constants'
import { AppContext } from '../../context'
import { getLinkStyle } from '../../styles/commonStyles'
import { getTypeStyles, moderatBold } from '../../styles/typeStyles'
import { tID } from '../../utils'
import { BodyText, Size as BodyTextSize } from '../bodyText/BodyText'
import { QuestionMarkIcon } from '../icons'
import { InfoIcon } from '../icons/InfoIcon'
import { InfoPopover } from '../infoPopover/InfoPopover'
import { Link } from '../link/Link'
import { TOOLTIP_TRIGGER_ICON } from '../tooltip/Tooltip'

export type StyledMarkdownProps = {
  content?: string
  hasError?: boolean
  center?: boolean
  customStyles?: Dict
  linkInfoPopover?: TitleInfoPopover
  handleVideoLinkOpen?: (url: string) => Promise<void>
  onPopOverPress?: (link: string) => void
}

async function handleLinkOpen(
  url: string,
  handleVideoLinkOpen?: (url: string) => Promise<void>,
  customerName?: string,
  domain?: string,
) {
  const supported = await Linking.canOpenURL(url)
  if (supported && url.indexOf('https://') === 0) {
    if (url.startsWith('https://player.vimeo.com') && handleVideoLinkOpen !== undefined) {
      handleVideoLinkOpen(url)
    } else if (IS_WEB) {
      window.open(url, '_blank')
    } else {
      WebBrowser.openBrowserAsync(url)
    }
  } else if (customerName !== undefined && domain !== undefined) {
    // If link cannot be opened, try an internal LW link
    WebBrowser.openBrowserAsync(`https://${customerName}.${domain}${url}`)
  } else {
    console.warn({
      unsupported_link: `attempted to navigate to an unsupported url from a markdown link: ${url}`,
    })
  }
}

function getHeaderRenderFunction(style: string): RenderFunction {
  return (node, children, _parent, styles) => (
    <View accessible key={node.key} style={styles[style]} accessibilityRole={AccessibilityRolesNative.HEADER}>
      {children}
    </View>
  )
}

function getBulletListRenderFunction(): RenderFunction {
  return (node, children, _parent, styles) => (
    <React.Fragment key={node.key}>
      {children.map((child: any, index: number) => {
        if (index === children.length - 1) {
          return React.cloneElement(child, {
            key: child.key,
            style: [child.props.style, styles.lastListItem],
          })
        }
        return child
      })}
    </React.Fragment>
  )
}

function getLinkRenderFunction(style: TextStyle): RenderFunction {
  return (node, children) => (
    <Link
      style={style}
      text={children}
      onPress={() => {
        if (IS_WEB && node?.attributes?.href) {
          window.open(node.attributes.href, '_blank')
        } else {
          WebBrowser.openBrowserAsync(node.attributes.href)
        }
      }}
      containerStyle={{ display: 'inline' }}
    />
  )
}

const TriggerContainer = styled(Pressable)({
  // Temporary fix to keep the link text aligned to the rest of the text until further investigation can be done.
  // This margin adjustment seems to be irrespective of device size (tested on various Android & iOS devices)
  // Android: -6
  // iOS: -28
  // Web: does not matter, styling is unaffected by this value (!?)
  marginBottom: Platform.OS === 'android' ? -6 : -2,
})

function getTooltipRenderFunction(
  linkInfoPopover: TitleInfoPopover,
  handleVideoLinkOpen?: (url: string) => Promise<void>,
  onPopOverPress?: (link: string) => void,
  customerName?: string,
  domain?: string,
): RenderFunction {
  const header = <BodyText text={linkInfoPopover.header || linkInfoPopover.content} size={BodyTextSize.DEFAULT} />
  const content = linkInfoPopover.header ? (
    <BodyText text={linkInfoPopover.content} size={BodyTextSize.SMALL} />
  ) : undefined
  let footer: React.ReactElement | undefined
  if (linkInfoPopover.footer) {
    if (linkInfoPopover.linkType) {
      footer = (
        <Link
          text={linkInfoPopover.footer}
          underline
          onPress={() => {
            const link = (IS_WEB ? linkInfoPopover.webLink : linkInfoPopover.mobileLink) || ''
            if (linkInfoPopover.linkType === 'internal') {
              onPopOverPress && onPopOverPress(link)
            } else if (linkInfoPopover.linkType === 'external') {
              handleLinkOpen(link, handleVideoLinkOpen, customerName, domain)
            }
          }}
        />
      )
    } else {
      footer = linkInfoPopover.footer ? <BodyText text={linkInfoPopover.footer} size={BodyTextSize.SMALL} /> : undefined
    }
  }
  return (node, children, _parent, _styles) => (
    <InfoPopover
      key={node.key}
      unstyledWrapper
      placement={linkInfoPopover?.placement || InfoPopoverPlacement.TOP}
      header={header}
      content={content}
      footerContent={footer}
      trigger={
        <TriggerContainer testID={tID('popover-button')}>
          {linkInfoPopover.icon ? (
            linkInfoPopover.icon === TOOLTIP_TRIGGER_ICON.INFO ? (
              <InfoIcon size={16} />
            ) : (
              <QuestionMarkIcon width={16} />
            )
          ) : (
            <BodyText text={children} />
          )}
        </TriggerContainer>
      }
      animationExitDuration={Platform.OS === 'android' ? 50 : 500} // animation is janky on Android
      mode={Platform.OS === 'android' ? 'multiple' : 'single'}
      popoverTriggerAction={linkInfoPopover?.popoverTriggerAction || InfoPopoverTriggerAction.PRESS}
    />
  )
}

/**
 * This component is special in that it accesses the secure store from the
 * consuming app and uses the customerName and domain set on the device to create the markdown component
 */
export const StyledMarkdown: FunctionComponent<StyledMarkdownProps> = ({
  content = '',
  hasError,
  center,
  customStyles,
  linkInfoPopover,
  handleVideoLinkOpen,
  onPopOverPress,
}) => {
  const { customerName, domain } = useContext(AppContext)
  const { colors } = useTheme()

  const onLinkPress = (url: string) => {
    // Removes "{{new_tab}}" from url which is used on web to designate link targets
    const linkWithoutTabAttribute = url.replace(/ *%7B[^)]*%7D */g, '')
    handleLinkOpen(linkWithoutTabAttribute, handleVideoLinkOpen, customerName, domain)
    // Returning false prevents Markdown from opening link
    return false
  }

  const errorStyles = hasError ? { color: colors.textError, textAlign: 'left' } : {}
  const customStylesheet = StyleSheet.create({
    h1: { ...(customStyles?.h1 || {}), ...errorStyles },
    h2: { ...(customStyles?.h2 || {}), ...errorStyles },
    h3: { ...(customStyles?.h3 || {}), ...errorStyles },
    h4: { ...(customStyles?.h4 || {}), ...errorStyles },
    h5: { ...(customStyles?.h5 || {}), ...errorStyles },
    b1: { ...(customStyles?.b1 || {}), ...errorStyles },
    p1: { ...(customStyles?.p1 || {}), ...errorStyles },
    p2: { ...(customStyles?.p2 || {}), ...errorStyles },
    p3: { ...(customStyles?.p3 || {}), ...errorStyles },
    em: { ...(customStyles?.em || {}), ...errorStyles },
  } as { [key: string]: TextStyle })

  const typeStyles = getTypeStyles(colors)

  const elements = {
    h1: typeStyles.headlineMedium,
    h2: typeStyles.headlineSmall,
    h3: typeStyles.subheadLarge,
    h4: { ...typeStyles.subheadMedium, lineHeight: 26.4 },
    h5: typeStyles.subheadSmall,
    b1: typeStyles.bodyLarge,
    p1: typeStyles.subheadXsmall,
    p2: typeStyles.bodyDefault,
    p3: typeStyles.bodyDefault,
    em: typeStyles.em,
  }

  const elementStyles = Object.keys(elements).reduce((acc, key) => {
    acc[key] = [elements[key], customStylesheet[key]]
    return acc
  }, elements)

  const styles = {
    /* stylelint-disable -- external Markdown lib will handle react native styling */
    heading1: elementStyles.h1,
    heading2: elementStyles.h2,
    heading3: elementStyles.h3,
    heading4: elementStyles.h4,
    heading5: elementStyles.b1,
    heading6: elementStyles.p1,
    paragraph: elementStyles.p2,
    list_item: elementStyles.p2,
    lastListItem: elementStyles.p3,
    link: getLinkStyle(colors).link,
    em: elementStyles.em,
    strong: moderatBold as TextStyle,
    textgroup: center ? textGroupCentered.textGroupCentered : {},
    body: { flexShrink: 1 },
  }

  /**
   * Custom heading rules added for accessibility.
   * See https://github.com/iamacup/react-native-markdown-display/blob/master/src/lib/renderRules.js
   * Having to add bullet_list to handle edge case of when list is the last item in a section as that
   *  is messing up new line margin
   */
  const rules: { [key: string]: RenderFunction } = {
    heading1: getHeaderRenderFunction('_VIEW_SAFE_heading1'),
    heading2: getHeaderRenderFunction('_VIEW_SAFE_heading2'),
    heading3: getHeaderRenderFunction('_VIEW_SAFE_heading3'),
    heading4: getHeaderRenderFunction('_VIEW_SAFE_heading4'),
    heading5: getHeaderRenderFunction('_VIEW_SAFE_heading5'),
    heading6: getHeaderRenderFunction('_VIEW_SAFE_heading6'),
    bullet_list: getBulletListRenderFunction(),
    link: getLinkRenderFunction(styles.link),
    ...(linkInfoPopover && {
      link: getTooltipRenderFunction(linkInfoPopover, handleVideoLinkOpen, onPopOverPress, customerName, domain),
    }),
  }

  return (
    <Markdown onLinkPress={onLinkPress} style={styles} rules={rules}>
      {content}
    </Markdown>
  )
}

const textGroupCentered = StyleSheet.create({
  textGroupCentered: { textAlign: 'center' },
})

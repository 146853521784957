import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  DELETE_CLINICAL_DATA_ACCESS_CODE_fulfilled,
  GET_CLINICAL_DATA_ACCESS_CODES_FOR_USER_fulfilled,
  GET_CLINICAL_DATA_ACCESS_CODES_FOR_USER_rejected,
  POST_NEW_CLINICAL_DATA_ACCESS_CODE_fulfilled,
  SET_SELECTED_ACCESS_CODE,
} from './clinicalDataSharingActions'

const initialState = fromJS({
  clinicalDataAccessCodes: undefined,
  hasFetchedClinicalDataAccessCodes: false,
  selectedAccessCode: undefined,
})
export default createReducer(initialState, {
  [GET_CLINICAL_DATA_ACCESS_CODES_FOR_USER_fulfilled]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('hasFetchedClinicalDataAccessCodes', true)
    return $$state.set('clinicalDataAccessCodes', action?.payload ?? [])
  },
  [GET_CLINICAL_DATA_ACCESS_CODES_FOR_USER_rejected]: ($$state: $TSFixMe) => {
    $$state = $$state.set('hasFetchedClinicalDataAccessCodes', true)
    return $$state.set('clinicalDataAccessCodes', [])
  },
  [SET_SELECTED_ACCESS_CODE]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('selectedAccessCode', action?.data)
  },
  [DELETE_CLINICAL_DATA_ACCESS_CODE_fulfilled]: ($$state: $TSFixMe) => {
    return $$state.set('hasFetchedClinicalDataAccessCodes', false)
  },
  [POST_NEW_CLINICAL_DATA_ACCESS_CODE_fulfilled]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('selectedAccessCode', action?.payload)
    return $$state.set('hasFetchedClinicalDataAccessCodes', false)
  },
})

import { defineMessage } from 'react-intl'

import {
  CustomerRedirectionProps,
  CustomerSubpopulations,
  GetCustomerCopyFunc,
  TREATMENT_OPTIONS,
  TREATMENT_RECOMMENDATION_TIER,
} from '@lyrahealth-inc/shared-app-logic'
import { Linking } from '@lyrahealth-inc/ui-core-crossplatform'

export const qualcomm: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  //Displayed in the dropdown, maps the location to it's external health center url
  const healthCenterLocations = [
    { label: 'Santa Clara', value: 'https://myhealth.stanfordhealthcare.org/Partners/Qualcomm#/' },
    { label: 'San Diego', value: 'https://myscripps.org/' },
  ]
  const onsiteTherapyConfiguration = {
    customCopy: {
      title: 'Qualcomm Health Center',
      description:
        'Qualcomm employees enrolled in a Qualcomm medical plan have access to therapy appointments at the Qualcomm Health Center (QHC).  The QHC in Santa Clara and San Diego offer onsite access to mental health services for employees and their dependents aged 18 or above.\n\nLearn more about available services at the QHC by visiting go/QHC. If you are an established QHC patient, call Santa Clara QHC at 408-546-2000 (x462000) or San Diego QHC at 858-651-5918 (x515918) for scheduling assistance.',
      buttonText: 'Book appointment',
      url: '',
    },
    minAge: 18,
    // Adding dropdown per: https://lyrahealth.atlassian.net/browse/PCES-56
    locationsDropdown: {
      options: healthCenterLocations,
      label: defineMessage({
        defaultMessage: 'Choose an appointment location',
        description: "Label for dropdown menu of qualcomm's health center locations",
      }),
      placeholder: defineMessage({
        defaultMessage: 'Select location',
        description: 'Option text for selecting a health center location',
      }),
      ctaClickHandler: (dropdownValue: string) => {
        if (dropdownValue) {
          Linking.openURL(dropdownValue)
        }
      },
    },
    footnote: `Your Lyra mental health benefit covers the cost of up to ${numberOfSessions} therapy sessions per person per year. Appointments made with Qualcomm Health Center count towards your annual sessions limit.`,
  }

  const subpopulations: CustomerSubpopulations = {
    header: defineMessage({
      defaultMessage: 'Which Qualcomm medical plan are you enrolled in?',
      description: 'Qualcomm subpopulation redirection header',
    }),
    options: [
      {
        label: defineMessage({
          defaultMessage: 'Premier plans',
          description: 'Qualcomm premier plans label',
        }),
        description: defineMessage({
          defaultMessage:
            'US benefits-eligible employees enrolled in the Premier QDHP or Premier PPO, their eligible dependents and household members including COBRA participants.',
          description: 'Qualcomm premier plans description',
        }),
      },
      {
        label: defineMessage({
          defaultMessage: 'All others',
          description: 'Qualcomm other plans label',
        }),
        description: defineMessage({
          defaultMessage:
            'US benefits-eligible employees enrolled in the UHC QDHP or Kaiser QDHP, or have waived Qualcomm medical plan coverage, and their eligible dependents and household members including COBRA participants.',
          description: 'Qualcomm other plans description',
        }),
      },
    ],
  }

  const customerRedirection = ({ shouldShowBanner, onLinkPress }: CustomerRedirectionProps) => ({
    header: defineMessage({
      defaultMessage: 'Are you enrolled in the Premier QDHP/PPO medical plan?',
      description: 'Header text to ask user which medical plan they are enrolled in',
    }),
    checkboxLabel: defineMessage({
      defaultMessage: 'Yes, I’m enrolled in Premier QDHP/PPO',
      description: 'Redirection checkbox label',
    }),
    redirectionText: defineMessage({
      defaultMessage: 'If you are not enrolled in Premier QDHP/PPO, <link>click here</link>.',
      description:
        'Text to inform user they should click on the link to redirect them to the right page if they are not enrolled in certain medical plan',
    }),
    redirectionLinkPress: onLinkPress,
    shouldShowBanner,
  })

  return {
    treatmentOptions: [
      {
        option: TREATMENT_OPTIONS.ONSITE_THERAPY,
        config: onsiteTherapyConfiguration,
        optionRanking: {
          [TREATMENT_RECOMMENDATION_TIER.TIER_2]: 2,
          [TREATMENT_RECOMMENDATION_TIER.TIER_3]: 1,
          seeMore: 0,
        },
      },
    ],
    subpopulations,
    customerRedirection,
  }
}

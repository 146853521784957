import { useMemo } from 'react'

import { closestIndexTo } from 'date-fns'
import { inRange, isEmpty, isNumber, isUndefined } from 'lodash-es'

import { Outcome } from '../../../models'
import { AssessmentScoringCardModel, CLINICAL_MEASURE_RESULTS, CLINICAL_MEASURES, SCORING_ICON_STYLE } from '../types'

type GetAssessmentScoringCardDataProps = {
  assessmentId: string
  clinicalMeasures: CLINICAL_MEASURES[] | undefined
  outcomesData: Partial<Outcome>[]
}

// Calculates the positive or negative difference between two clinical measure scores and returns the appropriate
// model for display in the UI.
const createAssessmentScoringCardModel = (
  clinicalMeasure: CLINICAL_MEASURES,
  currentScore: number,
  previousScore: number,
): AssessmentScoringCardModel => {
  const scoreDelta = currentScore - previousScore
  const isDeltaPositive = Math.sign(scoreDelta) === 1

  let clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.NO_CHANGE
  let iconStyle = SCORING_ICON_STYLE.NEUTRAL_TREND_STYLE

  if (currentScore !== previousScore) {
    switch (clinicalMeasure) {
      case 'CHRT':
        if (isDeltaPositive) {
          inRange(scoreDelta, 5)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_INCREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.INCREASE)
          iconStyle = SCORING_ICON_STYLE.NEGATIVE_TREND_UP_STYLE
        } else {
          inRange(scoreDelta, -4, 0)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_DECREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.DECREASE)
          iconStyle = SCORING_ICON_STYLE.POSITIVE_TREND_DOWN_STYLE
        }
        break

      case 'GAD':
        if (isDeltaPositive) {
          inRange(scoreDelta, 4)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_INCREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.INCREASE)
          iconStyle = SCORING_ICON_STYLE.NEGATIVE_TREND_UP_STYLE
        } else {
          inRange(scoreDelta, -3, 0)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_DECREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.DECREASE)
          iconStyle = SCORING_ICON_STYLE.POSITIVE_TREND_DOWN_STYLE
        }
        break

      case 'PCL5':
        if (isDeltaPositive) {
          inRange(scoreDelta, 5)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_INCREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.INCREASE)
          iconStyle = SCORING_ICON_STYLE.NEGATIVE_TREND_UP_STYLE
        } else {
          inRange(scoreDelta, -4, 0)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_DECREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.DECREASE)
          iconStyle = SCORING_ICON_STYLE.POSITIVE_TREND_DOWN_STYLE
        }
        break

      case 'PHQ':
        if (isDeltaPositive) {
          inRange(scoreDelta, 5)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_INCREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.INCREASE)
          iconStyle = SCORING_ICON_STYLE.NEGATIVE_TREND_UP_STYLE
        } else {
          inRange(scoreDelta, -4, 0)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_DECREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.DECREASE)
          iconStyle = SCORING_ICON_STYLE.POSITIVE_TREND_DOWN_STYLE
        }
        break

      case 'PSS':
        if (isDeltaPositive) {
          inRange(scoreDelta, 6)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_INCREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.INCREASE)
          iconStyle = SCORING_ICON_STYLE.NEGATIVE_TREND_UP_STYLE
        } else {
          inRange(scoreDelta, -5, 0)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_DECREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.DECREASE)
          iconStyle = SCORING_ICON_STYLE.POSITIVE_TREND_DOWN_STYLE
        }
        break

      case 'WELL-BEING':
        if (isDeltaPositive) {
          inRange(scoreDelta, 4)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_INCREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.INCREASE)
          iconStyle = SCORING_ICON_STYLE.POSITIVE_TREND_UP_STYLE
        } else {
          inRange(scoreDelta, -3, 0)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_DECREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.DECREASE)
          iconStyle = SCORING_ICON_STYLE.NEGATIVE_TREND_DOWN_STYLE
        }
        break

      case 'WSAS':
        if (isDeltaPositive) {
          inRange(scoreDelta, 6)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_INCREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.INCREASE)
          iconStyle = SCORING_ICON_STYLE.NEGATIVE_TREND_UP_STYLE
        } else {
          inRange(scoreDelta, -5, 0)
            ? (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.SLIGHT_DECREASE)
            : (clinicalMeasureResult = CLINICAL_MEASURE_RESULTS.DECREASE)
          iconStyle = SCORING_ICON_STYLE.POSITIVE_TREND_DOWN_STYLE
        }
        break
    }
  }

  return {
    clinicalMeasure,
    clinicalMeasureResult,
    iconStyle,
  }
}

/**
 * Create an array of AssessmentScoringCardModels for the UI to use in the Assessment summary page
 * @param assessmentId      The id of the assessment that was just submitted.
 * @param clinicalMeasures  An array of clinical measures included in the submitted assessment.
 * @param outcomesData      The fetched outcomes data for the user.
 * @returns                 An array of scoring card models.
 */
const getAssessmentScoringCardData = ({
  assessmentId,
  clinicalMeasures,
  outcomesData,
}: GetAssessmentScoringCardDataProps) => {
  if (isUndefined(clinicalMeasures) || isEmpty(outcomesData)) {
    return []
  } else {
    const scoringCardData: AssessmentScoringCardModel[] = []
    const submittedAssessment = outcomesData.find((outcome) => outcome.assignment_id === assessmentId)

    if (submittedAssessment) {
      const submittedAssessmentScores = submittedAssessment.scores
      const submittedAssessmentDate = submittedAssessment.response_date as string

      // Loop through the clinicalMeasures array to create a model for each measure
      clinicalMeasures.forEach((clinicalMeasure) => {
        const outcomesWithClinicalMeasure = outcomesData.filter(
          (outcome) =>
            !isUndefined(outcome.scores && outcome.scores[clinicalMeasure]) &&
            outcome.assignment_id !== submittedAssessment?.assignment_id,
        )

        if (outcomesWithClinicalMeasure.length > 0) {
          // Create a list with just the response_date data so that date-fns can find the closest one to the
          // submittedAssessment response_date
          const filteredOutcomesResponseDates = outcomesWithClinicalMeasure.map(
            (outcome) => new Date(outcome.response_date as string),
          )

          // Find the index of the score with the closest response_date
          const mostRecentPreviousOutcomeIndex = closestIndexTo(
            new Date(submittedAssessmentDate),
            filteredOutcomesResponseDates,
          ) as number

          // Get the most recent previous scores that include the clinical measure
          const mostRecentPreviousOutcome = outcomesWithClinicalMeasure[mostRecentPreviousOutcomeIndex]
          const mostRecentOutcomeScores = mostRecentPreviousOutcome && mostRecentPreviousOutcome.scores

          // Get the score for submitted assessment and the most recent previous score
          const currentScore = submittedAssessmentScores && submittedAssessmentScores[clinicalMeasure]
          const previousScore = mostRecentOutcomeScores && mostRecentOutcomeScores[clinicalMeasure]

          if (isNumber(currentScore) && isNumber(previousScore)) {
            const scoreCardModel = createAssessmentScoringCardModel(clinicalMeasure, currentScore, previousScore)
            scoringCardData.push(scoreCardModel)
          }
        }
      })
    }

    return scoringCardData
  }
}

export const useGetAssessmentScoringCardData = ({
  assessmentId,
  clinicalMeasures,
  outcomesData,
}: GetAssessmentScoringCardDataProps): AssessmentScoringCardModel[] | [] => {
  return useMemo(
    () => getAssessmentScoringCardData({ assessmentId, clinicalMeasures, outcomesData }),
    [assessmentId, clinicalMeasures, outcomesData],
  )
}

import { fromJS, List, Map } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { Episode } from '@lyrahealth-inc/shared-app-logic'

import {
  ADD_MESSAGE,
  CLEAR_SELECTED_APPOINTMENT,
  CLEAR_SESSION_STORE,
  DELETE_CUSTOM_NOTIFICATION_PREFERENCES,
  DESELECT_MESSENGER_PROVIDER,
  GET_CHILD_EPISODES,
  GET_CURRENT_EPISODES,
  GET_CUSTOM_NOTIFICATION_PREFERENCES,
  GET_LT_APPOINTMENTS,
  GET_LT_APPOINTMENTS_BY_PROVIDER,
  GET_LT_APPOINTMENTS_FOR_CLIENT,
  GET_MESSAGE_CONVERSATION,
  GET_MESSAGES,
  GET_NOTIFICATION_PREFERENCES,
  GET_PROVIDERS,
  GET_VIDEO_CALL_TOKEN,
  HIDE_SESSION_CANCELLATION_MODAL,
  INCREMENT_UNREAD_MESSAGE_COUNT,
  SAVE_MESSAGE_DRAFT,
  SELECT_MESSENGER_PROVIDER,
  SET_ACTIVE_LIVE_MSG_SESSION,
  SET_APPOINTMENT,
  SET_APPOINTMENT_RESCHEDULE,
  SET_CONVERSATIONS_CLIENT,
  SET_CUSTOM_NOTIFICATION_PREFERENCES,
  SET_DASHBOARD_FETCHED,
  SET_EPISODES,
  SET_FIRST_APPOINTMENT_BOOKED,
  SET_INTAKE_FORM_OPEN,
  SET_JUST_SHOWED_PUSHED_NOTIFICATION_PROMPT,
  SET_LIVE_MSG_PROVIDER_ID_TO_OPEN,
  SET_LT_PROVIDER,
  SET_NOTIFICATION_PREFERENCES,
  SET_OUTCOMES,
  SET_PROGRAMS,
  SET_SHOW_SESSION_NOTIFICATION_PROMPT,
  SET_VIEWED_NOTIFICATION_MODAL,
  SHOW_SESSION_CANCELLATION_MODAL,
  TOGGLE_LIVE_MSG_SESSION,
  TOGGLE_SHOW_LIVE_MSG_MODAL,
  TOGGLE_SHOWN_LIVE_MSG_MODAL,
  TOGGLE_VIDEO_SESSION_MODAL,
  UPDATE_SELECTED_CONVERSATION_ID,
  UPDATE_UNREAD_MESSAGE_COUNT,
  VIDEO_SESSION_SETTINGS_UPDATE,
  VIDEO_SESSION_STATUS_UPDATE,
} from './LyraTherapyConstants'
import assignmentsReducer from '../assignments/data/assignmentsReducer'

export const initialState = fromJS({
  assignments: {
    data: [],
    selectedIndex: undefined,
  },
  outcomes: {
    data: [],
  },
  appointments: fromJS([]),
  provider: {},
  programs: [],
  episodes: [],
  childEpisodes: {}, // episodes for user's children, keyed by child's lyraId
  messenger: {
    selectedProviderIdx: 0,
    drafts: [],
  },
  video: {
    sessionOpen: false,
    sessionInfo: {},
    showModal: false,
    settings: {},
    appointments: [], // these are scoped segregated from "all" appointments since over time, the full list could grow substantially while the "today's upcoming video appointments" will likely always stay much smaller
  },
  sessions: {
    appointments: [],
    appointment: {},
    episodes: {
      current: [],
    },
  },
  notifications: {
    preferences: {},
    customNotificationPreferences: {},
  },
  dashboardFetched: false,
  intakeFormOpen: false,
  firstAppointmentBooked: false,
  justShowedPushNotificationPrompt: false,
  viewedNotificationModal: false,
})

// Common reducer shared across appointment endpoints
const appointmentSharedReducer = (
  $$state: Map<string, any>,
  action: {
    appointmentType?: string
    data?: any
  },
) => {
  if (action.appointmentType === 'video') {
    const activeAppts = action.data.filter((appt: { appointmentStatus: string }) => {
      return ['newAppt', 'rescheduled'].includes(appt.appointmentStatus)
    })
    $$state = $$state.setIn(['video', 'appointments'], fromJS(activeAppts))
  } else {
    const activeAppts = action.data.filter((appt: { appointmentStatus: string }) => {
      return ['newAppt', 'rescheduled'].includes(appt.appointmentStatus)
    })

    $$state = $$state.setIn(['video', 'appointments'], fromJS(activeAppts))
    $$state = $$state.set('appointments', fromJS(action.data))
  }

  return $$state
}

export default createReducer(initialState, {
  ...assignmentsReducer,
  [GET_VIDEO_CALL_TOKEN]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['video', 'sessionInfo'], fromJS(action.data))
    return $$state
  },
  [SET_LT_PROVIDER]: ($$state: Map<string, any>, action: { data: LyraTherapyReducerAction }) => {
    $$state = $$state.set('provider', fromJS(action.data))
    return $$state
  },
  [SET_EPISODES]: ($$state: Map<string, any>, action: { data: LyraTherapyReducerAction }) => {
    $$state = $$state.set('episodes', fromJS(action.data))
    return $$state
  },
  [SET_PROGRAMS]: ($$state: Map<string, any>, action: { data: LyraTherapyReducerAction }) => {
    $$state = $$state.set('programs', fromJS(action.data))
    return $$state
  },
  [GET_LT_APPOINTMENTS]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    return appointmentSharedReducer($$state, action)
  },
  [GET_LT_APPOINTMENTS_BY_PROVIDER]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    return appointmentSharedReducer($$state, action)
  },
  [GET_LT_APPOINTMENTS_FOR_CLIENT]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    return appointmentSharedReducer($$state, action)
  },
  [TOGGLE_VIDEO_SESSION_MODAL]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['video', 'showModal'], action.payload)
    return $$state
  },
  [VIDEO_SESSION_STATUS_UPDATE]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['video', 'sessionOpen'], action.payload)
    return $$state
  },
  [VIDEO_SESSION_SETTINGS_UPDATE]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['video', 'settings'], fromJS(action.payload))
    return $$state
  },
  [SET_APPOINTMENT]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(
      ['sessions', 'appointment'],
      $$state
        .getIn(['sessions', 'appointment'])
        .mergeWith((oldVal: any, newVal: any) => newVal || oldVal, fromJS(action.appointment)),
    )
    return $$state
  },
  [SET_APPOINTMENT_RESCHEDULE]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['sessions', 'appointmentReschedule'], fromJS(action.appointment))
    return $$state
  },
  [CLEAR_SESSION_STORE]: ($$state: Map<string, any>) => {
    $$state = $$state.setIn(['sessions', 'appointments'], fromJS([]))
    $$state = $$state.setIn(['sessions', 'appointment'], fromJS({}))
    return $$state
  },
  [CLEAR_SELECTED_APPOINTMENT]: ($$state: Map<string, any>) => {
    $$state = $$state.setIn(['sessions', 'appointment'], fromJS({}))
    return $$state
  },
  [SHOW_SESSION_CANCELLATION_MODAL]: ($$state: Map<string, any>) => {
    $$state = $$state.setIn(['sessions', 'showModal'], true)
    return $$state
  },
  [HIDE_SESSION_CANCELLATION_MODAL]: ($$state: Map<string, any>) => {
    $$state = $$state.setIn(['sessions', 'showModal'], false)
    return $$state
  },
  [GET_NOTIFICATION_PREFERENCES]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['notifications', 'preferences'], fromJS(action?.preferences?.data) || fromJS({}))
    return $$state
  },
  [SET_NOTIFICATION_PREFERENCES]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['notifications', 'preferences'], fromJS(action.preferences))
    return $$state
  },
  [DELETE_CUSTOM_NOTIFICATION_PREFERENCES]: ($$state: Map<string, any>) => {
    $$state = $$state.setIn(['notifications', 'customNotificationPreferences'], fromJS({}))
    return $$state
  },
  [SET_CUSTOM_NOTIFICATION_PREFERENCES]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['notifications', 'customNotificationPreferences'], fromJS(action.preferences))
    return $$state
  },
  [GET_CUSTOM_NOTIFICATION_PREFERENCES]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(
      ['notifications', 'customNotificationPreferences'],
      fromJS(action?.preferences?.data) || fromJS({}),
    )
    return $$state
  },
  [SET_SHOW_SESSION_NOTIFICATION_PROMPT]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['notifications', 'showSessionNotificationPrompt'], action.value)
    return $$state
  },
  [GET_MESSAGE_CONVERSATION]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['messenger', 'conversations'], fromJS(action.data))
    return $$state
  },
  [SELECT_MESSENGER_PROVIDER]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    let providerIdx
    if (typeof action.data === 'number') {
      providerIdx = action.data
    } else {
      providerIdx = $$state
        .get('providers')
        .findIndex(($$provider: any) => $$provider.getIn(['provider', 'lyra_id']) === action.data)
    }
    $$state = $$state.setIn(['messenger', 'selectedProviderIdx'], providerIdx)
    return $$state
  },
  [DESELECT_MESSENGER_PROVIDER]: ($$state: Map<string, any>) => {
    $$state = $$state.setIn(['messenger', 'selectedProviderIdx'], null)
    return $$state
  },
  [GET_MESSAGES]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['messenger', 'messages', action.conversationId], fromJS(action.data))
    return $$state
  },
  [ADD_MESSAGE]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    const conversationId = action.conversationId || action?.data?.conversation_sid
    const $$conversationMessages = $$state.getIn(
      ['messenger', 'messages', conversationId, 'conversation_messages'],
      List(),
    )
    $$state = $$state.setIn(
      ['messenger', 'messages', conversationId, 'conversation_messages'],
      $$conversationMessages.push(fromJS(action.data)),
    )
    return $$state
  },
  [UPDATE_UNREAD_MESSAGE_COUNT]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    const conversationIdx = $$state
      .getIn(['messenger', 'conversations'])
      .findIndex(($$conversation: any) => $$conversation.get('conversation_id') === action.conversationId)
    $$state = $$state.setIn(
      ['messenger', 'conversations', conversationIdx, 'conversation_attributes'],
      fromJS(action.data),
    )
    return $$state
  },
  [INCREMENT_UNREAD_MESSAGE_COUNT]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    const conversationIdx = $$state
      .getIn(['messenger', 'conversations'])
      .findIndex(($$conversation: any) => $$conversation.get('conversation_id') === action.conversationId)
    const currentUnreadCount = $$state.getIn([
      'messenger',
      'conversations',
      conversationIdx,
      'conversation_attributes',
      'unread_patient_messages_count',
    ])
    $$state = $$state.setIn(
      ['messenger', 'conversations', conversationIdx, 'conversation_attributes', 'unread_patient_messages_count'],
      currentUnreadCount + 1,
    )
    return $$state
  },
  [SET_CONVERSATIONS_CLIENT]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['messenger', 'conversationsClient'], fromJS(action.data))
    return $$state
  },
  [UPDATE_SELECTED_CONVERSATION_ID]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    const conversationIndex = $$state.getIn(['messenger', 'conversations']).findIndex(($$conversation: any) => {
      return $$conversation.get('conversation_id') === action?.data?.oldConversationId
    })
    const conversationMessages = $$state.getIn(['messenger', 'messages', action?.data?.oldConversationId])

    // Update the conversation_id to the new one.
    $$state = $$state.setIn(['selectedConversation', 'conversation_id'], action?.data?.newConversationId)
    $$state = $$state.setIn(['selectedConversation', 'conversation_state'], 'active')
    $$state = $$state.setIn(
      ['messenger', 'conversations', conversationIndex, 'conversation_id'],
      action?.data?.newConversationId,
    )
    $$state = $$state.setIn(['messenger', 'conversations', conversationIndex, 'conversation_state'], 'active')

    // Update the overall messages array that is in state
    $$state = $$state.setIn(['messenger', 'messages', action?.data?.newConversationId], conversationMessages)
    return $$state
  },
  [SAVE_MESSAGE_DRAFT]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    if (action?.data?.conversationId) {
      const $$drafts = $$state.getIn(['messenger', 'drafts'])
      const draftIdx = $$drafts.findIndex(
        ($$draft: Map<string, any>) => $$draft.get('conversationId') === action?.data?.conversationId,
      )
      if (draftIdx === -1) {
        $$state = $$state.setIn(['messenger', 'drafts'], $$drafts.push(fromJS(action.data)))
      } else {
        $$state = $$state.setIn(['messenger', 'drafts', draftIdx], fromJS(action.data))
      }
    }
    return $$state
  },
  [GET_CURRENT_EPISODES]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['sessions', 'episodes', 'current'], fromJS(action.data) || fromJS([]))
    return $$state
  },
  [SET_OUTCOMES]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['outcomes', 'data'], fromJS(action?.data?.outcomes))
    return $$state
  },
  [GET_PROVIDERS]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['providers'], fromJS(action.data))
    return $$state
  },
  [GET_CHILD_EPISODES]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    if (action.data?.childLyraId) {
      $$state = $$state.setIn(['childEpisodes', action.data.childLyraId], fromJS(action.data.episodes))
    }
    return $$state
  },
  [SET_DASHBOARD_FETCHED]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.set('dashboardFetched', action.value)
    return $$state
  },
  [TOGGLE_LIVE_MSG_SESSION]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['messenger', 'inLiveMsgSession'], action.payload)
    return $$state
  },
  [TOGGLE_SHOWN_LIVE_MSG_MODAL]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['messenger', 'shownModal'], action.payload)
    $$state = $$state.setIn(['messenger', 'showModal'], true)
    $$state = $$state.setIn(['messenger', 'inLiveMsgSession'], true)
    return $$state
  },
  [SET_ACTIVE_LIVE_MSG_SESSION]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    const { startsIn, appt } = action.payload
    $$state = $$state.setIn(['messenger', 'activeLiveMsgAppt'], fromJS(appt))
    $$state = $$state.setIn(['messenger', 'activeLiveMsgApptStartsIn'], startsIn)
    return $$state
  },
  [TOGGLE_SHOW_LIVE_MSG_MODAL]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['messenger', 'showModal'], action.payload)
    return $$state
  },
  [SET_LIVE_MSG_PROVIDER_ID_TO_OPEN]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['messenger', 'liveMsgProviderIdToOpen'], action.payload)
    return $$state
  },
  [SET_INTAKE_FORM_OPEN]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['intakeFormOpen'], action.payload)
    return $$state
  },
  [SET_FIRST_APPOINTMENT_BOOKED]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['firstAppointmentBooked'], action.payload)
    return $$state
  },
  [SET_JUST_SHOWED_PUSHED_NOTIFICATION_PROMPT]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['justShowedPushNotificationPrompt'], action.payload)
    return $$state
  },
  [SET_VIEWED_NOTIFICATION_MODAL]: ($$state: Map<string, any>, action: LyraTherapyReducerAction) => {
    $$state = $$state.setIn(['viewedNotificationModal'], action.payload)
    return $$state
  },
})

type LyraTherapyReducerAction = {
  data?: {
    conversation_sid?: string
    oldConversationId?: string
    newConversationId?: string
    conversationId?: string
    outcomes?: {
      data: []
    }
    childLyraId?: string
    episodes?: Episode[]
  }
  payload?: any
  appointment?: object
  preferences?: { data?: object }
  action?: {
    appointmentType?: string
    data?: any
  }
  conversationId?: string
  value?: any
}

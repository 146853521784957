import { defineMessage } from 'react-intl'

import axios from 'axios'
import { fromJS, Map } from 'immutable'
import { AnyAction, Dispatch } from 'redux'

import {
  AppointmentClass,
  AvailabilitiesResponse,
  CALENDAR_SERVICE_BASE_URL,
  DAILY_END_TIME,
  DAILY_START_TIME,
  ProviderProgramTaxonomy,
  SLICE_DURATION_USER_MINUTES,
  TREATMENT_OPTIONS_TYPE,
} from '@lyrahealth-inc/shared-app-logic'

import * as BannerActions from '../../../../common/components/banner/banner-actions'
import { getJwt } from '../../../../common/http/data/jwt'
import { getAllTreatmentOptionsCosts, getProvider, getTreatmentTypeCost } from '../../../../common/http/data/onboard'
import { logToSumoLogic } from '../../../../common/utils/userUtils'
import { trackOnboardEvent } from '../../../../data/mixpanel'
import { getSelectedSearchId } from '../../../triage/data/triageSelectors'
import { createCrmNotificationForProviderSelection } from '../../data/crmActions'
import { getOnboardProvidersDisplayed } from '../../data/onboardSelectors'

export const GET_PROVIDER_COST_DATA = 'GET_PROVIDER_COST_DATA'
export const SET_PROVIDER_PROFILE_DATA = 'SET_PROVIDER_PROFILE_DATA'
export const CLEAR_PROVIDER_PROFILE_DATA = 'CLEAR_PROVIDER_PROFILE_DATA'
export const SET_PROVIDER_AVAILABILITY_DATA_CS = 'SET_PROVIDER_AVAILABILITY_DATA_CS'

const getProviderFromStore = (id: string) => {
  return (dispatch: Dispatch<AnyAction>, getState: () => Map<string, any>) => {
    const foundProvider = getOnboardProvidersDisplayed(getState()).find(($$provider) => {
      return $$provider && $$provider.lyra_id === id
    })
    if (foundProvider && (foundProvider as $TSFixMe).size !== 0) {
      dispatch(setProviderProfileData(foundProvider))
      return Promise.resolve(foundProvider)
    } else {
      return Promise.reject(new Error('No provider found'))
    }
  }
}

export const getProviderProfileData = (id: $TSFixMe) => {
  const request = getProvider(id)

  return (dispatch: $TSFixMe) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (providerReturn) => {
          dispatch(setProviderProfileData(fromJS(providerReturn).getIn(['data', '0', 'providersInfo', '0'])))
          resolve(providerReturn)
        },
        (error) => {
          dispatch(
            BannerActions.setBannerPropsAndStatus(
              BannerActions.BANNER_TYPE.DANGER,
              defineMessage({
                defaultMessage: 'Unable to get provider profile data',
                description: "Error message when getting provider's profile fails",
              }),
            ),
          )
          reject(error)
        },
      )
    })
  }
}

export const setProviderProfileData = (data: $TSFixMe) => {
  return {
    type: SET_PROVIDER_PROFILE_DATA,
    payload: data,
  }
}

export const clearProviderProfileData = () => {
  return {
    type: CLEAR_PROVIDER_PROFILE_DATA,
  }
}

// Given a provider, sets said provider as the chosen provider and navigates directly to the provider's profile.
// This should only be used when the provider is the only provider returned, but it is possible to shoehorn it in
// with a different use, so the Mixpanel event signaling this is conditional.
export const setProvider = ({ provider, isOnlyProviderReturned = false }: $TSFixMe) => {
  return (dispatch: $TSFixMe) => {
    dispatch(setProviderProfileData(provider))
    if (isOnlyProviderReturned) {
      dispatch(
        trackOnboardEvent({
          event: 'View provider recommendations - single',
        }),
      )
    }
    dispatch(createCrmNotificationForProviderSelection({ provider }))
  }
}

export interface GetProviderInitialSessionCostParams {
  providerId: string
  userId: string
  outsideOnboarding: boolean
}

export const getProviderInitialSessionCost = ({
  providerId,
  userId,
  outsideOnboarding,
}: GetProviderInitialSessionCostParams) => {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const state = getState()
    const searchId = getSelectedSearchId(state)

    // PROSPECT-3699 - Protects against the case where a user navigates backwards to an onboard provider profile link after exiting triage.
    // At this point we can't assume the searchId that was associated with the onboard provider profile, which is used to determine the treatment type
    // so we treat it the same as visiting a direct link provider profile when estimating the cost.
    const treatmentOptionTypeUnknown = outsideOnboarding || !searchId
    const request = treatmentOptionTypeUnknown
      ? getAllTreatmentOptionsCosts(providerId, userId)
      : getTreatmentTypeCost(providerId, userId, searchId)

    return new Promise(function (resolve, reject) {
      request.then(
        (costReturn) => {
          let costInfo

          if (treatmentOptionTypeUnknown && costReturn?.data?.length === 1) {
            /*
              If the user lands on a provider's profile via direct link, the BE will return an array of costs
              associated with each treatment type offered. If the provider offers only one treatment option, we
              display the cost of that treatment option, else costInfo defaults to an empty object, resulting in a
              hardcoded cost range displayed
            */
            costInfo = costReturn?.data[0]
          } else {
            // The user is in onboarding so the BE will return the cost of the user's selected treatment option
            costInfo = costReturn?.data
          }

          dispatch({
            type: GET_PROVIDER_COST_DATA,
            payload: costInfo || {},
          })
          resolve(costInfo)
        },
        (error) => {
          dispatch({
            type: GET_PROVIDER_COST_DATA,
            payload: {},
          })
          reject(error)
        },
      )
    })
  }
}

type ReconcileProviderCalendarServiceParams = {
  outsideOnboarding: boolean
  treatmentOption?: TREATMENT_OPTIONS_TYPE
  userId: string
  blendedCareProgramId: string
}

export const reconcileProviderCalendarService = (
  providerId: string,
  params: ReconcileProviderCalendarServiceParams,
) => {
  return (dispatch: Dispatch<AnyAction>, getState: () => Map<string, any>) => {
    return new Promise<AvailabilitiesResponse | void>(function (resolve, reject) {
      if (!getState().getIn(['appGlobals', 'isHydrated'])) {
        dispatch({
          type: 'RECONCILE_PROVIDER_FAILED',
          error: 'Store was not hydrated. Try again once the store is hydrated to prevent race conditions.',
        })
        return reject(new Error('Error reconciling Provider because the store was not hydrated'))
      }

      return dispatch(getProviderFromStore(providerId) as any)
        .then(undefined, () => {
          // The provider isn't in the store, so retrieve their data via API
          return dispatch(getProviderProfileData(providerId) as any)
        })
        .finally(() => {
          const { userId, outsideOnboarding, blendedCareProgramId } = params

          return dispatch(
            getProviderInitialSessionCost({
              providerId,
              userId,
              outsideOnboarding,
            }) as any,
          ).then(
            (costReturn: any) => {
              return resolve(costReturn)
            },
            (error: any) => {
              const errorMessage = 'Unable to get provider initial session cost. ' + error.message
              logToSumoLogic('lyraWebErrors', userId, {
                message: errorMessage,
                outsideOnboarding,
                selectedTreatmentOption: params.treatmentOption,
                blendedCareProgramId,
              })
              // The promise is being resolved, here in the error handler, rather than rejected because
              // we are handling any error in retrieving the cost info as if no cost info was returned.
              // This allows the provider profile to completely load and only show the hardcoded cost
              // info in the case of hpi-exclusive programs. The error is being logged to sumo logic
              // above and no further handling is required.
              return resolve()
            },
          )
        })
        .catch((error: any) => reject(error))
    })
  }
}

type GetProviderAvailabilityCalendarServiceParams = {
  programTaxonomy: Omit<ProviderProgramTaxonomy, 'role'>
  appointmentClass: AppointmentClass
  numDaysToFetchMs: number
  appointmentDuration?: number
}
export const getProviderAvailabilityCalendarService = (
  providerId: string,
  params: GetProviderAvailabilityCalendarServiceParams,
) => {
  return (dispatch: Dispatch<AnyAction>, _: () => Map<string, any>) => {
    return new Promise<AvailabilitiesResponse | void>(function (resolve) {
      getProviderAvailabilityCalendarServicePromise(providerId, params).then((response) => {
        dispatch(setProviderAvailabilityCalendarService(response))
        resolve(response)
      })
    })
  }
}

const getProviderAvailabilityCalendarServicePromise = async (
  providerId: string,
  params: GetProviderAvailabilityCalendarServiceParams,
): Promise<AvailabilitiesResponse> => {
  const { data: token } = await getJwt()
  const { data: availability } = await axios.post(
    `${process.env.CS_API_URL ?? CALENDAR_SERVICE_BASE_URL}/api/v1/provider/availability`,
    { provider_ids: [providerId] },
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        slice_duration_minutes: SLICE_DURATION_USER_MINUTES,
        appointment_duration_minutes: params?.appointmentDuration,
        start_datetime: new Date().toISOString(),
        end_datetime: new Date(Date.now() + params.numDaysToFetchMs).toISOString(),
        daily_start_time: DAILY_START_TIME,
        daily_end_time: DAILY_END_TIME,
        clientele: params.programTaxonomy.clientele,
        treatment: params.programTaxonomy.treatment,
        partner: params.programTaxonomy.partner,
        offering: params.programTaxonomy.offering,
        appointment_class: params.appointmentClass,
      },
    },
  )
  return availability
}

export const setProviderAvailabilityCalendarService = (data: AvailabilitiesResponse) => {
  return {
    type: SET_PROVIDER_AVAILABILITY_DATA_CS,
    payload: data,
  }
}

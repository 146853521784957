import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import cx from 'classnames'
import styled, { CSSObject } from 'styled-components'

import { RIGHT_TO_LEFT_LANGUAGES, spacing, useLyraIntl, useWindowSize } from '@lyrahealth-inc/shared-app-logic'
import { LYRA_DISPLAY_LANGUAGES, sortDisplayLanguages } from '@lyrahealth-inc/shared-app-logic/src/features/intl'
import { TextButton } from '@lyrahealth-inc/ui-core'
import {
  ChevronIcon,
  ChevronIconDirection,
  colors,
  GlobeIcon,
  RightToLeftLanguageAlertModal,
  useMediaQuerySize,
} from '@lyrahealth-inc/ui-core-crossplatform'

import LanguageSelectorModal from './LanguageSelectorModal'
import { getBeforeLaunch } from '../../data/customer/customerSelectors'

export interface LanguageSelectorProps {
  className?: string
  textColor?: string
  dropdownAlignment?: 'left' | 'right'
  customActionTextColor?: string
  beforeLaunch: boolean
  style?: CSSObject
}

const LanguageSelectorContainer = styled.div<{
  textColor?: string
  isMobileSized?: boolean
  isTabletSized: boolean
  customActionTextColor?: string
}>`
  display: inline-block;
  position: relative;
  .LanguageSelector-dropdown-button > span {
    line-height: 43px;
  }
  .button.button.button {
    color: ${(props) => props.textColor};
    margin: ${(props) => (props.isMobileSized ? '0' : '0 24px')};
    padding: 0;
    min-width: unset;

    .text {
      text-decoration: underline;
    }

    .globe-icon {
      position: relative;
      top: 6px;

      svg {
        vertical-align: baseline;
        margin-right: ${(props) => (props.isTabletSized ? '9px' : '0')};
      }
    }

    .chevron-icon {
      margin-left: 8px;
      top: 4px;
      position: ${(props) => (props.isMobileSized ? 'initial' : 'relative')};

      svg {
        vertical-align: baseline;
        margin-right: 2px;
      }
    }

    &:hover {
      color: ${(props) => props.customActionTextColor};
      .globe-icon {
        svg {
          vertical-align: baseline;
          color: ${(props) => props.customActionTextColor};
          stroke: ${(props) => props.customActionTextColor};
        }
      }
    }
    &:active,
    &:focus,
    &:focus:not(:focus-visible) {
      background-color: transparent;
      box-shadow: none;
      color: ${(props) => props.customActionTextColor};
      border: 1px solid ${(props) => props.customActionTextColor};
      .globe-icon {
        svg {
          vertical-align: baseline;
          color: ${(props) => props.customActionTextColor};
          stroke: ${(props) => props.customActionTextColor};
        }
      }
    }
  }
`

const LanguageSelectorModalContainer = styled.div<{
  isMobileSized?: boolean
  beforeLaunch?: boolean
  mobilePositionTop?: string
}>`
  position: ${(props) => (props.isMobileSized ? 'fixed' : 'absolute')};
  display: block;
  border-radius: ${spacing['8px']};
  cursor: default;
  padding: ${(props) => (props.beforeLaunch ? `${spacing['24px']}` : `${spacing['24px']} ${spacing['24px']} 0`)};
  margin: ${(props) => (props.isMobileSized ? '0' : '16px 0 0 0')};
  z-index: 99;
  background-color: ${colors.white};
  border: none;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  min-width: 335px;

  ${({ isMobileSized, mobilePositionTop }) =>
    isMobileSized &&
    `
      min-width: 100%;
      top: ${mobilePositionTop};
      right: 0;
      width: 100vw;
      height: calc(var(--vh) - ${mobilePositionTop});
    `}
`

const LanguageSelector: React.FunctionComponent<LanguageSelectorProps> = ({
  className,
  textColor = colors.white,
  dropdownAlignment = 'left',
  customActionTextColor,
  beforeLaunch,
  style,
}) => {
  const { formatMessage } = useIntl()
  const { isMinWidthTablet, isMobileSized } = useMediaQuerySize()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const modalDropdownRef = useRef(null)
  const { activeLanguage, setLanguage, availableLanguages: defaultAvailableLanguages } = useLyraIntl()
  const [modalAlignment, setModalAlignment] = useState(dropdownAlignment)
  const MOBILE_POSITION_TOP_PX = '56px'

  const [, windowHeight] = useWindowSize()

  const [rightToLeftLanguageAlertModalState, setRightToLeftLanguageAlertModalState] = useState({
    selectedLocale: '',
    isModalOpened: false,
  })

  const onLanguageSelectorModalClick = (selectedLocale: string) => {
    if (RIGHT_TO_LEFT_LANGUAGES.hasOwnProperty(selectedLocale)) {
      setRightToLeftLanguageAlertModalState({
        selectedLocale,
        isModalOpened: true,
      })
      return
    } else if (selectedLocale !== activeLanguage) {
      setLanguage(selectedLocale)
    }
    setIsModalOpen(false)
  }

  useEffect(() => {
    // Add check to see if modal is offscreen, if so default modal to right alignment
    if (isModalOpen && modalDropdownRef?.current && modalAlignment !== 'right') {
      const element = (modalDropdownRef.current as $TSFixMe).getBoundingClientRect()
      const divPosition = element.left + element.width
      if (divPosition > window.innerWidth) {
        setModalAlignment('right')
      }
    }
  }, [isModalOpen, modalDropdownRef, modalAlignment])

  useEffect(() => {
    // Disable scrolling for mobile view when modal is open to keep focus on language selector
    document.body.style.overflow = isMobileSized && isModalOpen ? 'hidden' : ''
  }, [isMobileSized, isModalOpen])

  const currentLanguage = LYRA_DISPLAY_LANGUAGES[activeLanguage].name

  const rootRef = React.useRef<HTMLDivElement>(null)
  // close the dropdown when clicking outside of the selector
  useEffect(() => {
    const handleOutsideClick = (e: Event) => {
      e.stopPropagation()
      if (
        rootRef.current &&
        e.target &&
        !rootRef.current.contains(e.target as HTMLElement) &&
        !rightToLeftLanguageAlertModalState.isModalOpened
      ) {
        setIsModalOpen(false)
      }
    }

    window.addEventListener('mousedown', handleOutsideClick, false)
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick, false)
    }
  }, [rootRef, setIsModalOpen, rightToLeftLanguageAlertModalState.isModalOpened])

  const availableLanguages: string[] = [...defaultAvailableLanguages]
  /**
   * Adding the right to left languages directly to the language selector because adding them to the config
   * would also add matching and selecting based on the users browser default language setting
   * and this would also load translation files which do not exist for RTL languages.
   *
   * Disabling Coming Soon RTL languages for the selector when Pathways is enabled because Bukwild has not supported
   * the new look and feel of pathways for RTL languages yet.
   */
  availableLanguages.push(...Object.keys(beforeLaunch ? {} : RIGHT_TO_LEFT_LANGUAGES))

  const handleShowClassicVersionPress = () => {
    if (rightToLeftLanguageAlertModalState.selectedLocale) {
      window.location.href = `/${
        RIGHT_TO_LEFT_LANGUAGES[rightToLeftLanguageAlertModalState.selectedLocale].micrositePath
      }`
    }
  }

  return (
    <>
      <LanguageSelectorContainer
        ref={rootRef}
        className={cx(className)}
        data-test-id='LanguageSelection-button'
        isMobileSized={isMobileSized}
        isTabletSized={isMinWidthTablet}
        textColor={textColor}
        customActionTextColor={customActionTextColor}
        style={style}
      >
        <TextButton
          customClass='button LanguageSelector-dropdown-button'
          data-test-id='LanguageSelector-button'
          onClick={() => {
            setIsModalOpen(!isModalOpen)
          }}
          aria-expanded={isModalOpen}
          aria-label={formatMessage({
            defaultMessage: 'Display language selector',
            description: 'Screen reader text for the display language selector',
          })}
        >
          <span className='globe-icon'>
            <GlobeIcon color={textColor} fillColor={textColor} />
          </span>
          {!isMobileSized && (
            <span data-test-id='LanguageSelector-language' className='text'>
              {currentLanguage}
            </span>
          )}
          <span className='chevron-icon'>
            <ChevronIcon
              fillColor={textColor}
              direction={isModalOpen ? ChevronIconDirection.UP : ChevronIconDirection.DOWN}
            />
          </span>
        </TextButton>
        {isModalOpen && (
          <LanguageSelectorModalContainer
            ref={modalDropdownRef}
            style={
              isMobileSized
                ? ({ '--vh': `${windowHeight}px` } as React.CSSProperties)
                : { [modalAlignment]: spacing['24px'] }
            }
            isMobileSized={isMobileSized}
            beforeLaunch={beforeLaunch}
            mobilePositionTop={MOBILE_POSITION_TOP_PX}
          >
            <LanguageSelectorModal
              activeLanguage={activeLanguage}
              availableLanguages={sortDisplayLanguages(availableLanguages)}
              onClick={onLanguageSelectorModalClick}
            />
            {
              // eslint-disable-next-line formatjs/no-literal-string-in-jsx
              ' '
            }
          </LanguageSelectorModalContainer>
        )}
      </LanguageSelectorContainer>
      {rightToLeftLanguageAlertModalState.isModalOpened && (
        <RightToLeftLanguageAlertModal
          selectedLocale={rightToLeftLanguageAlertModalState.selectedLocale!}
          onShowClassicVersionPress={handleShowClassicVersionPress}
          onUpdateLanguagePress={() =>
            setRightToLeftLanguageAlertModalState({ selectedLocale: '', isModalOpened: false })
          }
          visible={rightToLeftLanguageAlertModalState.isModalOpened}
        />
      )}
    </>
  )
}

const mapStateToProps = ($$state: Map<string, any>) => {
  return {
    beforeLaunch: getBeforeLaunch($$state),
  }
}

export default connect(mapStateToProps)(LanguageSelector)

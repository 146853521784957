import React from 'react'
import { TextStyle, View, ViewStyle } from 'react-native'

import { BodyText } from '../../atoms'
import { BodyTextSize } from '../../styles'

export interface EligibilityInformationProps {
  text: React.ReactNode
  style?: ViewStyle
  textStyle?: TextStyle
}

export const EligibilityInformation: React.FC<EligibilityInformationProps> = ({ text, style, textStyle }) => {
  return (
    <View style={{ marginBottom: 12, ...style }}>
      <BodyText text={text} size={BodyTextSize.DEFAULT} style={textStyle} />
    </View>
  )
}

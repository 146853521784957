import { defineMessage } from 'react-intl'

import { isEqual } from 'lodash-es'

import { OPTIONS_TYPE, PreferenceMetadataConfig, RENDER_CONDITION_TARGETS } from './types'
import { MEMBER_PREFERENCE_IDS, S4C_PROGRAM_OPTION_FLOW } from '../../memberPreferences/constants'
import { MEMBER_PREFERENCE_LABELS } from '../../searchForCare/messages'

const religionPreference: PreferenceMetadataConfig = {
  name: 'religions',
  title: MEMBER_PREFERENCE_LABELS.religions,
  options: [
    {
      text: MEMBER_PREFERENCE_LABELS.buddhist,
      id: MEMBER_PREFERENCE_IDS.BUDDHIST,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.christian,
      id: MEMBER_PREFERENCE_IDS.CHRISTIAN,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.hindu,
      id: MEMBER_PREFERENCE_IDS.HINDU,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.jewish,
      id: MEMBER_PREFERENCE_IDS.JEWISH,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.muslim,
      id: MEMBER_PREFERENCE_IDS.MUSLIM,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.nonReligiousAtheistAgnostic,
      id: MEMBER_PREFERENCE_IDS.NON_RELIGIOUS_ATHEIST_AGNOSTIC,
    },
  ],
  optionType: OPTIONS_TYPE.CHECKBOX,
  icon: 'MemberPreferencesReligionIllustrationIcon',
  promptText: defineMessage({
    defaultMessage: 'I prefer a provider who has experience working with clients who are any of the following:',
    description: 'Text prompting the user to select options they associate with',
  }),
  footerText: {
    message: defineMessage({
      defaultMessage:
        'We’ll prioritize finding you providers with this experience. We’ll also show you providers with experience supporting clients of faith.',
      description:
        'Description text clarifying to users that selecting an option here will show providers experienced supporting clients of faith',
    }),
    renderConditions: [
      {
        target: RENDER_CONDITION_TARGETS.SELECTED_PREFERENCES,
        path: 'religions',
        fn: (val) => !isEqual(val, []) && !isEqual(val, [MEMBER_PREFERENCE_IDS.NON_RELIGIOUS_ATHEIST_AGNOSTIC]),
      },
    ],
    color: 'textInactive',
  },
  visibleFor: [
    S4C_PROGRAM_OPTION_FLOW.COACHING,
    S4C_PROGRAM_OPTION_FLOW.COACHING_WITH_LMS,
    S4C_PROGRAM_OPTION_FLOW.THERAPY,
  ],
}

export default religionPreference

import { useMemo } from 'react'

import { useProgram } from './useProgram'
import {
  Program,
  PROVIDER_TYPES,
  ProviderEnums,
  ProviderInfo,
  ProviderProgramTaxonomy,
  ProviderType,
} from '../../../models'
import { DEFAULT_PROGRAM_TAXONOMY, PROGRAMS, ProgramType } from '../../common/constants/customerProgram'
import { TREATMENT_OPTIONS_TYPE } from '../../common/constants/treatmentOptions'

type Params = {
  provider?: ProviderInfo
  programs: Program[]
  treatmentOption: TREATMENT_OPTIONS_TYPE | null
}

/**
 * Hook used to get the program taxonomy that matches the selected treatment option
 */
export const useProgramTaxonomy = ({ provider, programs, treatmentOption }: Params) => {
  const selectedProgram = useProgram({ programs, treatmentOption })

  return useMemo(() => {
    if (!provider || !selectedProgram) {
      return DEFAULT_PROGRAM_TAXONOMY
    }

    let providerTaxonomyOptions: Omit<ProviderProgramTaxonomy, 'role'>[] | undefined
    if ('program_taxonomy' in provider) {
      providerTaxonomyOptions = provider.program_taxonomy
    } else {
      const providerStringType = provider?.lyra_type?.toUpperCase?.()
      const enumValue = ProviderEnums[providerStringType] ?? ProviderEnums.THERAPIST
      const providerType: ProviderType = PROVIDER_TYPES[enumValue]
      const programType: ProgramType = PROGRAMS[providerType.program]
      providerTaxonomyOptions = programType.treatmentOptionProperties?.programTaxonomyTreatmentOptions
    }

    if (!providerTaxonomyOptions) {
      return DEFAULT_PROGRAM_TAXONOMY
    }

    if (providerTaxonomyOptions.length === 1) {
      return providerTaxonomyOptions[0]
    }

    return selectedProgram.program_taxonomy?.find(({ clientele, offering, partner, treatment }) =>
      providerTaxonomyOptions?.find(
        (pt) =>
          pt.clientele === clientele &&
          pt.offering === offering &&
          pt.partner === partner &&
          pt.treatment === treatment,
      ),
    )
  }, [provider, selectedProgram])
}

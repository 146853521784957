import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Thinner and larger checkmark icon than CheckMarkIcon
 */
export const CheckMarkIconV2: FunctionComponent<CheckMarkIconProps> = ({
  fillColor,
  width = '24',
  height = '24',
  testID = 'CheckMarkIconV2',
}) => {
  const { colors } = useTheme()

  return (
    <Svg width={width} height={height} viewBox='0 0 24 24' fill={fillColor || colors.iconActive} testID={testID}>
      <Path
        d='M10.4039 17.5812V17.0442C10.6053 17.0442 10.7966 16.9666 10.9414 16.8208L20.0268 7.73539C20.3241 7.43806 20.3241 6.95781 20.0268 6.66049C19.7294 6.36316 19.2492 6.36316 18.9518 6.66053L10.404 15.2065L10.0243 14.8271L7.04765 11.8521C6.75032 11.5548 6.27007 11.5548 5.97275 11.8521C5.67542 12.1495 5.67542 12.6297 5.97275 12.927L9.86778 16.8221C10.0113 16.9666 10.2026 17.0442 10.4039 17.0442V17.5812Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

type CheckMarkIconProps = {
  fillColor?: string
  width?: string
  height?: string
  testID?: string
}

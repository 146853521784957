import React, { FunctionComponent } from 'react'
import { ClipPath, Defs, G, Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Icon for Notifications
 */
export const BellIcon: FunctionComponent<BellIconProps> = ({ size = 20 }) => {
  const { colors } = useTheme()
  const { clipPath } = colors.icons.bellIcon

  return (
    <Svg width={size} height={size} fill='none'>
      <G clipPath='url(#bell-icon)'>
        <Path
          d='M15 6.664a5 5 0 0 0-10 0c0 5.833-2.5 7.5-2.5 7.5h15s-2.5-1.667-2.5-7.5ZM11.442 17.5a1.666 1.666 0 0 1-2.883 0'
          stroke={colors.iconActive}
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 1a5.75 5.75 0 0 0-5.75 5.75c0 2.806-.6 4.555-1.158 5.578-.28.514-.554.852-.746 1.055a2.594 2.594 0 0 1-.27.248A.75.75 0 0 0 2.5 15h15a.75.75 0 0 0 .424-1.369l-.004-.003a2.589 2.589 0 0 1-.266-.246 5.028 5.028 0 0 1-.745-1.054c-.559-1.023-1.159-2.772-1.159-5.578A5.75 5.75 0 0 0 10 1ZM2.076 13.631s0 .001 0 0h.002l.003-.003h.001l-.006.003Zm13.516-.584c.089.163.178.314.267.453H4.141c.089-.139.178-.29.267-.453C5.1 11.778 5.75 9.777 5.75 6.75a4.25 4.25 0 1 1 8.5 0c0 3.027.65 5.028 1.342 6.297Zm-6.385 4.077a.75.75 0 0 0-1.297.752 2.417 2.417 0 0 0 4.18 0 .75.75 0 0 0-1.297-.752.916.916 0 0 1-1.586 0Z'
          fill={colors.iconActive}
        />
      </G>
      <Defs>
        <ClipPath id='bell-icon'>
          <Path fill={clipPath.fill} d='M0 0h20v20H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

type BellIconProps = {
  size?: string | number
}

import React, { FunctionComponent, ReactElement, ReactNode } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Headline, Subhead } from '../../atoms'
import { BodyTextSize, HeadlineSize, SubheadSize } from '../../styles'
import { Flex1View } from '../../templates'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

type MembershipItem = {
  label: { text: ReactNode; testID: string }
  description: { text: ReactNode; testID: string }
  icon?: ReactNode
  hide?: boolean
  extraContent?: ReactElement
  itemTestID?: string
}

export interface MembershipSummaryProps {
  title: ReactNode
  memberOfferings: MembershipItem[]
  backgroundColor?: string
}

const MembershipSummaryContainer = styled(Flex1View)<{ theme: ThemeType; backgroundColor?: string }>(
  ({ theme, backgroundColor }) => ({
    flexDirection: 'column',
    width: '100%',
    padding: theme.breakpoints.isMobileSized ? `${theme.spacing['64px']} ${theme.spacing['24px']}` : '124px 0px',
    ...(!theme.breakpoints.isMobileSized && { justifyContent: 'center', alignItems: 'center' }),
    backgroundColor: backgroundColor || theme.colors.backgroundPrimary,
  }),
)

const MembershipItemContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: theme.breakpoints.isMobileSized ? 'row' : 'column',
  alignItems: 'center',
  ...(!theme.breakpoints.isMobileSized && { padding: theme.spacing['24px'], maxWidth: '292px', height: '100%' }),
  ...(theme.breakpoints.isMobileSized && { marginBottom: theme.spacing['16px'] }),
  ...(!theme.breakpoints.isMinWidthMobileXs && { flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }),
}))

const TitleContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['32px'] : theme.spacing['64px'],
  justifyContent: 'center',
}))

const ItemsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: theme.breakpoints.isMobileSized ? 'column' : 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  ...(theme.breakpoints.isMobileSized && { alignItems: 'center' }),
}))

const MembershipItemTextSection = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'column',
  ...((!theme.breakpoints.isMobileSized || !theme.breakpoints.isMinWidthMobileXs) && {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }),
  ...(theme.breakpoints.isMobileSized && { maxWidth: '307px', flexShrink: 1 }),
}))

const IconContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMobileSized && { marginRight: theme.spacing['16px'] }),
  ...(!theme.breakpoints.isMobileSized && { marginBottom: theme.spacing['24px'] }),
}))

const DescriptionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  margin:
    theme.breakpoints.isMobileSized && theme.breakpoints.isMinWidthMobileXs
      ? `${theme.spacing['4px']} 0px`
      : `${theme.spacing['8px']} 0px`,
}))

const ItemTitle = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  ...((!theme.breakpoints.isMobileSized || !theme.breakpoints.isMinWidthMobileXxs) && { textAlign: 'center' }),
}))

const DescriptionText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  ...((!theme.breakpoints.isMobileSized || !theme.breakpoints.isMinWidthMobileXs) && {
    textAlign: 'center',
  }),
}))

export const MembershipSummary: FunctionComponent<MembershipSummaryProps> = ({
  title,
  memberOfferings,
  backgroundColor,
}) => {
  const { breakpoints } = useTheme()
  const { isMobileSized } = breakpoints
  const testID = 'MembershipSummary'

  const generateMemberShipItemComponent = (membershipItem: MembershipItem) => {
    const { icon, label, description, extraContent, itemTestID } = membershipItem
    const { text: descriptionText, testID: descriptionTestID } = description
    const { text: labelText, testID: labelTestID } = label
    return (
      <MembershipItemContainer testID={tID(`${testID}-${itemTestID}`)}>
        <IconContainer>{icon}</IconContainer>
        <MembershipItemTextSection>
          <ItemTitle
            testID={tID(isMobileSized ? `${testID}-${labelTestID}-mobileSized` : `${testID}-${labelTestID}`)}
            size={isMobileSized ? SubheadSize.XSMALL : SubheadSize.MEDIUM}
            text={labelText}
          />
          <DescriptionContainer>
            <DescriptionText
              testID={tID(`${testID}-${descriptionTestID}`)}
              text={descriptionText}
              size={isMobileSized ? BodyTextSize.SMALL : BodyTextSize.DEFAULT}
            />
          </DescriptionContainer>
          {extraContent}
        </MembershipItemTextSection>
      </MembershipItemContainer>
    )
  }
  return (
    <MembershipSummaryContainer testID={tID(testID)} backgroundColor={backgroundColor}>
      <TitleContainer>
        {isMobileSized ? (
          <Subhead size={SubheadSize.LARGE} text={title} textAlign='center' />
        ) : (
          <Headline text={title} size={HeadlineSize.SMALL} />
        )}
      </TitleContainer>
      <ItemsContainer>
        {memberOfferings.map((membershipItem) =>
          membershipItem?.hide ? null : generateMemberShipItemComponent(membershipItem),
        )}
      </ItemsContainer>
    </MembershipSummaryContainer>
  )
}

import React, { FunctionComponent } from 'react'
import { defineMessage, defineMessages, useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { CoverageType, CustomerName, PATHWAYS_TYPE } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Link } from '../../../atoms'
import {
  CareNavigatorCharacterSpotIllustration,
  IndividualTherapySpotIllustrationWhiteBorder,
  LyraMobileAppSpotIllustration,
  SelfCareEssentialsSpotIllustrationWhiteBorder,
} from '../../../atoms/illustrations'
import { useGetIsMobileWebBrowser } from '../../../hooks'
import { AppleAppStoreBadge, GooglePlayStoreBadge, MembershipSummary } from '../../../molecules'
import { BodyTextSize } from '../../../styles'
import { ThemeType, tID } from '../../../utils'

export interface LyraMembershipSummaryProps {
  isMobileAppDisabled?: boolean
  customerDisplayName?: string
  numberOfSessions?: string
  coverageType?: CoverageType
  isEssentialsDisabled?: boolean
  isDependentDisabled?: boolean
  language?: string
  pathwayType?: PATHWAYS_TYPE
  backgroundColor?: string
  onGetAppLinkPress: () => void
}

const AllBadgesContainer = styled.View({
  flexDirection: 'row',
})

const AppleAppStoreContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.spacing['8px'],
}))

enum LyraMemberOfferings {
  CARE_FOR_YOU_DEFAULT = 'CARE_FOR_YOU_DEFAULT',
  CARE_FOR_YOU_AND_YOUR_FAMILY_DEFAULT = 'CARE_FOR_YOU_AND_YOUR_FAMILY_DEFAULT',
  CARE_FOR_YOU_EAP_ONLY = 'CARE_FOR_YOU_EAP_ONLY',
  CARE_FOR_YOU_AND_YOUR_FAMILY_EAP_ONLY = 'CARE_FOR_YOU_AND_YOUR_FAMILY_EAP_ONLY',
  CARE_FOR_YOU_AND_YOUR_FAMILY_EAP_HPI = 'CARE_FOR_YOU_AND_YOUR_FAMILY_EAP_HPI',
  CARE_FOR_YOU_AND_YOUR_FAMILY_HPI_ONLY = 'CARE_FOR_YOU_AND_YOUR_FAMILY_HPI_ONLY',
  CARE_FOR_YOU_AND_YOUR_FAMILY_HPI_ONLY_CHILDREN_DISABLED = 'CARE_FOR_YOU_AND_YOUR_FAMILY_HPI_ONLY_CHILDREN_DISABLED',
  CARE_FOR_YOU_AND_YOUR_FAMILY_STARBUCKS = 'CARE_FOR_YOU_AND_YOUR_FAMILY_STARBUCKS',
  ON_DEMAND_WELLNESS_RESOURCES = 'ON_DEMAND_WELLNESS_RESOURCES',
  ALL_DAY_SUPPORT = 'ALL_DAY_SUPPORT',
  LYRA_HEALTH_MOBILE_APP = 'LYRA_HEALTH_MOBILE_APP',
}

const lyraMemberOfferingsLabels = defineMessages({
  [LyraMemberOfferings.CARE_FOR_YOU_DEFAULT]: {
    defaultMessage: 'Care for you',
    description: 'Label indicating members of Lyra will receive care for themselves.',
  },
  [LyraMemberOfferings.CARE_FOR_YOU_AND_YOUR_FAMILY_DEFAULT]: {
    defaultMessage: 'Care for you and your family',
    description: 'Label indicating members of Lyra will receive care for themselves and their family.',
  },
  [LyraMemberOfferings.ON_DEMAND_WELLNESS_RESOURCES]: {
    defaultMessage: 'On demand wellness resources',
    description: 'Label indicating members of Lyra will receive on demand support.',
  },
  [LyraMemberOfferings.ALL_DAY_SUPPORT]: {
    defaultMessage: '24/7 Care Navigator support',
    description: 'Label indicating members of Lyra will receive all day support from Lyra care navigators.',
  },
  [LyraMemberOfferings.LYRA_HEALTH_MOBILE_APP]: {
    defaultMessage: 'The Lyra Health App',
    description: 'Label indicating members of Lyra will receive access to the Lyra Mobile app',
  },
})

const lyraMemberOfferingsDescriptions = defineMessages({
  [LyraMemberOfferings.CARE_FOR_YOU_DEFAULT]: {
    defaultMessage: 'Get personalized care covered by {customerDisplayName}.',
    description: 'A short description that indicates care for user will be covered by their company.',
  },
  [LyraMemberOfferings.CARE_FOR_YOU_AND_YOUR_FAMILY_DEFAULT]: {
    defaultMessage: 'Get personalized care for you and your loved ones covered by {customerDisplayName}.',
    description: 'A short description that indicates care for user and their family will be covered by their company.',
  },
  [LyraMemberOfferings.CARE_FOR_YOU_EAP_ONLY]: {
    defaultMessage:
      'Get up to <highlight>{numberOfSessions} sessions per year</highlight> covered by {customerDisplayName}.',
    description: 'Description of number of sessions that will be provided to user by their company.',
  },
  [LyraMemberOfferings.CARE_FOR_YOU_AND_YOUR_FAMILY_EAP_ONLY]: {
    defaultMessage:
      'Get up to <highlight>{numberOfSessions} sessions per year</highlight> covered by {customerDisplayName} for each eligible person.',
    description:
      "A description of number of sessions the user's company will provide for the user and each of their family members.",
  },
  [LyraMemberOfferings.CARE_FOR_YOU_AND_YOUR_FAMILY_EAP_HPI]: {
    defaultMessage:
      'Get <highlight>{numberOfSessions} annual sessions</highlight> covered by {customerDisplayName}, and then check with your health plan for additional coverage.',
    description:
      "A description of the number of annual sessions the user's company will provide before their health plan will be needed for additional coverage.",
  },
  [LyraMemberOfferings.CARE_FOR_YOU_AND_YOUR_FAMILY_HPI_ONLY]: {
    defaultMessage: 'Get personalized care through an eligible health plan for you, your partner, or your children.',
    description:
      'A description of how care can be provided through an eligible health plan for the user, their spouse and their children.',
  },
  [LyraMemberOfferings.CARE_FOR_YOU_AND_YOUR_FAMILY_HPI_ONLY_CHILDREN_DISABLED]: {
    defaultMessage: 'Get personalized care through an eligible health plan for you and your loved ones.',
    description:
      'A description of how care can be provided through an eligible health plan for the user and their spouse.',
  },
  [LyraMemberOfferings.CARE_FOR_YOU_AND_YOUR_FAMILY_STARBUCKS]: {
    defaultMessage: 'Get personalized care covered for you and your eligible family members.',
    description: 'A short description that indicates care for user and their family will be covered by their company.',
  },
  [LyraMemberOfferings.ON_DEMAND_WELLNESS_RESOURCES]: {
    defaultMessage: 'Improve your well-being with expert-led meditations, videos, and more.',
    description: 'A description indicating some of the wellness sources offered by Lyra.',
  },
  [LyraMemberOfferings.ALL_DAY_SUPPORT]: {
    defaultMessage: 'Contact us anytime if you have questions about care options or benefits.',
    description: 'A description indicating user can reach out anytime of they have questions.',
  },
  [LyraMemberOfferings.LYRA_HEALTH_MOBILE_APP]: {
    defaultMessage:
      'Access care and explore our wellness resources with the Lyra app.{showAppLink, select, yes { <link>Get the app</link>} other {}}',
    description: 'A description indicating that user can also access all Lyra content from the mobile app.',
  },
})

const LYRA_MEMBERSHIP_SECTION_TITLE = defineMessage({
  defaultMessage: `What you get with Lyra`,
  description: 'Title for the lyra membership offerings section.',
})

export const LyraMembershipSummary: FunctionComponent<LyraMembershipSummaryProps> = ({
  customerDisplayName,
  numberOfSessions,
  coverageType,
  isMobileAppDisabled,
  isEssentialsDisabled,
  isDependentDisabled,
  language,
  pathwayType,
  backgroundColor,
  onGetAppLinkPress,
}) => {
  const { formatMessage } = useIntl()
  const { breakpoints, colors } = useTheme()
  const { isMobileSized } = breakpoints
  const iconSize = isMobileSized ? 64 : 96

  const isMobileWebBrowser = useGetIsMobileWebBrowser()
  const showAppStoreBadges = isMobileWebBrowser && breakpoints.isMobileSized
  const badgeSize = 32
  const isStarbucksCustomer = customerDisplayName?.toLowerCase() === CustomerName.STARBUCKS

  const getMemberCareOfferingDescription = (coverageType?: CoverageType) => {
    let selectedDescription
    switch (coverageType) {
      case CoverageType.EAP_ONLY_DEPENDENT_DISABLED:
        selectedDescription = lyraMemberOfferingsDescriptions.CARE_FOR_YOU_EAP_ONLY
        break
      case CoverageType.EAP_AND_HPI:
        selectedDescription = lyraMemberOfferingsDescriptions.CARE_FOR_YOU_AND_YOUR_FAMILY_EAP_HPI
        break
      case CoverageType.EAP_ONLY:
        selectedDescription = lyraMemberOfferingsDescriptions.CARE_FOR_YOU_AND_YOUR_FAMILY_EAP_ONLY
        break
      case CoverageType.HPI_ONLY:
        selectedDescription = lyraMemberOfferingsDescriptions.CARE_FOR_YOU_AND_YOUR_FAMILY_HPI_ONLY
        break
      case CoverageType.HPI_ONLY_CHILDREN_DISABLED:
        selectedDescription = lyraMemberOfferingsDescriptions.CARE_FOR_YOU_AND_YOUR_FAMILY_HPI_ONLY_CHILDREN_DISABLED
        break
      default:
        selectedDescription = isStarbucksCustomer
          ? lyraMemberOfferingsDescriptions.CARE_FOR_YOU_AND_YOUR_FAMILY_STARBUCKS
          : isDependentDisabled
          ? lyraMemberOfferingsDescriptions.CARE_FOR_YOU_DEFAULT
          : lyraMemberOfferingsDescriptions.CARE_FOR_YOU_AND_YOUR_FAMILY_DEFAULT
        break
    }

    return {
      text: formatMessage(selectedDescription, {
        customerDisplayName,
        numberOfSessions,
        highlight: (chunks: string) => (
          <BodyText
            color={colors.textActive}
            text={chunks}
            size={isMobileSized ? BodyTextSize.SMALL : BodyTextSize.DEFAULT}
          />
        ),
      }),
      testID: coverageType
        ? `careSection-description-${coverageType}`
        : isStarbucksCustomer
        ? 'careSection-description-starbucks'
        : 'careSection-description-default',
    }
  }

  const memberOfferings = [
    {
      label: {
        text: isDependentDisabled
          ? formatMessage(lyraMemberOfferingsLabels.CARE_FOR_YOU_DEFAULT)
          : formatMessage(lyraMemberOfferingsLabels.CARE_FOR_YOU_AND_YOUR_FAMILY_DEFAULT),
        testID: isDependentDisabled ? 'careSection-label-dependentDisabled' : 'careSection-label',
      },
      description: getMemberCareOfferingDescription(coverageType),
      icon: <IndividualTherapySpotIllustrationWhiteBorder size={iconSize} />,
      itemTestID: 'careSection',
    },
    {
      label: {
        text: formatMessage(lyraMemberOfferingsLabels.ON_DEMAND_WELLNESS_RESOURCES),
        testID: 'essentialsSection-label',
      },
      description: {
        text: formatMessage(lyraMemberOfferingsDescriptions.ON_DEMAND_WELLNESS_RESOURCES),
        testID: 'essentialsSection-description',
      },
      icon: <SelfCareEssentialsSpotIllustrationWhiteBorder size={iconSize} />,
      hide: isEssentialsDisabled,
      itemTestID: 'essentialsSection',
    },
    {
      label: { text: formatMessage(lyraMemberOfferingsLabels.ALL_DAY_SUPPORT), testID: 'careNavigatorSection-label' },
      description: {
        text: formatMessage(lyraMemberOfferingsDescriptions.ALL_DAY_SUPPORT),
        testID: 'careNavigatorSection-description',
      },
      icon: <CareNavigatorCharacterSpotIllustration size={iconSize} />,
      itemTestID: 'careNavigatorSection',
    },
    {
      label: {
        text: formatMessage(lyraMemberOfferingsLabels.LYRA_HEALTH_MOBILE_APP),
        testID: 'mobileAppSection-label',
      },
      description: {
        text: formatMessage(lyraMemberOfferingsDescriptions.LYRA_HEALTH_MOBILE_APP, {
          showAppLink: !showAppStoreBadges && pathwayType !== PATHWAYS_TYPE.COMING_SOON ? 'yes' : 'no',
          link: (text: string) => (
            <Link
              testID={tID(`MembershipSummary-mobileAppSection-getAppLink`)}
              text={text}
              size={isMobileSized ? BodyTextSize.SMALL : BodyTextSize.DEFAULT}
              onPress={onGetAppLinkPress}
            />
          ),
        }),
        testID: 'mobileAppSection-description',
      },
      icon: <LyraMobileAppSpotIllustration size={iconSize} />,
      hide: isMobileAppDisabled,
      // we only show app store badges on mobile devices (this includes mobile web)
      extraContent:
        showAppStoreBadges && pathwayType !== PATHWAYS_TYPE.COMING_SOON ? (
          <AllBadgesContainer testID={tID('AppStoreBadges')}>
            <AppleAppStoreContainer>
              <AppleAppStoreBadge height={badgeSize} locale={language} />
            </AppleAppStoreContainer>
            <GooglePlayStoreBadge height={badgeSize} locale={language} />
          </AllBadgesContainer>
        ) : undefined,
      itemTestID: 'mobileAppSection',
    },
  ]
  return (
    <MembershipSummary
      title={formatMessage(LYRA_MEMBERSHIP_SECTION_TITLE)}
      memberOfferings={memberOfferings}
      backgroundColor={backgroundColor}
    />
  )
}

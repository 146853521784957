import { IntlShape } from 'react-intl'

import {
  Attestations,
  extendMetadataAttestations,
  FormMetadata,
  getLegalNoticeLink,
  thisFieldIsRequiredErrorMessage,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyTextSize } from '../../styles'

export enum LegalAgreementsField {
  CONSENT_TO_TERMS_OF_USE = 'hasConsentedToTermsOfUse',
  CONSENT_TO_TERMS_OF_USE_AND_HIPAA = 'hasConsentedToTermsOfUseAndHIPAA',
}

const DIVIDER = 'divider'

export type LegalAgreementsMetadataProps = {
  intl: IntlShape
  isInternationalRegistrationExperience: boolean
  activeLanguage: string
  attestationContents?: Attestations | null
  hideDivider?: boolean
  textColor?: string
}

export const generateLegalAgreementsMetadata = ({
  intl,
  isInternationalRegistrationExperience = false,
  activeLanguage,
  attestationContents = null,
  hideDivider,
  textColor,
}: LegalAgreementsMetadataProps): FormMetadata => {
  const metadata: FormMetadata = {
    schema: {
      type: 'object',
      properties: {
        [DIVIDER]: {
          name: DIVIDER,
          type: 'string',
          show: !hideDivider,
        },
        [LegalAgreementsField.CONSENT_TO_TERMS_OF_USE]: {
          name: LegalAgreementsField.CONSENT_TO_TERMS_OF_USE,
          title: intl.formatMessage(
            {
              defaultMessage:
                'I agree to Lyra’s <termsOfUseLink>Terms of Use</termsOfUseLink> and <privacyPolicyLink>Privacy Policy</privacyPolicyLink>.',
              description: 'Message to acquire content from user',
            },
            {
              termsOfUseLink: (chunks) => `[${chunks}](${getLegalNoticeLink(activeLanguage, 'TERMS_OF_USE')})`,
              privacyPolicyLink: (chunks) => `[${chunks}](${getLegalNoticeLink(activeLanguage, 'PRIVACY_POLICY')})`,
            },
          ),
          type: 'boolean',
          widgetProps: {
            labelSize: BodyTextSize.SMALL,
            titleColor: textColor,
          },
          validation: {
            isBooleanTrue: true,
          },
          show: isInternationalRegistrationExperience,
        },
        [LegalAgreementsField.CONSENT_TO_TERMS_OF_USE_AND_HIPAA]: {
          name: LegalAgreementsField.CONSENT_TO_TERMS_OF_USE_AND_HIPAA,
          title: intl.formatMessage(
            {
              defaultMessage:
                'I agree to Lyra’s <termsOfUseLink>Terms of Use</termsOfUseLink>, <privacyPolicyLink>Privacy Policy</privacyPolicyLink>, <hipaaLink>HIPAA Notice</hipaaLink>, and <emailConsentLink>Consent to Use Personal Email</emailConsentLink>.',
              description: 'Message to acquire content from user',
            },
            {
              termsOfUseLink: (chunks) => `[${chunks}](${getLegalNoticeLink(activeLanguage, 'TERMS_OF_USE')})`,
              hipaaLink: (chunks) => `[${chunks}](${getLegalNoticeLink(activeLanguage, 'HIPAA_NOTICE')})`,
              emailConsentLink: (chunks) => `[${chunks}](${getLegalNoticeLink(activeLanguage, 'EMAIL_CONSENT')})`,
              privacyPolicyLink: (chunks) => `[${chunks}](${getLegalNoticeLink(activeLanguage, 'PRIVACY_POLICY')})`,
            },
          ),
          type: 'boolean',
          widgetProps: {
            labelSize: BodyTextSize.SMALL,
            titleColor: textColor,
          },
          validation: {
            isBooleanTrue: true,
          },
          show: !isInternationalRegistrationExperience,
        },
      },
    },
    uiSchema: {
      'ui:order': [
        DIVIDER,
        LegalAgreementsField.CONSENT_TO_TERMS_OF_USE,
        LegalAgreementsField.CONSENT_TO_TERMS_OF_USE_AND_HIPAA,
      ],
      [DIVIDER]: { 'ui:field': 'Divider' },
      [LegalAgreementsField.CONSENT_TO_TERMS_OF_USE]: {
        'ui:widget': 'checkbox',
        'ui:options': {
          baseInputStyle: {
            marginBottom: 8,
          },
        },
      },
      [LegalAgreementsField.CONSENT_TO_TERMS_OF_USE_AND_HIPAA]: {
        'ui:widget': 'checkbox',
        'ui:options': {
          baseInputStyle: {
            marginBottom: 8,
          },
        },
      },
    },
  }

  return attestationContents
    ? extendMetadataAttestations({
        attestationContents,
        errorMessage: thisFieldIsRequiredErrorMessage,
        metadata,
        intl,
        checkboxProps: {
          labelSize: BodyTextSize.SMALL,
          titleColor: textColor,
          // We are adding the margin bottom to account for endlines and the margin we should have after one
          markdownStyle: {
            p2: { marginBottom: 8 },
          },
        },
      })
    : metadata
}

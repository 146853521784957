import React from 'react'
import { defineMessage } from 'react-intl'
import { Linking } from 'react-native'

import { GetCustomerCopyFunc, WORKLIFE_SERVICE_IDS } from '@lyrahealth-inc/shared-app-logic'

import ConciergeIllustration from '../assets/worklife/illustration-concierge.png'
import DependentsIllustration from '../assets/worklife/illustration-dependents.png'
import { BodyText, Link } from '../atoms'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'
import { DEFAULT_WORKLIFE_SERVICES } from '../constants'

const benefitsPortal = ({ url = 'http://fmrbenefits.com/Home/health', linkText = 'Fidelity medical plan' } = {}) => (
  <Link
    text={linkText}
    onPress={() => {
      Linking.openURL(url)
    }}
  />
)

const excludedServices =
  'psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication, autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care'

const servicesNotCovered = (
  <>
    <BodyText
      text={`Services that are not covered include: ${excludedServices}. Please check with your medical plan or `}
    />
    {benefitsPortal({ url: 'http://fmrbenefits.com/', linkText: 'FMRbenefits' })}
    <BodyText text=' for coverage of these excluded services.' />
  </>
)

export const fidelity: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return {
    modalityQuestionnaireText: {
      modalityLabelAddendums: {
        video: defineMessage({
          defaultMessage:
            'RECOMMENDED: To support “social distancing”, we strongly encourage you to use video at this time. Research demonstrates that evidence-based therapies delivered by video are as effective as in-person treatment.',
          description:
            'Shown when client indicates their preference to meet by video or in person, when searching for a provider',
        }),
      },
    },
    displaySessionLimit: false,
    legalNotice: <CoverageNotice sessionLimit={numberOfSessions} />,
    servicesNotCovered,
    workLifeServices: [
      DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT,
      {
        identifiers: {
          id: 'concierge',
          dataTestId: 'workLife-selectConciergeServices',
        },
        title: 'Concierge Services with Circles',
        img: ConciergeIllustration,
        bullets: [
          'Event support: ideas, tickets, reservations',
          'Product research and gift suggestions',
          'Personal travel arrangements',
          'Home help: contractors, landscapers, etc.',
          <>
            <Link
              text='Learn more'
              onPress={() => {
                Linking.openURL('https://fmrbenefits.com/WorkandLife/Home-and-Travel/Concierge-Services')
              }}
            />
            <BodyText text=' ways Fidelity Concierge helps' />
          </>,
        ],
        CTA: 'Access a Fidelity concierge',
        PATH: 'https://community.circles.com/fidelity',
      },
      {
        identifiers: WORKLIFE_SERVICE_IDS.DEPENDENT_CARE,
        title: 'Care Coordination with Wellthy',
        img: DependentsIllustration,
        bullets: [
          'Care management for you and your loved ones',
          'Help managing complex and ongoing needs',
          'Support selecting providers and in-home care',
          <>
            <Link
              text='Learn more'
              onPress={() => {
                Linking.openURL('https://fmrbenefits.com/WorkandLife/Family/Raise-Caregiver')
              }}
            />
            <BodyText text=' about care coordination' />
          </>,
        ],
        CTA: 'Connect to a care professional',
        PATH: 'https://wellthy.com/fidelity/',
      },
    ],
  }
}

import { formatISO } from 'date-fns'

import {
  PROVIDER_RESULTS_SURVEY_DATE_LAST_SEEN_KEY,
  SHOW_PROVIDER_RESULTS_SURVEY_KEY,
} from '@lyrahealth-inc/shared-app-logic'

export const getShowProviderResultsSurveyKey = () => {
  return localStorage.getItem(SHOW_PROVIDER_RESULTS_SURVEY_KEY) === 'true'
}

export const updateStoredProviderResultsSurveyValues = () => {
  if (getShowProviderResultsSurveyKey()) {
    localStorage.setItem(PROVIDER_RESULTS_SURVEY_DATE_LAST_SEEN_KEY, formatISO(new Date()))
  }
  localStorage.removeItem(SHOW_PROVIDER_RESULTS_SURVEY_KEY)
}

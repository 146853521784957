import React from 'react'
import {
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { Location } from 'history'

export type WithRouterProps = {
  router: {
    location: Location
    navigate: NavigateFunction
    outletContext: Dict
    params: Params
    searchParams: URLSearchParams
    setSearchParams: () => void
  }
}

/**
 * This component is purely a wrapper so some React Router 6 hooks are made availble to
 * legacy class-based components
 * See https://reactrouter.com/en/main/start/faq#what-happened-to-withrouter-i-need-it
 */
function withRouter<P extends {}>(
  Component: React.ComponentType<P>,
): (props: Omit<P, keyof WithRouterProps>) => JSX.Element {
  function ComponentWithRouterProp(props: P) {
    const location = useLocation()
    const navigate = useNavigate()
    const outletContext = useOutletContext()
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()

    return (
      <Component {...props} router={{ location, navigate, outletContext, params, searchParams, setSearchParams }} />
    )
  }

  // @ts-expect-error TS(2322): Type '(props: P) => Element' is not assignable to ... Remove this comment to see the full error message
  return ComponentWithRouterProp
}

export default withRouter

import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const PreferenceMatchIcon: FunctionComponent<PreferenceMatchIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <G clipPath='url(#clip0_285_35695)'>
        <Path
          d='M22.06 9.55c0-4.17-3.39-7.55-7.55-7.55-3.38 0-6.25 2.23-7.21 5.3-3.07.96-5.3 3.83-5.3 7.2 0 4.17 3.39 7.55 7.55 7.55 3.38 0 6.25-2.23 7.21-5.3 3.07-.96 5.3-3.83 5.3-7.21v.01zM9.55 20.56c-3.34 0-6.05-2.72-6.05-6.05 0-2.42 1.42-4.5 3.47-5.47-.01.17-.03.34-.03.52 0 4.17 3.39 7.55 7.56 7.55.18 0 .35-.01.52-.03-.97 2.05-3.05 3.48-5.47 3.48zm7.53-5.53c.01-.17.03-.34.03-.52 0-4.17-3.39-7.55-7.55-7.55-.18 0-.35.01-.52.03.97-2.05 3.06-3.48 5.47-3.48 3.34 0 6.05 2.72 6.05 6.05 0 2.42-1.42 4.5-3.48 5.47z'
          fill={`${fillColor || colors.iconInactive}`}
        />
      </G>
      <Defs>
        <ClipPath id='clip0_285_35695'>
          <Path fill={colors.iconInverse} transform='translate(2 2)' d='M0 0H20.06V20.06H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export type PreferenceMatchIconProps = {
  size?: string | number
  fillColor?: string
}

import React from 'react'
import { IntlConfig } from 'react-intl'

export type Resolved = {
  preferredLanguages: readonly string[]
  resolvedToDefault: boolean
}
export type LyraIntlContextType = {
  setLanguage: (language: string) => void
  availableLanguages: readonly string[]
  resolved?: Resolved
  activeLanguage: string
  intlConfig: IntlConfig
  disabledTranslationsIntlConfig: IntlConfig
}

export const LyraIntlContext = React.createContext<LyraIntlContextType | null>(null)

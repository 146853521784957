import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import {
  BodyText,
  PrimaryButton,
  RestingCatIllustration,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
} from '@lyrahealth-inc/ui-core-crossplatform'

export interface WellnessCheckInResultsEmptyStateProps {
  onEmptyStatePress: () => void
}

const EmptyStateContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  margin: theme.breakpoints.isMobileSized
    ? `146px ${theme.spacing['16px']} auto ${theme.spacing['16px']}`
    : `138px auto auto auto`,
  maxWidth: '624px',
}))

const TitleContainer = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

const DescriptionContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['40px'],
}))

const StartAgainButton = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  alignSelf: 'center',
  width: theme.breakpoints.isMobileSized ? '100%' : 'auto',
}))

const ImageContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
  alignSelf: 'center',
}))

export const WellnessCheckInResultsEmptyState: FunctionComponent<WellnessCheckInResultsEmptyStateProps> = ({
  onEmptyStatePress,
}) => {
  const { breakpoints } = useTheme()

  return (
    <EmptyStateContainer testID={tID(`WellnessCheckInResultsEmptyState`)}>
      <ImageContainer>
        <RestingCatIllustration width={240} height={196} />
      </ImageContainer>
      <TitleContainer
        text={
          <FormattedMessage
            defaultMessage='We need a bit more info to share your results'
            description='Wellness check in results title empty state'
          />
        }
        textAlign='center'
        size={SubheadSize.MEDIUM}
      />
      <DescriptionContainer
        text={
          <FormattedMessage
            defaultMessage='Looks like you didn’t answer enough questions for full results. Try retaking the check-in, and be sure to answer as many questions as you can.'
            description='Wellness check in results description empty state'
          />
        }
        textAlign='center'
      />
      <StartAgainButton
        onPress={onEmptyStatePress}
        text={
          <FormattedMessage
            defaultMessage='Start again'
            description='Wellness check in results start again empty state'
          />
        }
        testID={tID('WellnessCheckInResultsEmptyStateStartAgainButton')}
        fullWidth={breakpoints.isMobileSized}
      />
    </EmptyStateContainer>
  )
}

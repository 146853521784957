import { useEffect, useState } from 'react'

import { ESSENTIALS_COURSE, ESSENTIALS_GET_COURSES_QUERY, EssentialsCourseRaw } from '@lyrahealth-inc/shared-app-logic'

import { runEssentialsQuery } from '../wellnessToolkitUtil'

export const useGetEssentialsCourse = (
  essentialsCmsUrl: string,
  userDisplayLanguage: string,
  essentialsCourse?: ESSENTIALS_COURSE,
  logError?: (error: Error) => void,
) => {
  const [course, setCourse] = useState<EssentialsCourseRaw | null>()

  const locale = userDisplayLanguage ?? 'en-US'

  useEffect(() => {
    if (!(essentialsCourse && essentialsCmsUrl && locale)) {
      return
    }

    const getCourse = async () => {
      const result = await runEssentialsQuery(
        locale,
        essentialsCourse,
        ESSENTIALS_GET_COURSES_QUERY,
        essentialsCmsUrl,
        logError,
      )
      if (result && result.length) {
        const courseData = result[0]
        setCourse(courseData)
      }
    }
    getCourse()
  }, [essentialsCmsUrl, essentialsCourse, locale, logError])

  return course
}

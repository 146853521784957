import { useCallback } from 'react'

import { isNil } from 'lodash-es'

import {
  CustomerTreatmentOption,
  CustomerTreatmentsOptionLocation,
  TREATMENT_OPTIONS_TYPE,
} from '@lyrahealth-inc/shared-app-logic'

function shouldIncludeTreatmentOption(searcherAge: number, treatmentOption: CustomerTreatmentOption) {
  const { minAge = 0 } = treatmentOption.config

  const meetsAgeRequirement = searcherAge >= minAge
  if (meetsAgeRequirement) {
    return true
  }
  return false
}

/** Takes in a list of treatment options and adds in the customer specific options based on `customerCopy` */
export const useAddCustomerTreatmentOptions = () => {
  return useCallback(
    (
      searcherAge: number,
      location: CustomerTreatmentsOptionLocation,
      treatmentOptions: TREATMENT_OPTIONS_TYPE[],
      configForSpecificTreatmentOption: { [key in TREATMENT_OPTIONS_TYPE]?: any },
      customerCopy?: { [key: string]: any },
    ) => {
      const customerTreatmentOptions: CustomerTreatmentOption[] = customerCopy?.treatmentOptions || []
      customerTreatmentOptions.forEach((item: CustomerTreatmentOption) => {
        const insertionIndex = item.optionRanking[location]
        if (shouldIncludeTreatmentOption(searcherAge, item) && !isNil(insertionIndex)) {
          treatmentOptions.splice(insertionIndex, 0, item.option)
          configForSpecificTreatmentOption[item.option] = item.config
        }
      })
      return { treatmentOptions: treatmentOptions, configForSpecificTreatmentOption: configForSpecificTreatmentOption }
    },
    [],
  )
}

import { get } from 'lodash-es'

import { Address } from '../../models'

export const formatAddressForSubmission = (address: Address) => {
  return [
    (address.street1 || address.address1) ?? '',
    address.street2 ? address.street2 : '',
    address.city ?? '',
    address.state ?? '',
    get(address, 'zipcode.zip', ''),
  ]
    .filter((address) => address !== '')
    .join(', ')
}

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useTheme } from 'styled-components/native'

import { MicrositeConfigSectionNames, PATHWAYS_TYPE } from '@lyrahealth-inc/shared-app-logic'
import { ThemeType } from '@lyrahealth-inc/ui-core-crossplatform'

import { getEssentialsDisabled, getLwMobileEnabled, getMicrositeConfig } from '../../data/customer/customerSelectors'

export const useUpdatedMicrositeConfig = (pathwaysType: PATHWAYS_TYPE) => {
  const micrositeConfig = useSelector(getMicrositeConfig)
  const essentialsDisabled = useSelector(getEssentialsDisabled)
  const isMobileAppDisabled = !useSelector(getLwMobileEnabled)
  const {
    breakpoints: { isMobileSized },
  } = useTheme() as ThemeType

  return useMemo(
    () => ({
      ...micrositeConfig,
      [MicrositeConfigSectionNames.HERO]: {
        pathwaysType,
      },
      [MicrositeConfigSectionNames.MOBILE_APP_PROMO_BANNER]: {
        hideSection:
          pathwaysType === PATHWAYS_TYPE.COMING_SOON ||
          isMobileSized ||
          isMobileAppDisabled ||
          micrositeConfig.mobileAppPromoBanner.hideSection,
      },
      [MicrositeConfigSectionNames.OFFERINGS_CAROUSEL]: {
        ...micrositeConfig.offeringsCarousel,
        hideWellnessResources: essentialsDisabled || micrositeConfig.offeringsCarousel.hideWellnessResources,
      },
      [MicrositeConfigSectionNames.MOBILE_APP_PROMO]: {
        pathwaysType,
        hideSection: isMobileAppDisabled || micrositeConfig.mobileAppPromo.hideSection,
      },
      [MicrositeConfigSectionNames.SELF_CARE]: {
        hideSection: essentialsDisabled || micrositeConfig.selfCare.hideSection,
      },
      [MicrositeConfigSectionNames.TESTIMONIALS]: {
        hideSection: micrositeConfig.testimonials.hideSection,
      },
      [MicrositeConfigSectionNames.LYRA_MEMBERSHIP]: {
        hideSection: (isMobileAppDisabled && essentialsDisabled) || micrositeConfig.lyraMembership.hideSection,
        hideNumberOfSessions: micrositeConfig.lyraMembership.hideNumberOfSessions,
      },
      [MicrositeConfigSectionNames.CARE_NAVIGATOR]: {
        hideSection: micrositeConfig.careNavigator.hideSection,
      },
      [MicrositeConfigSectionNames.FAQ]: {
        hideSection: micrositeConfig.faq.hideSection,
      },
    }),
    [essentialsDisabled, isMobileAppDisabled, micrositeConfig, pathwaysType, isMobileSized],
  )
}

import { useMemo } from 'react'

import { HealthPlansList } from '../../../models'

interface GetUserHealthPlanFromCustomerHealthPlanList {
  isHealthPlanProvided?: boolean
  healthPlansList?: HealthPlansList[]
  nonIntegratedHealthPlansList?: HealthPlansList[]
  healthPlanName?: string | null
}

export const useUserHealthPlanFromCustomerHealthPlanList = ({
  isHealthPlanProvided,
  healthPlanName,
  healthPlansList = [],
  nonIntegratedHealthPlansList = [],
}: GetUserHealthPlanFromCustomerHealthPlanList) => {
  return useMemo(() => {
    const allHealthPlans = [...healthPlansList, ...nonIntegratedHealthPlansList]
    if (isHealthPlanProvided && allHealthPlans.length > 0) {
      const selectedPlan = allHealthPlans.find(
        (healthPlan: HealthPlansList) => healthPlan.eligibility_name === healthPlanName,
      )
      return selectedPlan?.name
    }
    return
  }, [healthPlansList, nonIntegratedHealthPlansList, isHealthPlanProvided, healthPlanName])
}

import { useMemo } from 'react'

import { Appointment, Provider } from '../../../models'
import { getProviderSessionCounts } from '../utils'

/**
 * Gets the closest upcoming appointment with a bc provider
 */
export const useGetUpcomingProviderAppts = ({
  providers,
}: UseGetUpcomingProviderApptsParams): UpcomingProviderAppointments => {
  const getUpcomingProviderAppts = (providers: Provider[]) =>
    providers?.reduce((upcomingProviderAppts, provider) => {
      // finds the appointment sessionNumber that matches the current episode session count to find the upcoming appointment
      const currentAppointment = provider.appointments.find(
        (appointment) =>
          appointment.sessionNumber ===
          getProviderSessionCounts(appointment.lyraProviderId, appointment.episodeId, providers),
      )
      if (currentAppointment && ['newAppt', 'rescheduled'].includes(currentAppointment.appointmentStatus)) {
        upcomingProviderAppts[provider.provider.lyra_id] = currentAppointment
      }
      return upcomingProviderAppts
    }, {})

  return useMemo(() => (providers?.length ? getUpcomingProviderAppts(providers) : {}), [providers])
}

type UseGetUpcomingProviderApptsParams = {
  providers: Provider[]
}

type UpcomingProviderAppointments = { [providerId: string]: Appointment }

import React from 'react'

import { DirectDedicatedCareNavigatorInfoValues, NavBarProps } from '@lyrahealth-inc/shared-app-logic'
import { colors, NavBar } from '@lyrahealth-inc/ui-core-crossplatform'

import ContactCareNavigatorModal, { ContactCareNavigatorModalProps } from './ContactCareNavigatorModal'

type NavBarHeaderProps = Omit<ContactCareNavigatorModalProps, 'onExit'> &
  NavBarProps & {
    onExitContactCareNavigatorModal: () => void
    userDedicatedCareNavigatorInfo: DirectDedicatedCareNavigatorInfoValues
  }

/**
 * New L&F header using NavBar x-platform component
 */
const NavBarHeader: React.FC<NavBarHeaderProps> = ({
  isInternational,
  isModalOpen,
  customerPhoneNumber,
  isMobile,
  isLyrian,
  showLiveChat,
  startLiveChat,
  isTransparent,
  showScheduleCall,
  setIsModalOpen,
  onPressEmail,
  onPressFAQ,
  onPressPhoneCall,
  onPressScheduleCall,
  onPressLiveChat,
  onExit,
  onBack,
  onCareNavigatorHeaderEntryPointButtonPress,
  onExitContactCareNavigatorModal,
  backgroundColor = colors.ui_oatmeal1,
  borderBottomColor,
  shouldShowCareNavigatorHeaderEntrypoint = true,
  title,
  showBackButton,
  userDedicatedCareNavigatorInfo,
  style,
}) => {
  return (
    <NavBar
      backgroundColor={backgroundColor}
      borderBottomColor={borderBottomColor}
      onExit={onExit}
      onBack={onBack}
      title={title}
      shouldShowCareNavigatorHeaderEntrypoint={shouldShowCareNavigatorHeaderEntrypoint}
      isInternational={isInternational}
      isTransparent={isTransparent}
      onCareNavigatorHeaderEntryPointButtonPress={onCareNavigatorHeaderEntryPointButtonPress}
      careNavigatorHeaderEntryPointModal={
        <ContactCareNavigatorModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          customerPhoneNumber={customerPhoneNumber}
          isInternational={isInternational}
          showLiveChat={showLiveChat}
          startLiveChat={startLiveChat}
          isMobile={isMobile}
          isLyrian={isLyrian}
          onPressEmail={onPressEmail}
          onPressFAQ={onPressFAQ}
          onPressPhoneCall={onPressPhoneCall}
          onPressScheduleCall={onPressScheduleCall}
          onPressLiveChat={onPressLiveChat}
          onExit={onExitContactCareNavigatorModal}
          showScheduleCall={showScheduleCall}
          careNavigatorHeadshot={userDedicatedCareNavigatorInfo?.headShotURL}
          careNavigatorLicense={userDedicatedCareNavigatorInfo?.clinicalLicenses}
          careNavigatorName={userDedicatedCareNavigatorInfo?.firstName}
        />
      }
      showBackButton={showBackButton}
      style={style}
    />
  )
}

export default NavBarHeader

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useMediaQuerySize } from '@lyrahealth-inc/ui-core-crossplatform'

import useProviderProfilePage from './useProviderProfilePage'
import useUpdatedSchedulingPage from './useUpdatedSchedulingPage'
import { getIsCustomerCareTeam } from '../../data/customer/customerSelectors'

export const useShouldHideFloatingFooter = () => {
  const { pathname } = useLocation()
  const { isMobileSized } = useMediaQuerySize()
  const isSchedulingPage = useUpdatedSchedulingPage(pathname)
  const isProviderProfilePage = useProviderProfilePage(pathname)
  const isCareTeamPage = useSelector(getIsCustomerCareTeam)

  return useMemo(() => {
    const pathsToHideFooter = ['modality', 'satisfaction', 'unsubscribe']
    return (
      (isMobileSized && isSchedulingPage) ||
      pathsToHideFooter.some((path) => pathname.includes(path)) ||
      isCareTeamPage ||
      isProviderProfilePage
    )
  }, [isMobileSized, isSchedulingPage, isProviderProfilePage, isCareTeamPage, pathname])
}

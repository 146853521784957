import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { NotificationChannel, NotificationMethod } from '../../../models'
import { NotificationChannelStrings } from '../messages'

interface GetNotificationChannelContent {
  showAllNotificationMethods: boolean
}

export function useNotificationContentText({ showAllNotificationMethods }: GetNotificationChannelContent) {
  const { formatMessage } = useIntl()

  return useMemo(() => {
    const notificationContent: NotificationChannelContent[] = [
      {
        name: NotificationChannel.PROMOTIONS,
        title: formatMessage(NotificationChannelStrings.promotions),
        description: formatMessage(NotificationChannelStrings.promotionsDescription),
        disabledMethods: [NotificationMethod.PUSH, NotificationMethod.TEXT],
      },
    ]
    if (showAllNotificationMethods) {
      notificationContent.unshift(
        {
          name: NotificationChannel.ASSIGNMENTS,
          title: formatMessage(NotificationChannelStrings.activities),
          description: formatMessage(NotificationChannelStrings.activitiesDescription),
        },
        {
          name: NotificationChannel.APPOINTMENTS,
          title: formatMessage(NotificationChannelStrings.sessions),
          description: formatMessage(NotificationChannelStrings.sessionsDescription),
        },
        {
          name: NotificationChannel.MESSAGES,
          title: formatMessage(NotificationChannelStrings.messages),
          description: formatMessage(NotificationChannelStrings.messagesDescription),
        },
      )
    }
    return notificationContent
  }, [formatMessage, showAllNotificationMethods])
}

export type NotificationChannelContent = {
  name: NotificationChannel
  title: React.ReactNode
  description: React.ReactNode
  disabledMethods?: NotificationMethod[]
}

// @intl project:microsite

import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import classnames from 'classnames'

import { BaseModal, DefaultButton, Paragraph } from '@lyrahealth-inc/ui-core'

import styles from './legalNotice.module.scss'

type CoverageTermsContentProps = {
  legalLanguage: React.ReactNode
}

export const CoverageTermsContent = ({ legalLanguage }: CoverageTermsContentProps) => {
  const [isCoverageModalOpen, setCoverageModalVisibility] = useState(false)
  const intl = useIntl()

  const modalContent = (
    <>
      <Paragraph>{legalLanguage}</Paragraph>
      <DefaultButton
        data-test-id='Coverage-Modal-Close-Btn'
        onClick={() => setCoverageModalVisibility(false)}
        customClass={classnames(styles['coverage-close-btn'])}
      >
        <FormattedMessage
          defaultMessage='Close'
          description='Button to close a modal dialog displaying legal language about service coverage'
        />
      </DefaultButton>
    </>
  )
  return (
    <>
      <BaseModal
        data-test-id='Legal-Coverage-Modal'
        title={intl.formatMessage({
          defaultMessage: 'Coverage',
          description: 'Title text for a modal dialog displaying legal language about service coverage',
        })}
        isOpen={isCoverageModalOpen}
        body={modalContent}
        closeModal={() => setCoverageModalVisibility(false)}
        size={'medium'}
        showCloseButton
      />
      <DefaultButton
        unstyled
        data-test-id='Coverage-Link-Btn'
        onClick={() => setCoverageModalVisibility(true)}
        customClass={classnames(styles['coverage-modal-link'])}
      >
        <FormattedMessage
          defaultMessage='Coverage'
          description='Link to legal language about service coverage. Displayed on the footer of all client pages next to other legal links.'
        />
      </DefaultButton>
    </>
  )
}

import { useCallback } from 'react'

import { PostLatestProviderResults } from '../../../models'
import { ProgramType } from '../../common/constants/customerProgram'
import { TREATMENT_OPTIONS_TYPE } from '../../common/constants/treatmentOptions'
import { convertTriageTreatmentOptionToProgram } from '../utils'

export interface useLatestMatchesSearchArgs {
  searchId?: string
  postLatestSearchProviderResults: (arg: PostLatestProviderResults) => Promise<any>
  latestUserProviderTreatmentType?: string
  timeZone: string
}

export const useLatestMatchesSearchProviders = ({
  searchId,
  postLatestSearchProviderResults,
  latestUserProviderTreatmentType,
  timeZone,
}: useLatestMatchesSearchArgs) => {
  return useCallback(() => {
    if (searchId) {
      const getLatestProviderResults = async (searchId: string) => {
        await postLatestSearchProviderResults({
          searchId: searchId,
          timeZone: timeZone ?? 'America/Los_Angeles',
          blendedCareProgramId: convertTriageTreatmentOptionToProgram({
            treatmentOptionName: (latestUserProviderTreatmentType as TREATMENT_OPTIONS_TYPE) ?? null,
          })?.blendedCareProgramId as unknown as ProgramType,
        })
      }
      getLatestProviderResults(searchId)
    }
  }, [timeZone, searchId, latestUserProviderTreatmentType, postLatestSearchProviderResults])
}

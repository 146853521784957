import React, { FunctionComponent } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

type ConversationIconProps = {
  size?: number
  fillColor?: string
}

export const ConversationIcon: FunctionComponent<ConversationIconProps & SvgProps> = ({ size, fillColor, ...rest }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} fill='none' {...rest}>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 6.838h4c1.65 0 3 1.35 3 3v6c0 1.42-.99 2.61-2.31 2.92 0 .45.08 1.17.48 2 .12.23.1.51-.03.73-.14.22-.38.35-.64.35-2.59 0-4.12-1.75-4.74-3H12c-1.65 0-3-1.35-3-3v-2H7.24c-.62 1.25-2.15 3-4.74 3-.26 0-.5-.13-.64-.35a.758.758 0 0 1-.03-.73c.4-.82.48-1.54.48-2A3.003 3.003 0 0 1 0 10.838v-7c0-1.65 1.35-3 3-3h11c1.65 0 3 1.35 3 3v3Zm-10.25 5.5c-.32 0-.6.2-.71.5-.03.09-.65 1.79-2.41 2.34.312-1.246.12-2.16.101-2.254l-.001-.006a.749.749 0 0 0-.73-.58c-.83 0-1.5-.67-1.5-1.5v-7c0-.83.67-1.5 1.5-1.5h11c.83 0 1.5.67 1.5 1.5v7c0 .83-.67 1.5-1.5 1.5H6.75ZM13 5.588c0 .41-.34.75-.75.75h-7.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h7.5c.41 0 .75.34.75.75Zm0 3.5c0 .41-.34.75-.75.75h-7.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h7.5c.41 0 .75.34.75.75Zm8 8.25c.83 0 1.5-.67 1.5-1.5v-6c0-.83-.67-1.5-1.5-1.5h-4v2.5c0 1.65-1.35 3-3 3h-3.5v2c0 .83.67 1.5 1.5 1.5h5.25c.32 0 .61.2.71.51l.001.003c.04.108.665 1.782 2.409 2.327-.312-1.246-.12-2.16-.101-2.254l.001-.006c.08-.34.38-.58.73-.58Z'
        fill={fillColor || colors.iconInactive}
      />
    </Svg>
  )
}

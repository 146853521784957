import React, { FunctionComponent, useMemo } from 'react'
import { View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { ICON_CIRCLE_MOBILE_SIZE, ICON_CIRCLE_WEB_SIZE, MAX_NUMBER_CIRCLE } from '@lyrahealth-inc/shared-app-logic'
import { LockIcon, ResultsScoreIcon, ThemeType, tID } from '@lyrahealth-inc/ui-core-crossplatform'

const CardScoreContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
})

const HiddenTitleContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  margin: `0 ${theme.spacing['8px']} 0 ${theme.spacing['8px']}`,
  height: theme.spacing['8px'],
  minWidth: theme.spacing['64px'],
  backgroundColor: theme.colors.backgroundInactive,
}))

const IconContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  marginLeft: theme.spacing['4px'],
}))

export const WellnessCheckInResultsDomainCardScorePreview: FunctionComponent = () => {
  const { breakpoints, colors } = useTheme()
  const size = breakpoints.isMobileSized ? ICON_CIRCLE_MOBILE_SIZE : ICON_CIRCLE_WEB_SIZE

  const getResultsIcons = useMemo(() => {
    return Array.from({ length: MAX_NUMBER_CIRCLE }).map((_, index) => {
      return (
        <IconContainer key={index}>
          <ResultsScoreIcon width={size} height={size} />
        </IconContainer>
      )
    })
  }, [size])

  return (
    <CardScoreContainer testID={tID(`WellnessCheckInResultsDomainCardScorePreview`)}>
      <LockIcon strokeColor={colors.iconInactive} />
      <HiddenTitleContainer />
      {getResultsIcons}
    </CardScoreContainer>
  )
}

import React, { FunctionComponent } from 'react'
import { ViewStyle } from 'react-native'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Triangle icon representing playing something
 */
export const CirclePlayIcon: FunctionComponent<CirclePlayIconProps> = ({ size = 20, fillColor, style }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' style={style}>
      <Path
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 16.5L16 12L10 7.5V16.5ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z'
        fill={fillColor || colors.iconActive}
        fillRule='evenodd'
        clipRule='evenodd'
      />
    </Svg>
  )
}

export type CirclePlayIconProps = {
  size?: number
  fillColor?: string
  style?: ViewStyle
}

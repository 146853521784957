import { fromJS } from 'immutable'

import * as TriageDemographicsActions from './triage-demographics-actions'

// TODO: Could probably move this to onboard or triage reducer
export const triageDemographics = (
  state = fromJS({
    matchFor: undefined,
    age: '',
    gender: 'unknown',
    treatmentNaive: null,
    child: {
      firstname: '',
      lastname: '',
      dob: '',
      gender: '',
      careHistory: '',
    },
  }),
  action: $TSFixMe,
) => {
  switch (action.type) {
    case TriageDemographicsActions.UPDATE_TRIAGE_DEMOGRAPHICS:
      if (action.category === 'gender' || action.category === 'age' || action.category === 'matchFor') {
        return state.set(action.category, action.value)
      }
      // THIS IS TREATMENT HISTORY NOT NAIVE
      if (action.category === 'treatmentNaive') {
        switch (action.value) {
          case 'Yes':
            return state.set('treatmentNaive', true)
          case 'No':
            return state.set('treatmentNaive', false)
          default:
            return state.set('treatmentNaive', null)
        }
      }
      return state
    case TriageDemographicsActions.CLEAR_TRIAGE_DEMOGRAPHICS:
      state = state.set('matchFor', undefined)
      state = state.set('age', '')
      state = state.set('gender', 'unknown')
      state = state.set('treatmentNaive', null)
      state = state.set('child', {
        firstname: '',
        lastname: '',
        dob: '',
        gender: '',
        careHistory: '',
      })
      return state
    default:
      return state
  }
}

export default triageDemographics

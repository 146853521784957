import React from 'react'

import { fromJS } from 'immutable'

import { DEFAULT_WORKLIFE_SERVICES } from '../../../features/workLife/constants/workLifeConstants'
import BrightHorizonsImage from '../../../images/microsite/ReThink.png'
import { oneLine } from '../../utils/stringUtils'

export const virginiamason = () => {
  return fromJS({
    workLifeServices: [
      DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.FINANCIAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT,
      DEFAULT_WORKLIFE_SERVICES.DEPENDENT_CARE,
      {
        identifiers: {
          id: 'brightHorizons',
          dataTestId: 'workLife-selectBrightHorizons',
        },
        title: 'Bright Horizons Family Support',
        img: BrightHorizonsImage,
        paragraph: 'Care Membership',
        bullets: [
          'Access to ongoing care providers - for children, seniors, pets, tutoring, housekeeping, and more',
          <>
            {oneLine(`Backup Care`)}
            <br />
            {oneLine(`Subsidized coverage when regular care is unavailable - for children, seniors, pets`)}
          </>,
        ],
        CTA: 'Access Family Support',
        PATH: 'https://clients.brighthorizons.com/virginiamason',
      },
    ],
  })
}

import React, { FunctionComponent } from 'react'
import { G, Mask, Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Small lyra logo for the toolbar
 */
export const LyraToolbarLogo: FunctionComponent<LyraHeaderLogoProps> = ({ fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width='35' height='20' viewBox='0 0 35 20' fill='none'>
      <Mask id='mask0' mask-type='alpha' x='0' y='0' width='35' height='20'>
        <Path d='M0 0H34.7925V20H0V0Z' fill='white' />
      </Mask>
      <G mask='url(#mask0)'>
        <Path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.181 4.05879L10.6988 12.1877L7.57839 4.05879H5.38507L9.56822 14.7993L7.21661 20H9.51169L16.4648 4.05879H14.181ZM23.4292 4.0814C21.5072 4.0814 20.3201 5.12154 19.7095 6.05992V4.0814H17.6745V14.8672H19.7095V10.2092C19.7095 8.17411 20.9871 6.05992 23.3387 6.05992H23.7457V4.0814H23.4292ZM32.8017 9.46297C32.8017 7.58621 31.2754 6.05992 29.3986 6.05992C27.5219 6.05992 25.9956 7.58621 25.9956 9.46297C25.9956 11.3397 27.5219 12.866 29.3986 12.866C31.2867 12.866 32.8017 11.3397 32.8017 9.46297ZM34.7915 9.46297V14.8559H32.7678V13.68C31.8407 14.4149 30.6762 14.8672 29.3986 14.8672C26.4252 14.8672 24.0058 12.459 24.0058 9.47428C24.0058 6.48954 26.4139 4.0814 29.3986 4.0814C32.3721 4.0814 34.7915 6.48954 34.7915 9.46297ZM4.17534 12.8095C4.4806 12.8208 5.35115 12.8095 5.35115 12.8095V14.8445C5.35115 14.8445 4.55974 14.8445 4.17534 14.8445C3.02215 14.8445 -0.0078125 14.6297 -0.0078125 11.4302C-0.0078125 7.77841 -0.0078125 0 -0.0078125 0H2.02724C2.02724 0 2.02724 9.15772 2.02724 10.8536C2.02724 12.4364 3.07868 12.7643 4.17534 12.8095Z'
          fill={`${fillColor || colors.iconActive}`}
        />
      </G>
    </Svg>
  )
}

type LyraHeaderLogoProps = {
  fillColor?: string
}

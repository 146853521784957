import { type NavigateFunction } from 'react-router-dom'

import { CONTACT_CARE_TEAM_REQUEST_TYPES, type ContactCareTeamRequestType } from '@lyrahealth-inc/shared-app-logic'

import { PAGE_ROUTES } from '../../data/page-navigation/location-actions'

export const goToContactCareTeam = ({
  isDirectCareNavigatorExperience,
  navigate,
  typeOfRequest = CONTACT_CARE_TEAM_REQUEST_TYPES.USER_INITIATED_DEFAULT,
  mixpanelEntryPoint,
  mixpanelEntryPointDetail,
  preferenceDetail,
}: {
  isDirectCareNavigatorExperience?: boolean
  navigate: NavigateFunction
  typeOfRequest?: ContactCareTeamRequestType
  mixpanelEntryPoint?: string
  mixpanelEntryPointDetail?: string
  preferenceDetail?: { [key: string]: any }
}) => {
  if (isDirectCareNavigatorExperience) {
    navigate(
      { pathname: PAGE_ROUTES.CARE_NAVIGATOR_OPTIONS.SECURE_INDEX, search: 'hideXButton=true' },
      {
        state: { typeOfRequest, mixpanelEntryPoint, mixpanelEntryPointDetail, preferenceDetail },
      },
    )
  } else {
    navigate(PAGE_ROUTES.CARE_NAVIGATOR_OPTIONS.ONBOARD, {
      state: { typeOfRequest, mixpanelEntryPoint, mixpanelEntryPointDetail, preferenceDetail },
    })
  }
}

export const goToContactCareTeamConfirmation = (
  navigate: NavigateFunction,
  typeOfRequest?: ContactCareTeamRequestType,
  mixpanelEntryPoint?: string,
) => {
  navigate(`/secure/onboard/${PAGE_ROUTES.CONTACT_CARE_TEAM_CONFIRMATION}`, {
    state: { typeOfRequest, mixpanelEntryPoint },
  })
}

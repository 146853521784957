import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type ValuePropMentalHealthSessionsProps = {
  size?: number
}

export const ValuePropMentalHealthSessions: FunctionComponent<ValuePropMentalHealthSessionsProps> = ({ size = 56 }) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 56 56' fill='none'>
      <Path
        d='M28.1968 0.00952148C24.3314 0.00952148 20.5811 0.672387 17.0498 1.97931C8.64285 5.09555 2.73238 12.0042 0.833422 20.9335C-0.421486 26.7554 -0.261026 32.1403 1.32697 37.3879C3.3543 44.0896 8.33078 49.7411 14.9805 52.8927C18.2815 54.4608 22.0739 55.3561 26.2503 55.553C26.787 55.5774 27.3326 55.5907 27.8748 55.5907C31.2257 55.5907 34.5964 55.1314 37.8942 54.2262C38.1476 54.1631 38.3888 54.0978 38.6433 54.0226C41.6069 53.155 44.2738 51.7828 46.5557 49.9491C49.0334 47.9694 51.0729 45.4596 52.6111 42.4938C52.9143 41.9095 53.2043 41.2942 53.5241 40.5583C55.2836 36.4727 56.1335 31.899 55.983 27.3265C55.9122 25.3047 55.6112 23.1556 55.091 20.9335C54.2345 17.3281 52.838 13.9375 51.0519 11.1222C50.5628 10.3664 50.1135 9.73119 49.6686 9.16017C46.4583 5.0192 41.4564 2.11321 35.2084 0.760917C32.9133 0.262938 30.5539 0.010628 28.1979 0.010628L28.1968 0.00952148Z'
        fill='white'
      />
      <Mask
        id='mask0_10302_10763'
        // @ts-ignore maskType not supported in react-native-svg
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x={0}
        y={0}
        width={56}
        height={56}
      >
        <Path
          d='M28.1968 0.00952148C24.3314 0.00952148 20.5811 0.672387 17.0498 1.97931C8.64285 5.09555 2.73238 12.0042 0.833422 20.9335C-0.421486 26.7554 -0.261026 32.1403 1.32697 37.3879C3.3543 44.0896 8.33078 49.7411 14.9805 52.8927C18.2815 54.4608 22.0739 55.3561 26.2503 55.553C26.787 55.5774 27.3326 55.5907 27.8748 55.5907C31.2257 55.5907 34.5964 55.1314 37.8942 54.2262C38.1476 54.1631 38.3888 54.0978 38.6433 54.0226C41.6069 53.155 44.2738 51.7828 46.5557 49.9491C49.0334 47.9694 51.0729 45.4596 52.6111 42.4938C52.9143 41.9095 53.2043 41.2942 53.5241 40.5583C55.2836 36.4727 56.1335 31.899 55.983 27.3265C55.9122 25.3047 55.6112 23.1556 55.091 20.9335C54.2345 17.3281 52.838 13.9375 51.0519 11.1222C50.5628 10.3664 50.1135 9.73119 49.6686 9.16017C46.4583 5.0192 41.4564 2.11321 35.2084 0.760917C32.9133 0.262938 30.5539 0.010628 28.1979 0.010628L28.1968 0.00952148Z'
          fill='#FFE9E3'
        />
      </Mask>
      <G mask='url(#mask0_10302_10763)'>
        <Path
          d='M32.4486 10.5727C32.4486 10.5727 30.1209 6.45498 26.4202 7.76753C22.7194 9.08008 23.018 11.1697 21.228 12.5427C19.4381 13.9156 17.7062 14.3338 17.0499 16.363C16.3936 18.3922 16.9303 20.3018 15.2588 22.6296C13.5873 24.9573 10.7822 34.3891 14.1844 34.8061C17.5865 35.2231 36.9241 37.3116 36.9241 37.3116C36.9241 37.3116 41.4007 33.1335 40.9233 28.9554C40.4459 24.7773 39.3715 25.4336 39.5503 22.8085C39.7292 20.1822 39.7292 18.0333 38.297 16.6011C36.8648 15.1689 37.7 10.9316 33.0445 11.4682L32.4474 10.5727H32.4486Z'
          fill='#7F6E51'
        />
        <Path
          d='M6.24805 53.0681C6.24805 53.0681 8.81392 36.595 12.8131 33.5517C16.8124 30.5085 23.0197 29.6721 25.2278 29.6721C27.4359 29.6721 30.4803 29.0159 35.7317 30.9847C40.9843 32.9547 45.5794 34.5065 48.8027 41.0112C52.026 47.5171 53.2793 59.6332 53.2793 59.6332C53.2793 59.6332 48.0872 65.4224 29.7637 65.7209C11.4402 66.0194 7.02396 61.4836 6.96472 59.0954C6.90549 56.7084 6.24805 53.067 6.24805 53.067V53.0681Z'
          fill='#00A4B3'
        />
        <Path
          d='M23.9739 25.5102C23.9739 25.5102 23.2572 29.4955 23.4372 30.3945C23.6161 31.2924 25.2876 34.0999 28.4505 34.0429C31.6134 33.986 33.3441 31.4051 33.4637 30.3945C33.5834 29.384 32.3893 25.3987 32.0908 23.4902C31.7923 21.5818 25.6453 20.7954 23.9739 25.5113V25.5102Z'
          fill='#D49D73'
        />
        <Path
          d='M22.185 20.9581C22.185 20.9581 21.6448 27.9414 28.0914 28.1203C34.5369 28.2991 35.1943 22.8085 35.3732 22.2707C35.5521 21.7329 37.701 21.0174 37.3432 19.167C36.9855 17.3167 35.1943 17.6744 35.1943 17.6744C35.1943 17.6744 35.3976 12.1234 31.2671 12.0641C27.1367 12.0049 24.1526 12.7204 23.3163 15.5859C22.48 18.4515 22.185 20.957 22.185 20.957V20.9581Z'
          fill='#E6B087'
        />
        <Path
          d='M23.138 18.2121C23.138 18.2121 21.5862 16.004 20.5117 17.8544C19.4373 19.7047 22.6002 21.6143 22.6002 21.6143L23.1368 18.2121H23.138Z'
          fill='#E6B087'
        />
        <Path
          d='M28.2037 41.0688C28.2037 41.0688 36.0326 39.2338 36.7776 39.3206C37.4654 39.4008 37.9544 39.5909 38.5192 41.2303C39.0631 42.8104 42.7824 58.7329 42.789 58.7702C43.1835 60.9524 42.1869 61.1623 41.2419 61.5392C40.3069 61.9128 29.8717 64.3619 27.094 65.0069C26.3666 65.1762 25.57 65.1981 24.9557 64.9883C24.469 64.8212 23.9503 64.6839 23.3944 62.8995C22.6878 60.6316 19.5069 45.5134 19.5069 45.5134C19.5069 45.5134 19.1662 43.652 21.0287 43.0883C22.8911 42.5247 28.2037 41.0688 28.2037 41.0688Z'
          fill='#EDE6DE'
        />
        <Path
          d='M26.1197 41.2336C26.1197 41.2336 23.3387 41.6819 23.3793 42.5412C23.42 43.4004 23.4376 44.7179 24.8221 44.608C26.2065 44.4981 33.7827 42.6214 34.2441 42.1698C34.7056 41.7182 34.3386 39.6536 33.6036 39.5305C32.8685 39.4075 30.7852 40.1019 30.7852 40.1019C30.7852 40.1019 29.8864 38.3559 28.1371 38.8932C26.3878 39.4316 26.1197 41.2336 26.1197 41.2336Z'
          fill='#85CCD5'
        />
        <Path
          d='M32.5671 18.7058C32.5252 18.5781 32.4451 18.4619 32.3417 18.376C32.2639 18.3016 32.171 18.2459 32.0687 18.2226C31.7516 18.1506 31.4764 18.2854 31.3265 18.5444C31.2487 18.6408 31.1918 18.7546 31.1674 18.8847C31.1488 18.98 31.1441 19.0729 31.1523 19.16C31.1372 19.2808 31.1302 19.4028 31.1337 19.5224C31.1418 19.7861 31.2917 20.0172 31.5356 20.1206C31.7714 20.2205 32.0676 20.1752 32.2511 19.9847C32.5729 19.6502 32.7134 19.153 32.5659 18.7058H32.5671Z'
          fill='#AA8052'
        />
        <Path
          d='M26.7428 18.347C26.8299 18.1089 26.6893 17.8116 26.5058 17.6687C26.3083 17.5165 25.9959 17.4724 25.7787 17.6176C25.5731 17.7546 25.4139 17.937 25.3245 18.1624C25.2293 18.289 25.1723 18.4446 25.17 18.6014C25.1665 18.8163 25.2618 19.0416 25.4232 19.1915C25.5487 19.3634 25.6857 19.4749 25.9018 19.5039C26.1109 19.5318 26.2967 19.4679 26.4512 19.3239C26.6487 19.1404 26.7625 18.8697 26.7695 18.6014C26.7718 18.5108 26.7613 18.426 26.7428 18.3459V18.347Z'
          fill='#AA8052'
        />
        <Path
          d='M29.2059 18.5166C29.1397 17.7476 27.9306 17.7407 27.9968 18.5166C28.0897 19.5957 27.8783 20.6213 27.337 21.5622C26.949 22.2382 27.9921 22.8469 28.3801 22.172C29.0189 21.0592 29.3151 19.7966 29.2048 18.5166H29.2059Z'
          fill='#C49069'
        />
        <Path
          d='M32.1562 12.1059C32.1562 12.1059 34.4399 17.6674 35.1949 17.6755C35.9499 17.6837 35.7907 13.6101 35.7907 13.6101L32.1562 12.1047V12.1059Z'
          fill='#7F6E51'
        />
        <Path
          d='M32.364 11.8329L32.1561 12.1047C32.1561 12.1047 28.3381 13.5903 25.7502 13.7181C23.1622 13.8458 22.7789 17.7126 22.7789 17.7126L22.0762 14.7089L25.4946 10.8107L28.2417 10.4587L32.364 11.8329Z'
          fill='#7F6E51'
        />
        <Path
          d='M33.7948 17.0577C33.0724 16.2214 32.0339 15.7649 30.9305 15.7812C30.4821 15.7882 30.4809 16.4851 30.9305 16.4781C31.8643 16.4642 32.6925 16.8452 33.3023 17.5502C33.5962 17.8906 34.0876 17.3957 33.7948 17.0577Z'
          fill='#AA8052'
        />
        <Path
          d='M27.6628 15.1247C26.6616 14.7518 25.5697 14.8692 24.6625 15.4313C24.2815 15.6671 24.6312 16.27 25.0145 16.033C25.7788 15.5591 26.636 15.4836 27.477 15.7961C27.8975 15.9529 28.0798 15.2792 27.6617 15.1235L27.6628 15.1247Z'
          fill='#AA8052'
        />
        <Path
          d='M30.697 23.1882C29.669 23.9653 28.317 24.1779 27.1206 23.6598C26.7129 23.4833 26.3574 24.0838 26.7686 24.2615C28.1846 24.8748 29.8061 24.7296 31.049 23.7899C31.4021 23.5228 31.0548 22.9176 30.697 23.1882Z'
          fill='#C49069'
        />
        <Path
          d='M22.7789 17.7127C22.7789 17.7127 24.1856 17.3468 25.152 16.2132C26.1184 15.0795 26.7003 13.9865 28.1952 13.818C29.6901 13.6496 32.1573 12.1059 32.1573 12.1059L29.078 10.7388L24.1333 12.3649L22.0762 14.7101L22.7789 17.7139V17.7127Z'
          fill='#7F6E51'
        />
      </G>
    </Svg>
  )
}

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CostMessagingCoverageModalData, getCostMessagingCoverageType } from '@lyrahealth-inc/shared-app-logic'

import { useIsCostMessagingEnabled } from './useIsCostMessagingEnabled'
import {
  getCostMessagingModalText,
  getHideDependentField,
  getIsCustomerEAPAndHPISupported,
  getIsCustomerEAPExclusive,
  getIsCustomerEAPMeds,
  getIsCustomerHPIExclusive,
  getIsCustomerHPIMeds,
  getIsCustomerProgramEAPCoaching,
  getIsCustomerProgramHPITherapy,
} from '../../data/customer/customerSelectors'

/**
 * Hook to retrieve coverage type to display the correct membership section
 */
export const useCostMessagingCoverageType = (): CostMessagingCoverageModalData => {
  const isCostMessagingEnabled = useIsCostMessagingEnabled()
  const costMessagingModalText = useSelector(getCostMessagingModalText)
  const isHPIExclusive = useSelector(getIsCustomerHPIExclusive)
  const isEAPExclusive = useSelector(getIsCustomerEAPExclusive)
  const isDependentDisabled = useSelector(getHideDependentField)
  const isEAPAndHPISupported = useSelector(getIsCustomerEAPAndHPISupported)
  const isCustomerEAPMeds = useSelector(getIsCustomerEAPMeds)
  const isCustomerHPIMeds = useSelector(getIsCustomerHPIMeds)
  const isCustomerProgramEAPCoaching = useSelector(getIsCustomerProgramEAPCoaching)
  const isCustomerProgramHPITherapy = useSelector(getIsCustomerProgramHPITherapy)

  return useMemo(() => {
    if (isCostMessagingEnabled) {
      const costMessagingCoverageType = getCostMessagingCoverageType({
        isHPIExclusive,
        isEAPExclusive,
        isEAPAndHPISupported,
        isDependentDisabled,
        isCustomerEAPMeds,
        isCustomerHPIMeds,
        isCustomerProgramEAPCoaching,
        isCustomerProgramHPITherapy,
      })

      const coverageModalContents =
        isCostMessagingEnabled &&
        costMessagingModalText &&
        costMessagingCoverageType &&
        costMessagingModalText[costMessagingCoverageType] != undefined
          ? costMessagingModalText[costMessagingCoverageType]
          : []

      return {
        coverageModalContents,
        isCostMessagingEnabled,
        costMessagingCoverageType,
      }
    }

    return {
      coverageModalContents: [],
      isCostMessagingEnabled,
      costMessagingCoverageType: undefined,
    }
  }, [
    costMessagingModalText,
    isCostMessagingEnabled,
    isCustomerEAPMeds,
    isCustomerHPIMeds,
    isCustomerProgramEAPCoaching,
    isCustomerProgramHPITherapy,
    isDependentDisabled,
    isEAPAndHPISupported,
    isEAPExclusive,
    isHPIExclusive,
  ])
}

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFlags, useLyraIntl } from '@lyrahealth-inc/shared-app-logic'

import { getEssentialsDisabled } from '../../data/customer/customerSelectors'
import {
  getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
  getIsUserInternational,
  getIsUserLoggedIn,
} from '../../data/user/userSelectors'

export const useShouldShowWellnessCheckIn = () => {
  const { isWellnessCheckInEnabled } = useFlags()
  const { activeLanguage } = useLyraIntl()
  const isActiveLanguageEnglish = new Intl.Locale(activeLanguage).language === 'en'
  const essentialsDisabled = useSelector(getEssentialsDisabled)
  const isLoggedIn = useSelector(getIsUserLoggedIn)
  const isUserInternational = useSelector(getIsUserInternational)
  const isUserAssumedInternational = useSelector(getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer)

  const isInternational = isLoggedIn ? isUserInternational : isUserAssumedInternational

  return useMemo(() => {
    return isWellnessCheckInEnabled && !essentialsDisabled && isActiveLanguageEnglish && !isInternational
  }, [isWellnessCheckInEnabled, essentialsDisabled, isActiveLanguageEnglish, isInternational])
}

import { DEFAULT_CUSTOMER } from '../../common/constants/appConstants'
import { authenticationFailureHandler, noOpActionErrorHandler } from '../../common/http/actionErrorHandler'
import { getBaseApplicationUserData } from '../../common/http/data/login'
import { getHealthPlanPayment, setHealthPlanData } from '../../features/healthPlan/data/healthPlanActions'
import { setAppConstantsOnPageload } from '../appGlobals/appGlobalsActions'
import { setCurrentCountry } from '../currentCountry/currentCountryActions'
import { setCustomerCopy, setCustomerInfo } from '../customer/customerActions'
import { setCustomerCountry } from '../customerCountry/customerCountryActions'
import { setCustomerProperties } from '../customerProperties/customerPropertiesActions'
import { setUserInfo } from '../user/userActions'

export const SET_OKTA_WIDGET = 'SET_OKTA_WIDGET'
export const SET_USER_INFO_FETCHED = 'SET_USER_INFO_FETCHED'
export const SET_USER_INFO_STALE = 'SET_USER_INFO_STALE'
export const SET_CSRF = 'SET_CSRF'

export const getBaseApplicationData = () => {
  return (dispatch: $TSFixMe) => {
    return getBaseApplicationUserData().then(({ data: { customer, user, params, country } }) => {
      // The code below is what sets the state for international, we will need to get rid of the if(customer) conditional
      // above when we are ready to migrate from customer over to the customer country object
      if (customer) {
        dispatch(setCustomerInfo(customer))
        dispatch(setCustomerCopy(customer.name))
        const customerCountry = {
          attestations: customer.attestations,
          healthPlansList: customer.healthPlansList,
          id: country.id,
          programCoverageBreakdown: customer.programCoverageBreakdown,
          supportedPrograms: customer.supportedPrograms,
        }
        dispatch(setCustomerCountry(customerCountry))
        dispatch(setHealthPlanData(customer))
        dispatch(setCurrentCountry(country))
        dispatch(setCustomerProperties(customer.customerPropertiesMap))
      } else {
        dispatch(setCustomerCopy(DEFAULT_CUSTOMER))
      }

      if (user) {
        dispatch(setUserInfo({ user }))

        // TODO: Find a better place to call this endpoint, adding it to currentuser is adding wood to a wildfire
        dispatch(getHealthPlanPayment(user.id))
      }
      if (params) {
        const appGlobals = params.reduce((global: { [key: string]: string }, obj: { [key: string]: string }) => {
          return Object.assign(global, obj)
        }, {})

        dispatch(setAppConstantsOnPageload(appGlobals))
      }
      dispatch(setUserFetched())
      return user
    })
  }
}

export const login = ({ code, redirect_uri }: { code: string; redirect_uri: string }) => ({
  request: {
    baseURL: '/',
    method: 'post',
    url: '/login/code',
    data: { code, redirect_uri },
  },
  errorHandler: authenticationFailureHandler,
})

export const getCsrf = () => ({
  action: SET_CSRF,
  request: {
    method: 'get',
    url: '/unsecured/v1/utils/csrf',
  },
})

export const setUserFetched = () => ({
  type: SET_USER_INFO_FETCHED,
})

export const markUserAsStale = () => ({
  type: SET_USER_INFO_STALE,
})

export const getOktaSession = (baseURL: string) => ({
  request: {
    baseURL,
    method: 'get',
    url: '/api/v1/sessions/me',
    withCredentials: true,
  },
  errorHandler: noOpActionErrorHandler,
})

import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  CLEAR_APPOINTMENT,
  HIDE_APPOINTMENT_CONFLICT_MODAL,
  SET_APPOINTMENT,
  SHOW_APPOINTMENT_CONFLICT_MODAL,
} from './appointmentBookingActions'

const initialState = fromJS({
  showModal: false,
})

export default createReducer(initialState, {
  [SHOW_APPOINTMENT_CONFLICT_MODAL]: ($$state: $TSFixMe) => {
    $$state = $$state.set('showModal', true)
    return $$state
  },
  [HIDE_APPOINTMENT_CONFLICT_MODAL]: ($$state: $TSFixMe) => {
    $$state = $$state.set('showModal', false)
    return $$state
  },
  [SET_APPOINTMENT]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('appointment', fromJS(action.appointment))
    return $$state
  },
  [CLEAR_APPOINTMENT]: ($$state: $TSFixMe, _action: $TSFixMe) => {
    return $$state
      .keySeq()
      .toArray()
      .reduce((result: $TSFixMe, key: $TSFixMe) => {
        if (key !== 'appointment') {
          result = result.set(key, $$state.get(key))
        }
        return result
      }, fromJS({}))
  },
})

import axios from 'axios'
import axiosRetry from 'axios-retry'
import { compact, uniqBy } from 'lodash-es'

import { Address, GOOGLE_PLACES_VENDOR_API_URL } from '@lyrahealth-inc/shared-app-logic'

/**
 * Generate unique list of dropdown locations
 */
export const getConstantOnsiteDropdownOptions = (addressOptions: Address[]) => {
  return uniqBy(
    addressOptions.sort((address1: Address, address2: Address) => {
      return address1.city >= address2.city ? 1 : -1
    }),
    'city',
  ).map((address: Address, idx: number) => {
    return {
      label: `${address.city}, ${address.state}`,
      value: idx + 1,
    }
  })
}

/**
 * LocationMatcherDisplayFormats.RADIO_BUTTONS_ONLY
 * Return list of all possible onsite providers
 */
export const getConstantOnsiteLocationRadioOptions = (addressOptions: Address[]) => {
  return compact(
    addressOptions.map((address: Address) => {
      return address.id
        ? {
            label: `${address.name}`,
            value: address.id,
          }
        : undefined
    }),
  )
}

/**
 * LocationMatcherDisplayFormats.DROPDOWN_MENU_TO_RADIO_BUTTONS
 * Return list of filtered onsite providers from specific 'city,state'
 */
export const generateOnsiteLocationRadioOptions = (addressOptions: Address[], location: string) => {
  if (location) {
    return compact(
      addressOptions
        .filter((address: Address) => {
          const loc = `${address.city}, ${address.state}`
          return location && location === loc
        })
        .map((address: Address) => {
          return address.id
            ? {
                label: `${address.name}`,
                value: address.id,
              }
            : undefined
        }),
    )
  } else {
    return []
  }
}

export const getGooglePlacesAutocompleteFromFreeText = (
  jwt: string,
  textStr: string,
  isUserInternational: boolean,
  userCountryIsoCode?: string,
) => {
  const url = `${GOOGLE_PLACES_VENDOR_API_URL}/place/autocomplete/json`
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    params: {
      input: textStr,
      components: isUserInternational ? `country:${userCountryIsoCode}` : 'country:us|country:pr',
    },
  }
  const axiosInstance = axios.create()
  axiosRetry(axiosInstance, {
    retries: 3,
    retryCondition: (_isNetworkOrIdempotentRequestError) => true, // retry no matter what
  })
  return axiosInstance.get(url, config)
}

export const getGooglePlaceDetailsWithPlaceId = (jwt: string, placeId: string) => {
  const url = `${GOOGLE_PLACES_VENDOR_API_URL}/place/details/json`
  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    params: {
      place_id: placeId,
    },
  }
  const axiosInstance = axios.create()
  axiosRetry(axiosInstance, {
    retries: 3,
    retryCondition: (_isNetworkOrIdempotentRequestError) => true, // retry no matter what
  })

  return axiosInstance.get(url, config)
}

export const checkValidGeoLocationAndGrabDetails = (
  jwt: string,
  textStr: string,
  isUserInternational: boolean,
  userCountryIsoCode?: string,
  logToSumo?: (message: string, extras?: Dict, error?: Error | undefined) => void,
): any => {
  return new Promise(function (resolve, reject) {
    getGooglePlacesAutocompleteFromFreeText(jwt, textStr, isUserInternational, userCountryIsoCode)
      .then((response) => {
        if (response?.data?.predictions?.length >= 1) {
          const placeId = response?.data?.predictions[0]?.place_id
          if (placeId) {
            getGooglePlaceDetailsWithPlaceId(jwt, placeId)
              .then((response) => {
                const details = response.data.result
                return resolve(details)
              })
              .catch((error) => {
                logToSumo &&
                  logToSumo(`Location Error: Could not get a place details: ${error}`, {
                    textStr,
                    isUserInternational,
                    userCountryIsoCode,
                  })
                reject(error)
              })
          } else {
            logToSumo &&
              logToSumo('Location Error: Could not get a place id', {
                placeId,
                textStr,
                isUserInternational,
                userCountryIsoCode,
              })
            return resolve(null)
          }
        } else {
          logToSumo &&
            logToSumo(`Location Error: There were no predictions: ${response}`, {
              textStr,
              isUserInternational,
              userCountryIsoCode,
            })
          return resolve(null)
        }
      })
      .catch((error) => {
        logToSumo &&
          logToSumo(`Location Error: Could not get an autocomplete result: ${error}`, {
            textStr,
            isUserInternational,
            userCountryIsoCode,
          })
        reject(error)
      })
  })
}

import React, { FunctionComponent } from 'react'
import { Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Brief case Icon with a circle shown on the welcome screen
 */
export const BriefCaseIcon: FunctionComponent<BriefCaseIconProps> = ({ size = 44 }) => {
  const { colors } = useTheme()
  const { rect } = colors.icons.briefCaseIcon

  return (
    <Svg width={size} height={size} viewBox='0 0 44 44' fill='none'>
      <Rect width={size} height={size} rx='22' fill={rect.fill} />
      <Path
        d='M24 16V14H20V16H24ZM14 19V28C14 28.55 14.45 29 15 29H29C29.55 29 30 28.55 30 28V19C30 18.45 29.55 18 29 18H15C14.45 18 14 18.45 14 19ZM30 16C31.11 16 32 16.89 32 18V29C32 30.11 31.11 31 30 31H14C12.89 31 12 30.11 12 29L12.01 18C12.01 16.89 12.89 16 14 16H18V14C18 12.89 18.89 12 20 12H24C25.11 12 26 12.89 26 14V16H30Z'
        fill={colors.iconDefault}
      />
    </Svg>
  )
}

type BriefCaseIconProps = {
  size?: string | number
}

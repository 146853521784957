import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)(({}) => ({
  width: '150px',
  height: '150px',
}))

export const RegistrationGeneralPathwayIllustration: FunctionComponent = () => {
  return (
    <ImageContainer
      source={require('../../assets/RegistrationGeneralPathway.png')}
      testID={tID('RegistrationGeneralPathway')}
    />
  )
}

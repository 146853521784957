import type { Router } from '@remix-run/router'

/**
 * @deprecated This exists solely to adapt legacy code that relied on a global router.
 * Please don't add any new usages.
 */
export let appRouter_DO_NOT_USE: Pick<Router, 'navigate' | 'state'>

export function __setGlobalRouter(router: Router) {
  if (appRouter_DO_NOT_USE) {
    console.warn('legacyRouterShim: router was set multiple times')
  }
  appRouter_DO_NOT_USE = router
}

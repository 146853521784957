export enum EligibilityField {
  DEPENDENT_HEADER = 'dependentHeader',
  ELIGIBILITY_TYPE = 'eligibilityType',
  ELIGIBILITY_ERROR_BANNER = 'eligibilityErrorBanner',
  EMPLOYEE_FULL_NAME = 'employeeFullName',
  EMPLOYEE_FIRST_NAME = 'employeeFirstName',
  EMPLOYEE_LAST_NAME = 'employeeLastName',
  EMPLOYEE_DATE_OF_BIRTH = 'employeeDOB',
  DEPENDENT_INELIGIBLE = 'eligibilityDependentIneligible',
  DEPENDENT_EMPLOYEE_RELATIONSHIP = 'dependentTypeId',
  RELATIONSHIP_INFO = 'relationshipInfo',
  CUSTOMER_CODE_FIELD_NAME = 'customerCode',
  EMPLOYEE_CUSTOMER_CODE_FIELD_NAME = 'employeeCustomerCodeField',
  DEPENDENT_CUSTOMER_CODE_FIELD_NAME = 'dependentCustomerCodeField',
  EMPLOYEE_INFORMATION = 'employeeInformation',
}

/**
 * Type for the value of LD flag requireEligibilityConfirmation
 */
export interface RequireEligibilityConfirmationLDFlag {
  isEnabled?: boolean // true to require ineligible user to confirm eligibility upon logging in
  skipIneligibleUserWithLyraCode?: boolean // true to skip eligibility confirmation for ineligible users assigned with lyra code
}

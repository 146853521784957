import { IntlShape } from 'react-intl'

import {
  ELIGIBILITY_TYPES,
  EligibilityField,
  EligibilityInfo,
  FieldSchema,
  FormMetadata,
  registrationEligibilityFormHeader,
  ThemeColors,
  USER_MAX_AGE_REQUIREMENT,
  USER_MIN_AGE_REQUIREMENT,
  walmartCustomerCodeFieldLabel,
} from '@lyrahealth-inc/shared-app-logic'

import {
  getDobUiSchema,
  getEmployeeInformationFormMetadata,
  getFullNameUiSchema,
} from '../../../formMetadata/eligibilityMetadata'
import { commonInputFieldStyling } from '../../../organisms/formBody/sharedMetadata'
import { buildNameFields } from '../../../organisms/setUpAccountForm/basicInfoMetadata'

export const walmartDependentEligibilityMetadata = (
  intl: IntlShape,
  colors: ThemeColors,
  showRegistrationTips?: boolean,
  isMobileSized?: boolean,
): FormMetadata => {
  const dependentFormSubheaderSchema: FieldSchema = {
    name: 'dependentFormSubheader',
    content: `###### ${intl.formatMessage(registrationEligibilityFormHeader)}`,
    sectionHeader: true,
  }
  const informationSubHeaderSchema: FieldSchema = {
    type: 'string',
    name: 'informationSubHeader',
  }
  const informationSubHeaderUiSchema = { 'ui:field': 'DependentEligibilityInformation' }

  const dependentRegistrationTipsSchema: FieldSchema = {
    type: 'string',
    show: showRegistrationTips,
    name: 'dependentRegistrationTips',
  }
  const dependentRegistrationTipsUiSchema = { 'ui:field': 'DependentEligibilityErrorBanner' }

  const employeeFullNameSchema: FieldSchema[] = buildNameFields(
    intl,
    false,
    {
      firstName: { name: EligibilityField.EMPLOYEE_FIRST_NAME, isRequired: true },
      lastName: { name: EligibilityField.EMPLOYEE_LAST_NAME, isRequired: true },
    },
    false,
  )

  const employeeFullNameUiSchema = {
    items: getFullNameUiSchema(isMobileSized),
  }

  const employeeDobSchema: FieldSchema = {
    name: EligibilityField.EMPLOYEE_DATE_OF_BIRTH,
    title: intl.formatMessage({
      defaultMessage: 'Date of birth',
      description: `Label text for date of birth form input`,
    }),
    type: 'string',
    placeholder: '',
    validation: {
      isRequired: true,
      isValidAge: {
        min: USER_MIN_AGE_REQUIREMENT.DOMESTIC_EMPLOYEE,
        max: USER_MAX_AGE_REQUIREMENT.ADULT,
      },
    },
  }

  const customerCodeSchema: FieldSchema = {
    type: 'string',
    title: intl.formatMessage(walmartCustomerCodeFieldLabel),
    name: EligibilityField.DEPENDENT_CUSTOMER_CODE_FIELD_NAME,
    condition: {
      parentField: EligibilityField.ELIGIBILITY_TYPE,
      parentValue: ELIGIBILITY_TYPES.DEPENDENT,
    },
    validation: { isRequired: true, isValidCustomerCode: true },
  }
  const getCustomerCodeUiSchema = (baseInputStyle?: React.CSSProperties) => ({
    'ui:options': commonInputFieldStyling(isMobileSized, baseInputStyle),
  })

  return getEmployeeInformationFormMetadata({
    section1ItemsSchema: [dependentFormSubheaderSchema, informationSubHeaderSchema, dependentRegistrationTipsSchema],
    section1ItemsUiSchema: [{}, informationSubHeaderUiSchema, dependentRegistrationTipsUiSchema],
    section2ItemsSchema: employeeFullNameSchema,
    section2ItemsUiSchema: employeeFullNameUiSchema.items,
    section3ItemsSchema: [employeeDobSchema, customerCodeSchema],
    section3ItemsUiSchema: [getDobUiSchema({ marginBottom: 24 }), getCustomerCodeUiSchema({ marginBottom: 0 })],
    colors,
  })
}

export const generateWalmartDependentEligibilityMetadata = (
  eligibilityInfo: EligibilityInfo,
  intl: IntlShape,
  colors: ThemeColors,
  isMobileSized = false,
) => {
  const { showRegistrationTips = false } = eligibilityInfo

  return walmartDependentEligibilityMetadata(intl, colors, showRegistrationTips, isMobileSized)
}

import React, { Suspense, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { LayoutChangeEvent } from 'react-native'
import { useLocation } from 'react-router-dom'

import styled from 'styled-components/native'

import { AppPromoBanner, IS_WEB, LoadingIndicator, ThemeType } from '@lyrahealth-inc/ui-core-crossplatform'

import MicrositeAndElsewhereTopBanner from '../../../features/microsite/components/Banners/MicrositeAndElsewhereTopBanner'
import GenericModal from '../../../features/page-components/modals/generic/modal-container'
import { clientSessionContainerId } from '../../constants/appConstants'
import { NEGATIVE_PADDING_PATHS } from '../../constants/routingConstants'
import { useShowAppPromo } from '../../hooks/useShowAppPromo'
import { getRouteTitle } from '../../utils/routingUtils'
import { matchPaths } from '../../utils/stringUtils'
import Banner from '../banner/banner-container'
import Header from '../header/Header'
import ToastContainer from '../toastContainer/ToastContainer'

const ClientSessionContainer = styled.View<{
  theme: ThemeType
  shouldAddMargin: boolean
  extraContainerHeight: number
}>(({ theme, shouldAddMargin, extraContainerHeight }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 9,
  ...(shouldAddMargin &&
    IS_WEB && {
      marginTop: extraContainerHeight,
      borderTopWidth: '1px',
      borderTopColor: theme.colors.borderDefault,
    }),
}))

/**
 * Layout for the app which includes the header, banner, models, and footer
 */
export const MainAppLayout: React.FC = ({ children }) => {
  const intl = useIntl()
  const location = useLocation()
  const isHomePage = ['/'].includes(location.pathname)
  const [containerHeight, setContainerHeight] = useState(0)

  // Need to add extra margin to compensate for OnboardingNavBar
  const shouldAddMargin = matchPaths(location.pathname, NEGATIVE_PADDING_PATHS)

  useEffect(() => {
    // NOTE: On navigate, location may be updated before window.location
    const nextRouteTitle = getRouteTitle(window.location.origin + location.pathname, intl, '')
    if (nextRouteTitle) {
      document.title = nextRouteTitle
    }
  }, [intl, location])

  const { showAppPromoBanner } = useShowAppPromo()

  return (
    <>
      <MicrositeAndElsewhereTopBanner />
      <Banner />
      {/* Microsite homepage header behavior is slightly different then rest of web app 
      i.e. it is sticky for mobile sized screens so we use StickyView in Homepage.tsx to display header */}
      {!isHomePage && (
        <>
          {showAppPromoBanner && <AppPromoBanner />}
          <Header />
        </>
      )}
      <ClientSessionContainer
        shouldAddMargin={shouldAddMargin}
        extraContainerHeight={containerHeight}
        onLayout={(e: LayoutChangeEvent) => {
          setContainerHeight(e.nativeEvent.layout.height)
        }}
      >
        <div id={clientSessionContainerId} />
      </ClientSessionContainer>
      <GenericModal />
      <ToastContainer />
      <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
    </>
  )
}

import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  SET_ALL_COUNTRIES,
  SET_CUSTOMER_COUNTRY,
  SET_CUSTOMER_COUNTRY_LIST,
  SET_SUPPORTED_COUNTRIES,
} from './customerCountryActions'

const initialState = fromJS({
  customerCountryList: [],
})

export default createReducer(initialState, {
  [SET_CUSTOMER_COUNTRY]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const customerCountryInfo = fromJS(action.val)
    $$state = $$state.mergeDeep(customerCountryInfo, fromJS({ isLoaded: true }))
    return $$state
  },
  [SET_CUSTOMER_COUNTRY_LIST]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('customerCountryList', fromJS(action.data))
  },
  [SET_ALL_COUNTRIES]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('allCountries', fromJS(action.data))
  },
  [SET_SUPPORTED_COUNTRIES]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('supportedCountries', fromJS(action.data))
  },
})

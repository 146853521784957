import { useCallback, useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'lodash-es'

import { Assignment, VideoLinks } from '../../../models/assignments/Assignments'
import { AssignmentStatuses, Review, VideoLesson, VideoStatuses } from '../types'

/**
 * Based on video id, this function will map the id to the video links from the vimeo api
 * so we can set information like the duration of the video.
 * @param videoId vimeo video id
 * @param videoLinks video links return from vimeo api
 * @returns
 */
const mapVimeoData = (videoId: string, videoLinks?: VideoLinks | Dict): { time: number; timeFormatted: string } => {
  let timeInfo = { time: 0, timeFormatted: '' }
  if (videoId && videoLinks && !isEmpty(videoLinks)) {
    const match = videoLinks[`/videos/${videoId}`] as VideoLinks
    if (match) {
      timeInfo = {
        time: match.duration,
        timeFormatted: `${Math.round(match.duration / 60)} min`,
      }
    }
  }
  return timeInfo
}

/**
 * Hook handles traversing and mapping the assignments api data and vimeo api data to return
 * video links, selected lesson videos, and video progress.
 * This hook is technically ran twice, the first time is to get the video ids from assignments api response
 * and then again to map the vimeo data
 */
export const useVideoLessons = ({ assignments, videoLinks, selectedAssignmentId }: UseVideoLessonsParams): Return => {
  /**
   * Helper function to rip out the video information from the assignments
   * @param assignments list of Assignments
   * @returns Dict
   */
  const getVideosFromAssignment = useCallback(
    (assignments: Assignment[], videoLinks?: VideoLinks | Dict): Return | Dict => {
      return assignments.reduce<{ [assignmentId: string]: VideoInfo }>((result, assignment) => {
        if (!result[assignment.id]) {
          result[assignment.id] = {
            videoLinks: [],
            videoData: { videos: [], review: null },
            completionPercentage: 0,
          }
        }
        const { content_meta_data, assignment_responses: assignmentResponses, id } = assignment
        const contents = assignment.content_meta_data.contents ?? []
        let videoTotalLength = 0
        let lessonProgressCompleted = 0

        /** Each Lesson has contents which "can" consist of multiple videos for one lesson */
        contents.forEach((content, index) => {
          const propertyName = content_meta_data.contents ? content_meta_data.contents[index].name : ''
          const assignmentResponse = assignmentResponses?.find(
            (response) => response.response && response.response[propertyName],
          )
          const response = assignmentResponse?.response[propertyName]
          const responseStatus = response ? response.status : VideoStatuses.new
          const videoProgress = response ? response.progress : 0
          const videoId = content.meta_data.url
            ? content.meta_data.url.substring(content.meta_data.url.lastIndexOf('/') + 1)
            : ''

          const timeInfo = mapVimeoData(videoId, videoLinks)

          if (content.content_type === 'video') {
            result[assignment.id].videoLinks.push(videoId)
            result[assignment.id].videoData.videos.push({
              assignmentId: id,
              thumbnail: content.meta_data.thumbnail ?? '',
              url: content.meta_data.url ?? '',
              completionStatus: responseStatus,
              videoProgress,
              videoPropertyName: propertyName,
              videoTitle: content.title,
              videoId,
              videoIndex: index,
              ...timeInfo,
            } as VideoLesson)
            /**
             * Calculate based on the time in seconds saved for the assignment response how much the user has
             * completed the video lesson. If the lesson is completed, default to using the time in the vimeo api
             * because when a video is completed, the assignment response saves the progress with a value of 0 instead of the
             * end progress value of the video
             */
            lessonProgressCompleted +=
              responseStatus === VideoStatuses.completed ? timeInfo.time : videoProgress * timeInfo.time
            videoTotalLength += timeInfo.time
          } else if (content.content_type === 'form') {
            result[assignment.id].videoData.review = {
              reviewPropertyName: propertyName,
              reviewTime: content.estimatedCompletionTimeMinutes || 2,
              reviewCompletionStatus: responseStatus,
            }
          }
        })
        /** based on each lessons assignment response, calculate total lesson completion */
        result[assignment.id].completionPercentage =
          videoTotalLength > 0 ? (lessonProgressCompleted / videoTotalLength) * 100 : 0
        return result
      }, {})
    },
    [],
  )

  const [selectedVideos, setSelectedVideos] = useState<Video>({ videos: [], review: null })

  /** Begin the maddness of traversing the assignments object */
  const assignmentsWithVideos = useMemo(
    () => getVideosFromAssignment(assignments, videoLinks),
    [assignments, videoLinks, getVideosFromAssignment],
  )

  // Gets the video ids to pass to the vimeo api so we can get the video links and meta data info (duration of video)
  const videoIds = useMemo(
    () =>
      Object.values(assignmentsWithVideos).reduce<string[]>(
        // @ts-expect-error TS(2345): Argument of type '(videoIds: string[], value: Vide... Remove this comment to see the full error message
        (videoIds, value: VideoInfo) => videoIds.concat(value.videoLinks),
        [],
      ),
    [assignmentsWithVideos],
  )

  /** If given a selected assignment id, set the group of videos as the videos to return */
  useEffect(() => {
    if (selectedAssignmentId) {
      const videoInfo: VideoInfo = assignmentsWithVideos[selectedAssignmentId]
      setSelectedVideos(videoInfo.videoData)
    }
  }, [selectedAssignmentId, assignmentsWithVideos])

  /** Convienence function to get the lesson progress for a specific video based on assignment id */
  const getVideoProgressForId = useCallback(
    (assignmentId: string) => {
      const videoInfo: VideoInfo = assignmentsWithVideos[assignmentId]
      return videoInfo && videoInfo.completionPercentage
    },
    [assignmentsWithVideos],
  )

  /**
   * A way to get the progress of the assignment from the
   * assignment -> video object without having to re-map the activity groups
   * with the assignment -> video object
   * @param assignmentId
   * @returns
   */
  const getLessonProgress = (assignmentId: string, completionStatus: AssignmentStatuses) => {
    /**
     * If videos have been completely watched, i.e. progress equals 100, however, the status
     * of the lesson is still incomplete, assume review is not done and subtract 20% of the progress bar
     */
    const progress = getVideoProgressForId(assignmentId)
    const reviewIncomplete = completionStatus !== AssignmentStatuses.completed && progress === 100
    return Math.min(progress, progress - (reviewIncomplete ? 20 : 0))
  }

  return {
    // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type 'string[]... Remove this comment to see the full error message
    videoIds,
    selectedVideos: selectedVideos.videos,
    selectedReview: selectedVideos.review,
    getVideoProgressForId,
    getLessonProgress,
  }
}

type Video = { videos: VideoLesson[]; review: Review | null }

type UseVideoLessonsParams = {
  assignments: Assignment[]
  videoLinks?: VideoLinks | Dict
  selectedAssignmentId?: string
}

type Return = {
  videoIds: string[]
  selectedVideos: VideoLesson[]
  selectedReview: Review | null
  getVideoProgressForId: (assignmentId: string) => number
  getLessonProgress: (assignmentId: string, completionStatus: AssignmentStatuses) => number
}

interface VideoInfo {
  videoLinks: string[]
  videoData: { videos: VideoLesson[]; review: Review | null }
  completionPercentage: number
}

import { type OktaAuthOptions } from '@okta/okta-auth-js'

export function isInLoginFlow(pathname: $TSFixMe) {
  return ['/login'].reduce((useCustomClass, path) => useCustomClass || pathname.startsWith(path), false)
}

export function makeOktaAuthOptions(
  oktaClientId: string,
  oktaBaseUrl: string,
  redirectBaseUrl: string,
): OktaAuthOptions {
  return {
    clientId: oktaClientId,
    issuer: oktaBaseUrl + '/oauth2/default',
    pkce: false,
    redirectUri: redirectBaseUrl + '/login/okta',
    responseType: 'code',
    scopes: ['openid', 'email', 'profile', 'offline_access'],
  }
}

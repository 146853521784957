import { defineMessage } from 'react-intl'

export const faegredrinker = () => {
  return {
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage: 'Are you personnel of Faegre Drinker?',
        description: 'Asking if the user is an employee of the company Faegre Drinker',
      }),
  }
}

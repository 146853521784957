import { createAsyncThunk } from '@reduxjs/toolkit'

import { CreateChildUser, User, UserNotificationItem } from '@lyrahealth-inc/shared-app-logic'

import { getId } from './userSelectors'
import { noOpActionErrorHandler } from '../../common/http/actionErrorHandler'
import { axiosInstance } from '../../common/http/axiosInstance'
import { updateNotificationItem } from '../../common/http/data/editProfile'

export const SET_USER_INFO = 'SET_USER_INFO'
export const setUserInfo = (data: $TSFixMe) => ({
  type: SET_USER_INFO,
  data,
})
export const TOGGLE_OFF_MFA = 'TOGGLE_OFF_MFA'
export const TOGGLE_ON_MFA = 'TOGGLE_ON_MFA'
export const setUserPhoneCountryCode = (data: $TSFixMe) => ({
  type: SET_USER_PHONE_COUNTRY_CODE,
  data,
})
export const SET_USER_PHONE_COUNTRY_CODE = 'SET_USER_PHONE_COUNTRY_CODE'
export const SET_USER_DIRECT_DEDICATED_CARE_NAVIGATOR_LINK = 'SET_USER_DIRECT_DEDICATED_CARE_NAVIGATOR_LINK'
export const SET_USER_CHILDREN = 'SET_USER_CHILDREN'
export const SET_USER_REMAINING_TIME = 'SET_USER_REMAINING_TIME'

export const POST_GUARDIAN_INDICATED = 'POST_GUARDIAN_INDICATED'

export const SET_USER_DEDICATED_CARE_NAVIGATOR_INFO = 'SET_USER_DEDICATED_CARE_NAVIGATOR_INFO'

export const getDirectDedicatedCareNavigatorLink = (lyraId: string) => ({
  action: SET_USER_DIRECT_DEDICATED_CARE_NAVIGATOR_LINK,
  request: {
    method: 'get',
    url: `/v1/user/${lyraId}/direct-dedicated-care-navigator-link`,
  },
  errorHandler: noOpActionErrorHandler,
})

export const fetchUserChildren = (lyraId: string) => ({
  action: SET_USER_CHILDREN,
  request: {
    method: 'get',
    url: `/v1/user/${lyraId}/child`,
  },
  errorHandler: noOpActionErrorHandler,
})

export const createChildUser = ({ guardianLyraId, data }: CreateChildUser) => {
  return async () => {
    try {
      const response = await axiosInstance.put(`/v1/user/${guardianLyraId}/child`, data)
      return { payload: response.data }
    } catch (error: any) {
      return {
        meta: { requestStatus: 'rejected' },
        payload: {
          data: error.response.data,
        },
      }
    }
  }
}

export const createChildUserDeprecated = (guardianLyraId: string, child: any, customerCountryId: string) => {
  return {
    request: {
      method: 'put',
      url: `/v1/user/${guardianLyraId}/child`,
      withCredentials: true,
      data: {
        customerCountryId,
        ...child,
      },
    },
    errorHandler: noOpActionErrorHandler,
  }
}

export const getUserRemainingTime = () => ({
  action: SET_USER_REMAINING_TIME,
  request: {
    method: 'get',
    url: '/unsecured/v1/utils/user-session-remaining-time',
  },
  errorHandler: noOpActionErrorHandler,
})

export const postGuardianIndicated = (lyraId: string) => ({
  action: POST_GUARDIAN_INDICATED,
  request: {
    method: 'post',
    url: `/v1/user/${lyraId}/guardianship/indicated`,
  },
})

export const getDirectDedicatedCareNavigatorInfo = (lyraId: string) => ({
  action: SET_USER_DEDICATED_CARE_NAVIGATOR_INFO,
  request: {
    method: 'get',
    url: `/v1/user/${lyraId}/dedicated-care-navigator`,
  },
  errorHandler: noOpActionErrorHandler,
})

export const updateUserNotificationItem = createAsyncThunk<User, UserNotificationItem>(
  'UPDATE_USER_NOTIFICATION_ITEM',
  async (data, { getState, rejectWithValue }) => {
    const state: any = getState()
    const lyraId = getId(state)
    if (!lyraId) {
      return rejectWithValue(new Error('No lyraId'))
    }
    try {
      const response = await updateNotificationItem(lyraId, data)
      const updatedUser: User = response.data.user
      return updatedUser
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export type OnsiteIconProps = {
  size?: number
  fillColor?: string
  testID?: string
}

export const OnsiteIcon: FunctionComponent<OnsiteIconProps> = ({ size = 24, fillColor, testID }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none' testID={testID}>
      <Path
        d='M7.29922 7.19995V8.55158L8.17885 7.52535L8.76302 6.84381C8.86912 6.74887 9.04427 6.75211 9.14567 6.8535C9.20927 6.91711 9.215 6.95689 9.21269 6.99376C9.20914 7.05059 9.17615 7.17275 9.02496 7.36711L7.09211 9.29995H6.99211L6.92141 9.37066C6.87719 9.32673 6.83595 9.29851 6.81407 9.28393C6.79757 9.27292 6.78079 9.26257 6.77174 9.25698L6.76936 9.25551C6.75854 9.24883 6.75381 9.24584 6.75001 9.2433C6.74963 9.24305 6.74929 9.24282 6.749 9.24262L4.75277 7.2464C4.64803 7.14166 4.64803 6.95824 4.75277 6.8535C4.85751 6.74877 5.04093 6.74877 5.14567 6.8535L5.84567 7.5535L6.69922 8.40706V7.19995V6.99995C6.69922 5.52381 5.47536 4.29995 3.99922 4.29995C3.96183 4.29995 3.8826 4.27622 3.80277 4.1964C3.72295 4.11657 3.69922 4.03734 3.69922 3.99995C3.69922 3.96257 3.72295 3.88333 3.80277 3.8035C3.8826 3.72368 3.96183 3.69995 3.99922 3.69995C5.82308 3.69995 7.29922 5.17609 7.29922 6.99995V7.19995Z'
        stroke={fillColor || colors.iconInactive}
      />
      <Path
        d='M20.9992 3.19995H11.9992C11.5992 3.19995 11.1992 3.49995 11.1992 3.99995V11.2H3.99922C3.59922 11.2 3.19922 11.5 3.19922 12V21C3.19922 21.4 3.49922 21.7999 3.99922 21.7999H6.99922H8.99922H11.9992H20.9992C21.3992 21.7999 21.7992 21.5 21.7992 21V3.99995C21.7992 3.59995 21.3992 3.19995 20.9992 3.19995ZM8.19922 20.2H7.79922V16.7H8.29922V20.2H8.19922ZM11.1992 20.2H9.79922V16C9.79922 15.6 9.49922 15.2 8.99922 15.2H6.99922C6.59922 15.2 6.19922 15.5 6.19922 16V20.2H4.79922V12.7H11.2992V20.2H11.1992ZM20.1992 20.2H12.6992V12V4.79995H20.1992V20.2Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M14.9992 8.79995C15.3992 8.79995 15.7992 8.49995 15.7992 7.99995V6.99995C15.7992 6.59995 15.4992 6.19995 14.9992 6.19995C14.4992 6.19995 14.1992 6.59995 14.1992 6.99995V7.99995C14.1992 8.39995 14.5992 8.79995 14.9992 8.79995Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M17.9992 8.79995C18.3992 8.79995 18.7992 8.49995 18.7992 7.99995V6.99995C18.7992 6.59995 18.4992 6.19995 17.9992 6.19995C17.4992 6.19995 17.1992 6.59995 17.1992 6.99995V7.99995C17.1992 8.39995 17.5992 8.79995 17.9992 8.79995Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M14.9992 12.8C15.3992 12.8 15.7992 12.5 15.7992 12V11C15.7992 10.6 15.4992 10.2 14.9992 10.2C14.4992 10.2 14.1992 10.5 14.1992 11V12C14.1992 12.4 14.5992 12.8 14.9992 12.8Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M17.9992 12.8C18.3992 12.8 18.7992 12.5 18.7992 12V11C18.7992 10.6 18.4992 10.2 17.9992 10.2C17.4992 10.2 17.1992 10.5 17.1992 11V12C17.1992 12.4 17.5992 12.8 17.9992 12.8Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M14.9992 16.8C15.3992 16.8 15.7992 16.5 15.7992 16V15C15.7992 14.6 15.4992 14.2 14.9992 14.2C14.4992 14.2 14.1992 14.5 14.1992 15V16C14.1992 16.4 14.5992 16.8 14.9992 16.8Z'
        fill={fillColor || colors.iconInactive}
      />
      <Path
        d='M17.9992 16.8C18.3992 16.8 18.7992 16.5 18.7992 16V15C18.7992 14.6 18.4992 14.2 17.9992 14.2C17.4992 14.2 17.1992 14.5 17.1992 15V16C17.1992 16.4 17.5992 16.8 17.9992 16.8Z'
        fill={fillColor || colors.iconInactive}
      />
    </Svg>
  )
}

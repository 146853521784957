import { AnyAction } from 'redux'

import {
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  IDENTITY_PROVIDERS,
  PathwayOptions,
  REGISTRATION_EVENTS,
  TRIAGE_ENTRY_POINTS,
} from '@lyrahealth-inc/shared-app-logic'
import { SetupAccountFormValues } from '@lyrahealth-inc/ui-core-crossplatform/src/organisms/setUpAccountForm/SetUpAccountForm'

import * as BannerActions from '../../../common/components/banner/banner-actions'
import { getAttestationData, getDependentTypesData } from '../../../common/http/data/customer'
import * as MixpanelActions from '../../../data/mixpanel/index'

export const SET_ATTESTATIONS = 'SET_ATTESTATIONS'
export const SET_DEPENDENT_TYPES = 'SET_DEPENDENT_TYPES'
export const SET_ETHNICITY_CATEGORIES = 'SET_ETHNICITY_CATEGORIES'
export const UPDATE_REGISTRATION_FORM_VALUES = 'UPDATE_REGISTRATION_FORM_VALUES'
export const SET_REGISTRATION_FORM_VALUES = 'SET_REGISTRATION_FORM_VALUES'
export const SHOW_REGISTRATION_TIPS = 'SHOW_REGISTRATION_TIPS'
export const HIDE_REGISTRATION_TIPS = 'HIDE_REGISTRATION_TIPS'
export const SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY'
export const SET_SSO_TOKEN = 'SET_SSO_TOKEN'
export const SET_SSO_IDENTITY_PROVIDER = 'SET_SSO_IDENTITY_PROVIDER'
export const SET_SELECTED_PATHWAY_OPTION = 'SET_SELECTED_PATHWAY_OPTION'
export const CLEAR_SELECTED_PATHWAY_OPTION = 'CLEAR_SELECTED_PATHWAY_OPTION'
export const SET_TRIAGE_ENTRYPOINT = 'SET_TRIAGE_ENTRYPOINT'
export const SET_REDIRECT_URL_ON_TRIAGE_EXIT = 'SET_REDIRECT_URL_ON_TRIAGE_EXIT'
export const CLEAR_TRIAGE_ENTRYPOINT = 'CLEAR_TRIAGE_ENTRYPOINT'
export const SET_SELECTED_ESSENTIALS_TOPIC_IDS = 'SET_SELECTED_ESSENTIALS_TOPIC_IDS'

export const dispatchMixpanelForEmailConfirmation = () => {
  return (dispatch: $TSFixMe) => {
    dispatch(
      MixpanelActions.trackEventWithObj({
        event: REGISTRATION_EVENTS.REQUEST_EMAIL_VERIFICATION,
        [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.REGISTER_BY_EMAIL,
      }),
    )
  }
}

export const getEthnicityCategories = () => ({
  action: SET_ETHNICITY_CATEGORIES,
  request: {
    method: 'get',
    url: '/unsecured/v1/register/raceVersions',
  },
})

const displayErrorBanner = (error: $TSFixMe) => {
  return (dispatch: $TSFixMe) => {
    return dispatch(BannerActions.setBannerAndErrorMessage(error, true))
  }
}

export const getAttestations = (data: $TSFixMe) => {
  return async (dispatch: $TSFixMe) => {
    try {
      const attestation = await getAttestationData(data)
      return dispatch({
        type: SET_ATTESTATIONS,
        data: attestation.data,
      })
    } catch (error) {
      return displayErrorBanner(error)
    }
  }
}

export const getDependentTypes = (data: $TSFixMe) => {
  return async (dispatch: $TSFixMe) => {
    try {
      const response = await getDependentTypesData(data)
      return dispatch({
        type: SET_DEPENDENT_TYPES,
        dependentTypes: response.data,
      })
    } catch (error) {
      return displayErrorBanner(error)
    }
  }
}

export const updateRegistrationFormValues = (values: $TSFixMe) => {
  return {
    type: UPDATE_REGISTRATION_FORM_VALUES,
    data: values,
  }
}

export const setRegistrationFormValues = (values: SetupAccountFormValues) => {
  return {
    type: SET_REGISTRATION_FORM_VALUES,
    data: values,
  }
}

export const showRegistrationTips = () => ({
  type: SHOW_REGISTRATION_TIPS,
})

export const hideRegistrationTips = () => ({
  type: HIDE_REGISTRATION_TIPS,
})

export const getSelectedCountry = () => ({
  action: SET_SELECTED_COUNTRY,
  request: {
    method: 'get',
    url: '/unsecured/v1/detect-country',
  },
})

// @ts-expect-error TS(7030): Not all code paths return a value.
export const updateSelectedCountry = (country: $TSFixMe): AnyAction => {
  if (country) {
    return {
      type: SET_SELECTED_COUNTRY,
      data: {
        countryIso: country.countryIsoCode,
        countryName: country.countryName,
        countryId: country.countryId,
        customerCountryId: country.customerCountryId,
      },
    }
  }
}

export const setSsoToken = (value: string) => ({ type: SET_SSO_TOKEN, value })

export const setSsoIdentityProvider = (value: IDENTITY_PROVIDERS) => ({ type: SET_SSO_IDENTITY_PROVIDER, value })

export const setSelectedPathwayOption = (value: PathwayOptions) => {
  return async (dispatch: $TSFixMe) => {
    dispatch({
      type: SET_SELECTED_PATHWAY_OPTION,
      data: value,
    })
  }
}

export const clearSelectedPathwayOption = () => {
  return {
    type: CLEAR_SELECTED_PATHWAY_OPTION,
  }
}

export const setSelectedEssentialsTopicIds = (value: string[]) => {
  return {
    type: SET_SELECTED_ESSENTIALS_TOPIC_IDS,
    data: value,
  }
}

export const setTriageEntryPoint = (entryPoint: TRIAGE_ENTRY_POINTS, redirectUrlOnTriageExit?: string) => {
  return (dispatch: $TSFixMe) => {
    dispatch({
      type: SET_TRIAGE_ENTRYPOINT,
      data: entryPoint,
    })
    if (Boolean(redirectUrlOnTriageExit)) {
      dispatch({
        type: SET_REDIRECT_URL_ON_TRIAGE_EXIT,
        data: redirectUrlOnTriageExit,
      })
    }
  }
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CareNavigatorEmailLink } from '../../../atoms'

export interface WalmartEligibilityErrorMessageProps {
  isPristineConfirmEligibility?: boolean // is shown in the pristine confirm eligibility form
  isWalmartAPIError?: boolean // eligibility check error is from external walmart API
}

export const WalmartEligibilityErrorMessage: React.FC<WalmartEligibilityErrorMessageProps> = ({
  isPristineConfirmEligibility,
  isWalmartAPIError,
}) => {
  if (isWalmartAPIError) {
    return (
      <FormattedMessage
        defaultMessage='We’re currently unable to connect with Walmart to check your eligibility. Try again in a few moments, or contact {email} for help.'
        description='Error message when eligibility check failed due to unable to connect with Walmart systems'
        values={{
          email: <CareNavigatorEmailLink />,
        }}
      />
    )
  } else if (isPristineConfirmEligibility) {
    return (
      <FormattedMessage
        defaultMessage='Looks like we need to confirm your WIN number. Contact {email} for help.'
        description='Error message when Walmart user needs to confirm their eligibility using their Walmart Identification Number (WIN)'
        values={{
          email: <CareNavigatorEmailLink />,
        }}
      />
    )
  } else {
    return (
      <FormattedMessage
        defaultMessage='Looks like we can’t confirm the information you shared. Double-check the date of birth and WIN number, or
        contact {email} for help.'
        description='Error message when birth date and Walmart Identification Number (WIN) cannot be confirmed'
        values={{
          email: <CareNavigatorEmailLink />,
        }}
      />
    )
  }
}

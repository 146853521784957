import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Calendar Icon that can be used for elements related to Session History
 */
export const CalendarIcon: FunctionComponent<CalendarIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} fill='none'>
      <Path
        d='M12 15.75a.75.75 0 1 0 0-1.5H9.75v-4.5h4.5V12a.75.75 0 1 0 1.5 0V9.75h4.5V12a.75.75 0 1 0 1.5 0V5.5a2.18 2.18 0 0 0-2.1-2.25h-1.9V2a.75.75 0 1 0-1.5 0v1.25h-8.5V2a.75.75 0 0 0-1.5 0v1.25h-1.9a2.18 2.18 0 0 0-2.1 2.25v14a2.18 2.18 0 0 0 2.1 2.25H12a.75.75 0 1 0 0-1.5H9.75v-4.5H12Zm-3.75-1.5h-4.5v-4.5h4.5v4.5Zm-3.9-9.5h1.9V6a.75.75 0 0 0 1.5 0V4.75h8.5V6a.75.75 0 1 0 1.5 0V4.75h1.9a.69.69 0 0 1 .6.75v2.75H3.75V5.5a.69.69 0 0 1 .6-.75Zm3.9 15.5h-3.9a.69.69 0 0 1-.6-.75v-3.75h4.5v4.5Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M18 13.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5Zm0 8A3.25 3.25 0 1 1 21.25 18 3.26 3.26 0 0 1 18 21.25Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M18.75 17.62V16.5a.75.75 0 1 0-1.5 0v1.43c0 .199.08.39.22.53l1.07 1.07a.74.74 0 0 0 .53.22.73.73 0 0 0 .53-.22.75.75 0 0 0 0-1.06l-.85-.85Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

export type CalendarIconProps = {
  size?: string | number
  fillColor?: string
}

import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { WellnessCheckInResultsDomainProps } from '@lyrahealth-inc/shared-app-logic'
import {
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  WellnessCheckInResultsDomainCard,
  WellnessCheckInResultsDomainCardScore,
} from '@lyrahealth-inc/ui-core-crossplatform'

import {
  getDomainDisplayName,
  getWellnessCheckInEssentialsContents,
  translateWellnessCheckInNumberScoreToDomainScore,
} from '../../utils/wellnessCheckInResults/utils'
import { WellnessCheckInResultsDomainContent } from '../wellnessCheckInResultsDomainContent/WellnessCheckInResultsDomainContent'

const DomainContainer = styled.View({
  margin: `80px auto 112px auto`,
  flexDirection: 'row',
  columnGap: '96px',
})

const DomainCardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const EssentialsContainer = styled.View(() => ({
  flex: 1,
  maxWidth: '464px',
}))

const EssentialsHeaderTitleContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['24px'],
}))

const EssentialsTitleContainer = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

const DomainContentContainer = styled.View(() => ({
  marginBottom: '36px',
}))

export const WellnessCheckInResultsFullViewDomain: FunctionComponent<WellnessCheckInResultsDomainProps> = ({
  wellbeingResponse,
  onArticlePress,
  isPreview,
}) => {
  if (Object.keys(wellbeingResponse).length === 0) {
    throw new Error('Empty wellbeing response')
  }
  const [activeDomain, setActiveDomain] = useState(Object.keys(wellbeingResponse)[0])
  const essentialsContents = getWellnessCheckInEssentialsContents(wellbeingResponse[activeDomain].response)

  return (
    <DomainContainer testID={tID(`WellnessCheckInResultsFullViewDomain`)}>
      <View>
        {Object.keys(wellbeingResponse).map((domain, index) => {
          return (
            <DomainCardContainer key={index}>
              <WellnessCheckInResultsDomainCard
                title={getDomainDisplayName(domain)}
                score={translateWellnessCheckInNumberScoreToDomainScore(wellbeingResponse[domain].score)}
                onPress={() => {
                  setActiveDomain(domain)
                }}
                isActive={activeDomain === domain}
                domain={domain}
                isPreview={isPreview && index > 0}
              />
            </DomainCardContainer>
          )
        })}
      </View>
      <EssentialsContainer>
        <EssentialsHeaderTitleContainer>
          <EssentialsTitleContainer
            text={<FormattedMessage {...getDomainDisplayName(activeDomain)} />}
            size={SubheadSize.MEDIUM}
          />
          <WellnessCheckInResultsDomainCardScore
            score={translateWellnessCheckInNumberScoreToDomainScore(wellbeingResponse[activeDomain].score)}
          />
        </EssentialsHeaderTitleContainer>
        {essentialsContents.length > 0 && (
          <>
            {essentialsContents.map((content, index) => {
              return (
                <DomainContentContainer key={index}>
                  <WellnessCheckInResultsDomainContent
                    title={content.title}
                    body={content.body}
                    articleContents={content.articleContents}
                    key={activeDomain}
                    onArticlePress={onArticlePress}
                  />
                </DomainContentContainer>
              )
            })}
          </>
        )}
      </EssentialsContainer>
    </DomainContainer>
  )
}

import React from 'react'
import { G, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

type CalendarEmptyIconProps = {
  fillColor?: string
  height?: string | number
  strokeColor?: string
  width?: string | number
}

export const CalendarEmptyIcon: React.FC<CalendarEmptyIconProps> = ({
  fillColor,
  height = 23,
  strokeColor,
  width = 24,
}) => {
  const { colors } = useTheme()
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none'>
      <G id='Group 1073713012'>
        <Rect
          id='Rectangle 10162'
          x='6.55273'
          y='0.375'
          width='0.75'
          height='4.44759'
          rx='0.375'
          fill={fillColor ?? colors.textSecondary}
          stroke={strokeColor ?? colors.textPrimary}
          strokeWidth='0.75'
          strokeLinejoin='round'
        />
        <Rect
          id='Rectangle 10167'
          x='22.8828'
          y='8.05859'
          width='0.75'
          height='21.5254'
          transform='rotate(90 22.8828 8.05859)'
          fill={fillColor ?? colors.textSecondary}
          stroke={strokeColor ?? colors.textPrimary}
          strokeWidth='0.75'
          strokeLinejoin='round'
        />
        <Rect
          id='Rectangle 10163'
          x='17.6914'
          y='0.375'
          width='0.75'
          height='4.44759'
          rx='0.375'
          fill={fillColor ?? colors.textSecondary}
          stroke={strokeColor ?? colors.textPrimary}
          strokeWidth='0.75'
          strokeLinejoin='round'
        />
        <Rect
          id='Rectangle 10166'
          x='0.988281'
          y='2.97656'
          width='22.2604'
          height='18.5478'
          rx='3.25'
          stroke={strokeColor ?? colors.textPrimary}
          strokeWidth='1.5'
          strokeLinejoin='round'
        />
      </G>
    </Svg>
  )
}

import React, { FunctionComponent } from 'react'

import thumbsUpUrl from '../../assets/thumbs-up-rating.png'
import { Image } from '../image/Image'

interface ThumbsUpIllustrationProps {
  size?: number
}

export const ThumbsUpIllustration: FunctionComponent<ThumbsUpIllustrationProps> = ({ size = 48 }) => {
  return <Image source={thumbsUpUrl} accessibilityIgnoresInvertColors style={{ height: size, width: size }} />
}

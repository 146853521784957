import React, { FunctionComponent } from 'react'
import { Circle, G, Path, Svg } from 'react-native-svg'

import { tID } from '../../utils'

type ProfileBookingLyraCommunityTherapyIllustrationProps = {
  size?: number
}

/**
 * Illustration shown on appointment booking confirmation
 */
export const ProfileBookingLyraCommunityTherapyIllustration: FunctionComponent<
  ProfileBookingLyraCommunityTherapyIllustrationProps
> = ({ size = 72 }) => {
  return (
    <Svg
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 512 512'
      testID={tID('ProfileBookingLyraCommunityTherapyIllustration')}
    >
      <Path
        fill='#F5F2EE'
        d='M257.8 1.7c-35.3 0-69.6 6-102 18A232.3 232.3 0 0 0 7.7 193a293.6 293.6 0 0 0 4.5 150.5c18.6 61.2 64 113 124.9 141.7a268.5 268.5 0 0 0 103 24.4 346.2 346.2 0 0 0 113.3-14c27.1-8 51.5-20.5 72.4-37.3 22.6-18 41.3-41 55.3-68.1A280.6 280.6 0 0 0 503.7 193c-7.8-33-20.6-64-37-89.7-4.4-6.9-8.5-12.7-12.6-18C424.8 47.6 379.1 21 322 8.7c-21-4.6-42.5-6.9-64-6.9h-.1Z'
      />
      <G opacity='0.6'>
        <Circle cx='109.3' cy='134' r='22.5' fill='#85CCD5' />
        <Circle cx='435.3' cy='260' r='22.5' fill='#85CCD5' />
        <Circle cx='247.3' cy='452' r='22.5' fill='#85CCD5' />
        <Path
          stroke='#85CCD5'
          strokeDasharray='26 26'
          strokeLinecap='round'
          strokeWidth='13'
          d='m146.8 143.5 255 101M123.8 164.5l108 242M269.8 417.5l143-132'
        />
      </G>
      <Path
        fill='#7FB04D'
        d='M318 334.6C277.4 404 179.2 391 117.9 378.2c12-2.8 26-7.9 28.4-20 1.5-7.5-2.6-15-5.6-22-34.8-82.3 55-120.2 121.5-99.3 56 17.7 77 61.8 56 97.7Z'
      />
      <Path
        fill='#A3C979'
        d='M224.2 237.5c59.5 51.4 148.6 5.5 203-37.6-64 6.5-29.2-57.9-104.3-89.9-94-40-165.2 70.2-98.7 127.5Z'
      />
    </Svg>
  )
}

import React, { FunctionComponent, useEffect } from 'react'
import { View } from 'react-native'

import { DEFAULT_LANGUAGE, useScript } from '@lyrahealth-inc/shared-app-logic'

import { tID } from '../../utils'

export interface EssentialsHomepageWidgetProps {
  language: string
}

export const EssentialsHomepageWidget: FunctionComponent<EssentialsHomepageWidgetProps> = ({ language }) => {
  const scriptStatus = useScript(
    'https://lyra-essentials-widgets.bukwild.com/essentials-homepage-widget.js',
    false,
    undefined,
  )

  useEffect(() => {
    if (scriptStatus === 'ready') {
      const existingHomepage = document.querySelector('.widget.essentials-homepage')
      if (existingHomepage) {
        existingHomepage.remove()
      }

      const divElement = document.createElement('div')
      divElement.setAttribute('data-essentials-homepage-widget', '')
      divElement.setAttribute('data-locale', language === DEFAULT_LANGUAGE ? 'en' : language.toLowerCase())
      document.querySelector('[data-testid="EssentialsHomepageWidgetContainer"]')!.appendChild(divElement)
      window.EssentialsHomepageWidget.mount('[data-essentials-homepage-widget]')
    }
  }, [scriptStatus, language])

  return <View testID={tID('EssentialsHomepageWidgetContainer')} />
}

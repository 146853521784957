import { useCallback } from 'react'

import { noop } from 'lodash-es'

import { ProviderProgramTaxonomy } from '../../../models/providers/Providers'
import { YearProgramCoverage } from '../../../models/user/User'
import { getTriageTreatmentOptionFromCTPO, isValidCTPOCombination } from '../../common/constants/customerProgram'
import { TREATMENT_OPTIONS_TYPE } from '../../common/constants/treatmentOptions'
import { getProgramFromTreatmentOption, shouldNavigateToHPIPage } from '../../healthPlan/utils'
import { DirectLinkSource } from '../constants'

interface useNavigateToDirectPathBookingArgs {
  setSelectedTreatmentOptionCTPO: (ctpo: ProviderProgramTaxonomy) => void
  setSelectedTreatmentOption: (treatmentOption?: TREATMENT_OPTIONS_TYPE) => void
  setIsCurrentSearchDirectPath: (isDirectPath: boolean) => void
  setDirectLinkIntent: (directLinkIntent: string) => void
  setDirectLinkSource: (directLinkSource: DirectLinkSource | string) => void
  clearDirectPathState: () => void
  navigate: {
    goToHPIIneligible: () => void
    goToHPIPage: () => void
    goToLocationFinder: () => void
  }
}

/**
 * Hook that handles setting state variable needed for direct path navigation
 */
export const useNavigateToDirectPathBooking = ({
  setSelectedTreatmentOptionCTPO,
  setSelectedTreatmentOption,
  setIsCurrentSearchDirectPath,
  setDirectLinkIntent,
  setDirectLinkSource,
  clearDirectPathState,
  navigate: { goToHPIPage = noop, goToHPIIneligible = noop, goToLocationFinder = noop },
}: useNavigateToDirectPathBookingArgs) => {
  return useCallback(
    ({
      ctpo,
      directLinkIntent,
      directLinkSource,
      isHealthPlanConfirmed,
      getIsEAPSessionCountExhausted,
      currentProgramCoverageBreakdown,
    }: {
      ctpo: Partial<ProviderProgramTaxonomy>
      directLinkIntent?: string
      directLinkSource?: DirectLinkSource | string
      isHealthPlanConfirmed: boolean
      getIsEAPSessionCountExhausted: (options: { treatmentOption?: TREATMENT_OPTIONS_TYPE | undefined }) => boolean
      currentProgramCoverageBreakdown: YearProgramCoverage
    }) => {
      clearDirectPathState()
      setIsCurrentSearchDirectPath(true)
      if (!isValidCTPOCombination(ctpo)) {
        // Location finder page handles invalid CTPO values -> should land on care navigator page after entering location
        return goToLocationFinder()
      }
      const treatmentOption = getTriageTreatmentOptionFromCTPO(ctpo)
      setSelectedTreatmentOptionCTPO(ctpo)
      setSelectedTreatmentOption(treatmentOption)
      if (directLinkIntent) {
        setDirectLinkIntent(directLinkIntent)
      }
      if (directLinkSource) {
        setDirectLinkSource(directLinkSource)
      }

      const isEAPSessionCountExhausted = getIsEAPSessionCountExhausted({ treatmentOption })
      if (
        !!treatmentOption &&
        !getProgramFromTreatmentOption({
          treatmentOptionName: treatmentOption,
          customerPrograms: currentProgramCoverageBreakdown,
          allowPartialTreatmentOptions: true,
        })
      ) {
        goToHPIIneligible()
      } else if (
        shouldNavigateToHPIPage({
          treatmentOption,
          customerPrograms: currentProgramCoverageBreakdown,
          isHealthPlanConfirmed,
          isEAPSessionCountExhausted,
          allowPartialTreatmentOptions: true,
        })
      ) {
        goToHPIPage()
      } else {
        goToLocationFinder()
      }
    },
    [
      clearDirectPathState,
      goToHPIIneligible,
      goToHPIPage,
      goToLocationFinder,
      setDirectLinkIntent,
      setDirectLinkSource,
      setIsCurrentSearchDirectPath,
      setSelectedTreatmentOption,
      setSelectedTreatmentOptionCTPO,
    ],
  )
}

export const SELECT_ASSIGNMENT = 'SELECT_ASSIGNMENT'
export const GET_ASSIGNMENTS = 'GET_ASSIGNMENTS'
export const GET_VIDEO_LINKS = 'GET_VIDEO_LINKS'
export const UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT'
export const CLEAR_ASSIGNMENT = 'CLEAR_ASSIGNMENT'
export const ADD_ASSIGNMENT_RESPONSE = 'ADD_ASSIGNMENT_RESPONSE'
export const UPDATE_ASSIGNMENT_RESPONSE = 'UPDATE_ASSIGNMENT_RESPONSE'
export const PATCH_ASSIGNMENT_RESPONSE = 'PATCH_ASSIGNMENT_RESPONSE'
export const UPDATE_SELECTED_SESSION = 'UPDATE_SELECTED_SESSION'
export const DELETE_ASSIGNMENT_DRAFT = 'DELETE_ASSIGNMENT_DRAFT'
export const SET_ASSIGNMENT_STATUS = 'SET_ASSIGNMENT_STATUS'
export const GET_ASSIGNMENT = 'GET_ASSIGNMENT'

import React, { FunctionComponent, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { FORM_ERROR } from 'final-form'
import parsePhoneNumber from 'libphonenumber-js'
import styled, { useTheme } from 'styled-components/native'

import {
  ContactUsFormValues,
  DEFAULT_COUNTRY_ISO_CODE,
  isValidEmail,
  isValidPhoneNumber,
  User,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { CheckboxRFF, InputFieldRFF, MaskedInputRFF } from '../../atoms/formElements'
import { WarningIcon } from '../../atoms/icons'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { Subhead } from '../../atoms/subhead/Subhead'
import { XButton } from '../../atoms/xButton/XButton'
import { BodyTextSize, SubheadSize } from '../../styles/typeStyles'
import { Flex1SafeAreaView, Flex1View } from '../../templates/content/CommonViews'
import { tID } from '../../utils/utils'
import { ContactUsSuccessPage } from '../contactUsSuccessPage/ContactUsSuccessPage'

export interface ContactUsPageProps {
  submitForm: (v: { values: ContactUsFormValues }) => void
  onExit?: () => void
  user?: User
  isChildConcern?: boolean
  showSuccessDismiss?: boolean
  onSuccessDismiss?: () => void
}

const HoursHeaderTextContainer = styled.View(({ theme }) => ({
  marginTop: theme.spacing['24px'],
}))

const HoursTextContainer = styled.View(({ theme }) => ({
  marginLeft: theme.spacing['8px'],
}))

const SubTitleContainer = styled(BodyText)(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const TextContainer = styled(BodyText)(({ theme }) => ({
  marginBottom: theme.spacing['12px'],
}))

const ButtonContainer = styled(PrimaryButton)(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  marginBottom: theme.spacing['48px'],
  ...(!theme.breakpoints.isMobileSized && {
    width: '138px',
    alignSelf: 'flex-end',
  }),
}))

const HeaderContainer = styled(Subhead)(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  marginBottom: theme.spacing['16px'],
}))

const LabelContainer = styled(BodyText)(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const ContactUsPageContainer = styled(Flex1SafeAreaView)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['32px'] : 0,
  marginHorizontal: theme.breakpoints.isMobileSized ? `${theme.spacing['16px']}` : 0,
  backgroundColor: theme.colors.backgroundPrimary,
}))

const XIconContainer = styled(XButton)({
  alignSelf: 'flex-end',
})

const ErrorContainer = styled.View(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  marginBottom: theme.spacing['24px'],
  flexDirection: 'row',
  alignSelf: 'center',
}))

const WarningIconContainer = styled(WarningIcon)(({ theme }) => ({
  marginTop: '2px',
  marginRight: theme.spacing['8px'],
}))

export const ContactUsPage: FunctionComponent<ContactUsPageProps> = ({
  user,
  submitForm,
  onExit,
  isChildConcern = false,
  showSuccessDismiss = true,
  onSuccessDismiss,
}) => {
  const { formatMessage } = useIntl()
  const [showSuccess, setShowSuccess] = useState(false)
  const { breakpoints, colors } = useTheme()

  const initialValues = {
    emailAddress: user?.username || null,
    phoneNumber: user?.phone || null,
  }

  const formValidation = (values: ContactUsFormValues) => {
    const errors: { emailAddress?: string; phoneNumber?: string } = {}

    if (values.emailChecked) {
      if (!values.emailAddress) {
        errors.emailAddress = formatMessage({
          defaultMessage: 'Email address is required',
          description: 'Email input field error message indicating that an email address must be filled in',
        })
      } else if (isValidEmail(values.emailAddress, 'emailAddress', true, formatMessage).emailAddress) {
        errors.emailAddress = formatMessage({
          defaultMessage: 'Invalid Email',
          description: 'Email input field error message indicating that the email address entered is not valid',
        })
      }
    }
    if (values.phoneChecked) {
      if (!values.phoneNumber) {
        errors.phoneNumber = formatMessage({
          defaultMessage: 'Phone number is required',
          description: 'Phone number input field error message indicating that a phone number must be entered',
        })
      } else if (!isValidPhoneNumber(values.phoneNumber)) {
        errors.phoneNumber = formatMessage({
          defaultMessage: 'Invalid Phone number',
          description: 'Phone number input field error message indicating that a valid phone number must be entered',
        })
      }
    }
    return errors
  }

  const onSubmit = async (values: ContactUsFormValues) => {
    if (!values.phoneChecked && !values.emailChecked) {
      return {
        [FORM_ERROR]: (
          <FormattedMessage
            defaultMessage='Please select at least once contact method'
            description='Overall form error message indicating that the user needs to select a contact method before the form can be submitted'
          />
        ),
      }
    }
    try {
      const parsedNumber = values.phoneNumber && parsePhoneNumber(values.phoneNumber, DEFAULT_COUNTRY_ISO_CODE)
      values.phoneNumber = parsedNumber ? parsedNumber.number : values.phoneNumber
      await submitForm({ values })
      setShowSuccess(true)
    } catch (error) {
      return {
        [FORM_ERROR]: (
          <FormattedMessage
            defaultMessage='Unable to send message - {error}'
            description='Error message indicating that the message is unable to be sent'
            values={{ error }}
          />
        ),
      }
    }
    return {}
  }

  const HeaderText = !isChildConcern ? (
    <FormattedMessage
      defaultMessage='Send a message'
      description='Header text above a form to contact the care navigator team'
    />
  ) : (
    <FormattedMessage
      defaultMessage='Contact a Lyra Care Navigator'
      description='Header of form for getting in touch with a Lyra Care Navigator'
    />
  )

  const SubTitleText = !isChildConcern ? (
    <FormattedMessage
      defaultMessage='Our live chat isn’t available right now, but we’re still here to help. Send a message to the Care Navigator team, and we’ll respond within one business day.'
      description='Explains live chat being unavailable hours, shown above contact form to reach the care navigator team'
    />
  ) : (
    <FormattedMessage
      defaultMessage='One of our Care Navigators is available to assist you in any way possible. You’ll receive a response within 1 business day.'
      description="Subheader explaining that a Care Navigator will respond to the user's request in 1 business day"
    />
  )

  return (
    <ContactUsPageContainer testID={tID('ContactUsPage')}>
      {showSuccess ? (
        <ContactUsSuccessPage
          showDismiss={showSuccessDismiss}
          onDismissPress={onSuccessDismiss ? onSuccessDismiss : () => setShowSuccess(false)}
        />
      ) : (
        <Flex1View>
          {!!onExit && <XIconContainer onPress={onExit} showExitText={false} />}
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={formValidation}
            render={({ handleSubmit, values, errors, touched, submitError }) => (
              <KeyboardAwareScrollView>
                <HeaderContainer size={SubheadSize.LARGE} text={HeaderText} />
                <SubTitleContainer size={BodyTextSize.DEFAULT} text={SubTitleText} color={colors.textSecondary} />
                <Field
                  name='textareanote'
                  component={InputFieldRFF}
                  multiline
                  numberOfLines={4}
                  label={formatMessage({
                    defaultMessage: 'How can we support you today?',
                    description:
                      'Label of free text field where the user can enter a description of what they need help with',
                  })}
                  color={colors.textPrimary}
                />
                <LabelContainer
                  size={BodyTextSize.DEFAULT}
                  text={
                    <FormattedMessage
                      defaultMessage='How would you like us to respond?*'
                      description='Header of section containing options for the user to indicate how they would like to be contacted'
                    />
                  }
                  color={colors.textPrimary}
                />
                <Field
                  name='emailChecked'
                  component={CheckboxRFF}
                  title={
                    <FormattedMessage
                      defaultMessage='Email me'
                      description='Option that the user can select if they would like to be contacted via email'
                    />
                  }
                  baseInputStyle={{ marginBottom: values.emailChecked ? 8 : 24 }}
                />
                {values.emailChecked && (
                  <Field
                    name='emailAddress'
                    label={formatMessage({
                      defaultMessage: 'Email Address*',
                      description: 'Label of field for the user to enter their email address',
                    })}
                    component={InputFieldRFF}
                    error={touched && errors?.emailAddress}
                    baseInputStyle={{ marginBottom: 16 }}
                  />
                )}
                <Field
                  name='phoneChecked'
                  component={CheckboxRFF}
                  title={
                    <FormattedMessage
                      defaultMessage='Call me'
                      description='Option that the user can select if they would like to be contacted via phone'
                    />
                  }
                  baseInputStyle={{ marginBottom: values.phoneChecked ? 8 : 0 }}
                />
                {values.phoneChecked && (
                  /** Not using the international phone number entry because
                   * this page is not for international users currently. Users that are international
                   * get a different chat live for ICAS in the International Footer after they have registered
                   * and access it from the Homebase. A user can only submit a valid domestic phone number
                   * and the /v1/utils/contactcaremanager api only accepts domestic phone numbers currently.
                   */
                  <Field
                    name='phoneNumber'
                    label={formatMessage({
                      defaultMessage: 'Phone Number',
                      description: 'Label of field for the user to enter their phone number',
                    })}
                    type='cel-phone'
                    placeholder='+1 (XXX) XXX-XXXX'
                    mask='+1 (999) 999-9999'
                    component={MaskedInputRFF}
                    keyboardType='numeric'
                    convert={false}
                    baseInputStyle={{ marginBottom: 0 }}
                  />
                )}
                {!isChildConcern && (
                  <>
                    <HoursHeaderTextContainer>
                      <TextContainer
                        size={BodyTextSize.DEFAULT}
                        text={
                          <FormattedMessage
                            defaultMessage='Live chat is available:'
                            description='Header text describing when chat is available'
                          />
                        }
                        color={colors.textSecondary}
                      />
                    </HoursHeaderTextContainer>
                    <HoursTextContainer>
                      <BodyText
                        size={BodyTextSize.DEFAULT}
                        text={
                          <FormattedMessage
                            defaultMessage='·  Mon-Thu 8am-11pm ET'
                            description='Text describing week day hours'
                          />
                        }
                        color={colors.textSecondary}
                      />
                      <TextContainer
                        size={BodyTextSize.DEFAULT}
                        text={
                          <FormattedMessage
                            defaultMessage='·  Fri 8am-9pm ET'
                            description='Text describing week day hours'
                          />
                        }
                        color={colors.textSecondary}
                      />
                    </HoursTextContainer>
                  </>
                )}
                <ButtonContainer
                  text={
                    <FormattedMessage defaultMessage='Submit' description='Label of button to submit contact form' />
                  }
                  onPress={handleSubmit}
                  fullWidth={breakpoints.isMobileSized}
                  testID={tID('ContactUsPage-SubmitButton')}
                />
                {submitError && (
                  <ErrorContainer>
                    <WarningIconContainer fillColor={colors.iconError} size={14} />
                    <BodyText text={submitError} size={BodyTextSize.SMALL} color={colors.textError}></BodyText>
                  </ErrorContainer>
                )}
              </KeyboardAwareScrollView>
            )}
          />
        </Flex1View>
      )}
    </ContactUsPageContainer>
  )
}

import { ProgramCategory } from '../../../models/session/SessionUsage'
import { TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND, TREATMENT_OPTIONS_TYPE } from '../constants/treatmentOptions'

export function getSessionLimitProgramCategoryByTreatmentOption(
  treatmentOption: TREATMENT_OPTIONS_TYPE,
): ProgramCategory {
  switch (treatmentOption) {
    case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.COACHING:
    case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.LIVE_MESSAGING_COACHING:
    case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.GUIDED_SELF_CARE:
      return 'coaching'
    default:
      // all other treatment options are considered therapy
      return 'therapy'
  }
}

import React from 'react'

import { fromJS } from 'immutable'

import MoneyImage from '../../../images/microsite/EY.png'
import LifeCareImage from '../../../images/microsite/LifeCare.png'
import { oneLine } from '../../utils/stringUtils'

export const newyorkfed = () => {
  return fromJS({
    workLifeServices: [
      {
        identifiers: {
          id: 'fedProtect',
          dataTestId: 'workLife-fedProtect',
        },
        title: 'FedProtect',
        img: MoneyImage,
        bullets: [],
        paragraph:
          'FedProtect, powered by Allstate Identity Protection, offers identity and credit monitoring, fraud remediation and restoration, and identity theft reimbursement.',
        CTA: 'Visit FedProtect',
        PATH: 'https://www.myaip.com/fedprotect',
      },
      {
        identifiers: {
          id: 'ayco',
          dataTestId: 'workLife-ayco',
        },
        title: 'Ayco Financial Education',
        img: LifeCareImage,
        bullets: [],
        paragraph: (
          <>
            {oneLine(
              `The Federal Reserve’s Financial Education Program, available through Ayco, offers free access to coaches, as well as digital tools to help you better your financial well-being.`,
            )}
            <br />
            <br />
            {oneLine(
              `Although Ayco coaches can’t provide investment advice, they are versed in Federal Reserve retirement benefits, and can share guidance to help you make informed decisions.`,
            )}
          </>
        ),
        CTA: 'Visit Federal Reserve Benefits',
        PATH: 'http://federalreservebenefits.org/',
      },
    ],
  })
}

import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

export const SpiritualityAndExplorationIllustration: FunctionComponent<{ size?: number }> = ({ size = 40 }) => {
  return (
    <Svg width={size} height={size} fill='none'>
      <G clipPath='url(#spirituality-and-exploration-illustration)'>
        <Path
          fill='#FFDC69'
          d='M27.682 8.201c-2.46-2.041-5.798-2.52-8.898-2.016-2.997.496-6.165 1.747-8.001 4.234a9.22 9.22 0 0 0-1.75 4.822c-.172 2.327.332 4.772 1.656 6.73 1.306 1.923 4.773 3.477 4.26 6.107-.187.941 1.043 1.31 1.803 1.168.213.227.495.403.82.386l3.074-.176c.726.075 1.443.143 2.169.218.444.042.785-.134 1.007-.411.607-.034 1.862.142 1.862-.572 0-1.251-.188-2.898.299-3.957.486-1.058.905-1.52 1.7-2.251 1.357-1.26 2.407-2.764 2.928-4.537 1.042-3.553-.034-7.359-2.929-9.753V8.2Z'
        />
        <Path
          fill='#fff'
          d='M24.957 16.006c.077-1.47-1.46-2.184-2.536-1.21-.674.613-.828 1.596-.956 2.462a20.582 20.582 0 0 0-.188 1.764 6.491 6.491 0 0 1-2.024.495c-.128-.638-.316-1.26-.623-1.831-.658-1.235-2.067-2.554-3.535-1.823-1.452.723-1.068 2.529-.077 3.461.896.84 2.126 1.21 3.407 1.227.077.79.077 1.596.034 2.369a23.328 23.328 0 0 1-.785 5.032c-.163.613.742.966.905.344.52-1.965.828-3.973.862-6.006.009-.588 0-1.193-.051-1.79a8.326 8.326 0 0 0 1.836-.42c-.009.244-.026.48-.034.723-.052 2.386-.017 4.771-.017 7.149 0 .63.965.73.965.084 0-2.78-.094-5.595.076-8.384.197-.101.385-.21.564-.32 1.102-.722 2.11-1.94 2.186-3.326h-.009Zm-8.078 3.26c-.47-.152-.93-.379-1.28-.74-.368-.37-.795-1.126-.36-1.604 1.068-1.16 2.434.907 2.767 1.764.103.268.171.546.24.831a5.61 5.61 0 0 1-1.367-.252Zm5.397-.773c.069-1.101.24-3.31 1.324-3.168 1.187.152-.367 2.37-.735 2.714a5.24 5.24 0 0 1-.589.462v-.009Z'
        />
        <Path
          fill='#CDB8A6'
          d='M14.854 26.809h10.837v6.485a.71.71 0 0 1-.718.706h-9.41a.71.71 0 0 1-.717-.706V26.81h.008Z'
        />
      </G>
      <Defs>
        <ClipPath id='spirituality-and-exploration-illustration'>
          <Path fill='#fff' d='M0 0h40v40H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

import React, { FunctionComponent } from 'react'
import { G, Mask, Path, Svg } from 'react-native-svg'

import { tID } from '../../utils'

type ProfileBookingCoachingParentsIllustrationProps = {
  size?: number
}

/**
 * Illustration shown on appointment booking confirmation
 */
export const ProfileBookingCoachingParentsIllustration: FunctionComponent<
  ProfileBookingCoachingParentsIllustrationProps
> = ({ size = 72 }) => {
  return (
    <Svg
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 512 512'
      testID={tID('ProfileBookingCoachingParentsIllustration')}
    >
      <Path fill='#3BA5B5' d='M205.228 168.233L205.218 168.253L205.208 168.233H205.228Z' />
      <Path fill='#3BA5B5' d='M205.239 168.213L205.259 168.233H205.229L205.239 168.213Z' />
      <Path
        fill='white'
        d='M257.8 2C222.459 2 188.17 8.06049 155.884 20.0095C79.0203 48.5008 24.9818 111.665 7.61986 193.305C-3.85359 246.534 -2.38652 295.767 12.1323 343.745C30.6679 405.017 76.1671 456.688 136.964 485.504C167.145 499.84 201.819 508.025 240.003 509.826C244.91 510.049 249.898 510.17 254.855 510.17C285.492 510.17 316.31 505.972 346.461 497.695C348.778 497.119 350.983 496.522 353.311 495.834C380.406 487.901 404.789 475.355 425.652 458.59C448.305 440.49 466.952 417.543 481.016 390.428C483.788 385.086 486.439 379.46 489.363 372.732C505.45 335.377 513.22 293.561 511.844 251.755C511.197 233.27 508.445 213.621 503.69 193.305C495.858 160.342 483.09 129.341 466.76 103.602C462.288 96.6913 458.18 90.8838 454.113 85.6631C424.762 47.8027 379.03 21.2337 321.905 8.8699C300.921 4.31695 279.35 2.01012 257.81 2.01012L257.8 2Z'
      />
      <Mask
        id='mask0_6614_9885'
        // maskType='luminance' // when react-native-svg can be updated, we can flip this on
        maskUnits='userSpaceOnUse'
        x='21'
        y='23'
        width='469'
        height='466'
      >
        <Path
          fill='white'
          d='M489.879 252.443C489.251 234.727 486.54 216.373 482.27 198.131C475.289 168.739 463.815 139.762 448.082 114.954C444.43 109.328 440.686 103.905 436.619 98.6945C407.116 60.652 362.406 39.4858 316.644 29.5806C265.671 18.5321 211.47 21.7597 162.471 39.9007C91.5861 66.1661 43.3248 123.705 27.5817 197.716C21.5313 225.752 19.7607 257.441 22.4723 287.885H22.2598C23.828 305.186 26.9544 322.073 31.6389 337.603C49.0514 395.142 91.3635 440.803 145.26 466.34C174.45 480.202 207.069 487.082 240.225 488.65C274.099 490.218 308.499 485.938 340.299 477.187C342.383 476.671 344.366 476.145 346.45 475.517C371.36 468.222 393.042 456.961 411.598 442.058C431.823 425.9 448.497 405.574 461.428 380.664C464.24 375.241 466.739 369.828 469.137 364.294C484.354 328.953 491.133 290.596 489.777 252.554L489.879 252.453V252.443Z'
        />
      </Mask>
      <G mask='url(#mask0_6614_9885)'>
        <Path
          fill='#FFE9E3'
          stroke='#3BA5B5'
          strokeMiterlimit='10'
          d='M265.215 524.173C412.069 524.173 531.118 405.125 531.118 258.271C531.118 111.417 412.069 -7.63208 265.215 -7.63208C118.361 -7.63208 -0.6875 111.417 -0.6875 258.271C-0.6875 405.125 118.361 524.173 265.215 524.173Z'
        />
      </G>
      <Path
        fill='#5F912D'
        d='M260.228 344.332C268.635 324.916 267.968 296.354 255.634 278.648C247.075 266.355 230.33 256.814 215.7 255.175C200.422 253.465 184.163 254.659 170.787 262.54C159.031 269.461 149.105 279.821 143.712 292.61C140.222 300.876 138.684 309.982 139.16 318.987C140.111 336.986 148.377 355.461 163.462 365.316C181.684 377.214 206.29 382.253 226.9 374.634C239.618 369.929 254.208 358.223 260.218 344.342L260.228 344.332Z'
      />
      <Path
        fill='#3BA5B5'
        d='M275.677 376.698H378.473C383.91 376.698 388.317 372.29 388.317 366.853V222.373C388.317 216.936 383.91 212.528 378.473 212.528H275.677C270.24 212.528 265.833 216.936 265.833 222.373V366.853C265.833 372.29 270.24 376.698 275.677 376.698Z'
      />
      <Path
        fill='#FFAF9B'
        d='M116.962 201.47L260.764 111.665C266.683 107.972 274.524 110.542 277.084 117.038C295.427 163.508 281.576 215.766 241.47 240.807C201.363 265.859 148.316 255.377 114.624 218.488C109.92 213.338 111.043 205.163 116.962 201.47Z'
      />
    </Svg>
  )
}

export const DEFAULT_QUESTIONS = {
  WHAT_IS_LYRA_HEALTH: 'What is Lyra Health?',
  DISABILITY_PAPERWORK: 'Can you help me file disability paperwork?',
  WILL_MY_EMPLOYER_KNOW: 'Will my employer know if I use Lyra?',
  TYPES_OF_ISSUES: 'What types of issues can Lyra help me solve?',
  WHAT_IS_COVERED: 'What is covered?',
  WHAT_DOES_LYRA_NOT_COVER: 'What does Lyra not cover?',
  HOW_LONG_TO_USE: 'How long does it take to use Lyra?',
  HOW_DOES_LYRA_WORK: 'How does Lyra work?',
  HOW_MUCH_DOES_LYRA_COST: 'How much does Lyra cost?',
  FORMATS_FOR_CARE: 'What formats for care are available?',
  WHO_CAN_USE_LYRA: 'Who can use Lyra?',
  HOW_TO_RECOMMEND_LYRA: 'How can I recommend Lyra to a colleague, dependent or household member?',
  HOW_TO_RECOMMEND_LYRA_TO_A_COLLEAGUE_OR_DEPENDENT: 'How can I recommend Lyra to a colleague or dependent?',
  WHAT_DOES_THERAPY_ENTAIL: 'I’m new to therapy. What does it entail?',
  WHAT_IS_THE_DIFFERENCE_BETWEEN_THERAPY_AND_COACHING: 'What is the difference between therapy and coaching?',
  WHY_DOES_LYRA_COLLECT_FEEDBACK: 'Why does Lyra collect feedback after my therapy sessions?',
  WHATS_DIFFERENT_ABOUT_LYRA: 'What’s different about Lyra compared to my health plan?',
  CONTINUING_TO_SEE_PROVIDER:
    'If I’m already seeing a provider, can I continue to see them and have the session costs covered through the Lyra benefit?',
  WORK_LIFE_SERVICES: 'Does Lyra offer work life services?',
  WHAT_HAPPENS_IF_I_MISS_AN_APPOINTMENT: 'What happens if I miss an appointment or need to cancel last-minute?',
  WHAT_IF_I_MISS_AN_APPOINTMENT: 'What if I miss an appointment or need to cancel last-minute?',
  WHAT_IF_I_REACH_MY_SESSION_LIMIT: 'What if I reach my session limit and I want to continue meeting with my provider?',
  HOW_DOES_MEETING_VIA_LIVE_VIDEO_WORK: 'How does meeting with a provider via live video work?',
  HOW_DOES_MEETING_VIA_LIVE_MESSAGING_WORK: 'How does meeting with a provider via live messaging work?',
  HOW_DOES_ONSITE_SCHEDULING_WORK: 'How does scheduling for onsite therapy work?',
  CAN_I_USE_LYRA_WHILE_TRAVELING: 'Can I use Lyra if I’m traveling?',
  HOW_IS_LYRA_DIFFERENT_FROM_MEDICAL_PLAN: 'How is Lyra different from my medical plan?',
  IF_I_GO_ON_LEAVE_WILL_LYRA_COVER_MY_CARE: 'If I go on leave, will Lyra continue to cover my cost of care?',
  IF_I_LEAVE_OR_LOSE_MY_JOB_WILL_LYRA_COVER_MY_CARE:
    'If I leave or lose my job, will Lyra still cover the cost of my care?',
  WHAT_IS_IT_LIKE_GETTING_CARE_WITH_LYRA: 'What’s it like getting care with Lyra?',
  WHAT_IS_A_MENTAL_HEALTH_COACH: 'What is a mental health coach?',
  WHAT_IS_GUIDED_SELF_CARE: 'I’m not familiar with Guided Self-Care, what is it?',
  WHAT_IS_MENTAL_HEALTH_COACHING: 'I’m not familiar with Mental Health Coaching, what is it?',
  DIFFERENCE_BETWEEN_COACHING_AND_THERAPY: 'What’s the difference between Mental Health Coaching and therapy?',
  WHAT_IF_I_WANT_TO_FIND_PROVIDER_OF_COLOR: 'What if I want to find a provider of color?',
  WHAT_IS_MEDICATION_MANAGEMENT: 'I’m not familiar with medication management, what is it?',
}

export interface QuestionItem {
  question: string
  answer: JSX.Element
  key?: string
}

export const EXCLUDED_SERVICES = ({ considerMeds = 'false' } = {}) => {
  return `${considerMeds === 'true' ? '' : 'psychiatry, '}inpatient or residential treatment, hospitalization 
  (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription 
  medication, autism spectrum disorder treatment, services for remedial education, executive coaching, and 
  non-evidence-based behavioral health care`
}

import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { initialLocationObject } from '@lyrahealth-inc/shared-app-logic'

import {
  // For future use...
  // CLEAR_LOCATION_DATA_BY_KEYS,
  // SET_ONSITE_LOCATION,
  // SET_LOCATION_ADDRESS,
  CLEAR_FULL_LOCATION_OBJ,
  SET_ADDRESS_DROPDOWN_OPTION,
  SET_ADDRESS_OPTION,
  SET_FULL_LOCATION_OBJ,
  SET_LOCATION_GEO_DATA,
  SET_LOCATION_TIMEZONE,
} from './locationMatcherActions'

const initialState = fromJS(initialLocationObject)

export default createReducer(initialState, {
  [CLEAR_FULL_LOCATION_OBJ]: ($$state: $TSFixMe, _action: $TSFixMe) => {
    $$state = initialState
    return $$state
  },
  [SET_FULL_LOCATION_OBJ]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.merge(fromJS(action.payload))
    return $$state
  },
  [SET_LOCATION_GEO_DATA]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.mergeIn(['geo'], action.payload)
    return $$state
  },
  [SET_ADDRESS_OPTION]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('selectedAddressOption', action.payload)
    return $$state
  },
  [SET_ADDRESS_DROPDOWN_OPTION]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('selectedDropdownValue', action.payload)
    return $$state
  },
  [SET_LOCATION_TIMEZONE]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('timeZoneId', action.payload)
    return $$state
  },
  // For future use...
  // [CLEAR_LOCATION_DATA_BY_KEYS]: ($$state, action) => {
  //   for (let k = 0; k < action.payload.length; k++) {
  //     $$state = $$state.set(action.payload[k], initialState.get(action.payload[k]))
  //   }
  //   return $$state
  // },
  // [SET_ONSITE_LOCATION]: ($$state, action) => {
  //   $$state = $$state.set('onsiteName', action.payload)
  //   $$state = $$state.set('onsite', true)
  //   return $$state
  // },
  // [SET_LOCATION_ADDRESS]: ($$state, action) => {
  //   $$state = $$state.set('address', fromJS(action.payload))
  //   return $$state
  // }
})

import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { isSafari } from 'react-device-detect'
import { useSelector } from 'react-redux'

import styled from 'styled-components/native'

import { CMS_MICROSITE_MESSAGE } from '@lyrahealth-inc/shared-app-logic'
import { LoadingIndicator, tID, useGetIsMobileWebBrowser } from '@lyrahealth-inc/ui-core-crossplatform'

import { trackEventWithObj } from '../../../data/mixpanel'
import { useAppDispatch } from '../../../data/storeConfiguration/store'
import { getId } from '../../../data/user/userSelectors'
import { LYRA_HEALTH_NAME } from '../../constants/appConstants'

type CMSMicrositeIframeProps = {
  testId: string
  iFrameTitle: string
  micrositeUrl: string
  documentTitle: string
  messageHandlers: { [key in CMS_MICROSITE_MESSAGE]?: () => void }
}

const LoadingIndicatorContainer = styled.View(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing['32px'],
}))

const IFrameContainer = styled.View({
  flexGrow: 1,
  border: 0,
})

/** iFrame to any microsite set up through goodness/craft cms */
const CMSMicrositeIframe: FunctionComponent<CMSMicrositeIframeProps> = ({
  testId,
  iFrameTitle,
  micrositeUrl,
  documentTitle,
  messageHandlers,
}) => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useSelector(getId)
  const [isLoading, setIsLoading] = useState(true)
  const [iFrameHeight, setIFrameHeight] = useState<number>(0)
  /** Track url changes in order to reset scroll position */
  const [iframeUrl, setIframeUrl] = useState<string>('')
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const intervalRef = useRef(0)

  const HEADER_HEIGHT_WEB = 81
  const FOOTER_HEIGHT_WEB = isLoggedIn ? 119 : 137
  const HEADER_AND_FOOTER_HEIGHT_SAFARI_MWEB = 116
  const isMobileBrowser = useGetIsMobileWebBrowser() && !isSafari
  const FALLBACK_IFRAME_HEIGHT = 500
  const getApplicationContentHeight = useCallback(
    () =>
      isSafari
        ? window.innerHeight - HEADER_AND_FOOTER_HEIGHT_SAFARI_MWEB
        : window.innerHeight - HEADER_HEIGHT_WEB - FOOTER_HEIGHT_WEB,
    [FOOTER_HEIGHT_WEB],
  )
  const iFrameSrcUrl = micrositeUrl.startsWith('/') ? `${window.location.origin}${micrositeUrl}` : micrositeUrl
  document.title = `${documentTitle} | ${LYRA_HEALTH_NAME}`

  useEffect(() => {
    const handlePageShow = (event: { persisted: any }) => {
      if (event.persisted) {
        window.location.reload()
      }
    }
    window.addEventListener('pageshow', handlePageShow)
    return () => {
      window.removeEventListener('pageshow', handlePageShow)
    }
  }, [])

  useEffect(() => () => window.clearInterval(intervalRef.current), [])

  useEffect(() => {
    if (iframeUrl) {
      scroll(0, 0)
    }
  }, [iframeUrl])

  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      if (message.origin === window.location.origin) {
        const event = message.data.event
        const mixpanelData = message.data.mixPanelData
        const handleMessageForEvent = event && messageHandlers[event as CMS_MICROSITE_MESSAGE]
        if (handleMessageForEvent) {
          handleMessageForEvent()
        }
        if (mixpanelData && mixpanelData.event) {
          dispatch(trackEventWithObj(mixpanelData))
        }
      }
    }
    window.addEventListener('message', handleMessage, false)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [dispatch, messageHandlers])

  const onIFrameLoad = useCallback(() => {
    setIsLoading(false)
    const contentWindow = iFrameRef?.current?.contentWindow

    if (contentWindow) {
      const setUrlAndHeight = () => {
        let documentUrl = ''
        let height = FALLBACK_IFRAME_HEIGHT
        try {
          documentUrl = contentWindow.document.URL
          height = isMobileBrowser ? contentWindow.document?.body.scrollHeight : getApplicationContentHeight()
        } catch (e) {
          console.warn('Could not access iFrame window', e)
        }
        setIframeUrl(documentUrl)
        setIFrameHeight(height)
      }
      setUrlAndHeight()

      // Watch iframe url so we can scroll to the top on page change.
      // Also watch the iframe content height to set the height of the iframe on page change.
      intervalRef.current = window.setInterval(setUrlAndHeight, 500)
    }
  }, [getApplicationContentHeight, isMobileBrowser])

  return (
    <>
      {isLoading && (
        <LoadingIndicatorContainer>
          <LoadingIndicator size={45} />
        </LoadingIndicatorContainer>
      )}
      <IFrameContainer testID={tID(testId)}>
        <iframe
          title={iFrameTitle}
          onLoad={onIFrameLoad}
          ref={iFrameRef}
          src={iFrameSrcUrl}
          width='100%'
          height={iFrameHeight}
          style={{ border: 'none', flexGrow: 1 }}
        />
      </IFrameContainer>
    </>
  )
}

export default CMSMicrositeIframe

/* eslint-disable formatjs/no-literal-string-in-jsx */
import React from 'react'
import { CareNavigatorEmailLink } from '../../../atoms'

export const StarbucksEligibilityErrorMessage: React.FC<{}> = () => {
  return (
    <>
      Looks like we can’t confirm the information you shared. Double-check the Starbucks partner number, or contact{' '}
      <CareNavigatorEmailLink /> for help.
    </>
  )
}

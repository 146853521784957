// @intl project:provider-profile

import { defineMessages, IntlShape } from 'react-intl'

import { zonedTimeToUtc } from 'date-fns-tz'
import { compact, isEmpty, startCase, trim } from 'lodash-es'

import {
  PROVIDER_CONDITIONS,
  PROVIDER_CREDENTIALS,
  PROVIDER_SENSITIVITIES,
  ProviderTypesPluralEnum,
  REGISTERED_ACSW_CREDENTIAL,
} from './constants'
import {
  PROGRAM_TAXONOMY as PROGRAM_TAXONOMY_CONSTANTS,
  PROGRAMS,
} from '../../features/common/constants/customerProgram'
import { TREATMENT_RECOMMENDATION_TIER } from '../../features/common/constants/treatmentRecommendationsConstants'
import {
  Address,
  Appointment,
  APPOINTMENT_STATUS,
  CredentialParts,
  DistanceToProviderValue,
  DistanceUnitAbbreviation,
  Episode,
  EpisodeStates,
  MEETING_FORMATS,
  PreferredContactInfo,
  ProgramNames,
  Provider,
  PROVIDER_TYPES,
  ProviderEnums,
  ProviderExplainClinicalMatch,
  ProviderInfo,
  ProviderProgramTaxonomy,
  ProviderType,
  TreatmentModeEnums,
} from '../../models'
import { TREATMENT_MODE } from '../common/constants/appointmentModalityConstants'
import { TREATMENT_OPTIONS, TREATMENT_OPTIONS_TYPE } from '../common/constants/treatmentOptions'
import { is24HoursInTheFuture } from '../common/utils/dateUtils'
import { MEMBER_PREFERENCE_BE_TO_ID_MAPPING } from '../memberPreferences/constants'
import { getBCProgramNameFromSelectedTreatmentOption } from '../searchForCare/utils'

/**
 * Finds a list of BCProviders given a list of providers and episodes
 * This will check if the providers in the list are blended care and map
 * episodes with providers to ensure the provider is a valid BCx type
 */
export const getBCProviders = (providers: Provider[], episodes: Episode[]): Provider[] => {
  return providers
    .filter((provider) => isProviderBlendedCareType({ provider: provider.provider }))
    .filter((provider) => !!episodes.find((episode) => episode.provider_id === provider.provider.lyra_id))
}

/**
 * With a provider, check that a provider is a blended care type
 * @deprecated, please use `isBlendedCareProvider` going forward
 */
export const isProviderBlendedCareType = ({ provider }: { provider: ProviderInfo }): boolean => {
  return provider && provider.program_taxonomy
    ? isBlendedCareProvider({ programTaxonomy: provider.program_taxonomy })
    : false
}

/**
 * Helper function to help filter a list of providers that match a list of types
 */
export const isProviderAnyTypeOf = ({
  provider,
  providerTypes = [],
}: {
  provider: ProviderInfo
  providerTypes: ProviderType[]
}): boolean => {
  return providerTypes.some((providerType) => {
    return (
      provider &&
      (provider.lyra_type ?? PROVIDER_TYPES[ProviderEnums.PROVIDER].lyraType).toLowerCase() === providerType.lyraType
    )
  })
}

// Util function achieving same functionality as above, but directly intakes and compares the providerType
export const isProviderLyraTypeAnyTypeOf = ({
  providerType,
  providerTypes = [],
}: {
  providerType?: ProviderType
  providerTypes: ProviderType[]
}) => {
  if (!providerType) return false
  return providerTypes.some((type) => {
    return providerType.lyraType.toLowerCase() === type.lyraType.toLowerCase()
  })
}

export const isDAProviderOfferingTherapy = ({
  programTaxonomy = [],
}: {
  programTaxonomy: ProviderProgramTaxonomy[]
}) => {
  return programTaxonomy
    ? programTaxonomy.some(
        (pt) =>
          pt.treatment === PROGRAM_TAXONOMY_CONSTANTS.treatment.therapy &&
          pt.partner === PROGRAM_TAXONOMY_CONSTANTS.partner.directAccess,
      )
    : false
}

export const isDAProviderOfferingMeds = ({ programTaxonomy = [] }: { programTaxonomy: ProviderProgramTaxonomy[] }) => {
  return programTaxonomy
    ? programTaxonomy.some(
        (pt) =>
          pt.treatment === PROGRAM_TAXONOMY_CONSTANTS.treatment.medicationManagement &&
          pt.partner === PROGRAM_TAXONOMY_CONSTANTS.partner.directAccess,
      )
    : false
}

export const isBlendedCareProvider = ({ programTaxonomy = [] }: { programTaxonomy: ProviderProgramTaxonomy[] }) => {
  return programTaxonomy
    ? programTaxonomy.some(
        (taxonomy: ProviderProgramTaxonomy) => taxonomy.partner === PROGRAM_TAXONOMY_CONSTANTS.partner.blendedCare,
      )
    : false
}

export const isBCTFellowshipProvider = ({ programTaxonomy = [] }: { programTaxonomy: ProviderProgramTaxonomy[] }) => {
  return programTaxonomy
    ? programTaxonomy.some((pt) => pt?.offering === PROGRAM_TAXONOMY_CONSTANTS.offering.fellowship)
    : false
}

export const isBCTProvider = ({ programTaxonomy = [] }: { programTaxonomy: ProviderProgramTaxonomy[] }) => {
  if (!programTaxonomy || !isBlendedCareProvider({ programTaxonomy })) {
    return false
  }
  return programTaxonomy.some((pt) => pt.treatment === PROGRAM_TAXONOMY_CONSTANTS.treatment.therapy)
}

export const isBCCProvider = ({ programTaxonomy = [] }: { programTaxonomy: ProviderProgramTaxonomy[] }) => {
  if (!programTaxonomy || !isBlendedCareProvider({ programTaxonomy })) {
    return false
  }
  return programTaxonomy.some((pt) => pt.treatment === PROGRAM_TAXONOMY_CONSTANTS.treatment.coaching)
}

export const isBCMProvider = ({ programTaxonomy = [] }: { programTaxonomy: ProviderProgramTaxonomy[] }) => {
  if (!programTaxonomy || !isBlendedCareProvider({ programTaxonomy })) {
    return false
  }
  return programTaxonomy.some((pt) => pt.treatment === PROGRAM_TAXONOMY_CONSTANTS.treatment.medicationManagement)
}

export const isCLEProvider = ({ programTaxonomy = [] }: { programTaxonomy: ProviderProgramTaxonomy[] }) => {
  return programTaxonomy
    ? programTaxonomy.some((pt) => pt.offering === PROGRAM_TAXONOMY_CONSTANTS.offering.clinicalLeave)
    : false
}

export const isICASCounsellor = ({ programTaxonomy = [] }: { programTaxonomy: ProviderProgramTaxonomy[] }) => {
  return programTaxonomy ? programTaxonomy.some((pt) => pt?.partner === PROGRAM_TAXONOMY_CONSTANTS.partner.icas) : false
}

export const isAppleOnsiteProvider = ({ programTaxonomy = [] }: { programTaxonomy?: ProviderProgramTaxonomy[] }) => {
  return programTaxonomy
    ? programTaxonomy.some(
        (pt) => pt?.treatment === PROGRAM_TAXONOMY_CONSTANTS.treatment.therapy && pt?.partner === 'Apple',
      )
    : false
}

/**
 * Helper to get the episode current session count with a provider
 * @param {*} providerId string
 * @param {*} episodeId string
 * @param {*} providers Providers
 * @returns number | string | undefined
 */
export const getProviderSessionCounts = (
  providerId: string,
  episodeId: string | null,
  providers: Provider[],
): number => {
  const provider = providers.find((provider) => provider.provider.lyra_id === providerId)
  return episodeId && provider && provider.provider.episodeSessionCounts
    ? provider.provider.episodeSessionCounts[episodeId]
    : 0
}

export const getProviderSupportsInPerson = ({ treatmentMode }: { treatmentMode: TreatmentModeEnums }) => {
  const inPersonTreatments = [
    TreatmentModeEnums.ONLY_INPERSON,
    TreatmentModeEnums.BOTH_VIDEO_INPERSON,
    TreatmentModeEnums.INPERSON_PHONE_VIDEO,
    TreatmentModeEnums.BOTH_PHONE_INPERSON,
  ]
  return inPersonTreatments.some((inPersonTreatment) => inPersonTreatment === treatmentMode)
}

// Duplicate in nature to `getProviderSupportsInPerson`.
// TODO: deprecate that aforementioned function since this relies directly on what Arbiter tells us.
export const getProviderSupportsInPersonModality = ({ provider }: { provider: ProviderInfo }) => {
  return provider.sessionFormat?.isInPerson || false
}

export const getProviderSupportsVideo = ({ provider }: { provider: ProviderInfo }) => {
  return provider.sessionFormat?.isVideo || false
}

export const getProviderSupportsLiveMessaging = ({ provider }: { provider?: ProviderInfo }) => {
  return provider?.sessionFormat?.isLiveMessaging ?? false
}

export const getProviderShouldDisplayLiveMessagingModality = ({
  providerSupportsLiveMessaging,
  shownTierPage,
  outsideOnboarding = false,
}: {
  providerSupportsLiveMessaging: boolean
  shownTierPage?: TREATMENT_RECOMMENDATION_TIER
  outsideOnboarding?: boolean
}) => {
  return providerSupportsLiveMessaging && (shownTierPage === TREATMENT_RECOMMENDATION_TIER.TIER_1 || outsideOnboarding)
}

export const getShowMeetingFormatField = ({
  provider,
  liveMessagingEnabled,
  selectedTreatmentOption,
  outsideOnboarding = false,
  shownTierPage,
}: {
  provider?: ProviderInfo
  liveMessagingEnabled: boolean
  selectedTreatmentOption?: TREATMENT_OPTIONS_TYPE | null
  outsideOnboarding?: boolean
  shownTierPage?: TREATMENT_RECOMMENDATION_TIER
}) => {
  if (!provider) {
    return false
  }

  const isProviderCoach = provider
    ? isProviderAnyTypeOf({ provider, providerTypes: [PROVIDER_TYPES[ProviderEnums.COACH]] })
    : false

  const showMeetingFormatFieldForCoach =
    isProviderCoach &&
    liveMessagingEnabled &&
    selectedTreatmentOption === TREATMENT_OPTIONS.COACHING &&
    (outsideOnboarding || shownTierPage === TREATMENT_RECOMMENDATION_TIER.TIER_1)

  const isBlendedCareProvider = provider ? isProviderBlendedCareType({ provider }) : false
  const supportedVisitTypes = provider?.supportedVisitTypes
  const showMeetingFormatField =
    supportedVisitTypes !== TREATMENT_MODE.ONLY_INPERSON &&
    supportedVisitTypes !== TREATMENT_MODE.ONLY_VIDEO &&
    supportedVisitTypes !== TREATMENT_MODE.NONE &&
    (!isBlendedCareProvider || showMeetingFormatFieldForCoach)

  return showMeetingFormatField
}

/**
 * Function that returns the matches between provider selected preferences to the selected user member preferences
 */
export const getProviderSupportedPreferenceValues = (
  providerSelectedPreferences: string[],
  userSelectedPreferences: string[],
) => {
  // Map the provider preferences to its ID
  const mappedPreferenceValues = providerSelectedPreferences.map((value) => MEMBER_PREFERENCE_BE_TO_ID_MAPPING[value])
  // Map the user selected preferences to the values received from BE
  return userSelectedPreferences.filter((preferenceID) => mappedPreferenceValues.includes(preferenceID))
}

/**
 * Function to get the meeting format off the provider object if one is not already passed in
 */
export const getMeetingFormat = ({
  meetingFormat,
  provider,
  liveMessagingEnabled,
}: {
  meetingFormat?: MEETING_FORMATS | null
  provider: ProviderInfo
  liveMessagingEnabled: boolean
}) => {
  if (
    meetingFormat &&
    (meetingFormat === MEETING_FORMATS.NONE_SPECIFIED || meetingFormat === MEETING_FORMATS.VIDEO_AND_LIVE_MESSAGING)
  ) {
    if (liveMessagingEnabled) {
      return null
    } else {
      return MEETING_FORMATS.VIDEO
    }
  } else if (meetingFormat) {
    return meetingFormat
  } else if (liveMessagingEnabled) {
    return null
  } else if (provider.supportedVisitTypes === TREATMENT_MODE.ONLY_INPERSON) {
    return MEETING_FORMATS.IN_PERSON
  } else if (provider.supportedVisitTypes === TREATMENT_MODE.ONLY_VIDEO) {
    return MEETING_FORMATS.VIDEO
  } else if (isProviderBlendedCareType({ provider }) && provider.supportedVisitTypes.toLowerCase().includes('video')) {
    return MEETING_FORMATS.VIDEO
  } else {
    return null
  }
}

const getFirstMatchInParentheses = (str: string) => {
  // Returns the first match between parentheses or empty string
  // Ex: "Licensed Marriage and Family Therapist (LMFT)" => "LMFT"
  const matches = str.match(/\(([^)]+)\)/) ?? []

  // matches[0] = entire matched string. e.g. "(LMFT)"
  // matches[1] = first matched *capture-group* (text inside [non-backslash-escaped] parens, EXCLUDING those parens)
  // e.g. matches[1] = "LMFT"
  return (matches[1] ?? '').toUpperCase()
}

/*
 * Returns the explanatory copy that is tied to a specific provider credential.
 * If the credential does not have an explicit mapping, it falls back to the default for the provider type
 */
export const getCredentialExplanation = (
  { formatMessage }: IntlShape,
  provider: ProviderInfo,
  credential: string,
): string => {
  const cred = getFirstMatchInParentheses(credential)

  const CREDENTIAL_EXPLANATIONS = defineMessages({
    APRN: {
      defaultMessage:
        'Masters-level nurse practitioner who can provide evaluation and management of medications for behavioral health conditions.',
      description: "Provider Profile: Contextual copy for provider's credential: APRN",
    },
    LCSW: {
      defaultMessage:
        'Mental health professional with a master’s in social work. Lyra’s LCSWs are trained and licensed to provide therapy to individuals, couples, and families using evidence-based approaches.',
      description: "Provider Profile: Contextual copy for provider's credential: LSCW",
    },
    LMFT: {
      defaultMessage:
        'Mental health professional with a master’s in marriage and family therapy. Lyra’s LMFTs are trained and licensed to provide therapy to individuals, couples, and families using evidence-based approaches.',
      description: "Provider Profile: Contextual copy for provider's credential: LMFT",
    },
    MD: {
      defaultMessage:
        'Medical doctor who can provide evaluation and management of medications for behavioral health conditions.',
      description: "Provider Profile: Contextual copy for provider's credential: MD",
    },
    PHD: {
      defaultMessage:
        'Mental health professional with a doctorate in psychology, trained and licensed to provide therapy using a variety of approaches.',
      description: "Provider Profile: Contextual copy for provider's credential: PhD",
    },
    PSYD: {
      defaultMessage:
        'Mental health professional with a doctorate in psychology, trained and licensed to provide therapy using a variety of approaches.',
      description: "Provider Profile: Contextual copy for provider's credential: PsyD",
    },
    'REGISTERED ACSW': {
      defaultMessage:
        'ACSWs are completing their licensure in the Lyra Clinical Fellowship program. Each provider has 900+ hours of supervised clinical experience.',
      description: "Provider Profile: Contextual copy for provider's credential: Registered ACSW",
    },
  })

  if (CREDENTIAL_EXPLANATIONS[cred]) {
    return formatMessage(CREDENTIAL_EXPLANATIONS[cred])
  }

  /*
   * Known credentials not listed above, fall back to generic context by provider type
   * CMHC
   * CPC
   * LCPC
   * LICSW
   * LMHC
   * LPC
   * LPC-MHSP
   * LPCC
   */
  if (isProviderAnyTypeOf({ provider, providerTypes: [PROVIDER_TYPES[ProviderEnums.COACH]] })) {
    return formatMessage({
      defaultMessage:
        'Lyra’s mental health coaches draw on principles and practices from evidence-based therapies such as cognitive behavioral therapy (CBT), acceptance and commitment therapy (ACT), and dialectical behavior therapy (DBT).',
      description:
        "Provider Profile: Contextual copy for provider's credential: Generic message for mental health coach",
    })
  } else if (
    isProviderAnyTypeOf({
      provider,
      providerTypes: [PROVIDER_TYPES[ProviderEnums.PSYCHIATRIST], PROVIDER_TYPES[ProviderEnums.LYRAPRESCRIBER]],
    })
  ) {
    return formatMessage({
      defaultMessage:
        'Medical professional who can provide evaluation and management of medications for behavioral health conditions.',
      description:
        "Provider Profile: Contextual copy for provider's credential: Generic message for psychiatrist or physician",
    })
  } else {
    return formatMessage({
      defaultMessage:
        'Mental health professional trained and licensed to provide therapy using a variety of approaches. Depending on the provider, may work with adults, children, couples, or families.',
      description:
        "Provider Profile: Contextual copy for provider's credential: Generic message for mental health professional",
    })
  }
}

export const getLocalizedCredentialTitle = ({ formatMessage }: IntlShape, credential: string): string => {
  if (PROVIDER_CREDENTIALS[credential]) {
    return formatMessage(PROVIDER_CREDENTIALS[credential])
  } else {
    return credential
  }
}

export const getTypeConstantForProvider = (provider: ProviderInfo) => {
  return Object.values(PROVIDER_TYPES).find((providerTypeObject) => {
    const lyraType = provider.lyra_type ? provider.lyra_type : PROVIDER_TYPES[ProviderEnums.PROVIDER].lyraType
    return providerTypeObject.lyraType === lyraType.toLowerCase()
  })
}

export const getProviderTitle = ({ formatMessage }: IntlShape, provider: ProviderInfo): string => {
  const providerType = getTypeConstantForProvider(provider)
  return formatMessage(providerType?.displayTitle ?? PROVIDER_TYPES.PROVIDER.displayTitle)
}

export const shouldRenderTitleAndEducation = (provider: ProviderInfo): boolean => {
  return provider.credentials ? !provider.credentials.includes(REGISTERED_ACSW_CREDENTIAL) : false
}

export const getProviderFullCredentials = (intl: IntlShape, provider: ProviderInfo): CredentialParts[] => {
  return provider.credentials
    ? provider.credentials.map((credentialTitle, idx) => ({
        credential: getLocalizedCredentialTitle(intl, credentialTitle),
        education: shouldRenderTitleAndEducation(provider) ? (provider.education[idx] ?? {}).school : undefined,
        context: getCredentialExplanation(intl, provider, credentialTitle),
      }))
    : []
}

export const formatAddressForDisplay = (address?: Address) => {
  if (address === undefined) {
    return ''
  }

  let addressLine1 = ''
  let addressLine2 = ''

  addressLine1 = `${address.street1}`.concat(trim(address.street2) ? `, ${address.street2}` : '')
  addressLine2 = `${address.city}, ${address.state} ${address.zipcode ?? address.zip}`
  return `${addressLine1}, ${addressLine2}`
}

export const getDoAllProvidersSpeakCareLanguage = ({
  providers,
  careLanguage,
}: {
  providers: ProviderInfo[]
  careLanguage: string
}) => {
  return providers.every((provider: ProviderInfo) => {
    const speaksCareLanguage = provider.languages_bcp47?.find((language) => language?.tag === careLanguage)
    return speaksCareLanguage
  })
}

export const getProviderTypeDisplay = ({
  selectedTreatmentOption,
}: {
  selectedTreatmentOption?: TREATMENT_OPTIONS_TYPE | null
}) => {
  switch (selectedTreatmentOption) {
    case TREATMENT_OPTIONS.COACHING:
      return ProviderTypesPluralEnum.COACHES
    case TREATMENT_OPTIONS.INDIVIDUAL_THERAPY:
    case TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD:
    case TREATMENT_OPTIONS.COUPLES_THERAPY:
    case TREATMENT_OPTIONS.FAMILY_THERAPY:
    case TREATMENT_OPTIONS.FAMILY_THERAPY_CHILD:
    case TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_ICAS_PHONE:
    case TREATMENT_OPTIONS.ALCOHOL_USE_DISORDER:
      return ProviderTypesPluralEnum.THERAPISTS
    case TREATMENT_OPTIONS.BLENDED_CARE_MEDS:
    case TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS:
    case TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS_CHILD:
    case TREATMENT_OPTIONS.MEDICATION_CONSULTATION:
    case TREATMENT_OPTIONS.CONTROLLED_MEDICATION:
      return ProviderTypesPluralEnum.PRESCRIBERS
    default:
      return ProviderTypesPluralEnum.THERAPISTS
  }
}

export const getTreatmentOptionBasedOnProviderCapacity = ({
  selectedTreatmentOption,
  provider,
}: {
  selectedTreatmentOption?: TREATMENT_OPTIONS_TYPE
  provider: ProviderInfo
}) => {
  const providerCapacity = provider.providerCapacity
  const providerCapacityKeys = Object.keys(providerCapacity || {})
  const providerHasSingleCapacity = providerCapacityKeys?.length === 1
  return selectedTreatmentOption
    ? selectedTreatmentOption
    : providerHasSingleCapacity
    ? (providerCapacityKeys[0] as TREATMENT_OPTIONS_TYPE)
    : undefined
}

export const getProgramFromSelectedTreatmentOptionAndProviderCapacity = (
  selectedTreatmentOption?: string,
  providerCapacity?: { [key: string]: number },
) => {
  if (isEmpty(providerCapacity)) {
    return null
  }
  const capacities = Object.keys(providerCapacity).map((programName) => [programName, providerCapacity[programName]])
  for (const capacity of capacities) {
    const capacityName = capacity[0] as string
    if (
      selectedTreatmentOption === capacityName ||
      PROGRAMS[capacityName]?.customerPropertyId === selectedTreatmentOption ||
      PROGRAMS[capacityName]?.treatmentOptionProperties?.associatedTriageTreatmentOptions.includes(
        selectedTreatmentOption,
      )
    ) {
      return PROGRAMS[capacityName]
    }
  }
  return null
}

export const getConflictingEpisodeForProvider = ({
  currentEpisodes,
  selectedTreatmentOption,
  provider,
}: {
  currentEpisodes: Episode[]
  selectedTreatmentOption?: TREATMENT_OPTIONS_TYPE
  provider: ProviderInfo
}) => {
  return currentEpisodes.find((episode: Episode) => {
    const programFromTreatmentOptionAndProviderCapacity = getProgramFromSelectedTreatmentOptionAndProviderCapacity(
      selectedTreatmentOption,
      provider.providerCapacity,
    )
    if (
      programFromTreatmentOptionAndProviderCapacity &&
      episode.program_id !== programFromTreatmentOptionAndProviderCapacity?.blendedCareProgramId &&
      episode.provider_id !== provider.lyra_id
    ) {
      return false
    } else {
      if (
        episode?.program_name === ProgramNames.TeensTherapy ||
        episode?.program_name === ProgramNames.MedicationManagement
      ) {
        const matchingProgram = PROGRAMS[episode?.program_name]?.associatedProviderTypes?.includes(
          provider.lyra_type.toLowerCase(),
        )
        return matchingProgram
      } else {
        return false
      }
    }
  })
}

export const getExistingSingleSessionCoachingEpisode = ({ currentEpisodes }: { currentEpisodes: Episode[] }) => {
  return currentEpisodes.find((episode: Episode) => episode.program_name === 'SingleSessionCoaching')
}

export enum ContactType {
  EMAIL = 'email',
  PHONE = 'phone',
  BOTH = 'both',
}

// Determines if preferred contact type for a provider is email, phone or both
export const getProviderPreferredContactType = (email?: string, phone?: string) => {
  if (email && phone) {
    return ContactType.BOTH
  }
  if (email) {
    return ContactType.EMAIL
  } else {
    return ContactType.PHONE
  }
}

/**
 * Return the appropriate contact information for a given provider, based on their preferred contact methods.
 * We have no guarantee on how consistent the provider data will be, so the logic is somewhat complex.
 * @param ProviderInfo
 * @returns contact information
 */
export const getPreferredContactInfo = (provider?: ProviderInfo): PreferredContactInfo => {
  const PHONE_CONTACT = 'phone'
  const EMAIL_CONTACT = 'email'

  if (!provider) {
    return { [PHONE_CONTACT]: undefined, [EMAIL_CONTACT]: undefined }
  }

  /* Compile all possible contact information for a provider.
   * Some providers have contact information for an intake representative - this should be prioritized over provider
   * contact data even when not consistently inputted.
   */
  const allContactInformation = {
    [PHONE_CONTACT]: provider[`intake_representative_${PHONE_CONTACT}`] ?? provider[PHONE_CONTACT] ?? '',
    [EMAIL_CONTACT]: provider[`intake_representative_${EMAIL_CONTACT}`] ?? provider[EMAIL_CONTACT] ?? '',
  }

  /* Determine what the correct contact methods for a given provider are.
   * Any explicit intake representative contact methods supersede a provider's contact methods.
   */
  const intakeRepresentativeContactMethods = provider.intake_representative_contact ?? []
  const providerDirectContactMethods = provider.contact_methods ?? []
  const contactMethods = (
    intakeRepresentativeContactMethods.length === 0 ? providerDirectContactMethods : intakeRepresentativeContactMethods
  )
    ?.map((method) => {
      // Standardize in case of multiple contact methods in one string or mixed casing
      // The split results in an array of arrays, so we have to flatten it afterwards via reduce.
      return method?.toLowerCase()?.split(/\b/)
    })
    .reduce((accumulator, current) => {
      return accumulator.concat(current)
    }, [])

  /* There is no data standardization for a provider's contact methods, e.g. we can expect anything from "phone" to
   * "email" to ["phone", "email"] to "both" to "either / no preference" to no data at all.
   * Thus, display all possible contact methods if neither phone nor email are specified.
   */
  if (!contactMethods.includes(PHONE_CONTACT) && !contactMethods.includes(EMAIL_CONTACT)) {
    return allContactInformation
  } else {
    /* This means that at least one of (phone or email) is specified, so iterate through the listed contact methods
     * and only add the contact information that corresponds to the specified methods.
     */
    const contactInformation = {}
    contactMethods.forEach((method) => {
      if (Object.keys(allContactInformation).includes(method)) {
        contactInformation[method] = allContactInformation[method]
      }
    })
    return contactInformation
  }
}

/** Function that returns whether a program has the ability to reschedule an appointment based on the program name. */
const canCustomerProgramRescheduleAppointment = ({ programName }: { programName: string }): boolean => {
  return PROGRAMS[programName]?.canRescheduleAppointment ?? false
}

/** Function that returns whether or not a valid program name exists and if the provider capacity is
 *  within a certain range.
 */
const doesProviderCapacityFitInGivenRange = ({
  providerCapacity,
  minimumCapacity,
  maximumCapacity,
  selectedTreatmentOption,
}: {
  providerCapacity: { [treatmentOption in string]: number }
  minimumCapacity: number
  maximumCapacity: number
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}) => {
  const programName = getBCProgramNameFromSelectedTreatmentOption({ selectedTreatmentOption })
  return (
    programName &&
    providerCapacity?.[programName] >= minimumCapacity &&
    providerCapacity?.[programName] <= maximumCapacity
  )
}

/** Function that determines whether a provider is BCT and has a capacity that fits within the given range */
const doesBCTProviderCapacityFitInGivenRange = ({
  programTaxonomy,
  providerCapacity,
  minimumCapacity,
  maximumCapacity,
  selectedTreatmentOption,
}: {
  programTaxonomy: ProviderProgramTaxonomy[]
  providerCapacity: { [treatmentOption in string]: number }
  minimumCapacity: number
  maximumCapacity: number
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}) => {
  return (
    isBCTProvider({ programTaxonomy }) &&
    doesProviderCapacityFitInGivenRange({
      providerCapacity: providerCapacity,
      minimumCapacity,
      maximumCapacity,
      selectedTreatmentOption,
    })
  )
}

/** Function that determines whether a provider is BCM and has a capacity that fits within the given range */
const doesBCMProviderCapacityFitInGivenRange = ({
  programTaxonomy,
  providerCapacity,
  minimumCapacity,
  maximumCapacity,
  selectedTreatmentOption,
}: {
  programTaxonomy: ProviderProgramTaxonomy[]
  providerCapacity: { [treatmentOption in string]: number }
  minimumCapacity: number
  maximumCapacity: number
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}) => {
  return (
    isBCMProvider({ programTaxonomy }) &&
    doesProviderCapacityFitInGivenRange({
      providerCapacity: providerCapacity,
      minimumCapacity,
      maximumCapacity,
      selectedTreatmentOption,
    })
  )
}

/** Function that determines whether a provider is BCC and has a capacity that fits within the given range */
const doesBCCProviderCapacityFitInGivenRange = ({
  programTaxonomy,
  providerCapacity,
  minimumCapacity,
  maximumCapacity,
  selectedTreatmentOption,
}: {
  programTaxonomy: ProviderProgramTaxonomy[]
  providerCapacity: { [treatmentOption in string]: number }
  minimumCapacity: number
  maximumCapacity: number
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}) => {
  return (
    isBCCProvider({ programTaxonomy }) &&
    doesProviderCapacityFitInGivenRange({
      providerCapacity: providerCapacity,
      minimumCapacity,
      maximumCapacity,
      selectedTreatmentOption,
    })
  )
}

/**
 * Function to decide whether to render the SaveYourSpot section in the provider profile
 *
 * As of PROSPECT-3495, do not show SaveYourSpot section if the provider:
 * has no provider capacity saved
 * is not any of BCC/BCT/BCM
 * is BCM but has 3 or more spots left
 */
export const shouldRenderSaveYourSpotSection = ({
  programTaxonomy,
  providerCapacity,
  selectedTreatmentOption,
}: {
  programTaxonomy?: ProviderProgramTaxonomy[]
  providerCapacity?: { [treatmentOption in string]: number }
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}): boolean => {
  if (!programTaxonomy || !providerCapacity) {
    return false
  }

  return (
    doesBCMProviderCapacityFitInGivenRange({
      programTaxonomy,
      providerCapacity,
      minimumCapacity: 1,
      maximumCapacity: 2,
      selectedTreatmentOption,
    }) ||
    isBCCProvider({ programTaxonomy }) ||
    isBCTProvider({ programTaxonomy })
  )
}

/**
 * Function that determines whether or not to show that the current provider has limited
 * availability under the SaveYourSpot section in the provider profile.
 *
 * As of PROSPECT-3114, when a BCM/BCC/BCT provider has 1-2 spots left, show text indicating
 * the provider is frequently booked
 */
export const shouldShowPopularProviderText = ({
  programTaxonomy,
  providerCapacity,
  selectedTreatmentOption,
}: {
  programTaxonomy?: ProviderProgramTaxonomy[]
  providerCapacity?: { [treatmentOption in string]: number }
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}) => {
  if (!programTaxonomy || !providerCapacity) {
    return false
  }
  return (
    doesBCTProviderCapacityFitInGivenRange({
      programTaxonomy,
      providerCapacity,
      minimumCapacity: 1,
      maximumCapacity: 2,
      selectedTreatmentOption,
    }) ||
    doesBCCProviderCapacityFitInGivenRange({
      programTaxonomy,
      providerCapacity,
      minimumCapacity: 1,
      maximumCapacity: 2,
      selectedTreatmentOption,
    }) ||
    doesBCMProviderCapacityFitInGivenRange({
      programTaxonomy,
      providerCapacity,
      minimumCapacity: 1,
      maximumCapacity: 2,
      selectedTreatmentOption,
    })
  )
}

/**
 * Function that determines whether or not to show that the user has the opportunity to
 * adjust their appointments under the SaveYourSpot section in the provider profile
 * based on the program type
 */
export const shouldShowFlexibleReschedulingText = ({
  selectedTreatmentOption,
}: {
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}) => {
  const programName = getBCProgramNameFromSelectedTreatmentOption({ selectedTreatmentOption })
  return !isEmpty(programName) && canCustomerProgramRescheduleAppointment({ programName })
}

/**
 * Function that determines whether or not to show a prompting copy around a
 * given provider’s calendar filling up fast
 *
 * As of PROSPECT-3495, only show this text for BCT providers with 3+ availability
 */
export const shouldShowFillsUpFastText = ({
  programTaxonomy,
  providerCapacity,
  selectedTreatmentOption,
}: {
  programTaxonomy?: ProviderProgramTaxonomy[]
  providerCapacity?: { [treatmentOption in string]: number }
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}) => {
  if (!programTaxonomy || !providerCapacity) {
    return false
  }
  return doesBCTProviderCapacityFitInGivenRange({
    programTaxonomy,
    providerCapacity,
    minimumCapacity: 3,
    maximumCapacity: Number.POSITIVE_INFINITY,
    selectedTreatmentOption,
  })
}

/**
 * Function that returns the value of the treatment option given the provider's program taxonomy.
 * Intended to be used in conjunction with shouldRenderSaveYourSpotSection.
 */
export const getInferredLCXTreatmentOption = ({ programTaxonomy }: { programTaxonomy?: ProviderProgramTaxonomy[] }) => {
  if (isEmpty(programTaxonomy) || !programTaxonomy) {
    return null
  }
  if (isBCTProvider({ programTaxonomy })) {
    return TREATMENT_OPTIONS.INDIVIDUAL_THERAPY
  }

  if (isBCCProvider({ programTaxonomy })) {
    return TREATMENT_OPTIONS.COACHING
  }

  if (isBCMProvider({ programTaxonomy })) {
    return TREATMENT_OPTIONS.BLENDED_CARE_MEDS
  }
  return null
}

// Find a Provider that matches a given Provider ID and return a subset of their information.
export const getProviderDetailsForActivityIntroduction = (providers: Provider[], providerId: string) => {
  const selectedAssignmentProvider =
    providers.length > 0 && providers.find(({ provider }) => provider.lyra_id === providerId)
  return selectedAssignmentProvider
    ? {
        imageUrl: selectedAssignmentProvider.provider.lyraProviderImage.imageUrl,
        displayName: selectedAssignmentProvider.provider.display_name,
        firstName: selectedAssignmentProvider.provider.first_name,
        lyraType: selectedAssignmentProvider.provider.lyra_type,
      }
    : {}
}

// Find a Provider that matches a given Provider ID and return their First and Last name concatenated or an
// empty string
export const getProviderFirstAndLastName = (providers: Provider[], providerId: string | undefined) => {
  const selectedAssignmentProvider =
    providers.length > 0 && providerId && providers.find(({ provider }) => provider.lyra_id === providerId)

  return selectedAssignmentProvider
    ? `${selectedAssignmentProvider.provider.first_name} ${selectedAssignmentProvider.provider.last_name}`
    : ''
}

export const getLocalizedSpecializationsList = ({
  intl: { formatMessage, formatList },
  conditions = [],
  sensitivities = [],
}: {
  intl: IntlShape
  conditions?: string[]
  sensitivities?: string[]
}): string[] => {
  // Falls back to the plain text if the value is not declared in PROVIDER_CONDITIONS
  const formattedSpecializations = conditions.map((condition) =>
    PROVIDER_CONDITIONS[condition] ? formatMessage(PROVIDER_CONDITIONS[condition]) : condition,
  )

  if (sensitivities.length > 0) {
    const sensitivitiesList = formatList(
      sensitivities.map((sensitivity) =>
        PROVIDER_SENSITIVITIES[sensitivity] ? formatMessage(PROVIDER_SENSITIVITIES[sensitivity]) : sensitivity,
      ),
      { type: 'conjunction' },
    )
    formattedSpecializations.push(
      formatMessage(
        {
          defaultMessage: 'Experience with {sensitivitiesList} clients',
          description:
            'Provider Profile: Shown in a list of the provider\'s specializations. {sensitivitiesList} contains a list of sensitivities, e.g. "Experience with gay, lesbian, bisexual clients"',
        },
        {
          sensitivitiesList,
        },
      ),
    )
  }

  return formattedSpecializations.sort((a, b) => a.localeCompare(b))
}

export const getShouldDisplayBCTProviderBenefits = ({
  isCustomerDisplayingBCTBenefits = true,
  provider,
}: {
  isCustomerDisplayingBCTBenefits: boolean
  provider?: ProviderInfo
}) => {
  if (!provider || isEmpty(provider)) {
    return false
  }
  return (
    isCustomerDisplayingBCTBenefits &&
    isProviderAnyTypeOf({
      provider,
      providerTypes: [PROVIDER_TYPES[ProviderEnums.LYRATHERAPIST], PROVIDER_TYPES[ProviderEnums.LYRAFELLOW]],
    })
  )
}

export const hasInProgressBCxEpisode = ({ episodes, providers }: { episodes: Episode[]; providers: Provider[] }) => {
  const currentEpisodes = episodes.filter((episode) => episode.state === EpisodeStates.IN_PROGRESS)
  return currentEpisodes.some((currentEpisode) => {
    const episodeProvider = providers.find((provider) => provider.provider.lyra_id === currentEpisode.provider_id)
    return episodeProvider?.provider ? isProviderBlendedCareType({ provider: episodeProvider.provider }) : false
  })
}

export const canSeeDirectBookingLink = ({
  provider,
  episodes,
  appointments,
}: {
  provider: ProviderInfo
  episodes: Episode[]
  appointments: Appointment[]
}) => {
  if (
    !isProviderAnyTypeOf({
      provider,
      providerTypes: [
        PROVIDER_TYPES[ProviderEnums.LYRACLINICALLEAVEEVALUATOR],
        PROVIDER_TYPES[ProviderEnums.LYRAPRESCRIBER],
      ],
    })
  ) {
    return true
  }

  const activeEpisode = episodes.find((episode) => episode.state === EpisodeStates.IN_PROGRESS)
  if (!activeEpisode) {
    return true
  }

  return !appointments.some((appointment) => appointment.appointmentStatus !== APPOINTMENT_STATUS.CANCELED)
}

export const getActiveEpisode = ({ episodes, providerId }: { episodes: Episode[]; providerId?: string }) =>
  episodes.find((episode) => episode.provider_id === providerId && episode.state === EpisodeStates.IN_PROGRESS)

export const hasActiveEpisode = ({ episodes, providerId }: { episodes: Episode[]; providerId?: string }) =>
  episodes
    .filter((episode) => episode.provider_id === providerId)
    .some((episode) => episode.state === EpisodeStates.IN_PROGRESS)

export const getShouldShowPracticeContactInfo = ({
  isBlendedCareProvider,
  isUserInternational,
}: {
  isBlendedCareProvider: boolean
  isUserInternational: boolean
}) => {
  return !isBlendedCareProvider && !isUserInternational
}

export const getProviderBookingButtonText = ({
  intl,
  availabilityDate,
  providerTimeZone,
}: {
  intl: IntlShape
  /** ISO string representing datetime */
  availabilityDate: string
  providerTimeZone: string
}) => {
  const { formatMessage, formatDate } = intl
  // Create date object from iso string in user computer local time
  // Note - despite the method name, this is local time in the user device, NOT local time in the user's profile settings.
  const appointmentDateInLocalTime = zonedTimeToUtc(availabilityDate, providerTimeZone)

  // Intl.formatDate retrieves date (Month Day) user profile settings specified local time. E.g. May 1
  const appointmentDateInUserTimeZone = formatDate(appointmentDateInLocalTime, {
    month: 'short',
    day: 'numeric',
  })
  const currentDateInUserTimeZone = formatDate(Date.now(), {
    month: 'short',
    day: 'numeric',
  })
  const tomorrowDateInUserTimeZone = formatDate(Date.now() + 86400000, {
    month: 'short',
    day: 'numeric',
  })

  // Note that the below evaluates if the date is today or tomorrow in the time zone specified by the user in their profile settings.
  // What is considered today and tomorrow in user time zone may vary from the time zone (UTC) that was returned in provider availability
  // E.g. May 1 ?== May 2
  const isDateToday = appointmentDateInUserTimeZone === currentDateInUserTimeZone
  const isDateTomorrow = appointmentDateInUserTimeZone === tomorrowDateInUserTimeZone

  if (isDateToday || isDateTomorrow) {
    return formatMessage(
      {
        defaultMessage: '{todayOrTomorrow, select, today {Today} tomorrow {Tomorrow} other {today}}, {displayedDate}',
        description:
          'Text for button that redirects members straight to the appointment booking page and the appointment next available appointment is available today.',
      },
      {
        // e.g.: 7:00 PM
        displayedDate: formatDate(appointmentDateInLocalTime, {
          hour: 'numeric',
          minute: 'numeric',
        }),
        todayOrTomorrow: isDateToday ? 'today' : 'tomorrow',
      },
    )
  }
  // e.g.: Mon, Jan 12 at 3:00PM
  return formatDate(appointmentDateInLocalTime, {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    dayPeriod: 'short',
    weekday: 'short',
  })
}

export const shouldHighlightProviderAppt = ({
  shouldDisplayFastTrackBooking = false,
  appointmentDate,
  appointmentTime,
  timeZone,
  isBCProvider = false,
  shouldShowAvailabilityPreference = false,
  userAvailabilityPreference = null,
}: {
  shouldDisplayFastTrackBooking?: boolean
  appointmentDate: string
  appointmentTime: string
  timeZone: string
  isBCProvider?: boolean
  shouldShowAvailabilityPreference?: boolean | undefined
  userAvailabilityPreference?: string[] | null
}) => {
  // Highlight fast track booking when user does NOT have a valid availability preferece, i.e. when
  // they have specified valid date/times
  const hasValidAvailabilityPreference =
    shouldShowAvailabilityPreference &&
    userAvailabilityPreference &&
    userAvailabilityPreference.length > 0 &&
    !userAvailabilityPreference.includes('flexible')
  return (
    !hasValidAvailabilityPreference &&
    shouldDisplayFastTrackBooking &&
    is24HoursInTheFuture({
      startDate: appointmentDate,
      startTime: appointmentTime,
      timeZone,
    }) &&
    isBCProvider
  )
}

export const shouldDisplayFastTrackBooking = ({
  shouldDisplayFastTrackBookingFlag = false,
  provider,
  isTopRecommendedProvider = false,
}: {
  shouldDisplayFastTrackBookingFlag?: boolean
  provider: ProviderInfo
  isTopRecommendedProvider?: boolean
}) => {
  return shouldDisplayFastTrackBookingFlag && (isProviderBlendedCareType({ provider }) || isTopRecommendedProvider)
}

export const getProviderVideoLinks = ({
  providers,
  justIds = false,
}: {
  providers?: ProviderInfo[]
  justIds?: boolean
}) => {
  if (!providers?.length) {
    return []
  }

  const mappedProviders = providers.map((provider) => {
    const videoLink = provider.profileVideo?.videoLink

    if (!videoLink) {
      return
    }
    if (justIds) {
      const id = videoLink.split('/').pop()
      return id
    } else {
      return videoLink
    }
  })
  return compact(mappedProviders)
}

export const doesProviderHaveVideo = ({ provider }: { provider: ProviderInfo }) => {
  return getProviderVideoLinks({ providers: [provider] }).length > 0
}

export const makeProviderVideoDataForTracking = ({ providers }: { providers: ProviderInfo[] }) => {
  const providerVideoData = providers.map((provider) => {
    return {
      providerId: provider.lyra_id,
      video: doesProviderHaveVideo({ provider }),
    }
  })
  return {
    providers: providerVideoData,
  }
}

// Return only the unique conditions from ExplainClinicalMatch - these will be the positive conditions we want to show under a Provider profile
// The rest of specializations will be available in the 'Show More' Modal
export const getExplainClinicalMatchConditions = (explainClinicalMatchList: ProviderExplainClinicalMatch[]) => {
  if (explainClinicalMatchList?.length > 0) {
    return Array.from(new Set(explainClinicalMatchList.map((clinicalMatch) => startCase(clinicalMatch?.match?.value))))
  }
  return []
}

export const getProviderFirstNameToDisplay = ({ provider }: { provider?: ProviderInfo }) =>
  provider?.display_first_name || provider?.first_name || ''

export const getProviderFirstNameToDisplayFromString = (providerName: string) => providerName?.split(' ')[0] || ''

export const getDistanceToProvider = ({
  distance,
  isInternational,
}: {
  distance: number
  isInternational: boolean
}): DistanceToProviderValue => {
  return {
    value: Math.round(distance * 10) / 10,
    unit: isInternational ? DistanceUnitAbbreviation.KM : DistanceUnitAbbreviation.MI,
  }
}

export const getProviderAIBioSummary = ({ provider }: { provider?: ProviderInfo }) =>
  provider?.bio_summary_surfaceable && provider?.bio_summary ? provider?.bio_summary : null

import { RadioButtonType, spacing, ThemeColors } from '@lyrahealth-inc/shared-app-logic'

import { IS_WEB } from '../../../constants'

/**
 * This file contains all the styling for radio button components.
 * States overwrite base values.
 */

export const getRadioButtonStyles = (tokenColors: ThemeColors, isMobileSized?: boolean) => {
  return {
    [RadioButtonType.TEXT_OUTLINE]: {
      base: {
        backgroundColor: tokenColors.radioWithOutlineBackgroundDefault,
        borderColor: tokenColors.radioWithOutlineBorderDefault,
        borderWidth: 1,
        borderRadius: 4,
        marginBottom: 10,
      },
      sizes: {
        default: {
          paddingVertical: 12,
          paddingHorizontal: 16,
        },
      },
      states: {
        highlight: {
          backgroundColor: tokenColors.radioWithOutlineBackgroundHighlight,
          borderColor: tokenColors.secondaryButtonBlackOutlineDefault,
          borderWidth: 2,
          paddingVertical: 11,
          paddingHorizontal: 15,
        },
        selected: {
          backgroundColor: tokenColors.radioWithOutlineBackgroundSelected,
          borderColor: tokenColors.radioWithOutlineBorderSelected,
          borderWidth: 2,
          paddingVertical: 11,
          paddingHorizontal: 15,
        },
      },
    },
    [RadioButtonType.CIRCLE_TEXT_OUTLINE]: {
      base: {
        borderColor: tokenColors.radioWithOutlineBorderDefault,
        borderWidth: 1,
        borderRadius: 32,
        marginBottom: 8,
        alignItems: 'center',
        backgroundColor: tokenColors.radioWithOutlineBackgroundDefault,
      },
      sizes: {
        default: {
          paddingVertical: 16,
          paddingHorizontal: 24,
        },
      },
      states: {
        highlight: {
          borderColor: tokenColors.borderFocus,
          borderWidth: 1.5,
        },
        hovered: {
          borderColor: tokenColors.radioWithOutlineBorderHovered,
        },
        selected: {
          backgroundColor: tokenColors.radioWithOutlineBackgroundSelected,
          borderColor: tokenColors.radioWithOutlineBorderSelected,
        },
        readOnly: {
          color: tokenColors.radioWithOutlinePrimaryTextInactive,
        },
      },
    },
    [RadioButtonType.DESCRIPTION_NO_OUTLINE]: {
      base: {
        ...(IS_WEB && { width: 'fit-content', marginBottom: spacing['16px'] }),
      },
      sizes: {
        default: {
          padding: 0,
        },
      },
      states: {},
    },
    [RadioButtonType.DESCRIPTION_OUTLINE]: {
      base: {
        borderColor: tokenColors.radioWithOutlineBorderDefault,
        borderWidth: 1,
        borderRadius: 8,
        marginBottom: 8,
        alignItems: 'center',
        backgroundColor: tokenColors.radioWithOutlineBackgroundDefault,
      },
      sizes: {
        default: {
          paddingVertical: 12,
          paddingHorizontal: 16,
        },
      },
      states: {
        highlight: {
          borderColor: tokenColors.borderFocus,
          borderWidth: 1.5,
        },
        hovered: {
          borderColor: tokenColors.radioWithOutlineBorderHovered,
        },
        selected: {
          borderColor: tokenColors.radioWithOutlineBorderSelected,
          backgroundColor: tokenColors.radioWithOutlineBackgroundSelected,
        },
        selectedDescriptionReadOnly: {
          borderColor: tokenColors.radioWithOutlineBorderInactive,
        },
      },
    },
    [RadioButtonType.CIRCLE_DESCRIPTION_OUTLINE]: {
      base: {
        borderColor: tokenColors.radioWithOutlineBorderDefault,
        borderWidth: 1,
        borderRadius: 8,
        marginBottom: 8,
        alignItems: 'center',
        backgroundColor: tokenColors.radioWithOutlineBackgroundDefault,
      },
      sizes: {
        default: {
          paddingVertical: 12,
          paddingHorizontal: 16,
        },
      },
      states: {
        highlight: {
          borderColor: tokenColors.borderFocus,
          borderWidth: 1.5,
        },
        hovered: {
          borderColor: tokenColors.radioWithOutlineBorderHovered,
        },
        selected: {
          borderColor: tokenColors.radioWithOutlineBorderSelected,
          backgroundColor: tokenColors.radioWithOutlineBackgroundSelected,
        },
        selectedDescriptionReadOnly: {
          borderColor: tokenColors.radioWithOutlineBorderInactive,
        },
      },
    },
    [RadioButtonType.GRID]: {
      base: {
        backgroundColor: tokenColors.radioWithOutlineBackgroundDefault,
        borderColor: tokenColors.radioWithOutlineBorderDefault,
        borderWidth: 1,
      },
      sizes: {
        default: {
          height: 136,
          paddingVertical: 16,
          paddingHorizontal: 16,
          borderRadius: 24,
        },
        small: {
          height: 128,
          paddingVertical: 12,
          paddingHorizontal: 12,
          borderRadius: 16,
        },
      },
      states: {
        hovered: {
          borderColor: tokenColors.radioWithOutlineBorderHovered,
        },
        selected: {
          borderColor: tokenColors.radioWithOutlineBorderSelected,
          backgroundColor: tokenColors.radioWithOutlineBackgroundSelected,
        },
      },
    },
    [RadioButtonType.CIRCULAR_WITH_NUMBER]: {
      base: {
        backgroundColor: tokenColors.radioWithOutlineBackgroundDefault,
        borderColor: tokenColors.radioWithOutlineBorderDefault,
        borderWidth: 1,
      },
      sizes: {
        default: {
          height: isMobileSized ? 48 : 72,
          width: isMobileSized ? 48 : 72,
          borderRadius: 100,
        },
      },
      states: {
        hovered: {
          borderColor: tokenColors.radioWithOutlineBorderHovered,
        },
        selected: {
          borderColor: tokenColors.radioWithOutlineBorderSelected,
          backgroundColor: tokenColors.radioWithOutlineBackgroundSelected,
        },
      },
    },
  }
}

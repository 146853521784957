import React, { useMemo, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import type { TextStyle, ViewStyle } from 'react-native'

import styled from 'styled-components/native'

import { localeToDatePartLabels, localeToDatePartOrdering } from '@lyrahealth-inc/shared-app-logic'

import { BaseInput } from './BaseInput'
import { SelectField, selectFieldCast } from './SelectField'

export type DatePickerSelectFieldProps = {
  label: string
  name: string
  /**
   * The minimum year option. If omitted, defaults to maxYear - numYears
   */
  minYear?: number
  /**
   * The maximum year option. If omitted, defaults to this year
   */
  maxYear?: number
  /**
   * If `minYear` is not provided, this is used to calculate the minimum year as `maxYear - numYears`
   * Defaults to 100
   */
  numYears?: number
  /**
   * @param date current date string in ISO 8601 format, or `null` if invalid date was selected
   */
  onChange: (date: string | null) => void
  onFocus: () => void
  onBlur: () => void
  /**
   * Optional initial date to show selected.
   * Must be ISO 8601 formatted date string e.g. 2023-02-03
   */
  initialDate?: string
  yearOrder?: 'asc' | 'desc'
  /** If `undefined`, uses a purely numeric format. In some locales, `numeric` contains additional characters */
  yearFormat?: Intl.DateTimeFormatOptions['year']
  /** If `undefined`, uses a purely numeric format. In some locales, `numeric` contains additional characters */
  monthFormat?: Intl.DateTimeFormatOptions['month']
  /** If `undefined`, uses a purely numeric format. In some locales, `numeric` contains additional characters */
  dayFormat?: Intl.DateTimeFormatOptions['day']
  error?: string
  placeholder?: string
  baseInputStyle?: ViewStyle
  labelContainerStyle?: ViewStyle
  /** Override custom style for each select field */
  selectFieldStyle?: TextStyle
  showErrorAsWarning?: boolean
}

/**
 * Renders a fully internationalized date picker with 3 separate fields.
 * The date format, ordering, and display text are derived from the `locale` using Intl.DateTimeFormat.
 * This primarily is aimed at web apps, but it uses the crossplatform SelectField component,
 * so it should work on mobile as well.
 * Mobile most likely should use `DateField` with `display='spinner'` for similar effect.
 */
export const DatePickerSelectField: React.FC<DatePickerSelectFieldProps> = (props) => {
  const { locale } = useIntl()
  return <DatePickerSelectImpl {...props} locale={locale} />
}

const SelectGroupContainer = styled.View(({ theme: { breakpoints, spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: breakpoints.isMinWidthMobileXs ? spacing['16px'] : spacing['12px'],
  flexWrap: 'wrap',
  alignItems: 'center',
  alignSelf: 'stretch',
}))

const selectFieldBaseInputStyle: ViewStyle = {
  marginBottom: 0,
  flexGrow: 1,
}

function localeToYears(
  locale: string,
  minYear: number,
  maxYear: number,
  order: 'asc' | 'desc' = 'desc',
  yearFormat?: Intl.DateTimeFormatOptions['year'],
) {
  const years = Array.from({ length: maxYear - minYear + 1 }, (_, i) => (order === 'asc' ? minYear + i : maxYear - i))

  if (yearFormat) {
    const format = new Intl.DateTimeFormat(locale, {
      year: yearFormat,
      timeZone: 'UTC',
    }).format

    return years.map((year) => {
      return { value: year, label: format(Date.UTC(year, 0, 1)) }
    })
  }

  // use purely numeric year by default; some locales include additional characters with `year: 'numeric'` option
  return years.map((year) => ({ value: year, label: String(year) }))
}

function localeToMonths(locale: string, monthFormat: undefined | Intl.DateTimeFormatOptions['month'] = 'short') {
  const MONTHS_IN_YEAR = 12
  const months = Array.from({ length: MONTHS_IN_YEAR }, (_, i) => i /* 0-indexed month */)

  if (monthFormat) {
    const format = new Intl.DateTimeFormat(locale, {
      month: monthFormat,
      timeZone: 'UTC',
    }).format

    return Array(MONTHS_IN_YEAR)
      .fill(0)
      .map((_, monthIndex) => {
        return { value: monthIndex, label: format(Date.UTC(2023, monthIndex, 1)) }
      })
  }

  return months.map((monthIndex) => ({ value: monthIndex, label: String(monthIndex + 1) }))
}

function localeToDays(locale: string, dayFormat?: Intl.DateTimeFormatOptions['day']) {
  const MAX_DAYS_IN_MONTH = 31
  const days = Array.from({ length: MAX_DAYS_IN_MONTH }, (_, i) => i + 1)

  if (dayFormat) {
    const format = new Intl.DateTimeFormat(locale, {
      day: dayFormat,
      timeZone: 'UTC',
    }).format

    return days.map((day) => {
      return { value: day, label: format(Date.UTC(2023, 0, day)) }
    })
  }

  // use purely numeric day by default; some locales include additional characters with `day: 'numeric'` option
  return days.map((day) => ({ value: day, label: String(day) }))
}

const THIS_YEAR = new Date().getFullYear()

export const DatePickerSelectImpl: React.FC<DatePickerSelectFieldProps & { locale: string }> = ({
  locale,
  label,
  maxYear: maxYearProp,
  minYear: minYearProp,
  numYears = 100,
  error,
  name,
  initialDate,
  onChange,
  onFocus,
  onBlur,
  placeholder = '',
  yearFormat = undefined,
  monthFormat = 'short',
  dayFormat = undefined,
  yearOrder = 'desc',
  baseInputStyle,
  selectFieldStyle,
  showErrorAsWarning,
  labelContainerStyle,
}) => {
  const maxYear = maxYearProp ?? THIS_YEAR
  const minYear = minYearProp ?? maxYear - numYears

  const [selected, setSelected] = useState<[string | number, string | number, string | number]>(() => {
    if (initialDate) {
      const date = new Date(initialDate)
      let initialYear = date.getUTCFullYear()
      if (initialYear < minYear) {
        initialYear = minYear
      } else if (initialYear > maxYear) {
        initialYear = maxYear
      }
      return [initialYear, date.getUTCMonth(), date.getUTCDate()]
    }
    return [placeholder, placeholder, placeholder]
  })

  const { yearOptions, monthOptions, dayOptions, yearLabel, monthLabel, dayLabel, datePartOrder } = useMemo(() => {
    const { year: yearLabel, month: monthLabel, day: dayLabel } = localeToDatePartLabels(locale)
    return {
      yearOptions: localeToYears(locale, minYear, maxYear, yearOrder, yearFormat),
      monthOptions: localeToMonths(locale, monthFormat),
      dayOptions: localeToDays(locale, dayFormat),
      datePartOrder: localeToDatePartOrdering(locale),
      yearLabel,
      monthLabel,
      dayLabel,
    }
  }, [dayFormat, locale, maxYear, minYear, monthFormat, yearFormat, yearOrder])

  const [selectedYear, selectedMonth, selectedDay] = selected

  const onSelectChange = (yearArg: string | number, monthArg: string | number, dayArg: string | number) => {
    // Possible bug in RNPickerSelect fires onValueChange twice,
    // first with the value as a string, then again with number
    const year = selectFieldCast(yearArg)
    const monthIndex = selectFieldCast(monthArg)
    const day = selectFieldCast(dayArg)
    if (year !== selectedYear || monthIndex !== selectedMonth || day !== selectedDay) {
      setSelected([year, monthIndex, day])
      let isValidDate = false
      let date: Date | undefined
      if (typeof year === 'number' && typeof monthIndex === 'number' && typeof day === 'number') {
        date = new Date(Date.UTC(year, monthIndex, day))
        // Check the date did not shift compared to the selected values
        isValidDate = date.getUTCFullYear() === year && date.getUTCMonth() === monthIndex && date.getUTCDate() === day
      }
      if (isValidDate && date) {
        const dateStr = date.toISOString()
        onChange(dateStr.substring(0, dateStr.indexOf('T')))
      } else {
        onChange(null)
      }
    }
  }

  const selectParts = {
    year: (
      <SelectField
        key='year'
        name={`${name}-year`}
        subLabel={yearLabel}
        largeSubLabel={true}
        placeholder={placeholder}
        options={yearOptions}
        onChange={(year) => {
          onSelectChange(year, selectedMonth, selectedDay)
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        value={selectedYear}
        baseInputStyle={selectFieldBaseInputStyle}
        customWebStyle={selectFieldStyle}
        customIOSStyle={selectFieldStyle}
        customAndroidStyle={selectFieldStyle}
        error={error}
        hideErrorMessage
        showErrorAsWarning={showErrorAsWarning}
      />
    ),
    month: (
      <SelectField
        key='month'
        name={`${name}-month`}
        subLabel={monthLabel}
        largeSubLabel={true}
        placeholder=''
        options={monthOptions}
        onChange={(month) => {
          onSelectChange(selectedYear, month, selectedDay)
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        value={selectedMonth}
        baseInputStyle={selectFieldBaseInputStyle}
        customWebStyle={selectFieldStyle}
        customIOSStyle={selectFieldStyle}
        customAndroidStyle={selectFieldStyle}
        error={error}
        hideErrorMessage
        showErrorAsWarning={showErrorAsWarning}
      />
    ),
    day: (
      <SelectField
        key='day'
        name={`${name}-day`}
        subLabel={dayLabel}
        largeSubLabel={true}
        placeholder={placeholder}
        options={dayOptions}
        onChange={(day) => {
          onSelectChange(selectedYear, selectedMonth, day)
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        value={selectedDay}
        baseInputStyle={selectFieldBaseInputStyle}
        customWebStyle={selectFieldStyle}
        customIOSStyle={selectFieldStyle}
        customAndroidStyle={selectFieldStyle}
        error={error}
        hideErrorMessage
        showErrorAsWarning={showErrorAsWarning}
      />
    ),
  }
  return (
    <BaseInput
      label={label}
      error={error}
      name={name}
      style={baseInputStyle}
      showErrorAsWarning={showErrorAsWarning}
      labelContainerStyle={labelContainerStyle}
    >
      <SelectGroupContainer>{datePartOrder.map((part) => selectParts[part])}</SelectGroupContainer>
    </BaseInput>
  )
}

export const DatePickerSelectFieldRFF: React.FC<FieldRenderProps<string>> = ({
  input: { name, value, onChange, onFocus, onBlur },
  meta: { touched, error, submitError, dirtySinceLastSubmit },
  label,
  minYear,
  maxYear,
  numYears,
  yearOrder,
  baseInputStyle,
  selectFieldStyle,
  showErrorAsWarning,
  labelContainerStyle,
}) => {
  return (
    <DatePickerSelectField
      label={label}
      initialDate={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      error={touched && (error || (!dirtySinceLastSubmit && submitError))}
      name={name}
      minYear={minYear}
      maxYear={maxYear}
      numYears={numYears}
      yearOrder={yearOrder}
      baseInputStyle={baseInputStyle}
      selectFieldStyle={selectFieldStyle}
      /**
       * We only want to show the warning color after the user submits the form.
       * Using dirtySinceLastSubmit here to check if the user has updated the field
       * after submitting, so we so we can show the correct validation error
       * color or default color when needed.
       */
      showErrorAsWarning={showErrorAsWarning && !dirtySinceLastSubmit && !!submitError}
      labelContainerStyle={labelContainerStyle}
    />
  )
}

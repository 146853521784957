import React, { FunctionComponent, ReactElement } from 'react'
import { ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { QuestionMarkIcon, Tooltip } from '../../atoms'
import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { RowView } from '../../templates'

export interface IconListItemProps {
  icon: ReactElement
  title?: React.ReactNode
  text?: React.ReactNode
  noMargin?: boolean
  id?: string
  style?: ViewStyle
  tooltip?: React.ReactNode
}

export interface IconListProps {
  data: Array<IconListItemProps>
  itemStyle?: ViewStyle
}

const ItemContainer = styled.View<{ noMargin?: boolean }>(({ noMargin }) => ({
  flexDirection: 'row',
  marginBottom: noMargin ? '0px' : '12px',
}))

const ContentContainer = styled.View({
  marginLeft: '8px',
  flexShrink: 1,
})

const IconContainer = styled.View({
  flexShrink: 0,
})

const IconListItem: FunctionComponent<IconListItemProps> = ({ icon, title, text, noMargin, id, style, tooltip }) => {
  const { colors } = useTheme()

  return (
    <ItemContainer noMargin={noMargin} key={id} style={style}>
      <IconContainer>{icon}</IconContainer>
      <ContentContainer>
        {!!title && <BodyText text={title} size={Size.SMALL} color={colors.textPrimary} wrap />}
        <RowView>
          {!!text && (
            <BodyText text={text} size={Size.SMALL} color={!title ? colors.textPrimary : colors.textInactive} wrap />
          )}
          {tooltip && (
            <Tooltip content={tooltip} style={{ left: 4 }}>
              <QuestionMarkIcon width={19} fillColor={colors.iconInactive} />
            </Tooltip>
          )}
        </RowView>
      </ContentContainer>
    </ItemContainer>
  )
}

export const IconList: FunctionComponent<IconListProps> = ({ data, itemStyle }) => {
  return (
    <>
      {data.map(({ icon, title, text, tooltip }, index) => {
        const isLast = index === data.length - 1
        return (
          <IconListItem
            key={index}
            icon={icon}
            title={title}
            text={text}
            noMargin={isLast}
            style={itemStyle}
            tooltip={tooltip}
          />
        )
      })}
    </>
  )
}

import { noOpActionErrorHandler } from '../../common/http/actionErrorHandler'

export const SET_CUSTOMER_COUNTRY = 'SET_CUSTOMER_COUNTRY'
export const SET_CUSTOMER_COUNTRY_LIST = 'SET_CUSTOMER_COUNTRY_LIST'
export const SET_ALL_COUNTRIES = 'SET_ALL_COUNTRIES'
export const SET_SUPPORTED_COUNTRIES = 'SET_SUPPORTED_COUNTRIES'

export const setCustomerCountry = (val: $TSFixMe) => ({
  type: SET_CUSTOMER_COUNTRY,
  val,
})

export const getCustomerCountryList = () => ({
  action: SET_CUSTOMER_COUNTRY_LIST,
  request: {
    method: 'get',
    url: '/unsecured/v1/customer/countries',
  },
  errorHandler: noOpActionErrorHandler,
})

export const getAllCountries = () => ({
  action: SET_ALL_COUNTRIES,
  request: {
    method: 'get',
    url: '/unsecured/v1/countries',
  },
})

// Similar to getAllCountries, however it excludes any countries that the customer does not have a customer-country relationship with
export const getSupportedCountries = () => ({
  action: SET_SUPPORTED_COUNTRIES,
  request: {
    method: 'get',
    url: '/unsecured/v1/countries/supported',
  },
})

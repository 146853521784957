import React, { FunctionComponent } from 'react'
import { G, Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Standard Alarm Icon
 * Used on GetHelp Page
 */
export const AlarmIcon: FunctionComponent<AlarmIconProps> = ({ size = 94 }) => {
  const { colors } = useTheme()
  const { leftBulb, rightBulb, center, rightBase, leftBase } = colors.icons.alarmIcon

  return (
    <Svg width={size} height={size} viewBox='0 0 94 94'>
      <G id='MVP-app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <G id='N2' transform='translate(-22.000000, -105.000000)'>
          <G id='il-96-alarm' transform='translate(22.000000, 105.000000)'>
            <Rect id='Rectangle' x='0' y='0' width={size} height={size} />
            <G
              id='ambulance-siren-8755_3c91de60-30cf-445f-a8fb-026124cac50e'
              transform='translate(1.468750, 2.937500)'
              fillRule='nonzero'
            >
              <Path
                d='M74.90625,79.3125 L74.90625,55.8125 C74.90625,39.5891355 61.7546145,26.4375 45.53125,26.4375 C29.3078855,26.4375 16.15625,39.5891355 16.15625,55.8125 L16.15625,79.3125 L74.90625,79.3125 Z M14.6875,33.78125 C14.2979951,33.78125 13.9244737,33.6263694 13.6490938,33.3509063 L0.43034375,20.1321563 C-0.126271683,19.5558498 -0.118311299,18.6397817 0.448235217,18.0732352 C1.01478173,17.5066887 1.93084976,17.4987283 2.50715625,18.0553438 L15.7259062,31.2740938 C16.1458335,31.6941478 16.2714322,32.3257725 16.044152,32.8745239 C15.8168719,33.4232752 15.2814565,33.78125 14.6875,33.78125 Z M33.78125,20.5625094 C33.149336,20.5620202 32.5885597,20.1574094 32.388875,19.557875 L26.513875,1.932875 C26.2571403,1.16226519 26.6737183,0.329437869 27.4443281,0.072703125 C28.2149379,-0.184031619 29.0477653,0.232546435 29.3045,1.00315625 L35.1795,18.6281562 C35.3295262,19.0770064 35.2540789,19.5706473 34.9768307,19.9541936 C34.6995824,20.3377398 34.2545062,20.5641927 33.78125,20.5625094 Z M76.375,33.78125 C75.7810435,33.78125 75.2456281,33.4232752 75.018348,32.8745239 C74.7910678,32.3257725 74.9166665,31.6941478 75.3365938,31.2740938 L88.5553438,18.0553438 C89.1316502,17.4987283 90.0477183,17.5066887 90.6142648,18.0732352 C91.1808113,18.6397817 91.1887717,19.5558498 90.6321563,20.1321563 L77.4134063,33.3509063 C77.1380263,33.6263694 76.7645049,33.78125 76.375,33.78125 Z M57.28125,20.5625095 C56.8093888,20.5621418 56.3664328,20.3350997 56.09063,19.9522342 C55.8148272,19.5693688 55.7397667,19.0773078 55.888875,18.629625 L61.763875,1.004625 C61.9299532,0.506127832 62.3493609,0.134099145 62.8641113,0.0286785522 C63.3788616,-0.0767420403 63.9107518,0.100461359 64.2594238,0.49353794 C64.6080959,0.886614522 64.7205783,1.4358466 64.5545,1.93434375 L58.6795,19.5593437 C58.4786244,20.1603526 57.9149357,20.5647637 57.28125,20.5625095 Z'
                id='Shape'
                fill={rightBulb.fill}
              />
              <Path
                d='M16.15625,79.3125 L16.15625,55.8125 C16.2038404,39.608889 29.327639,26.4850904 45.53125,26.4375 L45.53125,79.3125 L16.15625,79.3125 Z'
                id='Path'
                fill={leftBulb.fill}
              />
              <Path
                d='M49.9375457,55.8125 C49.9476625,53.5622773 48.260643,51.6659429 46.0245408,51.4140452 C43.7884386,51.1621474 41.7218078,52.6356317 41.2310917,54.8317199 C40.7403757,57.0278081 41.9832622,59.2408043 44.1139062,59.9646562 C44.0887712,60.0475372 44.0715586,60.1326166 44.0625,60.21875 L44.0625,79.3125 C44.0625,80.1236682 44.7200818,80.7812499 45.53125,80.7812499 C46.3424182,80.7812499 47,80.1236682 47,79.3125 L47,60.21875 C46.9909414,60.1326166 46.9737288,60.0475372 46.9485938,59.9646562 C48.7314599,59.3637414 49.9334804,57.6939075 49.9375457,55.8125 Z'
                id='Path'
                fill={center.fill}
              />
              <Path
                d='M89.59375,88.125 L1.46875,88.125 C1.46875,83.2579906 5.41424064,79.3125 10.28125,79.3125 L80.78125,79.3125 C85.6482594,79.3125 89.59375,83.2579906 89.59375,88.125 L89.59375,88.125 Z'
                id='Path'
                fill={rightBase.fill}
              />
              <Path
                d='M45.53125,79.3125 L45.53125,88.125 L1.46875,88.125 C1.46875,83.2579906 5.41424064,79.3125 10.28125,79.3125 L45.53125,79.3125 Z'
                id='Path'
                fill={leftBase.fill}
              />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  )
}

type AlarmIconProps = {
  size?: number
}

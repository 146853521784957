import { PayloadAction } from '@reduxjs/toolkit'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { ProviderProgramTaxonomy } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_TREATMENT_OPTIONS,
  SET_FALLBACK_TREATMENT_OPTION,
  SET_PROVIDER_BASED_TREATMENT_OPTIONS_USER_IS_ELIGIBLE_FOR,
  SET_SELECTED_TREATMENT_OPTION,
  SET_SELECTED_TREATMENT_OPTION_CTPO,
} from './treatmentOptionsActions'

const initialState = fromJS({})

const treatmentOptionsReducer = createReducer(initialState, {
  [SET_SELECTED_TREATMENT_OPTION]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('selectedTreatmentOption', action.payload)
    return $$state
  },
  [SET_FALLBACK_TREATMENT_OPTION]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('fallbackTreatmentOption', action.payload)
    return $$state
  },
  [CLEAR_TREATMENT_OPTIONS]: () => {
    return initialState
  },
  [SET_PROVIDER_BASED_TREATMENT_OPTIONS_USER_IS_ELIGIBLE_FOR]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('providerBasedTreatmentOptionsUserIsEligibleFor', action.payload)
    return $$state
  },
  [SET_SELECTED_TREATMENT_OPTION_CTPO]: ($$state: $TSFixMe, action: PayloadAction<ProviderProgramTaxonomy>) => {
    $$state = $$state.set('selectedTreatmentOptionCTPO', action.payload)
    return $$state
  },
})

export default treatmentOptionsReducer

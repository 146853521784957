import { fromJS, List } from 'immutable'

// Defaulting all booleans to false
const defaultState = fromJS({
  providerList: [],
  totalResults: 0,
  startIdx: 0,
  NUM_PROVIDERS_TO_PAGINATE: 10,
  NUM_PROVIDERS_AT_LOAD: 50,
  unedited_provider: {},
  edited_provider: {
    is_recommended: {
      edit: 1,
      value: false,
    },
    active_license: {
      edit: 1,
      value: false,
    },
    is_lyra: {
      edit: 1,
      value: false,
    },
    is_blocked: {
      edit: 1,
      value: false,
    },
    sanctions: {
      edit: 1,
      value: false,
    },
    // addresses: {
    //    edit: 0,
    //    value: [CUSTOM_KEYS.address]
    // }
  },
})

const RESET_ADD_EDIT_PROVIDER = 'RESET_ADD_EDIT_PROVIDER'
const SET_PROVIDER = 'SET_PROVIDER'
const UPDATE_PROVIDER_PROPERTY = 'UPDATE_PROVIDER_PROPERTY'
const ADD_PROPERTY_TO_LIST = 'ADD_PROPERTY_TO_LIST'
const DELETE_PROPERTY_FROM_LIST = 'DELETE_PROPERTY_FROM_LIST'
const ADD_CUSTOM_SECTION = 'ADD_CUSTOM_SECTION'
const DELETE_CUSTOM_SECTION = 'DELETE_CUSTOM_SECTION'

export const addEditProvider = (state = defaultState, action: $TSFixMe) => {
  switch (action.type) {
    case RESET_ADD_EDIT_PROVIDER:
      return defaultState
    case UPDATE_PROVIDER_PROPERTY:
      return state.setIn(action.path, action.value)
    case SET_PROVIDER:
      if (action.provider) {
        return state.set('unedited_provider', action.provider).set('edited_provider', action.provider)
      }
      return defaultState
    /* ---------------CRUD operations on Lists----------------- */
    case ADD_PROPERTY_TO_LIST:
      return state.setIn(action.path, state.getIn(action.path, List()).push(''))
    case DELETE_PROPERTY_FROM_LIST:
      return state.setIn(action.path, state.getIn(action.path).delete(action.idx))
    /* ---------------CUSTOM ACTIONS----------------- */
    case DELETE_CUSTOM_SECTION:
      return state.updateIn(['edited_provider', action.field, 'value'], (arr: $TSFixMe) => arr.delete(action.idx))
    case ADD_CUSTOM_SECTION:
      // Checks if the key is here, if it's not then create it
      if (!state.getIn(['edited_provider', action.field, 'value'])) {
        return state.setIn(['edited_provider', action.field, 'value'], fromJS([action.customKey]))
      }
      return state.updateIn(['edited_provider', action.field, 'value'], (arr: $TSFixMe) =>
        arr.push(fromJS(action.customKey)),
      )
    default:
      return state
  }
}

export default addEditProvider

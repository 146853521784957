import React from 'react'

import LegalFooter from './LegalFooter'

const Footer = () => {
  return (
    <div>
      <LegalFooter />
    </div>
  )
}
export default Footer

import { fromJS } from 'immutable'

// NOTE: Starbucks doesn't need to be localized because they are not allowing support for other non-English languages

export const starbucks = () => {
  return fromJS({
    micrositeAndElsewhereTopBanner: null,
    // TODO: (insert jira) Disable extra nav options from CLIENT-876 until starbucks green light
    hideExtraHeaderOptions: true,
  })
}

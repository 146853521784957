import { getWorkLifeServiceCode } from '@lyrahealth-inc/shared-app-logic/src/features/common/utils/stringUtils'

import DependentsIllustration from '../../../images/microsite/illustration-dependents.png'
import FinancialIllustration from '../../../images/microsite/illustration-financial.png'
import LegalIllustration from '../../../images/microsite/illustration-legal.png'
import SecurityIllustration from '../../../images/microsite/illustration-security.png'

/**
 * Map of strings that map to identifiers for worklife services offered to customers (where the identifiers
 * are derived from the customer properties stored in the backend).
 */
export const WORKLIFE_SERVICE_IDS = {
  LEGAL_SERVICES: {
    id: 'legal',
    dataTestId: 'workLife-selectLegalServices',
  },
  FINANCIAL_SERVICES: {
    id: 'financial',
    dataTestId: 'workLife-selectFinancialServices',
  },
  IDENTITY_THEFT: {
    id: 'identity',
    dataTestId: 'workLife-selectIdentityTheft',
  },
  DEPENDENT_CARE: {
    id: 'dependent',
    dataTestId: 'workLife-selectDependentCare',
  },
}

export const DEFAULT_WORKLIFE_SERVICES = {
  LEGAL_SERVICES: {
    identifiers: WORKLIFE_SERVICE_IDS.LEGAL_SERVICES,
    title: 'Legal Services',
    img: LegalIllustration,
    paragraph: '',
    bullets: ['On-Call Legal Advice', '24-Hour Emergency Legal Services', 'Mediation', 'Document Preparation'],
    CTA: 'Access legal support',
    PATH: 'https://clcmembers.net/launch/46100780-6095-11e9-ab48-8963789f0545',
  },
  FINANCIAL_SERVICES: {
    identifiers: WORKLIFE_SERVICE_IDS.FINANCIAL_SERVICES,
    title: 'Financial Services',
    img: FinancialIllustration,
    paragraph: '',
    bullets: [
      'Tax Planning',
      'Financial Planning for: Debt, Budgeting, Retirement, and more…',
      'Includes Free Financial Consultation',
    ],
    CTA: 'Access financial support',
    PATH: 'https://www.msamembers.net/launch/3e209600-4219-11ea-860e-37575277bcdc',
  },
  IDENTITY_THEFT: {
    identifiers: WORKLIFE_SERVICE_IDS.IDENTITY_THEFT,
    title: 'Identity Theft',
    img: SecurityIllustration,
    paragraph: 'Consult with a Fraud Resolution Specialist to:',
    bullets: ['Resolve Fraud Promptly', 'Restore Your Identity and Good Credit'],
    CTA: 'Consult with an expert',
    PATH: 'https://clcmembers.net/launch/46100780-6095-11e9-ab48-8963789f0545',
  },
  DEPENDENT_CARE: {
    identifiers: WORKLIFE_SERVICE_IDS.DEPENDENT_CARE,
    title: 'Dependent Care',
    img: DependentsIllustration,
    paragraph: ({
      customerName,
      workLifeServiceDependentCareCode,
    }: {
      customerName: string
      workLifeServiceDependentCareCode?: string
    }) => {
      return `Get assistance finding care. If a company code is requested, use ${getWorkLifeServiceCode(
        customerName,
        workLifeServiceDependentCareCode,
      )}.`
    },
    bullets: ['Child Care', 'Elder Care', 'Pet Care'],
    CTA: 'Find dependent care',
    PATH: 'https://www.employeecareonline.com/',
  },
}

import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { BaseButton, ButtonType } from '../../atoms/baseButton/BaseButton'
import { IconButton } from '../../atoms/iconButton/IconButton'
import { GroupAddIcon } from '../../atoms/icons/GroupAddIcon'
import { tID } from '../../utils'

export interface ReferButtonProps {
  onPress: () => void
}

export const ReferButton: FunctionComponent<ReferButtonProps> = ({ onPress }) => {
  const {
    breakpoints: { isMobileSized },
    colors,
  } = useTheme()
  const { formatMessage } = useIntl()
  const referText = formatMessage({
    defaultMessage: 'Refer',
    description: 'Button for referral',
  })

  return isMobileSized ? (
    <IconButton
      accessibilityLabel={referText}
      onPress={onPress}
      iconColor={colors.secondaryButtonTextActive}
      leftIcon={<GroupAddIcon fillColor={colors.secondaryButtonTextActive} width={24} />}
      style={{ width: 24, height: 24, marginRight: 8, alignSelf: 'center' }}
      testID={tID('Refer-button')}
    />
  ) : (
    <BaseButton
      buttonType={ButtonType.SECONDARY}
      text={referText}
      accessibilityLabel={referText}
      leftIcon={<GroupAddIcon fillColor={colors.secondaryButtonTextActive} width={24} />}
      onPress={onPress}
      testID={tID('Refer-button')}
    />
  )
}

import React, { FunctionComponent } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

import { useTheme } from 'styled-components/native'

import { getTypeStyles } from '../../styles/typeStyles'
import { BodyText } from '../bodyText/BodyText'

/**
 * Standard badge for displaying a rounded icon with a number in it
 */
export const NumberBadge: FunctionComponent<NumberBadgeProps> = ({
  number,
  color,
  backgroundColor,
  borderWidth = 0,
  borderColor,
  size = 16,
  lineHeight = 12,
  style,
  typeStyle,
}) => {
  const { colors } = useTheme()
  const { numberBadgeIcon } = colors.icons

  return (
    <View
      style={[
        {
          ...styles.badge,
          backgroundColor: backgroundColor || numberBadgeIcon.background,
          borderWidth,
          borderColor: borderColor || numberBadgeIcon.border,
          height: size,
          borderRadius: size / 2,
          minWidth: size,
        },
        style,
      ]}
    >
      <BodyText
        style={{
          ...getTypeStyles(colors).bodyCaption,
          ...styles.number,
          ...typeStyle,
          color: color || colors.textInverse,
          lineHeight,
        }}
      >
        {number}
      </BodyText>
    </View>
  )
}

type NumberBadgeProps = {
  number: string | number
  color?: string
  backgroundColor?: string
  borderWidth?: number
  borderColor?: string
  size?: number
  lineHeight?: number
  style?: ViewStyle
  typeStyle?: Dict
}

const styles = StyleSheet.create({
  badge: {
    overflow: 'hidden',
    borderRadius: 4,
    borderWidth: 2,
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  number: {
    height: 10,
    textAlignVertical: 'center',
    textAlign: 'center',
  },
})

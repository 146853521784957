import { useCallback, useEffect } from 'react'

import { addMinutes } from 'date-fns'
import { useFlags } from 'launchdarkly-react-client-sdk'
import UAParser from 'ua-parser-js'

import { checkBusinessHours, useCountdownTimer } from '@lyrahealth-inc/shared-app-logic'
import { IS_WEB } from '@lyrahealth-inc/ui-core-crossplatform'

type InAppMessagingParams = {
  lyraId: string
  userFirstName: string
  userLastName: string
  userEmail: string
  customerName: string
  events: { onOnline: () => void; onOffline: () => void; onDisabled: () => void }
}

declare global {
  interface Window {
    hasLoadedInAppMessaging: boolean
  }
}

const USER_CHAT_EXPIRATION_TIMESTAMP: string = 'USER_CHAT_EXPIRATION_TIMESTAMP'

export const useInAppMessaging = ({
  lyraId,
  userFirstName,
  userLastName,
  userEmail,
  customerName,
  events,
}: InAppMessagingParams) => {
  const {
    showChatBusinessHoursEndingAt6PM,
    routeUserDirectlyToAgentInInAppMessagingChatWindow,
    chatSessionTimeoutDuration,
    enableInAppMessagingForTesting,
  } = useFlags()
  const duration = chatSessionTimeoutDuration
  const [countdown, resetCountdown] = useCountdownTimer(0, IS_WEB) // We don't need the timer to start until the user starts chat

  useEffect(() => {
    if (countdown === 1) {
      ;(window as $TSFixMe).embeddedservice_bootstrap.userVerificationAPI.clearSession()
    }
  }, [countdown])

  const setInAppMessagingProps = useCallback(() => {
    const userAgent = new UAParser(window.navigator.userAgent)
    const userBrowser = userAgent.getResult().browser.name
    const userOS = userAgent.getOS().name
    if (window.hasLoadedInAppMessaging) {
      const hiddenPrechatFields = {
        First_Name: userFirstName || '',
        Last_Name: userLastName || '',
        Email: userEmail || '',
        Lyra_ID: lyraId || '',
        Microsite: customerName || '',
        Browser: userBrowser,
        OS: userOS,
        RouteToAgent: routeUserDirectlyToAgentInInAppMessagingChatWindow ? 'True' : 'False',
      }

      ;(window as $TSFixMe).embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(hiddenPrechatFields)
    }
  }, [customerName, lyraId, routeUserDirectlyToAgentInInAppMessagingChatWindow, userEmail, userFirstName, userLastName])

  const resetSessionTimeout = () => {
    // Reset the chat timeout if the user sends a message to the chat
    resetCountdown(duration)
    // Set the timestamp for the timeout per the last sent message so that if the user closes the window before the chat is cleared,
    // LW can remove any existing chats once the timestamp expires
    localStorage.setItem(USER_CHAT_EXPIRATION_TIMESTAMP, addMinutes(new Date(), 5).toISOString())
  }

  const removeSessionTimeout = () => {
    resetCountdown(0)
    // Don't alter what's written to localStorage; if the user closes the tab, we still want to close the chat once the timeout hits
  }

  const onChatAvailableToUser = () => {
    // If the chat becomes available to the user, start the session timeout timer.
    resetSessionTimeout()
  }

  const setEventListenersForSessionTimeout = () => {
    window.addEventListener('onEmbeddedMessagingConversationStarted', () => {
      onChatAvailableToUser()
    })

    window.addEventListener('onEmbeddedMessagingConversationOpened', () => {
      onChatAvailableToUser()
    })

    window.addEventListener('onEmbeddedMessageSent', (e: CustomEventInit) => {
      // If a user sends a message and the timer is ticking, reset the session timeout timer
      if (
        e?.detail?.conversationEntry?.sender?.role === 'EndUser' &&
        e?.detail?.conversationEntry?.senderDisplayName === 'Guest' &&
        countdown > 0
      ) {
        resetSessionTimeout()
      }
    })

    window.addEventListener('onEmbeddedMessagingConversationParticipantChanged', (e: CustomEventInit) => {
      if (e?.detail?.conversationEntry?.entryPayload) {
        const entryPayloadDetail = JSON.parse(e?.detail?.conversationEntry?.entryPayload)?.entries?.[0]
        if (entryPayloadDetail.operation === 'remove') {
          console.log('Participant removed:', entryPayloadDetail.displayName)
          removeSessionTimeout()
        } else if (entryPayloadDetail.operation === 'add') {
          console.log('Participant added:', entryPayloadDetail.displayName)
          resetSessionTimeout()
        }
      }
    })
  }

  const launchInAppMessaging = () => {
    ;(window as $TSFixMe).embeddedservice_bootstrap.utilAPI.launchChat()
  }

  const loadInAppMessaging = useCallback(() => {
    const { onOnline, onOffline, onDisabled } = events

    if (window.hasLoadedInAppMessaging) {
      // For temporary purposes enableInAppMessagingForTesting flag allows for testing after hours
      const online = checkBusinessHours({ showChatBusinessHoursEndingAt6PM }) || enableInAppMessagingForTesting
      if (online && !window.Cypress) {
        onOnline()
        setInAppMessagingProps()
        setEventListenersForSessionTimeout()
        launchInAppMessaging()
      } else {
        onOffline()
      }
    } else {
      onDisabled()
    }
  }, [
    events,
    setEventListenersForSessionTimeout,
    setInAppMessagingProps,
    showChatBusinessHoursEndingAt6PM,
    enableInAppMessagingForTesting,
  ])

  return { loadInAppMessaging }
}

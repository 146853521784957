import { Address } from '../../models'
import {
  CONTACT_CARE_TEAM_REQUEST_TYPES,
  LYRIANS_SUPPORT_LINK,
  navigatorTicketTypes,
  PRODUCTION_DEFAULT_DIRECT_CARE_NAVIGATOR_BOOKING_LINK_BASE,
  STAGING_DEFAULT_DIRECT_CARE_ADVOCATE_BOOKING_LINK_BASE,
  STAGING_DEFAULT_DIRECT_CARE_NAVIGATOR_BOOKING_LINK_BASE,
} from '../common/constants/constants'
import { formatAddressForSubmission } from '../locationFinder/utils'

export const getCareNavigatorSchedulingLink = ({
  defaultDirectCareNavigatorBookingLink,
  directDedicatedCareNavigatorLink,
  launchDate,
  name,
  email,
  isZeroProvidersFlow = false,
  isAssertiveTriageFlow = false,
  salesforceId,
  isLyrian,
  isProd,
}: {
  defaultDirectCareNavigatorBookingLink: string
  directDedicatedCareNavigatorLink: string
  launchDate: string
  name: string
  email: string
  isZeroProvidersFlow: boolean
  isAssertiveTriageFlow: boolean
  salesforceId: string
  isLyrian: boolean
  isProd: boolean
}): string => {
  if (isLyrian) {
    return LYRIANS_SUPPORT_LINK
  }

  const launchYear = new Date(launchDate).getUTCFullYear()
  const useCareNavigatorLink = launchYear > 2022

  const environment = isProd ? 'production' : 'staging'
  const linkBases = {
    production: {
      navigator: PRODUCTION_DEFAULT_DIRECT_CARE_NAVIGATOR_BOOKING_LINK_BASE,
      advocate: defaultDirectCareNavigatorBookingLink,
    },
    staging: {
      navigator: STAGING_DEFAULT_DIRECT_CARE_NAVIGATOR_BOOKING_LINK_BASE,
      advocate: STAGING_DEFAULT_DIRECT_CARE_ADVOCATE_BOOKING_LINK_BASE,
    },
  }

  /**
   * In production: If the user has a dedicated care navigator schedule link, then
   * that is used. Otherwise, we have to use the default link for the
   * customer. The default link depends on the customer's launch date.
   * If the customer launched before 2023, we need to use the care advocate
   * link, which is set in the `defaultDirectCareNavigatorBookingLink` customer
   * property. If the customer launched on or after 1/1/2023, we need to use
   * the care navigator link, which is stored in a constant in shared-app-logic.
   *
   * On staging: If the user has a dedicated care navigator schedule link, then
   * that is used. For the default cases, can't use the same links that are used
   * in production so we have a test default care advocate link and a test default
   * care navigator link to use.
   */
  let link
  if (directDedicatedCareNavigatorLink) {
    link = directDedicatedCareNavigatorLink
  } else if (useCareNavigatorLink) {
    link = linkBases[environment].navigator
  } else {
    link = linkBases[environment].advocate
  }

  if (salesforceId) {
    link = link + `?sosfcontactid=${salesforceId}`
  } else {
    link = link + `?name=${name}&email=${email}`
  }

  if (isZeroProvidersFlow) {
    link = link + '&param=zp'
  } else if (isAssertiveTriageFlow) {
    link = link + '&param=at'
  }

  return link
}

export const createCareNavigatorPayload = ({
  isLocationOnsite,
  locationAddress,
  formattedAddress,
  locationUserInput,
  summaryOfIssues,
  requestHeader,
  selectedTreatmentOption,
  modalityPreference,
  typeOfRequest,
}: {
  isLocationOnsite: boolean
  locationAddress?: Address
  formattedAddress: string
  locationUserInput: string
  summaryOfIssues: string
  requestHeader: string
  selectedTreatmentOption: string | null
  modalityPreference: string
  typeOfRequest: string
}) => {
  const isSelectedLocationOnsite = isLocationOnsite
  const location = isSelectedLocationOnsite
    ? `ONSITE: ${locationAddress ? formatAddressForSubmission(locationAddress) : ''}`
    : formattedAddress || locationUserInput

  const notes =
    requestHeader === CONTACT_CARE_TEAM_REQUEST_TYPES.ASSERTIVE_TRIAGE_SCREENING
      ? `${requestHeader}, REQUEST: ${summaryOfIssues}`
      : `${
          requestHeader ? requestHeader + ', ' : ''
        }PREFERS_MODALITY[${modalityPreference}], COMING_FROM[${selectedTreatmentOption}], SEARCH_LOCATION[${location}] - ${summaryOfIssues}`

  const data = {
    preferredAppointmentType: modalityPreference,
    notes,
    typeOfRequest: navigatorTicketTypes[typeOfRequest],
  }
  return data
}

export const showDedicatedCareNavigatorSection = ({
  headShotURL,
  isInternational,
  isDedicatedCareNavigatorEnabled,
  memberBio,
}: {
  headShotURL: string
  isInternational: boolean
  isDedicatedCareNavigatorEnabled: boolean
  memberBio: string
}): boolean => {
  return isDedicatedCareNavigatorEnabled && Boolean(memberBio) && Boolean(headShotURL) && !isInternational
}

export const checkBusinessHours = ({
  showChatBusinessHoursEndingAt6PM,
}: {
  showChatBusinessHoursEndingAt6PM: boolean
}) => {
  const businessHoursTimeZone = 'America/Los_Angeles'
  const businessHours = showChatBusinessHoursEndingAt6PM
    ? [
        { day: 'Monday', from: '06:00', to: '18:00' },
        { day: 'Tuesday', from: '06:00', to: '18:00' },
        { day: 'Wednesday', from: '06:00', to: '18:00' },
        { day: 'Thursday', from: '06:00', to: '18:00' },
        { day: 'Friday', from: '06:00', to: '18:00' },
      ]
    : [
        { day: 'Monday', from: '06:00', to: '20:00' },
        { day: 'Tuesday', from: '06:00', to: '20:00' },
        { day: 'Wednesday', from: '06:00', to: '20:00' },
        { day: 'Thursday', from: '06:00', to: '20:00' },
        { day: 'Friday', from: '06:00', to: '18:00' },
      ]
  const now = new Date()
  const currentDayInWeek = now.toLocaleString('en-US', {
    timeZone: businessHoursTimeZone,
    weekday: 'long',
  })
  const currentTime = new Intl.DateTimeFormat([], {
    timeZone: businessHoursTimeZone,
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  }).format(now)
  const workingTimes = businessHours.filter((times) => times.day === currentDayInWeek)

  return workingTimes.some((time) => {
    if (currentTime >= time.from && currentTime <= time.to) return true
    return false
  })
}

import { axiosInstance } from '../axiosInstance'

export function submitHealthPlanInfoData(lyraId: $TSFixMe, data: $TSFixMe) {
  return axiosInstance.post(`/v1/patient/${lyraId}/healthplan-info`, data)
}

export function removeHealthPlanInfoData(lyraId: string, isSecondary: boolean) {
  const params = isSecondary ? { secondary: true } : undefined
  return axiosInstance.delete(`/v1/patient/${lyraId}/healthplan-info`, { params })
}

export function submitHealthPlanPaymentData(lyraId: $TSFixMe, data: $TSFixMe) {
  return axiosInstance.post(`/v1/patient-payment-method/cost-sharing/user/${lyraId}`, data)
}

export function removeHealthPlanPaymentData(lyraId: $TSFixMe) {
  return axiosInstance.delete(`/v1/patient-payment-method/cost-sharing/user/${lyraId}`)
}

export function getHealthPlanPaymentData(lyraId: $TSFixMe) {
  return axiosInstance.get(`/v1/patient-payment-method/user/${lyraId}`)
}

export function getPaymentHistoryData(lyraId: $TSFixMe) {
  return axiosInstance.get(`/v1/patient/${lyraId}/transfers`)
}

export function downloadPaymentsData(lyraId: $TSFixMe) {
  return axiosInstance.get(`/v1/patient/${lyraId}/transfers/csv`)
}

export function getEligibilityData() {
  return axiosInstance.get(`/v1/patient/eligibilityoptions`)
}

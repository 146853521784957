import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Triangle icon representing playing something
 */
export const PlayIcon: FunctionComponent<PlayIconProps> = ({ width = 11, height = 17 }) => {
  const { colors } = useTheme()
  return (
    <Svg width={width} height={height} viewBox='0 0 11 17' fill='none'>
      <Path d='M0 0V8.23285V16.9231L11 8.23285L0 0Z' fill={colors.iconInverse} />
    </Svg>
  )
}

type PlayIconProps = {
  width?: string | number
  height?: string | number
}

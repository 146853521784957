/********************************************************************************
 This is a style wrapper that creates a 100vh flex container. Header, footer
 and ContentLayout should be children of this.
 ********************************************************************************/

import React from 'react'

type Props = {
  children: $TSFixMe
}

const RootLayout = ({ children }: Props) => {
  return <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>{children}</div>
}

export default RootLayout

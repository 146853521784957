import { appRouter_DO_NOT_USE as appRouter } from '../../../routing/legacyRouterShim'

/**
 * @deprecated
 */
export function getLocationObj(/* ignored */ _state: any): { route: string; subroute: string } {
  const pathname = appRouter.state.location.pathname
  const pathArr = pathname.split('/')
  // This is extremely fragile and depends on the pathname: '/secure/onboard/<route>/<subroute>'
  return { route: pathArr[3] ?? '', subroute: pathArr[4] ?? '' }
}

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation, useNavigate } from 'react-router'

import { DirectPathBookingApplinkParams, useNavigateToDirectPathBooking } from '@lyrahealth-inc/shared-app-logic'

import { useGetIsEAPSessionCountExhausted } from './useIsEAPSessionCountExhausted'
import { getProgramCoverageBreakdown } from '../../data/customer/customerSelectors'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import { clearDirectPathState } from '../../features/appointmentDashboard/data/appointmentDashboardActions'
import { getIsHealthPlanConfirmed } from '../../features/healthPlan/data/healthPlanSelectors'
import {
  setDirectLinkIntent,
  setDirectLinkSource,
  setIsCurrentSearchDirectPath,
} from '../../features/onboard/data/onboardDataActions'
import { getOnboardDirectLinkSource } from '../../features/onboard/data/onboardSelectors'
import {
  setSelectedTreatmentOption,
  setSelectedTreatmentOptionCTPO,
} from '../../features/onboard/treatmentOptions/data/treatmentOptionsActions'

export const useGoToDirectPathBooking = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isHealthPlanConfirmed = useSelector(getIsHealthPlanConfirmed)
  const getIsEAPSessionCountExhausted = useGetIsEAPSessionCountExhausted()
  const currentProgramCoverageBreakdown = useSelector(getProgramCoverageBreakdown)?.toJS()
  const currentDirectLinkSource = useSelector(getOnboardDirectLinkSource)

  const navigationHandlers = useMemo(() => {
    return {
      goToLocationFinder: () => {
        // If the user navigates to a direct link search from an email they will already be on the location matcher page
        if (!matchPath(pathname, '/secure/onboard/match-location')) {
          navigate('/secure/onboard/match-location')
        }
      },
      goToHPIPage: () => navigate('/secure/coverage-check/onboard/plan-info', { replace: true }),
      goToHPIIneligible: () => navigate('/secure/coverage-check/onboard/ineligible-direct-path', { replace: true }),
    }
  }, [navigate, pathname])

  const navigateToDirectPath = useNavigateToDirectPathBooking({
    setSelectedTreatmentOptionCTPO: (ctpo) => dispatch(setSelectedTreatmentOptionCTPO(ctpo)),
    setSelectedTreatmentOption: (treatmentOption) => dispatch(setSelectedTreatmentOption(treatmentOption)),
    setIsCurrentSearchDirectPath: (isDirectPath) => dispatch(setIsCurrentSearchDirectPath(isDirectPath)),
    setDirectLinkIntent: (directLinkIntent) => dispatch(setDirectLinkIntent(directLinkIntent)),
    setDirectLinkSource: (directLinkSource) => dispatch(setDirectLinkSource(directLinkSource)),
    clearDirectPathState: () => dispatch(clearDirectPathState()),
    navigate: navigationHandlers,
  })

  return useCallback(
    ({ params }: { params: Partial<DirectPathBookingApplinkParams> }) => {
      navigateToDirectPath({
        ctpo: {
          clientele: params.clientele,
          treatment: params.treatment,
          partner: params.partner,
          offering: params.offering,
        },
        isHealthPlanConfirmed,
        getIsEAPSessionCountExhausted,
        currentProgramCoverageBreakdown,
        directLinkSource: currentDirectLinkSource || params.directLinkSource,
        directLinkIntent: params.directLinkIntent,
      })
    },
    [
      currentDirectLinkSource,
      currentProgramCoverageBreakdown,
      getIsEAPSessionCountExhausted,
      isHealthPlanConfirmed,
      navigateToDirectPath,
    ],
  )
}

export const ADD_BULK_KEY_VALUE = 'ADD_BULK_KEY_VALUE'
// @ts-expect-error TS(7006): Parameter 'key' implicitly has an 'any' type.
export const addBulkKeyValue = (key, value) => ({
  type: ADD_BULK_KEY_VALUE,
  key,
  value,
})

export const REMOVE_BULK_KEY = 'REMOVE_BULK_KEY'
export const removeBulkKey = (key: $TSFixMe) => ({
  type: REMOVE_BULK_KEY,
  key,
})

export const RESET_BULK_COPY = 'RESET_BULK_COPY'
export const resetBulkCopy = () => ({ type: RESET_BULK_COPY })

import { useMemo } from 'react'

import { uniq } from 'lodash-es'

import { CustomerPropertiesMap } from '../../../models'
import { PathwayOptions } from '../../pathways/constants'
import { ProgramCustomerPropertyId } from '../constants/customerProgram'

interface AvailabilityStatusReasonInfo {
  propertyName: string
  value?: boolean | number
}
interface AvailabilityInfo {
  available: boolean
  reasons: AvailabilityStatusReasonInfo[]
}

export interface CoachingToolkitAvailabilityInfo {
  individual: AvailabilityInfo
  parent: AvailabilityInfo
}

// Determines forked triage availability
export const useForkedTriageAvailability = ({
  customerPropertiesMap,
  coachingToolkitAvailability,
}: {
  customerPropertiesMap?: CustomerPropertiesMap
  coachingToolkitAvailability?: CoachingToolkitAvailabilityInfo
}) => {
  return useMemo(() => {
    let reasons: Array<AvailabilityStatusReasonInfo> = []

    if (customerPropertiesMap === undefined) return { availableOptions: [], showMultiCTAPathways: false, reasons }
    const essentialsDisabled = customerPropertiesMap[ProgramCustomerPropertyId.essentialsDisabled] === 'true'
    const shouldHideChildSearchCTA = customerPropertiesMap.shouldHideChildSearchCTA === 'true'
    const directAccessTherapyEnabled = customerPropertiesMap[ProgramCustomerPropertyId.directAccessTherapy] === 'true'

    reasons = [
      { propertyName: 'essentialsDisabled', value: essentialsDisabled },
      { propertyName: 'shouldHideChildSearchCTA', value: shouldHideChildSearchCTA },
    ]

    const neededProgramProperties = new Map<ProgramCustomerPropertyId, PathwayOptions[]>([
      [ProgramCustomerPropertyId.considerLyraTherapy, [PathwayOptions.SELF_SEARCH]],
      [
        ProgramCustomerPropertyId.directAccessTherapy,
        [
          PathwayOptions.SELF_SEARCH,
          PathwayOptions.CHILD_SEARCH,
          PathwayOptions.COUPLES_THERAPY,
          PathwayOptions.FAMILY_THERAPY,
        ],
      ],
      [ProgramCustomerPropertyId.considerMeds, [PathwayOptions.MEDICATION_CONSULTATION]],
    ])

    let availableOptions: PathwayOptions[] = []

    neededProgramProperties.forEach((pathwayOptions, propertyName) => {
      if (customerPropertiesMap[propertyName] === 'true') {
        availableOptions = [...availableOptions, ...pathwayOptions]
        reasons.push({ propertyName, value: true })
      } else {
        reasons.push({ propertyName, value: false })
      }
    })

    /**
     * If direct access therapy is enabled, but child search is disabled, remove the available option
     */
    if (directAccessTherapyEnabled && shouldHideChildSearchCTA) {
      availableOptions.splice(availableOptions.indexOf(PathwayOptions.CHILD_SEARCH), 1)
    }

    /**
     * Add toolkits(individual, parenting) if they are available options as well as the reasons they are/aren't available
     */
    if (coachingToolkitAvailability) {
      Object.keys(coachingToolkitAvailability).forEach((toolkit) => {
        const toolkitInfo = coachingToolkitAvailability[toolkit as keyof CoachingToolkitAvailabilityInfo]
        if (toolkitInfo.available) {
          availableOptions.push(
            toolkit === 'individual' ? PathwayOptions.COACHING_TOOLKIT : PathwayOptions.PARENTING_TOOLKIT,
          )
        }
        reasons = [...reasons, ...toolkitInfo.reasons]
      })
    }

    /**
     * Remove duplicate pathway options
     */
    availableOptions = uniq(availableOptions)

    const showMultiCTAPathways = !essentialsDisabled && availableOptions.length > 3
    return { availableOptions, showMultiCTAPathways, reasons }
  }, [coachingToolkitAvailability, customerPropertiesMap])
}

import React, { FunctionComponent, MutableRefObject, useEffect, useState } from 'react'
import { CursorValue, NativeSyntheticEvent, Pressable, PressableProps, TargetedEvent, ViewStyle } from 'react-native'

import { noop } from 'lodash-es'
import { useTheme } from 'styled-components/native'
import { useFocusVisible } from 'use-focus-visible'

import { getFocusBoxShadow } from '../../styles/commonStyles'
import { tID } from '../../utils'

export interface PressableOpacityProps extends PressableProps {
  style?: ViewStyle | Array<ViewStyle>
  testID?: string
  pressedBackgroundColor?: string
  hoveredBackgroundColor?: string
  disabledBackgroundColor?: string
  pressedBorderColor?: string
  disabled?: boolean
  pressedOpacity?: number
  showFocusIndicator?: boolean
  getFocusVisible?: (focusVisible: boolean) => void
  getHoverVisible?: (hoverVisible: boolean) => void
  onFocus?: () => void
  onBlur?: () => void
  accessible?: boolean
  focusable?: boolean
  accessibilityLabel?: string
  pressableRef?: MutableRefObject<any>
}

/**
 *  A PressableOpacity component that gives a specified opacity for any component
 */
export const PressableOpacity: FunctionComponent<PressableOpacityProps> = ({
  children,
  testID,
  style,
  pressedBackgroundColor,
  hoveredBackgroundColor,
  disabledBackgroundColor,
  pressedBorderColor,
  disabled,
  pressedOpacity = 0.6,
  showFocusIndicator = true,
  getFocusVisible = noop,
  getHoverVisible = noop,
  onFocus = noop,
  onBlur = noop,
  accessible = true,
  focusable = true,
  pressableRef,
  accessibilityLabel,
  ...rest
}) => {
  const { colors } = useTheme()
  const { focusVisible, onBlur: fvBlur, onFocus: fvFocus } = useFocusVisible()
  const [hoverVisible, setHoverVisible] = useState(false)

  useEffect(() => {
    getFocusVisible(focusVisible)
  }, [focusVisible, getFocusVisible])

  useEffect(() => {
    getHoverVisible(hoverVisible)
  }, [hoverVisible, getHoverVisible])

  const focus = (event: NativeSyntheticEvent<TargetedEvent>) => {
    fvFocus()
    onFocus(event)
  }

  const blur = (event: NativeSyntheticEvent<TargetedEvent>) => {
    fvBlur()
    onBlur(event)
  }

  const hoverIn = () => {
    setHoverVisible(true)
  }

  const hoverOut = () => {
    setHoverVisible(false)
  }

  return (
    <Pressable
      ref={pressableRef}
      accessible={accessible}
      focusable={focusable}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole='button'
      onFocus={focus}
      onBlur={blur}
      disabled={disabled}
      onHoverIn={hoverIn}
      onHoverOut={hoverOut}
      style={({ pressed, hovered }) => {
        const pressedStyles: {
          opacity?: number
          backgroundColor?: string
          cursor?: CursorValue
          outlineWidth: number
          boxShadow?: any
          borderColor?: string
        } = { outlineWidth: 0 }
        if (pressed) {
          pressedStyles.opacity = pressedOpacity
          if (pressedBackgroundColor !== undefined) {
            pressedStyles.backgroundColor = pressedBackgroundColor
          }
          // will work if the parent component currently has a border
          if (pressedBorderColor) {
            pressedStyles.borderColor = pressedBorderColor
          }
        }
        if (hovered) {
          pressedStyles.cursor = 'pointer'
          if (pressedBackgroundColor) {
            pressedStyles.backgroundColor = hoveredBackgroundColor ?? pressedBackgroundColor
            pressedStyles.opacity = 1
          }
        }
        if (focusVisible && showFocusIndicator) {
          pressedStyles.boxShadow = getFocusBoxShadow({ colors }).boxShadow
        }
        if (disabled && disabledBackgroundColor) {
          pressedStyles.backgroundColor = disabledBackgroundColor
        }
        return [style, pressedStyles]
      }}
      testID={tID(testID)}
      {...rest}
    >
      {children}
    </Pressable>
  )
}

import { PROGRAMS } from '../../common/constants/customerProgram'

/**
 * Logic for ProviderProfile that determines whether the user should see the provider profile
 */
export const useShouldDisplayProviderProfile = ({
  isOnsite,
  isUserInternational,
  isICASCounsellor,
  isBCTFellowshipProvider,
  outsideOnboarding,
  isCustomerCareTeam,
  isCustomerLyraBootcamp,
  providerSupportedPrograms,
  customerSupportedPrograms,
}: {
  isOnsite: boolean
  isUserInternational: boolean
  isICASCounsellor: boolean
  isBCTFellowshipProvider: boolean
  outsideOnboarding: boolean
  isCustomerCareTeam: boolean
  isCustomerLyraBootcamp: boolean
  providerSupportedPrograms: string[]
  customerSupportedPrograms: string[]
}) => {
  const checkUserProgramEligibility = !isCustomerCareTeam && !isCustomerLyraBootcamp
  const isCareTeamMember = outsideOnboarding && checkUserProgramEligibility

  /*
   * When outside onboarding, we need to make sure that the provider is apart of a program that the customer/user is eligible to see
   * Previously we used the provider lyra_type and checked the associated program configuration, however this doesn't work anymore
   * because lyra_type to program is a 1 to many relationship, i.e. Coach can map to both stressManagement and guidedSelfCare
   *
   * Furthermore, lyra_type is technically deprecated, though still an existing property in SF. Avoid using lyra_type to avoid future brittleness
   * of program checking in case we actually no longer support or update it.
   *
   * Program taxonomy, aka the CTPO, is the source of truth for which programs a provider offers. We use the program taxonomy and modalities assigned
   * to all providers to determine whether or not they serve a program supported by our user/customer. Because the provider modality is created from
   * the provider program taxonomy on the backend, we can safely use the modality to determine the program type.
   *
   * 1. Fetch all associated programs from provider modalities
   * 2. Create list of all programs offered by provider based on modalities (from customerPropertyId field)
   * 3. Check if any of these programs are offered by the user's employer (using the customerSupportedPrograms list)
   * 4. If step 3 is true, we can show the provider profile.
   * 5. Need edge case check for ICAS and BCT Fellows, who are special taxonomies that fall under DA Therapy and BC Therapy, respectively.
   */
  //   const programTaxonomy: ProviderProgramTaxonomy[] = providerTaxonomy.toJS()
  //   const providerModalitiesList: string[] = providerModalities.toJS()

  //   const providerSupportedPrograms = getProviderProgramsFromProviderModalities(providerModalitiesList)

  let userShouldSeeProviderProfile = false
  if (providerSupportedPrograms.some((program) => customerSupportedPrograms.includes(program))) {
    userShouldSeeProviderProfile = true
  }

  // We aren't currently assigning modalities for onsite providers. Onsite provider taxonomy have partner field dependent on which customer they are associated with
  // All onsite providers offer individual therapy (DirectAccess Therapy). We only have to check the customer supported programs if an onsite provider is found
  if (isOnsite && customerSupportedPrograms.includes(PROGRAMS.DIRECT_ACCESS_THERAPY.customerPropertyId)) {
    userShouldSeeProviderProfile = true
  }

  // ICAS providers have their own specific program taxonomy not covered by a modality. Check if the user is international and their employer has international therapy
  // if the current provider is an icas counsellor
  if (
    isUserInternational &&
    isICASCounsellor &&
    customerSupportedPrograms.includes(PROGRAMS.DIRECT_ACCESS_THERAPY.customerPropertyId)
  ) {
    userShouldSeeProviderProfile = true
  }

  // BCT Fellowship providers have their own specific program taxonomy not covered by a modality. Check if the user is eligible for BlendedCare Therapy
  // if the current provider is a BCT Fellow.
  if (isBCTFellowshipProvider && customerSupportedPrograms.includes(PROGRAMS.BlendedCareTherapy.customerPropertyId)) {
    userShouldSeeProviderProfile = true
  }

  return { isCareTeamMember, userShouldSeeProviderProfile }
}

import { defineMessage } from 'react-intl'

import { INFO_OVERLAY_TARGET_FNS, OPTIONS_TYPE, PreferenceMetadataConfig } from './types'
import { MIXPANEL_BUTTON_TEXT } from '../../common/constants/mixpanelConstants'
import { MEMBER_PREFERENCE_IDS, S4C_PROGRAM_OPTION_FLOW } from '../../memberPreferences/constants'
import { MEMBER_PREFERENCE_LABELS } from '../../searchForCare/messages'

const genderPreference: PreferenceMetadataConfig = {
  name: 'gender',
  title: MEMBER_PREFERENCE_LABELS.gender,
  options: [
    {
      text: MEMBER_PREFERENCE_LABELS.man,
      id: MEMBER_PREFERENCE_IDS.MAN,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.woman,
      id: MEMBER_PREFERENCE_IDS.WOMAN,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.nonBinaryPerson,
      id: MEMBER_PREFERENCE_IDS.NON_BINARY_PERSON,
    },
  ],
  optionType: OPTIONS_TYPE.CHECKBOX,
  icon: 'MemberPreferencesGenderIllustrationIcon',
  promptText: defineMessage({
    defaultMessage: 'I prefer a provider who identifies as any of the following:',
    description: 'Text prompting the user to select options they associate with',
  }),
  footerText: {
    message: defineMessage({
      defaultMessage: 'How Lyra defines non-binary',
      description: 'Text indicating a prompt to define the term non-binary',
    }),
    color: 'textInactive',
    infoOverlay: {
      onPress: {
        fn: INFO_OVERLAY_TARGET_FNS.PRESS_GENDER_PREF_DISCLAIMER,
      },
      content: defineMessage({
        defaultMessage:
          'Lyra celebrates and acknowledges all gender identities and gender expressions, while recognizing that non-binary can mean different things to different people. We define non-binary as not identifying exclusively as a woman or a man. That can mean identifying as one or more of the following: woman, man, non-binary, agender, two-spirit, gender non-conforming, and genderqueer. If you have questions or concerns, <link>contact a Care Navigator.</link>',
        description: 'An explanation of how Lyra defines the term non-binary',
      }),
      linkConfig: {
        onPress: {
          fn: INFO_OVERLAY_TARGET_FNS.PRESS_CARE_TEAM_LINK,
          params: {
            button_text: MIXPANEL_BUTTON_TEXT.CONTACT_A_CARE_NAVIGATOR,
            mixpanelEntryPoint: 'gender identity',
          },
        },
      },
    },
  },
  visibleFor: [
    S4C_PROGRAM_OPTION_FLOW.COACHING,
    S4C_PROGRAM_OPTION_FLOW.COACHING_WITH_LMS,
    S4C_PROGRAM_OPTION_FLOW.THERAPY,
  ],
}

export default genderPreference

/* eslint-disable formatjs/no-literal-string-in-jsx */

import {
  DependentTypes,
  ELIGIBILITY_TYPES,
  EligibilityField,
  EligibilityInfo,
  FieldSchema,
  FormMetadata,
  starbucksCustomerCodeFieldLabel,
  starbucksEligibilityFormHeader,
  ThemeColors,
  UiMetadata,
} from '@lyrahealth-inc/shared-app-logic'

import { getEmployeeInformationFormMetadata, getFullNameUiSchema } from '../../../formMetadata/eligibilityMetadata'
import { commonInputFieldBorderStyle } from '../../../organisms/formBody/sharedMetadata'
import { getFontStyles } from '../../../styles'

const starbucksDependentEligibilityMetadata = (
  dependentTypes: DependentTypes,
  colors: ThemeColors,
  showRegistrationTips?: boolean,
  isMobileSized?: boolean,
): FormMetadata => {
  /**
   * In order to show an addendum based on dependent type,
   * we will have a condition that checks EligibilityField.DEPENDENT_EMPLOYEE_RELATIONSHIP
   * for the value of the same id. We generate the entire schema for all the addendums
   * but only show them if the id matches the condition
   */
  const makeAddendums = () => {
    return dependentTypes.reduce(
      (acc, next) => {
        acc.schema[`addendum-${next.id}`] = {
          name: `addendum-${next.id}`,
          content: next.addendum,
          condition: {
            conditionType: 'AND',
            items: [
              {
                parentField: EligibilityField.ELIGIBILITY_TYPE,
                parentValue: ELIGIBILITY_TYPES.DEPENDENT,
              },
              {
                parentField: EligibilityField.DEPENDENT_EMPLOYEE_RELATIONSHIP,
                parentValue: next.id,
              },
            ],
          },
        }
        acc.uiOptions[`addendum-${next.id}`] = {
          'ui:options': {
            customStyles: {
              p2: {
                ...getFontStyles(colors).body.small,
                color: colors.textSecondary,
              },
            },
          },
        }
        return acc
      },
      { schema: {}, uiOptions: {} },
    )
  }

  const { schema: addendumSchema, uiOptions: addendumUIOptions } = makeAddendums()
  const addendumList = Object.values(addendumSchema)

  const customerCodeSchema: FieldSchema = {
    type: 'string',
    title: starbucksCustomerCodeFieldLabel,
    name: EligibilityField.DEPENDENT_CUSTOMER_CODE_FIELD_NAME,
    validation: {
      isRequired: true,
      isValidCustomerCode: true,
    },
    condition: {
      parentField: EligibilityField.ELIGIBILITY_TYPE,
      parentValue: ELIGIBILITY_TYPES.DEPENDENT,
    },
  }

  let relationshipInfoItems: any = [
    {
      type: 'string',
      title: 'What is your relationship?',
      name: EligibilityField.DEPENDENT_EMPLOYEE_RELATIONSHIP,
      validation: { isRequired: true },
      show: dependentTypes.length > 0,
      enum: dependentTypes.map(({ id }) => {
        return id
      }),
      enumNames: dependentTypes.map(({ dependentType }) => {
        return dependentType
      }),
      condition: {
        parentField: EligibilityField.ELIGIBILITY_TYPE,
        parentValue: ELIGIBILITY_TYPES.DEPENDENT,
      },
    },
  ]

  /**
   * Handles changing the order of where the addendum should appear
   * if the screen is mobile sized. If screen is mobile sized,
   * the form shows dependent fields in column view, so the addendum
   * has to appear under the dependent relationship field,
   * otherwise in tablet > desktop size it should appear at the bottom of the form
   */
  if (!isMobileSized) {
    relationshipInfoItems.push(customerCodeSchema)
  } else {
    relationshipInfoItems = relationshipInfoItems.concat(addendumList)
  }

  const getInputFieldUiSchema = (marginBottom: number) => ({
    'ui:options': {
      baseInputStyle: {
        marginBottom,
      },
      ...commonInputFieldBorderStyle,
    },
  })

  const dependentFormSubheaderSchema: FieldSchema = {
    name: 'dependentFormSubheader',
    content: `###### ${starbucksEligibilityFormHeader}`,
    condition: {
      parentField: EligibilityField.ELIGIBILITY_TYPE,
      parentValue: ELIGIBILITY_TYPES.DEPENDENT,
    },
  }

  const informationSubHeaderSchema: FieldSchema = {
    content: `Please add information about who is giving you access to Lyra. Use their Starbucks partner number only (no preceding US or 0)`,
    name: 'informationSubHeader',
    condition: {
      parentField: EligibilityField.ELIGIBILITY_TYPE,
      parentValue: ELIGIBILITY_TYPES.DEPENDENT,
    },
  }
  const informationSubHeaderUiSchema = {
    'ui:options': {
      customStyles: {
        p2: { marginTop: 0 },
      },
    },
  }

  const dependentRegistrationTipsSchema: FieldSchema = {
    type: 'string',
    show: showRegistrationTips,
    name: 'dependentRegistrationTips',
    condition: {
      parentField: EligibilityField.ELIGIBILITY_TYPE,
      parentValue: ELIGIBILITY_TYPES.DEPENDENT,
    },
  }
  const dependentRegistrationTipsUiSchema = { 'ui:field': 'DependentStarbucksEligibilityErrorBanner' }

  const fullNameSchema: FieldSchema[] = [
    {
      name: EligibilityField.EMPLOYEE_FIRST_NAME,
      title: 'Their first name',
      type: 'string',
      validation: {
        isRequired: true,
      },
      condition: {
        parentField: EligibilityField.ELIGIBILITY_TYPE,
        parentValue: ELIGIBILITY_TYPES.DEPENDENT,
      },
    },
    {
      name: EligibilityField.EMPLOYEE_LAST_NAME,
      title: 'Their last name',
      type: 'string',
      validation: {
        isRequired: true,
      },
      condition: {
        parentField: EligibilityField.ELIGIBILITY_TYPE,
        parentValue: ELIGIBILITY_TYPES.DEPENDENT,
      },
    },
  ]
  const fullNameUiSchema = {
    items: getFullNameUiSchema(isMobileSized),
  }

  const relationshipInfoSchema: FieldSchema = {
    type: 'array',
    inline: dependentTypes.length > 0,
    spaceBetweenFields: 16,
    condition: {
      parentField: EligibilityField.ELIGIBILITY_TYPE,
      parentValue: ELIGIBILITY_TYPES.DEPENDENT,
    },
    items: relationshipInfoItems,
  }

  let section3ItemsSchema = [relationshipInfoSchema]
  let section3ItemsUiSchema: UiMetadata[]

  const relationshipInfoUiSchema = {
    'ui:widget': 'select',
    'ui:options': {
      selectFieldStyle: {
        borderRadius: 8,
      },
      ...getInputFieldUiSchema(isMobileSized ? 16 : 0)['ui:options'],
    },
  }

  if (isMobileSized) {
    section3ItemsSchema.push(customerCodeSchema)
    section3ItemsUiSchema = [
      {
        items: [relationshipInfoUiSchema],
      },
      getInputFieldUiSchema(0),
    ]
  } else {
    section3ItemsSchema = section3ItemsSchema.concat(Object.values(addendumSchema))
    section3ItemsUiSchema = [
      {
        items: [relationshipInfoUiSchema, getInputFieldUiSchema(0)],
      },
    ].concat(Object.values(addendumUIOptions))
  }

  return getEmployeeInformationFormMetadata({
    section1ItemsSchema: [dependentFormSubheaderSchema, informationSubHeaderSchema, dependentRegistrationTipsSchema],
    section1ItemsUiSchema: [{}, informationSubHeaderUiSchema, dependentRegistrationTipsUiSchema],
    section2ItemsSchema: fullNameSchema,
    section2ItemsUiSchema: fullNameUiSchema.items,
    section3ItemsSchema,
    section3ItemsUiSchema,
    colors,
  })
}

export const generateStarbuckDependentEligibilityTypeMetadata = (
  { dependentTypes = [], showRegistrationTips = false }: EligibilityInfo,
  isMobileSized: boolean,
  colors: ThemeColors,
) => {
  return starbucksDependentEligibilityMetadata(dependentTypes, colors, showRegistrationTips, isMobileSized)
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
// getSize is not supported for expo-image
// eslint-disable-next-line no-restricted-imports
import { Image, View } from 'react-native'

import styled from 'styled-components/native'

import { APPLE_APP_STORE_LINK, CDN_ASSETS_LINK_BASE, DEFAULT_LANGUAGE } from '@lyrahealth-inc/shared-app-logic'

import { PressableOpacity } from '../../atoms'
import { Image as ExpoImage } from '../../atoms/image/Image'
import { openUrl } from '../../utils'
import { tID } from '../../utils/utils'

export interface AppleAppStoreBadgeProps {
  locale?: string
  height?: number
}

const AppStoreBadgePNG = styled(ExpoImage)<{ width: number; height: number }>(({ width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
}))

export const AppleAppStoreBadge: FunctionComponent<AppleAppStoreBadgeProps> = ({
  locale = DEFAULT_LANGUAGE,
  height = 32,
}) => {
  const [language] = locale.split('-')
  const { formatMessage } = useIntl()

  const appleAppStoreBadgesSubpath = 'apple_app_store_badges'

  const [appStoreBadgeUriState, setAppStoreBadgeUriState] = useState({ uri: '', width: 0 })

  // Since the PNGs are differing sizes we must get their original dimensions so we
  // can proportionally scale the width of the image to match the passed in height
  useEffect(() => {
    const fileSuffix = language !== 'en' ? locale : language

    const imageUri = `${CDN_ASSETS_LINK_BASE}/${appleAppStoreBadgesSubpath}/AppStoreBadge-black-${fileSuffix}.png`
    if (appStoreBadgeUriState.uri !== imageUri) {
      Image.getSize(
        imageUri,
        (originalWidth: number, originalHeight: number) => {
          setAppStoreBadgeUriState({ uri: imageUri, width: (originalWidth * height) / originalHeight })
          return
        },
        (error) => {
          console.error('Error getting image size: ', error)
          // reset to empty string and use default image if there is any issue loading the resource (values below represent dimensions of default image)
          setAppStoreBadgeUriState({ uri: '', width: (479 * height) / 160 })
        },
      )
    }
  }, [height, locale, appStoreBadgeUriState.uri, language])

  const onBadgePress = () => {
    openUrl(APPLE_APP_STORE_LINK)
  }

  return (
    <View testID={tID('AppleAppStoreBadge')}>
      <PressableOpacity
        onPress={onBadgePress}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Apple App Store Button',
          description: 'This button redirects the user to the Apple App Store',
        })}
      >
        <AppStoreBadgePNG
          width={appStoreBadgeUriState.width}
          height={height}
          source={
            appStoreBadgeUriState.uri
              ? { uri: appStoreBadgeUriState.uri }
              : require('../../assets/AppStoreBadge-black-en.png')
          }
          accessibilityIgnoresInvertColors
        />
      </PressableOpacity>
    </View>
  )
}

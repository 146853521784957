import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormattedDisplayName, FormattedMessage, useIntl } from 'react-intl'
import { Modal, NativeSyntheticEvent, Platform, Pressable, TextInputChangeEventData, ViewStyle } from 'react-native'
import ReactNativeCountryFlag from 'react-native-country-flag'

import { AsYouType, CountryCode as CountryIsoCode, getCountryCallingCode, isSupportedCountry } from 'libphonenumber-js'
import { isEmpty, isNil } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  closeModalButtonLabel,
  convertToInternationalFormat,
  convertToNationalFormat,
  CountryValues,
} from '@lyrahealth-inc/shared-app-logic'

import SearchCountryModal from './countrySelection/SearchCountryModal'
import { Flex1View } from '../../../src/templates/content/CommonViews'
import { TooltipPlacement, ToolTipTriggerConfig } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { BaseInput } from '../../atoms/formElements/BaseInput'
import { CheckIcon } from '../../atoms/icons/CheckIcon'
import { ChevronIcon, ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { IS_WEB } from '../../constants'
import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { getTypeStyles } from '../../styles/typeStyles'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

const ModalInnerBox = styled.Pressable<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  backgroundColor: colors.backgroundPrimary,
  paddingTop: '24px',
  ...(IS_WEB
    ? {
        minWidth: '345px',
        height: '600px',
        shadowColor: colors.shadowLow,
        shadowOffset: {
          width: '0',
          height: '2',
        },
        shadowOpacity: '0.25',
        shadowRadius: '4',
        elevation: '5',
        borderRadius: '10px',
        cursor: 'default',
      }
    : {
        width: '100%',
        height: '100%',
      }),
}))

const CountrySelectionDropdownButtonBox = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
})

const CountryCodeBox = styled.View(({ theme }) => ({
  marginHorizontal: theme.spacing['8px'],
}))

const CountryRowBox = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginVertical: '8px',
})

const CountryFlagAndNameBox = styled.View({
  flexDirection: 'row',
})

const CountryNameBox = styled.View({
  marginHorizontal: '10px',
})

const CountryButtonAndPhoneInputBox = styled.View<{ focused?: boolean; theme: ThemeType }>(({ focused, theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: focused ? '3px 15px' : `${theme.spacing['4px']} ${theme.spacing['16px']}`,
  backgroundColor: theme.colors.inputBackgroundDefault,
  borderWidth: focused ? '2px' : '1px',
  borderRadius: theme.spacing['8px'],
  borderColor: focused ? theme.colors.borderFocus : theme.colors.inputOutlineDefault,
}))

const CountryButtonBox = styled(Pressable)(({ theme }) => ({
  paddingRight: theme.spacing['8px'],
}))

const PhoneNumberBox = styled(Flex1View)<{ theme: ThemeType; hideCountrySelector?: boolean }>(
  ({ theme, hideCountrySelector }) => ({
    borderLeftWidth: hideCountrySelector ? 0 : '1px',
    paddingLeft: theme.spacing['8px'],
    height: '38px',
    borderColor: theme.colors.inputOutlineDefault,
  }),
)

const PhoneNumberTextInputBox = styled.TextInput({
  ...(IS_WEB && { outlineWidth: '0' }),
})

const ModalOverallBox = styled(Flex1View)({
  justifyContent: 'center',
  alignItems: 'center',
})

const ModalOuterBox = styled(Pressable)({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(74,74,74,.25)',
  flex: 1,
  ...(IS_WEB && { cursor: 'default' }),
})

const ModalHeaderBox = styled.View({
  flexDirection: 'row',
  justifyContent: 'left',
  alignItems: 'center',
  margin: '24px',
})

const ModalCancelBox = styled(Pressable)({
  position: 'absolute',
  right: '0',
})

export interface InternationalPhoneNumberEntryProps {
  onFocus: () => void
  onBlur: () => void
  placeholder?: string
  error?: string
  name: string
  value: string
  active?: boolean
  label?: string
  customerCountryList: CountryValues[]
  userCountryIsoCode: CountryIsoCode
  onChange: (arg: string) => void
  onCountryIsoCodeChange?: (arg: CountryIsoCode) => void
  linkUserCountryIsoCode?: boolean
  readOnly?: boolean
  baseInputStyle?: ViewStyle
  hideCountrySelector?: boolean
  hideInputFlag?: boolean
  toolTipContent?: string | ReactElement
  toolTipTriggerConfig?: ToolTipTriggerConfig
  toolTipPlacement?: TooltipPlacement
}

export const InternationalPhoneNumberEntry: FunctionComponent<InternationalPhoneNumberEntryProps> = ({
  label,
  value,
  placeholder,
  onChange = () => {},
  onCountryIsoCodeChange = () => {},
  onFocus,
  onBlur,
  error,
  name,
  active,
  customerCountryList,
  userCountryIsoCode,
  linkUserCountryIsoCode = false,
  readOnly,
  baseInputStyle,
  hideCountrySelector,
  hideInputFlag = false,
  toolTipContent,
  toolTipTriggerConfig,
  toolTipPlacement,
}) => {
  const { colors } = useTheme()
  const [focusRef] = useAccessibilityFocus({ active, delay: 200 })
  const supportedCountryList = isNil(customerCountryList)
    ? []
    : customerCountryList.filter((country) => isSupportedCountry(country.countryIsoCode))
  const { formatMessage } = useIntl()
  const [modalVisible, setModalVisible] = useState(false)
  const parsedNumber = new AsYouType()
  parsedNumber.input(value)
  const initialCountryCode = parsedNumber.country ?? userCountryIsoCode ?? 'US'
  const [selectedCountry, setSelectedCountry] = useState<CountryIsoCode>(initialCountryCode)
  const [phoneNumber, setPhoneNumber] = useState<string>(convertToNationalFormat(value, [initialCountryCode]))
  const selectedCallingCode = getCountryCallingCode(selectedCountry)
  const selectedCountryObj = customerCountryList?.find((country) => country.countryIsoCode === selectedCountry)
  const typeStyles = getTypeStyles(colors)
  const { lineHeight, ...prunedTypeStyles } = typeStyles.bodyDefault

  useEffect(() => {
    if (selectedCountry) {
      onChange(convertToInternationalFormat(phoneNumber, [selectedCountry]))
    }
  }, [onChange, phoneNumber, selectedCountry])

  useEffect(() => {
    if (linkUserCountryIsoCode && userCountryIsoCode && userCountryIsoCode !== selectedCountry) {
      setSelectedCountry(userCountryIsoCode)
    }
    // Only apply the selected country if linkUserCountryIsoCode enabled &
    // user country changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCountryIsoCode])

  const errorMessageId = name ? `${name}-error-message` : ''
  const countrySelectionDropdownButton = (
    <CountrySelectionDropdownButtonBox>
      {!hideInputFlag && <ReactNativeCountryFlag isoCode={selectedCountry} size={18} />}
      <CountryCodeBox>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
        <BodyText text={`+${selectedCallingCode}`} />
      </CountryCodeBox>
      {!isEmpty(customerCountryList) && (
        <ChevronIcon
          fillColor={colors.iconDefault}
          direction={modalVisible ? ChevronIconDirection.UP : ChevronIconDirection.DOWN}
        />
      )}
    </CountrySelectionDropdownButtonBox>
  )

  const getCountryButtons = ({ countryIsoCode, countryName }: CountryValues, key?: number | string) => (
    <Pressable
      accessibilitySelected={IS_WEB ? countryIsoCode === selectedCountry : undefined}
      // ignoring this TS issue, can use 'option' for role with react-native-web
      // @ts-ignore next-line
      accessibilityRole={IS_WEB ? 'option' : undefined}
      key={key}
      testID={tID(`SearchCountryModal-button-${countryName}`)}
      onPress={() => {
        setSelectedCountry(countryIsoCode)
        onCountryIsoCodeChange(countryIsoCode)
        closeModal()
      }}
    >
      <CountryRowBox>
        <CountryFlagAndNameBox>
          <ReactNativeCountryFlag size={20} isoCode={countryIsoCode} />
          <CountryNameBox>
            <BodyText text={<FormattedDisplayName type='region' value={countryIsoCode} />} size={BodyTextSize.SMALL} />
          </CountryNameBox>
          {countryIsoCode === selectedCountry && <CheckIcon withCircle={false} />}
        </CountryFlagAndNameBox>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
        <BodyText text={`+${getCountryCallingCode(countryIsoCode)}`} size={BodyTextSize.SMALL} />
      </CountryRowBox>
    </Pressable>
  )

  const closeModal = () => setModalVisible(false)

  return (
    <BaseInput
      label={label}
      error={error}
      name={name}
      style={baseInputStyle}
      errorMessageId={errorMessageId}
      toolTipContent={toolTipContent}
      toolTipTriggerConfig={toolTipTriggerConfig}
      toolTipPlacement={toolTipPlacement}
    >
      {readOnly ? (
        // eslint-disable-next-line formatjs/no-literal-string-in-jsx
        <BodyText size={BodyTextSize.DEFAULT}>{phoneNumber ? `+${selectedCallingCode} ${phoneNumber}` : '––'}</BodyText>
      ) : (
        <>
          <CountryButtonAndPhoneInputBox focused={active}>
            {hideCountrySelector ? undefined : (
              <CountryButtonBox
                ref={focusRef}
                testID={tID('CountryDropdown-Button')}
                onPress={() => {
                  setModalVisible(!modalVisible)
                }}
                disabled={isEmpty(customerCountryList)}
                accessibilityLabel={formatMessage(
                  {
                    defaultMessage: `Country or territory code {selectedCallingCode}, {selectedCountryName}`,
                    description: 'Dropdown modal to change country or territory code',
                  },
                  { selectedCallingCode: selectedCallingCode, selectedCountryName: selectedCountryObj?.countryName },
                )}
                accessibilityRole='button'
                accessibilityExpanded={modalVisible}
                accessibilityHasPopup='menu'
                accessible
              >
                {countrySelectionDropdownButton}
              </CountryButtonBox>
            )}
            <PhoneNumberBox hideCountrySelector={hideCountrySelector} defaultFlex style={typeStyles.bodyDefault}>
              <PhoneNumberTextInputBox
                testID={name}
                onChange={(e: NativeSyntheticEvent<TextInputChangeEventData>) => {
                  setPhoneNumber(convertToNationalFormat(e.nativeEvent.text, [selectedCountry]))
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                value={phoneNumber}
                keyboardType='number-pad'
                placeholder={placeholder}
                accessibilityLabel={formatMessage(
                  {
                    defaultMessage: `Search for your country or territory, {CountryName}, text field`,
                    description: 'Text field for inputing your phone number',
                  },
                  { CountryName: selectedCountryObj?.countryName },
                )}
                {...(IS_WEB && { 'aria-describedby': errorMessageId })}
                placeholderTextColor={colors.inputTextPlaceholder}
                style={[prunedTypeStyles, { height: '100%' }, Platform.OS !== 'ios' && { lineHeight }]}
              />
            </PhoneNumberBox>
          </CountryButtonAndPhoneInputBox>
          <ModalOverallBox defaultFlex>
            <Modal
              animationType={IS_WEB ? 'fade' : 'slide'}
              transparent
              visible={modalVisible}
              onRequestClose={closeModal}
            >
              <ModalOuterBox onPressOut={closeModal} focusable={false}>
                <ModalInnerBox focusable={false}>
                  <ModalHeaderBox>
                    <Subhead
                      text={
                        <FormattedMessage
                          defaultMessage='Country or territory code'
                          description="Label for various countries' or territories' phone number prefixes"
                        />
                      }
                      size={SubheadSize.SMALL}
                    />
                    <ModalCancelBox onPress={closeModal}>
                      <Subhead
                        text={formatMessage(closeModalButtonLabel)}
                        size={SubheadSize.XSMALL}
                        color={colors.textActive}
                      />
                    </ModalCancelBox>
                  </ModalHeaderBox>
                  <SearchCountryModal
                    countryList={supportedCountryList}
                    customCountryButtonDisplay={getCountryButtons}
                  />
                </ModalInnerBox>
              </ModalOuterBox>
            </Modal>
          </ModalOverallBox>
        </>
      )}
    </BaseInput>
  )
}

export interface InternationalPhoneNumberEntryRFFProps extends FieldRenderProps<string> {
  label?: string
  placeholder?: string
  customerCountryList: CountryValues[]
  userCountryIsoCode: CountryIsoCode
  onCountryIsoCodeChange?: (arg: CountryIsoCode) => void
  linkUserCountryIsoCode?: boolean
  readOnly?: boolean
  baseInputStyle?: ViewStyle
  hideCountrySelector?: boolean
  hideInputFlag?: boolean
  toolTipContent?: string | ReactElement
  toolTipTriggerConfig?: ToolTipTriggerConfig
  toolTipPlacement?: TooltipPlacement
}

export const InternationalPhoneNumberEntryRFF: FunctionComponent<InternationalPhoneNumberEntryRFFProps> = ({
  input: { onChange, onFocus, onBlur, name, value },
  meta: { active, touched, error },
  label,
  placeholder,
  customerCountryList,
  onCountryIsoCodeChange,
  linkUserCountryIsoCode = false,
  userCountryIsoCode,
  readOnly,
  baseInputStyle,
  hideCountrySelector,
  hideInputFlag,
  toolTipContent,
  toolTipTriggerConfig,
  toolTipPlacement,
}) => (
  <InternationalPhoneNumberEntry
    label={label}
    placeholder={placeholder}
    onChange={onChange}
    onFocus={onFocus}
    onBlur={onBlur}
    error={touched && error}
    name={name}
    value={value}
    active={active}
    customerCountryList={customerCountryList}
    onCountryIsoCodeChange={onCountryIsoCodeChange}
    userCountryIsoCode={userCountryIsoCode}
    linkUserCountryIsoCode={linkUserCountryIsoCode}
    readOnly={readOnly}
    baseInputStyle={baseInputStyle}
    hideCountrySelector={hideCountrySelector}
    hideInputFlag={hideInputFlag}
    toolTipContent={toolTipContent}
    toolTipTriggerConfig={toolTipTriggerConfig}
    toolTipPlacement={toolTipPlacement}
  />
)

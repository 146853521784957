import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { NATIVE_APP_FROM_LW_STORAGE_KEY } from '@lyrahealth-inc/shared-app-logic'

const usePathChanged = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const routesEnabledInNativeApp: string[] = []

    const NATIVE_APP_PATH_PREFIX = '/1lyra'
    if (routesEnabledInNativeApp.includes(pathname)) {
      window.sessionStorage.setItem(NATIVE_APP_FROM_LW_STORAGE_KEY, 'true')
      window.location.replace(window.location.href.replace(pathname, `${NATIVE_APP_PATH_PREFIX}${pathname}`))
    }
  }, [pathname])
}

export default usePathChanged

import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Linking } from 'react-native'

import styled from 'styled-components/native'

import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { BodyTextSize } from '../../styles/typeStyles'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { BodyText } from '../bodyText/BodyText'
import { Image } from '../image/Image'
import { Link } from '../link/Link'

export interface ReCaptchaPrivacyAndTermsProps {
  singleLineTerms?: boolean
}

const ReCAPTCHALegalContainer = styled.View<{ theme: ThemeType; singleLineTerms?: boolean }>(
  ({ theme, singleLineTerms }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    margin: singleLineTerms ? `${theme.spacing['24px']} 0 0 0` : `${theme.spacing['16px']} 0`,
    alignItems: 'center',
  }),
)

const ImageContainer = styled(Image)<{ size: number }>(({ size }) => ({
  height: size,
  width: size,
}))

const TextContainer = styled.View<{ theme: ThemeType; singleLineTerms?: boolean }>(({ theme, singleLineTerms }) => ({
  display: 'flex',
  flexDirection: singleLineTerms ? 'row' : 'column',
  marginLeft: singleLineTerms ? theme.spacing['4px'] : 15,
}))

export const ReCaptchaPrivacyAndTerms: FunctionComponent<ReCaptchaPrivacyAndTermsProps> = ({
  singleLineTerms = false,
}) => {
  const { isMobileSized } = useMediaQuerySize()

  const textSize = isMobileSized || singleLineTerms ? BodyTextSize.SMALL : BodyTextSize.DEFAULT

  return (
    <ReCAPTCHALegalContainer singleLineTerms={singleLineTerms}>
      <ImageContainer
        size={singleLineTerms ? 24 : 40}
        source={{ uri: 'https://www.gstatic.com/recaptcha/api2/logo_48.png' }}
        accessibilityIgnoresInvertColors
      />
      <TextContainer singleLineTerms={singleLineTerms}>
        <BodyText size={textSize}>
          <FormattedMessage
            defaultMessage='Protected by reCAPTCHA'
            description='Description informing users that the current action is protected by a CAPTCHA system'
          />
        </BodyText>
        <BodyText size={textSize}>
          <FormattedMessage
            defaultMessage={`{singleLineTerms, select, true { } other {}}<link1>Privacy</link1> & <link2>Terms</link2>`}
            description='Description text with links to Google’s privacy and terms'
            values={{
              link1: (chunks: JSX.Element) => (
                <Link
                  size={textSize}
                  text={chunks}
                  onPress={() => {
                    Linking.openURL('https://www.google.com/intl/en/policies/privacy/')
                  }}
                  style={{ ...(!singleLineTerms && { fontWeight: 'bold' }) }}
                  underline={singleLineTerms}
                />
              ),
              link2: (chunks: JSX.Element) => (
                <Link
                  size={textSize}
                  text={chunks}
                  onPress={() => {
                    Linking.openURL('https://www.google.com/intl/en/policies/terms/')
                  }}
                  style={{ ...(!singleLineTerms && { fontWeight: 'bold' }) }}
                  underline={singleLineTerms}
                />
              ),
              singleLineTerms,
            }}
          />
        </BodyText>
      </TextContainer>
    </ReCAPTCHALegalContainer>
  )
}

import React, { FunctionComponent, useEffect } from 'react'
import { useIntl } from 'react-intl'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import { LinearGradient } from 'expo-linear-gradient'
import { useTheme } from 'styled-components/native'

import { ProgressBarProps } from '@lyrahealth-inc/shared-app-logic'

import { AccessibilityRolesNative } from '../../constants'
import { tID } from '../../utils'

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  progress,
  height = 5,
  color,
  borderRadius = 200,
  backgroundColor,
  shouldAnimate = true,
  width = 100,
  duration = 300,
  style,
  accessibilityLiveRegion,
}) => {
  const { colors } = useTheme()
  const { progressBar } = colors.components
  const { formatMessage } = useIntl()
  const sanitizedProgress = Math.max(Math.min(progress, 1), 0)
  const sharedProgress = useSharedValue(sanitizedProgress)
  const barBackgroundColor = backgroundColor || progressBar.background

  useEffect(() => {
    sharedProgress.value = withTiming(sanitizedProgress, { duration: shouldAnimate ? duration : 0 })
  }, [duration, sanitizedProgress, sharedProgress, shouldAnimate])

  const animatedStyles = useAnimatedStyle(() => ({
    width: `${sharedProgress.value * 100}%`,
  }))

  const progressBarPercentage = Math.round(sanitizedProgress * 100)
  return (
    <LinearGradient
      colors={Array.isArray(barBackgroundColor) ? barBackgroundColor : [barBackgroundColor, barBackgroundColor]}
      accessibilityLiveRegion={accessibilityLiveRegion}
      accessibilityLabel={formatMessage(
        {
          defaultMessage: 'Progress bar percentage at {progressBarPercentage}',
          description: 'This bar shows the overall progress of an assessment or lesson to completion',
        },
        {
          progressBarPercentage,
        },
      )}
      testID={tID(`ProgressBar-percentage-${sanitizedProgress * 100}`)}
      accessibilityRole={AccessibilityRolesNative.PROGRESS_BAR}
      style={[
        {
          width,
          borderRadius,
        },
        style,
      ]}
    >
      <Animated.View style={[{ height, backgroundColor: color || progressBar.fill, borderRadius }, animatedStyles]} />
    </LinearGradient>
  )
}

import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import styled, { useTheme } from 'styled-components/native'

import { BRANCH_APP_LINK } from '@lyrahealth-inc/shared-app-logic'

import { PrimaryButton, TertiaryButton } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { LyraHealthAppIcon } from '../../atoms/icons/LyraHealthAppIcon'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { openUrl } from '../../utils/expo-linking'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'
import { BottomSheet } from '../bottomSheet/BottomSheet'

export interface AppPromoBottomSheetProps {
  onContinueInBrowserPress: () => void
}

const IconContainer = styled.View({
  marginTop: '-25px',
  border: '3px solid white',
  width: '65px',
  alignSelf: 'center',
  borderRadius: '100px',
})

const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  margin: theme.spacing['16px'],
}))

const TextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  gap: theme.spacing['4px'],
  marginHorizontal: theme.spacing['8px'],
  marginBottom: theme.spacing['16px'],
}))

const CTAContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '100%',
  gap: theme.spacing['8px'],
}))

export const AppPromoBottomSheet: FunctionComponent<AppPromoBottomSheetProps> = ({ onContinueInBrowserPress }) => {
  const { colors } = useTheme()
  const bottomSheetRef = React.useRef<BottomSheetGorhom>(null)
  const snapPoints = [276]

  return (
    <BottomSheet ref={bottomSheetRef} snapPoints={snapPoints} useWebPortal overflowVisible disableClose>
      <IconContainer>
        <LyraHealthAppIcon size={59} borderRadius={100} />
      </IconContainer>
      <ContentContainer testID={tID('AppPromoBottomSheet-content')}>
        <TextContainer>
          <Subhead
            size={SubheadSize.MEDIUM}
            text={
              <FormattedMessage
                defaultMessage='Get the Lyra app'
                description='Header for pop up that encourages user to download the Lyra mobile app'
              />
            }
          />
          <BodyText
            size={BodyTextSize.DEFAULT}
            text={
              <FormattedMessage
                defaultMessage='On-the-go access to all of our mental wellness resources'
                description='Description for pop up that encourages user to download the Lyra mobile app'
              />
            }
            color={colors.textSecondary}
            textAlign='center'
          />
        </TextContainer>
        <CTAContainer>
          <PrimaryButton
            text={
              <FormattedMessage
                defaultMessage='Open the app'
                description='Button that takes user to the app store if they do not have the Lyra mobile app, or opens the Lyra app if they have it'
              />
            }
            onPress={() => openUrl(BRANCH_APP_LINK)}
            testID={tID('AppPromoBottomSheet-openAppButton')}
            fullWidth
          />
          <TertiaryButton
            text={
              <FormattedMessage
                defaultMessage='Continue in browser'
                description='Button that dismisses the pop up to download the Lyra mobile app'
              />
            }
            onPress={onContinueInBrowserPress}
            testID={tID('AppPromoBottomSheet-continueInBrowserButton')}
            fullWidth
          />
        </CTAContainer>
      </ContentContainer>
    </BottomSheet>
  )
}

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export const EmailIcon: FunctionComponent<EmailIconProps> = ({ size = 25, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' testID={tID('EmailIcon')}>
      <Path
        d='M19.91 18.75H5.09C4.46484 18.7369 3.8697 18.4794 3.43225 18.0326C2.99479 17.5857 2.74986 16.9853 2.75 16.36V7.64C2.74986 7.0147 2.99479 6.41425 3.43225 5.96745C3.8697 5.52065 4.46484 5.26308 5.09 5.25H19.91C20.5352 5.26308 21.1303 5.52065 21.5678 5.96745C22.0052 6.41425 22.2501 7.0147 22.25 7.64V16.36C22.2501 16.9853 22.0052 17.5857 21.5678 18.0326C21.1303 18.4794 20.5352 18.7369 19.91 18.75ZM5.09 6.75C4.8644 6.76736 4.65336 6.86815 4.49805 7.03271C4.34274 7.19726 4.25431 7.41377 4.25 7.64V16.36C4.25431 16.5862 4.34274 16.8027 4.49805 16.9673C4.65336 17.1318 4.8644 17.2326 5.09 17.25H19.91C20.1356 17.2326 20.3466 17.1318 20.502 16.9673C20.6573 16.8027 20.7457 16.5862 20.75 16.36V7.64C20.7457 7.41377 20.6573 7.19726 20.502 7.03271C20.3466 6.86815 20.1356 6.76736 19.91 6.75H5.09Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M12.4991 13.75C12.1136 13.7527 11.7391 13.6219 11.4391 13.38L4.03906 7.58997L4.95906 6.40997L12.3591 12.2C12.3976 12.2344 12.4474 12.2534 12.4991 12.2534C12.5507 12.2534 12.6006 12.2344 12.6391 12.2L20.0391 6.40997L20.9591 7.58997L13.5591 13.38C13.259 13.6219 12.8845 13.7527 12.4991 13.75Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M5.04394 16.8799L4.72375 16.4959L9.95424 12.1349L10.2744 12.519L5.04394 16.8799Z'
        fill={fillColor || colors.iconActive}
        stroke={fillColor || colors.iconActive}
      />
      <Path
        d='M19.9628 16.8799L14.7316 12.5198L15.0517 12.1358L20.2829 16.4958L19.9628 16.8799Z'
        fill={fillColor || colors.iconActive}
        stroke={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

export interface EmailIconProps {
  size?: number
  fillColor?: string
}

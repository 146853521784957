import { useMemo, useRef } from 'react'

import round from 'lodash/round'

export type VideoWatchedStats = {
  /** An id or URI for the video */
  videoId?: string
  videoDuration: null | number
  videoEnded: boolean
  /** [start, end] times */
  playedIntervals: [number, number][]
  percentViewed?: number
  videoEvents: { event: TrackVideoPlayerEvent; time?: number }[]
}

type ResumeReason = 'app_foreground' | 'resume' | 'play'
type PauseReason = 'app_background' | 'pause' | 'player_close' | 'end'
type TrackVideoPlayerEvent = ResumeReason | PauseReason | 'seek'

type TrackVideoState = Omit<VideoWatchedStats, 'percentViewed'> & {
  // internal only state
  paused: boolean
  newInterval: null | {
    event: TrackVideoPlayerEvent
    startTime: null | number
  }
}

/**
 * Records the time intervals the user watched in a video and calculates the percentage of the video watched.
 *
 * @returns An object containing a few event functions including `progressed` and `seeked`, which continually updates
 * the watched interval times. Typically these will hook into a video player's event callbacks.
 *
 * `getStats` returns the current `playedIntervals` recorded and calculates `percentViewed`
 *
 * `playedIntervals` contains an array of [start, end] times with the time intervals of the video watched.
 * Any seeks will create new intervals.
 *
 * This is a best effort to track time intervals in the video that the user watched. It relies on the video player's
 * progress interval timer to update the current play time, which has races. It can sometimes be called with an updated
 * time /before/ we know it was due to a user action like a seek or pause. This will try to detect these cases to avoid
 * creating invalid time intervals.
 */
export function useTrackVideoWatchedIntervals(videoId?: string, progressIntervalMs?: number, debug = false) {
  const statsRef = useRef<TrackVideoState>({
    videoId,
    videoDuration: null,
    videoEvents: [],
    playedIntervals: [],
    videoEnded: false,
    paused: false,
    newInterval: null,
  })

  const state = statsRef.current

  return useMemo(() => {
    const currentInterval = () => {
      const intervals = state.playedIntervals
      return intervals.length > 0 ? intervals[intervals.length - 1] : null
    }

    const newInterval = (startTime: number, endTime: number, event: TrackVideoPlayerEvent) => {
      if (state.videoDuration && startTime >= state.videoDuration) {
        console.warn(
          `useTrackVideoWatchedIntervals: Ignoring new interval for time ${startTime} > ${state.videoDuration}`,
        )
        return false
      }
      const curInterval = currentInterval()
      if (curInterval && curInterval[0] === curInterval[1]) {
        // overwrite any interval that did not progress
        curInterval[0] = startTime
        curInterval[1] = endTime
      } else {
        // start new interval
        state.playedIntervals.push([startTime, endTime])
      }

      event && state.videoEvents.push({ event, time: startTime })

      return true
    }

    const extendCurrentInterval = (currentTime: number) => {
      const curInterval = currentInterval()
      if (curInterval) {
        if (shouldIgnoreProgress(curInterval[1], currentTime)) {
          return false
        }
        // update current interval's end time
        curInterval[1] = currentTime
        return true
      } else {
        // first interval
        return newInterval(currentTime, currentTime, 'play')
      }
    }

    // ugly:
    // Because `progressed` is called on a timer, there are races.
    // Ignore cases that create invalid played intervals.
    const shouldIgnoreProgress = (prevTime: number, currentTime: number) => {
      if (currentTime === prevTime) {
        // No-op
        return true
      }
      if (currentTime < prevTime) {
        // Ignore if time went backwards, which can happen if we received `progressed` for a backwards seek before `seek` is called.
        debug &&
          console.warn(`useTrackVideoWatchedIntervals: Ignoring backwards progress from ${prevTime} to ${currentTime}`)
        return true
      }
      if (progressIntervalMs && (currentTime - prevTime) * 1000 >= 3 * progressIntervalMs) {
        // We can receive a `progressed` call for a forward seek before `seek` is called,
        // which would incorrectly extend the current interval to the seeked time.
        // A large jump forward in the `progressed` time can also happen if the browser throttles the onProgress timer.
        // Returning false may record false positives: user seeked forward but we tracked it as watched time
        // Returning true here may lose data: user was watching the video, but the progress timer got throttled, and we dropped it as a forward-seek
        debug && console.warn(`useTrackVideoWatchedIntervals: Large progress jump from ${prevTime} to ${currentTime}`)
        return true
      }
      return false
    }

    return {
      /**
       * Calculates percentage watched based on watch intervals and returns current stats object
       */
      getStats: (): VideoWatchedStats => {
        const stats = {
          videoId: state.videoId,
          videoDuration: state.videoDuration,
          videoEnded: state.videoEnded,
          videoEvents: state.videoEvents,
          playedIntervals: state.playedIntervals,
          percentViewed: state.videoDuration
            ? calculatePercentageWatched(state.videoDuration, state.playedIntervals)
            : undefined,
        }
        state.videoEvents = []
        state.playedIntervals = []
        state.videoEnded = false
        debug && console.debug('useTrackVideoIntervals: getStats:', stats)
        return stats
      },

      setDuration: (duration: number) => {
        debug && console.debug('useTrackVideoIntervals: setDuration:', duration)
        state.videoDuration = duration
      },

      progressed: (currentTime: number) => {
        if (state.paused) {
          return
        }
        debug && console.debug('useTrackVideoIntervals: progressed:', currentTime)

        let updated = false
        if (
          !state.newInterval ||
          // Received a lagging progress update with currentTime before the state change
          (state.newInterval.startTime && currentTime < state.newInterval.startTime)
        ) {
          updated = extendCurrentInterval(currentTime)
        } else {
          updated = newInterval(state.newInterval.startTime ?? currentTime, currentTime, state.newInterval.event)
          state.newInterval = null
        }

        if (updated && state.videoDuration && currentTime >= state.videoDuration) {
          state.videoEnded = true
        }
      },

      seek: (seekTime: number) => {
        debug && console.debug('useTrackVideoIntervals: seek:', seekTime)
        state.newInterval = {
          event: 'seek',
          startTime: seekTime,
        }
      },

      ended: () => {
        if (state.paused) {
          return
        }
        debug && console.debug('useTrackVideoIntervals: ended')
        state.videoEnded = true
      },

      /**
       * Pauses tracking progress, extends the current interval to currentTime.
       */
      pause: (currentTime?: number, event?: PauseReason) => {
        if (state.paused) {
          return
        }
        debug && console.debug('useTrackVideoIntervals: pause', event, currentTime)
        currentTime !== undefined && currentTime !== null && extendCurrentInterval(currentTime)
        state.paused = true
        state.videoEvents.push({
          event: event ?? (state.videoDuration && currentTime && currentTime >= state.videoDuration ? 'end' : 'pause'),
          time: currentTime,
        })
      },

      /**
       * Resumes tracking progress and creates a new interval at currentTime.
       */
      resume: (currentTime?: number, event?: ResumeReason) => {
        if (state.paused) {
          debug && console.debug('useTrackVideoIntervals: resume', event, currentTime)
          state.paused = false
          // If currentTime is undefined, the next `progressed` will use the progressed time to start the interval.
          state.newInterval = {
            startTime: currentTime ?? null,
            event: event ?? 'resume',
          }
        }
      },
    }
  }, [state, progressIntervalMs, debug])
}

function calculatePercentageWatched(duration: number, intervals: [number, number][]): number | undefined {
  if (duration === 0) {
    return undefined
  }
  const merged = mergeOverlappingIntervals(intervals)
  const timeWatched = merged.reduce((res, interval) => {
    return res + (interval[1] - interval[0])
  }, 0)

  return Math.min(1, round(timeWatched / duration, 2))
}

function mergeOverlappingIntervals(intervals: [number, number][]) {
  intervals = intervals.slice()

  if (intervals.length === 0) {
    return intervals
  }

  // sort intervals by start time
  intervals = intervals.sort((i1, i2) => {
    return i1[0] - i2[0]
  })

  const merged: [number, number][] = [intervals[0].slice() as [number, number]]

  for (let i = 0; i < intervals.length; ++i) {
    const [curStart, curEnd] = intervals[i]
    const prevInterval = merged[merged.length - 1]
    const [_prevStart, prevEnd] = prevInterval

    if (curStart <= prevEnd) {
      // merge interval
      prevInterval[1] = Math.max(curEnd, prevEnd)
    } else {
      // new interval
      merged.push(intervals[i].slice() as [number, number])
    }
  }
  return merged
}

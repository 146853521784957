import React, { FunctionComponent, useState } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { IS_WEB } from '../../constants'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'
import { Headline, Size as HeadlineSize } from '../headline/Headline'
import { Subhead, Size as SubheadSize } from '../subhead/Subhead'

export interface DateBlockProps {
  title: string
  subtitle: string
  selected?: boolean
  size?: number
  onPress?: () => void
}

const DateBlockContainer = styled(PressableOpacity)<{
  hovered: boolean
  pressed: boolean
  selected?: boolean
  size?: number
  theme: ThemeType
}>(({ hovered, pressed, selected, size, theme }) => {
  return {
    height: size || 52,
    width: size || 52,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.backgroundSection,
    borderRadius: 5,
    overflow: 'visible',
    ...((hovered || pressed || selected) && { borderWidth: 1 }),
    ...(!!hovered && { borderColor: theme.colors.borderActive }),
    ...(!!pressed && { borderColor: theme.colors.components.dateBlock.border }),
    ...(!!selected && {
      borderColor: theme.colors.components.dateBlock.border,
      backgroundColor: theme.colors.components.dateBlock.background,
    }),
  }
})

const HeadlineContainer = styled(Headline)({
  ...(IS_WEB && {
    lineHeight: 'normal',
  }),
})

const SubHeadContainer = styled(Subhead)({
  ...(IS_WEB && {
    lineHeight: 'normal',
  }),
})

/**
 * Represents a date inside a square
 */
export const DateBlock: FunctionComponent<DateBlockProps> = ({ title, subtitle, selected, size, onPress }) => {
  const { colors } = useTheme()
  const [pressed, setPressed] = useState(false)
  const [hovered, setHovered] = useState(false)

  const handleOnPress = () => {
    setPressed((prevState) => !prevState)
  }

  const handleOnHover = () => {
    setHovered((prevState) => !prevState)
  }

  const getFontColor = () => {
    let color = colors.textSecondary
    if (pressed || selected) color = colors.components.dateBlock.text
    return color
  }

  return (
    <DateBlockContainer
      testID={tID('DateBlock')}
      onPress={onPress}
      onHoverIn={handleOnHover}
      onHoverOut={handleOnHover}
      hoveredBackgroundColor={colors.backgroundSection}
      pressedBackgroundColor={colors.backgroundHighlightTeal}
      onPressIn={handleOnPress}
      onPressOut={handleOnPress}
      hovered={hovered}
      pressed={pressed}
      selected={selected}
      size={size}
    >
      <HeadlineContainer size={HeadlineSize.EYEBROW} text={title} color={getFontColor()} />
      <SubHeadContainer size={SubheadSize.MEDIUM} text={subtitle} color={getFontColor()} />
    </DateBlockContainer>
  )
}

import { createSelector } from 'reselect'

import { isAgeTeen, SearcherType, TriageScreenerField, TriageScreenerNames } from '@lyrahealth-inc/shared-app-logic'

const getTriageState = ($$state: $TSFixMe) => $$state.get('triage')

export const getTriageMarkSelfHarmContactModalAsShown = createSelector(getTriageState, (triageState) =>
  triageState.get('markSelfHarmContactModalAsShown'),
)

export const getTriageScreeners = createSelector(getTriageState, ($$triageState) =>
  $$triageState.get('screeners').filter(($$screener: $TSFixMe) => $$screener.getIn(['data', 'mobileOnly'])),
)

export const getSearches = createSelector([getTriageState], ($$triageState) => $$triageState.get('searches'))

export const getRiskFactors = createSelector([getTriageState], ($$triageState) => $$triageState.get('riskFactors'))

export const getSelectedSearchId = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('selectedSearchId'),
)

export const getSelectedSearch = createSelector([getSearches, getSelectedSearchId], ($$searches, selectedSearchId) =>
  $$searches.find((search: $TSFixMe) => search?.get('id') === selectedSearchId),
)

export const getSelectedSearchBody = createSelector([getSelectedSearch], ($$selectedSearch) =>
  $$selectedSearch?.get('body'),
)

// Get the lyra id of the child that's selected for the current search
export const getChildLyraId = createSelector(
  [getSelectedSearchBody],
  (selectedSearchBody) => selectedSearchBody?.get('child_lyra_id') || selectedSearchBody?.get('childLyraId'),
)

// Return boolean if a child is selected for the current search
export const getIsChildSearchForCare = createSelector([getChildLyraId], (childId: string) => Boolean(childId))

export const getSelectedSearchQuestionnaires = createSelector([getSelectedSearchBody], ($$body) =>
  $$body?.get('questionnaires'),
)

export const getSelectedSearchPrimaryNeed = createSelector(
  [getSelectedSearchQuestionnaires],
  ($$questionnaires) =>
    $$questionnaires?.getIn([TriageScreenerNames.PRIMARY_NEEDS, TriageScreenerField.WHATS_ON_YOUR_MIND]) as
      | string
      | undefined,
)

export const getSelectedSearchNeeds = createSelector(
  [getSelectedSearchQuestionnaires],
  ($$questionnaires) => $$questionnaires?.get(TriageScreenerNames.PRIMARY_NEEDS) as Dict,
)

export const getTriageQuestions = createSelector([getTriageState], (triageState) => triageState.get('triageQuestions'))

export const getLastTriageScreener = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('lastTriageScreener'),
)

export const getTreatmentRecommendations = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('treatmentRecommendations'),
)

export const getShownTierPage = createSelector([getTreatmentRecommendations], ($$treatmentRecommendations) =>
  $$treatmentRecommendations?.get('shownTierPage'),
)

export const getUpdatingSearch = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('updatingSearch'),
)

export const getIsCreatingSearch = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('isCreatingSearch'),
)

export const getLoadingNextQuestion = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('loadingNextQuestion'),
)

export const getLoadingTreatmentRecommendations = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('loadingTreatmentRecommendations'),
)

export const getShowTriageHighAlertModal = createSelector([getTriageState], ($$triageState) =>
  $$triageState.getIn(['highAlert', 'showModal']),
)

export const getShowTriageHighAlertSI = createSelector([getTriageState], ($$triageState) =>
  $$triageState.getIn(['highAlert', 'isActiveSI']),
)

export const getHighAlertCallbackRequested = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('highAlertCallbackRequested'),
)

export const getEligibleTreatmentOptions = createSelector([getTreatmentRecommendations], ($$treatmentRecommendations) =>
  $$treatmentRecommendations?.get('eligibleTreatmentOptions'),
)

export const getShouldCreateNewSearch = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('shouldCreateNewSearch'),
)

export const getNavigationState = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('navigationState'),
)

export const getHasResumedCompletedSearch = createSelector([getNavigationState], ($$navigationState) =>
  $$navigationState.get('hasResumedCompletedSearch'),
)

export const getUiBackFromTreatmentRecs = createSelector([getNavigationState], ($$navigationState) =>
  $$navigationState.get('uiBackFromTreatmentRecs'),
)

export const getWordCloud = createSelector([getTriageState], ($$triageState) => $$triageState.get('wordCloud'))

export const getSearcherInfo = createSelector([getTriageState], ($$triageState) => $$triageState.get('searcherInfo'))

export const getSearcherType = createSelector([getSearcherInfo], ($$searcherInfo) => $$searcherInfo?.get('type'))

export const getSearcherDOB = createSelector([getSearcherInfo], ($$searcherInfo) => $$searcherInfo?.get('dob'))

// get searcher's age
export const getSearcherAge = createSelector([getSearcherInfo], ($$searcherInfo) => $$searcherInfo?.get('age'))

// check if the searcher's age falls within the range of a teen
export const getIsSearcherTeen = createSelector([getSearcherAge], (age) => {
  return age ? isAgeTeen(age) : false
})

export const getSearcherName = createSelector([getSearcherInfo], ($$searcherInfo) => $$searcherInfo?.get('name'))

export const getSearcherChildName = createSelector([getSearcherType, getSearcherName], (searcherType, searcherName) => {
  const searcherTypeIsChild = searcherType !== SearcherType.ADULT
  return searcherTypeIsChild ? searcherName : ''
})

export const getLoadingWordCloud = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('loadingWordCloud'),
)

export const getFetchedMetadataVersion = createSelector([getTriageState], ($$triageState) =>
  $$triageState.get('fetchedMetadataVersion'),
)

import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import classnames from 'classnames'

import { ArrowIcon, DefaultButton } from '@lyrahealth-inc/ui-core'

import styles from './backButton.module.scss'

export interface BackButtonProps {
  customClass?: string
  backButtonLocation?: string | number
}

const BackButton = ({ customClass, backButtonLocation = -1 }: BackButtonProps) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  return (
    <DefaultButton
      isOutlined
      customClass={classnames(styles['back-button'], customClass)}
      // @ts-expect-error TS(2769): No overload matches this call.
      onClick={() => navigate(backButtonLocation)}
      aria-label={formatMessage({
        defaultMessage: 'Go back',
        description: 'Click to go back to the previous page',
      })}
    >
      <ArrowIcon direction='left' />
    </DefaultButton>
  )
}

export default BackButton

import { useMemo } from 'react'

import moment from 'moment-timezone'

import { Appointment } from '../../../models'
import { SearcherType, TriageSearch, TriageSearchStatus } from '../../../models/searchForCare/SearchForCare'

/**
 * Returns any in progress search or completed search that has not led to an appointment
 */
export const useInProgressSearch = (searches: TriageSearch[], appointments?: Appointment[] | []) => {
  const inProgressSearch = useMemo(() => {
    const searchIds = new Set<string>()
    appointments?.forEach((appt) => {
      if (appt?.searchId) {
        searchIds.add(appt.searchId)
      }
    })

    return searches.find((search) => {
      const daysSinceSearchUpdate = moment().diff(moment(search?.update_date ?? search?.create_date), 'days')
      // Only adult searches should be resumed
      const isAdult = search?.body?.type === SearcherType.ADULT
      const status = search.triage_status
      const isCompletedSearchWithNoAppt = status === TriageSearchStatus.COMPLETED && !searchIds.has(search.id)
      return (
        (status === TriageSearchStatus.IN_PROGRESS || isCompletedSearchWithNoAppt) &&
        isAdult &&
        daysSinceSearchUpdate <= 7
      )
    })
  }, [searches, appointments])

  return inProgressSearch
}

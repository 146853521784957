import React from 'react'
import { defineMessage } from 'react-intl'
import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'
import { BodyText, Link } from '../atoms'
import { Linking } from 'react-native'

const excludedServices =
  'psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication, autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care'

/* eslint-disable formatjs/no-literal-string-in-jsx */
const servicesNotCovered = (
  <>
    <BodyText
      text={`Services that are not covered include: ${excludedServices}. Please check with your medical plan or `}
    />
    <Link
      text='go/behealthy'
      onPress={() => {
        Linking.openURL('https://my.sqprod.co/resources/us-go-behealthy#behavioral_health_resources')
      }}
    />
    <BodyText text=' for coverage of these excluded services.' />
  </>
)
/* eslint-enable formatjs/no-literal-string-in-jsx */

export const square: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return {
    displaySessionLimit: true,
    servicesNotCovered,
    legalNotice: (
      <CoverageNotice
        excludedServices={defineMessage({
          defaultMessage:
            'Lyra does not cover psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication, autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care.',
          description:
            'Text for describing the services that Lyra does not cover, displayed as part of the coverage notice',
        })}
        sessionLimit={numberOfSessions}
      />
    ),
  }
}

import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ViewStyle } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'
import tinyColor from 'tinycolor2'

import { NavBarProps } from '@lyrahealth-inc/shared-app-logic'

import { Subhead } from '../../atoms'
import { BackButtonSmall } from '../../atoms/backButtonSmall/BackButtonSmall'
import { LyraLogo } from '../../atoms/icons/LyraLogo'
import { XButton } from '../../atoms/xButton/XButton'
import { IS_WEB } from '../../constants'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { Modal } from '../../organisms/modal/Modal'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { CareNavigatorHeaderEntryPointButton } from '../careNavigatorHeaderEntryPointButton/CareNavigatorHeaderEntryPointButton'
import { ProgressBar } from '../progressBar/ProgressBar'

type StyledViewProps = {
  isTransparent?: boolean
  backgroundColor?: string
  borderBottomColor?: string
  style?: ViewStyle
  theme: ThemeType
  isFixed?: boolean
}

const StyledView = styled.View<StyledViewProps>(
  ({ isTransparent, backgroundColor, theme, borderBottomColor, isFixed }) => {
    const styles = {
      backgroundColor,
      width: '100%',
    }

    if (isTransparent) {
      return {
        ...styles,
        backgroundColor: 'transparent',
        ...(isFixed && {
          position: 'fixed',
        }),
      }
    } else {
      return {
        ...styles,
        backgroundColor: backgroundColor ?? theme.colors.backgroundPrimary,
        borderBottomColor: borderBottomColor ?? theme.colors.dividerPrimary,
        borderBottomWidth: '1px',
        ...(isFixed && {
          position: 'fixed',
        }),
      }
    }
  },
)

const NavBarContent = styled.View<{ theme: ThemeType; showProgressBar: boolean }>(({ theme, showProgressBar }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.breakpoints.isMobileSized
    ? theme.spacing['16px']
    : `${theme.spacing['16px']} ${theme.spacing['24px']}`,
  ...(showProgressBar && {
    paddingBottom: theme.spacing['12px'],
  }),
}))

const RightContainer = styled.View({
  flexDirection: 'row',
})

const StyledCareNavigatorHeaderEntryPointButton = styled(CareNavigatorHeaderEntryPointButton)(({ theme }) => ({
  marginRight: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['24px'],
}))

const StyledXButton = styled(XButton)({
  alignSelf: 'center',
  top: 0,
})

const PlaceHolderView = styled.View<{ theme: ThemeType; showTitle: boolean }>(({ theme, showTitle }) => ({
  /** When there's no Back or Exit button but the Lyra Logo is showing (not the title) -
   * take up the same amount of space as the Back/Exit button so the Lyra Logo is centered correctly */
  ...(!showTitle && {
    width: theme.spacing['24px'],
    height: theme.spacing['24px'],
  }),
}))

const StyledProgressBar = styled(ProgressBar)<{ theme: ThemeType }>(({ theme }) => ({
  marginHorizontal: theme.breakpoints.isMinWidthTablet ? theme.spacing['24px'] : theme.spacing['16px'],
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['12px'],
}))

export const NavBar: FunctionComponent<NavBarProps> = ({
  onBack,
  onExit,
  isTransparent,
  backgroundColor,
  borderBottomColor,
  backIconColor,
  onLayout,
  showBackButton = true,
  showCloseButton = true,
  style,
  title,
  a11yFocusedElementOnLoad,
  shouldShowCareNavigatorHeaderEntrypoint = false,
  isInternational = false,
  testId = 'NavBar',
  onCareNavigatorHeaderEntryPointButtonPress,
  careNavigatorHeaderEntryPointModal,
  careNavigatorHeaderEntryPointModalProps: {
    isShowingBottomSheet = false,
    setIsShowingBottomSheet,
    snapPoints = [],
    bottomSheetRef,
    openBottomSheet = noop,
    closeBottomSheet,
  } = {},
  careNavigatorConsolidatedBottomSheet,
  isSidePanel = false,
  useTitle = false,
  alternateExitButtonText,
  showLogoMobileSized = false,
  customRightComponent,
  isFixed,
  progressBarProps,
}) => {
  const { colors } = useTheme() as ThemeType
  const { isMinWidthTablet } = useMediaQuerySize()
  const { formatMessage } = useIntl()
  const lightBgColor = tinyColor(backgroundColor || colors.backgroundPrimary).isLight()

  const openBottomSheetWeb = () => {
    setIsShowingBottomSheet && setIsShowingBottomSheet(!isShowingBottomSheet)
  }
  // Web versions are handled by the useState whereas native mobile utilizes snap points
  const handleOnPress = () => {
    IS_WEB ? openBottomSheetWeb() : openBottomSheet()
    onCareNavigatorHeaderEntryPointButtonPress?.()
  }

  const showTitle = (useTitle || !isMinWidthTablet) && !showLogoMobileSized

  return (
    <>
      <StyledView
        isTransparent={isTransparent}
        backgroundColor={backgroundColor}
        borderBottomColor={borderBottomColor}
        onLayout={onLayout}
        style={style}
        ref={a11yFocusedElementOnLoad}
        isFixed={isFixed}
      >
        <NavBarContent showProgressBar={progressBarProps !== undefined}>
          <>
            {showBackButton && onBack ? (
              <BackButtonSmall
                testID={tID(`${testId}-backButton`)}
                onPress={onBack}
                accessibilityLabel={formatMessage({
                  defaultMessage: 'Back',
                  description: 'Back button to go to the previous page',
                })}
                color={backIconColor}
              />
            ) : (
              <PlaceHolderView showTitle={showTitle} />
            )}
          </>
          {showTitle ? (
            title && <Subhead text={title} size={SubheadSize.SMALL} />
          ) : (
            <LyraLogo
              fillColor={colors.iconActive}
              height={24}
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                margin: 'auto',
              }}
            />
          )}
          {(showCloseButton && onExit) || shouldShowCareNavigatorHeaderEntrypoint || customRightComponent ? (
            <RightContainer>
              {shouldShowCareNavigatorHeaderEntrypoint && (
                <StyledCareNavigatorHeaderEntryPointButton
                  isActive={isShowingBottomSheet}
                  isInternational={isInternational}
                  onPress={handleOnPress}
                  suppressAccessibilitySelected={IS_WEB}
                  lightBgColor={lightBgColor}
                />
              )}
              {customRightComponent}
              {onExit && (
                <StyledXButton
                  testID={tID(`${testId}-exitButton`)}
                  onPress={onExit}
                  size={24}
                  alternateText={alternateExitButtonText}
                />
              )}
            </RightContainer>
          ) : (
            <PlaceHolderView showTitle={showTitle} />
          )}
        </NavBarContent>
        {progressBarProps && <StyledProgressBar {...progressBarProps} />}
      </StyledView>
      {careNavigatorHeaderEntryPointModal || (
        <Modal
          modalContents={careNavigatorConsolidatedBottomSheet}
          onRequestClose={() => setIsShowingBottomSheet && setIsShowingBottomSheet(false)}
          visible={isShowingBottomSheet}
          bottomSheetRef={bottomSheetRef}
          snapPoints={snapPoints}
          onCloseEnd={() => {
            setIsShowingBottomSheet && setIsShowingBottomSheet(false)
            closeBottomSheet && closeBottomSheet()
          }}
          onLayout={openBottomSheet}
          isSidePanel={isSidePanel}
          scrollable
          backgroundColor={colors.backgroundSecondary}
        />
      )}
    </>
  )
}

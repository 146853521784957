import React, { FunctionComponent, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ImageSourcePropType, ScrollView } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { SelfCareWellnessTopicBackgroundImage } from '../../assets'
import { BodyText, PrimaryButton, SecondaryButton, SelfCareWellnessTopicCard, Subhead } from '../../atoms'
import { Image } from '../../atoms/image/Image'
import { useMediaQuerySize } from '../../hooks'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

export interface SelfCareWellnessTopicProps {
  topicCardContents: SelfCareWellnessTopicApiResponse[]
  onSubmit: (topicContents: SelfCareWellnessTopicContent[]) => void
  onSkip: () => void
  initialSelectedCards: SelfCareWellnessTopicContent[]
}

export type SelfCareWellnessTopicContent = {
  title: string
  id: string
  slug: string
}

export type SelfCareWellnessTopicApiResponse = SelfCareWellnessTopicContent & {
  imageUrl: string
}

const MainContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  margin: theme.breakpoints.isMobileSized
    ? `40px ${theme.spacing['16px']} ${theme.spacing['16px']} ${theme.spacing['16px']}`
    : `80px auto 80px auto`,
  maxWidth: theme.breakpoints.isMinWidthLaptop ? '726px' : '100%',
}))

const TitleContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

const DescriptionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const ButtonsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: theme.breakpoints.isMobileSized ? 'column-reverse' : 'row',
  marginLeft: theme.breakpoints.isMobileSized ? 0 : 'auto',
}))

const ContinueButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.breakpoints.isMobileSized ? 0 : theme.spacing['16px'],
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : 0,
}))

const CARD_SPACING = 12
const CARD_MOBILE_SPACING = 4

const TopicCardListContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginLeft: theme.breakpoints.isMinWidthLaptop ? `-${CARD_SPACING}px` : 'auto',
  marginRight: theme.breakpoints.isMinWidthLaptop ? `-${CARD_SPACING}px` : 'auto',
  marginBottom: '15px',
  width: theme.breakpoints.isMinWidthTablet ? '750px' : '100%',
}))

const TopicCardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  margin: theme.breakpoints.isMobileSized ? `${CARD_MOBILE_SPACING}px` : theme.spacing[`${CARD_SPACING}px`],
  flex: '1 1 47%',
  maxWidth: theme.breakpoints.isMinWidthDesktop
    ? '224px'
    : theme.breakpoints.isMinWidthTablet
    ? '224px'
    : theme.breakpoints.isMinWidthMobileXs
    ? '348px'
    : '415px',
}))

const BackgroundImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: '-1',
}))

export const SelfCareWellnessTopic: FunctionComponent<SelfCareWellnessTopicProps> = ({
  topicCardContents,
  onSkip,
  onSubmit,
  initialSelectedCards,
}) => {
  const { colors } = useTheme()
  const setInitialSelectedCards = (
    cards: SelfCareWellnessTopicContent[],
  ): { [key: string]: SelfCareWellnessTopicContent } => {
    return cards.reduce((idToTopicContent, currentValue) => {
      const id = currentValue.id
      idToTopicContent[id] = currentValue
      return idToTopicContent
    }, {})
  }

  const MAX_TOPIC_LIMIT = 3
  const { isMobileSized } = useMediaQuerySize()
  const [selectedCards, setSelectedCards] = useState<{ [key: string]: SelfCareWellnessTopicContent }>(
    setInitialSelectedCards(initialSelectedCards),
  )

  useEffect(() => {
    setSelectedCards(setInitialSelectedCards(initialSelectedCards))
  }, [initialSelectedCards])

  const onTopicCardClick = (wellnessTopicPayload: SelfCareWellnessTopicContent) => {
    const id = wellnessTopicPayload.id
    if (selectedCards[id]) {
      delete selectedCards[id]
    } else {
      if (Object.keys(selectedCards).length >= MAX_TOPIC_LIMIT) {
        return
      }
      selectedCards[id] = wellnessTopicPayload
    }

    setSelectedCards({ ...selectedCards })
  }

  // Add 2 extra invisible card content to allow flex to fill up space correctly.
  const DEFAULT_EXTRA_CARD_CONTENT: SelfCareWellnessTopicApiResponse = {
    imageUrl: '',
    title: '',
    id: 'EXTRA_CARD_ID',
    slug: '',
  }

  const cardContents = [...topicCardContents, DEFAULT_EXTRA_CARD_CONTENT, DEFAULT_EXTRA_CARD_CONTENT]

  return (
    <>
      <BackgroundImageContainer
        source={SelfCareWellnessTopicBackgroundImage as ImageSourcePropType}
        contentFit='fill'
        accessibilityIgnoresInvertColors
      />
      <ScrollView>
        <MainContainer testID={tID(`SelfCareWellnessTopic`)}>
          <TitleContainer>
            <Subhead
              level='1'
              size={SubheadSize.LARGE}
              color={colors.textSecondary}
              testID={tID('SelfCareWellnessTopicTitleContainer')}
              textAlign='center'
            >
              <>
                <Subhead
                  size={SubheadSize.LARGE}
                  text={
                    <FormattedMessage
                      description='Self care wellness topics first half'
                      defaultMessage='Pick up to {topics}'
                      values={{
                        topics: (
                          <Subhead
                            text={
                              <FormattedMessage
                                defaultMessage='3 wellness topics'
                                description='Self care wellness topics last half'
                              />
                            }
                            color={colors.textActive}
                            size={SubheadSize.LARGE}
                          />
                        ),
                      }}
                    />
                  }
                ></Subhead>
              </>
            </Subhead>
          </TitleContainer>
          <DescriptionContainer>
            <BodyText
              size={isMobileSized ? BodyTextSize.DEFAULT : BodyTextSize.LARGE}
              text={
                <FormattedMessage
                  defaultMessage='We’ll recommend videos, articles, meditations, and more based on your interests. '
                  description='Self care wellness topic description'
                />
              }
              textAlign='center'
            />
          </DescriptionContainer>
          <TopicCardListContainer>
            {topicCardContents.length > 0 &&
              cardContents.map((content, index) => {
                return (
                  <TopicCardContainer key={index}>
                    <SelfCareWellnessTopicCard
                      {...content}
                      isSelected={selectedCards[content.id] !== undefined}
                      onClick={onTopicCardClick}
                    />
                  </TopicCardContainer>
                )
              })}
          </TopicCardListContainer>
          <ButtonsContainer>
            <SecondaryButton
              onPress={onSkip}
              text={<FormattedMessage defaultMessage='Skip' description='Self care wellness topic skip button text' />}
              fullWidth={isMobileSized}
              testID={tID('SelfCareWellnessTopicSkip')}
            />
            <ContinueButtonContainer>
              <PrimaryButton
                onPress={() => {
                  onSubmit(Object.values(selectedCards))
                }}
                text={
                  <FormattedMessage
                    defaultMessage='Continue'
                    description='Self care wellness topic continue button text'
                  />
                }
                fullWidth={isMobileSized}
                testID={tID('SelfCareWellnessTopicContinue')}
              />
            </ContinueButtonContainer>
          </ButtonsContainer>
        </MainContainer>
      </ScrollView>
    </>
  )
}

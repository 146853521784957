import { PathwayOptions, User } from '@lyrahealth-inc/shared-app-logic'
import { SelfCareWellnessTopicApiResponse, SelfCareWellnessTopicContent } from '@lyrahealth-inc/ui-core-crossplatform'

import { postEssentialsTopic } from '../http/data/onboard'

export const convertIdToTopicApiResponse = (
  topicIds: string[],
  topicCardContents: SelfCareWellnessTopicApiResponse[],
): SelfCareWellnessTopicContent[] => {
  const topicIdToCardContent = Object.fromEntries(topicCardContents.map((content) => [content.id, content]))
  const initialSelectedCards: SelfCareWellnessTopicContent[] = []
  if (topicIds) {
    topicIds.forEach((id) => {
      const topicContent = topicIdToCardContent[id]
      if (topicContent) {
        initialSelectedCards.push({
          id: topicContent.id,
          title: topicContent.title,
          slug: topicContent.slug,
        })
      }
    })
  }
  return initialSelectedCards
}

export const saveTopicToEssentials = async (
  userId: string,
  topicCardContents: SelfCareWellnessTopicApiResponse[],
  pathwayOption: string,
  essentialsTopicIds: string[],
): Promise<boolean> => {
  if (pathwayOption === PathwayOptions.ESSENTIALS) {
    const topics = convertIdToTopicApiResponse(essentialsTopicIds, topicCardContents)
    const response = await postEssentialsTopic({ userId, topics })
    return response.data
  }

  return Promise.resolve(false)
}

export const getSaveTopicFn = (newUser: Partial<User>) => {
  return newUser?.lyraId
    ? saveTopicToEssentials
    : () => {
        Promise.resolve(true)
      }
}

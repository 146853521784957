import { useMemo } from 'react'

import { useAddCustomerTreatmentOptions } from './useAddCustomerTreatmentOptions'
import { CustomerTreatmentsOptionLocation, TreatmentRecommendations } from '../../../models'
import {
  stubTierOneEligibleTreatmentOptions,
  stubTierThreeEligibleTreatmentOptions,
  stubTierTwoEligibleTreatmentOptions,
  tierOneTreatmentOptionsToDisplay,
  tierOneTreatmentOptionsToDisplayIntl,
  tierThreeTreatmentOptionsToDisplay,
  tierThreeTreatmentOptionsToDisplayIntl,
  tierTwoTreatmentOptionsToDisplay,
  tierTwoTreatmentOptionsToDisplayIntl,
  TREATMENT_OPTIONS_TYPE,
} from '../../common/constants/treatmentOptions'
import { TREATMENT_RECOMMENDATION_TIER } from '../../common/constants/treatmentRecommendationsConstants'

/**
 * Gets treatment options for the treatment recommendations page for s4c
 * - Filters down treatment recommendations by tier, if user is international
 * - Adds custom treatment options per customer
 * - TODO: There is some additional logic needed for treatment exclusions
 * @param treatmentRecs Treatment recs returned from triage flow
 * @param isUserInternational
 * @param customerCopy Used to add in custom treatment options based on customer
 */
export const useGetTreatmentOptions = (
  searcherAge: number,
  treatmentRecs: TreatmentRecommendations | null,
  isUserInternational?: boolean,
  customerCopy?: { [key: string]: any },
) => {
  const addCustomerTreatmentOptions = useAddCustomerTreatmentOptions()

  const result = useMemo(() => {
    const configForSpecificTreatmentOption: { [key in TREATMENT_OPTIONS_TYPE]?: any } = {}

    if (treatmentRecs === null) {
      return {
        treatmentOptions: [],
        configForSpecificTreatmentOption,
      }
    }

    let treatmentOptions = treatmentRecs?.eligibleTreatmentOptions || []
    const shownTierPage = treatmentRecs?.shownTierPage

    switch (shownTierPage) {
      case TREATMENT_RECOMMENDATION_TIER.TIER_1:
        treatmentOptions = treatmentOptions
          ? isUserInternational
            ? treatmentOptions.filter((option) => tierOneTreatmentOptionsToDisplayIntl.includes(option))
            : treatmentOptions.filter((option) => tierOneTreatmentOptionsToDisplay.includes(option))
          : stubTierOneEligibleTreatmentOptions
        break
      case TREATMENT_RECOMMENDATION_TIER.TIER_2:
        treatmentOptions = treatmentOptions
          ? isUserInternational
            ? treatmentOptions.filter((option) => tierTwoTreatmentOptionsToDisplayIntl.includes(option))
            : treatmentOptions.filter((option) => tierTwoTreatmentOptionsToDisplay.includes(option))
          : stubTierTwoEligibleTreatmentOptions
        break
      case TREATMENT_RECOMMENDATION_TIER.TIER_3:
        treatmentOptions = treatmentOptions
          ? isUserInternational
            ? treatmentOptions.filter((option) => tierThreeTreatmentOptionsToDisplayIntl.includes(option))
            : treatmentOptions.filter((option) => tierThreeTreatmentOptionsToDisplay.includes(option))
          : stubTierThreeEligibleTreatmentOptions
    }

    // Do not show customer specifix treatment options for international users
    const treatmentOptionsToDisplay = isUserInternational
      ? treatmentOptions
      : addCustomerTreatmentOptions(
          searcherAge,
          shownTierPage as CustomerTreatmentsOptionLocation,
          treatmentOptions,
          configForSpecificTreatmentOption,
          customerCopy,
        ).treatmentOptions

    return {
      treatmentOptions: treatmentOptionsToDisplay,
      configForSpecificTreatmentOption,
    }
  }, [searcherAge, addCustomerTreatmentOptions, customerCopy, isUserInternational, treatmentRecs])

  return result
}

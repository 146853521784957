import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Icon to represent a list
 */
export const ListIcon: FunctionComponent<ListIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()
  const { fill } = colors.icons.listIcon

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 3H6C4.343 3 3 4.343 3 6V18C3 19.657 4.343 21 6 21H18C19.657 21 21 19.657 21 18V6C21 4.343 19.657 3 18 3ZM6.75 8C6.75 7.448 7.197 7 7.75 7C8.303 7 8.75 7.448 8.75 8C8.75 8.552 8.303 9 7.75 9C7.197 9 6.75 8.552 6.75 8ZM17.25 8C17.25 7.586 16.914 7.25 16.5 7.25H11.25C10.836 7.25 10.5 7.586 10.5 8C10.5 8.414 10.836 8.75 11.25 8.75H16.5C16.914 8.75 17.25 8.414 17.25 8ZM6.75 12C6.75 11.448 7.197 11 7.75 11C8.303 11 8.75 11.448 8.75 12C8.75 12.552 8.303 13 7.75 13C7.197 13 6.75 12.552 6.75 12ZM17.25 12C17.25 11.586 16.914 11.25 16.5 11.25H11.25C10.836 11.25 10.5 11.586 10.5 12C10.5 12.414 10.836 12.75 11.25 12.75H16.5C16.914 12.75 17.25 12.414 17.25 12ZM6.75 16C6.75 15.448 7.197 15 7.75 15C8.303 15 8.75 15.448 8.75 16C8.75 16.552 8.303 17 7.75 17C7.197 17 6.75 16.552 6.75 16ZM16.5 15.25C16.914 15.25 17.25 15.586 17.25 16C17.25 16.414 16.914 16.75 16.5 16.75H11.25C10.836 16.75 10.5 16.414 10.5 16C10.5 15.586 10.836 15.25 11.25 15.25H16.5ZM19.5 6C19.5 5.173 18.827 4.5 18 4.5H6C5.173 4.5 4.5 5.173 4.5 6V18C4.5 18.827 5.173 19.5 6 19.5H18C18.827 19.5 19.5 18.827 19.5 18V6Z'
        fill={`${fillColor || fill}`}
      />
    </Svg>
  )
}

type ListIconProps = {
  size?: string | number
  fillColor?: string
}

import { useSelector } from 'react-redux'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'

import {
  getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
  getIsUserInternational,
} from '../../data/user/userSelectors'

export const useIsCostMessagingEnabled = () => {
  const { isCostMessagingEnabled } = useFlags()
  const isUserInternational = useSelector(getIsUserInternational)
  const isUserAssumedInternational = useSelector(getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer)
  return isCostMessagingEnabled && !isUserInternational && !isUserAssumedInternational
}

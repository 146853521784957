import { defineMessage } from 'react-intl'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

export const bms: GetCustomerCopyFunc = () => {
  return {
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage: 'Are you an employee/retiree of Bristol Myers Squibb?',
        description: 'Asking the user if they are an employee or retiree of the company Bristol Myers Squibb',
      }),
  }
}

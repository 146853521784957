import React from 'react'

import contactModalStyles from './contact-modal.module.scss'
import ContactModal from './ContactModal'
import { axiosInstance } from '../../../../common/http/axiosInstance'
import * as ModalActions from '../generic/modal-actions'

export const CONTACT_US_TYPE = {
  CONTACT_US: 'CONTACT_US',
  CHILD_CONCERN: 'CHILD_CONCERN',
}
export const CONTACT_US_STATUS = {
  IDLE: 'IDLE',
  COMPLETE: 'COMPLETE',
}

export const SET_CONTACT_MODAL_TYPE = 'SET_CONTACT_MODAL_TYPE'
export const setContactModalType = (contactModalType: $TSFixMe) => ({
  type: SET_CONTACT_MODAL_TYPE,
  contactModalType,
})

const URL_CONTACT_CARE_MANAGER = '/unsecured/v1/utils/contactcaremanager'
const getChildConcernURL = (userId: $TSFixMe) => `/unsecured/v1/utils/childConcern/${userId}`

export const SET_CONTACT_MODAL_STATUS = 'SET_CONTACT_MODAL_STATUS'
export const setContactModalStatus = (status: $TSFixMe) => ({
  type: SET_CONTACT_MODAL_STATUS,
  status,
})

export const handleContactSubmit = ({ values, type, user, customer, matcherProvider }: $TSFixMe) => {
  return (dispatch: $TSFixMe) => {
    const userId = user.id
    const objToSend = {
      firstname: user?.firstname || '',
      lastname: user?.lastname || '',
      note: values.textareanote || '',
      careManagerName: customer?.defaultCareManager?.firstname || '',
      careManagerEmail: customer?.defaultCareManager?.username || '',
      call: values.phoneNumber,
      email: values.emailAddress || '',
      matcherProvider,
      customerName: customer.name || 'care',
    }

    switch (type) {
      case CONTACT_US_TYPE.CONTACT_US:
        return axiosInstance.post(URL_CONTACT_CARE_MANAGER, objToSend).then(() => {
          dispatch(handleSuccessfulContact())
        })
      case CONTACT_US_TYPE.CHILD_CONCERN:
        return axiosInstance.post(getChildConcernURL(userId), objToSend).then(() => {
          dispatch(handleSuccessfulContact())
        })
      default:
        return axiosInstance.post(URL_CONTACT_CARE_MANAGER, objToSend).then((response) => console.log(response))
    }
  }
}

export function handleSuccessfulContact() {
  return function (dispatch: $TSFixMe) {
    dispatch(setContactModalStatus(CONTACT_US_STATUS.COMPLETE))
  }
}

export function openContactModal(type: $TSFixMe) {
  return function (dispatch: $TSFixMe) {
    switch (type) {
      case CONTACT_US_TYPE.CONTACT_US:
        dispatch(setContactModalType(type))
        break
      case CONTACT_US_TYPE.CHILD_CONCERN:
        dispatch(setContactModalType(type))
        break
      default:
    }
    // Fill in dispatch here
    dispatch(ModalActions.setModalContent(<ContactModal />))
    dispatch(
      ModalActions.setModalProps({
        modalClass: 'contact-modal',
        showCloseButton: false,
        customStyles: contactModalStyles,
      }),
    )
  }
}

export interface AssessmentScoringCardModel {
  clinicalMeasure: CLINICAL_MEASURES
  clinicalMeasureResult: CLINICAL_MEASURE_RESULTS
  iconStyle: SCORING_ICON_STYLE
}

export interface GetOutcomesAction {
  clientId: string
  fields?: string
}

export enum CLINICAL_MEASURE_RESULTS {
  INCREASE = 'INCREASE',
  SLIGHT_INCREASE = 'SLIGHT_INCREASE',
  NO_CHANGE = 'NO_CHANGE',
  SLIGHT_DECREASE = 'SLIGHT_DECREASE',
  DECREASE = 'DECREASE',
}

export enum CLINICAL_MEASURES {
  GAD = 'GAD',
  PHQ = 'PHQ',
  'WELL-BEING' = 'WELL-BEING',
  PSS = 'PSS',
  CHRT = 'CHRT',
  PCL5 = 'PCL5',
  WSAS = 'WSAS',
}

export enum SCORING_ICON_STYLE {
  NEGATIVE_TREND_UP_STYLE = 'NEGATIVE_TREND_UP_STYLE',
  NEGATIVE_TREND_DOWN_STYLE = 'NEGATIVE_TREND_DOWN_STYLE',
  POSITIVE_TREND_UP_STYLE = 'POSITIVE_TREND_UP_STYLE',
  POSITIVE_TREND_DOWN_STYLE = 'POSITIVE_TREND_DOWN_STYLE',
  NEUTRAL_TREND_STYLE = 'NEUTRAL_TREND_STYLE',
}

import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

export const ResilienceAndCopingIllustration: FunctionComponent<{ size?: number }> = ({ size = 40 }) => {
  return (
    <Svg width={size} height={size} fill='none'>
      <G fill='#96CF51' clipPath='url(#resilience-and-coping-illustration)'>
        <Path d='M5.114 12.723a3865.86 3865.86 0 0 0 15.167 5.77s-.538-7.115-7.083-9.536c-3.537-1.308-6.42-.408-8.314.686-1.269.733-1.154 2.542.23 3.07v.01Z' />
        <Path d='M35.045 12.222c-3.642 1.4-12.321 5.704-14.763 6.27-.413.093-.903-.306-.903-.306s.538-7.114 7.083-9.535c3.931-1.457 7.046-.186 8.91 1.057.971.65.779 2.087-.317 2.504l-.01.01Z' />
        <Path d='M20.907 16.933h-1.932c-.584 0-1.057.456-1.057 1.02v15.026c0 .564.473 1.02 1.057 1.02h1.932c.584 0 1.057-.456 1.057-1.02V17.953c0-.564-.473-1.02-1.057-1.02Z' />
      </G>
      <Defs>
        <ClipPath id='resilience-and-coping-illustration'>
          <Path fill='#fff' d='M0 0h40v40H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { tID } from '../../utils'

type ProfileBookingShortTermDisabilityEvaluationIllustrationProps = {
  size?: number
}

/**
 * Illustration shown on appointment booking confirmation
 */
export const ProfileBookingShortTermDisabilityEvaluationIllustration: FunctionComponent<
  ProfileBookingShortTermDisabilityEvaluationIllustrationProps
> = ({ size = 72 }) => {
  return (
    <Svg
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 512 512'
      testID={tID('ProfileBookingShortTermDisabilityEvaluationIllustration')}
    >
      <Path
        fill='white'
        d='M257.8 1.72803C222.459 1.72803 188.17 7.78851 155.884 19.7375C79.0203 48.2289 24.9818 111.394 7.61986 193.033C-3.85359 246.262 -2.38652 295.495 12.1323 343.473C30.6679 404.745 76.1671 456.416 136.964 485.232C167.145 499.568 201.819 507.754 240.003 509.555C244.91 509.777 249.898 509.899 254.855 509.899C285.492 509.899 316.31 505.7 346.461 497.423C348.778 496.847 350.983 496.25 353.311 495.562C380.406 487.629 404.789 475.084 425.652 458.319C448.305 440.218 466.952 417.271 481.016 390.156C483.788 384.814 486.439 379.188 489.363 372.46C505.45 335.106 513.22 293.289 511.844 251.483C511.197 232.998 508.445 213.349 503.69 193.033C495.858 160.07 483.09 129.069 466.76 103.33C462.288 96.4194 458.18 90.6118 454.113 85.3911C424.762 47.5308 379.03 20.9617 321.905 8.59793C300.921 4.04497 279.35 1.73814 257.81 1.73814L257.8 1.72803Z'
      />
      <Path
        fill='#FFE9E3'
        d='M489.881 252.171C489.253 234.455 486.542 216.101 482.272 197.859C475.291 168.467 463.817 139.49 448.084 114.682C444.432 109.056 440.688 103.633 436.621 98.4225C407.118 60.38 362.408 39.2139 316.646 29.3087C265.673 18.2601 211.472 21.4877 162.473 39.6287C91.5881 65.8942 43.3268 123.433 27.5837 197.444C21.5333 225.48 19.7627 257.169 22.4742 287.613H22.2618C23.83 304.914 26.9564 321.801 31.6408 337.331C49.0534 394.87 91.3655 440.532 145.262 466.069C174.452 479.93 207.071 486.81 240.227 488.378C274.101 489.946 308.501 485.666 340.301 476.915C342.385 476.399 344.368 475.873 346.452 475.245C371.362 467.95 393.044 456.689 411.6 441.786C431.825 425.628 448.499 405.302 461.43 380.392C464.242 374.969 466.741 369.556 469.139 364.022C484.356 328.681 491.135 290.324 489.779 252.282L489.881 252.181V252.171Z'
      />
      <Path
        fill='#3BA5B5'
        d='M218.711 125.703C218.711 125.703 310.251 104.243 318.961 105.263C327.001 106.203 332.721 108.423 339.321 127.593C345.681 146.063 389.171 332.233 389.251 332.673C393.871 358.183 382.211 360.643 371.151 365.053C360.221 369.423 238.211 398.053 205.731 405.603C197.221 407.583 187.911 407.843 180.731 405.383C175.051 403.433 168.971 401.823 162.471 380.963C154.201 354.443 117.011 177.673 117.011 177.673C117.011 177.673 113.021 155.913 134.811 149.323C156.591 142.733 218.701 125.703 218.701 125.703H218.711Z'
      />
      <Path
        fill='#F7F5EF'
        d='M223.101 143.133C223.101 143.133 302.441 124.533 309.981 125.413C316.951 126.223 321.901 128.143 327.631 144.773C333.141 160.783 370.831 322.123 370.901 322.503C374.901 344.613 364.801 346.743 355.221 350.563C345.751 354.343 240.011 379.163 211.861 385.703C204.491 387.413 196.421 387.643 190.201 385.513C185.271 383.823 180.011 382.433 174.371 364.343C167.211 341.363 134.971 188.163 134.971 188.163C134.971 188.163 131.521 169.303 150.391 163.593C169.271 157.883 223.101 143.123 223.101 143.123V143.133Z'
      />
      <Path
        fill='#008295'
        d='M194.341 127.633C194.341 127.633 161.821 132.873 162.301 142.923C162.771 152.963 162.981 168.373 179.171 167.093C195.361 165.813 283.941 143.863 289.331 138.583C294.731 133.293 290.441 109.163 281.841 107.723C273.241 106.283 248.891 114.403 248.891 114.403C248.891 114.403 238.381 93.983 217.931 100.273C197.481 106.563 194.351 127.643 194.351 127.643L194.341 127.633Z'
      />
      <Path fill='#DCCDBF' d='M190.981 319.192L294.084 294.153L292.104 286L189.001 311.039L190.981 319.192Z' />
      <Path
        fill='#F39172'
        d='M241.272 179.903C245.404 179.213 248.632 177.593 252.253 180.266C258.346 184.848 250.922 201.408 266.54 197.108C277.418 194.061 280.333 193.99 282.505 203.764C285.176 215.821 285.877 221.169 272.38 223.84C265.33 225.206 264.714 228.596 265.925 234.561C269.372 250.623 267.387 250.116 244.841 254.103C240.136 254.958 238.968 250.37 237.406 242.308C235.948 234.783 232.53 233.447 225.152 235.378C214.481 238.059 210.967 235.079 208.981 223.39C207.521 214.704 208.213 211.131 218.474 208.92C234.901 205.348 228.021 199.868 227.509 189.501C227.227 184.351 230.142 182.513 238.27 180.715C239.282 180.519 240.317 180.151 241.348 180.007L241.273 179.91L241.272 179.903Z'
      />
      <Path fill='#DCCDBF' d='M187.108 298.937L328.139 264.688L326.159 256.534L185.128 290.784L187.108 298.937Z' />
      <Path fill='#FFC03C' d='M425.091 210.929L402.071 191.49L298.925 313.634L321.945 333.073L425.091 210.929Z' />
      <Path
        fill='#F69170'
        d='M425.396 179.523L432.968 185.917C437.231 189.517 437.769 195.901 434.169 200.164L425.072 210.937L402.052 191.497L411.149 180.724C414.749 176.461 421.133 175.923 425.396 179.523Z'
      />
      <Path fill='#DCCDBF' d='M298.941 313.626L291.001 346.386L321.961 333.066L298.941 313.626Z' />
    </Svg>
  )
}

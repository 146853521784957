import { useCallback } from 'react'

import {
  ageBuckets,
  getAgeBucket,
  isMinor,
  SEARCHER_TYPE_TO_MATCH_FOR,
  SearcherType,
} from '@lyrahealth-inc/shared-app-logic'

type Params = {
  matchFor: SEARCHER_TYPE_TO_MATCH_FOR
  dob?: string
  age?: number
}

/**
 * When fetching metadata, we need to take an account minors that are doing
 * searches for themselves. This hook encapsulates the logic needed to determine
 * the correct age and type if the user is a minor searching for themselves
 * otherwise, default to the original age and type
 */
export const useSearchAgeAndType = () => {
  return useCallback(({ matchFor, dob, age: originalAge }: Params) => {
    /**
     * If the user is searching for care for themselves and they are a minor,
     * we want to return primary needs and word clouds for adults for the user. We do this by asking the api
     * to return the metadata for age 18 and setting the search info type to adult
     */
    const isMinorSearchSelf = isMinor(dob ?? '') && matchFor === SEARCHER_TYPE_TO_MATCH_FOR.SELF
    const type = isMinorSearchSelf ? SearcherType.ADULT : getAgeBucket({ date: dob ?? '' })?.type ?? SearcherType.ADULT

    const minAdultAge = ageBuckets.find((a) => a.type === SearcherType.ADULT)?.min
    const age = isMinorSearchSelf ? minAdultAge : originalAge
    return { age, type }
  }, [])
}

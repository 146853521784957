import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { ViewStyle } from 'react-native'

import { isNil, isNumber } from 'lodash-es'
import { useTheme } from 'styled-components/native'

import { SliderField, STEP_INDICATOR_MARGIN_OFFSET } from './SliderField'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export const MulticolorSliderFieldRFF: FunctionComponent<FieldRenderProps<number | undefined>> = ({
  input: { value, onChange, name, onFocus, onBlur },
  meta: { touched, error, active },
  label,
  minimum,
  maximum,
  minLabel,
  maxLabel,
  initValue,
  accessibilityLabelledBy,
}) => {
  const {
    breakpoints: { isMobileSized },
    colors,
  } = useTheme() as ThemeType

  const multicolorSliderComponentColors = colors.components.multicolorSliderField

  useEffect(() => {
    if (!isNil(initValue) && isNil(value)) {
      onChange(initValue)
    }
  }, [initValue, onChange, value])

  const currValue = isNumber(value) ? value : initValue || 0

  const [multicolorThumbPositions, setMulticolorThumbPositions] = useState<number[]>([])

  const [thumbImagePressed, setThumbImagePressed] = useState(false)
  const handleOnThumbImagePressed = (thumbPressed: boolean) => {
    setThumbImagePressed(thumbPressed)
  }

  const handleUseLayoutEffect = useCallback(
    (numberOfSteps: number, sliderWidth: number) => {
      const thumbAdjustment = isMobileSized ? 48 : 40
      const stepMarkerOffset = isMobileSized ? (thumbImagePressed ? 8 : 10) : thumbImagePressed ? 20 : 22
      const newThumbPositions = [...Array(numberOfSteps + 1).keys()].map((_, index) => {
        const valueInPixels = ((sliderWidth - thumbAdjustment * 2) / numberOfSteps) * index
        return valueInPixels + (isMobileSized ? stepMarkerOffset + 7 * index : stepMarkerOffset - index)
      })
      setMulticolorThumbPositions(newThumbPositions)
    },
    [isMobileSized, thumbImagePressed],
  )

  const TRACK_HEIGHT = isMobileSized ? 40 : 32
  const THUMB_SIZE = isMobileSized ? (thumbImagePressed ? 52 : 48) : thumbImagePressed ? 44 : 40
  const STEP_MARKER_SIZE = 20

  // these colors aren't used anywhere else in the app, so not getting them from color tokens
  const backgroundColors = ['#C7A4C2', '#FFC8B9', '#FFDE74', '#E3F0B0', '#C1E0B7']

  return (
    <SliderField
      label={label}
      value={currValue}
      onValueChange={onChange}
      minimumValue={parseInt(minimum, 10)}
      maximumValue={parseInt(maximum, 10)}
      minLabel={minLabel}
      maxLabel={maxLabel}
      error={touched && error}
      name={name}
      onFocus={onFocus}
      onBlur={onBlur}
      active={active}
      accessibilityLabelledBy={accessibilityLabelledBy}
      showStepMarkers
      onUseLayoutEffect={handleUseLayoutEffect}
      onThumbImagePressed={handleOnThumbImagePressed}
      customStyles={{
        componentColors: multicolorSliderComponentColors,
        trackHeight: TRACK_HEIGHT,
        labelTextStyle: { color: colors.textSecondary },
        stepIndicatorContainerStyle: {
          marginHorizontal: isMobileSized ? 24 + STEP_INDICATOR_MARGIN_OFFSET : 32 + STEP_INDICATOR_MARGIN_OFFSET,
        },
        minTrackStyle: {
          borderColor: colors.borderDefault,
          borderWidth: 1,
          borderRightWidth: 0,
          top: 0,
          ...(value === minimum && {
            display: 'none',
          }),
          ...(value === maximum && {
            borderRightWidth: 1,
            borderTopRightRadius: TRACK_HEIGHT / 2,
            borderBottomRightRadius: TRACK_HEIGHT / 2,
            marginRight: 0,
          }),
        },
        maxTrackStyle: {
          borderColor: colors.borderDefault,
          borderWidth: 1,
          borderLeftWidth: 0,
          top: 0,
          ...(value === maximum && {
            display: 'none',
          }),
          ...(value === minimum && {
            borderLeftWidth: 1,
            borderTopLeftRadius: TRACK_HEIGHT / 2,
            borderBottomLeftRadius: TRACK_HEIGHT / 2,
            marginLeft: 0,
          }),
        },
        ...(isNumber(currValue) && {
          thumbStyle: {
            backgroundColor: backgroundColors[currValue],
            left: multicolorThumbPositions[currValue],
            boxShadow: `0px 2px 16px 0px ${multicolorSliderComponentColors.thumb.shadow.fill}`,
            position: 'absolute',
            border: `4px solid ${multicolorSliderComponentColors.thumb.border}`,
            width: THUMB_SIZE,
            height: THUMB_SIZE,
          } as ViewStyle,
          stepMarkerStyles: backgroundColors.map((backgroundColor: string) => {
            return {
              width: STEP_MARKER_SIZE,
              height: STEP_MARKER_SIZE,
              top: 6,
              borderRadius: STEP_MARKER_SIZE,
              backgroundColor,
            }
          }),
        }),
      }}
    />
  )
}

import React from 'react'
import { Outlet, Route } from 'react-router-dom'

import CustomerSpecificInstanceRedirectionContainer from './components/customerSpecificInstanceRedirection/CustomerSpecificInstanceRedirectionContainer'
import Homepage from './components/Homepage/Homepage'
import { MainAppLayout } from '../../common/components/appLayout/MainAppLayout'
import FloatingFooter from '../../common/components/footer/FloatingFooter'
import MicrositeIntlProvider from '../microsite/MicrositeIntlProvider'

const PhoneNumberDirectory = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PhoneNumberDirectory" */ '../../common/components/phoneNumberDirectory/PhoneNumberDirectory'
    ),
)

const MicrositeRouteContainer = (
  <MicrositeIntlProvider>
    <MainAppLayout>
      <Outlet />
      <FloatingFooter hideFooter={true} />
    </MainAppLayout>
  </MicrositeIntlProvider>
)

export const MicrositeRoutes = (
  <Route element={MicrositeRouteContainer}>
    <Route
      element={
        <>
          <Outlet />
          <CustomerSpecificInstanceRedirectionContainer />
        </>
      }
    >
      <Route index element={<Homepage />} />
      <Route path='care-team-phone-numbers' element={<PhoneNumberDirectory />} />
    </Route>
  </Route>
)

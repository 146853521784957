import React from 'react'
import { connect } from 'react-redux'

import { LANG_QUERY_PARAM, LyraIntlProps, LyraIntlProvider } from '@lyrahealth-inc/shared-app-logic/src/features/intl'

import { getRegistrationSelectedCountryIso } from '../../data/register/registerSelectors'
import {
  getTimeZoneOrDefaultIgnoreOnboarding,
  getUserCountryCode,
  getUserDisplayLanguageOrDefault,
} from '../../data/user/userSelectors'

type StateProps<T extends string = string> = Pick<LyraIntlProps<T>, 'countryIsoCode' | 'timeZone'> & {
  userDisplayLanguage: T
}

const mapState = (state: any): StateProps => {
  return {
    countryIsoCode: getUserCountryCode(state) || getRegistrationSelectedCountryIso(state),
    timeZone: getTimeZoneOrDefaultIgnoreOnboarding(state),
    userDisplayLanguage: getUserDisplayLanguageOrDefault(state),
  }
}

const AppIntlProvider = <T extends string>({
  children,
  userDisplayLanguage,
  ...restProps
}: LyraIntlProps<T> & StateProps<T>) => {
  // useSearchParams hook isn't used here because the url might change from shared-app-logic.
  // Because shared-app-logic can't call setSearchParams, it updates the url with the global URL() interface.
  // This will ultimately result in useSearchParams values to be stale here.
  const url = new URL(window.location.href)
  const languageFromQueryParams = url.searchParams.get(LANG_QUERY_PARAM)

  return (
    <LyraIntlProvider {...restProps} initialLanguage={languageFromQueryParams ?? userDisplayLanguage}>
      {children}
    </LyraIntlProvider>
  )
}

export default connect(mapState)(AppIntlProvider)

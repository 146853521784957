import React from 'react'
import { defineMessage, MessageDescriptor } from 'react-intl'

import {
  customerCodeValidationError,
  ELIGIBILITY_TYPES,
  EligibilityField,
  EligibilityInfo,
  EligibilityType,
  extendMetadata,
  GenerateCustomMetadata,
  GetCustomerCopyFunc,
  invalidEligibilityDobMessage,
} from '@lyrahealth-inc/shared-app-logic'

import { WalmartDependentEligibilityInformation } from './registration/walmart/WalmartDependentEligibilityInformation'
import { generateWalmartDependentEligibilityMetadata } from './registration/walmart/walmartEligibilityDependentMetadata'
import { generateWalmartEmployeeEligibilityMetadata } from './registration/walmart/walmartEligibilityEmployeeMetadata'
import { WalmartEligibilityErrorBanner } from './registration/walmart/WalmartEligibilityErrorBanner'
import { WalmartEligibilityErrorMessage } from './registration/walmart/WalmartEligibilityErrorMessage'
import { generateEligibilityTypeQuestions } from '../formMetadata/eligibilityMetadata'
import { BasicInfoField } from '../organisms/setUpAccountForm/basicInfoMetadata'

export const generateWalmartEligibilityMetadata: GenerateCustomMetadata = ({
  eligibilityInfo,
  eligibilityType,
  intl,
  isMobileSized,
  colors,
}) => {
  const eligibilityTypeQuestions = generateEligibilityTypeQuestions(eligibilityInfo, intl)
  const employeeMetadata = extendMetadata(
    eligibilityTypeQuestions,
    generateWalmartEmployeeEligibilityMetadata(intl, eligibilityInfo.isAttestation),
  )
  const dependentMetadata = extendMetadata(
    eligibilityTypeQuestions,
    generateWalmartDependentEligibilityMetadata(eligibilityInfo, intl, colors, isMobileSized),
  )
  return eligibilityType === ELIGIBILITY_TYPES.DEPENDENT ? dependentMetadata : employeeMetadata
}

const getCustomFields = (eligibilityInfo: EligibilityInfo) => {
  const isPristineConfirmEligibility = eligibilityInfo.isPristineConfirmEligibility
  return {
    DependentEligibilityErrorBanner: () => (
      <WalmartEligibilityErrorBanner isDependent isPristineConfirmEligibility={isPristineConfirmEligibility} />
    ),
    DependentEligibilityInformation: () => <WalmartDependentEligibilityInformation />,
  }
}

export const walmart: GetCustomerCopyFunc = () => {
  return {
    generateRegistrationFormSubHeaderText: () =>
      defineMessage({
        defaultMessage: 'Let’s get you ready to register. All you’ll need is a few minutes and your WIN number.',
        description:
          'Informing Walmart users they need to enter their Walmart Identification Number (WIN) during registration',
      }),
    generateConfirmEligibilityFormSubHeaderText: () =>
      defineMessage({
        defaultMessage:
          'To make sure you can continue accessing care, you’ll need to add a WIN number and date of birth to your profile.',
        description:
          'Informing Walmart users they need to enter their Walmart Identification Number (WIN) to confirm they are eligible',
      }),
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage: 'Are you an associate of Walmart?',
        description: 'Asking if the user is an associate of the company Walmart',
      }),
    eligibilityQuestionSubtitle: null, // return null to not show
    modalityQuestionnaireText: {
      modalityLabelAddendums: {
        video: '',
      },
    },
    generateEmployeeEligibilityError: (isConfirmEligibility?: boolean, isUnexpectedError?: boolean) => (
      <WalmartEligibilityErrorMessage
        isPristineConfirmEligibility={isConfirmEligibility}
        isWalmartAPIError={isUnexpectedError}
      />
    ),
    eligibilityMetadata: {
      generateMetadata: generateWalmartEligibilityMetadata,
      getCustomFields,
    },
    generateEligibilityCheckSubmissionErrors: (
      eligibilityType?: EligibilityType,
    ): {
      [fieldName: string]: MessageDescriptor
    } => {
      const isDependent = eligibilityType === 'dependent_other'
      if (isDependent) {
        return {
          [EligibilityField.EMPLOYEE_DATE_OF_BIRTH]: invalidEligibilityDobMessage,
          [EligibilityField.DEPENDENT_CUSTOMER_CODE_FIELD_NAME]: customerCodeValidationError,
        }
      } else {
        return {
          [BasicInfoField.USER_DATE_OF_BIRTH]: invalidEligibilityDobMessage,
          [EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME]: customerCodeValidationError,
        }
      }
    },
    generateConfirmEligibilityFormDefaultError: () => <WalmartEligibilityErrorMessage isPristineConfirmEligibility />,
  }
}

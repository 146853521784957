import React, { FunctionComponent } from 'react'
import { Circle, G, Mask, Path, Svg } from 'react-native-svg'

import { tID } from '../../utils'

type ProfileBookingLyraCommunityMedicationIllustrationProps = {
  size?: number
}

/**
 * Illustration shown on appointment booking confirmation
 */
export const ProfileBookingLyraCommunityMedicationIllustration: FunctionComponent<
  ProfileBookingLyraCommunityMedicationIllustrationProps
> = ({ size = 72 }) => {
  return (
    <Svg
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 512 512'
      testID={tID('ProfileBookingLyraCommunityMedicationIllustration')}
    >
      <Path
        fill='#F5F2EE'
        d='M257.8 1.72803C222.459 1.72803 188.17 7.78851 155.884 19.7375C79.0203 48.2289 24.9818 111.394 7.61986 193.033C-3.85359 246.262 -2.38652 295.495 12.1323 343.473C30.6679 404.745 76.1671 456.416 136.964 485.232C167.145 499.568 201.819 507.754 240.003 509.555C244.91 509.777 249.898 509.899 254.855 509.899C285.492 509.899 316.31 505.7 346.461 497.423C348.778 496.847 350.983 496.25 353.311 495.562C380.406 487.629 404.789 475.084 425.652 458.319C448.305 440.218 466.952 417.271 481.016 390.156C483.788 384.814 486.439 379.188 489.363 372.46C505.45 335.106 513.22 293.289 511.844 251.483C511.197 232.998 508.445 213.349 503.69 193.033C495.858 160.07 483.09 129.069 466.76 103.33C462.288 96.4194 458.18 90.6118 454.113 85.3911C424.762 47.5308 379.03 20.9617 321.905 8.59793C300.921 4.04497 279.35 1.73814 257.81 1.73814L257.8 1.72803Z'
      />
      <G opacity='0.6'>
        <Circle cx='95.5' cy='130.5' r='22.5' fill='#85CCD5' />
        <Circle cx='421.5' cy='256.5' r='22.5' fill='#85CCD5' />
        <Circle cx='233.5' cy='448.5' r='22.5' fill='#85CCD5' />
        <Path d='M133 140L388 241' stroke='#85CCD5' strokeWidth={13} strokeLinecap='round' strokeDasharray='26 26' />
        <Path d='M110 161L218 403' stroke='#85CCD5' strokeWidth={13} strokeLinecap='round' strokeDasharray='26 26' />
        <Path d='M256 414L399 282' stroke='#85CCD5' strokeWidth={13} strokeLinecap='round' strokeDasharray='26 26' />
      </G>
      <Path
        fill='#FFC03C'
        d='M319.818 388.442H188.349C179.354 388.442 172.059 381.147 172.059 372.152L165.877 161.421L345.021 172.227L336.097 372.496C335.905 381.349 328.671 388.432 319.818 388.432V388.442Z'
      />
      <Path
        fill='#A3C979'
        d='M221.419 307.087C212.3 299.49 198.75 300.723 191.152 309.841C183.555 318.96 184.788 332.51 193.907 340.107L227.549 368.137C236.668 375.735 250.219 374.501 257.816 365.383C265.413 356.265 264.18 342.714 255.061 335.117L221.419 307.087Z'
      />
      <Path
        fill='#7FB04D'
        d='M257.815 365.373C250.217 374.489 236.669 375.723 227.543 368.125L211.506 354.871L239.016 321.847L255.053 335.101C264.169 342.699 265.403 356.247 257.805 365.373H257.815Z'
      />
      <Path
        fill='#A3C979'
        d='M282.326 303.26L272.2 345.863C269.456 357.409 276.592 368.995 288.138 371.739C299.685 374.484 311.271 367.348 314.015 355.801L324.141 313.199C326.886 301.652 319.75 290.066 308.203 287.322C296.656 284.577 285.071 291.713 282.326 303.26Z'
      />
      <Path
        fill='#7FB04D'
        d='M308.223 287.345C319.767 290.087 326.91 301.681 324.158 313.226L319.423 333.481L277.607 323.536L282.342 303.28C285.084 291.736 296.679 284.593 308.223 287.345Z'
      />
      <Mask
        id='mask0_3922_6650'
        // maskType='luminance' // when react-native-svg can be updated, we can flip this on
        maskUnits='userSpaceOnUse'
        x={165}
        y={161}
        width={181}
        height={228}
      >
        <Path
          d='M319.817 388.442H188.348C179.353 388.442 172.058 381.147 172.058 372.152L165.876 161.421L345.02 172.227L336.096 372.496C335.904 381.349 328.67 388.432 319.817 388.432V388.442Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_3922_6650)'>
        <Path
          fill='white'
          d='M340.599 292.941L214.846 291.585C209.443 291.494 205.092 287.123 205.032 281.72L205.133 211.736C205.072 206.182 209.554 201.639 215.109 201.639L347.367 202.428L340.599 292.941Z'
        />
        <Path fill='#DCCDBF' d='M342.864 223.4H233.128V235.127H342.864V223.4Z' />
        <Path fill='#DCCDBF' d='M342.864 249.182H233.128V259.896H342.864V249.182Z' />
      </G>
      <Path
        fill='#6ECBD3'
        d='M347.387 145.667L164.479 144.473C161.505 144.453 159.036 146.749 158.833 149.714L157.012 177.052C156.8 180.289 159.37 183.041 162.617 183.041H348.429C351.626 183.041 354.175 180.38 354.044 177.193L352.961 151.049C352.84 148.054 350.381 145.687 347.387 145.667Z'
      />
      <Path
        fill='#6ECBD3'
        d='M334.615 124.582L179.936 119.007C175.08 118.835 170.82 121.911 169.96 126.231L162.888 161.714H348.001L343.893 132.261C343.296 128.001 339.371 124.754 334.615 124.582Z'
      />
      <Path
        fill='#FFC03C'
        d='M319.922 384.557H189.972C181.082 384.557 173.872 377.347 173.872 368.457L167.762 160.177L344.822 170.857L336.002 368.797C335.812 377.547 328.662 384.547 319.912 384.547L319.922 384.557Z'
      />
      <Mask
        id='mask1_3922_6650'
        // maskType='luminance' // when react-native-svg can be updated, we can flip this on
        maskUnits='userSpaceOnUse'
        x={167}
        y={160}
        width={178}
        height={225}
      >
        <Path
          fill='white'
          d='M319.922 384.557H189.972C181.082 384.557 173.872 377.347 173.872 368.457L167.762 160.177L344.822 170.857L336.002 368.797C335.812 377.547 328.662 384.547 319.912 384.547L319.922 384.557Z'
        />
      </Mask>
      <G mask='url(#mask1_3922_6650)'>
        <Path
          fill='white'
          d='M341.852 337.237L217.562 336.027C212.222 335.947 207.922 332.057 207.862 327.247L206.562 226.377C206.502 221.427 210.932 217.387 216.422 217.387L347.142 218.087L341.852 337.237Z'
        />
        <Path fill='#DCCDBF' d='M343.842 244.627H235.382V259.297H343.842V244.627Z' />
        <Path fill='#DCCDBF' d='M343.842 276.067H235.382V290.737H343.842V276.067Z' />
      </G>
      <Path
        fill='#6ECBD3'
        d='M347.172 144.607L166.392 143.427C163.452 143.407 161.012 145.677 160.812 148.607L159.012 175.627C158.802 178.827 161.342 181.547 164.552 181.547H348.202C351.362 181.547 353.882 178.917 353.752 175.767L352.682 149.927C352.562 146.967 350.132 144.627 347.172 144.607Z'
      />
      <Path
        fill='#6ECBD3'
        d='M333.182 124.317L182.992 119.007C178.272 118.837 174.142 121.777 173.302 125.887L166.432 159.717H346.182L342.192 131.637C341.612 127.577 337.802 124.487 333.182 124.317Z'
      />
    </Svg>
  )
}

import { useEffect, useState } from 'react'

import axios from 'axios'

import { SELF_CARE_CATEGORY_TYPE, translateToEssentialSiteCode } from '@lyrahealth-inc/shared-app-logic'

import { SelfCareWellnessTopicApiResponse } from '../pages'

const GRAPH_QUERY = `query getCategories($siteCategory: [String], $site:[String], $type:[QueryArgument]) {
  entries(section:"categories"
    relatedToCategories: { slug: $siteCategory }
    site: $site
    level: 1
    categoryType: $type
  ) {
    id
    title
    slug
    foreground: image { url }
    type: categoryType
    siteCategory {
      id
      title
      slug
    }
  }
}`

enum GRAPH_QUERY_VARIABLE {
  CATEGORY_TYPE = 'topic',
  SITE_CATEGORY = 'essentials',
}

export enum SELF_CARE_TOPIC {
  RECENT = 'Recent',
  SAVED = 'Saved',
  NAVIGATING_STUDENT_LIFE = 'Navigating Student Life',
  EMBRACING_IDENTITY = 'Embracing Identity',
}

const SELF_CARE_TOPIC_TITLE_BLACK_LIST = new Set([SELF_CARE_TOPIC.RECENT.toString(), SELF_CARE_TOPIC.SAVED.toString()])

const SELF_CARE_TOPIC_OUS_BLACK_LIST = new Set([
  SELF_CARE_TOPIC.EMBRACING_IDENTITY.toString(),
  SELF_CARE_TOPIC.NAVIGATING_STUDENT_LIFE.toString(),
])

export type GraphQLTopicApiForeground = {
  url: string
}

export type GraphQLTopicApiResponse = {
  title: string
  type: SELF_CARE_CATEGORY_TYPE
  foreground: GraphQLTopicApiForeground[]
  id: string
  slug: string
}

export const filterTopicApiResponse = (responses: GraphQLTopicApiResponse[]) => {
  return responses.filter(
    (response) =>
      !SELF_CARE_TOPIC_TITLE_BLACK_LIST.has(response.title) && response.type == SELF_CARE_CATEGORY_TYPE.TOPIC,
  )
}

export const filterOUSTopic = (responses: GraphQLTopicApiResponse[]) => {
  return responses.filter((response) => !SELF_CARE_TOPIC_OUS_BLACK_LIST.has(response.title))
}

type SelfCareWellnessTopicFetcherParams = {
  essentialsCmsUrl: string
  lyraLanguage: string
  shouldFilterOUS: boolean
}

export const useSelfCareWellnessTopicFetcher = ({
  essentialsCmsUrl,
  lyraLanguage,
  shouldFilterOUS,
}: SelfCareWellnessTopicFetcherParams): SelfCareWellnessTopicApiResponse[] => {
  const [topicCardContents, setTopicCardContents] = useState<SelfCareWellnessTopicApiResponse[]>([])

  useEffect(() => {
    const fetchTopics = async () => {
      const GRAPHQL_VARIABLES = {
        type: GRAPH_QUERY_VARIABLE.CATEGORY_TYPE,
        site: translateToEssentialSiteCode(lyraLanguage),
        siteCategory: GRAPH_QUERY_VARIABLE.SITE_CATEGORY,
      }

      const data = {
        query: GRAPH_QUERY,
        variables: GRAPHQL_VARIABLES,
      }

      try {
        if (!essentialsCmsUrl) {
          throw new Error('CMS URL is empty')
        }

        // Use new axios for essential csm external domain
        const instance = axios.create()
        const response = await instance.post(essentialsCmsUrl, data)

        if (response.status != 200) {
          throw new Error('Invalid response for wellness topic')
        } else {
          const responseData: GraphQLTopicApiResponse[] = response.data?.data?.entries
          let topicResponses = filterTopicApiResponse(responseData)
          if (shouldFilterOUS) {
            topicResponses = filterOUSTopic(topicResponses)
          }
          const topicContents: SelfCareWellnessTopicApiResponse[] = topicResponses.map(
            (data: GraphQLTopicApiResponse) => {
              return {
                title: data.title,
                imageUrl: data.foreground && data.foreground.length > 0 ? `https:${data.foreground[0].url}` : '',
                id: data.id,
                slug: data.slug,
              }
            },
          )
          return topicContents
        }
      } catch (e) {
        console.error('Failed to retrieve data for wellness topic ' + e)
        return []
      }
    }

    fetchTopics().then(setTopicCardContents)
  }, [essentialsCmsUrl, lyraLanguage, shouldFilterOUS])

  return topicCardContents
}

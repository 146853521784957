import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type Props = {
  size?: number | string
  outerCircleBgColor?: string
  innerCircleBgColor?: string
}

export const MedicationManagementSpotIllustration: FunctionComponent<Props> = ({
  size = 124,
  outerCircleBgColor = 'white',
  innerCircleBgColor = '#FFE9E3',
}) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 513 512' fill='none'>
      <Path
        d='M258.3 1.91479C222.959 1.91479 188.67 7.97528 156.384 19.9243C79.5203 48.4156 25.4818 111.58 8.11986 193.22C-3.35359 246.449 -1.88652 295.682 12.6323 343.66C31.1679 404.932 76.6671 456.603 137.464 485.418C167.645 499.755 202.319 507.94 240.503 509.741C245.41 509.964 250.398 510.085 255.355 510.085C285.992 510.085 316.81 505.886 346.961 497.61C349.278 497.033 351.483 496.437 353.811 495.749C380.906 487.816 405.289 475.27 426.152 458.505C448.805 440.405 467.452 417.458 481.516 390.343C484.288 385 486.939 379.375 489.863 372.647C505.95 335.292 513.72 293.476 512.344 251.67C511.697 233.185 508.945 213.536 504.19 193.22C496.358 160.256 483.59 129.256 467.26 103.517C462.788 96.6061 458.68 90.7986 454.613 85.5779C425.262 47.7175 379.53 21.1485 322.405 8.7847C301.421 4.23174 279.85 1.92491 258.31 1.92491L258.3 1.91479Z'
        fill={outerCircleBgColor}
      />
      <Path
        d='M490.38 252.357C489.753 234.641 487.042 216.288 482.772 198.046C475.791 168.654 464.317 139.677 448.584 114.868C444.932 109.243 441.188 103.82 437.121 98.6093C407.618 60.5668 362.908 39.4006 317.146 29.4954C266.173 18.4469 211.972 21.6745 162.973 39.8155C92.0881 66.0809 43.8268 123.62 28.0837 197.631C22.0333 225.667 20.2627 257.356 22.9742 287.8H22.7618C24.33 305.101 27.4564 321.987 32.1408 337.518C49.5534 395.057 91.8655 440.718 145.762 466.255C174.952 480.117 207.571 486.997 240.727 488.565C274.601 490.133 309.001 485.853 340.801 477.101C342.885 476.585 344.868 476.059 346.952 475.432C371.862 468.137 393.544 456.876 412.1 441.973C432.325 425.815 448.999 405.489 461.93 380.579C464.742 375.156 467.241 369.743 469.639 364.208C484.856 328.867 491.635 290.511 490.279 252.469L490.38 252.368V252.357Z'
        fill={innerCircleBgColor}
      />
      <Path
        d='M320.399 396.073H188.93C179.936 396.073 172.641 388.778 172.641 379.784L166.459 169.053L345.603 179.858L336.679 380.128C336.487 388.981 329.252 396.063 320.399 396.063V396.073Z'
        fill='#FFC03C'
      />
      <Path
        d='M222.001 314.719C212.882 307.122 199.332 308.355 191.734 317.473C184.137 326.592 185.37 340.142 194.489 347.74L228.131 375.77C237.25 383.367 250.801 382.133 258.398 373.015C265.995 363.897 264.762 350.346 255.643 342.749L222.001 314.719Z'
        fill='#00A4B3'
      />
      <Path
        d='M258.396 373.005C250.798 382.121 237.251 383.355 228.124 375.757L212.088 362.503L239.598 329.479L255.634 342.733C264.75 350.331 265.985 363.879 258.386 373.005H258.396Z'
        fill='#008295'
      />
      <Path
        d='M282.908 310.891L272.782 353.494C270.037 365.041 277.173 376.626 288.72 379.371C300.267 382.115 311.852 374.979 314.597 363.432L324.723 320.83C327.467 309.283 320.331 297.698 308.784 294.953C297.237 292.209 285.652 299.344 282.908 310.891Z'
        fill='#00A4B3'
      />
      <Path
        d='M308.804 294.976C320.348 297.718 327.491 309.313 324.739 320.857L320.004 341.113L278.188 331.167L282.923 310.911C285.664 299.367 297.259 292.224 308.804 294.976Z'
        fill='#008295'
      />
      <Mask id='mask0_16033_9809' maskUnits='userSpaceOnUse' x='166' y='169' width='180' height='228'>
        <Path
          d='M320.399 396.074H188.93C179.936 396.074 172.641 388.779 172.641 379.784L166.459 169.053L345.603 179.859L336.679 380.128C336.487 388.981 329.252 396.064 320.399 396.064V396.074Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_16033_9809)'>
        <Path
          d='M341.182 300.572L215.429 299.217C210.027 299.126 205.676 294.755 205.615 289.352L205.716 219.368C205.656 213.813 210.138 209.271 215.692 209.271L347.951 210.06L341.182 300.572Z'
          fill='white'
        />
        <Path d='M343.447 231.032H233.711V242.759H343.447V231.032Z' fill='#DCCDBF' />
        <Path d='M343.447 256.813H233.711V267.528H343.447V256.813Z' fill='#DCCDBF' />
      </G>
      <Path
        d='M347.969 153.298L165.061 152.104C162.087 152.084 159.618 154.381 159.416 157.345L157.594 184.683C157.382 187.921 159.952 190.673 163.2 190.673H349.011C352.208 190.673 354.758 188.012 354.626 184.825L353.543 158.681C353.422 155.686 350.963 153.319 347.969 153.298Z'
        fill='#6ECBD3'
      />
      <Path
        d='M335.198 132.213L180.519 126.638C175.662 126.466 171.403 129.542 170.543 133.862L163.471 169.345H348.584L344.476 139.893C343.879 135.633 339.953 132.385 335.198 132.213Z'
        fill='#6ECBD3'
      />
    </Svg>
  )
}

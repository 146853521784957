import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'
import { defineMessage } from 'react-intl'

export const mgb: GetCustomerCopyFunc = () => {
  return {
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage:
          'Are you a subscriber enrolled in a Mass General Brigham employee plan through Mass General Brigham Health Plan?',
        description: 'Question for user as part of the eligibility check for accessing benefits',
      }),
  }
}

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Essentials course icon
 */
export const CourseIcon: FunctionComponent<CourseIconProps> = ({ size = 25, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 17 17' fill='none'>
      <Path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2.30078 3.53691C2.30078 3.14639 2.61026 2.83691 3.00078 2.83691H14.0008C14.3913 2.83691 14.7008 3.14639 14.7008 3.53691V7.00358C14.7008 7.27972 14.9246 7.50358 15.2008 7.50358C15.4769 7.50358 15.7008 7.27972 15.7008 7.00358V3.53691C15.7008 2.5941 14.9436 1.83691 14.0008 1.83691H3.00078C2.05798 1.83691 1.30078 2.5941 1.30078 3.53691V11.8702C1.30078 12.8131 2.05798 13.5702 3.00078 13.5702H8.13411C8.41026 13.5702 8.63411 13.3464 8.63411 13.0702C8.63411 12.7941 8.41026 12.5702 8.13411 12.5702H3.00078C2.61026 12.5702 2.30078 12.2608 2.30078 11.8702V3.53691ZM7.57078 5.47277C7.41963 5.35742 7.21613 5.33778 7.04571 5.4221C6.87529 5.50642 6.76745 5.68011 6.76745 5.87025V9.73691C6.76745 9.92705 6.87529 10.1007 7.04571 10.1851C7.21613 10.2694 7.41963 10.2497 7.57078 10.1344L10.1041 8.20105C10.2281 8.10647 10.3008 7.95949 10.3008 7.80358C10.3008 7.64767 10.2281 7.50069 10.1041 7.4061L7.57078 5.47277ZM11.8675 9.23691C11.5913 9.23691 11.3675 9.46077 11.3675 9.73691C11.3675 10.0131 11.5913 10.2369 11.8675 10.2369H15.2008C15.4769 10.2369 15.7008 10.0131 15.7008 9.73691C15.7008 9.46077 15.4769 9.23691 15.2008 9.23691H11.8675ZM10.0341 11.7369C10.0341 11.4608 10.258 11.2369 10.5341 11.2369H15.2008C15.4769 11.2369 15.7008 11.4608 15.7008 11.7369C15.7008 12.0131 15.4769 12.2369 15.2008 12.2369H10.5341C10.258 12.2369 10.0341 12.0131 10.0341 11.7369ZM10.5341 13.2369C10.258 13.2369 10.0341 13.4608 10.0341 13.7369C10.0341 14.0131 10.258 14.2369 10.5341 14.2369H15.2008C15.4769 14.2369 15.7008 14.0131 15.7008 13.7369C15.7008 13.4608 15.4769 13.2369 15.2008 13.2369H10.5341Z'
        fill={fillColor || colors.iconInactive}
      />
    </Svg>
  )
}

type CourseIconProps = {
  size?: string | number
  fillColor?: string
}

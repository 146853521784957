// Utility method to check whether a browser supports unprefixed WebRTC.
// DetectRTC validates browsers that support prefixed WebRTC, which is not compatible
// with twilio-video 2.x.
/* The twilio-video package removed support for prefixed WebRTC in version 2.x.
 * (See: https://github.com/twilio/twilio-webrtc.js/commit/7716be1c55c2bd525c72ae4a68164caec54aaaef)
 * Importing twilio-video 2.x results in a runtime error from their WebRTC shim (twilio-webrtc).
 * On import, the shim deduces the client's browser and runs assuming that the global WebRTC
 * object is RTCPeerConnection, with no fallback to a prefixed version.
 * We cannot afford to stay on twilio-video 1.x due to breaking changes to WebRTC in modern-day
 * browsers (Chrome 72+, Firefox 68) combined with automatic browser updates.
 * This means that we have to wrap the twilio-video import in a try/catch and swallow the
 * runtime error if the import fails.
 */
const isCompatibleWithUnprefixedWebRTC = !!window.RTCPeerConnection

export default {
  isCompatibleWithUnprefixedWebRTC,
}

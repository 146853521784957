import React from 'react'
import { InlineErrorBanner } from '../../../molecules'
import { StarbucksEligibilityErrorMessage } from './StarbucksEligibilityErrorMessage'

export const StarbucksEligibilityErrorBanner: React.FC<{ isDependent: boolean }> = ({ isDependent }) => {
  return (
    <InlineErrorBanner
      text={<StarbucksEligibilityErrorMessage />}
      testId={`EmployeeInfoFor${isDependent ? 'Dependent' : 'Employee'}Eligibility-tips`}
    />
  )
}

import {
  addDays,
  addWeeks,
  differenceInCalendarDays,
  differenceInHours,
  differenceInYears,
  formatISO,
  formatRelative,
  isToday,
  isTomorrow,
  isValid,
  isWithinInterval,
  parse,
  parseISO,
} from 'date-fns'
import { enUS } from 'date-fns/locale'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { CountryCode } from 'libphonenumber-js'
import { capitalize, isNil } from 'lodash-es'
import moment from 'moment-timezone'

import { Appointment, utilizationQuarters } from '../../../models'
import { getDateFromAppointment } from '../../appointments/getDateFromAppointment'

/**
 * @deprecated Please do not add any new usages of this!
 */
// Converts country ISO code to a date format
// e.g 'CA' -> 'YYYY-MM-DD'
//     'RU' -> 'DD/MM/YYYY'
// Note: This function was created as a temporary solution to address Canada's date format, which is 'YYYY/MM/DD' as opposed to
//       all other international date formats which uses 'DD/MM/YYYY'. This may not be the best solution moving forward as
//       date-fns/locale only returns a subset of locale objects that uses a date format that fits our needs and we would also
//       need to maintain localeMapper for any additional country ISO code to locale mappings. This solution is subject to
//       change for Intlv5 as we will be adding a language picker to more pages and may change the use case of this function
export const countryIsoCodeToDateFormat = (countryCode: CountryCode) => {
  // Only defining the countries that don't have format of DD/MM/YYYY
  const localeMapper = {
    US: 'MM/DD/YYYY',
    CA: 'YYYY-MM-DD',
    DE: 'DD.MM.YYYY',
    PT: 'DD.MM.YYYY',
    PL: 'DD.MM.YYYY',
    JP: 'YYYY/MM/DD',
    KR: 'YYYY.MM.DD',
    CN: 'YYYY-MM-DD',
    NL: 'DD-MM-YYYY',
    CZ: 'DD.MM.YYYY',
    TW: 'YYYY-MM-DD',
    HK: 'YYYY-MM-DD',
    HU: 'YYYY.MM.DD',
    RO: 'DD.MM.YYYY',
    TR: 'DD.MM.YYYY',
  }
  const locale = localeMapper[countryCode]

  return locale || 'DD/MM/YYYY'
}

/**
 * Converts a UTC date string to a new date string with the same date, in ISO8601 format.
 * @param    date A UTC date string eg. 2018-09-19T19:52:12
 * @returns  A UTC date string in ISO8601 format eg. 2018-09-19T19:52:12Z
 */
export const convertUTCToISO8601format = (date: string) => {
  return date ? moment.utc(date).format() : ''
}

/**
 * Given a date string and date format, returns an object containing the month, day, and year from the date string
 * @param date A date string, e.g. '01/31/2023;
 * @param dateFormat A string representing the format of the date, e.g. 'MM/DD/YYYY'
 * @returns An object containing the month, day, and year of the given date, e.g. { month: '01', day: '31', year: '2023' }
 */
export const getDateParts = ({ date, dateFormat }: { date: string; dateFormat: string }) => {
  let month, day, year
  const delimiter = dateFormat[dateFormat.search(/[^A-Za-z]/)]
  const dateParts = date?.split(delimiter)
  const formatParts = dateFormat?.split(delimiter)

  formatParts?.forEach((part, index) => {
    switch (part) {
      case 'MM':
        month = dateParts[index]
        break
      case 'DD':
        day = dateParts[index]
        break
      case 'YYYY':
        year = dateParts[index]
        break
      default:
        break
    }
  })

  return {
    month,
    day,
    year,
  }
}

// Given a date string and a date format, converts the date to
// the domestic date format, MM/DD/YYYY
// e.g '1980/08/10', 'YYYY-MM-DD' -> '08/10/1980'
//     '08/10/1980', 'DD/MM/YYYY' -> '08/10/1980'
/**
 * @deprecated Please do not add any new usages of this!
 */
export const getDateInDomesticFormat = ({ date, dateFormat }: { date: string; dateFormat: string }) => {
  const { month, day, year } = getDateParts({ date, dateFormat })
  const domesticDate = [month, day, year].join('/')
  return domesticDate
}

/**
 * @deprecated Please do not add any new usages of this!
 *
 * Given a date string and date format, converts the date to ISO format (YYYY-MM-DD)
 * @param date A date string, e.g. '01/31/2023;
 * @param dateFormat A string representing the format of the date, e.g. 'MM/DD/YYYY'
 * @returns The given date string in ISO format, e.g. '2023-01-31' will be returned for { date: '01/31/2023', dateFormat: 'MM/DD/YYYY' }
 */
export const getDateInISOFormat = ({ date, dateFormat }: { date: string; dateFormat: string }) => {
  const { month, day, year } = getDateParts({ date, dateFormat })
  const isoDate = [year, month, day].join('-')
  return isoDate
}

/** @todo import { format } from 'date-fns' and make sure the dateFormat is in expected form: https://date-fns.org/v2.30.0/docs/Unicode-Tokens
 * @deprecated Please do not add any new usages of this!
 */
export const getDateInLocalizedFormat = ({
  date,
  currentDateFormat,
  desiredDateFormat,
}: {
  date: string
  currentDateFormat: string
  desiredDateFormat: string
}) => {
  const { month, day, year } = getDateParts({ date, dateFormat: currentDateFormat })
  const mapping = {
    MM: month,
    DD: day,
    YYYY: year,
  }
  const delimiter = desiredDateFormat[desiredDateFormat.search(/[^A-Za-z]/)]
  const dateFormatParts = desiredDateFormat?.split(delimiter)
  const formattedDate = [
    mapping[dateFormatParts[0].toUpperCase()],
    mapping[dateFormatParts[1].toUpperCase()],
    mapping[dateFormatParts[2].toUpperCase()],
  ].join(delimiter)
  return formattedDate
}

export const convertTimeToUTC = (datetime?: string | null, timezone?: string) => {
  if (!datetime) {
    return ''
  }
  if (!timezone) {
    return datetime
  }
  const utcDate = zonedTimeToUtc(datetime, timezone)
  return utcDate.toISOString()
}

export const isWithin24Hours = (appointment: Appointment) => {
  const sessionStart = getDateFromAppointment(appointment)
  return differenceInHours(sessionStart, new Date()) < 24
}

export const is24HoursInTheFuture = ({
  startDate,
  startTime,
  timeZone,
}: {
  startDate: string
  startTime: string
  timeZone: string
}) => {
  const futureDate = getDateFromAppointment({ startDate, startTime, timeZone })
  const hourDifference = differenceInHours(futureDate.getTime(), Date.now())
  return 0 <= hourDifference && hourDifference < 24
}

export const getCurrentQuarter = (date?: Date) => {
  const dateObj = date ?? new Date()
  return Math.floor((dateObj.getUTCMonth() + 3) / 3)
}

export const getStartOfNextQuarterByWeeks = (utilizationQuarters: utilizationQuarters, date?: Date) => {
  const dateObj = date ?? new Date()
  const dateObjUTC = format(utcToZonedTime(dateObj, 'UTC'), 'yyyy-MM-dd')
  const sortedQuarters = Object.values(utilizationQuarters).sort()
  const quarterEndDate = sortedQuarters.find(
    (quarter: [string, string]) =>
      quarter[0].localeCompare(dateObjUTC) === -1 && dateObjUTC.localeCompare(quarter[1]) === -1,
  )
  return quarterEndDate
    ? addDays(new Date(`${quarterEndDate[1]}T00:00:00.000+00:00`), 1)
    : getStartOfNextQuarterByMonth(date)
}

export const getStartOfNextQuarterByMonth = (date?: Date) => {
  const dateObj = date ?? new Date()
  let newDateYear = dateObj.getFullYear()
  const nextQuarterFirstMonth = (getCurrentQuarter(dateObj) * 3 + 1) % 12
  if (getCurrentQuarter(dateObj) === 4) {
    newDateYear = dateObj.getFullYear() + 1
  }
  return new Date(`${newDateYear}-${nextQuarterFirstMonth.toString().padStart(2, '0')}-01T00:00:00.000+00:00`)
}

export const getStartOfNextPerformanceYear = (date?: Date) => {
  const dateObj = date ?? new Date()
  const newDateYear = dateObj.getFullYear() + 1
  return new Date(`${newDateYear}-01-01T00:00:00.000+00:00`)
}

export const getWeeksToDate = (endDate: Date, currentSetDate?: Date) => {
  const currentDate = currentSetDate ?? new Date()
  const timeToEndOfQuarter = endDate.getTime() - currentDate.getTime()
  const weekInMilliseconds = 604800000
  return Math.floor(timeToEndOfQuarter / weekInMilliseconds)
}

export const getDaysToDate = (endDate: Date, currentSetDate?: Date) => {
  const currentDate = currentSetDate ?? new Date()
  const timeToEndOfQuarter = endDate.getTime() - currentDate.getTime()
  const dayInMilliseconds = 86400000
  return Math.floor(timeToEndOfQuarter / dayInMilliseconds)
}

type DateClassification = 'today' | 'tomorrow' | 'week' | null
export function getClassificationForDate(dateTime: string): DateClassification | null {
  const date = new Date(dateTime)
  if (isToday(date)) {
    return 'today'
  }

  if (isTomorrow(date)) {
    return 'tomorrow'
  }

  if (isWithinInterval(date, { start: new Date(), end: addWeeks(new Date(), 1) })) {
    return 'week'
  }

  return null
}

export const convertFormatToDateFnsStandard = (dateFormat: string) => {
  return dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd').replace('mm', 'MM')
}

/**
 * Given an ISO 8601 date string like '2023-01-02', convert it to `MM/dd/yyyy` US format, `01/02/2023`.
 *
 * Use sparingly! This util should not be necessary, except to convert request payloads for
 * specific backend endpoints that still expect the US date format.
 *
 * If null or undefined, returns null or undefined
 */
export function convertDateStringFromISOToUSFormat<T extends null | undefined | never>(
  iso8601Date: string | T,
): string | T {
  if (iso8601Date === null || iso8601Date === undefined) {
    return iso8601Date
  }
  const parsedDate = parse(iso8601Date, 'MM/dd/yyyy', new Date(), { locale: enUS })
  if (isValid(parsedDate)) {
    console.warn(`Date is already in US format - ${iso8601Date}`)
    return iso8601Date
  }
  return format(parseISO(iso8601Date), 'MM/dd/yyyy')
}

/**
 * Given a javascript date and timezone, create an ISO8601-compliant string with the timezone offset
 *
 * Ex: For Nov 28, 2023 at 9pm MST, this function returns the string 2023-11-28T21:00:00-07:00
 */
export function getISO8601DateWithTimezone({ date, timeZone }: { date: Date; timeZone: string }) {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone })
}

export function localeToDatePartLabels(locale: string) {
  const displayNames = new Intl.DisplayNames(locale, { type: 'dateTimeField' })
  return Object.fromEntries(
    ['year', 'month', 'day'].map((part) => [part, capitalize(displayNames.of(part) ?? '')]),
  ) as {
    year: string
    month: string
    day: string
  }
}

export function localeToDatePartOrdering(locale: string) {
  const parts = new Intl.DateTimeFormat(locale)
    .formatToParts()
    .filter((part) => ['year', 'month', 'day'].includes(part.type))
    .map((part) => part.type as 'year' | 'month' | 'day')
  return parts
}

export function localeToDatePartsWithFormatStyles(
  locale: string,
  date: Date,
  yearFormat?: Intl.DateTimeFormatOptions['year'],
  monthFormat?: Intl.DateTimeFormatOptions['month'],
  dayFormat?: Intl.DateTimeFormatOptions['day'],
) {
  const formatMonth = new Intl.DateTimeFormat(locale, {
    month: monthFormat,
    timeZone: 'UTC',
  }).format
  const formatDay = new Intl.DateTimeFormat(locale, {
    day: dayFormat,
    timeZone: 'UTC',
  }).format
  const formatYear = new Intl.DateTimeFormat(locale, {
    year: yearFormat,
    timeZone: 'UTC',
  }).format
  return {
    month: formatMonth(date),
    day: formatDay(date),
    year: formatYear(date),
  }
}

/**
 * Returns a date object with today's /local/ date in UTC
 * localDateToday().toISOString() => '2024-02-16T00:00:00.000Z'
 * where 2024-02-16 is today's date in the local timezone.
 */
export function localDateToday(): Date {
  const local = new Date() // Returns timestamp using local timezone
  return new Date(Date.UTC(local.getFullYear(), local.getMonth(), local.getDate()))
}

export function getUTCDateISOString(utcDate: Date): string {
  return utcDate.toISOString().split('T')[0]
}

// Compares two date objects in the same timezone to determine whether or not they are the same day.
// We do not need an explicit timezone check because date objects are always created in the user device local timezone
export function getIsSameDay({ date1, date2 }: { date1: Date; date2: Date }): boolean {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}

export function getFriendlyDate({
  date,
  addPreposition = false,
  displayTime = false,
  datePattern,
}: {
  date?: Date | string
  addPreposition?: boolean
  displayTime?: boolean
  datePattern?: string
}): string {
  if (isNil(date)) {
    return ''
  }
  if (Math.abs(differenceInCalendarDays(new Date(date), new Date())) <= 1) {
    return displayTime
      ? formatRelative(new Date(date), new Date())
      : formatRelative(new Date(date), new Date()).split(' at ')[0]
  }
  let dateFormat

  if (Math.abs(differenceInCalendarDays(new Date(date), new Date())) <= 7) {
    dateFormat = 'EEEE'
  } else if (datePattern) {
    dateFormat = datePattern
  } else {
    dateFormat = Math.abs(differenceInYears(new Date(date), new Date())) <= 1 ? 'MMMM do' : 'MMMM do yyyy'
  }

  if (displayTime) {
    dateFormat += ', h:mm aaa'
  }

  return addPreposition ? `on ${format(new Date(date), dateFormat)}` : format(new Date(date), dateFormat)
}

// Converts US formatted date to ISO formatted date that is required for the DatePicker component
export function convertUSFormatToISO(dateString: string) {
  const isoDate = parseISO(dateString)

  if (!isValid(isoDate)) {
    const date = parse(dateString, 'MM/dd/yyyy', new Date())
    return formatISO(date, { representation: 'date' })
  }

  return dateString
}

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * An icon representing refreshing/restarting
 */
export const RefreshIcon: FunctionComponent<RefreshIconProps> = ({ size = 17, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 18 18' fill='none'>
      <Path
        d='M1.2502 1L1.25 5L5.25 4.99995'
        stroke={fillColor || colors.iconInverse}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M0.838819 9.96674C0.410062 10.0175 0.100233 10.4074 0.188019 10.8301C0.565631 12.6485 1.49846 14.3126 2.867 15.5868C4.42696 17.0393 6.45179 17.891 8.58095 17.9902C10.7101 18.0895 12.8054 17.4298 14.4937 16.1288C16.182 14.8277 17.3537 12.9697 17.8003 10.8855C18.2468 8.80133 17.9392 6.62632 16.9321 4.74776C15.9251 2.8692 14.284 1.40902 12.301 0.627239C10.3181 -0.154546 8.1221 -0.207195 6.10399 0.478667C4.33351 1.08037 2.80079 2.21621 1.71133 3.72027C1.45805 4.06994 1.58097 4.55248 1.95128 4.77448C2.3216 4.99647 2.79861 4.8732 3.05823 4.52822C3.95269 3.33969 5.18738 2.44153 6.6071 1.95903C8.27461 1.39232 10.0891 1.43582 11.7276 2.08179C13.366 2.72776 14.722 3.93427 15.5541 5.48648C16.3862 7.03869 16.6404 8.83584 16.2715 10.5579C15.9025 12.28 14.9343 13.8153 13.5393 14.8903C12.1443 15.9654 10.413 16.5104 8.65375 16.4284C6.89448 16.3464 5.22141 15.6427 3.93245 14.4425C2.83504 13.4207 2.07671 12.0956 1.74771 10.6449C1.65221 10.2238 1.26758 9.91595 0.838819 9.96674Z'
        fill={fillColor || colors.iconInverse}
      />
    </Svg>
  )
}

type RefreshIconProps = {
  size?: string | number
  fillColor?: string
}

import React, { FunctionComponent } from 'react'
import { ViewStyle } from 'react-native'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils/utils'

export interface InnovationIconProps {
  size?: number
  color?: string
  style?: ViewStyle
}

export const InnovationIcon: FunctionComponent<InnovationIconProps> = ({ size = 24, color, style }) => {
  const { colors } = useTheme()

  return (
    <Svg testID={tID('InnovationIcon')} width={size} height={size} viewBox='0 0 24 24' fill='none' style={style}>
      <Path
        d='M12.001 6.42001L12.0029 6.42C12.6217 6.41643 13.235 6.53506 13.8078 6.7691C14.3806 7.00313 14.9015 7.34795 15.3407 7.78379C15.7799 8.21962 16.1287 8.73788 16.3671 9.30884C16.6055 9.87965 16.7288 10.4919 16.7301 11.1105C16.73 11.8944 16.6063 12.4468 16.3333 12.9756C16.0511 13.5222 15.5951 14.0732 14.8688 14.8343C14.4523 15.2691 14.1709 15.8154 14.0588 16.4069L14.0501 16.4531V16.5V17.33C14.0501 17.3963 14.0237 17.4599 13.9768 17.5068C13.93 17.5537 13.8664 17.58 13.8001 17.58H10.2401C10.1738 17.58 10.1102 17.5537 10.0633 17.5068C10.0164 17.4599 9.99006 17.3963 9.99006 17.33V16.45V16.3952L9.97818 16.3417C9.8523 15.7746 9.57302 15.253 9.17084 14.8338L9.16944 14.8324C8.43421 14.0723 7.97913 13.5219 7.70008 12.9771C7.42981 12.4494 7.31011 11.8971 7.31006 11.1105C7.31257 9.86728 7.80755 8.67569 8.68665 7.7966C9.56586 6.91739 10.7576 6.4224 12.001 6.42001ZM14.4744 14.5028L14.4744 14.5029L14.4813 14.4957C15.1456 13.8014 15.5835 13.2894 15.8496 12.7862C16.1296 12.2566 16.2038 11.7682 16.2199 11.1633L16.2201 11.1633V11.15C16.2201 10.0388 15.7786 8.97301 14.9928 8.18723C14.2071 7.40145 13.1413 6.96001 12.0301 6.96001C10.9188 6.96001 9.85306 7.40145 9.06728 8.18723C8.2815 8.97301 7.84006 10.0388 7.84006 11.15C7.84006 11.7573 7.90513 12.2495 8.18272 12.7832C8.44562 13.2887 8.88508 13.802 9.54879 14.4957L9.54878 14.4957L9.55135 14.4983C10.0585 15.0206 10.39 15.6878 10.5001 16.4068V16.58V17.08H11.0001H13.0001H13.5001V16.58V16.4092C13.6177 15.6878 13.9582 15.0211 14.4744 14.5028Z'
        stroke={color || colors.iconDefault}
      />
      <Path
        d='M9.77979 19.5H14.2198C14.2861 19.5 14.3497 19.5263 14.3966 19.5732C14.4434 19.6201 14.4698 19.6837 14.4698 19.75C14.4698 19.8163 14.4434 19.8799 14.3966 19.9268C14.3497 19.9737 14.2861 20 14.2198 20H9.77979C9.71348 20 9.64989 19.9737 9.60301 19.9268C9.55612 19.8799 9.52979 19.8163 9.52979 19.75C9.52979 19.6837 9.55612 19.6201 9.60301 19.5732C9.64989 19.5263 9.71348 19.5 9.77979 19.5Z'
        stroke={color || colors.iconDefault}
      />
      <Path
        d='M10.8901 21.75H13.1101C13.1764 21.75 13.24 21.7763 13.2869 21.8232C13.3338 21.8701 13.3601 21.9337 13.3601 22C13.3601 22.0663 13.3338 22.1299 13.2869 22.1768C13.24 22.2237 13.1764 22.25 13.1101 22.25H10.8901C10.8238 22.25 10.7602 22.2237 10.7134 22.1768C10.6665 22.1299 10.6401 22.0663 10.6401 22C10.6401 21.9337 10.6665 21.8701 10.7134 21.8232C10.7602 21.7763 10.8238 21.75 10.8901 21.75Z'
        stroke={color || colors.iconDefault}
      />
      <Path
        d='M12.1768 3.28678C12.1299 3.33366 12.0663 3.36 12 3.36C11.9337 3.36 11.8701 3.33366 11.8232 3.28678C11.7763 3.23989 11.75 3.1763 11.75 3.11V2C11.75 1.9337 11.7763 1.87011 11.8232 1.82322C11.8701 1.77634 11.9337 1.75 12 1.75C12.0663 1.75 12.1299 1.77634 12.1768 1.82322C12.2237 1.87011 12.25 1.9337 12.25 2V3.11C12.25 3.1763 12.2237 3.23989 12.1768 3.28678Z'
        stroke={color || colors.iconDefault}
      />
      <Path
        d='M6.64421 5.21127L6.6442 5.21128L6.64623 5.21331C6.69305 5.26019 6.71934 5.32373 6.71934 5.38998C6.71934 5.45152 6.69665 5.51072 6.65591 5.5564C6.61173 5.59163 6.55679 5.61096 6.5 5.61096C6.43968 5.61096 6.38143 5.58915 6.33596 5.54961L5.49903 4.77176C5.45519 4.72541 5.43066 4.66395 5.43066 4.59998C5.43066 4.5352 5.4558 4.47301 5.50069 4.42646C5.55065 4.37978 5.61652 4.35376 5.685 4.35376C5.75353 4.35376 5.81944 4.37982 5.86942 4.42655L6.64421 5.21127Z'
        stroke={color || colors.iconDefault}
      />
      <Path
        d='M3.10986 10.64H4.21986C4.28617 10.64 4.34976 10.6664 4.39664 10.7132C4.44352 10.7601 4.46986 10.8237 4.46986 10.89C4.46986 10.9563 4.44352 11.0199 4.39664 11.0668C4.34976 11.1137 4.28617 11.14 4.21986 11.14H3.10986C3.04356 11.14 2.97997 11.1137 2.93309 11.0668C2.8862 11.0199 2.85986 10.9563 2.85986 10.89C2.85986 10.8237 2.8862 10.7601 2.93309 10.7132C2.97997 10.6664 3.04356 10.64 3.10986 10.64Z'
        stroke={color || colors.iconDefault}
      />
      <Path
        d='M19.7798 10.64H20.8898C20.9561 10.64 21.0197 10.6664 21.0666 10.7132C21.1134 10.7601 21.1398 10.8237 21.1398 10.89C21.1398 10.9563 21.1134 11.0199 21.0666 11.0668C21.0197 11.1137 20.9561 11.14 20.8898 11.14H19.7798C19.7135 11.14 19.6499 11.1137 19.603 11.0668C19.5561 11.0199 19.5298 10.9563 19.5298 10.89C19.5298 10.8237 19.5561 10.7601 19.603 10.7132C19.6499 10.6664 19.7135 10.64 19.7798 10.64Z'
        stroke={color || colors.iconDefault}
      />
      <Path
        d='M18.4727 4.42958L18.4727 4.4296L18.4766 4.43332C18.5248 4.47885 18.5529 4.54165 18.5548 4.60791C18.5566 4.67243 18.5334 4.73508 18.4902 4.78282L17.7068 5.5662C17.7067 5.56623 17.7067 5.56625 17.7067 5.56628C17.6598 5.61305 17.5963 5.63932 17.5301 5.63932C17.464 5.63932 17.4005 5.6131 17.3536 5.56642C17.307 5.51956 17.2808 5.45612 17.2808 5.38998C17.2808 5.32373 17.3071 5.26019 17.3539 5.21331L17.354 5.21342L17.3627 5.20423L18.1034 4.42408C18.153 4.3789 18.2178 4.35376 18.2851 4.35376C18.3551 4.35376 18.4224 4.38094 18.4727 4.42958Z'
        stroke={color || colors.iconDefault}
      />
    </Svg>
  )
}

import { defineMessage, defineMessages } from 'react-intl'

export const requiredFieldErrorMessage = defineMessage({
  defaultMessage: 'Required',
  description: 'Error message displayed under input fields that require a non-empty value',
})

export const thisFieldIsRequiredErrorMessage = defineMessage({
  defaultMessage: 'This field is required',
  description: 'Text that says that field is required',
})

export const mustBeStringErrorMessage = defineMessage({
  defaultMessage: 'Must be a string',
  description: 'Error message for value that is not a string',
})

export const overLimitCharactersErrorMessage = defineMessage({
  defaultMessage: 'Must be {max} characters or less',
  description: 'Error message for over limit characters in string',
})

export const enterAValidEmailErrorMessage = defineMessage({
  defaultMessage: 'Please enter a valid email address',
  description: 'Error message for invalid email address input',
})

export const enterAValidDateErrorMessage = defineMessage({
  defaultMessage: 'Please enter a valid date',
  description: 'Error message for entering a valid date input',
})

export const dateCannotBeInFutureError = defineMessage({
  defaultMessage: 'Date cannot be in the future',
  description: 'Error message for invalid future date',
})

export const invalidPhoneNumberError = defineMessage({
  defaultMessage: 'Invalid phone number',
  description: 'Error message for invalid phone number',
})

export const enterAValidPhoneNumberError = defineMessage({
  defaultMessage: 'Please enter a valid phone number',
  description: 'Error message for entering a valid phone number',
})

export const requiredFieldErrorMessageWithFieldName = defineMessage({
  defaultMessage: 'This field is required: {fieldName}',
  description: 'Specifies which input field is required',
})

export const invalidPasswordErrorMessage = defineMessage({
  defaultMessage: 'Please create a stronger password!',
  description: 'Error message displayed under password input fields that require a stronger password',
})

export const invalidConfirmMatchingPasswordErrorMessage = defineMessage({
  defaultMessage: 'Please confirm that your password matches!',
  description: 'Text informing the user that the new password and the confirm password fields do not match',
})

export const invalidNumberErrorMessage = defineMessage({
  defaultMessage: 'Please enter a valid number',
  description: 'Error message when number input is invalid',
})

export const mustBeANumberErrorMessage = defineMessage({
  defaultMessage: 'Must be a number',
  description: 'Error message when value must be a number',
})

export const maxNumErrorMessage = defineMessage({
  defaultMessage: 'Must be {max} or less',
  description: 'Error message when value must be less than a number',
})

export const invalidYearErrorMessage = defineMessage({
  defaultMessage: 'Please enter a valid year',
  description: 'Error message when year is invalid',
})

export const yearCannotBeInFutureErrorMessage = defineMessage({
  defaultMessage: 'Year cannot be in the future',
  description: 'Error message when value is an invalid future year',
})

export const invalidDateErrorMessage = defineMessage({
  defaultMessage: 'Invalid Date',
  description: 'Error message when date is invalid',
})

export const invalidDateOfBirthErrorMessage = defineMessage({
  defaultMessage: 'Invalid date of birth',
  description: 'Error message when date of birth input is invalid',
})

export const mustBeOlderErrorMessage = defineMessage({
  defaultMessage: 'Must be {min} or older',
  description: 'Error message when user must be older',
})

export const mustBeYoungerErrorMessage = defineMessage({
  defaultMessage: 'Must be {max} or younger',
  description: 'Error message when user must be younger',
})

export const invalidGivenNameErrorMessage = defineMessage({
  defaultMessage: 'Invalid characters in given name',
  description: 'Error message for invalid characters in given name',
})

export const invalidFirstNameErrorMessage = defineMessage({
  defaultMessage: 'Invalid characters in first name',
  description: 'Error message for invalid characters in first name',
})

export const invalidFamilyNameErrorMessage = defineMessage({
  defaultMessage: 'Invalid characters in surname / family name',
  description: 'Error message for invalid characters in family name',
})

export const invalidLastNameErrorMessage = defineMessage({
  defaultMessage: 'Invalid characters in last name',
  description: 'Error message for invalid characters in last name',
})

export const invalidZipCodeErrorMessage = defineMessage({
  defaultMessage: 'Please enter a valid 5 digit zip code',
  description: 'Error message for invalid zip code',
})

export const wrongValueErrorMessage = defineMessage({
  defaultMessage: 'This field has the wrong value',
  description: 'Error message for wrong value',
})

export const thisFieldRequiresResponse = defineMessage({
  defaultMessage: 'A response is required',
  description: 'Error message displayed under input fields that require a response',
})

export const invalidFormatValidationError = defineMessage({
  defaultMessage: 'Invalid format',
  description: 'Validation error message when user input has invalid format',
})

export const childSearchMessages = defineMessages({
  duplicatedChildInputValidationMessage: {
    defaultMessage: 'This child already exists in the system',
    description: 'Error message when a duplicated child is being created by the client.',
  },
  createChildUserFailureMessage: {
    defaultMessage: 'We’re sorry. There was an error in saving your child’s information. Please try again.',
    description: 'Error message shown in the banner when saving user’s child’s information has failed',
  },
  childEligibilityCheckFailureMessage: {
    defaultMessage:
      'It looks like your child’s name isn’t currently in our system. Check the organization that grants them access to Lyra to make sure their name and date of birth matches the information shared.',
    description: 'Error message shown when creating a child is unsuccessful due to an eligibility check failure',
  },
})

export const closeModalButtonLabel = defineMessage({
  defaultMessage: 'Close',
  description: 'Label of button to close a modal',
})

export const resetPasswordErrorMessage = defineMessage({
  defaultMessage: 'We’re sorry there was an error requesting your reset password link, please try again',
  description: 'Error message when requesting a password reset link fails',
})

export const expiredAccountErrorMessage = defineMessage({
  defaultMessage: 'This account has expired, please use a different email',
  description: 'Error message when requesting a password reset link for an expired account',
})

export const customerCodeValidationError = defineMessage({
  defaultMessage: 'We cannot confirm this number',
  description: 'Validation error message for the input field of an employee ID number',
})

export const invalidEligibilityFirstNameMessage = defineMessage({
  defaultMessage: 'We cannot confirm this first name',
  description: 'Validation error message when inputted birthdate fails the eligibility check',
})

export const invalidEligibilityLastNameMessage = defineMessage({
  defaultMessage: 'We cannot confirm this last name',
  description: 'Validation error message when inputted birthdate fails the eligibility check',
})

export const invalidEligibilityDobMessage = defineMessage({
  defaultMessage: 'We cannot confirm this birthdate',
  description: 'Validation error message when inputted birthdate fails the eligibility check',
})

export const loginErrorMessages = defineMessages({
  APPLE: {
    defaultMessage: 'Sign in with Apple not supported.',
    description: 'Error message when user fails to login with apple',
  },
  GENERIC: {
    defaultMessage:
      'Login could not be completed. Please try again later or <careTeamLink>contact the care team</careTeamLink> for help.',
    description: 'Error message when user fails to login',
  },
  INCORRECT_LOGIN: {
    defaultMessage:
      "Looks like your email or password aren't in our system. Try adding them again, or reset your password.",
    description: 'Error message when user attempts to login with an incorrect username or password',
  },
  LOCKED: {
    defaultMessage: 'This account may be locked, or may not exist. Please try to reset your password.',
    description: 'Error message when user attempts to login with a locked account',
  },
  SSO_LOGIN_EMAIL_CONFLICT: {
    defaultMessage: 'Could not sign in. If you have an existing account, please log in below.',
    description:
      'Error message shown when user attempts to log in to their account with a different sign-on provider than expected. Prompts user to try again on the log in screen.',
  },
})

export const genericErrorMessage = defineMessage({
  defaultMessage: 'Sorry, something went wrong. Please try again.',
  description: 'Generic error message implying something went wrong.',
})

export const resetPasswordMessages = defineMessages({
  SUCCESS: {
    defaultMessage: 'Your password has successfully been changed. Log in using your new password.',
    description: 'Toast text informing user that their password has successfully been changed',
  },
  SUCCESS_LOGGED_IN: {
    defaultMessage: 'Your password has successfully been changed.',
    description: 'Toast text informing user that their password has successfully been changed',
  },
  INVALID_LINK: {
    defaultMessage: 'The link for resetting password is no longer valid. Please try resetting again.',
    description: 'Error message when the link user is using to reset password is no longer valid',
  },
})

export const creditCardErrorMessages = defineMessages({
  CARD_FIELD_INVALID: {
    defaultMessage: 'Your {cardFieldName} is invalid.',
    description: 'Validation error message when the credit card field is invalid',
  },
  CARD_FIELD_INCOMPLETE: {
    defaultMessage: 'Your {cardFieldName} is incomplete.',
    description: 'Validation error message when the credit card field is incomplete',
  },
  GENERIC_ERROR: {
    defaultMessage: 'Card information is invalid.',
    description: 'Validation error message when there card failed with unknown error',
  },
})

export const registrationMessages = defineMessages({
  GET_STARTED: {
    defaultMessage: 'Get started',
    description: 'Button text for patient to sign up to get care',
  },
  SIGN_UP_AND_EXPLORE: {
    defaultMessage: 'Sign up and explore',
    description: 'Button text for patient to sign up and explore Lyra offerings',
  },
})

export const existingChildProfileErrorMessage = defineMessage({
  defaultMessage:
    'We found an existing profile for this child. Select their profile to continue setting up care: <link>Find profile</link>.',
  description: 'Error message when a duplicated child is being created by the client',
})

export const careConsentFormError = defineMessage({
  defaultMessage: 'Agree to the terms of the program',
  description: 'Error message when client has not properly filled out the consent for care form',
})

export const dependentEligibilityCheckFailureMessage = defineMessage({
  defaultMessage:
    'It looks like your dependent’s name isn’t currently in our system. Check the organization that grants them access to Lyra to make sure their name and date of birth matches the information shared.',
  description: 'Error message shown when creating a dependent is unsuccessful due to an eligibility check failure',
})

import { defineMessage } from 'react-intl'
import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

export const bbins: GetCustomerCopyFunc = () => {
  return {
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage: 'Are you a teammate of Brown & Brown?',
        description: 'Asking if the user is an employee of the company Brown & Brown',
      }),
  }
}

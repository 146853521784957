import React, { FunctionComponent } from 'react'
import Svg, { Rect } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Pause button on top of thumbnail
 */
export const ThumbnailPauseIcon: FunctionComponent<ThumbnailPauseIconProps> = ({ size = 64 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none'>
      <Rect x='23' y='18' width='7' height='28' fill={colors.textDarkBackground} />
      <Rect x='35' y='18' width='7' height='28' fill={colors.textDarkBackground} />
    </Svg>
  )
}

type ThumbnailPauseIconProps = {
  size?: string | number
}

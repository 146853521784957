import React from 'react'
import type { IntlShape, MessageDescriptor } from 'react-intl'

import { type FieldSchema, SCHEMA_MESSAGE_FIELDS } from '@lyrahealth-inc/shared-app-logic'

import type { FormattedMessageType, StringTemplateData } from '../organisms/formBody/types'

type FormatFunc = (message: MessageDescriptor | string | undefined) => FormattedMessageType | undefined
type Properties = NonNullable<FieldSchema<FormattedMessageType>['properties']>

export const useGetTranslatedFieldSchema = (
  intl: IntlShape,
  schema: FieldSchema<MessageDescriptor | string>,
  stringTemplateData: StringTemplateData,
  enabled = false,
): FieldSchema<FormattedMessageType> => {
  return React.useMemo(() => {
    if (!enabled) {
      // schema does not need to be translated
      return schema as FieldSchema<string>
    }

    const format: FormatFunc = (message) => {
      if (typeof message === 'string' || typeof message === 'undefined') {
        return message
      }

      return intl.formatMessage(message, {
        ...stringTemplateData,
      }) as unknown as string
    }

    const newSchema = traverse(schema, format)
    return newSchema
  }, [enabled, intl, schema, stringTemplateData])
}

const traverse = (schema: FieldSchema, format: FormatFunc): FieldSchema<FormattedMessageType> => {
  return Object.keys(schema).reduce((result, key) => {
    const val = schema[key]
    if (SCHEMA_MESSAGE_FIELDS.has(key)) {
      result[key] = Array.isArray(val)
        ? val.map(format)
        : // options key is currently used in multiple ways as `{ fromContext?: string } | TMessage[]`
        key === 'options'
        ? val
        : format(val)
    } else if (key === 'properties') {
      result[key] = Object.keys(val).reduce((resultProperties, p) => {
        resultProperties[p] = traverse(val[p], format)
        return resultProperties
      }, {} as Properties)
    } else if (key === 'allOf' || key === 'anyOf' || key === 'oneOf' || key === 'items') {
      result[key] = (val as FieldSchema[]).map((nextSchema) => traverse(nextSchema, format))
    } else {
      result[key] = schema[key]
    }
    return result
  }, {} as FieldSchema<FormattedMessageType>)
}

import { useCallback } from 'react'

import { cloneDeep } from 'lodash-es'

import { Address, BlankAddressSearch, FullLocationObject, LocationObject } from '../../../models'
import { initialLocationObject } from '../constants'

type UseLocationSearchDataParams = {
  selectedAddressOption?: number
  addressSearch: BlankAddressSearch
  addressOptions: Array<Address>
  customerInfo?: string
}

/**
 * Helps create the search data object to use for LocationFinder logic
 */
export const useLocationSearchData = () =>
  useCallback(
    ({
      selectedAddressOption,
      addressSearch,
      addressOptions,
      customerInfo,
    }: UseLocationSearchDataParams): { searchData: FullLocationObject; locationData: LocationObject } => {
      const locationData = cloneDeep(initialLocationObject)
      let searchData: FullLocationObject = {}
      if (selectedAddressOption) {
        const addressId = selectedAddressOption
        const fullAddress = addressOptions.find((option: Address) => {
          return option.id === addressId
        })

        locationData.onsite = true
        locationData.onsiteName = customerInfo
        locationData.address = fullAddress
        if (locationData.geo) {
          // default lat and long is -180/180
          locationData.geo.lat = locationData.address?.lat ?? -180
          locationData.geo.lon = locationData.address?.lon ?? 180
        }

        // prep data to send along with triage info
        searchData = {
          location: locationData.address?.lat + ', ' + locationData.address?.lon,
          locationState: locationData.address?.state,
          knownLocationIdentifier: locationData?.address?.knownLocationIdentifier,
          onsite: true,
        }
      } else if (addressSearch) {
        locationData.near = true
        locationData.geo = addressSearch

        // prep data to send along with triage info
        searchData = {
          location: locationData.geo.lat
            ? locationData.geo.lat + ', ' + locationData.geo.lon
            : locationData.geo.formattedAddress,
          locationState: locationData.geo.state,
          locationCountry: locationData.geo.country,
          locationText: locationData.geo.formattedAddress,
          onsite: false,
        }
      }
      return { searchData, locationData }
    },
    [],
  )

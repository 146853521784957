import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CareNavigatorEmailLink } from '../../../atoms'

export interface AppleEligibilityErrorMessageProps {
  isDependent: boolean
  isPristineConfirmEligibility?: boolean // is shown in the pristine confirm eligibility form
}

export const AppleEligibilityErrorMessage: React.FC<AppleEligibilityErrorMessageProps> = ({
  isDependent,
  isPristineConfirmEligibility,
}) => {
  if (isPristineConfirmEligibility) {
    if (isDependent) {
      return (
        <FormattedMessage
          defaultMessage='The employee name and ID must exactly match the information on file with the employer. If you’re not sure what’s wrong contact {email} or confirm eligibility later.'
          description='Error message when Apple depedent user needs to confirm their eligibility using the employee’s Apple employee ID'
          values={{
            email: <CareNavigatorEmailLink linkType={'warning'} />,
          }}
        />
      )
    }

    return (
      <FormattedMessage
        defaultMessage='Your name and employee ID must exactly match the information on file with your employer. If you’re not sure what’s wrong contact {email} or confirm eligibility later.'
        description='Error message when Apple user needs to confirm their eligibility using their Apple employee ID'
        values={{
          email: <CareNavigatorEmailLink linkType={'warning'} />,
        }}
      />
    )
  } else {
    if (isDependent) {
      return (
        <FormattedMessage
          defaultMessage='The employee name and ID must exactly match the information on file with the employer. If you’re not sure what’s wrong you can continue and confirm eligibility later.'
          description='Error message when Employee ID and name cannot be confirmed while checking eligiblity for a dependent user'
        />
      )
    }
    return (
      <FormattedMessage
        defaultMessage='Your name and employee ID must exactly match the information on file with your employer. If you’re not sure what’s wrong you can continue and confirm eligibility later.'
        description='Error message when Employee ID and name cannot be confirmed while checking eligiblity for a user'
      />
    )
  }
}

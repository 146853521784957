import { useCallback, useMemo, useRef } from 'react'

import { AxiosResponse } from 'axios'

import {
  COMMON_MIXPANEL_PAGE,
  Episode,
  getWellnessToolkitAvailableConfig,
  RiskFactorsResponse,
  SearchRequestData,
  TriageSearch,
  User,
  useRunSpeculativeSearch,
  WELLNESS_TOOLKIT_TYPE,
  WellnessToolkitAvailableConfig,
} from '@lyrahealth-inc/shared-app-logic'

import {
  isLatestCompletedSearchNotTierThree,
  isNotInActiveEpisode as isNotInActiveEpisodeCheck,
  isNotSelfHarmOrSuicialIdeationIndicated,
} from '../wellnessToolkitUtil'

export interface useIsWellnessToolkitAvailableParams {
  toolkitType: WELLNESS_TOOLKIT_TYPE
  page?: COMMON_MIXPANEL_PAGE

  lyraId: string
  isUserTeen: boolean
  triageSearches: TriageSearch[]
  episodes: Episode[]
  isUserLoggedIn: boolean
  isInternationalUser: boolean
  postSpeculativeSearch: (lyraId: string, searchData: SearchRequestData) => Promise<AxiosResponse<boolean>>
  isCoachingEnabled: boolean
  riskFactors?: RiskFactorsResponse
  userDOB?: string
  userCountryName?: string
  isEssentialsEnabled: boolean
  hideDependentField?: boolean
  hideChildSearchCTA?: boolean
  hasIndicatedIsGuardian?: boolean
  userChildren?: User[]
  isFamilyHubEnabled?: boolean
  isCoachingEntryPointEnabled?: boolean
  displayLanguage?: string
  useFetchChildrenHook: (disableFetch?: boolean, forceFetch?: boolean) => { isLoading: boolean }
}

/**
 * Use the app specific version of this hook.
 *
 * Conditions for when a toolkit is available: https://lyrahealth.atlassian.net/wiki/spaces/EN/pages/3448995850/Wellness+Toolkits+Coaching+Parenting+Family+Hub
 * @param toolkitType
 * @param page Where the toolkit link is being shown. Defaults to homebase carousel
 *
 * The remaining params are all app specific.
 *
 * @returns `isToolkitAvailable` - if toolkit can be shown
 * @returns `isLoading` - if still evaluating the conditions
 */
export const useIsWellnessToolkitAvailable = ({
  toolkitType,
  page,
  lyraId,
  isUserTeen,
  triageSearches,
  isCoachingEnabled,
  riskFactors,
  episodes,
  isUserLoggedIn,
  isInternationalUser,
  postSpeculativeSearch,
  userDOB,
  userCountryName,
  isEssentialsEnabled,
  hideDependentField,
  hideChildSearchCTA,
  hasIndicatedIsGuardian,
  userChildren,
  isFamilyHubEnabled,
  isCoachingEntryPointEnabled,
  displayLanguage,
  useFetchChildrenHook,
}: useIsWellnessToolkitAvailableParams) => {
  const reasonsForAvailabilityStatus = useRef<{ propertyName: string; value?: boolean | number }[]>([])
  const conditions = useMemo(() => {
    return getWellnessToolkitAvailableConfig(toolkitType, page)
  }, [page, toolkitType])
  const { configExtras } = conditions

  let isFeatureFlagEnabled: boolean | undefined = false
  if (toolkitType === WELLNESS_TOOLKIT_TYPE.FAMILY_HUB) {
    isFeatureFlagEnabled = isFamilyHubEnabled
  } else if (
    toolkitType === WELLNESS_TOOLKIT_TYPE.INDIVIDUAL_COACHING ||
    toolkitType === WELLNESS_TOOLKIT_TYPE.PARENT_COACHING
  ) {
    isFeatureFlagEnabled = isCoachingEntryPointEnabled
  }

  const captureReasonsForAvailabilityStatus = useCallback(
    (
      conditionsToCheck: {
        name: keyof WellnessToolkitAvailableConfig
        relevantProperty: { name: string; value?: boolean | number }
      }[],
    ) => {
      conditionsToCheck.forEach((condition) => {
        if (conditions[condition.name]) {
          reasonsForAvailabilityStatus.current?.push({
            propertyName: `${toolkitType}-${condition.relevantProperty.name}`,
            value: condition.relevantProperty.value,
          })
        }
      })
    },
    [conditions, toolkitType],
  )

  /** Evaluate the higher level conditions so we can short circuit the logic that is more expensive, like speculative search */
  const isAvailable = useMemo(() => {
    console.debug(
      `Toolkit - ${toolkitType} isAvailable,
      [shouldFeatureFlagBeEnabled: ${conditions.shouldFeatureFlagBeEnabled}, ${isFeatureFlagEnabled}],
      [shouldEssentialsBeEnabled: ${conditions.shouldEssentialsBeEnabled}, ${isEssentialsEnabled}],
      [shouldCoachingBeEnabled: ${conditions.shouldCoachingBeEnabled}, ${isCoachingEnabled}],
      [shouldDependentsBeSupported: ${
        conditions.shouldDependentsBeSupported
      }, ${!hideDependentField}, ${!hideChildSearchCTA}],
      [shouldNotBeTeen: ${conditions.shouldNotBeTeen}, ${!isUserTeen}],
      [shouldNotBeInternational: ${conditions.shouldNotBeInternational}, ${!isInternationalUser}],
      [shouldDisplayLanguageBeEnglish: ${conditions.shouldDisplayLanguageBeEnglish}, ${displayLanguage?.includes(
        'en',
      )}]`,
    )
    captureReasonsForAvailabilityStatus([
      {
        name: 'shouldFeatureFlagBeEnabled',
        relevantProperty: { name: 'isFeatureFlagEnabled', value: isFeatureFlagEnabled },
      },
      {
        name: 'shouldEssentialsBeEnabled',
        relevantProperty: { name: 'isEssentialsEnabled', value: isEssentialsEnabled },
      },
      {
        name: 'shouldCoachingBeEnabled',
        relevantProperty: { name: 'isCoachingEnabled', value: isCoachingEnabled },
      },
      {
        name: 'shouldDependentsBeSupported',
        relevantProperty: {
          name: '!hideDependentField && !hideChildSearchCTA',
          value: !hideChildSearchCTA && !hideDependentField,
        },
      },
      {
        name: 'shouldNotBeTeen',
        relevantProperty: { name: '!isUserTeen', value: !isUserTeen },
      },
      {
        name: 'shouldNotBeInternational',
        relevantProperty: { name: '!isInternationalUser', value: !isInternationalUser },
      },
      {
        name: 'shouldDisplayLanguageBeEnglish',
        relevantProperty: { name: "displayLanguage?.includes('en')", value: displayLanguage?.includes('en') },
      },
    ])
    return (
      (conditions.shouldFeatureFlagBeEnabled ? isFeatureFlagEnabled : true) &&
      (conditions.shouldEssentialsBeEnabled ? isEssentialsEnabled : true) &&
      (conditions.shouldCoachingBeEnabled ? isCoachingEnabled : true) &&
      (conditions.shouldDependentsBeSupported ? !hideChildSearchCTA && !hideDependentField : true) &&
      (conditions.shouldNotBeTeen ? !isUserTeen : true) &&
      (conditions.shouldNotBeInternational ? !isInternationalUser : true) &&
      (conditions.shouldDisplayLanguageBeEnglish ? displayLanguage?.includes('en') : true)
    )
  }, [
    toolkitType,
    conditions.shouldFeatureFlagBeEnabled,
    conditions.shouldEssentialsBeEnabled,
    conditions.shouldCoachingBeEnabled,
    conditions.shouldDependentsBeSupported,
    conditions.shouldNotBeTeen,
    conditions.shouldNotBeInternational,
    conditions.shouldDisplayLanguageBeEnglish,
    isFeatureFlagEnabled,
    isEssentialsEnabled,
    isCoachingEnabled,
    hideDependentField,
    hideChildSearchCTA,
    isUserTeen,
    isInternationalUser,
    captureReasonsForAvailabilityStatus,
    displayLanguage,
  ])

  // Speculative search (skip if programSearchRequestData is undefined)
  const shouldHaveProviderSupply = conditions.shouldHaveProviderSupply && isUserLoggedIn
  const program = configExtras?.programSearchRequestData
  const { clientele, treatment, partner, offering, directLinkIntent } = program ?? {}
  const searchRequestData = useMemo(() => {
    return isAvailable && program && shouldHaveProviderSupply
      ? { clientele, treatment, partner, offering, directLinkIntent }
      : undefined
  }, [clientele, directLinkIntent, isAvailable, offering, partner, program, shouldHaveProviderSupply, treatment])
  const speculativeSearchResult = useRunSpeculativeSearch({
    searchRequestData,
    isUserLoggedIn,
    lyraId,
    userDOB,
    userCountryName,
    isInternationalUser,
    postSpeculativeSearch,
  })
  const hasAvailableProviderSupply = speculativeSearchResult.hasAvailableSupply

  const shouldFetchChildren = isAvailable && conditions.shouldBeKnownParent
  const isLoadingChildren = useFetchChildrenHook(!shouldFetchChildren, true).isLoading

  const isKnownParent =
    hasIndicatedIsGuardian ||
    userChildren?.length ||
    (configExtras?.triageConditionsChecker && configExtras?.triageConditionsChecker(triageSearches))

  const isNotSHOrSI = isNotSelfHarmOrSuicialIdeationIndicated(riskFactors)

  const isNotT3Recommendation = isLatestCompletedSearchNotTierThree(triageSearches)

  const isNotInActiveEpisode = isNotInActiveEpisodeCheck(
    episodes,
    lyraId,
    configExtras?.allowedProgramsForActiveEpisodes,
  )

  return useMemo(() => {
    const isToolkitAvailable = Boolean(
      isAvailable &&
        (conditions.shouldNotBeSHOrSI ? isNotSHOrSI : true) &&
        (conditions.shouldNotBeInActiveEpisode ? isNotInActiveEpisode : true) &&
        (conditions.shouldNotHaveT3Recommendation ? isNotT3Recommendation : true) &&
        (conditions.shouldBeKnownParent ? isKnownParent : true) &&
        (shouldHaveProviderSupply ? hasAvailableProviderSupply : true),
    )
    console.debug(
      `Toolkit - ${toolkitType}
      [isToolkitAvailable: ${isToolkitAvailable}],
      [isAvailable: ${isAvailable}],
      [shouldNotBeSHOrSI: ${conditions.shouldNotBeSHOrSI}, ${isNotSHOrSI}],
      [shouldNotBeInActiveEpisode: ${conditions.shouldNotBeInActiveEpisode}, ${isNotInActiveEpisode}],
      [shouldNotHaveT3Recommendation: ${conditions.shouldNotHaveT3Recommendation}, ${isNotT3Recommendation}],
      [shouldBeKnownParent: ${conditions.shouldBeKnownParent}, ${isKnownParent}],
      [shouldHaveProviderSupply: ${shouldHaveProviderSupply}, ${hasAvailableProviderSupply}]`,
    )
    captureReasonsForAvailabilityStatus([
      {
        name: 'shouldNotBeSHOrSI',
        relevantProperty: { name: 'isNotSHOrSI', value: isNotSHOrSI },
      },
      {
        name: 'shouldNotBeInActiveEpisode',
        relevantProperty: { name: 'isNotInActiveEpisode', value: isNotInActiveEpisode },
      },
      {
        name: 'shouldNotHaveT3Recommendation',
        relevantProperty: { name: 'isNotT3Recommendation', value: isNotT3Recommendation },
      },
      {
        name: 'shouldBeKnownParent',
        relevantProperty: {
          name: 'isKnownParent',
          value: isKnownParent,
        },
      },
      {
        name: 'shouldHaveProviderSupply',
        relevantProperty: { name: 'hasAvailableProviderSupply', value: hasAvailableProviderSupply },
      },
    ])
    return {
      /** <if condition applies> ? <evaluate condition> : true (skip condition) */
      isToolkitAvailable,
      isLoading: speculativeSearchResult.isLoading || isLoadingChildren,
      reasonsForAvailabilityStatus: reasonsForAvailabilityStatus.current,
    }
  }, [
    isAvailable,
    conditions.shouldNotBeSHOrSI,
    conditions.shouldNotBeInActiveEpisode,
    conditions.shouldNotHaveT3Recommendation,
    conditions.shouldBeKnownParent,
    isNotSHOrSI,
    isNotInActiveEpisode,
    isNotT3Recommendation,
    isKnownParent,
    shouldHaveProviderSupply,
    hasAvailableProviderSupply,
    toolkitType,
    captureReasonsForAvailabilityStatus,
    speculativeSearchResult.isLoading,
    isLoadingChildren,
  ])
}

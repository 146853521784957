import { defineMessage, defineMessages, MessageDescriptor } from 'react-intl'

import { PROGRAMS } from '../common/constants/customerProgram'

export const genericUnknownEligibilityTextMessages = defineMessage({
  defaultMessage: 'Contact {email} to find out if you are eligible',
  description:
    'Message displayed to notify user they need to email Lyrahealth customer service to confirm eligibility. ',
})

export type EligibilityContentType = {
  title: MessageDescriptor
  name: string
  colloquialName: MessageDescriptor
  program: string
  directAccessProgram?: string
  coverageStates: CoverageStatesType
}

export type CoverageStatesType = {
  [key: string]: CoverageStatesMessages | null
}

export type CoverageStatesMessages = {
  defaultMessage: string
  description: string
}

export const coachingEligibilityContent: EligibilityContentType = {
  title: defineMessage({
    defaultMessage: 'Lyra Coaching',
    description:
      "Title for the Lyra Coaching benefit and its status under the Benefit Eligibility section of the user's profile.",
  }),
  name: 'coaching',
  colloquialName: defineMessage({
    defaultMessage: 'Coaching',
    description:
      'Simplified and shortened way of typing Lyra Coaching which is used in the list of Benefit Eligibility under member profile if the user qualifies for it.',
  }),
  program: PROGRAMS.Coaching.customerPropertyId,
  coverageStates: {
    UNKNOWN: genericUnknownEligibilityTextMessages,
    NOT_OFFERED: null,
    ...defineMessages({
      EAP_SESSIONS_AVAILABLE: {
        defaultMessage: 'You can use free sessions for coaching with Lyra.',
        description: 'Message displaying to the user the they have free coaching sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED: {
        defaultMessage:
          'You can use free sessions for coaching with Lyra but you have used all of your free sessions this year.',
        description:
          'Message displaying to the user that they have finished their free coaching sessions under their Lyra benefit.',
      },
      NON_EAP_NOT_COVERED: {
        defaultMessage: 'Your health plan does not cover coaching with Lyra.',
        description:
          'Message displaying to the user that their health plan does not offer coaching sessions under their Lyra benefit.',
      },
      NON_EAP_VERIFY_HP: {
        defaultMessage:
          'Your health plan may cover coaching sessions with Lyra. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer coaching sessions and that they need to input payment or healthplan information in order to confirm this.',
      },
      NON_EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers coaching with Lyra.',
        description: 'Message displaying to the user that their health plan covers coaching sessions.',
      },
    }),
  },
}

export const coachingEligibilityContentCostMessaging: EligibilityContentType = {
  title: defineMessage({
    defaultMessage: 'Lyra Coaching',
    description:
      "Title for the Lyra Coaching benefit and its status under the Benefit Eligibility section of the user's profile.",
  }),
  name: 'coaching',
  colloquialName: defineMessage({
    defaultMessage: 'Coaching',
    description:
      'Simplified and shortened way of typing Lyra Coaching which is used in the list of Benefit Eligibility under member profile if the user qualifies for it.',
  }),
  program: PROGRAMS.Coaching.customerPropertyId,
  coverageStates: {
    UNKNOWN: genericUnknownEligibilityTextMessages,
    NOT_OFFERED: null,
    ...defineMessages({
      EAP_SESSIONS_AVAILABLE: {
        defaultMessage: 'You can use your covered sessions for coaching with Lyra.',
        description:
          'Message displaying to the user that they have covered coaching sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED: {
        defaultMessage: 'You have used all of your covered sessions for coaching for this year.',
        description:
          'Message displaying to the user that they have finished their covered coaching sessions under their Lyra benefit.',
      },
      NON_EAP_NOT_COVERED: {
        defaultMessage: 'Your health plan does not cover coaching with Lyra.',
        description:
          'Message displaying to the user that their health plan does not offer coaching sessions under their Lyra benefit.',
      },
      NON_EAP_VERIFY_HP: {
        defaultMessage:
          'You may be eligible for coaching sessions with cost-sharing under your health plan. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer coaching sessions through cost-sharing and that they need to input payment or healthplan information in order to confirm this.',
      },
      NON_EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers coaching with Lyra.',
        description: 'Message displaying to the user that their health plan covers coaching sessions.',
      },
    }),
  },
}

export const therapyEligibilityContent: EligibilityContentType = {
  title: defineMessage({
    defaultMessage: 'Lyra Therapy',
    description:
      "Title for the Lyra Therapy benefit and its status under the Benefit Eligibility section of the user's profile.",
  }),
  name: 'therapy',
  colloquialName: defineMessage({
    defaultMessage: 'Therapy',
    description:
      'Simplified and shortened way of typing Lyra Therapy which is used in the list of Benefit Eligibility under member profile if the user qualifies for it.',
  }),
  program: PROGRAMS.BlendedCareTherapy.customerPropertyId,
  directAccessProgram: PROGRAMS.DIRECT_ACCESS_THERAPY.customerPropertyId,
  coverageStates: {
    UNKNOWN: genericUnknownEligibilityTextMessages,
    NOT_OFFERED: null,
    ...defineMessages({
      EAP_SESSIONS_AVAILABLE: {
        defaultMessage: 'You can use free sessions for therapy with Lyra.',
        description: 'Message displaying to the user the they have free therapy sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED_INELIGIBLE_HP: {
        defaultMessage:
          'You can use free sessions for therapy with Lyra but you have used all your free sessions this year.',
        description:
          'Message displaying to the user that they have finished their free therapy sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED_VERIFY_HP: {
        defaultMessage:
          'Your health plan may cover therapy sessions with Lyra. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions and that they need to contact their healthcare provider to confirm eligibility.',
      },
      EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan covers therapy sessions under their Lyra benefit.',
      },
      EAP_REQUIRES_ATTESTATION: {
        defaultMessage:
          'Your health plan may cover therapy sessions with Lyra. Contact your provider/therapist to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions and that they need to contact their healthcare provider to confirm eligibility.',
      },
      NON_EAP_NOT_COVERED: {
        defaultMessage: 'Your health plan does not cover therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan does not offer therapy sessions under their Lyra benefit.',
      },
      NON_EAP_VERIFY_HP: {
        defaultMessage:
          'Your health plan may cover therapy sessions with Lyra. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions and that they need to input payment or healthplan information in order to confirm this.',
      },
      NON_EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan offers therapy sessions under their Lyra benefit.',
      },
      NON_EAP_REQUIRES_ATTESTATION: {
        defaultMessage:
          'Your health plan may cover therapy sessions with Lyra. Contact your provider/therapist to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions and that they need to contact their healthcare provider to confirm eligibility.',
      },
    }),
  },
}

export const therapyEligibilityContentCostMessaging: EligibilityContentType = {
  title: defineMessage({
    defaultMessage: 'Lyra Therapy',
    description:
      "Title for the Lyra Therapy benefit and its status under the Benefit Eligibility section of the user's profile.",
  }),
  name: 'therapy',
  colloquialName: defineMessage({
    defaultMessage: 'Therapy',
    description:
      'Simplified and shortened way of typing Lyra Therapy which is used in the list of Benefit Eligibility under member profile if the user qualifies for it.',
  }),
  program: PROGRAMS.BlendedCareTherapy.customerPropertyId,
  directAccessProgram: PROGRAMS.DIRECT_ACCESS_THERAPY.customerPropertyId,
  coverageStates: {
    UNKNOWN: genericUnknownEligibilityTextMessages,
    NOT_OFFERED: null,
    ...defineMessages({
      EAP_SESSIONS_AVAILABLE: {
        defaultMessage: 'You can use your covered sessions for therapy with Lyra.',
        description: 'Message displaying to the user that they have covered therapy sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED_INELIGIBLE_HP: {
        defaultMessage: 'You have used all of your covered sessions for therapy for this year.',
        description:
          'Message displaying to the user that they have finished their covered therapy sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED_VERIFY_HP: {
        defaultMessage:
          'You may be eligible for therapy sessions with cost-sharing under your health plan. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions with cost-sharing and that they need to contact their healthcare provider to confirm eligibility.',
      },
      EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan covers therapy sessions under their Lyra benefit.',
      },
      EAP_REQUIRES_ATTESTATION: {
        defaultMessage:
          'You may be eligible for therapy sessions with cost-sharing under your health plan. Contact your provider/therapist to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions with cost-sharing and that they need to contact their healthcare provider to confirm eligibility.',
      },
      NON_EAP_NOT_COVERED: {
        defaultMessage: 'Your health plan does not cover therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan does not offer therapy sessions under their Lyra benefit.',
      },
      NON_EAP_VERIFY_HP: {
        defaultMessage:
          'You may be eligible for therapy sessions with cost-sharing under your health plan. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions through cost-sharing and that they need to input payment or healthplan information in order to confirm this.',
      },
      NON_EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan offers therapy sessions under their Lyra benefit.',
      },
      NON_EAP_REQUIRES_ATTESTATION: {
        defaultMessage:
          'You may be eligible for therapy sessions with cost-sharing under your health plan. Contact your provider/therapist to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions through cost-sharing and that they need to contact their healthcare provider to confirm eligibility.',
      },
    }),
  },
}

export const medsEligibilityContent: EligibilityContentType = {
  title: defineMessage({
    defaultMessage: 'Lyra Medications',
    description:
      "Title for the Lyra Medications benefit and its status under the Benefit Eligibility section of the user's profile.",
  }),
  name: 'meds',
  colloquialName: defineMessage({
    defaultMessage: 'Medication Management',
    description:
      'Simplified and shortened way of typing Lyra Medications which is used in the list of Benefit Eligibility under member profile if the user qualifies for it.',
  }),
  program: PROGRAMS.MedicationManagement.customerPropertyId,
  directAccessProgram: PROGRAMS.DIRECT_ACCESS_MEDS.customerPropertyId,
  coverageStates: {
    UNKNOWN: genericUnknownEligibilityTextMessages,
    NOT_OFFERED: null,
    ...defineMessages({
      NON_EAP_NOT_COVERED: {
        defaultMessage: 'Your health plan does not cover medication management with Lyra.',
        description:
          'Message displaying to the user that their health plan does not offer medication management under their Lyra benefit.',
      },
      NON_EAP_VERIFY_HP: {
        defaultMessage:
          'Your health plan may cover medication management with Lyra. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer medication management and that they need to input payment or healthplan information in order to confirm this.',
      },
      NON_EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers medication management with Lyra.',
        description:
          'Message displaying to the user that their health plan offers medication management under their Lyra benefit.',
      },
      NON_EAP_REQUIRES_ATTESTATION: {
        defaultMessage:
          'Your health plan may cover medication management with Lyra. Contact your provider/physician to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer medication management and that they need to contact their healthcare provider to confirm eligibility.',
      },
      EAP_SESSIONS_AVAILABLE: {
        defaultMessage: 'You can use free sessions for medication management with Lyra.',
        description:
          'Message displaying to the user the they have free medication management sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED_INELIGIBLE_HP: {
        defaultMessage:
          'You can use free sessions for medication management with Lyra but you have used all your free sessions this year.',
        description:
          'Message displaying to the user that they have finished their free medication management sessions under their Lyra benefit.',
      },
    }),
  },
}

export const medsEligibilityContentCostMessaging: EligibilityContentType = {
  title: defineMessage({
    defaultMessage: 'Lyra Medications',
    description:
      "Title for the Lyra Medications benefit and its status under the Benefit Eligibility section of the user's profile.",
  }),
  name: 'meds',
  colloquialName: defineMessage({
    defaultMessage: 'Medication Management',
    description:
      'Simplified and shortened way of typing Lyra Medications which is used in the list of Benefit Eligibility under member profile if the user qualifies for it.',
  }),
  program: PROGRAMS.MedicationManagement.customerPropertyId,
  directAccessProgram: PROGRAMS.DIRECT_ACCESS_MEDS.customerPropertyId,
  coverageStates: {
    UNKNOWN: genericUnknownEligibilityTextMessages,
    NOT_OFFERED: null,
    ...defineMessages({
      NON_EAP_NOT_COVERED: {
        defaultMessage: 'Your health plan does not cover medication management with Lyra.',
        description:
          'Message displaying to the user that their health plan does not offer medication management under their Lyra benefit.',
      },
      NON_EAP_VERIFY_HP: {
        defaultMessage:
          'You may be eligible for medication consultations with cost-sharing under your health plan. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer medication management with cost-sharing and that they need to input payment or healthplan information in order to confirm this.',
      },
      NON_EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers medication management with Lyra.',
        description:
          'Message displaying to the user that their health plan offers medication management under their Lyra benefit.',
      },
      NON_EAP_REQUIRES_ATTESTATION: {
        defaultMessage:
          'You may be eligible for medication consultations with cost-sharing under your health plan. Contact your provider/physician to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer medication management with cost-sharing and that they need to contact their healthcare provider to confirm eligibility.',
      },
      EAP_SESSIONS_AVAILABLE: {
        defaultMessage: 'You may be eligible for covered medication consultations.',
        description:
          'Message displaying to the user that they have covered medication management sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED_INELIGIBLE_HP: {
        defaultMessage: 'You have used all of your covered sessions for medication management for this year.',
        description:
          'Message displaying to the user that they have finished their covered medication management sessions under their Lyra benefit.',
      },
    }),
  },
}

export const renewEligibilityContent: EligibilityContentType = {
  title: defineMessage({
    defaultMessage: 'Lyra Renew',
    description:
      "Title for the Lyra Renew benefit and its status under the Benefit Eligibility section of the user's profile.",
  }),
  name: 'therapy',
  colloquialName: defineMessage({
    defaultMessage: 'Renew',
    description:
      'Simplified and shortened way of typing Lyra Renew which is used in the list of Benefit Eligibility under member profile if the user qualifies for it.',
  }),
  program: PROGRAMS.AlcoholUseDisorderTherapy.customerPropertyId,
  coverageStates: {
    UNKNOWN: genericUnknownEligibilityTextMessages,
    NOT_OFFERED: null,
    ...defineMessages({
      EAP_SESSIONS_AVAILABLE: {
        defaultMessage: 'You can use free sessions for therapy with Lyra.',
        description: 'Message displaying to the user the they have free therapy sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED_INELIGIBLE_HP: {
        defaultMessage:
          'You can use free sessions for therapy with Lyra but you have used all your free sessions this year.',
        description:
          'Message displaying to the user that they have finished their free therapy sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED_VERIFY_HP: {
        defaultMessage:
          'Your health plan may cover therapy sessions with Lyra. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions and that they need to contact their healthcare provider to confirm eligibility.',
      },
      EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan covers therapy sessions under their Lyra benefit.',
      },
      EAP_REQUIRES_ATTESTATION: {
        defaultMessage:
          'Your health plan may cover therapy sessions with Lyra. Contact your provider/therapist to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions and that they need to contact their healthcare provider to confirm eligibility.',
      },
      NON_EAP_NOT_COVERED: {
        defaultMessage: 'Your health plan does not cover therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan does not offer therapy sessions under their Lyra benefit.',
      },
      NON_EAP_VERIFY_HP: {
        defaultMessage:
          'Your health plan may cover therapy sessions with Lyra. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions and that they need to input payment or healthplan information in order to confirm this.',
      },
      NON_EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan offers therapy sessions under their Lyra benefit.',
      },
      NON_EAP_REQUIRES_ATTESTATION: {
        defaultMessage:
          'Your health plan may cover therapy sessions with Lyra. Contact your provider/therapist to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions and that they need to contact their healthcare provider to confirm eligibility.',
      },
    }),
  },
}

export const renewEligibilityContentCostMessaging: EligibilityContentType = {
  title: defineMessage({
    defaultMessage: 'Lyra Renew',
    description:
      "Title for the Lyra Renew benefit and its status under the Benefit Eligibility section of the user's profile.",
  }),
  name: 'therapy',
  colloquialName: defineMessage({
    defaultMessage: 'Renew',
    description:
      'Simplified and shortened way of typing Lyra Renew which is used in the list of Benefit Eligibility under member profile if the user qualifies for it.',
  }),
  program: PROGRAMS.AlcoholUseDisorderTherapy.customerPropertyId,
  coverageStates: {
    UNKNOWN: genericUnknownEligibilityTextMessages,
    NOT_OFFERED: null,
    ...defineMessages({
      EAP_SESSIONS_AVAILABLE: {
        defaultMessage: 'You can use your covered sessions for therapy with Lyra.',
        description: 'Message displaying to the user that they have covered therapy sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED_INELIGIBLE_HP: {
        defaultMessage: 'You have used all of your covered sessions for therapy for this year.',
        description:
          'Message displaying to the user that they have finished their covered therapy sessions under their Lyra benefit.',
      },
      EAP_SESSIONS_DEPLETED_VERIFY_HP: {
        defaultMessage:
          'You may be eligible for therapy sessions with cost-sharing under your health plan. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions with cost-sharing and that they need to contact their healthcare provider to confirm eligibility.',
      },
      EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan covers therapy sessions under their Lyra benefit.',
      },
      EAP_REQUIRES_ATTESTATION: {
        defaultMessage:
          'You may be eligible for therapy sessions with cost-sharing under your health plan. Contact your provider/therapist to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions with cost-sharing and that they need to contact their healthcare provider to confirm eligibility.',
      },
      NON_EAP_NOT_COVERED: {
        defaultMessage: 'Your health plan does not cover therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan does not offer therapy sessions under their Lyra benefit.',
      },
      NON_EAP_VERIFY_HP: {
        defaultMessage:
          'You may be eligible for therapy sessions with cost-sharing under your health plan. Enter your health plan and payment information to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions with cost-sharing and that they need to input payment or healthplan information in order to confirm this.',
      },
      NON_EAP_COVERED_SESSIONS_AVAILABLE: {
        defaultMessage: 'Your health plan covers therapy sessions with Lyra.',
        description:
          'Message displaying to the user that their health plan offers therapy sessions under their Lyra benefit.',
      },
      NON_EAP_REQUIRES_ATTESTATION: {
        defaultMessage:
          'You may be eligible for therapy sessions with cost-sharing under your health plan. Contact your provider/therapist to confirm your eligibility.',
        description:
          'Message displaying to the user that their health plan might offer therapy sessions with cost-sharing and that they need to contact their healthcare provider to confirm eligibility.',
      },
    }),
  },
}

export const icasEligibilityContent: EligibilityContentType = {
  title: defineMessage({
    defaultMessage: 'Individual counselling',
    description:
      "Title for the Individual counselling benefit and its status under the Benefit Eligibility section of the international user's profile.",
  }),
  name: 'icas',
  colloquialName: defineMessage({
    defaultMessage: 'Counselling',
    description:
      'Simplified and shortened way of typing Independent Counselling and Advisory Services which is used in the list of Benefit Eligibility under member profile if the user qualifies for it.',
  }),
  program: PROGRAMS.ICASTherapy.customerPropertyId,
  directAccessProgram: PROGRAMS.DIRECT_ACCESS_THERAPY.customerPropertyId,
  coverageStates: {
    UNKNOWN: genericUnknownEligibilityTextMessages,
    NOT_OFFERED: null,
    ...defineMessages({
      EAP_SESSIONS_AVAILABLE: {
        defaultMessage:
          'Get matched with counsellors in your country or territory who speak your language. Have appointments by phone, over video, or in person.',
        description:
          'Message displaying to the international user that they can schedule counselling appointments and different ways to do so..',
      },
    }),
  },
}

export const getIntlCoachingEligibilityContent = (isCostMessagingEnabled?: boolean): EligibilityContentType => {
  return {
    ...(isCostMessagingEnabled ? coachingEligibilityContentCostMessaging : coachingEligibilityContent),
    title: defineMessage({
      defaultMessage: 'Mental health coaching',
      description:
        "Title for the Mental Health Coaching benefit and its status under the Benefit Eligibility section of the international user's profile.",
    }),
    coverageStates: {
      ...defineMessages({
        EAP_SESSIONS_AVAILABLE: {
          defaultMessage:
            'See a coach within a few days using easy online booking. Use the Lyra member platform to watch videos, complete exercises, track progress, and message your coach.',
          description:
            'Message displaying to the international user that they can schedule coaching appointments and different ways to do so.',
        },
      }),
    },
  }
}

export const intlEligibilityOptionsText = [getIntlCoachingEligibilityContent(), icasEligibilityContent]
export const intlEligibilityOptionsTextCostMessaging = [getIntlCoachingEligibilityContent(true), icasEligibilityContent]

export const domesticEligibilityOptionsText = [
  coachingEligibilityContent,
  therapyEligibilityContent,
  medsEligibilityContent,
  renewEligibilityContent,
]
export const domesticEligibilityOptionsTextCostMessaging = [
  coachingEligibilityContentCostMessaging,
  therapyEligibilityContentCostMessaging,
  medsEligibilityContentCostMessaging,
  renewEligibilityContentCostMessaging,
]

export const healthPlanPaymentMessages = defineMessages({
  creditCardRequirementDisclaimer: {
    defaultMessage:
      'You may have co-pays, co-insurances, or deductibles attached to future sessions. <link>Learn more</link>',
    description:
      'Body text underneath title in cost sharing payment forms. Explains why Lyra needs your credit/debit card information for cost sharing',
  },
  emailBeforeChargeDisclaimer: {
    defaultMessage: 'You’ll only be charged after each session, and we’ll always notify you by email beforehand.',
    description: 'Payment form message informing user they will be emailed before charged',
  },
  lateCancellationOrNoShowDisclaimer: {
    defaultMessage:
      'A payment is due because of a previous late cancellation or no-show. Add a debit or credit card to cover this payment',
    description:
      'Body text underneath title in infraction payment form. Explains why Lyra needs your credit/debit card information for infractions',
  },
})

export const defaultHealthPlanDisplayName = defineMessage({
  defaultMessage: 'EPO/PPO plan',
  description: 'Text to denote a default health plan name',
})

export const notEnrolledHealthPlanOption = defineMessage({
  defaultMessage:
    '{searchFor, select, self {I’m not} other {This child isn’t}} enrolled in a {hasCustomerName, select, true {health plan through {customerName}} other {health plan}}',
  description: 'Option text denoting that the user is not enrolled in a health plan',
})

import { careConsentFormError } from '../common/constants/messages'

type ContentAttributes = {
  [key: string]: {
    bypassReview?: boolean
    linkedAssignmentTitle?: string
    customErrorMessage?: { defaultMessage: string; description: string }
    hideInClientActivities?: boolean
    hideInstructionsIfHasPrereq?: boolean
    initialPageIfHasPrereq?: number
    linkedStatusAssignments?: string[]
    prerequisite?: string
    postrequisite?: string
    shownParentActivity?: string
    singlePageContent?: boolean
  }
}

export const customContentAttributesPhase1: ContentAttributes = {
  audCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'audClientIntake',
    singlePageContent: true,
    shownParentActivity: 'audClientIntake',
  },
  audClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['audCareConsent', 'audClientIntake'],
    prerequisite: 'audCareConsent',
  },
  bccCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'bccClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bccClientIntake',
  },
  bccClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bccCareConsent', 'bccClientIntake'],
    prerequisite: 'bccCareConsent',
  },
  bcmCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'bcmClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bcmClientIntake',
  },
  bcmClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bcmCareConsent', 'bcmClientIntake'],
    prerequisite: 'bcmCareConsent',
  },
  bctCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'bctClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bctClientIntake',
  },
  bctClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bctCareConsent', 'bctClientIntake'],
    prerequisite: 'bctCareConsent',
  },
  cleCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'cleClientIntake',
    singlePageContent: true,
    shownParentActivity: 'cleClientIntake',
  },
  cleClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['cleCareConsent', 'cleClientIntake'],
    prerequisite: 'cleCareConsent',
  },
  sscCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'sscClientIntake',
    singlePageContent: true,
    shownParentActivity: 'sscClientIntake',
  },
  sscClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['sscCareConsent', 'sscClientIntake'],
    prerequisite: 'sscCareConsent',
  },
  lcttCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'lcttClientIntake',
    singlePageContent: true,
    shownParentActivity: 'lcttClientIntake',
  },
  lcttClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['lcttCareConsent', 'lcttClientIntake'],
    prerequisite: 'lcttCareConsent',
  },
}

export const customContentAttributesPhase2: ContentAttributes = {
  audCareConsent: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'audClientIntake',
    singlePageContent: true,
    shownParentActivity: 'audClientIntake',
  },
  audClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['audCareConsent', 'audClientIntake', 'audInitialAssessmentMetadata'],
    prerequisite: 'audCareConsent',
    postrequisite: 'audInitialAssessmentMetadata',
  },
  audInitialAssessmentMetadata: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    prerequisite: 'audClientIntake',
    shownParentActivity: 'audClientIntake',
  },
  bccCareConsent: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'bccClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bccClientIntake',
  },
  bccClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bccCareConsent', 'bccClientIntake', 'bccBaselineMetadata'],
    prerequisite: 'bccCareConsent',
    postrequisite: 'bccBaselineMetadata',
  },
  bccBaselineMetadata: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    prerequisite: 'bccClientIntake',
    shownParentActivity: 'bccClientIntake',
  },
  bcmCareConsent: {
    bypassReview: true,
    linkedAssignmentTitle: 'Client Intake',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'bcmClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bcmClientIntake',
  },
  bcmClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Client Intake',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bcmCareConsent', 'bcmClientIntake', 'baseBaselineExtendedMetadata'],
    prerequisite: 'bcmCareConsent',
    postrequisite: 'baseBaselineExtendedMetadata',
  },
  baseBaselineExtendedMetadata: {
    bypassReview: true,
    linkedAssignmentTitle: 'Client Intake',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    prerequisite: 'bcmClientIntake',
    shownParentActivity: 'bcmClientIntake',
  },
  bctCareConsent: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'bctClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bctClientIntake',
  },
  bctClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bctCareConsent', 'bctClientIntake', 'baseInitialAssessmentMetadata'],
    prerequisite: 'bctCareConsent',
    postrequisite: 'baseInitialAssessmentMetadata',
  },
  baseInitialAssessmentMetadata: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    prerequisite: 'bctClientIntake',
    shownParentActivity: 'bctClientIntake',
  },
  cleCareConsent: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'cleClientIntake',
    singlePageContent: true,
    shownParentActivity: 'cleClientIntake',
  },
  cleClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['cleCareConsent', 'cleClientIntake', 'cleInitialAssessment'],
    prerequisite: 'cleCareConsent',
    postrequisite: 'cleInitialAssessment',
  },
  cleInitialAssessment: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    prerequisite: 'cleClientIntake',
    shownParentActivity: 'cleClientIntake',
  },
  lcttCareConsent: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'lcttClientIntake',
    singlePageContent: true,
    shownParentActivity: 'lcttClientIntake',
  },
  lcttClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['lcttCareConsent', 'lcttClientIntake', 'lcttInitialAssessment'],
    prerequisite: 'lcttCareConsent',
    postrequisite: 'lcttInitialAssessment',
  },
  lcttInitialAssessment: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    prerequisite: 'lcttClientIntake',
    shownParentActivity: 'lcttClientIntake',
  },
  sscCareConsent: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    postrequisite: 'sscClientIntake',
    singlePageContent: true,
    shownParentActivity: 'sscClientIntake',
  },
  sscClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['sscCareConsent', 'sscClientIntake', 'sscBaselineMetadata'],
    prerequisite: 'sscCareConsent',
    postrequisite: 'sscBaselineMetadata',
  },
  sscBaselineMetadata: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    prerequisite: 'sscClientIntake',
    shownParentActivity: 'sscClientIntake',
  },
}

export const LOADING_STATUS = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
}

export const SET_LOADING_INDICATOR_STATUS = 'SET_LOADING_INDICATOR_STATUS'
export const setLoadingIndicatorStatus = (status: $TSFixMe) => ({
  type: SET_LOADING_INDICATOR_STATUS,
  status,
})

export class LoadingIndicatorController {
  static setIndicatorLoading() {
    return function (dispatch: $TSFixMe, _getState: $TSFixMe) {
      dispatch(setLoadingIndicatorStatus(LOADING_STATUS.LOADING))
    }
  }

  static setIndicatorIdle() {
    return function (dispatch: $TSFixMe, _getState: $TSFixMe) {
      dispatch(setLoadingIndicatorStatus(LOADING_STATUS.IDLE))
    }
  }
}

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { userProfileMessages } from '@lyrahealth-inc/shared-app-logic'

import styles from './header.module.scss'
import { PAGE_ROUTES } from '../../../features/onboard/data/page-navigation/location-actions'
import BackButton from '../backButton/BackButton'

const pathToTitle = (path: string, isClientHPI?: boolean) => {
  switch (path) {
    case '/care-team-phone-numbers':
      return (
        <FormattedMessage
          defaultMessage='Care support'
          description='Header text for care navigator team support page'
        />
      )
    case '/secure/profile/update-location':
      return <FormattedMessage {...userProfileMessages.location} />
    case '/secure/profile/update-notification-settings':
      return <FormattedMessage {...userProfileMessages.notifications} />
    case '/secure/profile/update-personal-information':
      return <FormattedMessage {...userProfileMessages.personalInformation} />
    case '/secure/profile/update-child-information':
      return <FormattedMessage {...userProfileMessages.childInformation} />
    case PAGE_ROUTES.PROFILE.ELIGIBILITY:
      return <FormattedMessage defaultMessage='Eligibility' description='Title for the profile eligibility page' />
    case PAGE_ROUTES.PROFILE.SETTINGS:
      return <FormattedMessage {...userProfileMessages.settings} />
    case PAGE_ROUTES.PROFILE.SESSION_HISTORY:
      return <FormattedMessage {...userProfileMessages.sessionHistory} />
    case PAGE_ROUTES.PROFILE.HEALTH_PLAN_AND_PAYMENTS:
      return isClientHPI ? (
        <FormattedMessage {...userProfileMessages.healthPlanAndPayments} />
      ) : (
        <FormattedMessage {...userProfileMessages.payment} />
      )
    case PAGE_ROUTES.PROFILE.PRIVACY_AND_LEGAL:
      return <FormattedMessage {...userProfileMessages.privacyAndLegal} />
    default:
      return null
  }
}

const pathToBackButtonLocation = (path: string) => {
  switch (path) {
    case PAGE_ROUTES.PROFILE.HEALTH_PLAN_AND_PAYMENTS:
    case PAGE_ROUTES.PROFILE.SETTINGS:
    case PAGE_ROUTES.PROFILE.PRIVACY_AND_LEGAL:
      return '/secure/profile'
    default:
      return -1
  }
}

const MobileHeader = ({ pathname, isClientHPI }: { pathname: string; isClientHPI?: boolean }) => {
  return (
    <div className={styles['mobile-header']}>
      <BackButton backButtonLocation={pathToBackButtonLocation(pathname)} />
      <h4 className={styles.title}>{pathToTitle(pathname, isClientHPI)}</h4>
    </div>
  )
}

export default MobileHeader

import { AllCountryValues, CountryValues } from '../../models'
import { DOMESTIC_COUNTRY_ISO_CODES } from '../common/constants/constants'

import type { CountryCode as CountryIsoCode } from 'libphonenumber-js'

export const filterDomesticCountries = (countryList: CountryValues[]) => {
  return countryList.filter((country) => !DOMESTIC_COUNTRY_ISO_CODES.includes(country.countryIsoCode))
}
// Mapping needed to these field names to be compatible with the CountrySelection component
export const convertAllCountryValuesToCountryValues = (supportedCountryList: AllCountryValues[]): CountryValues[] => {
  return supportedCountryList?.map((country) => ({
    countryId: country.id,
    countryName: country.name,
    countryIsoCode: country.isoCode as CountryIsoCode,
    outOfCountryNumbersList: country.outOfCountryNumbersList,
    tollFreeNumbersList: country.tollFreeNumbersList,
  }))
}

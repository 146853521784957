/* eslint-disable formatjs/no-literal-string-in-jsx */

import {
  ELIGIBILITY_TYPES,
  EligibilityField,
  EligibilityInfo,
  FormMetadata,
  starbucksCustomerCodeFieldLabel,
  starbucksEligibilityFormHeader,
  ThemeColors,
} from '@lyrahealth-inc/shared-app-logic'

import { commonInputFieldBorderStyle } from '../../../organisms/formBody/sharedMetadata'
import { getFontStyles } from '../../../styles'

export const starbucksEmployeeEligibilityMetadata = (
  showRegistrationTips: boolean,
  colors: ThemeColors,
): FormMetadata => {
  return {
    schema: {
      type: 'object',
      properties: {
        employeeFormSubHeader: {
          name: 'employeeFormSubHeader',
          content: `#### ${starbucksEligibilityFormHeader}`,
          condition: {
            parentField: EligibilityField.ELIGIBILITY_TYPE,
            parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
          },
        },
        employeeRegistrationTips: {
          type: 'string',
          show: showRegistrationTips,
          name: 'employeeRegistrationTips',
          condition: {
            parentField: EligibilityField.ELIGIBILITY_TYPE,
            parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
          },
        },
        [EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME]: {
          type: 'string',
          title: starbucksCustomerCodeFieldLabel,
          name: EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME,
          condition: {
            parentField: EligibilityField.ELIGIBILITY_TYPE,
            parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
          },
          validation: {
            isRequired: true,
            isValidCustomerCode: true,
          },
        },
        partnerNumberText: {
          content: 'Please use your Starbucks partner number only (no preceding US or 0)',
          name: 'partnerBodyText',
          condition: {
            parentField: EligibilityField.ELIGIBILITY_TYPE,
            parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
          },
        },
      },
    },
    uiSchema: {
      'ui:order': [
        'employeeFormSubHeader',
        'employeeRegistrationTips',
        EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME,
        'partnerNumberText',
      ],
      [EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME]: {
        'ui:options': {
          baseInputStyle: {
            marginBottom: 0,
          },
          ...commonInputFieldBorderStyle,
        },
      },
      employeeRegistrationTips: { 'ui:field': 'EmployeeStarbucksEligibilityErrorBanner' },
      partnerNumberText: {
        'ui:options': {
          customStyles: {
            p2: {
              ...getFontStyles(colors).body.small,
              color: colors.textSecondary,
            },
          },
        },
      },
    },
  }
}

export const generateStarbucksEmployeeEligibilityMetadata = (
  { showRegistrationTips = false }: EligibilityInfo,
  colors: ThemeColors,
) => {
  return starbucksEmployeeEligibilityMetadata(!!showRegistrationTips, colors)
}

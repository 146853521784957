import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { ThemeType, tID } from '../../utils'

/**
 * Icon for Person with Plus sign
 */
export const PersonPlusIcon: FunctionComponent<PersonPlusIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme() as ThemeType

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' testID={tID('PersonPlusIcon')}>
      <Path
        d='M20 8L20 12'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M22 10L18 10'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M9.5 11C11.433 11 13 9.433 13 7.5C13 5.567 11.433 4 9.5 4C7.567 4 6 5.567 6 7.5C6 9.433 7.567 11 9.5 11Z'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M17 20V19C17 16.2386 14.7614 14 12 14H7C4.23858 14 2 16.2386 2 19V20'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  )
}

type PersonPlusIconProps = {
  size?: string | number
  fillColor?: string
}

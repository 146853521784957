import { axiosInstance } from '../../../common/http/axiosInstance'

export const UPDATE_EDITABLE_NOTE_VALUE = 'UPDATE_EDITABLE_NOTE_VALUE'
export const updateEditableNoteValue = (val: $TSFixMe) => ({
  type: UPDATE_EDITABLE_NOTE_VALUE,
  val,
})

export const CLEAR_EDITABLE_NOTE = 'CLEAR_EDITABLE_NOTE'
export const clearEditableNote = () => ({ type: CLEAR_EDITABLE_NOTE })

export const SET_NOTES = 'SET_NOTES'
// @ts-expect-error TS(7006): Parameter 'lyraId' implicitly has an 'any' type.
export const setNotes = (lyraId, notes) => ({
  type: SET_NOTES,
  lyraId,
  notes,
})

export function addEditableNote() {
  return function (dispatch: $TSFixMe, getState: $TSFixMe) {
    const state = getState()
    const lyraId = state.getIn(['commonNotes', 'lyraId'])
    const name = `${state.getIn(['user', 'firstname'], '')} ${state.getIn(['user', 'lastname'], '')}`.trim()
    const text = JSON.stringify(state.getIn(['commonNotes', 'editableNote'], ''))
    const query = `
mutation AddNote{
    noteAdded: addNote(method:"addNote", text:${text}, lyraId: "${lyraId}", author:"${name}"){
    lyraId
    providerName
    text
    dateString
    author
    notesId
    noteType
  }
}
`
    const data = {
      query,
      variables: { var: 'hi' },
      operationName: 'AddNote',
    }
    const url = '/v1/graphql'
    axiosInstance.post(url, data).then(({ data: r }) => {
      if (!r.errors) {
        dispatch(setNotes(lyraId, r.data.noteAdded))
        dispatch(clearEditableNote())
      }
    })
  }
}

export function getLatestNotes(_lyraId = '19d38cd6-98e5-49f6-8f33-285740b48908') {
  const query = `
query GetLatestNotes{
    notes: latestNotes{
    lyraId
    providerName
    text
    dateString
    author
    notesId
    noteType
  }
}
`
  const data = {
    query,
    variables: { var: 'hi' },
  }
  const url = '/v1/graphql'
  return axiosInstance.post(url, data)
}

import React, { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { FlatList, ListRenderItem, NativeScrollEvent, NativeSyntheticEvent, Text, View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { MessageItem } from '../../molecules/messageItem/MessageItem'
import { Flex1View } from '../../templates/content/CommonViews'
import { Message, MessageAttachmentData } from '../../ui-models'
import { ActivityReviewInfo } from '../../ui-models/assignments/Assignments'
import { ThemeType, tID } from '../../utils'

type MessagesProps = {
  messages: Message[]
  onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void
  scrollInfo?: MessagesScrollInfo
  listHeaderComponent?: ReactElement<View | Text> | null
  NewMessageView?: ReactElement<View | Text> | null
  onChatBubblePressed?: (messageId: string, receiver: boolean, activityInfo?: ActivityReviewInfo) => void
  onLinkPress?: (link: string) => void
  emptyMessagesView?: ReactElement | string
  shouldInvert?: boolean
  onAttachmentPressed?: (attachment: MessageAttachmentData) => void
}

type MessagesScrollInfo = {
  scrollToEnd: boolean
  animatedScroll: boolean
}

const MessagesContainer = styled(Flex1View)<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  backgroundColor: colors.backgroundSecondary,
  flexShrink: 1,
  flexBasis: 'auto',
}))

const EmptyView = styled(Flex1View)({
  justifyContent: 'center',
})

const MessageItemContainer = styled.View({
  paddingHorizontal: '15px',
  flexShrink: 1,
})

/**
 * These are the messages that show up in a chat thread
 * which includes time stamps, avatar, and chat bubble for each message
 * This component uses a FlatList as it has performance benefits for lazily rendering items
 * at a time and has future benefits of having the ability to add a loading indicator in case we need to pull messages
 * It inherits ScrollView props: https://docs.expo.io/versions/latest/react-native/flatlist/
 */
export const Messages: FunctionComponent<MessagesProps> = ({
  messages,
  onScroll,
  scrollInfo,
  listHeaderComponent,
  onChatBubblePressed,
  onLinkPress,
  emptyMessagesView,
  NewMessageView,
  shouldInvert = true,
  children,
  onAttachmentPressed,
}) => {
  const flatListRef = useRef<FlatList<Message> | null>(null)
  const { colors } = useTheme()

  // renders a single message
  const renderItem: ListRenderItem<Message> = ({ item }) => {
    return (
      <MessageItemContainer>
        <MessageItem
          item={item}
          onChatBubblePressed={onChatBubblePressed}
          onAttachmentPressed={onAttachmentPressed}
          onLinkPress={onLinkPress}
        />
        {/* The typing indicator will show after the last message */}
        {item.isLatestMessage && children}
      </MessageItemContainer>
    )
  }

  const scrollToEndCb = useCallback(
    (animated = false) => {
      if (flatListRef.current && messages.length > 0) {
        shouldInvert
          ? flatListRef.current.scrollToOffset({ animated, offset: 0 })
          : flatListRef.current.scrollToEnd({ animated })
      }
    },
    [shouldInvert, messages.length],
  )

  // check if the parent is requesting to scroll to the bottom of the message list
  useEffect(() => {
    if (scrollInfo) {
      const { scrollToEnd, animatedScroll } = scrollInfo
      if (scrollToEnd) {
        scrollToEndCb(animatedScroll)
      }
    }
  }, [scrollInfo, scrollToEndCb])

  // Since the FlatList is inverted we need to flip this view to display correctly
  const positionedEmptyView = useMemo(() => <EmptyView>{emptyMessagesView}</EmptyView>, [emptyMessagesView])

  const keyExtractor = (message: Message) => message.messageId
  // minIndexForVisible prevents jumping when items are added to list (typing indicator)
  // autoscrollToTopThresholdwill allow autoscrolling up to 75 pixels for new messages to allow new messages to show if at bottom
  const visibleContentConfig = { minIndexForVisible: 0, autoscrollToTopThreshold: 100 }
  return (
    <MessagesContainer>
      <FlatList
        testID={tID('Messages')}
        data={messages}
        ref={flatListRef}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        contentContainerStyle={{
          paddingVertical: 15,
          backgroundColor: colors.backgroundSecondary,
        }}
        maintainVisibleContentPosition={visibleContentConfig}
        onScroll={onScroll}
        ListHeaderComponent={listHeaderComponent}
        ListHeaderComponentStyle={{
          paddingLeft: 15,
          paddingTop: 5,
        }}
        inverted={messages?.length > 0 ? shouldInvert : false}
        invertStickyHeaders={false}
        ListEmptyComponent={positionedEmptyView}
        disableVirtualization
      />
      <View onFocus={scrollToEndCb}>{NewMessageView}</View>
    </MessagesContainer>
  )
}

import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { getStringIndexAsInt } from '@lyrahealth-inc/shared-app-logic'

import {
  CLEAR_MODALITY_QUESTIONNAIRE_ANSWERS,
  GET_MODALITY_QUESTIONNAIRE_SURVEY,
  SAVE_MODALITY_QUESTIONNAIRE_ANSWER_GROUP,
  SET_MODALITY_QUESTIONNAIRE_RECOMMENDATIONS,
  SHOW_MODALITY_SURVEY_RECOMMENDATIONS,
} from './modalityQuestionnaireActions'

const initialState = fromJS({
  answers: [],
  surveyData: {},
  recommendations: [],
  showSurveyRecommendations: false,
})

export default createReducer(initialState, {
  [GET_MODALITY_QUESTIONNAIRE_SURVEY]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.setIn(['surveyData'], fromJS(action.payload))
    return $$state
  },
  [SAVE_MODALITY_QUESTIONNAIRE_ANSWER_GROUP]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const index = getStringIndexAsInt(action.groupIndex, true)
    $$state = $$state.setIn(['answers', index], fromJS(action.payload.pageResponses[index]))
    return $$state
  },
  [SET_MODALITY_QUESTIONNAIRE_RECOMMENDATIONS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.setIn(['recommendations'], fromJS(action.payload))
    return $$state
  },
  [CLEAR_MODALITY_QUESTIONNAIRE_ANSWERS]: ($$state: $TSFixMe) => {
    $$state = $$state.setIn(['answers'], fromJS([]))
    return $$state
  },
  [SHOW_MODALITY_SURVEY_RECOMMENDATIONS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.setIn(['showSurveyRecommendations'], action.payload)
    return $$state
  },
})

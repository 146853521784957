import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import { Map } from 'immutable'
import { noop } from 'lodash-es'

import {
  COMMON_MIXPANEL_PROPERTIES,
  EmailCaptureArmType,
  EmailCaptureDescription,
  EmailCaptureMetric,
  ExperimentPayload,
  IDENTITY_PROVIDERS,
  LANG_QUERY_PARAM,
  LD_CUSTOM_EVENTS,
  REGISTRATION_EVENTS,
  REGISTRATION_PAGES,
  sanitizeEmailAddressInput,
  useEmailCaptureExperiment,
  useFlags,
  useLyraIntl,
} from '@lyrahealth-inc/shared-app-logic'
import { useLDClient } from '@lyrahealth-inc/shared-app-logic/src/features/flags/launchdarkly-client-sdk.web'
import { Layout, Modal, ReCaptchaPrivacyAndTerms, toJS } from '@lyrahealth-inc/ui-core-crossplatform'
import {
  SSO_BUTTON_MESSAGE_TYPE,
  SSO_BUTTON_TYPE,
} from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/ssoButton/SsoButton'
import {
  EmailCaptureFormValues,
  RegistrationEmailCaptureForm,
} from '@lyrahealth-inc/ui-core-crossplatform/src/organisms/registrationEmailCaptureForm/RegistrationEmailCaptureForm'
import { RegistrationVariantEmailCaptureForm } from '@lyrahealth-inc/ui-core-crossplatform/src/organisms/registrationEmailCaptureForm/RegistrationVariantEmailCaptureForm'

import styles from './registrationEmailCaptureContainer.module.scss'
import { SSOIframe } from './SSOIframe'
import { BANNER_TYPE, MESSAGE, setBannerPropsAndStatus } from '../../../common/components/banner/banner-actions'
import {
  CLICK_SSO_SIGN_UP_BUTTON,
  ERROR_CODES,
  GET_APPLE_SIGN_UP_ERROR,
  GET_APPLE_SIGN_UP_TOKEN,
  GET_GOOGLE_SIGN_UP_TOKEN,
  GET_SIGN_UP_BUTTON_WIDTH_MESSAGE,
  SEND_SIGN_UP_BUTTON_WIDTH_MESSAGE,
} from '../../../common/constants/appConstants'
import { useTrackViewPage } from '../../../common/hooks/useTrackViewPage'
import { postEmailVerification } from '../../../common/http/data/verifyEmail'
import { getCaptchaKey } from '../../../common/utils/customerUtils'
import { logToSumoLogic } from '../../../common/utils/userUtils'
import { postExperimentEntrypoint } from '../../../data/appGlobals/appGlobalsActions'
import { getAppleSSOClientId, getDeviceUUID, getGoogleSSOClientId } from '../../../data/appGlobals/appGlobalsSelectors'
import {
  getCustomerName,
  getDepartingRedirectURL,
  getIsAppleSSODisabled,
  getIsGoogleSSODisabled,
} from '../../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../../data/mixpanel'
import { getSelectedEssentialsTopicIds, getSelectedPathwayOption } from '../../../data/register/registerSelectors'
import { PAGE_ROUTES } from '../../onboard/data/page-navigation/location-actions'
import { dispatchMixpanelForEmailConfirmation, setSsoIdentityProvider, setSsoToken } from '../data/registerActions'

const SSO_SIGN_UP_IFRAME_HEIGHT = 70
const { UNPROCESSABLE_ENTITY: USER_ALREADY_VERIFIED_STATUS_CODE } = ERROR_CODES

type RegistrationEmailCaptureContainerProps = ConnectedProps<typeof connector> & {
  isModalView?: boolean
  showModalDelay?: number
  enableModalClose?: boolean
  onCloseRegistrationModal?: () => void
  isPreview?: boolean
  previewSubmitCallback?: (email: string, error?: string) => void
  previewComponents?: React.ReactElement
}

const RegistrationEmailCaptureContainer: FC<RegistrationEmailCaptureContainerProps> = ({
  recaptchaKey,
  customerName,
  appleSSOClientId,
  isAppleSSODisabled,
  googleSSOClientId,
  isGoogleSSODisabled,
  departingRedirectUrl,
  selectedPathwayOption,
  dispatchMixpanelForEmailConfirmation,
  setBannerPropsAndStatus,
  setSsoIdentityProvider,
  setSsoToken,
  selectedEssentialsTopicIds,
  isModalView = false,
  showModalDelay = 0,
  enableModalClose = false,
  onCloseRegistrationModal,
  trackEventWithObj,
  isPreview = false,
  previewSubmitCallback = () => {},
  previewComponents,
}) => {
  const { emailCapturePageExperiment } = useFlags()
  const navigate = useNavigate()
  const { activeLanguage } = useLyraIntl()

  const [isEmailError, setIsEmailError] = useState(false)
  const [isSSOLoading, setIsSSOLoading] = useState(true)
  const [recaptchaResponse, setRecaptchaResponse] = useState('none')
  const [isGenerateVerificationTokenLoading, setIsGenerateVerificationTokenLoading] = useState(false)
  const appleSSOAvailable = !isAppleSSODisabled && appleSSOClientId
  const googleSSOAvailable = !isGoogleSSODisabled && googleSSOClientId

  const [hasLoadedAppleSSO, setHasLoadedAppleSSO] = useState(appleSSOAvailable ? false : undefined)
  const [hasLoadedGoogleSSO, setHasLoadedGoogleSSO] = useState(googleSSOAvailable ? false : undefined)
  const [ssoIframeHeight, setSsoIframeHeight] = useState(0)

  const captchaRef = useRef<HTMLFormElement>(null)
  const googleIframeRef = useRef<HTMLIFrameElement>(null)
  const googleIframeContainerRef = useRef<HTMLDivElement>(null)
  const appleIframeRef = useRef<HTMLIFrameElement>(null)
  const appleIframeContainerRef = useRef<HTMLDivElement>(null)
  const careOrigin = window.location.origin.replace(customerName + '.', 'care.')
  const { state } = useLocation()
  /**
   * Since Okta widget sign up link requires a string to
   * route user to sign up page, we pass query param entrypoint
   * so that when a user navigates to this page from the login page
   * so that we can sent it to mixpanel
   */
  const [searchParams] = useSearchParams()
  const mixpanelEntryPoint = state?.mixpanelEntryPoint || searchParams.get('entryPoint')
  const routeOrigin = state?.origin
  const deviceUUID = useSelector(getDeviceUUID)

  const ldClient = useLDClient()
  const ldClientCallback = (event: LD_CUSTOM_EVENTS, payload: ExperimentPayload) => {
    ldClient?.track(event, payload)
  }
  const postMixpanelCallback = (event: string, eventData: { [key: string]: any }) => {
    trackEventWithObj({ event, ...eventData })
  }
  const logToSumo = (message: string) => {
    logToSumoLogic('lyraWebErrors', null, {
      message: message,
      type: 'error',
    })
  }

  const arm = !!emailCapturePageExperiment ? EmailCaptureArmType.EXPERIMENT : EmailCaptureArmType.CONTROL

  const { variant, submitExperimentPayload: handleEmailCaptureExperiment } = useEmailCaptureExperiment({
    arm: arm,
    deviceUUID: deviceUUID,
    customerName: customerName,
    pathwayOption: selectedPathwayOption,
    postExperimentCallback: postExperimentEntrypoint,
    postLDCallback: ldClientCallback,
    postMixpanelCallback: postMixpanelCallback,
    logToSumo: logToSumo,
  })

  useEffect(() => {
    if (ldClient != null) {
      // Setting timeout to resolve issue where ldClient was not tracking events regardless of it being initialized and track() succeeding
      setTimeout(() => {
        handleEmailCaptureExperiment({
          metric: EmailCaptureMetric.VIEW_PAGE,
          description: EmailCaptureDescription.VIEW_PAGE,
          launchDarklyEvent: LD_CUSTOM_EVENTS.EMAIL_CAPTURE_PAGE_VIEWED,
          // Omit mixpanelEvent arg since the page view event is tracked using useTrackViewPage below
        })
      }, 2000)
    }
  }, [handleEmailCaptureExperiment, ldClient])

  useTrackViewPage(REGISTRATION_PAGES.EMAIL_CAPTURE, {
    ...(mixpanelEntryPoint && { [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: mixpanelEntryPoint }),
    arm,
    variant,
  })

  useEffect(() => {
    if (departingRedirectUrl) {
      window.location.replace(departingRedirectUrl as string)
    }
  }, [departingRedirectUrl])

  useEffect(() => {
    const iframe = googleIframeRef.current
    window.onmessage = (event) => {
      const iframeContainer = googleIframeContainerRef.current

      if (!iframe?.contentWindow || !iframeContainer) {
        return
      }

      const sendButtonContainerWidthMsg = () => {
        const width = iframeContainer.offsetWidth
        iframe.contentWindow?.postMessage({ message: SEND_SIGN_UP_BUTTON_WIDTH_MESSAGE, width }, careOrigin)
      }

      if (event.origin === careOrigin) {
        // Handle Google iframe sizing (communicates with iframe via window.onmessage since we use Google JS API to render button)
        if (event.data === GET_SIGN_UP_BUTTON_WIDTH_MESSAGE) {
          if (iframe?.contentWindow) {
            sendButtonContainerWidthMsg()
          }

          const resizeObserver = new ResizeObserver(sendButtonContainerWidthMsg)
          resizeObserver.observe(iframeContainer)
        }

        // Handle error on Apple button click
        if (event.data === GET_APPLE_SIGN_UP_ERROR) {
          setBannerPropsAndStatus(BANNER_TYPE.DANGER, MESSAGE.EMAIL_REGISTRATION_METHOD_FAILED, true)
        }

        // Send email capture experiment data when SSO button is clicked
        if (event?.data?.message === CLICK_SSO_SIGN_UP_BUTTON) {
          let emailCaptureExperimentData = {}
          if (event.data?.identityProvider === IDENTITY_PROVIDERS.APPLE) {
            emailCaptureExperimentData = {
              metric: EmailCaptureMetric.CLICK_CONTINUE_WITH_APPLE,
              description: EmailCaptureDescription.CLICK_CONTINUE_WITH_APPLE,
              launchDarklyEvent: LD_CUSTOM_EVENTS.EMAIL_CAPTURE_PAGE_CONTINUE_WITH_APPLE,
              mixpanelEvent: REGISTRATION_EVENTS.CLICK_SIGN_UP_WITH_APPLE,
            }
          } else if (event.data?.identityProvider === IDENTITY_PROVIDERS.GOOGLE) {
            emailCaptureExperimentData = {
              metric: EmailCaptureMetric.CLICK_CONTINUE_WITH_GOOGLE,
              description: EmailCaptureDescription.CLICK_CONTINUE_WITH_GOOGLE,
              launchDarklyEvent: LD_CUSTOM_EVENTS.EMAIL_CAPTURE_PAGE_CONTINUE_WITH_GOOGLE,
              mixpanelEvent: REGISTRATION_EVENTS.CLICK_SIGN_UP_WITH_GOOGLE,
            }
          }

          handleEmailCaptureExperiment(emailCaptureExperimentData)
        }

        if (
          event.data &&
          (event.data?.message === GET_APPLE_SIGN_UP_TOKEN || event.data?.message === GET_GOOGLE_SIGN_UP_TOKEN)
        ) {
          if (event.data.message === GET_APPLE_SIGN_UP_TOKEN) {
            setSsoIdentityProvider(IDENTITY_PROVIDERS.APPLE)
          } else if (event.data.message === GET_GOOGLE_SIGN_UP_TOKEN) {
            setSsoIdentityProvider(IDENTITY_PROVIDERS.GOOGLE)
          }
          setSsoToken(event.data?.token)
        }
      }
    }
  }, [careOrigin, setSsoToken, setBannerPropsAndStatus, setSsoIdentityProvider, handleEmailCaptureExperiment])

  useEffect(() => {
    if (hasLoadedAppleSSO || hasLoadedGoogleSSO) {
      isSSOLoading && setIsSSOLoading(false)
      setSsoIframeHeight(SSO_SIGN_UP_IFRAME_HEIGHT)
    }
  }, [hasLoadedAppleSSO, hasLoadedGoogleSSO, isSSOLoading, setIsSSOLoading])

  const submitForm = async (values: EmailCaptureFormValues) => {
    const sanitizedEmail = sanitizeEmailAddressInput(values.emailAddress)
    if (sanitizedEmail) {
      values.emailAddress = sanitizedEmail
    }

    setIsGenerateVerificationTokenLoading(true)
    try {
      await postEmailVerification({
        username: encodeURIComponent(values.emailAddress),
        language: encodeURIComponent(activeLanguage),
        ...(deviceUUID && { deviceUUID: encodeURIComponent(deviceUUID) }),
        ...(selectedPathwayOption && { pathwayOption: encodeURIComponent(selectedPathwayOption) }),
        ...(selectedEssentialsTopicIds && { essentialsTopicIds: encodeURIComponent(selectedEssentialsTopicIds) }),
      })

      handleEmailCaptureExperiment({
        metric: EmailCaptureMetric.CLICK_CONTINUE,
        description: EmailCaptureDescription.CLICK_CONTINUE,
        launchDarklyEvent: LD_CUSTOM_EVENTS.EMAIL_CAPTURE_PAGE_CONTINUE,
        mixpanelEvent: REGISTRATION_EVENTS.REQUEST_EMAIL_VERIFICATION,
      })

      // TODO: Uncomment below once email capture experiment is stopped. Mixpanel event for email confirmation is tracked in handleEmailCaptureExperiment
      // LaunchDarkly flag: emailCapturePageExperiment, JIRA: ACCOUNT-2368
      //dispatchMixpanelForEmailConfirmation()

      // Wellness check in flow should stay on current page rather than redirect
      if (isPreview && previewSubmitCallback) {
        previewSubmitCallback(values.emailAddress)
      } else {
        navigate(`/register/verify?email=${encodeURIComponent(values.emailAddress)}`)
      }
    } catch (error: any) {
      if (error?.response?.status === USER_ALREADY_VERIFIED_STATUS_CODE) {
        if (isPreview && previewSubmitCallback) {
          previewSubmitCallback(values.emailAddress, 'registered')
        } else {
          navigate(`/register/verify?error=registered`)
        }
      } else {
        setIsEmailError(true)
      }
    } finally {
      setIsGenerateVerificationTokenLoading(false)
    }
  }

  const onSignUpButtonPress = async (values: EmailCaptureFormValues) => {
    setIsEmailError(false)
    if (recaptchaResponse === 'none') {
      try {
        await captchaRef?.current?.execute()
        submitForm(values)
      } catch (error: unknown) {
        console.error(error as string)
      }
    } else {
      submitForm(values)
    }
  }

  const onSignInLinkPress = () => {
    navigate(`/login`)
  }

  const onGoogleSSOLoadCallback = useCallback(() => {
    setHasLoadedGoogleSSO(true)
  }, [setHasLoadedGoogleSSO])

  const onAppleSSOLoadCallback = useCallback(() => {
    setHasLoadedAppleSSO(true)
  }, [setHasLoadedAppleSSO])

  const getRegistrationIframeURL = (ssoType: IDENTITY_PROVIDERS.APPLE | IDENTITY_PROVIDERS.GOOGLE) => {
    const ssoRoutes: Record<IDENTITY_PROVIDERS.APPLE | IDENTITY_PROVIDERS.GOOGLE, string> = {
      [IDENTITY_PROVIDERS.APPLE]: PAGE_ROUTES.SSO_REGISTRATION_APPLE,
      [IDENTITY_PROVIDERS.GOOGLE]: PAGE_ROUTES.SSO_REGISTRATION_GOOGLE,
    }
    const iframeUrl = new URL(ssoRoutes[ssoType], careOrigin)
    iframeUrl.searchParams.set(LANG_QUERY_PARAM, activeLanguage)
    if (isExperimentGroup) {
      iframeUrl.searchParams.set('messageType', SSO_BUTTON_MESSAGE_TYPE.CONTINUE)
    }
    const iframeUrlString = iframeUrl.toString()

    return iframeUrlString
  }

  const handleRecaptchaSuccess = (response: string) => {
    setRecaptchaResponse(response)
  }

  const renderGoogleSSOIframe = () => {
    if (!googleSSOAvailable) return undefined

    return (
      <SSOIframe
        iframeRef={googleIframeRef}
        registrationIframeURL={getRegistrationIframeURL(IDENTITY_PROVIDERS.GOOGLE)}
        onLoadCallback={onGoogleSSOLoadCallback}
        iFrameHeight={ssoIframeHeight}
        // This part is so the height of the SSO button section stays constant when loading
        loadingContainerHeight={
          googleSSOAvailable && appleSSOAvailable ? SSO_SIGN_UP_IFRAME_HEIGHT * 2 : SSO_SIGN_UP_IFRAME_HEIGHT
        }
        iframeContainerRef={googleIframeContainerRef}
        isSSOLoading={isSSOLoading}
        type={SSO_BUTTON_TYPE.GOOGLE}
      />
    )
  }

  const renderAppleSSOIframe = () => {
    if (!appleSSOAvailable) return undefined

    return (
      <SSOIframe
        iframeRef={appleIframeRef}
        registrationIframeURL={getRegistrationIframeURL(IDENTITY_PROVIDERS.APPLE)}
        onLoadCallback={onAppleSSOLoadCallback}
        iFrameHeight={ssoIframeHeight}
        iframeContainerRef={appleIframeContainerRef}
        // This part is so we only show one loading spinner when both google and apple SSO is available
        isSSOLoading={googleSSOAvailable ? false : isSSOLoading}
        loadingContainerHeight={googleSSOAvailable ? 0 : SSO_SIGN_UP_IFRAME_HEIGHT}
        padding={googleSSOAvailable ? 20 : 0}
        type={SSO_BUTTON_TYPE.APPLE}
      />
    )
  }

  const renderReCaptchaContent = () => {
    return (
      <>
        <ReCaptchaPrivacyAndTerms singleLineTerms={isModalView} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <ReCAPTCHA
          className={styles.hide}
          ref={captchaRef}
          size='invisible'
          badge='inline'
          sitekey={recaptchaKey}
          onChange={handleRecaptchaSuccess} // Success Callback
        />
      </>
    )
  }

  const bottomSheetRef = useRef<BottomSheetGorhom>(null)
  const snapHeight = useRef(Layout.window.height).current - 40
  const snapPoints = [snapHeight]

  const openBottomSheet = useCallback(() => {
    bottomSheetRef.current?.expand()
  }, [])

  const onClose = useCallback(() => {
    bottomSheetRef.current?.close()
    if (onCloseRegistrationModal) {
      onCloseRegistrationModal()
    }
  }, [onCloseRegistrationModal])

  // Exclude isModalView entry points
  const isExperimentGroup = emailCapturePageExperiment && !isModalView

  const formContent = isExperimentGroup ? (
    <RegistrationVariantEmailCaptureForm
      isEmailError={isEmailError}
      onSignInLinkPress={onSignInLinkPress}
      onSignUpButtonPress={onSignUpButtonPress}
      reCaptchaContent={renderReCaptchaContent()}
      customAppleSSOContent={renderAppleSSOIframe()}
      customGoogleSSOContent={renderGoogleSSOIframe()}
      isGenerateVerificationTokenLoading={isGenerateVerificationTokenLoading}
      routeOrigin={routeOrigin}
      isPreview={isPreview}
      previewComponents={previewComponents}
    />
  ) : (
    <RegistrationEmailCaptureForm
      isModalView={isModalView}
      isEmailError={isEmailError}
      onSignInLinkPress={onSignInLinkPress}
      onSignUpButtonPress={onSignUpButtonPress}
      reCaptchaContent={renderReCaptchaContent()}
      customAppleSSOContent={renderAppleSSOIframe()}
      customGoogleSSOContent={renderGoogleSSOIframe()}
      isGenerateVerificationTokenLoading={isGenerateVerificationTokenLoading}
      customerName={customerName}
    />
  )

  return (
    <>
      {isModalView ? (
        <Modal
          modalContents={formContent}
          visible
          scrollable
          scrollableModalWidth='464px'
          scrollableModalHeight='auto'
          showCloseIcon={enableModalClose}
          showDelay={showModalDelay}
          onRequestClose={enableModalClose ? onClose : noop}
          onCloseEnd={enableModalClose ? onClose : noop}
          snapPoints={snapPoints}
          disableClose={!enableModalClose}
          onLayout={openBottomSheet}
          showCloseIconInBottomSheet={enableModalClose}
        />
      ) : (
        <div className={styles.rootContainer}>{formContent}</div>
      )}
    </>
  )
}

const mapStateToProps = ($$state: Map<string, any>) => {
  return {
    recaptchaKey: getCaptchaKey($$state),
    customerName: getCustomerName($$state),
    appleSSOClientId: getAppleSSOClientId($$state),
    isAppleSSODisabled: getIsAppleSSODisabled($$state),
    googleSSOClientId: getGoogleSSOClientId($$state),
    isGoogleSSODisabled: getIsGoogleSSODisabled($$state),
    departingRedirectUrl: getDepartingRedirectURL($$state),
    selectedPathwayOption: getSelectedPathwayOption($$state),
    selectedEssentialsTopicIds: getSelectedEssentialsTopicIds($$state),
  }
}

const mapDispatchToProps = {
  dispatchMixpanelForEmailConfirmation,
  setBannerPropsAndStatus,
  setSsoIdentityProvider,
  setSsoToken,
  trackEventWithObj,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(RegistrationEmailCaptureContainer))

import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number | string
  outerCircleBgColor?: string
  innerCircleBgColor?: string
}

export const ParentCoachingSpotIllustration: FunctionComponent<Props> = ({
  size = 124,
  outerCircleBgColor = 'white',
  innerCircleBgColor = '#FFE9E3',
}) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 513 512' fill='none'>
      <Path
        d='M258.3 1.91479C222.959 1.91479 188.67 7.97528 156.384 19.9243C79.5203 48.4156 25.4818 111.58 8.11986 193.22C-3.35359 246.449 -1.88652 295.682 12.6323 343.66C31.1679 404.932 76.6671 456.603 137.464 485.418C167.645 499.755 202.319 507.94 240.503 509.741C245.41 509.964 250.398 510.085 255.355 510.085C285.992 510.085 316.81 505.886 346.961 497.61C349.278 497.033 351.483 496.437 353.811 495.749C380.906 487.816 405.289 475.27 426.152 458.505C448.805 440.405 467.452 417.458 481.516 390.343C484.288 385 486.939 379.375 489.863 372.647C505.95 335.292 513.72 293.476 512.344 251.67C511.697 233.185 508.945 213.536 504.19 193.22C496.358 160.256 483.59 129.256 467.26 103.517C462.788 96.6061 458.68 90.7986 454.613 85.5779C425.262 47.7175 379.53 21.1485 322.405 8.7847C301.421 4.23174 279.85 1.92491 258.31 1.92491L258.3 1.91479Z'
        fill={outerCircleBgColor}
      />
      <Path
        d='M490.38 252.357C489.753 234.641 487.042 216.288 482.772 198.046C475.791 168.654 464.317 139.677 448.584 114.868C444.932 109.243 441.188 103.82 437.121 98.6093C407.618 60.5668 362.908 39.4006 317.146 29.4954C266.173 18.4469 211.972 21.6745 162.973 39.8155C92.0881 66.0809 43.8268 123.62 28.0837 197.631C22.0333 225.667 20.2627 257.356 22.9742 287.8H22.7618C24.33 305.101 27.4564 321.987 32.1408 337.518C49.5534 395.057 91.8655 440.718 145.762 466.255C174.952 480.117 207.571 486.997 240.727 488.565C274.601 490.133 309.001 485.853 340.801 477.101C342.885 476.585 344.868 476.059 346.952 475.432C371.862 468.137 393.544 456.876 412.1 441.973C432.325 425.815 448.999 405.489 461.93 380.579C464.742 375.156 467.241 369.743 469.639 364.208C484.856 328.867 491.635 290.511 490.279 252.469L490.38 252.368V252.357Z'
        fill={innerCircleBgColor}
      />
      <Path
        d='M257.716 340.294C266.026 321.104 265.366 292.874 253.176 275.374C244.716 263.224 228.166 253.794 213.706 252.174C198.606 250.484 182.536 251.664 169.316 259.454C157.696 266.294 147.886 276.534 142.556 289.174C139.106 297.344 137.586 306.344 138.056 315.244C138.996 333.034 147.166 351.294 162.076 361.034C180.086 372.794 204.406 377.774 224.776 370.244C237.346 365.594 251.766 354.024 257.706 340.304L257.716 340.294Z'
        fill='#4D9116'
      />
      <Path
        d='M272.986 372.283H374.586C379.96 372.283 384.316 367.927 384.316 362.553V219.753C384.316 214.38 379.96 210.023 374.586 210.023H272.986C267.613 210.023 263.256 214.38 263.256 219.753V362.553C263.256 367.927 267.613 372.283 272.986 372.283Z'
        fill='#3BA5B5'
      />
      <Path
        d='M116.116 199.093L258.246 110.333C264.096 106.683 271.846 109.223 274.376 115.643C292.506 161.573 278.816 213.223 239.176 237.973C199.536 262.733 147.106 252.373 113.806 215.913C109.156 210.823 110.266 202.743 116.116 199.093Z'
        fill='#FFAF9B'
      />
    </Svg>
  )
}

import React, { FunctionComponent } from 'react'
import { G, Path, Polygon, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Lock Icon representing initial screen for biometrics
 */
export const LockPromptIcon: FunctionComponent<LockPromptIconProps> = ({ size = 96 }) => {
  const { colors } = useTheme()
  const { shackle, lockBody, rightBody, keyHole } = colors.icons.lockPromptIcon

  return (
    <Svg width={size} height={size} viewBox='0 0 96 96'>
      <G id='MVP-app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <G id='E!' transform='translate(-24.000000, -103.000000)'>
          <G id='il-96-lock' transform='translate(24.000000, 103.000000)'>
            <G
              id='lock-7051_d81fd840-ed14-4baa-8c59-bf27b51cfda2'
              transform='translate(15.000000, 3.000000)'
              fillRule='nonzero'
            >
              <Path
                d='M13.5,42 L13.5,19.5 C13.5,8.73044738 22.2304474,2.66453526e-15 33,2.66453526e-15 C43.7695526,2.66453526e-15 52.5,8.73044738 52.5,19.5 L52.5,42 L46.5,42 L46.5,19.5 C46.5,12.0441559 40.4558441,6 33,6 C25.5441559,6 19.5,12.0441559 19.5,19.5 L19.5,42 L13.5,42 Z'
                id='Path'
                fill={shackle.fill}
              />
              <Polygon id='Path' fill={lockBody.fill} points='0 42 66 42 66 90 0 90' />
              <Polygon id='Path' fill={rightBody.fill} points='0 42 33 42 33 90 0 90' />
              <Path
                d='M36,70.5 C36,72.1568542 34.6568542,73.5 33,73.5 C31.3431458,73.5 30,72.1568542 30,70.5 L30,61.5 C30,59.8431458 31.3431458,58.5 33,58.5 C34.6568542,58.5 36,59.8431458 36,61.5 L36,70.5 Z'
                id='Path'
                fill={keyHole.fill}
              />
            </G>
          </G>
        </G>
        <G id='-Connections' transform='translate(-1617.000000, -5331.000000)' />
      </G>
    </Svg>
  )
}

export type LockPromptIconProps = {
  size?: string | number
}

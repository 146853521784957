import { LDContext } from 'launchdarkly-js-client-sdk'
import {
  useFlags as realUseFlags,
  useLDClient as realUseLDClient,
  withLDProvider as realWithLDProvider,
} from 'launchdarkly-react-client-sdk'

import { FlagType } from './types'
import { defaultFlags, LDFlags } from './useFlags'
/**
 * This file mocks the LD sdk when being used in a Cypress tests context, and returns the real SDK otherwise.
 * This allows tests to be independent from the state of flags value in LD.
 */
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  interface Window {
    Cypress: any
    LDSDK: any
    initialLDFlags: LDFlags
  }
}
const MOCK_WINDOW = {
  Cypress: null,
  LDSDK: null,
  initialLDFlags: {} as LDFlags,
}
const windowProxy = typeof window === 'undefined' ? MOCK_WINDOW : window

export const useFlags = windowProxy.Cypress
  ? (): LDFlags => ({ ...defaultFlags, ...windowProxy.initialLDFlags })
  : realUseFlags
export const withLDProvider = windowProxy.Cypress
  ? () => {
      return (a: any) => a
    }
  : realWithLDProvider

class LDClientMock {
  defaultFlagType = FlagType.BOOL

  variations: { [key in FlagType]: { [key in string]: any } } = {
    [FlagType.BOOL]: {},
    [FlagType.JSON]: {},
    [FlagType.NUMBER]: {},
    [FlagType.STRING]: {},
  }

  public getContext = (): LDContext => ({})

  public identify = async (
    arg1: any,
    arg2?: any,
    onDone?: (err: Error | null, flags: Partial<LDFlags> | null) => void,
  ) => {
    onDone?.(null, { ...defaultFlags, ...windowProxy.initialLDFlags })
    return Promise.resolve({ ...defaultFlags, ...window.initialLDFlags })
  }

  public track = (key: string, data?: any, metricValue?: number): void => {
    console.debug('LD track called with key, data, metricValue: ', key, data, metricValue)
  }

  public variation = async (flagKey: string) => Promise.resolve(windowProxy.initialLDFlags[flagKey])

  public variationDetail = (flagKey: string) => {
    console.debug('Retrieving variation details for flag: ', flagKey)
    return {
      reason: undefined,
      value: windowProxy.initialLDFlags[flagKey],
      variationIndex: 0,
    }
  }

  private getVariation(flagType: FlagType, flagKey: string, defaultValue: any, environment?: string): Promise<any> {
    const value = this.variations[flagType][flagKey] ?? defaultValue
    console.log('LD', flagType, flagKey, defaultValue, environment, value)
    return value
  }

  async boolVariation(flagKey: string, defaultValue: any, environment?: string): Promise<boolean> {
    return this.getVariation(FlagType.BOOL, flagKey, defaultValue, environment)
  }

  async jsonVariation(flagKey: string, defaultValue: boolean, environment?: string): Promise<{ [key: string]: any }> {
    return this.getVariation(FlagType.JSON, flagKey, defaultValue, environment)
  }

  async stringVariation(flagKey: string, defaultValue: boolean, environment?: string): Promise<string> {
    return this.getVariation(FlagType.STRING, flagKey, defaultValue, environment)
  }

  async numberVariation(flagKey: string, defaultValue: boolean, environment?: string): Promise<number> {
    return this.getVariation(FlagType.NUMBER, flagKey, defaultValue, environment)
  }

  public on = () => null

  public off = () => null

  public waitUntilGoalsReady = () => Promise.resolve()

  public waitUntilReady = () => Promise.resolve()

  public waitForInitialization = () => Promise.resolve()

  public flush = () => Promise.resolve()

  public setStreaming = () => null

  public allFlags = () => ({ ...defaultFlags, ...window.initialLDFlags })

  public close = () => Promise.resolve()

  /** Not an actual method on LDClient. Use for tests only */
  setFlagValue(flagKey: string, value: any, flagType?: FlagType): void {
    this.variations[flagType || this.defaultFlagType][flagKey] = value
  }
}

const ldClientMock = new LDClientMock()
if (windowProxy.Cypress) {
  windowProxy.LDSDK = ldClientMock
}
const mockUseLDClient = () => ldClientMock
export const useLDClient = windowProxy.Cypress ? mockUseLDClient : realUseLDClient

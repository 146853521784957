import { type LDFlags } from '@lyrahealth-inc/shared-app-logic/src/features/flags/useFlags'
import {
  BrowserIntlAdapter,
  DEFAULT_LANGUAGE,
  DEV_DISPLAY_LANGUAGES,
  LANGUAGES_ENABLED,
  LYRA_DISPLAY_LANGUAGES,
  LyraDisplayLanguageTags,
  LyraIntlConfig,
} from '@lyrahealth-inc/shared-app-logic/src/features/intl'

const adapter = new BrowserIntlAdapter()

const emptyMessages = {}

export const EnglishOnlyConfig: Readonly<LyraIntlConfig<LyraDisplayLanguageTags>> = {
  languages: ['en-US'],
  defaultLanguage: DEFAULT_LANGUAGE,
  useDefaultMessages: true,
  // This should never be called because useDefaultMessages is true
  loadMessages: () => Promise.resolve(emptyMessages),
  adapter,
}

export function getMicrositeLanguageConfig({
  isLanguageSelectorEnabled,
  micrositeLanguagesEnabled,
  showDevLanguages,
}: Partial<Pick<LDFlags, 'isLanguageSelectorEnabled' | 'micrositeLanguagesEnabled' | 'showDevLanguages'>>): Readonly<
  LyraIntlConfig<LyraDisplayLanguageTags>
> {
  if (!isLanguageSelectorEnabled) {
    return EnglishOnlyConfig
  }

  const filteredLanguages = micrositeLanguagesEnabled
    ? micrositeLanguagesEnabled.filter((lang): lang is LyraDisplayLanguageTags =>
        LYRA_DISPLAY_LANGUAGES.hasOwnProperty(lang),
      )
    : LANGUAGES_ENABLED

  if (filteredLanguages.length === 0) {
    filteredLanguages.push('en-US')
  }

  return {
    languages: [...filteredLanguages, ...(showDevLanguages ? DEV_DISPLAY_LANGUAGES : [])],
    defaultLanguage: DEFAULT_LANGUAGE,
    loadMessages: (locale) =>
      import(
        /* webpackChunkName: "lang_[request]" */
        `../../lang/compiled/${locale}.json`
      ),
    adapter,
  }
}

export function getLyraWebLanguageConfig({
  isDisplayLanguageEnabled,
  languagesEnabled,
  showDevLanguages,
}: Partial<Pick<LDFlags, 'isDisplayLanguageEnabled' | 'languagesEnabled' | 'showDevLanguages'>>): Readonly<
  LyraIntlConfig<LyraDisplayLanguageTags>
> {
  if (!isDisplayLanguageEnabled) {
    return EnglishOnlyConfig
  }

  const filteredLanguages = languagesEnabled
    ? languagesEnabled.filter((lang): lang is LyraDisplayLanguageTags => LYRA_DISPLAY_LANGUAGES.hasOwnProperty(lang))
    : LANGUAGES_ENABLED

  if (filteredLanguages.length === 0) {
    filteredLanguages.push('en-US')
  }

  return {
    languages: [...filteredLanguages, ...(showDevLanguages ? DEV_DISPLAY_LANGUAGES : [])],
    defaultLanguage: DEFAULT_LANGUAGE,
    loadMessages: (locale) =>
      import(
        /* webpackChunkName: "lang_[request]" */
        `../../lang/compiled/${locale}.json`
      ),
    adapter,
  }
}

export enum PathwayOptions {
  SELF_SEARCH = 'self-search',
  CHILD_SEARCH = 'child-search',
  COACHING_TOOLKIT = 'coaching-toolkit',
  PARENTING_TOOLKIT = 'parenting-toolkit',
  ESSENTIALS = 'essentials',
  MEDICATION_CONSULTATION = 'medication-consultation',
  COUPLES_THERAPY = 'couples-therapy',
  FAMILY_THERAPY = 'family-therapy',
  WELLNESS_CHECK_IN = 'wellness-check-in',
}

export enum PathwaysRedirectUrls {
  SELF_TRIAGE = '/secure/onboard/about',
  CHILD_TRIAGE = '/secure/onboard/about-child',
  COACHING_TOOLKIT = '/secure/wellness/toolkit/purpose',
  PARENTING_TOOLKIT = '/secure/wellness/toolkit/parent',
  ESSENTIALS = '/secure/index/essentials',
  MEDICATION_CONSULTATION = '/secure/onboard/match-location?directPath=true&clientele=Individual&treatment=MedicationManagement&partner=BlendedCare&offering=Default&directLinkIntent=DIRECT_LINK_MEDS_CONSULT',
  WELLNESS_CHECK_IN = '/secure/wellness-check-in/results',
}

export const PathwayOptionToRedirectUrlMapping = {
  [PathwayOptions.SELF_SEARCH]: PathwaysRedirectUrls.SELF_TRIAGE,
  [PathwayOptions.CHILD_SEARCH]: PathwaysRedirectUrls.CHILD_TRIAGE,
  [PathwayOptions.COACHING_TOOLKIT]: PathwaysRedirectUrls.COACHING_TOOLKIT,
  [PathwayOptions.PARENTING_TOOLKIT]: PathwaysRedirectUrls.PARENTING_TOOLKIT,
  [PathwayOptions.ESSENTIALS]: PathwaysRedirectUrls.ESSENTIALS,
  [PathwayOptions.MEDICATION_CONSULTATION]: PathwaysRedirectUrls.MEDICATION_CONSULTATION,
  [PathwayOptions.WELLNESS_CHECK_IN]: PathwaysRedirectUrls.WELLNESS_CHECK_IN,
}

export const TEEN_ENABLED_PATHWAYS: PathwayOptions[] = [PathwayOptions.SELF_SEARCH, PathwayOptions.ESSENTIALS]

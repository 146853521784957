import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Linking } from 'react-native'

import { GetCustomerCopyFunc, TREATMENT_OPTIONS, TREATMENT_RECOMMENDATION_TIER } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Link } from '../atoms'

export const facebook: GetCustomerCopyFunc = () => {
  const crossoverHealthOnsiteTherapyConfiguration = {
    title: 'Therapy available through Crossover Health',
    description: (
      <>
        <BodyText
          text={
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            'Employees and adult dependents enrolled in the PPO, EPO or HDHP plan also have access to in-person and virtual therapy appointments through Crossover Health. Therapy visits with Crossover count towards your Lyra Mental Health Benefit.\n\nLearn more about the services available through '
          }
        />
        <Link
          text={
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            'Crossover Health.'
          }
          onPress={() => Linking.openURL('https://life-at.crossoverhealth.com')}
        />
      </>
    ),
    buttonText: 'Book appointment at Crossover Health',
    url: 'https://life-at.crossoverhealth.com/locations/',
  }

  return {
    /* Facebook is pretty particular about the copy they want for their customer site, so be careful changing anything.
     * If you're not sure, run it by marketing and/or business.
     */
    legalNotice: (
      <FormattedMessage
        defaultMessage='Lyra Health, Inc. works in partnership with Lyra Clinical Associates P.C. and other contracted affiliates to
          be your Lyra care navigator team and deliver clinical services in your community. Crossover Health Medical Group, APC is solely responsible
          for delivering the clinical services available through Lyra at the Life@ Wellness Center in Menlo Park and at
          Crossover Health’s nearsite locations in the Bay Area. Lyra does not cover
          psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient
          treatment, emergent care, prescription medication, autism spectrum disorder treatment, services for remedial
          education, and non-evidence-based behavioral health care.'
        description='Coverage notice for Facebook, describing what is not covered by Lyra'
      />
    ),
    treatmentOptions: [
      {
        option: TREATMENT_OPTIONS.ONSITE_THERAPY,
        config: {
          customCopy: crossoverHealthOnsiteTherapyConfiguration,
          minAge: 18,
        },
        optionRanking: {
          [TREATMENT_RECOMMENDATION_TIER.TIER_2]: 2,
          [TREATMENT_RECOMMENDATION_TIER.TIER_3]: 1,
          seeMore: 1,
        },
      },
    ],
    servicesNotCovered:
      'Services that are not covered include: psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, prescription medication, autism spectrum disorder treatment, services for remedial education, and non-evidence-based behavioral health care. Please check with your health plan or benefits portal for coverage of these excluded services.',
  }
}

import { useCallback, useMemo } from 'react'

import {
  AllConditions,
  Almanac,
  ConditionProperties,
  Engine,
  Event,
  NestedCondition,
  RuleResult,
} from 'json-rules-engine'
import { groupBy } from 'lodash-es'

import {
  INITIAL_WELLBEING_RESPONSE,
  WellbeingResult,
  WELLNESS_CHECK_IN_FACET_GROUP_TO_DOMAIN,
  WellnessCheckInDomain,
  WellnessCheckInFacetGroup,
  WellnessCheckInValency,
} from '../constants'
import { getWellnessCheckInMetadata } from '../wellnessCheckInMetadata'

/**
 * A hook that is used for the Wellness Check In Form.
 * Defines the rules and uses them to initialize the rules engine.
 * @returns a callback function that returns an object of the user's form values and the wellbeingResponse constructed
 * from those values. The wellbeing response includes the score of each domain and the wellbeing result for each facet.
 */
export const useWellnessCheckInRulesEngine = () => {
  const wellbeingResponse = INITIAL_WELLBEING_RESPONSE
  const VALENCY_INVERSION = 7
  const { schema } = getWellnessCheckInMetadata()

  const getRules = useCallback(() => {
    const calculateDomainScore = (domain: WellnessCheckInDomain, conditions: NestedCondition[]) => {
      const domainTotalScore = conditions
        .map((condition) => {
          const nestedSchema = (condition as ConditionProperties).path!.split('.')
          const questionName = nestedSchema[nestedSchema.length - 1]
          const questionValue = (condition as ConditionProperties & { factResult: number }).factResult
          const isLowValencyQuestion = schema.properties![questionName].valency === WellnessCheckInValency.Low
          /**
           * Invert the score for low valency questions (e.g. I felt down or depressed)
           * because higher value means lower wellbeing for those questions.
           * 6 becomes 1, 5 becomes 2, 4 becomes 3, etc.
           * This is necessary to standardize the values before calculating the
           * average score for each domain since higher value indicates higher wellbeing.
           */
          return isLowValencyQuestion ? VALENCY_INVERSION - questionValue : questionValue
        })
        .reduce((acc, prev) => acc + prev)

      wellbeingResponse[domain as WellnessCheckInDomain].score =
        Math.round((domainTotalScore / conditions.length) * 100) / 100
    }

    const skipDomain = (conditions: NestedCondition[]) => {
      const numTotalQuestions = conditions.length
      const answeredQuestions = conditions.filter(
        (condition) => (condition as NestedCondition & { result: boolean }).result,
      )
      /**
       * Skip the domain if less than half of the questions in that domain have been answered
       */
      return answeredQuestions.length / numTotalQuestions < 0.5
    }

    const getFacetGroupPriority = (facetGroup: WellnessCheckInFacetGroup) => {
      return (
        Object.keys(WellnessCheckInFacetGroup).length - Object.values(WellnessCheckInFacetGroup).indexOf(facetGroup)
      )
    }

    /**
     * iterate through schema and create three arrays of conditions for:
     * 1. user answered the question (value >= 1)
     * 2. user's answer indicates low wellbeing
     * 3. user's answer indicates high wellbeing
     */
    const [answeredEntireDomainConditionsArray, lowWellbeingConditionsArray, highWellbeingConditionsArray] =
      Object.values(schema.properties!).reduce(
        (
          [answered, low, high]: [NestedCondition[], NestedCondition[], NestedCondition[]],
          { engineRuleConditions },
        ) => {
          if (engineRuleConditions) {
            answered.push(engineRuleConditions?.answeredCondition)
            low.push(engineRuleConditions?.lowWellbeingCondition)
            high.push(engineRuleConditions?.highWellbeingCondition)
          }

          return [answered, low, high]
        },
        [[], [], []],
      ) as [NestedCondition[], NestedCondition[], NestedCondition[]]

    /**
     * using answeredEntireDomainConditionsArray created above, create a mapping
     * where key is domain and value is array of conditions.
     * the mapping will be used to create a rule for each domain
     * to determine whether user skipped more than 50% of questions in that domain
     */
    const answeredEntireDomainConditionsMapping = groupBy(
      answeredEntireDomainConditionsArray,
      ({ fact }: { fact: string }) => fact,
    )

    /**
     * using lowWellbeingConditionsArray created above, create a mapping
     * where key is facet group and value is array of conditions.
     * the mapping will be used to create a rule for each facet group
     * to determine whether the user's answers indicate low wellbeing
     * path.split('.')[1] gets the facet group name
     */
    const lowWellbeingConditionsMapping = groupBy(
      lowWellbeingConditionsArray,
      ({ path, all }: { path?: string; all?: NestedCondition[] }) => {
        if (all) {
          const condition = all[0] as ConditionProperties
          return condition.path!.split('.')[1]
        }
        return path!.split('.')[1]
      },
    )

    /** same as above but for high wellbeing */
    const highWellbeingConditionsMapping = groupBy(
      highWellbeingConditionsArray as Array<ConditionProperties & AllConditions>,
      ({ path, all }: { path?: string; all?: NestedCondition[] }) => {
        if (all) {
          const condition = all[0] as ConditionProperties
          return condition.path!.split('.')[1]
        }
        return path!.split('.')[1]
      },
    )

    /** arbitrary high number to ensure this set of rules is the first to run */
    const ANSWERED_ENTIRE_DOMAIN_RULES_PRIORITY = 100

    /**
     * create 1 rule per domain which checks if the user skipped any questions.
     * on success (user answered all questions in domain):
     *   - call calculateDomainScore to update the wellbeingResponse with domain score
     *   - set new fact domain.runFacetGroupWellbeingRules with value true
     *     so the wellbeing rules run for that domain
     * on failure (user skipped at least one question in domain):
     *   if user answered < 50% of questions in that domain
     *     - set new fact domain.runFacetGroupWellbeingRules with value false
     *       so the wellbeing rules do not run for that domain
     *   else the user has answered >= 50% questions in that domain
     *     - call calculateDomainScore to update the wellbeingResponse with domain score
     *     - set new fact domain.runFacetGroupWellbeingRules with value true
     *       so the wellbeing rules run for that domain
     * priority is set to 100 to ensure this set of rules is the first to run since
     * on succeed / failure the domain.runFacetGroupWellbeingRules is set,
     * which determines whether the low wellbeing / high wellbeing rules are run.
     * (higher number = higher priority)
     */
    const answeredEntireDomainRules = Object.keys(answeredEntireDomainConditionsMapping).map((domainName) => {
      const domain = domainName as WellnessCheckInDomain
      const conditions = answeredEntireDomainConditionsMapping[domain] as NestedCondition[]
      return {
        conditions: {
          all: conditions,
        },
        event: {
          type: 'answered',
          params: {
            domain: domain,
          },
        },
        priority: ANSWERED_ENTIRE_DOMAIN_RULES_PRIORITY,
        onSuccess: function (event: Event, almanac: Almanac, ruleResult: RuleResult) {
          calculateDomainScore(domain, (ruleResult.conditions as AllConditions).all)
          almanac.addFact(`${domain}.runFacetGroupWellbeingRules`, true)
        },
        onFailure: function (event: Event, almanac: Almanac, ruleResult: RuleResult) {
          if (skipDomain((ruleResult.conditions as AllConditions).all)) {
            almanac.addFact(`${domain}.runFacetGroupWellbeingRules`, false)
          } else {
            const answeredQuestions = (ruleResult.conditions as AllConditions).all.filter(
              (condition) => (condition as NestedCondition & { result: boolean }).result,
            )
            calculateDomainScore(domain, answeredQuestions)
            almanac.addFact(`${domain}.runFacetGroupWellbeingRules`, true)
          }
        },
      }
    })

    /**
     * create 1 low wellbeing rule per facet group.
     * event fires if domain.runFacetGroupWellbeingRules is true AND
     * ANY of the low wellbeing conditions in that facet group is true.
     * priority is set so that it's run in the order of WellnessCheckInFacetGroup enum
     * (e.g. the first facet group Mood has priority set to 13, the last facet group
     * Nature has priority 1; higher number = higher priority).
     * priority value range is 1-13 which ensures this set of rules runs after
     * the answeredEntireDomainRules, since the wellbeing rules are dependent on
     * the value of domain.runFacetGroupWellbeingRules.
     */
    const lowWellbeingRules = Object.keys(lowWellbeingConditionsMapping).map((facetGroupName) => {
      const facetGroup = facetGroupName as WellnessCheckInFacetGroup
      const domain = WELLNESS_CHECK_IN_FACET_GROUP_TO_DOMAIN[facetGroup]
      const conditions = lowWellbeingConditionsMapping[facetGroup] as NestedCondition[]
      const priority = getFacetGroupPriority(facetGroup)

      return {
        conditions: {
          all: [
            {
              fact: `${domain}.runFacetGroupWellbeingRules`,
              operator: 'equal',
              value: true,
            },
            {
              any: conditions,
            },
          ],
        },
        priority,
        event: {
          type: WellbeingResult.Low,
          params: {
            facetGroup: facetGroup,
          },
        },
      }
    })

    /**
     * create 1 high wellbeing rule per facet group.
     * event fires if domain.runFacetGroupWellbeingRules is true AND
     * ALL of the high wellbeing conditions in that facet group are true.
     * priority is set so that it's run in the order of WellnessCheckInFacetGroup enum
     * (e.g. the first facet group Mood has priority set to 13, the last facet group
     * Nature has priority 1; higher number = higher priority).
     * priority value range is 1-13 which ensures this set of rules runs after
     * the answeredEntireDomainRules, since the wellbeing rules are dependent on
     * the value of domain.runFacetGroupWellbeingRules.
     */
    const highWellbeingRules = Object.keys(highWellbeingConditionsMapping).map((facetGroupName) => {
      const facetGroup = facetGroupName as WellnessCheckInFacetGroup
      const domain = WELLNESS_CHECK_IN_FACET_GROUP_TO_DOMAIN[facetGroup]
      const conditions = highWellbeingConditionsMapping[facetGroup] as NestedCondition[]
      const priority = getFacetGroupPriority(facetGroup)

      return {
        conditions: {
          all: [
            {
              fact: `${domain}.runFacetGroupWellbeingRules`,
              operator: 'equal',
              value: true,
            },
            ...conditions,
          ],
        },
        priority,
        event: {
          type: WellbeingResult.High,
          params: {
            facetGroup: facetGroup,
          },
        },
      }
    })

    return [...answeredEntireDomainRules, ...lowWellbeingRules, ...highWellbeingRules]
  }, [wellbeingResponse, schema.properties])

  const engine = useMemo(() => new Engine(getRules(), { allowUndefinedFacts: true }), [getRules])

  const runRulesEngine = useCallback(
    async ({ values }: { values: Dict }) => {
      const { events } = await engine.run(values)
      events.forEach((event: Event) => {
        const { type, params } = event
        if (params?.facetGroup) {
          const facetGroup = params?.facetGroup as WellnessCheckInFacetGroup
          const domain = WELLNESS_CHECK_IN_FACET_GROUP_TO_DOMAIN[facetGroup] as WellnessCheckInDomain
          wellbeingResponse[domain].response.push({
            facetGroup,
            result: type as WellbeingResult,
          })
        }
      })
      return { ...values, wellbeingResponse }
    },
    [engine, wellbeingResponse],
  )

  return { runRulesEngine }
}

import React, { FunctionComponent } from 'react'

import thumbsUpUrl from '../../assets/thumbs-down-rating.png'
import { Image } from '../image/Image'

interface ThumbsDownIllustrationProps {
  size?: number
}

export const ThumbsDownIllustration: FunctionComponent<ThumbsDownIllustrationProps> = ({ size = 48 }) => {
  return <Image source={thumbsUpUrl} accessibilityIgnoresInvertColors style={{ height: size, width: size }} />
}

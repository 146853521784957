export function isInRegisterFlow(pathname: string) {
  return (
    isPathInAutoEnrollmentFlow(pathname) ||
    ['/register/account-setup', '/register/verify-account', '/register/verify', '/register/connect'].some((path) =>
      pathname.startsWith(path),
    )
  )
}

export function isPathInAutoEnrollmentFlow(pathname: string) {
  return pathname.startsWith('/register/auto')
}

import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

export const FinancesAndWorkIllustration: FunctionComponent<{ size?: number }> = ({ size = 40 }) => {
  return (
    <Svg width={size} height={size} fill='none'>
      <G clipPath='url(#finances-and-work-illustration)'>
        <Path
          fill='#CDB8A6'
          d='M11.726 13.96s-5.155-.577-6.508 1.135c-1.83 2.314-1.404 5.244 0 6.77 1.404 1.526 4.721 4.976 7.528 4.845 2.808-.132 0-3.45 0-3.45s-1.488.043-2.68-.612c-1.191-.655-4.253-2.838-3.828-4.76.425-1.921 3.19-2.314 5.36.219 2.169 2.533.127-4.148.127-4.148h.002Z'
        />
        <Path
          fill='#CDB8A6'
          d='M11.803 8.842s-2.148.575-1.611 3.629c.537 3.052 1.115 17.374 2.94 19.685 1.825 2.312 7.1 1.795 8.215 1.795 1.115 0 6.491-.06 7.506-1.988.861-1.635 2.818-15.377 3.142-19.953.066-.926-.76-2.48-1.873-2.947-1.866-.785-3.905-1.087-9.048-1.062-6.735.033-9.27.84-9.27.84Z'
        />
        <Path
          fill='#7F6246'
          d='M28.878 10.845c-1.144.219-2.296.394-3.452.519-4.052.438-8.131.271-12.157-.402-.126-.021-.216-.142-.26-.282-.156-.271-.05-.767.317-.786 1.154-.425 3.008-.46 4.139-.491 4.02-.114 7.445-.001 11.308.495.43.021.57.857.106.946Z'
        />
      </G>
      <Defs>
        <ClipPath id='finances-and-work-illustration'>
          <Path fill='#fff' d='M0 0h40v40H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

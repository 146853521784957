import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

export const PhysicalHealthAndActivityIllustration: FunctionComponent<{ size?: number }> = ({ size = 40 }) => {
  return (
    <Svg width={size} height={size} fill='none'>
      <G clipPath='url(#physical-health-and-activity-illustration)'>
        <Path
          fill='#85CCD5'
          d='M31 23.345C31 29.21 26.075 33 20 33S9 29.21 9 23.345C9 14.655 18 5 20 5s11 9.655 11 18.345Z'
        />
        <Path
          stroke='#EEE'
          strokeLinecap='round'
          strokeWidth={2}
          d='M17.84 11.416c-1.773 3.32-4.257 6.855-4.257 10.745M14.41 26.265l.082.165'
        />
      </G>
      <Defs>
        <ClipPath id='physical-health-and-activity-illustration'>
          <Path fill='#fff' d='M0 0h40v40H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

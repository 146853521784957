import React from 'react'

import { useTheme } from 'styled-components/native'

import { CARE_ADVISOR_EMAIL } from '@lyrahealth-inc/shared-app-logic'

import { Link, LinkProps } from './Link'
import { mailTo } from '../../utils'

export type CareNavigatorEmailLinkProps = Partial<Pick<LinkProps, 'underline' | 'text'>> & {
  linkType?: 'error' | 'warning' | 'default'
}

export const CareNavigatorEmailLink: React.FC<CareNavigatorEmailLinkProps> = ({
  linkType = 'error',
  underline = true,
  text = CARE_ADVISOR_EMAIL,
}) => {
  const { colors } = useTheme()

  let color
  if (linkType === 'error') {
    color = colors.textError
  } else if (linkType === 'warning') {
    color = colors.textWarning
  }

  return <Link text={text} onPress={() => mailTo(CARE_ADVISOR_EMAIL)} underline={underline} color={color} />
}

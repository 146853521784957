import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  SAVE_HEALTH_PLAN_SELECTION_FROM_TRIAGE,
  SET_USER_HEALTH_PLAN_NAMES_FROM_ELIGIBILITY_SERVICE,
} from './triageHealthPlanActions'
import { NOT_ENROLLED_IN_HEALTH_INSURANCE_THROUGH_CUSTOMER } from '../../../../common/constants/healthPlanConstants'

const initialState = fromJS({
  $$healthPlanNamesFromEligibilityService: [],
  selectedHealthPlan: {
    planName: NOT_ENROLLED_IN_HEALTH_INSURANCE_THROUGH_CUSTOMER.eligibility_name,
    bhbEligible: NOT_ENROLLED_IN_HEALTH_INSURANCE_THROUGH_CUSTOMER.enabled,
  },
})

export default createReducer(initialState, {
  [SAVE_HEALTH_PLAN_SELECTION_FROM_TRIAGE]: (state: $TSFixMe, action: $TSFixMe) => {
    return state.set('selectedHealthPlan', fromJS(action.selectedHealthPlan))
  },
  [SET_USER_HEALTH_PLAN_NAMES_FROM_ELIGIBILITY_SERVICE]: (state: $TSFixMe, action: $TSFixMe) => {
    return state.set('$$healthPlanNamesFromEligibilityService', fromJS(action.healthPlanNames))
  },
})

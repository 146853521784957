import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const NoPreferenceMatchIcon: FunctionComponent<NoPreferenceMatchIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <G clipPath='url(#clip0_297_38903)' fill={`${fillColor || colors.iconInactive}`}>
        <Path d='M9.55 6.92C5.39 6.92 2 10.31 2 14.48s3.39 7.55 7.55 7.55 7.55-3.39 7.55-7.55-3.39-7.55-7.55-7.55v-.01zm5.96 8.57c-.23.04-.47.07-.71.08a.75.75 0 000 1.5h.08c.05 0 .09-.01.14-.01a6.047 6.047 0 01-5.47 3.47c-3.34 0-6.05-2.72-6.05-6.05 0-2.43 1.44-4.53 3.52-5.49 0 .04 0 .07-.01.11v.04c-.03.41.29.75.7.77.41.02.77-.31.79-.72.01-.23.04-.45.08-.68.32-.05.64-.09.98-.09 3.34 0 6.05 2.72 6.05 6.05 0 .35-.04.69-.09 1.02h-.01zM20.149 7.32c.11.28.37.46.66.47.11 0 .21 0 .32-.05.39-.15.58-.59.42-.97-.19-.49-.44-.96-.72-1.4a.756.756 0 00-1.04-.22c-.35.23-.44.69-.22 1.04.23.35.43.73.58 1.12v.01zM9.15 5.98c.24.01.48-.09.63-.29.26-.33.55-.63.88-.89.32-.26.37-.73.11-1.06a.758.758 0 00-1.06-.11c-.41.33-.78.71-1.11 1.13-.26.33-.2.8.13 1.05.12.1.27.15.42.16v.01z' />
        <Path d='M8.86 11.72a.75.75 0 10-1.41.51c.18.5.41.98.69 1.42.13.21.36.34.59.35.15 0 .3-.03.44-.11.35-.22.46-.68.23-1.03a5.63 5.63 0 01-.55-1.13l.01-.01zM12.679 3.72c.07 0 .15 0 .23-.02.4-.1.83-.17 1.27-.2a.75.75 0 10-.1-1.5c-.53.04-1.05.12-1.54.24-.4.1-.65.51-.54.91.08.33.37.55.68.57zM22.05 9.88c.02-.41-.29-.73-.71-.75-.41-.02-.77.33-.79.74-.02.42-.09.84-.2 1.25-.11.4.13.81.53.92.05.01.1.02.15.02.35.02.67-.2.77-.55.14-.51.22-1.03.25-1.58l-.75-.09.75.04zM12.319 15.16a6.68 6.68 0 01-1.14-.55.747.747 0 10-.79 1.27c.44.27.91.51 1.42.69.07.03.14.04.22.04.32.02.63-.17.75-.49a.75.75 0 00-.45-.96h-.01zM19.2 13.35c-.27.33-.57.63-.9.89a.748.748 0 00.93 1.17c.41-.33.79-.7 1.12-1.11a.76.76 0 00-.11-1.06.76.76 0 00-1.06.11h.02zM18.81 4.21c.23-.35.13-.81-.22-1.04-.44-.29-.91-.52-1.41-.71a.75.75 0 00-.97.44c-.15.39.05.82.44.97.4.15.77.34 1.12.57.11.07.24.11.36.12.26.01.52-.11.67-.34l.01-.01z' />
      </G>
      <Defs>
        <ClipPath id='clip0_297_38903'>
          <Path fill={colors.iconInverse} transform='translate(2 2)' d='M0 0H20.05V20.03H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export type NoPreferenceMatchIconProps = {
  size?: string | number
  fillColor?: string
}

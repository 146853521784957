import { CustomerRedirectionProps, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { defineMessage } from 'react-intl'

// this configuration is using the original qualcomm (premier) configuration as a base.
export const qualcomm16eap: GetCustomerCopyFunc = () => {
  const customerRedirection = ({ shouldShowBanner, onLinkPress }: CustomerRedirectionProps) => ({
    header: defineMessage({
      defaultMessage: 'Are you enrolled in UHC QDHP, Kaiser QDHP, or waived insurance?',
      description: 'Header text to ask user which medical plan they are enrolled in',
    }),
    checkboxLabel: defineMessage({
      defaultMessage: 'Yes, I’m enrolled in UHC QDHP, Kaiser QDHP, or have waived insurance',
      description: 'Redirection checkbox label',
    }),
    redirectionText: defineMessage({
      defaultMessage:
        'If you’re not enrolled in UHC QDHP, Kaiser QDHP, or have waived insurance, <link>click here</link>.',
      description:
        'Text to inform user they should click on the link to redirect them to the right page if they are not enrolled in certain medical plan',
    }),
    redirectionLinkPress: onLinkPress,
    shouldShowBanner,
  })

  return {
    subpopulations: undefined,
    customerRedirection,
  }
}

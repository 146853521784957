import { defineMessage } from 'react-intl'

import { isEqual } from 'lodash-es'

import { OPTIONS_TYPE, PreferenceMetadataConfig, RENDER_CONDITION_TARGETS } from './types'
import { MEMBER_PREFERENCE_LABELS } from '../../searchForCare/messages'
import {
  AVAILABILITY_PREFERENCE_TIME_OF_DAY_MAPPING,
  MEMBER_PREFERENCE_IDS,
  S4C_PROGRAM_OPTION_FLOW,
} from '../constants'

const availabilities: PreferenceMetadataConfig = {
  name: 'availabilities',
  title: MEMBER_PREFERENCE_LABELS.AVAILABILITY,
  options: [
    {
      text: MEMBER_PREFERENCE_LABELS.flexible,
      id: MEMBER_PREFERENCE_IDS.FLEXIBLE,
      defaultValue: [MEMBER_PREFERENCE_IDS.FLEXIBLE],
    },
    {
      text: MEMBER_PREFERENCE_LABELS.specifyDateTime,
      id: MEMBER_PREFERENCE_IDS.SPECIFY_DATE_TIME,
      defaultValue: [],
    },
  ],
  optionType: OPTIONS_TYPE.RADIO,
  conditionalChildComponent: {
    componentName: 'AvailabilityCalendar',
    optionId: MEMBER_PREFERENCE_IDS.SPECIFY_DATE_TIME,
    mapKeysToValues: (keys: string[] | null) => {
      if (keys === null) return null
      else if (isEqual(keys, [MEMBER_PREFERENCE_IDS.FLEXIBLE])) return keys
      else {
        return keys.map((key) => {
          const gridValue = key.split('-')
          const timeOfDayKey = gridValue[1] as unknown as number
          return `${gridValue[0]}-${AVAILABILITY_PREFERENCE_TIME_OF_DAY_MAPPING[timeOfDayKey]}`
        })
      }
    },
    mapValuesToKeys: (values: string[] | null) => {
      if (values === null) return null
      else if (isEqual(values, [MEMBER_PREFERENCE_IDS.FLEXIBLE])) return values
      else {
        return values.map((value) => {
          const gridValue = value.split('-')
          const timeOfDayValue = `${gridValue[1]}-${gridValue[2]}`
          const gridPosition = Object.keys(AVAILABILITY_PREFERENCE_TIME_OF_DAY_MAPPING).find(
            (key) => AVAILABILITY_PREFERENCE_TIME_OF_DAY_MAPPING[key] === timeOfDayValue,
          )
          return `${gridValue[0]}-${gridPosition}`
        })
      }
    },
  },
  icon: 'CalendarOpenSpotsIllustration',
  promptText: defineMessage({
    defaultMessage: 'Select when you’d prefer to meet for your first 50 min appointment:',
    description: 'Text prompting the user to determine their preferred date and time for meeting a provider',
  }),
  visibleFor: [
    S4C_PROGRAM_OPTION_FLOW.COACHING,
    S4C_PROGRAM_OPTION_FLOW.COACHING_WITH_LMS,
    S4C_PROGRAM_OPTION_FLOW.THERAPY,
  ],
  renderConditions: [
    {
      target: RENDER_CONDITION_TARGETS.FEATURE_FLAG,
      fn: (condition) => condition === true,
    },
  ],
}

export default availabilities

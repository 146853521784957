import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

type YellowLightbulbIllustrationProps = {
  size?: string | number
}

export const YellowLightbulbIllustration: FunctionComponent<YellowLightbulbIllustrationProps> = ({ size = 32 }) => (
  <Svg width={size} height={size} viewBox='0 0 32 32' fill='none'>
    <Path
      d='M24.9203 7.14195C25.6738 6.46508 26.4282 5.78837 27.1816 5.11149C27.4784 4.84547 27.384 4.31237 27.1088 4.0736C26.7769 3.78677 26.3636 3.87936 26.0658 4.14611C25.3123 4.82299 24.5579 5.49969 23.8045 6.17657C23.5077 6.44259 23.6021 6.97569 23.8773 7.21446C24.2092 7.5013 24.6225 7.4087 24.9203 7.14195Z'
      fill='#FFD974'
    />
    <Path
      d='M28.4292 14.0701C29.3751 13.5828 30.3759 13.1778 31.3873 12.8458C32.2905 12.5488 31.7958 11.1632 30.8908 11.4599C29.7559 11.8332 28.6563 12.3085 27.5958 12.8547C27.2415 13.0379 27.1957 13.5765 27.402 13.8762C27.6494 14.2369 28.0733 14.252 28.4285 14.069L28.4292 14.0701Z'
      fill='#FFD974'
    />
    <Path
      d='M17.8127 4.35496C18.0693 3.23493 18.3271 2.11417 18.5837 0.994141C18.7956 0.0705401 17.3431 -0.200453 17.1316 0.721341C16.8749 1.84137 16.6172 2.96213 16.3605 4.08216C16.1486 5.00576 17.6011 5.27675 17.8127 4.35496Z'
      fill='#FFD974'
    />
    <Path
      d='M8.09093 2.44291C8.619 3.66265 9.24005 4.8531 9.65936 6.11601C9.95787 7.01483 11.3503 6.52252 11.0521 5.62189C10.6328 4.35898 10.0119 3.16763 9.48366 1.94879C9.10908 1.08431 7.71317 1.57035 8.09093 2.44291Z'
      fill='#FFD974'
    />
    <Path
      d='M0.78257 10.5177C1.86027 10.8221 2.93723 11.1255 4.01493 11.4299C4.39942 11.5386 4.80868 11.2095 4.87807 10.8438C4.95774 10.4238 4.67446 10.0937 4.28907 9.98484C3.21137 9.68043 2.13441 9.3771 1.05671 9.07269C0.672219 8.96398 0.262957 9.29304 0.193565 9.65882C0.113893 10.0788 0.397171 10.4089 0.78257 10.5177Z'
      fill='#FFD974'
    />
    <Path
      d='M1.82499 19.5891C2.7092 19.2239 3.59357 18.8579 4.47687 18.4925C5.36016 18.1272 4.86237 16.7429 3.98032 17.1066C3.09828 17.4703 2.21174 17.8378 1.32845 18.2032C0.453439 18.5645 0.942947 19.9528 1.82499 19.5891Z'
      fill='#FFD974'
    />
    <Path
      d='M15.8072 28.0096C15.1714 27.9945 14.6222 27.5649 14.4564 26.9541C14.2681 26.2605 14.027 25.3834 13.8578 24.8112C13.7746 24.5298 13.5902 24.0852 13.4239 23.7048C13.2423 23.2898 12.8752 22.9832 12.4317 22.8826C11.3306 22.6314 9.01587 22.0217 7.90684 19.079C6.38805 15.0472 9.42676 9.18852 15.8206 8.68455C21.3124 8.25161 25.0396 11.5753 24.9368 15.4253C24.8103 20.1501 21.4551 21.5988 21.2651 22.8591C21.1696 23.4945 20.6952 26.9541 20.5057 27.6466C20.3635 28.1662 17.4462 28.0482 15.8077 28.0096H15.8072Z'
      fill='#FFC03C'
    />
    <Path
      d='M19.5222 16.5123C19.3771 17.4509 17.5278 20.812 17.5278 20.812C17.5278 20.812 17.6318 25.2952 17.575 25.6314C17.4328 26.4816 16.5779 26.7182 16.3879 25.5369C16.267 24.7846 16.1029 20.812 16.1029 20.812C16.1029 20.812 13.7764 18.6384 13.7764 18.0242C13.7764 17.3609 14.2036 17.032 14.6785 17.3155C15.1535 17.5991 16.6729 19.2056 16.6729 19.2056C16.6729 19.2056 17.2901 17.7882 18.0022 16.0402C18.3693 15.1385 19.7116 15.2845 19.5216 16.5129L19.5222 16.5123Z'
      fill='white'
    />
    <Path
      d='M14.0475 27.836C14.3106 27.1821 15.6411 26.8347 17.2127 26.639C18.7843 26.4432 20.8371 26.2038 21.1379 26.7022C21.7078 27.6469 21.7078 29.9151 21.2643 30.1041C20.8208 30.2932 19.555 30.5449 19.555 30.5449C19.6224 31.0498 19.2402 32.0898 18.0356 31.9936C16.8221 31.8969 16.5163 30.9857 16.5163 30.9857C16.5163 30.9857 14.9969 31.1747 14.6805 30.734C14.3331 30.2501 13.794 28.4664 14.0475 27.836Z'
      fill='#DA9460'
    />
  </Svg>
)

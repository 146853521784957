export const PROFILE_MAX_WIDTH_PER_PAGE_IN_PX = {
  ELIGIBILITY: '784px',
}

export const USER_DOWNLOAD_DATA_FILE_NAME = 'lyra_user_data.zip'

export enum CHANGE_EMAIL_ERROR_TYPE {
  INVALID = 'INVALID',
  GENERIC = 'GENERIC',
}

import { defineMessage } from 'react-intl'
import { CustomerSubpopulations, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

const subpopulations: CustomerSubpopulations = {
  header: defineMessage({
    defaultMessage: 'Which Ford employee group are you associated with?',
    description: 'Ford subpopulation redirection header',
  }),
  subHeader: defineMessage({
    defaultMessage:
      'Select the scenario that applies to you (if you are associated with both groups, please select active salaried or supplemental)',
    description: 'Ford subpopulation redirection subheadr informing user to pick active if they are both',
  }),
  options: [
    {
      label: defineMessage({
        defaultMessage: 'Active salaried or supplemental',
        description: 'Type of Ford employee',
      }),
      description: defineMessage({
        defaultMessage:
          'I am an active salaried or supplemental employee of Ford, their spouse/domestic partner, or eligible dependent.',
        description: 'Ford employee description text',
      }),
    },
    {
      label: defineMessage({
        defaultMessage: 'Active TEVC hourly',
        description: 'Type of Ford employee',
      }),
      description: defineMessage({
        defaultMessage:
          'I am an active TEVC hourly employee of Ford, their spouse/domestic partner, or eligible dependent.',
        description: 'Ford employee description text',
      }),
    },
  ],
}

export const ford: GetCustomerCopyFunc = () => {
  return {
    subpopulations,
  }
}

import { defineMessage } from 'react-intl'

import { CustomerSubpopulations, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

export const united: GetCustomerCopyFunc = () => {
  const subpopulations: CustomerSubpopulations = {
    header: defineMessage({
      defaultMessage: 'Your access through United',
      description: 'United subpopulation redirection header',
    }),
    subHeader: defineMessage({
      defaultMessage: 'Select the scenario that applies to you',
      description: 'United subpopulation redirection subheader informing user to pick group they belong to',
    }),
    options: [
      {
        label: defineMessage({
          defaultMessage: 'United Airlines Employee',
          description: 'United Airlines Employee member label',
        }),
        description: defineMessage({
          defaultMessage: 'I am a United Airlines employee or eligible dependent',
          description: 'United Airlines Employee member description',
        }),
      },
      {
        label: defineMessage({
          defaultMessage: 'United Ground Express (UGE) Employee',
          description: 'United Ground Express member label',
        }),
        description: defineMessage({
          defaultMessage: 'I am a United Ground Express (UGE) employee or eligible dependent',
          description: 'United Ground Express member description',
        }),
      },
    ],
  }
  return {
    subpopulations,
  }
}

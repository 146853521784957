import React from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

type EventIconProps = {
  fillColor?: string
  size?: string | number
}

export const EventIcon: React.FC<EventIconProps> = ({ fillColor, size = 16 }) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} viewBox='0 0 16 16' fill='none'>
      <Path
        fill={fillColor ?? colors.iconActive}
        d='M13.293 14.293 1.707 2.707C1.077 2.077 1.523 1 2.414 1H14a1 1 0 0 1 1 1v11.586c0 .89-1.077 1.337-1.707.707Z'
      />
    </Svg>
  )
}

/**
 * errorCode enum from LW API response body
 */
export enum LW_API_ERROR_CODE_ENUM {
  USER_REGISTERED = 'USER_REGISTERED',
  USER_EXISTS = 'USER_EXISTS',
  USER_REGISTERED_DIFFERENT_CUSTOMER = 'USER_REGISTERED_DIFFERENT_CUSTOMER',
  USER_ACCOUNT_UNRECOGNIZED = 'USER_ACCOUNT_UNRECOGNIZED',
  USER_ACCOUNT_PENDING_VERIFICATION = 'USER_ACCOUNT_PENDING_VERIFICATION',
  INELIGIBLE_USER = 'INELIGIBLE_USER',
  INVALID_FACTOR_CODE = 'INVALID_FACTOR_CODE',
  INVALID_OKTA_PASSWORD = 'INVALID_OKTA_PASSWORD',
  USER_ACCOUNT_EXPIRED_MESSAGE = 'USER_ACCOUNT_EXPIRED_MESSAGE',
  INVALID_PAYMENT_INFO = 'INVALID_PAYMENT_INFO',
  SSO_LOGIN_EMAIL_CONFLICT = 'SSO_LOGIN_EMAIL_CONFLICT',
  CHILD_EMAIL_REGISTERED = 'CHILD_EMAIL_REGISTERED',
}

/**
 * errorCode from LW API response body
 */
export type LW_API_ERROR_CODE = `${LW_API_ERROR_CODE_ENUM}`

import React, { FunctionComponent } from 'react'
import { Circle, G, Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Standard Time Icon
 */
export const TimeIcon: FunctionComponent<TimeIconProps> = ({ size = 96 }) => {
  const { colors } = useTheme()
  const { timeIcon } = colors.icons

  return (
    <Svg width={size} height={size} viewBox='0 0 96 96'>
      <G id='MVP-app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <G id='L1' transform='translate(-24.000000, -103.000000)'>
          <G id='il-96-time' transform='translate(24.000000, 103.000000)'>
            <Rect id='Rectangle' x='0' y='0' width='96' height='96' />
            <G
              id='wall-clock-8724_6f8e932b-3453-4814-8c8c-616725d6ebd7'
              transform='translate(3.000000, 3.000000)'
              fillRule='nonzero'
            >
              <Circle id='Oval' fill={timeIcon.background} cx='45' cy='45' r='45' />
              <Path
                d='M67.0605,28.0605 C67.6289583,27.4719317 67.6208286,26.5363728 67.0422279,25.9577721 C66.4636272,25.3791714 65.5280683,25.3710417 64.9395,25.9395 L44.8395,46.0395 C44.0430689,45.3726698 43.0387204,45.0049923 42,45 C41.5854756,45.0059227 41.1738827,45.0705655 40.7775,45.192 L27.4275,33.4215 L24.3435,36.549 C23.81956,37.1906289 23.9149201,38.1355 24.5565,38.6595 L37.5285,49.272 C37.5285,49.3485 37.506,49.422 37.506,49.4985 C37.4974417,51.2735851 38.5379827,52.8862307 40.1588602,53.6099411 C41.7797376,54.3336515 43.6750655,54.0318508 44.991,52.8405 L45.006,52.8495 L45.114,52.7385 C45.165,52.6905 45.213,52.6395 45.264,52.5885 L48.2805,49.5 L46.5,49.4535 C46.4951019,49.2018257 46.4695157,48.9509803 46.4235,48.7035 L67.0605,28.0605 Z'
                id='Path'
                fill={timeIcon.clockHandBackground.fill}
              />
              <Path
                d='M45,16.5 C44.1715729,16.5 43.5,15.8284271 43.5,15 L43.5,7.5 C43.5,6.67157288 44.1715729,6 45,6 C45.8284271,6 46.5,6.67157288 46.5,7.5 L46.5,15 C46.5,15.8284271 45.8284271,16.5 45,16.5 Z M45,84 C44.1715729,84 43.5,83.3284271 43.5,82.5 L43.5,75 C43.5,74.1715729 44.1715729,73.5 45,73.5 C45.8284271,73.5 46.5,74.1715729 46.5,75 L46.5,82.5 C46.5,83.3284271 45.8284271,84 45,84 Z M15,46.5 L7.5,46.5 C6.67157288,46.5 6,45.8284271 6,45 C6,44.1715729 6.67157288,43.5 7.5,43.5 L15,43.5 C15.8284271,43.5 16.5,44.1715729 16.5,45 C16.5,45.8284271 15.8284271,46.5 15,46.5 Z M82.5,46.5 L75,46.5 C74.1715729,46.5 73.5,45.8284271 73.5,45 C73.5,44.1715729 74.1715729,43.5 75,43.5 L82.5,43.5 C83.3284271,43.5 84,44.1715729 84,45 C84,45.8284271 83.3284271,46.5 82.5,46.5 Z'
                id='Shape'
                fill={timeIcon.clockHandForeground.fill}
              />
              <Circle id='Oval' fill={timeIcon.clockHandForeground.fill} cx='45' cy='46.5' r='3' />
              <Path
                d='M45,51 C42.5147186,51 40.5,48.9852814 40.5,46.5 C40.5,44.0147186 42.5147186,42 45,42 C47.4852814,42 49.5,44.0147186 49.5,46.5 C49.5,48.9852814 47.4852814,51 45,51 Z M45,45 C44.1715729,45 43.5,45.6715729 43.5,46.5 C43.5,47.3284271 44.1715729,48 45,48 C45.8284271,48 46.5,47.3284271 46.5,46.5 C46.5,45.6715729 45.8284271,45 45,45 Z'
                id='Shape'
                fill={timeIcon.clockHandForeground.fill}
              />
              <Path
                d='M42.1245,47.1495002 C41.7785245,47.1496614 41.4433057,47.0292585 41.1765,46.809 L27.5505,35.661 C27.1262185,35.3247313 26.9160584,34.7859279 27.0006043,34.2511912 C27.0851502,33.7164545 27.451282,33.2687672 27.9586084,33.0797928 C28.4659348,32.8908184 29.0357265,32.9898827 29.4495,33.339 L43.0755,44.487 C43.5664104,44.8885983 43.7516766,45.5555528 43.538227,46.1528077 C43.3247773,46.7500627 42.7587507,47.1485168 42.1245,47.148 L42.1245,47.1495002 Z M47.733,46.767 C47.1264062,46.767 46.5795989,46.4014087 46.347483,45.8409818 C46.1153671,45.2805549 46.2436381,44.6354914 46.6725,44.2065 L67.9395,22.9395 C68.5280683,22.3710417 69.4636272,22.3791714 70.0422279,22.9577721 C70.6208286,23.5363728 70.6289583,24.4719317 70.0605,25.0605 L48.7935,46.3275 C48.5122609,46.6088241 48.1307922,46.767 47.733,46.767 L47.733,46.767 Z'
                id='Shape'
                fill={timeIcon.clockHandForeground.fill}
              />
            </G>
          </G>
        </G>
        <G id='-Connections' transform='translate(-5089.000000, -5319.000000)' />
      </G>
    </Svg>
  )
}

type TimeIconProps = {
  size?: number
}

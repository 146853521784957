type SubtitleParameters = {
  isProviderBlocked: boolean
  countryEmergencyNumbers: string[]
  sessionTime: string
  inVideoSession: boolean
  hasActiveAppointment: boolean
}
export const getChatHeaderSubTitleMsg = ({
  isProviderBlocked,
  countryEmergencyNumbers,
  inVideoSession,
  hasActiveAppointment,
  sessionTime,
}: SubtitleParameters) => {
  if (isProviderBlocked) {
    return `is unavailable. Call ${countryEmergencyNumbers} for emergencies.`
  }
  if (inVideoSession) {
    return ''
  }
  if (hasActiveAppointment) {
    return `Session today from ${sessionTime}`
  }
  return `Usually responds within 2 days. Call ${countryEmergencyNumbers} for emergencies.`
}

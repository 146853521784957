import { createAsyncThunk } from '@reduxjs/toolkit'

import { ClinicalDataAccessCode, FormDataForNewClinicalDataAccessCode } from '@lyrahealth-inc/shared-app-logic'

import { axiosInstance } from '../../common/http/axiosInstance'

export const GET_CLINICAL_DATA_ACCESS_CODES_FOR_USER = 'GET_CLINICAL_DATA_ACCESS_CODES_FOR_USER'
export const POST_NEW_CLINICAL_DATA_ACCESS_CODE = 'POST_NEW_CLINICAL_DATA_ACCESS_CODE'
export const DELETE_CLINICAL_DATA_ACCESS_CODE = 'DELETE_CLINICAL_DATA_ACCESS_CODE'
export const SET_SELECTED_ACCESS_CODE = 'SET_SELECTED_ACCESS_CODE'

export const createNewClinicalDataAccessCode = createAsyncThunk<
  ClinicalDataAccessCode[],
  FormDataForNewClinicalDataAccessCode
>(POST_NEW_CLINICAL_DATA_ACCESS_CODE, async (data) => {
  try {
    const response = await axiosInstance.post(`/v1/user/data-access-code`, data)
    return response.data
  } catch (e) {
    console.error(e)
  }
})

export const getClinicalDataAccessCodesForUser = createAsyncThunk<ClinicalDataAccessCode[], string>(
  GET_CLINICAL_DATA_ACCESS_CODES_FOR_USER,
  async (userLyraId: string) => {
    try {
      const response = await axiosInstance.get(`/v1/user/${userLyraId}/data-access-codes`)
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
)

export const deleteClinicalDataAccessCode = createAsyncThunk<void, { userLyraId: string; accessCode: string }>(
  DELETE_CLINICAL_DATA_ACCESS_CODE,
  async ({ userLyraId, accessCode }) => {
    try {
      return await axiosInstance.delete(`/v1/user/${userLyraId}/data-access-code/${accessCode}`)
    } catch (e) {
      console.error(e)
    }
  },
)

export const setSelectedAccessCode = (accessCode?: ClinicalDataAccessCode) => {
  return {
    type: SET_SELECTED_ACCESS_CODE,
    data: accessCode,
  }
}

export const GET_CLINICAL_DATA_ACCESS_CODES_FOR_USER_fulfilled = getClinicalDataAccessCodesForUser.fulfilled.type
export const GET_CLINICAL_DATA_ACCESS_CODES_FOR_USER_rejected = getClinicalDataAccessCodesForUser.rejected.type
export const DELETE_CLINICAL_DATA_ACCESS_CODE_fulfilled = deleteClinicalDataAccessCode.fulfilled.type
export const POST_NEW_CLINICAL_DATA_ACCESS_CODE_fulfilled = createNewClinicalDataAccessCode.fulfilled.type
export const POST_NEW_CLINICAL_DATA_ACCESS_CODE_rejected = createNewClinicalDataAccessCode.rejected.type

import React from 'react'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'
import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

const customerName = 'Netflix'

export const netflix: GetCustomerCopyFunc = ({ numberOfSessions, considerMeds }) => {
  return {
    customerName,
    displaySessionLimit: true,
    legalNotice: <CoverageNotice considerMeds={considerMeds} sessionLimit={numberOfSessions} />,
  }
}

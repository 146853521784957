import React from 'react'

import { GetCustomerCopyFunc, LocationMatcherDisplayFormats } from '@lyrahealth-inc/shared-app-logic'
import { onsiteAlternativeDescription, onsiteDescription } from './default'
import { BodyText } from '../atoms'

export const google: GetCustomerCopyFunc = () => {
  return {
    locationFinder: {
      onsiteFormat: LocationMatcherDisplayFormats.DROPDOWN_MENU_TO_RADIO_BUTTONS,
      onsiteDescription: (
        <>
          <BodyText>{onsiteDescription}</BodyText>
          <BodyText>{onsiteAlternativeDescription}</BodyText>
        </>
      ),
    },
  }
}

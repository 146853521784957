import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

export const MoodAndEmotionsIllustration: FunctionComponent<{ size?: number }> = ({ size = 40 }) => {
  return (
    <Svg width={size} height={size} fill='none'>
      <G clipPath='url(#mood-and-emotions-illustration)'>
        <Path
          fill='#FFDC69'
          d='M23.077 4.528c6.72 1.527 12.312 6.38 12.903 14.486.39 5.38-4.973 13.88-10.35 15.8-4.562 1.626-9.078 1.8-13.446-.874-5.665-3.473-8.65-8.653-8.125-15.012.538-6.607 3.737-11.86 10.645-14.38 2.943-1.073 7.21-.286 8.373-.02Z'
        />
        <Path
          fill='#26220F'
          d='M18.023 21.726c-.557 0-.98-.106-1.122-.64-.12-.48-.013-.906.437-1.166 1.458-.84 2.916-1.674 4.388-2.48.437-.24.92-.154 1.216.26a.925.925 0 0 1-.02 1.113c-1.277 1.6-2.923 2.6-4.899 2.927v-.014ZM11.722 18.678c-.101.847-.484 1.38-1.445 1.253-1.076-.146-2.507-2.353-2.332-3.606.127-.92.78-1.227 1.586-1.16 1.014.08 2.311 2.227 2.184 3.513h.007ZM20.558 9.146c-.025-.346.033-1.334.96-1.42.894-.08 2.857 2.226 2.803 3.273-.034.766-.43 1.273-1.237 1.34-1.169.1-2.513-1.56-2.533-3.193 0 0 .03.345.007 0Z'
        />
      </G>
      <Defs>
        <ClipPath id='mood-and-emotions-illustration'>
          <Path fill='#fff' d='M0 0h40v40H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'
import { defineMessage } from 'react-intl'

export const kiewit: GetCustomerCopyFunc = () => {
  return {
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage: 'Are you an employee?',
        description: 'Asking if the user is an employee',
      }),
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit'

import { BenefitGroup } from '@lyrahealth-inc/shared-app-logic'

import { axiosInstance } from '../../common/http/axiosInstance'

export const GET_ALL_BENEFITS_FOR_USER = 'GET_ALL_BENEFITS_FOR_USER'
export const GET_RECOMMENDED_BENEFITS_FOR_USER = 'GET_RECOMMENDED_BENEFITS_FOR_USER'

export const getAllBenefitsForUser = createAsyncThunk<BenefitGroup[], string>(
  GET_ALL_BENEFITS_FOR_USER,
  async (userLyraId: string) => {
    try {
      const response = await axiosInstance.get(`/v1/user/${userLyraId}/benefits`)
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
)

export const getRecommendedBenefitsForUser = createAsyncThunk<BenefitGroup[], string>(
  GET_RECOMMENDED_BENEFITS_FOR_USER,
  async (userLyraId: string) => {
    try {
      const response = await axiosInstance.get(`/v1/user/${userLyraId}/benefit-recommendations`)
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
)

export const GET_ALL_BENEFITS_FOR_USER_fulfilled = getAllBenefitsForUser.fulfilled.type
export const GET_RECOMMENDED_BENEFITS_FOR_USER_fulfilled = getRecommendedBenefitsForUser.fulfilled.type

import React, { FunctionComponent, LegacyRef, useEffect, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Platform, TouchableOpacity, View, ViewStyle } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { CalendarBookingIcon, ExerciseIcon } from '../../atoms'
import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { ChatBubble } from '../../atoms/chatBubble/ChatBubble'
import { ChatBubbleText } from '../../atoms/chatBubble/ChatBubbleText'
import { DeletedLiveMessageText } from '../../atoms/deletedLiveMessageText/DeletedLiveMessageText'
import { Avatar, avatarAltTextGeneric } from '../../atoms/icons/Avatar'
import { WarningIcon } from '../../atoms/icons/WarningIcon'
import { Timestamp } from '../../atoms/timestamp/Timestamp'
import { ActivityBubble } from '../../molecules/activityBubble/ActivityBubble'
import { Message, MessageAttachmentData, MessageType } from '../../ui-models'
import { ActivityReviewInfo } from '../../ui-models/assignments/Assignments'
import { getFriendlyFormattedTimeStamp, tID } from '../../utils/utils'
import { MessageAttachmentRenderer } from '../messageAttachmentRenderer/MessageAttachmentRenderer'

export type MessageItemProps = {
  item: Message
  onChatBubblePressed?: (messageId: string, receiver: boolean, activityInfo?: ActivityReviewInfo) => void
  onLinkPress?: (link: string) => void
  onAttachmentPressed?: (attachment: MessageAttachmentData) => void
}

const Row = styled.View<{ receiver: boolean }>(({ receiver }) => {
  const style = { marginVertical: '6px' }
  if (receiver) {
    return {
      ...style,
      flexDirection: 'row',
      flexWrap: 'nowrap',
    }
  } else {
    return {
      ...style,
      flexDirection: 'row-reverse',
    }
  }
})

const StatusContainer = styled.View({
  marginTop: '5px',
  marginRight: '5px',
  alignItems: 'flex-end',
})

const FailedStatus = styled.View({
  flexDirection: 'row',
})

const WarningIconContainer = styled.View({
  marginRight: '4px',
})

const AvatarContainer = styled.View({
  marginTop: '2px',
})

/**
 * This component renders a single message row which consists of the avatar, time stamp of when the message was created
 * and the chat bubble which contains the message itself.
 * Component also handles updating the status delivery of a message
 */
let MessageItem: FunctionComponent<MessageItemProps> = ({
  item,
  onChatBubblePressed,
  onLinkPress,
  onAttachmentPressed,
}) => {
  const intl = useIntl()
  const { colors } = useTheme()
  const { chatBubbleReceiver, chatBubbleSender, icon: messageItemIcon } = colors.components.messageItem
  const messageRef = useRef<HTMLElement>(null)
  const {
    receiver,
    dateCreated,
    avatarDetails,
    message,
    sendStatus,
    messageId,
    showTime,
    linkText,
    activityResponseId,
    activityId,
    submitDate,
    activityTitle,
    type,
    messageType,
    attachments,
  } = item

  const formattedDate = getFriendlyFormattedTimeStamp(intl, dateCreated)
  const dateCreatedObject = new Date(dateCreated)
  const locale = Intl.DateTimeFormat().resolvedOptions().locale
  const intlFormattedDateTime = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }).format(dateCreatedObject)

  useEffect(() => {
    if (messageRef && messageRef.current && Platform.OS === 'web') {
      messageRef.current.title = intlFormattedDateTime
    }
  }, [intlFormattedDateTime])

  const chatBubblePosition: ViewStyle = {
    marginLeft: 15,
    // margin right below accounts for the avatar being shown next to message
    marginRight: receiver ? 75 : 0,
    maxWidth: '75%',
  }

  // taken from ui-core. using regex to detect a url within a string
  const urlRegex = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;$]*[-A-Z0-9+&@#/%=~_|])/gi
  const hasLinkInText = function (text: any) {
    const tokens = text.split(urlRegex)
    return tokens.length > 1
  }

  const failed = sendStatus === 'failed'
  const hasLink = hasLinkInText(message)
  // TODO: Remove the Platform Check after https://lyrahealth.atlassian.net/browse/BCDELIVERY-4707
  //  when it'll become interactive in mobile
  const isInteractive =
    Boolean(linkText) ||
    (Boolean(activityResponseId) && Boolean(activityId)) ||
    (messageType && ['session_cancellation', 'request_session_time'].includes(messageType) && Platform.OS === 'web')
  const chatBubbleTouchable =
    (!receiver && failed) ||
    (receiver && !failed && isInteractive) ||
    Boolean(activityId) ||
    (messageType && ['session_cancellation', 'request_session_time'].includes(messageType) && Platform.OS === 'web') ||
    hasLink
  const chatBubbleBackgroundColor = receiver ? chatBubbleReceiver.background : chatBubbleSender.background
  const chatBubbleBorderColor = receiver ? chatBubbleReceiver.border : chatBubbleSender.border

  const onPress = () => {
    onChatBubblePressed && chatBubbleTouchable
      ? isInteractive && !hasLink
        ? onChatBubblePressed(messageId, receiver, {
            id: activityId,
            responseId: activityResponseId,
            activityType: messageType,
          })
        : onChatBubblePressed(messageId, receiver)
      : noop()
  }

  const icon =
    messageType === 'request_session_time' ? (
      <CalendarBookingIcon fillColor={!receiver ? colors.iconInverse : messageItemIcon.receiver.fill} />
    ) : (
      <ExerciseIcon fillColor={!receiver ? colors.iconInverse : messageItemIcon.receiver.fill} />
    )

  return (
    // casting to unknown because TS says View and HTMLElements do not have enough overlap
    //   if we don't cast to unknown, we'll need a tsignore when we set the ref's index property
    <View testID={tID('MessageItem')} ref={messageRef as unknown as LegacyRef<View>}>
      {showTime ? (
        <View testID={tID('MessageItem-timestamp')}>
          <Timestamp time={formattedDate} />
        </View>
      ) : null}
      {type === MessageType.DELETED ? (
        <DeletedLiveMessageText />
      ) : (
        <Row receiver={receiver}>
          {avatarDetails && (
            <AvatarContainer testID={tID('Messages-avatar')}>
              <Avatar
                details={avatarDetails}
                accessibilityLabel={intl.formatMessage(avatarAltTextGeneric, { name: avatarDetails.displayName })}
              />
            </AvatarContainer>
          )}
          <View style={chatBubblePosition}>
            <TouchableOpacity onPress={onPress} disabled={!chatBubbleTouchable}>
              {/* If the message is a comment (feedback) from the provider about an exercise */}
              {type === MessageType.ACTIVITY || messageType === 'request_session_time' ? (
                <ActivityBubble
                  title={activityTitle}
                  backgroundColor={chatBubbleBackgroundColor}
                  activityText={message}
                  submitDate={submitDate}
                  fontColor={!receiver ? colors.textInverse : colors.textPrimary}
                  icon={icon}
                />
              ) : (
                <ChatBubble
                  body={
                    <ChatBubbleText
                      text={message}
                      fontColor={!receiver ? colors.textInverse : colors.textPrimary}
                      onLinkPress={onLinkPress}
                    />
                  }
                  backgroundColor={chatBubbleBackgroundColor}
                  borderColor={chatBubbleBorderColor}
                  messageType={messageType}
                />
              )}
            </TouchableOpacity>
          </View>
        </Row>
      )}
      {attachments?.map((attachment) => (
        <Row key={attachment.key} receiver={receiver}>
          <View style={chatBubblePosition}>
            <MessageAttachmentRenderer attachment={attachment} onPress={onAttachmentPressed} />
          </View>
        </Row>
      ))}
      {sendStatus !== 'sent' ? (
        <StatusContainer testID={tID('MessageItem-status')}>
          {!receiver && sendStatus === 'sending' ? (
            <BodyText
              text={
                <FormattedMessage
                  defaultMessage='SENDING...'
                  description='This is a label that tells the user this message in the process of being sent'
                />
              }
              size={Size.CAPTION}
              textAlign='right'
              color={colors.textSecondary}
            />
          ) : null}
          {!receiver && sendStatus === 'failed' ? (
            <FailedStatus>
              <WarningIconContainer>
                <WarningIcon size={16} fillColor={colors.iconError} />
              </WarningIconContainer>
              <BodyText
                text={
                  <FormattedMessage
                    defaultMessage='NOT DELIVERED'
                    description='This is a label that tells the user this message did not reach the server and was not delivered'
                  />
                }
                size={Size.CAPTION}
                textAlign='right'
                color={colors.textError}
              />
            </FailedStatus>
          ) : null}
        </StatusContainer>
      ) : null}
    </View>
  )
}

MessageItem = React.memo(MessageItem)

export { MessageItem }

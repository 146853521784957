import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

import FocusTrap from 'focus-trap-react'
import styled from 'styled-components/native'

import { getDocumentObject } from '@lyrahealth-inc/shared-app-logic'

import { IS_WEB } from '../../constants'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

interface FullScreenOverlayProps {
  isOpen: boolean
  isFocused?: boolean
  customStyles?: { [key: string]: string }
  isFirst?: boolean
  renderInPortal?: boolean
}

const FullScreenOverlayContainer = styled.View<{
  theme: ThemeType
  styles?: { [key: string]: string }
}>(({ theme, styles = {} }) => {
  const defaultStyles = {
    backgroundColor: theme.colors.backgroundPrimary,
    position: IS_WEB ? ('fixed' as const) : ('absolute' as const),
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    overflow: 'scroll',
    // zIndex not needed if the component is rendered directly on document.body
    zIndex: '200',
  }

  return { ...defaultStyles, ...styles }
})

export const FullScreenOverlay: React.FC<FullScreenOverlayProps> = ({
  isOpen,
  customStyles,
  isFocused = true,
  isFirst = true,
  children,
  renderInPortal = false,
}) => {
  const wrapperRef = useRef<any>(null)

  useEffect(() => {
    const documentObject = getDocumentObject()

    function setFocus() {
      const modalElements = document.querySelector(
        '.react-datepicker, #InactivityTimeout, [data-testid="ActivityExitModal"]',
      )

      // If modal is displayed on the page, pause the focus trap for the page underneath so the modal is focusable
      // Otherwise resume focus on the fullscrenOverlay
      if (wrapperRef.current && modalElements) {
        wrapperRef.current?.focusTrap.pause()
      } else {
        wrapperRef?.current?.focusTrap.unpause()
      }
    }
    documentObject && documentObject.addEventListener('mousedown', setFocus)
    return () => {
      documentObject && documentObject.removeEventListener('mousedown', setFocus)
    }
  }, [wrapperRef])

  // Prevent additional vertical scrollbars
  useEffect(() => {
    const documentObject = getDocumentObject()
    const appRoot = documentObject && documentObject.getElementById('root')
    if (isOpen && documentObject) {
      documentObject.body.style.overflow = 'hidden'
      if (appRoot && renderInPortal) appRoot.style.visibility = 'hidden'
    }
    return () => {
      if (documentObject && isFirst) {
        documentObject.body.style.overflow = 'unset'
        if (appRoot && renderInPortal) appRoot.style.visibility = 'visible'
      }
    }
  }, [isOpen, isFirst, renderInPortal])

  return isOpen ? (
    <>
      {renderInPortal ? (
        createPortal(
          <FocusTrap active={isOpen && isFocused} ref={wrapperRef}>
            <FullScreenOverlayContainer styles={customStyles} testID={tID('fullscreen-overlay')} aria-modal={true}>
              {children}
            </FullScreenOverlayContainer>
          </FocusTrap>,
          document.body,
        )
      ) : (
        <FocusTrap active={isOpen && isFocused} ref={wrapperRef}>
          <FullScreenOverlayContainer styles={customStyles} testID={tID('fullscreen-overlay')} aria-modal={true}>
            {children}
          </FullScreenOverlayContainer>
        </FocusTrap>
      )}
    </>
  ) : null
}

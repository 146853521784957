import { ICAS_POLICIES_LINK } from '../../common/constants/constants'

export type LegalNoticeKind =
  | 'HIPAA_NOTICE'
  | 'PRIVACY_POLICY'
  | 'TERMS_OF_USE'
  | 'RACE_AND_ETHNICITY'
  | 'ACCESSIBILITY'
  | 'EMAIL_CONSENT'

const LEGAL_NOTICE_LINKS: Record<'en' | 'fr' | 'es', Partial<Record<LegalNoticeKind, string>>> = {
  en: {
    HIPAA_NOTICE: 'https://www.lyrahealth.com/hipaa-notice/',
    PRIVACY_POLICY: 'https://www.lyrahealth.com/privacy-policy/',
    TERMS_OF_USE: 'https://www.lyrahealth.com/terms-of-use/',
    RACE_AND_ETHNICITY: 'https://www.lyrahealth.com/notice-at-collection-race-and-ethnicity-data', // available in english only
    ACCESSIBILITY: 'https://www.lyrahealth.com/accessibility/',
    EMAIL_CONSENT: 'https://www.lyrahealth.com/email-consent/',
  },
  es: {
    HIPAA_NOTICE: 'https://www.lyrahealth.com/hipaa-notice-es/',
    PRIVACY_POLICY: 'https://www.lyrahealth.com/privacy-policy-es/',
    TERMS_OF_USE: 'https://www.lyrahealth.com/terms-of-use-es/',
  },
  fr: {
    HIPAA_NOTICE: 'https://www.lyrahealth.com/hipaa-notice-fr/',
    PRIVACY_POLICY: 'https://www.lyrahealth.com/privacy-policy-fr/',
    TERMS_OF_USE: 'https://www.lyrahealth.com/terms-of-use-fr/',
  },
}

const DEFAULT_LANG = 'en'

/**
 * ACCOUNT-474: The marketing website that hosts these legal notices does not detect the user's langauge,
 * rather it serves them in static paths in select required languages. This function returns the correct
 * localized url for each legal notice, given the user's active language in the app.
 *
 * e.g. Given fr-CA or fr-FR, return the notice link for fr. Defaults to en.
 *
 * @returns the link to the legal notice, given the user's current language
 * @param activeLanguage  language tag for user's current display language
 */
export function getLegalNoticeLink(activeLanguage: string, kind: LegalNoticeKind, isInternational?: boolean) {
  if (isInternational) {
    switch (kind) {
      case 'PRIVACY_POLICY':
        return ICAS_POLICIES_LINK
    }
  }

  let language = DEFAULT_LANG
  try {
    language = new Intl.Locale(activeLanguage).language
  } catch (e) {
    console.error('getLegalNoticeLink: Could not parse language tag:', e)
  }
  return LEGAL_NOTICE_LINKS[language]?.[kind] ?? LEGAL_NOTICE_LINKS[DEFAULT_LANG][kind]
}

import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { CustomerSubpopulations } from '@lyrahealth-inc/shared-app-logic'

import { IS_WEB } from '../../constants'
import { ChooseSubpopulationForm } from '../../organisms'
import { Modal } from '../../organisms/modal/Modal'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export interface SubpopulationRedirectionProps {
  subpopulations: CustomerSubpopulations
  onClose: () => void
  handleSubmit: (newCustomerIndex: number) => void
  useModal?: boolean
  isModalVisible?: boolean
  showDelay?: number
  showCloseIcon?: boolean
  closeOnScrim?: boolean
}

const ModalContainer = styled.View<{ theme: ThemeType }>(({ theme }) => {
  return {
    ...(IS_WEB &&
      !theme.breakpoints.isMinWidthTablet && {
        padding: '0 24px',
        position: 'fixed',
        top: '64px',
        overflow: 'auto',
        bottom: '0',
      }),
    backgroundColor: theme.colors.backgroundPrimary,
  }
})

export const SubpopulationRedirection: FunctionComponent<SubpopulationRedirectionProps> = ({
  subpopulations,
  onClose,
  handleSubmit,
  useModal = false,
  isModalVisible = false,
  showCloseIcon = true,
  showDelay = 0,
  closeOnScrim = true,
}) => {
  const content = (
    <ModalContainer testID={tID('SubpopulationRedirection-container')}>
      <ChooseSubpopulationForm
        subpopulationData={subpopulations}
        onBackPress={onClose}
        onSubmit={handleSubmit}
        showCloseIcon={showCloseIcon}
      />
    </ModalContainer>
  )

  if (useModal) {
    return (
      <Modal
        showCloseIcon={showCloseIcon}
        onRequestClose={onClose}
        onCloseEnd={onClose}
        visible={isModalVisible}
        modalContents={content}
        disableBottomSheet
        mobileContentCentered
        showDelay={showDelay}
        closeOnScrim={closeOnScrim}
      />
    )
  }

  return content
}

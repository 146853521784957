import React, { cloneElement, FC, ReactElement } from 'react'
import { View } from 'react-native'

import { AccordionProps } from '@lyrahealth-inc/ui-core-crossplatform/src/molecules/accordion/Accordion'

export type AccordionGroupProps = {
  accordions: ReactElement<AccordionProps>[]
  allowMultiExpand?: boolean
}

export const AccordionGroup: FC<AccordionGroupProps> = ({ accordions, allowMultiExpand = false }) => {
  const [expandedAccordions, setExpandedAccordions] = React.useState<Set<number>>(new Set())

  const handleAccordionClicked = (index: number, _isOpen: boolean) => {
    const updatedExpandedAccordions = new Set(expandedAccordions)
    if (updatedExpandedAccordions.has(index)) {
      updatedExpandedAccordions.delete(index)
    } else if (updatedExpandedAccordions.size > 0 && !allowMultiExpand) {
      updatedExpandedAccordions.clear()
      updatedExpandedAccordions.add(index)
    } else {
      updatedExpandedAccordions.add(index)
    }
    setExpandedAccordions(updatedExpandedAccordions)
  }

  return (
    <View>
      {accordions.map((accordion, index) => {
        return cloneElement(accordion as ReactElement<any>, {
          handleIsExpandedChanged: (isOpen: boolean) => handleAccordionClicked(index, isOpen),
          isExpanded: expandedAccordions.has(index),
        })
      })}
    </View>
  )
}

import React from 'react'

import { fromJS } from 'immutable'

import DependentIllustration from '../../../images/microsite/illustration-dependents.png'
import StationaryIllustration from '../../../images/microsite/illustration-financial.png'
import HeartHandsIllustration from '../../../images/microsite/illustration-heart-hands.png'
import YogaIllustration from '../../../images/microsite/illustration-yoga.png'
import { oneLine } from '../../utils/stringUtils'

/* eslint-disable formatjs/no-literal-string-in-jsx */

export const thehartford = fromJS({
  workLifeServices: [
    {
      identifiers: {
        id: 'careAtWork',
        dataTestId: 'workLife-selectCareAtWork',
      },
      title: 'Care @ Work',
      img: HeartHandsIllustration,
      paragraph: (
        <>
          {oneLine(`The Hartford provides – at no cost to you – unlimited access to `)}
          <a href='https://care.com/' target='_blank' rel='noreferrer'>
            Care.com
          </a>
          {oneLine(`, the world’s largest online community for finding care, including nannies, tutors,
                    senior caregivers, housekeepers, and more. Sign up at `)}
          <a href='http://care.com/thehartford' target='_blank' rel='noreferrer'>
            care.com/thehartford
          </a>
          {oneLine(`.`)}
        </>
      ),
      bullets: [],
    },
    {
      identifiers: {
        id: 'peaceAtHome',
        dataTestId: 'workLife-selectPeaceAtHome',
      },
      title: 'Peace at Home Parenting Solutions',
      img: YogaIllustration,
      paragraph: (
        <>
          {oneLine(`Access to live and recorded online parenting classes at no cost to you. Classes
                    include topic such as Back to School with Joy and Optimism, Positive Discipline, School Success –
                    Inspire Motivation and Engagement, and more. `)}
          <a href='https://www.cafewell.com/pages/full?id=hartpah21' target='_blank' rel='noreferrer'>
            Learn more
          </a>
        </>
      ),
      bullets: [],
    },
    {
      identifiers: {
        id: 'mavenFamily',
        dataTestId: 'workLife-selectMavenFamily',
      },
      title: 'Maven Family Benefits',
      img: DependentIllustration,
      paragraph: (
        <>
          {oneLine(`Get unlimited, on-demand support for fertility, pregnancy, adoption, surrogacy,
                postpartum, returning to work, and infant care with Maven. `)}
          <a href='https://www.cafewell.com/pages/full?id=hart_mav21' target='_blank' rel='noreferrer'>
            Learn More
          </a>
        </>
      ),
      bullets: [],
    },
    {
      identifiers: {
        id: 'perksAtWork',
        dataTestId: 'workLife-selectPerksAtWork',
      },
      title: 'Perks at Work',
      img: StationaryIllustration,
      paragraph: (
        <>
          {oneLine(`Check out Perks at Work for back-to-school savings and access to enrichment programs
                for children. `)}
          <a href='https://www.perksatwork.com/login' target='_blank' rel='noreferrer'>
            Sign in Here.
          </a>
        </>
      ),
      bullets: [],
    },
  ],
})

import React, { FunctionComponent } from 'react'
import { G, Polygon, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Check Icon used in checkbox
 */
export const CheckboxIcon: FunctionComponent<CheckboxIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24'>
      <G id='Artboard' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <Polygon
          id='Checked'
          fill={fillColor || colors.iconInverse}
          fillRule='nonzero'
          points='16.66655 6 19 8.58938673 10.0785 18.6 9.9413 18.4840926 5 12.918567 7.33345 10.328786 10.0785 13.4208323'
        />
      </G>
    </Svg>
  )
}

type CheckboxIconProps = {
  size?: string | number
  fillColor?: string
}

const defaultState = {}
import * as Actions from './graphql-type-mapper-actions'

export const graphqlTypeMapper = (state = defaultState, action = null) => {
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  switch (action.type) {
    case Actions.SET_GRAPHQL_TYPE_MAPPER: {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return action.mapper
    }
    default:
      return state
  }
}

export default graphqlTypeMapper

import React, { FunctionComponent } from 'react'
import { Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Icon of the application
 */
export const AppIcon: FunctionComponent<AppIconProps> = ({ size = 80, rounded }) => {
  const { colors } = useTheme()
  const { background, text } = colors.icons.appIcon

  return (
    <Svg width={size} height={size} viewBox='0 0 80 83' fill='none'>
      <Rect width='80' height='82.6994' fill={background} rx={rounded ? '18' : '0'} />
      <Path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M35.4514 34.7688L29.9937 47.9405L25.1032 34.7688H21.6649L28.2212 52.1712L24.5349 60.5983H28.1328L39.0309 34.7688H35.4514ZM49.9473 34.8055C46.9349 34.8055 45.074 36.4907 44.1177 38.0109V34.8055H40.9274V52.2816H44.1177V44.7339C44.1177 41.4359 46.1198 38.0109 49.8061 38.0109H50.4444V34.8055H49.9473V34.8055ZM64.6384 43.5258C64.6384 40.4853 62.2459 38.0121 59.3047 38.0121C56.3635 38.0121 53.9711 40.4853 53.9711 43.5258C53.9711 46.5662 56.3635 49.0394 59.3047 49.0394C62.2632 49.0394 64.6384 46.5662 64.6384 43.5258ZM67.7575 43.5258V52.2638H64.5856V50.3591C63.1322 51.5494 61.3069 52.2828 59.3047 52.2828C54.6438 52.2828 50.8519 48.3807 50.8519 43.5447C50.8519 38.7088 54.6266 34.8067 59.3047 34.8067C63.9645 34.8055 67.7575 38.7076 67.7575 43.5258ZM19.7684 48.948C20.2471 48.9658 21.6109 48.948 21.6109 48.948V52.246C21.6109 52.246 20.3699 52.246 19.7684 52.246C17.9614 52.246 13.2121 51.8983 13.2121 46.7134C13.2121 40.7962 13.2121 28.193 13.2121 28.193H16.4024C16.4024 28.193 16.4024 43.0309 16.4024 45.7794C16.4013 48.344 18.0498 48.8744 19.7684 48.948Z'
        fill={text}
      />
    </Svg>
  )
}

type AppIconProps = {
  size?: string | number
  rounded?: boolean
}

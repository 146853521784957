import React, { FunctionComponent } from 'react'
import { G, Polygon, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Message Icon that is shown on the welcome to app screen
 */
export const MessagePromptIcon: FunctionComponent<MessagePromptIconProps> = ({ size = 96 }) => {
  const { colors } = useTheme()
  const { backgroundMessageLight, backgroundMessageDark, foregroundMessage } = colors.icons.messagePromptIcon

  return (
    <Svg width={size} height={size} viewBox='0 0 96 96'>
      <G id='MVP-app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <G id='H1' transform='translate(-24.000000, -103.000000)'>
          <G id='il-96-message' transform='translate(24.000000, 103.000000)'>
            <G
              id='comments-8593_524bf166-ab22-482f-b8b5-eb9aaa1bb585'
              transform='translate(3.000000, 6.000000)'
              fillRule='nonzero'
            >
              <Polygon
                id='Path'
                fill={backgroundMessageLight.fill}
                points='75 33 75 45 39 45 39 66 64.395 66 81 82.605 81 66 90 66 90 33'
              />
              <Polygon id='Path' fill={backgroundMessageDark.fill} points='39 45 39 66 53.9565 66 74.9565 45' />
              <Polygon id='Path' fill={foregroundMessage.fill} points='75 0 0 0 0 45 12 45 12 61.5 28.581 45 75 45' />
            </G>
          </G>
        </G>
        <G id='-Connections' transform='translate(-3185.000000, -5319.000000)' />
      </G>
    </Svg>
  )
}

type MessagePromptIconProps = {
  size?: string | number
}

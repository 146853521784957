import React, { useMemo } from 'react'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import {
  EnableTranslations,
  LyraIntl,
  useSmartlingContextCapture,
} from '@lyrahealth-inc/shared-app-logic/src/features/intl'

import AppIntlProvider from '../intl/AppIntlProvider'
import { getMicrositeLanguageConfig } from '../intl/config'

const MicrositeIntlProvider: React.FC = ({ children }) => {
  const flags = useFlags()
  const intl = useMemo(() => {
    return new LyraIntl(getMicrositeLanguageConfig(flags))
  }, [flags])
  return (
    <AppIntlProvider intl={intl as LyraIntl<string>}>
      <ContextCapture />
      <EnableTranslations>{children}</EnableTranslations>
    </AppIntlProvider>
  )
}

const ContextCapture: React.FC = () => {
  useSmartlingContextCapture('GMEopEC6CUqutP8GiGUK7g')
  return null
}

export default MicrositeIntlProvider

import React from 'react'
import { Linking } from 'react-native'
import { defineMessage } from 'react-intl'
import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, CoverageNotice, Link } from '../atoms'

/* eslint-disable formatjs/no-literal-string-in-jsx */
const servicesNotCovered = (
  <>
    <BodyText
      text='Services that are not covered include: psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient 
      treatment, emergent care, long-term care or counseling, prescription medication, autism spectrum disorder treatment, 
      services for remedial education, executive coaching, and non-evidence-based behavioral health care. Please check with your medical insurance 
      provider or the Autodesk '
    />
    <Link
      text='benefits website'
      onPress={() => {
        Linking.openURL('https://benefits.autodesk.com/')
      }}
    />
    <BodyText text=' for coverage of these excluded services.' />
  </>
)

export const autodesk: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return {
    legalNotice: (
      <CoverageNotice
        excludedServices={defineMessage({
          defaultMessage:
            'Lyra does not cover psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication, autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care.',
          description:
            'Text for describing the services that Lyra does not cover, displayed as part of the coverage notice',
        })}
        sessionLimit={numberOfSessions}
      />
    ),
    servicesNotCovered,
  }
}

import React, { FunctionComponent } from 'react'
import { G, Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const AlignLeftIcon: FunctionComponent<AlignLeftIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 24 24'>
      <G stroke={fillColor || colors.iconDefault} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.25}>
        <Path d='M17 10.5H3M21 6.5H3M21 14.5H3M17 18.5H3' />
      </G>
    </Svg>
  )
}

export type AlignLeftIconProps = {
  size?: string | number
  fillColor?: string
}

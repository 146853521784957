import { useIntl } from 'react-intl'

import { Appointment } from '../../../models/appointment/Appointment'

export const useGetFormattedCalendarAppointment = (appt: Appointment) => {
  const { formatMessage } = useIntl()
  if (appt) {
    const treatmentType: string = appt.treatmentType
    const adjustedTreatmentType: string = (treatmentType?.charAt(0).toUpperCase() + treatmentType?.slice(1) ?? '')
      ?.split(/(?=[A-Z])/)
      .join(' ')

    const sessionNumber = appt.sessionNumber
    const subtitle = formatMessage(
      {
        defaultMessage: '{treatmentType} • Session {sessionNumber}',
        description: 'Subtitle that shows the type of treatment and which session number it is',
      },
      {
        treatmentType: adjustedTreatmentType,
        sessionNumber,
      },
    )

    return {
      id: appt.appointmentId,
      title: appt.provider?.display_name ?? '',
      avatarDetails: {
        src: appt.provider?.lyraProviderImage.imageUrl ?? '',
        displayName: appt.provider?.display_name ?? '',
      },
      subtitle: subtitle,
    }
  }
  return
}

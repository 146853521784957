import { TrackEventWithObjAction } from '../../data/mixpanel/types'

export function rerouteFromMicrosite({
  user,
  navigate,
  trackEventWithObj,
  mixpanelEvent,
  mixpanelEntryPoint,
}: {
  user: $TSFixMe
  navigate: $TSFixMe
  trackEventWithObj?: TrackEventWithObjAction
  mixpanelEvent?: string
  mixpanelEntryPoint?: string
}) {
  if (!user?.id) {
    if (trackEventWithObj && mixpanelEvent) {
      trackEventWithObj({
        event: mixpanelEvent,
        ...(mixpanelEntryPoint && { entrypoint: mixpanelEntryPoint }),
      })
    }
    navigate('/register/connect', { state: { ...(mixpanelEntryPoint && { mixpanelEntryPoint }) } })
  } else {
    navigate('/secure/onboard/about')
  }
}

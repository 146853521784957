import React, { FunctionComponent, ReactNode } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { PrimaryButton, PrimaryButtonProps } from '../../atoms/primaryButton/PrimaryButton'
import { getCommonStyles } from '../../styles'
import { ThemeType } from '../../utils'

/**
 * Currently used with the sticky view component to have a fixed bottom button on mobile
 */

export interface FixedBottomButtonProps extends PrimaryButtonProps {
  backgroundColor?: string
  isTransparent?: boolean
  secondaryButton?: ReactNode
  children?: ReactNode
  customContainerStyle?: CSSObject
  disabled?: boolean
}

const Container = styled.View<{
  theme: ThemeType
  bottomInset: number
  backgroundColor: string
  isTransparent: boolean
  customStyle?: CSSObject
}>(({ theme, bottomInset, backgroundColor, isTransparent, customStyle }) => {
  return theme.breakpoints.isMobileSized
    ? {
        ...getCommonStyles(theme.colors).fixedButtonContainer,
        borderTopWidth: isTransparent ? 0 : '1px',
        width: '100%',
        paddingBottom: bottomInset > 0 ? '12px' : '34px',
        backgroundColor,
        flexDirection: 'column-reverse',
        ...customStyle,
      }
    : {
        marginVertical: theme.spacing['32px'],
        marginLeft: 'auto',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        ...customStyle,
      }
})

export const FixedBottomButton: FunctionComponent<FixedBottomButtonProps> = ({
  text,
  onPress,
  loading,
  backgroundColor,
  children,
  isTransparent = false,
  secondaryButton,
  customContainerStyle,
  disabled,
  ...rest
}) => {
  const { colors, breakpoints } = useTheme()
  const insets = useSafeAreaInsets()
  return (
    <Container
      bottomInset={insets.bottom}
      backgroundColor={backgroundColor || colors.backgroundPrimary}
      isTransparent={isTransparent}
      customStyle={customContainerStyle}
    >
      {children}
      {secondaryButton}
      <PrimaryButton
        text={text}
        onPress={onPress}
        fullWidth={breakpoints.isMobileSized}
        loading={loading}
        disabled={disabled}
        {...rest}
      />
    </Container>
  )
}

import React, { FunctionComponent, useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import BottomSheetGorhom from '@gorhom/bottom-sheet'
import styled from 'styled-components/native'

import { LYRA_DISPLAY_LANGUAGES } from '@lyrahealth-inc/shared-app-logic'

import { PressableOpacity, PrimaryButton, TertiaryButton, XIcon } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { Modal } from '../../organisms/modal/Modal'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export interface RightToLeftLanguageAlertModalProps {
  selectedLocale: string
  onShowClassicVersionPress: () => void
  onUpdateLanguagePress: () => void
  visible: boolean
}

const ModalBodyContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMobileSized && { padding: theme.spacing['16px'] }),
}))

const TitleAndXButtonContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const StyledBodyText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : theme.spacing['16px'],
  marginBottom: theme.spacing['32px'],
}))

const CTAContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'end',
  gap: theme.spacing['16px'],
  ...(theme.breakpoints.isMobileSized && {
    flexDirection: 'column-reverse',
    gap: theme.spacing['8px'],
  }),
}))

export const RightToLeftLanguageAlertModal: FunctionComponent<RightToLeftLanguageAlertModalProps> = ({
  selectedLocale,
  onShowClassicVersionPress,
  onUpdateLanguagePress,
  visible,
}) => {
  const selectedLanguage = LYRA_DISPLAY_LANGUAGES[selectedLocale].name
  const bottomSheetRef = useRef<BottomSheetGorhom>(null)
  const openBottomSheet = useCallback(() => {
    bottomSheetRef.current?.expand()
  }, [])
  const closeBottomSheet = useCallback(() => {
    bottomSheetRef?.current?.close()
  }, [])

  const snapPoints = [300]

  const modalBody = (
    <ModalBodyContainer testID={tID('RightToLeftLanguageAlertModal')}>
      <TitleAndXButtonContainer>
        <Subhead
          text={
            <FormattedMessage
              defaultMessage='Choose another language'
              description='Title for modal that alerts user to select another language'
            />
          }
          size={SubheadSize.MEDIUM}
        />
        <PressableOpacity onPress={onUpdateLanguagePress}>
          <XIcon size={24} />
        </PressableOpacity>
      </TitleAndXButtonContainer>
      <StyledBodyText
        text={
          <FormattedMessage
            defaultMessage='Your preferred language is currently unavailable for this page. You can select another language or explore our classic version in {language}.'
            description='Description for modal that alerts user the language they have selected is unavailable for this page'
            values={{
              language: selectedLanguage,
            }}
          />
        }
        size={BodyTextSize.DEFAULT}
      />
      <CTAContainer>
        <TertiaryButton
          text={
            <FormattedMessage
              defaultMessage='Show classic version'
              description='Button text to take user to the old version of the home page in the user’s preferred language'
            />
          }
          onPress={onShowClassicVersionPress}
          testID={tID('RightToLeftLanguageAlertModal-showClassicVersionButton')}
          fullWidth
        />
        <PrimaryButton
          text={
            <FormattedMessage
              defaultMessage='Update'
              description='Button text that closes the modal so the user can update their preferred language'
            />
          }
          onPress={onUpdateLanguagePress}
          testID={tID('RightToLeftLanguageAlertModal-updateLanguageButton')}
          fullWidth
        />
      </CTAContainer>
    </ModalBodyContainer>
  )
  return (
    <Modal
      modalContents={modalBody}
      onRequestClose={onUpdateLanguagePress}
      onCloseEnd={() => {
        onUpdateLanguagePress()
        closeBottomSheet()
      }}
      visible={visible}
      useWebPortalForBottomSheet
      bottomSheetRef={bottomSheetRef}
      onLayout={openBottomSheet}
      snapPoints={snapPoints}
    />
  )
}

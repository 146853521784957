import React, { ReactElement } from 'react'
import { Field } from 'react-final-form'
import { KeyboardType, Pressable } from 'react-native'

import { JSONSchema7TypeName } from 'json-schema'
import { capitalize, get, isArray, isEmpty, isFunction, isNil, isNumber, isObject, isString } from 'lodash-es'
import { useTheme } from 'styled-components/native'

import { formatFunctions, parseFunctions } from '@lyrahealth-inc/shared-app-logic'

import { AddIcon, BodyText } from '../../atoms'
import { ButtonRangeGroupRFF } from '../../atoms/formElements/ButtonRangeGroup'
import { CheckboxRFF } from '../../atoms/formElements/Checkbox'
import { CheckboxGroupRFF } from '../../atoms/formElements/CheckboxGroup'
import { DateFieldRFF } from '../../atoms/formElements/DateField'
import { DatePickerSelectFieldRFF } from '../../atoms/formElements/DatePickerSelectField'
import { EditableInputField, EditableInputFieldProps } from '../../atoms/formElements/EditableInputField'
import { ImageRadioGroupRFF } from '../../atoms/formElements/ImageRadioGroup'
import { InputFieldProps, InputFieldRFF } from '../../atoms/formElements/InputField'
import { MaskedInputRFF } from '../../atoms/formElements/MaskedInput'
import { MulticolorSliderFieldRFF } from '../../atoms/formElements/MulticolorSliderField'
import { MultiSelectRFF } from '../../atoms/formElements/MultiSelect'
import { PasswordFieldRFF } from '../../atoms/formElements/passwordField/PasswordField'
import { RadioGroupRFF } from '../../atoms/formElements/RadioButtons'
import { SelectFieldRFF } from '../../atoms/formElements/SelectField'
import { SliderFieldRFF } from '../../atoms/formElements/SliderField'
import { TimeFieldRFF } from '../../atoms/formElements/TimeField'
import { TypeAheadRFF } from '../../atoms/formElements/typeAhead/TypeAhead'
import { TypeAheadCustomOptionsConfig } from '../../atoms/formElements/typeAhead/TypeAheadCustomOptionsConfig'
import * as Illustrations from '../../atoms/illustrations/index'
import { InlineWarningBanner, RequiredCheckboxWithBanner } from '../../molecules'
import { InlineErrorBanner } from '../../molecules/inlineErrorBanner/InlineErrorBanner'
import { InlineSuccessBanner } from '../../molecules/inlineSuccessBanner/InlineSuccessBanner'
import { WordCloudRFF } from '../../molecules/wordCloud/WordCloud'
import { CountrySelectorRFF } from '../../organisms/countrySelector/CountrySelector'
import { InternationalPhoneNumberEntryRFF } from '../../organisms/internationalPhoneNumberEntry/InternationalPhoneNumberEntry'
import { BodyTextSize } from '../../styles'
import { HealthPlanSelectorRFF } from '../healthPlanSelector/HealthPlanSelector'

import type { AddIconWidgetProps, RequiredCheckboxWithBannerWidgetProps, UICoreWidgetProps } from './types'

/**
 * List of widgets to be used be React Json Schema Form
 */

const DATA_KEYBOARD_MAP = new Map<JSONSchema7TypeName | 'default', KeyboardType>([
  ['string', 'default'],
  ['number', 'number-pad'],
  ['default', 'default'],
])

type CustomInputFieldWidgetProps<P> = { CustomInputFieldComponent?: React.FC<P>; customInputFieldProps?: P }

const TextWidget = <CustomInputFieldProps,>({
  schema: {
    name = '',
    title,
    subTitle,
    subTitleComponent,
    placeholder,
    type,
    backgroundColor,
    maxCharLength,
    multiline,
    numberOfLines,
    widgetProps,
    readOnlyOverride,
  },
  formContext: {
    readOnly,
    inputAccessoryViewID,
    returnKeyType,
    handleOnInputFocus,
    setInputRefs,
    handleOnInputBlur,
    getReturnKeyType,
    navigateToNewField,
    areAllInputRefsSet,
  },
  options: { baseInputStyle, inputContainerStyle, labelStyle, labelContainerStyle, isOptionalLabel },
  CustomInputFieldComponent,
  customInputFieldProps,
}: UICoreWidgetProps & CustomInputFieldWidgetProps<CustomInputFieldProps>): ReactElement => {
  const dataType = isString(type) ? type : 'default'
  const keyboardType = DATA_KEYBOARD_MAP.get(dataType)
  const { colors } = useTheme()
  return (
    <Field
      label={title}
      sublabel={subTitle}
      sublabelComponent={subTitleComponent}
      type='text'
      keyboardType={keyboardType}
      name={name}
      placeholder={placeholder}
      component={InputFieldRFF}
      readOnly={readOnlyOverride || readOnly}
      inputAccessoryViewID={inputAccessoryViewID}
      returnKeyType={returnKeyType}
      backgroundColor={backgroundColor}
      maxCharLength={maxCharLength}
      multiline={multiline}
      numberOfLines={numberOfLines}
      placeholderTextColor={colors.textInactive}
      labelStyle={labelStyle}
      labelContainerStyle={labelContainerStyle}
      isOptionalLabel={isOptionalLabel}
      baseInputStyle={baseInputStyle}
      inputContainerStyle={inputContainerStyle}
      handleOnInputFocus={handleOnInputFocus}
      setInputRefs={setInputRefs}
      handleOnInputBlur={handleOnInputBlur}
      getReturnKeyType={getReturnKeyType}
      navigateToNewField={navigateToNewField}
      areAllInputRefsSet={areAllInputRefsSet}
      CustomInputFieldComponent={CustomInputFieldComponent}
      customInputFieldProps={customInputFieldProps}
      {...widgetProps}
    />
  )
}

const CheckboxWidget = ({
  schema: { name = '', title, buttonType, badge, description, error, widgetProps, ...rest },
  options: { baseInputStyle },
  formContext: { readOnly },
}: UICoreWidgetProps): ReactElement => {
  /**
   * Since we do not use a real input field with type='checkbox', checked
   * does not work with react-final-form and is never triggered. Hence we need to use
   * a type other than type='checkbox', i.e. type='bool' for the field and use value in CheckboxRFF to determine if the field is true/false
   */
  return (
    <Field
      type='bool'
      name={name}
      component={CheckboxRFF}
      title={title}
      readOnly={readOnly}
      buttonType={buttonType}
      badge={badge}
      description={description}
      baseInputStyle={baseInputStyle}
      {...(error ? { error } : {})}
      {...(rest as Dict)}
      {...widgetProps}
    />
  )
}

const TextareaWidget = ({
  schema: { name = '', title, placeholder, numberOfLines, type, maxCharLength, showCharLimit },
  formContext: { readOnly, inputAccessoryViewID },
}: UICoreWidgetProps): ReactElement => {
  const dataType = isString(type) ? type : 'default'
  const keyboardType = DATA_KEYBOARD_MAP.get(dataType)
  const { colors } = useTheme()
  return (
    <Field
      label={title}
      type='text'
      keyboardType={keyboardType}
      name={name}
      placeholder={placeholder}
      component={InputFieldRFF}
      multiline
      numberOfLines={numberOfLines ?? 4}
      maxCharLength={maxCharLength}
      showCharLimit={showCharLimit}
      readOnly={readOnly}
      inputAccessoryViewID={inputAccessoryViewID}
      placeholderTextColor={colors.textInactive}
    />
  )
}

const DateWidget = ({
  options: { style, dateFieldDisplay, baseInputStyle, locale: customLocale },
  schema: { name = '', title, placeholder, widgetProps },
  formContext: { readOnly, locale, handleOnInputFocus, setInputRefs, handleOnInputBlur },
}: UICoreWidgetProps): ReactElement => {
  return (
    <Field
      label={title}
      type='text'
      name={name}
      placeholder={placeholder}
      component={DateFieldRFF}
      readOnly={readOnly}
      locale={customLocale || locale}
      style={style}
      dateFieldDisplay={dateFieldDisplay}
      baseInputStyle={baseInputStyle}
      handleOnInputFocus={handleOnInputFocus}
      handleOnInputBlur={handleOnInputBlur}
      setInputRefs={setInputRefs}
      {...widgetProps}
    />
  )
}

const TimeWidget = ({
  options: { style, baseInputStyle, locale: customLocale },
  schema: { name = '', title, placeholder, widgetProps },
  formContext: { readOnly, locale },
}: UICoreWidgetProps): ReactElement => {
  return (
    <Field
      label={title}
      type='text'
      name={name}
      placeholder={placeholder}
      component={TimeFieldRFF}
      readOnly={readOnly}
      locale={customLocale || locale}
      style={style}
      baseInputStyle={baseInputStyle}
      {...widgetProps}
    />
  )
}

const RangeWidget = ({
  schema: {
    name = '',
    title,
    minimum,
    maximum,
    minLabel,
    midLabel,
    maxLabel,
    default: initValue = 0,
    accessibilityLabelledBy,
    decimalValue, // save the value as a decimal value
    show,
    hidden,
    stepLabels,
  },
  formContext: { readOnly },
  options: { percentage, showDivider, rangeContainerStyle, rangeLabelStyle },
}: UICoreWidgetProps): ReactElement => {
  let parse = (value: any) => value
  let format = (value: any) => value
  const decimalScale = 10
  if (decimalValue) {
    parse = (value: any) => {
      if (value && isNumber(value)) {
        return value / decimalScale
      }
      return value
    }
    format = (value: any) => {
      if (value && isNumber(value)) {
        return Math.floor(value * decimalScale)
      }
      return value
    }
  }
  return (
    <Field
      label={title}
      hidden={hidden || show === false}
      type='text'
      name={name}
      parse={parse}
      format={format}
      initValue={initValue}
      component={SliderFieldRFF}
      accessibilityLabelledBy={accessibilityLabelledBy}
      minimum={minimum}
      maximum={maximum}
      minLabel={minLabel}
      midLabel={midLabel}
      maxLabel={maxLabel}
      readOnly={readOnly}
      percentage={percentage}
      stepLabels={stepLabels}
      showDivider={showDivider}
      rangeContainerStyle={rangeContainerStyle}
      rangeLabelStyle={rangeLabelStyle}
    />
  )
}

const MulticolorSliderWidget = ({
  schema: { name = '', title, minimum, maximum, minLabel, maxLabel, default: initValue = 0, accessibilityLabelledBy },
}: UICoreWidgetProps): ReactElement => {
  return (
    <Field
      label={title}
      type='text'
      name={name}
      initValue={initValue}
      component={MulticolorSliderFieldRFF}
      accessibilityLabelledBy={accessibilityLabelledBy}
      minimum={minimum}
      maximum={maximum}
      minLabel={minLabel}
      maxLabel={maxLabel}
    />
  )
}

const RadioWidget = ({
  options,
  schema: {
    name = '',
    title = '',
    titleSecondary,
    subTitle,
    subTitleComponent,
    titleAlignment,
    largeSubTitle,
    correctValue,
    correctValueDetails,
    type,
    buttonType,
    accessibilityLabelledBy,
    titleInfoPopoverConfig,
    titleInfoPopoverContent,
    minLabel,
    maxLabel,
  },
  formContext: { readOnly, showAnswers, formDisabled, ...contextProps },
  options: { baseInputStyle, customTextStyle, labelContainerStyle },
}: UICoreWidgetProps): ReactElement => {
  let buttons
  let isYesNo = false
  const shouldHighlight = showAnswers && !isNil(correctValue)
  if (type === 'boolean') {
    // FIXME: This is not internationalized, only works for English
    const yesText = get(options, 'enumOptions[0].label', '') as string
    const noText = get(options, 'enumOptions[1].label', '') as string
    if (capitalize(yesText) === 'Yes' && capitalize(noText) === 'No') isYesNo = true
    buttons = [
      {
        // By default the label for the first option is 'yes'
        label: capitalize(yesText),
        value: 1,
        highlight: shouldHighlight ? correctValue === 1 : undefined,
      },
      {
        // By default the label for the second option is 'no'
        label: capitalize(noText),
        value: 0,
        highlight: shouldHighlight ? correctValue === 0 : undefined,
      },
    ]
  } else {
    buttons =
      isArray(options.enumOptions) &&
      options.enumOptions.map((option) => {
        option.highlight = shouldHighlight ? correctValue === option.value : undefined
        if (option.schema) {
          option.description = option.schema.description
          option.badge = option.schema.badge
          option.inactive = option.schema.inactive
          option.imageComponent = Illustrations[option.schema.imageComponent]
        }
        return option
      })
  }
  return (
    <Field
      label={title}
      labelInfoPopover={
        titleInfoPopoverContent ? { content: titleInfoPopoverContent, ...titleInfoPopoverConfig } : undefined
      }
      subLabel={subTitle}
      subLabelComponent={subTitleComponent}
      labelSecondary={titleSecondary}
      labelAlignment={titleAlignment}
      largeSubLabel={largeSubTitle}
      type='text'
      name={name}
      options={options}
      component={RadioGroupRFF}
      buttonType={buttonType}
      readOnly={readOnly}
      buttons={buttons}
      showDescription={showAnswers && correctValueDetails}
      correctValueDetails={correctValueDetails}
      correctValue={correctValue}
      isYesNo={isYesNo}
      accessibilityLabelledBy={accessibilityLabelledBy}
      baseInputStyle={baseInputStyle}
      contextProps={contextProps}
      disabled={formDisabled}
      minLabel={minLabel}
      maxLabel={maxLabel}
      customTextStyle={customTextStyle}
      labelContainerStyle={labelContainerStyle}
    />
  )
}

const ImageRadioWidget = ({
  options,
  schema: {
    name = '',
    title,
    subTitle,
    subTitleComponent,
    titleAlignment,
    correctValue,
    correctValueDetails,
    largeSubTitle,
  },
  formContext: { readOnly, showAnswers, formDisabled, ...contextProps },
}: UICoreWidgetProps): ReactElement => {
  const buttons =
    isArray(options.enumOptions) &&
    options.enumOptions.map((option) => {
      if (option.schema) {
        option.inactive = option.schema.inactive
        option.imageComponent = Illustrations[option.schema.imageComponent]
        option.details = option.schema.details
      }
      return option
    })
  return (
    <Field
      label={title}
      subLabel={subTitle}
      subLabelComponent={subTitleComponent}
      largeSubLabel={largeSubTitle}
      contextProps={contextProps}
      labelAlignment={titleAlignment}
      type='text'
      name={name}
      options={options}
      component={ImageRadioGroupRFF}
      readOnly={readOnly}
      buttons={buttons}
      showDescription={showAnswers && correctValueDetails}
      correctValueDetails={correctValueDetails}
      correctValue={correctValue}
      isYesNo={false}
      disabled={formDisabled}
    />
  )
}

const MaskedInputWidget = ({
  options: { mask, style, baseInputStyle },
  schema: { name = '', title, placeholder, parseData, formatData },
  formContext: { readOnly, inputAccessoryViewID },
}: UICoreWidgetProps): ReactElement => {
  return (
    <Field
      style={style}
      mask={mask}
      label={title}
      type='text'
      name={name}
      placeholder={placeholder}
      component={MaskedInputRFF}
      readOnly={readOnly}
      inputAccessoryViewID={inputAccessoryViewID}
      parse={
        !isEmpty(parseData) && parseData !== undefined
          ? (value) => {
              if (!isEmpty(value) && value !== undefined) {
                const functionToRun = parseData[0]
                const params = parseData.slice(1)
                if (!isEmpty(parseFunctions) && isFunction(parseFunctions[functionToRun])) {
                  value = parseFunctions[functionToRun](value, params)
                }
              }
              return value
            }
          : undefined
      }
      format={
        !isEmpty(formatData) && formatData !== undefined
          ? (value) => {
              if (!isEmpty(value) && value !== undefined) {
                const functionToRun = formatData[0]
                const params = formatData.slice(1)
                if (!isEmpty(formatFunctions) && isFunction(formatFunctions[functionToRun])) {
                  value = formatFunctions[functionToRun](value, params)
                }
              }
              return value
            }
          : undefined
      }
      baseInputStyle={baseInputStyle}
    />
  )
}

const SelectWidget = ({
  options: { enumOptions, selectFieldStyle, useStringValue, baseInputStyle },
  schema: { name = '', title, placeholder, widgetProps },
  formContext: { readOnly, useTriageStyling },
}: UICoreWidgetProps): ReactElement => {
  return (
    <Field
      label={title}
      type='text'
      name={name}
      options={enumOptions}
      component={SelectFieldRFF}
      placeholder={placeholder}
      readOnly={readOnly}
      customWebStyle={selectFieldStyle}
      customIOSStyle={selectFieldStyle}
      customAndroidStyle={selectFieldStyle}
      useStringValue={useStringValue}
      baseInputStyle={baseInputStyle}
      useTriageStyling={useTriageStyling}
      {...widgetProps}
    />
  )
}

const CheckboxGroupWidget = ({
  schema: {
    name = '',
    title,
    subTitle,
    titleAlignment,
    enum: enumOptions,
    enumNames,
    enumExclusiveValuesGroups,
    buttonType,
    badge,
    titleSecondary,
    noneOfTheAboveCheckboxId,
  },
  formContext: { readOnly },
  options: { baseInputStyle, labelStyle, labelContainerStyle, inputContainerStyle, customTextStyle },
}: UICoreWidgetProps): ReactElement => {
  const options = enumOptions?.map((enumValue, index) => ({
    id: enumValue,
    text: enumNames?.[index] ?? enumValue,
    group: enumExclusiveValuesGroups?.[index],
  }))
  return (
    <Field
      readOnly={readOnly}
      name={name}
      component={CheckboxGroupRFF}
      label={title}
      labelSecondary={titleSecondary}
      subLabel={subTitle}
      labelAlignment={titleAlignment}
      options={options}
      buttonType={buttonType}
      badge={badge}
      baseInputStyle={baseInputStyle}
      labelStyle={labelStyle}
      labelContainerStyle={labelContainerStyle}
      inputContainerStyle={inputContainerStyle}
      customTextStyle={customTextStyle}
      noneOfTheAboveCheckboxId={noneOfTheAboveCheckboxId}
    />
  )
}

const TypeAheadWidget = ({
  schema: {
    name = '',
    title,
    subTitle,
    options,
    placeholder,
    multiSelect,
    allowUserInput,
    dataSource,
    saveFieldAsArray,
  },
  options: { baseInputStyle, inputContainerStyle, dropdownContainerStyle },
  formContext: {
    readOnly,
    useMultiSelectTypeAhead,
    saveSingleSelectFieldsAsArray,
    postExternalMatches,
    ...contextProps
  },
}: UICoreWidgetProps): ReactElement => {
  let fromContext: string | undefined
  if (typeof options === 'object' && 'fromContext' in options) {
    fromContext = options.fromContext
    options = (options.fromContext && contextProps[options.fromContext]) || []
  }
  const optionsWithIds =
    !useMultiSelectTypeAhead && Array.isArray(options)
      ? options.map((option: string) => ({ text: option, id: option }))
      : options

  return useMultiSelectTypeAhead ? (
    <Field
      name={name}
      component={TypeAheadRFF}
      label={title}
      subLabel={subTitle}
      options={options}
      customOptionsConfig={Array.isArray(options) && isObject(options[0]) && new TypeAheadCustomOptionsConfig(options)}
      readOnly={readOnly}
      multiSelect={multiSelect}
      saveSingleSelectFieldsAsArray={saveFieldAsArray ?? saveSingleSelectFieldsAsArray}
      allowUserInput={allowUserInput}
      placeholder={placeholder}
      showNoResultsDialog={false}
      dataSource={dataSource}
      postExternalMatches={postExternalMatches}
      fromContext={fromContext}
      baseInputStyle={baseInputStyle}
      inputContainerStyle={inputContainerStyle}
      dropdownContainerStyle={dropdownContainerStyle}
    />
  ) : (
    /**
     * Until we implement multiselect for mobile typeAhead
     * we can map to our checkbox component instead.
     */
    <Field readOnly={readOnly} name={name} component={CheckboxGroupRFF} label={title} options={optionsWithIds} />
  )
}

const MultiSelectWidget = ({
  schema: { name = '', title, subTitle, enumNames, enum: enumOptions },
  formContext: { readOnly },
}: UICoreWidgetProps): ReactElement => {
  const optionsWithIds = Array.isArray(enumNames)
    ? enumNames.map((enumName: string, index: number) => ({
        text: enumName,
        id: enumOptions ? enumOptions[index] : index,
      }))
    : []

  return (
    <Field
      name={name}
      component={MultiSelectRFF}
      label={title}
      subLabel={subTitle}
      options={optionsWithIds}
      readOnly={readOnly}
    />
  )
}

const InternationalPhoneInputWidget = ({
  schema: { name = '', title },
  formContext: { customerCountryList, userCountryIsoCode, readOnly },
  options: {
    baseInputStyle,
    toolTipContent,
    toolTipTriggerConfig,
    toolTipPlacement,
    hideInputFlag = false,
    linkUserCountryIsoCode = false,
  },
}: UICoreWidgetProps): ReactElement => {
  return (
    <Field
      name={name}
      label={title}
      type='text'
      component={InternationalPhoneNumberEntryRFF}
      userCountryIsoCode={userCountryIsoCode}
      customerCountryList={customerCountryList}
      readOnly={readOnly}
      baseInputStyle={baseInputStyle}
      toolTipContent={toolTipContent}
      toolTipTriggerConfig={toolTipTriggerConfig}
      toolTipPlacement={toolTipPlacement}
      hideInputFlag={hideInputFlag}
      linkUserCountryIsoCode={linkUserCountryIsoCode}
    />
  )
}

const WordCloudWidget = ({
  schema: {
    name = '',
    title,
    subTitle = '',
    titleAlignment,
    options,
    enum: enumOptions,
    enumNames,
    largeSubTitle,
    modifier,
  },
  formContext,
}: UICoreWidgetProps): ReactElement => {
  let optionsList = []
  let fromContext: string | undefined
  if (typeof options === 'object' && 'fromContext' in options) {
    fromContext = options.fromContext
    optionsList = (formContext.wordCloudWords?.[options.fromContext as string] as Array<string>) || []
  } else {
    optionsList = enumOptions?.map((enumValue, index) => enumNames?.[index] ?? enumValue) ?? []
  }

  return (
    <Field
      name={name}
      component={WordCloudRFF}
      label={title}
      subLabel={subTitle}
      labelAlignment={titleAlignment}
      largeSubLabel={largeSubTitle}
      options={optionsList}
      fromContext={fromContext}
      showCategories={formContext.showWordCloudCategories}
      modifier={modifier}
      disabled={formContext.formDisabled}
    />
  )
}

interface InlineBannerWidgetProps extends UICoreWidgetProps {
  options: {
    bannerMessage: string
  }
}

const InlineErrorBannerWidget = ({
  options: { bannerMessage },
  schema: { name },
  formContext: { inlineErrorBannerMessage },
}: InlineBannerWidgetProps): ReactElement | null => {
  return inlineErrorBannerMessage || bannerMessage ? (
    <InlineErrorBanner testId={name} text={bannerMessage ?? inlineErrorBannerMessage} />
  ) : null
}

const InlineWarningBannerWidget = ({
  options: { bannerMessage },
  schema: { name },
  formContext: { inlineWarningBannerMessage },
}: InlineBannerWidgetProps): ReactElement | null => {
  return inlineWarningBannerMessage || bannerMessage ? (
    <InlineWarningBanner testId={name} text={bannerMessage ?? inlineWarningBannerMessage} />
  ) : null
}

const InlineSuccessBannerWidget = ({
  schema: { name },
  formContext: { inlineSuccessBannerMessage },
}: UICoreWidgetProps): ReactElement | null => {
  return inlineSuccessBannerMessage ? <InlineSuccessBanner testId={name} text={inlineSuccessBannerMessage} /> : null
}

const CountrySelectorWidget = ({
  schema: { name = '', title, error },
  formContext: { customerCountryList, userCountryIsoCode, userCountryName },
  options: { baseInputStyle, inputContainerStyle, dropdownContainerStyle },
}: UICoreWidgetProps): ReactElement => {
  return (
    <Field
      name={name}
      label={title}
      error={error}
      component={CountrySelectorRFF}
      userCountryIsoCode={userCountryIsoCode}
      userCountryName={userCountryName}
      customerCountryList={customerCountryList}
      baseInputStyle={baseInputStyle}
      inputContainerStyle={inputContainerStyle}
      dropdownContainerStyle={dropdownContainerStyle}
    />
  )
}

const PasswordWidget = ({
  schema: {
    name = '',
    title,
    error,
    widgetProps,
    hidePasswordChecklistOnBlur,
    showPasswordChecklistByDefault,
    passwordAutoComplete,
  },
  formContext: {
    returnKeyType,
    passwordPolicy,
    inputAccessoryViewID,
    handleOnInputFocus,
    setInputRefs,
    handleOnInputBlur,
    getReturnKeyType,
    navigateToNewField,
    areAllInputRefsSet,
  },
  options: { baseInputStyle, inputContainerStyle, labelContainerStyle },
}: UICoreWidgetProps): ReactElement => {
  return (
    <Field
      name={name}
      label={title}
      error={error}
      component={PasswordFieldRFF}
      passwordPolicy={passwordPolicy}
      validateOnTouch={true}
      {...widgetProps}
      baseInputStyle={baseInputStyle}
      inputContainerStyle={inputContainerStyle}
      labelContainerStyle={labelContainerStyle}
      showPasswordChecklistByDefault={showPasswordChecklistByDefault}
      hidePasswordChecklistOnBlur={hidePasswordChecklistOnBlur}
      autoComplete={passwordAutoComplete}
      inputAccessoryViewID={inputAccessoryViewID}
      handleOnInputFocus={handleOnInputFocus}
      setInputRefs={setInputRefs}
      handleOnInputBlur={handleOnInputBlur}
      returnKeyType={returnKeyType}
      getReturnKeyType={getReturnKeyType}
      navigateToNewField={navigateToNewField}
      areAllInputRefsSet={areAllInputRefsSet}
    />
  )
}

const RequiredCheckboxWidget = ({
  schema: { name = '' },
  options: { headerText, checkboxLabel, shouldShowBanner, bannerMessage, baseInputStyle },
}: RequiredCheckboxWithBannerWidgetProps): ReactElement => {
  return (
    <RequiredCheckboxWithBanner
      headerText={headerText}
      checkboxLabel={checkboxLabel}
      checkboxName={name}
      bannerText={bannerMessage}
      shouldShowBanner={!!shouldShowBanner}
      baseInputStyle={baseInputStyle}
    />
  )
}

const DatePickerSelectWidget = ({
  options: { minYear, maxYear, numYears, yearOrder, baseInputStyle, selectFieldStyle, labelContainerStyle },
  schema: { name = '', title, widgetProps },
}: UICoreWidgetProps): ReactElement => {
  return (
    <Field
      label={title}
      type='text'
      name={name}
      component={DatePickerSelectFieldRFF}
      minYear={minYear}
      maxYear={maxYear}
      numYears={numYears}
      yearOrder={yearOrder}
      baseInputStyle={baseInputStyle}
      selectFieldStyle={selectFieldStyle}
      labelContainerStyle={labelContainerStyle}
      {...widgetProps}
    />
  )
}

const HealthPlanSelectorWidget = ({
  schema: { name = '', title, error },
  formContext: { healthPlan, healthPlansList, nonIntegratedHealthPlansList },
  options: { baseInputStyle, inputContainerStyle, dropdownContainerStyle },
}: UICoreWidgetProps): ReactElement => {
  return (
    <Field
      name={name}
      label={title}
      error={error}
      component={HealthPlanSelectorRFF}
      healthPlan={healthPlan}
      healthPlansList={healthPlansList}
      nonIntegratedHealthPlansList={nonIntegratedHealthPlansList}
      baseInputStyle={baseInputStyle}
      inputContainerStyle={inputContainerStyle}
      dropdownContainerStyle={dropdownContainerStyle}
    />
  )
}

const ButtonRangeWidget = ({
  schema: {
    accessibilityLabelledBy,
    default: initValue = 0,
    hidden,
    name = '',
    minimum,
    maximum,
    minLabel,
    maxLabel,
    show,
    title,
  },
  options: { rangeContainerStyle },
}: UICoreWidgetProps): ReactElement => {
  return (
    <Field
      accessibilityLabelledBy={accessibilityLabelledBy}
      component={ButtonRangeGroupRFF}
      hidden={hidden || show === false}
      initValue={initValue}
      label={title}
      maximum={maximum}
      maxLabel={maxLabel}
      minimum={minimum}
      minLabel={minLabel}
      name={name}
      rangeContainerStyle={rangeContainerStyle}
    />
  )
}

const AddIconWidget = ({
  schema: { name },
  options: { onPress, isOpen, text, size, style },
}: AddIconWidgetProps): ReactElement => {
  const { colors } = useTheme()

  return (
    <Pressable onPress={onPress} disabled={isOpen} accessible={!isOpen} style={style} testID={name}>
      {!isOpen && <AddIcon width={size} fillColor={colors.iconActive} />}
      {text && (
        <BodyText
          size={BodyTextSize.DEFAULT}
          style={{ paddingLeft: isOpen ? 0 : 8 }}
          text={text}
          color={isOpen ? colors.textPrimary : colors.textActive}
        />
      )}
    </Pressable>
  )
}

const EditableTextWidget = (props: UICoreWidgetProps): ReactElement => {
  const customInputFieldProps: Omit<EditableInputFieldProps, keyof InputFieldProps> = {
    editButtonText: props.options.editButtonText,
    editingLabel: props.options.editingLabel as string,
    clearValueOnEdit: props.options.clearValueOnEdit as boolean,
    onPressEdit: props.options.onPressEdit as () => void | undefined,
  }
  return (
    <TextWidget
      CustomInputFieldComponent={EditableInputField}
      customInputFieldProps={customInputFieldProps}
      {...props}
    />
  )
}

export default {
  TextWidget,
  CheckboxWidget,
  TextareaWidget,
  RangeWidget,
  DateWidget,
  time: TimeWidget,
  RadioWidget,
  SelectWidget,
  mask: MaskedInputWidget,
  checkboxGroup: CheckboxGroupWidget,
  typeAhead: TypeAheadWidget,
  multiSelect: MultiSelectWidget,
  internationalPhone: InternationalPhoneInputWidget,
  wordCloud: WordCloudWidget,
  imageRadio: ImageRadioWidget,
  inlineErrorBanner: InlineErrorBannerWidget,
  inlineSuccessBanner: InlineSuccessBannerWidget,
  inlineWarningBanner: InlineWarningBannerWidget,
  countrySelector: CountrySelectorWidget,
  password: PasswordWidget,
  requiredCheckbox: RequiredCheckboxWidget,
  datePickerSelect: DatePickerSelectWidget,
  healthPlanSelector: HealthPlanSelectorWidget,
  buttonRange: ButtonRangeWidget,
  addIconWidget: AddIconWidget,
  editableInputField: EditableTextWidget,
  multicolorSlider: MulticolorSliderWidget,
}

import { Map as ImmutableMap } from 'immutable'
import { createSelector } from 'reselect'

const getBenefitsState = (state: Map<string, any> | ImmutableMap<string, any>) => state?.get('benefits')

export const getRecommendedBenefitsForUser = createSelector(getBenefitsState, (state) => {
  return state?.get('recommendedBenefitGroupsForUser')
})

export const getAllBenefitsForUser = createSelector(getBenefitsState, (state) => {
  return state?.get('allBenefitGroupsForUser')
})

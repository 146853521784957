import { isFunction, isObject } from 'lodash-es'

import { PROGRAM_MEMBERSHIP_TYPES } from '@lyrahealth-inc/shared-app-logic'

import { getCustomerName } from '../../data/customer/customerSelectors'

export const generateUrlWithText = (url: $TSFixMe, textToDisplay: $TSFixMe) => {
  return `<a href=${url}>${textToDisplay}</a>`
}

export const getCaptchaKey = (state: $TSFixMe) => {
  const customerName = getCustomerName(state)
  let captcha = state.getIn(['appGlobals', 'captcha'])
  const captchaNovalidationCustomersString = state.getIn(['appGlobals', 'captchaNovalidationCustomers']) || '[]'
  const captchaNovalidationCustomers: Array<string> = JSON.parse(captchaNovalidationCustomersString)
  if (captchaNovalidationCustomers.includes(customerName)) {
    captcha = state.getIn(['appGlobals', 'captchaNovalidation'])
  }
  return captcha
}

export const generateCustomerCopy = ({ copy, customerInfo }: $TSFixMe) => {
  return generateCustomerCopyWithProperties({ copy, customerInfo })
}

// Passes an object of customer properties to a customer copy, if applicable
export const generateCustomerCopyWithProperties = ({ copy, customerInfo }: $TSFixMe) => {
  return isFunction(copy) ? copy(customerInfo) : copy
}

// Returns the customer specific version of a default FAQ question found in the Redux store.
// Since some customers have overridden the question language, the "key" property
// links the special version to the default version
export const getCustomerSpecificFAQQuestionCorrelatingToDefault = ({
  customerSpecificFaqQuestions,
  defaultQuestion,
}: $TSFixMe) => {
  return customerSpecificFaqQuestions?.find((questionItem: $TSFixMe) => {
    if (isObject(questionItem)) {
      return (questionItem as $TSFixMe).get('key', (questionItem as $TSFixMe).get('question', '')) === defaultQuestion
    } else {
      return questionItem === defaultQuestion
    }
  })
}

export const doesUserHaveMembershipInCustomer = ({ membershipType }: $TSFixMe) =>
  membershipType === PROGRAM_MEMBERSHIP_TYPES.MEMBER

import { fromJS, Map } from 'immutable'
import { createSelector } from 'reselect'

enum MatchForTypes {
  OTHER_CHILD = 'otherChild',
  SELF = 'self',
}

export const getTriageDemographics = (state: Map<string, any>) => state.get('triageDemographics', fromJS({}))

export const getTriageDemographicMatchFor = createSelector(getTriageDemographics, (triageDemographics) =>
  triageDemographics?.get('matchFor'),
)

export const getTriageIsSearchingForAdult = createSelector(
  getTriageDemographicMatchFor,
  (matchFor) => matchFor === MatchForTypes.SELF,
)

export const getIsTriageDemographicChild = createSelector(
  getTriageDemographicMatchFor,
  (matchFor) => matchFor === MatchForTypes.OTHER_CHILD,
)

import React from 'react'
import { Path, Svg } from 'react-native-svg'

type ResultsLineIconProps = {
  width?: number
  height?: number
}

export const ResultsLineIcon: React.FC<ResultsLineIconProps> = ({ width = 4, height = 42 }) => {
  return (
    <Svg width={width} height={height} viewBox='0 0 4 46' fill='none'>
      <Path d='M2 44L2 2' stroke='#017A8D' strokeWidth='4' strokeLinecap='round' />
    </Svg>
  )
}

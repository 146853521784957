import { createCrmAppointmentObject, ProviderInfo } from '@lyrahealth-inc/shared-app-logic'

import { createCRMAppointmentTicket } from '../../../common/http/data/welkin'
import { getId } from '../../../data/user/userSelectors'

/**
 * Creates a CRM notification to track a user's interest in a recommended provider.
 * Is called when a user clicks on a CTA to view a provider's profile, schedule, or availability for contact.
 *
 * @param {provider} The provider to create the appointment for
 * @returns {function(*, *)} An action to be dispatched
 */
export const createCrmNotificationForProviderSelection = ({ provider }: { provider: ProviderInfo }) => {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const userId = getId(getState())
    const scheduleObj = createCrmAppointmentObject({
      provider,
      userId,
    })

    return createCRMAppointmentTicket({ lyraId: userId, data: scheduleObj })
  }
}

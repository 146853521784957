import { useCallback } from 'react'

import { NewSearchData, SearcherInfo } from '../../../models'
import { cleanCompletedSearchForDuplication, CleanCompletedSearchForDuplicationArgs } from '../utils'

type useBackToTriage = {
  goBack?: () => void
  searcherInfo?: SearcherInfo | null
  isCreatingSearch: boolean
  postTriageSearch: (newSearchData: NewSearchData) => Promise<any>
  shouldCreateDuplicateInProgressSearch?: boolean
} & Omit<CleanCompletedSearchForDuplicationArgs, 'memberAge' | 'searcherType'>

export const useBackToTriage = ({
  goBack,
  search,
  searcherInfo,
  isCreatingSearch,
  postTriageSearch,
  shouldCreateDuplicateInProgressSearch = true,
  getWordCloudWords,
  wordCloudWords,
  primaryNeedsWord,
  country,
  lyraSocialCareEnabled,
  fetchedMetadataVersion,
}: useBackToTriage) => {
  const handleBackPress = useCallback(async () => {
    // Create a duplicate in_progress search when going back to triage
    goBack ? goBack() : null
    if (!isCreatingSearch && shouldCreateDuplicateInProgressSearch && search && searcherInfo) {
      const newSearchData = await cleanCompletedSearchForDuplication({
        search,
        getWordCloudWords,
        memberAge: searcherInfo.age,
        searcherType: searcherInfo.type,
        country,
        lyraSocialCareEnabled,
        primaryNeedsWord,
        wordCloudWords,
        fetchedMetadataVersion,
      })
      await postTriageSearch(newSearchData)
    }
  }, [
    getWordCloudWords,
    goBack,
    country,
    lyraSocialCareEnabled,
    postTriageSearch,
    primaryNeedsWord,
    searcherInfo,
    search,
    isCreatingSearch,
    shouldCreateDuplicateInProgressSearch,
    wordCloudWords,
    fetchedMetadataVersion,
  ])

  return handleBackPress
}

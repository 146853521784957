import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Wave / Hand Icon
 */
export const WavingHandIcon: FunctionComponent<WavingHandIconProps> = ({ size = 24 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none'>
      <Path
        d='M21.068 11.43a1.92 1.92 0 00-.93-1.66l.08-.08a2 2 0 000-2.76 1.798 1.798 0 00-.43-.31 2 2 0 00.27-1 2 2 0 00-2.93-1.68 1.917 1.917 0 00-.32-.43 2 2 0 00-1.38-.58 2 2 0 00-1.42.6l-4 4a2.211 2.211 0 10-4.33-.9l-.15.55a22.73 22.73 0 01-1.84 4.27 6.56 6.56 0 0010.45 7.69l6.35-6.35a1.94 1.94 0 00.58-1.36zM5.928 18.1a5.05 5.05 0 01-.92-5.94 23.932 23.932 0 002-4.55l.13-.59a.723.723 0 011.4.36l-1.08 4.18a.76.76 0 00.54.92.75.75 0 00.91-.54l.41-1.56 5.79-5.79a.43.43 0 01.32-.14.49.49 0 01.32.14.42.42 0 01.13.32.44.44 0 01-.13.32l-3.13 3.13-1.23 1.23a.741.741 0 000 1.06.73.73 0 00.53.22.74.74 0 00.53-.22l1.23-1.23 3.13-3.13 1-1a.47.47 0 01.64 0 .45.45 0 01.14.32.43.43 0 01-.14.32l-1 1-4.8 4.81a.74.74 0 000 1.06.75.75 0 001.06 0l1.41-1.41 3.39-3.37a.47.47 0 01.64 0 .452.452 0 010 .64l-1.41 1.36-4.1 4.1a.75.75 0 001.06 1.06l4.1-4.1a.45.45 0 01.64 0 .419.419 0 01.13.32.438.438 0 01-.13.32l-6.22 6.3-.13.13a5 5 0 01-7.16 0v-.05z'
        fill={colors.iconDefault}
      />
    </Svg>
  )
}

type WavingHandIconProps = {
  size?: number
}

import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { PATHWAYS_TYPE } from '@lyrahealth-inc/shared-app-logic'

import { MobileAppPromoIllustration, MobileAppQRCode } from '../../../atoms/illustrations'
import { Subhead, Size as SubheadSize } from '../../../atoms/subhead/Subhead'
import { AppleAppStoreBadge, GooglePlayStoreBadge } from '../../../molecules'
import { ThemeType, tID } from '../../../utils'

export interface MicrositeMobileAppPromoSectionProps {
  pathwaysType?: PATHWAYS_TYPE
  language?: string
  backgroundColor?: string
}

const Container = styled.View<{ theme: ThemeType; backgroundColor?: string }>(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor || theme.colors.backgroundPrimary,
  flexDirection: theme.breakpoints.isMinWidthLaptop ? 'row' : 'column-reverse',
  padding: theme.breakpoints.isMinWidthLaptop
    ? '124px 120px'
    : theme.breakpoints.isMinWidthTablet
    ? '80px 64px'
    : '64px 24px',
  gap: theme.breakpoints.isMinWidthLaptop
    ? '80px'
    : theme.breakpoints.isMinWidthTablet
    ? theme.spacing['32px']
    : theme.spacing['24px'],
  alignItems: 'center',
  justifyContent: 'center',
}))

const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['16px'],
  alignItems: 'center',
  maxWidth: '672px',
  ...(theme.breakpoints.isMinWidthLaptop && {
    flex: 1,
  }),
}))

const BadgesContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing['16px'],
}))

export const MicrositeMobileAppPromoSection: FunctionComponent<MicrositeMobileAppPromoSectionProps> = ({
  pathwaysType,
  language,
  backgroundColor,
}) => {
  const {
    breakpoints: { isMinWidthTablet, isMinWidthLaptop },
  } = useTheme() as ThemeType

  const isComingSoon = pathwaysType === PATHWAYS_TYPE.COMING_SOON
  const APP_STORE_BADGE_HEIGHT = 40

  return (
    <Container
      testID={tID('MicrositeMobileAppPromoSection')}
      id='lyra-mobile-app-promo-section'
      backgroundColor={backgroundColor}
    >
      <ContentContainer>
        {!isComingSoon && isMinWidthLaptop && <MobileAppQRCode />}
        <Subhead
          text={
            isComingSoon ? (
              <FormattedMessage
                defaultMessage='The Lyra app can help you improve your well-being anytime, anywhere'
                description='Text to describe the benefits of the Lyra mobile app'
              />
            ) : (
              <FormattedMessage
                defaultMessage='Download the Lyra app to improve your well-being anytime, anywhere'
                description='Text to encourage the user to download the Lyra mobile app'
              />
            )
          }
          size={SubheadSize.LARGE}
          textAlign='center'
        />
        {!isComingSoon && (
          <BadgesContainer testID={tID('MicrositeMobileAppPromoSection-AppStoreBadges')}>
            <AppleAppStoreBadge height={APP_STORE_BADGE_HEIGHT} locale={language} />
            <GooglePlayStoreBadge height={APP_STORE_BADGE_HEIGHT} locale={language} />
          </BadgesContainer>
        )}
      </ContentContainer>
      <MobileAppPromoIllustration
        width={isMinWidthLaptop ? 448 : isMinWidthTablet ? 356 : 263}
        height={isMinWidthLaptop ? 440 : isMinWidthTablet ? 350 : 278}
      />
    </Container>
  )
}

import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  DISMISS_ELIGIBILITY_BANNER,
  ENABLE_ELIGIBILITY_BANNER_FROM_CONFIRMATION_FORM,
  GET_EAP_ELIGIBILITY,
  GET_EAP_ELIGIBILITY_PENDING,
} from './eligibilityActions'

const initialState = fromJS({
  dismissedEligibilityBanner: false,
  comingFromConfirmationForm: false,
})

export default createReducer(initialState, {
  [DISMISS_ELIGIBILITY_BANNER]: ($$state: $TSFixMe) => {
    return $$state.set('dismissedEligibilityBanner', true)
  },
  [GET_EAP_ELIGIBILITY]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('eligibilityStatus', {
      isLoading: false,
      data: action.payload,
    })
  },
  [GET_EAP_ELIGIBILITY_PENDING]: ($$state: $TSFixMe) => {
    return $$state.mergeDeep(
      fromJS({
        eligibilityStatus: {
          isLoading: true,
        },
      }),
    )
  },
  [ENABLE_ELIGIBILITY_BANNER_FROM_CONFIRMATION_FORM]: ($$state: $TSFixMe) => {
    return $$state.set('comingFromConfirmationForm', true)
  },
})

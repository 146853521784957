import { MessageDescriptor } from 'react-intl'

import { addMonths, differenceInYears, isBefore, parseISO } from 'date-fns'
import { cloneDeep, has, intersection, isNil, isNumber, omit, partition, pick, sum, uniq } from 'lodash-es'
import moment from 'moment-timezone'

import {
  AgeBucket,
  ageBuckets,
  allPrimaryNeedsWordCloudMappings,
  ContactUsFormValues,
  currentToNextMetadataNamesAll,
  currentToNextMetadataNamesDefault,
  PrimaryNeedsWords,
  SearcherForTriageSummary,
} from './constants'
import { ProgramNames, TriageScreenerNames, TriageScreener as TriageScreenerType } from '../../models'
import {
  PROVIDER_TYPES,
  ProviderCapacity,
  ProviderInfo,
  ProviderProgramTaxonomy,
  TreatmentModeEnums,
} from '../../models/providers/Providers'
import {
  ExcludedReason,
  GetWordCloudWordsActionData,
  SearcherType,
  SearchRequestData,
  TriageResults,
  TriageSearch,
  WordCloudCategory,
  WordCloudData,
  WordCloudWords,
} from '../../models/searchForCare/SearchForCare'
import { ContactPref, Customer, User } from '../../models/user/User'
import { UnsupportedTreatmentOptionsConfigurationsType } from '../common/constants/baseTreatmentOptionConstants'
import { PROGRAMS, ProgramType } from '../common/constants/customerProgram'
import { TREATMENT_OPTIONS, TREATMENT_OPTIONS_TYPE } from '../common/constants/treatmentOptions'
import { isAgeTeen, isAgeYoungerThanTeen } from '../common/utils/userUtils'
import { S4C_PROGRAM_OPTION_FLOW } from '../memberPreferences/constants'
import { getProviderSupportsInPerson, isBlendedCareProvider } from '../providers/utils'
import { TriageScreenerField } from '../searchForCare/constants'
import {
  PRIMARY_NEEDS_WORDS_ADULT_MSG,
  PRIMARY_NEEDS_WORDS_CHILD_MSG,
  TRIAGE_WORDS_MSG,
} from '../searchForCare/messages'

type AgeUtilArgs = {
  date: string
  ageArg?: number
  dateFormat?: string
}

export type UseNewSearchDataArgs = {
  dob?: string
  userId?: string
  gender?: string
  employer?: string
  type?: SearcherType
  childUserId?: string
}

export type WordCloudKeys = 'wordCloud1' | 'wordCloud2'

const DIRECT_ACCESS = 'DirectAccess'
const NUMBER_OF_MONTHS_UNTIL_SURVEY_CAN_BE_SHOWN_AGAIN = 3

export const getAge = ({ date, dateFormat = 'MM/DD/YYYY' }: AgeUtilArgs) => {
  const momentDate = moment(date, dateFormat)
  if (!momentDate.isValid()) {
    return NaN
  }

  const age = moment().diff(moment(momentDate), 'years')
  return age
}

export function getAgeISO(iso8601Date: string): number {
  return differenceInYears(new Date(), parseISO(iso8601Date))
}

export const getAgeBucket = ({ date, dateFormat = 'MM/DD/YYYY', ageArg }: AgeUtilArgs): AgeBucket | undefined => {
  let age: number
  if (ageArg) {
    age = ageArg
  } else {
    age = getAge({ date, dateFormat })
  }
  return ageBuckets.find((ageBucket) => age >= ageBucket.min && age <= ageBucket.max)
}

export function getAgeBucketISO(iso8601Date: string): AgeBucket | undefined {
  const age = getAgeISO(iso8601Date)
  return ageBuckets.find((ageBucket) => age >= ageBucket.min && age <= ageBucket.max)
}

export const getIsCareBeingSearchedForTeen = ({
  isOutsideOnboarding,
  isUserTeen,
  isSearcherTeen,
}: {
  isOutsideOnboarding: boolean
  isUserTeen: boolean
  isSearcherTeen: boolean
}) => {
  return isOutsideOnboarding ? isUserTeen : isSearcherTeen
}

export const getIsParentLedLctTeenBooking = ({
  isParentLedBookingEnabled,
  isLyraCareProvider,
  isChildSearch,
  isSearcherTeen,
}: {
  isParentLedBookingEnabled: boolean
  isLyraCareProvider: boolean
  isChildSearch: boolean // true if a guardian is searching for care for a child.
  isSearcherTeen: boolean // true if care is being searched for a teen. Can be true if the user is a teen searching for self or if a guardian is searching for care for a child who is a teen.
}) => {
  return isParentLedBookingEnabled && isLyraCareProvider && isChildSearch && isSearcherTeen
}

export const getNewSearchData = ({ dob, userId, gender, employer, type, childUserId }: UseNewSearchDataArgs) => {
  const age = dob ? getAge({ date: dob }) : null

  const yob = dob ? moment(dob, 'MM/DD/YYYY').year() : null
  if (!!userId && !isNil(age) && gender && employer && yob && type) {
    return { lyra_id: userId, body: { age, gender, employer, yob, type, child_lyra_id: childUserId } }
  } else {
    return {}
  }
}

export const shouldShowHighAlertModal = (results: TriageResults) => {
  return (
    results.search?.safety_alert_indicated ||
    results.search?.self_harm_indicated ||
    results.search?.suicidal_ideation_indicated ||
    results.treatmentRecommendations?.suicidalIdeationIndicated ||
    results.treatmentRecommendations?.selfHarmIndicated ||
    false
  )
}

export const isActiveSI = (results: TriageResults) => {
  return (
    results.search?.suicidal_ideation_indicated || results.treatmentRecommendations?.suicidalIdeationIndicated || false
  )
}

// Given a triage treatment option name, return the corresponding customer program.
// Note that we do not match treatment options to customer programs when the customer program is marked as "not a full
// treatment option". This means that the customer program is provided as part of another treatment option.
// An example of this is blended care therapy, which is provided as part of individual therapy. All blended care therapists
// are categorized under individual therapy, but not all therapists under individual therapy are part of blended care.
export const convertTriageTreatmentOptionToProgram = ({
  treatmentOptionName,
  allowPartialTreatmentOptions = false,
}: {
  treatmentOptionName: TREATMENT_OPTIONS_TYPE | null
  allowPartialTreatmentOptions?: boolean
}) => {
  const allPrograms = Object.values(PROGRAMS)
  return allPrograms.find((program: ProgramType) => {
    return (
      treatmentOptionName &&
      program.treatmentOptionProperties?.associatedTriageTreatmentOptions.includes(treatmentOptionName) &&
      (program.treatmentOptionProperties.isFullTreatmentOption || allowPartialTreatmentOptions)
    )
  })
}

/** Function that returns the BC program name associated with the selected treatment option.
 *
 *  Will return an empty string if the selected treatment option is not a blended care program or if it is
 *  not included in any associated triage treatment options.
 */
export const getBCProgramNameFromSelectedTreatmentOption = ({
  selectedTreatmentOption,
}: {
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}) => {
  let programName = ''
  for (const program in PROGRAMS) {
    const programTaxonomyOptions: ProviderProgramTaxonomy[] =
      PROGRAMS[program].treatmentOptionProperties?.programTaxonomyTreatmentOptions
    if (
      PROGRAMS[program].treatmentOptionProperties?.associatedTriageTreatmentOptions.includes(selectedTreatmentOption) &&
      isBlendedCareProvider({ programTaxonomy: programTaxonomyOptions })
    ) {
      programName = program
    }
  }
  return programName
}

export const getProgramNameFromSelectedTreatmentOptionForCapacity = ({
  selectedTreatmentOption,
}: {
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}) => {
  let programName = ''
  for (const program in PROGRAMS) {
    if (
      PROGRAMS[program].treatmentOptionProperties?.associatedTriageTreatmentOptions.includes(selectedTreatmentOption)
    ) {
      if (
        [ProgramNames.DIRECT_ACCESS_THERAPY, ProgramNames.ICASTherapy, ProgramNames.DIRECT_ACCESS_MEDS].includes(
          program as ProgramNames,
        )
      ) {
        return DIRECT_ACCESS
      }
      programName = program
    }
  }
  return programName
}

// Takes the categorized word cloud elements returned by the server and flattens it
// so we can send it along in searches to provide context for which words the user saw.
export const formatWordCloudForSearchContext = (wordCloud?: WordCloudData) => {
  if (wordCloud) {
    return wordCloud.reduce((acc: any, value: WordCloudCategory) => {
      const defaultMessages = value.word_cloud_elements.map((element) => element.defaultMessage)
      return acc.concat(defaultMessages)
    }, [])
  } else {
    return []
  }
}

export type CleanCompletedSearchForDuplicationArgs = {
  search: TriageSearch
  wordCloudWords: WordCloudWords | null
  getWordCloudWords: (data: GetWordCloudWordsActionData) => Promise<any>
  fetchedMetadataVersion?: string
} & Omit<GetWordCloudWordsActionData, 'triageScreenerName'>

// Takes a completed search and removes values so that we can use it as a request
// for a new POST to /search - effectively duplicating it
export const cleanCompletedSearchForDuplication = async ({
  search,
  wordCloudWords,
  getWordCloudWords,
  memberAge,
  primaryNeedsWord,
  country,
  lyraSocialCareEnabled,
  searcherType,
  fetchedMetadataVersion,
}: CleanCompletedSearchForDuplicationArgs) => {
  let context = {}
  const newSearchBody = omit(search.body, ['clinicalFactors', 'searchId'])
  const cleanedSearch = pick(search, ['triage_metadata', 'lyra_id', 'metadata_version']) as Dict
  newSearchBody.age = memberAge
  cleanedSearch.metadata_version = fetchedMetadataVersion

  // Add wordcloud context to search
  const wordCloudScreeners = [TriageScreenerNames.WORD_CLOUD_1, TriageScreenerNames.WORD_CLOUD_2]
  const wordCloudsNeedingContext = intersection(wordCloudScreeners, Object.keys(search.body.questionnaires || {}))
  const [wordCloudsInState, wordCloudsToFetch] = partition(
    wordCloudsNeedingContext,
    (screenerName) => wordCloudWords?.[screenerName],
  )

  // If word cloud date exists in state use that for the context
  if (wordCloudsInState.length && wordCloudWords) {
    context = wordCloudsInState.reduce(
      (acc, screenerName) => ({
        ...acc,
        [screenerName]: formatWordCloudForSearchContext(wordCloudWords[screenerName]),
      }),
      context,
    )
  }

  // If it's not in state then we need to fetch it from the server
  if (wordCloudsToFetch.length && memberAge && primaryNeedsWord) {
    const promises = wordCloudsToFetch.map((screenerName) =>
      getWordCloudWords({
        memberAge,
        searcherType,
        primaryNeedsWord,
        triageScreenerName: screenerName as TriageScreenerNames,
        country,
        lyraSocialCareEnabled,
      }),
    )
    try {
      const values = await Promise.all(promises)
      context = values.reduce(
        (acc, value) => ({
          ...acc,
          [value.screenerName || value.meta.arg.triageScreenerName]: formatWordCloudForSearchContext(
            value.response || value.payload,
          ),
        }),
        context,
      )
    } catch (error) {
      console.warn(error)
    }
  }
  return { ...cleanedSearch, body: newSearchBody, context }
}

export const getProgramConfigByProvider = (provider: ProviderInfo) =>
  PROGRAMS[PROVIDER_TYPES[provider?.lyra_type?.toUpperCase?.()]?.program]

export const isWordCloud2Supported = (searcherType?: SearcherType, primaryNeed?: PrimaryNeedsWords) =>
  searcherType &&
  primaryNeed &&
  TriageScreenerNames.WORD_CLOUD_2 in (allPrimaryNeedsWordCloudMappings[searcherType][primaryNeed] ?? {})

export const getNumberOfScreeners = (searcherType?: SearcherType, primaryNeed?: PrimaryNeedsWords) => {
  const selectedCurrentToNextMetadataNames = searcherType
    ? currentToNextMetadataNamesAll[searcherType]
    : currentToNextMetadataNamesDefault
  const screenersWithoutWordCloud2 = omit(selectedCurrentToNextMetadataNames, ['wordCloud2'])
  // E.g. Younger children and middle children do not have wordCloud2.
  const includesWordCloud2 = searcherType && primaryNeed ? isWordCloud2Supported(searcherType, primaryNeed) : true

  return includesWordCloud2
    ? Object.entries(selectedCurrentToNextMetadataNames).length
    : Object.entries(screenersWithoutWordCloud2).length
}

export const isMinor = (dob: string) => (dob ? moment().diff(moment(dob, 'MM/DD/YYYY'), 'years') < 18 : false)

export const isSearchingForTherapy = (treatmentOption: TREATMENT_OPTIONS_TYPE | null) => {
  return (
    treatmentOption === TREATMENT_OPTIONS.INDIVIDUAL_THERAPY ||
    treatmentOption === TREATMENT_OPTIONS.COUPLES_THERAPY ||
    treatmentOption === TREATMENT_OPTIONS.FAMILY_THERAPY
  )
}

export const generatePostChildConcernContactPayload = ({
  user,
  customer,
  formValues,
  childLyraId,
  searchId,
}: {
  user: User
  customer: Customer
  formValues: ContactUsFormValues
  childLyraId?: string
  searchId?: string
}) => ({
  firstname: user?.firstname,
  lastname: user?.lastname,
  note: formValues.textareanote,
  careManagerName: customer?.defaultCareManager?.firstname || '',
  careManagerEmail: customer?.defaultCareManager?.username || '',
  call: formValues.phoneNumber,
  email: formValues.emailAddress || '',
  customerName: customer?.name || 'care',
  childLyraId,
  searchId,
})

export const generateContactUsFormValuesFromUserInfo = ({
  userContactPreference,
  userEmail,
  userPhone,
  childSearchForCareMessage,
}: {
  userContactPreference?: string
  userEmail?: string
  userPhone?: string | null
  childSearchForCareMessage?: string
}) => {
  const userPrefersEmail =
    isNil(userContactPreference) ||
    userContactPreference === ContactPref.UNASSIGNED ||
    userContactPreference === ContactPref.EMAIL
  const contactCareInfo: ContactUsFormValues = {
    emailChecked: userPrefersEmail,
    phoneChecked: !userPrefersEmail,
    emailAddress: !isNil(userEmail) ? userEmail : '',
    // don't include phone number if it isn't needed
    phoneNumber: !isNil(userPhone) && !userPrefersEmail ? userPhone : '',
    textareanote: '\n\n' + childSearchForCareMessage + '\n',
  }
  return contactCareInfo
}

export const getUnsupportedTreatmentOptionsConfiguration = ({
  unsupportedTreatmentOptionsConfigurations,
  excludedTreatmentOptionsReasons,
  isUserInternational,
}: {
  unsupportedTreatmentOptionsConfigurations: UnsupportedTreatmentOptionsConfigurationsType
  excludedTreatmentOptionsReasons?: ExcludedReason[]
  isUserInternational: boolean
}) => {
  if (isUserInternational || !excludedTreatmentOptionsReasons) {
    return null
  }
  const nonClinicalExclusionReasons = [
    'customerDisallowsSelfCareApps',
    'customerDisallowsStressManagement',
    'customerDisallowsStressManagementLM',
    'customerDisallowsSingleSessionCoaching',
    'customerDisallowsBCTherapy',
    'customerDisallowsDATherapy',
    'customerDisallowsBCMeds',
    'customerDisallowsDAMeds',
    'userBCMedsEligible', // When both BC and DA meds are eligible option, we remove DA meds
    'coachesUnavailable',
    'minorTreatmentTypeReplacedWithAdultType',
    'icasProvidersUnavailable', // excludes individualTherapy for eligible international users
    'icasProvidersAvailable', // excludes individualTherapyICASPhone for eligible international users
    'memberOUSAndNonEnglishDisplayLanguage', // excludes Coaching if member OUS and non-English display language
  ]

  const isExcludedForNonClinicalReasons = (optionToCheck: $TSFixMe) => {
    const excludedOption = excludedTreatmentOptionsReasons.find(
      (option: $TSFixMe) => option.treatmentOption === optionToCheck,
    )
    if (excludedOption) {
      const allReasonsAreNonClinical = excludedOption.reasons.every((reason: $TSFixMe) =>
        nonClinicalExclusionReasons.includes(reason),
      )
      const isNotAllowedByCustomer = excludedOption.reasons.some((reason: $TSFixMe) =>
        reason.startsWith('customerDisallows'),
      )
      return allReasonsAreNonClinical && isNotAllowedByCustomer
    } else {
      return false
    }
  }

  const medsDAChildExcluded = isExcludedForNonClinicalReasons(TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS_CHILD)
  const medsDAExcluded = isExcludedForNonClinicalReasons(TREATMENT_OPTIONS.DIRECT_ACCESS_MEDS)
  const medsBCExcluded = isExcludedForNonClinicalReasons(TREATMENT_OPTIONS.BLENDED_CARE_MEDS)
  const individualTherapyChildExcluded = isExcludedForNonClinicalReasons(TREATMENT_OPTIONS.INDIVIDUAL_THERAPY_CHILD)
  const individualTherapyExcluded = isExcludedForNonClinicalReasons(TREATMENT_OPTIONS.INDIVIDUAL_THERAPY)

  if (medsDAChildExcluded && medsDAExcluded && individualTherapyChildExcluded && individualTherapyExcluded) {
    return unsupportedTreatmentOptionsConfigurations.combined
  } else if (medsDAChildExcluded && medsDAExcluded) {
    return unsupportedTreatmentOptionsConfigurations.standalone.meds
  } else if (medsDAChildExcluded) {
    return unsupportedTreatmentOptionsConfigurations.standalone.medsChild
  } else if (medsDAExcluded && medsBCExcluded && individualTherapyExcluded) {
    return unsupportedTreatmentOptionsConfigurations.combined
  } else if (medsDAExcluded && medsBCExcluded) {
    return unsupportedTreatmentOptionsConfigurations.standalone.meds
  } else {
    return null
  }
}

// if the Child Search - Direct to CN feature flag is enabled, and
// the search is for child, then
// replace the PRIMARY_NEEDS metadata with the PRIMARY_NEEDS_DIRECT_CN metadata
export const usePrimaryNeedsDirectCNMetadata = (
  shouldShowChildDirectPathToCareNavigator: boolean,
  triageMetadata: TriageScreenerType[],
) => {
  let newMetadata = cloneDeep(triageMetadata)
  if (shouldShowChildDirectPathToCareNavigator) {
    const primaryNeedsID = newMetadata.find((metadata) => metadata.name === TriageScreenerNames.PRIMARY_NEEDS)
      ?.id as string
    newMetadata = newMetadata
      .filter((metadata) => metadata.name !== TriageScreenerNames.PRIMARY_NEEDS)
      .map((metadata) =>
        metadata.name === TriageScreenerNames.PRIMARY_NEEDS_DIRECT_CN
          ? { ...metadata, name: TriageScreenerNames.PRIMARY_NEEDS, id: primaryNeedsID }
          : metadata,
      )
  }
  return newMetadata
}

/**
 * Internal function to check if a provider has capacity for any program.
 * Used in the direct booking case to ensure that providers are correctly shown as available.
 */
const checkProviderHasAnyCapacity = ({
  providerCapacity,
  isDirectBooking = false,
}: {
  providerCapacity?: ProviderCapacity
  isDirectBooking?: boolean
}) => {
  if (!isDirectBooking || !providerCapacity) {
    return false
  }

  return sum(Object.values(providerCapacity)) > 0
}

/**
 * Function to help check if a provider has capacity for a given treatment option.
 * Supports checking LCx and DA providers and handling legacy providers that do not have a providerCapacity object
 */
export const checkProviderCapacity = ({
  providerCapacity,
  selectedTreatmentOption,
  isDirectBooking,
}: {
  providerCapacity?: ProviderCapacity
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
  isDirectBooking?: boolean
}) => {
  const legacyProviderCapacity = providerCapacity === undefined
  const program = getProgramNameFromSelectedTreatmentOptionForCapacity({ selectedTreatmentOption })

  const hasCapacityForDirectBooking = checkProviderHasAnyCapacity({ providerCapacity, isDirectBooking })

  const hasCapacityForGivenProgram = providerCapacity?.[program] !== 0
  /**
   * Providers that do not have a providerCapacity object returned are legacy providers or bad
   * data providers that should not have been surfaced, but are being surfaced by Arbiter/PSS
   * These providers have a null value for providerCapacity in LyraTherapy UPS Database. On the front end we get undefined.
   * We should default these providers to always having capacity for backwards compatibility
   *
   * On Production we should always expect providerCapacity to be defined by provider results
   * and we should verify that the capacity value is non-zero for the program.
   * EDIT AS OF https://lyrahealth.atlassian.net/browse/LCDELIVERY-5018
   * We will allow booking/contacting of providers with LCT/DirectAccess as a program through direct link booking
   * even if the capacity is 0. Booking a provider with no capacity through direct link booking was a feature
   * that had existed previously and now needs to be manually accounted for with the introduction of capacity check on Lyra web (eng side).
   */
  const programsToCheck =
    has(providerCapacity, DIRECT_ACCESS) ||
    has(providerCapacity, ProgramNames.BlendedCareTherapy) ||
    has(providerCapacity, ProgramNames.AlcoholUseDisorderTherapy) ||
    has(providerCapacity, ProgramNames.Coaching) ||
    has(providerCapacity, ProgramNames.SingleSessionCoaching)

  return (
    legacyProviderCapacity ||
    hasCapacityForGivenProgram ||
    hasCapacityForDirectBooking ||
    (!hasCapacityForGivenProgram && isDirectBooking && programsToCheck)
  )
}

export const isShowAppointmentsDisabled = ({
  providerCapacity,
  selectedTreatmentOption,
  hasAvailability,
  hasConnectedCalendar,
  hasCalendarError,
  isDirectBooking,
  providerMissingForSearch,
}: {
  providerCapacity?: ProviderCapacity
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
  hasAvailability: boolean
  hasConnectedCalendar: boolean
  hasCalendarError?: boolean
  isDirectBooking?: boolean
  providerMissingForSearch?: boolean
}) => {
  const hasCapacity = checkProviderCapacity({
    providerCapacity,
    selectedTreatmentOption,
    isDirectBooking,
  })
  /**
   * A provider that does not have capacity or availability should not be allowed
   * to be booked.
   *
   * A provider with an error should not be allowed to be booked
   */
  return Boolean(
    (hasConnectedCalendar && (!hasCapacity || !hasAvailability || hasCalendarError)) || providerMissingForSearch,
  )
}

export const isContactForAvailabilityDisabled = ({
  providerCapacity,
  selectedTreatmentOption,
  isDirectBooking,
}: {
  providerCapacity?: ProviderCapacity
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
  isDirectBooking?: boolean
}) => {
  const hasCapacity = checkProviderCapacity({ providerCapacity, selectedTreatmentOption, isDirectBooking })
  return !hasCapacity && !isDirectBooking
}

export const getInPersonProvidersCount = ({ providers }: { providers: ProviderInfo[] }) => {
  const inPersonProviderCount = providers.reduce((providerCount, currentProvider) => {
    const supportsInPerson = getProviderSupportsInPerson({
      treatmentMode: TreatmentModeEnums[currentProvider.supportedVisitTypes],
    })

    return supportsInPerson ? providerCount + 1 : providerCount
  }, 0)

  return inPersonProviderCount
}

/**
 * Checks if the user is a teen searching for care between the ages of 13-17
 */
export const isSelfInitiatedTeenSearchingForCare = ({
  isSearchingForAdultTherapy,
  searcherDOB,
}: {
  isSearchingForAdultTherapy: boolean
  searcherDOB: string
}): boolean => {
  const age = getAge({ date: searcherDOB })
  return isSearchingForAdultTherapy && isAgeTeen(age)
}

/**
 * Returns current search for care flow of the user
 */
export const getSearchForCareProgramOptionFlow = ({
  isSearchingForAdultTherapy,
  isSearchingForCoaching,
  searcherDOB,
  isSearchingForChildTherapy,
  isParentInitiatedChildPreferencesEnabled = false,
  isLMSCoachingEligible,
}: {
  isSearchingForAdultTherapy: boolean
  isSearchingForCoaching: boolean
  searcherDOB: string
  isSearchingForChildTherapy: boolean
  isParentInitiatedChildPreferencesEnabled?: boolean
  isLMSCoachingEligible?: boolean
}): S4C_PROGRAM_OPTION_FLOW => {
  const isSelfInitiatedTeen = isSelfInitiatedTeenSearchingForCare({ isSearchingForAdultTherapy, searcherDOB })
  const isSeacherTeen = isAgeTeen(getAge({ date: searcherDOB }))
  if (isSearchingForChildTherapy && isSeacherTeen) {
    return S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_TEEN
  } else if (isSelfInitiatedTeen) {
    return S4C_PROGRAM_OPTION_FLOW.SELF_INITIATED_TEENS
  } else if (isSearchingForAdultTherapy) {
    return S4C_PROGRAM_OPTION_FLOW.THERAPY
  } else if (isLMSCoachingEligible && isSearchingForCoaching) {
    return S4C_PROGRAM_OPTION_FLOW.COACHING_WITH_LMS
  } else if (isSearchingForCoaching) {
    return S4C_PROGRAM_OPTION_FLOW.COACHING
  } else if (isParentInitiatedChildPreferencesEnabled && isSearchingForChildTherapy) {
    return S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_CHILD
  }

  return S4C_PROGRAM_OPTION_FLOW.OTHER
}

export const getPrimaryNeedAndWordCloudExperiences = (search?: TriageSearch) => {
  const questionnaires = search?.body?.questionnaires
  const result = []
  if (questionnaires) {
    if ((questionnaires as $TSFixMe)?.[TriageScreenerNames.PRIMARY_NEEDS]?.[TriageScreenerField.WHATS_ON_YOUR_MIND]) {
      result.push(
        (questionnaires as $TSFixMe)?.[TriageScreenerNames.PRIMARY_NEEDS]?.[TriageScreenerField.WHATS_ON_YOUR_MIND],
      )
    }
    if (
      (questionnaires as $TSFixMe)?.[TriageScreenerNames.WORD_CLOUD_1]?.[TriageScreenerField.WHAT_ARE_YOU_EXPERIENCING]
    ) {
      result.push(
        (questionnaires as $TSFixMe)?.[TriageScreenerNames.WORD_CLOUD_1]?.[
          TriageScreenerField.WHAT_ARE_YOU_EXPERIENCING
        ],
      )
    }
    if (
      (questionnaires as $TSFixMe)?.[TriageScreenerNames.WORD_CLOUD_2]?.[
        TriageScreenerField.WHAT_ARE_YOU_EXPERIENCING_2
      ]
    ) {
      result.push(
        (questionnaires as $TSFixMe)?.[TriageScreenerNames.WORD_CLOUD_2]?.[
          TriageScreenerField.WHAT_ARE_YOU_EXPERIENCING_2
        ],
      )
    }
  }
  return uniq(result.flat().sort())
}

export const convertTriageTermsToMessages = (strArr: string[]): Array<MessageDescriptor> => {
  return strArr.map(
    (word) => PRIMARY_NEEDS_WORDS_ADULT_MSG[word] || PRIMARY_NEEDS_WORDS_CHILD_MSG[word] || TRIAGE_WORDS_MSG[word],
  )
}

export const isChildSearch = (search: TriageSearch) => {
  return search.body?.childLyraId || search.body?.child_lyra_id
}

export const getTriageSummaryType = (age?: number, searcherType?: SearcherType): SearcherForTriageSummary => {
  if (isNumber(age) && !isNil(SearcherForTriageSummary)) {
    if (isAgeYoungerThanTeen(age)) {
      return SearcherForTriageSummary.CHILD
    } else if (isAgeTeen(age) && searcherType !== SearcherType.ADULT) {
      return SearcherForTriageSummary.TEEN
    } else {
      return SearcherForTriageSummary.ADULT
    }
  } else {
    return SearcherForTriageSummary.ADULT
  }
}

export const getSpeculativeSearchDefaultCoachingSearchParams = ({
  isInternationalUser,
  userCountryName,
  userDOB,
}: {
  isInternationalUser: boolean
  userCountryName?: string
  userDOB?: string
}) => {
  const defaultSearchParams: SearchRequestData = {
    isUserInternational: isInternationalUser,
    onsite: false,
    matchFor: 'self',
    locationCountry: userCountryName,
    treatmentNaive: TREATMENT_OPTIONS.COACHING,
    treatmentPreference: TREATMENT_OPTIONS.COACHING,
  }

  defaultSearchParams.age = getAge({ date: userDOB ?? '' })

  return defaultSearchParams
}

export const getShowProviderResultsSurveyUsingDateLastSeen = ({
  providerResultsSurveyDateLastSeen,
}: {
  providerResultsSurveyDateLastSeen?: string
}) => {
  let showSurvey = false

  if (providerResultsSurveyDateLastSeen) {
    const dateThreeMonthsAhead = addMonths(
      parseISO(providerResultsSurveyDateLastSeen),
      NUMBER_OF_MONTHS_UNTIL_SURVEY_CAN_BE_SHOWN_AGAIN,
    )
    if (isBefore(new Date(), dateThreeMonthsAhead)) {
      showSurvey = false
    } else {
      showSurvey = true
    }
  } else {
    showSurvey = true
  }

  return showSurvey
}

import React, { FunctionComponent } from 'react'
import Svg, { Path, Rect } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { ThemeType, tID } from '../../utils'

export const BookSessionsIcon: FunctionComponent<BookSessionsIconProps> = ({ size = '24', fillColor }) => {
  const { colors } = useTheme() as ThemeType
  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' testID={tID('CalendarAlternateIcon')}>
      <Rect x='3' y='4' width='18' height='18' rx='2' stroke={fillColor || colors.iconActive} strokeWidth='1.5' />
      <Path
        d='M16 2V6'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M8 2V6'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M3 10H21'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  )
}

type BookSessionsIconProps = {
  size?: string
  fillColor?: string
}

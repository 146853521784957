import { fromJS } from 'immutable'

import * as MicrositeActions from './micrositeActions'

const initialState = {
  isMicrositeBannerDismissed: false,
}

const microsite = (state = fromJS(initialState), action: $TSFixMe) => {
  switch (action.type) {
    case MicrositeActions.CLOSE_MICROSITE_BANNER:
      return state.set('isMicrositeBannerDismissed', true)
    default:
      return state
  }
}

export default microsite

import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'

export const RolesAndRelationshipsIllustration: FunctionComponent<{ size?: number }> = ({ size = 40 }) => {
  return (
    <Svg width={size} height={size} fill='none'>
      <G clipPath='url(#roles-and-relationships-illustration)'>
        <Path
          fill='#FFB199'
          d='M34.631 20.633c-3.357 5.38-11.352 12.935-14.126 13.362-1.31.199-9.98-5.897-14.209-12.498-2.364-3.683-3.337-7.545-.798-10.533 2.273-2.661 4.76-3.237 7.053-2.86 4.095.666 7.483 4.388 7.483 4.388s5.61-7.147 12.714-2.7c3.552 2.214 4.217 7.108 1.883 10.84Z'
        />
      </G>
      <Defs>
        <ClipPath id='roles-and-relationships-illustration'>
          <Path fill='#fff' d='M0 0h40v40H0z' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export const UPDATE_PLACES_LOCATION = 'UPDATE_PLACES_LOCATION'
export const updatePlacesLocation = (val: $TSFixMe) => ({
  type: UPDATE_PLACES_LOCATION,
  val,
})

export const UPDATE_PLACES_INPUT_TEXT = 'UPDATE_PLACES_INPUT_TEXT'
export const updatePlacesInputText = (val: $TSFixMe) => ({
  type: UPDATE_PLACES_INPUT_TEXT,
  val,
})

export const UPDATE_PLACES_STATE = 'UPDATE_PLACES_STATE'
export const updatePlacesState = (val: $TSFixMe) => ({
  type: UPDATE_PLACES_STATE,
  val,
})

export const UPDATE_PLACES_COORDINATES = 'UPDATE_PLACES_COORDINATES'
export const updatePlacesCoordinates = (val: $TSFixMe) => ({
  type: UPDATE_PLACES_COORDINATES,
  val,
})

export const RESET_PLACES_TYPEAHEAD = 'RESET_PLACES_TYPEAHEAD'
export const resetPlacesTypeahead = () => ({ type: RESET_PLACES_TYPEAHEAD })

import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

export interface OpenQuotationIconProps {
  width?: string | number
}

export const OpenQuotationIcon: FunctionComponent<OpenQuotationIconProps> = ({ width = 24 }) => {
  return (
    <Svg width={width} height={width} viewBox='0 0 24 24' fill='none'>
      <Path
        opacity='0.8'
        d='M6.756 19.9998C4.068 19.9998 2.5 17.5918 2.5 14.3998C2.5 9.02377 5.972 5.55177 8.884 3.75977L10.62 5.83177C8.66 7.51177 7.148 9.63977 7.036 11.7118C8.772 11.4878 10.844 12.8878 10.844 15.7438C10.844 18.3758 9.052 19.9998 6.756 19.9998ZM17.06 19.9998C14.372 19.9998 12.748 17.5918 12.748 14.3998C12.748 9.02377 16.276 5.55177 19.132 3.75977L20.868 5.83177C18.908 7.51177 17.396 9.63977 17.284 11.7118C19.02 11.4878 21.092 12.8878 21.092 15.7438C21.092 18.3758 19.3 19.9998 17.06 19.9998Z'
        fill='#777673'
      />
    </Svg>
  )
}

import React, { forwardRef, ReactChildren, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Pressable, ViewStyle } from 'react-native'
import { OverlayProvider, Tooltip as RNTooltip } from 'react-native-popper'

import Constants from 'expo-constants'
import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { IS_WEB } from '../../constants'
import { BodyTextSize } from '../../styles'
import { Flex1View } from '../../templates/content/CommonViews'
import { ThemeType, tID } from '../../utils'
import { isChromaticWeb } from '../../utils/utils'
import { BodyText } from '../bodyText/BodyText'
import { InfoIcon } from '../icons/InfoIcon'
import { QuestionMarkIcon } from '../icons/QuestionMarkIcon'
import { PressableOpacity } from '../pressableOpacity/PressableOpacity'

export interface TooltipProps {
  placement?: TooltipPlacement
  testID?: string
  animationExitDuration?: number
  content?: string | React.ReactNode
  contentStyle?: { container?: Dict }
  numberOfLines?: number
  children?: ReactChildren | any
  triggerConfig?: ToolTipTriggerConfig
  offset?: number
  crossOffset?: number
  backgroundColor?: string
  contentTextColor?: string
  contentTextSize?: BodyTextSize
  showArrow?: boolean
  style?: ViewStyle
  hoverEnabled?: boolean
  accessibilityLabel?: string
  onPress?: () => void
  wrapperStyle?: ViewStyle
}

type TooltipManager = {
  show: () => void
  hide: () => void
}

export const TooltipProvider = OverlayProvider

export type TooltipPlacement =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top left'
  | 'top right'
  | 'bottom left'
  | 'bottom right'

const Wrapper = styled(Flex1View)({
  flexDirection: 'row',
  alignItems: 'center',
})

const ContentContainer = styled(PressableOpacity)<{
  theme: ThemeType
  backgroundColor?: string
  styles?: { [key: string]: Dict }
}>(({ theme, backgroundColor, styles = {} }) => ({
  maxWidth: 300,
  zIndex: 1,
  paddingVertical: 4,
  paddingHorizontal: 8,
  borderRadius: 4,
  backgroundColor: backgroundColor || theme.colors.backgroundTooltip,
  ...(styles && { ...styles.container }),
}))

export enum TOOLTIP_TRIGGER_ICON {
  QUESTION_MARK = 'questionMark',
  INFO = 'info',
}

/**
 * The tooltip trigger defaults to the QuestionMarkIcon (filled).
 */
export interface ToolTipTriggerConfig {
  icon?: TOOLTIP_TRIGGER_ICON
  isFilled?: boolean
  fillColor?: string
  size?: number
}

export const Tooltip = forwardRef<TooltipManager, TooltipProps>(
  (
    {
      children,
      animationExitDuration = isChromaticWeb() ? 0 : 500,
      placement = 'bottom',
      testID,
      content,
      triggerConfig,
      numberOfLines,
      offset,
      crossOffset,
      hoverEnabled,
      contentStyle,
      backgroundColor,
      contentTextColor,
      contentTextSize = BodyTextSize.SMALL,
      showArrow = true,
      style,
      accessibilityLabel,
      onPress = () => {},
      wrapperStyle,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false)
    const { formatMessage } = useIntl()
    const { colors } = useTheme()

    const tooltipContentRef = useRef<any>(null)
    const isChildrenString = typeof children === 'string'
    const isFilled = triggerConfig?.isFilled ?? true
    const size = triggerConfig?.size || 16
    const fillColor = triggerConfig?.fillColor || colors.iconActive

    let trigger = <QuestionMarkIcon width={size} isFilled />
    if (!isChildrenString && children) {
      trigger = React.cloneElement(children as any)
    } else if (triggerConfig?.icon === TOOLTIP_TRIGGER_ICON.QUESTION_MARK) {
      trigger = <QuestionMarkIcon width={size} isFilled={isFilled} fillColor={fillColor} />
    } else if (triggerConfig?.icon === TOOLTIP_TRIGGER_ICON.INFO) {
      trigger = <InfoIcon size={size} fillColor={fillColor} />
    }

    // Check if the content prop is of type string or FormattedMessage to determine if we should render
    // the tooltip content as a BodyText component with font and text styling, or as a React Node child
    const isContentString = typeof content === 'string'
    const isContentFormattedMessage =
      content && !isContentString && (content as React.ReactElement).type === FormattedMessage

    /**
     * ⚠️ There is a bug with storybook-native rendering the view within a SafeAreaView
     * which messes up the detection of the tooltip. So we will offset the tooltip
     * by hardcoding it to your device. If you are testing tooltips with a device that has notches
     * that is not an iPhone 12 pro, make sure to offset the tooltip below
     * with the value that the top of the SafeArea inset and bottom of the storybook story
     * https://github.com/storybookjs/react-native/pull/345
     */
    const getOffset = () => {
      if (Constants?.expoConfig?.extra?.IS_STORYBOOK) {
        if (placement.includes('top')) {
          return -35
        }
        if (placement.includes('bottom')) {
          return -47
        }
      }
      return offset
    }

    /**
     * If user passes a ref to the Tooltip, the user
     * can control the tooltip open and closing by ref.current.show() / ref.current.hide()
     */
    useImperativeHandle(ref, () => ({
      show: () => setOpen(true),
      hide: () => setOpen(false),
    }))

    // This fixes a bug where a tooltip were not able to show on top of a Modal on Web.
    // By default The z-index of the parent element of the Tooltip content is `0`,
    // which causes it to be behind the modal. Replacing the z-index by 'auto' fixes the issue.
    useEffect(() => {
      if (open && IS_WEB) {
        const tooltipContentParent = tooltipContentRef?.current?.parentNode?.parentNode?.parentNode
        if (tooltipContentParent) {
          tooltipContentParent.style.zIndex = 'auto'
        }
      }
    }, [open])

    return (
      <>
        <Wrapper style={wrapperStyle}>
          <RNTooltip
            isOpen={open}
            on='press'
            mode='multiple'
            placement={placement}
            offset={getOffset()}
            crossOffset={crossOffset}
            animationExitDuration={animationExitDuration}
            trigger={
              <Pressable
                accessibilityLabel={
                  accessibilityLabel
                    ? accessibilityLabel
                    : formatMessage({
                        defaultMessage: 'Tooltip',
                        description: 'Tooltip for more information',
                      })
                }
                hitSlop={24}
                testID={tID(`Tooltip${testID ? `-${testID}` : ''}`)}
                onPress={
                  hoverEnabled
                    ? onPress
                    : () => {
                        setOpen(!open)
                        onPress()
                      }
                }
                onHoverIn={hoverEnabled ? () => setOpen(true) : noop}
                onHoverOut={hoverEnabled ? () => setOpen(false) : noop}
                onFocus={() => setOpen(true)}
                onBlur={() => setOpen(false)}
                style={style}
              >
                {trigger}
              </Pressable>
            }
          >
            {!hoverEnabled && <RNTooltip.Backdrop onPress={() => setOpen(false)} />}
            <RNTooltip.Content>
              {showArrow && (
                <RNTooltip.Arrow
                  height={10}
                  width={10}
                  style={{ backgroundColor: backgroundColor || colors.backgroundTooltip }}
                />
              )}
              <ContentContainer
                pressableRef={tooltipContentRef}
                styles={contentStyle}
                testID={tID(`Tooltip-content${testID ? `-${testID}` : ''}`)}
                backgroundColor={backgroundColor}
                onHoverIn={hoverEnabled ? () => setOpen(true) : noop}
                onHoverOut={hoverEnabled ? () => setOpen(false) : noop}
              >
                {isContentString || isContentFormattedMessage ? (
                  <BodyText
                    numberOfLines={numberOfLines}
                    text={content as string}
                    size={contentTextSize}
                    color={contentTextColor || colors.textInverse}
                  />
                ) : (
                  content
                )}
              </ContentContainer>
            </RNTooltip.Content>
          </RNTooltip>
        </Wrapper>
      </>
    )
  },
)

import get from 'lodash/get'
import { isEmpty } from 'lodash-es'

import { DEFAULT_CARE_LANGUAGE, MemberPreferencesForUserV2 } from '@lyrahealth-inc/shared-app-logic'

import { getCareLanguagesForCountry } from '../../../../common/http/data/onboard'

export const SET_AVAILABLE_CARE_LANGUAGES = 'SET_AVAILABLE_CARE_LANGUAGES'
export const UPDATE_SELECTED_CARE_LANGUAGE = 'UPDATE_SELECTED_CARE_LANGUAGE'
export const RESET_CARE_LANGUAGE_STATE = 'RESET_CARE_LANGUAGE_STATE'
export const SET_USER_MEMBER_PREFERENCES_V2 = 'SET_USER_MEMBER_PREFERENCES_V2'
export const SET_PARENT_INITIATED_IN_PERSON_S4C_PREFERENCE_CHECKED =
  'SET_PARENT_INITIATED_IN_PERSON_S4C_PREFERENCE_CHECKED'
export const SET_PARENT_INITIATED_IN_PERSON_TEEN_S4C_PREFERENCE_CHECKED =
  'SET_PARENT_INITIATED_IN_PERSON_TEEN_S4C_PREFERENCE_CHECKED'

export const fetchAvailableCareLanguages = (country: string) => {
  return async (dispatch: $TSFixMe) => {
    let data
    let responseData
    try {
      const response = await getCareLanguagesForCountry(country)
      data = get(response, 'data')
    } finally {
      responseData = isEmpty(data) ? [DEFAULT_CARE_LANGUAGE] : data
      // set english as the default care language if no care languages returned by backend
      return dispatch({
        type: SET_AVAILABLE_CARE_LANGUAGES,
        data: responseData,
      })
    }
  }
}

export const updateSelectedCareLanguage = (selectedCareLanguage: string) => ({
  type: UPDATE_SELECTED_CARE_LANGUAGE,
  data: selectedCareLanguage,
})

export const resetCareLanguageState = () => ({
  type: RESET_CARE_LANGUAGE_STATE,
})

export const setMemberPreferencesForUserV2 = (value: MemberPreferencesForUserV2) => {
  return {
    type: SET_USER_MEMBER_PREFERENCES_V2,
    value,
  }
}

export const setIsParentInitiatedInPersonS4CPreferenceChecked = (value: boolean) => {
  return {
    type: SET_PARENT_INITIATED_IN_PERSON_S4C_PREFERENCE_CHECKED,
    value,
  }
}

export const setIsParentInitiatedInPersonTeenS4CPreferenceChecked = (value: boolean) => {
  return {
    type: SET_PARENT_INITIATED_IN_PERSON_TEEN_S4C_PREFERENCE_CHECKED,
    value,
  }
}

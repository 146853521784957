import { noOpActionErrorHandler } from '../actionErrorHandler'
import { axiosInstance } from '../axiosInstance'

export const GET_UNIVERSAL_PROPERTIES = 'GET_UNIVERSAL_PROPERTIES'

export function getAttestationData(allParams: { customerId: string; token?: string; id?: string; isSSO: string }) {
  const { customerId, ...params } = allParams
  return axiosInstance.get(`unsecured/v1/customer/${customerId}/attestation`, { params })
}

export const getDependentTypesData = ({
  customerId,
  token,
  id,
  isSSO,
}: {
  customerId: string
  token: string
  id: string
  isSSO: string
}) => {
  return axiosInstance.get(`unsecured/v1/customer/${customerId}/dependentType?token=${token}&id=${id}&isSSO=${isSSO}`)
}

export const getUniversalCustomerCountryProperties = (countryId: { countryId: number }) => ({
  action: GET_UNIVERSAL_PROPERTIES,
  request: {
    method: 'get',
    url: `/unsecured/v1/customer/country/${countryId}/properties/universal`,
  },
  errorHandler: noOpActionErrorHandler,
})

export const getSSOAuthentication = () => ({
  request: {
    method: 'get',
    url: `/sso/initiate`,
  },
  errorHandler: noOpActionErrorHandler,
})

export const initiateSSOForLyraLearn = async () => {
  await axiosInstance.get('/sso/initiate')
}

import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { SET_IS_INACTIVE, SET_TIME_INACTIVITY_WARNING } from './authActions'

interface AuthState extends Map<string, any> {
  timeInactivityWarning: number
  timeLastActive: number
}

const initialState: AuthState = fromJS({
  timeInactivityWarning: null,
  timeLastActive: null,
})

export default createReducer(initialState, {
  [SET_TIME_INACTIVITY_WARNING]: ($$state: AuthState, action: { value: number }) => {
    return $$state.set('timeInactivityWarning', action.value)
  },
  [SET_IS_INACTIVE]: ($$state: AuthState, action: { value: boolean }) => {
    return $$state.set('timeLastActive', action.value)
  },
})

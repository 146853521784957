import { useState } from 'react'
import { useIntl } from 'react-intl'

import { noop } from 'lodash-es'

import { GENDER_IDENTITY } from '../../../models'
import { EAPEligibilityRequest, EligibilityStatus } from '../../../models/eligibility/Eligibility'
import { MinorSessionUsage } from '../../../models/healthPlan/HealthPlan'
import {
  CreateChildUser,
  GetNextQuestionActions,
  NewSearchData,
  SearcherInfo,
  TriageSearch,
} from '../../../models/searchForCare/SearchForCare'
import { User } from '../../../models/user/User'
import { ELIGIBILITY_TYPES } from '../../common/constants/constants'
import { LW_API_ERROR_CODE } from '../../common/constants/errorCodes'
import {
  childSearchMessages,
  invalidEligibilityDobMessage,
  invalidEligibilityFirstNameMessage,
  invalidEligibilityLastNameMessage,
} from '../../common/constants/messages'
import { COMMON_SEARCH_FOR_CARE_SCREENS, S4C_EVENTS, S4C_PROPERTIES } from '../../common/constants/mixpanelConstants'
import { convertDateStringFromISOToUSFormat } from '../../common/utils/dateUtils'
import { getUserAge } from '../../common/utils/userUtils'
import { createEligibilityCheckEventData } from '../../eligibility/utils'
import { getIsMinorEAPSessionExhausted } from '../../healthPlan/utils'
import { CHILD_INFO_FORM_FIELD_NAMES, ChildInfoFormValues } from '../constants'
import { getNewSearchData } from '../utils'

interface NavigationRoutes {
  triage: () => void
  childSelection: () => void
  exit: () => void
  back: () => void
  getHelp: () => void
  otherChildCareInfo: (dob: string) => (() => void) | null
  healthPlanInfo: () => void
  triageSummary: () => void
}

export interface useChildInformationArgs {
  navigationRoutes: NavigationRoutes
  user: User
  employer?: string
  checkChildDependentEligibilityUsingDependentInfo: boolean
  hasBlockingEligibilityCheck?: boolean
  setSearcherInfo: (firstName?: string, lastname?: string, dob?: string, childLyraId?: string) => SearcherInfo | null
  trackEvent?: (event: string, props?: Record<string, unknown>) => void
  eventProps?: any
  postTriageSearch: (arg: NewSearchData) => Promise<any>
  setSelectedSearch: (searchId: string) => void
  logException: (message: string, extras: any, error: unknown) => void
  createChildUser: (data: CreateChildUser) => any
  getNextTriageQuestion: (args: GetNextQuestionActions) => Promise<any>
  fetchEAPEligibility?: (args: EAPEligibilityRequest) => any
  fetchUserChildren: (lyraId: string) => void
  onEligibilityCheckFailure?: () => void
  isHealthPlanConfirmed: boolean
  hasHPIandEAPPrograms: boolean
  minorsSessionUsages: MinorSessionUsage[]
  numberOfSessions?: string
  customerName?: string
  isInternational?: boolean
  shouldShowTriageSummary?: boolean
}

export enum ChildInformationErrorBannerMessages {
  EXISTING_PROFILE = 'EXISTING_PROFILE',
  CREATE_CHILD_USER_FAILED = 'CREATE_CHILD_USER_FAILED',
  ELIGIBILITY_CHECK_FAILED = 'ELIGIBILITY_CHECK_FAILED',
  DEFAULT = 'DEFAULT',
}

/**
 * Returns all the props needed to render the treatment recommendation page
 */

export const useChildInformation = ({
  navigationRoutes,
  user,
  employer,
  checkChildDependentEligibilityUsingDependentInfo = false,
  hasBlockingEligibilityCheck = false,
  setSearcherInfo,
  trackEvent = noop,
  eventProps,
  createChildUser,
  setSelectedSearch,
  postTriageSearch,
  getNextTriageQuestion,
  logException,
  fetchEAPEligibility,
  fetchUserChildren,
  onEligibilityCheckFailure,
  isHealthPlanConfirmed,
  hasHPIandEAPPrograms,
  minorsSessionUsages,
  numberOfSessions,
  customerName,
  isInternational,
  shouldShowTriageSummary,
}: useChildInformationArgs) => {
  const { formatMessage } = useIntl()
  const [errorBannerMessage, setErrorBannerMessage] = useState<ChildInformationErrorBannerMessages | undefined>()
  const [eligibilityCheckCount, setEligibilityCheckCount] = useState(0)

  const onSubmit = async ({ values }: { values: ChildInfoFormValues }): Promise<any> => {
    trackEvent(S4C_EVENTS.CLICK_CONTINUE_FROM_ADD_A_CHILD_FORM, eventProps)

    const { childsGenderIdentity, childsFirstName, childsLastName, soughtCareInThePast } = values
    let { childsDateOfBirth } = values
    childsDateOfBirth = convertDateStringFromISOToUSFormat<never>(childsDateOfBirth)

    if (!user.id) {
      throw new Error('user.id is undefined')
    }

    const { id: customerCountryId } = user.customerCountries as any
    try {
      if (fetchEAPEligibility && checkChildDependentEligibilityUsingDependentInfo) {
        const attemptCount = eligibilityCheckCount + 1
        setEligibilityCheckCount(attemptCount)

        const childDataForEligibilityCheck: EAPEligibilityRequest = {
          customerName: employer,
          lyraId: user.id,
          eligibilityType: ELIGIBILITY_TYPES.DEPENDENT,
          firstname: childsFirstName,
          lastname: childsLastName,
          dob: childsDateOfBirth,
        }

        const eligibilityResponse = await fetchEAPEligibility(childDataForEligibilityCheck).unwrap()
        const eligibilityStatus = eligibilityResponse.eligibilityStatus
        const isEligible = eligibilityStatus === EligibilityStatus.ELIGIBLE

        const eventData = createEligibilityCheckEventData({
          page: COMMON_SEARCH_FOR_CARE_SCREENS.CHILD_INFORMATION,
          eligibilityStatus,
          hasBlockingEligibilityCheck,
          eligibilityType: ELIGIBILITY_TYPES.DEPENDENT,
          attemptCount,
        })
        trackEvent(eventData.event, eventData.props)

        if (!isEligible) {
          setErrorBannerMessage(ChildInformationErrorBannerMessages.ELIGIBILITY_CHECK_FAILED)
          onEligibilityCheckFailure?.()
          return {
            [CHILD_INFO_FORM_FIELD_NAMES.childsFirstName]: formatMessage(invalidEligibilityFirstNameMessage),
            [CHILD_INFO_FORM_FIELD_NAMES.childsLastName]: formatMessage(invalidEligibilityLastNameMessage),
            [CHILD_INFO_FORM_FIELD_NAMES.childsDateOfBirth]: formatMessage(invalidEligibilityDobMessage),
          }
        }
      }

      let childGender = childsGenderIdentity

      // ACCOUNT-2288 - Temporarily, we will be ommiting the gender value if transgender is selected
      if (childsGenderIdentity === GENDER_IDENTITY.TRANSGENDER) {
        childGender = GENDER_IDENTITY.PREFER_NOT_TO_SAY
      }

      const createChildUserRequest = {
        guardianLyraId: user.id,
        data: {
          firstname: childsFirstName,
          lastname: childsLastName,
          dob: childsDateOfBirth,
          gender: childGender,
          careHistory: soughtCareInThePast ? 'Yes' : 'No',
          customerCountryId: customerCountryId || -1,
        },
      }
      const createChildUserResponse = await createChildUser(createChildUserRequest)
      if (createChildUserResponse.meta?.requestStatus === 'rejected') {
        const axiosError = (createChildUserResponse.payload as any)?.data as {
          message?: string
          errorCode?: LW_API_ERROR_CODE
        }

        if (axiosError?.errorCode === 'USER_EXISTS') {
          setErrorBannerMessage(ChildInformationErrorBannerMessages.EXISTING_PROFILE)
          const duplicatedChildErrorMessage = formatMessage(childSearchMessages.duplicatedChildInputValidationMessage)
          return {
            [CHILD_INFO_FORM_FIELD_NAMES.childsFirstName]: duplicatedChildErrorMessage,
            [CHILD_INFO_FORM_FIELD_NAMES.childsLastName]: duplicatedChildErrorMessage,
            [CHILD_INFO_FORM_FIELD_NAMES.childsDateOfBirth]: duplicatedChildErrorMessage,
            [CHILD_INFO_FORM_FIELD_NAMES.childsGenderIdentity]: duplicatedChildErrorMessage,
          }
        } else {
          setErrorBannerMessage(ChildInformationErrorBannerMessages.CREATE_CHILD_USER_FAILED)
          return
        }
      }
      fetchUserChildren && fetchUserChildren(user?.id)
      const childLyraId = createChildUserResponse.payload.lyraId
      if (eventProps) {
        eventProps[S4C_PROPERTIES.CHILD_LYRA_ID] = childLyraId
        eventProps[S4C_PROPERTIES.GUARDIAN_AGE] = getUserAge(user)
      }
      trackEvent(S4C_EVENTS.CREATE_CHILD_ACCOUNT, eventProps)
      const otherChildCareInfoRoute = navigationRoutes.otherChildCareInfo(childsDateOfBirth)
      if (otherChildCareInfoRoute) {
        return otherChildCareInfoRoute()
      }

      const isEAPSessionsExhausted = getIsMinorEAPSessionExhausted(minorsSessionUsages, childLyraId, numberOfSessions)

      const searcherInfo = setSearcherInfo(childsFirstName, childsLastName, childsDateOfBirth, childLyraId)
      const newSearchData = getNewSearchData({
        dob: childsDateOfBirth,
        userId: user.id,
        gender: childsGenderIdentity,
        employer,
        type: searcherInfo?.type,
        childUserId: childLyraId,
      })
      const searchResponse = await postTriageSearch(newSearchData)
      const newSearch = searchResponse.payload || (searchResponse as TriageSearch)
      setSelectedSearch(newSearch.id)
      getNextTriageQuestion({ id: newSearch.id, hideError: [404] })

      if (!isHealthPlanConfirmed && hasHPIandEAPPrograms && isEAPSessionsExhausted) {
        navigationRoutes.healthPlanInfo()
      } else if (shouldShowTriageSummary) {
        navigationRoutes.triageSummary()
      } else {
        navigationRoutes.triage()
      }
    } catch (error) {
      setErrorBannerMessage(ChildInformationErrorBannerMessages.DEFAULT)
      logException('Unable to submit child information', { source: 'ChildInformationScreen.tsx' }, error)
    }
  }

  const handleExitPress = () => {
    navigationRoutes.exit()
  }

  const handleBackPress = () => {
    navigationRoutes.back()
  }

  const handleSecondaryButtonPress = () => {
    navigationRoutes.getHelp()
  }

  return {
    onSubmit,
    onBackPress: handleBackPress,
    onExitPress: handleExitPress,
    loading: false,
    inlineErrorBannerMessage: errorBannerMessage,
    onFindProfilePress: navigationRoutes.childSelection,
    onSecondaryButtonPress: handleSecondaryButtonPress,
    customerName,
    isInternational,
  }
}

import { defineMessage, defineMessages, IntlShape } from 'react-intl'

import { DEPENDENT_VARIANT } from '../common/constants/constants'

export const childEmailRegisteredErrorMessage = defineMessage({
  defaultMessage:
    'This email is already associated with a Lyra account. Please try again or contact a Care Navigator for support.',
  description: 'Error message when the email has already been used to register a Lyra account and cannot be used again',
})

export const emailBelongsToSelfErrorMessage = defineMessage({
  defaultMessage: 'Email can’t be the same as your own. Please try again.',
  description: 'Error message when the email entered already belongs to current user and cannot be used again',
})

const BOOKING_CONFIRMATION_PRIMARY_BUTTON_TEXT = defineMessages({
  GET_READY: {
    defaultMessage: 'Get ready for session',
    description: 'Button text. Clicking will continue to care screen',
  },
  CONTINUE: {
    defaultMessage: 'Continue',
    description: 'Button text. Clicking will dimiss/move to next screen',
  },
  ACKNOWLEDGE: {
    defaultMessage: 'Got it',
    description: 'Button text. Clicking will acknowledge the booking confirmation',
  },
})

export function getBookingConfirmationPrimaryButtonText(
  isConnectedCalendar: boolean,
  bookedForUser: boolean,
  isCNTBooking: boolean,
) {
  if (bookedForUser) {
    return BOOKING_CONFIRMATION_PRIMARY_BUTTON_TEXT.ACKNOWLEDGE
  } else if (isConnectedCalendar && !isCNTBooking) {
    return BOOKING_CONFIRMATION_PRIMARY_BUTTON_TEXT.GET_READY
  } else {
    return BOOKING_CONFIRMATION_PRIMARY_BUTTON_TEXT.CONTINUE
  }
}

export function getBookingConfirmationSessionDetailsReminder({
  formatMessage,
  firstName,
  email,
}: {
  formatMessage: IntlShape['formatMessage']
  firstName?: string | undefined
  email?: string | undefined
}): string {
  return !!firstName && !!email
    ? formatMessage(
        {
          defaultMessage: `We also sent an email to {firstName} at {email} to create an account in order to attend session.`,
          description: 'Notice that account creation email was sent to person who the session was booked for',
        },
        { firstName, email },
      )
    : formatMessage({
        defaultMessage: `We'll send you a reminder, but be sure to write down or save your session details.`,
        description: 'Reminder to note down appointment information',
      })
}

export function getSelfManagedDependentConfirmationText({
  formatMessage,
  email,
  variant,
}: {
  formatMessage: IntlShape['formatMessage']
  email?: string | undefined
  variant?: DEPENDENT_VARIANT
}): string {
  if (variant === DEPENDENT_VARIANT.SELF_MANAGED_TEEN) {
    return formatMessage(
      {
        defaultMessage: `We emailed {email} with a link to create their own account. Their care is enabled by your health plan and payment information as your dependent—you can manage this information in your account settings.`,
        description: 'Notice that account creation email was sent to self managed their teen.',
      },
      { email },
    )
  } else if (variant === DEPENDENT_VARIANT.ADULT) {
    return formatMessage(
      {
        defaultMessage: `We emailed {email} with a link to create their own account.`,
        description: 'Notice that account creation email was sent to their adult dependent.',
      },
      { email },
    )
  } else {
    return ''
  }
}

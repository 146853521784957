import React from 'react'

import permissionsIllustrationUrl from '../../assets/permissions.png'
import { Image } from '../image/Image'

interface PermissionsIllustrationProps {
  width?: number
  height?: number
}

export function PermissionsIllustration({ width = 130, height = 100, ...props }: PermissionsIllustrationProps) {
  return (
    <Image
      source={permissionsIllustrationUrl}
      style={{ width, height }}
      contentFit='contain'
      {...props}
      accessibilityIgnoresInvertColors
    />
  )
}

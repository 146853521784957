import { defineMessage, MessageDescriptor } from 'react-intl'

import { DEFAULT_QUESTIONS } from './faqConstants'

export type UnsupportedTreatmentOptionConfigContent = {
  title: MessageDescriptor
  description: MessageDescriptor
  faqQuestions: Array<{ question: string }>
}
export interface UnsupportedTreatmentOptionsConfigurationsType {
  standalone: {
    meds: UnsupportedTreatmentOptionConfigContent
    medsChild: UnsupportedTreatmentOptionConfigContent
  }
  combined: UnsupportedTreatmentOptionConfigContent
}

export const unsupportedTreatmentOptionsConfigurations = {
  standalone: {
    meds: {
      title: defineMessage({ defaultMessage: 'Medication', description: 'Title for an unsupported treatment option' }),
      description: defineMessage({
        defaultMessage:
          'In some cases, medication can be an effective strategy for managing certain symptoms. While medication is not covered with your Lyra benefit, it may be available through your health plan.',
        description: 'Description for unsupported medication treatment option',
      }),
      faqQuestions: [
        {
          question: DEFAULT_QUESTIONS.WHAT_DOES_LYRA_NOT_COVER,
        },
      ],
    },
    medsChild: {
      title: defineMessage({ defaultMessage: 'Medication', description: 'Title for an unsupported treatment option' }),
      description: defineMessage({
        defaultMessage:
          'In some cases, medication can be an effective strategy for managing certain symptoms. While medication is not covered with your child’s Lyra benefit, it may be available through their health plan.',
        description: 'Description for unsupported medication treatment option',
      }),
      faqQuestions: [
        {
          question: DEFAULT_QUESTIONS.WHAT_DOES_LYRA_NOT_COVER,
        },
      ],
    },
  },
  combined: {
    title: defineMessage({
      defaultMessage: 'Therapy or medication management',
      description: 'Title for an unsupported treatment option',
    }),
    description: defineMessage({
      defaultMessage:
        'You are not eligible for therapy and medication management through Lyra, but may have options through your health plan. Please check directly with your health plan for more information about your options.',
      description: 'Text for unsupported treatment option',
    }),
    faqQuestions: [
      {
        question: DEFAULT_QUESTIONS.WHAT_DOES_LYRA_NOT_COVER,
      },
    ],
  },
}

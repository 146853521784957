import { createAsyncThunk } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import { OnboardingData, SelfManagedDependentRegistrationData, User } from '@lyrahealth-inc/shared-app-logic'

import * as BannerActions from '../../../common/components/banner/banner-actions'
import {
  activateMFAData,
  disableMFAData,
  enrollMFAData,
  onboard,
  registerSelfManagedDependent,
  submitEditProfileData,
  updateCountryData,
  updateDisplayLanguageData,
  updateTimeZoneData,
} from '../../../common/http/data/editProfile'
import { AppDispatch } from '../../../data/storeConfiguration/store'
import { setUserInfo, TOGGLE_OFF_MFA, TOGGLE_ON_MFA } from '../../../data/user/userActions'
import { getId } from '../../../data/user/userSelectors'

function handleError(error: BannerActions.LWAPIError, dispatch: AppDispatch) {
  dispatch(BannerActions.setBannerAndErrorMessage(error, true))
}

export const enrollMFA = (lyraId: string) => {
  return (dispatch: Dispatch) => {
    return new Promise(function (resolve, reject) {
      enrollMFAData(lyraId).then(
        (data) => {
          return resolve(data)
        },
        (error) => {
          handleError(error, dispatch)
          reject(error)
        },
      )
    })
  }
}

export const verifyMFA = (data: { passCode: string; lyraId: string }) => {
  return (dispatch: AppDispatch) => {
    return new Promise(function (resolve, reject) {
      activateMFAData(data.lyraId, data.passCode).then(
        (data) => {
          dispatch({ type: TOGGLE_ON_MFA })
          return resolve(data)
        },
        (error) => {
          reject(error)
        },
      )
    })
  }
}

export const disableMFA = (lyraId: string) => {
  return (dispatch: AppDispatch) => {
    return new Promise(function (resolve, reject) {
      disableMFAData(lyraId).then(
        (data) => {
          dispatch({ type: TOGGLE_OFF_MFA })
          resolve(data)
        },
        (error) => {
          handleError(error, dispatch)
          reject(error)
        },
      )
    })
  }
}

export const updateUserProfile = ({ lyraId, data }: { lyraId: string; data: Partial<User> }) => {
  return (dispatch: AppDispatch) => {
    return submitEditProfileData(lyraId, data).then(
      (success) => {
        dispatch(setUserInfo({ user: data }))
        return Promise.resolve(success)
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  }
}

export interface UpdateCountryRequest {
  customerCountryId: number
}

export const updateCountry = createAsyncThunk<User, UpdateCountryRequest>(
  'UPDATE_USER_COUNTRY',
  async (data: UpdateCountryRequest, { getState, rejectWithValue }) => {
    const state: any = getState()
    const lyraId = getId(state)

    try {
      const response = await updateCountryData(lyraId, data.customerCountryId)
      const updatedUser: User = response.data
      return updatedUser
    } catch (error) {
      console.error(error)
      return rejectWithValue(error)
    }
  },
)

export const UPDATE_USER_COUNTRY = updateCountry.fulfilled.type

export interface UpdateTimezoneRequest {
  timezone: string
}

export const updateTimeZone = createAsyncThunk<User, UpdateTimezoneRequest>(
  'UPDATE_USER_TIMEZONE',
  async (data: UpdateTimezoneRequest, { getState, rejectWithValue }) => {
    const state: any = getState()
    const lyraId = getId(state)

    try {
      const response = await updateTimeZoneData(lyraId, data.timezone)
      const updatedUser: User = response.data
      return updatedUser
    } catch (error) {
      console.error(error)
      return rejectWithValue(error)
    }
  },
)

export const UPDATE_USER_TIMEZONE = updateTimeZone.fulfilled.type

export interface UpdateDisplayLanguageRequest {
  displayLanguage: string
}

export const updateDisplayLanguage = createAsyncThunk<User, UpdateDisplayLanguageRequest>(
  'UPDATE_USER_DISPLAY_LANGUAGE',
  async (data: UpdateDisplayLanguageRequest, { getState, rejectWithValue }) => {
    const state: any = getState()
    const lyraId = getId(state)

    try {
      const response = await updateDisplayLanguageData(lyraId, data.displayLanguage)
      const updatedUser: User = response.data.user
      return updatedUser
    } catch (error) {
      console.error(error)
      return rejectWithValue(error)
    }
  },
)

export const UPDATE_USER_DISPLAY_LANGUAGE = updateDisplayLanguage.fulfilled.type

export const onboardUser = createAsyncThunk<User, OnboardingData>(
  'ONBOARD_USER',
  async (data: OnboardingData, { getState, rejectWithValue }) => {
    const state: any = getState()
    const lyraId = getId(state)

    try {
      const response = await onboard(lyraId, data)
      const updatedUser: User = response.data
      return updatedUser
    } catch (error) {
      console.error(error)
      return rejectWithValue(error)
    }
  },
)

export const registerSelfManagedDependentUser = createAsyncThunk<User, SelfManagedDependentRegistrationData>(
  'REGISTER_SELF_MANAGED_DEPENDENT_USER',
  async (data: SelfManagedDependentRegistrationData, { getState, rejectWithValue }) => {
    const state: any = getState()
    const lyraId = getId(state)

    try {
      const response = await registerSelfManagedDependent(lyraId, data)
      const pendingUser: User = response.data
      return pendingUser
    } catch (error) {
      console.error(error)
      return rejectWithValue(error)
    }
  },
)

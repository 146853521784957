import React, { FunctionComponent, useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { FooterPhoneIcon } from '../../atoms/icons/FooterPhoneIcon'
import { PhoneCallIllustrationIcon } from '../../atoms/illustrations/PhoneCallIllustrationIcon'
import { AppContext } from '../../context'
import { CareOptionCard } from '../../templates/careOptionCard/CareOptionCard'

export interface CareNavigatorPhoneCallOptionCardProps {
  customerPhoneNumber?: string
  isInternational?: boolean
  hideCustomerPhoneNumber?: boolean
  onPress: (() => void) | undefined
}

export const CareNavigatorPhoneCallOptionCard: FunctionComponent<CareNavigatorPhoneCallOptionCardProps> = ({
  customerPhoneNumber,
  isInternational = false,
  hideCustomerPhoneNumber = false,
  onPress,
}) => {
  const { isDedicatedCareNavigatorEnabled } = useContext(AppContext)
  const { colors } = useTheme()

  let optionHeader = null
  if (!hideCustomerPhoneNumber && customerPhoneNumber) {
    optionHeader = (
      <FormattedMessage
        defaultMessage='Call {phone}'
        description='Text description shown to user to call the phone number provided when they need to contact Lyra Health'
        values={{
          phone: customerPhoneNumber,
        }}
      />
    )
  } else {
    optionHeader = (
      <FormattedMessage
        defaultMessage='Call us'
        description='Text description shown to user to call the phone number provided when they need to contact Lyra Health'
      />
    )
  }

  let optionDescription = null
  if (isInternational) {
    optionDescription = (
      <FormattedMessage
        defaultMessage='We’re available 24/7 for general information or immediate support.'
        description='Text description explaining that the user may call the phone number at any time and any day for support right now'
      />
    )
  } else {
    optionDescription = (
      <FormattedMessage
        defaultMessage='Reach us 24/7 for general support.'
        description='Text description explaining that the user may call the phone number at any time and any day for help and support right now'
      />
    )
  }

  return (
    <CareOptionCard
      onPress={onPress}
      optionIcon={
        isDedicatedCareNavigatorEnabled ? (
          <FooterPhoneIcon fillColor={colors.textSecondary} />
        ) : (
          <PhoneCallIllustrationIcon />
        )
      }
      optionHeader={optionHeader}
      optionDescription={optionDescription}
      testId={'CareNavigatorPhonecallOptionCard'}
    />
  )
}

import React, { FunctionComponent, ReactElement, ReactNode } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { FlatList, ImageSourcePropType, View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { CustomerPropertiesMap, PATHWAYS_TYPE, ProgramCustomerPropertyId } from '@lyrahealth-inc/shared-app-logic'

import {
  ContentLibraryImage,
  ContentLibraryImageLargeDefault,
  MeetWithAnExpertImage,
  MeetWithAnExpertImageLargeDefault,
} from '../../../assets/microsite'
import {
  BodyText,
  CoachingSpotIllustration,
  CouplesAndFamilyTherapySpotIllustration,
  EmployerBenefitsSpotIllustrationWhiteBorder,
  IndividualTherapySpotIllustrationWhiteBorder,
  MedicationManagementSpotIllustration,
  OutPatientSupportSpotIllustrationWhiteBorder,
  ParentCoachingSpotIllustration,
  PrimaryButton,
  SelfCareEssentialsSpotIllustrationWhiteBorder,
  Subhead,
  WorkplaceWellbeingSpotIllustrationWhiteBorder,
} from '../../../atoms'
import { Image } from '../../../atoms/image/Image'
import { InfoCarousel } from '../../../molecules'
import { SubheadSize } from '../../../styles'
import { ThemeType } from '../../../utils'
import { tID } from '../../../utils/utils'

export interface LyraOfferingsCarouselProps {
  testID?: string
  customerPropertiesMap?: CustomerPropertiesMap
  pathwaysType?: PATHWAYS_TYPE
  isInternational?: boolean
  hideMeetWithExpert?: boolean
  hideWellnessResources?: boolean
  hideAllOfferings?: boolean
  meetWithAnExpertCtaPress?: () => void
  wellnessResourcesCtaPress?: () => void
  lyraOfferingsCtaPress?: () => void
  meetWithAnExpertTabPress?: () => void
  wellnessResourcesTabPress?: () => void
  lyraOfferingsTabPress?: () => void
  backgroundColor?: string
}

export enum LyraOfferingsOptions {
  '1_TO_1_SESSIONS_THERAPIST' = '1_TO_1_SESSIONS_THERAPIST',
  '1_TO_1_SESSIONS_COACH' = '1_TO_1_SESSIONS_COACH',
  WELLNESS_RESOURCES = 'WELLNESS_RESOURCES',
  FAMILY_AND_COUPLES_THERAPY = 'FAMILY_AND_COUPLES_THERAPY',
  CARE_FOR_COMPLEX_NEEDS = 'CARE_FOR_COMPLEX_NEEDS',
  OTHER_EMPLOYER_SPONSORED_BENEFITS = 'OTHER_EMPLOYER_SPONSORED_BENEFITS',
  MEDICATION = 'MEDICATION',
  MEDICATION_MANAGEMENT = 'MEDICATION_MANAGEMENT',
  PARENTING_SUPPORT = 'PARENTING_SUPPORT',
  CAREER_SUPPORT = 'CAREER_SUPPORT',
  CAREER_WELL_BEING = 'CAREER_WELL_BEING',
}

export const lyraOfferingsOptionTitles = defineMessages({
  [LyraOfferingsOptions['1_TO_1_SESSIONS_THERAPIST']]: {
    defaultMessage: '1:1 sessions with a therapist',
    description: 'Label indicating Lyra’s 1 on 1 sessions with therapists offering',
  },
  [LyraOfferingsOptions['1_TO_1_SESSIONS_COACH']]: {
    defaultMessage: '1:1 sessions with a coach',
    description: 'Label indicating Lyra’s 1 on 1 sessions with mental health coaches offering',
  },
  [LyraOfferingsOptions.WELLNESS_RESOURCES]: {
    defaultMessage: 'Wellness resources',
    description: 'Label indicating Lyra’s wellness resources offering',
  },
  [LyraOfferingsOptions.FAMILY_AND_COUPLES_THERAPY]: {
    defaultMessage: 'Family and couples therapy',
    description: 'Label indicating Lyra’s family and couples therapy offering',
  },
  [LyraOfferingsOptions.CARE_FOR_COMPLEX_NEEDS]: {
    defaultMessage: 'Care for complex needs',
    description: 'Label indicating Lyra’s offering for complex needs care',
  },
  [LyraOfferingsOptions.OTHER_EMPLOYER_SPONSORED_BENEFITS]: {
    defaultMessage: 'Other employer-sponsored benefits',
    description: 'Label indicating Lyra’s employer sponsored benefits offering',
  },
  [LyraOfferingsOptions.MEDICATION]: {
    defaultMessage: 'Medication',
    description: 'Label indicating Lyra’s medication offering',
  },
  [LyraOfferingsOptions.MEDICATION_MANAGEMENT]: {
    defaultMessage: 'Medication management',
    description: 'Label indicating Lyra’s medication management offering',
  },
  [LyraOfferingsOptions.PARENTING_SUPPORT]: {
    defaultMessage: 'Parenting support',
    description: 'Label indicating Lyra’s parenting support offering',
  },
  [LyraOfferingsOptions.CAREER_SUPPORT]: {
    defaultMessage: 'Career support',
    description: 'Label indicating Lyra’s career support offering',
  },
  [LyraOfferingsOptions.CAREER_WELL_BEING]: {
    defaultMessage: 'Career well-being',
    description: 'Label indicating Lyra’s career well-being offering',
  },
})

export const lyraOfferingMenuTitles = defineMessages({
  '1_TO_1_SESSIONS': {
    defaultMessage: '1:1 SESSIONS',
    description: 'Shortened menu title for section that gives more info about 1 on 1 sessions with our experts',
  },
  MEET_WITH_AN_EXPERT: {
    defaultMessage: 'MEET WITH AN EXPERT',
    description: 'Menu title for section that gives more info about 1 on 1 sessions with our experts',
  },
  WELLNESS_RESOURCES: {
    defaultMessage: 'WELLNESS RESOURCES',
    description: 'Menu title for section that gives more info about the wellness resources Lyra offers',
  },
  LIBRARY: {
    defaultMessage: 'LIBRARY',
    description: 'Shortened menu title for section that gives more info about 1 on 1 sessions with our experts',
  },
  ALL_OFFERINGS: {
    defaultMessage: 'ALL OFFERINGS',
    description: 'Menu title for section that gives more info of all other offerings provided by Lyra',
  },
})

export const lyraOfferingSectionHeaders = defineMessages({
  MEET_WITH_AN_EXERT_HEADER: {
    defaultMessage: '1:1 sessions with a mental health expert',
    description:
      'Heading for content that gives more info about Lyra’s 1 on 1 sessions with mental health experts offering',
  },
  WELLNESS_RESOURCES_HEADER: {
    defaultMessage: 'Browse our on demand wellness resources',
    description: 'Heading for content that gives more info about Lyra’s wellness resources',
  },
})

export const lyraOfferingSectionButtonCtaText = defineMessages({
  SINGLE_PATHWAY_DEFAULT: {
    defaultMessage: 'Sign up',
    description: 'Button text that will take user to create account page',
  },
  MULTI_PATHWAY_MEET_WITH_AN_EXPERT_SECTION: {
    defaultMessage: 'Book appointment',
    description: 'Button text that indicates clicking this button will start process of booking appointment',
  },
  MULTI_PATHWAY_RESOURCES_SECTION: {
    defaultMessage: 'Access resources',
    description: 'Button text that indicates that user will be taken to the wellness resources',
  },
  MULTI_PATHWAY_ALL_OFFERINGS_SECTION: {
    defaultMessage: 'Sign up',
    description: 'Button text that will take user to create account page',
  },
})

export const lyraOfferingSectionBodyText = defineMessages({
  MEET_WITH_AN_EXPERT_DEFAULT: {
    defaultMessage:
      'Our highly-trained experts come from a variety of backgrounds and use evidence-based techniques. Tell us a bit about yourself, and we’ll match you to providers who can help. Sessions can be in-person or via video at a time that works for you.',
    description:
      'A brief description of the kinds of experts we offer, the type techniques they use for care and the modalities for sessions they offer.',
  },
  WELLNESS_RESOURCES: {
    defaultMessage:
      'Build skills that support mental and emotional well-being through meditations, live events, videos, and more. Dive into topics like parenting, career, and improving sleep.',
    description: 'A brief description of the types content Lyra offers.',
  },
})

const CarouselContainer = styled.View<{ theme: ThemeType; backgroundColor?: string }>(({ theme, backgroundColor }) => ({
  padding: theme.breakpoints.isMobileSized
    ? `${theme.spacing['48px']} ${theme.spacing['24px']}`
    : `124px ${theme.breakpoints.isMinWidthDesktopL ? 124 : 62}px`,
  backgroundColor: backgroundColor || theme.colors.backgroundSecondary,
}))

const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: theme.breakpoints.isMinWidthLaptop ? 'row' : 'column',
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['32px'],
  alignItems: 'center',
  ...(theme.breakpoints.isMobileSized && { width: '100%', alignItems: 'center' }),
}))

const InfoSectionContainer = styled.View<{ theme: ThemeType; imagePosition?: string; isCentered: boolean }>(
  ({ theme, imagePosition, isCentered }) => ({
    justifyContent: 'center',
    ...((!theme.breakpoints.isMinWidthLaptop || isCentered) && {
      width: '100%',
      alignItems: 'center',
    }),
    ...(imagePosition && {
      marginTop: imagePosition === 'top' ? theme.spacing['24px'] : '0px',
      marginLeft: imagePosition === 'left' ? theme.spacing['56px'] : '0px',
      marginRight: imagePosition === 'right' ? theme.spacing['56px'] : '0px',
    }),
  }),
)

const InfoBodyContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : theme.spacing['24px'],
  marginBottom: theme.spacing['32px'],
}))

const InfoBodyText = styled(BodyText)({
  maxWidth: '496px',
})

const MeetWithAnExpertImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  ...(!theme.breakpoints.isMinWidthMobileXs && { width: '317px', height: '231px' }),
  ...(!theme.breakpoints.isMinWidthMobileXxs && { width: '300px', height: '218px' }),
  ...(theme.breakpoints.isMobileSized && theme.breakpoints.isMinWidthMobileXs && { width: '327px', height: '238px' }),
  ...(theme.breakpoints.isMinWidthDesktopL && { width: '480px', height: '348px' }),
  ...(!theme.breakpoints.isMinWidthDesktopL &&
    theme.breakpoints.isMinWidthTablet && { width: '360px', height: '261px' }),
}))

const WellnessResourcesImageContainer = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  ...(!theme.breakpoints.isMinWidthMobileXs && { width: '317px', height: '231px' }),
  ...(!theme.breakpoints.isMinWidthMobileXxs && { width: '300px', height: '218px' }),
  ...(theme.breakpoints.isMobileSized && theme.breakpoints.isMinWidthMobileXs && { width: '327px', height: '238px' }),
  ...(theme.breakpoints.isMinWidthDesktopL && { width: '480px', height: '348px' }),
  ...(!theme.breakpoints.isMinWidthDesktopL &&
    theme.breakpoints.isMinWidthTablet && { width: '360px', height: '261px' }),
}))

const LyraOfferingItemContainer = styled.View<{ theme: ThemeType; hasRightMargin: boolean }>(
  ({ theme, hasRightMargin }) => ({
    padding: theme.spacing['8px'],
    flexDirection: 'row',
    ...(!theme.breakpoints.isMobileSized && { width: '317px' }),
    ...(hasRightMargin && { marginRight: theme.spacing['16px'] }),
  }),
)

const ItemLabelContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['12px'],
  padding: `${theme.spacing['8px']} 0`,
  justifyContent: 'center',
  flex: 1,
  ...(!theme.breakpoints.isMobileSized && { maxWidth: '225px' }),
}))

const ItemIconContainer = styled.View({
  justifyContent: 'center',
})

export const LyraOfferingsCarousel: FunctionComponent<LyraOfferingsCarouselProps> = ({
  customerPropertiesMap,
  pathwaysType = PATHWAYS_TYPE.SINGLE,
  testID = 'LyraOfferingsCarousel',
  isInternational = false,
  hideMeetWithExpert,
  hideWellnessResources,
  hideAllOfferings,
  meetWithAnExpertCtaPress,
  wellnessResourcesCtaPress,
  lyraOfferingsCtaPress,
  meetWithAnExpertTabPress,
  wellnessResourcesTabPress,
  lyraOfferingsTabPress,
  backgroundColor,
}) => {
  const { formatMessage } = useIntl()
  const { breakpoints } = useTheme()
  const isComingSoon = pathwaysType === PATHWAYS_TYPE.COMING_SOON

  const wrapFormattedMessageWithTestID = ({
    testID,
    formattedMessage,
  }: {
    testID: string
    formattedMessage: ReactElement
  }) => <View testID={tID(testID)}>{formattedMessage}</View>

  const defaultButtonText = wrapFormattedMessageWithTestID({
    formattedMessage: <FormattedMessage {...lyraOfferingSectionButtonCtaText.SINGLE_PATHWAY_DEFAULT} />,
    testID: `${testID}-defaultSinglePathwayCtaText`,
  })
  const makeInfoSection = ({
    header,
    bodyText,
    bodyContent,
    buttonText,
    alwaysCentered = false,
    imagePosition,
    onButtonPress,
  }: {
    header?: string | ReactNode
    bodyText?: string | ReactNode
    bodyContent?: ReactNode
    buttonText: string | ReactNode
    alwaysCentered?: boolean
    imagePosition?: string
    onButtonPress: () => void
  }) => {
    return (
      <InfoSectionContainer imagePosition={imagePosition} isCentered={alwaysCentered}>
        {header && <Subhead size={SubheadSize.LARGE} text={header} />}
        <InfoBodyContainer>
          {bodyText && <InfoBodyText text={bodyText} />}
          {bodyContent}
        </InfoBodyContainer>
        <View>{!isComingSoon && <PrimaryButton onPress={onButtonPress} text={buttonText} />}</View>
      </InfoSectionContainer>
    )
  }
  const meetWithAnExpertSection = {
    title: breakpoints.isMobileSized
      ? wrapFormattedMessageWithTestID({
          formattedMessage: <FormattedMessage {...lyraOfferingMenuTitles['1_TO_1_SESSIONS']} />,
          testID: `${testID}-meetWithAnExpertSection-shortMenuTitle`,
        })
      : wrapFormattedMessageWithTestID({
          formattedMessage: <FormattedMessage {...lyraOfferingMenuTitles.MEET_WITH_AN_EXPERT} />,
          testID: `${testID}-meetWithAnExpertSection-longMenuTitle`,
        }),
    content: (
      <ContentContainer testID={tID(`${testID}-meetWithAnExpertSection`)}>
        <MeetWithAnExpertImageContainer
          source={
            (!breakpoints.isMobileSized
              ? MeetWithAnExpertImageLargeDefault
              : MeetWithAnExpertImage) as ImageSourcePropType
          }
        />
        {makeInfoSection({
          header: wrapFormattedMessageWithTestID({
            formattedMessage: <FormattedMessage {...lyraOfferingSectionHeaders.MEET_WITH_AN_EXERT_HEADER} />,
            testID: `${testID}-meetWithAnExpertSection-header`,
          }),
          bodyText: wrapFormattedMessageWithTestID({
            formattedMessage: <FormattedMessage {...lyraOfferingSectionBodyText.MEET_WITH_AN_EXPERT_DEFAULT} />,
            testID: `${testID}-meetWithAnExpertSection-bodyText`,
          }),
          buttonText:
            pathwaysType === PATHWAYS_TYPE.MULTIPLE
              ? wrapFormattedMessageWithTestID({
                  formattedMessage: (
                    <FormattedMessage {...lyraOfferingSectionButtonCtaText.MULTI_PATHWAY_MEET_WITH_AN_EXPERT_SECTION} />
                  ),
                  testID: `${testID}-meetWithAnExpertSection-multiPathwayCtaText`,
                })
              : defaultButtonText,
          imagePosition: breakpoints.isMinWidthLaptop ? 'left' : 'top',
          onButtonPress: () => {
            meetWithAnExpertCtaPress && meetWithAnExpertCtaPress()
          },
        })}
      </ContentContainer>
    ),
    onTabPress: meetWithAnExpertTabPress,
  }

  const wellnessResourcesSection = {
    title: wrapFormattedMessageWithTestID({
      formattedMessage: <FormattedMessage {...lyraOfferingMenuTitles.WELLNESS_RESOURCES} />,
      testID: `${testID}-wellnessResourcesSection-menuTitle`,
    }),
    content: (
      <ContentContainer testID={tID(`${testID}-wellnessResourcesSection`)}>
        {!breakpoints.isMinWidthLaptop && (
          <WellnessResourcesImageContainer source={ContentLibraryImage as ImageSourcePropType} />
        )}
        {makeInfoSection({
          header: wrapFormattedMessageWithTestID({
            formattedMessage: <FormattedMessage {...lyraOfferingSectionHeaders.WELLNESS_RESOURCES_HEADER} />,
            testID: `${testID}-wellnessResourcesSection-header`,
          }),
          bodyText: wrapFormattedMessageWithTestID({
            formattedMessage: <FormattedMessage {...lyraOfferingSectionBodyText.WELLNESS_RESOURCES} />,
            testID: `${testID}-wellnessResourcesSection-bodyText`,
          }),
          imagePosition: breakpoints.isMinWidthLaptop ? 'right' : 'top',
          buttonText:
            pathwaysType === PATHWAYS_TYPE.MULTIPLE
              ? wrapFormattedMessageWithTestID({
                  formattedMessage: (
                    <FormattedMessage {...lyraOfferingSectionButtonCtaText.MULTI_PATHWAY_RESOURCES_SECTION} />
                  ),
                  testID: `${testID}-wellnessResourcesSection-multiPathwayCtaText`,
                })
              : defaultButtonText,
          onButtonPress: () => {
            wellnessResourcesCtaPress && wellnessResourcesCtaPress()
          },
        })}
        {breakpoints.isMinWidthLaptop && (
          <WellnessResourcesImageContainer source={ContentLibraryImageLargeDefault as ImageSourcePropType} />
        )}
      </ContentContainer>
    ),
    onTabPress: wellnessResourcesTabPress,
  }

  const iconSize = breakpoints.isMobileSized ? 48 : 64

  const allLyraOfferings = [
    {
      offeringName: LyraOfferingsOptions['1_TO_1_SESSIONS_THERAPIST'],
      icon: <IndividualTherapySpotIllustrationWhiteBorder size={iconSize} />,
      label: formatMessage(lyraOfferingsOptionTitles['1_TO_1_SESSIONS_THERAPIST']),
      lyraWebPropertyNames: {
        [ProgramCustomerPropertyId.considerLyraTherapy]: 'true',
        [ProgramCustomerPropertyId.directAccessTherapy]: 'true',
      },
    },
    {
      offeringName: LyraOfferingsOptions['1_TO_1_SESSIONS_COACH'],
      icon: <CoachingSpotIllustration size={iconSize} />,
      label: formatMessage(lyraOfferingsOptionTitles['1_TO_1_SESSIONS_COACH']),
      lyraWebPropertyNames: {
        [ProgramCustomerPropertyId.stressManagement]: 'true',
      },
    },
    {
      offeringName: LyraOfferingsOptions.WELLNESS_RESOURCES,
      icon: <SelfCareEssentialsSpotIllustrationWhiteBorder size={iconSize} />,
      label: formatMessage(lyraOfferingsOptionTitles.WELLNESS_RESOURCES),
      lyraWebPropertyNames: { [ProgramCustomerPropertyId.essentialsDisabled]: 'false' },
    },
    {
      offeringName: LyraOfferingsOptions.FAMILY_AND_COUPLES_THERAPY,
      icon: <CouplesAndFamilyTherapySpotIllustration size={iconSize} />,
      label: formatMessage(lyraOfferingsOptionTitles.FAMILY_AND_COUPLES_THERAPY),
      lyraWebPropertyNames: {
        [ProgramCustomerPropertyId.directAccessTherapy]: 'true',
        [ProgramCustomerPropertyId.considerLyraTherapy]: 'true',
      },
    },
    {
      offeringName: LyraOfferingsOptions.CARE_FOR_COMPLEX_NEEDS,
      icon: <OutPatientSupportSpotIllustrationWhiteBorder size={iconSize} />,
      label: formatMessage(lyraOfferingsOptionTitles.CARE_FOR_COMPLEX_NEEDS),
      lyraWebPropertyNames: { [ProgramCustomerPropertyId.alcoholUseDisorderTherapy]: 'true' },
    },
    // @TODO: Need to figure out how to check if customer has other employer-sponsored benefits for a logged out user *part of phase 2
    {
      offeringName: LyraOfferingsOptions.OTHER_EMPLOYER_SPONSORED_BENEFITS,
      icon: <EmployerBenefitsSpotIllustrationWhiteBorder size={iconSize} />,
      label: formatMessage(lyraOfferingsOptionTitles.OTHER_EMPLOYER_SPONSORED_BENEFITS),
    },
    {
      offeringName: LyraOfferingsOptions.MEDICATION,
      icon: <MedicationManagementSpotIllustration size={iconSize} />,
      label: formatMessage(lyraOfferingsOptionTitles.MEDICATION),
      lyraWebPropertyNames: {
        [ProgramCustomerPropertyId.blendedCareMeds]: 'true',
        [ProgramCustomerPropertyId.considerMeds]: 'true',
      },
    },
    {
      offeringName: LyraOfferingsOptions.PARENTING_SUPPORT,
      icon: <ParentCoachingSpotIllustration size={iconSize} />,
      label: formatMessage(lyraOfferingsOptionTitles.PARENTING_SUPPORT),
      lyraWebPropertyNames: { [ProgramCustomerPropertyId.stressManagement]: 'true' },
    },
    // @TODO: phase 2 this offering can be available
    {
      offeringName: breakpoints.isMobileSized
        ? LyraOfferingsOptions.CAREER_WELL_BEING
        : LyraOfferingsOptions.CAREER_SUPPORT,
      icon: <WorkplaceWellbeingSpotIllustrationWhiteBorder size={iconSize} />,
      label: formatMessage(
        breakpoints.isMobileSized
          ? lyraOfferingsOptionTitles.CAREER_WELL_BEING
          : lyraOfferingsOptionTitles.CAREER_SUPPORT,
      ),
    },
  ]

  const availableLyraOfferings = allLyraOfferings.filter(
    (item) =>
      item?.lyraWebPropertyNames &&
      Object.keys(item.lyraWebPropertyNames).some((key) => {
        if (item.offeringName === LyraOfferingsOptions.PARENTING_SUPPORT) {
          return !isInternational && customerPropertiesMap?.[key] === item.lyraWebPropertyNames?.[key]
        }
        return (
          customerPropertiesMap?.[key] === item.lyraWebPropertyNames?.[key] ||
          // specifically for essentialsDisabled key, if it is not present in customerPropertiesMap then we can show the essentials lyra offering
          (key === ProgramCustomerPropertyId.essentialsDisabled && !customerPropertiesMap?.hasOwnProperty(key))
        )
      }),
  )

  const numColumns = breakpoints.isMobileSized ? 1 : breakpoints.isMinWidthLaptop ? 3 : 2

  const renderLyraOfferingItem = ({
    item,
    index,
  }: {
    item: { label: string; icon: ReactNode; lyraWebPropertyNames?: { [key: string]: string | undefined } }
    index: number
  }) => {
    return (
      <LyraOfferingItemContainer hasRightMargin={numColumns !== 1 && (index + 1) % numColumns !== 0}>
        <ItemIconContainer>{item.icon}</ItemIconContainer>
        <ItemLabelContainer>
          <BodyText text={item.label} />
        </ItemLabelContainer>
      </LyraOfferingItemContainer>
    )
  }

  const allOfferingsSection = {
    title: wrapFormattedMessageWithTestID({
      formattedMessage: <FormattedMessage {...lyraOfferingMenuTitles.ALL_OFFERINGS} />,
      testID: `${testID}-allOfferingsSection-menuTitle`,
    }),
    content: (
      <ContentContainer testID={tID(`${testID}-allOfferingsSection`)}>
        {makeInfoSection({
          bodyContent: (
            <FlatList
              key={`columnView-${numColumns}`}
              data={availableLyraOfferings}
              renderItem={renderLyraOfferingItem}
              keyExtractor={(_, index) => `LyraOffering-${index}`}
              numColumns={numColumns}
            />
          ),
          buttonText:
            pathwaysType === PATHWAYS_TYPE.MULTIPLE
              ? wrapFormattedMessageWithTestID({
                  formattedMessage: (
                    <FormattedMessage {...lyraOfferingSectionButtonCtaText.MULTI_PATHWAY_ALL_OFFERINGS_SECTION} />
                  ),
                  testID: `${testID}-allOfferingsSection-multiPathwayCtaText`,
                })
              : defaultButtonText,
          alwaysCentered: true,
          onButtonPress: () => {
            lyraOfferingsCtaPress && lyraOfferingsCtaPress()
          },
        })}
      </ContentContainer>
    ),
    onTabPress: lyraOfferingsTabPress,
  }

  const getCarouselItems = () => {
    const items = []
    if (!hideMeetWithExpert) {
      items.push(meetWithAnExpertSection)
    }
    if (!hideWellnessResources) {
      items.push(wellnessResourcesSection)
    }
    if (!hideAllOfferings) {
      items.push(allOfferingsSection)
    }
    return items
  }

  return (
    <CarouselContainer testID={tID(testID)} id='lyra-offerings-section' backgroundColor={backgroundColor}>
      <InfoCarousel items={getCarouselItems()} />
    </CarouselContainer>
  )
}

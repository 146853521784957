import { match } from '@formatjs/intl-localematcher'

import { DEFAULT_CARE_LANGUAGE } from '../../common/constants/constants'
export const findAvailableCareLanguage = ({
  activeLanguage = '',
  availableLanguages = [],
  defaultCareLanguage,
}: {
  activeLanguage?: string
  availableLanguages: string[]
  defaultCareLanguage?: string
}) => {
  return match([activeLanguage], availableLanguages, defaultCareLanguage ?? DEFAULT_CARE_LANGUAGE)
}

import { REGISTRATION_EVENTS } from '@lyrahealth-inc/shared-app-logic'

import { axiosInstance } from '../../../common/http/axiosInstance'
import * as MixpanelActions from '../../../data/mixpanel'

export const CLOSE_MICROSITE_BANNER = 'CLOSE_MICROSITE_BANNER'

export const doPageLoad = (customer: $TSFixMe, eventProps?: Dict, page?: string) => {
  return (dispatch: $TSFixMe) => {
    if (customer !== 'care') {
      dispatch(
        MixpanelActions.initSuperProperties({
          event: MixpanelActions.MIXPANEL_EVENTS.VIEW_MICROSITE,
          eventProps,
          page,
        }),
      )
    } else {
      dispatch(
        MixpanelActions.initSuperProperties({
          event: REGISTRATION_EVENTS.VIEW_FIND_EMPLOYER_PAGE,
          eventProps,
          page,
        }),
      )
    }
  }
}

export const closeMicrositeBanner = () => {
  return (dispatch: $TSFixMe) => {
    dispatch({ type: CLOSE_MICROSITE_BANNER })
  }
}

export const postPrefilterCustomer = ({ customerName }: { customerName: string }) => {
  return async () => {
    try {
      const response = await axiosInstance.post('/unsecured/v1/utils/prefilter', { customerName })
      return response.data
    } catch (error: any) {
      console.error(`Unable to verify if ${customerName} is an existing customer`, error)
    }
  }
}

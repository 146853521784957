import { useMemo } from 'react'

import { Assignment } from '../../../models/assignments/Assignments'
import { AssessmentStages, AssignmentStatuses } from '../types'

export const useLatestPostCareAssessment = (assignments: Assignment[]) => {
  const latestPostCareAssessment = (assignments: Assignment[]) => {
    const postEndAssessments: Assignment[] = assignments?.filter(
      (assignment) =>
        assignment?.content_meta_data?.stage === AssessmentStages.end ||
        assignment?.content_meta_data?.stage === AssessmentStages.post_episode,
    )

    // just return if there's no post episode or final assessment, or just one
    if (postEndAssessments.length === 0) return
    if (postEndAssessments.length === 1) return postEndAssessments[0]

    // if multiple post care assessments, filter out missed
    const unmissedPostEndAssessments: Assignment[] = postEndAssessments?.filter(
      (assignment) => assignment.status !== AssignmentStatuses.missed,
    )

    // sort by create date
    const sortedPostEndAssessments: Assignment[] = unmissedPostEndAssessments.sort(
      (a: Partial<Assignment>, b: Partial<Assignment>) => {
        if (a.create_date && b.create_date)
          return a.create_date > b.create_date ? -1 : a.create_date < b.create_date ? 1 : 0
        return 0
      },
    )

    // return the latest
    return sortedPostEndAssessments[0]
  }
  return useMemo(() => latestPostCareAssessment(assignments), [assignments])
}

import { ProviderProgramTaxonomy, TREATMENT_OPTIONS_TYPE } from '@lyrahealth-inc/shared-app-logic'

import { resetCareLanguageState } from '../../carePreferences/data/carePreferencesActions'
import { setMeetingFormat } from '../../data/onboardDataActions'

export type SelectTreatmentOptionArgs = {
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}

export const SET_SELECTED_TREATMENT_OPTION = 'SET_SELECTED_TREATMENT_OPTION'
export const SET_FALLBACK_TREATMENT_OPTION = 'SET_FALLBACK_TREATMENT_OPTION'
export const CLEAR_TREATMENT_OPTIONS = 'CLEAR_SELECTED_TREATMENT_OPTION_AND_PROGRAM_INFO_FOR_APPOINTMENT_BOOKING'
export const SET_PROVIDER_BASED_TREATMENT_OPTIONS_USER_IS_ELIGIBLE_FOR =
  'SET_PROVIDER_BASED_TREATMENT_OPTIONS_USER_IS_ELIGIBLE_FOR'
export const SET_SELECTED_TREATMENT_OPTION_CTPO = 'SET_SELECTED_TREATMENT_OPTION_CTPO'

export const setSelectedTreatmentOption = (selectedTreatmentOption: $TSFixMe) => {
  return (dispatch: $TSFixMe) => {
    // The meeting format isn't set until after the treatment option is selected.
    // That means that we should clear any previously set meeting formats here, because they will be stale.
    dispatch(setMeetingFormat(undefined))
    dispatch({
      type: SET_SELECTED_TREATMENT_OPTION,
      payload: selectedTreatmentOption,
    })
    dispatch(resetCareLanguageState())
  }
}

export const setFallbackTreatmentOption = (fallbackTreatmentOption: $TSFixMe) => {
  return (dispatch: $TSFixMe) => {
    dispatch({
      type: SET_FALLBACK_TREATMENT_OPTION,
      payload: fallbackTreatmentOption,
    })
  }
}

export const clearTreatmentOptions = () => ({
  type: CLEAR_TREATMENT_OPTIONS,
})

export const selectTreatmentOption = ({ selectedTreatmentOption }: SelectTreatmentOptionArgs) => {
  return (dispatch: $TSFixMe) => {
    dispatch(setSelectedTreatmentOption(selectedTreatmentOption))
  }
}

export const selectTreatmentOptionWithoutNavigating = (selectedTreatmentOption: TREATMENT_OPTIONS_TYPE) => {
  return (dispatch: $TSFixMe) => {
    dispatch(setSelectedTreatmentOption(selectedTreatmentOption))
  }
}

export const selectTreatmentOptionWithoutRedirect = ({ selectedTreatmentOption }: $TSFixMe) => {
  return (dispatch: $TSFixMe) => {
    dispatch(setSelectedTreatmentOption(selectedTreatmentOption))
  }
}

export const setProviderBasedTreatmentOptionsUserIsEligibleFor = (treatmentOptions: $TSFixMe) => ({
  type: SET_PROVIDER_BASED_TREATMENT_OPTIONS_USER_IS_ELIGIBLE_FOR,
  payload: treatmentOptions,
})

export const setSelectedTreatmentOptionCTPO = (ctpo: ProviderProgramTaxonomy) => ({
  type: SET_SELECTED_TREATMENT_OPTION_CTPO,
  payload: ctpo,
})

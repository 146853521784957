import React from 'react'

import * as Sentry from '@sentry/react'

import ErrorFallback from '../ErrorFallback/ErrorFallback'

type ErrorBoundaryWrapperProps = {
  children: React.ReactNode
}

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryWrapperProps> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      onError={(error) => console.error('ErrorBoundary error:', error)}
      fallback={<ErrorFallback />}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundaryWrapper

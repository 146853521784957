import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { FlatList, ListRenderItemInfo, Pressable, StyleProp, useWindowDimensions, ViewStyle } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useMediaQuery } from 'react-responsive'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  CARE_ADVISOR_EMAIL,
  FOOTER_VERSION,
  LYRA_ALL_CUSTOMER_HIGH_ALERT_TEAM_PHONE,
} from '@lyrahealth-inc/shared-app-logic'

import { FooterPhoneIcon } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { Divider } from '../../atoms/divider/Divider'
import { ChatLiveIcon } from '../../atoms/icons/ChatLiveIcon'
import { ContactIcon } from '../../atoms/icons/ContactIcon'
import { EmailIcon } from '../../atoms/icons/EmailIcon'
import { QuestionMarkIcon } from '../../atoms/icons/QuestionMarkIcon'
import { TruncatedText } from '../../atoms/truncate/TruncatedText'
import { breakpoints, IS_WEB } from '../../constants'
import { RowView } from '../../templates/content/CommonViews'
import { openUrl, tID } from '../../utils'

enum Breakpoint {
  'EX_SMALL_TO_TABLET',
  'TABLET_TO_DESKTOP',
  'LAPTOP',
  'DESKTOP',
}

type ListItem = {
  icon: ReactNode
  text: string
  onPress: () => void
  testIDSuffix: string
}

export interface FooterProps {
  isFloating?: boolean
  isUserInternational?: boolean
  isCustomerInternational?: boolean
  isLoggedIn?: boolean
  careSupportNumber?: string
  careSupportEmail?: string
  navigateToCareTeamPage?: () => void
  onChatLivePress?: () => void
  onFAQPress: () => void
  backgroundColor?: string
  disableLiveChat?: boolean
}

const FooterContainer = styled.View<{
  backgroundColor: string
  size: Breakpoint
  isFloating: boolean
  screenWidth: number
}>(({ backgroundColor, size, isFloating, screenWidth }) => ({
  width: `${screenWidth}px`,
  backgroundColor,

  ...(isFloating &&
    (size === Breakpoint.LAPTOP || size === Breakpoint.DESKTOP) && {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 100,
    }),
  ...(size === Breakpoint.EX_SMALL_TO_TABLET && { alignSelf: 'center' }),
}))

const ContentContainer = styled.View(({ theme }) => ({
  width: '100%',
  padding: theme.spacing['12px'],
  paddingHorizontal: theme.breakpoints.isMinWidthDesktop ? theme.spacing['16px'] : '0',
}))

const Row = styled(RowView)({
  width: '100%',
  alignItems: 'center',
})

// This is needed to properly truncate extra small screen widths <320px
const SmallTruncatedContainer = styled.View<{ hasIcon: boolean }>(({ hasIcon }) => ({
  width: hasIcon ? '90%' : '100%',
}))

const IconContainer = styled.View(({ theme }) => ({
  paddingRight: theme.spacing['8px'],
}))

const ItemContainer = styled(Pressable)<{
  size: Breakpoint
  isEnd: boolean
  isInternational?: boolean
  isUSIntlSupport?: boolean
}>(({ isEnd, size, isInternational, isUSIntlSupport }) => {
  const marginRight = {
    [Breakpoint.TABLET_TO_DESKTOP]: !isInternational ? '40px' : '40px',
    [Breakpoint.DESKTOP]: !isInternational ? '40px' : '20px',
  }
  return {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '20px',
    ...(size !== Breakpoint.EX_SMALL_TO_TABLET && isInternational && { maxWidth: '145px' }),
    ...(size === Breakpoint.TABLET_TO_DESKTOP && !isEnd && { marginRight: marginRight[Breakpoint.TABLET_TO_DESKTOP] }),
    ...(size === Breakpoint.DESKTOP && !isEnd && { marginRight: marginRight[Breakpoint.DESKTOP] }),
    ...(size === Breakpoint.EX_SMALL_TO_TABLET && { flexBasis: isInternational ? '30%' : '70%' }),
    ...(size === Breakpoint.EX_SMALL_TO_TABLET && IS_WEB && { flexBasis: isInternational ? '' : '65%' }),
    ...(size === Breakpoint.EX_SMALL_TO_TABLET && isUSIntlSupport && { marginRight: '62px' }),
  }
})

const InternationalCopyContainer = styled.View<{ size: Breakpoint }>(({ size }) => ({
  display: 'flex',
  flexDirection: 'row',

  ...(size === Breakpoint.EX_SMALL_TO_TABLET && { flexDirection: 'column' }),
  ...(size === Breakpoint.TABLET_TO_DESKTOP && {
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    gap: '16px',
  }),
  ...(size === Breakpoint.DESKTOP && { gap: '12px' }),
}))

const SupportTextContainer = styled.View<{ size: Breakpoint }>(({ size }) => ({
  margin: size === Breakpoint.DESKTOP ? '18px 0 20px 0' : '0px 16px',

  ...(size !== Breakpoint.DESKTOP && { alignSelf: 'center' }),
}))

const FlatListContainer = styled.View<{ size: Breakpoint }>(({ size }) => ({
  alignSelf: 'center',
  flexGrow: 1,
  paddingTop: '20px',
  ...(size !== Breakpoint.DESKTOP && { alignItems: 'center' }),
  ...(size === Breakpoint.DESKTOP && { paddingRight: 0 }),
  ...(size === Breakpoint.EX_SMALL_TO_TABLET && { width: '100%' }),
  ...(size === Breakpoint.EX_SMALL_TO_TABLET && { maxWidth: '370px' }),
}))

const FlatListInnerContainer = styled.View({
  width: '100%',
})

export const Footer: FunctionComponent<FooterProps> = ({
  onChatLivePress = noop,
  onFAQPress,
  navigateToCareTeamPage = noop,
  isFloating = false,
  isUserInternational = false,
  isCustomerInternational = false,
  isLoggedIn = false,
  careSupportNumber = LYRA_ALL_CUSTOMER_HIGH_ALERT_TEAM_PHONE,
  careSupportEmail = CARE_ADVISOR_EMAIL,
  backgroundColor,
  disableLiveChat = false,
}) => {
  const isInternational = isUserInternational || isCustomerInternational
  const isUserInternationalAndOfInternationalCustomer = isLoggedIn && isUserInternational && isCustomerInternational

  const { colors } = useTheme()
  const { formatMessage } = useIntl()
  const { width } = useWindowDimensions()
  const { left, right } = useSafeAreaInsets()

  /**
   * US version: user is located in US, customer does not have int'l configuration
   * US version with intl congif: user is located in US, customer has int'l configuration
   * OUS version: user is located OUS, customer has int'l config
   */
  const footerVersion = isUserInternational
    ? FOOTER_VERSION.OUS_VERSION
    : isCustomerInternational
    ? FOOTER_VERSION.US_VERSION_OUS_SUPPORT
    : FOOTER_VERSION.US_VERSION

  const TABLET_TO_DESKTOP = useMediaQuery({
    query: `(min-width: ${breakpoints.tablet + 1}px) and (max-width: ${breakpoints.desktop}px)`,
  })
  const LAPTOP = useMediaQuery({
    query: `(min-width: ${breakpoints.laptop + 1}px) and (max-width: ${breakpoints.desktop}px)`,
  })
  const DESKTOP = useMediaQuery({ query: `(min-width: ${breakpoints.desktop + 1}px)` })

  const size = DESKTOP
    ? Breakpoint.DESKTOP
    : LAPTOP
    ? Breakpoint.LAPTOP
    : TABLET_TO_DESKTOP
    ? Breakpoint.TABLET_TO_DESKTOP
    : Breakpoint.EX_SMALL_TO_TABLET

  const [itemData, setItemData] = useState<ListItem[]>([])

  useEffect(() => {
    const isExtraSmall = size === Breakpoint.EX_SMALL_TO_TABLET
    const items = {
      [FOOTER_VERSION.US_VERSION]: [
        {
          icon: <FooterPhoneIcon />,
          text: careSupportNumber,
          onPress: () => openUrl(`tel:${careSupportNumber}`),
          testIDSuffix: 'Contact',
        },
        {
          icon: <EmailIcon />,
          text: careSupportEmail,
          onPress: () => openUrl(`mailto:${careSupportEmail}`),
          testIDSuffix: 'Email',
        },
        ...(!disableLiveChat
          ? [
              {
                icon: <ChatLiveIcon />,
                text: formatMessage({
                  defaultMessage: 'Chat Live',
                  description: 'Text in footer to chat for support',
                }),
                onPress: () => onChatLivePress(),
                testIDSuffix: 'ChatLive',
              },
            ]
          : []),
        {
          icon: <QuestionMarkIcon width={24} fillColor={colors.iconActive} />,
          text: formatMessage({
            defaultMessage: 'FAQs',
            description: 'Text in footer describing frequently asked questions',
          }),
          onPress: () => onFAQPress(),
          testIDSuffix: 'FAQ',
        },
      ],
      [FOOTER_VERSION.US_VERSION_OUS_SUPPORT]: [
        {
          icon: <FooterPhoneIcon />,
          text: isInternational
            ? formatMessage({
                defaultMessage: 'Call us',
                description: 'Text in footer to navigate to the care team page',
              })
            : careSupportNumber,
          onPress: () => navigateToCareTeamPage(),
          testIDSuffix: 'Contact',
        },
        ...(!isUserInternationalAndOfInternationalCustomer
          ? [
              {
                icon: <QuestionMarkIcon width={24} fillColor={colors.iconActive} />,
                text: formatMessage({
                  defaultMessage: 'FAQs',
                  description: 'Text in footer describing frequently asked questions',
                }),
                onPress: () => onFAQPress(),
                testIDSuffix: 'FAQ',
              },
            ]
          : []),
        ...(!disableLiveChat
          ? [
              {
                icon: <ChatLiveIcon />,
                text: formatMessage({
                  defaultMessage: 'Chat Live',
                  description: 'Text in footer to chat for support',
                }),
                onPress: () => onChatLivePress(),
                testIDSuffix: 'ChatLive',
              },
            ]
          : []),
      ],
      [FOOTER_VERSION.OUS_VERSION]: [
        {
          icon: <ContactIcon />,
          text: isInternational
            ? formatMessage({
                defaultMessage: 'Contact',
                description: 'Text in footer to navigate to the care team page',
              })
            : careSupportNumber,
          onPress: () => navigateToCareTeamPage(),
          testIDSuffix: 'Contact',
        },
      ],
    }
    // switch the care@lyrahealth.com cell with call us
    if (isExtraSmall) {
      if (items[footerVersion][1] && items[footerVersion][2]) {
        const temp = items[footerVersion][1]
        items[footerVersion][1] = items[footerVersion][2]
        items[footerVersion][2] = temp
      }
    }
    setItemData(items[footerVersion])
  }, [
    size,
    itemData.length,
    onChatLivePress,
    onFAQPress,
    isInternational,
    careSupportNumber,
    careSupportEmail,
    formatMessage,
    disableLiveChat,
    footerVersion,
    isUserInternationalAndOfInternationalCustomer,
    navigateToCareTeamPage,
    colors.iconActive,
  ])

  // conditionally renders the footer text and links in a row depending on screen size
  const FooterRow = ({ children }: { children: React.ReactNode }) =>
    size === Breakpoint.DESKTOP ? <Row>{children}</Row> : <>{children}</>

  const contentContainerStyle = {
    alignSelf: size === Breakpoint.DESKTOP ? 'flex-end' : 'center',
    ...(size !== Breakpoint.DESKTOP &&
      footerVersion !== FOOTER_VERSION.OUS_VERSION && { width: '100%', alignItems: 'stretch' }),
    ...(itemData.length === 3 && { alignItems: 'center' }),
  }

  return (
    <FooterContainer
      testID={tID(`Footer-${footerVersion}`)}
      backgroundColor={backgroundColor || colors.backgroundPrimary}
      size={size}
      isFloating={isFloating}
      screenWidth={width - left - right}
    >
      {size === Breakpoint.EX_SMALL_TO_TABLET && <Divider width={345} />}
      <ContentContainer>
        <FooterRow>
          <SupportTextContainer size={size}>
            {isInternational ? (
              <InternationalCopyContainer size={size}>
                {/* eslint-disable formatjs/no-literal-string-in-jsx */}
                <BodyText
                  textAlign={'center'}
                  text={'Nuestro equipo de atención puede ayudarlo'}
                  size={BodyTextSize.DEFAULT}
                />
                <BodyText textAlign={'center'} text={'如果您有任何问题，请联系我们'} size={BodyTextSize.DEFAULT} />
                <BodyText
                  textAlign={'center'}
                  text={'私たちのケアチームがお手伝いします'}
                  size={BodyTextSize.DEFAULT}
                />
                {/* eslint-enable formatjs/no-literal-string-in-jsx */}
              </InternationalCopyContainer>
            ) : (
              <TruncatedText
                noMargin
                text={formatMessage({
                  defaultMessage: 'Get 24/7, unlimited support from a Care Navigator.',
                  description: 'Text in the footer',
                })}
                textAlign={size !== Breakpoint.DESKTOP ? 'center' : 'left'}
                fontColor={colors.textSecondary}
              />
            )}
          </SupportTextContainer>
          <FlatListContainer size={size}>
            <FlatListInnerContainer>
              <FlatList
                scrollEnabled={false}
                data={itemData}
                contentContainerStyle={contentContainerStyle as StyleProp<ViewStyle>}
                numColumns={size === Breakpoint.EX_SMALL_TO_TABLET ? 2 : 4}
                renderItem={({ item, index }: ListRenderItemInfo<ListItem>) => {
                  const isEnd = index === itemData.length - 1
                  const isUSIntlSupport =
                    itemData.length === 3 && index === 0 && footerVersion === FOOTER_VERSION.US_VERSION_OUS_SUPPORT
                  return (
                    <ItemContainer
                      size={size}
                      isEnd={isEnd}
                      isUSIntlSupport={isUSIntlSupport}
                      onPress={item.onPress}
                      isInternational={isInternational}
                      testID={tID(`Footer-link-${item.testIDSuffix}`)}
                    >
                      <Row>
                        {item.icon && <IconContainer>{item.icon}</IconContainer>}
                        <SmallTruncatedContainer hasIcon={item.icon as boolean}>
                          <TruncatedText text={item.text} fontColor={colors.textActive} noMargin />
                        </SmallTruncatedContainer>
                      </Row>
                    </ItemContainer>
                  )
                }}
                keyExtractor={(_, index) => `footer-list-${index}`}
              />
            </FlatListInnerContainer>
          </FlatListContainer>
        </FooterRow>
      </ContentContainer>
    </FooterContainer>
  )
}

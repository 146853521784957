import React from 'react'
import { fromJS } from 'immutable'
import { excludedServicesNotice } from './defaultWithoutMeds'
import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'
import { CoverageNotice } from '../atoms/coverageNotice/CoverageNotice'

export const netapp: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return fromJS({
    legalNotice: <CoverageNotice excludedServices={excludedServicesNotice} sessionLimit={numberOfSessions} />,
  })
}

import { defineMessage, MessageDescriptor } from 'react-intl'

const providerLedTherapyEnabledCoachingDisabled = defineMessage({
  defaultMessage: '1-on-1 therapy',
  description: 'Title describing provider led pathway with therapy enabled, coaching disabled and US users',
})

const providerLedTherapyEnabledCoachingDisabledOUS = defineMessage({
  defaultMessage: '1-on-1 counselling',
  description: 'Title describing provider led pathway with therapy enabled, coaching disabled and OUS users',
})

const providerLedTherapyEnabledCoachingEnabled = defineMessage({
  defaultMessage: '1-on-1 therapy & coaching',
  description: 'Title describing provider led pathway with therapy enabled, coaching enabled and US users',
})

const providerLedTherapyEnabledCoachingEnabledOUS = defineMessage({
  defaultMessage: '1-on-1 counselling & coaching',
  description: 'Title describing provider led pathway with therapy enabled, coaching enabled and OUS users',
})

const providerLedTherapyDisabledCoachingEnabled = defineMessage({
  defaultMessage: '1-on-1 coaching',
  description: 'Title describing provider led pathway with therapy disabled, coaching enabled',
})

export interface PathwayTileMessages {
  providerLed: MessageDescriptor
  wellnessCheckin: MessageDescriptor
  selfCare: MessageDescriptor
}

export const getPathwayTileMessages = ({
  therapyEnabled,
  coachingEnabled,
  internationalUser,
}: {
  therapyEnabled: boolean
  coachingEnabled: boolean
  internationalUser: boolean
}): PathwayTileMessages => {
  let providerLedMessage

  if (coachingEnabled) {
    if (!therapyEnabled) {
      providerLedMessage = providerLedTherapyDisabledCoachingEnabled
    } else {
      providerLedMessage = internationalUser
        ? providerLedTherapyEnabledCoachingEnabledOUS
        : providerLedTherapyEnabledCoachingEnabled
    }
  } else {
    providerLedMessage = internationalUser
      ? providerLedTherapyEnabledCoachingDisabledOUS
      : providerLedTherapyEnabledCoachingDisabled
  }

  return {
    providerLed: providerLedMessage,
    wellnessCheckin: defineMessage({
      defaultMessage: 'Wellness check-in',
      description: 'Title describing wellness check-in pathway',
    }),
    selfCare: defineMessage({
      defaultMessage: 'Meditations, courses & more',
      description: 'Title describing self care pathway',
    }),
  }
}

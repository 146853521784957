import React, { FunctionComponent, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
// getSize is not supported for expo-image
// eslint-disable-next-line no-restricted-imports
import { Image, View } from 'react-native'

import styled from 'styled-components/native'

import { CDN_ASSETS_LINK_BASE, DEFAULT_LANGUAGE, GOOGLE_PLAY_STORE_LINK } from '@lyrahealth-inc/shared-app-logic'

import { PressableOpacity } from '../../atoms'
import { Image as ExpoImage } from '../../atoms/image/Image'
import { openUrl } from '../../utils'
import { tID } from '../../utils/utils'

export interface GooglePlayStoreBadgeProps {
  locale?: string
  height?: number
}

const PlayStoreBadgePNG = styled(ExpoImage)<{ width: number; height: number }>(({ width, height }) => ({
  width: `${width}px`,
  height: `${height}px`,
}))

export const GooglePlayStoreBadge: FunctionComponent<GooglePlayStoreBadgeProps> = ({
  locale = DEFAULT_LANGUAGE,
  height = 32,
}) => {
  const [language] = locale.split('-')

  const googlePlayStoreBadgesSubpath = 'google_play_store_badges'

  const [playStoreBadgeUriState, setPlayStoreBadgeUriState] = useState({ uri: '', width: 0 })
  const { formatMessage } = useIntl()

  // Since the PNGs are differing sizes we must get their original dimensions so we
  // can proportionally scale the width of the image to match the passed in height
  useEffect(() => {
    let fileSuffix = ''

    switch (language) {
      case 'en':
        fileSuffix = 'en'
        break
      // badge asset file (provided by Google) for malay and indonesian is the same
      case 'id':
      case 'ms':
        fileSuffix = 'id-ID'
        break
      default:
        fileSuffix = locale
    }

    const imageUri = `${CDN_ASSETS_LINK_BASE}/${googlePlayStoreBadgesSubpath}/PlayStoreBadge-black-${fileSuffix}.png`
    if (playStoreBadgeUriState.uri !== imageUri) {
      Image.getSize(
        imageUri,
        (originalWidth: number, originalHeight: number) => {
          setPlayStoreBadgeUriState({ uri: imageUri, width: (originalWidth * height) / originalHeight })
          return
        },
        (error) => {
          console.error('Error getting image size: ', error)
          // reset to empty string and use default image if there is any issue loading the resource (values below represent dimensions of default image)
          setPlayStoreBadgeUriState({ uri: '', width: (564 * height) / 168 })
        },
      )
    }
  }, [height, language, locale, playStoreBadgeUriState.uri])

  const onBadgePress = () => {
    openUrl(GOOGLE_PLAY_STORE_LINK)
  }

  return (
    <View testID={tID('GooglePlayStoreBadge')}>
      <PressableOpacity
        onPress={onBadgePress}
        accessibilityLabel={formatMessage({
          defaultMessage: 'Google Play Store Button',
          description: 'This button redirects the user to the Google Play Store',
        })}
      >
        <PlayStoreBadgePNG
          width={playStoreBadgeUriState.width}
          height={height}
          source={
            playStoreBadgeUriState.uri
              ? { uri: playStoreBadgeUriState.uri }
              : require('../../assets/PlayStoreBadge-black-en.png')
          }
          accessibilityIgnoresInvertColors
        />
      </PressableOpacity>
    </View>
  )
}

import axios, { AxiosResponse } from 'axios'

import {
  Appointment,
  CALENDAR_SERVICE_BASE_URL,
  PostAppointmentForAdultRequest,
  PostAppointmentForChildRequest,
} from '@lyrahealth-inc/shared-app-logic'

import { getJwt } from './jwt'
import { axiosInstance } from '../axiosInstance'

export interface AppointmentRequestResponse {
  message: string
}

export function getAppointmentsByUserId(userId: string) {
  return axiosInstance.get(`/v1/calendar/appointment/${userId}`)
}

export function deleteAppointmentById({ userId, appointmentId }: { userId: string; appointmentId: string }) {
  return axiosInstance.delete(`/v1/calendar/appointment/${userId}/${appointmentId}`)
}

export function editAppointmentByUserId({ userId, data }: { userId: string; data: Appointment }) {
  return axiosInstance.put(`/v1/calendar/appointment/${userId}`, data)
}

export function patchAppointmentByUserId({
  userId,
  appointmentId,
  partialAppointment,
}: {
  userId: string
  appointmentId: number
  partialAppointment: Partial<Appointment>
}) {
  return axiosInstance.patch(`/v1/calendar/appointment/${userId}/${appointmentId}`, partialAppointment)
}

export function createNewAppointmentByUserId({ userId, data }: { userId: string; data: Appointment }) {
  return axiosInstance.post(`/v1/calendar/appointment/${userId}`, data)
}

export async function createNewAppointmentRequest({
  data,
}: {
  data: PostAppointmentForChildRequest | PostAppointmentForAdultRequest
}): Promise<AxiosResponse<AppointmentRequestResponse>> {
  const { data: token } = await getJwt()
  return axios.post(`${process.env.CS_API_URL ?? CALENDAR_SERVICE_BASE_URL}/api/v1/appointment_request_notify`, data, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

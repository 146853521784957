import React from 'react'

import { fromJS } from 'immutable'

import CareImage from '../../../images/microsite/Care.png'
import EYImage from '../../../images/microsite/EY.png'
import OptumImage from '../../../images/microsite/Optum.png'
import { oneLine } from '../../utils/stringUtils'

export const nike = () => {
  return fromJS({
    workLifeServices: [
      {
        identifiers: {
          id: 'optum-EAP',
          dataTestId: 'workLife-selectOptumServices',
        },
        title: 'Optum EAP services',
        img: OptumImage,
        bullets: [
          'Counseling services (e.g., managing stress, depression, anxiety, substance use)',
          'Financial and legal advice',
          'Relationship help (e.g., improving relationships at home or at work)',
          'access code NIKE, or call: 844-857-0973',
        ],
        CTA: 'Access Optum WLS',
        PATH: 'https://liveandworkwell.com/',
      },
      {
        identifiers: {
          id: 'EY',
          dataTestId: 'workLife-selectEYServices',
        },
        title: 'NIKE Inc. Financial Coaching Program provided by EY (Ernst and Young, LLP)',
        img: EYImage,
        bullets: [
          'Unlimited telephone and direct messaging support from a dedicated financial coach to help you achieve your long-term financial wellness goals.',
          'From retirement planning to putting together a household budget that helps you save for future plans.',
          'Your personalized profile provides a holistic view of your spending and saving as you track towards your financial goal.',
          <>
            {oneLine(`Access to a variety of financial resources and tools via our `)}
            <a href='https://nike.eynavigate.com/' target='_blank' rel='noreferrer'>
              EY Navigate™
            </a>
            {oneLine(` website`)}
          </>,
        ],
        CTA: 'Access EY Navigator',
        PATH: 'https://nike.eynavigate.com/',
      },
      {
        identifiers: {
          id: 'Caregiving-Benefits',
          dataTestId: 'workLife-selectCaregivingServices',
        },
        title: 'Caregiving Benefits with Care.com',
        img: CareImage,
        bullets: [
          'Post jobs, search and reach out to local caregivers for childcare, pet care, senior caregivers, ' +
            'adult care, housekeepers, tutors, and more.',
          'Nike-subsidized backup care used for school holidays, last-minute sitter cancellations, or any other time ' +
            'that regular care is not available',
          'Access to dedicated advisors in the Care.com network to help you find the best caregiver for your family',
        ],
        CTA: 'Find dependent care',
        PATH: 'https://nike.care.com/',
      },
    ],
  })
}

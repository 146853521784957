import React from 'react'

import { AppleEligibilityErrorMessage, AppleEligibilityErrorMessageProps } from './AppleEligibilityErrorMessage'
import { InlineWarningBanner } from '../../../molecules'

export interface AppleEligibilityErrorBannerProps extends AppleEligibilityErrorMessageProps {
  isDependent: boolean
}

export const AppleEligibilityErrorBanner: React.FC<AppleEligibilityErrorBannerProps> = ({ isDependent, ...rest }) => {
  const text = <AppleEligibilityErrorMessage {...rest} isDependent={isDependent} />

  return (
    <InlineWarningBanner
      text={text}
      testId={`EmployeeInfoFor${isDependent ? 'Dependent' : 'Employee'}Eligibility-tips`}
    />
  )
}

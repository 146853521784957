import { matchPath } from 'react-router-dom'

/**
 * Replaces all newlines and whitespace with exactly one space.
 * Use on template strings to prevent rendering them with newlines and extra whitespace.
 * @param string
 * @returns {string}
 */
export const oneLine = (string: $TSFixMe) => {
  return string.replace(/(\n|\s)+/g, ` `)
}

export const articlize = (company = 'a') => {
  const letter = company.charAt(0).toLowerCase()
  let text = 'a'
  if (['a', 'e', 'i', 'o', 'u'].includes(letter)) {
    text = 'an'
  }
  return text
}

export const validateAssignmentId = (id: $TSFixMe) => {
  return /^([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})$/.test(id)
}

/**
 * Converts numbers to words, limited only to numbers for which mapping exists
 * @param number
 * @returns {string}
 */
export const numberToWord = (number: $TSFixMe) => {
  const numbersToWords = {
    0: 'zero',
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
  }
  return numbersToWords[number]
}

export const matchPaths = (pathname: string, paths: string[]) => {
  return paths.some((path) => matchPath(path, pathname))
}

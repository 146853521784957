import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { View, ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, Image, Link, Subhead } from '../../atoms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

export interface CareNavigatorCareOptionsFooterProps {
  careNavigatorLinkTestId: string
  style?: ViewStyle
  subheadTextAlign?: 'left' | 'center'
  onContactCareNavigatorPress: () => void
  headerText?: React.ReactNode
}

const ContactCareNavigatorContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: theme.breakpoints.isMobileSized ? 'column' : 'row',
  justifyContent: 'space-between',
  ...(theme.breakpoints.isMobileSized && { alignItems: 'center', gap: theme.spacing['16px'] }),
  marginBottom: theme.spacing['24px'],
}))

const ContactCareNavigatorText = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  gap: theme.spacing['8px'],
  ...(theme.breakpoints.isMobileSized && { alignItems: 'center' }),
}))

const ContactCareNavigatorImage = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  width: theme.breakpoints.isMobileSized ? '112px' : '160px',
  height: theme.breakpoints.isMobileSized ? '48px' : '64px',
}))

export const CareNavigatorCareOptionsFooter: FunctionComponent<CareNavigatorCareOptionsFooterProps> = ({
  careNavigatorLinkTestId,
  style,
  subheadTextAlign = 'left',
  onContactCareNavigatorPress,
  headerText = (
    <FormattedMessage
      defaultMessage='Not sure where to start?'
      description='Header for section that takes user to contact a Care Navigator'
    />
  ),
}) => {
  const { colors, breakpoints } = useTheme()

  return (
    <ContactCareNavigatorContainer style={style}>
      <ContactCareNavigatorText>
        <Subhead text={headerText} textAlign={subheadTextAlign} size={SubheadSize.SMALL} />
        <BodyText
          text={
            <FormattedMessage
              defaultMessage='Our Care Navigators can help. <link>Get in touch</link>'
              description='Subtitle and link for section that takes user to contact a Care Navigator'
              values={{
                link: (chunks: string) => (
                  <View testID={tID(careNavigatorLinkTestId)}>
                    <Link text={chunks} size={BodyTextSize.SMALL} onPress={onContactCareNavigatorPress} underline />
                  </View>
                ),
              }}
            />
          }
          size={BodyTextSize.SMALL}
          color={colors.textSecondary}
          textAlign={breakpoints.isMobileSized ? 'center' : 'left'}
        />
      </ContactCareNavigatorText>
      <ContactCareNavigatorImage
        source={require('../../assets/care_navigator_home_base_entry_image.png')}
        accessibilityIgnoresInvertColors
      />
    </ContactCareNavigatorContainer>
  )
}

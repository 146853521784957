import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Credit Card Icon that can be used for elements related to Payment
 */
export const CreditCardIcon: FunctionComponent<CreditCardIconProps> = ({ size = 24 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.75 6.75c0-.6.443-1 .886-1h14.728c.442 0 .886.4.886 1v2.5H3.75v-2.5ZM2.25 10V6.75c0-1.332 1.022-2.5 2.386-2.5h14.728c1.364 0 2.386 1.168 2.386 2.5v10.5c0 1.332-1.022 2.5-2.386 2.5H4.636c-1.364 0-2.386-1.168-2.386-2.5V10Zm18 .75v6.5c0 .6-.444 1-.886 1H4.636c-.443 0-.886-.4-.886-1v-6.5h16.5Z'
        fill={colors.icons.creditCardIcon.fill}
      />
    </Svg>
  )
}

export type CreditCardIconProps = {
  size?: string | number
}

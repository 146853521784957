import { useCallback, useEffect, useState } from 'react'

import AsyncStorage from '@react-native-async-storage/async-storage'

interface StorageItem<T> {
  value: T
  expires: number
}

/**
 * Read/write a value to AsyncStorage (uses LocalStorage on web) with an expiration time.
 *
 * @param key Key to write to AsyncStorage
 * @param initialValue Value to use if the value in storage is expired or not found
 * @returns Stored value or initial value, and a setValue function
 */
export function useStorageWithExpiration<T>(key: string, initialValue: T, expirationInMs: number) {
  const [storedValue, setStoredValue] = useState<T>(initialValue)

  useEffect(() => {
    const getStoredValue = async () => {
      try {
        const item = await AsyncStorage.getItem(key)
        if (item) {
          const parsedItem: StorageItem<T> = JSON.parse(item)
          if (Date.now() < parsedItem.expires) {
            setStoredValue(parsedItem.value)
          } else {
            // If expired, remove the item and use initial value
            await AsyncStorage.removeItem(key)
          }
        }
      } catch (error) {
        console.error('Error reading from AsyncStorage:', error)
      }
    }
    getStoredValue()
  }, [key, initialValue])

  /**
   * Write value to AsyncStorage with an expiration time
   *
   * @param value
   * @param expirationInMs
   * @param updateState Updates the state and the hook returns a new value.
   *  Set to false to avoid re-rendering with the new value.
   */
  const setValue = useCallback(
    async (value: T, updateState = true) => {
      const item: StorageItem<T> = {
        value,
        expires: Date.now() + expirationInMs,
      }
      try {
        await AsyncStorage.setItem(key, JSON.stringify(item))
      } catch (error) {
        console.error('Error writing to AsyncStorage:', error)
      }
      updateState && setStoredValue(value)
    },
    [expirationInMs, key],
  )

  return [storedValue, setValue] as const
}

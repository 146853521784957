import React, { FunctionComponent } from 'react'
import { Path, Rect, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

export const LyraLearnIcon: FunctionComponent<LyraLearnIcon> = ({ size = 48 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 500 500' fill='none'>
      <Rect width='500' height='500' rx='83' fill={colors.backgroundIcon} />
      <Path
        d='M155.89 199.242C158.156 199.326 164.617 199.242 164.617 199.242V214.347C164.617 214.347 158.743 214.347 155.89 214.347C147.331 214.347 124.842 212.753 124.842 189.005C124.842 161.9 124.842 104.167 124.842 104.167H139.946C139.946 104.167 139.946 172.138 139.946 184.725C139.946 196.473 147.75 198.907 155.89 199.242ZM383.132 174.403V214.431H368.111V205.704C361.23 211.158 352.587 214.515 343.104 214.515C321.035 214.515 303.077 196.641 303.077 174.487C303.077 152.334 320.951 134.46 343.104 134.46C365.174 134.46 383.132 152.334 383.132 174.403ZM368.363 174.403C368.363 160.474 357.034 149.145 343.104 149.145C329.174 149.145 317.846 160.474 317.846 174.403C317.846 188.333 329.174 199.662 343.104 199.662C357.118 199.662 368.363 188.333 368.363 174.403ZM298.797 134.46C284.532 134.46 275.721 142.18 271.189 149.145V134.303H256.084V214.515H271.189V179.942C271.189 164.837 280.672 149.145 298.126 149.145H301.147V134.46H298.797ZM230.155 134.292L204.309 194.627L181.149 134.292H164.869L195.918 214.011L178.463 252.612H195.498L247.106 134.292H230.155Z'
        fill={colors.iconActive}
      />
      <Path
        d='M203.541 343.844C203.541 320.688 184.583 302.406 161.427 302.406C137.864 302.406 118.635 320.146 118.635 343.844C118.635 367.542 137.729 385.417 161.156 385.417C178.625 385.417 194.062 375.396 199.614 359.552H178.76C175.646 364.427 169.281 368.76 161.156 368.76C150.187 368.76 141.25 361.042 139.219 352.375H202.458C203.135 349.396 203.541 346.823 203.541 343.844ZM139.489 336.26C141.385 326.781 149.781 318.656 161.427 318.656C173.208 318.656 181.062 326.781 182.958 336.26H139.489Z'
        fill={colors.iconActive}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M274.463 385.335H292.241V344.95C292.241 322.678 274.12 304.647 251.856 304.647C229.506 304.647 211.471 322.682 211.471 345.032C211.471 367.383 229.59 385.417 251.856 385.417C260.262 385.417 268.009 382.803 274.463 378.449V385.335ZM251.856 322.099C264.457 322.099 274.707 332.349 274.707 344.95C274.707 357.555 264.535 367.801 251.856 367.801C239.255 367.801 229.005 357.551 229.005 344.95C229.005 332.349 239.255 322.099 251.856 322.099Z'
        fill={colors.iconActive}
      />
      <Path
        d='M348.064 305.362C333.799 305.362 325.181 311.394 320.649 318.359H320.456V307.023H301.2V385.417H320.456V343.987C320.456 328.882 329.939 325.043 347.393 325.043H350.414V305.362H348.064Z'
        fill={colors.iconActive}
      />
      <Path
        d='M378.625 385.417V339.375C378.625 329.761 386.208 322.042 395.822 322.042C405.302 322.042 412.885 329.761 412.885 339.375V385.417H432.114V338.021C432.114 318.386 419.656 304.708 401.645 304.708C389.999 304.708 382.687 311.615 378.895 319.063H378.625V307.011H359.395V385.417H378.625Z'
        fill={colors.iconActive}
      />
      <Path
        d='M103.965 366.169C106.231 366.252 112.692 366.169 112.692 366.169V385.417C112.692 385.417 106.818 385.417 103.965 385.417C95.4059 385.417 72.9167 383.823 72.9167 360.075C72.9167 332.97 72.9167 275.237 72.9167 275.237L92.1012 275.237C92.1012 275.237 92.1012 343.208 92.1012 355.795C92.1012 367.543 95.8254 365.833 103.965 366.169Z'
        fill={colors.iconActive}
      />
    </Svg>
  )
}

type LyraLearnIcon = {
  size?: string | number
}

import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useLocation } from 'react-router-dom'

import classnames from 'classnames'
import queryString from 'query-string'

import { CustomerName } from '@lyrahealth-inc/shared-app-logic'
import { formatMessageHelper } from '@lyrahealth-inc/shared-app-logic/src/features/intl'
import { Alert } from '@lyrahealth-inc/ui-core'

import { BANNER_TYPE, closeBanner, setBannerPropsAndStatus } from './banner-actions'
import styles from './banner.module.scss'
import {
  getBannerIncludeX,
  getBannerIsOpen,
  getBannerMessage,
  getBannerType,
} from '../../../data/banner/bannerSelectors'
import { getCustomerName } from '../../../data/customer/customerSelectors'
import { isInLoginFlow } from '../../../features/login/loginUtils'
import { isInRegisterFlow } from '../../../features/register/data/registerUtils'
import { ELIGIBILITY_EXPIRATION__WALMART_IN_MINS } from '../../constants/registrationFormConstants'

type BannerViewProps = ConnectedProps<typeof connector>

const BannerView = ({
  message,
  bannerType,
  customerName,
  isOpen,
  setBannerPropsAndStatus,
  includeX = false,
  closeBanner,
}: BannerViewProps) => {
  const { search } = useLocation()
  const errormsg = queryString.parse(search)?.errormsg
  useEffect(() => {
    if (errormsg) {
      setBannerPropsAndStatus(BANNER_TYPE.DANGER, errormsg, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errormsg])

  if (!message) {
    return null
  }

  const isRegisterPage = isInRegisterFlow(location.pathname)
  const isLoginPage = isInLoginFlow(location.pathname)
  const isProfilePage = location.pathname.includes('profile')

  return (
    <Alert
      show={isOpen}
      style={bannerType}
      includeX={includeX}
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'object'.
      customClass={classnames({
        [styles['authentication-banner']]: isRegisterPage || isLoginPage,
        [styles['eligibility-banner']]: includeX,
        [styles['profile-banner']]: isProfilePage,
      })}
      dismissHandler={() =>
        closeBanner({
          message,
          shouldDismissEligibilityCheck: true,
          ...(customerName == CustomerName.WALMART && {
            eapEligibilityBannerExpiration: ELIGIBILITY_EXPIRATION__WALMART_IN_MINS,
          }),
        })
      }
    >
      {formatMessageHelper(message)}
    </Alert>
  )
}

const mapStateToProps = ($$state: $TSFixMe) => {
  return {
    message: getBannerMessage($$state),
    bannerType: getBannerType($$state),
    isOpen: getBannerIsOpen($$state),
    includeX: getBannerIncludeX($$state),
    customerName: getCustomerName($$state),
  }
}

const connector = connect(mapStateToProps, { setBannerPropsAndStatus, closeBanner })
export default connector(BannerView)

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BaseInput } from './BaseInput'
import { InputField, InputFieldProps } from './InputField'
import { IS_WEB } from '../../constants'
import { BodyTextSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { BodyText } from '../bodyText/BodyText'
import { Link } from '../link/Link'

export interface EditableInputFieldProps extends InputFieldProps {
  editButtonText?: React.ReactNode | string
  editingLabel?: string
  clearValueOnEdit?: boolean
  onPressEdit?: () => void
}

const FieldValueBodyText = styled(BodyText)(({ theme: { spacing } }) => ({
  ...(IS_WEB && { overflowWrap: 'anywhere' }),
  marginRight: spacing['4px'],
}))

export const EditableInputField: React.FC<EditableInputFieldProps> = ({
  editButtonText,
  editingLabel,
  label,
  clearValueOnEdit,
  onPressEdit,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const {
    value,
    labelStyle,
    labelContainerStyle,
    isOptionalLabel,
    sublabel,
    subLabelComponent,
    error,
    name,
    toolTipContent,
    toolTipTriggerConfig,
    toolTipContentStyle,
    showErrorAsWarning,
    baseInputStyle,
    onChange,
  } = restProps
  const label_ = editing && editingLabel ? editingLabel : label
  const { colors } = useTheme() as ThemeType

  return editing ? (
    // eslint-disable-next-line jsx-a11y/no-autofocus
    <InputField autoFocus={true} label={label_} {...restProps} />
  ) : (
    <BaseInput
      label={label_}
      labelStyle={labelStyle}
      labelContainerStyle={labelContainerStyle}
      isOptionalLabel={isOptionalLabel}
      subLabel={sublabel}
      subLabelComponent={subLabelComponent}
      error={error}
      name={name}
      style={baseInputStyle}
      toolTipContent={toolTipContent}
      toolTipTriggerConfig={toolTipTriggerConfig}
      toolTipContentStyle={toolTipContentStyle}
      showErrorAsWarning={showErrorAsWarning}
    >
      <BodyText>
        <FieldValueBodyText text={value} size={BodyTextSize.DEFAULT} color={colors.textSecondary} />
        <Link
          testID={tID('EditableInputField-edit-link')}
          underline={true}
          onPress={() => {
            setEditing(true)
            IS_WEB && clearValueOnEdit && onChange?.('' as any)
            onPressEdit?.()
          }}
          text={
            editButtonText ?? (
              <FormattedMessage
                defaultMessage='Update'
                description='Button to start editing a text field to update personal information'
              />
            )
          }
        />
      </BodyText>
    </BaseInput>
  )
}

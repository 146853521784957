import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { ThemeType, tID } from '../../utils'

export const PracticeSkillsIcon: FunctionComponent<PracticeSkillsIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme() as ThemeType
  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none' testID={tID('CheckMarkCircleCutIcon')}>
      <Path
        d='M22 11.0857V12.0057C21.9988 14.1621 21.3005 16.2604 20.0093 17.9875C18.7182 19.7147 16.9033 20.9782 14.8354 21.5896C12.7674 22.201 10.5573 22.1276 8.53447 21.3803C6.51168 20.633 4.78465 19.2518 3.61096 17.4428C2.43727 15.6338 1.87979 13.4938 2.02168 11.342C2.16356 9.19029 2.99721 7.14205 4.39828 5.5028C5.79935 3.86354 7.69278 2.72111 9.79619 2.24587C11.8996 1.77063 14.1003 1.98806 16.07 2.86572'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M22 4.00537L12 14.0154L9 11.0154'
        stroke={fillColor || colors.iconActive}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  )
}

type PracticeSkillsIconProps = {
  size?: number
  fillColor?: string
}

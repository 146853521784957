import { Address, BlankAddressSearch, LocationObject } from '../../models'
import { VENDOR_BASE_API_URL } from '../common/constants/constants'

// the object for the address search typeahead
export const blankAddressSearch: BlankAddressSearch = {
  userInput: '', // the field input
  state: '', // google retrieved state
  country: '',
  lat: 0, // google retrieved latitude
  lon: 0, // google retrieved longitude
  formattedAddress: '', // google retrieved formatted address
}

export const initialLocationObject: LocationObject = {
  onsite: false, // if onsite option is selected
  onsiteName: '', // usually the company or campus name
  address: {} as Address,
  near: false, // if typeahead was used
  selectedAddressOption: 0, // current id value for onsite address
  selectedDropdownValue: {
    value: 0,
    locationName: '',
  }, // current (city, state) location name and dropdown value
  geo: blankAddressSearch, // the object for the address search details,
}

/**
 * @deprecated Use enum `LocationMatcherDisplayFormats` instead
 */
export const LOCATION_FINDER_DISPLAY_FORMATS = {
  DROPDOWN_MENU_TO_RADIO_BUTTONS: 'dropdown',
  RADIO_BUTTONS_ONLY: 'radioButtons',
}

export const GOOGLE_PLACES_VENDOR_API_URL = `${VENDOR_BASE_API_URL}/maps/api`

import { useMemo } from 'react'

import {
  AccessCodeListItemProps,
  ClinicalDataAccessCode,
  ClinicalDataSharingPageProps,
} from '@lyrahealth-inc/shared-app-logic'

type Params = {
  accessCodes: ClinicalDataAccessCode[]
  onPressManageAccessCode: (accessCode: ClinicalDataAccessCode) => void
  hideHeader: boolean
}

export const useClinicalDataSharingPage = ({
  accessCodes,
  onPressManageAccessCode,
  hideHeader,
}: Params): Omit<ClinicalDataSharingPageProps, 'onPressBackButton' | 'onPressCreateAccessCode'> => {
  const accessCodeListItems: AccessCodeListItemProps[] = useMemo(
    () =>
      accessCodes.map((a, idx) => {
        return {
          creationDateISO8601: a.create_datetime_utc,
          expirationDateISO8601: a.expire_datetime_utc,
          providerName: a.provider_surname,
          accessCode: a.access_code,
          showBottomDivider: idx >= accessCodes.length,
          onPressManage: () => onPressManageAccessCode(a),
        }
      }),
    [accessCodes, onPressManageAccessCode],
  )

  return {
    accessCodeListItems,
    hideHeader,
  }
}

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { DirectDedicatedCareNavigatorInfoValues } from '@lyrahealth-inc/shared-app-logic'
import { Header as UICoreHeader } from '@lyrahealth-inc/ui-core'
import { BodyTextSize, PrimaryButton, SecondaryButton, ThemeType, tID } from '@lyrahealth-inc/ui-core-crossplatform'
import { ButtonSize } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/baseButton/BaseButton'

import ContactCareNavigatorModal, { ContactCareNavigatorModalProps } from './ContactCareNavigatorModal'

type DefaultHeaderProps = Partial<ContactCareNavigatorModalProps> & {
  user?: $TSFixMe // TODO: PropTypes.instanceOf(Map)
  customClass?: string
  handleBackButtonClick?: $TSFixMeFunction
  handleLogout?: $TSFixMeFunction
  handleLogin?: () => void
  handleSignUpClick?: () => void
  trackMenuClick?: $TSFixMeFunction
  lyraLogo: React.ReactElement
  handleLogoClick?: $TSFixMeFunction
  handleRegister?: $TSFixMeFunction
  ghost?: boolean
  shouldHideLoginButton?: boolean
  loggedOutButtons?: $TSFixMe[]
  loggedInButtons?: $TSFixMe[]
  loginButtonClassName?: string
  navigationMenuProps?: $TSFixMe
  shouldShowCareNavigatorHeaderEntrypoint?: boolean
  isLyrian?: boolean
  showLiveChat: boolean
  showScheduleCall?: boolean
  headerNavMenu?: React.ReactElement
  CareNavigatorHeaderEntryPointButton?: React.ReactElement
  userDedicatedCareNavigatorInfo?: DirectDedicatedCareNavigatorInfoValues
}

const SignInButton = styled(SecondaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['16px'],
}))

const SignUpButton = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : theme.spacing['32px'],
}))

const DefaultHeader: React.FC<DefaultHeaderProps> = ({
  customClass,
  handleBackButtonClick,
  handleLogout,
  handleLogin,
  handleSignUpClick,
  trackMenuClick,
  user,
  lyraLogo,
  handleLogoClick,
  shouldHideLoginButton,
  handleRegister,
  loggedOutButtons,
  loggedInButtons,
  ghost,
  loginButtonClassName,
  navigationMenuProps = {},
  shouldShowCareNavigatorHeaderEntrypoint = false,
  isModalOpen = false,
  setIsModalOpen = noop,
  isInternational = false,
  customerPhoneNumber,
  onPressEmail = noop,
  onPressFAQ = noop,
  onPressPhoneCall = noop,
  onPressScheduleCall = noop,
  onPressLiveChat = noop,
  onExit = noop,
  isMobile = false,
  isLyrian = false,
  startLiveChat = false,
  showLiveChat,
  showScheduleCall,
  headerNavMenu,
  CareNavigatorHeaderEntryPointButton,
  userDedicatedCareNavigatorInfo,
}) => {
  const { breakpoints } = useTheme()
  const shouldShowSeparateLoginAndSignUp = breakpoints.isMinWidthMobileXs
  const shouldShowSmallerSize = breakpoints.isMobileSized
  /** One off button sizings that aren't finalized in design system yet. */
  const buttonTextSize = shouldShowSmallerSize ? BodyTextSize.CAPTION : undefined
  const buttonStyle = { ...(shouldShowSmallerSize && { height: 34 }) }

  const loginButton = shouldShowSeparateLoginAndSignUp && (
    <SignInButton
      text={<FormattedMessage defaultMessage='Log in' description='In the page header, a button to the login page' />}
      onPress={() => {
        handleLogin && handleLogin()
      }}
      testID={tID('SignIn-button')}
      customTextSize={buttonTextSize}
      style={buttonStyle}
      isSmall
    />
  )

  const signUpButton = (
    <SignUpButton
      text={
        shouldShowSeparateLoginAndSignUp ? (
          <FormattedMessage
            defaultMessage='Sign up'
            description='In the page header, a button that directs the user to sign up'
          />
        ) : (
          <FormattedMessage
            defaultMessage='Log in/Sign Up'
            description='In the page header, a button to the login page'
          />
        )
      }
      onPress={() => {
        if (shouldShowSeparateLoginAndSignUp) {
          handleSignUpClick && handleSignUpClick()
        } else {
          handleLogin && handleLogin()
        }
      }}
      testID={tID('SignUp-button')}
      size={ButtonSize.SMALL}
      customTextSize={buttonTextSize}
      style={buttonStyle}
    />
  )

  return (
    <>
      <UICoreHeader
        className={customClass}
        notification
        handleBackButtonClick={handleBackButtonClick}
        handleLogout={handleLogout}
        handleLogin={handleLogin}
        trackMenuClick={trackMenuClick}
        user={user}
        icon={''}
        lyraLogo={lyraLogo}
        handleLogoClick={handleLogoClick}
        shouldHideLoginButton={shouldHideLoginButton}
        handleRegister={handleRegister}
        loggedOutButtons={loggedOutButtons}
        loggedInButtons={loggedInButtons}
        ghost={ghost}
        loginButtonClassName={loginButtonClassName}
        navigationMenuProps={navigationMenuProps}
        shouldShowCareNavigatorHeaderEntrypoint={shouldShowCareNavigatorHeaderEntrypoint}
        CareNavigatorHeaderEntryPointButton={CareNavigatorHeaderEntryPointButton}
        isMobileSized={isMobile}
        loginButton={loginButton}
        signUpButton={signUpButton}
        headerNavMenu={headerNavMenu}
      />
      <ContactCareNavigatorModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        customerPhoneNumber={customerPhoneNumber}
        isInternational={isInternational}
        onPressEmail={onPressEmail}
        onPressFAQ={onPressFAQ}
        onPressPhoneCall={onPressPhoneCall}
        onPressScheduleCall={onPressScheduleCall}
        onPressLiveChat={onPressLiveChat}
        onExit={onExit}
        isMobile={isMobile}
        isLyrian={isLyrian}
        showLiveChat={showLiveChat}
        startLiveChat={startLiveChat}
        showScheduleCall={showScheduleCall}
        useWebPortalForBottomSheet={true}
        careNavigatorHeadshot={userDedicatedCareNavigatorInfo?.headShotURL}
        careNavigatorLicense={userDedicatedCareNavigatorInfo?.clinicalLicenses}
        careNavigatorName={userDedicatedCareNavigatorInfo?.firstName}
      />
    </>
  )
}

export default DefaultHeader

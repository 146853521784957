import { useMemo } from 'react'

import { Program, ProviderInfo } from '../../../models'
import { TREATMENT_OPTIONS_TYPE } from '../../common/constants/treatmentOptions'

type Params = {
  provider?: ProviderInfo
  programs: Program[]
  treatmentOption?: TREATMENT_OPTIONS_TYPE | null
}

/**
 * Hook used to get the program that matches the selected treatment option
 */
export const useProgram = ({ programs, treatmentOption }: Params) =>
  useMemo(
    () => programs.find(({ provider_modality }) => provider_modality === treatmentOption),
    [programs, treatmentOption],
  )

import { IntlShape } from 'react-intl'

import minimatch from 'minimatch'
import queryString from 'query-string'

import { LYRA_HEALTH_NAME } from '../constants/appConstants'

// Takes a pathname and returns the correct page title
// @ts-expect-error TS(7030): Not all code paths return a value.
export const getRouteTitle = (fullPathUrl: string, intl: IntlShape, routeTitleKey = '') => {
  // Maps location paths to their corresponding page title
  const routeTitleMap: { [key: string]: { url: string[]; title: string | null } } = {
    Login: {
      url: ['*/*/login'],
      title: intl.formatMessage({
        defaultMessage: 'Login',
        description: 'Browser tab title text for the login page',
      }),
    },
    'Lyra Health Member Login': {
      url: ['*/care.*'],
      title: intl.formatMessage({
        defaultMessage: 'Lyra Health Member Login',
        description: 'Browser tab title text for the generic member page',
      }),
    },
    'Reset Password': {
      url: ['*/*/forgot-password', '*/*/reset-password'],
      title: intl.formatMessage({
        defaultMessage: 'Reset Password',
        description: 'Browser tab title text for the password reset page',
      }),
    },
    Registration: {
      url: [
        '*/*/register',
        '*/*/register/connect',
        '*/*/register/enteremail',
        '*/*/register/verify',
        '*/*/register/orientation/**',
        '*/*/register/account-setup/**',
        '*/*/register/verify-account/**',
      ],
      title: intl.formatMessage({
        defaultMessage: 'Registration',
        description: 'Browser tab title text during the registration process',
      }),
    },
    HomeBase: {
      url: ['*/*/secure/index/search-care'],
      title: intl.formatMessage({
        defaultMessage: 'Find Care + Resources',
        description: 'Browser tab title text for the home base',
      }),
    },
    'Search for therapist': {
      url: ['*/*/secure/onboard/modality-questionnaire', '*/*/secure/onboard/modality-questionnaire/**'],
      title: intl.formatMessage({
        defaultMessage: 'Search for therapist',
        description: 'Browser tab title text determining user coaching preference',
      }),
    },
    'Search for Care': {
      url: ['*/*/secure/index/search-care', '*/*/secure/onboard/about-child', '*/*/secure/onboard/coaching/location'],
      title: intl.formatMessage({
        defaultMessage: 'Search for Care',
        description: 'Browser tab title text during the Search for Care process',
      }),
    },
    // PRIMARY CARE NEEDS/TRIAGE ROUTES
    'Share what is on your mind': {
      url: [],
      title: intl.formatMessage({
        defaultMessage: 'Share what is on your mind',
        description: 'Browser tab title text for user selecting their primary needs',
      }),
    },
    'Select the feelings you are experiencing': {
      url: [],
      title: intl.formatMessage({
        defaultMessage: 'Select the feelings you are experiencing',
        description: 'Browser tab title text for user selecting any appicable triage need options',
      }),
    },
    'Share your current anxiety level': {
      url: [],
      title: intl.formatMessage({
        defaultMessage: 'Share your current anxiety level',
        description: 'Browser tab title text for user selecting the severity of their triage need options',
      }),
    },
    'Impact on day-to-day life': {
      url: [],
      title: intl.formatMessage({
        defaultMessage: 'Impact on day-to-day life',
        description:
          'Browser tab title text for user selecting how much their triage needs have affected them in their everyday life',
      }),
    },
    'Share your timeline': {
      url: [],
      title: intl.formatMessage({
        defaultMessage: 'Share your timeline',
        description: 'Browser tab title text for user selecting how long their triage needs have affected them',
      }),
    },
    'Life changing events': {
      url: [],
      title: intl.formatMessage({
        defaultMessage: 'Life changing events',
        description: 'Browser tab title text for user selecting any significant events that have occured in their life',
      }),
    },
    'Current care status': {
      url: [],
      title: intl.formatMessage({
        defaultMessage: 'Current care status',
        description: 'Browser tab title text for user selecting any applicable care status',
      }),
    },
    'Historical understanding': {
      url: [],
      title: intl.formatMessage({
        defaultMessage: 'Historical understanding',
        description: 'Browser tab title text for user selecting any applicable historical engagements',
      }),
    },
    'Begin clinical questions': {
      url: ['*/*/secure/onboard/triage-screeners-introduction'],
      title: intl.formatMessage({
        defaultMessage: 'Begin clinical questions',
        description:
          'Browser tab title text informing user that they will be assessed in specific areas of their mental health',
      }),
    },
    'Final questions': {
      url: [],
      title: intl.formatMessage({
        defaultMessage: 'Final questions',
        description: 'Browser tab title text for user selecting clinical qustional to end the triage',
      }),
    },
    'Explore care options': {
      url: [],
      title: intl.formatMessage({
        defaultMessage: 'Explore care options',
        description: 'Browser tab title text for the list of available care options for the user',
      }),
    },
    'Search for a care provider': {
      url: ['*/*/secure/onboard/match-location'],
      title: intl.formatMessage({
        defaultMessage: 'Search for a care provider',
        description: 'Browser tab title text for matching providers to users via location',
      }),
    },
    'Book Coach Preferences': {
      url: ['*/*/secure/**/session-modality'],
      title: intl.formatMessage({
        defaultMessage: 'Book Coach Preferences',
        description: 'Browser tab title text for user preference page regarding how to meet for sessions',
      }),
    },
    'Care Options': {
      url: ['*/*/secure/onboard/more-options'],
      title: intl.formatMessage({
        defaultMessage: 'Care Options',
        description: 'Browser tab title text for the care options pages',
      }),
    },
    'Provider Results': {
      url: [
        '*/*/secure/onboard/t1-multi-providers',
        '*/*/secure/onboard/coaches',
        '*/*/secure/onboard/coaching/coaches',
        '*/*/secure/onboard/t1-multi-providers/latest-provider-matches',
        '*/*/secure/onboard/coaches/latest-provider-matches',
      ],
      title: intl.formatMessage({
        defaultMessage: 'Provider Results',
        description: 'Browser tab title text for results after searching for providers',
      }),
    },
    Provider: {
      url: ['*/*/secure/onboard/provider/**', '*/*/secure/index/provider/**'],
      title: null /* defer to ProviderContainer */,
    },
    Home: {
      url: ['*/*/secure/index/assignments'],
      title: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Browser tab title text for the home page upon logging in',
      }),
    },
    Library: {
      url: ['*/*/secure/index/essentials', '*/*/secure/index/work-hub'],
      title: intl.formatMessage({
        defaultMessage: 'Library',
        description: 'Browser tab title text when visiting the Essentials and Work Hub home page',
      }),
    },
    Sessions: {
      url: ['*/*/secure/index/sessions'],
      title: intl.formatMessage({
        defaultMessage: 'Sessions',
        description: 'Browser tab title text when viewing the sessions page',
      }),
    },
    Progress: {
      url: ['*/*/secure/index/progress'],
      title: intl.formatMessage({
        defaultMessage: 'Progress',
        description: 'Browser tab title text when viewing the progress page',
      }),
    },
    Payment: {
      url: ['**/infraction-payment-info', '**/payment-info'],
      title: intl.formatMessage({
        defaultMessage: 'Payment Info',
        description: 'Browser tab title text for navigating to payment info page',
      }),
    },
    Profile: {
      url: ['*/*/secure/profile', '*/*/secure/profile/**'],
      title: intl.formatMessage({
        defaultMessage: 'Profile',
        description: 'Browser tab title text for navigating to account management pages',
      }),
    },
    FAQs: {
      url: ['*/*/faq'],
      title: intl.formatMessage({
        defaultMessage: 'FAQs',
        description: 'Browser tab title text for navigating to the frequently asked questions page',
      }),
    },
    'Work and Life': {
      url: ['*/*/worklife'],
      title: 'Work and Life',
    },
    LyrawebMobile: {
      url: ['*/*/lyraweb-mobile/forgot-password'],
      title: intl.formatMessage({
        defaultMessage: 'LyrawebMobile',
        description: 'Browser tab title text for resetting password in mobile',
      }),
    },
  }

  // directly set title without having to iterate over the full map keys when routeTitleKey is defined
  if (routeTitleKey) {
    const { title } = routeTitleMap[routeTitleKey]
    return `${title} | ${LYRA_HEALTH_NAME}`
  }
  // generate the document.title string
  else if (fullPathUrl) {
    for (const key in routeTitleMap) {
      for (const url of routeTitleMap[key].url) {
        if (minimatch(fullPathUrl, url)) {
          const { title } = routeTitleMap[key]
          if (title) {
            if (key === 'Lyra Health Member Login') {
              return title
            } else {
              return `${title} | ${LYRA_HEALTH_NAME}`
            }
          } else {
            return null
          }
        }
      }
    }
  } else {
    return LYRA_HEALTH_NAME
  }
}

/**
 * Utility to preserve some query parameters during a route transition
 *
 * @param preserveParams List of parameters to copy from `current`, merge into `next` if it is not already set
 * @returns an updated search string
 */
export function preserveSearchParams(
  preserveParams: ReadonlyArray<string>,
  current: string | undefined,
  next: string | undefined,
): string | undefined {
  if (!current) {
    return next
  }

  const curParams = new URLSearchParams(current)
  const nextParams = new URLSearchParams(next)
  let modified = false
  for (const param of preserveParams) {
    if (curParams.has(param) && !nextParams.has(param)) {
      modified = true
      const values = curParams.getAll(param)
      values.forEach((val) => nextParams.append(param, val))
    }
  }

  // return the original search string if we did not modify anything
  return modified ? '?' + nextParams.toString() : next
}

export const routeToCustomerLoginUrl = (customer: string, queryParams?: Record<string, string>) => {
  const { host } = window.location
  const currentCustomer = host.substring(0, host.indexOf('.'))
  let redirectUrl = window.location.origin.replace(`${currentCustomer}.`, `${customer}.`) + '/login'
  if (queryParams) {
    redirectUrl += `?${queryString.stringify(queryParams)}`
  }
  window.location.replace(redirectUrl)
}

export const getLocalBaseUrl = (isCare = false, customer = '') => {
  return `https://${isCare ? 'care.' : customer ? customer + '.' : ''}localhost:4000`
}

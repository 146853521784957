/* eslint-disable formatjs/no-literal-string-in-jsx */
import React from 'react'
import { Linking } from 'react-native'
import { TREATMENT_RECOMMENDATION_TIER, TREATMENT_OPTIONS, GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'
import { BodyText, Link } from '../atoms'

export const psjh: GetCustomerCopyFunc = () => {
  const providenceHealthIndividualTherapyConfiguration = {
    title: 'Providence Behavioral Health Concierge',
    description: (
      <>
        <BodyText
          text={
            'Providence caregivers, their dependents, and/or household members in AK, CA, ID, MT, NM, OR, WA, and TX have free, same-day, or next-day access to virtual counseling and care navigation through the Providence Trusana platform.\n\nLearn more about our services or '
          }
        />
        <Link
          text={'schedule an appointment'}
          onPress={() => Linking.openURL('https://www.trusana.com/user-flow-type/EAP')}
        />
        <BodyText
          text={
            ' through the Providence Trusana platform. Appointments are available from 7a-8p (Pacific Time), 7 days/week.'
          }
        />
      </>
    ),
    buttonText: 'Find a Behavioral Health Concierge Provider',
    url: 'https://www.trusana.com/user-flow-type/EAP',
  }

  return {
    treatmentOptions: [
      {
        option: TREATMENT_OPTIONS.ONSITE_THERAPY,
        config: { customCopy: providenceHealthIndividualTherapyConfiguration },
        optionRanking: { [TREATMENT_RECOMMENDATION_TIER.TIER_2]: 2, [TREATMENT_RECOMMENDATION_TIER.TIER_3]: 1 },
      },
    ],
  }
}

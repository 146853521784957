import { useState } from 'react'
import { NativeScrollEvent, NativeSyntheticEvent } from 'react-native'

export const useIsScreenScrolled = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    setIsScrolled(Boolean(event.nativeEvent.contentOffset.y > 0))
  }

  return {
    isScrolled,
    handleScroll,
  }
}

import { fromJS, OrderedMap } from 'immutable'

import * as DirectAccessProviderActions from './direct-access-provider-actions'

const defaultState = {
  providerProfile: {},
  editableFields: {},
}

export const directAccessProvider = (state = fromJS(defaultState), action = null) => {
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  switch (action.type) {
    case DirectAccessProviderActions.SET_DIRECT_ACCESS_PROVIDER: {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.set('providerProfile', OrderedMap(action.providerProfile))
    }
    default:
      return state
  }
}

export default directAccessProvider

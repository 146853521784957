import { setBannerAndErrorMessage } from '../components/banner/banner-actions'
import { routeToCustomerLoginUrl } from '../utils/routingUtils'

/**
 * Handles a promise error of a redux action.
 *
 * Shows a red alert banner with the message returned by the server.
 *
 * @param {Object} error Error returned by the server.
 * @param {function} dispatch dispatch function from redux.
 * @param {string} [action] Redux action related to the request.
 */
export const actionErrorHandler = (error: $TSFixMe, dispatch: $TSFixMe) => {
  dispatch(setBannerAndErrorMessage(error, true))
  throw error
}

/**
 * No-op action error handler. Used to be passed as errorHandler to individual auto thunk action,
 * to override default errorHandler configured in autoThunkMiddleWare.
 */
export const noOpActionErrorHandler = () => null

/*
 * Okta sign in error handler. Used to redirect to the appropriate customer URL if needed with an error
 * query param, which will be used to set an error banner through the Login component. Otherwise, an error
 * banner is set and the user remains on the login page
 */
export const authenticationFailureHandler = (error: $TSFixMe, dispatch: $TSFixMe) => {
  const errorResponse = error.response?.data || {}
  const { errorCode } = errorResponse

  if (errorCode === 'USER_REGISTERED_DIFFERENT_CUSTOMER') {
    const { CUSTOMER_NAME } = errorResponse?.data
    routeToCustomerLoginUrl(CUSTOMER_NAME, { error: errorCode })
  } else {
    dispatch(setBannerAndErrorMessage(error, true))
  }
  throw error
}

// Ignores 404 errors as they are sometimes expected
export const expectedResourceNotFound = (error: $TSFixMe, dispatch: $TSFixMe) => {
  if (error?.response?.status === 404) {
    return null
  } else {
    dispatch(setBannerAndErrorMessage(error, true))
    throw error
  }
}

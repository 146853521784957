import React from 'react'
import { defineMessage } from 'react-intl'
import { Linking } from 'react-native'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import CareImage from '../assets/worklife/Care.png'
import EYImage from '../assets/worklife/EY.png'
import OptumImage from '../assets/worklife/Optum.png'
import { BodyText, CoverageNotice } from '../atoms'
import { Link } from '../atoms/link/Link'

/* eslint-disable formatjs/no-literal-string-in-jsx */
export const nike: GetCustomerCopyFunc = ({ numberOfSessions }) => {
  return {
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage: 'Are you an employee of Nike, Inc.?',
        description: 'Asking the user if they are an employee of the company Nike',
      }),
    servicesNotCovered:
      'Services that are not covered include: psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, prescription medication, autism spectrum disorder treatment, services for remedial education, and non-evidence-based behavioral health care. Please check with your medical plan or benefits portal for coverage of these excluded services.',
    legalNotice: (
      <CoverageNotice
        excludedServices={defineMessage({
          defaultMessage:
            'Lyra does not cover psychiatry, inpatient or residential treatment, hospitalization (including partial), intensive outpatient treatment, emergent care, prescription medication, autism spectrum disorder treatment, services for remedial education, and non-evidence-based behavioral health care.',
          description:
            'Text for describing the services that Lyra does not cover, displayed as part of the coverage notice',
        })}
        sessionLimit={numberOfSessions}
      />
    ),
    workLifeServices: [
      {
        identifiers: {
          id: 'optum-EAP',
          dataTestId: 'workLife-selectOptumServices',
        },
        title: 'Optum EAP services',
        img: OptumImage,
        bullets: [
          'Counseling services (e.g., managing stress, depression, anxiety, substance use)',
          'Financial and legal advice',
          'Relationship help (e.g., improving relationships at home or at work)',
          'access code NIKE, or call: 844-857-0973',
        ],
        CTA: 'Access Optum WLS',
        PATH: 'https://liveandworkwell.com/',
      },
      {
        identifiers: {
          id: 'EY',
          dataTestId: 'workLife-selectEYServices',
        },
        title: 'NIKE Inc. Financial Coaching Program provided by EY (Ernst and Young, LLP)',
        img: EYImage,
        bullets: [
          'Unlimited telephone and direct messaging support from a dedicated financial coach to help you achieve your long-term financial wellness goals.',
          'From retirement planning to putting together a household budget that helps you save for future plans.',
          'Your personalized profile provides a holistic view of your spending and saving as you track towards your financial goal.',
          <>
            <BodyText text='Access to a variety of financial resources and tools via our ' />
            <Link
              text='EY Navigate™'
              onPress={() => {
                Linking.openURL('https://nike.eynavigate.com/')
              }}
            />
            <BodyText text=' website' />
          </>,
        ],
        CTA: 'Access EY Navigator',
        PATH: 'https://nike.eynavigate.com/',
      },
      {
        identifiers: {
          id: 'Caregiving-Benefits',
          dataTestId: 'workLife-selectCaregivingServices',
        },
        title: 'Caregiving Benefits with Care.com',
        img: CareImage,
        bullets: [
          'Post jobs, search and reach out to local caregivers for childcare, pet care, senior caregivers, ' +
            'adult care, housekeepers, tutors, and more.',
          'Nike-subsidized backup care used for school holidays, last-minute sitter cancellations, or any other time ' +
            'that regular care is not available',
          'Access to dedicated advisors in the Care.com network to help you find the best caregiver for your family',
        ],
        CTA: 'Find dependent care',
        PATH: 'https://nike.care.com/',
      },
    ],
  }
}

import React, { FunctionComponent } from 'react'
import Svg, { Path, Rect } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Message Icon with a circle shown on the welcome screen
 */
export const MessageCircleIcon: FunctionComponent<MessageCircleIconProps> = ({ size = 44 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 44 44' fill='none'>
      <Rect width={size} height={size} rx='22' fill={colors.backgroundSecondary} />
      <Path
        d='M30 12H14C12.9 12 12 12.9 12 14V32L16 28H30C31.1 28 32 27.1 32 26V14C32 12.9 31.1 12 30 12ZM29 26H16L14 28V15C14 14.45 14.45 14 15 14H29C29.55 14 30 14.45 30 15V25C30 25.55 29.55 26 29 26Z'
        fill={colors.iconDefault}
      />
    </Svg>
  )
}

type MessageCircleIconProps = {
  size?: string | number
}

import { fromJS } from 'immutable'

import * as GooglePlacesTypeaheadActions from './google-places-typeahead-actions'

const defaultState = {
  location: '',
  text: '',
  state: null,
  coordinates: null,
}

export const googlePlacesTypeahead = (state = fromJS(defaultState), action = null) => {
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  switch (action.type) {
    case GooglePlacesTypeaheadActions.UPDATE_PLACES_COORDINATES: {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.set('coordinates', action.val)
    }
    case GooglePlacesTypeaheadActions.UPDATE_PLACES_INPUT_TEXT: {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.set('text', action.val)
    }
    case GooglePlacesTypeaheadActions.UPDATE_PLACES_LOCATION: {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.set('location', action.val)
    }
    case GooglePlacesTypeaheadActions.UPDATE_PLACES_STATE: {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.set('state', action.val)
    }
    case GooglePlacesTypeaheadActions.RESET_PLACES_TYPEAHEAD: {
      return fromJS(defaultState)
    }
    default:
      return state
  }
}

export default googlePlacesTypeahead

import {
  ChildInfoFormValues,
  convertDateStringFromISOToUSFormat,
  EAPEligibilityRequest,
  ELIGIBILITY_TYPES,
  EmailClient,
  getEmailAddressClientFromString,
  REGISTRATION_EVENTS,
  RegistrationData,
} from '@lyrahealth-inc/shared-app-logic'

import { trackEventWithObj } from '../../data/mixpanel'
// @ts-expect-error TS(2307): Cannot find module '../../register/types' or its c... Remove this comment to see the full error message
import { FormValues } from '../../register/types'

export const getRegistrationDataFromFormValues = (
  formValues: $TSFixMe,
  formProps: $TSFixMe,
  isInternational?: boolean,
): RegistrationData => {
  const { isAttestationEnabled, attestationContents, ethnicityInformation, customerName, id, token, sso } = formProps
  const {
    userFirstName,
    userLastName,
    password,
    userDOB,
    gender,
    hasConsentedToTermsOfUse,
    hasConsentedToHIPAA,
    race,
    allowUserResearch,
    eligibilityType = ELIGIBILITY_TYPES.UNKNOWN,
    dependentTypeId,
    customerCode,
    ...restOfValues
  } = formValues
  const attestations = Object.keys(restOfValues)
    .filter((formKey) => formKey.startsWith('attestation-'))
    .map((formKey) => restOfValues[formKey])
  const hasAttested = attestations.every((attestation) => attestation) && isAttestationEnabled
  const attestationIds = attestationContents.map((attestation: $TSFixMe) => attestation.id)

  // International users don't see HIPAA attestations, so we can ignore it
  const hasConsented = hasConsentedToTermsOfUse && (isInternational || hasConsentedToHIPAA)

  const registrationData: RegistrationData = {
    customerCode,
    dependentTypeId,
    eligibilityType,
    customer: customerName,
    firstname: userFirstName,
    lastname: userLastName,
    username: id,
    password,
    dob: convertDateStringFromISOToUSFormat(userDOB),
    gender,
    allowUserResearch,
    hasConsented: hasConsented,
    attestationIds: hasAttested ? attestationIds : undefined,
    token,
    race,
    raceVersionId: ethnicityInformation?.id,
    sso,
  }

  return registrationData
}

export const getEligibilityDataFromRegistration = (
  formValues: $TSFixMe,
  registrationData: $TSFixMe,
  newUser: $TSFixMe,
) => {
  const { customer, eligibilityType = ELIGIBILITY_TYPES.UNKNOWN, customerCode, dependentTypeId } = registrationData

  let {
    dob = newUser?.dob, // FIXME: Starbucks dependents won't have a DOB to pass in - need to figure out how it can be removed if possible
    firstname = newUser?.firstname,
    lastname = newUser?.lastname,
  } = registrationData

  // Eligibility Check for dependents - set first name, last name, dob to employee's info who's giving the dependent access to Lyra services
  // Does not apply to customers who require an eligibility check using dependent's info
  if (eligibilityType === ELIGIBILITY_TYPES.DEPENDENT) {
    // Override first and last name
    if (formValues?.employeeFirstName && formValues?.employeeLastName) {
      firstname = formValues.employeeFirstName
      lastname = formValues.employeeLastName
    }
    // Override DOB (This is not always present in the eligibility check e.g. Starbucks)
    if (formValues?.employeeDOB) {
      dob = convertDateStringFromISOToUSFormat(formValues.employeeDOB)
    }
  }

  return {
    customer,
    dob,
    firstname,
    lastname,
    eligibilityType,
    customerCode,
    dependentTypeId,
  }
}

export const getDataForEAPEligibilityCheck = ({
  customerName,
  formValues,
  lyraId,
}: {
  customerName: string
  formValues: FormValues
  lyraId?: string | null
  customerCountryId?: string
}): EAPEligibilityRequest => {
  const eligibilityType = formValues?.eligibilityType || ELIGIBILITY_TYPES.UNKNOWN
  const data = {
    lyraId,
    customerName,
    firstname: formValues?.userFirstName,
    lastname: formValues?.userLastName,
    dob: formValues?.userDOB,
    customerCode: formValues?.customerCode,
    eligibilityType,
    employeeFirstname: eligibilityType === ELIGIBILITY_TYPES.DEPENDENT ? formValues?.employeeFirstName : undefined,
    employeeLastname: eligibilityType === ELIGIBILITY_TYPES.DEPENDENT ? formValues?.employeeLastName : undefined,
    employeeDob: eligibilityType === ELIGIBILITY_TYPES.DEPENDENT ? formValues?.employeeDOB : undefined,
  }

  return data
}

export const getChildDataForEAPEligibilityCheck = ({
  customerName,
  formValues,
  lyraId,
}: {
  customerName: string
  formValues: ChildInfoFormValues
  lyraId?: string | null
}): EAPEligibilityRequest => {
  const data = {
    lyraId,
    customerName,
    firstname: formValues?.childsFirstName,
    lastname: formValues?.childsLastName,
    dob: convertDateStringFromISOToUSFormat(formValues?.childsDateOfBirth),
    eligibilityType: ELIGIBILITY_TYPES.DEPENDENT,
  }

  return data
}

export const handleOpenEmailPress = (email?: string | null) => {
  const emailClient = email && getEmailAddressClientFromString(email.toLowerCase())
  switch (emailClient) {
    case EmailClient.GMAIL: {
      trackEventWithObj({ event: REGISTRATION_EVENTS.CLICK_OPEN_IN_GMAIL })
      return window.location.replace(
        'https://mail.google.com/mail/u/0/#search/from%3Alyrahealth.com+in%3Aanywhere+newer_than%3A1d',
      )
    }
    case EmailClient.YAHOO: {
      trackEventWithObj({ event: REGISTRATION_EVENTS.CLICK_OPEN_IN_YAHOO })
      return window.location.replace('https://mail.yahoo.com/d/search/keyword=from%3Alyrahealth.com')
    }
    case EmailClient.OUTLOOK: {
      trackEventWithObj({ event: REGISTRATION_EVENTS.CLICK_OPEN_IN_OUTLOOK })
      return window.location.replace('https://outlook.office.com')
    }
    default:
      return
  }
}

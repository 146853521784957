import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  COMMON_MIXPANEL_PROPERTIES,
  PathwayOptions,
  PATHWAYS_ENTRY_POINTS,
  PATHWAYS_EVENTS,
  SELF_CARE_EVENTS,
  SELF_CARE_EVENTS_PROPERTIES,
  TRIAGE_ENTRY_POINTS,
  useLyraIntl,
} from '@lyrahealth-inc/shared-app-logic'
import {
  SelfCareWellnessTopic,
  SelfCareWellnessTopicContent,
  toJS,
  useSelfCareWellnessTopicFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { convertIdToTopicApiResponse } from '../../common/utils/selfCareWellnessTopicUtil'
import { getEssentialsCmsUrl, getIsDetectedCountryInternational } from '../../data/appGlobals/appGlobalsSelectors'
import { trackEventWithObj } from '../../data/mixpanel'
import { TrackEventWithObjAction } from '../../data/mixpanel/types'
import { getSelectedEssentialsTopicIds } from '../../data/register/registerSelectors'
import { setSelectedEssentialsTopicIds, setSelectedPathwayOption } from '../register/data/registerActions'

export interface SelfCareWellnessTopicContainerProp {
  essentialsCmsUrl: string
  selectedEssentialsTopicIds: string[]
  setSelectedEssentialsTopicIds: any
  setSelectedPathwayOption: any
  trackEventWithObj: TrackEventWithObjAction
  isDetectedCountryInternational: boolean
}

const SelfCareWellnessTopicContainer: FunctionComponent<SelfCareWellnessTopicContainerProp> = ({
  essentialsCmsUrl,
  selectedEssentialsTopicIds,
  setSelectedEssentialsTopicIds,
  setSelectedPathwayOption,
  trackEventWithObj,
  isDetectedCountryInternational,
}) => {
  const { activeLanguage } = useLyraIntl()
  const navigate = useNavigate()
  const topicCardContents = useSelfCareWellnessTopicFetcher({
    essentialsCmsUrl: essentialsCmsUrl,
    lyraLanguage: activeLanguage,
    shouldFilterOUS: isDetectedCountryInternational,
  })
  const initialSelectedCards = convertIdToTopicApiResponse(selectedEssentialsTopicIds, topicCardContents)
  const { state } = useLocation()
  const mixpanelEntryPoint = state?.mixpanelEntryPoint

  useEffect(() => {
    trackEventWithObj({
      event: PATHWAYS_EVENTS.VIEW_SELF_CARE_PATHWAY_TILE,
      ...(mixpanelEntryPoint && { [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: mixpanelEntryPoint }),
    })
  }, [mixpanelEntryPoint, trackEventWithObj])

  const saveUserTopic = async (topicContents: SelfCareWellnessTopicContent[]) => {
    const { ids, titles } = topicContents.reduce<{ ids: string[]; titles: string[] }>(
      (acc, curr) => {
        acc.ids.push(curr.id)
        acc.titles.push(curr.title)
        return acc
      },
      { ids: [], titles: [] },
    )
    setSelectedEssentialsTopicIds(ids)
    trackEventWithObj({
      event: SELF_CARE_EVENTS.CLICK_SELF_CARE_CONTINUE,
      [SELF_CARE_EVENTS_PROPERTIES.SELECTED_TOPIC_PREFERENCE]: titles.join(','),
    })
    navigateToRegister()
  }

  const skipUserTopic = () => {
    trackEventWithObj({
      event: SELF_CARE_EVENTS.CLICK_SELF_CARE_SKIP,
    })
    navigateToRegister()
  }

  const navigateToRegister = () => {
    setSelectedPathwayOption(PathwayOptions.ESSENTIALS)
    navigate('/register/connect', {
      state: { mixpanelEntryPoint: PATHWAYS_ENTRY_POINTS.SELF_CARE, origin: TRIAGE_ENTRY_POINTS.ESSENTIALS },
    })
  }

  return (
    <SelfCareWellnessTopic
      topicCardContents={topicCardContents}
      onSkip={skipUserTopic}
      onSubmit={saveUserTopic}
      initialSelectedCards={initialSelectedCards}
    />
  )
}

const mapStateToProps = ($$state: $TSFixMe) => {
  return {
    essentialsCmsUrl: getEssentialsCmsUrl($$state),
    selectedEssentialsTopicIds: getSelectedEssentialsTopicIds($$state),
    isDetectedCountryInternational: getIsDetectedCountryInternational($$state),
  }
}

const mapDispatchToProps = {
  setSelectedEssentialsTopicIds,
  setSelectedPathwayOption,
  trackEventWithObj,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(SelfCareWellnessTopicContainer))

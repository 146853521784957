import { useCallback } from 'react'

import { LDMultiKindContext, LDSingleKindContext } from 'launchdarkly-js-client-sdk'

import { useLDClient } from '@lyrahealth-inc/shared-app-logic/src/features/flags/launchdarkly-client-sdk.web'

/**
 * Updates the LaunchDarkly context by calling `identify`.
 */
export const useUpdateLDContext = () => {
  const ldClient = useLDClient()

  return useCallback(
    async (data) => {
      const context = ldClient?.getContext() as LDMultiKindContext
      const userContext = context?.user as LDSingleKindContext
      await ldClient?.identify({ ...context, user: { ...userContext, ...data } })
    },
    [ldClient],
  )
}

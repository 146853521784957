import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Mask, Path, Rect } from 'react-native-svg'

export const MedsConsultationSecondaryRec: FunctionComponent<MedsConsultationSecondaryRecProps> = ({
  width = 80,
  height = 80,
  outerCircleBgColor = 'white',
  innerCircleBgColor = '#FFE9E3',
}) => {
  return (
    <Svg width={width} height={height} viewBox='0 0 80 80' fill='none'>
      <G clip-path='url(#clip0_4898_179384)'>
        <Path
          d='M40.2812 0.0117188C34.7592 0.0117188 29.4015 0.95867 24.3569 2.8257C12.3469 7.27748 3.9034 17.147 1.1906 29.9031C-0.602123 38.2202 -0.372894 45.9128 1.89568 53.4093C4.79186 62.9832 11.9011 71.0568 21.4007 75.5592C26.1164 77.7993 31.5341 79.0782 37.5004 79.3596C38.2671 79.3944 39.0465 79.4134 39.8212 79.4134C44.6081 79.4134 49.4235 78.7573 54.1345 77.4641C54.4965 77.374 54.8412 77.2807 55.2048 77.1732C59.4384 75.9338 63.2483 73.9735 66.5081 71.354C70.0477 68.5258 72.9613 64.9403 75.1587 60.7036C75.5919 59.8689 76.0061 58.9899 76.463 57.9386C78.9766 52.102 80.1907 45.5681 79.9757 39.0359C79.8745 36.1476 79.4445 33.0776 78.7015 29.9031C77.4779 24.7526 75.4828 19.9088 72.9313 15.887C72.2325 14.8072 71.5907 13.8998 70.9551 13.0841C66.369 7.1684 59.2234 3.01699 50.2977 1.08514C47.0189 0.373742 43.6485 0.0132995 40.2828 0.0132995L40.2812 0.0117188Z'
          fill={outerCircleBgColor}
        />
        <Path
          d='M76.5437 39.1427C76.4457 36.3746 76.022 33.5069 75.3549 30.6565C74.2641 26.0641 72.4713 21.5364 70.0131 17.6601C69.4424 16.7811 68.8574 15.9337 68.2219 15.1196C63.6121 9.17545 56.6261 5.86824 49.4758 4.32055C41.5113 2.59422 33.0424 3.09852 25.3862 5.93306C14.3105 10.037 6.76968 19.0275 4.30982 30.5917C3.36445 34.9724 3.0878 39.9237 3.51148 44.6806H3.47828C3.72332 47.3839 4.21181 50.0224 4.94376 52.4491C7.66447 61.4396 14.2757 68.5741 22.6971 72.5643C27.258 74.7301 32.3548 75.8051 37.5353 76.0501C42.8281 76.2952 48.2031 75.6265 53.1719 74.259C53.4975 74.1784 53.8074 74.0962 54.1331 73.9981C58.0252 72.8583 61.413 71.0988 64.3124 68.7701C67.4726 66.2455 70.0779 63.0695 72.0983 59.1773C72.5377 58.33 72.9282 57.4842 73.3029 56.6195C75.6805 51.0974 76.7397 45.1043 76.5279 39.1601L76.5437 39.1443V39.1427Z'
          fill={innerCircleBgColor}
        />
        <Mask id='mask0_4898_179384' maskUnits='userSpaceOnUse' x='3' y='3' width='74' height='74'>
          <Path
            d='M76.5437 39.1467C76.4457 36.3785 76.022 33.5108 75.3549 30.6605C74.2641 26.068 72.4713 21.5403 70.0131 17.664C69.4424 16.785 68.8574 15.9377 68.2219 15.1235C63.6121 9.17936 56.6261 5.87215 49.4758 4.32446C41.5113 2.59813 33.0424 3.10243 25.3862 5.93696C14.3105 10.0409 6.76968 19.0314 4.30982 30.5956C3.36445 34.9763 3.0878 39.9276 3.51148 44.6845H3.47828C3.72332 47.3878 4.21181 50.0263 4.94376 52.453C7.66447 61.4435 14.2757 68.578 22.6971 72.5682C27.258 74.734 32.3548 75.809 37.5353 76.054C42.8281 76.2991 48.2031 75.6304 53.1719 74.2629C53.4975 74.1823 53.8074 74.1001 54.1331 74.0021C58.0252 72.8622 61.413 71.1027 64.3124 68.7741C67.4726 66.2494 70.0779 63.0734 72.0983 59.1812C72.5377 58.3339 72.9282 57.4881 73.3029 56.6234C75.6805 51.1013 76.7397 45.1082 76.5279 39.164L76.5437 39.1482V39.1467Z'
            fill='#FFE9E3'
          />
        </Mask>
        <G mask='url(#mask0_4898_179384)'>
          <Path
            d='M29.8925 18.8633C29.8925 18.8633 44.1956 15.5102 45.5565 15.6696C46.8128 15.8164 47.7065 16.1633 48.7378 19.1586C49.7315 22.0446 56.5268 51.1336 56.5393 51.2024C57.2612 55.1883 55.4393 55.5727 53.7112 56.2618C52.0034 56.9446 32.9393 61.418 27.8643 62.5977C26.5346 62.9071 25.08 62.9477 23.9581 62.5633C23.0706 62.2586 22.1206 62.0071 21.105 58.7477C19.8128 54.6039 14.0018 26.9836 14.0018 26.9836C14.0018 26.9836 13.3784 23.5836 16.7831 22.5539C20.1862 21.5243 29.8909 18.8633 29.8909 18.8633H29.8925Z'
            fill='#CCB5A3'
          />
          <Path
            d='M30.5785 21.586C30.5785 21.586 42.9754 18.6797 44.1535 18.8172C45.2426 18.9438 46.016 19.2438 46.9113 21.8422C47.7722 24.3438 53.6613 49.5532 53.6722 49.6125C54.2972 53.0672 52.7191 53.4 51.2222 53.9969C49.7425 54.5875 33.2207 58.4657 28.8222 59.4875C27.6707 59.7547 26.4097 59.7907 25.4379 59.4579C24.6676 59.1938 23.8457 58.9766 22.9644 56.15C21.8457 52.5594 16.8082 28.6219 16.8082 28.6219C16.8082 28.6219 16.2691 25.675 19.2176 24.7829C22.1676 23.8907 30.5785 21.5844 30.5785 21.5844V21.586Z'
            fill='#F7F5EF'
          />
          <Path
            d='M26.0852 19.1661C26.0852 19.1661 21.0039 19.9849 21.0789 21.5552C21.1524 23.1239 21.1852 25.5317 23.7149 25.3317C26.2446 25.1317 40.0852 21.7021 40.9274 20.8771C41.7711 20.0505 41.1008 16.2802 39.7571 16.0552C38.4133 15.8302 34.6086 17.0989 34.6086 17.0989C34.6086 17.0989 32.9664 13.9083 29.7711 14.8911C26.5758 15.8739 26.0868 19.1677 26.0868 19.1677L26.0852 19.1661Z'
            fill='#A99881'
          />
          <Path
            d='M25.5207 36.7222C24.0348 36.7222 22.8739 36.3691 22.8457 36.3597C22.5989 36.2816 22.4614 36.0191 22.5395 35.7722C22.6176 35.5253 22.8801 35.3878 23.127 35.466C23.3551 35.5378 28.3426 37.0378 30.2707 33.141C29.2801 32.8035 28.7317 32.2003 28.4379 31.716C27.6832 30.4753 27.9129 28.9441 28.6004 28.2925C29.0707 27.8457 30.2504 27.5707 31.1051 28.1378C31.8239 28.6144 32.4551 29.7488 31.5551 32.441C32.541 32.5128 33.7598 32.3113 35.1536 31.8285C36.6645 31.305 37.7786 30.7019 38.4082 30.091C37.7364 29.8628 37.2989 29.4457 37.0754 29.0566C36.6332 28.2863 36.8254 27.4207 37.5317 26.9972C37.8879 26.7832 38.4051 26.8535 38.9145 27.1832C39.4786 27.5488 40.0395 28.2707 39.927 29.1988C39.9223 29.2347 39.9176 29.2707 39.9114 29.3066C40.5629 29.2394 41.3754 28.9894 42.3661 28.4457C42.5926 28.3207 42.8786 28.4035 43.002 28.63C43.1254 28.8566 43.0442 29.1425 42.8176 29.266C41.4879 29.9972 40.3989 30.266 39.5348 30.266C39.5223 30.266 39.5114 30.266 39.4989 30.266C38.852 31.1863 37.502 32.0066 35.4629 32.7128C33.816 33.2832 32.3879 33.4972 31.2098 33.3503C29.9629 36.116 27.4645 36.7191 25.5207 36.7191V36.7222ZM29.9879 28.755C29.6614 28.755 29.3567 28.8691 29.2473 28.9738C28.891 29.3128 28.7098 30.3597 29.2395 31.23C29.5426 31.7269 30.0129 32.0753 30.6254 32.2707C31.2223 30.5472 31.2129 29.3347 30.5879 28.9207C30.4067 28.8003 30.1926 28.755 29.9879 28.755ZM37.9957 27.8144C37.6989 28.0066 37.7504 28.3519 37.8895 28.591C38.041 28.855 38.3895 29.1472 38.9582 29.2691C39.0145 29.08 39.0176 28.8925 38.9692 28.7097C38.8114 28.1191 38.1817 27.7925 37.9957 27.8128V27.8144Z'
            fill='#E8E4DB'
          />
          <Path
            d='M27.6833 44.534C25.988 44.534 24.9583 43.7622 24.9036 43.72C24.6989 43.5622 24.6599 43.2684 24.8177 43.0622C24.9755 42.8575 25.2677 42.8184 25.4739 42.9747C25.5333 43.02 26.8739 43.995 29.0286 43.409C29.7021 43.2262 30.3661 42.8247 30.8974 42.3669C30.5146 41.9544 30.3099 41.4184 30.3818 40.9106C30.4646 40.334 30.9052 39.9356 31.5333 39.87C32.6536 39.7559 32.8693 40.4278 32.9083 40.6372C32.988 41.0669 32.7755 41.6044 32.3802 42.1403C33.3474 42.2747 34.5786 41.445 35.7568 39.8387C35.8724 39.6825 36.0708 39.6137 36.2583 39.6637C36.2755 39.6684 38.0489 40.1309 39.3864 39.1903C39.5989 39.0419 39.8911 39.0934 40.0396 39.3044C40.188 39.5169 40.1364 39.809 39.9255 39.9575C38.5896 40.895 36.9974 40.7544 36.3286 40.6356C35.4614 41.7512 33.6052 43.6997 31.6943 42.9184C31.6896 42.9169 31.6849 42.9153 31.6802 42.9122C31.0021 43.5403 30.1286 44.0794 29.2739 44.3122C28.6958 44.47 28.163 44.5309 27.6833 44.5309V44.534ZM31.7849 40.795C31.7411 40.795 31.6896 40.7981 31.6286 40.8044C31.3396 40.834 31.3193 40.9762 31.3099 41.045C31.2849 41.2153 31.363 41.47 31.5505 41.6934C31.6646 41.5497 31.7599 41.4059 31.8349 41.27C31.9693 41.0247 31.9911 40.8762 31.9864 40.8231C31.9614 40.8137 31.9021 40.795 31.7849 40.795Z'
            fill='#E8E4DB'
          />
          <Path
            d='M30.2174 52.7396C28.4658 52.7396 26.8642 52.0817 26.7627 52.0396C26.5236 51.9396 26.4111 51.6646 26.5111 51.4255C26.6111 51.1864 26.8861 51.0739 27.1236 51.1739C27.1689 51.1927 30.4845 52.5458 32.397 51.2208C34.3595 49.8614 34.6986 48.8333 34.7002 48.8239C34.747 48.6599 34.8799 48.5333 35.047 48.4958C35.2142 48.4583 35.3892 48.5146 35.5017 48.6427C35.5424 48.6864 36.5658 49.7739 38.4424 48.938C38.6783 48.8333 38.9564 48.938 39.0611 49.1755C39.1658 49.4114 39.0611 49.6896 38.8236 49.7942C37.1424 50.5411 35.9424 50.088 35.3095 49.6802C34.9799 50.2114 34.2986 51.0411 32.9299 51.9896C32.1189 52.5521 31.147 52.7364 30.2174 52.7364V52.7396Z'
            fill='#E8E4DB'
          />
          <Path
            d='M59.5672 63.4406H44.7109C43.6953 63.4406 42.8703 62.6172 42.8703 61.6L42.1719 37.7891L62.4125 39.0109L61.4047 61.6391C61.3828 62.6391 60.5656 63.4391 59.5656 63.4391L59.5672 63.4406Z'
            fill='#FFC03C'
          />
          <Path
            d='M58.3973 54.5719H49.3082C48.216 54.5719 47.3301 53.525 47.3301 52.2328V45.175C47.3301 43.8828 48.216 42.8359 49.3082 42.8359H58.8676C59.9598 42.8359 60.8457 43.8828 60.8457 45.175L60.377 52.2328C60.377 53.525 59.491 54.5719 58.3988 54.5719H58.3973Z'
            fill='white'
          />
          <Path d='M58.8574 45.6406H49.7715V46.9656H58.8574V45.6406Z' fill='#DCCDBF' />
          <Path d='M58.8574 49.1797H49.7715V50.3906H58.8574V49.1797Z' fill='#DCCDBF' />
          <Path
            d='M62.6824 36.0094L42.0152 35.875C41.6792 35.8734 41.3995 36.1328 41.3777 36.4672L41.1714 39.5563C41.1464 39.9219 41.4371 40.2328 41.8042 40.2328H62.7995C63.1605 40.2328 63.448 39.9328 63.4339 39.5719L63.312 36.6172C63.298 36.2781 63.0214 36.0109 62.6824 36.0094Z'
            fill='#6ECBD3'
          />
          <Path
            d='M61.2391 33.6266L43.7625 32.9969C43.2141 32.9766 42.7328 33.3251 42.6344 33.8126L41.8359 37.8219H62.7516L62.2875 34.4938C62.2203 34.0126 61.7766 33.6454 61.2391 33.6266Z'
            fill='#6ECBD3'
          />
        </G>
      </G>
      <Defs>
        <ClipPath id='clip0_4898_179384'>
          <Rect width='80' height='80' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

type MedsConsultationSecondaryRecProps = {
  width?: number
  height?: number
  outerCircleBgColor?: string
  innerCircleBgColor?: string
}

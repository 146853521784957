import React from 'react'

import { fromJS } from 'immutable'

import { DEFAULT_WORKLIFE_SERVICES, WORKLIFE_SERVICE_IDS } from '../../../features/workLife/constants/workLifeConstants'
import ConciergeIllustration from '../../../images/microsite/illustration-concierge.png'
import DependentsIllustration from '../../../images/microsite/illustration-dependents.png'
import { oneLine } from '../../utils/stringUtils'

export const fidelity = () => {
  return fromJS({
    workLifeServices: [
      DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT,
      {
        identifiers: {
          id: 'concierge',
          dataTestId: 'workLife-selectConciergeServices',
        },
        title: 'Concierge Services with Circles',
        img: ConciergeIllustration,
        bullets: [
          'Event support: ideas, tickets, reservations',
          'Product research and gift suggestions',
          'Personal travel arrangements',
          'Home help: contractors, landscapers, etc.',
          <>
            <a
              href='https://fmrbenefits.com/WorkandLife/Home-and-Travel/Concierge-Services'
              target='_blank'
              rel='noreferrer'
            >
              Learn more
            </a>
            {oneLine(` ways Fidelity Concierge helps`)}
          </>,
        ],
        CTA: 'Access a Fidelity concierge',
        PATH: 'https://community.circles.com/fidelity',
      },
      {
        identifiers: WORKLIFE_SERVICE_IDS.DEPENDENT_CARE,
        title: 'Care Coordination with Wellthy',
        img: DependentsIllustration,
        bullets: [
          'Care management for you and your loved ones',
          'Help managing complex and ongoing needs',
          'Support selecting providers and in-home care',
          <>
            <a href='https://fmrbenefits.com/WorkandLife/Family/Raise-Caregiver' target='_blank' rel='noreferrer'>
              Learn more
            </a>
            {oneLine(` about care coordination`)}
          </>,
        ],
        CTA: 'Connect to a care professional',
        PATH: 'https://wellthy.com/fidelity/',
      },
    ],
  })
}

import { defineMessage, MessageDescriptor } from 'react-intl'

import {
  WellbeingResult,
  WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE,
  WellnessCheckInFacetGroup,
  WellnessCheckInResultsEssentialsArticleContent,
  WellnessCheckInResultsFacetGroupToContent,
} from '@lyrahealth-inc/shared-app-logic'

export const WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE_TO_TEXT: Record<
  WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE,
  MessageDescriptor
> = {
  [WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.LOW]: defineMessage({
    defaultMessage: 'Low',
    description: 'Wellness check in results domain low score',
  }),
  [WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.MODERATE]: defineMessage({
    defaultMessage: 'Moderate',
    description: 'Wellness check in results domain moderate score',
  }),
  [WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.HIGH]: defineMessage({
    defaultMessage: 'High',
    description: 'Wellness check in results domain high score',
  }),
}

const FACET_GROUP_TO_TITLE: Record<WellnessCheckInFacetGroup, MessageDescriptor> = {
  [WellnessCheckInFacetGroup.Mood]: defineMessage({
    defaultMessage: 'Mood',
    description: 'Mood content title',
  }),
  [WellnessCheckInFacetGroup.Emotions]: defineMessage({
    defaultMessage: 'Emotions',
    description: 'Emotions content title',
  }),
  [WellnessCheckInFacetGroup.Resilience]: defineMessage({
    defaultMessage: 'Resilience',
    description: 'Resilience content title',
  }),
  [WellnessCheckInFacetGroup.Coping]: defineMessage({
    defaultMessage: 'Coping',
    description: 'Coping content title',
  }),
  [WellnessCheckInFacetGroup.Relationships]: defineMessage({
    defaultMessage: 'Relationships',
    description: 'Relationships content title',
  }),
  [WellnessCheckInFacetGroup.PhysicalActivity]: defineMessage({
    defaultMessage: 'Physical activity',
    description: 'Physical activity content title',
  }),
  [WellnessCheckInFacetGroup.Sleep]: defineMessage({
    defaultMessage: 'Sleep',
    description: 'Sleep content title',
  }),
  [WellnessCheckInFacetGroup.Pain]: defineMessage({
    defaultMessage: 'Pain',
    description: 'Pain content title',
  }),
  [WellnessCheckInFacetGroup.Finance]: defineMessage({
    defaultMessage: 'Finance',
    description: 'Finance content title',
  }),
  [WellnessCheckInFacetGroup.Work]: defineMessage({
    defaultMessage: 'Work',
    description: 'Work content title',
  }),
  [WellnessCheckInFacetGroup.NewExperiences]: defineMessage({
    defaultMessage: 'New experiences',
    description: ' New experiences content title',
  }),
  [WellnessCheckInFacetGroup.Spiritual]: defineMessage({
    defaultMessage: 'Spiritual',
    description: 'Spiritual content title',
  }),
  [WellnessCheckInFacetGroup.Nature]: defineMessage({
    defaultMessage: 'Nature',
    description: ' Nature content title',
  }),
}

const articleType = defineMessage({
  defaultMessage: 'Article',
  description: 'Article essential type content',
})

const topicType = defineMessage({
  defaultMessage: 'Topic',
  description: 'Topic essential type content',
})

const meditationType = defineMessage({
  defaultMessage: 'Meditation',
  description: 'Meditation essential type content',
})

const soundscapesType = defineMessage({
  defaultMessage: 'Soundscapes',
  description: 'Soundscapes essential type content',
})

const minuteAttribute = defineMessage({
  defaultMessage: '{minute} min',
  description: 'Minute essential attribute content',
})

const episodeAttribute = defineMessage({
  defaultMessage: '{episode} episodes',
  description: 'Episode essential attribute content',
})

const itemAttribute = defineMessage({
  defaultMessage: '{item} items',
  description: 'Item essential attribute content',
})

const relationshipArticles: WellnessCheckInResultsEssentialsArticleContent[] = [
  {
    title: defineMessage({
      defaultMessage: 'Thriving at work',
      description: 'Low essential article title content for relationships',
    }),
    description: defineMessage({
      defaultMessage: 'Find more focus and joy at work',
      description: 'Low essential article body content for relationships',
    }),
    type: topicType,
    attribute: itemAttribute,
    subPath: 'essentials/topics/thriving-at-work',
    imageUrl: require('../../assets/wellnessCheckIn/ThrivingAtWork.png'),
    attributeDescValues: { item: '22' },
  },
  {
    title: defineMessage({
      defaultMessage: 'Parenting support',
      description: 'Low essential article title content for relationships part 2',
    }),
    description: defineMessage({
      defaultMessage: 'Balance the needs of your family with intention',
      description: 'Low essential article body content for relationships part 2',
    }),
    type: topicType,
    attribute: itemAttribute,
    subPath: 'essentials/topics/parenting-support',
    imageUrl: require('../../assets/wellnessCheckIn/ParentingSupport.png'),
    attributeDescValues: { item: '20' },
  },
  {
    title: defineMessage({
      defaultMessage: 'Strengthening relationships',
      description: 'Low essential article title content for relationships part 3',
    }),
    description: defineMessage({
      defaultMessage: 'Build and maintain relationships that support your well-being',
      description: 'Low essential article body content for relationships part 3',
    }),
    type: topicType,
    attribute: itemAttribute,
    subPath: 'essentials/topics/strengthening-relationships',
    imageUrl: require('../../assets/wellnessCheckIn/StrengtheningRelationships.png'),
    attributeDescValues: { item: '20' },
  },
]

const natureArticles = [
  {
    title: defineMessage({
      defaultMessage: 'Nature escapes',
      description: 'Low essential article title content for spiritual',
    }),
    description: defineMessage({
      defaultMessage: 'Environments to soothe and focus',
      description: 'Low essential article body content for spiritual',
    }),
    type: soundscapesType,
    attribute: itemAttribute,
    subPath: 'essentials/tools/soundscapes',
    imageUrl: require('../../assets/wellnessCheckIn/NatureEscapes.png'),
    attributeDescValues: { item: '4' },
  },
]

export const FACET_GROUP_TO_LOW_CONTENT: WellnessCheckInResultsFacetGroupToContent = {
  [WellnessCheckInFacetGroup.Mood]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Mood],
    body: defineMessage({
      defaultMessage:
        'Based on your answers, it seems like you may be struggling with depression, anxiety, or stress. While such challenges are common, how they are experienced and their impact can be quite different for people. Finding the support that best matches your unique situation is important. A Lyra mental health provider can guide you through the most helpful approach to improve your emotional wellbeing.{br}{br}' +
        'Lyra’s providers have extensive expertise in depression, anxiety, and stress—and when you register for Lyra, you can search for providers who focus on one (or all) of these areas.{br}{br}' +
        'Learn more about what to expect when working with a mental health provider with Lyra’s “Seeking Care” page.{br}{br}',
      description: 'Low essential body content for mood',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: 'Seeking care',
          description: 'Low essential article title content for mood',
        }),
        description: defineMessage({
          defaultMessage: 'Learn more about mental health resources for you',
          description: 'Low essential article body content for mood',
        }),
        type: defineMessage({
          defaultMessage: 'Video series',
          description: 'Low essential article type content for mood',
        }),
        attribute: episodeAttribute,
        subPath: 'essentials/articles/overcoming-stigma-and-normalizing-distress',
        imageUrl: require('../../assets/wellnessCheckIn/SeekingCare.png'),
        attributeDescValues: { episode: '6' },
      },
    ],
  },
  [WellnessCheckInFacetGroup.Emotions]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Emotions],
    body: defineMessage({
      defaultMessage:
        'It sounds like you haven’t been feeling enthusiastic lately. Your answers indicate that you may also be feeling ups and downs, depending on the day. These feelings are commonly known as “mood swings.” How you interact with others—and how you’re living your life—can have a big effect on how you’re feeling. It can be helpful to stay mindful about these things. {br}{br}' +
        'Consider taking a few moments to reflect on yourself and see what changes you can make to improve your emotional wellbeing. You may want to read about ways to become more aware of the present moment and engage in mindfulness practice.{br}',
      description: 'Low essential body content for emotions',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: 'It\'s Called Mindfulness "Practice" for a Reason',
          description: 'Low essential article title content for emotions',
        }),
        description: defineMessage({
          defaultMessage: "Remember: it's normal for minds to wander during mindfulness practice.",
          description: 'Low essential article body content for emotions',
        }),
        type: articleType,
        attribute: minuteAttribute,
        subPath: 'essentials/articles/mindfulness-its-about-practice-not-perfection',
        imageUrl: require('../../assets/wellnessCheckIn/Mindfulness.png'),
        attributeDescValues: { minute: '8' },
      },
    ],
  },
  [WellnessCheckInFacetGroup.Resilience]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Resilience],
    body: defineMessage({
      defaultMessage:
        'Based on what you shared, it seems like you may be having some difficulty coping with challenges or stressors. Others who share these struggles have found that working with a Lyra provider helped them build important skills to manage life’s stressors. If you’d like, Lyra can connect you with a provider who specializes in stress and coping.{br}{br}' +
        'Looking for something a bit more self-guided? Lyra has self-care resources, too. Consider starting with "The Power of Yes" and "What to Do When You\'re Feeling Lonely" to see what resonates with you. From there, you can dig deeper into the self-care library for more articles like these, plus helpful videos, soundscapes to wind down, and more.{br}',
      description: 'Low essential body content for resilience',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: 'The Power of Yes',
          description: 'Low essential article title content for resilience',
        }),
        description: defineMessage({
          defaultMessage: 'Finding freedom by changing what’s changeable, and letting go of what’s not.',
          description: 'Low essential article body content for resilience',
        }),
        type: articleType,
        attribute: minuteAttribute,
        subPath: 'essentials/articles/the-power-of-yes',
        imageUrl: require('../../assets/wellnessCheckIn/PowerOfYes.png'),
        attributeDescValues: { minute: '9' },
      },
      {
        title: defineMessage({
          defaultMessage: 'What to Do When You’re Feeling Lonely',
          description: 'Low essential article title content for resilience part 2',
        }),
        description: defineMessage({
          defaultMessage: 'Skills for feeling less lonely, even in a crowd.',
          description: 'Low essential article body content for resilience part 2',
        }),
        type: articleType,
        attribute: minuteAttribute,
        subPath: 'essentials/articles/what-to-do-when-youre-feeling-lonely',
        imageUrl: require('../../assets/wellnessCheckIn/FeelingLonely.png'),
        attributeDescValues: { minute: '8' },
      },
    ],
  },
  [WellnessCheckInFacetGroup.Coping]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Coping],
    body: defineMessage({
      defaultMessage:
        'Based on what you shared, it sounds like you may have been avoiding problems recently. People who avoid their problems often report feeling stuck in a cycle of procrastination. Consider learning specific ways to break out of this cycle of procrastination or to face your challenges more directly. The self-care library is ready to support you on this path. Check out these essential guides on “Taming Procrastination,” or work on facing difficult situations with “Facing Your Fears.”{br}',
      description: 'Low essential body content for coping',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: 'Taming procrastination',
          description: 'Low essential article title content for coping',
        }),
        description: defineMessage({
          defaultMessage: 'Learn why we procrastinate and develop skills for overcoming temptations to procrastinate.',
          description: 'Low essential article body content for coping',
        }),
        type: articleType,
        attribute: minuteAttribute,
        subPath: 'essentials/articles/taming-procrastination',
        imageUrl: require('../../assets/wellnessCheckIn/TamingProcrastination.png'),
        attributeDescValues: { minute: '8' },
      },
      {
        title: defineMessage({
          defaultMessage: 'Facing your fears',
          description: 'Low essential article title content for coping part 2',
        }),
        description: defineMessage({
          defaultMessage: '5 practical steps to overcome the counterproductive tendency of avoiding your fears.',
          description: 'Low essential article body content for coping part 2',
        }),
        type: articleType,
        attribute: minuteAttribute,
        subPath: 'essentials/articles/facing-your-fears',
        imageUrl: require('../../assets/wellnessCheckIn/FacingYourFears.png'),
        attributeDescValues: { minute: '4' },
      },
    ],
  },
  [WellnessCheckInFacetGroup.Relationships]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Relationships],
    body: defineMessage({
      defaultMessage:
        'When we asked about your roles and relationships, your answers seemed to reflect that there’s room for improvement. If you’re interested in learning ways to better manage your relationships, consider exploring self-care topics like “Thriving at Work,” “Parenting Support,” and “Strengthening Relationships.”{br}{br}' +
        'If you’re interested in something more tailored specifically to you, Lyra mental health providers are experts in helping people manage the demands of work, social, and home responsibilities.{br}',
      description: 'Low essential body content for relationships',
    }),
    articleContents: relationshipArticles,
  },
  [WellnessCheckInFacetGroup.PhysicalActivity]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.PhysicalActivity],
    body: defineMessage({
      defaultMessage:
        'Your responses indicate that you may be struggling to make time to take care of yourself. Making time for exercise or self-care activities can be difficult for many people. Yet, most people find that these types of activities do a lot to improve their well-being. {br}{br}' +
        'Try the self-help library to find ways to strengthen your self-care routine. For example you can start by reading “Building Resilience Through Self-Care.”{br}',
      description: 'Low essential body content for physical activity',
      articleContents: [
        {
          title: defineMessage({
            defaultMessage: 'Building Resilience through Self-Care',
            description: 'Low essential article title content for physical activity',
          }),
          description: defineMessage({
            defaultMessage: 'Prioritizing long-term goals by weighing the pros and cons to self-care',
            description: 'Low essential article body content for physical activity',
          }),
          type: articleType,
          attribute: minuteAttribute,
          subPath: 'essentials/articles/building-resilience-through-self-care',
          imageUrl: require('../../assets/wellnessCheckIn/BuildingResilience.png'),
          attributeDescValues: { minute: '7' },
        },
      ],
    }),
  },
  [WellnessCheckInFacetGroup.Sleep]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Sleep],
    body: defineMessage({
      defaultMessage:
        'It seems like you’re having some difficulty sleeping. Sleep is a common challenge for many people—with outside forces and life stressors often leading to difficulty winding down. If you want to learn some new ways to improve your sleep, check out the article “Sleep 101: How Sleep Works & How to Sleep Better.”{br}{br}' +
        'You may also consider meeting with a Lyra therapist to get more tailored advice on improving any insomnia symptoms you may experience.{br}',
      description: 'Low essential body content for sleep',
      articleContents: [
        {
          title: defineMessage({
            defaultMessage: 'Sleep 101: How Sleep Works & How to Sleep Better',
            description: 'Low essential article title content for sleep',
          }),
          description: defineMessage({
            defaultMessage: 'How sleep works, what causes sleep troubles, and skills to promote good sleep',
            description: 'Low essential article body content for sleep',
          }),
          type: articleType,
          attribute: minuteAttribute,
          subPath: 'essentials/articles/sleep-101-how-sleep-works-how-to-sleep-better',
          imageUrl: require('../../assets/wellnessCheckIn/Sleep.png'),
          attributeDescValues: { minute: '7' },
        },
      ],
    }),
  },
  [WellnessCheckInFacetGroup.Pain]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Pain],
    body: defineMessage({
      defaultMessage:
        'Some of your answers about your physical health indicate that you may have had some pain or discomfort in the last two weeks. Though it’s hard to do sometimes, taking it slow and pacing yourself could be helpful. Consider reading the article “Managing Chronic Pain with Pacing” for additional support.{br}{br}' +
        'You may also consider booking an initial appointment with a Lyra health provider. Our experts are well-versed in helping people cope with physical health conditions.{br}',
      description: 'Low essential body content for pain',
      articleContents: [
        {
          title: defineMessage({
            defaultMessage: 'Managing chronic pain with pacing',
            description: 'Low essential article title content for pain',
          }),
          description: defineMessage({
            defaultMessage: 'Finding balanced ways to respond to chronic pain',
            description: 'Low essential article body content for pain',
          }),
          type: articleType,
          attribute: minuteAttribute,
          subPath: 'essentials/articles/managing-chronic-pain-with-pacing',
          imageUrl: require('../../assets/wellnessCheckIn/ChronicPain.png'),
          attributeDescValues: { minute: '8' },
        },
      ],
    }),
  },
  [WellnessCheckInFacetGroup.Finance]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Finance],
    body: defineMessage({
      defaultMessage:
        'It sounds like you may be experiencing heightened levels of financial stress. While this can take many shapes and forms, emotional tension related to money is unfortunately quite common. In fact, most people find themselves stressed about money at least some of the time.{br}{br}' +
        'While you may not fully control the source of your financial concerns right now, there are strategies that can help better manage your financial stress.   As a starting point, check out the article “Coping with Financial Anxiety.”{br}',
      description: 'Low essential body content for finance',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: 'Coping with Financial Anxiety',
          description: 'Low essential article title content for finance',
        }),
        description: defineMessage({
          defaultMessage: '5 steps to lowering financial anxiety',
          description: 'Low essential article body content for finance',
        }),
        type: articleType,
        attribute: minuteAttribute,
        subPath: 'essentials/articles/coping-with-financial-anxiety',
        imageUrl: require('../../assets/wellnessCheckIn/Coping.png'),
        attributeDescValues: { minute: '7' },
      },
    ],
  },
  [WellnessCheckInFacetGroup.Work]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Work],
    body: defineMessage({
      defaultMessage:
        'When we asked you about your work life, some of your answers indicated that you may be dissatisfied, and may be struggling with symptoms of burnout. These concerns are unfortunately quite common. They are also treatable. Consider reading the article “Burnout 101” to learn more about burnout and specific steps to better manage it. {br}{br}' +
        'For one-on-one advice, consider booking an initial appointment with a trained Lyra provider. Be sure to use the preferences filters to find a provider that fits your needs and your background.{br}',
      description: 'Low essential body content for work',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: 'Burnout 101: Symptoms and coping',
          description: 'Low essential article title content for work',
        }),
        description: defineMessage({
          defaultMessage: 'How to recognize and relieve symptoms of burnout',
          description: 'Low essential article body content for work',
        }),
        type: articleType,
        attribute: minuteAttribute,
        subPath: 'essentials/articles/burnout-101-symptoms-and-coping',
        imageUrl: require('../../assets/wellnessCheckIn/Burnout.png'),
        attributeDescValues: { minute: '7' },
      },
    ],
  },
  [WellnessCheckInFacetGroup.NewExperiences]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.NewExperiences],
    body: defineMessage({
      defaultMessage:
        'Your answers seem to indicate that you may find it difficult sometimes to try new things. Some people find that being open to new experiences helps to improve their full sense of satisfaction and wellbeing.{br}{br}' +
        'If you’ve been feeling stuck or stagnant lately, consider making a plan to do something new and different this week. Getting out of your regular routine can help you find new things to enjoy. Check out the self-care library for ideas on how to do this—starting with the “Waking Up to Our Life” meditation.{br}',
      description: 'Low essential body content for new experiences',
      articleContents: [
        {
          title: defineMessage({
            defaultMessage: 'Waking Up to Our Life',
            description: 'Low essential article title content for new experience',
          }),
          description: defineMessage({
            defaultMessage: 'Enhance your life by cultivating appreciation for each new moment',
            description: 'Low essential article body content for new experience',
          }),
          type: meditationType,
          attribute: minuteAttribute,
          subPath: 'essentials/articles/waking-up-to-our-life',
          imageUrl: require('../../assets/wellnessCheckIn/WakingUp.png'),
          attributeDescValues: { minute: '11' },
        },
      ],
    }),
  },
  [WellnessCheckInFacetGroup.Spiritual]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Spiritual],
    body: defineMessage({
      defaultMessage:
        'Your responses indicate that your spiritual needs have not been met over the past two weeks. While spirituality plays different roles in peoples’ lives, some report that it has a strong impact on their wellbeing. If you’re looking to improve your spiritual wellbeing, consider being intentional about creating time and space for spiritual practices.{br}{br}' +
        'To focus this part of your life even more, you might consider meeting with a Lyra provider for one-on-one support. You can even filter your provider search by mental health providers with specific religious or spiritual backgrounds.{br}',
      description: 'Low essential body content for spiritual',
    }),
  },
  [WellnessCheckInFacetGroup.Nature]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Nature],
    body: defineMessage({
      defaultMessage:
        'Many people benefit from spending meaningful time out in nature—and it looks like it’s been difficult to have  that be part of your life lately. Consider whether setting a goal to spend more time in nature would be helpful for your overall wellbeing.{br}{br}' +
        'Many people find that listening to the sounds of nature helps reduce stress, even when spending time indoors. You can tap into this by checking out the “Nature Escapes” section in the self-care library for different environmental soundscapes.{br}',
      description: 'Low essential body content for nature',
      articleContents: natureArticles,
    }),
  },
}

export const FACET_GROUP_TO_HIGH_CONTENT: WellnessCheckInResultsFacetGroupToContent = {
  [WellnessCheckInFacetGroup.Mood]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Mood],
    body: defineMessage({
      defaultMessage:
        'Overall, your emotional wellbeing appears to be in a good place right now. Consider taking some time to reflect on what is going well for you. Think about the things you have been doing to manage your mood and emotions, so you can continue to build on these positive techniques.{br}{br}' +
        'Lyra has a full library of self-care resources to help you do just that. Use these resources to build on an already healthy approach to mental wellbeing—focusing on topics like sleep techniques, guided meditation, and more.{br}',
      description: 'High essential body content for mood',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: 'Self-care library',
          description: 'Low essential article title content for mood',
        }),
        description: defineMessage({
          defaultMessage: 'Essentials for your personal life',
          description: 'High essential article body content for mood',
        }),
        type: defineMessage({
          defaultMessage: 'Meditations, courses, videos, and more',
          description: 'High essential article type content for mood',
        }),
        subPath: 'essentials',
        imageUrl: require('../../assets/wellnessCheckIn/SelfCareLibrary.png'),
      },
    ],
  },
  [WellnessCheckInFacetGroup.Emotions]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Emotions],
    body: defineMessage({
      defaultMessage:
        'After reviewing the answers provided, it seems like you’ve been looking forward to moments in your life. It also seems like your mood has been stable recently. This is worth celebrating. Consider what choices you’ve been making that may have led to these positive outcomes. And if you want to build on this positivity, check out the self-care library for even more resources, such as guided meditations, soundscapes, and articles.{br}{br}' +
        'Consider starting with “10 Daily Opportunities for Mindfulness” as many members find that resource particularly valuable.{br}',
      description: 'High essential body content for emotions',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: '10 Daily Opportunities for Mindfulness',
          description: 'High essential article title content for emotions',
        }),
        description: defineMessage({
          defaultMessage: 'Combining mindfulness with everyday activities to lower your stress and be more present.',
          description: 'High essential article body content for emotions',
        }),
        type: defineMessage({
          defaultMessage: 'Video',
          description: 'Video essential type content',
        }),
        attribute: minuteAttribute,
        subPath: 'essentials/articles/10-daily-opportunities-for-mindfulness',
        imageUrl: require('../../assets/wellnessCheckIn/Opportunities.png'),
        attributeDescValues: { minute: '17' },
      },
    ],
  },
  [WellnessCheckInFacetGroup.Resilience]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Resilience],
    body: defineMessage({
      defaultMessage:
        'In answering questions about resilience, you indicated that you’ve been meeting your life challenges with a positive outlook. It also seems like you feel supported by the people in your life. To build on these strengths even more, consider exploring resources about coping with challenges under the “Managing Stress” topic in the self-care library.{br}',
      description: 'High essential body content for resilience',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: 'Managing stress',
          description: 'High essential article title content for resilience',
        }),
        description: defineMessage({
          defaultMessage: 'Build your toolbox for de-stressing',
          description: 'High essential article body content for resilience',
        }),
        type: topicType,
        attribute: itemAttribute,
        subPath: 'essentials/topics/managing-stress',
        imageUrl: require('../../assets/wellnessCheckIn/ManagingStress.png'),
        attributeDescValues: { item: '39' },
      },
    ],
  },
  [WellnessCheckInFacetGroup.Coping]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Coping],
    body: defineMessage({
      defaultMessage:
        'Your answers seem to indicate that you’ve been facing your problems head-on over the last couple of weeks—rather than avoiding things. This is a valuable coping skill and can help support your continued growth and resilience. You can read articles and watch videos filtered by specific subjects in the self-care library.{br}{br}' +
        'To learn more about ways to strengthen your resilience and coping skills, consider browsing the resources under the topic "Building Resilience.”{br}',
      description: 'High essential body content for coping',
      articleContents: [
        {
          title: defineMessage({
            defaultMessage: 'Building resilience',
            description: 'High essential article title content for coping',
          }),
          description: defineMessage({
            defaultMessage: 'Empower yourself to bounce back',
            description: 'High essential article body content for coping',
          }),
          type: topicType,
          attribute: itemAttribute,
          subPath: 'essentials/topics/building-resilience',
          imageUrl: require('../../assets/wellnessCheckIn/BuildingResilienceCoping.png'),
          attributeDescValues: { item: '31' },
        },
      ],
    }),
  },
  [WellnessCheckInFacetGroup.Relationships]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Relationships],
    body: defineMessage({
      defaultMessage:
        'Overall, you appear to be managing your social roles and relationships well right now. To continue to build on your strengths, consider browsing the self-care library to find the  resources most helpful to you. Take a look at “Thriving at Work,” “Parenting Support,” and “Strengthening Relationships” for starters.{br}',
      description: 'High essential body content for relationship',
    }),
    articleContents: relationshipArticles,
  },
  [WellnessCheckInFacetGroup.PhysicalActivity]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.PhysicalActivity],
    body: defineMessage({
      defaultMessage:
        'Based on your answers, it appears you’re making time for physical activity and to take care of yourself. Many people struggle to make time for these important routines and goals, so this may be a strength of yours.{br}{br}' +
        'You may be interested in other ways to increase your wellbeing like guided meditations or educational videos. Check out this meditation “Movement in the Moment,” and explore the full self-care library for more.{br}',
      description: 'High essential body content for physical activity',
      articleContents: [
        {
          title: defineMessage({
            defaultMessage: 'Movement in the Moment',
            description: 'High essential article title content for physical activity',
          }),
          description: defineMessage({
            defaultMessage: 'Find presence as you freely move your body however feels good to you',
            description: 'High essential article body content for physical activity',
          }),
          type: meditationType,
          attribute: minuteAttribute,
          subPath: 'essentials/articles/movement-in-the-moment',
          imageUrl: require('../../assets/wellnessCheckIn/Movement.png'),
          attributeDescValues: { minute: '8' },
        },
      ],
    }),
  },
  [WellnessCheckInFacetGroup.Sleep]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Sleep],
    body: defineMessage({
      defaultMessage:
        'It sounds like you have been getting high-quality sleep lately. Sleep quality can have a big impact on your overall wellbeing, so we encourage you to continue prioritizing this.  For more resources on relaxation and sleep, consider exploring the guided meditations and soundscapes in the self-care library.{br}',
      description: 'High essential body content for sleep',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: 'Practicing Meditation',
          description: 'High essential article title content for sleep',
        }),
        description: defineMessage({
          defaultMessage: 'Bring attention to everyday moments',
          description: 'High essential article body content for sleep',
        }),
        type: topicType,
        attribute: itemAttribute,
        subPath: 'essentials/topics/cultivating-mindfulness',
        imageUrl: require('../../assets/wellnessCheckIn/PracticingMeditation.png'),
        attributeDescValues: { item: '63' },
      },
    ],
  },
  [WellnessCheckInFacetGroup.Pain]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Pain],
    body: defineMessage({
      defaultMessage:
        'While it’s quite common for people to report physical health complaints, your answers tell us that you’re doing well in this area. To keep improving your wellbeing  consider accessing the self-care library for helpful articles on exercise, routines, and much more.{br}',
      description: 'High essential body content for pain',
    }),
  },
  [WellnessCheckInFacetGroup.Finance]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Finance],
    body: defineMessage({
      defaultMessage:
        'It sounds like your financial wellbeing is in a good place right now, and this may be a strength of yours.  Most people—even those at higher income levels—experience some stress about money sometimes.{br}{br}' +
        'To strengthen your financial and general wellbeing even more, consider exploring the self-care library.{br}',
      description: 'High essential body content for finance',
    }),
  },
  [WellnessCheckInFacetGroup.Work]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Work],
    body: defineMessage({
      defaultMessage:
        'Your responses indicate that you have been generally satisfied with your work lately. Consider taking a few moments to reflect on what’s been going well at work, and name the specific things you are grateful for about your role. Practicing gratitude around things going well in your life (not just limited to work) can help further strengthen your wellbeing.{br}{br}' +
        'You can learn more about how to strengthen emotional health in the self-care library. And be sure to explore the full library of work-related resources.{br}',
      description: 'High essential body content for work',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: 'Resources for your work life',
          description: 'High essential article title content for work',
        }),
        description: defineMessage({
          defaultMessage: 'Everyday support for professional challenges',
          description: 'High essential article body content for work',
        }),
        type: defineMessage({
          defaultMessage: 'Topics, events, and courses',
          description: 'High essential article type for work',
        }),
        attribute: minuteAttribute,
        subPath: 'work-hub',
        imageUrl: require('../../assets/wellnessCheckIn/Resource.png'),
        attributeDescValues: { minute: '11' },
      },
    ],
  },
  [WellnessCheckInFacetGroup.NewExperiences]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.NewExperiences],
    body: defineMessage({
      defaultMessage:
        'It sounds like you have been open to new experiences lately. This may be a strength of yours. Trying new things may help give you meaning and purpose. Want to take it even further? Explore the self-care library for new things to try—plus new ways to improve your mental and emotional wellbeing like video guides and articles. Consider starting with the “Power of Yes” article.{br}',
      description: 'High essential body content for new experience',
    }),
    articleContents: [
      {
        title: defineMessage({
          defaultMessage: 'The Power of Yes',
          description: 'High essential article title content for new experience',
        }),
        description: defineMessage({
          defaultMessage: 'Finding freedom by changing what’s changeable, and letting go of what’s not.',
          description: 'High essential article body content for new experience',
        }),
        type: articleType,
        attribute: minuteAttribute,
        subPath: 'essentials/articles/the-power-of-yes',
        imageUrl: require('../../assets/wellnessCheckIn/PowerOfYesNewExp.png'),
        attributeDescValues: { minute: '9' },
      },
    ],
  },
  [WellnessCheckInFacetGroup.Spiritual]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Spiritual],
    body: defineMessage({
      defaultMessage:
        'Based on what you shared, it seems that you’re focusing on spirituality lately. Take a moment to reflect on how you’ve been meeting your spiritual needs. If this is an area of life that is important to you, consider making a plan to keep prioritizing these needs.{br}',
      description: 'High essential body content for spiritual',
    }),
  },
  [WellnessCheckInFacetGroup.Nature]: {
    title: FACET_GROUP_TO_TITLE[WellnessCheckInFacetGroup.Nature],
    body: defineMessage({
      defaultMessage:
        'It looks like you’ve spent meaningful time in nature recently—which could be helping you to reduce feelings of stress and anxiety, and increase feelings of  happiness.{br}{br}' +
        'If spending time in nature feels important to your wellbeing, take a few moments to plan how you can continue to get benefits from your time outdoors.  Want to experience a connection to nature while being indoors? Explore the self-care nature soundscapes.{br}',
      description: 'High essential body content for nature',
    }),
    articleContents: natureArticles,
  },
}

export const WELLBEING_RESULT_TO_CONTENT_MAPPING: Record<WellbeingResult, WellnessCheckInResultsFacetGroupToContent> = {
  [WellbeingResult.Low]: FACET_GROUP_TO_LOW_CONTENT,
  [WellbeingResult.High]: FACET_GROUP_TO_HIGH_CONTENT,
}

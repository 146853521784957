export const ELECTION_MICROSITE_URL = '/election-microsite'

/** Add shared events here */
export const CMS_MICROSITE_MESSAGE_KEY = {
  CLICK_SELF_CARE_CONTENT: 'clickSelfCareContent',
} as const
export type CMS_MICROSITE_MESSAGE = (typeof CMS_MICROSITE_MESSAGE_KEY)[keyof typeof CMS_MICROSITE_MESSAGE_KEY]

export const ELECTION_CMS_MICROSITE_MESSAGE_KEY = {
  ...CMS_MICROSITE_MESSAGE_KEY,
  CLICK_WORKSHOP_REGISTER_NOW: 'clickWorkshopRegisterNow',
  CLICK_ELECTIONS_BOOK_A_SESSION_TODAY: 'clickElectionsBookASessionToday',
} as const
export type ELECTION_CMS_MICROSITE_MESSAGE =
  (typeof ELECTION_CMS_MICROSITE_MESSAGE_KEY)[keyof typeof ELECTION_CMS_MICROSITE_MESSAGE_KEY]

export const COMPLEX_CARE_CMS_MICROSITE_MESSAGE_KEY = {
  ...CMS_MICROSITE_MESSAGE_KEY,
  NAVIGATE_TO_MEDS_BOOKING: 'navigateToMedsBooking',
  NAVIGATE_TO_RENEW_BOOKING: 'navigateToRenewBooking',
  SCHEDULE_COMPLEX_CARE_CALL: 'scheduleComplexCareCall',
  SCHEDULE_DBT_ASSESSMENT_CALL: 'scheduleDBTAssessmentCall',
  NAVIGATE_TO_SUBPAGE: 'navigateToSubpage',
  OPEN_LEARN_MORE_PANEL: 'openLearnMorePanel',
  CLOSE_LEARN_MORE_PANEL: 'closeLearnMorePanel',
  EXPAND_FAQ: 'expandFAQ',
} as const
export type COMPLEX_CARE_CMS_MICROSITE_MESSAGE =
  (typeof COMPLEX_CARE_CMS_MICROSITE_MESSAGE_KEY)[keyof typeof COMPLEX_CARE_CMS_MICROSITE_MESSAGE_KEY]

import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Platform } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { Tooltip, TOOLTIP_TRIGGER_ICON } from '../../atoms/tooltip/Tooltip'
import { IS_WEB } from '../../constants'
import {
  IntegratedBenefitsItem,
  IntegratedBenefitsItemProps,
} from '../../molecules/integratedBenefitsItem/IntegratedBenefitsItem'
import { ThemeType } from '../../utils'

export interface IntegratedBenefitsLabeledItemGroupProps {
  label?: string
  items: IntegratedBenefitsItemProps[]
  showRecommendedBenefitsTooltip?: boolean
  coverageMessageLinkNode?: React.ReactNode
  widerBenefitsItemTitleContainer?: boolean
}

const Container = styled.View({
  flexShrink: 1,
})

const SubheadContainer = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      breakpoints: { isMinWidthTablet },
      spacing,
    },
  }) => ({
    flexDirection: 'row',
    marginBottom: isMinWidthTablet ? spacing['24px'] : spacing['16px'],
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
)

const CoveragelinkContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : '0',
  flexShrink: 1,
  alignSelf: 'center',
}))

const TitleContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
})

const ItemsGroupContainer = styled.View<{ theme: ThemeType }>(({ theme: { breakpoints, spacing, colors } }) => ({
  backgroundColor: colors.backgroundPrimary,
  flexShrink: 1,
  padding: breakpoints.isMinWidthTablet ? spacing['32px'] : spacing['16px'],
  borderRadius: spacing['16px'],
  boxShadow: `0px 2px 10px ${colors.components.integratedBenefitsLabeledItemGroup.itemGroup.boxShadow.fill}`,
  ...(Platform.OS === 'android'
    ? {
        elevation: '2',
        shadowColor: colors.components.integratedBenefitsLabeledItemGroup.itemGroup.shadow.fill,
      }
    : {}),
}))

const TooltipContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginLeft: spacing['8px'],
  flexShrink: 1,
}))

const FencepostContainer = styled.View<{ theme: ThemeType; showBorder: boolean }>(
  ({
    theme: {
      breakpoints: { isMinWidthTablet },
      spacing,
      colors,
    },
    showBorder,
  }) => ({
    flexShrink: 1,
    borderBottomWidth: showBorder ? '2px' : 0,
    marginBottom: showBorder ? (isMinWidthTablet ? spacing['24px'] : spacing['16px']) : 0,
    paddingBottom: showBorder ? (isMinWidthTablet ? spacing['24px'] : spacing['16px']) : 0,
    borderBottomColor: colors.dividerPrimary,
  }),
)

export const IntegratedBenefitsLabeledItemGroup: FunctionComponent<IntegratedBenefitsLabeledItemGroupProps> = ({
  label,
  items,
  showRecommendedBenefitsTooltip = false,
  coverageMessageLinkNode = undefined,
  widerBenefitsItemTitleContainer,
}: IntegratedBenefitsLabeledItemGroupProps) => {
  const {
    breakpoints: { isMinWidthTablet },
  } = useTheme()
  return (
    <Container>
      <SubheadContainer>
        <TitleContainer>
          <Subhead size={isMinWidthTablet ? SubheadSize.MEDIUM : SubheadSize.SMALL} text={label} />
          {showRecommendedBenefitsTooltip && (
            <TooltipContainer>
              <Tooltip
                placement='top'
                triggerConfig={{
                  icon: TOOLTIP_TRIGGER_ICON.INFO,
                  size: 24,
                  isFilled: false,
                }}
                content={
                  <FormattedMessage
                    defaultMessage='Each benefit is selected based on the information you’ve shared about your background, feelings, and interests.'
                    description='Tooltip explanation of the listed benefits'
                  />
                }
                hoverEnabled={IS_WEB}
              />
            </TooltipContainer>
          )}
        </TitleContainer>
        <TitleContainer>
          <CoveragelinkContainer>{coverageMessageLinkNode}</CoveragelinkContainer>
        </TitleContainer>
      </SubheadContainer>
      <ItemsGroupContainer>
        {items.length > 0 &&
          items.map((itemProps, idx) => (
            <FencepostContainer key={idx} showBorder={idx < items.length - 1}>
              <IntegratedBenefitsItem
                {...itemProps}
                widerBenefitsItemTitleContainer={widerBenefitsItemTitleContainer}
              />
            </FencepostContainer>
          ))}
      </ItemsGroupContainer>
    </Container>
  )
}

import { fromJS } from 'immutable'

import * as BannerActions from './banner-actions'

const defaultState = {
  isOpen: false,
  bannerType: '',
  message: '',
  includeX: false,
  bannerAction: '',
}

const banner = (state = fromJS(defaultState), action: $TSFixMe) => {
  switch (action.type) {
    case BannerActions.SET_BANNER_TYPE_AND_MESSAGE:
      return state
        .set('isOpen', true)
        .set('bannerType', action.bannerType)
        .set('message', action.message)
        .set('includeX', action.includeX)
        .set('bannerAction', action.bannerAction)
    case BannerActions.CLOSE_BANNER:
      return state.set('isOpen', false).set('includeX', false)
    default:
      return state
  }
}

export default banner

class GraphqlSchema {
  static getQuery() {
    return `
  __schema {
    types {
      name
      kind
      fields {
        name
        type {
          name
          kind
          ofType {
            name
            kind
          }
        }
      }
    }
  }
`
  }

  // Transform introspection AST into an easily searched map
  static transformSchemaToMap(schema: $TSFixMe) {
    const typeMapper = {}

    schema.types.map((type: $TSFixMe) => {
      if (type.kind === 'OBJECT' && !type.name.includes('_')) {
        typeMapper[type.name] = this.getFields(type.fields)
      }
    })

    return typeMapper
  }

  // Describe nested types
  static getFields(fields: $TSFixMe) {
    const subfieldMapper = {}
    fields.map((f: $TSFixMe) => {
      if (f.type.kind === 'LIST') {
        subfieldMapper[f.name] = [f.type.ofType.name]
      } else {
        subfieldMapper[f.name] = f.type.name
      }
    })
    return subfieldMapper
  }
}

export default GraphqlSchema

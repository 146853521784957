import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BRANCH_APP_LINK } from '@lyrahealth-inc/shared-app-logic'

import { PrimaryButton } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { LyraHealthAppIcon } from '../../atoms/icons/LyraHealthAppIcon'
import { openUrl } from '../../utils/expo-linking'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

const BannerContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  paddingVertical: '6.5px',
  paddingLeft: theme.spacing['16px'],
  paddingRight: theme.spacing['20px'],
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
  zIndex: 9,
  backgroundColor: theme.colors.backgroundPrimary,
}))

const TextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['12px'],
  flex: 1,
}))

const StyledPrimaryButton = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  minWidth: '70px',
  height: '31px',
  alignSelf: 'center',
  paddingHorizontal: theme.spacing['0px'],
  backgroundColor: '#006de5', // using a shade of blue that isn't used anywhere else in the app so don't need to use color token
}))

export const AppPromoBanner: FunctionComponent = () => {
  const { colors } = useTheme()

  return (
    <BannerContainer testID={tID('AppPromoBanner')}>
      <LyraHealthAppIcon size={35} />
      <TextContainer>
        <BodyText
          size={BodyTextSize.SMALL}
          text={
            <FormattedMessage
              defaultMessage='Lyra Health'
              description='Name of the company - used as header for banner that encourages user to download the Lyra mobile app'
            />
          }
        />
        <BodyText
          size={BodyTextSize.CAPTION}
          text={
            <FormattedMessage
              defaultMessage='Open in the Lyra app'
              description='Description for banner that encourages user to download the Lyra mobile app'
            />
          }
          color={colors.textSecondary}
        />
      </TextContainer>
      <StyledPrimaryButton
        text={
          <FormattedMessage
            defaultMessage='OPEN'
            description='Button that takes user to the app store if they do not have the Lyra mobile app, or opens the Lyra app if they have it'
          />
        }
        onPress={() => openUrl(BRANCH_APP_LINK)}
        testID={tID('AppPromoBanner-openAppButton')}
        customTextSize={BodyTextSize.SMALL}
      />
    </BannerContainer>
  )
}

import { defineMessage } from 'react-intl'

import { GetCustomerCopyFunc } from '@lyrahealth-inc/shared-app-logic'

import BrightHorizonsImage from '../assets/worklife/ReThink.png'
import { DEFAULT_WORKLIFE_SERVICES } from '../constants'

/* eslint-enable formatjs/no-literal-string-in-jsx */

export const abbvie: GetCustomerCopyFunc = () => {
  return {
    dependentButtonText: defineMessage({
      defaultMessage: 'No, I’m a dependent or household member',
      description: 'Declaring the current user is a dependent of an eligible user',
    }),
    workLifeServices: [
      DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.FINANCIAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT,
      DEFAULT_WORKLIFE_SERVICES.DEPENDENT_CARE,
      {
        identifiers: {
          id: 'brightHorizons',
          dataTestId: 'workLife-selectBrightHorizons',
        },
        title: 'Bright Horizons',
        img: BrightHorizonsImage,
        paragraph: '',
        bullets: [
          'Help finding caregiving support for infants to elders',
          'Backup care when regular arrangements fall through',
          'Educational resources and tutoring discounts',
        ],
        CTA: 'Access Bright Horizons',
        PATH: 'https://clients.brighthorizons.com/abbvie',
      },
    ],
  }
}

import { defineMessage, defineMessages, MessageDescriptor } from 'react-intl'

import { WellbeingResponse } from './types'

export enum WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE {
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
}

export enum WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE_LIMIT {
  LOW = 2.5,
  MODERATE = 4.5,
}

export enum WellnessCheckInDomain {
  MoodAndEmotions = 'moodAndEmotions',
  ResilienceAndCoping = 'resilienceAndCoping',
  RolesAndRelationships = 'rolesAndRelationships',
  PhysicalHealthAndActivity = 'physicalHealthAndActivity',
  FinancesAndWork = 'financesAndWork',
  SpiritualityAndExploration = 'spiritualityAndExploration',
}

export const WellnessCheckInDomainToTitle: Record<WellnessCheckInDomain, MessageDescriptor> = {
  [WellnessCheckInDomain.MoodAndEmotions]: defineMessage({
    defaultMessage: 'Mood and emotions',
    description: 'Mood and emotions domain display name for Wellness Check In flow',
  }),
  [WellnessCheckInDomain.ResilienceAndCoping]: defineMessage({
    defaultMessage: 'Resilience and coping',
    description: 'Resilience and coping domain display name for Wellness Check In flow',
  }),
  [WellnessCheckInDomain.RolesAndRelationships]: defineMessage({
    defaultMessage: 'Roles and relationships',
    description: 'Roles and relationships domain display name for Wellness Check In flow',
  }),
  [WellnessCheckInDomain.PhysicalHealthAndActivity]: defineMessage({
    defaultMessage: 'Physical health and activity',
    description: 'Physical health and activity domain display name for Wellness Check In flow',
  }),
  [WellnessCheckInDomain.FinancesAndWork]: defineMessage({
    defaultMessage: 'Finances and work',
    description: 'Finances and work domain display name for Wellness Check In flow',
  }),
  [WellnessCheckInDomain.SpiritualityAndExploration]: defineMessage({
    defaultMessage: 'Spirituality and exploration',
    description: 'Spirituality and exploration domain display name for Wellness Check In flow',
  }),
}

export enum WellnessCheckInFacetGroup {
  Mood = 'mood',
  Emotions = 'emotions',
  Resilience = 'resilience',
  Coping = 'coping',
  Relationships = 'relationships',
  PhysicalActivity = 'physicalActivity',
  Sleep = 'sleep',
  Pain = 'pain',
  Finance = 'finance',
  Work = 'work',
  NewExperiences = 'newExperiences',
  Spiritual = 'spiritual',
  Nature = 'nature',
}

export enum WellnessCheckInValency {
  High,
  Low,
}

export enum WellbeingResult {
  High = 'high',
  Low = 'low',
}

export const WELLNESS_CHECK_IN_FACET_GROUP_TO_DOMAIN: Record<WellnessCheckInFacetGroup, WellnessCheckInDomain> = {
  [WellnessCheckInFacetGroup.Mood]: WellnessCheckInDomain.MoodAndEmotions,
  [WellnessCheckInFacetGroup.Emotions]: WellnessCheckInDomain.MoodAndEmotions,
  [WellnessCheckInFacetGroup.Resilience]: WellnessCheckInDomain.ResilienceAndCoping,
  [WellnessCheckInFacetGroup.Coping]: WellnessCheckInDomain.ResilienceAndCoping,
  [WellnessCheckInFacetGroup.Relationships]: WellnessCheckInDomain.RolesAndRelationships,
  [WellnessCheckInFacetGroup.PhysicalActivity]: WellnessCheckInDomain.PhysicalHealthAndActivity,
  [WellnessCheckInFacetGroup.Sleep]: WellnessCheckInDomain.PhysicalHealthAndActivity,
  [WellnessCheckInFacetGroup.Pain]: WellnessCheckInDomain.PhysicalHealthAndActivity,
  [WellnessCheckInFacetGroup.Finance]: WellnessCheckInDomain.FinancesAndWork,
  [WellnessCheckInFacetGroup.Work]: WellnessCheckInDomain.FinancesAndWork,
  [WellnessCheckInFacetGroup.NewExperiences]: WellnessCheckInDomain.SpiritualityAndExploration,
  [WellnessCheckInFacetGroup.Spiritual]: WellnessCheckInDomain.SpiritualityAndExploration,
  [WellnessCheckInFacetGroup.Nature]: WellnessCheckInDomain.SpiritualityAndExploration,
}

export const INITIAL_WELLBEING_RESPONSE: WellbeingResponse = {
  [WellnessCheckInDomain.MoodAndEmotions]: {
    score: 0,
    response: [],
  },
  [WellnessCheckInDomain.ResilienceAndCoping]: {
    score: 0,
    response: [],
  },
  [WellnessCheckInDomain.RolesAndRelationships]: {
    score: 0,
    response: [],
  },
  [WellnessCheckInDomain.PhysicalHealthAndActivity]: {
    score: 0,
    response: [],
  },
  [WellnessCheckInDomain.FinancesAndWork]: {
    score: 0,
    response: [],
  },
  [WellnessCheckInDomain.SpiritualityAndExploration]: {
    score: 0,
    response: [],
  },
}

export enum HowAreYouFeelingTodayOption {
  Bad = 'bad',
  NotGood = 'notGood',
  Ok = 'ok',
  Good = 'good',
  Great = 'great',
}

export const HOW_ARE_YOU_FEELING_TODAY_MSG = defineMessages({
  [HowAreYouFeelingTodayOption.Bad]: {
    defaultMessage: 'I’m feeling bad',
    description: 'One of the options for slider input where user indicates their current mood',
  },
  [HowAreYouFeelingTodayOption.NotGood]: {
    defaultMessage: 'I’m not feeling good',
    description: 'One of the options for slider input where user indicates their current mood',
  },
  [HowAreYouFeelingTodayOption.Ok]: {
    defaultMessage: 'I’m ok',
    description: 'One of the options for slider input where user indicates their current mood',
  },
  [HowAreYouFeelingTodayOption.Good]: {
    defaultMessage: 'I’m feeling good',
    description: 'One of the options for slider input where user indicates their current mood',
  },
  [HowAreYouFeelingTodayOption.Great]: {
    defaultMessage: 'I’m feeling great',
    description: 'One of the options for slider input where user indicates their current mood',
  },
})

export const SOCIAL_CARE_OPTIONS_MSG = defineMessages({
  accessToFoodOrHousing: {
    defaultMessage: 'Access to food or housing',
    description: 'Checkbox option for user to select whether they need support for access to food or housing',
  },
  accessToTransportation: {
    defaultMessage: 'Access to transportation',
    description: 'Checkbox option for user to select whether they need support for access to transportation',
  },
  feelingSafeInMyCommunity: {
    defaultMessage: 'Feeling safe in my community',
    description: 'Checkbox option for user to select whether they need support for feeling safe in their community',
  },
  noneOfTheAbove: {
    defaultMessage: 'None of the above',
    description:
      'Checkbox option for user to select whether they do not need any of the listed options for social care support',
  },
})

export enum SOCIAL_NEED_OPTIONS {
  ACCESS_TO_FOOD_OR_HOUSING = 'accessToFoodOrHousing',
  ACCESS_TO_TRANSPORTATION = 'accessToTransportation',
  FEELING_SAFE_IN_MY_COMMUNITY = 'feelingSafeInMyCommunity',
  NONE_OF_THE_ABOVE = 'noneOfTheAbove',
}

export const ICON_CIRCLE_MOBILE_SIZE = 20
export const ICON_CIRCLE_WEB_SIZE = 16
export const MAX_NUMBER_CIRCLE = 3

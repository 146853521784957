import { fromJS } from 'immutable'

import * as LoadingIndicatorActions from './loading-indicator-actions'
const defaultState = {
  status: LoadingIndicatorActions.LOADING_STATUS.IDLE,
}

export const loadingIndicator = (state = fromJS(defaultState), action = null) => {
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  switch (action.type) {
    case LoadingIndicatorActions.SET_LOADING_INDICATOR_STATUS:
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      return state.set('status', action.status)
    default:
      return state
  }
}

export default loadingIndicator

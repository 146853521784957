import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Shield Icon that can be used for elements related to Health Plan Details
 */
export const ShieldIcon: FunctionComponent<ShieldIconProps> = ({ size = 24 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.737 1.298a.75.75 0 0 1 .526 0l8 3A.75.75 0 0 1 20.75 5v7c0 3.335-2.211 6.025-4.256 7.814a22.428 22.428 0 0 1-4.056 2.804l-.073.038-.02.01-.007.003-.001.001s-.002 0-.337-.67l-.336.67h-.002l-.006-.004-.02-.01-.074-.038a20.73 20.73 0 0 1-1.209-.7 22.426 22.426 0 0 1-2.847-2.104C5.461 18.025 3.25 15.334 3.25 12V5a.75.75 0 0 1 .487-.702l8-3ZM12 22l-.336.67a.75.75 0 0 0 .671 0L12 22Zm0-.85a20.946 20.946 0 0 0 3.506-2.464c1.955-1.711 3.744-4.02 3.744-6.686V5.52L12 2.8 4.75 5.52V12c0 2.665 1.789 4.975 3.744 6.686A20.949 20.949 0 0 0 12 21.149Z'
        fill={colors.iconActive}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 8.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Z'
        fill={colors.iconActive}
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25 12a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z'
        fill={colors.iconActive}
      />
    </Svg>
  )
}

export type ShieldIconProps = {
  size?: string | number
}

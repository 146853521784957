import { List, Map } from 'immutable'
import { createSelector } from 'reselect'

import {
  Appointment,
  ConversationObject,
  Episode,
  EpisodeStates,
  ImmutableTypedMap,
  Provider,
  ProviderInfo,
} from '@lyrahealth-inc/shared-app-logic'

import { getOnboardProviderProfileData } from '../../features/onboard/data/onboardSelectors'

const getImmutableLyraTherapyState = (state: Map<string, any>) => state?.get('lyraTherapy')
const getLyraTherapyState = createSelector(getImmutableLyraTherapyState, (immutableLyraTherapyState) => {
  return immutableLyraTherapyState?.toJS()
})
export const getAssignmentData = createSelector(getLyraTherapyState, (lyraTherapyState) => {
  return lyraTherapyState?.assignments?.data
})
export const getAssignmentSelectedIndex = createSelector(getLyraTherapyState, (lyraTherapyState) => {
  return lyraTherapyState?.assignments?.selectedIndex
})
export const getSelectedAssignment = createSelector(
  [getAssignmentData, getAssignmentSelectedIndex],
  (assignmentData, assignmentSelectedIndex) => {
    return assignmentData[assignmentSelectedIndex]
  },
)
export const getLyraTherapyEpisodes = createSelector(
  getLyraTherapyState,
  (lyraTherapyState) => lyraTherapyState.episodes,
)

export const getLyraTherapy = (state: Map<string, any>) => state?.get('lyraTherapy')

export const getEpisodes = createSelector(getLyraTherapy, (lyraTherapy) => lyraTherapy?.get('episodes', Map()))

export const getOutcomes = createSelector(getLyraTherapy, (lyraTherapy) => lyraTherapy?.get('outcomes', Map()))

export const getOutcomesData = createSelector(getOutcomes, (outcomes) => outcomes?.get('data', List()))

export const getSessions = createSelector(getLyraTherapy, (lyraTherapy) => lyraTherapy?.get('sessions'))

export const getPrograms = createSelector(getLyraTherapy, (lyraTherapy) => lyraTherapy?.get('programs') ?? List())

export const getMessenger = createSelector(getLyraTherapy, (lyraTherapy) => lyraTherapy?.get('messenger'))

export const getSelectedProviderIdx = createSelector(getMessenger, (messenger) => messenger?.get('selectedProviderIdx'))

export const getConversations = createSelector(getMessenger, (messenger) => messenger?.get('conversations') ?? Map())

export const getAppointment = createSelector(getSessions, (sessions) => sessions?.get('appointment'))

export const getAppointmentReschedule = createSelector(getSessions, (sessions) =>
  sessions?.get('appointmentReschedule'),
)

export const getSessionEpisodes = createSelector(getSessions, (sessions) => sessions?.get('episodes'))

export const getCurrentEpisodes = createSelector(getSessionEpisodes, (sessionEpisodes) =>
  sessionEpisodes?.get('current'),
)

export const getProviders = createSelector(getLyraTherapy, (lyraTherapy) => lyraTherapy?.get('providers'))

export const getSelectedProvider = createSelector([getProviders, getSelectedProviderIdx], (providers, index) => {
  return providers?.get(index)?.get('provider') ?? Map()
})

export const getSelectedConversation = createSelector(
  [getConversations, getSelectedProvider],
  (conversations: ImmutableTypedMap<ConversationObject>[], provider) => {
    return conversations?.find((conversation) => conversation?.get('provider_lyra_id') === provider.get('lyra_id'))
  },
)

export const getCurrentProvider = createSelector(
  [getProviders, getOnboardProviderProfileData],
  (providers: ImmutableTypedMap<Provider>[], onboardProviderProfileData: ImmutableTypedMap<ProviderInfo>) => {
    return providers?.find(
      (provider) =>
        (provider?.get('provider') as ImmutableTypedMap<ProviderInfo>)?.get('lyra_id') ===
        onboardProviderProfileData?.get('lyra_id'),
    )
  },
)

// @ts-expect-error TS(2769): No overload matches this call.
export const getCurrentProviderAppointments = createSelector(
  getCurrentProvider,
  (provider: ImmutableTypedMap<Provider>) => provider?.get('appointments'),
)

export const getCurrentProviderActiveEpisodeAppointments = createSelector(
  [getCurrentProviderAppointments, getEpisodes],
  (appointments, episodes) => {
    const typedAppts: List<ImmutableTypedMap<Appointment>> = appointments as unknown as List<
      ImmutableTypedMap<Appointment>
    >
    const currentEpisodeIds = (episodes?.toJS() as Episode[])
      ?.filter((episode) => episode.state === EpisodeStates.IN_PROGRESS)
      .map((episode) => episode.id)
    return typedAppts
      ?.toJS()
      ?.filter(
        (appointment: Appointment) => appointment.episodeId && currentEpisodeIds?.includes(appointment.episodeId),
      )
  },
)

export const getCurrentAppointmentProvider = createSelector(
  [getProviders, getAppointment],
  (providers: ImmutableTypedMap<Provider>[], appoinment: ImmutableTypedMap<Appointment>) =>
    providers?.find(
      (provider) =>
        (provider?.get('provider') as ImmutableTypedMap<ProviderInfo>)?.get('lyra_id') ===
        (appoinment?.get('provider') as ImmutableTypedMap<ProviderInfo>)?.get('lyra_id'),
    ),
)

export const getCurrentAppointmentProviderInfo = createSelector(
  getCurrentAppointmentProvider,
  (provider: ImmutableTypedMap<Provider> | undefined) => provider?.get('provider'),
)

export const getCurrentAppointmentProviderAppointments = createSelector(
  getCurrentAppointmentProvider,
  (provider: ImmutableTypedMap<Provider> | undefined) => provider?.get('appointments') ?? [],
)

export const getCurrentAppointmentProviderActiveEpisodeAppointments = createSelector(
  [getCurrentAppointmentProvider, getEpisodes],
  (provider: ImmutableTypedMap<Provider> | undefined, episodes) => {
    const currentEpisodeIds = (episodes?.toJS() as Episode[])
      ?.filter((episode) => episode.state === EpisodeStates.IN_PROGRESS)
      .map((episode) => episode.id)
    return (
      (provider?.get('appointments') as List<Appointment>)
        ?.toJS()
        ?.filter(
          (appointment: Appointment) => appointment.episodeId && currentEpisodeIds?.includes(appointment.episodeId),
        ) ?? []
    )
  },
)

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import classnames from 'classnames'
import { AnyAction, bindActionCreators } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { CARE_ADVISOR_DISPLAY_PHONE_NUMBER } from '@lyrahealth-inc/shared-app-logic'
import { ContentAlignment, NotificationBanner } from '@lyrahealth-inc/ui-core'
import { toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './banners.module.scss'
import { getCustomerPhone } from '../../../../data/customer/customerSelectors'
import { closeMicrositeBanner } from '../../data/micrositeActions'
import { getIsMicrositeBannerDismissed } from '../../data/micrositeSelectors'

interface RacialStressBannerProps {
  generalSupportNumber: string
  displayRacialStressBanner: boolean
  closeMicrositeBanner: () => ThunkAction<Promise<void>, Map<string, any>, unknown, AnyAction>
  actions: { closeMicrositeBanner: () => void }
}

const RacialStressBanner = ({
  actions: { closeMicrositeBanner },
  generalSupportNumber = CARE_ADVISOR_DISPLAY_PHONE_NUMBER,
  displayRacialStressBanner,
}: RacialStressBannerProps) => {
  return (
    displayRacialStressBanner && (
      <NotificationBanner
        // @ts-expect-error TS(2769): No overload matches this call.
        level='microsite-info'
        contentAlignment={ContentAlignment.CENTER}
        closeBannerCTA={closeMicrositeBanner}
        closeBannerBtnCustomClass={styles['close-banner-btn']}
        notifications={[
          <div key='n1' className={classnames(styles['top-banner'], styles['microsite-info'])}>
            <FormattedMessage
              defaultMessage='<strong>If you are experiencing racial stress</strong>, please
            call (800) 640-7721 (6:30am - 5pm PT M-F) for assistance in finding a provider. For all other support, call
            {generalSupportNumber} or email care@lyrahealth.com.'
              description='Text in a banner about how to get help if experiencing racial stress'
              values={{
                generalSupportNumber,
                strong: (text: string) => <strong className={styles['strong-text-semi-bold']}>{text}</strong>,
              }}
            />
          </div>,
        ]}
      />
    )
  )
}

const mapStateToProps = ($$state: any) => {
  return {
    generalSupportNumber: getCustomerPhone($$state),
    displayRacialStressBanner: !getIsMicrositeBannerDismissed($$state),
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators({ closeMicrositeBanner }, dispatch),
  }
}

// @ts-expect-error TS(2345): Argument of type '(wrappedComponentProps: RacialSt... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(toJS(RacialStressBanner))

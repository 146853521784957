import { useMemo } from 'react'

import { useFlags } from '../../flags/useFlags'

export enum PATHWAYS_VERSION {
  MULTIPLE_CTA = 'multiple CTA',
  SINGLE_CTA = 'single CTA',
}

export const useGetPathwaysVersion = (): { version?: PATHWAYS_VERSION } => {
  const { isPathwaysMicrositeMultiCTAEnabled } = useFlags()

  return useMemo(() => {
    return isPathwaysMicrositeMultiCTAEnabled
      ? { version: PATHWAYS_VERSION.MULTIPLE_CTA }
      : { version: PATHWAYS_VERSION.SINGLE_CTA }
  }, [isPathwaysMicrositeMultiCTAEnabled])
}

import { useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { ELIGIBILITY_TYPES, EligibilityInfo, EligibilityType } from '@lyrahealth-inc/shared-app-logic'

import { generateEligibilityMetadata } from '../formMetadata/eligibilityMetadata'

type Params = {
  eligibilityInfo: EligibilityInfo
  eligibilityType?: EligibilityType
  showInternationalFormat?: boolean
  showEligibilityErrorBanner?: boolean
}

/**
 * Handles generating the custom eligibility metadata if
 * the customer has one
 */
export const useEligibilityMetadata = ({
  eligibilityInfo,
  showInternationalFormat = false,
  showEligibilityErrorBanner = false,
}: Params) => {
  const {
    breakpoints: { isMobileSized },
    colors,
  } = useTheme()
  const intl: IntlShape = useIntl()
  const [eligibilityType, setEligibilityType] = useState(ELIGIBILITY_TYPES.EMPLOYEE as EligibilityType)

  const { customEligibilityMetadata } = eligibilityInfo
  const eligibilityMetadata = customEligibilityMetadata
    ? customEligibilityMetadata.generateMetadata({
        eligibilityInfo,
        eligibilityType,
        isMobileSized,
        intl,
        isInternational: showInternationalFormat,
        colors,
      })
    : generateEligibilityMetadata({
        eligibilityInfo,
        showInternationalFormat,
        intl,
        showEligibilityErrorBanner,
        isMobileSized,
        colors,
      })
  return {
    eligibilityMetadata,
    customFields: customEligibilityMetadata?.getCustomFields?.(eligibilityInfo),
    eligibilityType,
    setEligibilityType,
  }
}

import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { BaseButton } from '../../atoms/baseButton/BaseButton'
import { BodyText } from '../../atoms/bodyText/BodyText'
import { ContactUsSuccessIllustration } from '../../atoms/illustrations/ContactUsSuccessIllustration'
import { Subhead } from '../../atoms/subhead/Subhead'
import { BodyTextSize, SubheadSize } from '../../styles/typeStyles'
import { tID } from '../../utils/utils'

export interface ContactUsSuccessPageProps {
  showDismiss?: boolean
  onDismissPress?: () => void
}

const ContactUsSuccessPageContainer = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
})

const SubheadSuccessContainer = styled(Subhead)(({ theme }) => ({
  marginTop: '57px',
  marginBottom: theme.spacing['12px'],
}))

const ButtonContainer = styled(BaseButton)(({ theme }) => ({
  marginTop: '26px',
  marginBottom: theme.spacing['24px'],
  width: '138px',
  alignSelf: 'center',
}))

export const ContactUsSuccessPage: FunctionComponent<ContactUsSuccessPageProps> = ({
  showDismiss = true,
  onDismissPress = noop,
}) => {
  const { colors } = useTheme()
  return (
    <ContactUsSuccessPageContainer testID={tID('ContactUsSuccessPage')}>
      <ContactUsSuccessIllustration />
      <SubheadSuccessContainer
        size={SubheadSize.LARGE}
        text={<FormattedMessage defaultMessage='Message sent' description='Header text about message has been sent' />}
      />
      <BodyText
        size={BodyTextSize.DEFAULT}
        text={
          <FormattedMessage
            defaultMessage='Thank you for your message. Our Care Navigator team will review and respond within one business day.'
            description='Text about contacting back within one business day'
          />
        }
        color={colors.textSecondary}
        textAlign='center'
      />
      {showDismiss && (
        <ButtonContainer
          text={<FormattedMessage defaultMessage='Ok' description='Label of button to dismiss page' />}
          onPress={onDismissPress}
          testID={tID('ContactUsSuccessPage-dismiss-button')}
        />
      )}
    </ContactUsSuccessPageContainer>
  )
}

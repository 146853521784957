import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Icon for Personal Info
 */
export const PersonalInfoIcon: FunctionComponent<PersonalInfoIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} fill='none'>
      <Path
        d='M9 10.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Zm0-6a2.25 2.25 0 1 1 0 4.5 2.25 2.25 0 0 1 0-4.5ZM16.43 13.61a2.18 2.18 0 1 0-2.18-2.18 2.19 2.19 0 0 0 2.18 2.18Zm0-2.86a.68.68 0 1 1 0 1.36.68.68 0 0 1 0-1.36ZM9 12.25A5.76 5.76 0 0 0 3.25 18v2a.75.75 0 1 0 1.5 0v-2a4.25 4.25 0 1 1 8.5 0v2a.75.75 0 1 0 1.5 0v-2A5.76 5.76 0 0 0 9 12.25Z'
        fill={fillColor || colors.iconActive}
      />
      <Path
        d='M20.39 16.84a4.34 4.34 0 0 0-5.69-2.23.757.757 0 1 0 .6 1.39 2.81 2.81 0 0 1 3.94 2.57V20a.75.75 0 0 0 1.5 0v-1.43a4.29 4.29 0 0 0-.35-1.73Z'
        fill={fillColor || colors.iconActive}
      />
    </Svg>
  )
}

type PersonalInfoIconProps = {
  size?: string | number
  fillColor?: string
}

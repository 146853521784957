import React, { FunctionComponent, useContext } from 'react'
import { View } from 'react-native'
import Hyperlink from 'react-native-hyperlink'

import { useTheme } from 'styled-components/native'
import tinyColor from 'tinycolor2'

import { IS_WEB } from '../../constants'
import { AppContext } from '../../context'
import { getCommonStyles, getLinkStyle } from '../../styles/commonStyles'
import { moderatPlain } from '../../styles/typeStyles'
import { tID } from '../../utils'
import { openUrl } from '../../utils/expo-linking'

/**
 * A wrapper component that uses a react native port of linkify-it to parse text and
 * create hyperlinks with appropriate styling
 */

export const HyperLinkWithStyle: FunctionComponent<HyperLinkWithStyleProps> = ({
  textColor,
  children,
  onLinkPress,
}) => {
  const { colors } = useTheme()
  const commonStyles = getCommonStyles(colors)
  const { openBranchUrl } = useContext(AppContext)
  const linkStyleObject = tinyColor(textColor || colors.textPrimary).isLight()
    ? { ...commonStyles.linkDarkBg, color: colors.textButton }
    : getLinkStyle(colors).link
  const onPress = (link: string) => (!!openBranchUrl ? openBranchUrl(link) : openUrl(link))

  return (
    <Hyperlink
      onPress={(link) => {
        onLinkPress && onLinkPress(link)
        onPress(link)
      }}
      linkStyle={IS_WEB ? { ...linkStyleObject, ...moderatPlain } : linkStyleObject}
    >
      <View testID={tID('HyperLinkWithStyle')}>{children}</View>
    </Hyperlink>
  )
}

type HyperLinkWithStyleProps = {
  textColor: string | undefined
  // Typing for Hyperlink does not like ReactElement or node
  children: any
  onLinkPress?: (link: string) => void
}

import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import {
  ICON_CIRCLE_MOBILE_SIZE,
  ICON_CIRCLE_WEB_SIZE,
  MAX_NUMBER_CIRCLE,
  WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE,
} from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  ResultsScoreIcon,
  ThemeType,
  tID,
  useMediaQuerySize,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE_TO_TEXT } from '../../utils/wellnessCheckInResults/wellnessCheckInDomainEssentialsConstants'

export interface WellnessCheckInResultsDomainCardScoreProps {
  score: WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE
}

const CardScoreContainer = styled(View)<{ theme: ThemeType }>(() => ({
  flexDirection: 'row',
  alignItems: 'center',
}))

const IconsContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  marginLeft: theme.spacing['4px'],
}))

const IconContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['4px'],
}))

export const WellnessCheckInResultsDomainCardScore: FunctionComponent<WellnessCheckInResultsDomainCardScoreProps> = ({
  score,
}) => {
  const { colors } = useTheme()
  const { isMobileSized } = useMediaQuerySize()
  const width = isMobileSized ? ICON_CIRCLE_MOBILE_SIZE : ICON_CIRCLE_WEB_SIZE

  const getScoreIconColor = (currentScore?: WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE) => {
    switch (currentScore) {
      case WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.LOW:
        return colors.components.wellnessCheckInResultsDomainScore.low
      case WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.MODERATE:
        return colors.components.wellnessCheckInResultsDomainScore.moderate
      case WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.HIGH:
        return colors.components.wellnessCheckInResultsDomainScore.high
      default:
        return colors.components.wellnessCheckInResultsDomainScore.default
    }
  }

  const createScoreIconElement = (currentScore?: WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE) => {
    return (
      <IconContainer>
        <ResultsScoreIcon
          width={width}
          height={width}
          innerCircleFillColor={getScoreIconColor(currentScore)?.innerCircle?.fill}
          outerCircleFillColor={getScoreIconColor(currentScore)?.outerCircle?.fill}
        />
      </IconContainer>
    )
  }

  const getScoreIconElements = () => {
    const icons = []

    for (let i = 0; i < MAX_NUMBER_CIRCLE; i++) {
      if (
        (score === WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.LOW && i < 1) ||
        (score === WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.MODERATE && i < 2) ||
        (score === WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.HIGH && i < 3)
      ) {
        icons.push(createScoreIconElement(score))
      } else {
        icons.push(createScoreIconElement())
      }
    }

    return icons
  }

  return (
    <CardScoreContainer testID={tID(`WellnessCheckInResultsDomainCardScore`)}>
      <BodyText
        text={<FormattedMessage {...WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE_TO_TEXT[score]} />}
        size={isMobileSized ? BodyTextSize.DEFAULT : BodyTextSize.SMALL}
        color={getScoreIconColor(score)?.text}
      />
      <IconsContainer>{getScoreIconElements()}</IconsContainer>
    </CardScoreContainer>
  )
}

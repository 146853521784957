import get from 'lodash/get'

import { getUserHealthPlansDataFromEligibilityService } from '../../../../common/http/data/onboard'

export const SAVE_HEALTH_PLAN_SELECTION_FROM_TRIAGE = 'SAVE_HEALTH_PLAN_SELECTION_FROM_TRIAGE'
export const SET_IS_USER_BHB_ELIGIBLE = 'SET_IS_USER_BHB_ELIGIBLE'
export const SET_USER_HEALTH_PLAN_NAMES_FROM_ELIGIBILITY_SERVICE = 'SET_USER_HEALTH_PLAN_NAMES_FROM_ELIGIBILITY_SERVICE'

export const saveHealthPlanSelectionFromTriage = (selectedHealthPlan: $TSFixMe) => {
  return {
    type: SAVE_HEALTH_PLAN_SELECTION_FROM_TRIAGE,
    selectedHealthPlan,
  }
}

export const getUserHealthPlanNamesFromEligibilityService = ({ lyraId }: $TSFixMe) => {
  return (dispatch: $TSFixMe) => {
    return getUserHealthPlansDataFromEligibilityService({ lyraId }).then((response) => {
      return dispatch({
        type: SET_USER_HEALTH_PLAN_NAMES_FROM_ELIGIBILITY_SERVICE,
        healthPlanNames: get(response, 'data', []),
      })
    })
  }
}

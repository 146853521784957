import { IntlShape } from 'react-intl'

import {
  appleCustomerCodeFieldLabel,
  ELIGIBILITY_TYPES,
  EligibilityField,
  EligibilityInfo,
  FormMetadata,
  registrationEligibilityFormHeader,
  USER_MAX_AGE_REQUIREMENT,
  USER_MIN_AGE_REQUIREMENT,
} from '@lyrahealth-inc/shared-app-logic'

import { IS_WEB } from '../../../constants'
import { commonInputFieldBorderStyle } from '../../../organisms/formBody/sharedMetadata'
import { BasicInfoField } from '../../../organisms/setUpAccountForm/basicInfoMetadata'

export const generateAppleEmployeeEligibilityMetadata = (
  intl: IntlShape,
  isInternational: boolean,
  eligibilityInfo: EligibilityInfo,
): FormMetadata => {
  const { isAttestation, isPristineConfirmEligibility, hasBlockingEligibilityCheck } = eligibilityInfo
  const isFromConfirmEligibilityPage = isPristineConfirmEligibility === true
  const showEligibilityErrorAsWarning = !hasBlockingEligibilityCheck

  return {
    schema: {
      type: 'object',
      properties: {
        employeeFormSubHeader: {
          name: 'employeeFormSubHeader',
          content: `#### ${intl.formatMessage(registrationEligibilityFormHeader)}`,
          show: isFromConfirmEligibilityPage || isAttestation === true,
          condition: {
            parentField: EligibilityField.ELIGIBILITY_TYPE,
            parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
          },
        },
        informationSubHeader: {
          type: 'string',
          name: 'informationSubHeader',
          show: isFromConfirmEligibilityPage,
          condition: {
            parentField: EligibilityField.ELIGIBILITY_TYPE,
            parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
          },
        },
        [EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME]: {
          type: 'string',
          title: intl.formatMessage(appleCustomerCodeFieldLabel.label),
          subTitleComponent: isFromConfirmEligibilityPage ? undefined : 'AppleEmployeeIdSublabel',
          name: EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME,
          condition: {
            parentField: EligibilityField.ELIGIBILITY_TYPE,
            parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
          },
          validation: { isRequired: !isInternational, isValidCustomerCode: true }, // Apple ID is minimum of 1 digit, and grows incrementally
          widgetProps: {
            showErrorAsWarning: showEligibilityErrorAsWarning,
          },
        },
        ...(isAttestation && {
          [EligibilityField.EMPLOYEE_DATE_OF_BIRTH]: {
            name: BasicInfoField.USER_DATE_OF_BIRTH,
            condition: {
              parentField: EligibilityField.ELIGIBILITY_TYPE,
              parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
            },
            title: intl.formatMessage({
              defaultMessage: 'Date of birth',
              description: 'Date of birth field label',
            }),
            placeholder: '',
            type: 'string',
            validation: {
              isRequired: true,
              isValidAge: {
                min: USER_MIN_AGE_REQUIREMENT.DOMESTIC_EMPLOYEE,
                max: USER_MAX_AGE_REQUIREMENT.ADULT,
              },
            },
          },
        }),
      },
    },
    uiSchema: {
      'ui:order': [
        'employeeFormSubHeader',
        'informationSubHeader',
        EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME,
        EligibilityField.EMPLOYEE_DATE_OF_BIRTH,
      ],
      informationSubHeader: { 'ui:field': 'EligibilityInformation' },
      [EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME]: {
        'ui:options': {
          ...(!isAttestation &&
            !isFromConfirmEligibilityPage && {
              isOptionalLabel: isInternational,
              labelStyle: {
                fontWeight: 500,
              },
            }),
          baseInputStyle: {
            marginBottom: 32,
          },
          ...commonInputFieldBorderStyle,
        },
      },
      [EligibilityField.EMPLOYEE_DATE_OF_BIRTH]: {
        'ui:widget': IS_WEB ? 'datePickerSelect' : 'date',
        'ui:options': IS_WEB
          ? {
              numYears: USER_MAX_AGE_REQUIREMENT.ADULT,
              selectFieldStyle: {
                borderRadius: 8,
              },
            }
          : {
              dateFieldDisplay: 'spinner',
              selectFieldStyle: {
                borderRadius: 8,
              },
            },
      },
    },
  }
}

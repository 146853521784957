import { createSelector } from 'reselect'

import { ImmutableTypedMap } from '@lyrahealth-inc/shared-app-logic'

import { MatcherSearchState } from '../list-view/matcher-search/matcher-search-reducer'

export const getMatcherSearch = (state: Map<string, any>): ImmutableTypedMap<MatcherSearchState> =>
  state?.get('matcherSearch')

export const getMatcherSearchCNTBookingProvider = createSelector(getMatcherSearch, (matcherSearch) =>
  matcherSearch.get('cntBookingProviderLyraId'),
)

export const getMatcherSearchText = createSelector(getMatcherSearch, (matcherSearch) => matcherSearch.get('searchText'))

export const getMatcherSearchTreatmentPreference = createSelector(getMatcherSearch, (matcherSearch) =>
  matcherSearch.get('treatmentPreference'),
)

export const getMatcherSearchDistance = createSelector(getMatcherSearch, (matcherSearch) =>
  matcherSearch.get('distance'),
)

export const getMatcherSearchAge = createSelector(getMatcherSearch, (matcherSearch) => matcherSearch.get('age'))

export const getMatcherSearchName = createSelector(getMatcherSearch, (matcherSearch) => matcherSearch.get('searchName'))

export const getMatcherSearchQualitySearch = createSelector(getMatcherSearch, (matcherSearch) =>
  matcherSearch.get('qualitySearch'),
)

export const getMatcherSearchShowLCProvidersOnly = createSelector(getMatcherSearch, (matcherSearch) =>
  matcherSearch.get('showLCProvidersOnly'),
)

export const getMatcherSearchSessionFormat = createSelector(getMatcherSearch, (matcherSearch) =>
  matcherSearch.get('sessionFormat'),
)

export const getMatcherSearchAreAllRequired = createSelector(getMatcherSearch, (matcherSearch) =>
  matcherSearch.get('areAllRequired'),
)

export const getMatcherSearchCustomer = createSelector(getMatcherSearch, (matcherSearch) =>
  matcherSearch.get('searchCustomer'),
)

export const getMatcherSearchCustomerValue = createSelector(getMatcherSearchCustomer, (searchCustomer) =>
  (searchCustomer as $TSFixMe).get('value', 'care'),
)

export const getMatcherSearchRequiredStateLicenses = createSelector(getMatcherSearch, (matcherSearch) =>
  (matcherSearch as $TSFixMe).get('requiredStateLicenses', []),
)

export const getMatcherSearchBulkCopy = createSelector(getMatcherSearch, (matcherSearch) =>
  matcherSearch.get('bulkCopy'),
)

export const getMatcherResultsPage = createSelector(getMatcherSearch, (matcherSearch) => matcherSearch.get('page'))

export const getMatcherSearchData = createSelector(getMatcherSearch, (matcherSearch) => matcherSearch.get('data'))

export const getMatcherSearchNavigatorProviders = createSelector(getMatcherSearchData, (searchData) =>
  (searchData as $TSFixMe).get('navigatorProviders'),
)

export const getMatcherSearchDirectAccessProviders = createSelector(getMatcherSearchData, (searchData) =>
  (searchData as $TSFixMe).get('directAccessProviders'),
)

import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export const LaptopIcon = ({ height = 24, width = 24, fillColor, ...rest }: LaptopIconProps & SvgProps) => {
  const { colors } = useTheme()

  return (
    <Svg width={width} height={height} viewBox='0 0 24 24' fill='none' testID={tID('LaptopIcon')} {...rest}>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='m20.246 15.35 1.4 1.06h-.04a1.81 1.81 0 0 1-1.09 3.26h-17a1.81 1.81 0 0 1-1.09-3.26l1.4-1.06V6.67a2.35 2.35 0 0 1 2.35-2.34h11.72a2.35 2.35 0 0 1 2.35 2.34v8.68ZM5.578 6.075a.85.85 0 0 0-.252.595l.01 8.31h2a3.52 3.52 0 0 1 3.47-3h2.46a3.52 3.52 0 0 1 3.48 3h2V6.67a.85.85 0 0 0-.85-.84H6.176a.85.85 0 0 0-.598.245Zm3.288 8.905h6.31a2 2 0 0 0-1.95-1.48h-2.42a2 2 0 0 0-1.94 1.48Zm11.877 3.158a.29.29 0 0 0 .113-.158v-.02a.29.29 0 0 0-.11-.33l-1.5-1.13H4.826l-1.46 1.13a.31.31 0 0 0 .19.56h17a.29.29 0 0 0 .187-.052Zm-7.4-6.855a2.35 2.35 0 1 1-2.614-3.907 2.35 2.35 0 0 1 2.614 3.907Zm-.835-2.64a.85.85 0 1 0-.944 1.413.85.85 0 0 0 .944-1.413Z'
        fill={fillColor || colors.iconInactive}
      />
    </Svg>
  )
}

export type LaptopIconProps = {
  height?: number
  width?: number
  fillColor?: string
}

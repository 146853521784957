import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import { ContentLayout, RootLayout } from '@lyrahealth-inc/ui-core'

import ComingSoonMicrositeHomeContainer from '../../../features/microsite/components/comingSoonContainer/ComingSoonMicrositeHomeContainer'
import MicrositeIntlProvider from '../../../features/microsite/MicrositeIntlProvider'
import Header from '../header/Header'
import ToastContainer from '../toastContainer/ToastContainer'

const ComingSoon = () => {
  const ComingSoonHomepage = (
    <MicrositeIntlProvider>
      <Header />
      <ToastContainer />
      <ContentLayout>
        <ComingSoonMicrositeHomeContainer />
      </ContentLayout>
    </MicrositeIntlProvider>
  )

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  const router = sentryCreateBrowserRouter([
    {
      path: '*',
      index: true,
      element: ComingSoonHomepage,
    },
  ])

  return (
    <RootLayout>
      <RouterProvider router={router} />
    </RootLayout>
  )
}

export default ComingSoon

import { isFuture, isValid, parseISO } from 'date-fns'
import { createSelector } from 'reselect'

import { EAPEligibilityResponse, EligibilityStatus } from '@lyrahealth-inc/shared-app-logic'

import { ELIGIBILITY_BANNER_SNOOZE_EXPIRATION } from '../../../../common/components/banner/banner-actions'
import { getBannerIncludeX, getBannerIsOpen } from '../../../../data/banner/bannerSelectors'

const getEligibility = (state: $TSFixMe) => state.get('eligibility')

export const getComingFromConfirmationForm = createSelector(getEligibility, (eligibility) =>
  eligibility?.get('comingFromConfirmationForm'),
)

export const getDismissedEligibilityBanner = createSelector(getEligibility, (eligibility) =>
  eligibility?.get('dismissedEligibilityBanner'),
)

const getEligibilityStatusState = createSelector(getEligibility, (eligibility) =>
  eligibility?.get('eligibilityStatus', {}),
)

const getEligibilityResponse = createSelector(
  getEligibilityStatusState,
  (eligibility) => eligibility?.data as EAPEligibilityResponse,
)

export const getIsEligibilityStatusLoading = createSelector(
  getEligibilityStatusState,
  (eligibility) => eligibility?.isLoading as boolean,
)

export const getIsEligibilityStatusInitialLoading = createSelector(
  getEligibilityResponse,
  getIsEligibilityStatusLoading,
  (data, isLoading) => !data && isLoading,
)

export const getEligibilityStatus = createSelector(getEligibilityResponse, (eligibility) => {
  return eligibility?.eligibilityStatus as EligibilityStatus | undefined
})

export const showEligibilityBanner = createSelector(
  getEligibilityStatus,
  getDismissedEligibilityBanner,
  getBannerIsOpen,
  getBannerIncludeX,
  (eligibilityStatus, hasDismissedEligibilityBanner, isBannerOpen, doesBannerHaveDismiss) => {
    const shouldDisplayEligibilityBanner =
      eligibilityStatus && !hasDismissedEligibilityBanner && (!isBannerOpen || (isBannerOpen && doesBannerHaveDismiss))
    if (!shouldDisplayEligibilityBanner) {
      return false
    }
    // If we have nothing in our local storage for the snooze date for the eligibility banner or
    // if our current date is after the expiration date for the snooze date then display the eligibility banner
    let shouldShowBanner = true
    try {
      const eligibilityBannerSnoozeExpirationDate = localStorage.getItem(ELIGIBILITY_BANNER_SNOOZE_EXPIRATION)
      if (eligibilityBannerSnoozeExpirationDate) {
        const dateObj = parseISO(eligibilityBannerSnoozeExpirationDate)
        if (isValid(dateObj) && isFuture(dateObj)) {
          shouldShowBanner = false
        }
      }
    } catch (err) {
      console.error('Error while parsing ELIGIBILITY_BANNER_SNOOZE_EXPIRATION from localStorage:', err)
    }
    return shouldShowBanner
  },
)

import { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { SEARCHER_TYPE_TO_MATCH_FOR, TriageSearch, useFlags } from '@lyrahealth-inc/shared-app-logic'

import { handleZeroViewSelectArgs } from '../../features/appointmentDashboard/data/appointmentDashboardActions'

export interface NavigateToLatestProviderMatchesProps {
  setLatestMatchesSearch: (search?: TriageSearch) => void
  setSelectedSearchId: (searchId: string) => void
  inProgressSearch?: TriageSearch
  handleZeroViewSelect: (args: handleZeroViewSelectArgs) => void
}

export const useNavigateToLatestProviderMatches = ({
  setLatestMatchesSearch,
  inProgressSearch,
  handleZeroViewSelect,
  setSelectedSearchId,
}: NavigateToLatestProviderMatchesProps) => {
  const navigate = useNavigate()
  const { shouldShowTriageSummary } = useFlags()
  return useCallback(() => {
    // If we are using the old triage, there is not search object, so set as an empty object
    setLatestMatchesSearch(inProgressSearch)
    if (inProgressSearch) {
      setSelectedSearchId(inProgressSearch.id)
    }
    handleZeroViewSelect({
      whoAmIHereFor: SEARCHER_TYPE_TO_MATCH_FOR.SELF,
      navigate,
      currentLocation: location.pathname,
      showSummaryView: shouldShowTriageSummary && inProgressSearch === undefined,
    })
  }, [
    setLatestMatchesSearch,
    inProgressSearch,
    handleZeroViewSelect,
    navigate,
    shouldShowTriageSummary,
    setSelectedSearchId,
  ])
}

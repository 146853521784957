import { GENDER_IDENTITY } from '../../models'
import { REGISTRATION_EVENTS } from '../common/constants/mixpanelConstants'

export const genderIdentityOptionsList = [
  GENDER_IDENTITY.MALE,
  GENDER_IDENTITY.FEMALE,
  GENDER_IDENTITY.TRANSGENDER,
  GENDER_IDENTITY.NON_BINARY,
  GENDER_IDENTITY.OTHER,
  GENDER_IDENTITY.PREFER_NOT_TO_SAY,
]

export enum IDENTITY_PROVIDERS {
  GOOGLE = 'google',
  APPLE = 'apple',
  PARTNER = 'partner',
  NONE = 'none',
}

export const REGISTRATION_SUCCESS_EVENT_MAP: Record<IDENTITY_PROVIDERS, string> = {
  [IDENTITY_PROVIDERS.APPLE]: REGISTRATION_EVENTS.REGISTRATION_APPLE_SSO_SUCCESS,
  [IDENTITY_PROVIDERS.GOOGLE]: REGISTRATION_EVENTS.REGISTRATION_GOOGLE_SSO_SUCCESS,
  [IDENTITY_PROVIDERS.PARTNER]: REGISTRATION_EVENTS.REGISTRATION_PARTNER_SSO_SUCCESS,
  [IDENTITY_PROVIDERS.NONE]: REGISTRATION_EVENTS.REGISTRATION_SUCCESS,
}

export enum EmailClient {
  APPLEMAIL = 'mail',
  GMAIL = 'gmail',
  YAHOO = 'yahoo',
  OUTLOOK = 'outlook',
}

export const DEFAULT_MAX_CHILD_COUNT = 10

import { defineMessage } from 'react-intl'

import * as BannerActions from '../../../../common/components/banner/banner-actions'
import { getProvidersForLocationData } from '../../../../common/http/data/onboard'

export interface LocationDropdownValue {
  value: number
  locationName: string
}

export interface AddressSearchValue {
  userInput: string
  state: string
  country: string
  lat: string
  lon: string
  formattedAddress: string
}

export const SET_FULL_LOCATION_OBJ = 'SET_FULL_LOCATION_OBJ'
export const SET_LOCATION_GEO_DATA = 'SET_LOCATION_GEO_DATA'
export const CLEAR_FULL_LOCATION_OBJ = 'CLEAR_FULL_LOCATION_OBJ'
export const SET_ADDRESS_OPTION = 'SET_ADDRESS_OPTION'
export const SET_ADDRESS_DROPDOWN_OPTION = 'SET_ADDRESS_DROPDOWN_OPTION'
export const SET_LOCATION_TIMEZONE = 'SET_LOCATION_TIMEZONE'

export const clearFullLocationObj = () => {
  return {
    type: CLEAR_FULL_LOCATION_OBJ,
  }
}

export const setFullLocationObj = (locationData: $TSFixMe) => {
  return {
    type: SET_FULL_LOCATION_OBJ,
    payload: locationData,
  }
}

export const setLocationGeoData = (geoObj: $TSFixMe) => {
  return {
    type: SET_LOCATION_GEO_DATA,
    payload: geoObj,
  }
}

export const setAddressOption = (addressOption: number) => {
  return {
    type: SET_ADDRESS_OPTION,
    payload: addressOption,
  }
}

export const setAddressDropdownOption = (dropdownValue: LocationDropdownValue) => {
  return {
    type: SET_ADDRESS_DROPDOWN_OPTION,
    payload: dropdownValue,
  }
}

export const setLocationTimeZone = (timeZoneId: string) => {
  return {
    type: SET_LOCATION_TIMEZONE,
    payload: timeZoneId,
  }
}

export const verifyProviderSupportForLocation = (userId: $TSFixMe, providerId: $TSFixMe, locationData: $TSFixMe) => {
  const requestData = {
    identifiers: [providerId],
    location: locationData.location,
    locationState: locationData.locationState,
    distance: '20.0',
  }
  const request = getProvidersForLocationData(userId, requestData)

  return (dispatch: $TSFixMe) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (verificationReturn) => {
          resolve(verificationReturn.data)
        },
        (error) => {
          dispatch(
            BannerActions.setBannerPropsAndStatus(
              BannerActions.BANNER_TYPE.DANGER,
              defineMessage({
                defaultMessage:
                  'An error occurred in verifying this provider’s support for that location. Please try again.',
                description: 'Error message for when a provider’s support for a location cannot be verified',
              }),
              true,
            ),
          )
          reject(error)
        },
      )
    })
  }
}

/* For future use...
export const clearLocationDataByKeys = (keysArray) => {
  return {
    type: CLEAR_LOCATION_DATA_BY_KEYS,
    payload: keysArray
  }
}

export const setOnsiteLocation = (onsiteName) => {
  return {
    type: SET_ONSITE_LOCATION,
    payload: onsiteName
  }
}

export const setLocationAddress = (addressObj) => {
  return {
    type: SET_LOCATION_ADDRESS,
    payload: addressObj
  }
}
*/

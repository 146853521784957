import React from 'react'
import { defineMessage } from 'react-intl'

import CareImage from '../assets/worklife/ReThink.png'
import { BodyText } from '../atoms'
import { DEFAULT_WORKLIFE_SERVICES } from '../constants'

export const commonspirit = () => {
  return {
    dependentButtonText: defineMessage({
      defaultMessage: 'No, I’m a dependent or household member',
      description: 'Declaring the current user is a dependent of an eligible user',
    }),
    workLifeServices: [
      DEFAULT_WORKLIFE_SERVICES.LEGAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.FINANCIAL_SERVICES,
      DEFAULT_WORKLIFE_SERVICES.IDENTITY_THEFT,
      DEFAULT_WORKLIFE_SERVICES.DEPENDENT_CARE,
      {
        identifiers: {
          id: 'care',
          dataTestId: 'workLife-selectCare',
        },
        title: 'Care.com',
        img: CareImage,
        paragraph: 'Care Membership',
        bullets: [
          'Access to ongoing care providers - for children, seniors, pets, tutoring, housekeeping, and more',
          // eslint-disable-next-line react/jsx-key
          <BodyText
            text={'Backup Care \nSubsidized coverage when regular care is unavailable - for children, seniors, pets'}
          />,
        ],
        CTA: 'Access Care.com',
        PATH: 'https://commonspirit.care.com/app/ent-memberhome/commonspirit',
      },
    ],
  }
}

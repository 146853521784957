import React, { FunctionComponent } from 'react'
import Svg, { Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * An icon used to represent "follow the science"
 */
export const TrendDownIcon: FunctionComponent<TrendDownIconProps> = ({ size = 24, fillColor }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.25 3.11915C15.1515 3.11868 15.0538 3.1379 14.9628 3.17568C14.8718 3.21346 14.7892 3.26904 14.72 3.33915C14.5795 3.47977 14.5007 3.6704 14.5007 3.86915C14.5007 4.0679 14.5795 4.25852 14.72 4.39915L18.44 8.11915H17.25C17.0511 8.11915 16.8603 8.19817 16.7197 8.33882C16.579 8.47947 16.5 8.67024 16.5 8.86915C16.5 9.06806 16.579 9.25883 16.7197 9.39948C16.8603 9.54013 17.0511 9.61915 17.25 9.61915H20.25C20.4489 9.61915 20.6397 9.54013 20.7803 9.39948C20.921 9.25883 21 9.06806 21 8.86915M21 8.86676V5.86915C21 5.67024 20.921 5.47947 20.7803 5.33882C20.6397 5.19817 20.4489 5.11915 20.25 5.11915C20.0511 5.11915 19.8603 5.19817 19.7197 5.33882C19.579 5.47947 19.5 5.67024 19.5 5.86915V7.05915L15.78 3.33915C15.7108 3.26904 15.6282 3.21346 15.5372 3.17568C15.4462 3.1379 15.3485 3.11868 15.25 3.11915M1.25 20.1191C1.25259 20.3173 1.33244 20.5065 1.47253 20.6466C1.61263 20.7867 1.80189 20.8666 2 20.8691H22C22.1989 20.8691 22.3897 20.7901 22.5303 20.6495C22.671 20.5088 22.75 20.3181 22.75 20.1191C22.75 19.9202 22.671 19.7295 22.5303 19.5888C22.3897 19.4482 22.1989 19.3691 22 19.3691H18.75V12.1191C18.7474 11.921 18.6676 11.7318 18.5275 11.5917C18.3874 11.4516 18.1981 11.3717 18 11.3691H14.75V8.11915C14.7474 7.92104 14.6676 7.73178 14.5275 7.59168C14.3874 7.45159 14.1981 7.37174 14 7.36915H10.75V4.11915C10.7474 3.92104 10.6676 3.73178 10.5275 3.59168C10.3874 3.45159 10.1981 3.37174 10 3.36915H6C5.80189 3.37174 5.61263 3.45159 5.47253 3.59168C5.33244 3.73178 5.25259 3.92104 5.25 4.11915V19.3691H2C1.80189 19.3717 1.61263 19.4516 1.47253 19.5917C1.33244 19.7318 1.25259 19.921 1.25 20.1191ZM17.25 19.3691H14.75V12.8691H17.25V19.3691ZM6.75 4.86915H9.25V19.3691H6.75V4.86915Z'
        fill={fillColor || colors.iconInactive}
      />
    </Svg>
  )
}

type TrendDownIconProps = {
  size?: string | number
  fillColor?: string
}

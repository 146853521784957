import { useEffect, useState } from 'react'

import {
  DEFAULT_LANGUAGE,
  ESSENTIALS_CATEGORY,
  ESSENTIALS_GET_ARTICLES_BY_CATEGORY_QUERY,
  ESSENTIALS_GET_CATEGORIES_QUERY,
  ESSENTIALS_SUBCATEGORY,
  EssentialsArticleRaw,
} from '@lyrahealth-inc/shared-app-logic'

import { runEssentialsQuery } from '../wellnessToolkitUtil'

export const useGetEssentialsArticlesByCategory = (
  essentialsCategories: ESSENTIALS_CATEGORY[],
  essentialsCmsUrl: string,
  userDisplayLanguage: string,
  essentialsExcludedSubcategories?: ESSENTIALS_SUBCATEGORY[],
  logError?: (error: Error) => void,
) => {
  const [articles, setArticles] = useState<EssentialsArticleRaw[]>([])

  const locale = userDisplayLanguage || DEFAULT_LANGUAGE

  useEffect(() => {
    if (!(essentialsCategories && essentialsCmsUrl)) {
      return
    }

    const getArticles = async () => {
      let articlesList: EssentialsArticleRaw[] = []
      const categoriesResult = await Promise.all(
        essentialsCategories.map((category) =>
          runEssentialsQuery(locale, category, ESSENTIALS_GET_CATEGORIES_QUERY, essentialsCmsUrl, logError),
        ),
      )

      for (const category of categoriesResult) {
        const categoryData = category[0]
        const articlesForSubcategoryResult = await Promise.all(
          categoryData.subcategories
            .filter(
              (item: { slug: string }) =>
                !essentialsExcludedSubcategories?.includes(item.slug as ESSENTIALS_SUBCATEGORY),
            )
            .map((item: { slug: string }) => {
              return runEssentialsQuery(
                locale,
                item.slug,
                ESSENTIALS_GET_ARTICLES_BY_CATEGORY_QUERY,
                essentialsCmsUrl,
                logError,
              )
            }),
        )
        for (const articlesForSubcategory of articlesForSubcategoryResult) {
          articlesList = articlesList.concat(articlesForSubcategory)
        }
      }

      setArticles(articlesList)
    }
    getArticles()
  }, [essentialsCategories, essentialsCmsUrl, essentialsExcludedSubcategories, locale, logError])

  return articles
}

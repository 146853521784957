import { fromJS } from 'immutable'

import * as MatcherSearchActions from './matcher-search-actions'

type DefaultMatcherData = {
  navigatorProviders: any[]
  directAccessProviders: any[]
}

type SearchCustomerShape = {
  label: string
  value: string
}

export type RequiredStateLicensesShape = {
  label: string
  value: string
}

const defaultData = {
  navigatorProviders: [],
  directAccessProviders: [],
}

export interface MatcherSearchState {
  data: DefaultMatcherData
  searchText: string
  distance: number
  age: number
  ageBucket: string
  treatmentPreference: string
  bulkCopy: string
  searchName: string
  sessionFormat: any[]
  areAllRequired: false
  zendeskIdSearch: string
  status: string
  qualitySearch: boolean
  showLCProvidersOnly: boolean
  searchCustomer: SearchCustomerShape
  requiredStateLicenses: RequiredStateLicensesShape[]
  cntBookingProviderLyraId: string
  page: number
}

type MatcherSearchReducerAction = {
  data: object
  type: string
  providerLyraId: string
  copy: string
  param: string
  value: string
  searchType: string
  page: number
}

const defaultState = {
  data: defaultData,
  searchText: '',
  distance: 10,
  age: '',
  ageBucket: '18-24',
  treatmentPreference: 'individualTherapy',
  bulkCopy: '',
  searchName: '',
  sessionFormat: [],
  areAllRequired: false,
  zendeskIdSearch: '',
  status: 'empty',
  qualitySearch: true,
  showLCProvidersOnly: true,
  searchCustomer: { label: 'Care', value: 'care' },
  requiredStateLicenses: [],
  providerLyraId: '',
  page: 1,
}

export const matcherSearch = (state = fromJS(defaultState), action: MatcherSearchReducerAction) => {
  switch (action.type) {
    case MatcherSearchActions.SET_MATCHER_SEARCH_PARAM: {
      return state.setIn([action.param], action.value)
    }
    case MatcherSearchActions.SET_SEARCH_CUSTOMER_PARAM: {
      return state.set('searchCustomer', fromJS(action.value))
    }
    case MatcherSearchActions.SET_MATCHER_LIST_DATA: {
      return state.setIn(['data', action.searchType], action.data)
    }
    case MatcherSearchActions.RESET_MATCHER_LIST_DATA: {
      return state.setIn(['data'], fromJS(defaultData))
    }
    case MatcherSearchActions.SET_MATCHER_BULK_COPY: {
      return state.setIn(['bulkCopy'], action.copy)
    }
    case MatcherSearchActions.CLEAR_MATCHER_BULK_COPY: {
      return state.setIn(['bulkCopy'], '')
    }
    case MatcherSearchActions.RESET_MATCHER_SEARCH: {
      return fromJS(defaultState)
    }
    case MatcherSearchActions.SET_CNT_BOOKING_PROVIDER: {
      return state.setIn(['cntBookingProviderLyraId'], action.providerLyraId)
    }
    case MatcherSearchActions.CLEAR_CNT_BOOKING_PROVIDER: {
      return state.setIn(['cntBookingProviderLyraId'], '')
    }
    case MatcherSearchActions.SET_MATCHER_RESULTS_PAGE: {
      return state.set('page', action.page)
    }
    default:
      return state
  }
}

export default matcherSearch

import React, { FunctionComponent, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { css, useTheme } from 'styled-components/native'

import {
  getWellnessCheckInMetadata,
  HOW_ARE_YOU_FEELING_TODAY_MSG,
  HowAreYouFeelingTodayOption,
  useWellnessCheckInRulesEngine,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyText, PrimaryButton } from '../../atoms'
import { MoodEmojiLottie } from '../../atoms/moodEmojiLottie/MoodEmojiLottie'
import { MoodEmojiPopInLottie } from '../../atoms/moodEmojiPopInLottie/MoodEmojiPopInLottie'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { WellnessCheckInBackgroundContainer } from '../../templates'
import { ThemeType, tID } from '../../utils'
import { FormBody, FormButtonParams, FormChangeProperties } from '../formBody/FormBody'

export interface WellnessCheckInFormProps {
  currentPage: number
  totalPages: number
  socialCareEnabled: boolean
  onSubmit: (values: object) => void
  onCTAPress: () => void
}

const STICKY_HEADER_MARGIN_TOP_OFFSET = 72
const HOW_ARE_YOU_FEELING_TODAY_PAGE_WIDTH = 504
const QUESTIONNAIRE_PAGE_WIDTH = 576

const MOOD_LOTTIE_JSON = {
  [HowAreYouFeelingTodayOption.Bad]: require('../../assets/lottie/mood-bad-emoji.json'),
  [HowAreYouFeelingTodayOption.NotGood]: require('../../assets/lottie/mood-notGood-emoji.json'),
  [HowAreYouFeelingTodayOption.Ok]: require('../../assets/lottie/mood-ok-emoji.json'),
  [HowAreYouFeelingTodayOption.Good]: require('../../assets/lottie/mood-good-emoji.json'),
  [HowAreYouFeelingTodayOption.Great]: require('../../assets/lottie/mood-great-emoji.json'),
}

const HowAreYouFeelingTodayContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: `${80 + STICKY_HEADER_MARGIN_TOP_OFFSET}px`,
  alignItems: 'center',
  paddingHorizontal: theme.spacing['16px'],
}))

const AnimationContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['48px'],
}))

const InstructionsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: `${48 + STICKY_HEADER_MARGIN_TOP_OFFSET}px`,
  paddingHorizontal: theme.spacing['16px'],
  maxWidth: `${HOW_ARE_YOU_FEELING_TODAY_PAGE_WIDTH}px`,
  alignSelf: 'center',
  width: '100%',
}))

const SocialCareMarginContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.breakpoints.isMobileSized
    ? `${32 + STICKY_HEADER_MARGIN_TOP_OFFSET}px`
    : `${72 + STICKY_HEADER_MARGIN_TOP_OFFSET}px`,
}))

const CTAContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMinWidthTablet && { flexDirection: 'row', justifyContent: 'flex-end' }),
}))

/**
 * A component that renders the Wellness Check In Form.
 */

export const WellnessCheckInForm: FunctionComponent<WellnessCheckInFormProps> = ({
  currentPage,
  totalPages,
  socialCareEnabled,
  onSubmit,
  onCTAPress,
}) => {
  const intl = useIntl()
  const {
    breakpoints: { isMobileSized },
    spacing,
  } = useTheme() as ThemeType
  const { schema, uiSchema } = getWellnessCheckInMetadata(isMobileSized, socialCareEnabled)
  const { runRulesEngine } = useWellnessCheckInRulesEngine()
  const submitRef = useRef(noop)
  const [currMoodOption, setCurrMoodOption] = useState(HowAreYouFeelingTodayOption.Ok)
  const [showPopInEmoji, setShowPopInEmoji] = useState(false)
  const isHowAreYouFeelingTodayPage = currentPage === 1
  const isSocialCarePage = socialCareEnabled && currentPage === totalPages

  const handleFormChange = ({ values }: FormChangeProperties) => {
    if (isHowAreYouFeelingTodayPage) {
      setCurrMoodOption(
        Object.values(HowAreYouFeelingTodayOption)[
          values.howAreYouFeelingToday as number
        ] as HowAreYouFeelingTodayOption,
      )
      setShowPopInEmoji(true)
    }
  }

  const handleSaveForm = async ({ values }: { values: Dict }) => {
    const formResponse = await runRulesEngine({ values })
    onSubmit(formResponse)
  }

  const handleCTAPress = () => {
    if (currentPage === totalPages) {
      submitRef.current()
    }
    if (currentPage < totalPages) {
      onCTAPress()
    }
  }

  return (
    <WellnessCheckInBackgroundContainer>
      {isHowAreYouFeelingTodayPage ? (
        <HowAreYouFeelingTodayContainer>
          <Subhead
            text={
              <FormattedMessage
                defaultMessage='To start, how are you feeling today?'
                description='Wellness Check In: Initial question for user completing wellness check in form'
              />
            }
            size={isMobileSized ? SubheadSize.MEDIUM : SubheadSize.LARGE}
            textAlign='center'
          />
          <AnimationContainer>
            {showPopInEmoji && (
              <MoodEmojiPopInLottie
                animationSource={MOOD_LOTTIE_JSON[currMoodOption]}
                handleAnimationFinished={() => setShowPopInEmoji(false)}
              />
            )}
            {!showPopInEmoji && <MoodEmojiLottie animationSource={MOOD_LOTTIE_JSON[currMoodOption]} />}
          </AnimationContainer>
          <Subhead
            text={intl.formatMessage(HOW_ARE_YOU_FEELING_TODAY_MSG[currMoodOption])}
            size={SubheadSize.XSMALL}
            textAlign='center'
          />
        </HowAreYouFeelingTodayContainer>
      ) : isSocialCarePage ? (
        <SocialCareMarginContainer />
      ) : (
        <InstructionsContainer>
          <BodyText
            text={
              <FormattedMessage
                defaultMessage='On most days over the past two weeks:'
                description='Context for the wellness check in form for user to answer questions about their mental health for the past two weeks'
              />
            }
          />
        </InstructionsContainer>
      )}
      <FormBody
        name='WellnessCheckInForm'
        schema={schema}
        uiSchema={uiSchema}
        intl={intl}
        saveForm={handleSaveForm}
        onFormChange={handleFormChange}
        showCurrentPageOnly
        currentPage={currentPage}
        handleSubmitRef={submitRef}
        totalPages={totalPages}
        enableTranslations
        formButton={(props: FormButtonParams) => {
          submitRef.current = props.handleSubmit
          return (
            <CTAContainer>
              <PrimaryButton
                {...props}
                text={<FormattedMessage defaultMessage='Continue' description='Button to continue to next screen' />}
                onPress={handleCTAPress}
                fullWidth={isMobileSized}
                testID={tID('WellnessCheckInForm-submit')}
              />
            </CTAContainer>
          )
        }}
        scrollContainerCustomStyles={{
          formBodyPageContainer: css`
            background-color: transparent;
            max-width: ${isHowAreYouFeelingTodayPage
              ? QUESTIONNAIRE_PAGE_WIDTH
              : HOW_ARE_YOU_FEELING_TODAY_PAGE_WIDTH}px;
            padding-top: ${isHowAreYouFeelingTodayPage || isMobileSized ? spacing['24px'] : spacing['32px']};
          `,
          scrollContainerStyle: { overflow: 'visible' },
        }}
        formBodyCustomStyles={{
          submitButtonContainer: css`
            border-top-width: 0;
            background: transparent;
            box-shadow: none;
            margin-bottom: 80px;
          `,
          submitButtonWrapper: css`
            max-width: ${isHowAreYouFeelingTodayPage
              ? QUESTIONNAIRE_PAGE_WIDTH
              : HOW_ARE_YOU_FEELING_TODAY_PAGE_WIDTH}px;
            padding-left: 16px;
            padding-right: 16px;
          `,
        }}
      />
    </WellnessCheckInBackgroundContainer>
  )
}

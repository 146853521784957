import React, { FunctionComponent, useContext } from 'react'
import { StyleSheet, Text } from 'react-native'

import { useTheme } from 'styled-components/native'

import { HyperLinkWithStyle } from '../../atoms/link/HyperLinkWithStyle'
import { AppContext } from '../../context'
import { getTypeStyles } from '../../styles'

type ChatBubbleTextProps = {
  text: string
  fontColor?: string
  onLinkPress?: (link: string) => void
}

const styles = StyleSheet.create({
  text: {
    textAlign: 'left',
    flexShrink: 1,
    flexWrap: 'wrap',
    margin: 12,
    lineHeight: 24,
  },
})
/**
 * Simple Chat bubble text styling
 */
export const ChatBubbleText: FunctionComponent<ChatBubbleTextProps> = ({ text, fontColor, onLinkPress }) => {
  const { copyPasteDisabled } = useContext(AppContext)
  const { colors } = useTheme()
  return (
    <HyperLinkWithStyle textColor={fontColor} onLinkPress={onLinkPress}>
      {/* Required to be Text component for Hyperlink to work correctly */}
      {/* eslint-disable-next-line lyrahealth/no-react-native-text */}
      <Text
        selectable={!copyPasteDisabled}
        style={[getTypeStyles(colors).bodyDefault, styles.text, { color: fontColor }]}
      >
        {text}
      </Text>
    </HyperLinkWithStyle>
  )
}
